import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormSelect,
  FormCheckbox
} from "shards-react";

import { Divider, Typography, TextField, Grid, Dialog,
         DialogContent, DialogActions, DialogTitle, DialogContentText,
         Button as ButtonMaterialUI } from '@material-ui/core';

import { Table } from 'reactstrap';

//translate
import { FormattedMessage } from 'react-intl';
//
import {CopyToClipboard} from 'react-copy-to-clipboard';
//
import SignPad from './signpad'

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//UUID
import uuid from 'uuid';

//JWT
import jwt from 'jsonwebtoken';
import * as jose from 'jose'

//Image
import ImageUploader from 'react-images-upload';
import ReactImageFallback from "react-image-fallback";

//Base64
import { Base64 } from 'js-base64';

import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

//Prefix Phones by countries
const Prefix = require('./CountryDialPhoneCodes.json');

//const { encryptECIES } = require('blockstack/lib/encryption');


function ConfirmacionChangeThemeProfile (props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"><div className="fa fa-info">&nbsp;&nbsp;{props.dialogTitle}</div></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Table className="text-center" responsive borderless style={{ color: 'black', fontSize:14 }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "420px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <label style={{ color: 'grey', fontSize:16 }} htmlFor="feAboutMe">Background Image</label>
                          <ImageUploader
                              withIcon={true}
                              withPreview={true}
                              buttonText='Choose image'
                              onChange={props.handleChangeBackground}
                              imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                              maxFileSize={10485760}
                              fileSizeError=" file size is too big"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{width: "420px", textAlign:"center", color: 'black', fontSize:14 }}>
                          {props.backgroundTF ?
                            <img src={props.background} height="50" alt=""/>
                          :
                            null
                          }
                        </td>
                      </tr>
                      <tr>
                        <td style={{width: "420px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <FormCheckbox
                            toggle
                            checked={props.checkedMode}
                            onChange={e=>props.handleChangeMode(e,props.checkedMode)}>
                          </FormCheckbox>
                          Theme {props.checkedMode ? 'Dark' : 'Light'}
                        </td>
                      </tr>
                   </tbody>
                </Table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', props.mode, props.background)} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm', props.mode, props.background)} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionChangeVcardProfile (props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"><div className="fa fa-info">&nbsp;&nbsp;{props.dialogTitle}</div></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Table className="text-center" responsive borderless style={{ color: 'black', fontSize:14 }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "420px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <FormCheckbox
                               toggle
                               checked={props.checkedModeVcard}
                               onChange={e=>props.handleChangeModeVcard(e,props.checkedModeVcard)}
                           >
                               VCARD {props.checkedModeVcard ? 'Active' : 'Inactive'}
                           </FormCheckbox>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "420px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <FormCheckbox
                               inline
                               checked={props.checkedModeVcardTelephone}
                               onChange={e=>props.handleChangeModeVcardTelephone(e,props.checkedModeVcardTelephone)}
                           >
                               Include Telephone
                           </FormCheckbox>
                        </td>
                      </tr>
                      <tr>
                        <td style={{width: "420px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <FormCheckbox
                               inline
                               checked={props.checkedModeVcardEmail}
                               onChange={e=>props.handleChangeModeVcardEmail(e,props.checkedModeVcardEmail)}
                           >
                               Include Email
                           </FormCheckbox>
                         </td>
                      </tr>
                      <tr>
                        <td style={{width: "420px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <FormCheckbox
                               inline
                               checked={props.checkedModeVcardSocialNetwork}
                               onChange={e=>props.handleChangeModeVcardSocialNetwork(e,props.checkedModeVcardSocialNetwork)}
                           >
                               Include Social Network
                           </FormCheckbox>
                         </td>
                      </tr>
                      <tr>
                        <td style={{width: "420px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <FormCheckbox
                               inline
                               checked={props.checkedModeVcardWeb}
                               onChange={e=>props.handleChangeModeVcardWeb(e,props.checkedModeVcardWeb)}
                           >
                               Include Web
                           </FormCheckbox>
                         </td>
                      </tr>
                      <tr>
                        <td style={{width: "420px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <FormCheckbox
                               inline
                               checked={props.checkedModeVcardId}
                               onChange={e=>props.handleChangeModeVcardId(e,props.checkedModeVcardId)}
                           >
                               Include ID
                           </FormCheckbox>
                         </td>
                      </tr>
                      <tr>
                        <td style={{width: "420px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <FormCheckbox
                               inline
                               checked={props.checkedModeVcardNotes}
                               onChange={e=>props.handleChangeModeVcardNotes(e,props.checkedModeVcardNotes)}
                           >
                               Include Description
                           </FormCheckbox>
                         </td>
                      </tr>
                      <tr>
                        <td style={{width: "420px", textAlign:"left", color: 'black', fontSize:14 }}>
                        </td>
                      </tr>
                   </tbody>
                </Table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialogVcard(props.dialogTitle, 'cancel', props.modeVcard, props.checkedModeVcardTelephone, props.checkedModeVcardEmail, props.checkedModeVcardSocialNetwork, props.checkedModeVcardWeb, props.checkedModeVcardId, props.checkedModeVcardNotes)} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialogVcard(props.dialogTitle, 'confirm', props.modeVcard, props.checkedModeVcardTelephone, props.checkedModeVcardEmail, props.checkedModeVcardSocialNetwork, props.checkedModeVcardWeb, props.checkedModeVcardId, props.checkedModeVcardNotes)} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class UserAccountDetails  extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userSession: new UserSession(),
      username: '',
      user: {},
      jsonBlockstack: undefined,
      status: false,
      value: '',
      copied: false,
      prefixX: '',
      smsPrefix: '',
      smsCountry: '',
      dialogOpen: false,
      dialogOpenVcard: false,
      dialogMessage: '',
      dialogTitle: '',
      mode: 'Light',
      modeVcard: 'Active',
      background: '',
      checkedMode: false,
      checkedModeVcard: true,
      checkedModeVcardTelephone: false,
      checkedModeVcardEmail: false,
      checkedModeVcardSocialNetwork: false,
      checkedModeVcardWeb: false,
      checkedModeVcardId: false,
      checkedModeVcardNotes: false,
      pictures: '',
      profileAvatar: '',
      newavatar: true,
      file64ProfileAvatar: '',
    }
  }

  c01EmailNotifications = React.createRef();
  c02SMSNotifications = React.createRef();
  c03STX = React.createRef();
  c04Applications = React.createRef();
  c02SMSCountry = React.createRef();
  c01EmailScope = React.createRef();
  c02SMSScope = React.createRef();
  c03STXScope = React.createRef();
  c03BTC = React.createRef();
  c03BitcoinScope = React.createRef();
  c04ApplicationsScope = React.createRef();

  componentDidMount() {
    this.c01EmailScope.current.value = this.props.emailScope
    this.c02SMSScope.current.value = this.props.smsScope
    this.c03STXScope.current.value = this.props.stxScope
    this.c02SMSCountry.current.value = this.props.smsCountry
    this.c03BitcoinScope.current.value = this.props.btcScope
    this.c04ApplicationsScope.current.value = this.props.applicationsScope
    this.setState({ prefixX: this.props.smsPrefix, smsPrefix: this.props.smsPrefix, smsCountry: this.props.smsCountry });
    this.setState({ mode: this.props.mode, modeVcard: this.props.modeVcard, background: this.props.background });
    this.setState({ checkedMode: this.props.checkedMode, checkedModeVcard: this.props.checkedModeVcard, checkedModeVcardTelephone: this.props.checkedModeVcardTelephone });
    this.setState({ checkedModeVcardEmail: this.props.checkedModeVcardEmail, checkedModeVcardSocialNetwork: this.props.checkedModeVcardSocialNetwork, checkedModeVcardWeb: this.props.checkedModeVcardWeb });
    this.setState({ checkedModeVcardId: this.props.checkedModeVcardId, checkedModeVcardNotes: this.props.checkedModeVcardNotes });
  }

  UNSAFE_componentWillReceiveProps() {
    this.c01EmailScope.current.value = this.props.emailScope
    this.c02SMSScope.current.value = this.props.smsScope
    this.c03STXScope.current.value = this.props.stxScope
    this.c02SMSCountry.current.value = this.props.smsCountry
    this.c03BitcoinScope.current.value = this.props.btcScope
    this.c04ApplicationsScope.current.value = this.props.applicationsScope
    this.setState({ prefixX: this.props.smsPrefix, smsPrefix: this.props.smsPrefix, smsCountry: this.props.smsCountry });
    this.setState({ mode: this.props.mode, modeVcard: this.props.modeVcard, background: this.props.background });
    this.setState({ checkedMode: this.props.checkedMode, checkedModeVcard: this.props.checkedModeVcard, checkedModeVcardTelephone: this.props.checkedModeVcardTelephone });
    this.setState({ checkedModeVcardEmail: this.props.checkedModeVcardEmail, checkedModeVcardSocialNetwork: this.props.checkedModeVcardSocialNetwork, checkedModeVcardWeb: this.props.checkedModeVcardWeb });
    this.setState({ checkedModeVcardId: this.props.checkedModeVcardId, checkedModeVcardNotes: this.props.checkedModeVcardNotes });
  }

  handleDialog = (type, action, mode, background) => {
      if (action === 'confirm') {
        this.setState({ dialogOpen: false });
        this.nuevoThemeProfile(mode,background)
      }
      if (action === 'cancel') {
        this.setState({ dialogOpen: false });
      }
  }

  handleDialogVcard = (type, action, mode, telephone, email, socialnetwork, web, id, notes) => {
      if (action === 'confirm') {
        this.setState({ dialogOpenVcard: false });
        this.nuevoVcardProfile(mode,telephone,email,socialnetwork,web,id,notes)
      }
      if (action === 'cancel') {
        this.setState({ dialogOpenVcard: false });
      }
  }

  handleChangePrefix = (e) => {
    this.setState({
        prefixX: this.c02SMSCountry.current.value,
        smsPrefix: this.c02SMSCountry.current.value,
        smsCountry: this.props.smsCountry
    });
  }

  handleChangeBackground = (pictureFiles, pictureDataURLs) => {
    this.setState({ pictures: pictureFiles, profileAvatar: pictureDataURLs, newavatar: true, file64ProfileAvatar: Base64.encode(pictureDataURLs) });
    this.setState({background: pictureDataURLs})
  }

  handleChangeMode = (e,modeX) => {
    if (modeX === false){
       this.setState({mode: 'Dark'})
       this.setState({checkedMode: true});
    }else {
      this.setState({mode: "Light"})
      this.setState({checkedMode: false});
    }
  }

  handleChangeModeVcard = (e,modeX) => {
    e.preventDefault();
    if (modeX === false){
       this.setState({modeVcard: 'Active'})
       this.setState({checkedModeVcard: true});
    }else {
      this.setState({modeVcard: "Inactive"})
      this.setState({checkedModeVcard: false, checkedModeVcardTelephone: false, checkedModeVcardEmail: false, checkedModeVcardSocialNetwork: false, checkedModeVcardWeb: false, checkedModeVcardId: false, checkedModeVcardNotes: false});
    }
  }

  handleChangeModeVcardTelephone = (e,modeX) => {
    e.preventDefault();
    if (modeX === false){
       this.setState({checkedModeVcardTelephone: true});
       this.setState({modeVcard: 'Active'})
       this.setState({checkedModeVcard: true});
    }else {
      this.setState({checkedModeVcardTelephone: false});
    }
  }

  handleChangeModeVcardEmail = (e,modeX) => {
    e.preventDefault();
    if (modeX === false){
       this.setState({checkedModeVcardEmail: true});
       this.setState({modeVcard: 'Active'})
       this.setState({checkedModeVcard: true});
    }else {
      this.setState({checkedModeVcardEmail: false});
    }
  }

  handleChangeModeVcardSocialNetwork = (e,modeX) => {
    e.preventDefault();
    if (modeX === false){
       this.setState({checkedModeVcardSocialNetwork: true});
       this.setState({modeVcard: 'Active'})
       this.setState({checkedModeVcard: true});
    }else {
      this.setState({checkedModeVcardSocialNetwork: false});
    }
  }

  handleChangeModeVcardWeb = (e,modeX) => {
    e.preventDefault();
    if (modeX === false){
       this.setState({checkedModeVcardWeb: true});
       this.setState({modeVcard: 'Active'})
       this.setState({checkedModeVcard: true});
    }else {
      this.setState({checkedModeVcardWeb: false});
    }
  }

  handleChangeModeVcardId = (e,modeX) => {
    e.preventDefault();
    if (modeX === false){
       this.setState({checkedModeVcardId: true});
       this.setState({modeVcard: 'Active'})
       this.setState({checkedModeVcard: true});
    }else {
      this.setState({checkedModeVcardId: false});
    }
  }

  handleChangeModeVcardNotes = (e,modeX) => {
    e.preventDefault();
    if (modeX === false){
       this.setState({checkedModeVcardNotes: true});
       this.setState({modeVcard: 'Active'})
       this.setState({checkedModeVcard: true});
    }else {
      this.setState({checkedModeVcardNotes: false});
    }
  }

  handleCaracteresEspeciales = (e) => {
    let palabra = e.target.value
    let ArrayIdValue = palabra.split(`"`)
    e.target.value = ArrayIdValue[0]
    palabra = e.target.value
    ArrayIdValue = palabra.split("'")
    e.target.value = ArrayIdValue[0]
  }

  changeThemeProfile = (e) =>{
    e.preventDefault();
    Promise.all([this.getThemeProfile()])
     .then(
       (resolve) =>{},
       (reject) =>{})
    this.setState({ dialogOpen: true, dialogTitle: 'Theme Profile' })
  }

  changeVcardProfile = (e) =>{
    e.preventDefault();
    Promise.all([this.getVcardProfile()])
     .then(
       (resolve) =>{},
       (reject) =>{})
    this.setState({ dialogOpenVcard: true, dialogTitle: 'Vcard' })
  }


  nuevoThemeProfile = (mode,background) =>{
      const file64 = Base64.encode(background);
      const jsonBlockstack =`{"mode":"${mode}","background":"${file64}"}`;
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      putFileDB(this.props.username, `themeprofile.json`, 'Crosscheck_Profile', JSON.stringify(jsonBlockstack), JSON.stringify(options))
       .then(() => {
         this.setState({ mode: mode });
         this.setState({ background: background });
         if (mode === 'Dark'){
            this.setState({checkedMode: true});
         }else{
            this.setState({checkedMode: false});
         }
       })
  }

  nuevoVcardProfile = (mode,telephone,email,socialnetwork,web,id,notes) =>{
      const jsonBlockstack =`{"mode":"${mode}","telephone":"${telephone}","email":"${email}","socialnetwork":"${socialnetwork}","web":"${web}","id":"${id}","notes":"${notes}"}`;
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      putFileDB(this.props.username, `vcardprofile.json`, 'Crosscheck_Profile', JSON.stringify(jsonBlockstack), JSON.stringify(options))
       .then(() => {
         this.setState({ modecheckedModeVcard: mode, checkedModeVcardTelephone: telephone, checkedModeVcardEmail: email, checkedModeVcardSocialNetwork: socialnetwork, checkedModeVcardWeb: web, checkedModeVcardId: id, checkedModeVcardNotes: notes });
       })
  }

  getThemeProfile = () =>{
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    return new Promise ((resolve1, reject1) =>{
      getFileDB(this.props.username, `themeprofile.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)

             this.setState({ mode: jsonBlockstack6.mode  });
             this.setState({ background: Base64.decode(jsonBlockstack6.background) });
             if (jsonBlockstack6.mode === 'Dark'){
                this.setState({checkedMode: true});
             }else{
                this.setState({checkedMode: false});
             }
             resolve1()
           } else {
             reject1()
           }
         })
        .catch(error => {
          console.log(error)
          reject1()
         });
    });
  }

  getVcardProfile = () =>{
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    return new Promise ((resolve1, reject1) =>{
      getFileDB(this.props.username, `vcardprofile.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)

             this.setState({ modeVcard: jsonBlockstack6.mode, checkedModeVcardTelephone: jsonBlockstack6.telephone, checkedModeVcardEmail: jsonBlockstack6.email });
             this.setState({ checkedModeVcardSocialNetwork: jsonBlockstack6.socialnetwork, checkedModeVcardWeb: jsonBlockstack6.web, checkedModeVcardId: jsonBlockstack6.id, checkedModeVcardNotes: jsonBlockstack6.notes });
            if (jsonBlockstack6.mode === 'Active'){
               this.setState({checkedModeVcard: true});
            }else{
               this.setState({checkedModeVcard: false});
            }

             if (jsonBlockstack6.telephone === 'true'){
                this.setState({checkedModeVcardTelephone: true});
             }else{
                this.setState({checkedModeVcardTelephone: false});
             }
             if (jsonBlockstack6.email === 'true'){
                this.setState({checkedModeVcardEmail: true});
             }else{
                this.setState({checkedModeVcardEmail: false});
             }
             if (jsonBlockstack6.socialnetwork === 'true'){
                this.setState({checkedModeVcardSocialNetwork: true});
             }else{
                this.setState({checkedModeVcardSocialNetwork: false});
             }
             if (jsonBlockstack6.web === 'true'){
                this.setState({checkedModeVcardWeb: true});
             }else{
                this.setState({checkedModeVcardWeb: false});
             }
             if (jsonBlockstack6.id === 'true'){
                this.setState({checkedModeVcardId: true});
             }else{
                this.setState({checkedModeVcardId: false});
             }
             if (jsonBlockstack6.notes === 'true'){
                this.setState({checkedModeVcardNotes: true});
             }else{
                this.setState({checkedModeVcardNotes: false});
             }
             resolve1()
           } else {
             reject1()
           }
         })
        .catch(error => {
          console.log(error)
          reject1()
         })
    })
  }

  nuevoProfileUser = e => {
      e.preventDefault();
      let EmailNotifications = ''
      let SMSNotifications = ''
      let cryptoSTX = ''
      let cryptoBTC = ''
      let cryptoKDA = ''
      let cryptoETH = ''

      if (this.c03STX.current.value.length > 0){
         cryptoSTX = this.c03STX.current.value
      }else{
        cryptoSTX = this.props.STX
      }
      if (this.c03BTC.current.value.length > 0){
         cryptoBTC = this.c03BTC.current.value
      }else{
        cryptoBTC = this.props.identityAddress
      }
      if (this.c01EmailNotifications.current.value.length > 0){
         EmailNotifications = this.c01EmailNotifications.current.value
      }else{
         EmailNotifications = this.props.EMAIL
      }
      if (this.c02SMSNotifications.current.value.length > 0){
         SMSNotifications = this.c02SMSNotifications.current.value
      }else{
         SMSNotifications = this.props.SMS
      }
      let STXScope = this.c03STXScope.current.value
      let BTCScope = this.c03BitcoinScope.current.value
      let SMSScope = this.c02SMSScope.current.value
      let emailScope = this.c01EmailScope.current.value
      let smsCountry = this.c02SMSCountry.current.value
      let applicationsScope = this.c04ApplicationsScope.current.value
      let smsPrefix = this.state.smsPrefix
      // enviar a Blockstack

      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      getFileDB(this.props.username, `myUserConfig.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             this.setState({ jsonProfileUser: jsonBlockstack5  });

             const jsonBlockstack =`{"emailNotifications":"${EmailNotifications}","smsNotifications":"${SMSNotifications}","STX":"${cryptoSTX}","BTC":"${cryptoBTC}","KDA":"${cryptoKDA}","ETH":"${cryptoETH}","profileName":"${jsonBlockstack6.profileName}","profileAvatar":"${jsonBlockstack6.profileAvatar}","facebook":"${jsonBlockstack6.facebook}","twitter":"${jsonBlockstack6.twitter}","youtube":"${jsonBlockstack6.youtube}","instagram":"${jsonBlockstack6.instagram}","linkedin":"${jsonBlockstack6.linkedin}","pinterest":"${jsonBlockstack6.pinterest}","aboutme":"${jsonBlockstack6.aboutme}","smsScope":"${SMSScope}","btcScope":"${BTCScope}","emailScope":"${emailScope}","smsCountry":"${smsCountry}","smsPrefix":"${smsPrefix}","stxScope":"${STXScope}","webSite":"${jsonBlockstack6.webSite}","applicationsScope":"${applicationsScope}"}`;
             this.putNuevoProfileUser(jsonBlockstack)
           } else {
             const jsonBlockstack =`{"emailNotifications":"${EmailNotifications}","smsNotifications":"${SMSNotifications}","STX":"${cryptoSTX}","BTC":"${cryptoBTC}","KDA":"${cryptoKDA}","ETH":"${cryptoETH}","profileName":"","profileAvatar":"","facebook":"","twitter":"","youtube":"","instagram":"","linkedin":"","pinterest":"","aboutme":"","smsScope":"${SMSScope}","btcScope":"${BTCScope}","emailScope":"${emailScope}","smsCountry":"${smsCountry}","smsPrefix":"${smsPrefix}","stxScope":"${STXScope}","webSite":"","applicationsScope":"${applicationsScope}"}`;
             this.putNuevoProfileUser(jsonBlockstack)
           }
         })
        .catch(error => {
          const jsonBlockstack =`{"emailNotifications":"${EmailNotifications}","smsNotifications":"${SMSNotifications}","STX":"${cryptoSTX}","BTC":"${cryptoBTC}","KDA":"${cryptoKDA}","ETH":"${cryptoETH}","profileName":"","profileAvatar":"","facebook":"","twitter":"","youtube":"","instagram":"","linkedin":"","pinterest":"","aboutme":"","smsScope":"${SMSScope}","btcScope":"${BTCScope}","emailScope":"${emailScope}","smsCountry":"${smsCountry}","smsPrefix":"${smsPrefix}","stxScope":"${STXScope}","webSite":"","applicationsScope":"${applicationsScope}"}`;
          this.putNuevoProfileUser(jsonBlockstack)
         });
  }

  putNuevoProfileUser = (jsonBlockstack) => {
      this.setState({ jsonBlockstack })
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      putFileDB(this.props.username, `myUserConfig.json`, 'Crosscheck_Profile', JSON.stringify(jsonBlockstack), JSON.stringify(options))
       .then(() => {})
           this.putProfile(JSON.parse(jsonBlockstack))
      // redireccionar
      const path = '/'
      this.props.history.push(path);
  }


  putProfile = (jsonBlockstack3) => {
    let jwtProfile = ''
    let jwtFecha = new Date();
    let jwtToken = ''
    let jwtName = jsonBlockstack3.profileName
    let jwtDescription = jsonBlockstack3.aboutme
    let jwtWeb = jsonBlockstack3.webSite
    let jwtEmail = jsonBlockstack3.emailNotifications
    let jwtSms = jsonBlockstack3.smsNotifications
    let jwtSmsCountry = jsonBlockstack3.smsCountry
    let jwtSmsPrefix = jsonBlockstack3.smsPrefix
    let jwtScopeSms = jsonBlockstack3.smsScope
    let jwtScopeEmail = jsonBlockstack3.emailScope
    let jwtScopeApplications = jsonBlockstack3.applicationsScope
    let jwtIdBitcoin = jsonBlockstack3.BTC
    let jwtScopeBitcoin = jsonBlockstack3.btcScope
    let jwtIdStacks = jsonBlockstack3.STX
    let jwtScopeStacks = jsonBlockstack3.stxScope
    let jwtUrlFacebook = jsonBlockstack3.facebook
    let jwtUrlTwitter = jsonBlockstack3.twitter
    let jwtUrlYoutube = jsonBlockstack3.youtube
    let jwtUrlInstagram = jsonBlockstack3.instagram
    let jwtUrlLinkedin = jsonBlockstack3.linkedin
    let jwtUrlPinterest = jsonBlockstack3.pinterest
    let jwtAvatar = jsonBlockstack3.profileAvatar
    let jwtSignature = ''

    jwtProfile = jwtProfile + `[`
    jwtProfile = jwtProfile + `  {`
    jwtProfile = jwtProfile + `    "header": {`
    jwtProfile = jwtProfile + `      "typ": "JWT",`
    jwtProfile = jwtProfile + `       "alg": "HS256"`
    jwtProfile = jwtProfile + `     },`
    jwtProfile = jwtProfile + `     "payload": {`
    jwtProfile = jwtProfile + `        "jti": "${uuid()}",`
    jwtProfile = jwtProfile + `        "iat": "${jwtFecha.toISOString()}",`
    jwtProfile = jwtProfile + `        "exp": "${jwtFecha.toISOString()}",`
    jwtProfile = jwtProfile + `        "claim": {`
    jwtProfile = jwtProfile + `          "@type": "Person",`
    jwtProfile = jwtProfile + `          "@context": "http://schema.org",`
    jwtProfile = jwtProfile + `          "name": "${jwtName}",`
    jwtProfile = jwtProfile + `          "description": "${jwtDescription}",`
    jwtProfile = jwtProfile + `          "web": "${jwtWeb}",`
    jwtProfile = jwtProfile + `          "account": [`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "email",`
    jwtProfile = jwtProfile + `              "identifier": "${jwtEmail}",`
    jwtProfile = jwtProfile + `              "proofType": "mailto",`
    jwtProfile = jwtProfile + `              "proofUrl": "",`
    jwtProfile = jwtProfile + `              "scope": "${jwtScopeEmail}"`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "sms",`
    jwtProfile = jwtProfile + `              "identifier": "${jwtSms}",`
    jwtProfile = jwtProfile + `              "proofType": "phone",`
    jwtProfile = jwtProfile + `              "proofUrl": "",`
    jwtProfile = jwtProfile + `              "smsCountry": "${jwtSmsCountry}",`
    jwtProfile = jwtProfile + `              "smsPrefix": "${jwtSmsPrefix}",`
    jwtProfile = jwtProfile + `              "scope": "${jwtScopeSms}"`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "bitcoin",`
    jwtProfile = jwtProfile + `              "identifier": "${jwtIdBitcoin}",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "",`
    jwtProfile = jwtProfile + `              "scope": "${jwtScopeBitcoin}"`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "stacks",`
    jwtProfile = jwtProfile + `              "identifier": "${jwtIdStacks}",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "",`
    jwtProfile = jwtProfile + `              "scope": "${jwtScopeStacks}"`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "facebook",`
    jwtProfile = jwtProfile + `              "identifier": "",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "${jwtUrlFacebook}",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "twitter",`
    jwtProfile = jwtProfile + `              "identifier": "",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "${jwtUrlTwitter}",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "youtube",`
    jwtProfile = jwtProfile + `              "identifier": "",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "${jwtUrlYoutube}",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "instagram",`
    jwtProfile = jwtProfile + `              "identifier": "",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "${jwtUrlInstagram}",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "linkedin",`
    jwtProfile = jwtProfile + `              "identifier": "",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "${jwtUrlLinkedin}",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "pinterest",`
    jwtProfile = jwtProfile + `              "identifier": "",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "${jwtUrlPinterest}",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "did:web",`
    jwtProfile = jwtProfile + `              "identifier": "${this.props.username}",`
    jwtProfile = jwtProfile + `              "proofType": "did",`
    jwtProfile = jwtProfile + `              "proofUrl": "",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "did:stack:v2",`
    jwtProfile = jwtProfile + `              "identifier": "did:stack:v2:${this.props.STX}",`
    jwtProfile = jwtProfile + `              "proofType": "did",`
    jwtProfile = jwtProfile + `              "proofUrl": "",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "did:btc-addr",`
    jwtProfile = jwtProfile + `              "identifier": "did:btc-addr:${this.props.identityAddress}",`
    jwtProfile = jwtProfile + `              "proofType": "did",`
    jwtProfile = jwtProfile + `              "proofUrl": "",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            }`
    jwtProfile = jwtProfile + `          ],`
    jwtProfile = jwtProfile + `          "applicationsScope": "${jwtScopeApplications}",`
    jwtProfile = jwtProfile + `          "avatar": "${jwtAvatar}"`
    jwtProfile = jwtProfile + `        }`
    jwtProfile = jwtProfile + `     }`
    jwtProfile = jwtProfile + `  }`
    jwtProfile = jwtProfile + `]`

    jwtSignature = `"${this.props.username}${jwtFecha.toISOString()}"`
    jwtToken = jwt.sign(jwtProfile,jwtSignature)

    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
    putFileDB(this.props.username, `profile.json`, 'Crosscheck_Profile', jwtToken, JSON.stringify(options))
     .then(() => {
        this.putDidW3c(jsonBlockstack3)
    })
  }

  putDidW3c = (jsonBlockstack3) => {
    let jwtDidW3c = ''
    jwtDidW3c = jwtDidW3c + `{`
    jwtDidW3c = jwtDidW3c + `  "@context": "https://www.w3.org/ns/did/v1",`
    jwtDidW3c = jwtDidW3c + `  "id": "did:web:${this.props.username}",`
    jwtDidW3c = jwtDidW3c + `  "verificationMethod": [{`
    jwtDidW3c = jwtDidW3c + `     "id": "did:web:${this.props.username}#controller",`
    jwtDidW3c = jwtDidW3c + `     "type": "Secp256k1",`
    jwtDidW3c = jwtDidW3c + `     "controller": "did:web:${this.props.username}",`
    jwtDidW3c = jwtDidW3c + `     "stacksAddress": "${jsonBlockstack3.STX}"`
    jwtDidW3c = jwtDidW3c + `  }],`
    jwtDidW3c = jwtDidW3c + `  "authentication": [`
    jwtDidW3c = jwtDidW3c + `     "did:web:${this.props.username}#controller"`
    jwtDidW3c = jwtDidW3c + `  ]`
    jwtDidW3c = jwtDidW3c + `}`

    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
    putFileDB(this.props.username, `didw3c.json`, 'Crosscheck_Profile', jwtDidW3c, JSON.stringify(options))
     .then(() => {
    })
  }

  copyText = (e,crypto) => {
    let value=''
    if (crypto==='stx'){if (this.c03STX.current.value.length > 0){value=this.c03STX.current.value}else{value=this.props.STX}}
    if (crypto==='btc'){if (this.c03BTC.current.value.length > 0){value=this.c03BTC.current.value}else{value=this.props.BTC}}
    if (crypto==='kda'){if (this.c04KDA.current.value.length > 0){value=this.c04KDA.current.value}else{value=this.props.KDA}}
    if (crypto==='eth'){if (this.c05ETH.current.value.length > 0){value=this.c05ETH.current.value}else{value=this.props.ETH}}
    this.setState({value})

    return(
      <CopyToClipboard
         text={value}
         onCopy={() => this.setState({copied: true})}>
      </CopyToClipboard>
    )
  }

  copyToClipBoard = async () => {
    let value=this.props.stacksNetX
    try {
      await navigator.clipboard.writeText(value);
      this.setState({copied: true})
    } catch (err) {
      this.setState({copied: false})
    }
  };

  copyToClipBoard2 = async () => {
    let value=this.props.BTC
    if (this.props.BTC === undefined){value= this.props.identityAddress}
    try {
      await navigator.clipboard.writeText(value);
      this.setState({copied: true})
    } catch (err) {
      this.setState({copied: false})
    }
  };

  render() {
    const { userSession } = this.props
    const identityAddress = 'did:btc-addr:'+this.props.identityAddress
    const identityAddressStack = 'did:stack:v2:'+this.props.STX
    const zonefile_hash = this.props.zonefile_hash

    if (EMAIL === undefined){EMAIL=''}
    let {EMAIL} = this.props
    let {SMS} = this.props
    let STX = this.props.stacksNetX
    let BTC = this.props.BTC
    let {KDA} = this.props
    let {ETH} = this.props
    if (EMAIL === undefined){EMAIL=''}
    if (SMS === undefined){SMS=''}
    if (STX === undefined){STX=''}
    if (BTC === undefined){BTC= this.props.identityAddress}
    if (KDA === undefined){KDA=''}
    if (ETH === undefined){ETH=''}
    let existEmail = false
    let existSMS = false
    let existSTX = false
    let existBTC = false
    let existKDA = false
    let existETH = false
    if (EMAIL.length > 0){existEmail = true}
    if (SMS.length > 0){existSMS = true}
    if (STX.length > 0){existSTX = true}
    if (BTC.length > 0){existBTC = true}
    if (KDA.length > 0){existKDA = true}
    if (ETH.length > 0){existETH = true}

    let stacksNetX2 = ''
    if (this.props.serverStacksX==='MainNet'){
       stacksNetX2 = 'mainnet'
    }
    if (this.props.serverStacksX==='TestNet'){
       stacksNetX2 = 'testnet'
    }

    let backgroundTF = false
    if (this.state.background !== ''){
      backgroundTF = true
    }

    let editstxnetwork = true
    let editsignature = true
    let editthemeprofile = true
    let editvcard = true

    let opacity1X = '0.5'
    let opacity2X = '0.5'
    let opacity3X = '0.5'
    let opacity4X = '0.5'
    let opacity5X = '0.5'
    let opacity6X = '0.5'
    let opacity7X = '0.5'
    let opacity8X = '0.5'
    let opacity9X = '0.5'
    let activeTab1X = false
    let activeTab2X = false
    let activeTab3X = false
    let activeTab4X = false
    let activeTab5X = false
    let activeTab6X = false
    let activeTab7X = false
    let activeTab8X = false
    let activeTab9X = false
    let activeTab10X = false
    if (this.props.activeTab === '1'){opacity1X = '1.0'; activeTab1X = true}
    if (this.props.activeTab === '2'){opacity2X = '1.0'; activeTab2X = true}
    if (this.props.activeTab === '3'){opacity3X = '1.0'; activeTab3X = true}
    if (this.props.activeTab === '4'){opacity4X = '1.0'; activeTab4X = true}
    if (this.props.activeTab === '5'){opacity5X = '1.0'; activeTab5X = true}
    if (this.props.activeTab === '6'){opacity6X = '1.0'; activeTab6X = true}
    if (this.props.activeTab === '7'){opacity7X = '1.0'; activeTab7X = true}
    if (this.props.activeTab === '8'){opacity8X = '1.0'; activeTab8X = true}
    if (this.props.activeTab === '9'){opacity9X = '1.0'; activeTab9X = true}
    if (this.props.activeTab === '10'){
       opacity1X = '1.0'; opacity2X = '1.0'; opacity3X = '1.0'; opacity4X = '1.0'; opacity5X = '1.0'; opacity6X = '1.0'; opacity7X = '1.0'; opacity8X = '1.0'; opacity9X = '1.0';
       activeTab1X = true; activeTab2X = true; activeTab3X = true; activeTab4X = true; activeTab5X = true; activeTab6X = true; activeTab7X = true; activeTab8X = true; activeTab9X = true; activeTab10X = true;
       editstxnetwork = false; editsignature = false; editthemeprofile = false; editvcard = false
    }

    if (this.props.settingProfileMode === 'Simple'){opacity6X = '1.0'; activeTab6X = false}

    return (
      <>
        <ConfirmacionChangeThemeProfile
            handleDialog={this.handleDialog}
            dialogMessage={this.state.dialogMessage}
            dialogOpen={this.state.dialogOpen}
            dialogTitle={this.state.dialogTitle}
            mode={this.state.mode}
            checkedMode={this.state.checkedMode}
            background={this.state.background}
            handleChangeBackground={this.handleChangeBackground}
            handleChangeMode={this.handleChangeMode}
            backgroundTF={backgroundTF}
        />

        <ConfirmacionChangeVcardProfile
            handleDialogVcard={this.handleDialogVcard}
            dialogMessage={this.state.dialogMessage}
            dialogOpen={this.state.dialogOpenVcard}
            dialogTitle={this.state.dialogTitle}
            modeVcard={this.state.modeVcard}
            checkedModeVcard={this.state.checkedModeVcard}
            checkedModeVcardTelephone={this.state.checkedModeVcardTelephone}
            checkedModeVcardEmail={this.state.checkedModeVcardEmail}
            checkedModeVcardSocialNetwork={this.state.checkedModeVcardSocialNetwork}
            checkedModeVcardWeb={this.state.checkedModeVcardWeb}
            checkedModeVcardId={this.state.checkedModeVcardId}
            checkedModeVcardNotes={this.state.checkedModeVcardNotes}
            handleChangeModeVcard={this.handleChangeModeVcard}
            handleChangeModeVcardTelephone={this.handleChangeModeVcardTelephone}
            handleChangeModeVcardEmail={this.handleChangeModeVcardEmail}
            handleChangeModeVcardSocialNetwork={this.handleChangeModeVcardSocialNetwork}
            handleChangeModeVcardWeb={this.handleChangeModeVcardWeb}
            handleChangeModeVcardId={this.handleChangeModeVcardId}
            handleChangeModeVcardNotes={this.handleChangeModeVcardNotes}
        />
        {activeTab6X ?

          <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d', opacity: opacity6X } : {opacity: opacity6X}}>

            <ListGroup flush>
              <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        {/* Email Notifications */}
                        <Col md="12" className="form-group">
                          <label htmlFor="feServerSTX"><FormattedMessage id="useraccountdetail.serverStacks" /></label>
                          <InputGroup className="mb-2">
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                    <img src="images/red2.png" weight="30" height="30" alt="" title="STX" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              id="feServerSTX"
                              style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                              disabled={true}
                              placeholder={this.props.serverStacksX}
                            />
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row form>
                        {/* STX */}
                        <Col md="12" className="form-group">
                          <label htmlFor="feIdentityAddress"><FormattedMessage id="useraccountdetail.addressStacks" /></label>
                          <InputGroup className="mb-2">
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                <strong style={{cursor: 'pointer'}}><a href={`https://explorer.stacks.co/address/${STX}/?chain=${stacksNetX2}`} target="_blank" rel="noopener noreferrer">
                                    <img src="images/stacks-stx-logo.png" weight="30" height="30" alt="" title="STX" /></a>
                                </strong>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              id="feIdentityAddress"
                              style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                              innerRef={this.c03STX}
                              disabled={true}
                              onChange={() => {this.setState({value:'', copied: false})}}
                              placeholder={existSTX ? STX : "STX Address"}
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                <strong style={{cursor: 'pointer'}}  onClick={()=>this.copyToClipBoard()}>
                                    { this.props.colorDark ?
                                       <img src="images/copypasteGrey.png" weight="25" height="25" alt=""/>
                                    :
                                       <img src="images/copypaste.png" weight="25" height="25" alt=""/>
                                    }
                                </strong>
                              </InputGroupText>
                            </InputGroupAddon>
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                <FormSelect
                                    id="scope"
                                    style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                                    innerRef={this.c03STXScope}
                                    disabled={!editstxnetwork}
                                >
                                  <option style={this.props.colorDark ? {color:'white'} : {color:'darkblue'}} value="Private">Private</option>
                                  <option style={this.props.colorDark ? {color:'white'} : {color:'darkblue'}} value="Public">Public</option>
                                </FormSelect>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row form>
                        {/* STX */}
                        <Col md="12" className="form-group">
                          <label htmlFor="feIdentityAddress"><FormattedMessage id="useraccountdetail.addressBitcoin" /></label>
                          <InputGroup className="mb-2">
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                 <img src="images/bitcoin-btc-logo.png" weight="30" height="30" alt="" title="BTC" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              id="feIdentityAddress"
                              style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                              innerRef={this.c03BTC}
                              disabled={true}
                              onChange={() => {this.setState({value:'', copied: false})}}
                              placeholder={existBTC ? BTC : "BTC Address"}
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                <strong style={{cursor: 'pointer'}}  onClick={()=>this.copyToClipBoard2()}>
                                    { this.props.colorDark ?
                                       <img src="images/copypasteGrey.png" weight="25" height="25" alt=""/>
                                    :
                                       <img src="images/copypaste.png" weight="25" height="25" alt=""/>
                                    }
                                </strong>
                              </InputGroupText>
                            </InputGroupAddon>
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                <FormSelect
                                    id="scope"
                                    style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                                    disabled={!editstxnetwork}
                                    innerRef={this.c03BitcoinScope}
                                >
                                  <option style={this.props.colorDark ? {color:'white'} : {color:'darkblue'}} value="Private">Private</option>
                                  <option style={this.props.colorDark ? {color:'white'} : {color:'darkblue'}} value="Public">Public</option>
                                </FormSelect>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>

            <ListGroup flush>
              <ListGroupItem className="p-4 text-center" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d', opacity: opacity6X } : {opacity: opacity6X}}>
                  <Row>
                    <Col md="1"></Col>
                    <Col md="10">
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group text-center">
                          </Col>
                        </Row>
                        <Row form>
                          {/* Identity Address Stack*/}
                          <Col md="12" className="form-group text-center">
                            <label style={{ color: 'grey', fontSize:16 }} htmlFor="feIdentityAddress"><FormattedMessage id="useraccountdetail.identityaddress" /></label>
                            <FormInput
                              id="feIdentityAddressStack"
                              style={ this.props.colorDark ? { backgroundColor:'#111f2c', color:'white', textAlign: 'center'} : {textAlign: 'center'}}
                              value={identityAddressStack}
                              disabled={true}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          {/* Identity Address */}
                          <Col md="12" className="form-group text-center">
                            <label style={{ color: 'grey', fontSize:16 }} htmlFor="feIdentityAddress"><FormattedMessage id="useraccountdetail.identityaddress" /></label>
                            <FormInput
                              id="feIdentityAddress"
                              style={ this.props.colorDark ? { backgroundColor:'#111f2c', color:'white', textAlign: 'center'} : {textAlign: 'center'}}
                              value={identityAddress}
                              disabled={true}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          {/* Hash ZoneFile */}
                          <Col md="12" className="form-group text-center">
                            <label style={{ color: 'grey', fontSize:16 }} htmlFor="feHashZoneFile"><FormattedMessage id="useraccountdetail.hashzoneFile" /></label>
                            <FormInput
                              id="feHashZoneFile"
                              style={ this.props.colorDark ? { backgroundColor:'#111f2c', color:'white', textAlign: 'center'} : {textAlign: 'center'}}
                              disabled={true}
                              value={zonefile_hash}
                            />
                          </Col>
                        </Row>

                      </Form>
                    </Col>
                    <Col md="1"></Col>
                  </Row>
                </Card>
              </ListGroupItem>
            </ListGroup>

          </Card>
        :
          <>
          <input type="hidden" ref={this.c03STX} />
          <input type="hidden" ref={this.c03STXScope} />
          <input type="hidden" ref={this.c03BTC} />
          <input type="hidden" ref={this.c03BitcoinScope} />
          </>
        }
        {activeTab5X ?
          <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d', opacity: opacity5X } : {opacity: opacity5X}}>
            <ListGroup flush>
              <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        {/* Email Notifications */}
                        <Col md="12" className="form-group">
                          <label htmlFor="feEmail"><FormattedMessage id="useraccountdetail.emailnotifications" /></label>
                          <InputGroup className="mb-2">
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                    <img src="images/email.png" weight="30" height="30" alt="" title="eMail" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="email"
                              style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                              id="feEmailNotifications"
                              placeholder={existEmail ? EMAIL : "Email Notifications"}
                              disabled={activeTab10X}
                              innerRef={this.c01EmailNotifications}
                              onChange={e=>this.handleCaracteresEspeciales(e)}
                              autoComplete="email"
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                <FormSelect
                                    id="scope"
                                    style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                                    disabled={!editstxnetwork}
                                    innerRef={this.c01EmailScope}
                                >
                                  <option style={this.props.colorDark ? {color:'white'} : {color:'darkblue'}} value="Private">Private</option>
                                  <option style={this.props.colorDark ? {color:'white'} : {color:'darkblue'}} value="Public">Public</option>
                                </FormSelect>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row form>
                        {/* SMS Notifications */}
                        <Col md="12" className="form-group">
                          <label htmlFor="fePassword"><FormattedMessage id="useraccountdetail.smsnotifications" /></label>
                          <InputGroup className="mb-2">
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                    <img src="images/sms.png" weight="30" height="30" alt="" title="SMS" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                <FormSelect
                                    id="prefix"
                                    style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                                    disabled={activeTab10X}
                                    innerRef={this.c02SMSCountry}
                                    onChange={e=>this.handleChangePrefix(e)}
                                >
                                    {Prefix.map((todo, i) => {
                                        if (todo.name === this.state.smsCountry) {
                                          return (
                                            <option style={this.props.colorDark ? {color:'white'} : {color:'grey'}} key={i} value={todo.dialCode} selected style={{backgroundImage: `url("${todo.flag}")`}}>{`${todo.name}`}</option>
                                          )
                                        }else{
                                          return (
                                            <option style={this.props.colorDark ? {color:'white'} : {color:'grey'}} key={i} value={todo.dialCode} style={{backgroundImage: `url("${todo.flag}")`}}>{`${todo.name}`}</option>
                                          )
                                        }
                                    })}
                                </FormSelect>
                              </InputGroupText>
                            </InputGroupAddon>
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                {this.state.smsPrefix}
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              id="feSMS Notifications"
                              style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                              disabled={activeTab10X}
                              placeholder={existSMS ? SMS : "SMS Notifications"}
                              innerRef={this.c02SMSNotifications}
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                <FormSelect
                                    id="scope"
                                    style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                                    disabled={!editstxnetwork}
                                    innerRef={this.c02SMSScope}
                                >
                                  <option style={this.props.colorDark ? {color:'white'} : {color:'darkblue'}} value="Private">Private</option>
                                  <option style={this.props.colorDark ? {color:'white'} : {color:'darkblue'}} value="Public">Public</option>
                                </FormSelect>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row form>
                        {/* Applications */}
                        <Col md="12" className="form-group">
                          <label htmlFor="fePassword"><FormattedMessage id="useraccountdetail.applications" /></label>
                          <InputGroup className="mb-2">
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                    <img src="images/applications.png" weight="30" height="30" alt="" title="Applications" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                              id="feApplications"
                              placeholder={"Applications"}
                              innerRef={this.c04Applications}
                              disabled={true}
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                <FormSelect
                                    id="scope"
                                    style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                                    disabled={!editstxnetwork}
                                    innerRef={this.c04ApplicationsScope}
                                >
                                  <option style={this.props.colorDark ? {color:'white'} : {color:'darkblue'}} value="Private">Private</option>
                                  <option style={this.props.colorDark ? {color:'white'} : {color:'darkblue'}} value="Public">Public</option>
                                </FormSelect>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                      </Row>
                      {editstxnetwork ?
                        <Button theme="success" onClick={e=>this.nuevoProfileUser(e)}><FormattedMessage id="alert.confirm" /></Button>
                      : null }
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        :
          <>
          <input type="hidden" ref={this.c01EmailNotifications} />
          <input type="hidden" ref={this.c02SMSNotifications} />
          <input type="hidden" ref={this.c04Applications} />
          <input type="hidden" ref={this.c02SMSCountry} />
          <input type="hidden" ref={this.c01EmailScope} />
          <input type="hidden" ref={this.c02SMSScope} />
          <input type="hidden" ref={this.c04ApplicationsScope} />
          </>
        }
        {activeTab7X ?
          <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d', opacity: opacity7X } : {opacity: opacity7X}}>
            <ListGroup flush>
              <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        {/* SignPad */}
                        <Col md="12" className="form-group">
                          <label htmlFor="feEmail"><FormattedMessage id="contract.listcol11" /></label>
                          <Card small>
                             <SignPad colorDark={ this.props.colorDark} editsignature={editsignature}/>
                          </Card>
                        </Col>
                      </Row>
                      <Row form>
                          &nbsp;
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        : null}
        {activeTab8X ?
          <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d', opacity: opacity8X } : {opacity: opacity8X}}>
            <ListGroup flush>
              <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        {/* SignPad */}
                        <Col md="12" className="form-group">
                          <Table size="sm" className="text-center" responsive borderless>
                              <tbody>
                                <tr>
                                  <td style={{ width: "420px", textAlign:"left", fontSize:14 }}>
                                    <label style={{ color: 'grey', fontSize:16 }} htmlFor="feAboutMe">Background Image</label>
                                    {editthemeprofile ?
                                      <ImageUploader
                                          withIcon={true}
                                          withPreview={true}
                                          buttonText='Choose image'
                                          onChange={this.handleChangeBackground}
                                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                                          maxFileSize={10485760}
                                          fileSizeError=" file size is too big"
                                      />
                                    : null }
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{width: "420px", textAlign:"center", fontSize:14 }}>
                                    {backgroundTF ?
                                      <img src={this.state.background} height="100" alt=""/>
                                    :
                                      null
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{width: "420px", textAlign:"left", fontSize:14 }}>
                                    <FormCheckbox
                                      toggle
                                      checked={this.state.checkedMode}
                                      disabled={activeTab10X}
                                      onChange={e=>this.handleChangeMode(e,this.state.checkedMode)}>
                                    </FormCheckbox>
                                    Theme {this.state.checkedMode ? 'Dark' : 'Light'}
                                  </td>
                                </tr>
                             </tbody>
                          </Table>
                          {editthemeprofile ?
                            <Button onClick={() => this.handleDialog(this.state.dialogTitle, 'confirm', this.state.mode, this.state.background)} theme="success">
                                <FormattedMessage id="alert.confirm" />
                            </Button>
                          : null }
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        : null}
        {activeTab9X ?
          <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d', opacity: opacity9X } : {opacity: opacity9X}}>
            <ListGroup flush>
              <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                <Row>
                  <Col>
                    <Row form>
                      <Form>
                        <Col md="12">
                          <Table size="sm" className="text-center" responsive borderless>
                              <tbody>
                                <tr>
                                  <td style={{textAlign:"left", fontSize:12 }}>
                                    <FormCheckbox
                                         toggle
                                         checked={this.state.checkedModeVcard}
                                         disabled={activeTab10X}
                                         onChange={e=>this.handleChangeModeVcard(e,this.state.checkedModeVcard)}
                                     >
                                         VCARD {this.state.checkedModeVcard ? 'Active' : 'Inactive'}
                                     </FormCheckbox>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{textAlign:"left", fontSize:10 }}>
                                    <FormCheckbox
                                         inline
                                         checked={this.state.checkedModeVcardTelephone}
                                         disabled={activeTab10X}
                                         onChange={e=>this.handleChangeModeVcardTelephone(e,this.state.checkedModeVcardTelephone)}
                                     >
                                         Include Telephone
                                     </FormCheckbox>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{textAlign:"left", fontSize:10 }}>
                                    <FormCheckbox
                                         inline
                                         checked={this.state.checkedModeVcardEmail}
                                         disabled={activeTab10X}
                                         onChange={e=>this.handleChangeModeVcardEmail(e,this.state.checkedModeVcardEmail)}
                                     >
                                         Include Email
                                     </FormCheckbox>
                                   </td>
                                </tr>
                                <tr>
                                  <td style={{textAlign:"left", fontSize:10 }}>
                                    <FormCheckbox
                                         inline
                                         checked={this.state.checkedModeVcardSocialNetwork}
                                         disabled={activeTab10X}
                                         onChange={e=>this.handleChangeModeVcardSocialNetwork(e,this.state.checkedModeVcardSocialNetwork)}
                                     >
                                         Include Social Network
                                     </FormCheckbox>
                                   </td>
                                </tr>
                                <tr>
                                  <td style={{textAlign:"left", fontSize:10 }}>
                                    <FormCheckbox
                                         inline
                                         checked={this.state.checkedModeVcardWeb}
                                         disabled={activeTab10X}
                                         onChange={e=>this.handleChangeModeVcardWeb(e,this.state.checkedModeVcardWeb)}
                                     >
                                         Include Web
                                     </FormCheckbox>
                                   </td>
                                </tr>
                                <tr>
                                  <td style={{textAlign:"left", fontSize:10 }}>
                                    <FormCheckbox
                                         inline
                                         checked={this.state.checkedModeVcardId}
                                         disabled={activeTab10X}
                                         onChange={e=>this.handleChangeModeVcardId(e,this.state.checkedModeVcardId)}
                                     >
                                         Include ID
                                     </FormCheckbox>
                                   </td>
                                </tr>
                                <tr>
                                  <td style={{textAlign:"left", fontSize:10 }}>
                                    <FormCheckbox
                                         inline
                                         checked={this.state.checkedModeVcardNotes}
                                         disabled={activeTab10X}
                                         onChange={e=>this.handleChangeModeVcardNotes(e,this.state.checkedModeVcardNotes)}
                                     >
                                         Include Description
                                     </FormCheckbox>
                                   </td>
                                </tr>
                                <tr>
                                  <td style={{textAlign:"left", fontSize:10 }}>
                                  </td>
                                </tr>
                             </tbody>
                          </Table>
                          {editvcard ?
                            <Button onClick={() => this.handleDialogVcard(this.state.dialogTitle, 'confirm', this.state.modeVcard, this.state.checkedModeVcardTelephone, this.state.checkedModeVcardEmail, this.state.checkedModeVcardSocialNetwork, this.state.checkedModeVcardWeb, this.state.checkedModeVcardId, this.state.checkedModeVcardNotes)} theme="success">
                                <FormattedMessage id="alert.confirm" />
                            </Button>
                          : null }
                        </Col>
                      </Form>
                    </Row>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        : null}
      </>
    )
  }
};

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Account Details"
};

export default withRouter(UserAccountDetails);
