import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux';
import { tokenDatosSensores, mostrarDatosSensores } from '../../actions/sensoresActions';

class DatosSensores extends Component {

     componentDidMount() {
       this.props.tokenDatosSensores();
     }

     render() {
          const {sensores} = this.props;
          console.log(sensores);
          this.props.mostrarDatosSensores(this.props.sensores);
          const sensores1 = this.props.sensores;
          console.log(sensores1);
          return (
               <React.Fragment>
                    <h2 className="text-center my-5">API Data Sensor</h2>
                    <div className="row justify-content-center">
                         <div className="col-md-8">
                              <ul>
                                   {}
                              </ul>
                         </div>
                    </div>
               </React.Fragment>
           );
     }
}
// state
const mapStateToProps = state => ({
     sensores: state.sensores.sensores
})

export default connect(mapStateToProps, { tokenDatosSensores, mostrarDatosSensores })(DatosSensores);
