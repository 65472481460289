//React
import React, { Component } from 'react';

//UI
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormTextarea,
  FormSelect,
  Container
} from "shards-react";
import { Table } from 'reactstrap';
import { Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';

import TituloPagina2 from "../common/TituloPagina2";

//UUID
import uuid from 'uuid';

//base64
import { Base64 } from 'js-base64';

//components
import ContractAttachDeliverable from '../contractattachfile/ContractAttachDeliverable';
import ContractDeliverableMail from './ContractDeliverableMail';
import SignatureDeliverable from './SignatureDeliverable';
import CloseDeliverable from './CloseDeliverable';

// blockstack
//import { UserSession } from 'blockstack';
import { writeFile, readFile } from "blockstack-large-storage";
import { UserSession } from '@stacks/auth';
import { makeRandomPrivKey } from '@stacks/transactions';
import { Storage } from '@stacks/storage'

//translate
import { FormattedMessage } from 'react-intl';

//Axios
import axios from 'axios';

//Zonefile (Gaia)
import { parseZoneFile } from 'zone-file'

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB, deleteFileDB} from '../../actions/mongoDBActions'

import ContractLoader from "../contracts/ContractLoader";

//Configuración
import { MAINNET_STACKS_API_URL,
         TESTNET_STACKS_API_URL } from '../../config.js'

function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionWarning(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionAccept(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('accept')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'accept')} theme="warning" autoFocus>
                   <FormattedMessage id="alert.accept" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class ContractDeliverable extends Component {

    constructor(props) {
        super(props);

        this.displayConfigDeliverable = this.displayConfigDeliverable.bind(this);
        this.displayListDeliverable = this.displayListDeliverable.bind(this);
        this.displayPaginacion = this.displayPaginacion.bind(this);
        this.listDeliverable = this.listDeliverable.bind(this);
        this.listDeliverable2 = this.listDeliverable2.bind(this);
        this.newDeliverable = this.newDeliverable.bind(this);
        this.deleteDeliverableConfirm = this.deleteDeliverableConfirm.bind(this);
        this.sendMail = this.sendMail.bind(this);
        this.sendMail2 = this.sendMail2.bind(this);
        this.clickRemoveDeliverable = this.clickRemoveDeliverable.bind(this);
        this.clickRemoveDeliverable1 = this.clickRemoveDeliverable1.bind(this);
        this.clickRemoveDeliverable2 = this.clickRemoveDeliverable2.bind(this);
        this.clickRemoveDeliverable3 = this.clickRemoveDeliverable3.bind(this);
        this.clickRemoveDeliverable4 = this.clickRemoveDeliverable4.bind(this);
        this.viewDeliverable = this.viewDeliverable.bind(this);
        this.signatureDeliverable = this.signatureDeliverable.bind(this);
        this.returnMail = this.returnMail.bind(this);
        this.pageContracts = this.pageContracts.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          scrollInitial: 1,
          scrollPrevious: 0,
          scrollNext: 0,
          scrollEnd: 5,
          scrollLength: 0,
          pageContracts: 5,
          language: 'English',
          jsonDeliverable: [],
          jsonBlockstackZ40: [],
          jsonBlockstackZ50: [],
          jsonBlockstackZ60c: [],
          existListDeliverableX: false,
          displayLoader: false,
          displayEmailX: false,
          dialogMessage: '',
          dialogOpen: false,
          dialogAccept: false,
          dialogWarning: false,
          dialogOpenWarning: false,
          dialogTitle: '',
          jsonAttachFile:[],
          jsonAttachFile1:[],
          jsonAttachFile2:[],
          uuidX: '',
          disabledX: false,
          disabledNew: false,
          id: '',
          view: false,
          networkUrlX: '',
          messageDeliverableX: '',
          messageRemoveDeliverableX: '',
          jsonUserGroup: [],
          idCreator: '',
          existCreator: '',
          idPayer: '',
          existPayer: '',
          paidDeliverableX: false,
          existDeliverableX: false,
          deliverableUuid: '',
          selectedDeliverableCostX: 'sinCosto',
          deliverableUuidX: '',
          viewDeliverableX: true,
          displaySignatureDeliverableX: false,
          displayCloseX: false,
          jsonPaymentForm: [],
          displayEmail2X: false,
          form_deliverableX: '',
          toX: '',
          ccX: '',
          roleDeliverableX: '',
          signatureFormX: '',
          signatureSubjectX: '',
          registerNumber: '',
          authorizersX: [],
          roleX: [],
          bSignatureNextLevelX: false,
          displayClosedDeliveryX: false,
          bCloseX: false,
          displayNow: false,
        }
    }

    cContractByPage = React.createRef();

    handleDialog = (type, action) => {
      if (type === 'Confirm: 3001') {
         if (action === 'confirm') {
              this.setState({ dialogOpen: false });
              let e = ''
              if (localStorage["correlativoDeliverable"]) {
                const i = localStorage.getItem('correlativoDeliverable')
                const id = localStorage.getItem('idDeliverable')
                const deliverableUuid = localStorage.getItem('deliverableUuid')
                this.clickRemoveDeliverable(e, i, id, deliverableUuid)
              }
         }else{
           if (action === 'cancel') {
            this.setState({ dialogOpen: false });
           }
         }
      }
      if (type === 'Warning: 2001') {
         if (action === 'accept') {
              this.setState({ dialogOpenAccept: false });
         }
      }
      if (type === 'Error: 1001') {
         if (action === 'cancel') {
              this.setState({ dialogOpenWarning: false });
         }
      }
    }

    pageContracts(){
      this.setState({pageContracts:parseInt(this.cContractByPage.current.value)})
      this.setState({scrollEnd:parseInt(this.cContractByPage.current.value)})
      this.setState({scrollInitial: 1})
      this.listDeliverable(this.state.authorizersX,this.state.roleX)
    }

    componentDidUpdate = () => {
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
          }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }

      this.setState({ networkUrlX })

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      let {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      if (languageX === 'English'){this.setState({ messageDeliverableX:`${'A deliverable has been shipped'}`,messageRemoveDeliverableX:`${'A deliverable has been deleted'}`})}
      if (languageX === 'French'){this.setState({ messageDeliverableX:`${'Un livrable a été expédié'}`,messageRemoveDeliverableX:`${'Un livrable a été supprimé'}`})}
      if (languageX === 'Spanish'){this.setState({ messageDeliverableX:`${'Un entregable ha sido enviado'}`,messageRemoveDeliverableX:`${'Un entregable ha sido eliminado'}`})}
      if (languageX === 'Portuguese'){this.setState({ messageDeliverableX:`${'Uma entrega foi enviada'}`,messageRemoveDeliverableX:`${'Uma entrega foi excluída'}`})}
      if (languageX === 'Swedish'){this.setState({ messageDeliverableX:`${'En leverans har skickats'}`,messageRemoveDeliverableX:`${'En leverans har tagits bort'}`})}
      if (languageX === 'Netherlands'){this.setState({ messageDeliverableX:`${'En leverans har skickats'}`,messageRemoveDeliverableX:`${'Een levering is verwijderd'}`})}
      if (languageX === 'Russian'){this.setState({ messageDeliverableX:`${'Товар отправлен'}`,messageRemoveDeliverableX:`${'Результат был удален'}`})}
      if (languageX === 'Japanese'){this.setState({ messageDeliverableX:`${'成果物が発送されました'}`,messageRemoveDeliverableX:`${'成果物が削除されました'}`})}
      if (languageX === 'Chinese'){this.setState({ messageDeliverableX:`${'已交付可交付成果'}`,messageRemoveDeliverableX:`${'可交付成果已被刪除'}`})}
      if (languageX === 'German'){this.setState({ messageDeliverableX:`${'Eine Lieferung wurde versendet'}`,messageRemoveDeliverableX:`${'Eine Lieferung wurde gelöscht'}`})}
      if (languageX === 'Italian'){this.setState({ messageDeliverableX:`${'Un deliverable è stato spedito'}`,messageRemoveDeliverableX:`${'Un deliverable è stato eliminato'}`})}
    }

    componentDidMount() {
        Promise.all([this.goAuthorizersNames() ])
            .then((resolve) =>{},(reject) =>{})
    }

    listDeliverable(authorizersX,roleX){
        this.setState({jsonBlockstackZ50: [],existListDeliverableX: false,displayLoader: false, displayNow: false})
        Promise.all([this.listChat2(),
                     this.listDeliverable1()])
                 .then((resolve1) =>{},(reject1)  =>{})
        authorizersX.map((todo, i) => {
            roleX.map((todoRoleX, y) => {
              if (todoRoleX.code === todo.role){
                if (todoRoleX.generate) {
                    Promise.all([this.listDeliverable2(todo.id,authorizersX,roleX)])
                             .then((resolve1) =>{},(reject1)  =>{})
                }
              }
            })
        })
    }

    listDeliverable1 = () => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({displayUserGroup: true})
      return new Promise((resolve1, reject1) => {
          getFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_sign_send_deliverablelist6.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.setState({jsonBlockstackZ40:jsonBlockstack6 })
                 resolve1()
               } else {
                 resolve1()
               }
             })
              .catch(error => {
                console.log(error)
                resolve1()
             });
      });
    }

    listDeliverable2(userX,authorizersX,roleX){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({displayUserGroup: true})
      return new Promise((resolve1, reject1) => {
          getFileDB(userX, `${this.props.typeContract}_${this.props.numberContract}_deliverablelist6.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 let jsonBlockstack4 = []
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 jsonBlockstack4 = JSON.parse(jsonBlockstack5)

                 let level2X = ''
                 let generate2X = false
                 let user2X = ''
                 let user3X = ''
                 let close2X = ''
                 let largoX = jsonBlockstack4.length
                 jsonBlockstack4.map((todo, i) => {
                     authorizersX.map((todoAuthorizer, y) => {
                       if (todoAuthorizer.codeFormsDeliverable === todo.form){
                            roleX.map((todoRoleX, z) => {
                              if (todoRoleX.code === todoAuthorizer.role){
                                if (todoRoleX.close){
                                  close2X = todoAuthorizer.id
                                }
                                if (todoAuthorizer.id !== this.state.username){
                                  todoAuthorizer.firmasgte = true
                                }else{
                                  todoAuthorizer.firmasgte = false
                                  level2X = todoRoleX.level
                                  todo.authorizeGenerate = todoRoleX.generate
                                  todo.authorizeModify = todoRoleX.modify
                                  todo.authorizeSignature = todoRoleX.signature
                                  todo.authorizeClose = todoRoleX.close
                                  todo.authorizeVisualize = todoRoleX.visualize
                                  todo.authorizeLevel = todoRoleX.level
                                }
                                todoAuthorizer.level = todoRoleX.level
                              }
                            })
                       }
                     })
                     authorizersX.map((todoAuthorizer, y) => {
                      if (todoAuthorizer.firmasgte === true){
                          if (level2X + 1 === 2){
                              user2X = todo.to
                          }
                          if (level2X + 1 > 2){
                            if (todoAuthorizer.level === level2X + 1){
                              user2X = todoAuthorizer.id
                            }
                          }

                          if (level2X - 1 === 1){
                              user3X = todo.origin
                          }
                          if (level2X - 1 === 2){
                              user3X = todo.to
                          }
                          if (level2X - 1 > 2){
                            if (todoAuthorizer.level === level2X - 1){
                              user3X = todoAuthorizer.id
                            }
                          }
                       }
                     })
                     todo.bSignatureNextLevel = false
                     Promise.all([this.viewSignRemote(user2X,todo.deliverableUuid)])
                            .then((resolve2) =>{
                              todo.bSignatureNextLevel = resolve2[0]
                              this.setState({jsonBlockstackZ50: jsonBlockstack4},()=>{})
                            },(reject2)  =>{})
                     if (level2X - 1 > 0){
                        todo.bSignaturePreviousLevel = false
                        Promise.all([this.viewSignRemote(user3X,todo.deliverableUuid)])
                               .then((resolve2) =>{
                                 todo.bSignaturePreviousLevel = resolve2[0]
                                 this.setState({jsonBlockstackZ50: jsonBlockstack4},()=>{})
                               },(reject2)  =>{})
                     }else{
                        todo.bSignaturePreviousLevel = true
                     }
                     Promise.all([this.viewCloseDeliverable(close2X,todo.deliverableUuid)])
                            .then((resolve2) =>{
                              todo.close = true
                              this.setState({jsonBlockstackZ50: jsonBlockstack4},()=>{})
                            },(reject2)  =>{
                              todo.close = false
                              this.setState({jsonBlockstackZ50: jsonBlockstack4},()=>{})
                            })

                     if (i === (largoX - 1)){
                       this.setState({jsonBlockstackZ50: jsonBlockstack4,existListDeliverableX: true,displayLoader: true},()=>{})
                       resolve1()
                     }
                 })
               } else {
                 this.setState({displayLoader: true})
                 reject1()
               }
             })
              .catch(error => {
                console.log(error)
                this.setState({displayLoader: false})
                reject1()
             });
      });
    }

    viewSignRemote = (toX,deliverableUuidX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({displayUserGroup: true})
      return new Promise((resolve2, reject2) => {
          getFileDB(toX, `${this.props.typeContract}_${this.props.numberContract}_${deliverableUuidX}_deliverable_form.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 resolve2(jsonBlockstack6[0].bsignature)
               } else {
                 resolve2(false)
               }
             })
              .catch(error => {
                console.log(error)
                reject2()
             });
      });
    }

    viewCloseDeliverable = (userX,deliverableUuidX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({displayUserGroup: true})
      return new Promise((resolve2, reject2) => {
          getFileDB(userX, `${this.props.typeContract}_${this.props.numberContract}_${deliverableUuidX}_close_deliverable.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 resolve2(jsonBlockstack6.close)
               } else {
                 reject2()
               }
             })
              .catch(error => {
                console.log(error)
                reject2()
             });
      });
    }

    listChat2 = () => {
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        let options = { decrypt: false, verify: false }
        let jsonBlockstack4 = []
        return new Promise ((resolve1, reject1) =>{
            getFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_chat3.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.setState({jsonBlockstackZ60c: jsonBlockstack6})
                 resolve1()
               } else {
                 resolve1()
               }
             })
              .catch(error => {
                console.log(error)
                resolve1()
             })
        })
    }

    //Salvar Registro chat
    sendChat = (message,jsonBlockstackZ60c) =>{
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      const dateNow = new Date()
      const id = uuid()
      const message64 = Base64.encode(message);
      let jsonAttachFile = jsonBlockstackZ60c
      if (jsonAttachFile.length > 0){
          let jsonBlockstack3 = JSON.stringify(jsonAttachFile)
          if (jsonBlockstack3.substring(0,1)==='"') {
             jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
          }
          if (jsonBlockstack3.substring(0,1)==='[') {
             jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
          }
          jsonAttachFile =`${jsonBlockstack3},{"id":"${id}","origin":"${this.state.username}","date":"${dateNow}","message":"${message64}"}`
      }else{
          jsonAttachFile =`{"id":"${id}","origin":"${this.state.username}","date":"${dateNow}","message":"${message64}"}`
      }
      jsonAttachFile =`[${jsonAttachFile}]`
      jsonAttachFile = JSON.parse(jsonAttachFile)
      this.setState({jsonBlockstackZ60c: jsonAttachFile})
      putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_chat3.json`, JSON.stringify(jsonAttachFile), JSON.stringify(options))
        .then(keyUrl => {
        })
    }

    displayConfigDeliverable() {
        const {userlistRemote} = this.state
        const myContactX = this.state.mycontact
        return (
          <div>
            <Table borderless size="sm">
              <tbody>
                <tr>
                  <td>
                    <FormSelect
                        id="ContractByPage"
                        style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {backgroundColor:"#F0EFEC"}}
                        innerRef={this.cContractByPage}
                        placeholder=""
                        onChange={this.pageContracts}
                      >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </FormSelect>
                  </td>
                  <td>
                     <Button outline pill theme="warning" size="sm" className="mb-2" onClick={this.returnMail}>
                         <i className="material-icons mr-1">replay</i> <FormattedMessage id="contract.refresh" />
                     </Button>
                  </td>
                  <td>
                     {this.props.confirmSignX ?
                       <Button outline pill theme="success" disabled={this.state.disabledNew} size="sm" className="mb-2" onClick={this.newDeliverable}>
                           <i className="material-icons mr-1">replay</i> <FormattedMessage id="contract.newmail" />
                       </Button>
                     :
                       <Button outline pill theme="success" disabled={this.state.disabledNew} size="sm" className="mb-2" onClick={this.newDeliverableMessage}>
                           <i className="material-icons mr-1">replay</i> <FormattedMessage id="contract.newmail" />
                       </Button>
                     }
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        )
    }

    displayListDeliverable(){
          let displaySendReceiveX = "images/send.svg"
          const jsondisplayListDeliverable = this.state.jsonBlockstackZ50
          let displayEditX2 = "images/eyeGreen.png"
          let displayDeleteX2 = "images/deleteRed.png"

          let viewDeliverableX = true
          let deleteDeliverableX = true

          let deleteArray = []
          let viewArray = []
          if (this.props.userOrigin !== this.state.username) {
            deleteDeliverableX = false
            if (this.props.colorDark) {displayDeleteX2 = "images/delete.png"}else{displayDeleteX2 = "images/delete25p.png"}
              if (this.props.selectedSignaturePayment === 'SignatureAndPayment') {
                 if (this.state.existDeliverableX) {
                    if (!this.state.paidDeliverableX) {
                       viewDeliverableX = false
                    }
                 }
              }
          }

          return (
            <div>
              <Table size="sm" className="text-center" responsive hover striped>
                <thead>
                    <tr style={{fontSize:13}}>
                      {!this.state.displayLoader ?
                        <th><ContractLoader colorDark={this.props.colorDark}/></th>
                      :
                        <th></th>
                      }
                      <th ><FormattedMessage id="configuration.tag1" /></th>
                      <th ><FormattedMessage id="aboutcontact.from" /></th>
                      <th ><FormattedMessage id="aboutcontact.to" /></th>
                      <th ><FormattedMessage id="aboutcontact.cc" /></th>
                      <th ><FormattedMessage id="contract.listcol5" /></th>
                      <th ><FormattedMessage id="contract.listcol6" /></th>
                      <th ><FormattedMessage id="deliverable.form" /></th>
                      <th ><FormattedMessage id="contract.listcol32" /></th>
                      <th ><FormattedMessage id="contract.listcol33" /></th>
                      <th ><FormattedMessage id="contract.listcol11" /></th>
                      <th ><FormattedMessage id="contract.listcol27" /></th>
                      <th ><FormattedMessage id="sign.see" /></th>
                      <th ><FormattedMessage id="configuration.delete" /></th>
                    </tr>
                </thead>
                <tbody>
                   {jsondisplayListDeliverable.map((todo, i) => {
                     let authorizeGenerate = false
                     let authorizeModify = false
                     let authorizeSignature = false
                     let authorizeClose = false
                     let authorizeVisualize = false
                     let authorizeLevel = false
                     let displayListDeliverable3X = false
                     let role4X = ''
                     let viewDeliverableX = true
                     let displayTo = false
                     let permitAccess = false

                     this.state.authorizersX.map((todoAuthorizer, y) => {
                       if (todoAuthorizer.codeFormsDeliverable === todo.form){
                            if (todoAuthorizer.id === this.state.username){
                                 role4X = todoAuthorizer.role
                                 permitAccess = true
                                 this.state.roleX.map((todoRoleX, x) => {
                                 if (todoRoleX.code === todoAuthorizer.role){
                                   authorizeGenerate = todoRoleX.generate
                                   authorizeModify = todoRoleX.modify
                                   authorizeSignature = todoRoleX.signature
                                   authorizeClose = todoRoleX.close
                                   authorizeVisualize = todoRoleX.visualize
                                   if (todoRoleX.signature){
                                      displayListDeliverable3X = true
                                   }
                                 }
                               })
                            }
                       }
                     })
                     if (todo.origin === this.state.username || todo.to === this.state.username || todo.cc === this.state.username || permitAccess){
                         if (todo.origin === this.state.username){
                            displaySendReceiveX = "images/send.svg"
                            viewDeliverableX = true
                            displayTo = true
                         }else{
                            displaySendReceiveX = "images/receive.svg"
                         }

                         let signX = 'Waiting signature'
                         let sendX = 'Not sent'
                         this.state.jsonBlockstackZ40.map((todoSignSend, z) => {
                           if (todoSignSend.deliverableUuid === todo.deliverableUuid){
                             signX = todoSignSend.statussign
                             sendX = todoSignSend.statussend
                           }
                         })
                         deleteDeliverableX = false
                         if (this.props.colorDark) {displayDeleteX2 = "images/delete.png"}else{displayDeleteX2 = "images/delete25p.png"}
                         if (todo.origin === this.state.username) {
                           if (signX !== 'Signed'){
                              deleteDeliverableX = true
                              displayDeleteX2 = "images/deleteRed.png"
                           }
                         }

                         deleteArray[i]= displayDeleteX2
                         viewArray[i]= viewDeliverableX

                         return (
                            <tr key={i}>
                              {todo.bSignaturePreviousLevel ?
                               <>
                                 <td><strong style={{cursor: 'pointer'}}><img src={displaySendReceiveX} style={{maxWidth:20}} weight="20" height="20" alt=""/></strong></td>
                                 <td style={this.props.colorDark ? {color: 'black'} : {}}>
                                    <Card style={{ backgroundColor: todo.hexTag, color: todo.colorletterTag }}>{todo.tag}</Card>
                                 </td>
                                 <td>{todo.origin}</td>
                                 {displayTo ?
                                   <td>{todo.to}</td>
                                 :
                                   <td>{this.state.username}</td>
                                 }
                                 <td>{todo.cc}</td>
                                 <td>{todo.subject}</td>
                                 <td>{todo.date.substring(4,24)}</td>
                                 <td>{todo.form}</td>
                                 <td style={{ color: 'orange' }}>{signX}</td>
                                 <td style={{ color: 'orange' }}>{sendX}</td>
                                 {this.state.displayNow ?
                                   <>
                                     {authorizeSignature ?
                                       <td><strong style={{cursor: 'pointer'}}><img src="images/signGrey2.png" weight="20" height="20" alt=""/></strong></td>
                                     :
                                       <td></td>
                                     }
                                     {authorizeClose ?
                                       <>
                                         {todo.close ?
                                           <td style={{ color: 'orange' }}>Closed</td>
                                         :
                                           <td><strong style={{cursor: 'pointer'}}><img src="images/registerGrey.png" weight="20" height="20" alt=""/></strong></td>
                                         }
                                       </>
                                     :
                                      <>
                                        {todo.close ?
                                           <td style={{ color: 'orange' }}>Closed</td>
                                        :
                                           <td></td>
                                        }
                                      </>
                                     }
                                     {authorizeVisualize ?
                                        <td><strong style={{cursor: 'pointer'}}><img src="images/eyeGrey.png" weight="20" height="20" alt=""/></strong></td>
                                     :
                                        <td></td>
                                     }
                                     <td><strong style={{cursor: 'pointer'}}><img src={displayDeleteX2} weight="20" height="20" alt=""/></strong></td>
                                   </>
                                 :
                                   <>
                                     {todo.close ?
                                       <>
                                         {authorizeSignature ?
                                           <td><strong style={{cursor: 'pointer'}} onClick={e=>this.signatureDeliverable(e,i,todo.id,todo.origin,todo.deliverableUuid,viewArray[i],role4X,todo.form,todo.subject,todo.bSignatureNextLevel,todo.close)}><img src="images/signRed.png" weight="20" height="20" alt=""/></strong></td>
                                         :
                                           <td></td>
                                         }
                                         {authorizeClose ?
                                           <td><strong style={{cursor: 'pointer'}} onClick={e=>this.closedDeliverable(e,i,todo.id,todo.origin,todo.deliverableUuid,viewArray[i],todo.form,todo.subject)}><img src="images/registerRed.png" weight="20" height="20" alt=""/></strong></td>
                                         :
                                           <td style={{ color: 'orange' }}>Closed</td>
                                         }
                                         {authorizeVisualize ?
                                            <td><strong style={{cursor: 'pointer'}} onClick={e=>this.viewDeliverable(e,i,todo.id,todo.origin,todo.deliverableUuid,viewArray[i],todo.to,todo.cc, todo.form)}><img src={displayEditX2} weight="20" height="20" alt=""/></strong></td>
                                         :
                                            <td></td>
                                         }
                                         <td></td>
                                       </>
                                     :
                                       <>
                                         {authorizeSignature ?
                                           <td><strong style={{cursor: 'pointer'}} onClick={e=>this.signatureDeliverable(e,i,todo.id,todo.origin,todo.deliverableUuid,viewArray[i],role4X,todo.form,todo.subject,todo.bSignatureNextLevel,todo.close)}><img src="images/signGreen.png" weight="20" height="20" alt=""/></strong></td>
                                         :
                                           <td></td>
                                         }
                                         {authorizeClose ?
                                           <td><strong style={{cursor: 'pointer'}} onClick={e=>this.registerDeliverable(e,i,todo.id,todo.origin,todo.deliverableUuid,viewArray[i],todo.form,todo.subject)}><img src="images/registerGreen.png" weight="20" height="20" alt=""/></strong></td>
                                         :
                                           <td></td>
                                         }
                                         {authorizeVisualize ?
                                            <td><strong style={{cursor: 'pointer'}} onClick={e=>this.viewDeliverable(e,i,todo.id,todo.origin,todo.deliverableUuid,viewArray[i],todo.to,todo.cc, todo.form)}><img src={displayEditX2} weight="20" height="20" alt=""/></strong></td>
                                         :
                                            <td></td>
                                         }
                                         {deleteDeliverableX ?
                                           <td><strong style={{cursor: 'pointer'}} onClick={e=>this.deleteDeliverable(e,i,todo.id,todo.origin,deleteArray[i],todo.deliverableUuid)}><img src={displayDeleteX2} weight="20" height="20" alt=""/></strong></td>
                                         :
                                           <td><strong style={{cursor: 'pointer'}}><img src={displayDeleteX2} weight="20" height="20" alt=""/></strong></td>
                                         }
                                      </>
                                     }
                                   </>
                                 }
                                </>
                              :
                                null
                              }
                             </tr>
                          )}}
                      )}
                  </tbody>
              </Table>
            </div>
          )
    }

    displayPaginacion() {
            let scrollAuxiliar = 0
            let scrollAuxiliar2 = this.state.scrollEnd
            if (this.state.scrollEnd > this.state.scrollLength) {
               scrollAuxiliar2 = this.state.scrollLength
            }

            let mailPageX = `Deliverable ${this.state.scrollInitial} to ${scrollAuxiliar2} of ${this.state.scrollLength}`
            if (this.state.language === 'English'){mailPageX = `Deliverable ${this.state.scrollInitial} to ${scrollAuxiliar2} of ${this.state.scrollLength}`}
            if (this.state.language === 'French'){mailPageX = `Livrable ${this.state.scrollInitial} une ${scrollAuxiliar2} de ${this.state.scrollLength}`}
            if (this.state.language === 'Spanish'){mailPageX = `Entregable ${this.state.scrollInitial} a ${scrollAuxiliar2} de ${this.state.scrollLength}`}
            if (this.state.language === 'Portuguese'){mailPageX = `Entrega ${this.state.scrollInitial} um ${scrollAuxiliar2} do ${this.state.scrollLength}`}
            if (this.state.language === 'Swedish'){mailPageX = `Leveranser ${this.state.scrollInitial} en ${scrollAuxiliar2} av ${this.state.scrollLength}`}
            if (this.state.language === 'Netherlands'){mailPageX = `Leverbaar ${this.state.scrollInitial} een ${scrollAuxiliar2} van ${this.state.scrollLength}`}
            if (this.state.language === 'Russian'){mailPageX = `подлежащий доставке ${this.state.scrollInitial} в ${scrollAuxiliar2} из ${this.state.scrollLength}`}
            if (this.state.language === 'Japanese'){mailPageX = `成果物 ${this.state.scrollInitial} 私は ${scrollAuxiliar2} の ${this.state.scrollLength}`}
            if (this.state.language === 'Chinese'){mailPageX = `可交付成果 ${this.state.scrollInitial} 至 ${scrollAuxiliar2} 的 ${this.state.scrollLength}`}
            if (this.state.language === 'German'){mailPageX = `Lieferbar ${this.state.scrollInitial} Ich bin ${scrollAuxiliar2} von ${this.state.scrollLength}`}
            if (this.state.language === 'Italian'){mailPageX = `Consegnabile ${this.state.scrollInitial} a ${scrollAuxiliar2} di ${this.state.scrollLength}`}

            return (
              <div>
                <Table size="sm" className="text-center">
                  <tbody>
                      <tr>
                        <td>{mailPageX}</td>
                        <td><strong style={{cursor: 'pointer'}} onClick={()=>{
                                if (this.state.scrollInitial > this.state.pageContracts){
                                   this.setState({scrollInitial:1})
                                   this.setState({scrollEnd:this.state.pageContracts})
                                   this.setState({ searchDeliverable: false })
                                   this.listDeliverable(this.state.authorizersX,this.state.roleX)
                                }
                            }}><img src="images/initial.png" weight="15" height="15" alt=""/></strong></td>
                        <td><strong style={{cursor: 'pointer'}} onClick={()=>{
                                if (this.state.scrollInitial > this.state.pageContracts){
                                  scrollAuxiliar = this.state.scrollInitial - this.state.pageContracts
                                  if (scrollAuxiliar<1){
                                  }else{
                                    this.setState({scrollInitial:scrollAuxiliar})
                                    this.setState({scrollEnd:scrollAuxiliar + (this.state.pageContracts - 1)})
                                  }
                                  this.setState({ searchDeliverable: false })
                                  this.listDeliverable(this.state.authorizersX,this.state.roleX)
                                }
                            }}><img src="images/previous.png" weight="20" height="20" alt=""/></strong></td>
                            <td><strong style={{cursor: 'pointer'}} onClick={()=>{
                                    if (this.state.scrollInitial + this.state.pageContracts <= this.state.scrollLength){
                                      scrollAuxiliar = this.state.scrollInitial + this.state.pageContracts
                                      this.setState({scrollEnd:scrollAuxiliar + (this.state.pageContracts - 1)})
                                      this.setState({scrollInitial:scrollAuxiliar})
                                      this.setState({ searchDeliverable: false })
                                      this.listContract()
                                    }
                            }}><img src="images/next.png" weight="20" height="20" alt=""/></strong></td>
                        <td><strong style={{cursor: 'pointer'}} onClick={()=>{
                                  if (this.state.scrollInitial + this.state.pageContracts <= this.state.scrollLength) {
                                    scrollAuxiliar = parseInt(this.state.scrollLength / this.state.pageContracts)
                                    scrollAuxiliar = (scrollAuxiliar * this.state.pageContracts) + 1
                                    this.setState({scrollInitial:scrollAuxiliar})
                                    this.setState({scrollEnd:this.state.scrollLength})
                                    this.setState({ searchDeliverable: false })
                                    this.listContract()
                                  }
                            }}><img src="images/end.png" weight="15" height="15" alt=""/></strong></td>
                      </tr>
                  </tbody>
                </Table>
              </div>
            )
    }

    viewDeliverable(e, optx, id, from, deliverableUuid,viewDeliverableX, toX, ccX, formX){
       this.setState({displayEmailX:true,displayEmail2X:true,displaySignatureDeliverableX:false,displayCloseX:false,id:id,from:from,view:true,uuidX:id,disabledNew:true,deliverableUuid,deliverableUuidX: deliverableUuid, viewDeliverableX, toX, ccX, form_deliverableX: formX},()=>{})
       this.setState({displayNow: true})
    }

    returnMail(){
      this.setState({displayEmailX:false,displayEmail2X:false,disabledNew:false,displaySignatureDeliverableX:false,displayCloseX:false})
      this.listDeliverable(this.state.authorizersX,this.state.roleX)
    }

    returnSign = (jsonListDeliverable1,jsonListDeliverable2) => {
      this.setState({jsonBlockstackZ40:jsonListDeliverable1})
      this.setState({jsonBlockstackZ50:jsonListDeliverable2})
    }

    newDeliverable(){
      this.setState({displayEmailX:true,displayEmail2X:false,displaySignatureDeliverableX:false,displayCloseX:false,uuidX:uuid(),disabled:false,disabledNew:true,view:false,from:this.state.username},()=>{})
      this.setState({displayNow: true})
    }

    signatureDeliverable = (e, optx, id, from, deliverableUuid,viewDeliverableX,roleDeliverableX,signatureFormX,signatureSubjectX,bSignatureNextLevelX,bCloseX) => {
       this.setState({displayEmailX:false,displayEmail2X:false,displaySignatureDeliverableX:true,displayCloseX:false,id:id,from:from,view:false,uuidX:id,deliverableUuid,deliverableUuidX: deliverableUuid, viewDeliverableX, roleDeliverableX, signatureFormX, signatureSubjectX, registerNumber: optx, bSignatureNextLevelX, bCloseX},()=>{})
       this.setState({displayNow: true})
    }

    registerDeliverable(e,iX,id,userOrigin,deliverableUuid,viewArray,signatureFormX,signatureSubjectX,){
      this.setState({displayEmailX:false,displayEmail2X:false,displaySignatureDeliverableX:false,displayCloseX:true,id:id,from:userOrigin,view:false,uuidX:id,deliverableUuid,deliverableUuidX:deliverableUuid,signatureFormX,signatureSubjectX,displayClosedDeliveryX:false },()=>{})
      this.setState({displayNow: true})
    }

    closedDeliverable(e,iX,id,userOrigin,deliverableUuid,viewArray,signatureFormX,signatureSubjectX,){
      this.setState({displayEmailX:false,displayEmail2X:false,displaySignatureDeliverableX:false,displayCloseX:true,id:id,from:userOrigin,view:false,uuidX:id,deliverableUuid,deliverableUuidX:deliverableUuid,signatureFormX,signatureSubjectX,displayClosedDeliveryX:true },()=>{})
      this.setState({displayNow: true})
    }

    deleteDeliverable = (e,i,id,userX,displayDeleteX2,deliverableUuid) => {
      if (userX === this.state.username){
        Promise.all([this.viewSignRemote(userX,deliverableUuid)])
               .then((resolve2) =>{
                 let bSignatureNextLevel = resolve2[0]
                 if (bSignatureNextLevel){
                  this.deleteDeliverableNotPosible()
                 }else{
                  this.deleteDeliverableConfirm(e,i,id,displayDeleteX2,deliverableUuid)
                 }
               })
      }
    }

    returnBack = () => {
      if (!localStorage["form_deliverable"]) {
        this.setState({displayEmail2X:false})
      }else{
        this.setState({displayEmail2X:true})
        const storage_deliverable = localStorage.getItem('form_deliverable')
        const storage_deliverableX = JSON.parse(storage_deliverable)
        this.setState({form_deliverableX:storage_deliverableX.form_deliverable})
      }
    }

    //Eliminar Registro Entregable
    clickRemoveDeliverable = (e,optx,id,deliverableUuid) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      this.setState({displayEmailX:false,displayEmail2X:false,displaySignatureDeliverableX:false,displayCloseX:false})
      let jsonAttachFileX = this.state.jsonBlockstackZ50
      let jsonAttachFileX2 = ''
      jsonAttachFileX.map((todo2,key2)=>{
          if (todo2.id !== id){
            if (jsonAttachFileX2 === ''){
              jsonAttachFileX2=`{"id":"${todo2.id}","origin":"${todo2.origin}","to":"${todo2.to}","cc":"${todo2.cc}","subject":"${todo2.subject}","date":"${todo2.date}","message":"${todo2.message}","deliverableCost":"${todo2.deliverableCost}","deliverableUuid":"${todo2.deliverableUuid}","form":"${todo2.form}","tag":"${todo2.tag}","descriptionTag":"${todo2.descriptionTag}","hexTag":"${todo2.hexTag}","colorletterTag":"${todo2.colorletterTag}","statussign":"${todo2.statussign}","statussend":"${todo2.statussend}","close":${todo2.close}}`

            }else{
              jsonAttachFileX2=`${jsonAttachFileX2},{"id":"${todo2.id}","origin":"${todo2.origin}","to":"${todo2.to}","cc":"${todo2.cc}","subject":"${todo2.subject}","date":"${todo2.date}","message":"${todo2.message}","deliverableCost":"${todo2.deliverableCost}","deliverableUuid":"${todo2.deliverableUuid}","form":"${todo2.form}","tag":"${todo2.tag}","descriptionTag":"${todo2.descriptionTag}","hexTag":"${todo2.hexTag}","colorletterTag":"${todo2.colorletterTag}","statussign":"${todo2.statussign}","statussend":"${todo2.statussend}","close":${todo2.close}}`
            }
          }
      })
      if (jsonAttachFileX2 === ''){
        jsonAttachFileX = `[]`
      }else{
        jsonAttachFileX=`[${jsonAttachFileX2}]`
      }
      jsonAttachFileX = JSON.parse(jsonAttachFileX)
      this.setState({jsonBlockstackZ50: jsonAttachFileX})
      putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_deliverablelist6.json`, JSON.stringify(jsonAttachFileX), JSON.stringify(options))
          .then(() => {
            this.clickRemoveDeliverable1(e,optx,id,deliverableUuid)
          })
    }

    //Eliminar Registro Entregable Sign - Send
    clickRemoveDeliverable1 = (e,optx,id,deliverableUuid) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      this.setState({displayEmailX:false,displayEmail2X:false,displaySignatureDeliverableX:false,displayCloseX:false})
      let jsonAttachFileX = this.state.jsonBlockstackZ40
      let jsonAttachFileX2 = ''
      jsonAttachFileX.map((todo2,key2)=>{
          if (todo2.id !== id){
            if (jsonAttachFileX2 === ''){
              jsonAttachFileX2=`{"id":"${todo2.id}","origin":"${todo2.origin}","to":"${todo2.to}","cc":"${todo2.cc}","subject":"${todo2.subject}","date":"${todo2.date}","message":"${todo2.message}","deliverableCost":"${todo2.deliverableCost}","deliverableUuid":"${todo2.deliverableUuid}","form":"${todo2.form}","tag":"${todo2.tag}","descriptionTag":"${todo2.descriptionTag}","hexTag":"${todo2.hexTag}","colorletterTag":"${todo2.colorletterTag}","statussign":"${todo2.statussign}","statussend":"${todo2.statussend}","close":${false}}`
            }else{
              jsonAttachFileX2=`${jsonAttachFileX2},{"id":"${todo2.id}","origin":"${todo2.origin}","to":"${todo2.to}","cc":"${todo2.cc}","subject":"${todo2.subject}","date":"${todo2.date}","message":"${todo2.message}","deliverableCost":"${todo2.deliverableCost}","deliverableUuid":"${todo2.deliverableUuid}","form":"${todo2.form}","tag":"${todo2.tag}","descriptionTag":"${todo2.descriptionTag}","hexTag":"${todo2.hexTag}","colorletterTag":"${todo2.colorletterTag}","statussign":"${todo2.statussign}","statussend":"${todo2.statussend}","close":${false}}`
            }
          }
      })
      if (jsonAttachFileX2 === ''){
        jsonAttachFileX = `[]`
      }else{
        jsonAttachFileX=`[${jsonAttachFileX2}]`
      }
      jsonAttachFileX = JSON.parse(jsonAttachFileX)
      this.setState({jsonBlockstackZ60: jsonAttachFileX})
      putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_sign_send_deliverablelist6.json`, JSON.stringify(jsonAttachFileX), JSON.stringify(options))
          .then(() => {
            this.clickRemoveDeliverable2(id)
            this.clickRemoveDeliverable4(deliverableUuid)
            this.sendChat(this.state.messageRemoveDeliverableX,this.state.jsonBlockstackZ60c)
            this.listDeliverable(this.state.authorizersX,this.state.roleX)
          })
    }

    // Borrar archivo asociado a entregable (attach)
    clickRemoveDeliverable2(id){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      deleteFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_${id}_attachdeliverable.json`)
        .then(() => {
           this.clickRemoveDeliverable3(id)
         })
    }

    // Borrar archivo asociado a entregable (attach)
    clickRemoveDeliverable3(id){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      deleteFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_${id}_attachdeliverable_files.json`)
         .then(() => {
         })
    }

    // Borrar archivo asociado a entregable (firma)
    clickRemoveDeliverable4 = (deliverableUuid) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      deleteFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_${deliverableUuid}_deliverable_form.json`)
         .then(() => {
         })
    }

    //Guardar Registro Entregable
    sendMail(to,subject,message,cc,jsonBlockstackZ50,deliverableCost,deliverableUuid,jsonPaymentForm){
      let form_deliverable = ''
      let storage_deliverable = ''
      let storage_deliverableX = {}
      let codeTag = ''
      let descriptionTag = ''
      let hexTag = ''
      let colorletterTag = ''
      let role_deliverable = ''

      if (localStorage["form_deliverable"]) {
         storage_deliverable = localStorage.getItem('form_deliverable')
         storage_deliverableX = JSON.parse(storage_deliverable)
         form_deliverable = storage_deliverableX.form_deliverable
         codeTag = storage_deliverableX.codeTag
         descriptionTag = storage_deliverableX.descriptionTag
         hexTag = storage_deliverableX.hexTag
         colorletterTag = storage_deliverableX.colorletterTag
         role_deliverable = storage_deliverableX.role
      }

      let generateX = false
      this.state.authorizersX.map((todoAuthorizer, y) => {
        if (todoAuthorizer.codeFormsDeliverable === form_deliverable){
             if (todoAuthorizer.id === this.state.username){
                this.state.roleX.map((todoRoleX, x) => {
                  if (todoRoleX.code === todoAuthorizer.role){
                    generateX = todoRoleX.generate
                  }
                })
             }
        }
      })

      if (generateX){
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
        const dateNow = new Date()
        const id = this.state.uuidX
        const message64 = Base64.encode(message);

        if (cc==='feCC'){cc = ''}
        let jsonAttachFile = jsonBlockstackZ50
        if (jsonAttachFile.length > 0){
            let jsonBlockstack3 = JSON.stringify(jsonAttachFile)
            if (jsonBlockstack3.substring(0,1)==='"') {
               jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
            }
            if (jsonBlockstack3.substring(0,1)==='[') {
               jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
            }
            jsonAttachFile =`${jsonBlockstack3},{"id":"${id}","origin":"${this.state.username}","to":"${to}","cc":"${cc}","subject":"${subject}","date":"${dateNow}","message":"${message64}","deliverableCost":"${deliverableCost}","deliverableUuid":"${deliverableUuid}","form":"${form_deliverable}","tag":"${codeTag}","descriptionTag":"${descriptionTag}","hexTag":"${hexTag}","colorletterTag":"${colorletterTag}","role":"${role_deliverable}","statussign":"Waiting signature","statussend":"Not sent","close":${false}}`
        }else{
            jsonAttachFile =`{"id":"${id}","origin":"${this.state.username}","to":"${to}","cc":"${cc}","subject":"${subject}","date":"${dateNow}","message":"${message64}","deliverableCost":"${deliverableCost}","deliverableUuid":"${deliverableUuid}","form":"${form_deliverable}","tag":"${codeTag}","descriptionTag":"${descriptionTag}","hexTag":"${hexTag}","colorletterTag":"${colorletterTag}","role":"${role_deliverable}","statussign":"Waiting signature","statussend":"Not sent","close":${false}}`
        }
        jsonAttachFile =`[${jsonAttachFile}]`
        jsonAttachFile = JSON.parse(jsonAttachFile)
        this.setState({jsonBlockstackZ50: jsonAttachFile})
        putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_deliverablelist6.json`, JSON.stringify(jsonAttachFile), JSON.stringify(options))
          .then(keyUrl => {
            this.putAuthorizers(this.state.username,to,deliverableUuid)
            this.sendMail2(to,subject,message,cc,this.state.jsonBlockstackZ40,deliverableCost,deliverableUuid,jsonPaymentForm)
          })
      }
    }

    //Guardar Registro Entregable
    sendMail2(to,subject,message,cc,jsonBlockstackZ40,deliverableCost,deliverableUuid,jsonPaymentForm){
      let form_deliverable = ''
      let storage_deliverable = ''
      let storage_deliverableX = {}
      let codeTag = ''
      let descriptionTag = ''
      let hexTag = ''
      let colorletterTag = ''
      let role_deliverable = ''

      if (localStorage["form_deliverable"]) {
         storage_deliverable = localStorage.getItem('form_deliverable')
         storage_deliverableX = JSON.parse(storage_deliverable)
         form_deliverable = storage_deliverableX.form_deliverable
         codeTag = storage_deliverableX.codeTag
         descriptionTag = storage_deliverableX.descriptionTag
         hexTag = storage_deliverableX.hexTag
         colorletterTag = storage_deliverableX.colorletterTag
         role_deliverable = storage_deliverableX.role
      }

      let generateX = false
      this.state.authorizersX.map((todoAuthorizer, y) => {
        if (todoAuthorizer.codeFormsDeliverable === form_deliverable){
             if (todoAuthorizer.id === this.state.username){
                this.state.roleX.map((todoRoleX, x) => {
                  if (todoRoleX.code === todoAuthorizer.role){
                    generateX = todoRoleX.generate
                  }
                })
             }
        }
      })

      if (generateX){
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
        const dateNow = new Date()
        const id = this.state.uuidX
        const message64 = Base64.encode(message);

        if (cc==='feCC'){cc = ''}
        let jsonAttachFile = jsonBlockstackZ40
        if (jsonAttachFile.length > 0){
            let jsonBlockstack3 = JSON.stringify(jsonAttachFile)
            if (jsonBlockstack3.substring(0,1)==='"') {
               jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
            }
            if (jsonBlockstack3.substring(0,1)==='[') {
               jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
            }
            jsonAttachFile =`${jsonBlockstack3},{"id":"${id}","origin":"${this.state.username}","to":"${to}","cc":"${cc}","subject":"${subject}","date":"${dateNow}","message":"${message64}","deliverableCost":"${deliverableCost}","deliverableUuid":"${deliverableUuid}","form":"${form_deliverable}","tag":"${codeTag}","descriptionTag":"${descriptionTag}","hexTag":"${hexTag}","colorletterTag":"${colorletterTag}","role":"${role_deliverable}","statussign":"Waiting signature","statussend":"Not sent","close":${false}}`
        }else{
            jsonAttachFile =`{"id":"${id}","origin":"${this.state.username}","to":"${to}","cc":"${cc}","subject":"${subject}","date":"${dateNow}","message":"${message64}","deliverableCost":"${deliverableCost}","deliverableUuid":"${deliverableUuid}","form":"${form_deliverable}","tag":"${codeTag}","descriptionTag":"${descriptionTag}","hexTag":"${hexTag}","colorletterTag":"${colorletterTag}","role":"${role_deliverable}","statussign":"Waiting signature","statussend":"Not sent","close":${false}}`
        }
        jsonAttachFile =`[${jsonAttachFile}]`
        jsonAttachFile = JSON.parse(jsonAttachFile)
        this.setState({jsonBlockstackZ50: jsonAttachFile})
        putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_sign_send_deliverablelist6.json`, JSON.stringify(jsonAttachFile), JSON.stringify(options))
          .then(keyUrl => {
            this.sendChat(this.state.messageDeliverableX,this.state.jsonBlockstackZ60c)
            this.listDeliverable(this.state.authorizersX,this.state.roleX)
            this.setState({displayEmailX:false,displayEmail2X:false,displaySignatureDeliverableX:false,displayCloseX:false,disabledNew:false})
            localStorage.removeItem('form_deliverable')
          })
      }
    }

    putAuthorizers = (from,to,deliverableUuid) => {
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
        let authorizers2X = []
        let bSi = false
        const authorizersX = this.state.authorizersX
        authorizersX.map((todo, i) => {

            //-------------------------------------------
            //TODO Debe ser por Form
            //-------------------------------------------

            bSi = false
            if (todo.role === 'Supervisor MD'){
               if (todo.id === from ){
                  bSi = true
               }
            }else{
              if (todo.role === 'Adm MD'){
                 if (todo.id === to ){
                    bSi = true
                 }
              }else{
                bSi = true
              }
            }
            if (bSi === true){
              authorizers2X.push({"codeFormsDeliverable":todo.codeFormsDeliverable,"id":todo.id,"name":todo.name,"role":todo.role,"active":todo.active,"signature":todo.signature,"identityAddress":todo.identityAddress,"statusInvite":todo.statusInvite,"email":todo.email, "publicKey":todo.publicKey, "conceptFormsDeliverable":todo.conceptFormsDeliverable})
            }
        })
        putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_${deliverableUuid}_authorizer_deliverable.json`, JSON.stringify(authorizers2X), JSON.stringify(options))
           .then(() => {
           })
    }

    goAuthorizersNames(){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin,`${this.props.typeContract}_${this.props.numberContract}_authorizer_deliverable_2.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.setState({authorizersX:jsonBlockstack6 })
                 this.goDetailRoleNames(jsonBlockstack6)
                 resolve1()
               } else {}
               resolve1()
             })
            .catch(error => {
              reject1()
            });
      })
    }

    goDetailRoleNames(authorizersX){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin,`role_deliverable_2.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.setState({roleX: jsonBlockstack6})
                 this.listDeliverable(authorizersX,jsonBlockstack6)
                 resolve1()
               } else {}
               resolve1()
             })
            .catch(error => {
              reject1()
            });
      })
    }

    newDeliverableMessage = () => {
        if (this.state.language === 'English'){this.setState({ dialogOpenAccept: true,  dialogMessage: 'Before sending a Deliverable the signatures must be registered (published the document in Blockchain)',dialogTitle: 'Warning: 2001'})}
        if (this.state.language === 'French'){this.setState({ dialogOpenAccept: true,  dialogMessage: "Avant d'envoyer un Livrable, les signatures doivent être enregistrées (publié le document dans Blockchain)",dialogTitle: 'Warning: 2001'})}
        if (this.state.language === 'Spanish'){this.setState({ dialogOpenAccept: true,  dialogMessage: 'Antes de enviar un Entregable deben estar registradas las firmas (publicado el documento en Blockchain)',dialogTitle: 'Warning: 2001'})}
        if (this.state.language === 'Portuguese'){this.setState({ dialogOpenAccept: true,  dialogMessage: 'Antes de enviar um Produto, as assinaturas devem ser registradas (publicado o documento no Blockchain)',dialogTitle: 'Warning: 2001'})}
        if (this.state.language === 'Swedish'){this.setState({ dialogOpenAccept: true,  dialogMessage: 'Innan du skickar en leverans måste underskrifterna registreras (publiceras dokumentet i Blockchain)',dialogTitle: 'Warning: 2001'})}
        if (this.state.language === 'Netherlands'){this.setState({ dialogOpenAccept: true,  dialogMessage: 'Voordat een Deliverable wordt verzonden, moeten de handtekeningen worden geregistreerd (het document in Blockchain gepubliceerd)',dialogTitle: 'Warning: 2001'})}
        if (this.state.language === 'Russian'){this.setState({ dialogOpenAccept: true,  dialogMessage: 'Перед отправкой Поставки подписи должны быть зарегистрированы (опубликован документ в блокчейне)',dialogTitle: 'Warning: 2001'})}
        if (this.state.language === 'Japanese'){this.setState({ dialogOpenAccept: true,  dialogMessage: '成果物を送信する前に、署名を登録する必要があります（ブロックチェーンでドキュメントを公開）',dialogTitle: 'Warning: 2001'})}
        if (this.state.language === 'Chinese'){this.setState({ dialogOpenAccept: true,  dialogMessage: '在發送可交付成果之前，必須註冊簽名（在區塊鏈中發布文檔）',dialogTitle: 'Warning: 2001'})}
        if (this.state.language === 'German'){this.setState({ dialogOpenAccept: true,  dialogMessage: 'Vor dem Senden eines Liefergegenstandes müssen die Signaturen registriert werden (das Dokument wurde in Blockchain veröffentlicht)',dialogTitle: 'Warning: 2001'})}
        if (this.state.language === 'Italian'){this.setState({ dialogOpenAccept: true,  dialogMessage: 'Prima di inviare un Deliverable, le firme devono essere registrate (pubblicato il documento in Blockchain)',dialogTitle: 'Warning: 2001'})}
    }

    deleteDeliverableNotPosible = () => {
        if (this.state.language === 'English'){this.setState({ dialogOpenWarning: true,  dialogMessage: 'It is not possible to delete the Deliverable, you must first unsign it!',dialogTitle: 'Error: 1001'})}
        if (this.state.language === 'French'){this.setState({ dialogOpenWarning: true,  dialogMessage: "Il n'est pas possible de supprimer le Livrable, vous devez d'abord le déssigner!",dialogTitle: 'Error: 1001'})}
        if (this.state.language === 'Spanish'){this.setState({ dialogOpenWarning: true,  dialogMessage: '¡No es posible eliminar el Entregable, debe antes anular la firma!',dialogTitle: 'Error: 1001'})}
        if (this.state.language === 'Portuguese'){this.setState({ dialogOpenWarning: true,  dialogMessage: 'Não é possível excluir o Entregável, você deve primeiro cancelar a assinatura!',dialogTitle: 'Error: 1001'})}
        if (this.state.language === 'Swedish'){this.setState({ dialogOpenWarning: true,  dialogMessage: 'Det är inte möjligt att ta bort Deliverablen, du måste först avsigna den!',dialogTitle: 'Error: 1001'})}
        if (this.state.language === 'Netherlands'){this.setState({ dialogOpenWarning: true,  dialogMessage: 'Het is niet mogelijk om de Deliverable te verwijderen, u moet deze eerst ongedaan maken!',dialogTitle: 'Error: 1001'})}
        if (this.state.language === 'Russian'){this.setState({ dialogOpenWarning: true,  dialogMessage: 'Удалить результат невозможно, его необходимо сначала расписать!',dialogTitle: 'Error: 1001'})}
        if (this.state.language === 'Japanese'){this.setState({ dialogOpenWarning: true,  dialogMessage: '成果物を削除することはできません。まず署名を解除する必要があります。',dialogTitle: 'Error: 1001'})}
        if (this.state.language === 'Chinese'){this.setState({ dialogOpenWarning: true,  dialogMessage: '無法刪除 Deliverable，您必須先取消簽名！',dialogTitle: 'Error: 1001'})}
        if (this.state.language === 'German'){this.setState({ dialogOpenWarning: true,  dialogMessage: 'Es ist nicht möglich, das Deliverable zu löschen, Sie müssen es zuerst abmelden!',dialogTitle: 'Error: 1001'})}
        if (this.state.language === 'Italian'){this.setState({ dialogOpenWarning: true,  dialogMessage: 'Non è possibile eliminare il Deliverable, devi prima annullare la firma!',dialogTitle: 'Error: 1001'})}
    }

    deleteDeliverableConfirm = (e,i,id,displayDeleteX2,deliverableUuid) => {
      if (displayDeleteX2 === "images/deleteRed.png"){
        if (deliverableUuid === undefined){deliverableUuid = ''}
        localStorage.setItem('correlativoDeliverable',i)
        localStorage.setItem('idDeliverable',id)
        localStorage.setItem('deliverableUuid',deliverableUuid)
        if (this.state.language === 'English'){this.setState({ dialogOpen: true,  dialogMessage: 'Are you sure to delete the deliverable?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
        if (this.state.language === 'French'){this.setState({ dialogOpen: true,  dialogMessage: 'Voulez-vous vraiment supprimer le livrable ?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
        if (this.state.language === 'Spanish'){this.setState({ dialogOpen: true,  dialogMessage: '¿Está seguro de eliminar el entregable?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
        if (this.state.language === 'Portuguese'){this.setState({ dialogOpen: true,  dialogMessage: 'Tem certeza de que deseja excluir a entrega??',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
        if (this.state.language === 'Swedish'){this.setState({ dialogOpen: true,  dialogMessage: 'Är du säker på att ta bort leveransen?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
        if (this.state.language === 'Netherlands'){this.setState({ dialogOpen: true,  dialogMessage: 'Weet u zeker dat u het product wilt verwijderen?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
        if (this.state.language === 'Russian'){this.setState({ dialogOpen: true,  dialogMessage: 'Вы уверены, что хотите удалить результат?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
        if (this.state.language === 'Japanese'){this.setState({ dialogOpen: true,  dialogMessage: '成果物を削除してもよろしいですか?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
        if (this.state.language === 'Chinese'){this.setState({ dialogOpen: true,  dialogMessage: '您確定要刪除可交付成果嗎？',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
        if (this.state.language === 'German'){this.setState({ dialogOpen: true,  dialogMessage: 'Möchten Sie die Lieferung wirklich löschen?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
        if (this.state.language === 'Italian'){this.setState({ dialogOpen: true,  dialogMessage: 'Sei sicuro di eliminare il deliverable?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
      }
    }



    render() {
          const messageSubtittle = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`
          const messageSubtittle2 = `${this.props.typeContract}/${this.props.numberContract}`
          const {displayEmailX,displayEmail2X,displaySignatureDeliverableX,displayCloseX} = this.state

          let titleX = "Deliverables"
          if (this.state.language === 'English'){titleX = "Deliverables"}
          if (this.state.language === 'French'){titleX = "Livrables"}
          if (this.state.language === 'Spanish'){titleX = "Entregables"}
          if (this.state.language === 'Portuguese'){titleX = "Entregas"}
          if (this.state.language === 'Swedish'){titleX = "Leveranser"}
          if (this.state.language === 'Netherlands'){titleX = "Deliverables"}
          if (this.state.language === 'Russian'){titleX = "Практические результаты"}
          if (this.state.language === 'Japanese'){titleX = "成果物"}
          if (this.state.language === 'Chinese'){titleX = "可交付成果"}
          if (this.state.language === 'German'){titleX = "Liefergegenstände"}
          if (this.state.language === 'Italian'){titleX = "Prodotti Finali"}

          return (
            <div>
              <Container fluid className="main-content-container px-2">
                  <Row noGutters className="page-header py-4">
                    <TituloPagina2
                      sm="8"
                      title={titleX}
                      subtitle={messageSubtittle}
                      className="text-sm-left"
                    />
                  </Row>

                  <Confirmacion
                      handleDialog={this.handleDialog}
                      dialogMessage={this.state.dialogMessage}
                      dialogOpen={this.state.dialogOpen}
                      dialogTitle={this.state.dialogTitle}
                  />
                  <ConfirmacionWarning
                      handleDialog={this.handleDialog}
                      dialogMessage={this.state.dialogMessage}
                      dialogOpen={this.state.dialogOpenWarning}
                      dialogTitle={this.state.dialogTitle}
                  />
                  <ConfirmacionAccept
                      handleDialog={this.handleDialog}
                      dialogMessage={this.state.dialogMessage}
                      dialogOpen={this.state.dialogOpenAccept}
                      dialogTitle={this.state.dialogTitle}
                  />

                     <Card small style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                       <Row>&nbsp;</Row>
                       <React.Fragment>
                         <Row form>
                           <Col md="8"></Col>
                           <Col md="4" style={{fontSize:10}}>
                               {this.displayConfigDeliverable()}
                           </Col>
                         </Row>
                         <Row form>
                           <Col md="0"></Col>
                           <Col md="12" style={{fontSize:11}}>
                               {this.displayListDeliverable()}
                           </Col>
                         </Row>
                        <Row form>
                          <Col md="8"></Col>
                          <Col md="4" style={{fontSize:10}}>
                              {this.displayPaginacion()}
                          </Col>
                        </Row>
                      </React.Fragment>
                    </Card>
                    <Row>
                      { displayEmailX ?
                        <Col lg="7" className="mb-4">
                          <Card small className="mb-4 pt-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                            <CardHeader className="border-bottom text-center" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                              <div className="mb-3 mx-auto" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                                 <ContractAttachDeliverable typeContract={this.props.typeContract} numberContract={this.props.numberContract} subjectContract={''} view={this.state.view} from={this.state.from} username={this.state.username} uuid={this.state.uuidX} returnMail={this.returnMail} returnProps={this.returnProps} jsonAttachFile={this.state.jsonAttachFile} jsonAttachFile1={this.state.jsonAttachFile1} jsonAttachFile2={this.state.jsonAttachFile2} colorDark={this.props.colorDark}  userOrigin={this.props.userOrigin} viewDeliverableX={this.state.viewDeliverableX} returnBack = {this.returnBack} />
                              </div>
                            </CardHeader>
                          </Card>
                        </Col>
                      : null }
                      { displayEmail2X ?
                        <Col lg="5" className="mb-4">
                          <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                            <ListGroup flush>
                              <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                                  <ContractDeliverableMail from={this.state.from} username={this.state.username} id={this.state.id} view={this.state.view} jsonBlockstackZ50={this.state.jsonBlockstackZ50} jsonBlockstackZ80={this.state.jsonBlockstackZ80} sendMail={this.sendMail} returnMail={this.returnMail} jsonBlockstackZ70={this.state.jsonBlockstackZ70} confirmSignX={this.props.confirmSignX} FIACurrency={this.props.FIACurrency} cryptoPactClarityEthereum={this.props.cryptoPactClarityEthereum} typeContract={this.props.typeContract} numberContract={this.props.numberContract} myUserConfig={this.props.myUserConfig} colorDark={ this.props.colorDark} userRole={this.props.userRole} userOrigin={this.props.userOrigin} selectedSignaturePayment={this.props.selectedSignaturePayment} paidDeliverableX={this.state.paidDeliverableX} existDeliverableX={this.state.existDeliverableX} jsonPaymentForm={this.state.jsonPaymentForm}  CurrencyType={this.props.CurrencyType} FIACurrency={this.props.FIACurrency} cryptoCurrencyOrigin={this.props.cryptoCurrencyOrigin} amountPayment={this.props.amountPayment} cryptoCurrencyPayment={this.props.cryptoCurrencyPayment} selectedDeliverableCostX={this.state.selectedDeliverableCostX} deliverableUuidX={this.state.deliverableUuidX} form_deliverableX={this.state.form_deliverableX} toX={this.state.toX} ccX={this.state.ccX} />
                              </ListGroupItem>
                            </ListGroup>
                          </Card>
                        </Col>
                      : null }
                    </Row>
                    { displaySignatureDeliverableX ?
                      <Row>
                        <Col lg="12" className="mb-4">
                          <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                            <ListGroup flush>
                              <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                                  <SignatureDeliverable from={this.state.from} username={this.state.username} id={this.state.id}  returnMail={this.returnMail} typeContract={this.props.typeContract} numberContract={this.props.numberContract} subjectContract={this.props.subjectContract} myUserConfig={this.props.myUserConfig} colorDark={ this.props.colorDark} userRole={this.props.userRole} userOrigin={this.props.userOrigin} deliverableUuidX = {this.state.deliverableUuidX} role={this.state.roleDeliverableX} trimmedDataURL={this.props.trimmedDataURL} signatureFormX={this.state.signatureFormX} signatureSubjectX={this.state.signatureSubjectX} registerNumber={this.state.registerNumber} jsonListDeliverable={this.state.jsonBlockstackZ50} jsonListDeliverableSignSend={this.state.jsonBlockstackZ40} returnSign={this.returnSign} bSignatureNextLevelX={this.state.bSignatureNextLevelX} roleDeliverableX={this.state.roleDeliverableX} authorizersX={this.state.authorizersX} roleX={this.state.roleX} bCloseX={this.state.bCloseX} />
                              </ListGroupItem>
                            </ListGroup>
                          </Card>
                        </Col>
                      </Row>
                    : null }
                    { displayCloseX ?
                      <Row>
                        <Col lg="12" className="mb-4">
                          <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                            <ListGroup flush>
                              <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                                  <CloseDeliverable from={this.state.from} username={this.state.username} id={this.state.id}  returnMail={this.returnMail} typeContract={this.props.typeContract} numberContract={this.props.numberContract} subjectContract={this.props.subjectContract} myUserConfig={this.props.myUserConfig} colorDark={ this.props.colorDark} userRole={this.props.userRole} userOrigin={this.props.userOrigin} deliverableUuidX = {this.state.deliverableUuidX} role={this.state.roleDeliverableX} registerNumber={this.state.registerNumber} jsonListDeliverable={this.state.jsonBlockstackZ50} jsonListDeliverableSignSend={this.state.jsonBlockstackZ40} returnSign={this.returnSign} bSignatureNextLevelX={this.state.bSignatureNextLevelX}  signatureFormX={this.state.signatureFormX} signatureSubjectX={this.state.signatureSubjectX} authorizersX={this.state.authorizersX} roleX={this.state.roleX} displayClosedDeliveryX={this.state.displayClosedDeliveryX} />
                              </ListGroupItem>
                            </ListGroup>
                          </Card>
                        </Col>
                      </Row>
                    : null }

              </Container>
            </div>

          );
     }
}

export default ContractDeliverable;
