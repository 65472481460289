import React from "react";
import { NavItem, NavLink, Badge, Collapse, DropdownItem } from "shards-react";

//translate
import { FormattedMessage } from 'react-intl';

export default class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      language: 'English',
      viewAllNotifications: false,
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
  }

  UNSAFE_componentWillMount() {
    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English' })
    }else{
      this.setState({ language: languageX })
    }
  }

  toggleNotifications() {
    this.setState({
      visible: !this.state.visible
    });
  }


  viewAllNotifications = (e) => {
    if (this.state.viewAllNotifications === false){
      this.setState({ viewAllNotifications: true })
    }else{
      this.setState({ viewAllNotifications: false })
    }
  }

  simulation = (e) => {
    let language3 = 'en'
    if (this.state.language === 'English'){
       language3 = 'en'
    }
    if (this.state.language === 'French'){
      language3 = 'fr'
    }
    if (this.state.language === 'Spanish'){
      language3 = 'es'
    }
    if (this.state.language === 'Portuguese'){
      language3 = 'pt'
    }
    if (this.state.language === 'Swedish'){
      language3 = 'sv'
    }
    if (this.state.language === 'Netherlands'){
      language3 = 'nl'
    }
    if (this.state.language === 'Russian'){
      language3 = 'ru'
    }
    if (this.state.language === 'Japanese'){
      language3 = 'jp'
    }
    if (this.state.language === 'Chinese'){
      language3 = 'cn'
    }
    if (this.state.language === 'German'){
      language3 = 'de'
    }
    if (this.state.language === 'Italian'){
      language3 = 'it'
    }
    //window.location = window.location.origin + `?lang=${language3}#/simulator`
  }

  render() {
    const viewAllNotificationsX = this.state.viewAllNotifications
    return (
      <NavItem className="border-right dropdown notifications">
        <NavLink
          className="nav-link-icon text-center"
          onClick={this.toggleNotifications}
          style={{cursor: 'pointer'}}
        >
          <div className="nav-link-icon__wrapper">
            <i className="material-icons">&#xE7F4;</i>
            <Badge pill theme="danger">
              2
            </Badge>
          </div>
        </NavLink>
        <Collapse
          open={this.state.visible}
          className="dropdown-menu dropdown-menu-small"
          style={ this.props.colorDark ? { backgroundColor:'#6788e6'} : {}}
        >
          <DropdownItem tag="a" style={{cursor: 'pointer'}}>
            <div className="notification__icon-wrapper">
              <div className="notification__icon">
                <i className="material-icons">video_call</i>
              </div>
            </div>
            <div className="notification__content">
              <span className="notification__category"><FormattedMessage id="notification.video" /></span>
              <p>
                <FormattedMessage id="notification.watchdemovideo" />{" "}
                <a style={{color: "blue", cursor: 'pointer'}} href="https://www.youtube.com/watch?v=cWnIGMGOhWA"><FormattedMessage id="notification.presshere" /></a>
              </p>
            </div>
          </DropdownItem>
          <DropdownItem tag="a" style={{cursor: 'pointer'}}>
            <div className="notification__icon-wrapper">
              <div className="notification__icon">
                <i className="material-icons">flash_on</i>
              </div>
            </div>
            <div className="notification__content">
              <span className="notification__category"><FormattedMessage id="notification.simulation" /></span>
              <p>
                <FormattedMessage id="notification.quicksimulation" />{" "}
                <strong style={{color: "blue", cursor: 'pointer'}} onClick={e => this.simulation(e)}><FormattedMessage id="notification.presshere" /></strong>
                {/*<a href="https://xck.app/?lang=es#/simulator"><FormattedMessage id="notification.presshere" /></a>*/}
              </p>
            </div>
          </DropdownItem>
          <DropdownItem tag="a" style={{cursor: 'pointer'}} className="notification__all text-center" onClick={e => this.viewAllNotifications(e)}>
            {viewAllNotificationsX ?
              <FormattedMessage id="notification.notmorenotifications" />
            :
              <FormattedMessage id="notification.viewallnotifications" />
            }
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
