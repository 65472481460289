import { MOSTRAR_TEMPLATEALL, TOKEN_TEMPLATE, MOSTRAR_TEMPLATE } from './types';

import axios from 'axios';
import {token} from '../config.js'

export const tokenTemplate = (usernameX) => async dispatch => {

    const respuesta = await axios.post(`https://xck.app:4443/serverUserNameLogin`,
          {"userName":`${usernameX}`},
          { headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }})
     dispatch({
          type: TOKEN_TEMPLATE,
          payload: respuesta.data
     })
}

export const mostrarTemplateAllAnt = (usernameX) => dispatch => {
  return new Promise ((resolve, reject) =>{
     axios.post(`https://xck.app:4443/serverUserNameLogin`,
           {"userName":`${usernameX}`},
           { headers: {
             'Content-Type': 'application/json',
             'Accept': 'application/json',
           }})
       .then(respuesta => {
           const {token} = respuesta.data
           axios.get(`https://xck.app:4443/templateAll`,
               {headers: {
                  "token": `${token}`
               }})
             .then(respuesta => {
                dispatch({
                  type: MOSTRAR_TEMPLATEALL,
                  payload: respuesta.data.arrayTemplateDB
                })
                resolve()
             })
             .catch(function (error) {
                reject()
             })
       })
       .catch(function (error) {
          reject()
       })
  })
}

export const mostrarTemplateAll = (usernameX) => dispatch => {
  return new Promise ((resolve, reject) =>{
       axios.get(`https://xck.app:4443/templateAll`,
           {headers: {
              "token": `${token}`
           }})
         .then(respuesta => {
            dispatch({
              type: MOSTRAR_TEMPLATEALL,
              payload: respuesta.data.arrayTemplateDB
            })
            resolve()
         })
         .catch(function (error) {
            reject()
         })
   })
}


export const mostrarTemplateAnt = (usernameX,idtemplate) => async dispatch => {
      await axios.post(`https://xck.app:4443/serverUserNameLogin`,
            {"userName":`${usernameX}`},
            { headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            }})
        .then(respuesta => {
            const {token} = respuesta.data
            axios.get(`https://xck.app:4443/template/${idtemplate}`,
                  {headers: {
                     "token": `${token}`
                  }})
              .then(respuesta => {
                 dispatch({
                      type: MOSTRAR_TEMPLATE,
                      payload: respuesta.data
                 })
              })
        })
}

export const mostrarTemplate = (usernameX,idtemplate) => async dispatch => {
      axios.get(`https://xck.app:4443/template/${idtemplate}`,
            {headers: {
               "token": `${token}`
            }})
        .then(respuesta => {
           dispatch({
                type: MOSTRAR_TEMPLATE,
                payload: respuesta.data
           })
        })
}
