//React
import React, { Component } from 'react';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Container, Button, Card, FormCheckbox } from "shards-react";
import { Table } from 'reactstrap';

import TituloPagina2 from "../common/TituloPagina2";

import NewContractIoTDevice from "./NewContractIoTDevice";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//translate
import { FormattedMessage } from 'react-intl';

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

class ContractIoTDevice extends Component {

    constructor(props) {
        super(props);

        this.clickRemove = this.clickRemove.bind(this);
        this.clickEdit = this.clickEdit.bind(this);
        this.crearContractIoTDevice = this.crearContractIoTDevice.bind(this);
        this.goContractIoTDevice = this.goContractIoTDevice.bind(this);
        this.exitProgram = this.exitProgram.bind(this);
        this.exitIdentification = this.exitIdentification.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          opcionContractIoTDevice: '',
          displayContractIoTDevice: false,
          jsonBlockstack: [],
          jsonBlockstackY: [],
          jsonBlockstack2: [],
          typeContractIoTDevice: '',
          idx: '',
          jsonEnrollment: [],
          displayLink: false,
          enrollment: '',
          activate:false,
          language: 'English',
        }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
    }

    handleChange(e,chk,i) {
      const newState = {};
      newState[chk] = !this.state[chk];
      this.setState({...this.state, ...newState});
      this.setState({isErrorWrite: false})
      if (chk==='activate'){
        const {jsonBlockstackY} = this.state
        jsonBlockstackY.map((todo2, y) => {
          if (y === i) {
            if (todo2.activate === true){
              todo2.activate = false
            }else{
              if (todo2.activate === false){
                todo2.activate = true
              }
            }
            const {userSession} = this.state
            const storage = new Storage({ userSession });
            const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
            const {enrollment} = this.state
            // actualizar activate
            putFileDB(this.state.username,`${this.props.typeContract}_${this.state.idx}_${enrollment}_device.json`, 'Crosscheck_IoTDevice', JSON.stringify(jsonBlockstackY), JSON.stringify(options))
              .then(() => {
                 //console.log(`Grabación Exitosa Contract with IoTDevice (${this.props.typeContract}_${this.state.idx}_${enrollment}_device.json`)
              })
          }
        })
      }
    }

    goContractIoTDevice(enrollment) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({idx: this.props.numberContract})
      this.setState({jsonBlockstackY: []})
      this.setState({displayContractIoTDevice: true})
      this.setState({enrollment: enrollment})
      this.setState({displayLink: true})

      localStorage.removeItem('jsonBlockstack50D')

      getFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_${enrollment}_device.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             localStorage.setItem('jsonBlockstack50D',jsonBlockstack5)
             this.setState({jsonBlockstackY: jsonBlockstack6})
           } else {
             //console.log(`No Data (${this.props.typeContract}_${this.props.numberContract}_${enrollment}_device.json)`);
           }
         })
          .catch(error => {
            //console.log(error);
          });
    }

    clickRemove = (e,optx) => {
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
        const contractiotdevice = this.state.jsonBlockstackY
        const {enrollment} = this.state
        // borrarlo del objeto
        contractiotdevice.splice(optx,1);
        // actualizar state
        this.setState({jsonBlockstackY: contractiotdevice})
        const jsonBlockstack1 = JSON.stringify(contractiotdevice)
        putFileDB(this.state.username,`${this.props.typeContract}_${this.state.idx}_${enrollment}_device.json`, 'Crosscheck_IoTDevice', jsonBlockstack1, JSON.stringify(options))
          .then(() => {
             this.setState({
                status: true
             })
             //console.log(`Grabación Exitosa Contract with IoTDevice (${this.props.typeContract}_${this.state.idx}_${enrollment}_device.json`)
             localStorage.setItem('jsonBlockstack50D',jsonBlockstack1)
          })
    }

    crearContractIoTDevice = (infoContractIoTDevice) => {
      this.setState({jsonBlockstackY: JSON.parse(infoContractIoTDevice)})
      localStorage.setItem('jsonBlockstack50D',infoContractIoTDevice)
    }

    clickEdit = (e,optx) => {
    }

    exitProgram(){
       //window.location = '/'
       this.props.returnProps();
    }

    exitIdentification(){
      this.setState({displayLink: false})
    }

    render() {
        const {jsonBlockstackY} = this.state
        const {idx} = this.state
        const {typeContract} = this.props
        const {enrollment} = this.props
        const messageSubtittle = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`
        const messageSubtittle2 = `${this.props.typeContract}/${this.props.numberContract}`
        const {jsonEnrollment} = this.props

        const displayLinkX = this.state.displayLink
        const displayFullChargeX = true
        let correlativo = 0
        let activate = false
        let titleX = ''
        if (this.state.language === 'English'){
          titleX = "Link Contract and Sensors"
        }
        if (this.state.language === 'French'){
          titleX = "Lien de contrat et capteurs"
        }
        if (this.state.language === 'Spanish'){
          titleX = "Enlace de contrato y sensores"
        }
        if (this.state.language === 'Portuguese'){
          titleX = "Contrato de ligação e sensores"
        }
        if (this.state.language === 'Swedish'){
          titleX = "Länkkontrakt och sensorer"
        }
        if (this.state.language === 'Netherlands'){
          titleX = "Contract en sensoren koppelen"
        }
        if (this.state.language === 'Russian'){
          titleX = "Ссылка Контракт и Датчики"
        }
        if (this.state.language === 'Japanese'){
          titleX = "リンクコントラクトとセンサー"
        }
        if (this.state.language === 'Chinese'){
          titleX = "链接合同和传感器"
        }
        if (this.state.language === 'German'){
          titleX = "Link Vertrag und Sensoren"
        }
        if (this.state.language === 'Italian'){
          titleX = "Contratto di collegamento e sensori"
        }
        return (
          <div>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                  <TituloPagina2
                    sm="8"
                    title={titleX}
                    subtitle={messageSubtittle}
                    className="text-sm-left"
                  />
                </Row>
                <Row>
                  {displayFullChargeX ?
                    <Col md="3">
                      <Card small className="mb-12 pt-3">
                        <label>&nbsp;</label>
                        <Table size="sm" className="text-center" responsive hover striped style={{fontSize:12}}>
                            <thead>
                              <tr>
                                <th style={{ width: "30px" }}>#</th>
                                <th style={{ width: "160px" }}><FormattedMessage id="iotdevice.identification" /></th>
                                <th style={{ width: "80px" }}><FormattedMessage id="iotdevice.status" /></th>
                              </tr>
                            </thead>
                            <tbody>
                              {jsonEnrollment.map((todo, i) => {
                                correlativo = i
                                return (
                                    <tr key={i}>
                                        <td>{++correlativo}</td>
                                        <td><strong style={{cursor: 'pointer'}} onClick={()=>{this.goContractIoTDevice(todo.enrollment)}}>{todo.enrollment}</strong></td>
                                        <td><strong style={{cursor: 'pointer'}} onClick={()=>{this.goContractIoTDevice(todo.enrollment)}}><img src={todo.link} weight="25" height="25" alt=""/></strong></td>
                                    </tr>
                                 )})}
                            </tbody>
                        </Table>

                      </Card>
                    </Col>
                  : null }
                  {displayLinkX ?
                    <React.Fragment>
                      <Col md="9">
                        <Card small className="mb-12 pt-3">
                          <Col md="1"></Col>
                          <Col>
                            <Row noGutters className="page-header py-4">
                              <TituloPagina2
                                sm="8"
                                title={this.state.enrollment}
                                subtitle={messageSubtittle2}
                                className="text-sm-left"
                              />
                            </Row>
                            <Row>
                              <Col>
                                <Form style={{fontSize:12}}>
                                  <Row form>
                                    <Col md="4">
                                      <label></label>
                                        <NewContractIoTDevice typeContract={typeContract} idx={idx} crearContractIoTDevice={this.crearContractIoTDevice} opcionContractIoTDevice={this.state.opcionContractIoTDevice} enrollment={this.state.enrollment}/>
                                    </Col>

                                    <Col md="6">
                                      <label>&nbsp;</label>
                                      {this.state.displayContractIoTDevice ?
                                             <Table size="sm">
                                                <thead>
                                                  <tr>
                                                    <th><FormattedMessage id="iotdevice.code" /></th>
                                                    <th><FormattedMessage id="iotdevice.description" /></th>
                                                    <th><FormattedMessage id="iotdevice.activate" /></th>
                                                    <th><FormattedMessage id="iotdevice.delete" /></th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {jsonBlockstackY.map((todo, i) => {
                                                     activate=todo.activate
                                                     return (
                                                        <tr key={i}>
                                                            <td>{todo.code}</td>
                                                            <td>{todo.description}</td>
                                                            <td>
                                                              <FormCheckbox
                                                                  inline
                                                                  checked={activate}
                                                                  onChange={e=>this.handleChange(e, "activate",i)}
                                                                  disabled={false}
                                                              >
                                                              </FormCheckbox>
                                                            </td>
                                                            <td><Button pill theme="danger" onClick={e=>this.clickRemove(e, i)}><FontAwesomeIcon icon={faTimes} /></Button></td>
                                                        </tr>
                                                      )})}
                                                </tbody>
                                              </Table>
                                     : null }
                                    </Col>

                                  </Row>
                                </Form>
                              </Col>
                            </Row>
                            <Row form>
                               <Button pill theme="warning" onClick={this.exitIdentification}><FormattedMessage id="button.identificationlist" /></Button>&nbsp;
                               <Button pill theme="secondary" onClick={this.exitProgram}><FormattedMessage id="button.contractlist" /></Button>
                            </Row>
                            <br></br>
                          </Col>
                        </Card>
                      </Col>
                    </React.Fragment>
                  : null }
                </Row>
                <br></br>
            </Container>
          </div>
        );

     }
}

export default ContractIoTDevice;
