import React, { Component } from 'react';
import { Container, Card} from "shards-react";

import axios from 'axios';

//Funcion que formatea el numero para mostrarlo con separador de miles.

class DatosCryptoCurrency extends Component {
     constructor(props) {
        super(props);
        this.state = {
          lastUSD: undefined,
          symbolUSD: undefined,
          lastCLP: undefined,
          symbolCLP: undefined,
          lastEUR: undefined,
          symbolEUR: undefined,
          lastGBP: undefined,
          symbolGBP: undefined,
          lastJPY: undefined,
          symbolJPY: undefined,
          lastCNY: undefined,
          symbolCNY: undefined,
          lastRUB: undefined,
          symbolRUB: undefined,
          separador: ',', // separador para los miles
          sepDecimal: '.', // separador para los decimales

        }
     }

     componentDidMount() {
        //this.getCrypto();
     }

     formatear(num){
       num +='';
       var splitStr = num.split('.');
       var splitLeft = splitStr[0];
       var splitRight = splitStr.length > 1 ? this.state.sepDecimal + splitStr[1] : '';
       var regx = /(\d+)(\d{3})/;
       while (regx.test(splitLeft)) {
          splitLeft = splitLeft.replace(regx, '$1' + this.state.separador + '$2');
       }
       return this.simbol + splitLeft +splitRight;
     }

     format(num, simbol){
        this.simbol = simbol ||'';
        return this.formatear(num);
     }

     getCrypto = () => {
          axios
              .get("https://blockchain.info/ticker")
              .then(respuesta => {
                 const {USD} = respuesta.data;
                 const {EUR} = respuesta.data;
                 const {CLP} = respuesta.data;
                 const {GBP} = respuesta.data;
                 const {JPY} = respuesta.data;
                 const {CNY} = respuesta.data;
                 const {RUB} = respuesta.data;
                 this.setState({lastUSD: USD.last,
                                symbolUSD: USD.symbol,
                                lastEUR: EUR.last,
                                symbolEUR: EUR.symbol,
                                lastCLP: CLP.last,
                                symbolCLP: CLP.symbol,
                                lastGBP: GBP.last,
                                symbolGBP: GBP.symbol,
                                lastJPY: JPY.last,
                                symbolJPY: JPY.symbol,
                                lastCNY: CNY.last,
                                symbolCNY: CNY.symbol,lastRUB: RUB.last,
                                symbolRUB: RUB.symbol})
              })
              .catch(err => {
                  return null;
              })
     };

     mostrarResultado = () => {
           return (
                <React.Fragment>
                  <Container fluid className="px-0" style={ this.props.colorDark ? { backgroundColor:'#1f2124'} : {}}>
                    <Card theme="ligth" style={{ color: "grey", fontSize:11}}>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              {/*&nbsp;&nbsp;<div className="fa fa-info"></div>*/}
                            </td>
                            <td>
                              {/*&nbsp;&nbsp;{`Bitcoin Market (BTC):  USD${this.state.symbolUSD} ${this.format(parseFloat(this.state.lastUSD), '')}   |   CLP${this.state.symbolCLP} ${this.format(parseFloat(this.state.lastCLP), '')}   |   EUR${this.state.symbolEUR} ${this.format(parseFloat(this.state.lastEUR), '')}   |   GPB${this.state.symbolGBP} ${this.format(parseFloat(this.state.lastGBP), '')}   |   JPY${this.state.symbolJPY} ${this.format(parseFloat(this.state.lastJPY), '')}   |   CNY${this.state.symbolCNY} ${this.format(parseFloat(this.state.lastCNY), '')}   |   ${this.state.symbolRUB} ${this.format(parseFloat(this.state.lastRUB), '')}`}*/}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                  </Container>
                </React.Fragment>
            );
      }

      render() {
        return (
            <React.Fragment>
                 {this.mostrarResultado() }
            </React.Fragment>
        )
      }
}

export default DatosCryptoCurrency;
