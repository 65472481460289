import React, { Component } from 'react';

//UI
import {Container, Row, Col, FormInput, Button, Card, InputGroupAddon, InputGroupText, InputGroup} from "shards-react";
import { Table } from 'reactstrap';

// blockstack
import { UserSession } from '@stacks/connect';
import { StacksTestnet, StacksMainnet } from '@stacks/network';
import { StackingClient } from '@stacks/stacking';

//transla
import { FormattedMessage } from 'react-intl';

// Spinning
import ContractLoader from "./ContractLoader";

//Configuración
import { SMART_CONTRACT_ADDRESS_MAINNET_STX,
         SMART_CONTRACT_NAME_MAINNET_STX,
         SMART_CONTRACT_ADDRESS_TESTNET_STX,
         SMART_CONTRACT_NAME_TESTNET_STX,
         SMART_CONTRACT_ADDRESS_MAINNET_XCK,
         SMART_CONTRACT_NAME_MAINNET_XCK,
         SMART_CONTRACT_ADDRESS_MAINNET_MXM,
         SMART_CONTRACT_NAME_MAINNET_MXM,
         SMART_CONTRACT_ADDRESS_MAINNET_MIA,
         SMART_CONTRACT_NAME_MAINNET_MIA,
         SMART_CONTRACT_ADDRESS_MAINNET_NYC,
         SMART_CONTRACT_NAME_MAINNET_NYC,
         SMART_CONTRACT_ADDRESS_MAINNET_FRIE,
         SMART_CONTRACT_NAME_MAINNET_FRIE,
         SMART_CONTRACT_ADDRESS_MAINNET_DIKO,
         SMART_CONTRACT_NAME_MAINNET_DIKO,
         MAINNET_STACKS_API_URL,
         TESTNET_STACKS_API_URL,
         MAINNET_STACKS_API_URL_MONGODB,
         TESTNET_STACKS_API_URL_MONGODB } from '../../config.js'

//Clarity
import { balanceOf, getBalance } from "../../clarity/clarityfunctions"

class ContractDrawerFinancesSend extends Component {
  constructor(props) {
      super(props);

      this.state = {
        userSession: new UserSession(),
        username: '',
        user: {},
        code: '',
        stacksNetX: '',
        serverStacksX: '',
        networkX: '',
        clientX: '',
        balanceCtaSTX: null,
        balanceCtaXCK: null,
        balanceCtaMXM: null,
        balanceCtaMIA: null,
        balanceCtaNYC: null,
        balanceCtaFRIE: null,
        balanceCtaDIKO: null,
        arrow: true,
        addressKeyX: '',
       }

    }

    c01SendTo = React.createRef();
    c02Mount = React.createRef();
    c03Memo = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state

      if (userSession.isUserSignedIn()) {
        let stacksNetX = ''
        let serverStacksX = ''
        let networkUrlX = ''
        let networkX = ''
        let contractNameX = ''
        let contractAddressX = ''
        let contractAddressX2 = ''
        let contractNameX_XCK = ''
        let contractAddressX_XCK = ''
        let contractAddressX2_XCK = ''
        let contractNameX_MXM = ''
        let contractAddressX_MXM = ''
        let contractAddressX2_MXM = ''
        let contractNameX_MIA = ''
        let contractAddressX_MIA = ''
        let contractAddressX2_MIA = ''
        let contractNameX_NYC = ''
        let contractAddressX_NYC = ''
        let contractAddressX2_NYC = ''
        let contractNameX_FRIE = ''
        let contractAddressX_FRIE = ''
        let contractAddressX2_FRIE = ''
        let contractNameX_DIKO = ''
        let contractAddressX_DIKO = ''
        let contractAddressX2_DIKO = ''
        let addressKeyX = ''

        const user = userSession.loadUserData()

        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }

        if (serverStacksX === 'MainNet'){
           networkUrlX = MAINNET_STACKS_API_URL
           contractNameX = SMART_CONTRACT_NAME_MAINNET_STX
           contractAddressX = SMART_CONTRACT_ADDRESS_MAINNET_STX
           contractAddressX2 = SMART_CONTRACT_ADDRESS_MAINNET_STX
           contractNameX_XCK = SMART_CONTRACT_NAME_MAINNET_XCK
           contractAddressX_XCK = SMART_CONTRACT_ADDRESS_MAINNET_XCK
           contractAddressX2_XCK = SMART_CONTRACT_ADDRESS_MAINNET_XCK
           contractNameX_MXM = SMART_CONTRACT_NAME_MAINNET_MXM
           contractAddressX_MXM = SMART_CONTRACT_ADDRESS_MAINNET_MXM
           contractAddressX2_MXM = SMART_CONTRACT_ADDRESS_MAINNET_MXM
           contractNameX_MIA = SMART_CONTRACT_NAME_MAINNET_MIA
           contractAddressX_MIA = SMART_CONTRACT_ADDRESS_MAINNET_MIA
           contractAddressX2_MIA = SMART_CONTRACT_ADDRESS_MAINNET_MIA
           contractNameX_NYC = SMART_CONTRACT_NAME_MAINNET_NYC
           contractAddressX_NYC = SMART_CONTRACT_ADDRESS_MAINNET_NYC
           contractAddressX2_NYC = SMART_CONTRACT_ADDRESS_MAINNET_NYC
           contractNameX_FRIE = SMART_CONTRACT_NAME_MAINNET_FRIE
           contractAddressX_FRIE = SMART_CONTRACT_ADDRESS_MAINNET_FRIE
           contractAddressX2_FRIE = SMART_CONTRACT_ADDRESS_MAINNET_FRIE
           contractNameX_DIKO = SMART_CONTRACT_NAME_MAINNET_DIKO
           contractAddressX_DIKO = SMART_CONTRACT_ADDRESS_MAINNET_DIKO
           contractAddressX2_DIKO = SMART_CONTRACT_ADDRESS_MAINNET_DIKO

           addressKeyX =  user.profile.stxAddress.mainnet
           networkX = new StacksMainnet()
        }
        if (serverStacksX === 'TestNet'){
           networkUrlX = TESTNET_STACKS_API_URL
           contractNameX = SMART_CONTRACT_NAME_TESTNET_STX
           contractAddressX = SMART_CONTRACT_ADDRESS_TESTNET_STX
           contractAddressX2 = SMART_CONTRACT_ADDRESS_MAINNET_STX
           addressKeyX =  user.profile.stxAddress.testnet
           networkX = new StacksTestnet()
        }

        this.setState({serverStacksX, addressKeyX})

        balanceOf(contractAddressX, addressKeyX, networkUrlX, contractNameX,contractAddressX2, networkX, user)
          .then(val => this.setState({balanceCtaSTX: val}))

        getBalance(addressKeyX, contractNameX_XCK,contractAddressX_XCK, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaXCK: val}))

        getBalance(addressKeyX, contractNameX_MXM,contractAddressX_MXM, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaMXM: val}))

        getBalance(addressKeyX, contractNameX_MIA,contractAddressX_MIA, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaMIA: val}))

        getBalance(addressKeyX, contractNameX_NYC,contractAddressX_NYC, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaNYC: val}))

        getBalance(addressKeyX, contractNameX_FRIE,contractAddressX_FRIE, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaFRIE: val}))

        getBalance(addressKeyX, contractNameX_DIKO,contractAddressX_DIKO, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaDIKO: val}))
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      if (languageX === 'English'){this.setState({ isocode: "en"})}
      if (languageX === 'French'){this.setState({ isocode: "fr"})}
      if (languageX === 'Spanish'){this.setState({ isocode: "es"})}
      if (languageX === 'Portuguese'){this.setState({ isocode: "pt"})}
      if (languageX === 'Swedish'){this.setState({ isocode: "sv"})}
      if (languageX === 'Netherlands'){this.setState({ isocode: "nl"})}
      if (languageX === 'Russian'){this.setState({ isocode: "ru"})}
      if (languageX === 'Japanese'){this.setState({ isocode: "ja"})}
      if (languageX === 'Chinese'){this.setState({ isocode: "zh"})}
      if (languageX === 'German'){this.setState({ isocode: "de",})}
      if (languageX === 'Italian'){this.setState({ isocode: "it"})}

    }

    handleChange(e,chk) {
      const newState = {};
      newState[chk] = !this.state[chk];

      if (chk==='arrow' && this.state.arrow===true){
        this.setState({arrow:false})
      }else{
        if (chk==='arrow' && this.state.arrow===false){
          this.setState({arrow:true})
        }
      }
    }

    sendMax = () => {
    }

    sendSTX = () => {
    }

    render() {
        let imgarrow = ''
        if (this.state.arrow===true){imgarrow='images/arrowdown.png'}else{imgarrow='images/arrowup.png'}
        let stacksNetX2 = 'mainnet'
        if (this.state.serverStacksX === 'TestNet'){stacksNetX2 = 'testnet'}
        let booleanSTX = true
        let booleanXCK = true
        let booleanMXM = true
        let booleanMIA = true
        let booleanNYC = true
        let booleanFRIE = true
        let booleanDIKO = true
        if (this.state.balanceCtaSTX === null) {
          booleanSTX = false
        }
        if (this.state.balanceCtaXCK === null) {
          booleanXCK = false
        }
        if (this.state.balanceCtaMXM === null) {
          booleanMXM = false
        }
        if (this.state.balanceCtaMIA === null) {
          booleanMIA = false
        }
        if (this.state.balanceCtaNYC === null) {
          booleanNYC = false
        }
        if (this.state.balanceCtaFRIE === null) {
          booleanFRIE = false
        }
        if (this.state.balanceCtaDIKO === null) {
          booleanDIKO = false
        }
        return (
          <div>
            <Container fluid className="main-content-container px-2" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                  <Card small className="text-uppercase"  style={ this.props.colorDark ? { backgroundColor:'#233b58' } : { backgroundColor:'#e9ecef' }}>
                        <table>
                          <tbody>
                            <tr>
                              <td style={ this.props.colorDark ? {width: "95%", color: "grey"} : {width: "95%"}}>
                                 &nbsp;&nbsp;
                                 <strong style={{cursor: 'pointer'}} onClick={e => this.handleChange(e,"arrow")}><img src={imgarrow} weight="20" height="20" alt=""/></strong>
                                 &nbsp;&nbsp;
                                 <FormattedMessage id="finances.send" />
                              </td>
                              <td style={{width: "5%"}}>
                                 <strong style={{cursor: 'pointer'}} onClick={e => this.handleChange(e,"arrow")}><img src={imgarrow} weight="20" height="20" alt=""/></strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                  </Card>
                  {this.state.arrow ?
                    <>
                      <div className="text-center">
                        <Row form>&nbsp;</Row>
                        <Row form>
                          <Col md="2"></Col>
                          <Col md="8">
                            <Table size="sm" style={{fontSize:18}} className="text-left" responsive >
                              <tbody>
                                <tr>
                                  <td style={{ width: "20%" }}><img src="images/stx.svg" weight="25" height="25" fill= "black" alt=""/></td>
                                  { booleanSTX ?
                                    <td style={this.props.colorDark ? {color:'white', width: "60%"} : {width: "60%"}}>{this.state.balanceCtaSTX.toLocaleString(this.state.isocode, { maximumFractionDigits: 6, minimumFractionDigits: 6 })}</td>
                                  :
                                    <td style={this.props.colorDark ? {color:'white', width: "60%"} : {width: "60%"}}><ContractLoader colorDark={this.props.colorDark} /></td>
                                  }
                                  <td style={this.props.colorDark ? {color:'white', width: "20%"} : {width: "20%"}}>STX</td>
                                </tr>
                                <tr>
                                  {this.props.colorDark ?
                                     <td style={{ width: "20%" }}><img src="images/isotipo2.svg" weight="30" height="30" alt=""/></td>
                                  :
                                     <td style={{ width: "20%" }}><img src="images/isotipo1.svg" weight="30" height="30" alt=""/></td>
                                  }
                                  { booleanXCK ?
                                    <td style={this.props.colorDark ? {color:'white', width: "60%"} : {width: "60%"}}>{this.state.balanceCtaXCK.toLocaleString(this.state.isocode, { maximumFractionDigits: 6, minimumFractionDigits: 6 })}</td>
                                  :
                                    <td style={this.props.colorDark ? {color:'white', width: "60%"} : {width: "60%"}}><ContractLoader colorDark={this.props.colorDark} /></td>
                                  }
                                  <td style={this.props.colorDark ? {color:'white', width: "20%"} : {width: "20%"}}>XCK</td>
                                </tr>
                                <tr>
                                  <td style={{ width: "20%" }}><img src="images/mxm.svg" weight="30" height="30" alt=""/></td>
                                  { booleanMXM ?
                                    <td style={this.props.colorDark ? {color:'white', width: "60%"} : {width: "60%"}}>{this.state.balanceCtaMXM.toLocaleString(this.state.isocode, { maximumFractionDigits: 6, minimumFractionDigits: 6 })}</td>
                                  :
                                    <td style={this.props.colorDark ? {color:'white', width: "60%"} : {width: "60%"}}><ContractLoader colorDark={this.props.colorDark} /></td>
                                  }
                                  <td style={this.props.colorDark ? {color:'white', width: "20%"} : {width: "20%"}}>MXM</td>
                                </tr>
                                <tr>
                                  <td style={{ width: "20%" }}><img src="images/mia.svg" weight="30" height="30" alt=""/></td>
                                  { booleanMIA ?
                                    <td style={this.props.colorDark ? {color:'white', width: "60%"} : {width: "60%"}}>{this.state.balanceCtaMIA.toLocaleString(this.state.isocode, { maximumFractionDigits: 6, minimumFractionDigits: 6 })}</td>
                                  :
                                    <td style={this.props.colorDark ? {color:'white', width: "60%"} : {width: "60%"}}><ContractLoader colorDark={this.props.colorDark} /></td>
                                  }
                                  <td style={this.props.colorDark ? {color:'white', width: "20%"} : {width: "20%"}}>MIA</td>
                                </tr>
                                <tr>
                                  <td style={{ width: "20%" }}><img src="images/nyc.svg" weight="30" height="30" alt=""/></td>
                                  { booleanNYC ?
                                    <td style={this.props.colorDark ? {color:'white', width: "60%"} : {width: "60%"}}>{this.state.balanceCtaNYC.toLocaleString(this.state.isocode, { maximumFractionDigits: 6, minimumFractionDigits: 6 })}</td>
                                  :
                                    <td style={this.props.colorDark ? {color:'white', width: "60%"} : {width: "60%"}}><ContractLoader colorDark={this.props.colorDark} /></td>
                                  }
                                  <td style={this.props.colorDark ? {color:'white', width: "20%"} : {width: "20%"}}>NYC</td>
                                </tr>
                                <tr>
                                  <td style={{ width: "20%" }}><img src="images/frie.svg" weight="30" height="30" alt=""/></td>
                                  { booleanFRIE ?
                                    <td style={this.props.colorDark ? {color:'white', width: "60%"} : {width: "60%"}}>{this.state.balanceCtaFRIE.toLocaleString(this.state.isocode, { maximumFractionDigits: 6, minimumFractionDigits: 6 })}</td>
                                  :
                                    <td style={this.props.colorDark ? {color:'white', width: "60%"} : {width: "60%"}}><ContractLoader colorDark={this.props.colorDark} /></td>
                                  }
                                  <td style={this.props.colorDark ? {color:'white', width: "20%"} : {width: "20%"}}>FRIE</td>
                                </tr>
                                <tr>
                                <td style={{ width: "20%" }}><img src="images/diko.png" weight="30" height="30" alt=""/></td>
                                  { booleanDIKO ?
                                    <td style={this.props.colorDark ? {color:'white', width: "60%"} : {width: "60%"}}>{this.state.balanceCtaDIKO.toLocaleString(this.state.isocode, { maximumFractionDigits: 6, minimumFractionDigits: 6 })}</td>
                                  :
                                    <td style={this.props.colorDark ? {color:'white', width: "60%"} : {width: "60%"}}><ContractLoader colorDark={this.props.colorDark} /></td>
                                  }
                                  <td style={this.props.colorDark ? {color:'white', width: "20%"} : {width: "20%"}}>DIKO</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                          <Col md="1"></Col>
                        </Row>
                        <Row form>&nbsp;</Row>
                        <span><a style={{fontSize:11}} href="https://coinmarketcap.com/currencies/stacks/markets/" target="_blank" rel="noopener noreferrer"><FormattedMessage id="finances.tipocambio" /></a></span>
                        <br></br>
                        <span><a style={{fontSize:11}} href={`https://explorer.stacks.co/address/${this.state.addressKeyX}/?chain=${stacksNetX2}`} target="_blank" rel="noopener noreferrer"><FormattedMessage id="finances.movimientos" /></a></span>
                        <Row form>&nbsp;</Row>
                      </div>
                    </>
                : null }
            </Container>
          </div>
        );
    }
}

export default ContractDrawerFinancesSend;
