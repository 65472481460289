//React
import React, { Component } from 'react';
import { Link } from 'react-router-dom'

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Card, Container, FormSelect, Button, FormInput, InputGroupAddon, InputGroupText, InputGroup } from "shards-react";
import { Table } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";
import { Divider, Typography, TextField, Grid, Dialog,
         DialogContent, DialogActions, DialogTitle, DialogContentText,
         Button as ButtonMaterialUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';

import TituloPagina2 from "../common/TituloPagina2";

// blockstack
import { UserSession } from 'blockstack';

//translate
import { FormattedMessage } from 'react-intl';

import {username_new} from '../../actions/userActions'

function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'install')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.install" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'continue')} theme="success">
                    <FormattedMessage id="alert.continue" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class ActiveParticipants extends Component {

    constructor(props) {
        super(props);

        this.state = {
          userSession: new UserSession(),
          username: '',
          name: '',
          user: {},
          dialogOpen: false,
          dialogMessage: '',
          dialogTitle: '',
          jsonUserGroupRemote: [],
          languaje: 'English',
        }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      const {identityAddress} = userSession.loadUserData(),
            {decentralizedID} = userSession.loadUserData()
      this.setState({ identityAddress, decentralizedID, displayUserGroup: true})

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      const languageCryptocurrency = localStorage.getItem('languageCryptocurrency')
      if (languageCryptocurrency === 'Ethereum Solidity (ETH)'){
          const metamask = localStorage.getItem('metamask')
          if (metamask === 'false'){
            this.alertConfirm(languageX);
          }
      }
    }

    handleDialog = (type, action) => {
      if (type === 'Error: 1001') {
        if (action === 'install') {
          this.setState({ dialogOpen: false });
          window.location = 'https://metamask.io/'
        }
        if (action === 'continue') {
          this.setState({ dialogOpen: false });
        }
      }
    }

    alertConfirm = (languageX) => {
      if (languageX === 'English'){this.setState({dialogOpen: true,dialogMessage: `Ethereum browser not detected. You should consider installing MetaMask!`,dialogTitle: 'Error: 1001',})}
      if (languageX === 'French'){this.setState({dialogOpen: true,dialogMessage: `Navigateur Ethereum non détecté. Vous devriez envisager d'installer MetaMask!`,dialogTitle: 'Error: 1001',})}
      if (languageX === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: `Navegador Ethereum no detectado. ¡Debería considerar instalar MetaMask!`,dialogTitle: 'Error: 1001',})}
      if (languageX === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: `Navegador Ethereum não detectado. Você deve considerar a instalação do MetaMask!`,dialogTitle: 'Error: 1001',})}
      if (languageX === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: `Ethereum-webbläsare har inte upptäckts. Du bör överväga att installera MetaMask!`,dialogTitle: 'Error: 1001',})}
      if (languageX === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: `Ethereum-browser niet gedetecteerd. U zou moeten overwegen om MetaMask te installeren!`,dialogTitle: 'Error: 1001',})}
      if (languageX === 'Russian'){this.setState({dialogOpen: true,dialogMessage: `Браузер Ethereum не обнаружен. Вы должны рассмотреть возможность установки MetaMask!`,dialogTitle: 'Error: 1001',})}
      if (languageX === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: `イーサリアムブラウザーが検出されません。 MetaMaskのインストールを検討する必要があります。`,dialogTitle: 'Error: 1001',})}
      if (languageX === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: `未检测到以太坊浏览器。 您应该考虑安装MetaMask！`,dialogTitle: 'Error: 1001',})}
      if (languageX === 'German'){this.setState({dialogOpen: true,dialogMessage: `Ethereum-Browser nicht erkannt. Sie sollten die Installation von MetaMask in Betracht ziehen!`,dialogTitle: 'Error: 1001',})}
      if (languageX === 'Italian'){this.setState({dialogOpen: true,dialogMessage: `Browser Ethereum non rilevato. Dovresti considerare l'installazione di MetaMask!`,dialogTitle: 'Error: 1001',})}
    }

    exitProgram = () =>{
       this.props.returnProps();
    }

    render() {
        let titleX = ''
        let messageSubtittle = ''
        let c01Wallet = ''
        let c02Wallet = ''
        const languageCryptocurrency = localStorage.getItem('languageCryptocurrency')
        if (languageCryptocurrency === 'Ethereum Solidity (ETH)'){
            let accountbalanceethereum = localStorage.getItem('accountbalanceethereum')
            if (accountbalanceethereum !== null && accountbalanceethereum !== undefined){
               accountbalanceethereum = JSON.parse(accountbalanceethereum)
               c01Wallet = accountbalanceethereum.account
               c02Wallet = accountbalanceethereum.balance
            }
        }
        return (
          <div>
            <Container fluid className="main-content-container px-4">
              <Row noGutters className="page-header py-4">
                <TituloPagina2
                  sm="4"
                  title={titleX}
                  subtitle={messageSubtittle}
                  className="text-sm-left"
                />
              </Row>
              <Confirmacion
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpen}
                  dialogTitle={this.state.dialogTitle}
              />
              <Row>
                <Col>
                  <Form style={{fontSize:12}}>
                      <Row form>
                        <Col md="3">
                          <label htmlFor="Account"><FormattedMessage id="wallet.account" /></label>
                          <FormInput
                             id="Account"
                             type="text"
                             placeholder={c01Wallet}
                             disabled={true}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        <Col md="3">
                          <label htmlFor="Balance"><FormattedMessage id="wallet.balance" /></label>
                          <FormInput
                             id="Balance"
                             type="text"
                             placeholder={c02Wallet}
                             disabled={true}
                          />
                        </Col>
                      </Row>
                  </Form>
                </Col>
              </Row>
              <Row form>
                 <Button pill theme="secondary" onClick={() => this.exitProgram()}><FormattedMessage id="button.contractlist" /></Button>
              </Row>
              <br></br>
            </Container>
          </div>
        );
    }
}

export default ActiveParticipants;
