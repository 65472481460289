import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Container,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormTextarea,
  Button,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormSelect,
  FormCheckbox,
  Progress
} from "shards-react";

import {  Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';
import { Table } from 'reactstrap';

//Number Format
import NumberFormat from 'react-number-format';

//
import { QRCode } from 'react-qrcode-logo';

//translate
import { FormattedMessage } from 'react-intl';

//
import {CopyToClipboard} from 'react-copy-to-clipboard';

//
import SignPad from './signpad'

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'
import { makeRandomPrivKey } from '@stacks/transactions';
import { StacksTestnet, StacksMainnet } from '@stacks/network';

//Loader
import ContractLoader from "../contracts/ContractLoader";
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

//UUID
import uuid from 'uuid';

//JWT
import jwt from 'jsonwebtoken';
import * as jose from 'jose'

//Image
import ImageUploader from 'react-images-upload';
import ReactImageFallback from "react-image-fallback";

//Base64
import { Base64 } from 'js-base64';

//Configuración
import { SMART_CONTRACT_ADDRESS_MAINNET_STX,
        SMART_CONTRACT_NAME_MAINNET_STX,
        SMART_CONTRACT_ADDRESS_TESTNET_STX,
        SMART_CONTRACT_NAME_TESTNET_STX,
        SMART_CONTRACT_ADDRESS_MAINNET_XCK,
        SMART_CONTRACT_NAME_MAINNET_XCK,
        SMART_CONTRACT_ADDRESS_MAINNET_MXM,
        SMART_CONTRACT_NAME_MAINNET_MXM,
        SMART_CONTRACT_ADDRESS_MAINNET_MIA,
        SMART_CONTRACT_NAME_MAINNET_MIA,
        SMART_CONTRACT_ADDRESS_MAINNET_NYC,
        SMART_CONTRACT_NAME_MAINNET_NYC,
        SMART_CONTRACT_ADDRESS_MAINNET_FRIE,
        SMART_CONTRACT_NAME_MAINNET_FRIE,
        MAINNET_STACKS_API_URL,
        TESTNET_STACKS_API_URL } from '../../config.js'

//Clarity
import {transferAttribution, balanceOf, getTransactionRegister, getBalance, service_fee} from "../../clarity/clarityfunctions"

//Currency
import currencyPayment from '../../utils/currency-payment';

import {username_new} from '../../actions/userActions'

import { fadNaAtLogin, fadNaAtRequisition } from '../../actions/fadNaAtActions';

//Countries
const Country = require('./countries.json');

//AttibutionDocuments
const AttributionDocuments = require('./attributiondocuments.json');

function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class NationalIdentity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userSession: new UserSession(),
      username: '',
      user: {},
      person: '',
      exist: true,
      pubKey: '',
      publicKey: '',
      opcionContractIoTDevice: '',
      displayContractIoTDevice: false,
      jsonBlockstack: [],
      jsonBlockstackY: [],
      jsonBlockstackZ: [],
      jsonBlockstack2: [],
      jsonBlockstackZ50c: [],
      typeContractIoTDevice: '',
      idx: '',
      url: 'https://gaia.blockstack.org/hub/',
      url2: 'https://core.blockstack.org/v1/users/',
      url3: 'https://core.blockstack.org/v1/names/',
      identityAddress: undefined,
      decentralizedID: undefined,
      language: 'English',
      nameBlockstack: '',
      displayLoader: false,
      goConfirmSign: false,
      dialogOpen: false,
      dialogOpenPaymentForm: false,
      dialogOpenSign: false,
      dialogOpenUnsign: false,
      dialogOpenConfirmSign: false,
      dialogMessage: '',
      dialogTitle: '',
      balanceCta: 0,
      resultTransfer: '',
      stacksAddress: '',
      privateKey: '',
      contractAddressX: '',
      addressKeyX: '',
      networkUrlX: '',
      contractNameX: '',
      contractAddressX2: '',
      serverStacksX: '',
      usernameX: '',
      jsonPaymentForm: '',
      buttonErrorPaymentForm: false,
      getTransaction: '',
      published: false,
      amount: 0.01,
      stacksAddressRecipient: '',
      stacksAddressSender: '',
      registerTransactionRegister: '',
      resultTransfer: '',
      disabledButton: false,
      balanceCtaSTX: null,
      balanceCtaXCK: null,
      balanceCtaMXM: null,
      balanceCtaMIA: null,
      balanceCtaNYC: null,
      balanceCtaFRIE: null,
      cryptoCurrencyX: 'STX - Stacks',
      symbolCriptoCurrency: 'STX',
      priceSTX: 0,
      priceXCK: 0,
      priceMXM: 0,
      priceMIA: 0,
      priceNYC: 0,
      priceFRIE: 0,
      messageRegisterX: '',
      isocode: 'en',


      displayPresentation: true,
      displayInputData: false,
      displayPayVerification: false,
      displayIdVerification: false,
      displayPhoneVerification: false,
      displayViewPdf: false,
      displaySummary: false,
      country: '',
      txId: '',
      status: '',
      countryDisabled: false,
      displayButtonRed: true,
      displayHabilitaBoton: false,
      pdfX: '',
      xmlX: '',
      respuestaRequisition: '',
      requisitionId: '',
      requisitionUrl: '',
      requisitionKey: '',
      requisitionVector: '',
      requisitionTicket: '',
      requisitionDiasRestantes: 5,
      requisitionDiasRestantes2: 100,
      themeRequisitionDiasRestantes: 'success',
      successJson: undefined,
      errorJson: '',
      mock: false,
      mockError: false,
    }
  }

  c01Country = React.createRef();

  componentDidMount() {

  }

  UNSAFE_componentWillReceiveProps() {
  }

  UNSAFE_componentWillMount() {
    const { userSession } = this.state
    let stacksNetX = ''
    let serverStacksX = ''
    let networkUrlX = ''

    if (userSession.isUserSignedIn()) {
      const user = userSession.loadUserData()
      this.setState({ user })

      if (!localStorage["serverStacks"]) {
        serverStacksX = 'MainNet'
      }else{
        serverStacksX = localStorage.getItem('serverStacks')
      }

      if (serverStacksX === 'MainNet'){
         networkUrlX = MAINNET_STACKS_API_URL
         stacksNetX =  user.profile.stxAddress.mainnet
      }
      if (serverStacksX === 'TestNet'){
         networkUrlX = TESTNET_STACKS_API_URL
         stacksNetX =  user.profile.stxAddress.testnet
      }
    }
    this.setState({stacksAddressSender: stacksNetX, stacksAddressRecipient: 'SPRK2JVQ988PYT19JSAJNR3K9YZAZGVY04XMC2Z7', networkUrlX}) //cta_platcc_paradigma_id

    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English' })
    }else{
      this.setState({ language: languageX })
    }

    const {username} = userSession.loadUserData();
    if (username === '' || username === undefined || username === null){
      username_new(stacksNetX).then(val => this.setState({username: val},()=>{}))
    }else{
      this.setState({username },()=>{this.getContract()})
    }

    this.setState({displayHabilitaBoton: false})

    if (languageX === 'English'){this.setState({ messageRegisterX:`${'Atribución pagada'}`})}
    if (languageX === 'French'){this.setState({ messageRegisterX:`${'Atribución pagada'}`})}
    if (languageX === 'Spanish'){this.setState({ messageRegisterX:`${'Atribución pagada'}`})}
    if (languageX === 'Portuguese'){this.setState({ messageRegisterX:`${'Atribución pagada'}`})}
    if (languageX === 'Swedish'){this.setState({ messageRegisterX:`${'Atribución pagada'}`})}
    if (languageX === 'Netherlands'){this.setState({ messageRegisterX:`${'Atribución pagada'}`})}
    if (languageX === 'Russian'){this.setState({ messageRegisterX:`${'Atribución pagada'}`})}
    if (languageX === 'Japanese'){this.setState({ messageRegisterX:`${'Atribución pagada'}`})}
    if (languageX === 'Chinese'){this.setState({ messageRegisterX:`${'Atribución pagada'}`})}
    if (languageX === 'German'){this.setState({ messageRegisterX:`${'Atribución pagada'}`})}
    if (languageX === 'Italian'){this.setState({ messageRegisterX:`${'Atribución pagada'}`})}
  }


  handleChangeCountry = (e) =>{
    e.preventDefault();
    this.putAttributionData(this.c01Country.current.value,this.state.txId,this.state.status, {})
  }

  handleChangeCryptoCurrency = (e) => {
    const palabra = e.target.value
    const ArrayIdValue = palabra.split(' - ')
    const symbolCurrencyX = ArrayIdValue[0]
    this.setState({cryptoCurrencyX: e.target.value, symbolCriptoCurrency: symbolCurrencyX})
    if (symbolCurrencyX === 'STX') {this.setState({amount:this.state.priceSTX})}
    if (symbolCurrencyX === 'XCK') {this.setState({amount:this.state.priceXCK})}
    if (symbolCurrencyX === 'MXM') {this.setState({amount:this.state.priceMXM})}
    if (symbolCurrencyX === 'MIA') {this.setState({amount:this.state.priceMIA})}
    if (symbolCurrencyX === 'NYC') {this.setState({amount:this.state.priceNYC})}
    if (symbolCurrencyX === 'FRIE') {this.setState({amount:this.state.priceFRIE})}
  }

  handleDialog = (e, type, action, cryptoX, saldoX) => {
    e.preventDefault();
    if (action === 'pay') {
        const message64 = Base64.encode(this.state.messageRegisterX)
        transferAttribution(this.state.stacksAddressSender, this.state.serverStacksX, this.state.stacksAddressRecipient, parseFloat(this.state.amount), this.props.typeContract, this.props.numberContract, this.state.symbolCriptoCurrency, message64, this.state.jsonBlockstackZ50c, uuid(), this.state.username, this.state.country)
         .then(result => {
            this.setState({ dialogOpenConfirmSign: false, resultTransfer: result, disabledButton: true })
            if (result !== undefined ){
              this.registerTransactionRegister(result)
            }
        })
    }
  }

  handleDialog2 = (type, action) => {
    if (type === 'Error: 1001') {
        if (action === 'cancel') {
          this.setState({ dialogOpen: false });
        }
    }
    if (type === 'Error: 1002') {
        if (action === 'cancel') {
          this.setState({ dialogOpen: false });
        }
    }
  }

  handleNext = (e,pagina) => {
    e.preventDefault();
    this.setState({displayButtonRed: true, displayPresentation: false})
    if (pagina === 0){
      Promise.all([this.getAttributionData()
                  ])
         .then(
            (resolve) => {this.setState({displayHabilitaBoton: true, displayButtonRed: false})},
            (reject) => {this.setState({displayHabilitaBoton: false, displayButtonRed: true})}
         )
      this.setState({displayInputData:true,displayPayVerification:false,displayIdVerification:false,displayViewPdf:false,displaySummary:false})
    }
    if (pagina === 1){
      if (this.props.SMS === undefined || this.props.SMS === null || this.props.SMS.trim() === ''){
         this.setState({displayButtonRed: true})
         this.faltaDataConfirm()
      }else{
         if (this.props.EMAIL === undefined || this.props.EMAIL === null || this.props.EMAIL.trim() === ''){
           this.setState({displayButtonRed: true})
           this.faltaDataConfirm()
         }else{
            if (this.props.username === undefined || this.props.username === null || this.props.username === ''){
              this.setState({displayButtonRed: true})
              this.faltaIdConfirm()
            }else{
              if (this.state.displayHabilitaBoton === true){
                this.setState({displayInputData:false,displayPayVerification:true,displayIdVerification:false,displayViewPdf:false,displaySummary:false})
                if (this.state.txId !== undefined){
                  if (this.state.txId !== null && this.state.txId !== '' ) {
                    getTransactionRegister(this.state.networkUrlX, this.state.txId)
                      .then(val => {
                          this.setState({ getTransaction: val })
                          if (val.tx_status === 'failed' || val.tx_status === 'abort_by_post_condition' || val.tx_status === 'abort_by_response'){
                            this.setState({status:val.tx_status})
                            this.confirmSign()
                          }
                          if (val.tx_status === 'success'){
                            this.setState({displayButtonRed: false})
                          }
                      })
                  }else{
                    this.confirmSign()
                  }
                }else{
                  this.confirmSign()
                }
              }
            }
         }
      }
    }
    if (pagina === 2){
      if (this.state.txId !== undefined){
        if (this.state.txId !== null && this.state.txId !== '' ) {
          getTransactionRegister(this.state.networkUrlX, this.state.txId)
            .then(val => {
                this.setState({ getTransaction: val })
                if (val.tx_status === 'success'){
                  if (this.state.mock === true){
                    if (this.state.mockError === false){
                      this.setState({successJson: true})
                      this.setState({errorJson: null})
                      let resultadoJson1Y =
                      {
                        "data": {
                          "success": true,
                          "error": null,
                          "code": null,
                          "data": {
                            "requisitionId": "105a10a2-624d-4883-9998-111a96eb5371",
                            "url": "https://uat.firmaautografa.com/requisitions/fadPackage/105a10a2-624d-4883-9998-111a96eb5371",
                            "key": "jGfrIYc1F0G3z1ws",
                            "vector": "ltfJQzAQHO5Nl6bO",
                            "ticket": "45ede09827e2c1dd5fb705aa20318dbf53d3ec3536b8731698d0b0b9dddff1b10bf2639e6a54e387e0a1234a08406d8f",
                            "allTickets": {
                              "1": "45ede09827e2c1dd5fb705aa20318dbf53d3ec3536b8731698d0b0b9dddff1b10bf2639e6a54e387e0a1234a08406d8f"
                            }
                          }
                        },
                        "status": 200,
                        "statusText": "OK",
                        "headers": {},
                        "config": {
                          "transformRequest": {},
                          "transformResponse": {},
                          "timeout": 0,
                          "xsrfCookieName": "XSRF-TOKEN",
                          "xsrfHeaderName": "X-XSRF-TOKEN",
                          "maxContentLength": -1,
                          "headers": {
                            "Accept": "application/json, text/plain, */*"
                          },
                          "method": "get",
                          "url": "https://domains.paradigma.global/fad_na_at/requisition/Josep Fontirroig R.&&&jfontirroig.xck.app&&&did:web:jfontirroig.xck.app&&&SP31C1RGYVJVWNYWJNRVSZQA2XN9HDEA08FB0G03S&&&jfontirroig@gmail.com&&&+56981955972&&&es&&&eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAtMjU2In0.Z4POvDkxfDvtLNfc-1GMiLsuD_KpM-hB7UF5gOjiJEg_GfRNdoMahvETwrLK4B8jPixx8gutEucSUIVtejuqgAEBl47KdIk7WHqtUtJ0QPMn4pCtCaWb6hyeFgSLPnADrhOr08LIFCYEsAHxH4apAEvliEGov7b448-RlVOxDfsuziKGYwVKhGjB5Lq3177FpGKBTqjtpUfunKkfTaXIWu0aQnMalc59Yf5A-AOstq1TJgVXsUsDIwxi9RmzVsO2r7MUbH90jsoeRVDhlWKacH81PRRdqWzesrFUnZSwrLGTHBAynH0Dxg6hXmMo8cPXpkLLU8LSZUh2qZdm0wTAWw.2KvpvieNgeuE9F5c.HBq4PuoUt52iiOUw0blk5tLQbhNUihNvtUOLNvN9-EvybhovkTJjOIl3EngnMRJTVsfIYFo83kDxvfEhvdL8118WnZp4Ao8RxHNk1Mfe6mqRdebLyP9OsO8chGBjfmNW-JG8KY8rhdZMXkWkzuEowkDv2dnv_ooA7WHEC9A_uRxgQrSJKb1hIdLwTUTSAZ3-msf3U6QlNqT7Y1a7X2aI33wrq8XnoxMZfv_eRH-J-v_GX3NNhPQinQsECUIfOUe1gA41yOrmmdjg1GB6XHKxByJX3igwA58rIqzG6L7NWuBiWP2fw7pmeRpOauqcFjGqAlKH4BvQ8Q9VGC1-10f0UmRFcXqrv4GA9d0SmKqFC9FTQdIo_-Js6Nw-MWNRlRI7gJX6ZYmA6IkjYCCnxZ49yya1QQwB28RPqLN8qr24ydUz_Z_CSmiaZFKOKpO5Mg69oq8tcyx3P_UKocBnbjyRJSSZ1P80nL7vWVbNM4N07jk0VCzPTrSoLUpa1pXIWejHJCNBcs2EBdLfwo84WSdaHtJ7h-anZnWlBkntTnzD8Zv6dXE6-9UH_HynyONzStBD9BjG0LTa0E3EirjPucpCYUzrXBneEyBfWONRE4jgcLAP-bvcGugsrgTXeMnRAxQSP_LrXPRS8Lzgqn9y_qeyYKS0d1EZ280PoOKKDOm-fP-KwmhlCg6s6XTZ3qZ5tju3yKN5a0LnXdZHpONVVvtHm16ptKp_-hJZDysw5B_B2b_36GRPqulB7yQpWH_76NnRgmuloVrJJmLaZjGd_QCnONu_i00mTR9GvNBVBmLULVO6qYbyULUVSNy5hsAy8QO_yydBvnqpURV07PM30P8aCiPlSeIKsD8J1qA.PyvXLyyT3dCXuv0ybw9bpA"
                        },
                        "request": {}
                      }
                      let resultadoJson2Y = resultadoJson1Y.data
                      let resultadoJson3Y = resultadoJson2Y.data
                      this.setState({requisitionId: resultadoJson3Y.requisitionId})
                      this.setState({requisitionUrl: resultadoJson3Y.url})
                      this.setState({requisitionKey: resultadoJson3Y.key})
                      this.setState({requisitionVector: resultadoJson3Y.vector})
                      this.setState({requisitionTicket: resultadoJson3Y.ticket})
                      this.setState({requisitionDiasRestantes: 3, requisitionDiasRestantes2: 60, themeRequisitionDiasRestantes: 'warning'})
                    }else{
                      this.setState({successJson: false})
                      this.setState({errorJson: "El nombre del firmante es inválido. Solo puede contener letras, numeros, espacios, guiones medios, guiones bajos, puntos y comas ... "})
                    }
                  }else{
                    if (this.state.requisitionId === undefined || this.state.requisitionId === null || this.state.requisitionId === '' ){
                      let phoneX = this.props.smsPrefix + this.props.SMS
                      let dibwebX = 'did:web:' + this.props.username
                      fadNaAtLogin()
                         .then(resultadoX => {
                           let resultadoJson1 = JSON.parse(resultadoX)
                           let resultadoJson2 = resultadoJson1.data
                           let resultadoJson3 = resultadoJson2.data

                           fadNaAtRequisition(this.props.profileName,this.props.username,dibwebX,this.props.STX,this.props.EMAIL,phoneX,this.props.language,resultadoJson3)
                              .then(resultado2X => {
                                console.log('respuesta_api_fad_requisition')
                                console.log(resultado2X)
                                this.setState({respuestaRequisition: resultado2X})
                                let resultadoJson1Y = JSON.parse(resultado2X)
                                let resultadoJson2Y = resultadoJson1Y.data
                                let resultadoJson3Y = resultadoJson2Y.data

                                let successJson = resultadoJson2Y.success
                                let errorJson = resultadoJson2Y.error;
                                this.setState({successJson: resultadoJson2Y.success})
                                if (successJson ===true){
                                  this.setState({requisitionId: resultadoJson3Y.requisitionId})
                                  this.setState({requisitionUrl: resultadoJson3Y.url})
                                  this.setState({requisitionKey: resultadoJson3Y.key})
                                  this.setState({requisitionVector: resultadoJson3Y.vector})
                                  this.setState({requisitionTicket: resultadoJson3Y.ticket})
                                  this.setState({requisitionDiasRestantes: 5, requisitionDiasRestantes2: 100, themeRequisitionDiasRestantes: 'success'})

                                  this.putAttributionData(this.state.country,this.state.txId,this.state.status, resultadoJson3Y)
                                }else{
                                  this.setState({errorJson: resultadoJson2Y.error})
                                }
                              })

                         })
                    }
                  }
                  this.setState({displayButtonRed: false,displayInputData:false,displayPayVerification:false,displayIdVerification:true,displayViewPdf:false,displaySummary:false})
                }
            })
        }
      }
    }
    if (pagina === 3){this.setState({displayButtonRed: false,displayInputData:false,displayPayVerification:false,displayIdVerification:false,displayViewPdf:true,displaySummary:false})}
    if (pagina === 4){this.setState({displayButtonRed: false,displayInputData:false,displayPayVerification:false,displayIdVerification:false,displayViewPdf:false,displaySummary:true})}
  }

  handleBack = (e,pagina) => {
    e.preventDefault();
    this.setState({displayButtonRed: false, displayPresentation: false})
    if (pagina === 2){this.setState({displayInputData:true,displayPayVerification:false,displayIdVerification:false,displayViewPdf:false,displaySummary:false})}
    if (pagina === 3){this.setState({displayInputData:false,displayPayVerification:true,displayIdVerification:false,displayViewPdf:false,displaySummary:false})}
    if (pagina === 4){this.setState({displayInputData:false,displayPayVerification:false,displayIdVerification:true,displayViewPdf:false,displaySummary:false})}
    if (pagina === 5){this.setState({displayInputData:false,displayPayVerification:false,displayIdVerification:false,displayViewPdf:true,displaySummary:false})}

  }

  getAttributionData = () => {
     const {userSession} = this.state
     const storage = new Storage({ userSession });
     const options = { decrypt: false, verify: false }
     this.c01Country.current.value = ''
     let jsonBlockstack4 = []
     this.setState({countryDisabled: false})
     return new Promise ((resolve, reject) =>{
       getFileDB(this.state.username, `AttributionData.json`)
        .then((fileContents) => {
          if(fileContents) {
            const jsonBlockstack1 = JSON.parse(fileContents)
            const jsonBlockstack2 = jsonBlockstack1.data
            let jsonBlockstack5 = '[]'
            if (jsonBlockstack2 !== null){
               const jsonBlockstack3 = jsonBlockstack2.dataobject
               const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
               if (jsonBlockstack4.substring(0,1) === '"') {
                  jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
               } else {
                  jsonBlockstack5 = jsonBlockstack4
               }
            }
            let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
            jsonBlockstack4 = JSON.parse(jsonBlockstack5)

            this.c01Country.current.value = jsonBlockstack4.country
            this.setState({country: jsonBlockstack4.country, txId: jsonBlockstack4.txId, status: jsonBlockstack4.status})
            if (jsonBlockstack4.status === 'success'  || jsonBlockstack4.status === 'pending'){
              this.setState({countryDisabled: true})
            }
            if (this.props.SMS === undefined || this.props.SMS === null || this.props.SMS.trim() === ''){reject()}
            if (this.props.EMAIL === undefined || this.props.EMAIL === null || this.props.EMAIL.trim() === ''){reject()}
            if (this.props.username === undefined || this.props.username === null || this.props.username.trim() === ''){reject()}

            if (jsonBlockstack4.requisitionId !== undefined && jsonBlockstack4.requisitionId !== null && jsonBlockstack4.requisitionId !== ''){this.setState({requisitionId: jsonBlockstack4.requisitionId, successJson: true})}else{this.setState({successJson: jsonBlockstack4.successFad})}
            if (jsonBlockstack4.requisitionUrl !== undefined && jsonBlockstack4.requisitionUrl !== null && jsonBlockstack4.requisitionUrl !== ''){this.setState({requisitionUrl: jsonBlockstack4.requisitionUrl})}
            if (jsonBlockstack4.requisitionKey !== undefined && jsonBlockstack4.requisitionKey !== null && jsonBlockstack4.requisitionKey !== ''){this.setState({requisitionKey: jsonBlockstack4.requisitionKey})}
            if (jsonBlockstack4.requisitionVector !== undefined && jsonBlockstack4.requisitionVector !== null && jsonBlockstack4.requisitionVector !== ''){this.setState({requisitionVector: jsonBlockstack4.requisitionVector})}
            if (jsonBlockstack4.requisitionTicket !== undefined && jsonBlockstack4.requisitionTicket !== null && jsonBlockstack4.requisitionTicket !== ''){this.setState({requisitionTicket: jsonBlockstack4.requisitionTicket})}
            let diff_in_days = 0
            if (jsonBlockstack4.requisitionDate === undefined){
              this.setState({requisitionDiasRestantes: 1, requisitionDiasRestantes2: 20, themeRequisitionDiasRestantes: 'danger'})
            }else{
              var newFecha2 = jsonBlockstack4.requisitionDate
              const [first, second, third] = newFecha2.split('/')
              const day = first.split('/');
              const month = second.split('/');
              const year = third.split('/');
              const newFechaOrigen = new Date(year, month - 1, day);

              const hoy = new Date()
              const dia = hoy.getDate()
              const mes = hoy.getMonth()
              const anio= hoy.getFullYear()
              const newFechaHoy = new Date(anio, mes, dia)

              var day_as_milliseconds = 86400000;
              var diff_in_millisenconds = newFechaHoy - newFechaOrigen;
              diff_in_days = diff_in_millisenconds / day_as_milliseconds;

              var diasRestantes = 5 - diff_in_days
              var porcentaje = 20 * diasRestantes

              let themeRequisitionDiasRestantes = 'success'
              if (diasRestantes == 3 || diasRestantes === 4){
                 themeRequisitionDiasRestantes = 'warning'
              }
              if (diasRestantes == 1 || diasRestantes === 2){
                 themeRequisitionDiasRestantes = 'danger'
              }
              diff_in_days = diasRestantes
              this.setState({requisitionDiasRestantes: diasRestantes, requisitionDiasRestantes2: porcentaje, themeRequisitionDiasRestantes})

            }

            if (diff_in_days === 0 || diff_in_days < 0){
               this.putAttributionReset(jsonBlockstack4.country)
            }

            resolve();
          } else {
            reject();
          }
        })
         .catch(error => {
             console.log(error)
             reject();
        });
     });
  }

  getPdf = () => {
     const {userSession} = this.state
     const storage = new Storage({ userSession });
     const options = { decrypt: false, verify: false }
     let jsonBlockstack4 = {}
     return new Promise ((resolve, reject) =>{
       getFileDB(this.state.username, `fad_pdf.json`)
        .then((fileContents) => {
          if(fileContents) {
            const jsonBlockstack1 = JSON.parse(fileContents)
            const jsonBlockstack2 = jsonBlockstack1.data
            let jsonBlockstack5 = '[]'
            if (jsonBlockstack2 !== null){
               const jsonBlockstack3 = jsonBlockstack2.dataobject
               const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
               if (jsonBlockstack4.substring(0,1) === '"') {
                  jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
               } else {
                  jsonBlockstack5 = jsonBlockstack4
               }
            }
            let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
            this.setState({pdfX: jsonBlockstack6.file})
            resolve();
          } else {
            reject();
          }
        })
         .catch(error => {
             console.log(error)
             reject();
        });
     });
  }

  getXml = () => {
    return new Promise ((resolve, reject) =>{

       this.setState({xmlX:
          `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
          <requisition>
              <contractName>Contrato</contractName>
              <acceptanceLegend>Yo ${this.props.name} con Id descentralizado ${this.props.username} acepto la firma autógrafa digital, hoy ${new Date()}.</acceptanceLegend>
              <validity>5</validity>
              <idDocument>5501-0001</idDocument>
              <contractType>Contrato</contractType>
              <certificate>
                  <page>1</page>
                  <positionX1>8.48066</positionX1>
                  <positionX2>88.48066</positionX2>
                  <positionY1>78.4089</positionY1>
                  <positionY2>90.53880000000001</positionY2>
              </certificate>
              <signers>
                  <signerName>${this.props.name}</signerName>
                  <mail>${this.props.EMAIL}</mail>
                  <phone>${this.props.SMS}</phone>
                  <authenticationType>Código de Seguridad</authenticationType>
                  <authenticationData>1234</authenticationData>
                  <order>1</order>
                  <signatures>
                      <centerX>68.3702</centerX>
                      <centerY>34.4741</centerY>
                      <page>1</page>
                      <positionX1>56.5608</positionX1>
                      <positionX2>80.1796</positionX2>
                      <positionY1>31.6991</positionY1>
                      <positionY2>37.2491</positionY2>
                      <signerType>Firmante</signerType>
                      <optional>false</optional>
                  </signatures>
                      <inePictureFront>true</inePictureFront>
                      <inePictureBack>true</inePictureBack>
                      <compareIneSelfie>true</compareIneSelfie>
                      <compareSelfieProof>true</compareSelfieProof>
              </signers>
              <acceptanceVideoNotRequired>false</acceptanceVideoNotRequired>
              <signOnWeb>false</signOnWeb>
          </requisition>`
        })
       resolve();
    });
  }


  putAttributionData = (countryX,txIdX,statusX,requisitionX) =>{
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }

      let isocode = 'es', dateFormatX = "dd/MM/yyyy"
      const optionsDate = {year: "numeric",month: "2-digit",day: "numeric"};
      const newFecha = new Date()
      const newFecha2 =  newFecha.toLocaleString(isocode,optionsDate)

      const jsonBlockstack =`{"country":"${countryX}", "txId":"${txIdX}", "status":"${statusX}", "requisitionId":"${requisitionX.requisitionId}", "requisitionUrl":"${requisitionX.url}", "requisitionKey":"${requisitionX.key}", "requisitionVector":"${requisitionX.vector}", "requisitionTicket":"${requisitionX.ticket}", "successFad":true, "requisitionDate":"${newFecha2}", "requisitionLanguage":"${this.props.language}"}`;

      putFileDB(this.state.username, `AttributionData.json`, 'Crosscheck_Profile', JSON.stringify(jsonBlockstack), JSON.stringify(options))
       .then(() => {
         const jsonBlockstack4 = JSON.parse(jsonBlockstack)
         if (this.state.displayInputData === true){
            this.c01Country.current.value = jsonBlockstack4.country
         }
         this.setState({country: jsonBlockstack4.country, txId: jsonBlockstack4.txId, status: jsonBlockstack4.status})
         this.setState({displayButtonRed: false})
         this.setState({displayHabilitaBoton: true})
       })
  }

  putAttributionReset = (countryX) =>{
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      const jsonBlockstack =`{"country":"${countryX}"}`;

      putFileDB(this.state.username, `AttributionData.json`, 'Crosscheck_Profile', JSON.stringify(jsonBlockstack), JSON.stringify(options))
       .then(() => {
         this.setState({txId: '', status: ''})
       })
  }

  confirmSign = () => {
    let stacksNetX = ''
    let serverStacksX = ''
    let networkUrlX = ''
    let contractNameX = ''
    let contractAddressX = ''
    let contractAddressX2 = ''
    let addressKeyX = ''

    let stacksAddressSender = ''

    let networkX = ''
    let contractNameX_XCK = ''
    let contractAddressX_XCK = ''
    let contractAddressX2_XCK = ''
    let contractNameX_MXM = ''
    let contractAddressX_MXM = ''
    let contractAddressX2_MXM = ''
    let contractNameX_MIA = ''
    let contractAddressX_MIA = ''
    let contractAddressX2_MIA = ''
    let contractNameX_NYC = ''
    let contractAddressX_NYC = ''
    let contractAddressX2_NYC = ''
    let contractNameX_FRIE = ''
    let contractAddressX_FRIE = ''
    let contractAddressX2_FRIE = ''

    const privateKey = makeRandomPrivKey();
    this.setState({privateKey})

    const { userSession } = this.state
    const user = userSession.loadUserData()
    this.setState({ user })
    if (!localStorage["serverStacks"]) {
      serverStacksX = 'MainNet'
    }else{
      serverStacksX = localStorage.getItem('serverStacks')
    }
    if (serverStacksX === 'MainNet'){
       networkUrlX = MAINNET_STACKS_API_URL
       contractNameX = SMART_CONTRACT_NAME_MAINNET_STX
       contractAddressX = SMART_CONTRACT_ADDRESS_MAINNET_STX
       contractAddressX2 = SMART_CONTRACT_ADDRESS_MAINNET_STX
       contractNameX_XCK = SMART_CONTRACT_NAME_MAINNET_XCK
       contractAddressX_XCK = SMART_CONTRACT_ADDRESS_MAINNET_XCK
       contractAddressX2_XCK = SMART_CONTRACT_ADDRESS_MAINNET_XCK
       contractNameX_MXM = SMART_CONTRACT_NAME_MAINNET_MXM
       contractAddressX_MXM = SMART_CONTRACT_ADDRESS_MAINNET_MXM
       contractAddressX2_MXM = SMART_CONTRACT_ADDRESS_MAINNET_MXM
       contractNameX_MIA = SMART_CONTRACT_NAME_MAINNET_MIA
       contractAddressX_MIA = SMART_CONTRACT_ADDRESS_MAINNET_MIA
       contractAddressX2_MIA = SMART_CONTRACT_ADDRESS_MAINNET_MIA
       contractNameX_NYC = SMART_CONTRACT_NAME_MAINNET_NYC
       contractAddressX_NYC = SMART_CONTRACT_ADDRESS_MAINNET_NYC
       contractAddressX2_NYC = SMART_CONTRACT_ADDRESS_MAINNET_NYC
       contractNameX_FRIE = SMART_CONTRACT_NAME_MAINNET_FRIE
       contractAddressX_FRIE = SMART_CONTRACT_ADDRESS_MAINNET_FRIE
       contractAddressX2_FRIE = SMART_CONTRACT_ADDRESS_MAINNET_FRIE
       addressKeyX =  user.profile.stxAddress.mainnet
       stacksAddressSender =  user.profile.stxAddress.mainnet
       stacksNetX =  user.profile.stxAddress.mainnet
       networkX = new StacksMainnet()
    }
    if (serverStacksX === 'TestNet'){
       networkUrlX = TESTNET_STACKS_API_URL
       contractNameX = SMART_CONTRACT_NAME_TESTNET_STX
       contractAddressX = SMART_CONTRACT_ADDRESS_TESTNET_STX
       contractAddressX2 = SMART_CONTRACT_ADDRESS_MAINNET_STX
       addressKeyX =  user.profile.stxAddress.testnet
    }
    const  {username} = this.state;

    this.setState({privateKey, contractAddressX, stacksAddressSender, networkUrlX, contractNameX,contractAddressX2, serverStacksX, usernameX: username})

    balanceOf(contractAddressX, stacksAddressSender, networkUrlX, contractNameX,contractAddressX2, serverStacksX, username)
        .then(val => this.setState({balanceCtaSTX: val}))

    getBalance(addressKeyX, contractNameX_XCK,contractAddressX_XCK, 1000000, serverStacksX)
        .then(val => this.setState({balanceCtaXCK: val}))

    getBalance(addressKeyX, contractNameX_MXM,contractAddressX_MXM, 1000000, serverStacksX)
        .then(val => this.setState({balanceCtaMXM: val}))

    getBalance(addressKeyX, contractNameX_MIA,contractAddressX_MIA, 1000000, serverStacksX)
        .then(val => this.setState({balanceCtaMIA: val}))

    getBalance(addressKeyX, contractNameX_NYC,contractAddressX_NYC, 1000000, serverStacksX)
        .then(val => this.setState({balanceCtaNYC: val}))

    getBalance(addressKeyX, contractNameX_FRIE,contractAddressX_FRIE, 1000000, serverStacksX)
        .then(val => this.setState({balanceCtaFRIE: val}))

    this.setState({ dialogOpenConfirmSign: false, dialogMessage: '', dialogTitle: 'Register Signatures', })

    this.priceCrypto(stacksAddressSender)
  }

  priceCrypto = (stacksAddressSender) => {

    //service_fee(stacksAddressSender, 'stx', 1000000).then(val => this.setState({priceSTX: val}));
    //service_fee(stacksAddressSender, 'xck', 1000000).then(val => this.setState({priceXCK: val}));
    //service_fee(stacksAddressSender, 'mxm', 1000000).then(val => this.setState({priceMXM: val}));
    //service_fee(stacksAddressSender, 'mia', 1000000).then(val => this.setState({priceMIA: val}));
    //service_fee(stacksAddressSender, 'nyc', 1000000).then(val => this.setState({priceNYC: val}));
    //service_fee(stacksAddressSender, 'frie', 1000000).then(val => this.setState({priceFRIE: val}));
    this.setState({priceMIA: 206})
    this.setState({priceNYC: 216})
    this.setState({priceFRIE: 0.5})
    this.setState({priceSTX: 0.01})
    this.setState({priceXCK: 5})
  }

  faltaDataConfirm(){
    if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: '------ Missing data ------',dialogTitle: 'Error: 1001',})}
    if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "------ Données manquantes ------",dialogTitle: 'Error: 1001',})}
    if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: '------ Faltan Datos ------',dialogTitle: 'Error: 1001',})}
    if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: '------ Dados ausentes ------',dialogTitle: 'Error: 1001',})}
    if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: '------ Saknar data ------',dialogTitle: 'Error: 1001',})}
    if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: '------ Ontbrekende gegevens ------',dialogTitle: 'Error: 1001',})}
    if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: '------ Потерянная информация ------',dialogTitle: 'Error: 1001',})}
    if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '------ 欠測データ ------',dialogTitle: 'Error: 1001',})}
    if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '------ 缺失數據 ------',dialogTitle: 'Error: 1001',})}
    if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: '------ Fehlende Daten ------',dialogTitle: 'Error: 1001',})}
    if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: '------ Dati mancanti ------',dialogTitle: 'Error: 1001',})}
  }

  faltaIdConfirm(){
    if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'It is not possible to have a public profile with the provisional decentralized domain',dialogTitle: 'Error: 1002',})}
    if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "Il n'est pas possible d'avoir un profil public avec le domaine décentralisé provisoire",dialogTitle: 'Error: 1002',})}
    if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'No es posible tener un perfil público con el dominio descentralizado provisorio',dialogTitle: 'Error: 1002',})}
    if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'Não é possível ter um perfil público com o domínio descentralizado provisório',dialogTitle: 'Error: 1002',})}
    if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Det är inte möjligt att ha en offentlig profil med den provisoriska decentraliserade domänen',dialogTitle: 'Error: 1002',})}
    if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'Het is niet mogelijk om een openbaar profiel te hebben met het voorlopige decentrale domein',dialogTitle: 'Error: 1002',})}
    if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Невозможно иметь общедоступный профиль с временным децентрализованным доменом.',dialogTitle: 'Error: 1002',})}
    if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '仮分散ドメインで公開プロフィールを持つことはできません',dialogTitle: 'Error: 1002',})}
    if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '臨時去中心化域不可能有公共檔案',dialogTitle: 'Error: 1002',})}
    if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Ein öffentliches Profil mit der vorläufigen dezentralen Domain ist nicht möglich',dialogTitle: 'Error: 1002',})}
    if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: 'Non è possibile avere un profilo pubblico con il dominio decentralizzato provvisorio',dialogTitle: 'Error: 1002',})}
  }

  render() {
    let cryptoPactClarityEthereumX = ''
    let cryptoPactClarityEthereum2X = ''
    let myUserConfig = this.props.myUserConfig
    let balanceX = 0
    let transactionX = this.state.amount
    let amountpaymentX=parseFloat(this.state.amount)
    let commissionX = 0
    let rateX = 0
    let saldoX = 0
    cryptoPactClarityEthereumX = 'Stacks'
    cryptoPactClarityEthereum2X = this.state.symbolCriptoCurrency
    if (cryptoPactClarityEthereum2X === 'STX'){cryptoPactClarityEthereumX = 'Stacks'; balanceX = parseFloat(this.state.balanceCtaSTX)}
    if (cryptoPactClarityEthereum2X === 'XCK'){cryptoPactClarityEthereumX = 'Crosscheck'; balanceX = parseFloat(this.state.balanceCtaXCK)}
    if (cryptoPactClarityEthereum2X === 'MXM'){cryptoPactClarityEthereumX = 'Mixmi'; balanceX = parseFloat(this.state.balanceCtaMXM)}
    if (cryptoPactClarityEthereum2X === 'MIA'){cryptoPactClarityEthereumX = 'Miami Coin'; balanceX = parseFloat(this.state.balanceCtaMIA)}
    if (cryptoPactClarityEthereum2X === 'NYC'){cryptoPactClarityEthereumX = 'New York Coin'; balanceX = parseFloat(this.state.balanceCtaNYC)}
    if (cryptoPactClarityEthereum2X === 'FRIE'){cryptoPactClarityEthereumX = 'Friedger Token'; balanceX = parseFloat(this.state.balanceCtaFRIE)}
    let disableTransferX = true
    if (balanceX > 0){
      if (amountpaymentX !== undefined && amountpaymentX !== null && amountpaymentX !== 0 && amountpaymentX !== ''){
        if (balanceX >= (amountpaymentX + commissionX + rateX)) {
            saldoX = balanceX - (amountpaymentX + commissionX + rateX)
            disableTransferX = false
        }else{
            saldoX = balanceX
        }
      }
    }

    let publishedX = false
    if (this.props.jsonStatusLifeCycle === 'Published' || this.state.published){
       publishedX = true
    }

    const tx_id = this.state.getTransaction.tx_id
    const url_tx = `https://explorer.stacks.co/txid/${tx_id}?chain=mainnet`
    const tx_status = this.state.getTransaction.tx_status
    const tx_type = this.state.getTransaction.tx_type
    const sender_address = this.state.getTransaction.sender_address
    const url_sender = `https://explorer.stacks.co/address/${sender_address}?chain=mainnet`
    const fee_rate = parseFloat(this.state.getTransaction.fee_rate) / 1000000
    const contract_call_function_name = 'transfer'
    const block_hash = this.state.getTransaction.block_hash
    const url_block_hash = `https://explorer.stacks.co/block/${block_hash}?chain=mainnet`
    const burn_block_time_iso = this.state.getTransaction.burn_block_time_iso

    const currencyPaymentX = currencyPayment.CcyNtry

    let comprobantePagoX = false
    let comprobantePago2X = false
    if (tx_status === 'success'  || tx_status === 'pending'){
       comprobantePagoX = true
       if (this.state.txId === '' || this.state.txId === undefined || this.state.txId === null){
         comprobantePagoX = false
       }
    }else {
      if (tx_id !== '' && tx_id !== undefined){
         comprobantePago2X = true
      }
    }

    let loaderX = true
    if (this.state.successJson === undefined){loaderX = true}
    if (this.state.successJson === true){loaderX = false}
    if (this.state.successJson === false){loaderX = false}

    return (
      <Container fluid className="main-content-container px-4">
        <Confirmacion
            handleDialog={this.handleDialog2}
            dialogMessage={this.state.dialogMessage}
            dialogOpen={this.state.dialogOpen}
            dialogTitle={this.state.dialogTitle}
        />
        { this.state.displayPresentation ?
          <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d' } : {}}>
            <ListGroup flush>
              <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                <Row form>
                  <Col md="1"></Col>
                  <Col md="10">
                    <Table size="sm" style={{fontSize:18}} className="text-center" borderless responsive hover striped>
                      <thead>
                          <tr>
                              <td style={{width: "100%", fontSize:18}}>Para activar la Atribución Nacional de tu cuenta distribuida sigue estos pasos</td>
                          </tr>
                      </thead>
                    </Table>
                    <Table size="sm" style={ this.props.colorDark ? {fontSize:12, color:'white'} : {fontSize:12}} className="text-center" borderless responsive hover striped>
                      <thead>
                          <tr>
                              <td style={{width: "20%"}}><img src="images/number2Green1.png" weight="50" height="50" alt=""/></td>
                              <td style={{width: "20%"}}><img src="images/number2Green2.png" weight="50" height="50" alt=""/></td>
                              <td style={{width: "20%"}}><img src="images/number2Green3.png" weight="50" height="50" alt=""/></td>
                              <td style={{width: "20%"}}><img src="images/number2Green4.png" weight="50" height="50" alt=""/></td>
                              <td style={{width: "20%"}}><img src="images/number2Green5.png" weight="50" height="50" alt=""/></td>
                          </tr>
                          <tr>
                              <td style={{width: "20%"}}></td>
                              <td style={{width: "20%"}}></td>
                              <td style={{width: "20%"}}></td>
                              <td style={{width: "20%"}}></td>
                              <td style={{width: "20%"}}></td>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td style={{width: "20%"}}><FormattedMessage id="profile.paso1" /></td>
                              <td style={{width: "20%"}}><FormattedMessage id="profile.paso2" /></td>
                              <td style={{width: "20%"}}><FormattedMessage id="profile.paso3" />...<FormattedMessage id="profile.paso4" /></td>
                              <td style={{width: "20%"}}><FormattedMessage id="profile.paso5" /></td>
                              <td style={{width: "20%"}}><FormattedMessage id="profile.paso6" /></td>
                          </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col md="1"></Col>
                </Row>
                <input type="hidden" ref={this.c01Country} />
              </ListGroupItem>
            </ListGroup>
            <ListGroup flush>
              <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                <Row>
                  <Col md="1"></Col>
                  <Col md="1"></Col>
                  <Col md="8"></Col>
                  <Col md="1">
                     <Button outline pill theme="success" onClick={e=>this.handleNext(e,0)}><FormattedMessage id="profile.netx" /></Button>
                  </Col>
                  <Col md="1"></Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>
              <Col md="4"></Col>
              <Col md="4">
                <div className="text-center" style={ this.props.colorDark ? {color: 'grey', fontSize:9} : {fontSize:9}}>
                  <span>Firma Autógrafa digital <a href={`https://na-at.com.mx/cloud-identity`} target="_blank" rel="noopener noreferrer"><img src="/images/fad2.png" style={{ width:50, height:27}} /></a> Powered by NA-AT Technologies</span>
                </div>
              </Col>
              <Col md="4"></Col>
            </Row>
            <Row>&nbsp;</Row>
          </Card>
        :
          <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d' } : {}}>
            <Row>&nbsp;</Row>
            <Row form>
              <Col md="1"></Col>
              <Col md="10">
                <Table size="sm" style={{fontSize:10}} className="text-center" borderless responsive hover striped>
                  <thead>
                      <tr>
                        { this.state.displayInputData ?
                          <td style={{width: "15%"}}><img src="images/number2Green1.png" weight="50" height="50" alt=""/></td>
                        :
                          <td style={{width: "15%"}}><img src="images/numberRed1.png" weight="50" height="50" alt=""/></td>
                        }
                        { this.state.displayPayVerification ?
                          <td style={{width: "15%"}}><img src="images/number2Green2.png" weight="50" height="50" alt=""/></td>
                        :
                          <td style={{width: "15%"}}><img src="images/numberRed2.png" weight="50" height="50" alt=""/></td>
                        }
                        { this.state.displayIdVerification ?
                          <td style={{width: "15%"}}><img src="images/number2Green3.png" weight="50" height="50" alt=""/></td>
                        :
                          <td style={{width: "15%"}}><img src="images/numberRed3.png" weight="50" height="50" alt=""/></td>
                        }
                        { this.state.displayViewPdf ?
                          <td style={{width: "15%"}}><img src="images/number2Green4.png" weight="50" height="50" alt=""/></td>
                        :
                          <td style={{width: "15%"}}><img src="images/numberRed4.png" weight="50" height="50" alt=""/></td>
                        }
                        { this.state.displaySummary ?
                          <td style={{width: "15%"}}><img src="images/number2Green5.png" weight="50" height="50" alt=""/></td>
                        :
                          <td style={{width: "15%"}}><img src="images/numberRed5.png" weight="50" height="50" alt=""/></td>
                        }
                      </tr>
                      <tr>
                          <td style={{width: "20%"}}><FormattedMessage id="profile.dataentry" /></td>
                          <td style={{width: "20%"}}><FormattedMessage id="profile.payattribution" /></td>
                          <td style={{width: "20%"}}><FormattedMessage id="profile.getid" /></td>
                          <td style={{width: "20%"}}><FormattedMessage id="profile.attributionstatus" /></td>
                          <td style={{width: "20%"}}><FormattedMessage id="profile.summary" /></td>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                      </tr>
                  </tbody>
                </Table>
              </Col>
              <Col md="1"></Col>
            </Row>
            <Row>&nbsp;</Row>
            {this.state.displayInputData ?
              <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d' } : {}}>
                <ListGroup flush>
                  <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                    <Row>
                      <Col md="1"></Col>
                      <Col>
                        <Row form>
                          <Form>
                            <Col md="12" className="form-group">
                              <label htmlFor="fePassword"><FormattedMessage id="aboutcontact.emailaddress" /></label>
                              <InputGroup className="mb-2">
                                <InputGroupAddon type="append">
                                  <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                        <img src="images/email.png" weight="30" height="30" alt="" title="SMS" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <FormInput
                                  id="feEmailNotifications"
                                  style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                                  disabled={true}
                                  placeholder={this.props.EMAIL}
                                />
                              </InputGroup>
                            </Col>
                          </Form>
                        </Row>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>

                <ListGroup flush>
                  <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                    <Row>
                      <Col md="1"></Col>
                      <Col>
                        <Row form>
                          <Form>
                            <Col md="12" className="form-group">
                              <label htmlFor="fePassword"><FormattedMessage id="aboutcontact.phone" /></label>
                              <InputGroup className="mb-2">
                                <InputGroupAddon type="append">
                                  <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                        <img src="images/smartphone.png" weight="30" height="30" alt="" title="SMS" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <InputGroupAddon type="append">
                                  <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                    {this.props.smsPrefix}
                                  </InputGroupText>
                                </InputGroupAddon>
                                <FormInput
                                  id="feSMS Notifications"
                                  style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                                  disabled={true}
                                  placeholder={this.props.SMS}
                                />
                              </InputGroup>
                            </Col>
                          </Form>
                        </Row>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>

                <ListGroup flush>
                  <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                    <Row>
                      <Col md="1"></Col>
                      <Col>
                        <Row form>
                          <Form>
                            <Col md="12" className="form-group">
                              <label htmlFor="fePassword"><FormattedMessage id="profile.countrynationality" /></label>
                              <InputGroup className="mb-2">
                                <InputGroupAddon type="append">
                                  <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                        <img src="images/countries.png" weight="30" height="30" alt="" title="SMS" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <InputGroupAddon type="append">
                                  <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                    <FormSelect
                                        id="prefix"
                                        style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                                        disabled={this.state.countryDisabled}
                                        innerRef={this.c01Country}
                                        onChange={e=>this.handleChangeCountry(e)}
                                        placeholder={this.state.country}
                                    >
                                        {Country.map((todo, i) => {
                                            if (todo.ENGLISH === this.state.country) {
                                              return (
                                                <option style={this.props.colorDark ? {color:'white'} : {color:'grey'}} key={i} value={todo.ENGLISH} selected >{`${todo.ENGLISH}`}</option>
                                              )
                                            }else{
                                              return (
                                                <option style={this.props.colorDark ? {color:'white'} : {color:'grey'}} key={i} value={todo.ENGLISH} >{`${todo.ENGLISH}`}</option>
                                              )
                                            }
                                        })}
                                    </FormSelect>
                                  </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                            </Col>

                          </Form>
                        </Row>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>

                <ListGroup flush>
                  <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                    <Row>
                      <Col md="1"></Col>
                      <Col>
                        <Row form>
                          <Form>
                            <Col md="12" className="form-group">
                              <label htmlFor="fePassword"><FormattedMessage id="profile.accepteddocuments" /></label>
                              <ul>
                                {AttributionDocuments.map((todo, i) => {
                                    if (todo.country === this.state.country) {
                                      return (
                                        <li style={{fontSize:10}}>{todo.document}</li>
                                      )
                                    }
                                })}
                              </ul>
                            </Col>
                          </Form>
                        </Row>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
                <ListGroup flush>
                  <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                    <Row>
                      <Col md="1"></Col>
                      <Col md="1"></Col>
                      <Col md="8"></Col>
                      <Col md="1">
                         <Button outline pill theme={this.state.displayButtonRed ? "danger" : "success" } onClick={e=>this.handleNext(e,1)}><FormattedMessage id="profile.netx" /></Button>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            : null }

            {this.state.displayPayVerification ?
              <>
                <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d' } : {}}>
                  {comprobantePagoX ?
                    <>
                      <ListGroup flush>
                        <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                          <Row>&nbsp;</Row>
                          <Row>
                            <Col>
                              <Form style={{fontSize:12}}>
                                <Row form>
                                  <Col md="2"></Col>
                                  <Col md="8">
                                    <Table size="sm" className="text-center" responsive hover striped bordered style={this.props.colorDark ? {color: 'orange' } : { color: 'black' }}>
                                        <thead>
                                          <tr style={this.props.colorDark ? { textAlign:"center", color: 'white', fontSize:12 } : { textAlign:"center", color: 'blue', fontSize:12 }}>
                                            <th style={{ width: "30%", textAlign:"left", fontSize:16 }}><FormattedMessage id="usergroup.concept" /></th>
                                            <th style={{ width: "70%", textAlign:"right", fontSize:9 }}><Button outline pill theme="secondary" onClick={e=>this.handleNext(e,1)}><FormattedMessage id="contract.refresh" /></Button></th>
                                          </tr>
                                      </thead>
                                        <tbody>
                                          <tr>
                                            <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Transaction Id:</td>
                                            <td style={{ width: "70%", textAlign:"right", fontSize:10 }}><a href={url_tx} target="_blank" rel="noopener noreferrer">{tx_id}</a></td>
                                          </tr>
                                          <tr>
                                            <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Status</td>
                                            <td style={{ width: "70%", textAlign:"right", fontSize:16 }}>{tx_status}</td>
                                          </tr>
                                          <tr>
                                            <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Type</td>
                                            <td style={{ width: "70%", textAlign:"right", fontSize:10 }}>{tx_type}</td>
                                          </tr>
                                          <tr>
                                            <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Function Name</td>
                                            <td style={{ width: "70%", textAlign:"right", fontSize:10 }}>{contract_call_function_name}</td>
                                          </tr>
                                          <tr>
                                            <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Sender Address</td>
                                            <td style={{ width: "70%", textAlign:"right", fontSize:10 }}><a href={url_sender} target="_blank" rel="noopener noreferrer">{sender_address}</a></td>
                                          </tr>
                                          <tr>
                                            <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Amount</td>
                                            <td style={{ width: "70%", textAlign:"right", fontSize:10 }}>{this.state.amount} STX</td>
                                          </tr>
                                          <tr>
                                            <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Fee Rate</td>
                                            <td style={{ width: "70%", textAlign:"right", fontSize:10 }}>{fee_rate} STX</td>
                                          </tr>
                                          <tr>
                                            <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Block Hash</td>
                                            <td style={{ width: "70%", textAlign:"right", fontSize:10 }}><a href={url_block_hash} target="_blank" rel="noopener noreferrer">{block_hash}</a></td>
                                          </tr>
                                          <tr>
                                            <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Block Time</td>
                                            <td style={{ width: "70%", textAlign:"right", fontSize:9 }}>{burn_block_time_iso}</td>
                                          </tr>
                                        </tbody>
                                    </Table>
                                  </Col>
                                  <Col md="2"></Col>
                                </Row>
                              </Form>
                            </Col>
                          </Row>

                        </ListGroupItem>
                      </ListGroup>
                    </>
                  :
                    <>
                      <ListGroup flush>
                        <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                          <Row>
                            <Col md="1"></Col>
                            <Col>
                              <Row form>
                                <Form>
                                  <Col md="12" className="form-group">
                                    <Card theme="warning" >
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td>
                                              &nbsp;&nbsp;<div className="fa fa-info"></div>
                                            </td>
                                            <td>
                                               &nbsp;&nbsp;<FormattedMessage id="profile.payattribution" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </Card>
                                    <Table size="sm" className="text-center" responsive hover striped bordered style={this.props.colorDark ? { color: 'white', fontSize:11 } : { color: 'black', fontSize:11 }}>
                                        <tbody>
                                          <tr>
                                            <td style={this.props.colorDark ? { width: "60%", textAlign:"left", color: 'orange', fontSize:13 } : { width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="payment.crytocurrency" /></td>
                                            <td style={this.props.colorDark ? { width: "40%", textAlign:"right", color: 'white', fontSize:13 } : { width: "40%", textAlign:"right", color: 'black', fontSize:13 }}>
                                              <FormSelect
                                                  id="cryptocurrency"
                                                  value={this.state.cryptoCurrencyX}
                                                  placeholder=""
                                                  disabled={false}
                                                  onChange={this.handleChangeCryptoCurrency}
                                              >
                                                  {currencyPaymentX.map((todo, i) => {
                                                      return (
                                                         <option style={this.props.colorDark ? {color:'grey'} : {color:'darkblue'}} key={i} value={`${todo.Ccy} - ${todo.CcyNm}`}>{`${todo.Ccy} - ${todo.CcyNm}`}</option>
                                                      )
                                                  })}
                                              </FormSelect>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td style={this.props.colorDark ? { width: "60%", textAlign:"left", color: 'orange', fontSize:13 } : { width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="pay.previousbalance" /></td>
                                            <td style={this.props.colorDark ? { width: "40%", textAlign:"right", color: 'white', fontSize:13 } : { width: "40%", textAlign:"right", color: 'black', fontSize:13 }}><NumberFormat value={balanceX} displayType={'text'} thousandSeparator={true} /> {cryptoPactClarityEthereum2X}</td>
                                          </tr>
                                          <tr>
                                            <td style={this.props.colorDark ? { width: "60%", textAlign:"left", color: 'orange', fontSize:13 } : { width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="pay.amounttransfer" /></td>
                                            <td style={this.props.colorDark ? { width: "40%", textAlign:"right", color: 'white', fontSize:13 } : { width: "40%", textAlign:"right", color: 'black', fontSize:13 }}>{transactionX} {cryptoPactClarityEthereum2X}</td>
                                          </tr>
                                        </tbody>
                                    </Table>
                                    <Button disabled={disableTransferX} onClick={e => this.handleDialog(e, this.state.dialogTitle, 'pay', cryptoPactClarityEthereum2X, saldoX)} theme={disableTransferX ? "danger" : "success"}>
                                        <FormattedMessage id="alert.pay" />
                                    </Button>
                                  </Col>
                                </Form>
                              </Row>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      </ListGroup>

                      {comprobantePago2X ?

                        <ListGroup flush>
                          <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                            <Row>&nbsp;</Row>
                            <Row>
                              <Col>
                                <Form style={{fontSize:12}}>
                                  <Row form>
                                    <Col md="2"></Col>
                                    <Col md="8">
                                      <Table size="sm" className="text-center" responsive hover striped bordered style={this.props.colorDark ? {color: 'orange' } : { color: 'black' }}>
                                          <thead>
                                              <tr style={this.props.colorDark ? { textAlign:"center", color: 'white', fontSize:12 } : { textAlign:"center", color: 'blue', fontSize:12 }}>
                                                <th><FormattedMessage id="usergroup.concept" /></th>
                                                <th></th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Transaction Id:</td>
                                              <td style={{ width: "70%", textAlign:"right", fontSize:10 }}><a href={url_tx} target="_blank" rel="noopener noreferrer">{tx_id}</a></td>
                                            </tr>
                                            <tr>
                                              <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Status</td>
                                              <td style={{ width: "70%", textAlign:"right", fontSize:10 }}>{tx_status}</td>
                                            </tr>
                                            <tr>
                                              <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Type</td>
                                              <td style={{ width: "70%", textAlign:"right", fontSize:10 }}>{tx_type}</td>
                                            </tr>
                                            <tr>
                                              <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Function Name</td>
                                              <td style={{ width: "70%", textAlign:"right", fontSize:10 }}>{contract_call_function_name}</td>
                                            </tr>
                                          </tbody>
                                      </Table>
                                    </Col>
                                    <Col md="2"></Col>
                                  </Row>
                                </Form>
                              </Col>
                            </Row>

                          </ListGroupItem>
                        </ListGroup>

                      : null }

                    </>
                  }
                  <ListGroup flush>
                    <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                      <Row>
                        <Col md="1"></Col>
                        <Col md="1">
                            <Button outline pill theme="warning" onClick={e=>this.handleBack(e,2)}><FormattedMessage id="profile.back" /></Button>
                        </Col>
                        <Col md="8"></Col>
                        <Col md="1">
                            <Button outline pill theme={this.state.displayButtonRed ? "danger" : "success" } onClick={e=>this.handleNext(e,2)}><FormattedMessage id="profile.netx" /></Button>
                        </Col>
                        <Col md="1"></Col>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                </Card>
              </>
            : null }

            {this.state.displayIdVerification ?
              <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d' } : {}}>
                {loaderX ?
                  <ListGroup flush>
                      <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                        <Row>
                          <Col md="5"></Col>
                          <Col md="2" className="form-group">
                            <InputGroup className="mb-2">
                              <ContractLoader colorDark={this.props.colorDark}/>
                            </InputGroup>
                          </Col>
                          <Col md="5"></Col>
                        </Row>
                      </ListGroupItem>
                  </ListGroup>
                :
                  <>
                    {this.state.successJson ?
                      <>
                          <ListGroup flush>
                            <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d', color:'white', fontSize:11} : {color:'grey', fontSize:11}}>
                              <Row form>
                                <Col md="1"></Col>
                                  <Col md="10" className="text-center">
                                     <FormattedMessage id="requisition.diasrestantes" />
                                </Col>
                                <Col md="1"></Col>
                              </Row>
                              <Row form>
                                <Col md="1"></Col>
                                  <Col md="10">
                                     <Progress theme={this.state.themeRequisitionDiasRestantes} value={`${this.state.requisitionDiasRestantes2}`}>{this.state.requisitionDiasRestantes}</Progress>
                                </Col>
                                <Col md="1"></Col>
                              </Row>
                            </ListGroupItem>
                          </ListGroup>
                          <ListGroup flush>
                            <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                              <Row form>
                                <Col md="1"></Col>
                                <Col md="10">
                                  <Card small className="text-uppercase" style={ this.props.colorDark ? { backgroundColor:'black' } : { backgroundColor:'#e9ecef' }}>
                                      <table className="text-center">
                                        <tbody>
                                          <tr>
                                            <td style={ this.props.colorDark ? {width: "95%", color: "grey"} : {width: "95%"}}>
                                               <FormattedMessage id="requisition.download" />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                  </Card>
                                </Col>
                                <Col md="1"></Col>
                              </Row>
                            </ListGroupItem>
                          </ListGroup>

                          <ListGroup flush>
                            <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                              <Row form>
                                <Col md="1"></Col>
                                <Col md="10">
                                    <table className="text-center">
                                      <tbody>
                                        <tr>
                                          <td style={ this.props.colorDark ? {width: "20%", color: "grey", textAlign:"center"} : {width: "20%", textAlign:"center"}}></td>
                                          <td style={ this.props.colorDark ? {width: "25%", color: "grey", textAlign:"center"} : {width: "25%", textAlign:"center"}}>
                                             <a href={`http://dev.na-at.com.mx/FAD_UAT/`} target="_blank" rel="noopener noreferrer"><img title={'Google Play'} src="images/google_play.png" weight="150" height="75" alt=""/></a>
                                          </td>
                                          <td style={ this.props.colorDark ? {width: "10%", color: "grey", textAlign:"center"} : {width: "10%", textAlign:"center"}}></td>
                                          <td style={ this.props.colorDark ? {width: "25%", color: "grey", textAlign:"center"} : {width: "25%", textAlign:"center"}}>
                                             <a href={`http://dev.na-at.com.mx/FAD_UAT/`} target="_blank" rel="noopener noreferrer"><img title={'App Store'} src="images/app_store.png" weight="150" height="75" alt=""/></a>
                                          </td>
                                          <td style={ this.props.colorDark ? {width: "20%", color: "grey", textAlign:"center"} : {width: "20%", textAlign:"center"}}></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                </Col>
                                <Col md="1"></Col>
                              </Row>
                            </ListGroupItem>
                          </ListGroup>

                          <ListGroup flush>
                            <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                              <Row form>
                                <Col md="1"></Col>
                                <Col md="10">
                                  <Card small className="text-uppercase" style={ this.props.colorDark ? { backgroundColor:'black' } : { backgroundColor:'#e9ecef' }}>
                                      <table className="text-center">
                                        <tbody>
                                          <tr>
                                            <td style={ this.props.colorDark ? {width: "95%", color: "grey"} : {width: "95%"}}>
                                               <FormattedMessage id="requisition.document" />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                  </Card>
                                </Col>
                                <Col md="1"></Col>
                              </Row>
                            </ListGroupItem>
                          </ListGroup>
                          <ListGroup flush>
                            <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                              <Row form>
                                <Col md="1"></Col>
                                <Col md="10">
                                    <table className="text-center">
                                      <tbody>
                                        <tr>
                                          <td style={ this.props.colorDark ? {width: "100%", color: "grey", verticalAlign: "middle"} : {width: "100%", verticalAlign: "middle"}}>
                                            <FormattedMessage id="requisition.qr" />
                                          </td>
                                        </tr>
                                        <tr>&nbsp;</tr>
                                        <tr>
                                          <td style={ this.props.colorDark ? {width: "100%", color: "grey", verticalAlign: "middle"} : {width: "100%", verticalAlign: "middle"}}>
                                             <QRCode value={this.state.requisitionTicket} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                              </Col>
                              <Col md="1"></Col>
                            </Row>
                          </ListGroupItem>
                        </ListGroup>

                        <ListGroup flush>
                          <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                            <Row>
                              <Col md="1"></Col>
                              <Col md="1">
                                  <Button outline pill theme="warning" onClick={e=>this.handleBack(e,3)}><FormattedMessage id="profile.back" /></Button>
                              </Col>
                              <Col md="8"></Col>
                              <Col md="1">
                                  <Button outline pill theme="success" onClick={e=>this.handleNext(e,3)}><FormattedMessage id="profile.netx" /></Button>
                              </Col>
                              <Col md="1"></Col>
                            </Row>
                          </ListGroupItem>
                        </ListGroup>
                      </>
                    :
                      <ListGroup flush>
                          <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                            <Row>
                              <Col md="1"></Col>
                              <Col md="10" className="form-group">
                                <label htmlFor="fePassword"><FormattedMessage id="requisition.error" /></label>
                                <InputGroup className="mb-2">
                                  <FormTextarea
                                    id="errorJson"
                                    style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'white'} : {}}
                                    disabled={true}
                                    placeholder={this.state.errorJson}
                                  />
                                </InputGroup>
                              </Col>
                              <Col md="1"></Col>
                            </Row>
                          </ListGroupItem>
                      </ListGroup>
                    }
                  </>
                }
              </Card>
            : null
            }

            {this.state.displayViewPdf ?
              <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d' } : {}}>
                <ListGroup flush>
                  <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                    <Row>
                      <Col md="1"></Col>
                      <Col md="1">
                          <Button outline pill theme="warning" onClick={e=>this.handleBack(e,4)}><FormattedMessage id="profile.back" /></Button>
                      </Col>
                      <Col md="8"></Col>
                      <Col md="1">
                          <Button outline pill theme="success" onClick={e=>this.handleNext(e,4)}><FormattedMessage id="profile.netx" /></Button>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            : null }

            {this.state.displaySummary ?
              <Card small className="mb-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d' } : {}}>
                <ListGroup flush>
                  <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                    <Row>
                      <Col md="1"></Col>
                      <Col md="1">
                         <Button outline pill theme="warning" onClick={e=>this.handleBack(e,5)}><FormattedMessage id="profile.back" /></Button>
                      </Col>
                      <Col md="8"></Col>
                      <Col md="1"></Col>
                      <Col md="1"></Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            : null }
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>
              <Col md="4"></Col>
              <Col md="4">
                <div className="text-center" style={ this.props.colorDark ? {color: 'grey', fontSize:9} : {fontSize:9}}>
                  <span>Firma Autógrafa digital <a href={`https://na-at.com.mx/cloud-identity`} target="_blank" rel="noopener noreferrer"><img src="/images/fad2.png" style={{ width:50, height:27}} /></a> Powered by NA-AT Technologies</span>
                </div>
              </Col>
              <Col md="4"></Col>
            </Row>
            <Row>&nbsp;</Row>
          </Card>
        }
      </Container>
    )
  }
};

export default withRouter(NationalIdentity);
