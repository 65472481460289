import React, { Component } from 'react';

import {Button, Card } from "shards-react";

//translate
import { FormattedMessage } from 'react-intl';

//base64
//import { Base64 } from 'js-base64';

//blob
//import { base64StringToBlob } from 'blob-util';

//PDF
import MyViewerPdf from './MyViewerPdf'

//Audio
import ReactAudioPlayer from 'react-audio-player'

//Video
//import { Player, ControlBar } from 'video-react';
//import DownloadButton from './DownloadButton';
import ReactPlayer from 'react-player';

class MyViewer extends Component {

  constructor(props) {
      super(props);
      this.exitProgram = this.exitProgram.bind(this);

      this.state = {
        dialogMessage: '',
        dialogOpenCancel: false,
        dialogTitle: '',
      }
  }

  UNSAFE_componentWillMount() {
    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English' })
    }else{
      this.setState({ language: languageX })
    }
  }

  exitProgram(){
     //window.location = '/'
     this.props.returnProps();
  }

  render() {

    const {fileAttachX, filedecodeAttachX, typeAttachX} = this.props
    const { typeAttachText, typeAttachImage, typeAttachPdf, typeAttachAudio, typeAttachVideo, scale } = this.props;

    return (
        <>
            {typeAttachPdf ?
              <div style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                 <MyViewerPdf filedecodeAttachX={filedecodeAttachX} scale={scale} origen={this.props.origen} />
              </div>
            : null }
            {typeAttachImage ?
              <div style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                <img src={`${filedecodeAttachX}`} style={{"width":"100%"}} />
              </div>
            : null }
            {typeAttachText ?
              <div style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                {`${filedecodeAttachX}`}
              </div>
            : null }
            {typeAttachAudio ?
              <div style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                <ReactAudioPlayer
                  src={filedecodeAttachX}
                  autoPlay
                  controls
                />
              </div>
            : null }
            {typeAttachVideo ?
              <div>
                {/*<Player src={filedecodeAttachX} autoPlay>
                  <ControlBar autoHide={false}>
                    <DownloadButton order={7} />
                  </ControlBar>
                </Player>*/}

                <ReactPlayer
                  url={filedecodeAttachX}
                  className='react-player'
                  playing
                  width='100%'
                  height='100%'
                />
              </div>
            : null }
            <br></br>
        </>
    );
  }
}

export default MyViewer;
