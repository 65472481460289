import React from "react";
import { Container, Row, Col } from "shards-react";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Person } from '@stacks/profile';
import { Storage } from '@stacks/storage'

import axios from 'axios';

import TituloPagina from "../components/common/TituloPagina";
import MyFinancesLeft from "../components/myfinances/MyFinancesLeft";
import MyFinancesRight from "../components/myfinances/MyFinancesRight";

import {username_new} from '../actions/userActions'
import {getFileDB} from '../actions/mongoDBActions'


class ContenedorMyFinances extends React.Component {
  constructor() {
    super();

    this.state = {
      userSession: new UserSession(),
      identityAddress: undefined,
      decentralizedID: undefined,
      name: undefined,
      avatar:'',
      avatar2: 'images/avatar.png',
      username: undefined,
      user: {},
      url: 'https://gaia.blockstack.org/hub/',
      url2: 'https://core.blockstack.org/v1/users/',
      url3: 'https://core.blockstack.org/v1/names/',
      error: undefined,
      exist: false,
      languaje: 'English',
      pubKey: '',
      zonefile_hash: '',
      status: '',
      jsonProfileUser: '',
      stacksNetX: '',
      serverStacksX: '',
      SMS: ' ',
      EMAIL: ' ',
      STX: ' ',
      KDA: ' ',
      ETH: ' ',
      colorDark: false,
      person: {
         name() {
           return 'Anonymous';
         },
         avatarUrl() {
           return this.state.avatar2;
         },
      },
    };
  }

  UNSAFE_componentWillMount() {
    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English' })
    }else{
      this.setState({ language: languageX })
    }

    let colorThemeX = 'Blue'
    if (localStorage["colorTheme"]) {
      colorThemeX = localStorage.getItem('colorTheme')
      if (colorThemeX === 'Dark') {
         this.setState({colorDark: true})
      }
    }

  }

  componentDidMount() {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }

    let stacksNetX = ''
    let serverStacksX = ''
    if (userSession.isUserSignedIn()) {
      const user = userSession.loadUserData()
      if (!localStorage["serverStacks"]) {
        serverStacksX = 'MainNet'
      }else{
        serverStacksX = localStorage.getItem('serverStacks')
      }
      if (serverStacksX === 'MainNet'){
	       stacksNetX =  user.profile.stxAddress.mainnet
      }
      if (serverStacksX === 'TestNet'){
	       stacksNetX =  user.profile.stxAddress.testnet
      }

      this.setState({ user })
      this.setState({ stacksNetX })
      this.setState({ serverStacksX })
    }

    const {username} = userSession.loadUserData();
    if (username === '' || username === undefined || username === null){
      username_new(stacksNetX).then(val => this.setState({username: val})) ;
    }else{
      this.setState({username })
    }

    const {identityAddress} = userSession.loadUserData();
    const {decentralizedID} = userSession.loadUserData();
    const {name} = userSession.loadUserData().profile;

    this.setState({ identityAddress })
    this.setState({ decentralizedID })
    this.setState({ name })

    let person;
    if(username) {
       person = new Person(username.profile);
    } else {
       person = ""
    }
    this.setState({person})

    const url2x = `${this.state.url2}${username}`
    axios.get(url2x)
      .then(result => {
         const jsonBlockstack1 = JSON.stringify(result.data)
         const var_a_remp = `{"${username}":`
         const jsonBlockstack2 = jsonBlockstack1.replace(var_a_remp,"'");
         const jsonBlockstack3 = jsonBlockstack2 + "'";
         const jsonBlockstack4 = JSON.parse(jsonBlockstack3.substring(1,jsonBlockstack3.length - 2))
         const public_key = jsonBlockstack4.public_key
         this.setState({ pubKey: public_key })
         this.setState({ name: jsonBlockstack4.profile.name})
         let avatar = ''
         const palabra = username
         const ArrayIdValue = palabra.split('.blockstack')
         if (ArrayIdValue.length===2){
            avatar = `${this.state.url}${identityAddress}//avatar-0`
         }else{
           avatar = `${this.state.url}${identityAddress}/0//avatar-0`
         }
         this.setState({ avatar: avatar })
      })
      .catch(error => {
        //console.log('error')
      });

    const url3x = `${this.state.url3}${username}`
    axios
        .get(url3x)
        .then(respuesta => {
           const {zonefile_hash} = respuesta.data;
           this.setState({ zonefile_hash })
           const {status} = respuesta.data;
           this.setState({ status });
        })
        .catch(err => {
            return null;
        })

    getFileDB(this.state.username, `myUserConfig.json`)
       .then((fileContents) => {
         if(fileContents) {
           const jsonBlockstack1 = JSON.parse(fileContents)
           const jsonBlockstack2 = jsonBlockstack1.data
           let jsonBlockstack5 = '[]'
           if (jsonBlockstack2 !== null){
              const jsonBlockstack3 = jsonBlockstack2.dataobject
              const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
              if (jsonBlockstack4.substring(0,1) === '"') {
                 jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
              } else {
                 jsonBlockstack5 = jsonBlockstack4
              }
           }
           let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
           this.setState({ SMS: jsonBlockstack6.smsNotifications });
           this.setState({ EMAIL: jsonBlockstack6.emailNotifications });
           this.setState({ STX: jsonBlockstack6.STX });
           this.setState({ KDA: jsonBlockstack6.KDA });
           this.setState({ ETH: jsonBlockstack6.ETH });
           localStorage.removeItem('sms2')
           localStorage.removeItem('email2')
           localStorage.setItem('sms2',jsonBlockstack6.smsNotifications)
           localStorage.setItem('email2',jsonBlockstack6.emailNotifications)
         } else {
           //console.log("No Data");
         }
       })
        .catch(error => {
          //console.log(error);
        });
  }

  render() {
    let titleX = ''
    let subtitleX = ''
    if (this.state.language === 'English'){titleX = "My Finances"}
    if (this.state.language === 'French'){titleX = "Mes finances"}
    if (this.state.language === 'Spanish'){titleX = "Mis Finanzas"}
    if (this.state.language === 'Portuguese'){titleX = "Minhas Finanças"}
    if (this.state.language === 'Swedish'){titleX = "Min Ekonomi"}
    if (this.state.language === 'Netherlands'){titleX = "Mijn Financiën"}
    if (this.state.language === 'Russian'){titleX = "Мои финансы"}
    if (this.state.language === 'Japanese'){titleX = "私の財政"}
    if (this.state.language === 'Chinese'){titleX = "我的財務"}
    if (this.state.language === 'German'){titleX = "Meine Finanzen"}
    if (this.state.language === 'Italian'){titleX = "Le mie Finanze"}

    return (
        <Container fluid className="main-content-container px-4" style={ this.state.colorDark ? { backgroundColor:'#060a0f'} : {}}>
          <Row noGutters className="page-header py-4">
            <TituloPagina title={titleX} subtitle={subtitleX} md="12" className="ml-sm-auto mr-sm-auto" />
          </Row>
          <Row>
            <Col lg="5">
              <MyFinancesLeft username={this.state.username} url={this.state.url} person={this.state.person}  avatar={this.state.avatar} name={this.state.name} stacksNetX={this.state.stacksNetX} serverStacksX={this.state.serverStacksX} />
            </Col>
            <Col lg="7">
              <MyFinancesRight username={this.state.username} userSession={this.state.userSession} publicKey={this.state.pubKey} identityAddress={this.state.identityAddress} decentralizedID={this.state.decentralizedID} zonefile_hash={this.state.zonefile_hash} SMS={this.state.SMS} EMAIL={this.state.EMAIL} STX={this.state.STX} KDA={this.state.KDA} ETH={this.state.ETH}  />
            </Col>
          </Row>
        </Container>
    )
  }
};

export default ContenedorMyFinances;
