import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Button,
} from "shards-react";

// blockstack
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//translate
import { FormattedMessage } from 'react-intl';

import {getFileDB} from '../../actions/mongoDBActions'


class MyFinancesLeft extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userSession: new UserSession(),
      languaje: 'English',
      profileName: '',
      colorDark: false,
    };
  }

  UNSAFE_componentWillMount() {
    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English' })
    }else{
      this.setState({ language: languageX })
    }

    let colorThemeX = 'Blue'
    if (localStorage["colorTheme"]) {
      colorThemeX = localStorage.getItem('colorTheme')
      if (colorThemeX === 'Dark') {
         this.setState({colorDark: true})
      }
    }
  }

  componentDidMount() {
    if (this.props.name === '' || this.props.name === null || this.props.name === undefined){
      this.readName()
    }
  }

  readName = () => {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    getFileDB(this.props.username, `myUserConfig.json`)
       .then((fileContents) => {
         if(fileContents) {
           const jsonBlockstack1 = JSON.parse(fileContents)
           const jsonBlockstack2 = jsonBlockstack1.data
           let jsonBlockstack5 = '[]'
           if (jsonBlockstack2 !== null){
              const jsonBlockstack3 = jsonBlockstack2.dataobject
              const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
              if (jsonBlockstack4.substring(0,1) === '"') {
                 jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
              } else {
                 jsonBlockstack5 = jsonBlockstack4
              }
           }
           let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
           this.setState({profileName: jsonBlockstack6.profileName})
           this.setState({ jsonProfileUser: jsonBlockstack5 });
         }
       })
      .catch(error => {
       });
  }

  render() {
    const username = this.props.username
    const stacksNetX = this.props.stacksNetX
    const serverStacksX = this.props.serverStacksX
    let stacksNetX2 = 'mainnet'
    if (serverStacksX === 'TestNet'){stacksNetX2 = 'testnet'}

    let person = ""
    if (this.props.name !== '' && this.props.name !== null && this.props.name !== undefined){
       person = this.props.name
    }else{
       if (this.state.profileName !== '' && this.state.profileName!== null && this.state.profileName !== undefined) {
         person = this.state.profileName
       }else{
         person = this.props.name
       }
    }
    if (person==='' || person === null){
       person = 'Nameless'
       if (this.state.language === 'English'){person = "Nameless"}
       if (this.state.language === 'French'){person = "Sans Nom"}
       if (this.state.language === 'Spanish'){person = "Sin Nombre"}
       if (this.state.language === 'Portuguese'){person = "Sem Nome"}
       if (this.state.language === 'Swedish'){person = "Inget Namn"}
       if (this.state.language === 'Netherlands'){person = "Geen Naam"}
       if (this.state.language === 'Russian'){person = "Нет имени"}
       if (this.state.language === 'Japanese'){person = "名前なし"}
       if (this.state.language === 'Chinese'){person = "没有名字"}
       if (this.state.language === 'German'){person = "Kein Name"}
       if (this.state.language === 'Italian'){person = "Nessun Nome"}
    }

    return (
      <Card small className="mb-4 pt-3" style={ this.state.colorDark ? { backgroundColor:'#0b151d'} : {}}>
        <CardHeader className="border-bottom text-center" style={ this.state.colorDark ? { backgroundColor:'#0b151d'} : {}}>
          <span style={{fontSize:20}} className="text-muted d-block mb-2">{person}</span>
          <span style={{fontSize:12}} className="text-muted d-block mb-2">{username}</span>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem className="p-4 text-center" style={ this.state.colorDark ? { backgroundColor:'#0b151d'} : {}}>
            <Row>
              <Col>
                <Form style={ this.state.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                  <Row form>
                    <Col md="12" className="form-group text-center">
                    </Col>
                  </Row>
                  <Row form>
                    {/* serverStacksX */}
                    <Col md="12" className="form-group text-center" style={ this.state.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                      <label htmlFor="serverStacksX" style={ this.state.colorDark ? { color:'#505459'} : {}}><FormattedMessage id="useraccountdetail.serverStacks" /></label>
                      <FormInput
                        id="serverStacksX"
                        style={ this.state.colorDark ? { backgroundColor:'#111f2c', color: 'white', textAlign: 'center'} : {textAlign: 'center'}}
                        value={serverStacksX}
                        disabled={true}
                        onChange={() => {}}
                      />
                    </Col>
                  </Row>
                  <Row form>&nbsp;</Row>
                  <Row form>&nbsp;</Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    )
  }
};

export default MyFinancesLeft;
