import React from "react";
import {
  Container,
  Row,
  Col,
  Card
} from "shards-react";

import TituloPagina from "../components/common/TituloPagina";

import NewBlog from "../pages/blogs/new";

const ContenedorBlogViewAllPost = () => (
  <div>
    <Container fluid className="px-0">
      <Card theme="info" style={{ color: "white" }}>
        <table>
          <tbody>
            <tr>
              <td>
                &nbsp;&nbsp;<div className="fa fa-info"></div>
              </td>
              <td>
                &nbsp;&nbsp;Create a new Blog
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
    </Container>
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-2">
        <TituloPagina
          sm="4"
          title="Blog"
          subtitle="New"
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="12" className="mb-4">
          {/* Tracking Contract */}
          <Card small>
            <NewBlog />
          </Card>
        </Col>

      </Row>
    </Container>
  </div>
);

export default ContenedorBlogViewAllPost;
