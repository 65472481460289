//React
import React, { Component } from 'react';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Container, Button, Card } from "shards-react";
import { Table } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";
import { Divider, Typography, TextField, Grid, Dialog,
        DialogContent, DialogActions, DialogTitle, DialogContentText,
        Button as ButtonMaterialUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';

//UUID
import uuid from 'uuid';

//loader
import ContractLoader from "../contracts/ContractLoader";

import TituloPagina2 from "../common/TituloPagina2";

// blockstack
//import { UserSession,  } from 'blockstack';
import { UserSession, AppConfig } from '@stacks/auth';
import { makeRandomPrivKey } from '@stacks/transactions';
import { Storage } from '@stacks/storage'

//translate
import { FormattedMessage } from 'react-intl';

//Axios
import axios from 'axios';

//Configuracion
import {token} from '../../config.js'

//Number Format
import NumberFormat from 'react-number-format';

//Email
import emailjs from 'emailjs-com'

//Configuración
import { SMART_CONTRACT_ADDRESS_MAINNET_STX,
         SMART_CONTRACT_NAME_MAINNET_STX,
         SMART_CONTRACT_ADDRESS_TESTNET_STX,
         SMART_CONTRACT_NAME_TESTNET_STX,
         MAINNET_STACKS_API_URL,
         TESTNET_STACKS_API_URL } from '../../config.js'

//Clarity
import {transfer, balanceOf} from "../../clarity/clarityfunctions"

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'


//base64
import { Base64 } from 'js-base64';

function ConfirmacionSign(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                     <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                     <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionUnSign(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionWarning(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'accept')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionConfirmSign(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Container fluid className="px-0">
                    <Card theme="warning" >
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              &nbsp;&nbsp;<div className="fa fa-info"></div>
                            </td>
                            <td>
                               &nbsp;&nbsp;<FormattedMessage id="sign.message" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                </Container>
                <Table size="sm" className="text-left" responsive hover striped bordered style={{ color: 'blue', fontSize:11 }}>
                    <tbody>
                      <tr>
                        <th style={{ width: "40%", textAlign:"left", color: 'blue', fontSize:11 }}><FormattedMessage id="useraction.network" /></th>
                        <th style={{ width: "60%", textAlign:"right", color: 'black', fontSize:13 }}>{props.addressX}</th>
                      </tr>
                    </tbody>
                </Table>
                <Table size="sm" className="text-center" responsive hover striped bordered style={{ color: 'black', fontSize:11 }}>
                    <thead>
                      <tr style={{ textAlign:"center", color: 'blue', fontSize:15 }}>
                        <th><FormattedMessage id="usergroup.concept" /></th>
                        <th>{props.cryptoPactClarityEthereumX}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="pay.previousbalance" /></td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}><NumberFormat value={props.balanceX} displayType={'text'} thousandSeparator={true} /> {props.cryptoPactClarityEthereum2X}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="pay.amounttransfer" /></td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}>{props.transactionX} {props.cryptoPactClarityEthereum2X}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="pay.commission" /></td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}>{props.commissionX} {props.cryptoPactClarityEthereum2X}</td>
                      </tr>
                    </tbody>
                </Table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', props.cryptoPactClarityEthereum2X, props.saldoX)} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button disabled={props.disableTransferX} onClick={() => props.handleDialog(props.dialogTitle, 'pay', props.cryptoPactClarityEthereum2X, props.saldoX)} theme="success">
                    <FormattedMessage id="alert.pay" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionPaymentForm(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class ContractSign extends Component {

    constructor(props) {
        super(props);

        this.signContract = this.signContract.bind(this);
        this.unsignContract = this.unsignContract.bind(this);
        this.signUserGroup = this.signUserGroup.bind(this);
        this.signUserGroupWrite = this.signUserGroupWrite.bind(this);
        this.sendMailSigned = this.sendMailSigned.bind(this);
        this.sendMailUnSigned = this.sendMailUnSigned.bind(this);
        this.signConfirm = this.signConfirm.bind(this);
        this.unsignConfirm = this.unsignConfirm.bind(this);
        this.exitProgram = this.exitProgram.bind(this);
        this.confirmSign = this.confirmSign.bind(this);
        this.actualizarConfirmSign = this.actualizarConfirmSign.bind(this);
        this.actualizarConfirmSign2 = this.actualizarConfirmSign2.bind(this);
        this.actualizarConfirmSign3 = this.actualizarConfirmSign3.bind(this);
        this.actualizarConfirmSign4 = this.actualizarConfirmSign4.bind(this);
        this.actualizarConfirmSign5 = this.actualizarConfirmSign5.bind(this);
        this.getDetailPaymentFormsLegacy = this.getDetailPaymentFormsLegacy.bind(this);
        this.validatePaymentForm1 = this.validatePaymentForm1.bind(this);
        this.validatePaymentForm2 = this.validatePaymentForm2.bind(this);

        this.state = {
          userSession: new UserSession(new AppConfig()),
          username: '',
          user: {},
          person: '',
          exist: true,
          pubKey: '',
          publicKey: '',
          opcionContractIoTDevice: '',
          displayContractIoTDevice: false,
          jsonBlockstack: [],
          jsonBlockstackY: [],
          jsonBlockstackZ: [],
          jsonBlockstack2: [],
          jsonBlockstackZ50c: [],
          typeContractIoTDevice: '',
          idx: '',
          url: 'https://gaia.blockstack.org/hub/',
          url2: 'https://api.hiro.so/v1/names/',
          url3: 'https://api.hiro.so/v1/names/',
          identityAddress: undefined,
          decentralizedID: undefined,
          language: 'English',
          nameBlockstack: '',
          displayLoader: false,
          goConfirmSign: false,
          dialogOpen: false,
          dialogOpenPaymentForm: false,
          dialogOpenSign: false,
          dialogOpenUnsign: false,
          dialogOpenConfirmSign: false,
          dialogMessage: '',
          dialogTitle: '',
          balanceCta: 0,
          resultTransfer: '',
          stacksAddress: '',
          privateKey: '',
          contractAddressX: '',
          addressKeyX: '',
          networkUrlX: '',
          contractNameX: '',
          contractAddressX2: '',
          serverStacksX: '',
          usernameX: '',
          jsonPaymentForm: '',
          buttonErrorPaymentForm: false,
          errorPaymentForm: true,
          nameX: '',
          avatarX: '',
          messageSignX: '',
          messageUnSignX: '',
        }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }

      const {identityAddress} = userSession.loadUserData();
      const {decentralizedID} = userSession.loadUserData();

      this.setState({ identityAddress, decentralizedID, networkUrlX })

      let {name} = userSession.loadUserData().profile;
      if (name === '' || name === undefined || name === null){
        name = this.props.myUserConfig.profileName
      }
      this.setState({ nameBlockstack: name })

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      if (languageX === 'English'){this.setState({ messageSignX:`${'Document Signed by'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Signature Canceled by'} ${this.props.username}`})}
      if (languageX === 'French'){this.setState({ messageSignX:`${'Document signé par'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Signature annulée par'} ${this.props.username}`})}
      if (languageX === 'Spanish'){this.setState({ messageSignX:`${'Documento Firmado por'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Firma Anulada por'} ${this.props.username}`})}
      if (languageX === 'Portuguese'){this.setState({ messageSignX:`${'Documento assinado por'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Assinatura cancelada por'} ${this.props.username}`})}
      if (languageX === 'Swedish'){this.setState({ messageSignX:`${'Dokument Signerat av'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Signatur annullerad av'} ${this.props.username}`})}
      if (languageX === 'Netherlands'){this.setState({ messageSignX:`${'Document ondertekend door'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Handtekening geannuleerd door'} ${this.props.username}`})}
      if (languageX === 'Russian'){this.setState({ messageSignX:`${'Документ подписан'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Подпись отменена'} ${this.props.username}`})}
      if (languageX === 'Japanese'){this.setState({ messageSignX:`${'署名された文書'} ${this.props.username}`});this.setState({ messageUnSignX:`${'署名はによってキャンセルされました'} ${this.props.username}`})}
      if (languageX === 'Chinese'){this.setState({ messageSignX:`${'文件簽署人'} ${this.props.username}`});this.setState({ messageUnSignX:`${'簽名取消者'} ${this.props.username}`})}
      if (languageX === 'German'){this.setState({ messageSignX:`${'Dokument unterzeichnet von'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Unterschrift storniert von'} ${this.props.username}`})}
      if (languageX === 'Italian'){this.setState({ messageSignX:`${'Documento firmato da'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Firma annullata da'} ${this.props.username}`})}

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => {
          this.setState({username: val})
          Promise.all(
               [this.listChat2(this.props.typeContract,this.props.numberContract,val),this.getDetail(val)]
          ).then(
              (resolve) =>{},
              (reject) =>{}
          )
        })
      }else{
        this.setState({ username })
        Promise.all(
             [this.listChat2(this.props.typeContract,this.props.numberContract,username),this.getDetail(username)]
        ).then(
            (resolve) =>{},
            (reject) =>{}
        )
      }
    }

    UNSAFE_componentWillReceiveProps() {
      this.completaNameAndAvatar(this.props.jsonBlockstackY)
    }

    componentDidMount() {
      Promise.all(
           [this.listChat2(this.props.typeContract,this.props.numberContract,this.props.username),this.getDetail(this.props.username)]
      ).then(
          (resolve) =>{},
          (reject) =>{}
      )
    }

    getDetail = (username) => {
        if (this.props.typeContract !== 'simulate') {
          if (this.props.selectedSignaturePayment === 'SignatureAndPayment') {
            Promise.all([this.getDetailPaymentFormsLegacy(username)])
              .then(
                (resolve) =>{
                  let total_percent = 0
                  this.state.jsonPaymentForm.map((todo, i) => {
                      total_percent = total_percent + parseFloat(todo.percent)
                  })
                  if (total_percent < 100){
                     this.validatePaymentForm1()
                  }else{
                    this.setState({errorPaymentForm: false})
                  }
                },
                (reject) =>{
                    this.validatePaymentForm2()
                }
             )
          }else{
             this.setState({errorPaymentForm: false})
          }
        }
    }

    completaNameAndAvatar = async (jsonUserGroup) => {
      const salida = []
      const resultado = await Promise.all(jsonUserGroup.map( async (todo) => {
         let userX = this.props.username
         todo.designsignature = ''
         if (todo.id !== this.props.username){
            userX = todo.id
         }

         return await Promise.all([this.readRemoteNameAvatar(userX)])
           .then(
             (resolve) =>{
               salida.push({id:todo.id, role:todo.role, signaturedatetime:todo.signaturedatetime, signature:todo.signature, identityAddress: todo.identityAddress, name:resolve[0].nameX, avatar:resolve[0].avatarX})
             },
             (reject)  =>{
               salida.push({id:todo.id, role:todo.role, signaturedatetime:todo.signaturedatetime, signature:todo.signature, identityAddress: todo.identityAddress, name:'', avatar:''})
             }
           )
      }))
      this.setState({jsonBlockstackY: salida},()=>{})
    }

    readRemoteNameAvatar = (userX) => {
      return new Promise ((resolve, reject) =>{
        getFileDB(userX,`myUserConfig.json`)
           .then((fileContents) => {
             if (fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               resolve({nameX: jsonBlockstack6.profileName, avatarX: jsonBlockstack6.profileAvatar})
             }else{
               reject()
             }
           })
          .catch(error => {
            console.log(error)
            reject()
        });
      });
    }

    handleDialog = (type, action) => {
      if (type === 'Confirm: 3001') {
         if (action === 'confirm') {
              this.setState({ dialogOpenSign: false });
              this.signContract()
         }else{
           if (action === 'cancel') {
            this.setState({ dialogOpenSign: false });
           }
         }
      }
      if (type === 'Confirm: 3002') {
         if (action === 'confirm') {
              this.setState({ dialogOpenUnSign: false });
              this.unsignContract()
         }else{
           if (action === 'cancel') {
            this.setState({ dialogOpenUnSign: false });
           }
         }
      }
      if (type === 'Error: 1001') {
         if (action === 'cancel') {
            this.setState({ dialogOpenPaymentForm: false, buttonErrorPaymentForm: true });
            //this.exitProgram()
         }
      }
      if (type === 'Error: 1002') {
         if (action === 'cancel') {
            this.setState({ dialogOpenPaymentForm: false, buttonErrorPaymentForm: true });
            //this.exitProgram()
         }
      }
    }

    handleDialog2 = (type, action, cryptoX, saldoX) => {
      if (type === 'Register Signatures') {
        if (action === 'pay') {
          this.setState({ dialogOpenConfirmSign: false });
          this.actualizarConfirmSign(cryptoX, saldoX)
        }
        if (action === 'cancel') {
          this.setState({ dialogOpenConfirmSign: false, displayConfirmSignImpide: false, });
        }
      }
    }

    actualizarConfirmSign(cryptoX, saldoX){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      let myUserConfig = this.props.myUserConfig
      let balanceSTX2 = myUserConfig.balanceSTX
      let balanceKDA2 = myUserConfig.balanceKDA
      let balanceETH2 = myUserConfig.balanceETH
      if (cryptoX==='STX'){balanceSTX2 = saldoX}
      if (cryptoX==='KDA'){balanceKDA2 = saldoX}
      if (cryptoX==='ETH'){balanceETH2 = saldoX}
      let SMSNotifications = myUserConfig.smsNotifications
      let EmailNotifications = myUserConfig.emailNotifications
      let cryptoSTX = myUserConfig.STX
      let cryptoKDA = myUserConfig.KDA
      let cryptoETH = myUserConfig.ETH
      let cryptoBTC = myUserConfig.BTC
      let profileName = myUserConfig.profileName
      let profileAvatar = myUserConfig.profileAvatar
      const jsonBlockstack =`{"emailNotifications":"${EmailNotifications}","smsNotifications":"${SMSNotifications}","STX":"${cryptoSTX}","BTC":"${cryptoBTC}","KDA":"${cryptoKDA}","ETH":"${cryptoETH}","balanceSTX":"${balanceSTX2}","balanceKDA":"${balanceKDA2}","balanceETH":"${balanceETH2}","profileName":"${profileName}","profileAvatar":"${profileAvatar}","facebook":"${myUserConfig.facebook}","twitter":"${myUserConfig.twitter}","youtube":"${myUserConfig.youtube}","instagram":"${myUserConfig.instagram}","linkedin":"${myUserConfig.linkedin}","pinterest":"${myUserConfig.pinterest}","aboutme":"${myUserConfig.aboutme}","smsScope":"${myUserConfig.smsScope}"","emailScope":"${myUserConfig.emailScope}","smsCountry":"${myUserConfig.smsCountry}","smsPrefix":"${myUserConfig.smsPrefix}","stxScope":"${myUserConfig.stxScope}","btcScope":"${myUserConfig.btcScope}","webSite":"${myUserConfig.webSite}","applicationsScope":"${myUserConfig.applicationsScope}"}`;
      putFileDB(this.props.username, `myUserConfig.json`, 'Crosscheck_Sign', JSON.stringify(jsonBlockstack), JSON.stringify(options))
       .then(() => {
         this.actualizarConfirmSign2()
       })
    }

    actualizarConfirmSign2(){
      let jsonBlockstack4 = []
      getFileDB(this.props.username,`contractlist.json`)
         .then((fileContents) => {
           if(fileContents) {
             this.setState({existListContract: true})
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             jsonBlockstack6.map((todo, i) => {
                if (todo.typeContract === this.props.typeContract && todo.configurationContractNumber === this.props.numberContract) {
                   todo.confirmSign = true
                   this.actualizarConfirmSign3(jsonBlockstack6)
                }
             })
           }
         })
        .catch(error => {
        });
    }

    actualizarConfirmSign3(jsonBlockstack4){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      putFileDB(this.props.username, `contractlist.json`, 'Crosscheck_Sign', JSON.stringify(jsonBlockstack4), JSON.stringify(options))
        .then(() => {
           this.actualizarConfirmSign4()
        })
        .catch(error => {});
    }

    actualizarConfirmSign4(){
      let jsonBlockstack4 = []
      getFileDB(this.props.username,`${this.props.typeContract}_${this.props.numberContract}.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack4 = ''
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             jsonBlockstack4 = JSON.parse(jsonBlockstack5)
             const jsonBlockstack =`{"idParadigma":"${jsonBlockstack4.idParadigma}","idLogisticContract":"${jsonBlockstack4.idLogisticContract}","idContainer":"${jsonBlockstack4.idContainer}","idBillOfLading":"${jsonBlockstack4.idBillOfLading}","idTypeBillOfLading":"${jsonBlockstack4.idTypeBillOfLading}","dateContract":"${jsonBlockstack4.dateContract}","shipper":"${jsonBlockstack4.shipper}","consignee":"${jsonBlockstack4.consignee}","notifyParty":"${jsonBlockstack4.notifyParty}","carrier":"${jsonBlockstack4.carrier}","placeOfAceptance":"${jsonBlockstack4.placeOfAceptance}","portOfLading":"${jsonBlockstack4.portOfLading}","vessel":"${jsonBlockstack4.vessel}","portOfDischarge":"${jsonBlockstack4.portOfDischarge}","placeOfDelivery":"${jsonBlockstack4.placeOfDelivery}","finalDestination":"${jsonBlockstack4.finalDestination}","amount":"${jsonBlockstack4.amount}","signature":"${jsonBlockstack4.signature}","statusLifeCycle":"Published","contractPolicy":"${jsonBlockstack4.contractPolicy}","statusActive":"${jsonBlockstack4.statusActive}","statusBlockchain":"Blockchain","usernameInsert":"${jsonBlockstack4.usernameInsert}","dateInsert":"${jsonBlockstack4.dateInsert}","usernameUpdate":"${jsonBlockstack4.usernameUpdate}","dateUpdate":"${jsonBlockstack4.dateUpdate}","usernameDelete":"${jsonBlockstack4.usernameDelete}","dateDelete":"${jsonBlockstack4.dateDelete}","typeContract":"${jsonBlockstack4.typeContract}","configurationContractNumber":"${jsonBlockstack4.configurationContractNumber}","startDate":"${jsonBlockstack4.startDate}","endDate":"${jsonBlockstack4.endDate}","transportOriginDestination":"${jsonBlockstack4.transportOriginDestination}","conditionsIntegrityMaterials":"${jsonBlockstack4.conditionsIntegrityMaterials}","useAssets":"${jsonBlockstack4.useAssets}","subject":"${jsonBlockstack4.subject}","currency":"${jsonBlockstack4.currency}"}`

             this.actualizarConfirmSign5(jsonBlockstack)
           }else{
             this.exitProgram()
           }
         })
        .catch(error => {
            this.exitProgram()
        });
    }

    actualizarConfirmSign5(jsonBlockstack){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      putFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}.json`, 'Crosscheck_Sign', JSON.stringify(jsonBlockstack), JSON.stringify(options))
          .then(() => {
           this.exitProgram()
        })
        .catch(error => {});
    }

    exitProgram(){
       this.props.returnProps('returnWithFilter',this.props.typeContract,this.props.numberContract);
    }

    signContract(){
       const { userSession } = this.state
       const {username} = this.state;
       const url2x = `${this.state.url2}${username}`
       this.setState({ displayLoader: true})
       axios.get(url2x)
         .then(result => {
           this.setState({ person: '',
                           exist: true })
            const jsonBlockstack1 = JSON.stringify(result.data)
            const var_a_remp = `{"${username}":`
            const jsonBlockstack2 = jsonBlockstack1.replace(var_a_remp,"'");
            const jsonBlockstack3 = jsonBlockstack2 + "'";
            const jsonBlockstack4 = JSON.parse(jsonBlockstack3.substring(1,jsonBlockstack3.length - 2))
            const public_key = jsonBlockstack4.public_key
            this.setState({ pubKey: public_key })
            let e = ''
            this.signUserGroup(this.props.typeContract,this.props.numberContract,username,public_key,'sign')
         })
         .catch(error => {
           this.signContractNames(username)
         });
    }

    signContractNames = (userX) =>{
      return new Promise ((resolve1, reject1) =>{
        if (userX === undefined || userX === null){reject1()}
        var nameLookupURL = this.state.networkUrlX + "/v1/names/" + userX;
        axios.get(nameLookupURL)
          .then(result => {
            const {zonefile} = result.data
            const zonefile1 = zonefile.split('"')
            const zonefile3 = zonefile1[1]
            const zonefile4 = zonefile3.replace(/\\/g,'')
            axios.get(zonefile4)
               .then(result => {
                  const jsonBlockstack1 = JSON.stringify(result.data[0].decodedToken.payload.claim.appsMeta)
                  let jsonBlockstack2 = jsonBlockstack1
                  let jsonBlockstack4 = {}
                  const var_a_remp = `"${window.location.origin}":`
                  if (window.location.origin === 'http://localhost:3000'){jsonBlockstack2 = jsonBlockstack1.replace(var_a_remp,`"localhost":`);}
                  if (window.location.origin === 'https://xck.app'){jsonBlockstack2 = jsonBlockstack1.replace(var_a_remp,`"xckapp":`);}
                  const jsonBlockstack3 = JSON.parse(jsonBlockstack2)
                  if (window.location.origin === 'http://localhost:3000'){jsonBlockstack4 = jsonBlockstack3.localhost}
                  if (window.location.origin === 'https://xck.app'){jsonBlockstack4 = jsonBlockstack3.xckapp}
                  const {publicKey} = jsonBlockstack4
                  this.signUserGroup(this.props.typeContract,this.props.numberContract,userX,publicKey,'sign')
                  resolve1()
               })
             .catch(error => {
               console.log(error)
               this.setState({ error, exist: false })
               const public_key = this.state.publicKey
               this.setState({ pubKey: public_key })
               this.signUserGroup(this.props.typeContract,this.props.numberContract,userX,public_key,'sign')
               reject1()
             });
          })
          .catch(error => {
            console.log(error)
            this.setState({ error, exist: false })
            const public_key = uuid()
            this.setState({ pubKey: public_key })
            this.signUserGroup(this.props.typeContract,this.props.numberContract,userX,public_key,'sign')
            reject1()
          });
      });
    }

    unsignContract(){
       this.setState({ displayLoader: true})
       const { userSession } = this.state
       const {username} = this.state
       let e = ''
       this.signUserGroup(this.props.typeContract,this.props.numberContract,username,"",'unsign')
    }

    signUserGroup = (TypeContract,ConfigurationContractNumber,UsernameInsert,Signature,modo) => {
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = { decrypt: false, verify: false }
       getFileDB(this.props.username,`${TypeContract}_${ConfigurationContractNumber}_usergroup.json`)
         .then((fileContents) => {
           if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack4 = ''
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              jsonBlockstack4 = JSON.parse(jsonBlockstack5)
              let cambiaSignature = false
              jsonBlockstack4.map ((todo, key) => {
                 if (todo.id === UsernameInsert && todo.signature !== Signature){
                    todo.name=this.state.nameBlockstack
                    todo.signature=Signature
                    if (Signature === '' || modo === 'unsign'){
                      todo.signaturedatetime= ''
                    }else{
                      todo.signaturedatetime= new Date()
                    }
                    cambiaSignature = true
                 }
              })
              if (cambiaSignature===true){
                let jsonBlockstack5 = JSON.stringify(jsonBlockstack4)
                this.signUserGroupWrite(TypeContract,ConfigurationContractNumber,jsonBlockstack5,modo)
              }
           } else {
              const dateTimeX =  new Date()
              const jsonBlockstack4=`[{"id":"${this.props.username}","name":"${this.state.nameBlockstack}","role":"${this.props.whatUserRole}","active":"true","signature":"${this.state.pubKey}","signaturedatetime":"${dateTimeX}","identityAddress":"${this.state.identityAddress}","statusInvite":true,"email":"","publicKey":"${this.state.pubKey}"}]`
              let jsonBlockstack5 = JSON.stringify(jsonBlockstack4)
              this.signUserGroupWrite(TypeContract,ConfigurationContractNumber,jsonBlockstack5,modo)
           }
         })
        .catch(error => {
              console.log(error)
              const dateTimeX =  new Date()
              const jsonBlockstack4=`[{"id":"${this.props.username}","name":"${this.state.nameBlockstack}","role":"${this.props.whatUserRole}","active":"true","signature":"${this.state.pubKey}","signaturedatetime":"${dateTimeX}","identityAddress":"${this.state.identityAddress}","statusInvite":true,"email":"","publicKey":"${this.state.pubKey}"}]`
              let jsonBlockstack5 = JSON.stringify(jsonBlockstack4)
              this.signUserGroupWrite(TypeContract,ConfigurationContractNumber,jsonBlockstack5,modo)
         });
    }

    signUserGroupWrite = (TypeContract,ConfigurationContractNumber,jsonBlockstack5,modo) => {
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
       putFileDB(this.props.username, `${TypeContract}_${ConfigurationContractNumber}_usergroup.json`, 'Crosscheck_Sign', jsonBlockstack5, JSON.stringify(options))
          .then(() => {
             if (modo === 'sign'){
                this.sendChat(this.state.messageSignX,this.state.jsonBlockstackZ50c)
                if (this.props.whatUserRole !== 'Creator'){
                    this.sendMailSigned(this.props.username)
                }else{
                    this.exitProgram()
                }
             }
             if (modo === 'unsign'){
                this.sendChat(this.state.messageUnSignX,this.state.jsonBlockstackZ50c)
                if (this.props.whatUserRole !== 'Creator'){
                   this.sendMailUnSigned(this.props.username)
                }else{
                   this.exitProgram()
                }
             }
          })
        .catch(error => {
        });
    }

    sendMailSigned(usernameX){
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       let options = ''
       if (usernameX === this.props.userOrigin){
           options = { decrypt: false, verify: false }
       }else{
           options = { username: this.props.userOrigin, app: window.location.origin, decrypt: false, verify: false }
       }
       let user2X = usernameX
       if (usernameX === '') { user2X = this.props.username}
       getFileDB(user2X,`myUserConfig.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack3 = ''
              let jsonBlockstack4 = ''
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 jsonBlockstack3 = jsonBlockstack2.dataobject
                 jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              jsonBlockstack3 = JSON.parse(jsonBlockstack5)
              const subject = 'A participant has signed your contract using CrossCheck'
              const senderName = ''

              emailjs.send("service_9dv38as", "template_7fs3bhn", {receiverUserName: this.props.userOrigin,address: jsonBlockstack3.emailNotifications,signerUserName: usernameX,typeContract: this.props.typeContract,numberContract: this.props.numberContract,role: this.props.whatUserRole, subject, senderName}, "user_djYXBdBTeG1Km5qtyqkDl")
                  .then(response => {
                     console.log('SUCCESS!', response.status, response.text)
                     this.exitProgram()
                  }, error => {
                     console.log('FAILED...', error)
                     this.exitProgram()
                  });

              /*
              fetch("https://xck.app:5443/sendContractSigned",
                    {
                      method: "POST",
                      headers:{
                        'Content-Type': 'application/json',
                        "token": `${token}`
                      },
                      body: JSON.stringify({
                        receiverUserName: this.props.userOrigin,
                        address: jsonBlockstack3.emailNotifications,
                        signerUserName: usernameX,
                        typeContract: this.props.typeContract,
                        numberContract: this.props.numberContract,
                        role: this.props.whatUserRole
                      })
                    }
              ).then((response)=>{
                if (response.status === 200) {
                  console.log('email sended');
                  this.exitProgram()
                }
                else{
                  console.log('error sending the email')
                  this.exitProgram()
                }
              }).catch(()=>{
                console.log('error sending the email')
                this.exitProgram()
              })
              */
            } else {
              console.log("No Data");
            }
          })
           .catch(error => {
             console.log(error);
          });
    }


    sendMailUnSigned(usernameX){
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       let options = ''
       if (usernameX === this.props.userOrigin){
           options = { decrypt: false, verify: false }
       }else{
           options = { username: this.props.userOrigin, app: window.location.origin, decrypt: false, verify: false }
       }
       let user2X = usernameX
       if (usernameX === '') { user2X = this.props.userOrigin}
       getFileDB(user2X, `myUserConfig.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack3 = ''
              let jsonBlockstack4 = ''
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 jsonBlockstack3 = jsonBlockstack2.dataobject
                 jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              jsonBlockstack3 = JSON.parse(jsonBlockstack5)
              const subject = 'A participant has unsigned your contract using CrossCheck'
              const senderName = ''

              emailjs.send("service_9dv38as", "template_bn997nf", {receiverUserName: this.props.userOrigin,address: jsonBlockstack3.emailNotifications,signerUserName: usernameX,typeContract: this.props.typeContract,numberContract: this.props.numberContract,role: this.props.whatUserRole, subject, senderName}, "user_djYXBdBTeG1Km5qtyqkDl")
                  .then(response => {
                     console.log('SUCCESS!', response.status, response.text)
                     this.exitProgram()
                  }, error => {
                     console.log('FAILED...', error)
                     this.exitProgram()
                  });

              /*
              fetch("https://xck.app:5443/sendContractUnsigned",
                    {
                      method: "POST",
                      headers:{
                        'Content-Type': 'application/json',
                        "token": `${token}`
                      },
                      body: JSON.stringify({
                        receiverUserName: this.props.userOrigin,
                        address: jsonBlockstack3.emailNotifications,
                        signerUserName: usernameX,
                        typeContract: this.props.typeContract,
                        numberContract: this.props.numberContract,
                        role: this.props.whatUserRole
                      })
                    }
              ).then((response)=>{
                if (response.status === 200) {
                  console.log('email sended');
                  this.exitProgram()
                }
                else{
                  console.log('error sending the email')
                  this.exitProgram()
                }
              }).catch(()=>{
                console.log('error sending the email')
                this.exitProgram()
              })
              */
            } else {
              console.log("No Data");
            }
          })
           .catch(error => {
             console.log(error);
          });
    }

    signConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenSign: true,dialogMessage: 'Are you sure of sign the document?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'French'){this.setState({dialogOpenSign: true,dialogMessage: 'Êtes-vous sûr de signer le document?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenSign: true,dialogMessage: '¿Está seguro de firmar el documento?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenSign: true,dialogMessage: 'Você tem certeza de assinar o documento?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenSign: true,dialogMessage: 'Är du säker på att skriva under dokumentet?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenSign: true,dialogMessage: 'Weet u zeker dat u het document ondertekent?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenSign: true,dialogMessage: 'Вы уверены, что подписали документ?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenSign: true,dialogMessage: '文書に署名してもよろしいですか？',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenSign: true,dialogMessage: '您確定要簽署文件嗎？',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'German'){this.setState({dialogOpenSign: true,dialogMessage: 'Sind Sie sicher, das Dokument zu unterschreiben?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenSign: true,dialogMessage: 'Sei sicuro di firmare il documento?',dialogTitle: 'Confirm: 3001',})}
    }

    unsignConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Are you sure of cancel sign the document?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'French'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Êtes-vous sûr d’annuler la signature du document?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenUnSign: true,dialogMessage: '¿Está seguro de cancelar la firma del documento?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Tem certeza de que deseja cancelar o documento?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Är du säker på att du kommer att skriva under dokumentet?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Weet u zeker dat u het document wilt annuleren?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Вы уверены, что хотите отменить подписать документ?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenUnSign: true,dialogMessage: 'ドキュメントをキャンセルしますか？',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenUnSign: true,dialogMessage: '您確定要在文件上簽字嗎？',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'German'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Sind Sie sicher, das Dokument abzubrechen?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Sei sicuro di annullare la firma del documento?',dialogTitle: 'Confirm: 3002',})}
    }

    validatePaymentForm1(){
      if (this.state.language === 'English'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'The payment method is incomplete' ,dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'French'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Le moyen de paiement est incomplet',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'La forma de Pago está incompleta',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'O método de pagamento está incompleto',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Betalningsmetoden är ofullständig',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Betalningsmetoden är ofullständig',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Способ оплаты не указан ',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenPaymentForm: true,dialogMessage: '支払い方法が不完全です',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenPaymentForm: true,dialogMessage: '付款方式不完整',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'German'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Die Zahlungsmethode ist unvollständig',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Il metodo di pagamento è incompleto',dialogTitle: 'Error: 1001',})}
    }

    validatePaymentForm2(){
      if (this.state.language === 'English'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Missing to enter the Payment Method',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'French'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Manque de saisir le mode de paiement',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Falta ingresar la Forma de Pago',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Falta inserir o método de pagamento',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Saknar att ange betalningsmetod',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Ontbrekend om de betalingsmethode in te voeren',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Не удалось ввести способ оплаты',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'お支払い方法の入力漏れ',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenPaymentForm: true,dialogMessage: '缺少輸入付款方式',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'German'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Es fehlt die Zahlungsmethode einzugeben',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenPaymentForm: true,dialogMessage: 'Manca per inserire il metodo di pagamento',dialogTitle: 'Error: 1002',})}
    }

    confirmSign = () => {

      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''
      let contractNameX = ''
      let contractAddressX = ''
      let contractAddressX2 = ''
      let addressKeyX = ''

      const privateKey = makeRandomPrivKey();
      this.setState({privateKey})

      const { userSession } = this.state
      const user = userSession.loadUserData()
      const {username} = this.state;

      if (!localStorage["serverStacks"]) {
        serverStacksX = 'MainNet'
      }else{
        serverStacksX = localStorage.getItem('serverStacks')
      }

      if (serverStacksX === 'MainNet'){
         networkUrlX = MAINNET_STACKS_API_URL
         contractNameX = SMART_CONTRACT_NAME_MAINNET_STX
         contractAddressX = SMART_CONTRACT_ADDRESS_MAINNET_STX
         contractAddressX2 = SMART_CONTRACT_ADDRESS_MAINNET_STX
         addressKeyX =  user.profile.stxAddress.mainnet
      }
      if (serverStacksX === 'TestNet'){
         networkUrlX = TESTNET_STACKS_API_URL
         contractNameX = SMART_CONTRACT_NAME_TESTNET_STX
         contractAddressX = SMART_CONTRACT_ADDRESS_TESTNET_STX
         contractAddressX2 = SMART_CONTRACT_ADDRESS_MAINNET_STX
         addressKeyX =  user.profile.stxAddress.testnet
      }

      this.setState({privateKey, contractAddressX, addressKeyX, networkUrlX, contractNameX,contractAddressX2, serverStacksX, usernameX: username})

      balanceOf(contractAddressX, addressKeyX, networkUrlX, contractNameX,contractAddressX2, serverStacksX, username)
        .then(val => this.setState({balanceCta: val}))

      this.setState({ dialogOpenConfirmSign: true, dialogMessage: '', dialogTitle: 'Register Signatures', })
    }


    getDetailPaymentFormsLegacy(username){
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       let options = ''
       let user2X = username
       if (username === this.props.userOrigin) {
         options = { decrypt: false, verify: false }
       }else{
         options = { username: this.props.userOrigin, app: window.location.origin, decrypt: false, verify: false }
       }
       if (username === '') { user2X = this.props.userOrigin}
       user2X = this.props.userOrigin
       let jsonBlockstack4 = []
       return new Promise ((resolve, reject) =>{
         getFileDB(user2X,`${this.props.typeContract}_${this.props.numberContract}_detail_payment_form.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack3 = ''
              let jsonBlockstack4 = ''
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 jsonBlockstack3 = jsonBlockstack2.dataobject
                 jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              jsonBlockstack4 = JSON.parse(jsonBlockstack5)
              this.setState({jsonPaymentForm:jsonBlockstack4},() =>{resolve();});
            } else {
              reject()
            }
          })
           .catch(error => {
               reject()
          });
       });
    }

    listChat2 = (typeContract,numberContract,username) => {
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        let options = { decrypt: false, verify: false }
        let jsonBlockstack4 = []
        return new Promise ((resolve1, reject1) =>{
            getFileDB(username,`${typeContract}_${numberContract}_chat3.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack3 = ''
                 let jsonBlockstack4 = ''
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    jsonBlockstack3 = jsonBlockstack2.dataobject
                    jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.setState({jsonBlockstackZ50c: jsonBlockstack6})
                 resolve1()
               } else {
                 resolve1()
               }
             })
              .catch(error => {
                resolve1()
             })
        })
    }

    sendChat = (message,jsonBlockstackZ50c) =>{
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      const dateNow = new Date()
      const id = uuid()
      const message64 = Base64.encode(message);
      let jsonAttachFile = jsonBlockstackZ50c
      if (jsonAttachFile.length > 0){
          let jsonBlockstack3 = JSON.stringify(jsonAttachFile)
          if (jsonBlockstack3.substring(0,1)==='"') {
             jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
          }
          if (jsonBlockstack3.substring(0,1)==='[') {
             jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
          }
          jsonAttachFile =`${jsonBlockstack3},{"id":"${id}","origin":"${this.props.username}","date":"${dateNow}","message":"${message64}"}`
      }else{
          jsonAttachFile =`{"id":"${id}","origin":"${this.props.username}","date":"${dateNow}","message":"${message64}"}`
      }
      jsonAttachFile =`[${jsonAttachFile}]`
      jsonAttachFile = JSON.parse(jsonAttachFile)
      this.setState({jsonBlockstackZ50c: jsonAttachFile})
      putFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_chat3.json`, 'Crosscheck_Sign', JSON.stringify(jsonAttachFile), JSON.stringify(options))
        .then(keyUrl => {
        })
    }

    render() {
        let {jsonBlockstackY} = this.state
        if (this.state.jsonBlockstackY.length===0){
           jsonBlockstackY = this.props.jsonBlockstackY
        }
        const {jsonBlockstackZ} = this.props
        const {jsonStatusBlockchain} = this.props
        let jsonStatusBlockchainX = true
        if (jsonStatusBlockchain === 'Blockchain'){
          jsonStatusBlockchainX = false
        }
        const {idx} = this.state
        const {typeContract} = this.props
        const messageSubtittle = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`
        let avatar = undefined
        let avatar1 = undefined
        const avatar2 = 'images/avatar.png'
        const username = this.props.username

        let titleX = ''
        let messageSignX = ''
        let messageUnSignX = ''
        if (this.state.language === 'English'){titleX = "Signature List"}
        if (this.state.language === 'French'){titleX = "Liste de Signature"}
        if (this.state.language === 'Spanish'){titleX = "Lista de Firmas"}
        if (this.state.language === 'Portuguese'){titleX = "Lista de Assinaturas"}
        if (this.state.language === 'Swedish'){titleX = "Signaturlista"}
        if (this.state.language === 'Netherlands'){titleX = "Handtekeningenlijst"}
        if (this.state.language === 'Russian'){titleX = "Список подписей"}
        if (this.state.language === 'Japanese'){titleX = "署名リスト"}
        if (this.state.language === 'Chinese'){titleX = "签名清单"}
        if (this.state.language === 'German'){titleX = "Unterschriftenliste"}
        if (this.state.language === 'Italian'){titleX = "Elenco delle firme"}

        let displayConfirmSign = false
        let displayMessageCertified = false
        if (this.props.typeContract !== 'simulate'){
          if (this.props.userRole === 'Creator'){
            if (this.props.todosSign===true){
              if (this.props.confirmSignX === true){
                displayMessageCertified = true
              }else {
                displayConfirmSign = false
              }
            }
          }else{
            if (this.props.confirmSignX === true){
              displayMessageCertified = true
            }
          }
        }
        let cryptoPactClarityEthereumX = ''
        let cryptoPactClarityEthereum2X = ''
        let myUserConfig = this.props.myUserConfig
        let balanceX = parseFloat(this.state.balanceCta)
        let transactionX = 0
        let commissionX = 0
        let rateX = 0
        let saldoX = 0
        cryptoPactClarityEthereumX = 'Stacks'
        cryptoPactClarityEthereum2X = 'STX'
        let disableTransferX = false
        if (balanceX < (transactionX + commissionX + rateX)) {
            saldoX = balanceX
            disableTransferX = true
        }else{
            saldoX = balanceX - (transactionX + commissionX + rateX)
        }

        return (
          <div>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                  <TituloPagina2
                    sm="8"
                    title={titleX}
                    subtitle={messageSubtittle}
                    className="text-sm-left"
                  />
                </Row>
                <ConfirmacionSign
                    handleDialog={this.handleDialog}
                    dialogMessage={this.state.dialogMessage}
                    dialogOpen={this.state.dialogOpenSign}
                    dialogTitle={this.state.dialogTitle}
                />
                <ConfirmacionUnSign
                    handleDialog={this.handleDialog}
                    dialogMessage={this.state.dialogMessage}
                    dialogOpen={this.state.dialogOpenUnSign}
                    dialogTitle={this.state.dialogTitle}
                />
                <ConfirmacionConfirmSign
                    handleDialog={this.handleDialog2}
                    dialogMessage={this.state.dialogMessage}
                    dialogOpen={this.state.dialogOpenConfirmSign}
                    dialogTitle={this.state.dialogTitle}
                    cryptoPactClarityEthereumX={cryptoPactClarityEthereumX}
                    cryptoPactClarityEthereum2X={cryptoPactClarityEthereum2X}
                    addressX={this.state.serverStacksX}
                    balanceX={this.state.balanceCta}
                    transactionX={transactionX}
                    saldoX={saldoX}
                    disableTransferX={disableTransferX}
                    rateX={rateX}
                    commissionX={commissionX}
                />
                <ConfirmacionPaymentForm
                    handleDialog={this.handleDialog}
                    dialogMessage={this.state.dialogMessage}
                    dialogOpen={this.state.dialogOpenPaymentForm}
                    dialogTitle={this.state.dialogTitle}
                />

                {displayConfirmSign ?
                  <>
                    <Row>&nbsp;</Row>
                    <Row>
                      <Col md="12">
                        <Card theme="light" style={{ color: "red" }}>
                            <Table size="sm" style={{fontSize:14}} className="text-center" borderless responsive >
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="fa fa-info-circle mx-2">&nbsp;&nbsp;<FormattedMessage id="sign.confirm" /></div>
                                  </td>
                                </tr>
                                <tr>
                                  <Button outline pill theme="warning" size="sm" className="mb-2" onClick={this.confirmSign}>
                                      <i className="material-icons mr-1">replay</i> <FormattedMessage id="sign.buttonconfirm" />
                                  </Button>
                                </tr>
                              </tbody>
                            </Table>
                        </Card>
                      </Col>
                    </Row>
                  </>
                : null }
                {displayMessageCertified ?
                  <Container fluid className="px-0">
                      <Card theme="warning" >
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                &nbsp;&nbsp;<div className="fa fa-info"></div>
                              </td>
                              <td>
                                 &nbsp;&nbsp;<FormattedMessage id="document.published2" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Card>
                  </Container>
                : null }

                <Row>&nbsp;</Row>
                <Row>
                  <Col>
                    <Form style={{fontSize:12}}>
                      <Row form>
                        <Col md="12">
                          <label></label>
                           <Table size="sm" className="text-center" responsive hover striped>
                              <thead>
                                <tr>
                                  <th></th>
                                  <th style={{fontSize:16}}><FormattedMessage id="sign.userid" /></th>
                                  <th style={{fontSize:16}}><FormattedMessage id="sign.name" /></th>
                                  <th style={{fontSize:16}}><FormattedMessage id="sign.role" /></th>
                                  <th style={{fontSize:16}}><FormattedMessage id="contract.withdate" /></th>
                                  {/*<th>Sign</th>*/}
                                  <th></th>
                                  <th style={{fontSize:16}}><FormattedMessage id="sign.signature" /></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {jsonBlockstackY.map((todo, i) => {
                                   let signX = false
                                   let payX = false
                                   jsonBlockstackZ.map((todo2, y) => {
                                     if(todo.role===todo2.description){
                                       signX = todo2.sign
                                       payX = todo2.pay
                                     }
                                   })
                                   let trimmedDataURLX = false
                                   if (todo.id === this.props.username){
                                       if (todo.signature !== '' && this.props.trimmedDataURL !== null){
                                          trimmedDataURLX = true
                                       }
                                   }
                                   let buttonSign = false
                                   let buttonUnSign = false
                                   if(!this.state.buttonErrorPaymentForm){
                                     if (todo.id === this.props.username) {
                                         if (this.props.whatUserRole==="Creator" || this.props.whatUserRole==="Authorizer" || this.props.whatUserRole==="Authorizer-Inviter"){
                                           if (this.props.statusActive2){
                                             if (this.props.existContract){
                                                if (this.props.jsonStatusIdPact !== '' && this.props.jsonStatusIdPact !== undefined) {
                                                  if (this.props.jsonStatusBlockchain === 'Cloud' && this.props.jsonStatusLifeCycle === 'Draft') {
                                                     if (this.props.signTrue === true){
                                                        if (this.state.errorPaymentForm === false){
                                                           buttonSign = true
                                                        }
                                                     }else{
                                                        buttonUnSign = true
                                                     }
                                                  }
                                                }
                                              }
                                            }
                                         }
                                     }
                                   }
                                   if (signX===true){
                                      let avatar = ''
                                      let signY = "no"
                                      let payY = "no"
                                      if (signX===true){signY="yes"}
                                      if (payX===true){payY="yes"}
                                      const palabra = todo.id
                                      const ArrayIdValue = palabra.split('.blockstack')
                                      avatar = ''
                                      if (todo.avatar === undefined || todo.avatar === null || todo.avatar === ''){
                                        if (ArrayIdValue.length===2){
                                           avatar = `${this.state.url}${todo.identityAddress}//avatar-0`
                                        }else{
                                           avatar = `${this.state.url}${todo.identityAddress}/0//avatar-0`
                                        }
                                      }else{
                                        avatar = Base64.decode(todo.avatar)
                                      }
                                      avatar1 = "images/avatar.png"
                                      let displayPendingX = false
                                      if (jsonStatusBlockchainX && todo.signature) {
                                         displayPendingX = true
                                      }
                                      let displayLoaderX = false
                                      if (buttonSign && this.state.displayLoader){
                                        displayLoaderX = true
                                      }
                                      if (todo.name === undefined || todo.name === null || todo.name === 'undefined' ){todo.name = ''}

                                      return (
                                        <tr key={i} style={ this.props.colorDark ? { color:'white'} : {color:'black'}}>
                                           <td>
                                              <div className="mb-3 mx-auto">
                                                <ReactImageFallback
                                                     src={avatar}
                                                     fallbackImage={avatar2}
                                                     initialImage={avatar2}
                                                     alt={" "}
                                                     className="rounded-circle"
                                                     width="30"
                                                />
                                              </div>
                                            </td>
                                            <td>{todo.id}</td>
                                            <td>{todo.name}</td>
                                            <td>{todo.role}</td>
                                            <td>{todo.signaturedatetime}</td>
                                            {/*<td>{signY}</td>*/}
                                            <td>
                                              { trimmedDataURLX ?
                                                <div>
                                                    <img src={this.props.trimmedDataURL} style={{ width:50, height:35}} />
                                                </div>
                                              : null
                                              }
                                            </td>
                                            <td>
                                                {todo.signature}
                                                {displayPendingX ?
                                                  <React.Fragment>
                                                    &nbsp;
                                                    {displayMessageCertified
                                                    ?
                                                      <div className="fa fa-info-circle mx-2" style={{fontSize:9, color:'green'}}>
                                                        &nbsp;
                                                        <FormattedMessage id="sign.certificated" />
                                                      </div>
                                                    :
                                                      <div className="fa fa-info-circle mx-2" style={{fontSize:9, color:'red'}}>
                                                        &nbsp;
                                                        <FormattedMessage id="sign.pendingcertification" />
                                                      </div>
                                                    }
                                                  </React.Fragment>
                                                :
                                                  <>
                                                    {displayMessageCertified
                                                    ?
                                                      <div className="fa fa-info-circle mx-2" style={{fontSize:9, color:'green'}}>
                                                        &nbsp;
                                                        <FormattedMessage id="sign.certificated" />
                                                      </div>
                                                    : null }
                                                  </>
                                                }
                                            </td>
                                            {displayMessageCertified ?
                                              null
                                            :
                                              <>
                                                {buttonSign ?
                                                   <React.Fragment>
                                                     {displayLoaderX ?
                                                        <td><ContractLoader colorDark={this.props.colorDark} /></td>
                                                     :
                                                        <td><Button pill theme="warning" onClick={this.signConfirm}><FormattedMessage id="button.sign" /></Button></td>
                                                     }
                                                   </React.Fragment>
                                                :
                                                   <td></td>
                                                }
                                                {buttonUnSign ?
                                                   <React.Fragment>
                                                     {displayLoaderX ?
                                                        <td><ContractLoader colorDark={this.props.colorDark} /></td>
                                                     :
                                                        <td><Button pill theme="warning" onClick={this.unsignConfirm}><FormattedMessage id="button.unsign" /></Button></td>
                                                     }
                                                   </React.Fragment>
                                                :
                                                   <td></td>
                                                }
                                              </>
                                            }
                                        </tr>
                                  )}})}
                              </tbody>
                            </Table>
                        </Col>
                      </Row>
                    </Form>
                  </Col>

                </Row>
                <Row form>
                  <Button pill theme="secondary" onClick={this.exitProgram}><FormattedMessage id="button.contractlist" /></Button>
                </Row>

                <br></br>
            </Container>
          </div>
        );
    }
}

export default ContractSign;
