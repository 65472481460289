import { MOSTRAR_DATOSSENSORES, TOKEN_DATOSSENSORES, PACT_DATOSENSOR, SIMULATE_DATOSENSOR, SIMULATE_DATOSSENSORES } from '../actions/types';

// cada reducer tiene su propio state

const initialState = {
     sensores: []
}

export default function(state = initialState, action) {
     switch(action.type) {
          case MOSTRAR_DATOSSENSORES:
               return {
                    ...state,
                    sensores: action.payload
               }

          case SIMULATE_DATOSENSOR:
               return {
                    ...state,
                    sensores: action.payload
          }

          case SIMULATE_DATOSSENSORES:
               return {
                    ...state,
                    sensores: action.payload
               }

          case TOKEN_DATOSSENSORES:
               return {
                    ...state,
                    sensores: action.payload
               }

           case PACT_DATOSENSOR:
               return {
                   ...state,
                   sensores: [...state.sensores, action.payload]
               }
          default:
            return state;
      }
}
