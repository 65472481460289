//Ethereum
import ContractCrosscheck from './ethereum/contracts/ContractCrosscheck.json'
import contract from 'truffle-contract'

export default (async function contractCrosscheck(provider) {
  const contractX = contract(ContractCrosscheck);
  contractX.setProvider(provider);
  let instance = await contractX.deployed();
  return instance;
});
