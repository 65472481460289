import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
//import SweetAlert from 'sweetalert-react';

import { Form, InputGroup, InputGroupAddon, InputGroupText, FormInput, Button, FormSelect } from "shards-react";
//import { CustomInput } from 'reactstrap';
// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//Redux
import {connect} from 'react-redux';

//Components
import { mostrarTemplateAll } from '../../../actions/templateActions';

// Spinning
import Loader from '../../loader'

//Proptype
import PropTypes from 'prop-types';

//translate
import { FormattedMessage } from 'react-intl';

import {username_new} from '../../../actions/userActions'
import {getFileDB, putFileDB} from '../../../actions/mongoDBActions'
import {profileUser} from '../../../actions/gaiaActions'

import forge from 'node-forge'

class NavbarSearch extends Component {
      constructor(props) {
        super(props);
        this.searchContract = this.searchContract.bind(this);
        this.searchTrackingContract = this.searchTrackingContract.bind(this);
        this.searchIoTDevice = this.searchIoTDevice.bind(this);
        this.DisplayAlert = this.DisplayAlert.bind(this);
        this.BotonSearch = this.BotonSearch.bind(this);
        this.SearchAdvanced = this.SearchAdvanced.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.searchCategories = this.searchCategories.bind(this);
        this.categoryTypeContract = this.categoryTypeContract.bind(this);
        this.categoryNumberContract = this.categoryNumberContract.bind(this);
        this.categoryTag = this.categoryTag.bind(this);
        this.categoryContact = this.categoryContact.bind(this);
        this.SettingsX = this.SettingsX.bind(this);

        this.state = {
          userSession: new UserSession(),
          identityAddress: undefined,
          decentralizedID: undefined,
          username: undefined,
          user: {},
          jsonBlockstack: undefined,
          status: false,
          okSeach: true,
          isLoading: false,
          pubKey: "",
          redirect: false,
          show: "hide",
          newMessage: "",
          receiver: "",
          selectedSearch: null,
          switchInit:true,
          showAlert: false,
          sinTemplateDetail: true,
          language: 'English',
          templatesAllLocal: [],
          templateFrom: 'Server',
          yesNow: false,
          secondComboBox: false,
          category: 0,
          disabledInput: false,
          colorDark: false,
        }
      }

      c01ConfigurationContract = React.createRef();
      c02ConfigurationContract = React.createRef();
      textSearch = React.createRef()

      UNSAFE_componentWillMount() {
        const { userSession } = this.state
        let stacksNetX = ''
        let serverStacksX = ''
        if (userSession.isUserSignedIn()) {
          const user = userSession.loadUserData()
          this.setState({ user })
          if (!localStorage["serverStacks"]) {
            serverStacksX = 'MainNet'
          }else{
            serverStacksX = localStorage.getItem('serverStacks')
          }
          if (serverStacksX === 'MainNet'){
             stacksNetX =  user.profile.stxAddress.mainnet
          }
          if (serverStacksX === 'TestNet'){
             stacksNetX =  user.profile.stxAddress.testnet
          }
        }

        let colorThemeX = 'Blue'
        if (localStorage["colorTheme"]) {
          colorThemeX = localStorage.getItem('colorTheme')
          if (colorThemeX === 'Dark') {
             this.setState({colorDark: true})
          }
        }

        const languageX = localStorage.getItem('language')
        if (languageX === undefined || languageX === ''){
          this.setState({ language: 'English' })
        }else{
          this.setState({ language: languageX })
        }

        const {username} = userSession.loadUserData();
        if (username === '' || username === undefined || username === null){
          username_new(stacksNetX).then(val => this.setState({username: val},()=>{
            Promise.all([this.SettingsX(val)])
               .then(
                  (resolve) => {},
                  (reject) => {}
               )
          })) ;
        }else{
          this.setState({ username },()=>{
            Promise.all([this.SettingsX(username)])
               .then(
                  (resolve) => {},
                  (reject) => {}
               )

          })
        }
      }

      SettingsX(username){
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = { decrypt: false, verify: false }
        let settingBringContractTemplateFrom = 'Server'
        this.setState({templateFrom:'Server'})
        getFileDB(username, `settings3.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 let jsonBlockstack8 = '[]'
                 let jsonBlockstack10 = '[]'
                 if (jsonBlockstack2 !== null){
                     const jsonBlockstack3 = jsonBlockstack2.dataobject
                     const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                     if (jsonBlockstack4.substring(0,1) === '"') {
                        jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                     } else {
                        jsonBlockstack5 = jsonBlockstack4
                     }

                     const jsonBlockstack6 = jsonBlockstack2.options
                     const jsonBlockstack7 = jsonBlockstack6.replace(/\\/g,"");
                     if (jsonBlockstack7.substring(0,1) === '"') {
                        jsonBlockstack8 = jsonBlockstack7.substring(1,jsonBlockstack7.length - 1)
                     } else {
                        jsonBlockstack8 = jsonBlockstack7
                     }
                     let jsonBlockstack9 = JSON.parse(jsonBlockstack8)

                     if (jsonBlockstack9.encrypt === true) {
                       Promise.all([profileUser(username)])
                        .then(
                          (publicKey) =>{
                            const vi = jsonBlockstack5
                            const data = jsonBlockstack5,
                                  iv = '1111111111111111',
                                  password = publicKey
                            const key = forge.md.sha256.create().update(password).digest().getBytes();
                            const encryptedBytes = forge.util.decode64(data);
                            const decipher = forge.cipher.createDecipher('AES-CTR', key)
                            decipher.start({ iv });
                            decipher.update(forge.util.createBuffer(encryptedBytes));
                            decipher.finish();
                            const decryptedBytes = decipher.output.getBytes();
                            const decryptedString = forge.util.encodeUtf8(decryptedBytes);
                            let jsonBlockstack4 = decryptedString.replace(/\\/g,"");
                            if (jsonBlockstack4.substring(0,1) === '"') {
                               jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                            } else {
                               jsonBlockstack5 = jsonBlockstack4
                            }
                            jsonBlockstack10 = JSON.parse(jsonBlockstack5)
                            jsonBlockstack10.map((todo,i)=>{
                              if(todo.code==='BringContractTemplateFrom'){
                                settingBringContractTemplateFrom = todo.description
                                this.setState({templateFrom: settingBringContractTemplateFrom})
                              }
                            })
                          },
                          (reject) =>{}
                        )
                     }else{
                       jsonBlockstack10 = JSON.parse(jsonBlockstack5)
                       jsonBlockstack10.map((todo,i)=>{
                         if(todo.code==='BringContractTemplateFrom'){
                           settingBringContractTemplateFrom = todo.description
                           this.setState({templateFrom: settingBringContractTemplateFrom})
                         }
                       })
                     }
                 }
               }
             })
      }

      categoryTypeContract(){
        this.setState({category: 1})
        this.setState({yesNow: true})
        this.setState({secondComboBox: true})
        this.setState({disabledInput: true})
        if (this.state.templateFrom === 'Server'){
           Promise.all(
             [this.props.mostrarTemplateAll('')]
           ).then(
             (resolve) =>{
             },
             (reject) =>{
                  this.readContractDoc()
             }
           )
         }else{
           this.readContractDoc()
         }
      }

      categoryNumberContract(){
        this.setState({category: 2})
        this.setState({yesNow: true})
        this.setState({secondComboBox: true})
        this.setState({disabledInput: false})
        if (this.state.templateFrom === 'Server'){
           Promise.all(
             [this.props.mostrarTemplateAll('')]
           ).then(
             (resolve) =>{
             },
             (reject) =>{
                  this.readContractDoc()
             }
           )
         }else{
           this.readContractDoc()
         }
      }

      categoryTag(){
        this.setState({category: 3})
        this.setState({secondComboBox: true})
        this.setState({disabledInput: true})
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = { decrypt: false, verify: false }
        this.setState({templatesAllLocal: []})
        getFileDB(this.state.username, `tag.json`)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               this.setState({templatesAllLocal: jsonBlockstack6})
             }
           })
            .catch(error => {
           });
      }

      categoryContact(){
        this.setState({category: 4})
        this.setState({secondComboBox: true})
        this.setState({disabledInput: true})
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = { decrypt: false, verify: false }
        this.setState({templatesAllLocal: []})
        getFileDB(this.state.username, `userlist_remote.json`)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               this.setState({templatesAllLocal: jsonBlockstack6})
             }
           })
            .catch(error => {
           });
      }

      readContractDoc(){
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = { decrypt: false, verify: false }
        this.setState({templatesAllLocal: []})
        getFileDB(this.state.username, `contractdoc.json`, options)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               this.setState({templatesAllLocal: jsonBlockstack6})
             } else {
                 //console.log("No Data (contractdoc.json)");
             }
           })
            .catch(error => {
                //console.log(error);
                //console.log("No Data (contractdoc.json)");
         });
      }

      componentDidMount() {
        const { userSession } = this.state
        let stacksNetX = ''
        let serverStacksX = ''
        if (userSession.isUserSignedIn()) {
          const user = userSession.loadUserData()
          this.setState({ user })
          if (!localStorage["serverStacks"]) {
            serverStacksX = 'MainNet'
          }else{
            serverStacksX = localStorage.getItem('serverStacks')
          }
          if (serverStacksX === 'MainNet'){
    	       stacksNetX =  user.profile.stxAddress.mainnet
          }
          if (serverStacksX === 'TestNet'){
    	       stacksNetX =  user.profile.stxAddress.testnet
          }
        }
        const {username} = userSession.loadUserData();
        if (username === '' || username === undefined || username === null){
          username_new(stacksNetX).then(val => this.setState({username: val})) ;
        }else{
          this.setState({username })
        }
        const {identityAddress} = userSession.loadUserData();
        const {decentralizedID} = userSession.loadUserData();
        this.setState({ identityAddress })
        this.setState({ decentralizedID })
        this.props.mostrarTemplateAll('jfontirroig.id.blockstack')
      }

      searchCategories(){
        if (this.c01ConfigurationContract.current.value === `Categories`){this.setState({ yesNow: false, secondComboBox: false, disabledInput: false })}
        if (this.c01ConfigurationContract.current.value === `By Type Document`){this.categoryTypeContract()}
        if (this.c01ConfigurationContract.current.value === `By Number Document`){this.categoryNumberContract()}
        if (this.c01ConfigurationContract.current.value === `By Tag`){this.categoryTag()}
        if (this.c01ConfigurationContract.current.value === `By Contact`){this.categoryContact()}
        if (this.c01ConfigurationContract.current.value === `By Subject`){this.setState({ yesNow: false, secondComboBox: false, disabledInput: false, category: 5 })}
      }

      searchContract() {
        this.setState({sinTemplateDetail: true})
        this.setState({JSONTemplateName: ''})
        this.setState({showAlert: false})
        if (this.state.category===2){
          localStorage.setItem('category',this.state.category)
          const palabra = this.c02ConfigurationContract.current.value
          const ArrayIdValue = palabra.split('#$%')
          const idx = ArrayIdValue[0]
          if (idx !== ''){
            this.setState({sinTemplateDetail: false})
            const namex = ArrayIdValue[1]
            this.setState({JSONTemplateName: namex.toLowerCase()})
            this.searchTrackingContract(namex.toLowerCase())
          }
        }
        if (this.state.category===5){
           localStorage.setItem('searchContract',`${this.textSearch.current.value}`)
           localStorage.setItem('category',this.state.category)
           const {location} = this.props
           const {pathname} = location
           if (pathname === '/start'){
              this.setState({showAlert: false})
              const path = '/open'
              this.props.history.push(path);
           }else{
              const path = '/start'
              this.props.history.push(path);
           }
        }

      }

      searchTrackingContract(nombre) {
          const {userSession} = this.state
          const storage = new Storage({ userSession });
          const options = { decrypt: false, verify: false }
          localStorage.removeItem('jsonBlockstack2')
          getFileDB(this.state.username, `${nombre}_${this.textSearch.current.value}.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.setState({ jsonBlockstack: jsonBlockstack6  });
                 //console.log("Data are here");
                 this.setState({isLoading: true})
                 this.setState({showAlert: false})
                 this.setState({ yesNow: false, secondComboBox: false, disabledInput: false })
                 localStorage.setItem('searchContract',`${nombre}_${this.textSearch.current.value}.json`)
                 localStorage.setItem('searchTypeContract',nombre)
                 localStorage.setItem('jsonBlockstack2',jsonBlockstack5)
                 //window.location = '/start'

                 const {location} = this.props
                 const {pathname} = location
                 if (pathname === '/start'){
                   this.setState({showAlert: false})
                   const path = '/open'
                   this.props.history.push(path);
                 }else{
                   const path = '/start'
                   this.props.history.push(path);
                 }
               } else {
                   this.setState({showAlert: true})
                   //console.log("No Data");
               }
             })
              .catch(error => {
               this.setState({showAlert: true})
                //console.log(error);
              });
      }

      searchIoTDevice() {
      }

      handleInputChange() {
        this.setState({showAlert: false})
      }

      DisplayAlert() {
        if (this.state.JSONTemplateName === '') {
          return (
              <Button squared theme="danger" disabled={true}>
                Select<br></br>an option
              </Button>
          )
        }else{
          return (
              <Button squared theme="danger" disabled={true}>
                Error<br></br>Not Found
              </Button>
          )
        }
      }

      BotonSearch() {
        //<strong style={{cursor: 'pointer'}}  onClick={this.searchContract}><img src="images/searchUser.png" weight="20" height="20" alt=""/></strong>
        if (this.state.isLoading) {
          return (
             <Loader />
          )
        }else{
          return (
            <>
            { this.state.colorDark ?
                 <Button style={ this.state.colorDark ? { backgroundColor:'#060a0f'} : {}} disabled={this.state.disabledInput} onClick={this.searchContract}><img src="images/searchUser.png" weight="20" height="20" alt=""/></Button>
            :
                 <Button theme="light" disabled={this.state.disabledInput} onClick={this.searchContract}><img src="images/searchUser.png" weight="20" height="20" alt=""/></Button>
            }
            </>
          )
        }
      }

      SearchAdvanced() {
         //window.location = '/search/advanced'
         const path = '/search/advanced'
         this.props.history.push(path);
      }

      render() {
           let arrayTemplateDBX = []
           if (this.state.category === 1 || this.state.category === 2){
             if (this.state.yesNow === true){
               if (this.state.templateFrom==='Server') {
                 arrayTemplateDBX = this.props.templates[0]
               }else{
                 arrayTemplateDBX = this.state.templatesAllLocal
               }

               if (arrayTemplateDBX === undefined){
                 arrayTemplateDBX = []
               }
               arrayTemplateDBX.sort(function(a, b){
                 return a.priority-b.priority
               })
             }
           }
           if (this.state.category === 3){
             arrayTemplateDBX = this.state.templatesAllLocal
           }
           if (this.state.category === 4){
             arrayTemplateDBX = this.state.templatesAllLocal
           }

           const secondComboBoxX = this.state.secondComboBox

           let placeholderX1 = 'Search By Categories'
           let typeContractX1 = `By Type Document`
           let numberContractX1 = `By Number Document`
           let tagContractX1 = `By Tag`
           let contactContractX1 = `By Contact`
           let subjectContractX1 = `By Subject`
           let placeholderX2 = "Enter your search here..."
           let placeholderX3 = "Select an Option"

           if (this.state.language === 'English'){placeholderX3 = "Select an Option"}
           if (this.state.language === 'French'){placeholderX3 = "Sélectionner une Option"}
           if (this.state.language === 'Spanish'){placeholderX3 = "Seleccione una Opción"}
           if (this.state.language === 'Portuguese'){placeholderX3 = "Selecione uma Opção"}
           if (this.state.language === 'Swedish'){placeholderX3 = "Välj ett Alternativ"}
           if (this.state.language === 'Netherlands'){placeholderX3 = "Kies een Optie"}
           if (this.state.language === 'Russian'){placeholderX3 = "Выберите опцию"}
           if (this.state.language === 'Japanese'){placeholderX3 = "オプションを選択"}
           if (this.state.language === 'Chinese'){placeholderX3 = "选择一个选项"}
           if (this.state.language === 'German'){placeholderX3 = "Wähle eine Option"}
           if (this.state.language === 'Italian'){placeholderX3 = "Seleziona un'opzione"}

           if (this.state.language === 'English'){
             placeholderX1 = 'Search By Categories'
             typeContractX1 = `By Type Document`
             numberContractX1 = `By Number Document`
             tagContractX1 = `By Tag`
             contactContractX1 = `By Contact`
             subjectContractX1 = `By Subject`
             placeholderX2 = "Enter your search here..."
           }
           if (this.state.language === 'French'){
             placeholderX1 = 'Recherche par Catégories'
             typeContractX1 = `Par type de contrat`
             numberContractX1 = `Par numéro de contrat`
             tagContractX1 = `Par étiquette`
             contactContractX1 = `Par contact`
             subjectContractX1 = `Par sujet`
             placeholderX2 = "Entrez votre recherche ici..."
           }
           if (this.state.language === 'Spanish'){
             placeholderX1 = 'Buscar por Categorías'
             typeContractX1 = `Por tipo de Documento`
             numberContractX1 = `Por número de Documento`
             tagContractX1 = `Por Etiqueta`
             contactContractX1 = `Por Contacto`
             subjectContractX1 = `Por Asunto`
             placeholderX2 = "Ingrese su búsqueda aquí..."
           }
           if (this.state.language === 'Portuguese'){
             placeholderX1 = 'Pesquisa por Categorias'
             typeContractX1 = `Por tipo Documento`
             numberContractX1 = `Por número Documento`
             tagContractX1 = `Por Etiqueta`
             contactContractX1 = `Por Contato`
             subjectContractX1 = `Por Assunto`
             placeholderX2 = "Digite sua pesquisa aqui..."
           }
           if (this.state.language === 'Swedish'){
             placeholderX1 = 'Sök efter Kategorier'
             typeContractX1 = `Efter typkontrakt`
             numberContractX1 = `Avtalskontrakt`
             tagContractX1 = `Med tagg`
             contactContractX1 = `Av kontakt`
             subjectContractX1 = `Efter ämne`
             placeholderX2 = "Ange din sökning här..."
           }
           if (this.state.language === 'Netherlands'){
             placeholderX1 = 'Zoeken op Categorieën'
             typeContractX1 = `Per type contract`
             numberContractX1 = `Op nummer contract`
             tagContractX1 = `Op tag`
             contactContractX1 = `Via contact`
             subjectContractX1 = `Op onderwerp`
             placeholderX2 = "Voer hier uw zoekopdracht in..."
           }
           if (this.state.language === 'Russian'){
             placeholderX1 = 'Поиск по Kатегориям'
             typeContractX1 = `По типу контракта`
             numberContractX1 = `По номеру контракта`
             tagContractX1 = `По тегу`
             contactContractX1 = `По контакту`
             subjectContractX1 = `По предмету`
             placeholderX2 = "Введите ваш поиск здесь ..."
           }
           if (this.state.language === 'Japanese'){
             placeholderX1 = 'カテゴリーで検索'
             typeContractX1 = `タイプ契約別`
             numberContractX1 = `番号契約`
             tagContractX1 = `タグ別`
             contactContractX1 = `連絡先`
             subjectContractX1 = `件名別`
             placeholderX2 = "ここに検索を入力してください..."
           }
           if (this.state.language === 'Chinese'){
             placeholderX1 = '按类别搜索'
             typeContractX1 = `按类型合同`
             numberContractX1 = `按号码合同`
             tagContractX1 = `按标签`
             contactContractX1 = `通过联系`
             subjectContractX1 = `按主题`
             placeholderX2 = "在这里输入您的搜索..."
           }
           if (this.state.language === 'German'){
             placeholderX1 = 'Suche nach Kategorien'
             typeContractX1 = `Nach Vertragsart`
             numberContractX1 = `Nach Nummernvertrag`
             tagContractX1 = `Nach Tag`
             contactContractX1 = `Durch Kontakt`
             subjectContractX1 = `Nach Betreff`
             placeholderX2 = "Geben Sie hier Ihre Suche ein..."
           }
           if (this.state.language === 'Italian'){
             placeholderX1 = 'Cerca per Categorie'
             typeContractX1 = `Per tipo di contratto`
             numberContractX1 = `Per numero contratto`
             tagContractX1 = `Per tag`
             contactContractX1 = `Per contatto`
             subjectContractX1 = `Per soggetto`
             placeholderX2 = "Inserisci qui la tua ricerca..."
           }

           return (
              <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex"
                    style={ this.state.colorDark ? { backgroundColor:'#0c151d'} : {}}>
                <InputGroup className="mb-2">
                  <InputGroupAddon type="prepend">
                      <FormSelect style={{margin:"10px"}}
                          style={ this.state.colorDark ? { backgroundColor:'#0c151d'} : {}}
                          id="TypeContract"
                          innerRef={this.c01ConfigurationContract}
                          placeholder={placeholderX1}
                          onChange={this.searchCategories}
                          disabled={this.state.displayTemplateDetail}
                      >
                          <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value={`Categories`}>{placeholderX1}</option>
                          <option value={`By Type Document`} disabled={true}>{typeContractX1}</option>
                          <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value={`By Number Document`}>{numberContractX1}</option>
                          <option value={`By Tag`} disabled={true}>{tagContractX1}</option>
                          <option value={`By Contact`} disabled={true}>{contactContractX1}</option>
                          <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value={`By Subject`}>{subjectContractX1}</option>
                      </FormSelect>
                  </InputGroupAddon>
                  {secondComboBoxX ?
                    <InputGroupAddon type="prepend">
                        <FormSelect style={{margin:"10px"}}  style={ this.state.colorDark ? { backgroundColor:'#0c151d'} : {}}
                            id="TypeContract"
                            innerRef={this.c02ConfigurationContract}
                            placeholder={placeholderX3}
                            onChange={this.searchContracts}
                            disabled={this.state.displayTemplateDetail}
                        >
                        <option value={``}>{placeholderX3}</option>
                          {arrayTemplateDBX.map((todo, key) => {
                              let isActiveX = todo.isActive
                              if (isActiveX){
                                if (this.state.category === 1 || this.state.category === 2){
                                  return (
                                     <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} key={todo._id} disabled={!isActiveX} value={`${todo._id}#$%${todo.secondaryName}`}>{todo.name}</option>
                                  )
                                }
                              }else{
                                if (this.state.category === 1 || this.state.category === 2){
                                  return (
                                     <option key={todo._id} disabled={!isActiveX} value={`${todo._id}#$%${todo.secondaryName}`}>{todo.name}</option>
                                  )
                                }
                              }
                              if (this.state.category === 3){
                                return (
                                 <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} key={key} value={`${todo.code}`}>{todo.code}</option>
                                )
                              }
                              if (this.state.category === 4){
                                if (todo.remoteuser !== ''){
                                  return (
                                   <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} key={key} value={`${todo.remoteuser}`}>{todo.remoteuser}</option>
                                  )
                                }
                              }
                          })}
                        </FormSelect>
                    </InputGroupAddon>
                  : null }
                  <div>&nbsp;</div>
                  <FormInput
                    style={ this.state.colorDark ? { backgroundColor:'#0c151d'} : {}}
                    className="navbar-search"
                    placeholder={placeholderX2}
                    type="text"
                    innerRef={this.textSearch}
                    onChange={this.handleInputChange}
                    disabled={this.state.disabledInput}
                  />
                  <InputGroupAddon type="append" style={ this.state.colorDark ? { backgroundColor:'#0c151d'} : {}}>
                    <InputGroupText style={ this.state.colorDark ? { backgroundColor:'#0c151d'} : {}}>
                      { this.state.showAlert ?
                         this.DisplayAlert()
                      :
                         this.BotonSearch()
                      }
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Form>
           )
      }
}

NavbarSearch.propTypes = {
    mostrarTemplateAll : PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  templates: state.templates.templatesAll,
})

const mapDispatchToProps = dispatch => {
  return {
    mostrarTemplateAll: (username) => {dispatch(mostrarTemplateAll(username));},
  };
};

export default withRouter(connect( mapStateToProps, mapDispatchToProps)(NavbarSearch))
