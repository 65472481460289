export default function() {
  return [
    {
      title: "My Profile",
      title_es: "Mi perfil",
      title_fr: "Mon profil",
      title_pt: "Meu perfil",
      title_sv: "Min profil",
      title_nl: "Mijn profiel",
      title_ru: "Мой профайл",
      title_jp: "私のプロフィール",
      title_cn: "我的簡歷",
      title_de: "Mein Profil",
      title_it: "Il mio profilo",
      htmlBefore: '<i class="material-icons">person_pin</i>',
      to: "/profile",
    },
    {
      title: "My Documents",
      title_es: "Mis Documentos",
      title_fr: "Mes Documents",
      title_pt: "Meus Documentos",
      title_sv: "Mina Dokument",
      title_nl: "Mijn Documenten",
      title_ru: "Мои документы",
      title_jp: "私の文書",
      title_cn: "我的文件",
      title_de: "Meine Dokumente",
      title_it: "I miei documenti",
      htmlBefore: '<i class="material-icons">home</i>',
      to: "/start",
    },
    {
      title: "My Invitations",
      title_es: "Mis Invitaciones",
      title_fr: "Mes Invitations",
      title_pt: "Meus Convites",
      title_sv: "Mina Inbjudningar",
      title_nl: "Mijn Uitnodigingen",
      title_ru: "Мои приглашения",
      title_jp: "私の招待",
      title_cn: "我的邀请",
      title_de: "Meine Einladungen",
      title_it: "I miei Inviti",
      htmlBefore: '<i class="material-icons">contact_mail</i>',
      to: "/invitation",
    },
    {
      title: "My Finances",
      title_es: "Mis Finanzas",
      title_fr: "Mes finances",
      title_pt: "Minhas Finanças",
      title_sv: "Min Ekonomi",
      title_nl: "Mijn Financiën",
      title_ru: "Мои финансы",
      title_jp: "私の財政",
      title_cn: "我的財務",
      title_de: "Meine Finanzen",
      title_it: "Le mie Finanze ",
      htmlBefore: '<i class="material-icons">account_balance_wallet</i>',
      to: "/finance",
    },
    {
      title: "Configuration",
      title_es: "Configuración",
      title_fr: "Configuration",
      title_pt: "Configuração",
      title_sv: "Konfiguration",
      title_nl: "Configuratie",
      title_ru: "конфигурация",
      title_jp: "設定",
      title_cn: "组态",
      title_de: "Konfiguration",
      title_it: "Configurazione",
      htmlBefore: '<i class="material-icons">settings</i>',
      to: "/configuration",
    },
  ];
}
