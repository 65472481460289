import React, { Component } from 'react';
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  Link,
} from '@react-pdf/renderer';

//translate
import { FormattedMessage } from 'react-intl';

//base64
import { Base64 } from 'js-base64';

//Clarity
import {getTransactionRegister} from "../../clarity/clarityfunctions"

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  tittle: {
    fontSize: 14,
    textAlign: 'center',
    fontFamily: 'Oswald',
    color: '#909497',
  },

  description: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 30,
    color: '#909497',
  },

  subtitle: {
    fontSize: 12,
    margin: 12,
    fontFamily: 'Oswald',
  },

  text: {
    margin: 10,
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },

  text2: {
    margin: 10,
    fontSize: 8,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },

  text3: {
    margin: 10,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },

  text5: {
    margin: 10,
    fontSize: 9,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },

  link: {
    position: 'absolute',
    bottom: 50,
    left: 0,
    right: 0,
    margin: 10,
    fontSize: 9,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    color: 'blue',
  },

  sign: {
    margin: 10,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
    lineHeight : '0.05px',
  },

  image: {
    marginVertical: 10,
    marginHorizontal: 220,
  },

  imagesign: {
    minWidth:100,
    maxWidth:150,
    minHeight:30,
    maxHeight:50,
    width:150,
    height:50
  },

  imagedigital: {
    minWidth:100,
    maxWidth:100,
    minHeight:100,
    maxHeight:100,
    width:100,
    height:100
  },

  header: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: 'center',
    color: '#ECF0F1',
  },

  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  table: {
      width: '100%',
      fontSize: 8,
      textAlign: 'center',
    },
    rowtable: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
    },
    headertable: {
      borderTop: 'none',
    },
    // So Declarative and unDRY 👌
    row1table: {
      width: '5%',
    },
    row2table: {
      width: '25%',
    },
    row3table: {
      width: '12%',
    },
    row4table: {
      width: '12%',
    },
    row5table: {
      width: '12%',
    },
    row6table: {
      width: '12%',
    },
    row7table: {
      width: '22%',
    },
});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const Subtitle = ({ children, ...props }) => (
  <Text style={styles.subtitle} {...props}>
    {children}
  </Text>
);

class CertificatePaymentMade extends Component {

    constructor(props) {
        super(props);

        this.state = {
          language: 'English',
          isocode: 'en',
          dateFormatX: 'dd/MM/yyyy',
        }
    }

    UNSAFE_componentWillMount() {
      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English'})
      }else{
        this.setState({ language: languageX })
      }
      if (languageX === 'English'){this.setState({ isocode: "en", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'French'){this.setState({ isocode:  "fr", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Spanish'){this.setState({ isocode:  "es", dateFormatX:"dd/MM/yyyy"})}
      if (languageX === 'Portuguese'){this.setState({ isocode:  "pt", dateFormatX:"dd/MM/yyyy"})}
      if (languageX === 'Swedish'){this.setState({ isocode:  "sv", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Netherlands'){this.setState({ isocode:  "nl", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Russian'){this.setState({ isocode:  "ru", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Japanese'){this.setState({ isocode:  "ja_JP", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Chinese'){this.setState({ isocode: "zh", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'German'){this.setState({ isocode:  "de", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Italian'){this.setState({ isocode:  "it", dateFormatX:"MM/dd/yyyy"})}

    }

    render() {
      let paymentFormX = 'Payment Form'
      let signatoriesX = 'Signatories'
      let signatureX = 'Signature'
      let nameX = 'Name'
      let titleX = "Agreement Document"
      let itemX = "Item"
      let conceptX = "Concept"
      let estimatedDateX = "Estimated Date"
      let percentX = "Percent"
      let amountX = "Amount"
      let checkInX = 'Check in: '
      let digitallysignedX = 'Digitally signed by '
      let withdateX = 'With date '
      let totalsX = 'Totals'
      let registrationX = 'Blockchain Registration'
      let paymentForm1X = 'The total amount of this agreement is'
      let paymentForm2X = 'and will be paid in'
      let paymentForm3X = 'at the exchange rate at the time of payment'
      let parrafo1X = 'The undersigned certifies that, to date, the following payments corresponding to the document have been made'
      let parrafo2X = 'through the electronic payment system through the website https://xck.app.'
      let parrafo3X = 'The details of reported payments can be found on the Crosscheck internet portal.'
      let certificateTittleX = "CERTIFICATE OF PAYMENTS MADE"
      let payDayX = 'Fecha Pago'
      let exchangeRateX = 'Tipo Cambio'
      let amountPaidX = 'Monto Pagado'
      let transactionId = 'ID Transacción'
      let totalAmountPaidX = "Total Amount Paid"

      if (this.state.language === 'English'){ paymentFormX = 'Payment Method'; signatoriesX = 'Signatories'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Agreement Document"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'French'){ paymentFormX = 'Mode de Paiement'; signatoriesX = 'Signataires'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Document d'accord"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Spanish'){ paymentFormX = 'Forma de Pago'; signatoriesX = 'Firmantes'; signatureX = 'Firma'; nameX = 'Nombre'; titleX = "Documento de Acuerdo"; itemX = "Item"; conceptX = "Concepto"; estimatedDateX = "Fecha Estimada"; percentX = "Porcentaje"; amountX = "Monto"; checkInX = "Verificar en"}
      if (this.state.language === 'Portuguese'){ paymentFormX = 'Forma de pagamento'; signatoriesX = 'Signatários'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Documento de Acuerdo"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Swedish'){ paymentFormX = 'Betalningsmetod'; signatoriesX = 'Undertecknare'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Avtalsdokument"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Netherlands'){ paymentFormX = 'Betalingswijze'; signatoriesX = 'Ondertekenaars'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Overeenkomst document"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Russian'){ paymentFormX = 'Способ оплаты'; signatoriesX = 'Подписавшие'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Документ соглашения"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Japanese'){ paymentFormX = '支払方法'; signatoriesX = '署名者'; signatureX = 'Signature'; nameX = 'Name'; titleX = "契約書"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Chinese'){ paymentFormX = '付款方法'; signatoriesX = '簽署人'; signatureX = 'Signature'; nameX = 'Name'; titleX = "协议文件"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'German'){ paymentFormX = 'Zahlungsmethode'; signatoriesX = 'Unterzeichner'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Einverständniserklärung"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Italian'){ paymentFormX = 'Metodo di pagamento'; signatoriesX = 'Firmatari'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Documento di accordo"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}

      if (this.state.language === 'English'){ payDayX = 'Pay Day'; exchangeRateX = 'Exchange Rate'; amountPaidX = 'Amount Paid '; transactionId = 'Transaction Id'}
      if (this.state.language === 'French'){ payDayX = 'Pay Day'; exchangeRateX = 'Exchange Rate'; amountPaidX = 'Amount Paid '; transactionId = 'Transaction Id'}
      if (this.state.language === 'Spanish'){ payDayX = 'Fecha Pago'; exchangeRateX = 'Tipo Cambio'; amountPaidX = 'Monto Pagado'; transactionId = 'ID Transacción'}
      if (this.state.language === 'Portuguese'){ payDayX = 'Pay Day'; exchangeRateX = 'Exchange Rate'; amountPaidX = 'Amount Paid '; transactionId = 'Transaction Id'}
      if (this.state.language === 'Swedish'){ payDayX = 'Pay Day'; exchangeRateX = 'Exchange Rate'; amountPaidX = 'Amount Paid '; transactionId = 'Transaction Id'}
      if (this.state.language === 'Netherlands'){ payDayX = 'Pay Day'; exchangeRateX = 'Exchange Rate'; amountPaidX = 'Amount Paid '; transactionId = 'Transaction Id'}
      if (this.state.language === 'Russian'){ payDayX = 'Pay Day'; exchangeRateX = 'Exchange Rate'; amountPaidX = 'Amount Paid '; transactionId = 'Transaction Id'}
      if (this.state.language === 'Japanese'){ payDayX = 'Pay Day'; exchangeRateX = 'Exchange Rate'; amountPaidX = 'Amount Paid '; transactionId = 'Transaction Id'}
      if (this.state.language === 'Chinese'){ payDayX = 'Pay Day'; exchangeRateX = 'Exchange Rate'; amountPaidX = 'Amount Paid '; transactionId = 'Transaction Id'}
      if (this.state.language === 'German'){ payDayX = 'Pay Day'; exchangeRateX = 'Exchange Rate'; amountPaidX = 'Amount Paid '; transactionId = 'Transaction Id'}
      if (this.state.language === 'Italian'){ payDayX = 'Pay Day'; exchangeRateX = 'Exchange Rate'; amountPaidX = 'Amount Paid '; transactionId = 'Transaction Id'}

      if (this.state.language === 'English'){ digitallysignedX = 'Digitally signed by '; withdateX = 'With date '; totalsX = 'Totals'; registrationX = 'Blockchain Registration'}
      if (this.state.language === 'French'){ digitallysignedX = 'Signé numériquement par '; withdateX = 'Avec date '; totalsX = 'Totaux'; registrationX = 'Enregistrement de la blockchain'}
      if (this.state.language === 'Spanish'){ digitallysignedX = 'Firmado digitalmente por '; withdateX = 'Con fecha '; totalsX = 'Totales'; registrationX = 'Registro Blockchain'}
      if (this.state.language === 'Portuguese'){ digitallysignedX = 'Assinado digitalmente por '; withdateX = 'Com data '; totalsX = 'Totais'; registrationX = 'Registro de Blockchain'}
      if (this.state.language === 'Swedish'){ digitallysignedX = 'Digitalt signerad av '; withdateX = 'Med datum '; totalsX = 'Summor'; registrationX = 'Blockchain registrering'}
      if (this.state.language === 'Netherlands'){ digitallysignedX = 'Digitaal ondertekend door '; withdateX = 'Met datum '; totalsX = 'Totalen'; registrationX = 'Blockchain-registratie'}
      if (this.state.language === 'Russian'){ digitallysignedX = 'Цифровая подпись '; withdateX = 'С датой '; totalsX = 'Итоги'; registrationX = 'Блокчейн Регистрация'}
      if (this.state.language === 'Japanese'){ digitallysignedX = 'デジタル署名者 '; withdateX = '日付付き '; totalsX = '合計'; registrationX = 'ブロックチェーン登録'}
      if (this.state.language === 'Chinese'){ digitallysignedX = '數字簽名者 '; withdateX = '有日期 '; totalsX = '總計'; registrationX = '區塊鏈註冊'}
      if (this.state.language === 'German'){ digitallysignedX = 'Digital signiert von '; withdateX = 'Mit Datum '; totalsX = 'Summen'; registrationX = 'Blockchain-Registrierung'}
      if (this.state.language === 'Italian'){ digitallysignedX = 'Firmato digitalmente da '; withdateX = 'Con data '; totalsX = 'Totali'; registrationX = 'Registrazione Blockchain'}

      if (this.state.language === 'English'){ paymentForm1X = "The total amount of this agreement is"; paymentForm2X = "and will be paid in"; paymentForm3X = "at the exchange rate at the time of payment"}
      if (this.state.language === 'French'){ paymentForm1X = "Le montant total de cet accord est"; paymentForm2X = "aet sera payé en"; paymentForm3X = "au taux de change au moment du paiement"}
      if (this.state.language === 'Spanish'){ paymentForm1X = "El monto total de este acuerdo es de"; paymentForm2X = "y será pagado en"; paymentForm3X = "al tipo de cambio del momento del pago"}
      if (this.state.language === 'Portuguese'){ paymentForm1X = "O valor total deste contrato é"; paymentForm2X = "e será pago em"; paymentForm3X = "à taxa de câmbio no momento do pagamento"}
      if (this.state.language === 'Swedish'){ paymentForm1X = "Det totala beloppet för detta avtal är"; paymentForm2X = "och kommer att betalas in"; paymentForm3X = "till växelkursen vid betalningstillfällett"}
      if (this.state.language === 'Netherlands'){ paymentForm1X = "Het totale bedrag van deze overeenkomst is"; paymentForm2X = "en wordt uitbetaald"; paymentForm3X = "tegen de wisselkoers op het moment van betaling"}
      if (this.state.language === 'Russian'){ paymentForm1X = "Общая сумма этого договора составляет"; paymentForm2X = "и будет оплачен в"; paymentForm3X = "по курсу на момент оплаты"}
      if (this.state.language === 'Japanese'){ paymentForm1X = "この契約の総額は、"; paymentForm2X = "で支払われます"; paymentForm3X = "支払い時の為替レートで"}
      if (this.state.language === 'Chinese'){ paymentForm1X = "本協議總金額為"; paymentForm2X = "並將支付"; paymentForm3X = "按付款時的匯率"}
      if (this.state.language === 'German'){ paymentForm1X = "Der Gesamtbetrag dieser Vereinbarung beträgt"; paymentForm2X = "und wird einbezahlt"; paymentForm3X = "zum Wechselkurs zum Zeitpunkt der Zahlung"}
      if (this.state.language === 'Italian'){ paymentForm1X = "L'importo totale di questo accordo è"; paymentForm2X = "e verrà pagato"; paymentForm3X = "al tasso di cambio al momento del pagamento"}

      if (this.state.language === 'English'){totalAmountPaidX = "Total Amount Paid"; certificateTittleX = "CERTIFICATE OF PAYMENTS MADE"; parrafo1X = "The undersigned certifies that, up to this date, the following payments corresponding to the document have been made"; parrafo2X = "through the electronic payment system through the website https://xck.app."; parrafo3X = "The details of reported payments can be found on the Crosscheck internet portal."}
      if (this.state.language === 'French'){ totalAmountPaidX = "Total Amount Paid"; certificateTittleX = "CERTIFICATE OF PAYMENTS MADE"; parrafo1X = "The undersigned certifies that, up to this date, the following payments corresponding to the document have been made"; parrafo2X = "through the electronic payment system through the website https://xck.app."; parrafo3X = "The details of reported payments can be found on the Crosscheck internet portal."}
      if (this.state.language === 'Spanish'){ totalAmountPaidX = "Total Monto Pagado"; certificateTittleX = "CERTIFICADO DE PAGOS REALIZADOS"; parrafo1X = "Quien suscribe certifica que, a la fecha de hoy se han efectuado los siguientes pagos correspondientes al documento"; parrafo2X = "mediante el sistema de pago electrónico a través del sitio internet https://xck.app."; parrafo3X = "El detalle de pagos informado, se encuentran en el portal de internet de Crosscheck."}
      if (this.state.language === 'Portuguese'){ totalAmountPaidX = "Total Amount Paid"; certificateTittleX = "CERTIFICATE OF PAYMENTS MADE"; parrafo1X = "The undersigned certifies that, up to this date, the following payments corresponding to the document have been made"; parrafo2X = "through the electronic payment system through the website https://xck.app."; parrafo3X = "The details of reported payments can be found on the Crosscheck internet portal."}
      if (this.state.language === 'Swedish'){ totalAmountPaidX = "Total Amount Paid"; certificateTittleX = "CERTIFICATE OF PAYMENTS MADE"; parrafo1X = "The undersigned certifies that, up to this date, the following payments corresponding to the document have been made"; parrafo2X = "through the electronic payment system through the website https://xck.app."; parrafo3X = "The details of reported payments can be found on the Crosscheck internet portal."}
      if (this.state.language === 'Netherlands'){ totalAmountPaidX = "Total Amount Paid"; certificateTittleX = "CERTIFICATE OF PAYMENTS MADE"; parrafo1X = "The undersigned certifies that, up to this date, the following payments corresponding to the document have been made"; parrafo2X = "through the electronic payment system through the website https://xck.app."; parrafo3X = "The details of reported payments can be found on the Crosscheck internet portal."}
      if (this.state.language === 'Russian'){ totalAmountPaidX = "Total Amount Paid"; certificateTittleX = "CERTIFICATE OF PAYMENTS MADE"; parrafo1X = "The undersigned certifies that, up to this date, the following payments corresponding to the document have been made"; parrafo2X = "through the electronic payment system through the website https://xck.app."; parrafo3X = "The details of reported payments can be found on the Crosscheck internet portal."}
      if (this.state.language === 'Japanese'){ totalAmountPaidX = "Total Amount Paid"; certificateTittleX = "CERTIFICATE OF PAYMENTS MADE"; parrafo1X = "The undersigned certifies that, up to this date, the following payments corresponding to the document have been made"; parrafo2X = "through the electronic payment system through the website https://xck.app."; parrafo3X = "The details of reported payments can be found on the Crosscheck internet portal."}
      if (this.state.language === 'Chinese'){ totalAmountPaidX = "Total Amount Paid"; certificateTittleX = "CERTIFICATE OF PAYMENTS MADE"; parrafo1X = "The undersigned certifies that, up to this date, the following payments corresponding to the document have been made"; parrafo2X = "through the electronic payment system through the website https://xck.app."; parrafo3X = "The details of reported payments can be found on the Crosscheck internet portal."}
      if (this.state.language === 'German'){ totalAmountPaidX = "Total Amount Paid"; certificateTittleX = "CERTIFICATE OF PAYMENTS MADE"; parrafo1X = "The undersigned certifies that, up to this date, the following payments corresponding to the document have been made"; parrafo2X = "through the electronic payment system through the website https://xck.app."; parrafo3X = "The details of reported payments can be found on the Crosscheck internet portal."}
      if (this.state.language === 'Italian'){ totalAmountPaidX = "Total Amount Paid"; certificateTittleX = "CERTIFICATE OF PAYMENTS MADE"; parrafo1X = "The undersigned certifies that, up to this date, the following payments corresponding to the document have been made"; parrafo2X = "through the electronic payment system through the website https://xck.app."; parrafo3X = "The details of reported payments can be found on the Crosscheck internet portal."}


      const firmantesX = this.props.jsonBlockstackY

      let displayFormaPagoX = false
      let formapagoX = []
      if (this.props.jsonBlockstackFormPago.length > 0){
         formapagoX = this.props.jsonBlockstackFormPago
         displayFormaPagoX = true
      }
      const base64LinkX = Base64.encode(`${this.props.typeContract}>>>${this.props.numberContract}>>>${this.props.userOrigin}>>>${this.props.whatUserRole}>>>${this.props.description}>>>Certificate Payments Made`)
      const linkX = `https://${this.props.usernameX}?pdf:${base64LinkX}`

      let fechapagoX = ''
      let idX = ''
      let tipocambioX = ''
      let amountpaymentX = ''

      const optionsDate = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric"
      };

      const fechaHoyX = new Date()

      let totalpercentX = 0;
      let totalamountX = 0;

      let imageFirmadoDigitalmenteX = "images/digitally_signed_english.png"
      if (this.state.language === 'Spanish'){
        imageFirmadoDigitalmenteX = "images/firmadodigitalmente.png"
      }
      
      return (
        <Document>
           <Page size="A4" style={styles.body} wrap>
             <Text style={styles.header} fixed> ~ Created with Crosscheck by Paradigma ~ </Text>

             <View><Text>{" "}</Text></View>
             <Text style={styles.tittle}>{certificateTittleX}</Text>
             <View><Text>{" "}</Text></View>
             <Text style={styles.text}>{`${parrafo1X} ${this.props.typeContract}/${this.props.numberContract} ${parrafo2X}`}</Text>
             <View><Text>{" "}</Text></View>

             {displayFormaPagoX ?
               <>
               <View style={styles.table}>
                   <View style={[styles.rowtable, styles.boldtable, styles.headertable]}>
                     <Text style={styles.row1table}>{itemX}</Text>
                     <Text style={styles.row2table}>{conceptX}</Text>
                     <Text style={styles.row3table}>{amountX}</Text>
                     <Text style={styles.row4table}>{payDayX}</Text>
                     <Text style={styles.row5table}>{exchangeRateX}</Text>
                     <Text style={styles.row6table}>{amountPaidX}</Text>
                     <Text style={styles.row7table}>{transactionId}</Text>
                   </View>
                   {formapagoX.map((todo, i) => {
                     if (todo.paid === true && todo.status === 'success'){
                       totalpercentX = totalpercentX + +(Math.round(todo.percent + "e+2")  + "e-2");
                       totalamountX =  totalamountX + +(Math.round(todo.amount + "e+2")  + "e-2");
                       if (todo.fechapago === undefined){ fechapagoX = ''} else {fechapagoX = todo.fechapago.toLocaleString(this.state.isocode,optionsDate)}
                       if (todo.txid === undefined){idX = ''} else {idX = `${todo.txid.substring(0,7)}...${todo.txid.substring(todo.txid.length-7)}`}
                       if (todo.tipocambio === undefined){tipocambioX = ''} else {tipocambioX = todo.tipocambio}
                       if (todo.amountpayment === undefined){amountpaymentX = ''} else {amountpaymentX = todo.amountpayment}
                       return (
                         <View key={i} style={styles.rowtable} wrap={false}>
                           <Text style={styles.row1table}><Text style={styles.boldtable}>{++i}</Text></Text>
                           <Text style={styles.row2table}>{todo.concept}</Text>
                           <Text style={styles.row3table}>{`${todo.amount} ${this.props.symbolcurrency}`}</Text>
                           <Text style={styles.row4table}>{fechapagoX}</Text>
                           <Text style={styles.row5table}>{tipocambioX}</Text>
                           <Text style={styles.row6table}>{`${amountpaymentX} ${this.props.cryptoCurrency}`}</Text>
                           <Text style={styles.row7table}>{idX}</Text>
                         </View>
                       )
                     }
                   })}
                   <View style={styles.rowtable} wrap={false}>
                     <Text style={styles.row1table}><Text style={styles.boldtable}>{''}</Text></Text>
                     <Text style={styles.row2table}>{totalAmountPaidX}</Text>
                     <Text style={styles.row3table}>{`${totalamountX} ${this.props.symbolcurrency}`}</Text>
                     <Text style={styles.row4table}>{''}</Text>
                     <Text style={styles.row5table}>{''}</Text>
                     <Text style={styles.row6table}>{''}</Text>
                     <Text style={styles.row7table}>{''}</Text>
                   </View>
               </View>
               </>
             :
               null
             }

             <View><Text>{" "}</Text></View>
             <Text style={styles.text5}>{parrafo3X}</Text>
             <View><Text>{" "}</Text></View>

             {firmantesX.map((todo, i) => {
                if (todo.role === 'Creator'){
                  let trimmedDataURLX = false
                  if (todo.signpad !== undefined){
                    trimmedDataURLX = true
                  }
                  return (
                    <>
                      <View style={styles.table}>
                        <View key={i} style={styles.rowtable} wrap={false}>
                          <Text style={styles.row2table}>
                          <Image
                            style={styles.imagedigital}
                            src={imageFirmadoDigitalmenteX}
                          />
                          </Text>
                          <Text style={styles.row2table}>
                          <Image
                            style={styles.imagesign}
                            src={todo.signpad}
                          />
                          </Text>
                        </View>
                      </View>

                      <View>
                        <Text style={styles.sign}>{`${digitallysignedX} ${todo.name}`}</Text>
                        <Text style={styles.sign}>{`did:web: ${todo.id}`}</Text>
                    </View>
                    </>
                  )
                }
             })}

             <View><Text>{" "}</Text></View>

             <Text style={styles.text2}>{`Crosscheck ID: ${this.props.userOrigin}/${this.props.typeContract}/${this.props.numberContract}/CertificatePaymentsMade`}</Text>
             <Text style={styles.text5}>{fechaHoyX.toLocaleString(this.state.isocode,optionsDate)}</Text>
             <Text style={styles.link}><Link src={linkX}>{`${checkInX}: ${linkX}`}</Link></Text>
          </Page>
        </Document>
      )
    }
}
export default CertificatePaymentMade;
