//React
import React, { Component } from 'react';

//UI
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormTextarea,
  FormSelect,
  Container
} from "shards-react";
import { Table } from 'reactstrap';
import { Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';

//base64
import { Base64 } from 'js-base64';

//translate
import { FormattedMessage } from 'react-intl';

//Emojis Smiles
import EmojiPicker from "emoji-picker-react";

class ContractChatNew extends Component {

    constructor(props) {
        super(props);

        this.sendChat = this.sendChat.bind(this);

        this.state = {
          disabled: false,
        }
    }

    c01Contact = React.createRef();
    c04Contact = React.createRef();

    componentDidMount() {
        this.c01Contact.current.value = this.props.from
    }

    UNSAFE_componentWillReceiveProps() {
      this.c01Contact.current.value = this.props.from
    }

    sendChat(){
      this.props.sendChat(this.c04Contact.current.value,this.props.jsonBlockstackZ60)
    }

    render() {
          const {jsonBlockstackZ70} = this.props
          return (
            <div>
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        {/* Name */}
                        <Col className="form-group">
                          <label htmlFor="feFrom" style={{fontSize:16}} ><FormattedMessage id="aboutcontact.from" /></label>
                          <FormInput
                            id="feFrom"
                            style={ this.props.colorDark ? { backgroundColor:'#111f2c', color:'white'} : {}}
                            innerRef={this.c01Contact}
                            onChange={() => {}}
                            disabled={true}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        {/* Message */}
                        <Col className="form-group">
                          <label htmlFor="feMessage" style={{fontSize:16}} ><FormattedMessage id="aboutcontact.message" /></label>
                          <FormTextarea
                            id="feMessage"
                            style={ this.props.colorDark ? { backgroundColor:'#111f2c', color:'white'} : {}}
                            rows="7"
                            innerRef={this.c04Contact}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        <Col md="10">
                          <EmojiPicker/>
                        </Col>
                        <Col md="2">
                          <Button theme="accent" onClick={this.sendChat} ><FormattedMessage id="aboutcontact.send" /></Button>&nbsp;&nbsp;
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>
          );
     }
}

export default ContractChatNew;
