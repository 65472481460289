//React
import React, { Component } from 'react';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Card, Container, FormSelect, Button, FormInput, InputGroupAddon, InputGroupText, InputGroup, DatePicker } from "shards-react";

import { Table } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";
import { Divider, Typography, TextField, Grid, Dialog,
         DialogContent, DialogActions, DialogTitle, DialogContentText,
         Button as ButtonMaterialUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';

//Tittle
import TituloPagina2 from "../common/TituloPagina2";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//Axios
import axios from 'axios';

//translate
import { FormattedMessage } from 'react-intl';

//
import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'


function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionAccept(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('accept')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'accept')} theme="warning" autoFocus>
                   <FormattedMessage id="alert.accept" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class FormsDeliverable extends Component {

    constructor(props) {
        super(props);

        this.exitProgram = this.exitProgram.bind(this);
        this.newDeliverableForm = this.newDeliverableForm.bind(this);
        this.deletePaymentForm = this.deletePaymentForm.bind(this);
        this.editPaymentForm = this.editPaymentForm.bind(this);
        this.faltaDataConfirm = this.faltaDataConfirm.bind(this);
        this.getDeliverableForms = this.getDeliverableForms.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          name: '',
          user: {},
          modifying: false,
          jsonConceptForm: [],
          error: false,
          indice: 0,
          language: 'English',
          displayConceptManagement: false,
          displayPaymentForm: true,
        }
    }

    c01ConceptForm = React.createRef();
    c02ConceptForm = React.createRef();
    c03ConceptForm = React.createRef();
    c04ConceptForm = React.createRef();
    c05ConceptForm = React.createRef();

    componentDidMount() {
      if (this.props.username === this.props.userOrigin){
        Promise.all([this.getDeliverableForms(this.props.username)])
          .then((resolve) =>{},(reject) =>{})
      }else{
        Promise.all([this.getDeliverableForms(this.props.userOrigin)])
          .then((resolve) =>{},(reject) =>{})
      }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      let languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
        languageX = 'en'
      }else{
        this.setState({ language: languageX })
      }
    }

    handleDialog = (type, action) => {
      if (type === 'Error: 1001') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1002') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1003') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
    }

    handleChangeConceptForm = (e) => {
      e.preventDefault();
    }
    handleChangeSign = (e) => {
      e.preventDefault();
    }
    handleChangeRegister = (e) => {
      e.preventDefault();
    }

    faltaDataConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: '------ Missing data ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "------ Données manquantes ------",dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: '------ Faltan Datos ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: '------ Dados ausentes ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: '------ Saknar data ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: '------ Ontbrekende gegevens ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: '------ Потерянная информация ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '------ 欠測データ ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '------ 缺失數據 ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: '------ Fehlende Daten ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: '------ Dati mancanti ------',dialogTitle: 'Error: 1001',})}
    }

    errorEliminarConfirm = () => {
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'Existe Grupo de Usuario asociado al registro que desea eliminar',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "Existe Grupo de Usuario asociado al registro que desea eliminar",dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'Existe Grupo de Usuario asociado al registro que desea eliminar',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'Existe Grupo de Usuario asociado al registro que desea eliminar',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Existe Grupo de Usuario asociado al registro que desea eliminar',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'Existe Grupo de Usuario asociado al registro que desea eliminar',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Existe Grupo de Usuario asociado al registro que desea eliminar',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: 'Existe Grupo de Usuario asociado al registro que desea eliminar',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: 'Existe Grupo de Usuario asociado al registro que desea eliminar',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Existe Grupo de Usuario asociado al registro que desea eliminar',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: 'Existe Grupo de Usuario asociado al registro que desea eliminar',dialogTitle: 'Error: 1002',})}
    }

    errorActualizarConfirm = () => {
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: '------ Existe el Concepto ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "------ Existe el Concepto ------",dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: '------ Existe el Concepto ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: '------ Existe el Concepto ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: '------ Existe el Concepto ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: '------ Existe el Concepto ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: '------ Existe el Concepto ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '------ Existe el Concepto ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '------ Existe el Concepto ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: '------ Existe el Concepto ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: '------ Existe el Concepto ------',dialogTitle: 'Error: 1003',})}
    }

    newDeliverableForm = (e,code) => {
          e.preventDefault();

          if(this.c01ConceptForm.current.value.length === 0 || this.c02ConceptForm.current.value.length === 0  || this.c03ConceptForm.current.value.length === 0) {
               this.setState({error: true});
               this.faltaDataConfirm()
               return;
          }

          const {userSession} = this.state
          const storage = new Storage({ userSession });
          const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }

          const c01ConceptForm = this.c01ConceptForm.current.value
          const c02ConceptForm = this.c02ConceptForm.current.value
          const c03ConceptForm = this.c03ConceptForm.current.value
          const c04ConceptForm = 'Yes'
          const c05ConceptForm = 'Yes'

          this.setState({error: false})
          const {indice} = this.state
          let jsonBlockstack2 = ''
          let jsonConceptFormX = this.state.jsonConceptForm
          let putConceptFormX = true
          let siActualizar = true
          if (!this.state.modifying){
            if (jsonConceptFormX.length === 0){
              jsonBlockstack2 =`[{"concept":"${c01ConceptForm}","code":"${c02ConceptForm}","description":"${c03ConceptForm}","sign":"${c04ConceptForm}","register":"${c05ConceptForm}"}]`
              this.setState({jsonConceptForm:JSON.parse(jsonBlockstack2)})
            }else{
              jsonConceptFormX.map((todo, i) => {
                   if (todo.concept === c01ConceptForm){
                     siActualizar = false
                   }
              })
              if (siActualizar){
                jsonConceptFormX = JSON.stringify(jsonConceptFormX)
                if (jsonConceptFormX.substring(0,1)==='"') {
                   jsonConceptFormX = jsonConceptFormX.substring(1,jsonConceptFormX.length - 1);
                }
                if (jsonConceptFormX.substring(0,1)==='[') {
                   jsonConceptFormX = jsonConceptFormX.substring(1,jsonConceptFormX.length - 1);
                }
                jsonBlockstack2 =`[${jsonConceptFormX},{"concept":"${c01ConceptForm}","code":"${c02ConceptForm}","description":"${c03ConceptForm}","sign":"${c04ConceptForm}","register":"${c05ConceptForm}"}]`
                this.setState({jsonConceptForm:JSON.parse(jsonBlockstack2)})
              }
            }
          }else{
            jsonConceptFormX.map((todo, i) => {
                 if (indice === i){
                   todo.concept = c01ConceptForm
                   todo.code = c02ConceptForm
                   todo.description = c03ConceptForm
                   todo.sign = c04ConceptForm
                   todo.register = c05ConceptForm
                 }
              })
            jsonBlockstack2 = jsonConceptFormX
            this.setState({modifying:false})
          }
          if (siActualizar){
            putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_deliverable_form.json`, 'Crosscheck_Deliverable', JSON.stringify(jsonBlockstack2), JSON.stringify(options))
              .then(() => {
            })
          }else{
            this.errorActualizarConfirm()
          }
          this.c01ConceptForm.current.value = ''
          this.c02ConceptForm.current.value = ''
          this.c03ConceptForm.current.value = ''
          this.c04ConceptForm.current.value = 'Yes'
          this.c05ConceptForm.current.value = 'Yes'
    }

    initialDeliverableForm = () => {
          const {userSession} = this.state
          const storage = new Storage({ userSession });
          const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
          const jsonBlockstack2 =`[{"concept":"ReporteTurno","code":"ReporteTurno","description":"Reporte de Turno","sign":"Yes","register":"Yes"},{"concept":"AdobePDF","code":"FormPDF","description":"Format Adobe PDF","sign":"Yes","register":"Yes"},{"concept":"MicrosoftExcel","code":"FormExcel","description":"Format Microsoft Excel","sign":"Yes","register":"Yes"}]`
          this.setState({jsonConceptForm:JSON.parse(jsonBlockstack2)})
          putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_deliverable_form.json`, 'Crosscheck_Deliverable', JSON.stringify(jsonBlockstack2), JSON.stringify(options))
            .then(() => {
          })
    }

    editPaymentForm = (e,optx) => {
       e.preventDefault();
       const {jsonConceptForm} = this.state
       const {concept} = jsonConceptForm[optx]
       const {code} = jsonConceptForm[optx]
       const {description} = jsonConceptForm[optx]
       const {sign} = jsonConceptForm[optx]
       const {register} = jsonConceptForm[optx]

       this.c01ConceptForm.current.value = concept
       this.c02ConceptForm.current.value = code
       this.c03ConceptForm.current.value = description
       this.c04ConceptForm.current.value = sign
       this.c05ConceptForm.current.value = register

       this.setState({modifying:true, indice:optx})
    }

    deletePaymentForm = (e,optx, codeX, percentX, amountX) => {
         const {userSession} = this.state
         const storage = new Storage({ userSession });
         const options = { decrypt: false, verify: false }
         getFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_authorizer_deliverable.json`)
            .then((fileContents) => {
              if(fileContents) {
                const jsonBlockstack1 = JSON.parse(fileContents)
                const jsonBlockstack2 = jsonBlockstack1.data
                let jsonBlockstack5 = '[]'
                if (jsonBlockstack2 !== null){
                   const jsonBlockstack3 = jsonBlockstack2.dataobject
                   const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                   if (jsonBlockstack4.substring(0,1) === '"') {
                      jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                   } else {
                      jsonBlockstack5 = jsonBlockstack4
                   }
                }
                let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                let siEliminar = true
                jsonBlockstack6.map((todo,i) => {
                  if (todo.codeFormsDeliverable === codeX){
                     siEliminar = false
                  }
                })
                if (siEliminar){
                  this.deletePaymentForm2(e,optx, codeX, percentX, amountX)
                }else{
                  this.errorEliminarConfirm()
                }
              } else {
                this.deletePaymentForm2(e,optx, codeX, percentX, amountX)
              }
            })
             .catch(error => {
              this.deletePaymentForm2(e,optx, codeX, percentX, amountX)
            });
    }

    deletePaymentForm2 = (e,optx, codeX, percentX, amountX) => {
         const {userSession} = this.state
         const storage = new Storage({ userSession });
         const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
         const setting = this.state.jsonConceptForm
         setting.splice(optx,1);
         this.setState({jsonConceptForm: setting})
         const jsonBlockstack1 = JSON.stringify(setting)
         putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_deliverable_form.json`, 'Crosscheck_Deliverable', jsonBlockstack1, JSON.stringify(options))
           .then(() => {
         })
         this.c01ConceptForm.current.value = ''
         this.c02ConceptForm.current.value = ''
         this.c03ConceptForm.current.value = ''
         this.c04ConceptForm.current.value = 'Yes'
         this.c05ConceptForm.current.value = 'Yes'
    }

    getDeliverableForms(username){
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = { decrypt: false, verify: false }
       let jsonBlockstack4 = []
       return new Promise ((resolve, reject) =>{
         getFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_deliverable_form.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonConceptForm: jsonBlockstack6})
              resolve(true);
            } else {
              resolve(true);
            }
          })
           .catch(error => {
               resolve(true);
          });
       });
    }

    exitProgram(){
       this.props.returnProps();
    }

    render() {
        let titleX = 'Deliverables Format'
        let placeholderX1 = "Select an Option"
        if (this.state.language === 'English'){titleX = "Deliverables Format";placeholderX1 = "Select an Option"}
        if (this.state.language === 'French'){titleX = "Format des livrables";placeholderX1 = "Sélectionner unproe.ide Option"}
        if (this.state.language === 'Spanish'){titleX = "Formato de Entregables";placeholderX1 = "Seleccione una Opción"}
        if (this.state.language === 'Portuguese'){titleX = "Formato de Entregáveis";placeholderX1 = "Selecione uma Opção"}
        if (this.state.language === 'Swedish'){titleX = "Leveransformat";placeholderX1 = "Välj ett Alternativ"}
        if (this.state.language === 'Netherlands'){titleX = "Leveringsformaat";placeholderX1 = "Kies een Optie"}
        if (this.state.language === 'Russian'){titleX = "Формат результатов";placeholderX1 = "Выберите опцию"}
        if (this.state.language === 'Japanese'){titleX = "成果物の形式";placeholderX1 = "オプションを選択"}
        if (this.state.language === 'Chinese'){titleX = "交付成果格式";placeholderX1 = "选择一个选项"}
        if (this.state.language === 'German'){titleX = "Lieferformat";placeholderX1 = "Wähle eine Option"}
        if (this.state.language === 'Italian'){titleX = "Formato dei risultati finali";placeholderX1 = "Seleziona un'opzione"}

        const messageSubtittle = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`
        const {username} = this.state

        const jsonConceptFormX = this.state.jsonConceptForm
        let disabledX = false
        if (jsonConceptFormX.length > 0){
          disabledX = true
        }

        let bloquedX = false
        if (this.props.whatUserRole !== 'Creator'){
          bloquedX = true
          disabledX = true
        }
        const jsonConceptPaymentFormX = this.state.jsonConceptPaymentForm

        let item = 0

        return (
          <Container fluid className="main-content-container px-4">

            <Confirmacion
                handleDialog={this.handleDialog}
                dialogMessage={this.state.dialogMessage}
                dialogOpen={this.state.dialogOpen}
                dialogTitle={this.state.dialogTitle}
            />
            <ConfirmacionAccept
                handleDialog={this.handleDialog}
                dialogMessage={this.state.dialogMessage}
                dialogOpen={this.state.dialogOpenAccept}
                dialogTitle={this.state.dialogTitle}
            />
            <Row noGutters className="page-header py-4">
                <TituloPagina2
                  sm="4"
                  title={titleX}
                  subtitle={messageSubtittle}
                  className="text-sm-left"
                />
            </Row>
            {!bloquedX ?
              <Row>
                <Col md="12">
                  <Table size="sm" className="text-center" responsive borderless>
                     <thead>
                       <tr>
                         <th style={{width: "5%"}}></th>
                         <th style={{width: "15%", fontsize:16}}><FormattedMessage id="payment.concept" /></th>
                         <th style={{width: "10%", fontsize:16}}><FormattedMessage id="iotdevice.code" /></th>
                         <th style={{width: "30%", fontsize:16}}><FormattedMessage id="iotdevice.description" /></th>
                         <th style={{width: "10%", fontsize:16}}><FormattedMessage id="sign.signature" /></th>
                         <th style={{width: "10%", fontsize:16}}><FormattedMessage id="contract.listcol27" /></th>
                         <th style={{width: "10%"}}></th>
                         <th style={{width: "10%"}}></th>
                         <th></th>
                       </tr>
                     </thead>
                     <tbody>
                       <tr>
                         <td></td>
                         <td>
                           <FormSelect
                               id="ConceptForm"
                               innerRef={this.c01ConceptForm}
                               placeholder="Select an Option"
                               onChange={e=>this.handleChangeConceptForm(e)}
                               disabled={false}
                               style={{backgroundColor:"#F2EACE"}}
                             >
                             <option value={``} selected disabled hidden>{placeholderX1}</option>
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value="ReporteTurno">Reporte de Turno</option>
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value="AdobePDF">Adobe PDF (.pdf)</option>
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value="MicrosoftPowerpoint">Microsoft Powerpoint (.ppt - .pptx)</option>
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value="MicrosoftWord">Microsoft Word (.doc - .docx)</option>
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value="MicrosoftExcel">Microsoft Excel (xls - .xlsx)</option>
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value="OpenOffice">OpenOffice (.odp - .ods - .odt)</option>
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value="Text">Text (.txt - .text)</option>
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value="Image">Image (.jpg/.jpeg - .png - .gif)</option>
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value="Video">Video (.mp4)</option>
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value="Audio">Audio (.mp3)</option>
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value="Compressed">Compressed (.zip)</option>
                           </FormSelect>
                         </td>
                         <td>
                           <FormInput
                             id="Code"
                             type="text"
                             innerRef={this.c02ConceptForm}
                             placeholder=""
                             className="text-left"
                           />

                         </td>
                         <td>
                           <FormInput
                             id="Description"
                             type="text"
                             innerRef={this.c03ConceptForm}
                             placeholder=""
                             disabled={false}
                             placeholder=""
                             className="text-left"
                           />
                         </td>
                         <td>
                           <FormSelect
                               id="Sign"
                               innerRef={this.c04ConceptForm}
                               placeholder="Select an Option"
                               onChange={e=>this.handleChangeSign(e)}
                               disabled={true}
                               style={{backgroundColor:"#F2EACE"}}
                             >
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} selected value="Yes">Yes</option>
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value="No">No</option>
                         </FormSelect>
                         </td>
                         <td>
                           <FormSelect
                               id="Register"
                               innerRef={this.c05ConceptForm}
                               placeholder="Select an Option"
                               onChange={e=>this.handleChangeRegister(e)}
                               disabled={true}
                               style={{backgroundColor:"#F2EACE"}}
                             >
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} selected value="Yes">Yes</option>
                             <option style={this.state.colorDark ? {color:'orange'} : {color:'darkblue'}} value="No">No</option>
                         </FormSelect>
                         </td>
                         <td>
                           {!this.state.modifying ?
                              <Button pill theme="warning" disabled={bloquedX} onClick={e=>this.newDeliverableForm(e)}>---<FormattedMessage id="configuration.add" />---</Button>
                           :
                              <Button pill theme="warning" disabled={bloquedX} onClick={e=>this.newDeliverableForm(e)}>---<FormattedMessage id="configuration.modify" />---</Button>
                           }
                         </td>
                         <td></td>
                       </tr>
                     </tbody>
                  </Table>
                </Col>
              </Row>
            : null }
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>
              <Col md="12">
                 <Table size="sm" className="text-center" responsive hover striped>
                    <thead>
                      <tr>
                        <th style={{width: "5%", fontsize:16}}><FormattedMessage id="payment.item" /></th>
                        <th style={{width: "15%", fontsize:16}}><FormattedMessage id="payment.concept" /></th>
                        <th style={{width: "10%", fontsize:16}}><FormattedMessage id="iotdevice.code" /></th>
                        <th style={{width: "30%", fontsize:16}}><FormattedMessage id="iotdevice.description" /></th>
                        <th style={{width: "10%", fontsize:16}}><FormattedMessage id="sign.signature" /></th>
                        <th style={{width: "10%", fontsize:16}}><FormattedMessage id="contract.listcol27" /></th>
                        <th style={{width: "10%", fontsize:16}}><FormattedMessage id="configuration.edit" /></th>
                        <th style={{width: "10%", fontsize:16}}><FormattedMessage id="configuration.delete" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      {jsonConceptFormX.map((todo, i) => {
                        item = i
                        return (
                            <tr key={i} style={ this.props.colorDark ? { color:'white'} : {color:'black'}}>
                                <td>{++item}</td>
                                <td>{todo.concept}</td>
                                <td>{todo.code}</td>
                                <td>{todo.description}</td>
                                <td>{todo.sign}</td>
                                <td>{todo.register}</td>
                                {!bloquedX ?
                                  <>
                                    <td><Button pill theme="success" disabled={bloquedX} onClick={e=>this.editPaymentForm(e, i)}><FontAwesomeIcon icon={faEdit} /></Button></td>
                                    <td><Button pill theme="danger" disabled={bloquedX} onClick={e=>this.deletePaymentForm(e, i, todo.code, todo.percent, todo.amount)}><FontAwesomeIcon icon={faTimes} /></Button></td>
                                  </>
                                :
                                  <>
                                    <td></td>
                                    <td></td>
                                  </>
                                }
                            </tr>
                      )})}
                    </tbody>
                  </Table>
              </Col>
            </Row>
          </Container>
        );
    }
}

export default FormsDeliverable;
