import { combineReducers } from 'redux';
import {reducer as notificationsReducer} from 'reapop';
//import { reducer as alertsReducer } from 'react-redux-alerts';

import sensoresReducer from './sensoresReducers';
import contractReducer from './contractReducers';
import errorReducer from './errorReducers';
import authReducer from './authReducers';
import UserReducer from './userReducers';
import smartcontract from './pactReducers';
import userGroups from './modelReducers';
import templateReducer from './templateReducers';

export default combineReducers({
  sensores: sensoresReducer,
  error: errorReducer,
  auth: authReducer,
  notifications: notificationsReducer,
  //alerts: alertsReducer,
  user: UserReducer,
  contracts: contractReducer,
  smartcontract,
  userGroups,
  templates: templateReducer,
});
