import React from "react";
import { withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
  Button,
  FormSelect
} from "shards-react";

import { Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';

import ReactImageFallback from "react-image-fallback";

import {username_new} from '../../../../actions/userActions'
import {getFileDB, putFileDB} from '../../../../actions/mongoDBActions'
import {profileUser} from '../../../../actions/gaiaActions'

// blockstack
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//translate
import { FormattedMessage } from 'react-intl';

//base64
import { Base64 } from 'js-base64';

import forge from 'node-forge'

const punycode = require('punycode/');

function ConfirmacionNetwork(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog(props.dialogTitle, 'cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.dialogMessage}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <br></br>
                    <br></br>
                    {props.optMainNet ?
                      <FormSelect
                          id="ModeSetting"
                          placeholder="Select an Option"
                          onChange={e=>props.handleNetwork(e)}
                      >
                        <option value="MainNet" selected>MainNet</option>
                        <option value="TestNet">TestNet</option>
                      </FormSelect>
                    :
                      <FormSelect
                          id="ModeSetting"
                          placeholder="Select an Option"
                          onChange={e=>props.handleNetwork(e)}
                      >
                        <option value="MainNet">MainNet</option>
                        <option value="TestNet" selected>TestNet</option>
                      </FormSelect>

                    }
                    <br></br>
                    <br></br>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      userSession: new UserSession(),
      username: '',
      user: {},
      identityAddress: undefined,
      url:'https://gaia.blockstack.org/hub/',
      languaje: 'English',
      imagen: '',
      serverStacks: 'MainNet',
      dialogOpen: false,
      dialogMessage: '',
      dialogTitle: '',
      placeholderX1: '',
      optionNetwork: '',
      avatar:'',
      avatar2: 'images/avatar.png',
      profileName: '',
      profileAvatar: [],


    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
    this.changeNetwork = this.changeNetwork.bind(this);
    this.confirmChangeNetwork = this.confirmChangeNetwork.bind(this);

  }

  UNSAFE_componentWillMount() {
    const { userSession } = this.state

    let stacksNetX = ''
    let serverStacksX = ''

    if (userSession.isUserSignedIn()) {
      const user = userSession.loadUserData()
      this.setState({ user })

      if (!localStorage["serverStacks"]) {
        serverStacksX = 'MainNet'
      }else{
        serverStacksX = localStorage.getItem('serverStacks')
      }
      if (serverStacksX === 'MainNet'){
         stacksNetX =  user.profile.stxAddress.mainnet
      }
      if (serverStacksX === 'TestNet'){
         stacksNetX =  user.profile.stxAddress.testnet
      }

    }

    const {identityAddress} = userSession.loadUserData();
    this.setState({ identityAddress })

    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English' })
    }else{
      this.setState({ language: languageX })
    }

    this.setState({ serverStacks: serverStacksX })
    this.setState({ optionNetwork: serverStacksX })

    this.setState({ placeholderX1: "Select an Option"})
    if (this.state.language === 'English'){ this.setState({ placeholderX1: "Select an Option"}) }
    if (this.state.language === 'French'){ this.setState({ placeholderX1: "Sélectionner une Option"}) }
    if (this.state.language === 'Spanish'){ this.setState({ placeholderX1: "Seleccione una Opción"}) }
    if (this.state.language === 'Portuguese'){ this.setState({ placeholderX1: "Selecione uma Opção"}) }
    if (this.state.language === 'Swedish'){ this.setState({ placeholderX1: "Välj ett Alternativ"}) }
    if (this.state.language === 'Netherlands'){ this.setState({ placeholderX1: "Kies een Optie"}) }
    if (this.state.language === 'Russian'){ this.setState({ placeholderX1: "Выберите опцию"}) }
    if (this.state.language === 'Japanese'){this.setState({ placeholderX1: "オプションを選択"}) }
    if (this.state.language === 'Chinese'){ this.setState({ placeholderX1: "选择一个选项"}) }
    if (this.state.language === 'German'){ this.setState({ placeholderX1: "Wähle eine Option"}) }
    if (this.state.language === 'Italian'){ this.setState({ placeholderX1: "Seleziona un'opzione"}) }

    let avatar = ''
    //const palabra = username
    //const ArrayIdValue = palabra.split('.blockstack')
    //if (ArrayIdValue.length===2){
    //   avatar = `${this.state.url}${identityAddress}//avatar-0`
    //}else{
    //  avatar = `${this.state.url}${identityAddress}/0//avatar-0`
    //}
    this.setState({ avatar: avatar })


    const {username} = userSession.loadUserData();
    if (username === '' || username === undefined || username === null){
      username_new(stacksNetX).then(val => this.setState({username: val},()=>{
        Promise.all([this.readNameAvatar(val)])
           .then(
              (resolve) => {},
              (reject) => {}
           )
      })) ;
    }else{
      this.setState({ username },()=>{
        Promise.all([this.readNameAvatar(username)])
           .then(
              (resolve) => {},
              (reject) => {}
           )

      })
    }

  }

  handleDialog = (type, action) => {
    if (type === '') {
      if (action === 'confirm') {
        if (this.state.optionNetwork === 'MainNet' || this.state.optionNetwork === 'TestNet'){
          this.setState({ dialogOpen: false });
          Promise.all(
             [this.changeNetwork()
          ]
          ).then(
            (resolve) =>{},
            (reject) =>{}
          )
        }
      }
      if (action === 'cancel') {
        this.setState({ dialogOpen: false });
      }
    }
  }

  handleNetwork = (e) => {
    this.setState({ optionNetwork: e.target.value });
  }

  changeNetwork = () => {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
      return new Promise ((resolve, reject) =>{
        getFileDB(this.state.username, `settings3.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             let jsonBlockstack8 = '[]'
             let jsonBlockstack10 = '[]'
             if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }

                 const jsonBlockstack6 = jsonBlockstack2.options
                 const jsonBlockstack7 = jsonBlockstack6.replace(/\\/g,"");
                 if (jsonBlockstack7.substring(0,1) === '"') {
                    jsonBlockstack8 = jsonBlockstack7.substring(1,jsonBlockstack7.length - 1)
                 } else {
                    jsonBlockstack8 = jsonBlockstack7
                 }
                 let jsonBlockstack9 = JSON.parse(jsonBlockstack8)

                 if (jsonBlockstack9.encrypt === true) {
                   Promise.all([profileUser(this.state.username)])
                    .then(
                      (publicKey) =>{
                        const vi = jsonBlockstack5
                        const data = jsonBlockstack5,
                              iv = '1111111111111111',
                              password = publicKey
                        const key = forge.md.sha256.create().update(password).digest().getBytes();
                        const encryptedBytes = forge.util.decode64(data);
                        const decipher = forge.cipher.createDecipher('AES-CTR', key)
                        decipher.start({ iv });
                        decipher.update(forge.util.createBuffer(encryptedBytes));
                        decipher.finish();
                        const decryptedBytes = decipher.output.getBytes();
                        const decryptedString = forge.util.encodeUtf8(decryptedBytes);
                        let jsonBlockstack4 = decryptedString.replace(/\\/g,"");
                        if (jsonBlockstack4.substring(0,1) === '"') {
                           jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                        } else {
                           jsonBlockstack5 = jsonBlockstack4
                        }
                        jsonBlockstack10 = JSON.parse(jsonBlockstack5)
                        let jsonBlockstack4Q = JSON.parse(jsonBlockstack5)
                        let largo = jsonBlockstack4Q.length
                        let finLoop = false
                        jsonBlockstack4Q.map((todo,i)=>{
                          if(todo.code==='StacksNetwork'){
                            todo.description = this.state.optionNetwork
                            finLoop = true
                          }
                          if (i === (largo - 1)){
                          }
                        })
                        let jsonBlockstack3 = JSON.stringify(jsonBlockstack4Q)
                        //this.putNetwork(jsonBlockstack3)
                        resolve(true);
                      },
                      (reject) =>{
                        resolve(true);
                      }
                    )
                 }else{
                   jsonBlockstack10 = JSON.parse(jsonBlockstack5)
                   let jsonBlockstack4Q = JSON.parse(jsonBlockstack5)
                   let largo = jsonBlockstack4Q.length
                   let finLoop = false
                   jsonBlockstack4Q.map((todo,i)=>{
                     if(todo.code==='StacksNetwork'){
                       todo.description = this.state.optionNetwork
                       finLoop = true
                     }
                     if (i === (largo - 1)){
                     }
                   })
                   let jsonBlockstack3 = JSON.stringify(jsonBlockstack4Q)
                   //this.putNetwork(jsonBlockstack3)
                   resolve(true);
                 }
             }else{
               resolve(true);
             }
           } else {
             resolve(true);
           }

/*
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             let jsonBlockstack4Q = JSON.parse(jsonBlockstack5)
             let largo = jsonBlockstack4Q.length
             let finLoop = false
             jsonBlockstack4Q.map((todo,i)=>{
               if(todo.code==='StacksNetwork'){
                 todo.description = this.state.optionNetwork
                 finLoop = true
               }
               if (i === (largo - 1)){
               }
             })
             let jsonBlockstack3 = JSON.stringify(jsonBlockstack4Q)
             //this.putNetwork(jsonBlockstack3)
             resolve(true);
           } else {
             resolve(true);
           }

*/

         })
        .catch(error => {
          resolve(true);
        })
      })
  }

  readNameAvatar = (username) => {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    getFileDB(username, `myUserConfig.json`)
       .then((fileContents) => {
         if(fileContents) {
           const jsonBlockstack1 = JSON.parse(fileContents)
           const jsonBlockstack2 = jsonBlockstack1.data
           let jsonBlockstack5 = '[]'
           if (jsonBlockstack2 !== null){
              const jsonBlockstack3 = jsonBlockstack2.dataobject
              const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
              if (jsonBlockstack4.substring(0,1) === '"') {
                 jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
              } else {
                 jsonBlockstack5 = jsonBlockstack4
              }
           }
           let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
           this.setState({profileName: jsonBlockstack6.profileName})
           this.setState({profileAvatar: Base64.decode(jsonBlockstack6.profileAvatar)})
         }
       })
      .catch(error => {
       });
  }

  putNetwork = (jsonBlockstack3) => {
    const { userSession } = this.state
    const storage = new Storage({ userSession });
    let options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
    return new Promise ((resolve, reject) =>{
             localStorage.setItem('serverStacks',this.state.optionNetwork)
             this.setState({ serverStacks: this.state.optionNetwork })
             let jsonBlockstack1 = JSON.stringify(jsonBlockstack3)

             Promise.all([profileUser(this.state.username)])
              .then(
                (resolve) =>{
                    const data = jsonBlockstack1,
                          iv = '1111111111111111',
                          password = resolve
                    const key = forge.md.sha256.create().update(password).digest().getBytes();
                    const cipher = forge.cipher.createCipher('AES-CTR', key);
                    cipher.start({ iv });
                    cipher.update(forge.util.createBuffer(data));
                    cipher.finish();
                    const encryptedBytes = cipher.output.getBytes();
                    const encryptedBase64 = forge.util.encode64(encryptedBytes);
                    options = {contentType: 'application/json', encrypt: true, dangerouslyIgnoreEtag: true }
                    jsonBlockstack1 = encryptedBase64
                    putFileDB(this.state.username, `settings3.json`, 'Crosscheck_UserAction', jsonBlockstack1, JSON.stringify(options))
                      .then(() => {
                        resolve(true);
                    })
                     .catch(error => {
                       resolve(true);
                    })
                  },
                (reject) =>{
                    putFileDB(this.state.username, `settings3.json`, 'Crosscheck_UserAction', jsonBlockstack1, JSON.stringify(options))
                      .then(() => {
                        resolve(true);
                    })
                     .catch(error => {
                       resolve(true);
                    })
                  }
              )
    })
  }

  confirmChangeNetwork = () => {

    if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'Change Network (Stacks)',dialogTitle: '',})}
    if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: `Changer de réseau (Stacks) `,dialogTitle: '',})}
    if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'Cambiar la Red (Stacks)',dialogTitle: '',})}
    if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'Alterar rede (Stacks) ',dialogTitle: '',})}
    if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Ändra nätverk (Stacks)',dialogTitle: '',})}
    if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'Netwerk wijzigen (Stacks)',dialogTitle: '',})}
    if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Изменить сеть (Stacks)',dialogTitle: '',})}
    if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: 'Stacks)',dialogTitle: '',})}
    if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: 'Stacks',dialogTitle: '',})}
    if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Netzwerk ändern (Stacks)',dialogTitle: '',})}
    if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: "Cambia rete (Stacks) ",dialogTitle: '',})}
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  signOut = () => {
    const { userSession } = this.state;
    userSession.signUserOut();
    window.location = '/'
    //const path = '/logout'
    //this.props.history.push(path);
  }

  profile = () => {
    const path = '/user-profile-lite'
    this.props.history.push(path);
  }

  setting = () => {
    const path = '/masters'
    this.props.history.push(path);
  }

  network = () => {
    this.confirmChangeNetwork()
  }

  render() {
    const username = this.props.username

    let person = ""
    let avatar = ""

    if (this.state.username !== ''){
       person = this.state.username
       if (this.state.profileAvatar !== '' && this.state.profileAvatar !== null && this.state.profileAvatar !== undefined && this.state.profileAvatar.length > 0){
          avatar = this.state.profileAvatar
       }else{
          avatar = this.state.avatar
       }
    }else{
       if (this.state.username === '' && (this.state.profileName !== '' && this.state.profileName!== null && this.state.profileName !== undefined)) {
         person = this.state.profileName
       }else{
         person = this.state.username
       }
       if (this.state.profileAvatar !== '' && this.state.profileAvatar !== null && this.state.profileAvatar !== undefined && this.state.profileAvatar.length > 0){
          avatar = this.state.profileAvatar
       }else{
          avatar = this.state.avatar
       }
    }

    const personX = punycode.toUnicode(person)

    const avatar2 = 'images/avatar.png'

    let optMainNet = false
    let optTestNet = false
    if (this.state.optionNetwork === 'MainNet'){
      optMainNet = true
      optTestNet = false
    }
    if (this.state.optionNetwork === 'TestNet'){
      optMainNet = false
      optTestNet = true
    }

    return (
      <>
        <ConfirmacionNetwork
            handleDialog={this.handleDialog}
            dialogMessage={this.state.dialogMessage}
            dialogOpen={this.state.dialogOpen}
            dialogTitle={this.state.dialogTitle}
            handleNetwork={this.handleNetwork}
            placeholderX1={this.state.placeholderX1}
            optMainNet={optMainNet}
            optTestNet={optTestNet}
        />
        <NavItem
            tag={Dropdown}
            caret
            >
            <DropdownToggle
                caret
                tag={NavLink}
                className="text-nowrap px-3"
                style={{cursor: 'pointer'}}
                onClick={this.toggleUserActions}
                >
                <ReactImageFallback
                     src={avatar}
                     fallbackImage={avatar2}
                     initialImage={avatar2}
                     alt={" "}
                     className="user-avatar rounded-circle mr-2"
                />{" "}
                <span className="d-none d-md-inline-block" style={{color: 'orange'}}>{personX}</span>
            </DropdownToggle>

            <Collapse
                tag={DropdownMenu}
                right
                small
                open={this.state.visible}
                style={ this.props.colorDark ? { backgroundColor:'#b6cde2'} : {}}
                >
                <DropdownItem tag="a" style={{cursor: 'pointer', textDecoration: 'none'}}>
                    <div onClick={this.profile}>
                       <i class="material-icons">person_pin</i> <FormattedMessage id="useraction.userprofile" />
                    </div>
                </DropdownItem>
                <DropdownItem tag="a" style={{cursor: 'pointer', textDecoration: 'none'}}>
                    <div onClick={this.setting}>
                       <i class="material-icons">settings</i> <FormattedMessage id="useraction.setting" />
                    </div>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem tag="a" className="text-success" style={{cursor: 'pointer', textDecoration: 'none'}}>
                    <div onClick={this.network}>
                       <i class="material-icons text-success">share</i> <FormattedMessage id="useraction.network" />&nbsp;[{this.state.serverStacks}]
                    </div>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                    <div onClick={this.signOut}>
                        <i className="material-icons text-danger">&#xE879;</i><FormattedMessage id="useraction.logout" />
                    </div>
                </DropdownItem>
            </Collapse>
        </NavItem>
      </>
    );
  }
}

export default withRouter(UserActions)
