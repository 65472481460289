import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Navbar } from "shards-react";

import NavbarSearch from "./NavbarSearch";
import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";

let colorDark = false
let colorThemeX = 'Blue'
if (localStorage["colorTheme"]) {
    colorThemeX = localStorage.getItem('colorTheme')
    if (colorThemeX === 'Dark') {
       colorDark = true
    }
}

const MainNavbar = ({ layout, stickyTop, colorDark }) => {
  let classes = null
  if (colorDark) {
    classes = classNames(
      "main-navbar",
      stickyTop && "sticky-top"
    )
  }else{
    classes = classNames(
      "main-navbar",
      "bg-white",
      stickyTop && "sticky-top"
    )
  }

  return (
    <div className={classes}>
      <Container className="p-0"  style={ colorDark ? { backgroundColor:'#0c151d'} : {}}>
        <Navbar className="align-items-stretch flex-md-nowrap p-0" style={ colorDark ? { backgroundColor:'#0c151d'} : {}}>
          <NavbarSearch />
          <NavbarNav />
          <NavbarToggle colorDark={colorDark}/>
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

export default MainNavbar;
