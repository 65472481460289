const currencyFIATPayment =
    {
    "CcyNtry": [
      {
          "CcyNm": "US Dollar",
          "Ccy": "USD"
      }
    ]
}

export default currencyFIATPayment;
