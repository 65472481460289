//React
import React, { Component } from 'react';

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

// Spinning
import LoaderLittle from '../loader/LoaderLittle'

import {username_new} from '../../actions/userActions'
import {getFileDB, deleteFileDB} from '../../actions/mongoDBActions'

class ContractDelete extends Component {

    constructor(props) {
        super(props);
        this.delContract = this.delContract.bind(this);
        this.delUserGroup = this.delUserGroup.bind(this);
        this.delRules = this.delRules.bind(this);
        this.delChat = this.delChat.bind(this);
        this.delDeliverableList = this.delDeliverableList.bind(this);
        this.delDeliverableList2 = this.delDeliverableList2.bind(this);
        this.delDeliverableList3 = this.delDeliverableList3.bind(this);
        this.delDeliverable = this.delDeliverable.bind(this);
        this.delDeliverableFiles = this.delDeliverableFiles.bind(this);
        this.delDeliverableFiles2 = this.delDeliverableFiles2.bind(this);
        this.delAttachList = this.delAttachList.bind(this);
        this.delAttachList2 = this.delAttachList2.bind(this);
        this.delAttachListFiles = this.delAttachListFiles.bind(this);
        this.delAttachListFiles2 = this.delAttachListFiles2.bind(this);
        this.delIoTDevice = this.delIoTDevice.bind(this);
        this.delIoTDevice2 = this.delIoTDevice2.bind(this);
        this.delListContract = this.delListContract.bind(this);
        this.delUploadedDetail = this.delUploadedDetail.bind(this);
        this.delBlankDetail = this.delBlankDetail.bind(this);
        this.delSpreadsheetDetail = this.delSpreadsheetDetail.bind(this);
        this.delHeadPaymentForms = this.delHeadPaymentForms.bind(this);
        this.delDetailPaymentForms = this.delDetailPaymentForms.bind(this);

        this.returnProps = this.returnProps.bind(this);

        this.state = {
          userSession: new UserSession(),
          fullCharge: false,
          username: '',
          user: {},
        }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
           stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
           stacksNetX =  user.profile.stxAddress.testnet
        }
      }

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => {
          this.setState({username: val})
          Promise.all(
              [this.delContract(val),
               this.delUserGroup(val),
               this.delChat(val),
               this.delDeliverableList(val),
               this.delDeliverableList3(val),
               this.delDeliverable(val),
               this.delDeliverableFiles(val),
               this.delAttachList(val),
               this.delAttachList2(val),
               this.delAttachListFiles(val),
               this.delIoTDevice(val),
               this.delRules(val),
               this.delBlankDetail(val),
               this.delUploadedDetail(val),
               this.delSpreadsheetDetail(val),
               this.delHeadPaymentForms(val),
               this.delDetailPaymentForms(val)
              ]
          ).then(
            (resolve) =>{
              this.delListContract(val);
              this.setState({showLoader:false});
              this.setState({fullCharge: true});
            },
            (reject) =>{
              this.delListContract(val);
              this.setState({showLoader:false});
              this.setState({fullCharge: true});
          })
          .catch(error => {
          console.log(error)
          })
        })
      }else{
        this.setState({ username })
        Promise.all(
            [this.delContract(username),
             this.delUserGroup(username),
             this.delChat(username),
             this.delDeliverableList(username),
             this.delDeliverableList3(username),
             this.delDeliverable(username),
             this.delDeliverableFiles(username),
             this.delAttachList(username),
             this.delAttachList2(username),
             this.delAttachListFiles(username),
             this.delIoTDevice(username),
             this.delRules(username),
             this.delBlankDetail(username),
             this.delUploadedDetail(username),
             this.delSpreadsheetDetail(username),
             this.delHeadPaymentForms(username),
             this.delDetailPaymentForms(username)
            ]
          ).then(
            (resolve) =>{
              this.delListContract(username);
              this.setState({showLoader:false});
              this.setState({fullCharge: true});
            },
            (reject) =>{
            this.delListContract(username);
              this.setState({showLoader:false});
              this.setState({fullCharge: true});
          })
          .catch(error => {
            console.log(error)
          });
      }
      //const setCharge = () =>{ this.setState({fullCharge: true});}
    }

    componentDidMount() {

    }

    //--------------------------------------------------------------------------------------------
    delContract(username){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delUserGroup(username){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_usergroup.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delChat(username){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_chat3.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delDeliverableList(username){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      return new Promise ((resolve, reject) =>{
        getFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_deliverablelist6.json`)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
                  let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                  let largo = jsonBlockstack6.length
                  if (largo === 0){resolve()}
                  jsonBlockstack6.map((todoattach2,keyattach2)=>{
                      this.delDeliverableList2(username, todoattach2.id)
                      if (keyattach2 === (largo -1)){
                         resolve()
                      }
                  })
               }else{
                 resolve()
               }
             }else{
               resolve()
             }
           })
           .catch(error => {
             resolve()
           })
        })
    }
    //--------------------------------------------------------------------------------------------
    delDeliverableList2(username,uuid){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      return new Promise ((resolve, reject) =>{
        getFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_${uuid}_attachdeliverable.json`)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
                  let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                  let largo = jsonBlockstack6.length
                  if (largo === 0){resolve()}
                  jsonBlockstack6.map((todoattach2,keyattach2)=>{
                      this.delDeliverableFiles2(username,uuid,todoattach2.id)
                      if (keyattach2 === (largo - 1)){
                         resolve()
                      }
                  })
               }else{
                 resolve()
               }
             }else{
               resolve()
             }
           })
           .catch(error => {
             resolve()
           })
        })
    }
    //--------------------------------------------------------------------------------------------
    delDeliverableList3(username){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_deliverablelist6.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delDeliverable(username){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_attachdeliverable.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delDeliverableFiles(username){
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_attachdeliverable_files.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delDeliverableFiles2(username,uuid,id){
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_${uuid}_${id}_attachdeliverable_files.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delAttachList(username){
      return new Promise ((resolve, reject) =>{
        getFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_attachlist.json`)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
                  let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                  let largo = jsonBlockstack6.length
                  if (largo === 0){resolve()}
                  jsonBlockstack6.map((todoattach2,keyattach2)=>{
                      this.delAttachListFiles2(username,todoattach2.id)
                      if (keyattach2 === (largo - 1)){
                         resolve()
                      }
                  })
              }else{
               resolve()
              }
             }else{
               resolve()
             }
           })
           .catch(error => {
             resolve()
           })
        })
    }
    //--------------------------------------------------------------------------------------------
    delAttachList2(username){
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_attachlist.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delAttachListFiles2(username,id){
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_${id}_attachlist_files.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delAttachListFiles(username){
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_attachlist_files.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delIoTDevice(username){
      return new Promise ((resolve, reject) =>{
        getFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_rules.json`)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
                  let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                  let largo = jsonBlockstack6.length
                  if (largo === 0){resolve()}
                  jsonBlockstack6.map((todo, i) => {
                    this.delIoTDevice2(username,todo.enrollment)
                    if (i === (largo - 1)){
                       resolve()
                    }
                  })
               }else{
                 resolve()
               }
             }else{
               resolve()
             }
           })
            .catch(error => {
              resolve()
           });
      })
    }
    //--------------------------------------------------------------------------------------------
    delIoTDevice2(username,enrollment){
      return new Promise ((resolve2, reject2) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_${enrollment}_device.json`)
          .then(() => {
            resolve2()
          })
          .catch(error => {
            resolve2()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delRules(username){
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_rules.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delBlankDetail(username){
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_blankdetail.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delUploadedDetail(username){
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_uploadeddetail.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delSpreadsheetDetail(username){
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_spreadsheetdetail.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delListContract(username){
       this.props.deleteListContract(this.props.optx,this.props.typeContract,this.props.numberContract)
    }
    //--------------------------------------------------------------------------------------------
    delHeadPaymentForms(username){
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_head_payment_form.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------
    delDetailPaymentForms(username){
      return new Promise ((resolve, reject) =>{
        deleteFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_detail_payment_form.json`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            resolve()
          })
      })
    }
    //--------------------------------------------------------------------------------------------

    returnProps(){
    }

    render() {
      if (!this.state.fullCharge) {
         return(
           <div>
              <LoaderLittle />
           </div>
         )
      }else{
        return(
          <div>
          </div>
        )
      }
    }
}

export default ContractDelete;
