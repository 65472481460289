/* config.js */

const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uIjoiN2M1MGQ5NWYzMzZjNjJmOGNiZWU5ODAwYjA0NjkxMDAyYzJiMTk0ODlhNjgyMTE1YjI3OGZjM2Q2ZjY3ZjMzN2IxYmRiN2IyYjE1ZGRjZTIwNzFlY2MyZDUyMTZhNmJmYzkxNWY2MDllYjBlNzA0MDhjOWEzYWNkYmU3NzkxNjYxYjUwZDIzODkwMTBhOTY1ODAzYjdjMTlkMWNlZDkwMTg1NGNhMmZlMDQ3OTg2MzBhNTQwZDY5NmFhNGFhZTcxMjFmNTJhN2FjNmE1NWVjMjMwMDcxMzYwZDQ3ZDc2ZTA4NWUxMDE4OGFmYjE4NzY4YjAxZTJjNDZhMmE4ZDAzODAyZWZhYmUyZDRjMmMwOTM5OWI4MTM0MmJhODljOWIzMDljOTVlZmMwMDU4OTgyZDFlMTA5OGFiZmFiNjQ5NWFlMDE4M2NiOWJjOWIzMTBkNzUyMjRlMmVjNzA5ZTIxYjQ4YzY0NzA1ZGExNjFlMDUyZWFkZTUwMDc0N2Y1NzZhNzUxNzU1NDY0YmE5MWIxYjUzZjE1MWNhMmFkMzAyNWU5Yzk0MDE2NGVhMTk0MTMxMDkzNzUxY2Y5OWNhMTk4NjU3YWY3MTk4MjI2Y2FlOTRhNzM4Mjk0MTU3Nzg0MDM2ZDU5YjdiOTQyMjI4ZTZjNTQ2OTgxM2Q4OGFlOTBkNmE3NWQ1Nzg3MmJmYWY2YTJkN2U5YjY1NzczY2U0M2MzZGQ3NWQwNTZmYTA3M2UzN2JiOTBmNzRmM2ZjZWZhNWNjM2U3ZTBjODM0NTVlNWViMzBmMThlMTk4M2JiZGE3ZmVlZDBjZWY4MzE2MjQ5YTZkMzY4MzM4OTk3NmQ2OWRjMThhZTA4MTA5YzUzOWUyOTFkNDEwZDVlZjdkMjEwOGQ1NGFhYmNlNWQyYmU4NDkiLCJpYXQiOjE1NzExNjU1MDF9.uzMiFhd_3vjm9YT2jtFIdGLramx2uzpjuate-Hgo1wM'

export {token}

export const STACKS_API_URL = process.env.STACKS_API_URL

export const SWAPR_STX = process.env.SWAPR_STX

//MainNet STX
export const SMART_CONTRACT_ADDRESS_MAINNET_STX = "SP3YK7KWMYRCDMV5M4792T0T7DERQXHJJGGEPV1N8"
export const SMART_CONTRACT_NAME_MAINNET_STX = "cc-sip010-stx"

//MainNet XCK
export const SMART_CONTRACT_ADDRESS_MAINNET_XCK = "SP3YK7KWMYRCDMV5M4792T0T7DERQXHJJGGEPV1N8"
export const SMART_CONTRACT_NAME_MAINNET_XCK = "cc-sip010-xck-v1"

//MainNet MXM
export const SMART_CONTRACT_ADDRESS_MAINNET_MXM = "SP13MW8Z069PPZ2F0W21X9390XDZ0PYVDK59KHQVY"
export const SMART_CONTRACT_NAME_MAINNET_MXM = "cc-sip010-mxm-v1"

//MainNet MIA
export const SMART_CONTRACT_ADDRESS_MAINNET_MIA = "SP1H1733V5MZ3SZ9XRW9FKYGEZT0JDGEB8Y634C7R"
export const SMART_CONTRACT_NAME_MAINNET_MIA = "miamicoin-token-v2"

//MainNet NYC
export const SMART_CONTRACT_ADDRESS_MAINNET_NYC = "SPSCWDV3RKV5ZRN1FQD84YE1NQFEDJ9R1F4DYQ11"
export const SMART_CONTRACT_NAME_MAINNET_NYC = "newyorkcitycoin-token-v2"

//MainNet FRIE
export const SMART_CONTRACT_ADDRESS_MAINNET_FRIE = "SPN4Y5QPGQA8882ZXW90ADC2DHYXMSTN8VAR8C3X"
export const SMART_CONTRACT_NAME_MAINNET_FRIE = "friedger-token-v1"

//TestNet STX
export const SMART_CONTRACT_ADDRESS_TESTNET_STX = "ST3YK7KWMYRCDMV5M4792T0T7DERQXHJJGHT96SVC"
export const SMART_CONTRACT_NAME_TESTNET_STX = "cc-ft-stx"

//MainNet Arkadiko DIKO
export const SMART_CONTRACT_ADDRESS_MAINNET_DIKO = "SP2C2YFP12AJZB4MABJBAJ55XECVS7E4PMMZ89YZR"
export const SMART_CONTRACT_NAME_MAINNET_DIKO = "arkadiko-token"

export const MAINNET_STACKS_API_URL = "https://api.hiro.so"
export const TESTNET_STACKS_API_URL = "https://api.hiro.so"
export const MAINNET_STACKS_API_URL_MONGODB = "https://domains.paradigma.global/api"
export const TESTNET_STACKS_API_URL_MONGODB = "https://domains.paradigma.global/api"
