//React
import React, { Component } from 'react';

//UI
import { Card,CardHeader,Button,ListGroup,ListGroupItem,Row,Col,Form,FormInput,FormTextarea,FormSelect,Container} from "shards-react";
import { Table } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";

//UUID
import uuid from 'uuid';

//components
import ContractDrawerChatNew from './ContractDrawerChatNew';
import TituloPagina2 from "../common/TituloPagina2";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//translate
import { FormattedMessage } from 'react-intl';

//base64
import { Base64 } from 'js-base64';

//Axios
import axios from 'axios';

//Socket.IO
import io from 'socket.io-client';

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

//Configuración
import { MAINNET_STACKS_API_URL,
         TESTNET_STACKS_API_URL } from '../../config.js'

const socket = io('https://domains.paradigma.global/socket.io', { reconnection: false, forceNew: true  }); // Cambia la URL por la de tu servidor

class ContractDrawerChat extends Component {

    constructor(props) {
        super(props);

        this.displayListChat = this.displayListChat.bind(this);
        this.displayConfigChat = this.displayConfigChat.bind(this);
        this.displayConfigChat2 = this.displayConfigChat2.bind(this);
        this.displayPaginacion = this.displayPaginacion.bind(this);
        this.listChat = this.listChat.bind(this);
        this.listChat2 = this.listChat2.bind(this);
        this.goChatUserGroupRemoto = this.goChatUserGroupRemoto.bind(this);
        this.sendChat = this.sendChat.bind(this);
        this.clickRemoveChat = this.clickRemoveChat.bind(this);
        this.pageContracts = this.pageContracts.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.keyForDecrypt = this.keyForDecrypt.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          scrollInitial: 1,
          scrollPrevious: 0,
          scrollNext: 0,
          scrollEnd: 25,
          scrollLength: 0,
          pageContracts: 25,
          language: 'English',
          jsonChat: [],
          jsonBlockstackZ60: [],
          jsonBlockstackZ70: [],
          jsonBlockstackZ50: [],
          jsonBlockstackZ50a: [],
          jsonBlockstackZ50b: [],
          jsonBlockstackZ50c: [],
          id: '',
          url: 'https://gaia.blockstack.org/hub/',
          url2: 'https://core.blockstack.org/v1/users/',
          url3: 'https://core.blockstack.org/v1/names/',
          identityAddress: undefined,
          decentralizedID: undefined,
          arrow: true,
          publicKey: '',
          networkUrlX: '',
          nameX: '',
          avatarX: '',
          existListDeliverableX: true,
          messageUuidX: '',
        }
    }

    cContractByPage = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
           stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
        }
        if (serverStacksX === 'TestNet'){
           stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }

      const {identityAddress} = userSession.loadUserData();
      const {decentralizedID} = userSession.loadUserData();

      this.setState({ identityAddress, decentralizedID, networkUrlX })

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val},()=>{
          Promise.all([this.listChat(val)])
             .then(
                (resolve) => {},
                (reject) => {}
             )
        })) ;
      }else{
        this.setState({ username },()=>{
          Promise.all([this.listChat(username)])
             .then(
                (resolve) => {},
                (reject) => {}
             )

        })
      }
    }

    componentDidUpdate = () => {
      let messageUuid = '',messageUserId = '',messageTypeContract = '',messageNumberContract = '',messageDateTime = '',messageChat = '',messageAction = ''
      let jsonBlockstack4b = ''
      let jsonBlockstack4c = ''
      let jsonBlockstack4d = ''
      socket.on('chat message', async (msg) => {
        let msgObject = JSON.parse(msg)
        let {messageUuid,messageUserId,messageTypeContract,messageNumberContract,messageDateTime,messageChat,messageAction} = msgObject
        try {
          if (messageTypeContract === this.props.typeContract && messageNumberContract === this.props.numberContract && messageUserId !== this.state.username && messageAction === 'insert'){
              if (messageUuid !== this.state.messageUuidX) {
                  let salida = this.state.jsonBlockstackZ80
                  this.setState({messageUuidX: messageUuid})
                  this.setState({existListDeliverableX: true})
                  Promise.all([this.readRemoteNameAvatar(messageUserId)])
                    .then(
                      (resolve) =>{
                          salida.push({id:messageUuid, origin:messageUserId, date:messageDateTime, message:messageChat, name:resolve[0].nameX, avatar:resolve[0].avatarX})
                          this.setState({jsonBlockstackZ80:salida})
                          jsonBlockstack4b = this.state.jsonBlockstackZ80
                          this.setState({jsonBlockstackZ50b: jsonBlockstack4b})
                          jsonBlockstack4c = this.state.jsonBlockstackZ50a
                          jsonBlockstack4d = jsonBlockstack4c.concat(jsonBlockstack4b)
                          this.setState({jsonBlockstackZ50: jsonBlockstack4d})
                          this.setState({scrollLength: jsonBlockstack4d.length})
                      },
                      (reject)  =>{
                          salida.push({id:messageUuid, origin:messageUserId, date:messageDateTime, message:messageChat, name:'', avatar:''})
                          this.setState({jsonBlockstackZ80:salida})
                          jsonBlockstack4b = this.state.jsonBlockstackZ80
                          this.setState({jsonBlockstackZ50b: jsonBlockstack4b})
                          jsonBlockstack4c = this.state.jsonBlockstackZ50a
                          jsonBlockstack4d = jsonBlockstack4c.concat(jsonBlockstack4b)
                          this.setState({jsonBlockstackZ50: jsonBlockstack4d})
                          this.setState({scrollLength: jsonBlockstack4d.length})
                      }
                  )
              }
           }
        }
        catch (e) {
            console.log(e)
            return
        }
      })
    }

    handleChange(e,chk) {
      const newState = {};
      newState[chk] = !this.state[chk];

      if (chk==='arrow' && this.state.arrow===true){
        this.setState({arrow:false})
      }else{
        if (chk==='arrow' && this.state.arrow===false){
          this.setState({arrow:true})
        }
      }
    }

    pageContracts(){
      this.setState({pageContracts:parseInt(this.cContractByPage.current.value)})
      this.setState({scrollEnd:parseInt(this.cContractByPage.current.value)})
      this.setState({scrollInitial: 1})
      this.listChat(this.state.username)
    }

    completaNameAndAvatar = async (jsonUserGroup,username) => {
      const salida = []
      const resultado = await Promise.all(jsonUserGroup.map( async (todo) => {
         let userX = username
         if (todo.origin !== username){
            userX = todo.origin
         }
         return await Promise.all([this.readRemoteNameAvatar(userX)])
           .then(
             (resolve) =>{
                 salida.push({id:todo.id, origin:todo.origin, date:todo.date, message:todo.message, name:resolve[0].nameX, avatar:resolve[0].avatarX})
             },
             (reject)  =>{
                 salida.push({id:todo.id, origin:todo.origin, date:todo.date, message:todo.message, name:'', avatar:''})
             }
           )
      }))
      this.setState({jsonBlockstackZ50: salida},()=>{})
    }

    readRemoteNameAvatar = (userX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      let options = {}
      if (userX === '') {
         options = { decrypt: false, verify: false }
      }else{
        options = { username: userX, decrypt: false, verify: false }
      }
      return new Promise ((resolve, reject) =>{
        getFileDB(userX, `myUserConfig.json`)
           .then((fileContents) => {
             if (fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               resolve({nameX: jsonBlockstack6.profileName, avatarX: jsonBlockstack6.profileAvatar})
             }else{
               reject()
             }
           })
          .catch(error => {
            console.log(error)
        });
      });
    }

    listChat(username){
        this.setState({jsonBlockstackZ60: []})
        this.setState({jsonBlockstackZ70: []})
        this.setState({jsonBlockstackZ50: []})
        this.setState({jsonBlockstackZ50a: []})
        this.setState({jsonBlockstackZ50b: []})
        this.setState({jsonBlockstackZ50c: []})
        Promise.all(
           [this.listChat2(this.props.typeContract, this.props.numberContract,username,username)]
         ).then(
           (resolve) =>{
               let originX2 = this.props.userOrigin
               if (this.props.userOrigin === username){
                  originX2 = username
               }
               this.goChatUserGroupRemoto(this.props.typeContract, this.props.numberContract, originX2, username)
           },
           (reject) =>{
           }
        )
    }

    goChatUserGroupRemoto(typeContract, configurationContractNumber, originX, username){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      let options = ''
      if (originX === ''){options = { decrypt: false, verify: false }}else{options = { username: originX, decrypt: false, verify: false }}
      let jsonBlockstack4d = []
      new Promise ((resolve4e, reject4e) =>{
          getFileDB(originX, `${typeContract}_${configurationContractNumber}_usergroup.json`)
           .then((fileContents) => {
             if(fileContents) {
                if (fileContents !== '[]' && fileContents !== ''){
                  const jsonBlockstack1 = JSON.parse(fileContents)
                  const jsonBlockstack2 = jsonBlockstack1.data
                  let jsonBlockstack5 = '[]'
                  if (jsonBlockstack2 !== null){
                     const jsonBlockstack3 = jsonBlockstack2.dataobject
                     const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                     if (jsonBlockstack4.substring(0,1) === '"') {
                        jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                     } else {
                        jsonBlockstack5 = jsonBlockstack4
                     }
                  }
                  let jsonBlockstack6 = JSON.parse(jsonBlockstack5)

                  jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                  const resultado = Array.from(new Set(jsonBlockstack4d.map(s => s.id)))
                     .map(id => {
                         return {
                             id: jsonBlockstack4d.find(s => s.id === id).id,
                             identityAddress: jsonBlockstack4d.find(s => s.id === id).identityAddress
                         };
                     });
                  jsonBlockstack4d = resultado
                  this.setState({jsonBlockstackZ70: jsonBlockstack4d})
                  const largo = jsonBlockstack4d.length
                  jsonBlockstack4d.map ((todo3, key3) => {
                      //------------------------------------------------------------------------------------------
                      if (todo3.id !== username){
                          Promise.all([this.keyForDecrypt(todo3.id)]).then(
                            (resolve) =>{this.listChat2(typeContract,configurationContractNumber,todo3.id,username)},
                            (reject) =>{})
                      }
                      //------------------------------------------------------------------------------------------
                      if (key3 === largo -1){
                        resolve4e()
                      }
                  })
                }else{
                  //console.log('goChatUserGroupRemoto')
                  //console.log('else 1')
                  resolve4e()
                }
             } else {
               //console.log('goChatUserGroupRemoto')
               //console.log('else 2')
                resolve4e()
             }
           })
           .catch(error => {
             console.log(error)
              resolve4e()
           });
      })
    }

    keyForDecrypt(userX) {
        this.keyForDecryptLegacy(userX)
    }

    keyForDecryptLegacy = (userX) => {
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = { username: userX, decrypt: false, verify: false }
        this.setState({ publicKey: '' })
        return new Promise((resolve1, reject1) => {
            getFileDB(userX, `publickey2.json`)
               .then((fileContents) => {
                 if(fileContents) {
                   const jsonBlockstack1 = JSON.parse(fileContents)
                   const jsonBlockstack2 = jsonBlockstack1.data
                   let jsonBlockstack5 = '[]'
                   if (jsonBlockstack2 !== null){
                      const jsonBlockstack3 = jsonBlockstack2.dataobject
                      const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                      if (jsonBlockstack4.substring(0,1) === '"') {
                         jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                      } else {
                         jsonBlockstack5 = jsonBlockstack4
                      }
                   }
                   let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                   this.setState({publicKey: jsonBlockstack6.publicKey2},() =>{resolve1();});
                 } else {
                   resolve1()
                 }
               })
                .catch(error => {
                  console.log(error)
                  this.keyForDecrypt2(userX)
                  resolve1()
               });
        });
    }


    keyForDecrypt2(userX){
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = { username: userX, decrypt: false, verify: false }
        this.setState({ publicKey: '' })
        return new Promise((resolve1, reject1) => {
              getFileDB(userX, `public_key.json`)
               .then((fileContents) => {
                 if(fileContents) {
                   const jsonBlockstack1 = JSON.parse(fileContents)
                   const jsonBlockstack2 = jsonBlockstack1.data
                   let jsonBlockstack5 = '[]'
                   if (jsonBlockstack2 !== null){
                      const jsonBlockstack3 = jsonBlockstack2.dataobject
                      const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                      if (jsonBlockstack4.substring(0,1) === '"') {
                         jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                      } else {
                         jsonBlockstack5 = jsonBlockstack4
                      }
                   }
                   let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                   this.setState({publicKey: jsonBlockstack6.publicKey2},() =>{resolve1();});
                 } else {
                   resolve1()
                 }
               })
                .catch(error => {
                  console.log(error)
                  resolve1()
               });
        });
    }

    listChat2(typeContract,numberContract,userX,username){
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        let options = ''
        let user2X = userX
        if (this.props.encryptDecryptFile === 'Yes' && this.props.encryptDecryptFile === true){
          if (userX === username){user2X = username}
        }else{
          if (userX === username){user2X = username}
        }

        let jsonBlockstack4 = []
        return new Promise ((resolve1, reject1) =>{
            getFileDB(user2X, `${typeContract}_${numberContract}_chat3.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 if (userX === username){
                     let jsonBlockstack4b = []
                     this.setState({jsonBlockstackZ50c: jsonBlockstack6})
                     this.setState({jsonBlockstackZ60: jsonBlockstack6})
                     this.setState({jsonBlockstackZ50a: jsonBlockstack6})
                     jsonBlockstack4b = jsonBlockstack6.concat(this.state.jsonBlockstackZ50b)
                     this.setState({jsonBlockstackZ50: jsonBlockstack4b})
                     this.setState({scrollLength: jsonBlockstack4b.length})
                     this.completaNameAndAvatar(jsonBlockstack4b,username)
                 }else{
                     let jsonBlockstack4b = []
                     let jsonBlockstack4c = []
                     this.setState({jsonBlockstackZ80: jsonBlockstack6})
                     jsonBlockstack4b = this.state.jsonBlockstackZ50b.concat(jsonBlockstack6)
                     this.setState({jsonBlockstackZ50b: jsonBlockstack4b})
                     jsonBlockstack4c = this.state.jsonBlockstackZ50a.concat(jsonBlockstack4b)
                     this.setState({jsonBlockstackZ50: jsonBlockstack4c})
                     this.setState({scrollLength: jsonBlockstack4b.length})
                     this.setState({existListDeliverableX: true})
                     this.completaNameAndAvatar(jsonBlockstack4c,username)
                 }
                 resolve1()
               } else {
                 resolve1()
               }
             })
              .catch(error => {
                console.log(error)
                resolve1()
             })
        })
    }

    displayListChat(){
          let jsondisplayListChat = this.state.jsonBlockstackZ50.sort(function(a, b) {
              // Use toUpperCase() to ignore character casing
              let mesA = a.date.substring(4,7).toUpperCase()
              if (mesA === 'JAN'){mesA='01'}
              if (mesA === 'FEB'){mesA='02'}
              if (mesA === 'MAR'){mesA='03'}
              if (mesA === 'APR'){mesA='04'}
              if (mesA === 'MAY'){mesA='05'}
              if (mesA === 'JUN'){mesA='06'}
              if (mesA === 'JUL'){mesA='07'}
              if (mesA === 'AGO'){mesA='08'}
              if (mesA === 'SEP'){mesA='09'}
              if (mesA === 'OCT'){mesA='10'}
              if (mesA === 'NOV'){mesA='11'}
              if (mesA === 'DEC'){mesA='12'}
              let mesB = b.date.substring(4,7).toUpperCase()
              if (mesB === 'JAN'){mesB='01'}
              if (mesB === 'FEB'){mesB='02'}
              if (mesB === 'MAR'){mesB='03'}
              if (mesB === 'APR'){mesB='04'}
              if (mesB === 'MAY'){mesB='05'}
              if (mesB === 'JUN'){mesB='06'}
              if (mesB === 'JUL'){mesB='07'}
              if (mesB === 'AGO'){mesB='08'}
              if (mesB === 'SEP'){mesB='09'}
              if (mesB === 'OCT'){mesB='10'}
              if (mesB === 'NOV'){mesB='11'}
              if (mesB === 'DEC'){mesB='12'}
              const bandA = a.date.substring(11,15).toUpperCase()+mesA+a.date.substring(8,10).toUpperCase()+' '+a.date.substring(16,25).toUpperCase();
              const bandB = b.date.substring(11,15).toUpperCase()+mesB+b.date.substring(8,10).toUpperCase()+' '+b.date.substring(16,25).toUpperCase()
              let comparison = 0;
              if (bandA > bandB) {
                comparison = 1;
              } else if (bandA < bandB) {
                comparison = -1;
              }
              return comparison
          })

          let avatar = ''
          const avatar2 = 'images/avatar.png'
          return (
            <div>
              <Table responsive>
                <thead>
                  <tr>
                  </tr>
                </thead>
                <tbody>
                   {jsondisplayListChat.map((todo, i) => {
                     const message = Base64.decode(todo.message);
                     let linea = '_'
                     let displayDeleteX2 = ""
                     if (todo.origin === this.state.username){
                         displayDeleteX2 = "images/deleteRed.png"
                     }
                     const palabra = todo.origin
                     const ArrayIdValue = palabra.split('.blockstack')
                     avatar = ''
                     if (todo.avatar === undefined || todo.avatar === null || todo.avatar === ''){
                       if (ArrayIdValue.length===2){
                          avatar = `${this.state.url}${todo.identityAddress}//avatar-0`
                       }else{
                          avatar = `${this.state.url}${todo.identityAddress}/0//avatar-0`
                       }
                     }else{
                       avatar = Base64.decode(todo.avatar)
                     }

                     return (
                       <React.Fragment>
                         <tr>
                           <React.Fragment>
                              <td style={{fontSize:12, color:'red'}}>
                                <ReactImageFallback
                                     src={avatar}
                                     fallbackImage={avatar2}
                                     initialImage={avatar2}
                                     alt={" "}
                                     className="rounded-circle"
                                     width="20"
                                />
                                &nbsp;&nbsp;&nbsp;
                                [{todo.origin}]
                                &nbsp;&nbsp;&nbsp;
                                {todo.date.substring(4,25)}
                                <br></br>
                                <strong style={{cursor: 'pointer'}} onClick={e=>this.clickRemoveChat(e,i,todo.id)}><img src={displayDeleteX2} weight="15" height="15" alt=""/></strong>
                                &nbsp;&nbsp;
                                <span style={ this.props.colorDark ? { fontSize:14, color:'white'} : {fontSize:14, color:'black'}}>{message}</span>
                              </td>
                           </React.Fragment>
                         </tr>
                         <tr>
                            &nbsp;&nbsp;
                         </tr>
                       </React.Fragment>
                    )})}
                </tbody>
              </Table>
            </div>
          )
    }

    displayConfigChat() {
        const {userlistRemote} = this.state
        const myContactX = this.state.mycontact
        return (
          <div>
            <Table borderless size="sm">
              <tbody>
                <tr>
                  <td>
                    <FormSelect
                        id="ContractByPage"
                        innerRef={this.cContractByPage}
                        placeholder=""
                        onChange={this.pageContracts}
                        style={{backgroundColor:"#F0EFEC"}}
                      >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </FormSelect>
                  </td>
                  <td>
                     <Button outline pill theme="warning" size="sm" className="mb-2" onClick={this.listChat}>
                         <i className="material-icons mr-1">replay</i> <FormattedMessage id="contract.refresh" />
                     </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        )
    }

    displayConfigChat2() {
        const {userlistRemote} = this.state
        const myContactX = this.state.mycontact
        return (
          <div>
            <Table borderless size="sm" className="text-center" b>
              <tbody>
                <tr>
                  <td>
                     <Button outline pill theme="warning" size="sm" className="mb-2" onClick={this.listChat}>
                         <i className="material-icons mr-1">replay</i> <FormattedMessage id="contract.refresh" />
                     </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        )
    }

    displayPaginacion() {
            let scrollAuxiliar = 0
            let scrollAuxiliar2 = this.state.scrollEnd
            if (this.state.scrollEnd > this.state.scrollLength) {
               scrollAuxiliar2 = this.state.scrollLength
            }
            let mailPageX = `Message ${this.state.scrollInitial} to ${scrollAuxiliar2} of ${this.state.scrollLength}`
            if (this.state.language === 'English'){
               mailPageX = `Message ${this.state.scrollInitial} to ${scrollAuxiliar2} of ${this.state.scrollLength}`
            }
            if (this.state.language === 'French'){
              mailPageX = `Message ${this.state.scrollInitial} une ${scrollAuxiliar2} de ${this.state.scrollLength}`
            }
            if (this.state.language === 'Spanish'){
              mailPageX = `Mensaje ${this.state.scrollInitial} a ${scrollAuxiliar2} de ${this.state.scrollLength}`
            }
            if (this.state.language === 'Portuguese'){
              mailPageX = `Mensagem ${this.state.scrollInitial} um ${scrollAuxiliar2} do ${this.state.scrollLength}`
            }
            if (this.state.language === 'Swedish'){
              mailPageX = `Meddelande ${this.state.scrollInitial} en ${scrollAuxiliar2} av ${this.state.scrollLength}`
            }
            if (this.state.language === 'Netherlands'){
              mailPageX = `Bericht ${this.state.scrollInitial} een ${scrollAuxiliar2} van ${this.state.scrollLength}`
            }
            if (this.state.language === 'Russian'){
              mailPageX = `Сообщение ${this.state.scrollInitial} в ${scrollAuxiliar2} из ${this.state.scrollLength}`
            }
            if (this.state.language === 'Japanese'){
              mailPageX = `メッセージ ${this.state.scrollInitial} 私は ${scrollAuxiliar2} の ${this.state.scrollLength}`
            }
            if (this.state.language === 'Chinese'){
              mailPageX = `信息 ${this.state.scrollInitial} 至 ${scrollAuxiliar2} 的 ${this.state.scrollLength}`
            }
            if (this.state.language === 'German'){
              mailPageX = `Botschaft ${this.state.scrollInitial} Ich bin ${scrollAuxiliar2} von ${this.state.scrollLength}`
            }
            if (this.state.language === 'Italian'){
              mailPageX = `Messaggio ${this.state.scrollInitial} a ${scrollAuxiliar2} di ${this.state.scrollLength}`
            }

            return (
              <div>
                <Table size="sm" className="text-center">
                  <tbody>
                      <tr>
                        <td>{mailPageX}</td>
                        <td><strong style={{cursor: 'pointer'}} onClick={()=>{
                                if (this.state.scrollInitial > this.state.pageContracts){
                                   this.setState({scrollInitial:1})
                                   this.setState({scrollEnd:this.state.pageContracts})
                                   this.listChat(this.state.username)
                                }
                            }}><img src="images/initial.png" weight="15" height="15" alt=""/></strong></td>
                        <td><strong style={{cursor: 'pointer'}} onClick={()=>{
                                if (this.state.scrollInitial > this.state.pageContracts){
                                  scrollAuxiliar = this.state.scrollInitial - this.state.pageContracts
                                  if (scrollAuxiliar<1){
                                  }else{
                                    this.setState({scrollInitial:scrollAuxiliar})
                                    this.setState({scrollEnd:scrollAuxiliar + (this.state.pageContracts - 1)})
                                  }
                                  this.listChat(this.state.username)
                                }
                            }}><img src="images/previous.png" weight="20" height="20" alt=""/></strong></td>
                            <td><strong style={{cursor: 'pointer'}} onClick={()=>{
                                    if (this.state.scrollInitial + this.state.pageContracts <= this.state.scrollLength){
                                      scrollAuxiliar = this.state.scrollInitial + this.state.pageContracts
                                      this.setState({scrollEnd:scrollAuxiliar + (this.state.pageContracts - 1)})
                                      this.setState({scrollInitial:scrollAuxiliar})
                                      this.listChat(this.state.username)
                                    }
                            }}><img src="images/next.png" weight="20" height="20" alt=""/></strong></td>
                        <td><strong style={{cursor: 'pointer'}} onClick={()=>{
                                  if (this.state.scrollInitial + this.state.pageContracts <= this.state.scrollLength) {
                                    scrollAuxiliar = parseInt(this.state.scrollLength / this.state.pageContracts)
                                    scrollAuxiliar = (scrollAuxiliar * this.state.pageContracts) + 1
                                    this.setState({scrollInitial:scrollAuxiliar})
                                    this.setState({scrollEnd:this.state.scrollLength})
                                    this.listChat(this.state.username)
                                  }
                            }}><img src="images/end.png" weight="15" height="15" alt=""/></strong></td>
                      </tr>
                  </tbody>
                </Table>
              </div>
            )
    }

    clickRemoveChat = (e,optx,id) => {
      let jsonAttachFileX = this.state.jsonBlockstackZ60
      let jsonAttachFileX2 = ''
      let data = {}
      let enviaX = false
      jsonAttachFileX.map((todo,key)=>{
          if (todo.id !== id){
            if (jsonAttachFileX2 === ''){
              jsonAttachFileX2=`{"id":"${todo.id}","origin":"${todo.origin}","date":"${todo.date}","message":"${todo.message}"}`
            }else{
              jsonAttachFileX2=`${jsonAttachFileX2},{"id":"${todo.id}","origin":"${todo.origin}","date":"${todo.date}","message":"${todo.message}"}`
            }
          }else{
            data = JSON.stringify({messageUuid:todo.id,messageUserId:this.state.username,messageTypeContract:this.props.typeContract,messageNumberContract:this.props.numberContract,messageDateTime:todo.date,messageChat:todo.message,messageAction:'delete'})
            enviaX = true
          }
      })
      if (jsonAttachFileX2 === ''){
        jsonAttachFileX = `[]`
      }else{
        jsonAttachFileX=`[${jsonAttachFileX2}]`
      }
      jsonAttachFileX = JSON.parse(jsonAttachFileX)
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_chat3.json`, 'Crosscheck_Chat', JSON.stringify(jsonAttachFileX), JSON.stringify(options))
          .then(() => {
            if (enviaX) {
               socket.emit('chat message', data);
            }
            this.listChat(this.state.username)
          })
    }

    sendChat(message,jsonBlockstackZ60){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      const dateNow = new Date()
      const id = uuid()
      const message64 = Base64.encode(message);
      let jsonAttachFile = jsonBlockstackZ60
      if (jsonAttachFile.length > 0){
          let jsonBlockstack3 = JSON.stringify(jsonAttachFile)
          if (jsonBlockstack3.substring(0,1)==='"') {
             jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
          }
          if (jsonBlockstack3.substring(0,1)==='[') {
             jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
          }
          jsonAttachFile =`${jsonBlockstack3},{"id":"${id}","origin":"${this.state.username}","date":"${dateNow}","message":"${message64}"}`
      }else{
          jsonAttachFile =`{"id":"${id}","origin":"${this.state.username}","date":"${dateNow}","message":"${message64}"}`
      }
      jsonAttachFile =`[${jsonAttachFile}]`
      jsonAttachFile = JSON.parse(jsonAttachFile)
      this.setState({jsonBlockstackZ60: jsonAttachFile})
      putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_chat3.json`, 'Crosscheck_Chat', JSON.stringify(jsonAttachFile), JSON.stringify(options))
        .then(() => {
            const data = JSON.stringify({messageUuid:id,messageUserId:this.state.username,messageTypeContract:this.props.typeContract,messageNumberContract:this.props.numberContract,messageDateTime:`${dateNow}`,messageChat:message64,messageAction:'insert'})
            socket.emit('chat message', data);
            this.listChat(this.state.username)
        })
    }

    render() {
          let imgarrow = ''
          if (this.state.arrow===true){imgarrow='images/arrowdown.png'}else{imgarrow='images/arrowup.png'}
          return (
            <div>
               <Container fluid className="main-content-container px-2" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                    <Card className="text-uppercase" style={ this.props.colorDark ? { backgroundColor:'#233b58' } : { backgroundColor:'#e9ecef' }} >
                        <table>
                          <tbody>
                            <tr>
                              <td style={ this.props.colorDark ? {width: "95%", color: "grey"} : {width: "95%"}}>
                                 &nbsp;&nbsp;
                                 <strong style={{cursor: 'pointer'}} onClick={e => this.handleChange(e,"arrow")}><img src={imgarrow} weight="20" height="20" alt=""/></strong>
                                 &nbsp;&nbsp;
                                 <FormattedMessage id="configuration.chat" />
                              </td>
                              <td style={{width: "5%"}}>
                                 <strong style={{cursor: 'pointer'}} onClick={e => this.handleChange(e,"arrow")}><img src={imgarrow} weight="20" height="20" alt=""/></strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                    </Card>
                    {this.state.arrow ?
                      <>
                        <Row>&nbsp;</Row>
                        {/*
                        <Row form>
                          <Col md="0"></Col>
                          <Col md="12" style={{fontSize:10}}>
                              {this.displayConfigChat2()}
                          </Col>
                        </Row>
                        */}
                        <Row form>
                          <Col md="0"></Col>
                          <Col md="12" style={{fontSize:11}}>
                              {this.displayListChat()}
                          </Col>
                        </Row>
                      {/*
                       <Row form>
                         <Col md="0"></Col>
                         <Col md="12" style={{fontSize:10}}>
                             {this.displayPaginacion()}
                         </Col>
                       </Row>
                       */}
                       <Row>
                         <Col md="0"></Col>
                         <Col md="12">
                              <ContractDrawerChatNew from={this.state.username}
                                                     id={this.state.id} view={this.state.view}
                                                     jsonBlockstackZ60={this.state.jsonBlockstackZ60}
                                                     sendChat={this.sendChat}
                                                     jsonBlockstackZ70={this.state.jsonBlockstackZ70}
                                                     listChat={this.listChat}
                              />
                         </Col>
                       </Row>
                     </>
                  : null }
              </Container>
            </div>
          );
     }
}

export default ContractDrawerChat;
