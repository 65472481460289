import React from "react";
import { withRouter } from "react-router-dom";

import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

import {username_new} from '../../../../actions/userActions'
import {getFileDB, putFileDB} from '../../../../actions/mongoDBActions'
import {profileUser} from '../../../../actions/gaiaActions'

import { Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';

import { Card } from "shards-react";

import { FormSelect, Button } from "shards-react";

import forge from 'node-forge'

//translate
import { FormattedMessage } from 'react-intl';

function ConfirmacionNetwork(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog(props.dialogTitle, 'cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.dialogMessage}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <br></br>
                    <br></br>
                    {props.optMainNet ?
                      <FormSelect
                          id="ModeSetting"
                          placeholder="Select an Option"
                          onChange={e=>props.handleNetwork(e)}
                      >
                        <option value="MainNet" selected>MainNet</option>
                        <option value="TestNet">TestNet</option>
                      </FormSelect>
                    :
                      <FormSelect
                          id="ModeSetting"
                          placeholder="Select an Option"
                          onChange={e=>props.handleNetwork(e)}
                      >
                        <option value="MainNet">MainNet</option>
                        <option value="TestNet" selected>TestNet</option>
                      </FormSelect>

                    }
                    <br></br>
                    <br></br>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class Network extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      userSession: new UserSession(),
      username: '',
      user: {},
      languaje: 'English',
      serverStacks: 'MainNet',
      dialogOpen: false,
      dialogMessage: '',
      dialogTitle: '',
      placeholderX1: '',
      optionNetwork: '',
    };

    this.changeNetwork = this.changeNetwork.bind(this);
    this.confirmChangeNetwork = this.confirmChangeNetwork.bind(this);
  }

  componentDidMount() {
    const { userSession } = this.state
    let stacksNetX = ''
    let serverStacksX = ''
    if (userSession.isUserSignedIn()) {
      const user = userSession.loadUserData()
      this.setState({ user })
      if (!localStorage["serverStacks"]) {
        serverStacksX = 'MainNet'
      }else{
        serverStacksX = localStorage.getItem('serverStacks')
      }
      if (serverStacksX === 'MainNet'){
         stacksNetX =  user.profile.stxAddress.mainnet
      }
      if (serverStacksX === 'TestNet'){
         stacksNetX =  user.profile.stxAddress.testnet
      }
    }
    const {username} = userSession.loadUserData();
    if (username === '' || username === undefined || username === null){
      username_new(stacksNetX).then(val => this.setState({username: val})) ;
    }else{
      this.setState({username })
    }

    const {identityAddress} = userSession.loadUserData();
    this.setState({ identityAddress })
  }


  UNSAFE_componentWillMount() {
    const { userSession } = this.state
    let stacksNetX = ''
    let serverStacksX = ''
    if (userSession.isUserSignedIn()) {
      const user = userSession.loadUserData()
      this.setState({ user })
      if (!localStorage["serverStacks"]) {
        serverStacksX = 'MainNet'
      }else{
        serverStacksX = localStorage.getItem('serverStacks')
      }
      if (serverStacksX === 'MainNet'){
         stacksNetX =  user.profile.stxAddress.mainnet
      }
      if (serverStacksX === 'TestNet'){
         stacksNetX =  user.profile.stxAddress.testnet
      }
    }
    const {username} = userSession.loadUserData();
    if (username === '' || username === undefined || username === null){
      username_new(stacksNetX).then(val => this.setState({username: val})) ;
    }else{
      this.setState({username })
    }

    this.setState({ serverStacks: serverStacksX })
    this.setState({ optionNetwork: serverStacksX })

    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English' })
    }else{
      this.setState({ language: languageX })
    }

    this.setState({ placeholderX1: "Select an Option"})
    if (this.state.language === 'English'){ this.setState({ placeholderX1: "Select an Option"}) }
    if (this.state.language === 'French'){ this.setState({ placeholderX1: "Sélectionner une Option"}) }
    if (this.state.language === 'Spanish'){ this.setState({ placeholderX1: "Seleccione una Opción"}) }
    if (this.state.language === 'Portuguese'){ this.setState({ placeholderX1: "Selecione uma Opção"}) }
    if (this.state.language === 'Swedish'){ this.setState({ placeholderX1: "Välj ett Alternativ"}) }
    if (this.state.language === 'Netherlands'){ this.setState({ placeholderX1: "Kies een Optie"}) }
    if (this.state.language === 'Russian'){ this.setState({ placeholderX1: "Выберите опцию"}) }
    if (this.state.language === 'Japanese'){this.setState({ placeholderX1: "オプションを選択"}) }
    if (this.state.language === 'Chinese'){ this.setState({ placeholderX1: "选择一个选项"}) }
    if (this.state.language === 'German'){ this.setState({ placeholderX1: "Wähle eine Option"}) }
    if (this.state.language === 'Italian'){ this.setState({ placeholderX1: "Seleziona un'opzione"}) }


  }

  handleDialog = (type, action) => {
    if (type === '') {
      if (action === 'confirm') {
        if (this.state.optionNetwork === 'MainNet' || this.state.optionNetwork === 'TestNet'){
          this.setState({ dialogOpen: false });
          Promise.all(
             [this.changeNetwork()
          ]
          ).then(
            (resolve) =>{},
            (reject) =>{}
          )
        }
      }
      if (action === 'cancel') {
        let serverStacksX = 'MainNet'
        if (localStorage["serverStacks"]) {
          serverStacksX = localStorage.getItem('serverStacks')
        }
        this.setState({ serverStacks: serverStacksX })
        this.setState({ optionNetwork: serverStacksX })
        this.setState({ dialogOpen: false });
      }
    }
  }

  handleNetwork = (e) => {
    this.setState({ optionNetwork: e.target.value });
  }

  changeNetwork = () => {
      const { userSession } = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      return new Promise ((resolve, reject) =>{
        getFileDB(this.state.username, `settings3.json`)
         .then((fileContents) => {
            if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               let jsonBlockstack8 = '[]'
               let jsonBlockstack10 = '[]'
               if (jsonBlockstack2 !== null){
                   const jsonBlockstack3 = jsonBlockstack2.dataobject
                   const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                   if (jsonBlockstack4.substring(0,1) === '"') {
                      jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                   } else {
                      jsonBlockstack5 = jsonBlockstack4
                   }

                   const jsonBlockstack6 = jsonBlockstack2.options
                   const jsonBlockstack7 = jsonBlockstack6.replace(/\\/g,"");
                   if (jsonBlockstack7.substring(0,1) === '"') {
                      jsonBlockstack8 = jsonBlockstack7.substring(1,jsonBlockstack7.length - 1)
                   } else {
                      jsonBlockstack8 = jsonBlockstack7
                   }
                   let jsonBlockstack9 = JSON.parse(jsonBlockstack8)

                   if (jsonBlockstack9.encrypt === true) {
                     Promise.all([profileUser(this.state.username)])
                      .then(
                        (publicKey) =>{
                          const vi = jsonBlockstack5
                          const data = jsonBlockstack5,
                                iv = '1111111111111111',
                                password = publicKey
                          const key = forge.md.sha256.create().update(password).digest().getBytes();
                          const encryptedBytes = forge.util.decode64(data);
                          const decipher = forge.cipher.createDecipher('AES-CTR', key)
                          decipher.start({ iv });
                          decipher.update(forge.util.createBuffer(encryptedBytes));
                          decipher.finish();
                          const decryptedBytes = decipher.output.getBytes();
                          const decryptedString = forge.util.encodeUtf8(decryptedBytes);
                          let jsonBlockstack4 = decryptedString.replace(/\\/g,"");
                          if (jsonBlockstack4.substring(0,1) === '"') {
                             jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                          } else {
                             jsonBlockstack5 = jsonBlockstack4
                          }
                          jsonBlockstack10 = JSON.parse(jsonBlockstack5)
                          let jsonBlockstack4Q = JSON.parse(jsonBlockstack5)
                          let largo = jsonBlockstack4Q.length
                          let finLoop = false
                          jsonBlockstack4Q.map((todo,i)=>{
                            if(todo.code==='StacksNetwork'){
                              todo.description = this.state.optionNetwork
                              finLoop = true
                            }
                            if (i === (largo - 1)){
                            }
                          })
                          let jsonBlockstack3 = JSON.stringify(jsonBlockstack4Q)
                          //this.putNetwork(jsonBlockstack3)
                          resolve(true);
                        },
                        (reject) =>{
                          resolve(true);
                        }
                      )
                   }else{
                     jsonBlockstack10 = JSON.parse(jsonBlockstack5)
                     let jsonBlockstack4Q = JSON.parse(jsonBlockstack5)
                     let largo = jsonBlockstack4Q.length
                     let finLoop = false
                     jsonBlockstack4Q.map((todo,i)=>{
                       if(todo.code==='StacksNetwork'){
                         todo.description = this.state.optionNetwork
                         finLoop = true
                       }
                       if (i === (largo - 1)){
                       }
                     })
                     let jsonBlockstack3 = JSON.stringify(jsonBlockstack4Q)
                     //this.putNetwork(jsonBlockstack3)
                     resolve(true);
                   }
              } else {
                resolve(true);
              }
           } else {
              resolve(true);
           }
         })
        .catch(error => {
          resolve(true);
        })
      })
  }


  putNetwork = (jsonBlockstack3) => {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    let options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
    return new Promise ((resolve, reject) =>{
         localStorage.setItem('serverStacks',this.state.optionNetwork)
         this.setState({ serverStacks: this.state.optionNetwork })
         let jsonBlockstack1 = JSON.stringify(jsonBlockstack3)
         Promise.all([profileUser(this.state.username)])
          .then(
            (resolve) =>{
                const data = jsonBlockstack1,
                      iv = '1111111111111111',
                      password = resolve
                const key = forge.md.sha256.create().update(password).digest().getBytes();
                const cipher = forge.cipher.createCipher('AES-CTR', key);
                cipher.start({ iv });
                cipher.update(forge.util.createBuffer(data));
                cipher.finish();
                const encryptedBytes = cipher.output.getBytes();
                const encryptedBase64 = forge.util.encode64(encryptedBytes);
                options = {contentType: 'application/json', encrypt: true, dangerouslyIgnoreEtag: true }
                jsonBlockstack1 = encryptedBase64
                putFileDB(this.state.username,`settings3.json`, 'Crosscheck_Network', jsonBlockstack1, JSON.stringify(options))
                  .then(() => {
                    resolve(true);
                })
                 .catch(error => {
                   resolve(true);
               })
              },
            (reject) =>{
                putFileDB(this.state.username,`settings3.json`, 'Crosscheck_Network', jsonBlockstack1, JSON.stringify(options))
                  .then(() => {
                    resolve(true);
                })
                 .catch(error => {
                   resolve(true);
               })
              }
          )
    })
  }

  confirmChangeNetwork = () => {

    if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'Change Network (Stacks)',dialogTitle: '',})}
    if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: `Changer de réseau (Stacks) `,dialogTitle: '',})}
    if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'Cambiar la Red (Stacks)',dialogTitle: '',})}
    if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'Alterar rede (Stacks) ',dialogTitle: '',})}
    if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Ändra nätverk (Stacks)',dialogTitle: '',})}
    if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'Netwerk wijzigen (Stacks)',dialogTitle: '',})}
    if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Изменить сеть (Stacks)',dialogTitle: '',})}
    if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: 'Stacks)',dialogTitle: '',})}
    if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: 'Stacks',dialogTitle: '',})}
    if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Netzwerk ändern (Stacks)',dialogTitle: '',})}
    if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: "Cambia rete (Stacks) ",dialogTitle: '',})}
  }


  network = () => {
    this.confirmChangeNetwork()
  }

  render() {

    let optMainNet = false
    let optTestNet = false
    let displayNetwork = ''
    if (this.state.optionNetwork === 'MainNet'){
      optMainNet = true
      optTestNet = false
      displayNetwork = "   MainNet   "
    }
    if (this.state.optionNetwork === 'TestNet'){
      optMainNet = false
      optTestNet = true
      displayNetwork = "   TestNet   "
    }

    return (
      <>
        <ConfirmacionNetwork
            handleDialog={this.handleDialog}
            dialogMessage={this.state.dialogMessage}
            dialogOpen={this.state.dialogOpen}
            dialogTitle={this.state.dialogTitle}
            handleNetwork={this.handleNetwork}
            placeholderX1={this.state.placeholderX1}
            optMainNet={optMainNet}
            optTestNet={optTestNet}
        />
        <div className="text-center"
             onClick={this.network}
             style={{marginLeft:70, marginTop:15, maxWidth: "100px"}}
        >
           {optMainNet ?
              <>
                {this.props.colorDark ?
                   <strong style={{cursor: 'pointer'}} onClick={this.network}><Card style={{ backgroundColor: '#2d3039' }}>&nbsp;&nbsp;&nbsp;&nbsp;MainNet&nbsp;&nbsp;&nbsp;&nbsp;</Card></strong>
                :
                   <strong style={{cursor: 'pointer'}} onClick={this.network}><Card style={{ backgroundColor: '#f4f5f5' }}>&nbsp;&nbsp;&nbsp;&nbsp;MainNet&nbsp;&nbsp;&nbsp;&nbsp;</Card></strong>
                }
              </>
           :
             <>
               <strong style={{cursor: 'pointer'}} onClick={this.network}><Card style={{ backgroundColor: '#ffff03' }}> &nbsp;&nbsp;&nbsp;&nbsp;TestNet&nbsp;&nbsp;&nbsp;&nbsp;</Card></strong>
             </>
           }
        </div>
      </>
    );
  }
}

export default withRouter(Network)
