//Ethereum
import Web3 from 'web3';

import  {resolve} from 'path';

const getWeb3 = () => {
  return new Promise((resolve,reject) => {
    window.addEventListener("load", function(){
      // Modern dapp browsers...
      let web3 = undefined;
      if (window.ethereum) {
        web3 = window.ethereum;
        if (typeof web3 !== undefined){
          web3 = new Web3(window.ethereum);
          try {
            // Request account access if needed
            window.ethereum.enable();
            resolve(web3)
          } catch (error) {
            // User denied account access...
            localStorage.setItem('metamask','false')
            console.log("User denied account access...");
            reject()
          }
        }else{
          // Non-dapp browsers...
          localStorage.setItem('metamask','false')
          console.log("(1) Non-Ethereum browser detected. You should consider trying MetaMask!");
          reject()
        }
      }else{
        web3 = window.web3;
        if (typeof web3 !== undefined){
          if (window.web3) {
            // Legacy dapp browsers...
            web3 = new Web3(web3.currentProvider);
            resolve(web3)
          }else{
            // Non-dapp browsers...
            localStorage.setItem('metamask','false')
            console.log("(2) Non-Ethereum browser detected. You should consider trying MetaMask!");
            reject()
          }
        }else{
          // Non-dapp browsers...
          localStorage.setItem('metamask','false')
          console.log("(3) Non-Ethereum browser detected. You should consider trying MetaMask!");
          reject()
        }
      }
    })
  })
}

export default getWeb3;
