import { MOSTRAR_DATOSSENSORES, TOKEN_DATOSSENSORES, PACT_DATOSENSOR, SIMULATE_DATOSENSOR, SIMULATE_DATOSSENSORES } from './types';

import axios from 'axios';

// Simulate Data Sensor with JSON Server
export const simulateDatosensor = id => async dispatch => {
     const respuesta = await axios.get(`http://localhost:5000/data/${id}`);
     dispatch({
          type: SIMULATE_DATOSENSOR,
          payload: respuesta.data
     })
}

export const simulateDatossensores = () => async dispatch => {
     console.log('entro')
     const respuesta = await axios.get(`http://localhost:5000/data`);
     dispatch({
          type: SIMULATE_DATOSSENSORES,
          payload: respuesta.data
     })
}

//curl -X POST --header 'Content-Type: application/json' --header 'Accept: application/json' -d '{"clientId":"89445a4d-7ca9-422c-b3b7-ce10bcb5b70b","clientSecret": "cSOhgsJR7T4naxX/iHeZJUZuJ5E5XN493EquyNK+JYOlhVGFK3VDMA" }' 'https://api.data-platform.developer.ssni.com/api/tokens'

export const tokenDatosSensores = () => async dispatch => {
     const respuesta = await axios.post(
           'https://api.data-platform.developer.ssni.com/api/tokens',
           {"clientId":"89445a4d-7ca9-422c-b3b7-ce10bcb5b70b","clientSecret": "cSOhgsJR7T4naxX/iHeZJUZuJ5E5XN493EquyNK+JYOlhVGFK3VDMA"},
           { headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
           } } )
     dispatch({
          type: TOKEN_DATOSSENSORES,
          payload: respuesta.data
     })
}


export const mostrarDatosSensores = (token) => async dispatch => {
     console.log(token)
     const respuesta = await axios.get(
           'https://api.data-platform.developer.ssni.com/api/solutions/StudioSimulation/observations',
           {headers: {
             "Authorization" : `${token}`
           } } )
     dispatch({
          type: MOSTRAR_DATOSSENSORES,
          payload: respuesta.data
     })
}

export const pactDatoSensor = (nombre,valor) => async dispatch => {
     //sendPactCommand(`(todos.new-todo ${JSON.stringify(nombre)} ${JSON.stringify(valor)})`);
     dispatch({
          type: PACT_DATOSENSOR,
          payload: nombre,valor
     })
}
