import React, {Component} from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {
  Card,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Container
} from "shards-react";

import TituloPagina from "../components/common/TituloPagina";

//translate
import { FormattedMessage } from 'react-intl';

class Help extends Component {
  constructor(props, context) {
      super();

      this.state = {
        languaje: 'English',
      }

      // Define some Markdown content
      this.ContentMarkdown001 =`### Use Guide of the application`;
      this.ContentMarkdown002 =`![](https://www.weusecoins.com/images/company/blockstack.png)`;
      this.ContentMarkdown003 =`#### Table of Contents`;
      this.ContentMarkdown004 =`1. [Create Master Files](#paragraph1)`;
      this.ContentMarkdown005 =`2. [Create Link IoT Devices with Sensors](#paragraph2)`;
      this.ContentMarkdown006 =`3. [Create Tracking Contract](#paragraph3)`;
      this.ContentMarkdown007 =`4. [Create  Link Contract with Device](#paragraph4) `;
      this.ContentMarkdown008 =`5. [Display Dashboard](#paragraph5)`;
      this.ContentMarkdown009 =`To start the use of the dApp, we suggest to enter the following data as defined.`;
      this.ContentMarkdown015 =`#### 1. Create Master Files`;
      this.ContentMarkdown015a =`----`;
      this.ContentMarkdown016 =`You must enter the Sensors and Devices masters`;
      this.ContentMarkdown017 =`Sensors:`;
      this.ContentMarkdown018 ='- Code: 1  and Description: Temperature';
      this.ContentMarkdown019 ='- Code: 2  and Description: Humidity'
      this.ContentMarkdown020 ='- Code: 3  and Description: Gas Control'
      this.ContentMarkdown021 ='- Code: 4  and Description: GPS'
      this.ContentMarkdown022 ='Device:'
      this.ContentMarkdown023 ='- Code: 98234567  Description: UBLOX C30-U201'
      this.ContentMarkdown023a='The data of device 98234567 is accesible by dApp'
      this.ContentMarkdown024 ='#### 2. Create Link IoT Devices with Sensors'
      this.ContentMarkdown025 ='----'
      this.ContentMarkdown026 ='The application requires Link IoT Devices with Sensors'
      this.ContentMarkdown027 ='Device UBLOX C30-U201:'
      this.ContentMarkdown028 ='- Code: 1  and Description: Temperature'
      this.ContentMarkdown030 ='- Code: 2  and Description: Humidity'
      this.ContentMarkdown031 ='- Code: 3  and Description: Gas Control'
      this.ContentMarkdown032 ='#### 3. Create Tracking Contract'
      this.ContentMarkdown032a='----'
      this.ContentMarkdown033 ='You must create Tracking Contract'
      this.ContentMarkdown034 ='Enter next fields:'
      this.ContentMarkdown035 ='- Contract Number (*)'
      this.ContentMarkdown036 ='- Container ID (*)'
      this.ContentMarkdown037 ='- Bill of Lading Number (*)'
      this.ContentMarkdown038 ='You must Mark Check Option "Conditions Integrity of Materials"'
      this.ContentMarkdown039 ='Create Rules'
      this.ContentMarkdown040 ='- Lower Threshold'
      this.ContentMarkdown041 ='- Upper Threshold'
      this.ContentMarkdown041a='- Mark Check Option "Active Rule"'
      this.ContentMarkdown042 ='Actions'
      this.ContentMarkdown043 ='- Mark Check Option "I agree with your Contract Policy"'
      this.ContentMarkdown044 ='- Press Publish Draft'
      this.ContentMarkdown045 ='#### 4. Create Link Contract with Device'
      this.ContentMarkdown045a='----'
      this.ContentMarkdown046 ='- Select Contract'
      this.ContentMarkdown047 ='- Select Device'
      this.ContentMarkdown048 ='#### 5. Display Dashboard'
      this.ContentMarkdown048a='----'
      this.ContentMarkdown049 ='Using the Dashboard and selected the Contract you can monitor the reception sensor data '
  }

  componentWillMount() {
    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English' })
    }else{
      this.setState({ language: languageX })
    }
  }

  render() {

      let titleX = "Use Guide of the application"
      let subtitleX = "Help"
      if (this.state.language === 'English'){
        titleX = "Use Guide of the application."
        subtitleX = "Help"
      }
      if (this.state.language === 'French'){
        titleX = "Guide d'utilisation de l'application"
        subtitleX = "L'aide"
      }
      if (this.state.language === 'Spanish'){
        titleX = "Guía de uso de la aplicación"
        subtitleX = "Ayuda"
      }
      if (this.state.language === 'Portuguese'){
        titleX = "Use o Guia do aplicativo"
        subtitleX = "Ajuda"
      }
      if (this.state.language === 'Swedish'){
        titleX = "Använd programguiden"
        subtitleX = "Hjälpa"
      }
      if (this.state.language === 'Netherlands'){
        titleX = "Gebruik de gids van de applicatie"
        subtitleX = "Helpen"
      }
      if (this.state.language === 'Russian'){
        titleX = "Руководство по применению приложения"
        subtitleX = "помощь"
      }
      if (this.state.language === 'Japanese'){
        titleX = "アプリケーションの使用ガイド"
        subtitleX = "助けて"
      }
      if (this.state.language === 'Chinese'){
        titleX = "使用应用程序指南"
        subtitleX = "帮助"
      }
      if (this.state.language === 'German'){
        titleX = "Gebrauchsanweisung der Anwendung"
        subtitleX = "Hilfe"
      }
      if (this.state.language === 'Italian'){
        titleX = "Usa la guida dell'applicazione"
        subtitleX = "Aiutare"
      }

      let colorThemeX = 'Blue'
      let colorThemeX2 = 'info'
      if (localStorage["colorTheme"]) {colorThemeX = localStorage.getItem('colorTheme')}
      if (colorThemeX === 'Blue') {colorThemeX2 = 'info'}
      if (colorThemeX === 'Green') {colorThemeX2 = 'success'}
      if (colorThemeX === 'Grey') {colorThemeX2 = 'secondary'}

       return (
            <div>
              <Container fluid className="px-0">
                <Card theme={colorThemeX2} style={{ color: "white" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          &nbsp;&nbsp;<div className="fa fa-info"></div>
                        </td>
                        <td>
                          &nbsp;&nbsp;<FormattedMessage id="help.title" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card>

              </Container>
              <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                  <TituloPagina
                    sm="4"
                    title={titleX}
                    subtitle={subtitleX}
                    className="text-sm-left"
                  />
                </Row>
                <Row>
                  <Col lg="12" className="mb-4">
                    <Card small className="mb-4 pt-3">
                      <ListGroup flush>
                        <ListGroupItem className="p-0">
                          <Col md="1">&nbsp;</Col>
                          <Col md="11">
                            <div>
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown002} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown003} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown004} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown005} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown006} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown007} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown008} />
                              <br></br>
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown009} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown010} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown011} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown012} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown013} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown014} />
                              <br></br>
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown015} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown015a} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown016} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown017} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown018} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown019} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown020} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown021} />
                              <br></br>
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown022} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown023} />
                              <br></br>
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown023a} />
                              <br></br>
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown024} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown025} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown026} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown027} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown028} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown029} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown030} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown031} />
                              <br></br>
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown032} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown032a} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown033} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown034} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown035} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown036} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown037} />
                              <br></br>
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown038} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown040} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown041} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown041a} />
                              <br></br>
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown042} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown043} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown044} />
                              <br></br>
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown045} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown045a} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown046} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown047} />
                              <br></br>
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown048} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown048a} />
                              <ReactMarkdown escapeHtml={true} source={this.ContentMarkdown049} />
                            </div>
                          </Col>
                          <br></br>
                          <Link to = "/">
                             <div className="text-center">
                                <Button theme="accent"><FormattedMessage id="simulator.return" /></Button>
                             </div>
                             <br></br>
                          </Link>
                        </ListGroupItem>
                      </ListGroup>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
        );
      }
 }


export default Help;
