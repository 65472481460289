import React, { Component } from 'react';
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  Link,
} from '@react-pdf/renderer';

//translate
import { FormattedMessage } from 'react-intl';

//base64
import { Base64 } from 'js-base64';

//Clarity
import {getTransactionRegister} from "../../clarity/clarityfunctions"

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  typedocument: {
    fontSize: 16,
    textAlign: 'center',
    fontFamily: 'Oswald',
    color: '#909497',
  },
  numberdocument: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 6,
    color: '#909497',
  },
  description: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 30,
    color: '#909497',
  },
  subtitle: {
    fontSize: 16,
    margin: 12,
    fontFamily: 'Oswald',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  text2: {
    margin: 3,
    fontSize: 8,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  text3: {
    margin: 12,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  link: {
    position: 'absolute',
    bottom: 50,
    left: 0,
    right: 0,
    margin: 12,
    fontSize: 9,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    color: 'blue',
  },
  sign: {
    margin: 12,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
    lineHeight : '0.05px',
  },
  image: {
    marginVertical: 10,
    marginHorizontal: 220,
  },
  imagesign: {
    minWidth:100,
    maxWidth:150,
    minHeight:30,
    maxHeight:50,
    width:150,
    height:50
  },
  imagedigital: {
    minWidth:100,
    maxWidth:100,
    minHeight:100,
    maxHeight:100,
    width:100,
    height:100
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: '#ECF0F1',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  table: {
      width: '100%',
      fontSize: 9,
    },
    rowtable: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
    },
    headertable: {
      borderTop: 'none',
    },
    // So Declarative and unDRY 👌
    row1table: {
      width: '10%',
    },
    row2table: {
      width: '30%',
    },
    row3table: {
      width: '20%',
    },
    row4table: {
      width: '20%',
    },
    row5table: {
      width: '20%',
    },
});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const Subtitle = ({ children, ...props }) => (
  <Text style={styles.subtitle} {...props}>
    {children}
  </Text>
);

class MyDocument extends Component {

    constructor(props) {
        super(props);

        this.state = {
          languageX: 'English',
        }
    }

    UNSAFE_componentWillMount() {
      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English'})
      }else{
        this.setState({ language: languageX })
      }
    }

    render() {
      let paymentFormX = 'Payment Form'
      let signatoriesX = 'Signatories'
      let signatureX = 'Signature'
      let nameX = 'Name'
      let titleX = "Agreement Document"
      let itemX = "Item"
      let conceptX = "Concept"
      let estimatedDateX = "Estimated Date"
      let percentX = "Percent"
      let amountX = "Amount"
      let checkInX = 'Check in: '
      let digitallysignedX = 'Digitally signed by '
      let withdateX = 'With date '
      let totalsX = 'Totals'
      let registrationX = 'Blockchain Registration'
      let paymentForm1X = 'The total amount of this agreement is'
      let paymentForm2X = 'and will be paid in'
      let paymentForm3X = 'at the exchange rate at the time of payment'

      if (this.state.language === 'English'){ paymentFormX = 'Payment Method'; signatoriesX = 'Signatories'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Agreement Document"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'French'){ paymentFormX = 'Mode de Paiement'; signatoriesX = 'Signataires'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Document d'accord"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Spanish'){ paymentFormX = 'Forma de Pago'; signatoriesX = 'Firmantes'; signatureX = 'Firma'; nameX = 'Nombre'; titleX = "Documento de Acuerdo"; itemX = "Item"; conceptX = "Concepto"; estimatedDateX = "Fecha Estimada"; percentX = "Porcentaje"; amountX = "Monto"; checkInX = "Verificar en"}
      if (this.state.language === 'Portuguese'){ paymentFormX = 'Forma de pagamento'; signatoriesX = 'Signatários'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Documento de Acuerdo"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Swedish'){ paymentFormX = 'Betalningsmetod'; signatoriesX = 'Undertecknare'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Avtalsdokument"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Netherlands'){ paymentFormX = 'Betalingswijze'; signatoriesX = 'Ondertekenaars'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Overeenkomst document"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Russian'){ paymentFormX = 'Способ оплаты'; signatoriesX = 'Подписавшие'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Документ соглашения"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Japanese'){ paymentFormX = '支払方法'; signatoriesX = '署名者'; signatureX = 'Signature'; nameX = 'Name'; titleX = "契約書"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Chinese'){ paymentFormX = '付款方法'; signatoriesX = '簽署人'; signatureX = 'Signature'; nameX = 'Name'; titleX = "协议文件"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'German'){ paymentFormX = 'Zahlungsmethode'; signatoriesX = 'Unterzeichner'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Einverständniserklärung"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Italian'){ paymentFormX = 'Metodo di pagamento'; signatoriesX = 'Firmatari'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Documento di accordo"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}

      if (this.state.language === 'English'){ digitallysignedX = 'Digitally signed by '; withdateX = 'With date '; totalsX = 'Totals'; registrationX = 'Blockchain Registration'}
      if (this.state.language === 'French'){ digitallysignedX = 'Signé numériquement par '; withdateX = 'Avec date '; totalsX = 'Totaux'; registrationX = 'Enregistrement de la blockchain'}
      if (this.state.language === 'Spanish'){ digitallysignedX = 'Firmado digitalmente por '; withdateX = 'Con fecha '; totalsX = 'Totales'; registrationX = 'Registro Blockchain'}
      if (this.state.language === 'Portuguese'){ digitallysignedX = 'Assinado digitalmente por '; withdateX = 'Com data '; totalsX = 'Totais'; registrationX = 'Registro de Blockchain'}
      if (this.state.language === 'Swedish'){ digitallysignedX = 'Digitalt signerad av '; withdateX = 'Med datum '; totalsX = 'Summor'; registrationX = 'Blockchain registrering'}
      if (this.state.language === 'Netherlands'){ digitallysignedX = 'Digitaal ondertekend door '; withdateX = 'Met datum '; totalsX = 'Totalen'; registrationX = 'Blockchain-registratie'}
      if (this.state.language === 'Russian'){ digitallysignedX = 'Цифровая подпись '; withdateX = 'С датой '; totalsX = 'Итоги'; registrationX = 'Блокчейн Регистрация'}
      if (this.state.language === 'Japanese'){ digitallysignedX = 'デジタル署名者 '; withdateX = '日付付き '; totalsX = '合計'; registrationX = 'ブロックチェーン登録'}
      if (this.state.language === 'Chinese'){ digitallysignedX = '數字簽名者 '; withdateX = '有日期 '; totalsX = '總計'; registrationX = '區塊鏈註冊'}
      if (this.state.language === 'German'){ digitallysignedX = 'Digital signiert von '; withdateX = 'Mit Datum '; totalsX = 'Summen'; registrationX = 'Blockchain-Registrierung'}
      if (this.state.language === 'Italian'){ digitallysignedX = 'Firmato digitalmente da '; withdateX = 'Con data '; totalsX = 'Totali'; registrationX = 'Registrazione Blockchain'}

      if (this.state.language === 'English'){ paymentForm1X = "The total amount of this agreement is"; paymentForm2X = "and will be paid in"; paymentForm3X = "at the exchange rate at the time of payment"}
      if (this.state.language === 'French'){ paymentForm1X = "Le montant total de cet accord est"; paymentForm2X = "aet sera payé en"; paymentForm3X = "au taux de change au moment du paiement"}
      if (this.state.language === 'Spanish'){ paymentForm1X = "El monto total de este acuerdo es de"; paymentForm2X = "y será pagado en"; paymentForm3X = "al tipo de cambio del momento del pago"}
      if (this.state.language === 'Portuguese'){ paymentForm1X = "O valor total deste contrato é"; paymentForm2X = "e será pago em"; paymentForm3X = "à taxa de câmbio no momento do pagamento"}
      if (this.state.language === 'Swedish'){ paymentForm1X = "Det totala beloppet för detta avtal är"; paymentForm2X = "och kommer att betalas in"; paymentForm3X = "till växelkursen vid betalningstillfällett"}
      if (this.state.language === 'Netherlands'){ paymentForm1X = "Het totale bedrag van deze overeenkomst is"; paymentForm2X = "en wordt uitbetaald"; paymentForm3X = "tegen de wisselkoers op het moment van betaling"}
      if (this.state.language === 'Russian'){ paymentForm1X = "Общая сумма этого договора составляет"; paymentForm2X = "и будет оплачен в"; paymentForm3X = "по курсу на момент оплаты"}
      if (this.state.language === 'Japanese'){ paymentForm1X = "この契約の総額は、"; paymentForm2X = "で支払われます"; paymentForm3X = "支払い時の為替レートで"}
      if (this.state.language === 'Chinese'){ paymentForm1X = "本協議總金額為"; paymentForm2X = "並將支付"; paymentForm3X = "按付款時的匯率"}
      if (this.state.language === 'German'){ paymentForm1X = "Der Gesamtbetrag dieser Vereinbarung beträgt"; paymentForm2X = "und wird einbezahlt"; paymentForm3X = "zum Wechselkurs zum Zeitpunkt der Zahlung"}
      if (this.state.language === 'Italian'){ paymentForm1X = "L'importo totale di questo accordo è"; paymentForm2X = "e verrà pagato"; paymentForm3X = "al tasso di cambio al momento del pagamento"}

      const contentStateX = JSON.stringify(this.props.contentState, null, 4)
      const textX = JSON.parse(this.props.contentState)
      const textX2= textX.blocks
      const firmantesX = this.props.jsonBlockstackY

      let displayFormaPagoX = false
      let formapagoX = []
      if (this.props.jsonBlockstackFormPago.length > 0){
         formapagoX = this.props.jsonBlockstackFormPago
         displayFormaPagoX = true
      }
      const base64LinkX = Base64.encode(`${this.props.typeContract}>>>${this.props.numberContract}>>>${this.props.userOrigin}>>>${this.props.whatUserRole}>>>${this.props.description}`)
      const linkX = `https://${this.props.usernameX}?pdf:${base64LinkX}`

      let totalpercentX = 0;
      let totalamountX = 0;

      let imageFirmadoDigitalmenteX = "images/digitally_signed_english.png"
      if (this.state.language === 'Spanish'){
        imageFirmadoDigitalmenteX = "images/firmadodigitalmente.png"
      }

      return (
        <Document>
           <Page size="A4" style={styles.body} wrap>
             <Text style={styles.header} fixed>
               ~ Created with Crosscheck by Paradigma ~
             </Text>

             <Image
               style={styles.image}
               src="https://xck.app/images/isotipo3.png"
             />

             <Text style={styles.typedocument}>{this.props.typeContract}</Text>
             <Text style={styles.numberdocument}>{this.props.numberContract}</Text>
             <Text style={styles.description}>{this.props.description}</Text>

             <Subtitle>
               {titleX}
             </Subtitle>

             {textX2.map((todo, i) => {
               if (todo.text !== undefined && todo.text !== ''){
                 return (
                   <Text style={styles.text}>
                     {todo.text}
                   </Text>
                 )
               }
             })}

             {displayFormaPagoX ?
               <>
               <Subtitle>
                 {paymentFormX}
               </Subtitle>

               <Text style={styles.text3}> {`${paymentForm1X} ${this.props.totaldocumento} ${this.props.symbolcurrency} ${paymentForm2X} ${this.props.cryptoCurrency} ${paymentForm3X}`} </Text>

               <View style={styles.table}>
                     <View style={[styles.rowtable, styles.boldtable, styles.headertable]}>
                       <Text style={styles.row1table}>{itemX}</Text>
                       <Text style={styles.row2table}>{conceptX}</Text>
                       <Text style={styles.row3table}>{estimatedDateX}</Text>
                       <Text style={styles.row4table}>{percentX}</Text>
                       <Text style={styles.row5table}>{amountX}</Text>
                     </View>
                     {formapagoX.map((todo, i) => {
                       totalpercentX = totalpercentX + +(Math.round(todo.percent + "e+2")  + "e-2");
                       totalamountX =  totalamountX + +(Math.round(todo.amount + "e+2")  + "e-2");
                       return (
                         <View key={i} style={styles.rowtable} wrap={false}>
                           <Text style={styles.row1table}>
                             <Text style={styles.boldtable}>{++i}</Text>
                           </Text>
                           <Text style={styles.row2table}>{todo.concept}</Text>
                           <Text style={styles.row3table}>{todo.estimateddate}</Text>
                           <Text style={styles.row3table}>{`${todo.percent} %`}</Text>
                           <Text style={styles.row5table}>{`${todo.amount} ${this.props.symbolcurrency}`}</Text>
                         </View>
                     )})}
                     <View style={styles.rowtable} wrap={false}>
                       <Text style={styles.row1table}>
                         <Text style={styles.boldtable}>{''}</Text>
                       </Text>
                       <Text style={styles.row2table}>{totalsX}</Text>
                       <Text style={styles.row3table}>{''}</Text>
                       <Text style={styles.row3table}>{`${totalpercentX} %`}</Text>
                       <Text style={styles.row5table}>{`${totalamountX} ${this.props.symbolcurrency}`}</Text>
                     </View>

               </View>
               </>
             :
               null
             }

             <View>
               <Text>{" "}</Text>
             </View>

             <Subtitle>
               {signatoriesX}
             </Subtitle>
             {firmantesX.map((todo, i) => {
                if ((todo.role === 'Creator' || todo.role === 'Authorizer' || todo.role === 'Authorizer-Inviter')){
                  let trimmedDataURLX = false
                  if (todo.signpad !== undefined){
                    trimmedDataURLX = true
                  }
                  return (
                    <>
                      <View style={styles.table}>
                        <View key={i} style={styles.rowtable} wrap={false}>
                          <Text style={styles.row2table}>
                          <Image
                            style={styles.imagedigital}
                            src={imageFirmadoDigitalmenteX}
                          />
                          </Text>
                          <Text style={styles.row2table}>
                          <Image
                            style={styles.imagesign}
                            src={todo.signpad}
                          />
                          </Text>
                        </View>
                      </View>

                      <View>
                        <Text style={styles.sign}>{`${digitallysignedX} ${todo.name}`}</Text>
                        <Text style={styles.sign}>{`${withdateX} ${todo.signaturedatetime}`}</Text>
                        <Text style={styles.sign}>{`${signatureX}: ${todo.signature}`}</Text>
                        <Text style={styles.sign}>{`did:web: ${todo.id}`}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                      </View>
                    </>
                  )
                }
             })}

             <Text  style={styles.text2}>{`Crosscheck ID: ${this.props.userOrigin}/${this.props.typeContract}/${this.props.numberContract}`}</Text>
             <Text  style={styles.text2}></Text>
             <Text  style={styles.text2}>{`${registrationX}: ${this.props.getTransaction.block_hash}`}</Text>
             <Text  style={styles.text2}>{`${withdateX}: ${this.props.getTransaction.burn_block_time_iso}`}</Text>
             <Text  style={styles.text2}>{`URL: `}
                 <Link src={`https://explorer.stacks.co/block/${this.props.getTransaction.block_hash}?chain=mainnet`}>{`https://explorer.stacks.co/block/${this.props.getTransaction.block_hash}?chain=mainnet`}</Link>
             </Text>
             <Text  style={styles.link}>
                <Link src={linkX}>{`${checkInX}: ${linkX}`}</Link>
             </Text>
             <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                 `${pageNumber} / ${totalPages}`
             )} fixed />
          </Page>
        </Document>
      )
    }
}
export default MyDocument;
