//React
import React, { Component } from 'react';

//Redux
import {connect} from 'react-redux';

import { withRouter } from "react-router-dom";

//UI
import {Row, Col, Form, Card, Container, InputGroupText, FormRadio,
        FormCheckbox, Button, FormSelect, InputGroupAddon } from "shards-react";

// blockstack
import { UserSession } from 'blockstack';

// Spinning
import Loader from '../components/loader'

import TituloPagina from "../components/common/TituloPagina";

//Actions
import { mostrarTemplateAll } from '../actions/templateActions';
import {getFileDB, putFileDB} from '../actions/mongoDBActions'

//Sagas
import { fetchPactLogisticConfig, fetchDeletedPactLogisticConfig } from '../actions/pactActions';

//Proptype
import PropTypes from 'prop-types';

//translate
import { FormattedMessage } from 'react-intl';

import {username_new} from '../actions/userActions'
import {profileUser} from '../actions/gaiaActions'

import forge from 'node-forge'

class SearchAdvanced extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.searchAdvanced = this.searchAdvanced.bind(this);
        this.searchContracts = this.searchContracts.bind(this);
        this.searchContracts2 = this.searchContracts2.bind(this);
        this.fetchBlockstackConfig = this.fetchBlockstackConfig.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          startDate: new Date(),
          endDate: new Date(),
          nowDate: new Date(),
          existContract: false,
          showLoader: false,
          c03AdvancedSearch: true,
          jsonBlockstack2:[],
          goButton: true,
          smartcontract2: [],
          smartcontract3: [],
          selectedSearch: 'switchBlockstack',
          goOpcion: false,
          dynamicFormXML: '[]',
          mensajeErrorTemplates: '',
          errorMensajeTemplates: false,
          displayTemplate: false,
          displayTemplateDetail: false,
          JSONTemplateDetail: '',
          JSONTemplateName: '',
          typeContractOption: '',
          languaje: 'English',
          templatesAllLocal: [],
          templateFrom: 'Server',
        }
    }

    c01AdvancedSearch = React.createRef();
    c02AdvancedSearch = React.createRef();

    componentWillMount() {
        const { userSession } = this.state
        let stacksNetX = ''
        let serverStacksX = ''
        if (userSession.isUserSignedIn()) {
          const user = userSession.loadUserData()
          this.setState({ user })
          if (!localStorage["serverStacks"]) {
            serverStacksX = 'MainNet'
          }else{
            serverStacksX = localStorage.getItem('serverStacks')
          }
          if (serverStacksX === 'MainNet'){
    	       stacksNetX =  user.profile.stxAddress.mainnet
          }
          if (serverStacksX === 'TestNet'){
    	       stacksNetX =  user.profile.stxAddress.testnet
          }
        }
        const {username} = userSession.loadUserData();
        if (username === '' || username === undefined || username === null){
          username_new(stacksNetX).then(val => this.setState({username: val},()=>{
            Promise.all([this.getSettings3(val)])
               .then(
                  (resolve) => {},
                  (reject) => {}
               )
          })) ;
        }else{
          this.setState({ username },()=>{
            Promise.all([this.getSettings3(username)])
               .then(
                  (resolve) => {},
                  (reject) => {}
               )

          })
        }
    }

    getSettings3 = (username) => {
        const options = { decrypt: false }
        let settingBringContractTemplateFrom = 'Server'
        this.setState({templateFrom:'Server'})
        getFileDB(username,`settings3.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 let jsonBlockstack8 = '[]'
                 let jsonBlockstack10 = '[]'
                 if (jsonBlockstack2 !== null){
                     const jsonBlockstack3 = jsonBlockstack2.dataobject
                     const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                     if (jsonBlockstack4.substring(0,1) === '"') {
                        jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                     } else {
                        jsonBlockstack5 = jsonBlockstack4
                     }

                     const jsonBlockstack6 = jsonBlockstack2.options
                     const jsonBlockstack7 = jsonBlockstack6.replace(/\\/g,"");
                     if (jsonBlockstack7.substring(0,1) === '"') {
                        jsonBlockstack8 = jsonBlockstack7.substring(1,jsonBlockstack7.length - 1)
                     } else {
                        jsonBlockstack8 = jsonBlockstack7
                     }
                     let jsonBlockstack9 = JSON.parse(jsonBlockstack8)

                     if (jsonBlockstack9.encrypt === true) {
                       Promise.all([profileUser(username)])
                        .then(
                          (publicKey) =>{
                            const vi = jsonBlockstack5
                            const data = jsonBlockstack5,
                                  iv = '1111111111111111',
                                  password = publicKey
                            const key = forge.md.sha256.create().update(password).digest().getBytes();
                            const encryptedBytes = forge.util.decode64(data);
                            const decipher = forge.cipher.createDecipher('AES-CTR', key)
                            decipher.start({ iv });
                            decipher.update(forge.util.createBuffer(encryptedBytes));
                            decipher.finish();
                            const decryptedBytes = decipher.output.getBytes();
                            const decryptedString = forge.util.encodeUtf8(decryptedBytes);
                            let jsonBlockstack4 = decryptedString.replace(/\\/g,"");
                            if (jsonBlockstack4.substring(0,1) === '"') {
                               jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                            } else {
                               jsonBlockstack5 = jsonBlockstack4
                            }
                            jsonBlockstack10 = JSON.parse(jsonBlockstack5)
                            jsonBlockstack10.map((todo,i)=>{
                              if(todo.code==='BringContractTemplateFrom'){
                                settingBringContractTemplateFrom = todo.description
                              }
                            })
                            if (settingBringContractTemplateFrom === 'Server'){
                              Promise.all(
                                [this.props.mostrarTemplateAll('jfontirroig.id.blockstack')]
                              ).then(
                                (resolve) =>{
                                },
                                (reject) =>{
                                     this.readContractDoc(username)
                                }
                              )
                            }else{
                              this.readContractDoc(username)
                            }
                          },
                        (reject) =>{}
                       )
                     }else{
                       let jsonBlockstack10 = JSON.parse(jsonBlockstack5)
                       jsonBlockstack10.map((todo,i)=>{
                         if(todo.code==='BringContractTemplateFrom'){
                           settingBringContractTemplateFrom = todo.description
                         }
                       })
                       if (settingBringContractTemplateFrom === 'Server'){
                         Promise.all(
                           [this.props.mostrarTemplateAll('jfontirroig.id.blockstack')]
                         ).then(
                           (resolve) =>{
                           },
                           (reject) =>{
                                this.readContractDoc(username)
                           }
                         )
                       }else{
                         this.readContractDoc(username)
                       }
                     }
                 }
               } else {}
             })
              .catch(error => {
                //console.log(error);
              });

        const languageX = localStorage.getItem('language')
        if (languageX === undefined || languageX === ''){
          this.setState({ language: 'English' })
        }else{
          this.setState({ language: languageX })
        }
    }

    readContractDoc(username){
      const { userSession } = this.state
      const options = { decrypt: false }
      getFileDB(username, `contractdoc.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             this.setState({templatesAllLocal: jsonBlockstack6})
             this.setState({templateFrom:'Local'})
           } else {
               //console.log("No Data (contractdoc.json)");
           }
         })
          .catch(error => {
              //console.log(error);
              //console.log("No Data (contractdoc.json)");
       });
    }

    handleDate(val){
        this.setState({
          starDate: new Date(val)
        })
    }

    handleChangeOption(optSearch) {
        this.setState({
          selectedSearch: optSearch,
          showAlert: false,
          showLoader:false,
        });
        this.c01AdvancedSearch.current.value='SelectOptionTypeContract'
    }

    handleChange(e,chk) {
        const newState = {};
        this.setState({smartcontract: []})
        newState[chk] = !this.state[chk];
        this.setState({...this.state, ...newState});
        this.c01AdvancedSearch.current.value='SelectOptionTypeContract'
        this.setState({showLoader:false})
    }

    searchAdvanced() {
          localStorage.removeItem('jsonSearchAdvanced')
          //window.location = '/error'
          const path = '/start'
          this.props.history.push(path);
    }

    searchContracts2() {
        const palabra = this.c02AdvancedSearch.current.value
        const ArrayIdValue = palabra.split('#$%')
        const idx = ArrayIdValue[0]
        if (idx !== ''){
          const typeFile = this.state.typeContractOption
          const numberContract = ArrayIdValue[1]
          const { userSession } = this.state
          //const options = { decrypt: true }
          const options = { decrypt: false }
          getFileDB(this.state.username, `${typeFile}_${numberContract}.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.setState({goButton:false})
                 localStorage.setItem('searchContract',`${typeFile}_${numberContract}.json`)
                 localStorage.setItem('searchTypeContract',typeFile)
                 localStorage.setItem('jsonBlockstack2',jsonBlockstack5)
                 if (this.state.selectedSearch === "switchPACT"){
                    localStorage.setItem('idsmartcontract',idx)
                 }
               } else {
                 //console.log("No Data");
               }
             })
              .catch(error => {
                 //console.log(error);
             })
        };
    }

    searchContracts() {
        if (this.c01AdvancedSearch.current.value !== 'SelectOptionTypeContract') {
          this.setState({showLoader:true})
          const palabra = this.c01AdvancedSearch.current.value
          const ArrayIdValue = palabra.split('#$%')
          const idx = ArrayIdValue[0]
          if (idx !== ''){
            const namex = ArrayIdValue[1]
            this.setState({typeContractOption: namex.toLowerCase()})
            if (this.state.c03AdvancedSearch===true){
              if (this.state.selectedSearch === "switchPACT") {
                 this.props.fetchPactLogisticConfig();
              }
              if (this.state.selectedSearch === "switchBlockstack") {
                 this.fetchBlockstackConfig()
              }
            }
          }
        }else{
          this.setState({showLoader:false})
        }
    }

    async fetchBlockstackConfig(){
      const { userSession } = this.state
      let options = { decrypt: false }
      let jsonBlockstack4 = []
      await getFileDB(this.state.username, `contractlist.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             this.setState({smartcontract3: jsonBlockstack6})
           } else {
             //console.log("No Data (contractlist.json)");
           }
         })
          .catch(error => {
            //console.log("Catch Error (contractlist.json)");
         });
    }

    render() {
        let arrayTemplateDBX = []
        if (this.state.templateFrom==='Server') {
          arrayTemplateDBX = this.props.templates[0]
        }else{
          arrayTemplateDBX = this.state.templatesAllLocal
        }

        if (arrayTemplateDBX === undefined){
          arrayTemplateDBX = []
        }
        arrayTemplateDBX.sort(function(a, b){
          return a.priority-b.priority
        })

        let smartcontract4=[]
        if (this.state.selectedSearch === "switchPACT") {
             smartcontract4 = this.props.smartcontract;
        } else {
           if (this.state.selectedSearch === "switchBlockstack") {
             smartcontract4 = this.state.smartcontract3;
           }
        }

        let placeholderX1 = "Select an Option"
        let titleX = "Search"
        let subtitleX = "Advanced"
        if (this.state.language === 'English'){
          placeholderX1 = "Select an Option"
          titleX = "Search"
          subtitleX = "Advanced"
        }
        if (this.state.language === 'French'){
          placeholderX1 = "Chercher"
          titleX = "Groupe d'utilisateurs"
          subtitleX = "Avancée"
        }
        if (this.state.language === 'Spanish'){
          placeholderX1 = "Seleccione una Opción"
          titleX = "Búsqueda"
          subtitleX = "Avanzada"
        }
        if (this.state.language === 'Portuguese'){
          placeholderX1 = "Selecione uma Opção"
          titleX = "Procurar"
          subtitleX = "Avançada"
        }
        if (this.state.language === 'Swedish'){
          placeholderX1 = "Välj ett Alternativ"
          titleX = "Sök"
          subtitleX = "Avancerad"
        }
        if (this.state.language === 'Netherlands'){
          placeholderX1 = "Kies een Optie"
          titleX = "Zoeken"
          subtitleX = "Gevorderd"
        }
        if (this.state.language === 'Russian'){
          placeholderX1 = "Выберите опцию"
          titleX = "Поиск"
          subtitleX = "продвинутый"
        }
        if (this.state.language === 'Japanese'){
          placeholderX1 = "オプションを選択"
          titleX = "調べる"
          subtitleX = "高度な"
        }
        if (this.state.language === 'Chinese'){
          placeholderX1 = "选择一个选项"
          titleX = "搜索"
          subtitleX = "高级"
        }
        if (this.state.language === 'German'){
          placeholderX1 = "Wähle eine Option"
          titleX = "Suche"
          subtitleX = "Fortgeschritten"
        }
        if (this.state.language === 'Italian'){
          placeholderX1 = "Seleziona un'opzione"
          titleX = "Ricerca"
          subtitleX = "Avanzate"
        }

        return (
          <div>
            <Container fluid className="px-0">
                <Card theme="info" style={{ color: "white" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          &nbsp;&nbsp;<div className="fa fa-info"></div>
                        </td>
                        <td>
                           &nbsp;&nbsp;<FormattedMessage id="seachadvanced.advancedseach" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
            </Container>
            <Container fluid className="main-content-container px-4">
              <Row noGutters className="page-header py-4">
                <TituloPagina
                  sm="4"
                  title={titleX}
                  subtitle={subtitleX}
                  className="text-sm-left"
                />
              </Row>
              <Row>
                <Col>
                  <Form style={{fontSize:13}}>
                    <Row form>
                      <Col md="2">
                        <label htmlFor="TypeContract"><FormattedMessage id="seachadvanced.type" /></label>
                        <FormSelect
                          arrayTemplateDBX  id="TypeContract"
                            innerRef={this.c01AdvancedSearch}
                            placeholder="Select an Option"
                            onChange={this.searchContracts}
                        >
                        <option value="SelectOptionTypeContract">{placeholderX1}</option>
                        {arrayTemplateDBX.map((todo, key) => {
                            let isActiveX = todo.isActive
                            return (
                              <option key={todo._id} disabled={!isActiveX} value={`${todo._id}#$%${todo.secondaryName}`}>{todo.name}</option>
                        )})}
                        </FormSelect>
                      </Col>
                      <Col md="1">
                        <br></br>
                        { this.state.showLoader ?
                        <Loader />
                        : null }
                      </Col>
                      <Col md="4">
                        <label htmlFor="IdContract"><FormattedMessage id="seachadvanced.idcontract" /></label>
                        <FormSelect
                            id="IdContract"
                            innerRef={this.c02AdvancedSearch}
                            placeholder="Select an Option"
                            onChange={this.searchContracts2}
                          >
                          <option value="IdContract">{placeholderX1}</option>
                          {smartcontract4.map((todo, key) => {
                            if (todo.typeContract === this.state.typeContractOption){
                              return (
                                 <option key={todo.id} value={`${todo.id}#$%${todo.configurationContractNumber}`}>{todo.configurationContractNumber}</option>
                              )
                            }else{
                              return ''
                            }
                          })}
                        </FormSelect>
                      </Col>
                    </Row>
                    <br></br>
                    <Row form>
                      <Col md="2">
                         <FormCheckbox
                              inline
                              checked={this.state.c03AdvancedSearch}
                              onChange={e=>this.handleChange(e, "c03AdvancedSearch")}
                              disabled={false}
                          >
                              <FormattedMessage id="seachadvanced.notdeleted" />
                          </FormCheckbox>
                      </Col>
                      <Col md="1"></Col>
                      <Col md="2">
                        <label><FormattedMessage id="seachadvanced.origin" />&nbsp;&nbsp;&nbsp;</label>
                        <InputGroupAddon type="prepend">
                          <InputGroupText>
                            <FormRadio
                              name="optSearch"
                              checked={this.state.selectedSearch === "switchPACT"}
                              onChange={() => {
                                this.handleChangeOption("switchPACT");
                              }}
                            >
                              <FormattedMessage id="seachadvanced.pact" />
                            </FormRadio>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <FormRadio
                              name="optSearch"
                              checked={this.state.selectedSearch === "switchClarity"}
                              onChange={() => {
                                this.handleChangeOption("switchClarity");
                              }}
                            >
                              <FormattedMessage id="seachadvanced.clarity" />
                            </FormRadio>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <FormRadio
                              name="optSearch"
                              checked={this.state.selectedSearch === "switchBlockstack"}
                              onChange={() => {
                                this.handleChangeOption("switchBlockstack");
                              }}
                            >
                              <FormattedMessage id="seachadvanced.gaia" />
                            </FormRadio>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </InputGroupText>
                        </InputGroupAddon>
                      </Col>
                    </Row>

                    <br></br>
                    <div>
                        <Button pill theme="success" disabled={this.state.goButton} onClick={this.searchAdvanced}><FormattedMessage id="seachadvanced.go" /></Button>
                    </div>
                    <br></br>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        );
    }
}

SearchAdvanced.propTypes = {
    mostrarTemplateAll : PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  smartcontract: state.smartcontract.smartcontract,
  templates: state.templates.templatesAll,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchPactLogisticConfig: () => {dispatch(fetchPactLogisticConfig());},
    fetchDeletedPactLogisticConfig: () => {dispatch(fetchDeletedPactLogisticConfig());},
    mostrarTemplateAll: (username) => {dispatch(mostrarTemplateAll(username));},
  };
};

export default withRouter(connect( mapStateToProps, mapDispatchToProps)(SearchAdvanced));
