import React, { Component } from 'react';

//UI
import {FormSelect} from "shards-react";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//translate
import { FormattedMessage } from 'react-intl';

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'


class NewIoTDevice extends Component {
  constructor(props) {
      super(props);

      this.goNewIoTDevice = this.goNewIoTDevice.bind(this);

      this.state = {
        userSession: new UserSession(),
        username: '',
        user: {},
        code: '',
        description: '',
        error: false,
        infoIoTDevice: '',
        status: false,
        jsonBlockstackX: [],
        language: 'English',

       }
    }

    c01IoTDevice = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val},()=>{
          Promise.all([this.getSensor(val)])
             .then(
                (resolve) => {},
                (reject) => {}
             )
        })) ;
      }else{
        this.setState({ username },()=>{
          Promise.all([this.getSensor(username)])
             .then(
                (resolve) => {},
                (reject) => {}
             )

        })
      }

    }

    getSensor = (username) => {
      this.setState({jsonBlockstackX: []})
      this.setState({displayIoTDevice: true})
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }

      localStorage.removeItem('jsonBlockstack31')

      getFileDB(username, `sensor_id.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             localStorage.setItem('jsonBlockstack31',jsonBlockstack5)
             this.setState({jsonBlockstackX: jsonBlockstack6})
           } else {
             //console.log("No Data");
           }
         })
         .catch(error => {
            //console.log(error);
         });
    }

    goNewIoTDevice = e => {
         const { userSession } = this.state
         e.preventDefault();
         const palabra = this.c01IoTDevice.current.value
         const ArrayIdValue = palabra.split(' - ')
         const idx = ArrayIdValue[0]
         const descriptionSensor = ArrayIdValue[1];
         //-------------------------------------------
         // enviar a Blockstack (Cloud Storage)
         //-------------------------------------------
         // Crear el objeto
         let jsonBlockstack2 = ''
         let infoIoTDevice = ''
         let reviewIoTDevice = ''
         if (localStorage["jsonBlockstack30"]) {
           jsonBlockstack2 = localStorage.getItem('jsonBlockstack30')
           const jsonBlockstack3 = jsonBlockstack2.substring(1,jsonBlockstack2.length - 1)
           if (jsonBlockstack3 === '') {
              infoIoTDevice =`[{"code":"${idx}","description":"${descriptionSensor}"}]`
              reviewIoTDevice = '[]'
           }else{
              infoIoTDevice =`[${jsonBlockstack3},{"code":"${idx}","description":"${descriptionSensor}"}]`
              reviewIoTDevice = `[${jsonBlockstack3}]`
           }
         }else{
           infoIoTDevice =`[{"code":"${idx}","description":"${descriptionSensor}"}]`
           reviewIoTDevice = '[]'
         }

         let existNewIoTDevice3 = false
         const infoIoTDeviceJSON = JSON.parse(reviewIoTDevice)
         infoIoTDeviceJSON.map((todo, i) => {
            if (todo.code === idx) {
               existNewIoTDevice3 = true
               this.setState({existNewContract: true})
               return(true)
            }
            return false
         })

         if (existNewIoTDevice3===true){
            return
         }

         const storage = new Storage({ userSession });
         const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }

         putFileDB(this.state.username, `device_${this.props.idx}_sensor.json`, 'Crosscheck_IoTDevice', JSON.stringify(infoIoTDevice), JSON.stringify(options))
           .then(() => {
              this.setState({
                 status: true
              })
              //console.log(`Grabación Exitosa IoTDevice (device_${this.props.idx}_sensor.json`)
           })

         // enviar por props.
         this.props.crearIoTDevice(infoIoTDevice);
    }

    render() {
          const {jsonBlockstackX} = this.state;

          let placeholderX1 = "Select an Option"
          if (this.state.language === 'English'){
            placeholderX1 = "Select an Option"
          }
          if (this.state.language === 'French'){
            placeholderX1 = "Sélectionner une Option"
          }
          if (this.state.language === 'Spanish'){
            placeholderX1 = "Seleccione una Opción"
          }
          if (this.state.language === 'Portuguese'){
            placeholderX1 = "Selecione uma Opção"
          }
          if (this.state.language === 'Swedish'){
            placeholderX1 = "Välj ett Alternativ"
          }
          if (this.state.language === 'Netherlands'){
            placeholderX1 = "Kies een Optie"
          }
          if (this.state.language === 'Russian'){
            placeholderX1 = "Выберите опцию"
          }
          if (this.state.language === 'Japanese'){
            placeholderX1 = "オプションを選択"
          }
          if (this.state.language === 'Chinese'){
            placeholderX1 = "选择一个选项"
          }
          if (this.state.language === 'German'){
            placeholderX1 = "Wähle eine Option"
          }
          if (this.state.language === 'Italian'){
            placeholderX1 = "Seleziona un'opzione"
          }

          return (
            <React.Fragment>
                  <label htmlFor="TypeIoTDevice"><FormattedMessage id="configuration.sensors" /> (*)</label>
                  <FormSelect
                      id="TypeIoTDevice"
                      style={ this.props.colorDark ? { backgroundColor:'#505459', color:'white'} : {}}
                      innerRef={this.c01IoTDevice}
                      placeholder="Select an Option"
                      onChange={e=>this.goNewIoTDevice(e)}
                  >
                  <option value="SelectOptionMasterFile">{placeholderX1}</option>
                  {jsonBlockstackX.map((todo, i) => (
                      <option key={i} style={this.props.colorDark ? {color:'orange'} : {color:'darkblue'}} value={`${todo.code} - ${todo.description}`}>{todo.description}</option>
                  ))}
                  </FormSelect>
            </React.Fragment>
           );
     }
}

export default NewIoTDevice;
