import React, { Component } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import MyDocument from "./GeneratePdf";
import ReporteTurno from "./GeneratePdfReporteTurno";
import CertificateReporteTurno from "./GeneratePdfCertificateReporteTurno";

// blockstack
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

import {username_new} from '../../actions/userActions'

//Axios
import axios from 'axios';

//Zonefile (Gaia)
import { parseZoneFile } from 'zone-file'

//UUID
import uuid from 'uuid';

//Configuración
import { MAINNET_STACKS_API_URL,
         TESTNET_STACKS_API_URL } from '../../config.js'

//Spinning
import ContractLoader from "../contracts/ContractLoader";

//Clarity
import {getTransactionRegister} from "../../clarity/clarityfunctions"

import './ViewerPdf.css'

class ViewerPdfReporteTurno extends Component {

    constructor(props) {
        super(props);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user:{},
          jsonBlockstackY: [],
          networkUrlX: '',
          trimmedDataURL: null,
          jsonBlockstackFormPago: [],
          originMoney: 'USD - US Dollar',
          symbolcurrency: 'USD',
          jsonHeadPaymentForm: [],
          cryptoCurrency: 'STX',
          totaldocumento: '',
          saldodocumento: null,
          uuidX: uuid(),
          endPutFile: false,
          getTransaction: '',
          fullRead: false,
        }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }
      const {username} = userSession.loadUserData();
      this.setState({networkUrlX })

      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }
    }

    //-----------------------------------------------------------------------

    render() {
      return (
        <div>
              {this.props.displayCertificate ?
                  <PDFViewer width="1000" height="1200" className="app">
                    <CertificateReporteTurno contentState={this.props.contentState}
                                typeContract={this.props.typeContract}
                                numberContract={this.props.numberContract}
                                jsonBlockstackY={this.state.jsonBlockstackY}
                                description={this.props.description}
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                trimmedDataURL={this.state.trimmedDataURL}
                                jsonBlockstackFormPago={this.state.jsonBlockstackFormPago}
                                totaldocumento={this.state.totaldocumento}
                                originMoney={this.state.originMoney}
                                cryptoCurrency={this.state.cryptoCurrency}
                                symbolcurrency={this.state.symbolcurrency}
                                uuidX={this.state.uuidX}
                                usernameX={this.state.username}
                                userOrigin={this.props.userOrigin}
                                whatUserRole={this.props.whatUserRole}
                                userRole={this.props.whatUserRole}
                                colorDark={this.props.colorDark}
                                getTransaction={this.state.getTransaction}
                                cReporteTurnoNameFirmante1={this.props.cReporteTurnoNameFirmante1}
                                cReporteTurnoIdFirmante1={this.props.cReporteTurnoIdFirmante1}
                                cReporteTurnoPublicKeyFirmante1={this.props.cReporteTurnoPublicKeyFirmante1}
                                cReporteTurnoDateTimeFirmante1={this.props.cReporteTurnoDateTimeFirmante1}
                                cReporteTurnoSignatureFirmante1={this.props.cReporteTurnoSignatureFirmante1}
                                cReporteTurnotrimmedDataURLFirmante1={this.props.cReporteTurnotrimmedDataURLFirmante1}
                                cReporteTurnoNameFirmante2={this.props.cReporteTurnoNameFirmante2}
                                cReporteTurnoIdFirmante2={this.props.cReporteTurnoIdFirmante2}
                                cReporteTurnoPublicKeyFirmante2={this.props.cReporteTurnoPublicKeyFirmante2}
                                cReporteTurnoDateTimeFirmante2={this.props.cReporteTurnoDateTimeFirmante2}
                                cReporteTurnoSignatureFirmante2={this.props.cReporteTurnoSignatureFirmante2}
                                cReporteTurnotrimmedDataURLFirmante2={this.props.cReporteTurnotrimmedDataURLFirmante2}
                                cReporteTurnoNameFirmante3={this.props.cReporteTurnoNameFirmante3}
                                cReporteTurnoIdFirmante3={this.props.cReporteTurnoIdFirmante3}
                                cReporteTurnoPublicKeyFirmante3={this.props.cReporteTurnoPublicKeyFirmante3}
                                cReporteTurnoDateTimeFirmante3={this.props.cReporteTurnoDateTimeFirmante3}
                                cReporteTurnoSignatureFirmante3={this.props.cReporteTurnoSignatureFirmante3}
                                cReporteTurnotrimmedDataURLFirmante3={this.props.cReporteTurnotrimmedDataURLFirmante3}
                                cReporteTurnoNameFirmante4={this.props.cReporteTurnoNameFirmante4}
                                cReporteTurnoIdFirmante4={this.props.cReporteTurnoIdFirmante4}
                                cReporteTurnoPublicKeyFirmante4={this.props.cReporteTurnoPublicKeyFirmante4}
                                cReporteTurnoDateTimeFirmante4={this.props.cReporteTurnoDateTimeFirmante4}
                                cReporteTurnoSignatureFirmante4={this.props.cReporteTurnoSignatureFirmante4}
                                cReporteTurnotrimmedDataURLFirmante4={this.props.cReporteTurnotrimmedDataURLFirmante4}
                                cReporteTurnoNameFirmante5={this.props.cReporteTurnoNameFirmante5}
                                cReporteTurnoIdFirmante5={this.props.cReporteTurnoIdFirmante5}
                                cReporteTurnoPublicKeyFirmante5={this.props.cReporteTurnoPublicKeyFirmante5}
                                cReporteTurnoDateTimeFirmante5={this.props.cReporteTurnoDateTimeFirmante5}
                                cReporteTurnoSignatureFirmante5={this.props.cReporteTurnoSignatureFirmante5}
                                cReporteTurnotrimmedDataURLFirmante5={this.props.cReporteTurnotrimmedDataURLFirmante5}
                                cReporteTurnoNameFirmante6={this.props.cReporteTurnoNameFirmante6}
                                cReporteTurnoIdFirmante6={this.props.cReporteTurnoIdFirmante6}
                                cReporteTurnoPublicKeyFirmante6={this.props.cReporteTurnoPublicKeyFirmante6}
                                cReporteTurnoDateTimeFirmante6={this.props.cReporteTurnoDateTimeFirmante6}
                                cReporteTurnoSignatureFirmante6={this.props.cReporteTurnoSignatureFirmante6}
                                cReporteTurnotrimmedDataURLFirmante6={this.props.cReporteTurnotrimmedDataURLFirmante6}
                                cReporteTurnoNameFirmante7={this.props.cReporteTurnoNameFirmante6}
                                cReporteTurnoIdFirmante7={this.props.cReporteTurnoIdFirmante7}
                                cReporteTurnoPublicKeyFirmante7={this.props.cReporteTurnoPublicKeyFirmante7}
                                cReporteTurnoDateTimeFirmante7={this.props.cReporteTurnoDateTimeFirmante7}
                                cReporteTurnoSignatureFirmante7={this.props.cReporteTurnoSignatureFirmante7}
                                cReporteTurnotrimmedDataURLFirmante7={this.props.cReporteTurnotrimmedDataURLFirmante7}
                                fecha={this.props.fecha}
                                workordercliente={this.props.workordercliente}
                                workordercampaña={this.props.workordercampaña}
                                workordersondaje={this.props.workordersondaje}
                                workordertipoperforacion={this.props.workordertipoperforacion}
                                workorderfase={this.props.workorderfase}
                                workordernumerocontrato={this.props.workordernumerocontrato}
                                workorderdeclinacion={this.props.workorderdeclinacion}
                                workorderobservacion={this.props.workorderobservacion}
                      />
                  </PDFViewer>
              :
                  <PDFViewer width="1000" height="1200" className="app">
                      <ReporteTurno contentState={this.props.contentState}
                                  typeContract={this.props.typeContract}
                                  numberContract={this.props.numberContract}
                                  jsonBlockstackY={this.state.jsonBlockstackY}
                                  description={this.props.description}
                                  startDate={this.props.startDate}
                                  endDate={this.props.endDate}
                                  trimmedDataURL={this.state.trimmedDataURL}
                                  jsonBlockstackFormPago={this.state.jsonBlockstackFormPago}
                                  totaldocumento={this.state.totaldocumento}
                                  originMoney={this.state.originMoney}
                                  cryptoCurrency={this.state.cryptoCurrency}
                                  symbolcurrency={this.state.symbolcurrency}
                                  uuidX={this.state.uuidX}
                                  usernameX={this.state.username}
                                  userOrigin={this.props.userOrigin}
                                  whatUserRole={this.props.whatUserRole}
                                  userRole={this.props.whatUserRole}
                                  colorDark={this.props.colorDark}
                                  getTransaction={this.state.getTransaction}
                                  cReporteTurnoNameFirmante1={this.props.cReporteTurnoNameFirmante1}
                                  cReporteTurnoIdFirmante1={this.props.cReporteTurnoIdFirmante1}
                                  cReporteTurnoPublicKeyFirmante1={this.props.cReporteTurnoPublicKeyFirmante1}
                                  cReporteTurnoDateTimeFirmante1={this.props.cReporteTurnoDateTimeFirmante1}
                                  cReporteTurnoSignatureFirmante1={this.props.cReporteTurnoSignatureFirmante1}
                                  cReporteTurnotrimmedDataURLFirmante1={this.props.cReporteTurnotrimmedDataURLFirmante1}
                                  cReporteTurnoNameFirmante2={this.props.cReporteTurnoNameFirmante2}
                                  cReporteTurnoIdFirmante2={this.props.cReporteTurnoIdFirmante2}
                                  cReporteTurnoPublicKeyFirmante2={this.props.cReporteTurnoPublicKeyFirmante2}
                                  cReporteTurnoDateTimeFirmante2={this.props.cReporteTurnoDateTimeFirmante2}
                                  cReporteTurnoSignatureFirmante2={this.props.cReporteTurnoSignatureFirmante2}
                                  cReporteTurnotrimmedDataURLFirmante2={this.props.cReporteTurnotrimmedDataURLFirmante2}
                                  cReporteTurnoNameFirmante3={this.props.cReporteTurnoNameFirmante3}
                                  cReporteTurnoIdFirmante3={this.props.cReporteTurnoIdFirmante3}
                                  cReporteTurnoPublicKeyFirmante3={this.props.cReporteTurnoPublicKeyFirmante3}
                                  cReporteTurnoDateTimeFirmante3={this.props.cReporteTurnoDateTimeFirmante3}
                                  cReporteTurnoSignatureFirmante3={this.props.cReporteTurnoSignatureFirmante3}
                                  cReporteTurnotrimmedDataURLFirmante3={this.props.cReporteTurnotrimmedDataURLFirmante3}
                                  cReporteTurnoNameFirmante4={this.props.cReporteTurnoNameFirmante4}
                                  cReporteTurnoIdFirmante4={this.props.cReporteTurnoIdFirmante4}
                                  cReporteTurnoPublicKeyFirmante4={this.props.cReporteTurnoPublicKeyFirmante4}
                                  cReporteTurnoDateTimeFirmante4={this.props.cReporteTurnoDateTimeFirmante4}
                                  cReporteTurnoSignatureFirmante4={this.props.cReporteTurnoSignatureFirmante4}
                                  cReporteTurnotrimmedDataURLFirmante4={this.props.cReporteTurnotrimmedDataURLFirmante4}
                                  cReporteTurnoNameFirmante5={this.props.cReporteTurnoNameFirmante5}
                                  cReporteTurnoIdFirmante5={this.props.cReporteTurnoIdFirmante5}
                                  cReporteTurnoPublicKeyFirmante5={this.props.cReporteTurnoPublicKeyFirmante5}
                                  cReporteTurnoDateTimeFirmante5={this.props.cReporteTurnoDateTimeFirmante5}
                                  cReporteTurnoSignatureFirmante5={this.props.cReporteTurnoSignatureFirmante5}
                                  cReporteTurnotrimmedDataURLFirmante5={this.props.cReporteTurnotrimmedDataURLFirmante5}
                                  cReporteTurnoNameFirmante6={this.props.cReporteTurnoNameFirmante6}
                                  cReporteTurnoIdFirmante6={this.props.cReporteTurnoIdFirmante6}
                                  cReporteTurnoPublicKeyFirmante6={this.props.cReporteTurnoPublicKeyFirmante6}
                                  cReporteTurnoDateTimeFirmante6={this.props.cReporteTurnoDateTimeFirmante6}
                                  cReporteTurnoSignatureFirmante6={this.props.cReporteTurnoSignatureFirmante6}
                                  cReporteTurnotrimmedDataURLFirmante6={this.props.cReporteTurnotrimmedDataURLFirmante6}
                                  cReporteTurnoNameFirmante7={this.props.cReporteTurnoNameFirmante6}
                                  cReporteTurnoIdFirmante7={this.props.cReporteTurnoIdFirmante7}
                                  cReporteTurnoPublicKeyFirmante7={this.props.cReporteTurnoPublicKeyFirmante7}
                                  cReporteTurnoDateTimeFirmante7={this.props.cReporteTurnoDateTimeFirmante7}
                                  cReporteTurnoSignatureFirmante7={this.props.cReporteTurnoSignatureFirmante7}
                                  cReporteTurnotrimmedDataURLFirmante7={this.props.cReporteTurnotrimmedDataURLFirmante7}
                                  fecha={this.props.fecha}
                                  workordercliente={this.props.workordercliente}
                                  workordercampaña={this.props.workordercampaña}
                                  workordersondaje={this.props.workordersondaje}
                                  workordertipoperforacion={this.props.workordertipoperforacion}
                                  workorderfase={this.props.workorderfase}
                                  workordernumerocontrato={this.props.workordernumerocontrato}
                                  workorderdeclinacion={this.props.workorderdeclinacion}
                                  pdfReporteTurnoDiaX={this.props.pdfReporteTurnoDiaX}
                                  pdfReporteTurnoNocheX={this.props.pdfReporteTurnoNocheX}
                                  consolidadoFondoX={this.props.consolidadoFondoX}
                                  consolidadoX={this.props.consolidadoX}
                                  workorderobservacion={this.props.workorderobservacion}
                                  consolidadoFondoX2={this.props.consolidadoFondoX2}
                      />
                  </PDFViewer>
              }
        </div>
      )
   }
}
export default ViewerPdfReporteTurno;
