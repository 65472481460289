import React, { Component } from 'react';

//UI
import {Container, Card, Col, FormInput, Button, FormTextarea, Row, Form, FormCheckbox } from "shards-react";

import { Table } from 'reactstrap';

//translate
import { FormattedMessage } from 'react-intl';

import NewMasterFileContractUpload from "./NewMasterFileContractUpload";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//Editor Wysiwyg
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
//import draftToHtml from 'draftjs-to-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

const content = {"entityMap":{},"blocks":[{"key":"637gr","text":"Initialized from content state.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]};

function uploadImageCallBack(file) {
  return new Promise(
    (resolve, reject) => {
      const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
      xhr.open('POST', 'https://api.imgur.com/3/image');
      xhr.setRequestHeader('Authorization', 'Client-ID 8d26ccd12712fca');
      const data = new FormData(); // eslint-disable-line no-undef
      data.append('image', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    },
  );
}


class NewMasterFileContractOther extends Component {
  constructor(props) {
      super(props);

      const contentState = convertFromRaw(content);
      const editorState = EditorState.createWithContent(contentState);

      this.getCompanyData = this.getCompanyData.bind(this);
      this.putCompanyData = this.putCompanyData.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleChange2 = this.handleChange2.bind(this);

      this.state = {
        userSession: new UserSession(),
        username: '',
        user: {},
        language: 'English',
        filedecodeAttachX: {},
        fileAttachX: {},
        typeAttachX: '',
        nameAttachX: '',
        typeAttachText: false,
        typeAttachImage: false,
        typeAttachPdf: true,
        typeAttachAudio: false,
        typeAttachVideo: false,
        yesUpload: false,
        displayWriteUp: true,
        paymentform: false,
        participants: false,
        pay: false,
        certification: false,
        trace: false,
        deliverable: false,
        network: false,
        addons: false,
        activarparticipants: false,
        certificarfirma: false,
        activarentregable: false,
        certificarreglas: false,
        displayPaymentform: false,
        displayParticipants: false,
        displayPay: false,
        displayCertification: false,
        displayTrace: false,
        displayDeliverable: false,
        displayNetwork: false,
        displayAddons: false,
        contentState:contentState,
        editorState: editorState,
        jsonBlockstack: [],
        isActive: false,
       }
    }

    cTitleContract = React.createRef();
    cPriorityContract = React.createRef();
    cTaxContract = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
      this.setState({yesUpload:false, filedecodeAttachX:this.props.fileX})

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val},()=>{
          Promise.all([this.goRole(val),this.getCompanyData(val)])
             .then(
                (resolve) => {},
                (reject) => {}
             )
        })) ;
      }else{
        this.setState({ username },()=>{
          Promise.all([this.goRole(username),this.getCompanyData(username)])
             .then(
                (resolve) => {},
                (reject) => {}
             )

        })
      }
    }

    componentDidMount() {
      this.cPriorityContract.current.value = this.props.priorityX
      this.cTaxContract.current.value = this.props.taxX
      this.cTitleContract.current.value = this.props.titleX
      this.setState({paymentform: this.props.paymentform })
      this.setState({participants: this.props.participants })
      this.setState({pay: this.props.pay})
      this.setState({certification: this.props.certification})
      this.setState({trace: this.props.trace})
      this.setState({deliverable: this.props.deliverable})
      this.setState({network: this.props.network})
      this.setState({addons: this.props.addons})
      this.setState({activarparticipants: this.props.activarparticipants})
      this.setState({certificarfirma: this.props.certificarfirma})
      this.setState({activarentregable: this.props.activarentregable})
      this.setState({certificarreglas: this.props.certificarreglas})
    }

    goRole = (username) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let typeRole2 = 'role'
      this.setState({jsonBlockstack: []})
      getFileDB(username, `${typeRole2}_${this.props.secondaryNameX}.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
                let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                this.setState({jsonBlockstack: jsonBlockstack6})
             }else{
               this.goRole2(username)
             }
           } else {
             this.goRole2(username)
           }
         })
        .catch(error => {
           this.goRole2(username)
        });
    }

    goRole2 = (username) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let typeRole2 = 'role'
      this.setState({jsonBlockstack: []})
      getFileDB(username, `${typeRole2}.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             this.setState({jsonBlockstack: jsonBlockstack6})
           }
         })
         .catch(error => {
         });
    }

    putRole = (codigo,username) =>{
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      const jsonBlockstack1 = JSON.stringify(codigo)
      putFileDB(username, `role_${this.props.secondaryNameX}.json`, 'Crosscheck_MasterFile', jsonBlockstack1, JSON.stringify(options))
        .then(() => {
          //console.log(`Grabación Exitosa (contractdoc.json)`)
        })

    }

    onContentStateChange: Function = (contentState) => {
      this.setState({contentState,});
      if (contentState === '' || contentState === null){
        this.setState({displayWriteUp: true})
      }else{
        this.setState({displayWriteUp: false})
      }
    };

    onEditorStateChange: Function = (editorState) => {
        this.setState({editorState,});
    };

    handleChange(e,chk) {
      const newState = {};
      newState[chk] = !this.state[chk];
      if (!this.state[chk] ===true){
        if (chk==="paymentform"){this.setState({displayPaymentform:true})}else{this.setState({displayPaymentform:false})}
        if (chk==="participants"){this.setState({displayParticipants:true})}else{this.setState({displayParticipants:false})}
        if (chk==="pay"){this.setState({displayPay:true})}else{this.setState({displayPay:false})}
        if (chk==="certification"){this.setState({displayCertification:true})}else{this.setState({displayCertification:false})}
        if (chk==="trace"){this.setState({displayTrace:true})}else{this.setState({displayTrace:false})}
        if (chk==="deliverable"){this.setState({displayDeliverable:true})}else{this.setState({displayDeliverable:false})}
        if (chk==="network"){this.setState({displayNetwork:true})}else{this.setState({displayNetwork:false})}
        if (chk==="addons"){this.setState({displayAddons:true})}else{this.setState({displayAddons:false})}
      }else {
        this.setState({displayPaymentform:false,
                       displayParticipants:false,
                       displayPay:false,
                       displayCertification:false,
                       displayTrace:false,
                       displayDeliverable:false,
                       displayNetwork:false,
                       displayAddons:false})

      }
      this.setState({...this.state, ...newState, isErrorWrite: false})
    }

    handleChange2(e,chk) {
      const newState = {};
      newState[chk] = !this.state[chk];
      this.setState({...this.state, ...newState, isErrorWrite: false})
    }

    handleChangeActive = (e,chk,i,codeX) => {
      const newState = {};
      newState[chk] = !this.state[chk];
      this.setState({...this.state, ...newState, isErrorWrite: false})

      if (chk==='isActive'){
        const {jsonBlockstack} = this.state
        jsonBlockstack.map((todo2, y) => {
          if (y === i) {
            if (todo2.isActive === true){
              todo2.isActive = false
            }else{
              if (todo2.isActive === false){
                todo2.isActive = true
              }
            }
            const {userSession} = this.state
            const storage = new Storage({ userSession });
            const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
            putFileDB(this.state.username, `role_${this.props.secondaryNameX}.json`, 'Crosscheck_MasterFile', JSON.stringify(jsonBlockstack), JSON.stringify(options))
              .then(() => {
            })
          }
        })
      }
    }
    getCompanyData(username){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      getFileDB(username,`${this.props.secondaryNameX}_settings_companydata.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             const contentState = convertFromRaw(JSON.parse(jsonBlockstack5))
             this.setState({contentState, })
             const editorState = EditorState.createWithContent(contentState);
             this.setState({editorState, })
           }
         })
          .catch(error => {
       });
    }

    putCompanyData(){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }

      const BlankDetail = JSON.stringify(this.state.contentState, null, 4)

      putFileDB(this.state.username,`${this.props.secondaryNameX}_settings_companydata.json`, 'Crosscheck_MasterFile', BlankDetail, JSON.stringify(options))
        .then(() => {
           let e = ''

           this.props.sendOther(e,this.cTitleContract.current.value,this.cPriorityContract.current.value,this.cTaxContract.current.value,this.state.filedecodeAttachX,this.props.idxX,this.state.paymentform,this.state.participants,this.state.pay,this.state.trace,this.state.deliverable,this.state.network,this.state.addons, this.state.activarparticipants, this.state.certificarfirma, this.state.activarentregable, this.state.certificarreglas);

        })
    }

    sendOther = e => {
      this.putCompanyData()
    }

    deleteOther = e => {
      this.props.deleteOther(this.props.idxX);
    }


    returnOther = e => {
      this.props.returnOther();
    }

    returnUploadContract = (file, file64) => {
      this.setState({filedecodeAttachX: file,
                     fileAttachX: file64,
                     typeAttachX: '',
                     nameAttachX: '',
                     newUploaded: true,
                     seeAttachX: true,
                     typeAttachText: false,
                     typeAttachImage: true,
                     typeAttachPdf: false,
                     typeAttachAudio: false,
                     typeAttachVideo: false,
                     yesUpload:true,
                     paymentform:true,
                     participants: false,
                     pay: false,
                     certification: false,
                     trace: false,
                     deliverable: false,
                     network: false,
                     addons: false,
                     activarparticipants: false,
                     certificarfirma: false,
                     activarentregable: false,
                     certificarreglas: false,})
    }

    render() {
          const { filedecodeAttachX, fileAttachX, typeAttachX } = this.state;
          const { typeAttachText, typeAttachImage, typeAttachPdf, typeAttachAudio, typeAttachVideo } = this.state;
          const { paymentform, participants, pay, certification, trace, deliverable, network, addons } = this.state;
          const { activarparticipants, certificarfirma, activarentregable, certificarreglas } = this.state;
          let displayPaymentform = false
          let displayParticipants = false
          let displayPay = false
          let displayCertification = false
          let displayTrace = false
          let displayDeliverable = false
          let displayNetwork = false
          let displayAddons = false
          if (paymentform === true){displayPaymentform = true}
          if (participants === true){displayParticipants = true}
          if (pay === true){displayPay = false}
          if (certification === true){displayCertification = true}
          if (trace === true){displayTrace = true}
          if (deliverable === true){displayDeliverable = true}
          if (network === true){displayNetwork = true}
          if (addons === true){displayAddons = true}
          const scale = 1.0

          //const { contentState } = this.state;
          const { editorState } = this.state;
          const { displayWriteUp } = this.state;

          return (
            <Container fluid className="main-content-container px-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
              <Form style={{fontSize:13}}>
                <Card style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                  <Row>
                    <Col md="1"></Col>
                    <Col md="6"><label style={{color: 'red', fontSize:20}}>{` ${this.props.nameX}`}</label></Col>
                  </Row>
                  <Row>&nbsp;</Row>
                  {displayParticipants ? null : null }
                  {displayPaymentform ? null : null }
                  {displayPay ?
                    <Row>
                        <Col md="1"></Col>
                        <Col md="10">
                          <Card style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                            <Row>
                              <Col md="1"></Col>
                              <Col md="6"><label style={{color: 'red', fontSize:16}}><FormattedMessage id="tab.pay" /></label></Col>
                            </Row>
                            <Row>
                            <Table size="sm" className="text-center" responsive hover striped>
                                  <thead>
                                    <tr>
                                      <th style={{ width: "16px" }}><FormattedMessage id="contract.listcol21" /></th>
                                      <th style={{ width: "17px" }}><FormattedMessage id="contract.listcol22" /></th>
                                      <th style={{ width: "17px" }}><FormattedMessage id="contract.listcol23" /></th>
                                      <th style={{ width: "17px" }}><FormattedMessage id="contract.listcol24" /></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <td>
                                        <FormCheckbox
                                            inline
                                            style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}
                                            checked={activarparticipants}
                                            onChange={e=>this.handleChange2(e, "activarparticipants")}
                                            disabled={false}
                                        >
                                        </FormCheckbox>
                                    </td>
                                    <td>
                                        <FormCheckbox
                                            inline
                                            style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}
                                            checked={certificarfirma}
                                            onChange={e=>this.handleChange2(e, "certificarfirma")}
                                            disabled={false}
                                        >
                                        </FormCheckbox>
                                    </td>
                                    <td>
                                        <FormCheckbox
                                            inline
                                            style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}
                                            checked={activarentregable}
                                            onChange={e=>this.handleChange2(e, "activarentregable")}
                                            disabled={false}
                                        >
                                        </FormCheckbox>
                                    </td>
                                    <td>
                                        <FormCheckbox
                                            inline
                                            style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}
                                            checked={certificarreglas}
                                            onChange={e=>this.handleChange2(e, "certificarreglas")}
                                            disabled={false}
                                        >
                                        </FormCheckbox>
                                    </td>
                                  </tbody>
                              </Table>
                              </Row>
                            </Card>
                        </Col>
                        <Col md="1"></Col>
                      </Row>
                  : null }
                  {displayCertification ? null : null }
                  {displayTrace ? null : null }
                  {displayDeliverable ? null : null }
                  {displayNetwork ? null : null }
                  {displayAddons ? null : null }
                  <Row></Row>
                  <Row>
                    <Col md="1"></Col>
                    <Col md="10">
                      <label style={this.props.colorDark ? {color: 'white', fontSize:14} : {color: 'blue', fontSize:14}}><FormattedMessage id="configuration.title" /></label>
                      <Form style={{fontSize:14, backgroundColor:"#F0EFEC"}}>
                        <Editor
                          editorState={editorState}
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                          onContentStateChange={this.onContentStateChange}
                          onEditorStateChange={this.onEditorStateChange}
                          toolbar={{
                            history: { inDropdown: true },
                            image: {
                                  uploadCallback: uploadImageCallBack,
                                  previewImage: true,
                                  alt: { present: true, mandatory: false },
                                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                   },
                          }}
                        />
                      </Form>
                    </Col>
                    <Col md="1"></Col>
                  </Row>
                  <Row>&nbsp;</Row>
                  <Row>
                    <Col md="1"></Col>
                    <Col md="10">
                      <label style={this.props.colorDark ? {color: 'white', fontSize:14} : {color: 'blue', fontSize:14}}><FormattedMessage id="configuration.note" /></label>
                      <FormTextarea
                        style={{fontSize:20}}
                        style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}
                        id="title"
                        type="text"
                        innerRef={this.cTitleContract}
                        placeholder=''
                        alt=''
                      />
                    </Col>
                    <Col md="1"></Col>
                  </Row>
                  <Row>&nbsp;</Row>
                  <Row>
                    <Col md="1"></Col>
                    <Col md="5">
                        <label style={this.props.colorDark ? {color: 'white', fontSize:14} : {color: 'blue', fontSize:14}}><FormattedMessage id="configuration.logo" /></label>
                        <NewMasterFileContractUpload returnUploadContract={this.returnUploadContract} colorDark={ this.props.colorDark}/>
                    </Col>
                    <Col md="5">
                        <label>&nbsp;</label>
                        <Card style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                          <div>
                            <img src={`${filedecodeAttachX}`} style={{"width":"90%"}} />
                          </div>
                        </Card>
                    </Col>
                    <Col md="1"></Col>
                  </Row>
                  <Row>&nbsp;</Row>

                  <Row>
                    <Col md="1"></Col>
                    <Col md="10">
                       <label style={this.props.colorDark ? {color: 'white', fontSize:14} : {color: 'blue', fontSize:14}}><FormattedMessage id="login.section5d" /></label>
                       <Table size="sm" className="text-center" responsive hover striped>
                          <thead>
                            <tr>
                              <th style={{ width: "30px" }}><FormattedMessage id="configuration.code" /></th>
                              <th style={{ width: "160px" }}><FormattedMessage id="configuration.description" /></th>
                              <th style={{ width: "20px" }}><FormattedMessage id="configuration.active" /></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.jsonBlockstack.map((todo, i) => {
                              if (todo.isActive === undefined){todo.isActive = true}
                              return (
                                  <tr key={i}>
                                      <td style={this.props.colorDark ? {color: 'white'} : {color: 'black'}}>{todo.code}</td>
                                      <td style={this.props.colorDark ? {color: 'white'} : {color: 'black'}}>{todo.description}</td>
                                      <td>
                                        <FormCheckbox
                                            inline
                                            checked={todo.isActive}
                                            onChange={e=>this.handleChangeActive(e, "isActive", i, todo.code)}
                                            disabled={false}
                                         >
                                         </FormCheckbox>
                                      </td>
                                  </tr>
                               )})}
                          </tbody>
                        </Table>
                    </Col>
                    <Col md="1"></Col>
                  </Row>

                  <Row>&nbsp;</Row>
                  <Row>
                    <Col md="1"></Col>
                    <Col md="2">
                      <label style={this.props.colorDark ? {color: 'white', fontSize:14} : {color: 'blue', fontSize:14}}><FormattedMessage id="configuration.priority" /></label>
                      <FormInput
                        style={{fontSize:13}}
                        style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}
                        id="priority"
                        type="number"
                        innerRef={this.cPriorityContract}
                        placeholder=''
                        alt=''
                      />
                    </Col>
                    <Col md="2">
                      <label style={this.props.colorDark ? {color: 'white', fontSize:14} : {color: 'blue', fontSize:14}}><FormattedMessage id="configuration.taxrate" /></label>
                      <FormInput
                        style={{fontSize:13}}
                        style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}
                        id="tax"
                        type="number"
                        innerRef={this.cTaxContract}
                        placeholder=''
                        alt=''
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="1"></Col>
                    <Col md="4">
                      <div>
                        <label style={{fontSize:20}}>&nbsp;</label>
                        <br></br>
                        <Button pill theme="success" onClick={e=>this.sendOther(e)}>&nbsp;&nbsp;<FormattedMessage id="configuration.save" />&nbsp;&nbsp;</Button>
                        &nbsp;&nbsp;
                        <Button pill theme="danger" onClick={e=>this.deleteOther(e)}>&nbsp;&nbsp;<FormattedMessage id="invitation.delete" />&nbsp;&nbsp;</Button>
                        &nbsp;&nbsp;
                        <Button pill theme="warning" onClick={e=>this.returnOther(e)}>&nbsp;&nbsp;<FormattedMessage id="contract.listcol19" />&nbsp;&nbsp;</Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>&nbsp;</Row>
                </Card>
              </Form>
            </Container>
           );
     }
}

export default NewMasterFileContractOther;
