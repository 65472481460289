import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormTextarea,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Button,
  Container
} from "shards-react";

import { Table } from 'reactstrap';

import { Divider, Typography, TextField, Grid, Dialog,
         DialogContent, DialogActions, DialogTitle, DialogContentText,
         Button as ButtonMaterialUI } from '@material-ui/core';

import ReactImageFallback from "react-image-fallback";
import ImageUploader from 'react-images-upload';

// blockstack
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'
import { StacksTestnet, StacksMainnet } from '@stacks/network';


//translate
import { FormattedMessage } from 'react-intl';

//base64
import { Base64 } from 'js-base64';

//UUID
import uuid from 'uuid';

//JWT
import jwt from 'jsonwebtoken';

//Clarity
import {updateProfile } from "../../clarity/clarityfunctions"
import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'


function ConfirmacionChangeSocialNetworks (props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"><div className="fa fa-info">&nbsp;&nbsp;{props.dialogTitle}</div></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Table className="text-center" responsive borderless style={{ color: 'black', fontSize:14 }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "520px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <InputGroup className="mb-2">
                            <FormInput
                              style={{fontSize:14}}
                              className="text-left"
                              id="facebook"
                              type="text"
                              disabled={false}
                              placeholder="URL Facebook"
                              alt={props.facebook}
                              value={props.facebook}
                              onChange={e=>props.handleChangeSocialNetworks(e,'facebook')}
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText>
                                <strong style={{cursor: 'pointer'}}>
                                  <a href={props.facebook} target="_blank" rel="noopener noreferrer"><img src="images/profile_facebook.png" weight="30" height="30" alt=""/></a>
                                </strong>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "520px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <InputGroup className="mb-2">
                            <FormInput
                              style={{fontSize:14}}
                              className="text-left"
                              id="twitter"
                              type="text"
                              disabled={false}
                              placeholder="URL Twitter"
                              alt={props.twitter}
                              value={props.twitter}
                              onChange={e=>props.handleChangeSocialNetworks(e,'twitter')}
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText>
                                <strong style={{cursor: 'pointer'}}>
                                  <a href={props.twitter} target="_blank" rel="noopener noreferrer"><img src="images/profile_twitter.png" weight="30" height="30" alt=""/></a>
                                </strong>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "520px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <InputGroup className="mb-2">
                            <FormInput
                              style={{fontSize:14}}
                              className="text-left"
                              id="youtube"
                              type="text"
                              disabled={false}
                              placeholder="URL Youtube"
                              alt={props.youtube}
                              value={props.youtube}
                              onChange={e=>props.handleChangeSocialNetworks(e,'youtube')}
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText>
                                <strong style={{cursor: 'pointer'}}>
                                  <a href={props.youtube} target="_blank" rel="noopener noreferrer"><img src="images/profile_youtube.png" weight="30" height="30" alt=""/></a>
                                </strong>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "520px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <InputGroup className="mb-2">
                            <FormInput
                              style={{fontSize:14}}
                              className="text-left"
                              id="instagram"
                              type="text"
                              disabled={false}
                              placeholder="URL Instagram"
                              alt={props.instagram}
                              value={props.instagram}
                              onChange={e=>props.handleChangeSocialNetworks(e,'instagram')}
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText>
                                <strong style={{cursor: 'pointer'}}>
                                  <a href={props.instagram} target="_blank" rel="noopener noreferrer"><img src="images/profile_instagram.png" weight="30" height="30" alt=""/></a>
                                </strong>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "520px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <InputGroup className="mb-2">
                            <FormInput
                              style={{fontSize:14}}
                              className="text-left"
                              id="linkedin"
                              type="text"
                              disabled={false}
                              placeholder="URL Linkedin"
                              alt={props.linkedin}
                              value={props.linkedin}
                              onChange={e=>props.handleChangeSocialNetworks(e,'linkedin')}
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText>
                                <strong style={{cursor: 'pointer'}}>
                                  <a href={props.linkedin} target="_blank" rel="noopener noreferrer"><img src="images/profile_linkedin.png" weight="30" height="30" alt=""/></a>
                                </strong>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "520px", textAlign:"left", color: 'black', fontSize:14 }}>
                          <InputGroup className="mb-2">
                            <FormInput
                              style={{fontSize:14}}
                              className="text-left"
                              id="pinterest"
                              type="text"
                              disabled={false}
                              placeholder="URL Pinterest"
                              alt={props.pinterest}
                              value={props.pinterest}
                              onChange={e=>props.handleChangeSocialNetworks(e,'pinterest')}
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText>
                                <strong style={{cursor: 'pointer'}}>
                                  <a href={props.pinterest} target="_blank" rel="noopener noreferrer"><img src="images/profile_pinterest.png" weight="30" height="30" alt=""/></a>
                                </strong>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </td>
                      </tr>
                   </tbody>
                </Table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', props.facebook, props.twitter, props.youtube, props.instagram, props.linkedin, props.pinterest)} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm', props.facebook, props.twitter, props.youtube, props.instagram, props.linkedin, props.pinterest)} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class UserDetails extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userSession: new UserSession(),
      username: undefined,
      user: '',
      stxAddress2X: '',
      serverStacksX: '',
      networkX: '',
      identityAddress: undefined,
      decentralizedID: undefined,
      visible: false,
      languaje: 'English',
      imagen: '',
      newname: false,
      newwebsite: false,
      newavatar: false,
      profileName: '',
      profileAvatar: [],
      profileWebSite: '',
      pictures: [],
      file64ProfileAvatar: '',
      dialogOpen: false,
      dialogMessage: '',
      dialogTitle: '',
      facebook: '',
      twitter: '',
      instagram: '',
      youtube: '',
      linkedin: '',
      pinterest: '',
      aboutme: '',
      newAboutme: false,
    };
  }

  c01Aboutme = React.createRef();

  UNSAFE_componentWillMount() {
    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English' })
    }else{
      this.setState({ language: languageX })
    }

    const {userSession} = this.state
    let stacksNetX = ''
    let serverStacksX = ''
    let networkX = ''
    if (userSession.isUserSignedIn()) {
      const user = userSession.loadUserData()
      if (!localStorage["serverStacks"]) {
        serverStacksX = 'MainNet'
      }else{
        serverStacksX = localStorage.getItem('serverStacks')
      }
      if (serverStacksX === 'MainNet'){
	       stacksNetX =  user.profile.stxAddress.mainnet
         networkX = new StacksMainnet()
      }
      if (serverStacksX === 'TestNet'){
	       stacksNetX =  user.profile.stxAddress.testnet
         networkX = new StacksTestnet()
      }

      this.setState({ user })
      this.setState({ stacksNetX })
      this.setState({ serverStacksX })
      this.setState({ networkX })
    }

    const {identityAddress} = userSession.loadUserData();
    const {decentralizedID} = userSession.loadUserData();
    const {name} = userSession.loadUserData().profile;

    this.setState({ identityAddress, decentralizedID, name })

    const {username} = userSession.loadUserData();
    if (username === '' || username === undefined || username === null){
      username_new(stacksNetX).then(val => this.setState({username: val},()=>{
        Promise.all([this.readNameAvatar(val),this.readNameWebSite(val)])
           .then(
              (resolve) => {},
              (reject) => {}
           )
      })) ;
    }else{
      this.setState({ username },()=>{
        Promise.all([this.readNameAvatar(username),this.readNameWebSite(username)])
           .then(
              (resolve) => {},
              (reject) => {}
           )

      })
    }
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.props.activeTab === '3' || this.props.activeTab === '10'){
       this.c01Aboutme.current.value = this.props.aboutme
       this.setState({ aboutme: this.props.aboutme })
    }
    this.setState({ facebook: this.props.facebook, twitter: this.props.twitter, youtube: this.props.youtube, instagram: this.props.instagram, linkedin: this.props.linkedin, pinterest: this.props.pinterest })
  }

  componentDidMount() {
    if (this.props.activeTab === '3' || this.props.activeTab === '10') {
       this.c01Aboutme.current.value = this.props.aboutme
    }
  }

  handleChangeSocialNetworks = (e,type) => {
    if (type === 'facebook'){
       this.setState({facebook: e.target.value})
    }
    if (type === 'twitter'){
       this.setState({twitter: e.target.value})
    }
    if (type === 'youtube'){
       this.setState({youtube: e.target.value})
    }
    if (type === 'instagram'){
       this.setState({instagram: e.target.value})
    }
    if (type === 'linkedin'){
       this.setState({linkedin: e.target.value})
    }
    if (type === 'pinterest'){
       this.setState({pinterest: e.target.value})
    }
  }

  handleDialog = (type, action, facebook,twitter,youtube,instagram,linkedin,pinterest) => {
    if (type === 'Maintain Social Networks') {
      if (action === 'confirm') {
        this.setState({ dialogOpen: false });
        this.addSocialNetworks(facebook,twitter,youtube,instagram,linkedin,pinterest);
      }
      if (action === 'cancel') {
        this.setState({ dialogOpen: false });
      }
    }
  }

  handleCaracteresEspeciales = (e) => {
    let palabra = e.target.value
    let ArrayIdValue = palabra.split(`"`)
    e.target.value = ArrayIdValue[0]
    palabra = e.target.value
    ArrayIdValue = palabra.split("'")
    e.target.value = ArrayIdValue[0]
  }

  changeAvatar = (e) =>{
    e.preventDefault();
    this.setState({ newavatar: true })
  }

  changeRedSocial = (e) =>{
    e.preventDefault();
    this.setState({ dialogOpen: true, dialogTitle: 'Maintain Social Networks' })
  }

  changeAboutMe = (e) =>{
    e.preventDefault();
    this.setState({ newAboutme: true })
  }

  changeName = (e) =>{
    e.preventDefault();
    this.setState({ newname: true })
  }

  changeWebSite = (e) =>{
    e.preventDefault();
    this.setState({ newwebsite: true })
  }

  handleChangeInput = (e) =>{
    e.preventDefault();

    let palabra = e.target.value
    let ArrayIdValue = palabra.split(`"`)
    e.target.value = ArrayIdValue[0]
    palabra = e.target.value
    ArrayIdValue = palabra.split("'")
    e.target.value = ArrayIdValue[0]

    this.setState({profileName: e.target.value})

  }

  handleChangeInputWebSite = (e) =>{
    e.preventDefault();

    let palabra = e.target.value
    let ArrayIdValue = palabra.split(`"`)
    e.target.value = ArrayIdValue[0]
    palabra = e.target.value
    ArrayIdValue = palabra.split("'")
    e.target.value = ArrayIdValue[0]

    this.setState({profileWebSite: e.target.value})
  }

  onDrop = ( pictureFiles, pictureDataURLs) => {
    this.setState({ pictures: pictureFiles, profileAvatar: pictureDataURLs, newavatar: true, file64ProfileAvatar: Base64.encode(pictureDataURLs) });
    const file64 = Base64.encode(pictureDataURLs);
    this.addAvatar(file64)
  }


  putProfile = (jsonBlockstack3) => {

    let jwtProfile = ''
    let jwtFecha = new Date();
    let jwtToken = ''
    let jwtName = jsonBlockstack3.profileName
    let jwtDescription = jsonBlockstack3.aboutme
    let jwtWeb = jsonBlockstack3.webSite
    let jwtEmail = jsonBlockstack3.emailNotifications
    let jwtSms = jsonBlockstack3.smsNotifications
    let jwtSmsCountry = jsonBlockstack3.smsCountry
    let jwtSmsPrefix = jsonBlockstack3.smsPrefix
    let jwtScopeSms = jsonBlockstack3.smsScope
    let jwtScopeEmail = jsonBlockstack3.emailScope
    let jwtIdBitcoin = jsonBlockstack3.BTC
    let jwtScopeBitcoin = jsonBlockstack3.btcScope
    let jwtIdStacks = jsonBlockstack3.STX
    let jwtScopeStacks = jsonBlockstack3.stxScope
    let jwtUrlFacebook = jsonBlockstack3.facebook
    let jwtUrlTwitter = jsonBlockstack3.twitter
    let jwtUrlYoutube = jsonBlockstack3.youtube
    let jwtUrlInstagram = jsonBlockstack3.instagram
    let jwtUrlLinkedin = jsonBlockstack3.linkedin
    let jwtUrlPinterest = jsonBlockstack3.pinterest
    let jwtAvatar = jsonBlockstack3.profileAvatar
    let jwtSignature = ''

    jwtProfile = jwtProfile + `[`
    jwtProfile = jwtProfile + `  {`
    jwtProfile = jwtProfile + `    "header": {`
    jwtProfile = jwtProfile + `      "typ": "JWT",`
    jwtProfile = jwtProfile + `       "alg": "HS256"`
    jwtProfile = jwtProfile + `     },`
    jwtProfile = jwtProfile + `     "payload": {`
    jwtProfile = jwtProfile + `        "jti": "${uuid()}",`
    jwtProfile = jwtProfile + `        "iat": "${jwtFecha.toISOString()}",`
    jwtProfile = jwtProfile + `        "exp": "${jwtFecha.toISOString()}",`
    jwtProfile = jwtProfile + `        "claim": {`
    jwtProfile = jwtProfile + `          "@type": "Person",`
    jwtProfile = jwtProfile + `          "@context": "http://schema.org",`
    jwtProfile = jwtProfile + `          "name": "${jwtName}",`
    jwtProfile = jwtProfile + `          "description": "${jwtDescription}",`
    jwtProfile = jwtProfile + `          "web": "${jwtWeb}",`
    jwtProfile = jwtProfile + `          "account": [`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "email",`
    jwtProfile = jwtProfile + `              "identifier": "${jwtEmail}",`
    jwtProfile = jwtProfile + `              "proofType": "mailto",`
    jwtProfile = jwtProfile + `              "proofUrl": "",`
    jwtProfile = jwtProfile + `              "scope": "${jwtScopeEmail}"`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "sms",`
    jwtProfile = jwtProfile + `              "identifier": "${jwtSms}",`
    jwtProfile = jwtProfile + `              "proofType": "phone",`
    jwtProfile = jwtProfile + `              "proofUrl": "",`
    jwtProfile = jwtProfile + `              "smsCountry": "${jwtSmsCountry}",`
    jwtProfile = jwtProfile + `              "smsPrefix": "${jwtSmsPrefix}",`
    jwtProfile = jwtProfile + `              "scope": "${jwtScopeSms}"`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "bitcoin",`
    jwtProfile = jwtProfile + `              "identifier": "${jwtIdBitcoin}",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "",`
    jwtProfile = jwtProfile + `              "scope": "${jwtScopeBitcoin}"`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "stacks",`
    jwtProfile = jwtProfile + `              "identifier": "${jwtIdStacks}",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "",`
    jwtProfile = jwtProfile + `              "scope": "${jwtScopeStacks}"`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "facebook",`
    jwtProfile = jwtProfile + `              "identifier": "",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "${jwtUrlFacebook}",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "twitter",`
    jwtProfile = jwtProfile + `              "identifier": "",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "${jwtUrlTwitter}",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "youtube",`
    jwtProfile = jwtProfile + `              "identifier": "",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "${jwtUrlYoutube}",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "instagram",`
    jwtProfile = jwtProfile + `              "identifier": "",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "${jwtUrlInstagram}",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "linkedin",`
    jwtProfile = jwtProfile + `              "identifier": "",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "${jwtUrlLinkedin}",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "pinterest",`
    jwtProfile = jwtProfile + `              "identifier": "",`
    jwtProfile = jwtProfile + `              "proofType": "http",`
    jwtProfile = jwtProfile + `              "proofUrl": "${jwtUrlPinterest}",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "did:web",`
    jwtProfile = jwtProfile + `              "identifier": "${this.props.username}",`
    jwtProfile = jwtProfile + `              "proofType": "did",`
    jwtProfile = jwtProfile + `              "proofUrl": "",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "did:stack:v2",`
    jwtProfile = jwtProfile + `              "identifier": "did:stack:v2:${this.props.STX}",`
    jwtProfile = jwtProfile + `              "proofType": "did",`
    jwtProfile = jwtProfile + `              "proofUrl": "",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            },`
    jwtProfile = jwtProfile + `            {`
    jwtProfile = jwtProfile + `              "@type": "Account",`
    jwtProfile = jwtProfile + `              "placeholder": false,`
    jwtProfile = jwtProfile + `              "service": "did:btc-addr",`
    jwtProfile = jwtProfile + `              "identifier": "did:btc-addr:${this.props.identityAddress}",`
    jwtProfile = jwtProfile + `              "proofType": "did",`
    jwtProfile = jwtProfile + `              "proofUrl": "",`
    jwtProfile = jwtProfile + `              "scope": ""`
    jwtProfile = jwtProfile + `            }`
    jwtProfile = jwtProfile + `          ],`
    jwtProfile = jwtProfile + `          "avatar": "${jwtAvatar}"`
    jwtProfile = jwtProfile + `        }`
    jwtProfile = jwtProfile + `     }`
    jwtProfile = jwtProfile + `  }`
    jwtProfile = jwtProfile + `]`

    jwtToken = jwt.sign(jwtProfile,`"${this.props.username}${jwtFecha.toISOString()}"`)

    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
    putFileDB(this.props.username, `profile.json`, 'Crosscheck_Profile', jwtToken, JSON.stringify(options))
     .then(() => {
       this.putDidW3c(jsonBlockstack3)
    })
  }

  putDidW3c = (jsonBlockstack3) => {
    let jwtDidW3c = ''
    jwtDidW3c = jwtDidW3c + `{`
    jwtDidW3c = jwtDidW3c + `  "@context": "https://www.w3.org/ns/did/v1",`
    jwtDidW3c = jwtDidW3c + `  "id": "did:web:${this.props.username}",`
    jwtDidW3c = jwtDidW3c + `  "verificationMethod": [{`
    jwtDidW3c = jwtDidW3c + `     "id": "did:web:${this.props.username}#controller",`
    jwtDidW3c = jwtDidW3c + `     "type": "Secp256k1",`
    jwtDidW3c = jwtDidW3c + `     "controller": "did:web:${this.props.username}",`
    jwtDidW3c = jwtDidW3c + `     "stacksAddress": "${jsonBlockstack3.STX}"`
    jwtDidW3c = jwtDidW3c + `  }],`
    jwtDidW3c = jwtDidW3c + `  "authentication": [`
    jwtDidW3c = jwtDidW3c + `     "did:web:${this.props.username}#controller"`
    jwtDidW3c = jwtDidW3c + `  ]`
    jwtDidW3c = jwtDidW3c + `}`

    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
    putFileDB(this.props.username, `didw3c.json`, 'Crosscheck_Profile', jwtDidW3c, JSON.stringify(options))
     .then(() => {
    })
  }

  readNameAvatar = (username) => {
    const {userSession} = this.state
    const storage = new Storage({ userSession });


console.log('ddddddddddddddddddddddddddd')
console.log(username)

    const options = { decrypt: false, verify: false }
    getFileDB(username, `myUserConfig.json`)
       .then((fileContents) => {
         if(fileContents) {
           const jsonBlockstack1 = JSON.parse(fileContents)
           const jsonBlockstack2 = jsonBlockstack1.data
           let jsonBlockstack5 = '[]'
           if (jsonBlockstack2 !== null){
              const jsonBlockstack3 = jsonBlockstack2.dataobject
              const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
              if (jsonBlockstack4.substring(0,1) === '"') {
                 jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
              } else {
                 jsonBlockstack5 = jsonBlockstack4
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)

              this.setState({ jsonProfileUser: jsonBlockstack5  });
              this.setState({profileName: jsonBlockstack6.profileName})
              this.setState({profileWebSite: jsonBlockstack6.webSite})
              this.setState({facebook: jsonBlockstack6.facebook,twitter: jsonBlockstack6.twitter,youtube: jsonBlockstack6.youtube,instagram: jsonBlockstack6.instagram,linkedin: jsonBlockstack6.linkedin,pinterest: jsonBlockstack6.pinterest,aboutme: jsonBlockstack6.aboutme})
              this.setState({profileAvatar: Base64.decode(jsonBlockstack6.profileAvatar)})
           }
         }
       })
      .catch(error => {
       });
  }

  readNameWebSite = (username) => {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    getFileDB(username, `myUserConfig.json`)
       .then((fileContents) => {
         if(fileContents) {
           const jsonBlockstack1 = JSON.parse(fileContents)
           const jsonBlockstack2 = jsonBlockstack1.data
           let jsonBlockstack5 = '[]'
           if (jsonBlockstack2 !== null){
              const jsonBlockstack3 = jsonBlockstack2.dataobject
              const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
              if (jsonBlockstack4.substring(0,1) === '"') {
                 jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
              } else {
                 jsonBlockstack5 = jsonBlockstack4
              }
           }
           let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
           this.setState({profileWebSite: jsonBlockstack6.webSite})
           this.setState({ jsonProfileUser: jsonBlockstack5  });
       }
       })
      .catch(error => {
       });
  }


  addAvatar = (file64) => {
    this.setState({ newname: false })
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    getFileDB(this.props.username, `myUserConfig.json`)
       .then((fileContents) => {
         if(fileContents) {
           const jsonBlockstack1 = JSON.parse(fileContents)
           const jsonBlockstack2 = jsonBlockstack1.data
           let jsonBlockstack5 = '[]'
           if (jsonBlockstack2 !== null){
              const jsonBlockstack3 = jsonBlockstack2.dataobject
              const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
              if (jsonBlockstack4.substring(0,1) === '"') {
                 jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
              } else {
                 jsonBlockstack5 = jsonBlockstack4
              }
           }
           let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
           this.setState({ jsonProfileUser: jsonBlockstack5  });
           const jsonBlockstack =`{"emailNotifications":"${jsonBlockstack6.emailNotifications}","smsNotifications":"${jsonBlockstack6.smsNotifications}","STX":"${jsonBlockstack6.STX}","KDA":"${jsonBlockstack6.KDA}","ETH":"${jsonBlockstack6.ETH}","profileName":"${jsonBlockstack6.profileName}","profileAvatar":"${file64}","facebook":"${jsonBlockstack6.facebook}","twitter":"${jsonBlockstack6.twitter}","youtube":"${jsonBlockstack6.youtube}","instagram":"${jsonBlockstack6.instagram}","linkedin":"${jsonBlockstack6.linkedin}","pinterest":"${jsonBlockstack6.pinterest}","aboutme":"${jsonBlockstack6.aboutme}","smsScope":"${jsonBlockstack6.smsScope}","emailScope":"${jsonBlockstack6.emailScope}","smsCountry":"${jsonBlockstack6.smsCountry}","smsPrefix":"${jsonBlockstack6.smsPrefix}","stxScope":"${jsonBlockstack6.stxScope}","webSite":"${jsonBlockstack6.webSite}","BTC":"${jsonBlockstack6.BTC}","btcScope":"${jsonBlockstack6.btcScope}"}`;
           const jsonBlockstack9 = JSON.parse(jsonBlockstack)
           this.putName(jsonBlockstack,jsonBlockstack9)
         } else {
             const jsonBlockstack =`{"emailNotifications":"","smsNotifications":"","STX":"","KDA":"","ETH":"","profileName":"${this.state.profileName}","profileAvatar":"${file64}","facebook":"${this.state.facebook}","twitter":"${this.state.twitter}","youtube":"${this.state.youtube}","instagram":"${this.state.instagram}","linkedin":"${this.state.linkedin}","pinterest":"${this.state.pinterest}","aboutme":"${this.state.aboutme}","smsScope":"","emailScope":"","smsCountry":"","smsPrefix":"","stxScope":"","webSite":"","BTC":"","btcScope":""}`;
             this.putName(jsonBlockstack,null)
         }
       })
      .catch(error => {
          const jsonBlockstack =`{"emailNotifications":"","smsNotifications":"","STX":"","KDA":"","ETH":"","profileName":"${this.state.profileName}","profileAvatar":"${file64}","facebook":"${this.state.facebook}","twitter":"${this.state.twitter}","youtube":"${this.state.youtube}","instagram":"${this.state.instagram}","linkedin":"${this.state.linkedin}","pinterest":"${this.state.pinterest}","aboutme":"${this.state.aboutme}","smsScope":"","emailScope":"","smsCountry":"","smsPrefix":"","stxScope":"","webSite":"","BTC":"","btcScope":""}`;
          this.putName(jsonBlockstack,null)
       });
  }

  addName = (e) => {
    e.preventDefault();
    this.setState({ newname: false })
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    getFileDB(this.props.username, `myUserConfig.json`)
       .then((fileContents) => {
         if(fileContents) {
           const jsonBlockstack1 = JSON.parse(fileContents)
           const jsonBlockstack2 = jsonBlockstack1.data
           let jsonBlockstack5 = '[]'
           if (jsonBlockstack2 !== null){
              const jsonBlockstack3 = jsonBlockstack2.dataobject
              const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
              if (jsonBlockstack4.substring(0,1) === '"') {
                 jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
              } else {
                 jsonBlockstack5 = jsonBlockstack4
              }
           }
           let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
           this.setState({ jsonProfileUser: jsonBlockstack5  });
           const jsonBlockstack =`{"emailNotifications":"${jsonBlockstack6.emailNotifications}","smsNotifications":"${jsonBlockstack6.smsNotifications}","STX":"${jsonBlockstack6.STX}","KDA":"${jsonBlockstack6.KDA}","ETH":"${jsonBlockstack6.ETH}","profileName":"${this.state.profileName}","profileAvatar":"${jsonBlockstack6.profileAvatar}","facebook":"${jsonBlockstack6.facebook}","twitter":"${jsonBlockstack6.twitter}","youtube":"${jsonBlockstack6.youtube}","instagram":"${jsonBlockstack6.instagram}","linkedin":"${jsonBlockstack6.linkedin}","pinterest":"${jsonBlockstack6.pinterest}","aboutme":"${jsonBlockstack6.aboutme}","smsScope":"${jsonBlockstack6.smsScope}","emailScope":"${jsonBlockstack6.emailScope}","smsCountry":"${jsonBlockstack6.smsCountry}","smsPrefix":"${jsonBlockstack6.smsPrefix}","stxScope":"${jsonBlockstack6.stxScope}","webSite":"${jsonBlockstack6.webSite}","BTC":"${jsonBlockstack6.BTC}","btcScope":"${jsonBlockstack6.btcScope}"}`;
           const jsonBlockstack9 = JSON.parse(jsonBlockstack)
           this.putName(jsonBlockstack,jsonBlockstack9)

           updateProfile(this.props.networkX,this.props.storageX,this.props.zonefileX,this.state.profileName,this.state.profileWebSite,this.state.facebook,this.state.twitter,this.state.youtube,this.state.instagram,this.state.linkedin,this.state.pinterest,this.state.aboutme,this.state.profileAvatar)
            .then()

         } else {
           const jsonBlockstack =`{"emailNotifications":"","smsNotifications":"","STX":"","KDA":"","ETH":"","profileName":"${this.state.profileName}","profileAvatar":"","facebook":"${this.state.facebook}","twitter":"${this.state.twitter}","youtube":"${this.state.youtube}","instagram":"${this.state.instagram}","linkedin":"${this.state.linkedin}","pinterest":"${this.state.pinterest}","aboutme":"${this.state.aboutme}","smsScope":"","emailScope":"","smsCountry":"","smsPrefix":"","stxScope":"","webSite":"","BTC":"","btcScope":""}`;
           this.putName(jsonBlockstack,null)
         }
       })
      .catch(error => {
        const jsonBlockstack =`{"emailNotifications":"","smsNotifications":"","STX":"","KDA":"","ETH":"","profileName":"${this.state.profileName}","profileAvatar":"","facebook":"${this.state.facebook}","twitter":"${this.state.twitter}","youtube":"${this.state.youtube}","instagram":"${this.state.instagram}","linkedin":"${this.state.linkedin}","pinterest":"${this.state.pinterest}","aboutme":"${this.state.aboutme}","smsScope":"","emailScope":"","smsCountry":"","smsPrefix":"","stxScope":"","webSite":"","BTC":"","btcScope":""}`;
        this.putName(jsonBlockstack,null)
       });
  }

  addWebSite = (e) => {
    e.preventDefault();
    this.setState({ newwebsite: false })
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    getFileDB(this.props.username, `myUserConfig.json`)
       .then((fileContents) => {
         if(fileContents) {
           const jsonBlockstack1 = JSON.parse(fileContents)
           const jsonBlockstack2 = jsonBlockstack1.data
           let jsonBlockstack5 = '[]'
           if (jsonBlockstack2 !== null){
              const jsonBlockstack3 = jsonBlockstack2.dataobject
              const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
              if (jsonBlockstack4.substring(0,1) === '"') {
                 jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
              } else {
                 jsonBlockstack5 = jsonBlockstack4
              }
           }
           let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
           this.setState({ jsonProfileUser: jsonBlockstack5  });
           const jsonBlockstack =`{"emailNotifications":"${jsonBlockstack6.emailNotifications}","smsNotifications":"${jsonBlockstack6.smsNotifications}","STX":"${jsonBlockstack6.STX}","KDA":"${jsonBlockstack6.KDA}","ETH":"${jsonBlockstack6.ETH}","profileName":"${jsonBlockstack6.profileName}","profileAvatar":"${jsonBlockstack6.profileAvatar}","facebook":"${jsonBlockstack6.facebook}","twitter":"${jsonBlockstack6.twitter}","youtube":"${jsonBlockstack6.youtube}","instagram":"${jsonBlockstack6.instagram}","linkedin":"${jsonBlockstack6.linkedin}","pinterest":"${jsonBlockstack6.pinterest}","aboutme":"${jsonBlockstack6.aboutme}","smsScope":"${jsonBlockstack6.smsScope}","emailScope":"${jsonBlockstack6.emailScope}","smsCountry":"${jsonBlockstack6.smsCountry}","smsPrefix":"${jsonBlockstack6.smsPrefix}","stxScope":"${jsonBlockstack6.stxScope}","webSite":"${this.state.profileWebSite}","BTC":"${jsonBlockstack6.BTC}","btcScope":"${jsonBlockstack6.btcScope}"}`;
           const jsonBlockstack9 = JSON.parse(jsonBlockstack)
           this.putName(jsonBlockstack,jsonBlockstack9)
         } else {
           const jsonBlockstack =`{"emailNotifications":"","smsNotifications":"","STX":"","KDA":"","ETH":"","profileName":"${this.state.profileName}","profileAvatar":"","facebook":"${this.state.facebook}","twitter":"${this.state.twitter}","youtube":"${this.state.youtube}","instagram":"${this.state.instagram}","linkedin":"${this.state.linkedin}","pinterest":"${this.state.pinterest}","aboutme":"${this.state.aboutme}","smsScope":"","emailScope":"","smsCountry":"","smsPrefix":"","stxScope":"","webSite":"${this.state.profileWebSite}","BTC":"","btcScope":""}`;
           this.putName(jsonBlockstack,null)
         }
       })
      .catch(error => {
        const jsonBlockstack =`{"emailNotifications":"","smsNotifications":"","STX":"","KDA":"","ETH":"","profileName":"${this.state.profileName}","profileAvatar":"","facebook":"${this.state.facebook}","twitter":"${this.state.twitter}","youtube":"${this.state.youtube}","instagram":"${this.state.instagram}","linkedin":"${this.state.linkedin}","pinterest":"${this.state.pinterest}","aboutme":"${this.state.aboutme}","smsScope":"","emailScope":"","smsCountry":"","smsPrefix":"","stxScope":"","webSite":"${this.state.profileWebSite}","BTC":"","btcScope":""}`;
        this.putName(jsonBlockstack,null)
       });
  }

  addAboutMe = (e) => {
    e.preventDefault();
    this.setState({ newAboutme: false })
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    let aboutmeX = ''
    getFileDB(this.props.username, `myUserConfig.json`)
       .then((fileContents) => {
         if(fileContents) {
           const jsonBlockstack1 = JSON.parse(fileContents)
           const jsonBlockstack2 = jsonBlockstack1.data
           let jsonBlockstack5 = '[]'
           if (jsonBlockstack2 !== null){
              const jsonBlockstack3 = jsonBlockstack2.dataobject
              const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
              if (jsonBlockstack4.substring(0,1) === '"') {
                 jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
              } else {
                 jsonBlockstack5 = jsonBlockstack4
              }
           }
           let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
           this.setState({ jsonProfileUser: jsonBlockstack5  });

           aboutmeX = jsonBlockstack6.aboutme
           if (this.props.activeTab === '3'){
              aboutmeX = this.c01Aboutme.current.value
           }

           const jsonBlockstack =`{"emailNotifications":"${jsonBlockstack6.emailNotifications}","smsNotifications":"${jsonBlockstack6.smsNotifications}","STX":"${jsonBlockstack6.STX}","KDA":"${jsonBlockstack6.KDA}","ETH":"${jsonBlockstack6.ETH}","profileName":"${this.state.profileName}","profileAvatar":"${jsonBlockstack6.profileAvatar}","facebook":"${jsonBlockstack6.facebook}","twitter":"${jsonBlockstack6.twitter}","youtube":"${jsonBlockstack6.youtube}","instagram":"${jsonBlockstack6.instagram}","linkedin":"${jsonBlockstack6.linkedin}","pinterest":"${jsonBlockstack6.pinterest}","aboutme":"${aboutmeX}","smsScope":"${jsonBlockstack6.smsScope}","emailScope":"${jsonBlockstack6.emailScope}","smsCountry":"${jsonBlockstack6.smsCountry}","smsPrefix":"${jsonBlockstack6.smsPrefix}","stxScope":"${jsonBlockstack6.stxScope}","webSite":"${jsonBlockstack6.webSite}","BTC":"${jsonBlockstack6.BTC}","btcScope":"${jsonBlockstack6.btcScope}"}`;
           const jsonBlockstack9 = JSON.parse(jsonBlockstack)
           this.putName(jsonBlockstack,jsonBlockstack9)
         } else {
           const jsonBlockstack =`{"emailNotifications":"","smsNotifications":"","STX":"","KDA":"","ETH":"","profileName":"${this.state.profileName}","profileAvatar":"","facebook":"${this.state.facebook}","twitter":"${this.state.twitter}","youtube":"${this.state.youtube}","instagram":"${this.state.instagram}","linkedin":"${this.state.linkedin}","pinterest":"${this.state.pinterest}","aboutme":"${aboutmeX}","smsScope":"","emailScope":"","smsCountry":"","smsPrefix":"","stxScope":"","webSite":"","BTC":"","btcScope":""}`;

           if (this.props.activeTab === '3'){
              this.setState({ aboutme: aboutmeX })
           }

           this.putName(jsonBlockstack,null)
         }
       })
      .catch(error => {
        const jsonBlockstack =`{"emailNotifications":"","smsNotifications":"","STX":"","KDA":"","ETH":"","profileName":"${this.state.profileName}","profileAvatar":"","facebook":"${this.state.facebook}","twitter":"${this.state.twitter}","youtube":"${this.state.youtube}","instagram":"${this.state.instagram}","linkedin":"${this.state.linkedin}","pinterest":"${this.state.pinterest}","aboutme":"${aboutmeX}","smsScope":"","emailScope":"","smsCountry":"","smsPrefix":"","stxScope":"","webSite":"","BTC":"","btcScope":""}`;
        this.putName(jsonBlockstack,null)
       });
  }

  addSocialNetworks = (facebook,twitter,youtube,instagram,linkedin,pinterest) => {
    this.setState({ newAboutme: false })

    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    let aboutmeX = ''
    getFileDB(this.props.username, `myUserConfig.json`)
       .then((fileContents) => {
         if(fileContents) {
           const jsonBlockstack1 = JSON.parse(fileContents)
           const jsonBlockstack2 = jsonBlockstack1.data
           let jsonBlockstack5 = '[]'
           if (jsonBlockstack2 !== null){
              const jsonBlockstack3 = jsonBlockstack2.dataobject
              const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
              if (jsonBlockstack4.substring(0,1) === '"') {
                 jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
              } else {
                 jsonBlockstack5 = jsonBlockstack4
              }
           }
           let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
           this.setState({ jsonProfileUser: jsonBlockstack5  });

           aboutmeX = jsonBlockstack6.aboutme
           if (this.props.activeTab === '3'){
              aboutmeX = this.c01Aboutme.current.value
           }

           const jsonBlockstack =`{"emailNotifications":"${jsonBlockstack6.emailNotifications}","smsNotifications":"${jsonBlockstack6.smsNotifications}","STX":"${jsonBlockstack6.STX}","KDA":"${jsonBlockstack6.KDA}","ETH":"${jsonBlockstack6.ETH}","profileName":"${this.state.profileName}","profileAvatar":"${jsonBlockstack6.profileAvatar}","facebook":"${facebook}","twitter":"${twitter}","youtube":"${youtube}","instagram":"${instagram}","linkedin":"${linkedin}","pinterest":"${pinterest}","aboutme":"${aboutmeX}","smsScope":"${jsonBlockstack6.smsScope}","emailScope":"${jsonBlockstack6.emailScope}","smsCountry":"${jsonBlockstack6.smsCountry}","smsPrefix":"${jsonBlockstack6.smsPrefix}","stxScope":"${jsonBlockstack6.stxScope}","webSite":"${jsonBlockstack6.webSite}","BTC":"${jsonBlockstack6.BTC}","btcScope":"${jsonBlockstack6.btcScope}","applicationsScope":"${jsonBlockstack6.applicationsScope}"}`;
           const jsonBlockstack9 = JSON.parse(jsonBlockstack)

           if (this.props.activeTab === '3'){
              this.setState({ aboutme: aboutmeX })
           }

           this.putName(jsonBlockstack,jsonBlockstack9)
         } else {
           const jsonBlockstack =`{"emailNotifications":"","smsNotifications":"","STX":"","KDA":"","ETH":"","profileName":"${this.state.profileName}","profileAvatar":"","facebook":"${facebook}","twitter":"${twitter}","youtube":"${youtube}","instagram":"${instagram}","linkedin":"${linkedin}","pinterest":"${pinterest}","aboutme":"${aboutmeX}","smsScope":"","emailScope":"","smsCountry":"","smsPrefix":"","stxScope":"","webSite":"","BTC":"","btcScope":"","applicationsScope":""}`;
           this.putName(jsonBlockstack,null)
         }
       })
      .catch(error => {
        const jsonBlockstack =`{"emailNotifications":"","smsNotifications":"","STX":"","KDA":"","ETH":"","profileName":"${this.state.profileName}","profileAvatar":"","facebook":"${facebook}","twitter":"${twitter}","youtube":"${youtube}","instagram":"${instagram}","linkedin":"${linkedin}","pinterest":"${pinterest}","aboutme":"${aboutmeX}","smsScope":"","emailScope":"","smsCountry":"","smsPrefix":"","stxScope":"","webSite":"","BTC":"","btcScope":"","applicationsScope":""}`;
        this.putName(jsonBlockstack,null)
       });
  }

  putName = (jsonBlockstack,jsonBlockstack3) =>{
      this.setState({ jsonBlockstack })
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      putFileDB(this.props.username, `myUserConfig.json`, 'Crosscheck_Profile', JSON.stringify(jsonBlockstack), JSON.stringify(options))
       .then(() => {
         if (jsonBlockstack3 !== null){
            this.putProfile(jsonBlockstack3)
         }
       })
  }

  putImagen = (jsonBlockstack,jsonBlockstack9,nameFile,imagen) =>{
      this.setState({ jsonBlockstack })
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      putFileDB(this.props.username, nameFile, 'Crosscheck_Profile', imagen, JSON.stringify(options))
       .then(() => {
         this.putName(jsonBlockstack,jsonBlockstack9)
       })
  }

  render() {
    const languageX = this.state.language
    let descripX = ''
    let title = ''
    let placeholderX3 = "Enter a short description about yourself "
    if (languageX === 'English'){title =  this.props.userDetails.metaTitle;descripX = this.props.userDetails.metaValue;placeholderX3 = "Enter a short description about yourself "}
    if (languageX === 'Spanish'){title =  this.props.userDetails.metaTitle_es;descripX = this.props.userDetails.metaValue_es;placeholderX3 = "Ingresa una breve descripcion sobre ti"}
    if (languageX === 'French'){title =  this.props.userDetails.metaTitle_fr;descripX = this.props.userDetails.metaValue_fr;placeholderX3 = "Entrez votre description ici"}
    if (languageX === 'Portuguese'){title =  this.props.userDetails.metaTitle_pt;descripX = this.props.userDetails.metaValue_pt;placeholderX3 = "Insira sua descrição aqui"}
    if (languageX === 'Swedish'){title =  this.props.userDetails.metaTitle_sv;descripX = this.props.userDetails.metaValue_sv;placeholderX3 = "Ange din beskrivning här"}
    if (languageX === 'Netherlands'){title =  this.props.userDetails.metaTitle_nl;descripX = this.props.userDetails.metaValue_nl;placeholderX3 = "Vul hier uw omschrijving in"}
    if (languageX === 'Russian'){title =  this.props.userDetails.metaTitle_ru;descripX = this.props.userDetails.metaValue_ru;placeholderX3 = "Введите свое описание здесь"}
    if (languageX === 'Japanese'){title =  this.props.userDetails.metaTitle_jp;descripX = this.props.userDetails.metaValue_jp;placeholderX3 = "ここに説明を入力してください"}
    if (languageX === 'Chinese'){title =  this.props.userDetails.metaTitle_cn;descripX = this.props.userDetails.metaValue_cn;placeholderX3 = "在此處輸入您的描述"}
    if (languageX === 'German'){title =  this.props.userDetails.metaTitle_de;descripX = this.props.userDetails.metaValue_de;placeholderX3 = "Geben Sie hier Ihre Beschreibung ein"}
    if (languageX === 'Italian'){title =  this.props.userDetails.metaTitle_it;descripX = this.props.userDetails.metaValue_it;placeholderX3 = "Inserisci qui la tua descrizione"}

    const username = this.props.username
    let bUsername = true
    let bUsername2 = false
    if (username === undefined || username === null || username === ''){
      bUsername = false
    }else{
      if (username === this.props.STX.substring(0,5).toLowerCase()+'...'+this.props.STX.substring(this.props.STX.length-5).toLowerCase()+'.xck.app'){
        bUsername2 = true
      }
    }
    let person = ""
    let bperson = true
    if (this.props.name !== '' && this.props.name !== null && this.props.name !== undefined){
       person = this.props.name
       bperson = true
    }else{
       if (this.state.profileName !== '' && this.state.profileName!== null && this.state.profileName !== undefined) {
         person = this.state.profileName
         bperson = true
       }else{
         person = this.props.name
         bperson = true
       }
    }


    let website
    if (this.state.profileWebSite !== '' && this.state.profileWebSite!== null && this.state.profileWebSite !== undefined) {
      website = this.state.profileWebSite
    }

    let avatar = ""
    if (this.props.avatar !== '' && this.props.avatar !== null && this.props.avatar !== undefined){
       avatar = this.props.avatar
    }else{
        if (this.state.profileAvatar !== '' && this.state.profileAvatar !== null && this.state.profileAvatar !== undefined && this.state.profileAvatar.length > 0){
           avatar = this.state.profileAvatar
        }else{
           avatar = this.props.avatar
        }
    }

    const avatar2 = 'images/avatar.png'
    const identityAddress = 'did:btc-addr:'+this.props.identityAddress
    const identityAddressStack = 'did:stack:v2:'+this.props.STX
    const zonefile_hash = this.props.zonefile_hash

    let editavatar = false
    if (this.props.avatar === '' || this.props.avatar === null || this.props.avatar === undefined){
       editavatar = true
    }

    let editname = true
    if (this.state.newname) {
       editname = false
    }

    let editaboutme = true
    let editwebsite = true
    let websocialnetwork = true

    let opacity1X = '0.5'
    let opacity2X = '0.5'
    let opacity3X = '0.5'
    let opacity4X = '0.5'
    let opacity5X = '0.5'
    let opacity6X = '0.5'
    let opacity7X = '0.5'
    let opacity8X = '0.5'
    let opacity9X = '0.5'
    let opacity10X = '0.5'
    let activeTab1X = false
    let activeTab2X = false
    let activeTab3X = false
    let activeTab4X = false
    let activeTab5X = false
    let activeTab6X = false
    let activeTab7X = false
    let activeTab8X = false
    let activeTab9X = false
    let activeTab10X = false
    if (this.props.activeTab === '1'){opacity1X = '1.0'; activeTab1X = true}
    if (this.props.activeTab === '2'){opacity2X = '1.0'; activeTab2X = true}
    if (this.props.activeTab === '3'){opacity3X = '1.0'; activeTab3X = true}
    if (this.props.activeTab === '4'){opacity4X = '1.0'; activeTab4X = true}
    if (this.props.activeTab === '5'){opacity5X = '1.0'; activeTab5X = false}
    if (this.props.activeTab === '6'){opacity6X = '1.0'; activeTab6X = true}
    if (this.props.activeTab === '7'){opacity7X = '1.0'; activeTab7X = true}
    if (this.props.activeTab === '8'){opacity8X = '1.0'; activeTab8X = true}
    if (this.props.activeTab === '9'){opacity9X = '1.0'; activeTab9X = true}
    if (this.props.activeTab === '10'){
       opacity1X = '1.0'; opacity2X = '1.0'; opacity3X = '1.0'; opacity4X = '1.0'; opacity5X = '1.0'; opacity6X = '1.0'; opacity7X = '1.0'; opacity8X = '1.0'; opacity9X = '1.0';
       activeTab1X = true; activeTab2X = true; activeTab3X = true; activeTab4X = true; activeTab5X = false; activeTab6X = true; activeTab7X = true; activeTab8X = true; activeTab9X = true;
       editavatar = false; editname = false; editaboutme = false; editwebsite = false; websocialnetwork = false
    }

    return (
      <>
        <ConfirmacionChangeSocialNetworks
            handleDialog={this.handleDialog}
            dialogMessage={this.state.dialogMessage}
            dialogOpen={this.state.dialogOpen}
            dialogTitle={this.state.dialogTitle}
            facebook={this.state.facebook}
            twitter={this.state.twitter}
            instagram={this.state.instagram}
            youtube={this.state.youtube}
            linkedin={this.state.linkedin}
            pinterest={this.state.pinterest}
            handleChangeSocialNetworks={this.handleChangeSocialNetworks}
        />
        {activeTab1X ?
          <Card small className="mb-4 pt-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d', opacity: opacity1X } : {opacity: opacity1X}}>
              <Table size="sm" className="text-center" responsive borderless>
                  <tbody>
                    <tr>
                      <td style={{ width: "90%" }}>
                        <div className="mb-3 mx-auto">
                          {this.state.newavatar ?
                              <ImageUploader
                                  withIcon={true}
                                  withPreview={true}
                                  buttonText='Choose image'
                                  onChange={this.onDrop}
                                  imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                                  maxFileSize={1048576}
                                  fileSizeError=" file size is too big"
                              />
                          :
                              <>
                                <ReactImageFallback
                                     src={avatar}
                                     fallbackImage={avatar2}
                                     initialImage={avatar2}
                                     alt={" "}
                                     className="rounded-circle"
                                     width="110"
                                />
                              </>
                          }
                        </div>
                      </td>
                      <td style={{ width: "10%" }}>
                        <div className="mb-3 mx-auto">
                          {this.state.newavatar ?
                              null
                          :
                              <>
                                {editavatar ?
                                  <>
                                    &nbsp;&nbsp;
                                    <strong style={{cursor: 'pointer'}} onClick={e=>{this.changeAvatar(e)}}>
                                        { this.props.colorDark ?
                                           <img src="images/editpencil128Grey.png" weight="25" height="25" alt=""/>
                                        :
                                           <img src="images/editpencil128.png" weight="25" height="25" alt=""/>
                                        }
                                    </strong>
                                  </>
                                :
                                  null
                                }
                              </>
                          }
                        </div>
                      </td>
                    </tr>
                  </tbody>
               </Table>
          </Card>
        : null
        }
        {activeTab2X ?
          <Card small className="mb-4 pt-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d', opacity: opacity2X } : {opacity: opacity2X}}>
              <Table size="sm" className="text-center" responsive borderless>
                  <tbody>
                    <tr>
                      <td style={{ width: "90%" }}>
                        {this.state.newname ?
                            <InputGroup className="mb-2">
                              <FormInput
                                style={{fontSize:18}}
                                className="text-center"
                                id="newname"
                                type="text"
                                disabled={false}
                                placeholder={"Nameless"}
                                alt={"Enter the Name"}
                                value={this.state.profileName}
                                onChange={e=>this.handleChangeInput(e)}
                              />
                              <InputGroupAddon type="append">
                                <InputGroupText>
                                   <Button theme="accent" onClick={e=>{this.addName(e)}} ><FormattedMessage id="usergroup.add" /></Button>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                        :
                          <h4 className="mb-0">
                             {bperson ?
                                <>
                                  {this.state.profileName} &nbsp;
                                </>
                             :
                                <>
                                  <span style={{ color: "grey" }}>Nameless&nbsp;&nbsp;</span>
                                </>
                             }
                          </h4>
                        }
                      </td>
                      <td style={{ width: "10%" }}>
                        {this.state.newname ?
                          null
                        :
                          <h4 className="mb-0">
                             {bperson ?
                                <>
                                  {editname ?
                                    <strong style={{cursor: 'pointer'}} onClick={e=>{this.changeName(e)}}>
                                        { this.props.colorDark ?
                                           <img src="images/editpencil128Grey.png" weight="25" height="25" alt=""/>
                                        :
                                           <img src="images/editpencil128.png" weight="25" height="25" alt=""/>
                                        }
                                    </strong>
                                  :
                                    null
                                  }
                                </>
                             :
                                <strong style={{cursor: 'pointer'}} onClick={e=>{this.changeName(e)}}>
                                  { this.props.colorDark ?
                                     <img src="images/editpencil128Grey.png" weight="25" height="25" alt=""/>
                                  :
                                     <img src="images/editpencil128.png" weight="25" height="25" alt=""/>
                                  }
                                  </strong>
                             }
                          </h4>
                        }
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "90%" }}>
                         {bUsername ?
                            <>
                              {bUsername2 ?
                                <>
                                  <span className="text-muted d-block mb-2">{username}{'  ('}<FormattedMessage id="profile.provisory" />{')'}</span>
                                  <br></br>
                                  <span className="text-muted d-block mb-2"><FormattedMessage id="profile.provisory2" /></span>
                                  <br></br>
                                  <a href="https://domains.paradigma.global" target="_blank" rel="noopener noreferrer"><FormattedMessage id="profile.claim" /></a>
                                </>
                              :
                                <span className="text-muted d-block mb-2">{username}</span>
                              }
                            </>
                         :
                            <a href="https://domains.paradigma.global" target="_blank" rel="noopener noreferrer"><FormattedMessage id="profile.claim" /></a>
                         }
                      </td>
                      <td style={{ width: "10%" }}>
                      </td>
                    </tr>
                 </tbody>
              </Table>
          </Card>
        : null
        }
        {activeTab3X ?
          <Card small className="mb-4 pt-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d', opacity: opacity3X } : {opacity: opacity3X}}>
            <ListGroup flush>
              <ListGroupItem className="p-4 text-center" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                  <Table size="sm" className="text-center" responsive borderless>
                      <tbody>
                        <tr>
                          <td style={{ width: "90%" }}>
                             <label style={{ color: 'grey', fontSize:16 }} htmlFor="feAboutMe"><FormattedMessage id="profile.aboutme" /></label>
                          </td>
                          <td style={{ width: "10%" }}>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "90%" }}>
                            <InputGroup className="mb-2">
                              <FormTextarea
                                id="feMessage"
                                rows="5"
                                style={ this.props.colorDark ? { backgroundColor:'#111f2c', color:'white'} : {backgroundColor:"#F4F4F4"}}
                                innerRef={this.c01Aboutme}
                                onChange={e=>this.handleCaracteresEspeciales(e)}
                                disabled={!this.state.newAboutme}
                              />
                              {this.state.newAboutme ?
                                <InputGroupAddon type="append">
                                  <InputGroupText>
                                     <Button theme="accent" onClick={e=>{this.addAboutMe(e)}} ><FormattedMessage id="usergroup.add" /></Button>
                                  </InputGroupText>
                                </InputGroupAddon>
                              :
                                 null
                              }
                            </InputGroup>
                          </td>
                          <td style={{ width: "10%" }}>
                             {this.state.newAboutme ?
                               null
                             :
                               <>
                               { editaboutme ?
                                 <strong style={{cursor: 'pointer'}} onClick={e=>{this.changeAboutMe(e)}}>
                                   { this.props.colorDark ?
                                      <img src="images/editpencil128Grey.png" weight="25" height="25" alt=""/>
                                   :
                                      <img src="images/editpencil128.png" weight="25" height="25" alt=""/>
                                   }
                                 </strong>
                               : null
                               }
                               </>
                             }
                          </td>
                        </tr>
                     </tbody>
                  </Table>
              </ListGroupItem>
            </ListGroup>
          </Card>
        :
          null
        }
        {activeTab4X ?
          <Card small className="mb-4 pt-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d', opacity: opacity4X } : {opacity: opacity4X}}>
            <ListGroup flush>
              <ListGroupItem className="p-4 text-center" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                  <Table size="sm" className="text-center" responsive borderless>
                      <tbody>
                        <tr>
                          <td style={{ width: "90%" }}>
                             <label style={{ color: 'grey', fontSize:16 }} htmlFor="feAboutMe"><FormattedMessage id="profile.website" /></label>
                          </td>
                          <td style={{ width: "10%" }}>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "90%" }}>
                            {this.state.newwebsite ?
                                <InputGroup className="mb-2">
                                  <FormInput
                                    style={ this.props.colorDark ? { backgroundColor:'#111f2c', fontSize:18, color:'white', fontSize:18} : {}}
                                    className="text-center"
                                    id="newwebsite"
                                    type="text"
                                    disabled={false}
                                    placeholder={""}
                                    alt={"Enter the URL Web Site"}
                                    value={this.state.profileWebSite}
                                    onChange={e=>this.handleChangeInputWebSite(e)}
                                  />
                                  <InputGroupAddon type="append">
                                    <InputGroupText>
                                       <Button theme="accent" onClick={e=>{this.addWebSite(e)}} ><FormattedMessage id="usergroup.add" /></Button>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                </InputGroup>
                            :
                              <h4 className="mb-0">
                                 {website ?
                                    <>
                                      <span style={ this.props.colorDark ? { color:'white', fontSize:18} : {color:'black', fontSize:18}} >{website} &nbsp;</span>
                                    </>
                                  :
                                    <>
                                      <span style={{ color: "grey", fontSize:18}} >Enter URL website here &nbsp;&nbsp;</span>
                                    </>
                                 }
                              </h4>
                            }
                          </td>
                          <td style={{ width: "10%" }}>
                            {this.state.newwebsite ?
                              null
                            :
                              <>
                              {editwebsite ?
                                <h4 className="mb-0">
                                    <strong style={{cursor: 'pointer'}} onClick={e=>{this.changeWebSite(e)}}>
                                        { this.props.colorDark ?
                                           <img src="images/editpencil128Grey.png" weight="25" height="25" alt=""/>
                                        :
                                           <img src="images/editpencil128.png" weight="25" height="25" alt=""/>
                                        }
                                    </strong>
                                </h4>
                              : null
                              }
                              </>
                            }
                          </td>
                        </tr>
                     </tbody>
                  </Table>
              </ListGroupItem>
            </ListGroup>

            <ListGroup flush>
              <ListGroupItem className="p-4 text-center" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                  <Table size="sm" className="text-center" responsive borderless style={{ color: 'black', fontSize:11 }}>
                      <tbody>
                        <tr>
                          <td style={{ width: "90%" }}>
                             <label style={{ color: 'grey', fontSize:16 }} htmlFor="feAboutMe"><FormattedMessage id="profile.socialnetwork" /></label>
                          </td>
                          <td style={{ width: "10%" }}>
                          </td>
                        </tr>
                      </tbody>
                  </Table>
                <Row>
                  <Table size="sm" className="text-center" responsive borderless style={{ color: 'black', fontSize:11 }}>
                      <tbody>
                        <tr>
                          <td style={{ width: "15%" }}><a style={{fontSize:9}} href={this.state.facebook} target="_blank" rel="noopener noreferrer"><img src="images/profile_facebook.png" weight="50" height="50" alt=""/></a></td>
                          <td style={{ width: "15%" }}><a style={{fontSize:9}} href={this.state.twitter} target="_blank" rel="noopener noreferrer"><img src="images/profile_twitter.png" weight="50" height="50" alt=""/></a></td>
                          <td style={{ width: "15%" }}><a style={{fontSize:9}} href={this.state.youtube} target="_blank" rel="noopener noreferrer"><img src="images/profile_youtube.png" weight="50" height="50" alt=""/></a></td>
                          <td style={{ width: "15%" }}><a style={{fontSize:9}} href={this.state.instagram} target="_blank" rel="noopener noreferrer"><img src="images/profile_instagram.png" weight="50" height="50" alt=""/></a></td>
                          <td style={{ width: "15%" }}><a style={{fontSize:9}} href={this.state.linkedin} target="_blank" rel="noopener noreferrer"><img src="images/profile_linkedin.png" weight="50" height="50" alt=""/></a></td>
                          <td style={{ width: "15%" }}><a style={{fontSize:9}} href={this.state.pinterest} target="_blank" rel="noopener noreferrer"><img src="images/profile_pinterest.png" weight="50" height="50" alt=""/></a></td>
                          <td style={{ width: "10%" }}>
                             <>
                               { websocialnetwork ?
                                 <strong style={{cursor: 'pointer'}} onClick={e=>{this.changeRedSocial(e)}}>
                                   { this.props.colorDark ?
                                      <img src="images/editpencil128Grey.png" weight="25" height="25" alt=""/>
                                   :
                                      <img src="images/editpencil128.png" weight="25" height="25" alt=""/>
                                   }
                                 </strong>
                                : null
                                }
                              </>
                          </td>
                        </tr>
                        <tr>
                          <td style={ this.props.colorDark ? { width: "15%", textAlign:"center", color: 'white', fontSize:10} : {width: "15%", textAlign:"center", color: 'black', fontSize:10}}>Facebook</td>
                          <td style={ this.props.colorDark ? { width: "15%", textAlign:"center", color: 'white', fontSize:10} : {width: "15%", textAlign:"center", color: 'black', fontSize:10}}>Twitter</td>
                          <td style={ this.props.colorDark ? { width: "15%", textAlign:"center", color: 'white', fontSize:10} : {width: "15%", textAlign:"center", color: 'black', fontSize:10}}>Youtube</td>
                          <td style={ this.props.colorDark ? { width: "15%", textAlign:"center", color: 'white', fontSize:10} : {width: "15%", textAlign:"center", color: 'black', fontSize:10}}>Instagram</td>
                          <td style={ this.props.colorDark ? { width: "15%", textAlign:"center", color: 'white', fontSize:10} : {width: "15%", textAlign:"center", color: 'black', fontSize:10}}>LinkEdIn</td>
                          <td style={ this.props.colorDark ? { width: "15%", textAlign:"center", color: 'white', fontSize:10} : {width: "15%", textAlign:"center", color: 'black', fontSize:10}}>Pinterest</td>
                          <td style={ this.props.colorDark ? { width: "15%", textAlign:"center", color: 'white', fontSize:10} : {width: "15%", textAlign:"center", color: 'black', fontSize:10}}></td>
                        </tr>
                      </tbody>
                  </Table>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        : null
        }
      </>
    )
  }
};

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.object
};

UserDetails.defaultProps = {
  userDetails: {
    name: "John Smith",
    jobTitle: "Nameless",
    jobTitle_es: "Sin Nombre",
    jobTitle_fr: "Sans Nom",
    jobTitle_pt: "Sem Nome",
    jobTitle_sv: "Inget Namn",
    jobTitle_nl: "Geen Naam",
    jobTitle_ru: "Нет имени",
    jobTitle_jp: "名前なし",
    jobTitle_cn: "没有名字",
    jobTitle_de: "Kein Name",
    jobTitle_it: "Nessun Nome",
    performanceReportTitle: "Workload",
    performanceReportValue: 74,
    metaTitle: "Description",
    metaTitle_es: "Descripción",
    metaTitle_fr: "La description",
    metaTitle_pt: "Descrição",
    metaTitle_sv: "Beskrivning",
    metaTitle_nl: "Omschrijving",
    metaTitle_ru: "Описание",
    metaTitle_jp: "説明",
    metaTitle_cn: "描述",
    metaTitle_de: "Beschreibung",
    metaTitle_it: "Descrizione",
    metaValue: "This info represents your descentralized identity, used by login to CrossCheck application.",
    metaValue_es: "Esta información representa su identidad descentralizada, utilizada al iniciar sesión en la aplicación Crosscheck.",
    metaValue_fr: "Cette information représente votre identité décentralisée, utilisée pour vous connecter à l'application Crosscheck.",
    metaValue_pt: "Esta informação representa sua identidade descentralizada, usada pelo login no aplicativo Crosscheck.",
    metaValue_sv: "Denna information representerar din decentraliserade identitet, som används genom att logga in på Crosscheck-applikationen.",
    metaValue_nl: "Deze info vertegenwoordigt uw gedecentraliseerde identiteit, die wordt gebruikt door in te loggen bij de applicatie Crosscheck.",
    metaValue_ru: "Эта информация представляет вашу децентрализованную личность, используемую при входе в приложение Crosscheck.",
    metaValue_jp: "この情報は、パラダイマクロスチェックアプリケーションへのログインで使用される分散IDを表します。",
    metaValue_cn: "此信息表示您的分散身份，登录Crosscheck应用程序时使用。",
    metaValue_de: "Diese Informationen stellen Ihre dezentrale Identität dar, die bei der Anmeldung bei der Crosscheck-Anwendung verwendet wird.",
    metaValue_it: "Queste informazioni rappresentano la tua identità decentralizzata, utilizzata per l'accesso all'applicazione Crosscheck."
  }
};

export default UserDetails;
