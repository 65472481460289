import React, { Component } from 'react';

//UI
import {Col, FormInput, Button } from "shards-react";

//translate
import { FormattedMessage } from 'react-intl';


class ContractUserGroupSendEmail extends Component {
  constructor(props) {
      super(props);

      this.state = {
        language: 'English',
       }
    }

    c03UserGroup = React.createRef();

    UNSAFE_componentWillMount() {
      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
    }

    componentDidMount() {
        this.c03UserGroup.current.value = this.props.emailx
    }

    sendInvite = e => {
      this.props.sendInvite(e,this.c03UserGroup.current.value);
    }

    render() {
          return (
            <React.Fragment>
                <Col md="1"></Col>
                <Col md="6">
                  <label style={{fontSize:20}}><FormattedMessage id="usergroup.entermailof" />{` ${this.props.idx}`}</label>
                  <FormInput
                    style={{fontSize:13}}
                    id="email"
                    type="text"
                    innerRef={this.c03UserGroup}
                    placeholder={this.state.emailx}
                    alt={this.state.emailx}
                  />
                </Col>
                <Col>
                  <div>
                    <label style={{fontSize:20}}>&nbsp;</label>
                    <br></br>
                     <Button pill theme="warning" onClick={e=>this.sendInvite(e)}>&nbsp;&nbsp;<FormattedMessage id="usergroup.submit" />&nbsp;&nbsp;</Button>
                  </div>
                </Col>
            </React.Fragment>
           );
     }
}

export default ContractUserGroupSendEmail;
