//React
import React, { Component } from 'react';

//Redux
import { withRouter } from "react-router-dom";

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Form, Card, Container, Button } from "shards-react";
import { Table } from 'reactstrap';
import { Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';

import TituloPagina from "../components/common/TituloPagina";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

import NewInvitation from "../components/invitation/NewInvitation";

//translate
import { FormattedMessage } from 'react-intl';

//Email
import emailjs from 'emailjs-com'

//Configuración
import {token} from '../config.js'

import {username_new} from '../actions/userActions'
import {getFileDB, putFileDB} from '../actions/mongoDBActions'

function ConfirmacionCancel(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionAccept(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('accept')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'accept')} theme="warning" autoFocus>
                   <FormattedMessage id="alert.accept" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
      let colorThemeX = 'Blue'
      let colorThemeX2 = 'info'
      if (localStorage["colorTheme"]) {colorThemeX = localStorage.getItem('colorTheme')}
      if (colorThemeX === 'Blue') {colorThemeX2 = 'info'}
      if (colorThemeX === 'Green') {colorThemeX2 = 'success'}
      if (colorThemeX === 'Grey') {colorThemeX2 = 'secondary'}

function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="danger" autoFocus>
                     <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="warning">
                     <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class ContenedorInvitation extends Component {

    constructor(props) {
        super(props);

        this.handleChangeOption = this.handleChangeOption.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.sendConfirm = this.sendConfirm.bind(this);
        this.responseConfirm = this.responseConfirm.bind(this);
        this.profileConfirm = this.profileConfirm.bind(this);
        this.clickRemoveInvitation = this.clickRemoveInvitation.bind(this);
        this.crearInvitation = this.crearInvitation.bind(this);
        this.goInvitation = this.goInvitation.bind(this);
        this.goUserConfig = this.goUserConfig.bind(this);
        this.forwardInvitation = this.forwardInvitation.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          jsonBlockstack: [],
          jsonBlockstackZ: [],
          idx: '',
          dialogOpen: false,
          dialogMessage: '',
          dialogTitle: '',
          languaje: 'English',
          setItemInfo: null,
          colorDark: false,
        }
    }

    c01MasterFile = React.createRef();
    c01Setting = React.createRef();
    c02Setting = React.createRef();
    c01IoTDevice = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
      let colorThemeX = 'Blue'
      if (localStorage["colorTheme"]) {
        colorThemeX = localStorage.getItem('colorTheme')
        if (colorThemeX === 'Dark') {
           this.setState({colorDark: true})
        }
      }

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => {
          this.setState({username: val})
          Promise.all(
             [this.goUserConfig(val),
             this.goInvitation(val)
             ]
          ).then(
            (resolve) =>{},
            (reject) =>{}
          )
        })
      }else{
        this.setState({ username })
        Promise.all(
           [this.goUserConfig(username),
           this.goInvitation(username)
           ]
        ).then(
          (resolve) =>{},
          (reject) =>{}
        )
      }
    }

    handleChangeOption(optSearch) {
        this.setState({
          selectedConfiguration: optSearch,
        });
        if (optSearch==="ContractMasterFile"){
          this.goDetailSetting()
          this.setState({displayIoTDevice: false})
        }
    }

    onMouseEnterHandler = (i) =>{
      this.setState({setItemInfo: i})
    }

    onMouseLeaveHandler = (i) => {
    }

    handleDialog = (type, action) => {
        if (type === 'Confirm: 3001') {
            if (action === 'accept') {
              this.setState({ dialogOpenAccept: false });
            }
        }
        if (type === 'Error: 1001') {
            if (action === 'cancel') {
              this.setState({ dialogOpenCancel: false });
            }
        }
        if (type === 'Error: 1002') {
            if (action === 'cancel') {
              this.setState({ dialogOpenCancel: false });
            }
        }
        if (type === 'Confirm: 3002') {
            if (action === 'cancel') {
              this.setState({ dialogOpen: false });
            }
            if (action === 'confirm') {
              this.setState({ dialogOpen: false });
              this.forwardInvitation()
            }
        }
    }

    sendConfirm(e, optx, email, name){
      if (this.state.language === 'English'){this.setState({ dialogOpen: true, dialogMessage: 'Confirm the sending of the email?', dialogTitle: 'Confirm: 3002', email: email, name: name, })}
      if (this.state.language === 'French'){this.setState({ dialogOpen: true, dialogMessage: "Confirmer l'envoi de l'email?", dialogTitle: 'Confirm: 3002', email: email, name: name, })}
      if (this.state.language === 'Spanish'){this.setState({ dialogOpen: true, dialogMessage: '¿Confirmar el envío del correo electrónico?', dialogTitle: 'Confirm: 3002', email: email, name: name, })}
      if (this.state.language === 'Portuguese'){this.setState({ dialogOpen: true, dialogMessage: 'Confirmar o envio do email?', dialogTitle: 'Confirm: 3002', email: email, name: name, })}
      if (this.state.language === 'Swedish'){this.setState({ dialogOpen: true, dialogMessage: 'Bekräfta att e-postmeddelandet skickas?', dialogTitle: 'Confirm: 3002', email: email, name: name, })}
      if (this.state.language === 'Netherlands'){this.setState({ dialogOpen: true, dialogMessage: 'Bevestig het verzenden van de e-mail?', dialogTitle: 'Confirm: 3002', email: email, name: name, })}
      if (this.state.language === 'Russian'){this.setState({ dialogOpen: true, dialogMessage: 'Подтвердить отправку электронного письма?', dialogTitle: 'Confirm: 3002', email: email, name: name, })}
      if (this.state.language === 'Japanese'){this.setState({ dialogOpen: true, dialogMessage: 'メールの送信を確認しますか？', dialogTitle: 'Confirm: 3002', email: email, name: name, })}
      if (this.state.language === 'Chinese'){this.setState({ dialogOpen: true, dialogMessage: '确认发送电子邮件？', dialogTitle: 'Confirm: 3002', email: email, name: name, })}
      if (this.state.language === 'German'){this.setState({ dialogOpen: true, dialogMessage: 'Bestätigen Sie das Versenden der E-Mail?', dialogTitle: 'Confirm: 3002', email: email, name: name, })}
      if (this.state.language === 'Italian'){this.setState({ dialogOpen: true, dialogMessage: "Conferma l'invio dell'email?", dialogTitle: 'Confirm: 3002', email: email, name: name, })}
    }

    responseConfirm(){
      if (this.state.language === 'English'){this.setState({ dialogOpenAccept: true, dialogMessage: 'Email was sent successfully', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'French'){this.setState({ dialogOpenAccept: true, dialogMessage: 'Email a été envoyé avec succès', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Spanish'){this.setState({ dialogOpenAccept: true, dialogMessage: 'Email fue enviado satisfactoriamente', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Portuguese'){this.setState({ dialogOpenAccept: true, dialogMessage: 'O email foi enviado com sucesso', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Swedish'){this.setState({ dialogOpenAccept: true, dialogMessage: 'E-postmeddelandet skickades', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Netherlands'){this.setState({ dialogOpenAccept: true, dialogMessage: 'E-mail is succesvol verzonden', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Russian'){this.setState({ dialogOpenAccept: true, dialogMessage: 'Письмо было успешно отправлено', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Japanese'){this.setState({ dialogOpenAccept: true, dialogMessage: 'メールは正常に送信されました', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Chinese'){this.setState({ dialogOpenAccept: true, dialogMessage: '电子邮件已成功发送', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'German'){this.setState({ dialogOpenAccept: true, dialogMessage: 'Die Email wurde erfolgreich versendet', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Italian'){this.setState({ dialogOpenAccept: true, dialogMessage: "L'email è stata inviata con successo", dialogTitle: 'Confirm: 3001', })}
    }

    deleteConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenCancel: true, dialogMessage: 'It cannot be deleted, it is connected to a device', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'French'){this.setState({dialogOpenCancel: true, dialogMessage: 'Il ne peut pas être supprimé, il est connecté à un appareil', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenCancel: true, dialogMessage: 'No se puede eliminar, está conectado a un dispositivo', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenCancel: true, dialogMessage: 'Não pode ser excluído, está conectado a um dispositivo', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenCancel: true, dialogMessage: 'Det kan inte raderas, det är anslutet till en enhet', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenCancel: true, dialogMessage: 'Het kan niet worden verwijderd, het is verbonden met een apparaat', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Russian'){this.setState({dialogOpenCancel: true, dialogMessage: 'Он не может быть удален, он подключен к устройству', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenCancel: true, dialogMessage: '削除できません。デバイスに接続されています', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenCancel: true, dialogMessage: '它无法删除，它连接到设备', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'German'){this.setState({dialogOpenCancel: true, dialogMessage: 'Es kann nicht gelöscht werden, es ist mit einem Gerät verbunden', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Italian'){this.setState({dialogOpenCancel: true, dialogMessage: 'Non può essere eliminato, è collegato a un dispositivo', dialogTitle: 'Error: 1001', })}
    }

    profileConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenCancel: true, dialogMessage: 'You must enter the email address in your user profile', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'French'){this.setState({dialogOpenCancel: true, dialogMessage: "Vous devez entrer l'adresse email dans votre profil d'utilisateur", dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenCancel: true, dialogMessage: 'Debe ingresar la dirección de correo electrónico en su perfil de usuario', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenCancel: true, dialogMessage: 'Você deve inserir o endereço de email no seu perfil de usuário', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenCancel: true, dialogMessage: 'Du måste ange e-postadressen i din användarprofil', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenCancel: true, dialogMessage: 'U moet het e-mailadres in uw gebruikersprofiel invoeren', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Russian'){this.setState({dialogOpenCancel: true, dialogMessage: 'Вы должны ввести адрес электронной почты в своем профиле пользователя', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenCancel: true, dialogMessage: 'ユーザープロフィールにメールアドレスを入力する必要があります', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenCancel: true, dialogMessage: '您必须在用户个人资料中输入电子邮件地址', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'German'){this.setState({dialogOpenCancel: true, dialogMessage: 'Sie müssen die E-Mail-Adresse in Ihr Benutzerprofil eingeben', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Italian'){this.setState({dialogOpenCancel: true, dialogMessage: "Devi inserire l'indirizzo e-mail nel tuo profilo utente", dialogTitle: 'Error: 1002', })}
    }

    clickRemoveInvitation = (e,optx, email) => {
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
        const invitation = this.state.jsonBlockstack
        invitation.splice(optx,1);
        this.setState({jsonBlockstack: invitation})
        const jsonBlockstack1 = JSON.stringify(invitation)

        putFileDB(this.state.username, `my_invitations.json`, 'Crosscheck_MyInvitations', jsonBlockstack1, JSON.stringify(options))
          .then(() => {
             localStorage.setItem('jsonBlockstack30I',jsonBlockstack1)
          })
    }

    clickEditInvitation = (e,optx) => {
    }

    goUserConfig(usernameX) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({jsonBlockstackZ: []})
      return new Promise ((resolve1, reject1) =>{
          getFileDB(usernameX,`myUserConfig.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.setState({jsonBlockstackZ: jsonBlockstack6})
               } else {}
               resolve1()
             })
            .catch(error => {
              reject1()
            });
      })
    }

    goInvitation(usernameX) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({jsonBlockstack: []})
      localStorage.removeItem('jsonBlockstack30I')

      return new Promise ((resolve1, reject1) =>{
          getFileDB(usernameX,`my_invitations.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 localStorage.setItem('jsonBlockstack30I',jsonBlockstack5)
                 this.setState({jsonBlockstack: jsonBlockstack6})
               } else {}
               resolve1()
             })
            .catch(error => {
              reject1()
            });
      })
    }

    crearInvitation = (infoInvitation) => {
      this.setState({jsonBlockstack: JSON.parse(infoInvitation)})
      localStorage.setItem('jsonBlockstack30I',infoInvitation)
    }


    forwardInvitation(){
      const {userSession} = this.state
      const {emailNotifications} = this.state.jsonBlockstackZ
      const senderEmail = emailNotifications
      if (senderEmail === null || senderEmail === undefined){
        this.profileConfirm()
      }else{
        if (senderEmail.indexOf('@') === -1){
           this.profileConfirm()
        }else{
          const nameTo = this.state.name
          const {email} = this.state
          const subject ='Invite to join me doing cross border business using CrossCheck'
          const reference = 'REF'+Math.floor(Math.random() * 100000000) + 1;
          const userName = this.state.username
          const {name} =  userSession.loadUserData().profile;

          emailjs.send("service_9dv38as", "template_do160w7", {nameTo, senderEmail, email, subject, reference, userName, senderName: name}, "user_djYXBdBTeG1Km5qtyqkDl")
            .then(response => {
               console.log('SUCCESS!', response.status, response.text)
               this.responseConfirm()
            }, error => {
               console.log('FAILED...', error)
            });
        }
      }
    }

/*
    forwardInvitation(){
      const {emailNotifications} = this.state.jsonBlockstackZ
      if (emailNotifications.indexOf('@') === -1 || emailNotifications === '' || emailNotifications === undefined){
         this.profileConfirm()
      }
      const {name} = this.state
      const {email} = this.state

      fetch("https://xck.app:5443/sendCrosscheckInvitation",
            {
              method: "POST",
              headers:{
                'Content-Type': 'application/json',
                "token": `${token}`
              },
              body: JSON.stringify({
                name: name,
                email: email,
                senderEmail: emailNotifications
              })
            }
      ).then((response)=>{
        if (response.status === 200) {
          console.log('email sended');
          this.responseConfirm()
        }
        else{
          console.log('error sending the email')
          console.log(response.status);
        }
      }).catch(()=>{
        console.log('error sending the email')
      })
    }
*/

    render() {
        const {jsonBlockstack} = this.state
        const {jsonBlockstackZ} = this.state

        let titleX = ''
        let subtitleX = ''
        if (this.state.language === 'English'){
          titleX = "My Invitations"
          subtitleX = "Maintain"
        }
        if (this.state.language === 'French'){
          titleX = "Mes invitations"
          subtitleX = "Maintenir"
        }
        if (this.state.language === 'Spanish'){
          titleX = "Mis Invitaciones"
          subtitleX = "Mantener"
        }
        if (this.state.language === 'Portuguese'){
          titleX = "Meus Convites"
          subtitleX = "Manter"
        }
        if (this.state.language === 'Swedish'){
          titleX = "Mina inbjudningar"
          subtitleX = "Upprätthålla"
        }
        if (this.state.language === 'Netherlands'){
          titleX = "Mijn uitnodigingen"
          subtitleX = "Onderhouden"
        }
        if (this.state.language === 'Russian'){
          titleX = "Мои приглашения"
          subtitleX = "поддерживать"
        }
        if (this.state.language === 'Japanese'){
          titleX = "私の招待"
          subtitleX = "維持する"
        }
        if (this.state.language === 'Chinese'){
          titleX = "我的邀请"
          subtitleX = "保持"
        }
        if (this.state.language === 'German'){
          titleX = "Meine Einladungen"
          subtitleX = "Pflegen"
        }
        if (this.state.language === 'Italian'){
          titleX = "I miei inviti"
          subtitleX = "Mantenere"
        }

        let colorThemeX = 'Blue'
        let colorThemeX2 = 'info'
        if (localStorage["colorTheme"]) {colorThemeX = localStorage.getItem('colorTheme')}
        if (colorThemeX === 'Blue') {colorThemeX2 = 'info'}
        if (colorThemeX === 'Green') {colorThemeX2 = 'success'}
        if (colorThemeX === 'Grey') {colorThemeX2 = 'secondary'}
        if (colorThemeX === 'Dark') {colorThemeX2 = 'dark'}

        return (
            <>
            { !this.state.colorDark ?
              <Container fluid className="px-0">
                  <Card theme={colorThemeX2} style={{ color: "white" }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            &nbsp;&nbsp;<div className="fa fa-info"></div>
                          </td>
                          <td>
                             &nbsp;&nbsp;<FormattedMessage id="invitation.title" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
              </Container>
            : null
            }
            <Container fluid className="main-content-container px-4" style={ this.state.colorDark ? { backgroundColor:'#060a0f'} : {}}>
              <Row noGutters className="page-header py-4">
                <TituloPagina
                  sm="4"
                  title={titleX}
                  subtitle={subtitleX}
                  className="text-sm-left"
                />
              </Row>
              <Confirmacion
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpen}
                  dialogTitle={this.state.dialogTitle}
              />
              <ConfirmacionCancel
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenCancel}
                  dialogTitle={this.state.dialogTitle}
              />
              <ConfirmacionAccept
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenAccept}
                  dialogTitle={this.state.dialogTitle}
              />
                  <Row>
                    <Col>
                      <Form style={{fontSize:13}}>
                        <Row form>
                          <Col md="12">
                            <label>&nbsp;</label>
                            <NewInvitation crearInvitation={this.crearInvitation} jsonBlockstackZ={jsonBlockstackZ} token={token} colorDark={this.state.colorDark} username={this.state.username}/>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="1"></Col>
                          <Col md="10">
                            <label>&nbsp;</label>
                                   <Table size="sm" className="text-center" responsive hover striped>
                                      <thead>
                                        <tr>
                                          <th style={{ width: "10px" }}></th>
                                          <th style={{ width: "30px", fontSize:16 }}><FormattedMessage id="invitation.email" /></th>
                                          <th style={{ width: "160px", fontSize:16 }}><FormattedMessage id="invitation.name" /></th>
                                          <th style={{ width: "160px", fontSize:16 }}><FormattedMessage id="usergroup.userid" /></th>
                                          <th style={{ width: "20px", fontSize:16 }}><FormattedMessage id="invitation.forward" /></th>
                                          <th style={{ width: "20px", fontSize:16 }}><FormattedMessage id="invitation.delete" /></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {jsonBlockstack.map((todo, i) => {
                                          let setColorInfo = false
                                          if (this.state.setItemInfo === i) {
                                            setColorInfo = true
                                          }
                                          return (
                                              <tr key={i} onMouseEnter={()=>this.onMouseEnterHandler(i)} onMouseLeave={()=>this.onMouseLeaveHandler(i)} style={ this.state.colorDark ? { color:'white'} : {color:'black'}}>
                                                  { setColorInfo ?
                                                    <td><img src="images/circleGreen.png" weight="10" height="10" alt=""/></td>
                                                  :
                                                    <td></td>
                                                  }
                                                  <td>{todo.email}</td>
                                                  <td>{todo.name}</td>
                                                  <td>{todo.userid}</td>
                                                  <td>
                                                    <strong style={{cursor: 'pointer'}} onClick={e=>this.sendConfirm(e, i, todo.email, todo.name)}>
                                                      { setColorInfo ?
                                                        <img src="images/sendContract.png" title="click here to resend invitation"  weight="60" height="60" alt=""/>
                                                      :
                                                        <img src="images/sendContractGreen.png" title="click here to resend invitation"  weight="60" height="60" alt=""/>
                                                      }
                                                    </strong>
                                                  </td>
                                                  <td><Button pill theme="danger" onClick={e=>this.clickRemoveInvitation(e, i, todo.email)}><FontAwesomeIcon icon={faTimes} /></Button></td>
                                              </tr>
                                           )})}
                                      </tbody>
                                    </Table>
                          </Col>
                          <Col md="1"></Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
            </Container>
            </>
        );
    }
}

export default withRouter(ContenedorInvitation);
