import axios from 'axios';

export async function profileUser(userid) {
    let respuesta = ''
    let configProfileZonefile = ''

    var configProfile = {
        method: 'get',
        url: 'https://api.hiro.so/v1/names/' + userid,
        headers: {'x-hiro-api-key': 'xcheck_WTpZ9LK4BzykUnbTfpPrEMdPiLjMsqPM'}
    };

    await axios(configProfile)
      .then(function(responseProfile) {
          const {zonefile} = responseProfile.data
          const zonefile1 = zonefile.indexOf('"');
          const zonefile2 = zonefile.lastIndexOf('"');
          const zonefile3 = zonefile.substring(zonefile1+1,zonefile2)
          const zonefile4 = zonefile3.replace(/\\/g,'')
          configProfileZonefile = {
              method: 'get',
              url: zonefile4
          };
      })
      .catch(error => {
      });

    await axios(configProfileZonefile)
        .then(result => {
            respuesta = result.data[0].decodedToken.payload.subject.publicKey
        })
        .catch(error => {
            respuesta = 'password_crosscheck'
        });

    return respuesta

}
