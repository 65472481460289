import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card
} from "shards-react";

import TituloPagina from "../components/common/TituloPagina";

import Contract from "../components/contracts/Contract";

import { withRouter } from 'next/router';

// blockstack
import { UserSession } from 'blockstack';

//translate
import { FormattedMessage } from 'react-intl';

import {username_new} from '../actions/userActions'

//Axios
import axios from 'axios';

class ContenedorContract extends Component {
    constructor(props) {
      super(props);

      this.createListContract = this.createListContract.bind(this);

      this.state = {
        userSession: new UserSession(),
        username: '',
        user: {},
        switchLogisticContract: false,
        switchDeliveryContract: false,
        infoListContract: '',
        status: false,
        language: 'English',
        zonefile4: '',
        storageSX: '',
      }
    }
    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => {
          this.setState({username: val})
          Promise.all([this.getAxios(val)]).then((resolve) =>{},(reject) =>{})
        })
      }else{
        this.setState({ username })
        Promise.all([this.getAxios(username)]).then((resolve) =>{},(reject) =>{})
      }
    }

    getAxios = (userX) => {
      return new Promise ((resolve, reject) =>{
        if (userX === undefined || userX === null || userX === ''){reject()}
        var nameLookupURL = "https://api.hiro.so/v1/names/" + userX;
        var config = {
          method: 'get',
          url: nameLookupURL,
          headers: {'x-hiro-api-key': 'xcheck_WTpZ9LK4BzykUnbTfpPrEMdPiLjMsqPM'}
        };
        axios(config)
          .then(result => {
            const {zonefile} = result.data
            const zonefile1 = zonefile.split('"')
            const zonefile2 = zonefile1[1]
            const zonefile3 = zonefile2.replace(/\\/g,'')
            const zonefile4 = zonefile3.replace('.json/','.json')
            axios.get(zonefile4)
               .then(result => {
                  const jsonBlockstack1 = JSON.stringify(result.data[0].decodedToken.payload.claim.appsMeta)
                  let jsonBlockstack2 = jsonBlockstack1
                  let jsonBlockstack4 = {}
                  const var_a_remp = `"${window.location.origin}":`
                  if (window.location.origin === 'http://localhost:3000'){jsonBlockstack2 = jsonBlockstack1.replace(var_a_remp,`"localhost":`);}
                  if (window.location.origin === 'https://xck.app'){jsonBlockstack2 = jsonBlockstack1.replace(var_a_remp,`"xckapp":`);}
                  const jsonBlockstack3 = JSON.parse(jsonBlockstack2)
                  if (window.location.origin === 'http://localhost:3000'){jsonBlockstack4 = jsonBlockstack3.localhost}
                  if (window.location.origin === 'https://xck.app'){jsonBlockstack4 = jsonBlockstack3.xckapp}
                  const {storage} = jsonBlockstack4
                  this.setState({zonefile4,storageSX:storage})
               })
               .catch(error => {
                 reject()
               });
          })
          .catch(error => {
            reject()
          });
      });
    }


    createListContract = (existListContract,jsonBlockstackZ,Id,TypeContract,ConfigurationContractNumber,IdContainer,IdBillOfLading,IdTypeBillOfLading,DateLogisticContract,optx) => {
      const { userSession } = this.state
      let jsonBlockstack2 = ''
      let infoListContract = ''
      let active = true
      if (optx==='delete'){
        active = false;
      }
      if (localStorage["jsonBlockstack40"]) {
        jsonBlockstack2 = localStorage.getItem('jsonBlockstack40')
        const jsonBlockstack3 = jsonBlockstack2.substring(1,jsonBlockstack2.length - 1)
        if (jsonBlockstack3 === '') {
           infoListContract=`[{"id":"${Id}","typeContract":"${TypeContract}","configurationContractNumber":"${ConfigurationContractNumber}","idContainer":"${IdContainer}","idBillOfLading":"${IdBillOfLading}","idTypeBillOfLading":"${IdTypeBillOfLading}","dateLogisticContract":"${DateLogisticContract}","active":"${active}"}]`
        }else{
           infoListContract =`[${jsonBlockstack3},{"id":"${Id}","typeContract":"${TypeContract}","configurationContractNumber":"${ConfigurationContractNumber}","idContainer":"${IdContainer}","idBillOfLading":"${IdBillOfLading}","idTypeBillOfLading":"${IdTypeBillOfLading}","dateLogisticContract":"${DateLogisticContract}","active":"${active}"}]`
        }
      }else{
        infoListContract =`[{"id":"${Id}","typeContract":"${TypeContract}","configurationContractNumber":"${ConfigurationContractNumber}","idContainer":"${IdContainer}","idBillOfLading":"${IdBillOfLading}","idTypeBillOfLading":"${IdTypeBillOfLading}","dateLogisticContract":"${DateLogisticContract}","active":"${active}"}]`
      }
      this.setState({ infoListContract })
      const options = { encrypt: false }

      userSession.putFile(`contractlist.json`, JSON.stringify(infoListContract), options)
        .then(() => {
           this.setState({
              status: true
           })
           //console.log(`Grabación Exitosa Contract List (contractlist.json`)
        })

      localStorage.removeItem('jsonBlockstack40')

    }

    render() {
      let titleX = ''
      let subtitleX = ''
      if (this.state.language === 'English'){
        titleX = "Agreement Document"
        subtitleX = "Management"
      }
      if (this.state.language === 'French'){
        titleX = "Document d'accord"
        subtitleX = "Maintenir"
      }
      if (this.state.language === 'Spanish'){
        titleX = "Documento de Acuerdo"
        subtitleX = "Gestión"
      }
      if (this.state.language === 'Portuguese'){
        titleX = "Documento de Acuerdo"
        subtitleX = "Manter"
      }
      if (this.state.language === 'Swedish'){
        titleX = "Avtalsdokument"
        subtitleX = "Upprätthålla"
      }
      if (this.state.language === 'Netherlands'){
        titleX = "Overeenkomst document"
        subtitleX = "Onderhouden"
      }
      if (this.state.language === 'Russian'){
        titleX = "Документ соглашения"
        subtitleX = "поддерживать"
      }
      if (this.state.language === 'Japanese'){
        titleX = "契約書"
        subtitleX = "維持する"
      }
      if (this.state.language === 'Chinese'){
        titleX = "协议文件"
        subtitleX = "维持"
      }
      if (this.state.language === 'German'){
        titleX = "Einverständniserklärung"
        subtitleX = "Pflegen"
      }
      if (this.state.language === 'Italian'){
        titleX = "Documento di accordo"
        subtitleX = "Mantenere"
      }

      let colorThemeX = 'Blue'
      let colorThemeX2 = 'info'
      let colorDark = false
      if (localStorage["colorTheme"]) {colorThemeX = localStorage.getItem('colorTheme')}
      if (colorThemeX === 'Blue') {colorThemeX2 = 'info'}
      if (colorThemeX === 'Green') {colorThemeX2 = 'success'}
      if (colorThemeX === 'Grey') {colorThemeX2 = 'secondary'}
      if (colorThemeX === 'Dark') {colorThemeX2 = 'dark'; colorDark = true}

      return (
          <>
            { !colorDark ?
              <Container fluid className="px-0">
                <Card theme={colorThemeX2} style={{ color: "white" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          &nbsp;&nbsp;<div className="fa fa-info"></div>
                        </td>
                        <td>
                          &nbsp;&nbsp;<FormattedMessage id="containercontract.title" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
              </Container>
            : null
            }
            <Container fluid className="main-content-container px-4" style={ colorDark ? { backgroundColor:'#060a0f'} : {}}>
              <Row noGutters className="page-header py-2">
                <TituloPagina
                  sm="4"
                  title={titleX}
                  subtitle={subtitleX}
                  className="text-sm-left"
                />
              </Row>

              <Row>
                <Col lg="12" className="mb-4">
                  {/* Smart Contract */}
                  <Card style={ colorDark ? { backgroundColor:'#0b151d'} : {}} small>
                    <Contract createListContract={this.createListContract} colorDark={colorDark} zonefile4={this.state.zonefile4} storageSX={this.state.storage}/>
                  </Card>
                </Col>

              </Row>
            </Container>
          </>
        )
      }
}

export default withRouter(ContenedorContract);
