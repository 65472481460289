import React from "react";
//import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import ReactImageFallback from "react-image-fallback";
import { FormattedMessage } from 'react-intl';

//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

import {username_new} from '../../../actions/userActions'
import {getFileDB} from '../../../actions/mongoDBActions'
import {profileUser} from '../../../actions/gaiaActions'

import forge from 'node-forge'

class Language extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userSession: new UserSession(),
      username: '',
      user: {},
      visible: false,
      languaje: 'English',
      imagen: '',
      languageDisplay: 'ISO Code',
    };

    this.toggleFormatActions = this.toggleFormatActions.bind(this);
    this.english = this.english.bind(this);
    this.french = this.french.bind(this);
    this.spanish = this.spanish.bind(this);
    this.swedish = this.swedish.bind(this);
    this.netherlands = this.netherlands.bind(this);
    this.russian = this.russian.bind(this);
    this.chinese = this.chinese.bind(this);
    this.japanese = this.japanese.bind(this);
    this.german = this.german.bind(this);
    this.italian = this.italian.bind(this);
  }

  toggleFormatActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  componentWillMount() {
    const { userSession } = this.state
    let stacksNetX = ''
    let serverStacksX = ''
    if (userSession.isUserSignedIn()) {
      const user = userSession.loadUserData()
      this.setState({ user })
      if (!localStorage["serverStacks"]) {
        serverStacksX = 'MainNet'
      }else{
        serverStacksX = localStorage.getItem('serverStacks')
      }
      if (serverStacksX === 'MainNet'){
         stacksNetX =  user.profile.stxAddress.mainnet
      }
      if (serverStacksX === 'TestNet'){
         stacksNetX =  user.profile.stxAddress.testnet
      }
    }

    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English' })
    }else{
      this.setState({ language: languageX })
    }

    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }

    let settingLanguageDisplay = 'ISO Code'

    const {username} = userSession.loadUserData();
    if (username === '' || username === undefined || username === null){
      username_new(stacksNetX).then(val => this.setState({username: val},()=>{
        Promise.all([this.getSettings3(val)])
           .then(
              (resolve) => {},
              (reject) => {}
           )
      })) ;
    }else{
      this.setState({ username },()=>{
        Promise.all([this.getSettings3(username)])
           .then(
              (resolve) => {},
              (reject) => {}
           )

      })
    }
  }

  getSettings3 = (username) => {
    const options = { decrypt: false }
    let settingLanguageDisplay = 'ISO Code'
    getFileDB(username, `settings3.json`)
         .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              let jsonBlockstack8 = '[]'
              let jsonBlockstack10 = '[]'
              if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }

                  const jsonBlockstack6 = jsonBlockstack2.options
                  const jsonBlockstack7 = jsonBlockstack6.replace(/\\/g,"");
                  if (jsonBlockstack7.substring(0,1) === '"') {
                     jsonBlockstack8 = jsonBlockstack7.substring(1,jsonBlockstack7.length - 1)
                  } else {
                     jsonBlockstack8 = jsonBlockstack7
                  }
                  let jsonBlockstack9 = JSON.parse(jsonBlockstack8)

                  if (jsonBlockstack9.encrypt === true) {
                    Promise.all([profileUser(username)])
                     .then(
                       (publicKey) =>{
                         const vi = jsonBlockstack5
                         const data = jsonBlockstack5,
                               iv = '1111111111111111',
                               password = publicKey
                         const key = forge.md.sha256.create().update(password).digest().getBytes();
                         const encryptedBytes = forge.util.decode64(data);
                         const decipher = forge.cipher.createDecipher('AES-CTR', key)
                         decipher.start({ iv });
                         decipher.update(forge.util.createBuffer(encryptedBytes));
                         decipher.finish();
                         const decryptedBytes = decipher.output.getBytes();
                         const decryptedString = forge.util.encodeUtf8(decryptedBytes);
                         let jsonBlockstack4 = decryptedString.replace(/\\/g,"");
                         if (jsonBlockstack4.substring(0,1) === '"') {
                            jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                         } else {
                            jsonBlockstack5 = jsonBlockstack4
                         }
                         jsonBlockstack10 = JSON.parse(jsonBlockstack5)
                         jsonBlockstack10.map((todo,i)=>{
                           if(todo.code==='LanguageDisplay'){
                             settingLanguageDisplay = todo.description
                           }
                         })
                         this.setState({languageDisplay: settingLanguageDisplay})
                       },
                       (reject) =>{}
                     )
                  }else{
                    jsonBlockstack10 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack10.map((todo,i)=>{
                      if(todo.code==='LanguageDisplay'){
                        settingLanguageDisplay = todo.description
                      }
                    })
                    this.setState({languageDisplay: settingLanguageDisplay})
                  }
             }
           }
         })
          .catch(error => {
            //console.log(error);
         });
  }

  english(){
    localStorage.setItem('language','English')
    //window.history.pushState({}, document.title, `/${'en'}#/contract`);
    window.location = window.location.origin + `?lang=${'en'}#/start`
  }
  french(){
    localStorage.setItem('language','French')
    //window.history.pushState({}, document.title, `/${'fr'}#/contract`);
    window.location = window.location.origin + `?lang=${'fr'}#/start`
  }
  spanish(){
    localStorage.setItem('language','Spanish')
    //window.history.pushState({}, document.title, `/${'es'}#/contract`);
    window.location = window.location.origin + `?lang=${'es'}#/start`
  }
  portuguese(){
    localStorage.setItem('language','Portuguese')
    //window.history.pushState({}, document.title, `/${'pt'}#/contract`);
    window.location = window.location.origin + `?lang=${'pt'}#/start`
  }
  swedish(){
    localStorage.setItem('language','Swedish')
    //window.history.pushState({}, document.title, `/${'sv'}#/contract`);
    window.location = window.location.origin + `?lang=${'sv'}#/start`
  }
  netherlands(){
    localStorage.setItem('language','Netherlands')
    //window.history.pushState({}, document.title, `/${'nl'}#/contract`);
    window.location = window.location.origin + `?lang=${'nl'}#/start`
  }
  russian(){
    localStorage.setItem('language','Russian')
    //window.history.pushState({}, document.title, `/${'nl'}#/contract`);
    window.location = window.location.origin + `?lang=${'ru'}#/start`
  }
  japanese(){
    localStorage.setItem('language','Japanese')
    //window.history.pushState({}, document.title, `/${'nl'}#/contract`);
    window.location = window.location.origin + `?lang=${'jp'}#/start`
  }
  chinese(){
    localStorage.setItem('language','Chinese')
    //window.history.pushState({}, document.title, `/${'nl'}#/contract`);
    window.location = window.location.origin + `?lang=${'ch'}#/start`
  }
  german(){
    localStorage.setItem('language','German')
    //window.history.pushState({}, document.title, `/${'nl'}#/contract`);
    window.location = window.location.origin + `?lang=${'de'}#/start`
  }
  italian(){
    localStorage.setItem('language','Italian')
    //window.history.pushState({}, document.title, `/${'nl'}#/contract`);
    window.location = window.location.origin + `?lang=${'it'}#/start`
  }

  render() {
    let displayFlag = false
    if (this.state.languageDisplay === 'Flag'){
       displayFlag = true
    }
    let imagenX = 'images/united-kingdom-flag-round-medium.png'
    //let isoCodeX = 'en'
    let languageCodeX = 'English Language'
    if (this.state.language === 'English'){
      imagenX = 'images/united-kingdom-flag-round-medium.png'
      //isoCodeX = 'en'
      languageCodeX = 'EN - English Language'
    }
    if (this.state.language === 'French'){
      imagenX = 'images/france-flag-round-medium.png'
      //isoCodeX = 'fr'
      languageCodeX = 'FR - Langue Française'
    }
    if (this.state.language === 'Spanish'){
      imagenX = 'images/spain-flag-round-medium.png'
      //isoCodeX = 'es'
      languageCodeX = 'ES - Idioma Español'
    }
    if (this.state.language === 'Portuguese'){
      imagenX = 'images/portugal-flag-round-medium.png'
      //isoCodeX = 'pt'
      languageCodeX = 'PT - Língua Portuguesa'
    }
    if (this.state.language === 'Swedish'){
      imagenX = 'images/sweden-flag-round-medium.png'
      //isoCodeX = 'sv'
      languageCodeX = 'SV - Svenska'
    }
    if (this.state.language === 'Netherlands'){
      imagenX = 'images/netherlands-flag-round-medium.png'
      //isoCodeX = 'nl'
      languageCodeX = 'NL - Nederlandse Taal'
    }
    if (this.state.language === 'Russian'){
      imagenX = 'images/russia-flag-round-medium.png'
      //isoCodeX = 'ru'
      languageCodeX = 'RU - Русский язык'
    }
    if (this.state.language === 'Japanese'){
      imagenX = 'images/japan-flag-round-medium.png'
      //isoCodeX = 'jp'
      languageCodeX = 'JP - 日本語'
    }
    if (this.state.language === 'Chinese'){
      imagenX = 'images/china-flag-round-medium.png'
      //isoCodeX = 'cn'
      languageCodeX = 'CN - 中文'
    }
    if (this.state.language === 'German'){
      imagenX = 'images/germany-flag-round-medium.png'
      //isoCodeX = 'de'
      languageCodeX = 'DE - Deutsche Sprache'
    }
    if (this.state.language === 'Italian'){
      imagenX = 'images/italy-flag-round-medium.png'
      //isoCodeX = 'it'
      languageCodeX = 'IT - Lingua Italiana'
    }

    return (
      <NavItem
          tag={Dropdown}
          caret
          dropup
          //toggle={this.toggleUserActions}
          >
          <DropdownToggle
              caret
              tag={NavLink}
              className="text-nowrap px-1"
              onClick={this.toggleFormatActions}
              style={{cursor: 'pointer'}}
              >
              {displayFlag ?
                <ReactImageFallback
                     src={imagenX}
                     fallbackImage={imagenX}
                     initialImage={imagenX}
                     alt={" "}
                     className="ml-5 mr-2" style={{width:23, height:23}}
                />
              :
                <React.Fragment>
                     &nbsp;&nbsp;{languageCodeX}&nbsp;&nbsp;
                </React.Fragment>
              }
          </DropdownToggle>

          <Collapse
              tag={DropdownMenu}
              right
              small
              open={this.state.visible}
              style={ this.props.colorDark ? { backgroundColor:'#d3e1ee'} : {}}
              >

              {displayFlag ?
                <React.Fragment>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.english}>
                          <i className="flag-icon flag-icon-gb"></i> <FormattedMessage id="language.english" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.french}>
                          <i className="flag-icon flag-icon-fr"></i> <FormattedMessage id="language.french" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.spanish}>
                          <i className="flag-icon flag-icon-es"></i> <FormattedMessage id="language.spanish" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.portuguese}>
                          <i className="flag-icon flag-icon-pt"></i> <FormattedMessage id="language.portuguese" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.german}>
                          <i className="flag-icon flag-icon-de"></i> <FormattedMessage id="language.german" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.italian}>
                          <i className="flag-icon flag-icon-it"></i> <FormattedMessage id="language.italian" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.swedish}>
                         <i className="flag-icon flag-icon-se"></i> <FormattedMessage id="language.swedish" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.netherlands}>
                          <i className="flag-icon flag-icon-nl"></i> <FormattedMessage id="language.netherlands" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.russian}>
                          <i className="flag-icon flag-icon-ru"></i> <FormattedMessage id="language.russian" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.japanese}>
                          <i className="flag-icon flag-icon-jp"></i> <FormattedMessage id="language.japanese" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.chinese}>
                          <i className="flag-icon flag-icon-cn"></i> <FormattedMessage id="language.chinese" />
                      </div>
                  </DropdownItem>
                </React.Fragment>
              :
                <React.Fragment>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.english}>
                          EN -  <FormattedMessage id="language.english" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.french}>
                          FR - <FormattedMessage id="language.french" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.spanish}>
                          ES - <FormattedMessage id="language.spanish" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.portuguese}>
                          PT - <FormattedMessage id="language.portuguese" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.german}>
                          DE - <FormattedMessage id="language.german" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.italian}>
                          IT - <FormattedMessage id="language.italian" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.swedish}>
                         SV - <FormattedMessage id="language.swedish" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.netherlands}>
                          NL - <FormattedMessage id="language.netherlands" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.russian}>
                          RU - <FormattedMessage id="language.russian" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.japanese}>
                          JP - <FormattedMessage id="language.japanese" />
                      </div>
                  </DropdownItem>
                  <DropdownItem tag="a" className="text-danger" style={{cursor: 'pointer'}}>
                      <div onClick={this.chinese}>
                          CN - <FormattedMessage id="language.chinese" />
                      </div>
                  </DropdownItem>
               </React.Fragment>
            }
          </Collapse>
      </NavItem>
    );
  }
}


export default withRouter(Language)
