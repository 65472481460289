//React
import React, { Component } from 'react';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Container, Button, Card } from "shards-react";
import { Table } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";
import { Divider, Typography, TextField, Grid, Dialog,
        DialogContent, DialogActions, DialogTitle, DialogContentText,
        Button as ButtonMaterialUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';

//UUID
import uuid from 'uuid';

//Axios
import axios from 'axios'

//loader
import ContractLoader from "../contracts/ContractLoader";

import TituloPagina2 from "../common/TituloPagina2";

// blockstack
//import { UserSession,  } from 'blockstack';
import { UserSession, AppConfig } from '@stacks/auth';
import { makeRandomPrivKey } from '@stacks/transactions';
import { Storage } from '@stacks/storage'

//translate
import { FormattedMessage } from 'react-intl';

//Number Format
import NumberFormat from 'react-number-format';

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'


//Configuración
import { SMART_CONTRACT_ADDRESS_MAINNET_STX,
         SMART_CONTRACT_NAME_MAINNET_STX,
         SMART_CONTRACT_ADDRESS_TESTNET_STX,
         SMART_CONTRACT_NAME_TESTNET_STX,
         MAINNET_STACKS_API_URL,
         TESTNET_STACKS_API_URL } from '../../config.js'

//base64
import { Base64 } from 'js-base64';

function ConfirmacionSign(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                     <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                     <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionUnSign(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class SignatureDeliverable extends Component {

    constructor(props) {
        super(props);

        this.signContract = this.signContract.bind(this);
        this.unsignContract = this.unsignContract.bind(this);
        this.signUserGroup = this.signUserGroup.bind(this);
        this.signUserGroupWrite = this.signUserGroupWrite.bind(this);
        this.signConfirm = this.signConfirm.bind(this);
        this.unsignConfirm = this.unsignConfirm.bind(this);
        this.exitProgram = this.exitProgram.bind(this);

        this.state = {
          userSession: new UserSession(new AppConfig()),
          username: '',
          user: {},
          person: '',
          exist: true,
          pubKey: '',
          publicKey: '',
          opcionContractIoTDevice: '',
          displayContractIoTDevice: false,
          jsonBlockstack: [],
          jsonBlockstackY: [],
          jsonBlockstackZ: [],
          jsonBlockstack2: [],
          jsonBlockstackZ50c: [],
          typeContractIoTDevice: '',
          idx: '',
          url: 'https://gaia.blockstack.org/hub/',
          url2: 'https://core.blockstack.org/v1/users/',
          url3: 'https://core.blockstack.org/v1/names/',
          identityAddress: undefined,
          decentralizedID: undefined,
          language: 'English',
          nameBlockstack: '',
          displayLoader: false,
          goConfirmSign: false,
          dialogOpen: false,
          dialogOpenPaymentForm: false,
          dialogOpenSign: false,
          dialogOpenUnsign: false,
          dialogOpenConfirmSign: false,
          dialogMessage: '',
          dialogTitle: '',
          balanceCta: 0,
          resultTransfer: '',
          stacksAddress: '',
          privateKey: '',
          contractAddressX: '',
          addressKeyX: '',
          networkUrlX: '',
          contractNameX: '',
          contractAddressX2: '',
          serverStacksX: '',
          usernameX: '',
          jsonPaymentForm: '',
          buttonErrorPaymentForm: false,
          errorPaymentForm: true,
          nameX: '',
          avatarX: '',
          messageSignX: '',
          messageUnSignX: '',
          jsonUserGroup: '',
          isocode: 'en',
          jsonBlockstackZ90: [],
          authorizersX: [],
          deliverableUuidX: '',
        }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      const {identityAddress} = userSession.loadUserData();
      const {decentralizedID} = userSession.loadUserData();

      this.setState({ identityAddress, decentralizedID, networkUrlX })

      let {name} = userSession.loadUserData().profile;
      if (name === '' || name === undefined || name === null){
        name = this.props.myUserConfig.profileName
      }
      this.setState({ nameBlockstack: name })

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      if (languageX === 'English'){this.setState({ messageSignX:`${'Document Signed by'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Signature Canceled by'} ${this.props.username}`})}
      if (languageX === 'French'){this.setState({ messageSignX:`${'Document signé par'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Signature annulée par'} ${this.props.username}`})}
      if (languageX === 'Spanish'){this.setState({ messageSignX:`${'Documento Firmado por'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Firma Anulada por'} ${this.props.username}`})}
      if (languageX === 'Portuguese'){this.setState({ messageSignX:`${'Documento assinado por'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Assinatura cancelada por'} ${this.props.username}`})}
      if (languageX === 'Swedish'){this.setState({ messageSignX:`${'Dokument Signerat av'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Signatur annullerad av'} ${this.props.username}`})}
      if (languageX === 'Netherlands'){this.setState({ messageSignX:`${'Document ondertekend door'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Handtekening geannuleerd door'} ${this.props.username}`})}
      if (languageX === 'Russian'){this.setState({ messageSignX:`${'Документ подписан'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Подпись отменена'} ${this.props.username}`})}
      if (languageX === 'Japanese'){this.setState({ messageSignX:`${'署名された文書'} ${this.props.username}`});this.setState({ messageUnSignX:`${'署名はによってキャンセルされました'} ${this.props.username}`})}
      if (languageX === 'Chinese'){this.setState({ messageSignX:`${'文件簽署人'} ${this.props.username}`});this.setState({ messageUnSignX:`${'簽名取消者'} ${this.props.username}`})}
      if (languageX === 'German'){this.setState({ messageSignX:`${'Dokument unterzeichnet von'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Unterschrift storniert von'} ${this.props.username}`})}
      if (languageX === 'Italian'){this.setState({ messageSignX:`${'Documento firmato da'} ${this.props.username}`});this.setState({ messageUnSignX:`${'Firma annullata da'} ${this.props.username}`})}

      if (languageX === 'English'){this.setState({ isocode: "en"})}
      if (languageX === 'French'){this.setState({ isocode: "fr"})}
      if (languageX === 'Spanish'){this.setState({ isocode: "es"})}
      if (languageX === 'Portuguese'){this.setState({ isocode: "pt"})}
      if (languageX === 'Swedish'){this.setState({ isocode: "sv"})}
      if (languageX === 'Netherlands'){this.setState({ isocode: "nl"})}
      if (languageX === 'Russian'){this.setState({ isocode: "ru"})}
      if (languageX === 'Japanese'){this.setState({ isocode: "ja"})}
      if (languageX === 'Chinese'){this.setState({ isocode: "zh"})}
      if (languageX === 'German'){this.setState({ isocode: "de",})}
      if (languageX === 'Italian'){this.setState({ isocode: "it"})}

    }

    componentDidMount() {
        let stacksNetX = ''
        let serverStacksX = ''
        let networkUrlX = ''
        const {userSession} = this.state
        const user = userSession.loadUserData()
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
           stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
        }
        if (serverStacksX === 'TestNet'){
           stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
        let {username} = userSession.loadUserData();
        if (username === '' || username === undefined || username === null){
          username_new(stacksNetX)
            .then(val => {
              this.setState({username: val })
            })
        }else{
          this.setState({username })
        }
        Promise.all([this.getSignDeliverable(),
                     this.getSignRemote()])
          .then((resolve) =>{},(reject) =>{})
        this.setState({deliverableUuidX: this.props.deliverableUuidX})
    }

/*
    componentDidUpdate(){
      if (this.props.deliverableUuidX !== this.state.deliverableUuidX){
        this.setState({jsonBlockstackZ90:[]},() =>{});
        Promise.all([this.getSignDeliverable(),
                     this.getSignRemote()])
          .then((resolve) =>{},(reject) =>{})
        this.setState({deliverableUuidX: this.props.deliverableUuidX},() =>{});
      }
    }
*/

    getSignRemote = () => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.from,`${this.props.typeContract}_${this.props.numberContract}_${this.props.deliverableUuidX}_authorizer_deliverable.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                 this.setState({jsonBlockstackZ90: []},() =>{});
                 let jsonBlockstackZ90 = []
                 jsonBlockstack4d.map((todoAuthorizer, y) => {
                   if (todoAuthorizer.codeFormsDeliverable === this.props.signatureFormX){
                      if (todoAuthorizer.id !== this.props.username){
                        Promise.all([this.getSignRemoteNames(this.props.deliverableUuidX,todoAuthorizer.id)])
                             .then((resolve1) => {
                                 if (resolve1[0].bsignature){
                                   let registroX = resolve1[0]
                                   jsonBlockstackZ90 = [...jsonBlockstackZ90, registroX]
                                   jsonBlockstackZ90.map((todoSign, z) => {
                                     if (todoAuthorizer.id === todoSign.id) {
                                         todoSign.role = todoAuthorizer.role
                                         Promise.all([this.getSignature2Names(todoAuthorizer.id)])
                                              .then((resolve1) => {
                                                 todoSign.firma = resolve1[0].signature
                                                 this.setState({jsonBlockstackZ90: jsonBlockstackZ90},() =>{});
                                              },(reject1) => {})
                                         Promise.all([this.readRemoteNameAvatarNames(todoAuthorizer.id)])
                                              .then((resolve1) => {
                                                  todoSign.name = resolve1[0].profileName
                                                  todoSign.avatar = resolve1[0].profileAvatar
                                                  this.setState({jsonBlockstackZ90: jsonBlockstackZ90},() =>{});
                                              },(reject1) => {})
                                      }
                                   })
                                   this.setState({jsonBlockstackZ90: jsonBlockstackZ90},() =>{});
                                 }
                              },(reject1) => {})
                      }
                   }
                 })
                 resolve1()
               } else {
                 reject1()
               }
             })
            .catch(error => {
              reject1()
            });
        })
    }

    getSignRemoteNames = (deliverableUuidX, userX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      return new Promise ((resolve1, reject1) =>{
          getFileDB(userX,`${this.props.typeContract}_${this.props.numberContract}_${this.props.deliverableUuidX}_deliverable_form.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 let registroX = jsonBlockstack6[0]
                 resolve1(registroX)
               } else {
                 reject1()
               }
             })
            .catch(error => {
              reject1()
            });
        })
    }

    getSignature2Names = (userX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      return new Promise ((resolve1, reject1) =>{
          getFileDB(userX,`signature.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 let registroX = jsonBlockstack6
                 resolve1(registroX)
               } else {
                 reject1()
               }
             })
            .catch(error => {
              reject1()
            });
        })
    }

    readRemoteNameAvatarNames = (userX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      return new Promise ((resolve1, reject1) =>{
          getFileDB(userX,`myUserConfig.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 let registroX = jsonBlockstack6
                 resolve1(registroX)
               } else {
                 reject1()
               }
             })
            .catch(error => {
              reject1()
            });
        })
    }

    getSignDeliverable(){
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = { decrypt: false, verify: false }
       let jsonBlockstack4 = []
       return new Promise ((resolve, reject) =>{
         getFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_${this.props.deliverableUuidX}_deliverable_form.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonUserGroup: jsonBlockstack6})
              this.completaNameAndAvatar(jsonBlockstack6)
              resolve(true);
            } else {
              this.putSignDeliverable()
              resolve(true);
            }
          })
           .catch(error => {
             this.putSignDeliverable()
               resolve(true);
          });
       });
    }

    putSignDeliverable = () => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      let jsonAttachFile =[{"uuid":this.props.deliverableUuidX,"id":this.props.username,"role":this.props.role,"signature":null,"bsignature":false,"signaturedatetime":null,"identityAddress":null,"name":null,avatar:null}]
      this.setState({jsonUserGroup: jsonAttachFile})
      putFileDB(this.props.username,`${this.props.typeContract}_${this.props.numberContract}_${this.props.deliverableUuidX}_deliverable_form.json`, 'Crosscheck_Deliverable', JSON.stringify(jsonAttachFile), JSON.stringify(options))
        .then(keyUrl => {
          this.completaNameAndAvatar(jsonAttachFile)
      })
    }

    completaNameAndAvatar = async (jsonUserGroup) => {
      const salida = []
      const resultado = await Promise.all(jsonUserGroup.map( async (todo) => {
         if (todo.id === this.props.username){
           return await Promise.all([this.readRemoteNameAvatar()])
             .then(
               (resolve) =>{
                 salida.push({uuid:todo.uuid, id:todo.id, role:todo.role, signature:todo.signature, bsignature:todo.bsignature, signaturedatetime:todo.signaturedatetime, identityAddress: todo.identityAddress, name:resolve[0].nameX, avatar:resolve[0].avatarX})
               },
               (reject)  =>{
                 salida.push({uuid:todo.uuid, id:todo.id, role:todo.role, signature:todo.signature, bsignature:todo.bsignature, signaturedatetime:todo.signaturedatetime, identityAddress: todo.identityAddress, name:'', avatar:''})
               }
             )
         }
      }))
      this.setState({jsonUserGroup: salida},()=>{})
    }

    readRemoteNameAvatar = () => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      let options = {}
      options = { decrypt: false, verify: false }
      return new Promise ((resolve, reject) =>{
        getFileDB(this.props.username, `myUserConfig.json`)
           .then((fileContents) => {

             if (fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               resolve({nameX: jsonBlockstack6.profileName, avatarX: jsonBlockstack6.profileAvatar})
             }else{
               reject()
             }
           })
          .catch(error => {
            reject()
        });
      });
    }

    handleDialog = (type, action) => {
      if (type === 'Confirm: 3001') {
         if (action === 'confirm') {
              this.setState({ dialogOpenSign: false });
              this.signContract()
         }else{
           if (action === 'cancel') {
            this.setState({ dialogOpenSign: false });
           }
         }
      }
      if (type === 'Confirm: 3002') {
         if (action === 'confirm') {
              this.setState({ dialogOpenUnSign: false });
              this.unsignContract()
         }else{
           if (action === 'cancel') {
            this.setState({ dialogOpenUnSign: false });
           }
         }
      }
    }

    returnMail = () =>{
      this.props.returnMail()
    }

    exitProgram(){
      this.props.returnMail()
    }

    returnSign = (jsonListDeliverableSignSend) => {
      this.props.returnSign(jsonListDeliverableSignSend, this.props.jsonListDeliverable)
    }

    signContract(){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({ publicKey: '' })
      return new Promise((resolve1, reject1) => {
          getFileDB(this.props.username, `publickey2.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.setState({publicKey: jsonBlockstack6.publicKey2},() =>{resolve1();});
                 this.signUserGroup(jsonBlockstack6.publicKey2,'sign')
               } else {
                 resolve1()
               }
             })
              .catch(error => {
                console.log(error)
                resolve1()
             });
      });
    }

    unsignContract(){
         this.setState({ displayLoader: true})
         this.signUserGroup("",'unsign')
    }

    signUserGroup = (Signature,modo) => {
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = { decrypt: false, verify: false }
       getFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_${this.props.deliverableUuidX}_deliverable_form.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              let cambiaSignature = false
              jsonBlockstack6.map ((todo, key) => {
                 if (todo.id === this.props.username && todo.signature !== Signature){
                    todo.signature=Signature
                    if (Signature === '' || modo === 'unsign'){
                      todo.signaturedatetime= ''
                      todo.bsignature = false
                    }else{
                      const isocode = this.state.isocode
                      const optionsDate = {
                          weekday: "short",
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour:	"2-digit",
                          minute:	"2-digit",
                          second:	"2-digit"
                      };
                      let signatureDateTimeX = new Date()
                      signatureDateTimeX = signatureDateTimeX.toLocaleString(isocode,optionsDate)
                      todo.signaturedatetime = signatureDateTimeX

                      todo.bsignature = true
                    }
                    cambiaSignature = true
                 }
              })
              if (cambiaSignature===true){
                let jsonBlockstack5 = JSON.stringify(jsonBlockstack6)
                this.signUserGroupWrite(jsonBlockstack5,modo)
              }
           }
         })
        .catch(error => {
              console.log(error)
              const dateTimeX =  new Date()
         });
    }

    signUserGroupWrite = (jsonBlockstack5,modo) => {
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
       putFileDB(this.props.username,`${this.props.typeContract}_${this.props.numberContract}_${this.props.deliverableUuidX}_deliverable_form.json`, 'Crosscheck_Deliverable', jsonBlockstack5, JSON.stringify(options))
          .then(() => {
             this.updateListDeliverable(modo)
          })
        .catch(error => {
        });
    }

    updateListDeliverable = (modo) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }

      let jsonListDeliverableSignSend = this.props.jsonListDeliverableSignSend

      this.props.jsonListDeliverable.map((todo, i) => {
          let bExiste = false
          jsonListDeliverableSignSend.map((todo2, y) => {
            if (todo2.deliverableUuid === todo.deliverableUuid){
              bExiste = true
            }
          })
          if (bExiste === false){
            jsonListDeliverableSignSend.push({id:todo.id, origin:todo.origin, to:todo.to, cc:todo.cc, subject:todo.subject, date:todo.date, message:todo.message, deliverableCost:todo.deliverableCost, deliverableUuid:todo.deliverableUuid, form:todo.form, tag:todo.tag, descriptionTag:todo.descriptionTag, hexTag:todo.hexTag, colorletterTag:todo.colorletterTag, statussign:todo.statussign,statussend:todo.statussend,close:todo.close})
          }
      })
      jsonListDeliverableSignSend.map((todo2,key2)=>{
          if (todo2.deliverableUuid === this.props.deliverableUuidX){
            if (modo === 'sign'){
              todo2.statussign = "Signed"
              todo2.statussend = "Sent"
            }
            if (modo === 'unsign'){
              todo2.statussign = "Waiting Signature"
              todo2.statussend = "Not Sent"
            }
          }
      })
      putFileDB(this.props.username,`${this.props.typeContract}_${this.props.numberContract}_sign_send_deliverablelist6.json`, 'Crosscheck_Deliverable', JSON.stringify(jsonListDeliverableSignSend), JSON.stringify(options))
          .then(() => {
            Promise.all([this.getSignDeliverable()])
              .then(
                 (resolve) =>{this.returnSign(jsonListDeliverableSignSend)},
                 (reject) =>{}
              )
          })
    }


    signConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenSign: true,dialogMessage: 'Are you sure to sign the deliverable?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'French'){this.setState({dialogOpenSign: true,dialogMessage: 'Êtes-vous sûr de signer le livrable ?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenSign: true,dialogMessage: '¿Está seguro de firmar el entregable?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenSign: true,dialogMessage: 'Você tem certeza de assinar a entrega?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenSign: true,dialogMessage: 'Är du säker på att du signerar leveransen?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenSign: true,dialogMessage: 'Bent u zeker dat u het document ondertekent?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenSign: true,dialogMessage: 'Вы уверены, что подписали документ?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenSign: true,dialogMessage: '成果物に署名しますか?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenSign: true,dialogMessage: '您確定要簽署可交付成果嗎？',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'German'){this.setState({dialogOpenSign: true,dialogMessage: 'Sind Sie sicher, dass Sie die Lieferung unterschreiben?',dialogTitle: 'Confirm: 3001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenSign: true,dialogMessage: 'Sei sicuro di firmare il deliverable?',dialogTitle: 'Confirm: 3001',})}
    }

    unsignConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Are you sure to unsign the deliverable?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'French'){this.setState({dialogOpenUnSign: true,dialogMessage: "Êtes-vous sûr d'annuler la signature du livrable ?",dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenUnSign: true,dialogMessage: '¿Está seguro de cancelar la firma del entregable?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Tem certeza de cancelar a assinatura da entrega?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Är du säker på att avregistrera leveransen?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Weet u zeker dat u de levering ongedaan wilt maken?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Вы уверены, что хотите отменить подписку?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenUnSign: true,dialogMessage: '成果物の署名を解除してもよろしいですか?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenUnSign: true,dialogMessage: '您確定要取消簽署可交付成果嗎？',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'German'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Sind Sie sicher, dass Sie die Lieferung zurücknehmen möchten?',dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenUnSign: true,dialogMessage: 'Sei sicuro di annullare la firma del deliverable?',dialogTitle: 'Confirm: 3002',})}
    }

    render() {
        let {jsonUserGroup} = this.state
        if (this.state.jsonUserGroup.length===0){
           jsonUserGroup = []
        }
        const {idx} = this.state
        const {typeContract} = this.props
        const messageSubtittle = `${this.props.signatureFormX}/${this.props.signatureSubjectX}`
        let avatar = undefined
        let avatar1 = undefined
        const avatar2 = 'images/avatar.png'
        const username = this.props.username

        let titleX = ''
        let messageSignX = ''
        let messageUnSignX = ''
        if (this.state.language === 'English'){titleX = "Sign Deliverable"}
        if (this.state.language === 'French'){titleX = "Signer le Livrable"}
        if (this.state.language === 'Spanish'){titleX = "Firmar Entregable"}
        if (this.state.language === 'Portuguese'){titleX = "Assinar Entregável"}
        if (this.state.language === 'Swedish'){titleX = "Skylt Levereras"}
        if (this.state.language === 'Netherlands'){titleX = "Teken Leverbaar"}
        if (this.state.language === 'Russian'){titleX = "Подписать"}
        if (this.state.language === 'Japanese'){titleX = "成果物に署名する"}
        if (this.state.language === 'Chinese'){titleX = "簽署可交付成果"}
        if (this.state.language === 'German'){titleX = "Lieferbar Unterschreiben"}
        if (this.state.language === 'Italian'){titleX = "Segno Consegnabile"}



        return (
          <div>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                  <TituloPagina2
                    sm="8"
                    title={titleX}
                    subtitle={messageSubtittle}
                    className="text-sm-left"
                  />
                </Row>
                <ConfirmacionSign
                    handleDialog={this.handleDialog}
                    dialogMessage={this.state.dialogMessage}
                    dialogOpen={this.state.dialogOpenSign}
                    dialogTitle={this.state.dialogTitle}
                />
                <ConfirmacionUnSign
                    handleDialog={this.handleDialog}
                    dialogMessage={this.state.dialogMessage}
                    dialogOpen={this.state.dialogOpenUnSign}
                    dialogTitle={this.state.dialogTitle}
                />
                <Row>&nbsp;</Row>
                <Row>
                  <Col>
                    <Form style={{fontSize:12}}>
                      <Row form>
                        <Col md="12">
                          <label></label>
                           <Table size="sm" className="text-center" responsive hover striped>
                              <thead>
                                <tr>
                                  <th></th>
                                  <th style={{fontSize:16}}><FormattedMessage id="sign.userid" /></th>
                                  <th style={{fontSize:16}}><FormattedMessage id="sign.name" /></th>
                                  <th style={{fontSize:16}}><FormattedMessage id="sign.role" /></th>
                                  <th style={{fontSize:16}}><FormattedMessage id="contract.withdate" /></th>
                                  <th></th>
                                  <th style={{fontSize:16}}><FormattedMessage id="sign.signature" /></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>

                                {this.state.jsonBlockstackZ90.map((todo, i) => {
                                  const palabra = todo.id
                                  const ArrayIdValue = palabra.split('.blockstack')
                                  avatar = ''
                                  if (todo.avatar === undefined || todo.avatar === null || todo.avatar === ''){
                                    if (ArrayIdValue.length===2){
                                       avatar = `${this.state.url}${todo.identityAddress}//avatar-0`
                                    }else{
                                       avatar = `${this.state.url}${todo.identityAddress}/0//avatar-0`
                                    }
                                  }else{
                                    avatar = Base64.decode(todo.avatar)
                                  }
                                  let avatar1 = "images/avatar.png"
                                  if (todo.name === undefined || todo.name === null || todo.name === 'undefined' ){todo.name = ''}
                                  let trimmedDataURLX = true
                                  let displayLoaderX = false
                                  let signatureX = ''

                                  if (todo.signature === '' || todo.signature === undefined || todo.signature === null ){
                                     signatureX = `...`
                                  }else{
                                     signatureX = `${todo.signature.substring(0,15)}...${todo.signature.substring(todo.signature.length-15)}`
                                  }

                                  if (todo.firma === '' || todo.firma === undefined || todo.firma === null ){
                                     trimmedDataURLX = false
                                  }

                                  return (
                                    <tr key={i} style={ this.props.colorDark ? { color:'white'} : {color:'black'}}>
                                       <td>
                                          <div className="mb-3 mx-auto">
                                            <ReactImageFallback
                                                 src={avatar}
                                                 fallbackImage={avatar2}
                                                 initialImage={avatar2}
                                                 alt={" "}
                                                 className="rounded-circle"
                                                 width="30"
                                            />
                                          </div>
                                        </td>
                                        <td>{todo.id}</td>
                                        <td>{todo.name}</td>
                                        <td>{todo.role}</td>
                                        <td>{todo.signaturedatetime}</td>
                                        <td>
                                          { trimmedDataURLX ?
                                            <div>
                                                <img src={todo.firma} style={{ width:50, height:35}} />
                                            </div>
                                          : null
                                          }
                                        </td>
                                        <td>
                                            {signatureX}
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )})}

                                {jsonUserGroup.map((todo, i) => {
                                  const palabra = todo.id
                                  const ArrayIdValue = palabra.split('.blockstack')
                                  avatar = ''
                                  if (todo.avatar === undefined || todo.avatar === null || todo.avatar === ''){
                                    if (ArrayIdValue.length===2){
                                       avatar = `${this.state.url}${todo.identityAddress}//avatar-0`
                                    }else{
                                       avatar = `${this.state.url}${todo.identityAddress}/0//avatar-0`
                                    }
                                  }else{
                                    avatar = Base64.decode(todo.avatar)
                                  }
                                  let avatar1 = "images/avatar.png"
                                  if (todo.name === undefined || todo.name === null || todo.name === 'undefined' ){todo.name = ''}
                                  let buttonSign = false
                                  let buttonUnSign = false
                                  let trimmedDataURLX = false
                                  let displayLoaderX = false
                                  let signatureX = ''

                                  if (todo.signature === '' || todo.signature === undefined || todo.signature === null ){
                                     signatureX = `...`
                                     buttonSign = true
                                  }else{
                                     signatureX = `${todo.signature.substring(0,15)}...${todo.signature.substring(todo.signature.length-15)}`
                                     buttonUnSign = true
                                     if (this.props.trimmedDataURL !== null){
                                       trimmedDataURLX = true
                                    }
                                  }

                                  if (this.props.bSignatureNextLevelX) {
                                     buttonSign = false
                                     buttonUnSign = false
                                  }

                                  return (
                                    <tr key={i} style={ this.props.colorDark ? { color:'white'} : {color:'black'}}>
                                       <td>
                                          <div className="mb-3 mx-auto">
                                            <ReactImageFallback
                                                 src={avatar}
                                                 fallbackImage={avatar2}
                                                 initialImage={avatar2}
                                                 alt={" "}
                                                 className="rounded-circle"
                                                 width="30"
                                            />
                                          </div>
                                        </td>
                                        <td>{todo.id}</td>
                                        <td>{todo.name}</td>
                                        <td>{this.props.roleDeliverableX}</td>
                                        <td>{todo.signaturedatetime}</td>
                                        <td>
                                          { trimmedDataURLX ?
                                            <div>
                                                <img src={this.props.trimmedDataURL} style={{ width:50, height:35}} />
                                            </div>
                                          : null
                                          }
                                        </td>
                                        <td>
                                            {signatureX}
                                        </td>
                                        {this.props.bCloseX ?
                                          <>
                                            <td></td>
                                            <td></td>
                                          </>
                                        :
                                          <>
                                            {this.props.bSignatureNextLevelX ?
                                              <>
                                                <td style={{color:'orange'}}>
                                                   <FormattedMessage id="sign.nextlevelsigned" />
                                                </td>
                                                <td></td>
                                              </>
                                            :
                                              <>
                                                {buttonSign ?
                                                   <React.Fragment>
                                                     {displayLoaderX ?
                                                        <td><ContractLoader colorDark={this.props.colorDark} /></td>
                                                     :
                                                        <td><Button pill theme="warning" onClick={this.signConfirm}><FormattedMessage id="button.sign" /></Button></td>
                                                     }
                                                   </React.Fragment>
                                                :
                                                   <td></td>
                                                }
                                                {buttonUnSign ?
                                                   <React.Fragment>
                                                     {displayLoaderX ?
                                                        <td><ContractLoader colorDark={this.props.colorDark} /></td>
                                                     :
                                                        <td><Button pill theme="warning" onClick={this.unsignConfirm}><FormattedMessage id="button.unsign" /></Button></td>
                                                     }
                                                   </React.Fragment>
                                                :
                                                   <td></td>
                                                }
                                              </>
                                            }
                                          </>
                                        }
                                    </tr>
                                )})}
                              </tbody>
                            </Table>
                        </Col>
                      </Row>
                    </Form>
                  </Col>

                </Row>
                <Row form>
                  <Button pill theme="secondary" onClick={this.exitProgram}><FormattedMessage id="contract.listcol19" /></Button>
                </Row>

                <br></br>
            </Container>
          </div>
        );
    }
}

export default SignatureDeliverable;
