//React
import React, { Component } from 'react';

//UI
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormTextarea,
  FormSelect,
  Container
} from "shards-react";
import { Table } from 'reactstrap';
import { Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';

//base64
import { Base64 } from 'js-base64';

//translate
import { FormattedMessage } from 'react-intl';

class ContractDrawerChatNew extends Component {

    constructor(props) {
        super(props);

        this.sendChat = this.sendChat.bind(this);
        this.listChat = this.listChat.bind(this);

        this.state = {
          disabled: false,
          language: 'English',
        }
    }

    c04Contact = React.createRef();

    UNSAFE_componentWillMount() {
      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
    }

    sendChat(){
      if (this.c04Contact.current.value.length>0){
          const messagec04 = this.c04Contact.current.value
          this.c04Contact.current.value = ''
          this.props.sendChat(messagec04,this.props.jsonBlockstackZ60)
      }else{
          this.listChat()
      }
    }

    listChat(){
      this.props.listChat()
    }

    render() {
          const {jsonBlockstackZ70} = this.props
          let placeholderX3 = "Enter message to send"
          if (this.state.language === 'English'){placeholderX3 = "Enter message to send"}
          if (this.state.language === 'French'){placeholderX3 = "Saisissez le message à envoyer"}
          if (this.state.language === 'Spanish'){placeholderX3 = "Ingrese mensaje a enviar"}
          if (this.state.language === 'Portuguese'){placeholderX3 = "Digite a mensagem para enviar"}
          if (this.state.language === 'Swedish'){placeholderX3 = "Ange ett meddelande som ska skickas"}
          if (this.state.language === 'Netherlands'){placeholderX3 = "Voer het te verzenden bericht in"}
          if (this.state.language === 'Russian'){placeholderX3 = "Введите сообщение для отправки"}
          if (this.state.language === 'Japanese'){placeholderX3 = "送信するメッセージを入力してください"}
          if (this.state.language === 'Chinese'){placeholderX3 = "輸入信息發送"}
          if (this.state.language === 'German'){placeholderX3 = "Geben Sie die zu sendende Nachricht ein"}
          if (this.state.language === 'Italian'){placeholderX3 = "Inserisci il messaggio da inviare"}
          return (
            <>
              <div>
                  <FormTextarea
                    id="feMessage"
                    rows="1"
                    innerRef={this.c04Contact}
                    style={{backgroundColor:"#F2EACE"}}
                    placeholder={placeholderX3}
                  />
              </div>
              <br></br>
              <div className="text-center">
                    <Button outline pill theme="warning" size="sm" className="mb-2" onClick={this.sendChat}>
                        <i className="material-icons mr-1">replay</i> <FormattedMessage id="contract.refresh" />
                    </Button>
              </div>
            </>
          );
     }
}

export default ContractDrawerChatNew;
