import React, {Component} from "react";

//UI
import { Container, Row, Col, Form, FormSelect, Card, Button, InputGroupText, FormRadio, InputGroupAddon } from "shards-react";

//chart
import { PieChart, Pie, Sector } from 'recharts';

//Components
import TituloPagina from "./../components/common/TituloPagina";
import SmallStats from "./../components/dashboard/SmallStats";
import DetailDataReceived from "./../components/dashboard/DetailDataReceived";
//import UsersByDevice from "./../components/dashboard/UsersByDevice";
import NewDraft from "./../components/dashboard/NewDraft";
import Discussions from "./../components/dashboard/Discussions";
import TopReferrals from "./../components/dashboard/TopReferrals";

//SetTimeOut React
import ReactTimeout from 'react-timeout'
import TimerMixin from 'react-timer-mixin'

// Spinning
import Loader from './../components/loader'

import DatosCryptoCurrency from "./../components/cryptocurrency/DatosCryptoCurrency";

//Proptype
import PropTypes from 'prop-types';

// blockstack
import { UserSession } from 'blockstack';

// Redux
import { connect } from 'react-redux';
import {simulateDatossensores} from "../actions/sensoresActions"
import {getFileDB, putFileDB} from '../actions/mongoDBActions'

//Axios (API)
import axios from 'axios';

//Sagas (PACT)
import { fetchPactLogisticConfig,
         saveNewPactDataSensorTemperature,
         saveNewPactDataSensor,
         saveNewPactDataSensorHumidity,
         saveNewPactDataSensorGascontrol }
  from '../actions/pactActions';

import {username_new} from '../actions/userActions'

class Dashboard extends Component {
     constructor(props) {
       super(props);

       this.goDashboard = this.goDashboard.bind(this);
       this.getTrackingContract = this.getTrackingContract.bind(this);
       this.getContractIoTDevice = this.getContractIoTDevice.bind(this);
       this.getMasterFileSensor = this.getMasterFileSensor.bind(this);
       this.getMasterFileDevice = this.getMasterFileDevice.bind(this);
       this.getAPIDataSensor = this.getAPIDataSensor.bind(this);
       this.getDataSensor = this.getDataSensor.bind(this);
       this.putDataSensor = this.putDataSensor.bind(this);
       this.arrayPlusDelay1 = this.arrayPlusDelay1.bind(this);
       this.arrayPlusDelay2 = this.arrayPlusDelay2.bind(this);
       this.arrayPlusDelay3 = this.arrayPlusDelay3.bind(this);
       this.messageError = this.messageError.bind(this);
       this.searchContracts = this.searchContracts.bind(this);

       this.state = {
         userSession: new UserSession(),
         username: '',
         user: {},
         isLoading: false,
         displayDashboard: false,
         jsonListContract: [],
         jsonContractDevice: undefined,
         jsonMasterFileSensor: undefined,
         jsonMasterFileDEvice: undefined,
         jsonTrackingContract: undefined,
         jsonDataSensor: undefined,
         datasensor: [],
         dataJsonX: [],
         verificationRules: [],
         dataCustomActiveShapePieChart: [],
         existContractIoTDevice: true,
         selectedSearch: 'switchBlockstack',
         showAlert: false,
         showLoader:false,
         smallStats2: [],
         referralData: [],
         doughnutData: [],
         chartData: [],
         mensajeErrorX: '',
         errorMensaje2: false,
         errorMensaje3: false,
         errorMensaje4: false,
         errorMensaje5: false,
         activeIndex: 1,
         colorCustomActiveShapePieChart: "#03E1BC",
       }
     }

     c01Dashboard = React.createRef();

     componentWillMount() {
       const { userSession } = this.state
       let stacksNetX = ''
       let serverStacksX = ''
       if (userSession.isUserSignedIn()) {
         const user = userSession.loadUserData()
         this.setState({ user })
         if (!localStorage["serverStacks"]) {
           serverStacksX = 'MainNet'
         }else{
           serverStacksX = localStorage.getItem('serverStacks')
         }
         if (serverStacksX === 'MainNet'){
   	       stacksNetX =  user.profile.stxAddress.mainnet
         }
         if (serverStacksX === 'TestNet'){
   	       stacksNetX =  user.profile.stxAddress.testnet
         }
       }
       const {username} = userSession.loadUserData();
       if (username === '' || username === undefined || username === null){
         username_new(stacksNetX).then(val => this.setState({username: val})) ;
       }else{
         this.setState({username })
       }

       const referralData = `{"title": "Number data imported from the Sensors",
         "referralData": [
           {
             "title": "Temperature",
             "value": "-"
           },
           {
             "title": "Humidity",
             "value": "-"
           },
           {
             "title": "Gas Control",
             "value": "-"
           },
           {
             "title": "Impact",
             "value": "-"
           },
           {
             "title": "Vibration",
             "value": "-"
           },
           {
             "title": "Proximity",
             "value": "-"
           },
           {
             "title": "Seal Integrity",
             "value": "-"
           },
           {
             "title": "GPS",
             "value": "-"
           }
       ]}`

      this.setState({ referralData: JSON.parse(referralData) })

      const chartData = `{
        "title": "Graph Data Received",
        "chartData": {
          "labels": "Array.from(new Array(30), (_, i) => (i === 0 ? 1 : i))",
          "datasets": [
            {
              "label": "Temperature",
              "fill": "start",
              "data": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "backgroundColor": "rgba(0,123,255,0.1)",
              "borderColor": "rgba(0,123,255,1)",
              "pointBackgroundColor": "#ffffff",
              "pointHoverBackgroundColor": "rgb(0,123,255)",
              "borderWidth": 1.5,
              "pointRadius": 0,
              "pointHoverRadius": 3
            },
            {
              "label": "Humidity",
              "fill": "start",
              "data": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "backgroundColor": "rgba(255,65,105,0.1)",
              "borderColor": "rgba(255,65,105,1)",
              "pointBackgroundColor": "#ffffff",
              "pointHoverBackgroundColor": "rgba(255,65,105,1)",
              "borderDash": [3, 3],
              "borderWidth": 1,
              "pointRadius": 0,
              "pointHoverRadius": 2,
              "pointBorderColor": "rgba(255,65,105,1)"
            }
          ]}
        }`

        this.setState({ chartData: JSON.parse(chartData) })

        const dataJsonX = `{"data": [
                {"json": ""},
                {"json": ""},
                {"json": ""},
                {"json": ""},
                {"json": ""},
                {"json": ""},
                {"json": ""},
                {"json": ""},
                {"json": ""}
           ]}`

           this.setState({ dataJsonX: JSON.parse(dataJsonX) })

           const verificationRules = `{
                "title": "Verification of Rules",
                "chartData": {
                  "datasets": [
                    {
                      "hoverBorderColor": "#ffffff",
                      "data": [58.3, 41.7],
                      "backgroundColor": [
                        "rgba(0,123,255,0.9)",
                        "rgba(0,123,255,0.5)"
                      ]
                    }
                  ],
                  "labels": ["Within Range", "Out of Range"]
                }
              }`

           this.setState({ verificationRules: JSON.parse(verificationRules) })

           const dataCustomActiveShapePieChart = `[
             { "name": "Within Range", "value": 10 },
             { "name": "Out of Range", "value": 12 }
           ]`;

           this.setState({ dataCustomActiveShapePieChart: JSON.parse(dataCustomActiveShapePieChart) })


     }

     componentDidMount() {
       this.searchContracts()
     }

     componentWillUnmount() {
       TimerMixin.clearTimeout();

     }

     getRandomInt (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
     }

     //First
     searchContracts() {
       //PACT
       if (this.state.selectedSearch==='switchPACT'){
           this.props.fetchPactLogisticConfig();
       }else{
           //Blockstack
           const { userSession } = this.state
           this.setState({jsonListContract: []})
           const options = { decrypt: false }
           getFileDB(this.state.username, `contractlogistic.json`)
              .then((fileContents) => {
                if(fileContents) {
                  const jsonBlockstack1 = JSON.parse(fileContents)
                  const jsonBlockstack2 = jsonBlockstack1.data
                  let jsonBlockstack5 = '[]'
                  if (jsonBlockstack2 !== null){
                     const jsonBlockstack3 = jsonBlockstack2.dataobject
                     const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                     if (jsonBlockstack4.substring(0,1) === '"') {
                        jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                     } else {
                        jsonBlockstack5 = jsonBlockstack4
                     }
                  }
                  let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                  this.setState({jsonListContract: jsonBlockstack6})
                } else {
                  //console.log("No Data (contractlogistic.json)");
                }
              })
               .catch(error => {
                 //console.log(error);
               });
       }

     }
     handleChangeOption(optSearch) {
         this.setState({
           selectedSearch: optSearch,
           showAlert: false,
           showLoader:false,
           displayDashboard: false,
         });
         this.c01Dashboard.current.value='SelectOptionDashboard'
     }

     async goDashboard() {
        if (this.c01Dashboard.current.value=== 'SelectOptionDashboard'){
          this.setState({ displayDashboard: false })
        }else{

          this.setState({showLoader:true});
          //------------------------
          this.getTrackingContract()
          //------------------------
      }
     }

     async getTrackingContract() {
         const { userSession } = this.state
         this.setState({jsonTrackingContract: undefined})
         const palabra = this.c01Dashboard.current.value
         const options = { decrypt: false }
         this.setState({ errorMensaje2: false })
         let jsonBlockstack4 = []
         await getFileDB(this.state.username, `logistic_${palabra}.json`)
            .then((fileContents) => {
              if(fileContents) {
                const jsonBlockstack1 = JSON.parse(fileContents)
                const jsonBlockstack2 = jsonBlockstack1.data
                let jsonBlockstack5 = '[]'
                if (jsonBlockstack2 !== null){
                   const jsonBlockstack3 = jsonBlockstack2.dataobject
                   const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                   if (jsonBlockstack4.substring(0,1) === '"') {
                      jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                   } else {
                      jsonBlockstack5 = jsonBlockstack4
                   }
                }
                let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                this.setState({jsonTrackingContract: jsonBlockstack6})
               //-------------------------
                this.getContractIoTDevice(jsonBlockstack4)
               //-------------------------
              } else {
                //console.log(`No Data (logistic_${palabra}.json)`);
                this.setState({showLoader:false});
                this.setState({mensajeErrorX: 'Inconsistent Data: Contract Detail not found'})
                this.setState({ displayDashboard: false })
                this.setState({ errorMensaje2: true })
              }
            })
             .catch(error => {
               //console.log(error);
               this.setState({showLoader:false});
            });
     }

     async getContractIoTDevice(jsonTrackingContractX) {
       const { userSession } = this.state
       const palabra = this.c01Dashboard.current.value
       this.setState({displayContractIoTDevice: true})
       this.setState({idx: palabra})
       this.setState({jsonContractDevice: undefined})
       let jsonBlockstack4 = []
       const options = { decrypt: false }
       await getFileDB(this.state.username, `contract_${palabra}_device.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonContractDevice: jsonBlockstack6})
              this.setState({ existContractIoTDevice: true })
              //-------------------------
              this.getMasterFileSensor(jsonTrackingContractX,jsonBlockstack6)
              //-------------------------
            } else {
                //console.log(`No Data (contract_${palabra}_device.json)`);
                this.setState({showLoader:false});
                this.setState({ displayDashboard: false })
                this.setState({ existContractIoTDevice: false })
            }
          })
           .catch(error => {
             //console.log(error);
             this.setState({ displayDashboard: false })
             this.setState({showLoader:false});
             this.setState({ existContractIoTDevice: false })
          });
     }

     async getMasterFileSensor(jsonTrackingContractX,jsonContractDeviceX) {
       const { userSession } = this.state
       this.setState({jsonMasterFileSensor: undefined})
       this.setState({ errorMensaje3: false })
       let jsonBlockstack4 = []
       const options = { decrypt: false }
       await getFileDB(this.state.username, `sensor_id.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonMasterFileSensor: jsonBlockstack6})
              //-------------------------
              this.getMasterFileDevice(jsonTrackingContractX,jsonContractDeviceX,jsonBlockstack6)
              //-------------------------
            } else {
              //console.log("No Data (sensor_id.json)");
              this.setState({mensajeErrorX: 'Inconsistent Data: Master File Sensors not found'})
              this.setState({showLoader:false});
              this.setState({ displayDashboard: false })
              this.setState({ errorMensaje3: true })
            }
          })
           .catch(error => {
             //console.log(error);
             this.setState({showLoader:false});
          });
     }

     async getMasterFileDevice(jsonTrackingContractX,jsonContractDeviceX,jsonMasterFileSensorX) {
       const { userSession } = this.state
       this.setState({jsonMasterFileDevice: undefined})
       this.setState({ errorMensaje4: false })
       let jsonBlockstack4 = []
       const options = { decrypt: false }
       await getFileDB(this.state.username, `sensor_category.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonMasterFileDevice: jsonBlockstack6})
              //-------------------------
              this.getDataSensor(jsonTrackingContractX,jsonContractDeviceX,jsonMasterFileSensorX,jsonBlockstack6)
              //-------------------------
            } else {
              //console.log("No Data (sensor_category.json)");
              this.setState({mensajeErrorX: 'Inconsistent Data: Master File Devices not found'})
              this.setState({showLoader:false});
              this.setState({ displayDashboard: false })
              this.setState({ errorMensaje4: true })
            }
          })
           .catch(error => {
             //console.log(error);
             this.setState({showLoader:false});
           });
     }

     async getDataSensor(jsonTrackingContractX,jsonContractDeviceX,jsonMasterFileSensorX,jsonMasterFileDeviceX) {
       const { userSession } = this.state
       const palabra = this.c01Dashboard.current.value
       this.setState({jsonDataSensor: undefined})
       this.setState({ errorMensaje5: false })
       let jsonBlockstack6 = []
       const options = { decrypt: false }
       await getFileDB(this.stater.username, `data_sensor_${palabra}.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonDataSensor: jsonBlockstack6})
              //-------------------------
              this.getAPIDataSensor(jsonTrackingContractX,jsonContractDeviceX,jsonMasterFileSensorX,jsonMasterFileDeviceX,jsonBlockstack6)
              //-------------------------
            } else {
              //console.log(`No Data (data_sensor_${palabra}.json)`);
              //-------------------------
              this.getAPIDataSensor(jsonTrackingContractX,jsonContractDeviceX,jsonMasterFileSensorX,jsonMasterFileDeviceX,jsonBlockstack6)
              //-------------------------
            }
          })
           .catch(error => {
             //console.log(error);
           });
     }

     async getAPIDataSensor(jsonTrackingContractX,jsonContractDeviceX,jsonMasterFileSensorX,jsonMasterFileDeviceX,jsonDataSensorX) {
       //this.props.simulateDatossensores()
       let datasensor = []
       const contractNumber = this.c01Dashboard.current.value
       await axios.get(`https://xck.app:8443/data`)
         .then(res => {
           datasensor = res.data;
           //console.log(datasensor)
           this.setState({ datasensor: datasensor })
           this.setState({ displayDashboard: true })
           //console.log(Object.keys(datasensor).length)
           this.setState({showLoader:false});
           if (Object.keys(datasensor).length === 0) {
               this.setState({mensajeErrorX: 'Error: API Server Data sensor not found'})
               this.setState({ displayDashboard: false })
               this.setState({ errorMensaje5: true })
           }
           clearTimeout()
           //-------------------------
           this.arrayPlusDelay1(datasensor, 5000, contractNumber, jsonTrackingContractX,jsonContractDeviceX,jsonMasterFileSensorX,jsonMasterFileDeviceX,jsonDataSensorX)
           //-------------------------
         })
     }


     arrayPlusDelay1 = (array, delay, contractNumber, jsonTrackingContractX,jsonContractDeviceX,jsonMasterFileSensorX,jsonMasterFileDeviceX,jsonDataSensorX) => {

       let valorTemperature = ''
       let valorHumidity = ''
       let valorGasControl = ''
       let idTypeSensorTemperature='Temperature'
       let idTypeSensorHumidity='Humidity'
       let idTypeSensorGasControl='Gas Control'
       let dataTemperature = [10, 7, 1, 5, 6, 2, 8]
       let dataHumidity = [17, 18, 15, 21, 20, 23, 15]
       let dataGasControl = [11, 12, 14, 17, 13, 17, 19]
       let variationTemperature = 0
       let variationHumidity = 0
       let variationGasControl = 0
       let countTemperature = 0
       let countHumidity = 0
       let countGasControl = 0
       let decreaseTemperature = false
       let increaseTemperature = false
       let decreaseHumidity = false
       let increaseHumidity = false
       let decreaseGasControl = false
       let increaseGasControl = false
       let countBadTemperature = 0
       let countBadHumidity = 0
       let countBadGascontrol = 0
       let countGoodTemperature = 0
       let countGoodHumidity = 0
       let countGoodGascontrol = 0

       let dataJson = ['','','', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']
       let charDataValue = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
       let charDataValue2 = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
       let charDataValue3 = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]

       let unitTemperature = ''
       let unitHumidity = ''
       let unitGasControl = ''
       let unitMeasurement = ''

        array.forEach( (el, i) => {
          TimerMixin.setTimeout( () => {
              //console.log(array[i]);

              let idTypeSensor = ''
              //let idTypeDevice = ''
              if (array[i].sensorid === jsonMasterFileSensorX.code){
                 idTypeSensor = jsonMasterFileSensorX.description
              }
              //if (array[i].deviceid === jsonContractDeviceX.code){
              //   idTypeDevice = jsonContractDeviceX.description
              //}

              unitTemperature = jsonTrackingContractX.temperatureUnitMeasurement
              unitHumidity = jsonTrackingContractX.humidityUnitMeasurement
              unitGasControl = jsonTrackingContractX.gascontrolUnitMeasurement

              const idSensor = array[i].sensorid,
                    idTrackingContract = contractNumber,
                    idContainer = jsonTrackingContractX.idContainer,
                    idBillOfLading = jsonTrackingContractX.idBillOfLading,
                    idCaller = array[i].deviceid,
                    idCategory = array[i].deviceid,
                    valorSensor = parseFloat(array[i].value),
                    //valorSensor = array[i].value,
                    dateDataSensor = array[i].date,
                    latitudDataSensor = parseFloat(array[i].latitude),
                    longituDataSensor = parseFloat(array[i].longitude)
                    //latitudDataSensor = array[i].latitude,
                    //longituDataSensor = array[i].longitude

                    let idPact = null
                    let temperatureLowerThreshold = null
                    let temperatureUpperThreshold = null
                    let temperatureActiveRule = null
                    let humidityLowerThreshold = null
                    let humidityUpperThreshold = null
                    let humidityActiveRule = null
                    let gascontrolLowerThreshold = null
                    let gascontrolUpperThreshold = null
                    let gascontrolActiveRule = null


                    if (this.state.selectedSearch==='switchPACT'){
                        this.props.smartcontract.map((todo, i) => {
                            if (todo.configurationContractNumber === contractNumber) {
                              temperatureLowerThreshold = todo.temperatureLowerThreshold;
                              temperatureUpperThreshold = todo.temperatureUpperThreshold;
                              temperatureActiveRule = todo.temperatureActiveRule;
                              humidityLowerThreshold = todo.humidityLowerThreshold;
                              humidityUpperThreshold = todo.humidityUpperThreshold;
                              humidityActiveRule = todo.humidityActiveRule;
                              gascontrolLowerThreshold = todo.gascontrolLowerThreshold;
                              gascontrolUpperThreshold = todo.gascontrolUpperThreshold;
                              gascontrolActiveRule = todo.gascontrolActiveRule;
                              idPact = todo.id;
                              //idPact = todo.id.toString();
                            }
                            return idPact
                        })
                    }else{
                        temperatureLowerThreshold = jsonTrackingContractX.temperatureLowerThreshold;
                        temperatureUpperThreshold = jsonTrackingContractX.temperatureUpperThreshold;
                        if (jsonTrackingContractX.temperatureActiveRule==='true'){
                           temperatureActiveRule = true;
                        }else{
                          temperatureActiveRule = false;
                        }
                        humidityLowerThreshold = jsonTrackingContractX.humidityLowerThreshold;
                        humidityUpperThreshold = jsonTrackingContractX.humidityUpperThreshold;
                        if (jsonTrackingContractX.humidityActiveRule==='true'){
                           humidityActiveRule = true;
                        }else{
                          humidityActiveRule = false;
                        }
                        gascontrolLowerThreshold = jsonTrackingContractX.gascontrolLowerThreshold;
                        gascontrolUpperThreshold = jsonTrackingContractX.gascontrolUpperThreshold;
                        if (jsonTrackingContractX.gascontrolActiveRule==='true'){
                           gascontrolActiveRule = true;
                        }else{
                          gascontrolActiveRule = false;
                        }
                    }
              //-----------------------
              // Cálculos para PACT
              //-----------------------
              let statusRuleTemperatureLower = false
              let statusRuleTemperatureUpper = false
              let statusRuleHumidityLower = false
              let statusRuleHumidityUpper = false
              let statusRuleGasControlLower = false
              let statusRuleGasControlUpper = false

              if (idSensor === "1") {
                  unitMeasurement = unitTemperature
                if (temperatureActiveRule===true){
                  if (valorSensor < parseFloat(temperatureLowerThreshold)){
                     statusRuleTemperatureLower = true
                     countBadTemperature = countBadTemperature + 1
                  }
                  if (valorSensor > parseFloat(temperatureUpperThreshold)){
                     statusRuleTemperatureUpper = true
                     countBadTemperature = countBadTemperature + 1
                  }
                  if (valorSensor >= parseFloat(temperatureLowerThreshold) &&  valorSensor <= parseFloat(temperatureUpperThreshold)){
                     countGoodTemperature = countGoodTemperature + 1
                  }
                }
              }
              if (idSensor === "2") {
                unitMeasurement = unitHumidity
                if (humidityActiveRule===true){
                  if (valorSensor < parseFloat(humidityLowerThreshold)){
                     statusRuleHumidityLower = true
                     countBadHumidity = countBadHumidity + 1
                  }
                  if (valorSensor > parseFloat(humidityUpperThreshold)){
                     statusRuleHumidityUpper = true
                     countBadHumidity = countBadHumidity + 1
                  }
                  if (valorSensor >= parseFloat(humidityLowerThreshold) &&  valorSensor <= parseFloat(humidityUpperThreshold)){
                     countGoodHumidity = countGoodHumidity + 1
                  }

                }
              }
              if (idSensor === "3") {
                unitMeasurement = unitGasControl
                if (gascontrolActiveRule===true){
                  if (valorSensor < parseFloat(gascontrolLowerThreshold)){
                     statusRuleGasControlLower = true
                     countBadGascontrol = countBadGascontrol + 1
                  }
                  if (valorSensor > parseFloat(gascontrolUpperThreshold)){
                     statusRuleGasControlUpper = true
                     countBadGascontrol = countBadGascontrol + 1
                  }
                  if (valorSensor >= parseFloat(gascontrolLowerThreshold) &&  valorSensor <= parseFloat(gascontrolUpperThreshold)){
                     countGoodGascontrol = countGoodGascontrol + 1
                  }
                }
              }

              //-----------------------
              // Cálculos para gráficos
              //-----------------------
              if (array[i].sensorid === '1'){
                 valorTemperature = valorSensor
                 countTemperature = countTemperature + 1
                 dataTemperature[0] = dataTemperature[1]
                 dataTemperature[1] = dataTemperature[2]
                 dataTemperature[2] = dataTemperature[3]
                 dataTemperature[3] = dataTemperature[4]
                 dataTemperature[4] = dataTemperature[5]
                 dataTemperature[5] = dataTemperature[6]
                 dataTemperature[6] = parseFloat(array[i].value)
                 if (dataTemperature[5]===0) {
                     variationTemperature =  dataTemperature[6] * 100
                 }else{
                     variationTemperature =  ((dataTemperature[6]-dataTemperature[5])/dataTemperature[5]) * 100
                 }
                 if (dataTemperature[6] <  dataTemperature[5]) {
                   decreaseTemperature = true
                   increaseTemperature = false
                 }else{
                   if (dataTemperature[6] ===  dataTemperature[5]) {
                     decreaseTemperature = false
                     increaseTemperature = false
                   }else{
                     if (dataTemperature[6] >  dataTemperature[5]) {
                       decreaseTemperature = false
                       increaseTemperature = true
                     }
                   }
                 }

                 charDataValue[0] = charDataValue[1]
                 charDataValue[1] = charDataValue[2]
                 charDataValue[2] = charDataValue[3]
                 charDataValue[3] = charDataValue[4]
                 charDataValue[4] = charDataValue[5]
                 charDataValue[5] = charDataValue[6]
                 charDataValue[6] = charDataValue[7]
                 charDataValue[7] = charDataValue[8]
                 charDataValue[8] = charDataValue[9]
                 charDataValue[9] = charDataValue[10]
                 charDataValue[10] = charDataValue[11]
                 charDataValue[11] = charDataValue[12]
                 charDataValue[12] = charDataValue[13]
                 charDataValue[13] = charDataValue[14]
                 charDataValue[14] = charDataValue[15]
                 charDataValue[15] = charDataValue[16]
                 charDataValue[16] = charDataValue[17]
                 charDataValue[17] = charDataValue[18]
                 charDataValue[18] = charDataValue[19]
                 charDataValue[19] = charDataValue[20]
                 charDataValue[20] = charDataValue[21]
                 charDataValue[21] = charDataValue[22]
                 charDataValue[22] = charDataValue[23]
                 charDataValue[23] = charDataValue[24]
                 charDataValue[24] = charDataValue[25]
                 charDataValue[25] = charDataValue[26]
                 charDataValue[26] = charDataValue[27]
                 charDataValue[27] = charDataValue[28]
                 charDataValue[28] = charDataValue[29]
                 charDataValue[29] = parseFloat(array[i].value)

              }else{
                if (array[i].sensorid === '2'){
                   valorHumidity = valorSensor
                   countHumidity = countHumidity + 1
                   dataHumidity[0] = dataHumidity[1]
                   dataHumidity[1] = dataHumidity[2]
                   dataHumidity[2] = dataHumidity[3]
                   dataHumidity[3] = dataHumidity[4]
                   dataHumidity[4] = dataHumidity[5]
                   dataHumidity[5] = dataHumidity[6]
                   dataHumidity[6] = parseFloat(array[i].value)
                   if (dataHumidity[5]===0) {
                       variationHumidity =  dataHumidity[6] * 100
                   }else{
                       variationHumidity =  ((dataHumidity[6]-dataHumidity[5])/dataHumidity[5]) * 100
                   }

                   if (dataHumidity[6] <  dataHumidity[5]) {
                     decreaseHumidity = true
                     increaseHumidity = false
                   }else{
                     if (dataHumidity[6] ===  dataHumidity[5]) {
                       decreaseHumidity = false
                       increaseHumidity = false
                     }else{
                       if (dataHumidity[6] >  dataHumidity[5]) {
                         decreaseHumidity = false
                         increaseHumidity = true
                       }
                    }
                  }

                  charDataValue2[0] = charDataValue2[1]
                  charDataValue2[1] = charDataValue2[2]
                  charDataValue2[2] = charDataValue2[3]
                  charDataValue2[3] = charDataValue2[4]
                  charDataValue2[4] = charDataValue2[5]
                  charDataValue2[5] = charDataValue2[6]
                  charDataValue2[6] = charDataValue2[7]
                  charDataValue2[7] = charDataValue2[8]
                  charDataValue2[8] = charDataValue2[9]
                  charDataValue2[9] = charDataValue2[10]
                  charDataValue2[10] = charDataValue2[11]
                  charDataValue2[11] = charDataValue2[12]
                  charDataValue2[12] = charDataValue2[13]
                  charDataValue2[13] = charDataValue2[14]
                  charDataValue2[14] = charDataValue2[15]
                  charDataValue2[15] = charDataValue2[16]
                  charDataValue2[16] = charDataValue2[17]
                  charDataValue2[17] = charDataValue2[18]
                  charDataValue2[18] = charDataValue2[19]
                  charDataValue2[19] = charDataValue2[20]
                  charDataValue2[20] = charDataValue2[21]
                  charDataValue2[21] = charDataValue2[22]
                  charDataValue2[22] = charDataValue2[23]
                  charDataValue2[23] = charDataValue2[24]
                  charDataValue2[24] = charDataValue2[25]
                  charDataValue2[25] = charDataValue2[26]
                  charDataValue2[26] = charDataValue2[27]
                  charDataValue2[27] = charDataValue2[28]
                  charDataValue2[28] = charDataValue2[29]
                  charDataValue2[29] = parseFloat(array[i].value)

                }else{
                  if (array[i].sensorid === '3'){
                     valorGasControl = valorSensor
                     countGasControl = countGasControl + 1
                     dataGasControl[0] = dataGasControl[1]
                     dataGasControl[1] = dataGasControl[2]
                     dataGasControl[2] = dataGasControl[3]
                     dataGasControl[3] = dataGasControl[4]
                     dataGasControl[4] = dataGasControl[5]
                     dataGasControl[5] = dataGasControl[6]
                     dataGasControl[6] = parseFloat(array[i].value)
                     if (dataGasControl[5]===0) {
                         variationGasControl =  dataGasControl[6] * 100
                     }else{
                         variationGasControl =  ((dataGasControl[6]-dataGasControl[5])/dataGasControl[5]) * 100
                     }
                     if (dataGasControl[6] <  dataGasControl[5]) {
                       decreaseGasControl = true
                       increaseGasControl = false
                     }else{
                       if (dataGasControl[6] ===  dataGasControl[5]) {
                         decreaseGasControl = false
                         increaseGasControl = false
                       }else{
                         if (dataGasControl[6] >  dataGasControl[5]) {
                           decreaseGasControl = false
                           increaseGasControl = true
                         }
                      }
                    }

                    charDataValue3[0] = charDataValue3[1]
                    charDataValue3[1] = charDataValue3[2]
                    charDataValue3[2] = charDataValue3[3]
                    charDataValue3[3] = charDataValue3[4]
                    charDataValue3[4] = charDataValue3[5]
                    charDataValue3[5] = charDataValue3[6]
                    charDataValue3[6] = charDataValue3[7]
                    charDataValue3[7] = charDataValue3[8]
                    charDataValue3[8] = charDataValue3[9]
                    charDataValue3[9] = charDataValue3[10]
                    charDataValue3[10] = charDataValue3[11]
                    charDataValue3[11] = charDataValue3[12]
                    charDataValue3[12] = charDataValue3[13]
                    charDataValue3[13] = charDataValue3[14]
                    charDataValue3[14] = charDataValue3[15]
                    charDataValue3[15] = charDataValue3[16]
                    charDataValue3[16] = charDataValue3[17]
                    charDataValue3[17] = charDataValue3[18]
                    charDataValue3[18] = charDataValue3[19]
                    charDataValue3[19] = charDataValue3[20]
                    charDataValue3[20] = charDataValue3[21]
                    charDataValue3[21] = charDataValue3[22]
                    charDataValue3[22] = charDataValue3[23]
                    charDataValue3[23] = charDataValue3[24]
                    charDataValue3[24] = charDataValue3[25]
                    charDataValue3[25] = charDataValue3[26]
                    charDataValue3[26] = charDataValue3[27]
                    charDataValue3[27] = charDataValue3[28]
                    charDataValue3[28] = charDataValue3[29]
                    charDataValue3[29] = parseFloat(array[i].value)

                  }
                }
              }

              dataJson[0] = dataJson[1]
              dataJson[1] = dataJson[2]
              dataJson[2] = dataJson[3]
              dataJson[3] = dataJson[4]
              dataJson[4] = dataJson[5]
              dataJson[5] = dataJson[6]
              dataJson[6] = dataJson[7]
              dataJson[7] = dataJson[8]
              dataJson[8] = `Sensor:${idSensor}, Contract:${idTrackingContract}, Container:${idContainer}, Device:${idCaller}, Valor:${valorSensor}, Unit:${unitMeasurement}, Latitude:${latitudDataSensor}, Longitude:${longituDataSensor}`

              const dataJsonX = `{"data": [
                      {"json": "${dataJson[0]}"},
                      {"json": "${dataJson[1]}"},
                      {"json": "${dataJson[2]}"},
                      {"json": "${dataJson[3]}"},
                      {"json": "${dataJson[4]}"},
                      {"json": "${dataJson[5]}"},
                      {"json": "${dataJson[6]}"},
                      {"json": "${dataJson[7]}"},
                      {"json": "${dataJson[8]}"}
                 ]}`

              //-----------------------

              const smallStats = `[
                      {
                        "label": "${idTypeSensorTemperature}",
                        "value": "${valorTemperature}${unitTemperature}",
                        "percentage": "${variationTemperature.toFixed(2)}%",
                        "increase": ${decreaseTemperature},
                        "decrease": ${increaseTemperature},
                        "chartLabels": [null, null, null, null, null, null, null],
                        "attrs": { "md": "6", "sm": "6" },
                        "datasets": [
                          {
                            "label": "Today",
                            "fill": "start",
                            "borderWidth": 1.5,
                            "backgroundColor": "rgba(0, 184, 216, 0.1)",
                            "borderColor": "rgb(0, 184, 216)",
                            "data": [${dataTemperature[0]}, ${dataTemperature[1]}, ${dataTemperature[2]}, ${dataTemperature[3]}, ${dataTemperature[4]}, ${dataTemperature[5]}, ${dataTemperature[6]}]
                          }
                        ]
                      },
                      {
                        "label": "${idTypeSensorHumidity}",
                        "value": "${valorHumidity}${unitHumidity}",
                        "percentage": "${variationHumidity.toFixed(2)}%",
                        "increase": ${increaseHumidity},
                        "decrease": ${decreaseHumidity},
                        "chartLabels": [null, null, null, null, null, null, null],
                        "attrs": { "md": "6", "sm": "6" },
                        "datasets": [
                          {
                            "label": "Today",
                            "fill": "start",
                            "borderWidth": 1.5,
                            "backgroundColor": "rgba(23,198,113,0.1)",
                            "borderColor": "rgb(23,198,113)",
                            "data": [${dataHumidity[0]}, ${dataHumidity[1]}, ${dataHumidity[2]}, ${dataHumidity[3]}, ${dataHumidity[4]}, ${dataHumidity[5]}, ${dataHumidity[6]}]
                          }
                        ]
                      },
                      {
                        "label": "${idTypeSensorGasControl}",
                        "value": "${valorGasControl}${unitGasControl}",
                        "percentage": "${variationGasControl.toFixed(2)}%",
                        "increase": ${increaseGasControl},
                        "decrease": ${decreaseGasControl},
                        "chartLabels": [null, null, null, null, null, null, null],
                        "attrs": { "md": "4", "sm": "6" },
                        "datasets": [
                          {
                            "label": "Today",
                            "fill": "start",
                            "borderWidth": 1.5,
                            "backgroundColor": "rgba(255,180,0,0.1)",
                            "borderColor": "rgb(255,180,0)",
                            "data": [${dataGasControl[0]}, ${dataGasControl[1]}, ${dataGasControl[2]}, ${dataGasControl[3]}, ${dataGasControl[4]}, ${dataGasControl[5]}, ${dataGasControl[6]}]
                          }
                        ]
                      },
                      {
                       "label": "Within Range",
                        "value": "${countGoodTemperature+countGoodHumidity+countGoodGascontrol}",
                        "percentage": "",
                        "increase": false,
                        "decrease": false,
                        "chartLabels": [null, null, null, null, null, null, null],
                        "attrs": { "md": "4", "sm": "6" },
                        "datasets": [
                          {
                            "label": "Today",
                            "fill": "start",
                            "borderWidth": 1.5,
                            "backgroundColor": "rgba(255,65,105,0.1)",
                            "borderColor": "rgb(255,65,105)",
                            "data": [0, 0, 0, 0, 0, 0, 0]
                          }
                        ]
                      },
                      {
                        "label": "Out of Range",
                        "value": "${countBadTemperature+countBadHumidity+countBadGascontrol}",
                        "percentage": "",
                        "increase": false,
                        "decrease": false,
                        "chartLabels": [null, null, null, null, null, null, null],
                        "attrs": { "md": "4", "sm": "6" },
                        "datasets": [
                          {
                            "label": "Today",
                            "fill": "start",
                            "borderWidth": 1.5,
                            "backgroundColor": "rgb(0,123,255,0.1)",
                            "borderColor": "rgb(0,123,255)",
                            "data": [0, 0, 0, 0, 0, 0, 0]
                          }
                        ]
                      }
                    ]`

                  const referralData = `{"title": "Number data imported from the Sensors",
                    "referralData": [
                      {
                        "title": "${idTypeSensorTemperature}",
                        "value": "${countTemperature}"
                      },
                      {
                        "title": "${idTypeSensorHumidity}",
                        "value": "${countHumidity}"
                      },
                      {
                        "title": "${idTypeSensorGasControl}",
                        "value": "${countGasControl}"
                      },
                      {
                        "title": "Impact",
                        "value": "-"
                      },
                      {
                        "title": "Vibration",
                        "value": "-"
                      },
                      {
                        "title": "Proximity",
                        "value": "-"
                      },
                      {
                        "title": "Seal Integrity",
                        "value": "-"
                      },
                      {
                        "title": "GPS",
                        "value": "${countTemperature+countHumidity+countGasControl}"
                      }
                  ]}`

                  const chartData = `{
                    "title": "Graph Data Received",
                    "chartData": {
                      "labels": "Array.from(new Array(30), (_, i) => (i === 0 ? 1 : i))",
                      "datasets": [
                        {
                          "label": "Temperature",
                          "fill": "start",
                          "data": [
                            ${charDataValue[0]},
                            ${charDataValue[1]},
                            ${charDataValue[2]},
                            ${charDataValue[3]},
                            ${charDataValue[4]},
                            ${charDataValue[5]},
                            ${charDataValue[6]},
                            ${charDataValue[7]},
                            ${charDataValue[8]},
                            ${charDataValue[9]},
                            ${charDataValue[10]},
                            ${charDataValue[11]},
                            ${charDataValue[12]},
                            ${charDataValue[13]},
                            ${charDataValue[14]},
                            ${charDataValue[15]},
                            ${charDataValue[16]},
                            ${charDataValue[17]},
                            ${charDataValue[18]},
                            ${charDataValue[19]},
                            ${charDataValue[20]},
                            ${charDataValue[21]},
                            ${charDataValue[22]},
                            ${charDataValue[23]},
                            ${charDataValue[24]},
                            ${charDataValue[25]},
                            ${charDataValue[26]},
                            ${charDataValue[27]},
                            ${charDataValue[28]},
                            ${charDataValue[29]}
                          ],
                          "backgroundColor": "rgba(0,123,255,0.1)",
                          "borderColor": "rgba(0,123,255,1)",
                          "pointBackgroundColor": "#ffffff",
                          "pointHoverBackgroundColor": "rgb(0,123,255)",
                          "borderWidth": 1.5,
                          "pointRadius": 0,
                          "pointHoverRadius": 3
                        },
                        {
                          "label": "Humidity",
                          "fill": "start",
                          "data": [
                            ${charDataValue2[0]},
                            ${charDataValue2[1]},
                            ${charDataValue2[2]},
                            ${charDataValue2[3]},
                            ${charDataValue2[4]},
                            ${charDataValue2[5]},
                            ${charDataValue2[6]},
                            ${charDataValue2[7]},
                            ${charDataValue2[8]},
                            ${charDataValue2[9]},
                            ${charDataValue2[10]},
                            ${charDataValue2[11]},
                            ${charDataValue2[12]},
                            ${charDataValue2[13]},
                            ${charDataValue2[14]},
                            ${charDataValue2[15]},
                            ${charDataValue2[16]},
                            ${charDataValue2[17]},
                            ${charDataValue2[18]},
                            ${charDataValue2[19]},
                            ${charDataValue2[20]},
                            ${charDataValue2[21]},
                            ${charDataValue2[22]},
                            ${charDataValue2[23]},
                            ${charDataValue2[24]},
                            ${charDataValue2[25]},
                            ${charDataValue2[26]},
                            ${charDataValue2[27]},
                            ${charDataValue2[28]},
                            ${charDataValue2[29]}
                          ],
                          "backgroundColor": "rgba(255,65,105,0.1)",
                          "borderColor": "rgba(255,65,105,1)",
                          "pointBackgroundColor": "#ffffff",
                          "pointHoverBackgroundColor": "rgba(255,65,105,1)",
                          "borderDash": [3, 3],
                          "borderWidth": 1,
                          "pointRadius": 0,
                          "pointHoverRadius": 2,
                          "pointBorderColor": "rgba(255,65,105,1)"
                        }
                      ]}
                    }`

                 const verificationRules = `{
                      "title": "Verification of Rules",
                      "chartData": {
                        "datasets": [
                          {
                            "hoverBorderColor": "#ffffff",
                            "data": [${countGoodTemperature+countGoodHumidity+countGoodGascontrol}, ${countBadTemperature+countBadHumidity+countBadGascontrol}],
                            "backgroundColor": [
                              "rgba(0,123,255,0.9)",
                              "rgba(0,123,255,0.5)"
                            ]
                          }
                        ],
                        "labels": ["Within Range", "Out of Range"]
                      }
                  }`

                 const dataCustomActiveShapePieChart = `[
                    { "name": "Within Range", "value": ${countGoodTemperature+countGoodHumidity+countGoodGascontrol} },
                    { "name": "Out of Range", "value": ${countBadTemperature+countBadHumidity+countBadGascontrol} }
                 ]`;

                 if (countBadTemperature+countBadHumidity+countBadGascontrol===0){
                   this.setState({colorCustomActiveShapePieChart:"#03E1BC"})
                 }else{
                    if (countBadTemperature+countBadHumidity+countBadGascontrol>=1 && countBadTemperature+countBadHumidity+countBadGascontrol<=5){
                      this.setState({colorCustomActiveShapePieChart:"#FF9F00"})
                    }else{
                      if (countBadTemperature+countBadHumidity+countBadGascontrol>=6 && countBadTemperature+countBadHumidity+countBadGascontrol<=10){
                        this.setState({colorCustomActiveShapePieChart:"#FF3D00"})
                      }else{
                        if (countBadTemperature+countBadHumidity+countBadGascontrol>=11 && countBadTemperature+countBadHumidity+countBadGascontrol<=15){
                          this.setState({colorCustomActiveShapePieChart:"#E50505"})
                        }else{
                          if (countBadTemperature+countBadHumidity+countBadGascontrol>=16){
                            this.setState({colorCustomActiveShapePieChart:"#700000"})
                          }
                        }
                      }
                    }
                 }

                 this.setState({ smallStats2: JSON.parse(smallStats) })
                 this.setState({ referralData: JSON.parse(referralData) })
                 this.setState({ chartData: JSON.parse(chartData) })
                 this.setState({ dataJsonX: JSON.parse(dataJsonX) })
                 this.setState({ verificationRules: JSON.parse(verificationRules) })
                 this.setState({ dataCustomActiveShapePieChart: JSON.parse(dataCustomActiveShapePieChart) })

                  // enviar a PACT
                  let statusRule = ''
                  if (idSensor === "1") {
                    //this.props.saveNewPactDataSensorTemperature(idPact,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor)
                    if (statusRuleTemperatureLower===true){
                           statusRule = 'Bad_Lower'
                    }else{
                      if (statusRuleTemperatureUpper===true){
                           statusRule = 'Bad_Upper'
                      }
                    }
                  }else{
                    if (idSensor === "2") {
                        //this.props.saveNewPactDataSensorHumidity(idPact,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor)
                        if (statusRuleHumidityLower===true){
                               statusRule = 'Bad_Lower'
                        }else{
                          if (statusRuleHumidityUpper===true){
                               statusRule = 'Bad_Upper'
                          }
                        }
                    }else{
                      if (idSensor === "3") {
                          //this.props.saveNewPactDataSensorGascontrol(idPact,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor)
                          if (statusRuleGasControlLower===true){
                                 statusRule = 'Bad_Lower'
                          }else{
                            if (statusRuleGasControlUpper===true){
                                 statusRule = 'Bad_Upper'
                            }
                          }
                      }
                    }
                  }
                  if (statusRuleTemperatureLower===true || statusRuleTemperatureUpper===true ||
                      statusRuleHumidityLower===true || statusRuleHumidityUpper===true ||
                      statusRuleGasControlLower===true || statusRuleGasControlUpper===true ){
                     this.props.saveNewPactDataSensor(idPact,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor,statusRule)
                  }
                  //Retorna estado desde PACT

                  // enviar a Blockstack
                  this.putDataSensor(jsonDataSensorX,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor,idPact,unitMeasurement)

          }, i * delay);
        })
     }

     arrayPlusDelay2(array, delay, contractNumber) {
       var i = 0
       function loop() {
           //console.log(array[i]);
           if (i++ < array.length - 1)
               setTimeout(loop, delay);
       }
       setTimeout(loop, delay);
     }

     arrayPlusDelay3(array, delay, contractNumber) {
       var i = 0
       var interval = setInterval(function() {
           //console.log(array[i]);
           if (i++ >= array.length - 1)
               clearInterval(interval);
       }, delay)
      return interval
     }

     putDataSensor(jsonDataSensorX,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor,idPact,unitMeasurement) {
       const { userSession } = this.state
       const palabra = idTrackingContract
       //-------------------------------------------
       // enviar a Blockstack (Cloud Storage)
       //-------------------------------------------
       // Crear el objeto
       let infoContractIoTDevice =''

       const jsonBlockstack1 = JSON.stringify(jsonDataSensorX)
       const jsonBlockstack2 = jsonBlockstack1.substring(1,jsonBlockstack1.length - 1)
       if (jsonBlockstack2 === '') {
          infoContractIoTDevice =`[{"idSensor":"${idSensor}","idTrackingContract":"${idTrackingContract}","idTrackingContract":"${idTrackingContract}","idContainer":"${idContainer}","idBillOfLading":"${idBillOfLading}","idCaller":"${idCaller}","idCategory":"${idCategory}","idTypeSensor":"${idTypeSensor}","valorSensor":"${valorSensor}","unitMeasurement":"${unitMeasurement}","dateDataSensor":"${dateDataSensor}","latitudDataSensor":"${latitudDataSensor}","longituDataSensor":"${longituDataSensor}","idPact":"${idPact}"}]`
       }else{
          infoContractIoTDevice = `[${jsonBlockstack2},{"idSensor":"${idSensor}","idTrackingContract":"${idTrackingContract}","idTrackingContract":"${idTrackingContract}","idContainer":"${idContainer}","idBillOfLading":"${idBillOfLading}","idCaller":"${idCaller}","idCategory":"${idCategory}","idTypeSensor":"${idTypeSensor}","valorSensor":"${valorSensor}","unitMeasurement":"${unitMeasurement}","dateDataSensor":"${dateDataSensor}","latitudDataSensor":"${latitudDataSensor}","longituDataSensor":"${longituDataSensor}","idPact":"${idPact}"}]`
       }

       const options = { encrypt: false }
       putFileDB(this.state.username, `data_sensor_${palabra}.json`, 'Crosscheck_Dashboard', JSON.stringify(infoContractIoTDevice), JSON.stringify(options))
         .then(() => {
            this.setState({
               status: true
            })
            //console.log(`Grabación Exitosa Data Sensor (data_sensor_${palabra}.json`)
         })
     }

     messageError() {
        if (!this.state.existContractIoTDevice){
          return (
              <React.Fragment>
                <br></br>
                <Row form>
                  <Col md="12">
                      <Card theme="secondary" style={{ color: "white" }}>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <div className="fa fa-info-circle mx-2"></div>
                              </td>
                              <td>
                                Contract without IoT Devices &nbsp;&nbsp;&nbsp;
                              </td>
                              <td>
                                &nbsp;&nbsp;
                                <Button pill theme="light" onClick={() => this.setState({ existContractIoTDevice: true })}>Understood</Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Card>
                  </Col>
                </Row>
              </React.Fragment>
          )
        }
      }

      messageError2() {
         if (this.state.errorMensaje2){
           return (
               <React.Fragment>

                 <br></br>
                 <Row form>
                   <Col md="12">
                       <Card theme="secondary" style={{ color: "white" }}>
                         <table>
                           <tbody>
                             <tr>
                               <td>
                                 <div className="fa fa-info-circle mx-2"></div>
                               </td>
                               <td>
                                 Inconsistent Data: Contract Detail not found &nbsp;&nbsp;&nbsp;
                               </td>
                               <td>
                                 &nbsp;&nbsp;
                                 <Button pill theme="light" onClick={() => this.setState({ errorMensaje2: false })}>Understood</Button>
                               </td>
                             </tr>
                           </tbody>
                         </table>
                       </Card>
                   </Col>
                 </Row>
               </React.Fragment>
           )
         }
       }

       messageError3() {
          if (this.state.errorMensaje3){
            return (
                <React.Fragment>
                  <br></br>
                  <Row form>
                    <Col md="12">
                        <Card theme="secondary" style={{ color: "white" }}>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="fa fa-info-circle mx-2"></div>
                                </td>
                                <td>
                                  Inconsistent Data: Master File Sensors not found &nbsp;&nbsp;&nbsp;
                                </td>
                                <td>
                                  &nbsp;&nbsp;
                                  <Button pill theme="light" onClick={() => this.setState({ errorMensaje3: false })}>Understood</Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Card>
                    </Col>
                  </Row>
                </React.Fragment>
            )
          }
        }

        messageError4() {
           if (this.state.errorMensaje4){
             return (
                 <React.Fragment>
                   <br></br>
                   <Row form>
                     <Col md="12">
                         <Card theme="secondary" style={{ color: "white" }}>
                           <table>
                             <tbody>
                               <tr>
                                 <td>
                                   <div className="fa fa-info-circle mx-2"></div>
                                 </td>
                                 <td>
                                   Inconsistent Data: Master File Devices not found &nbsp;&nbsp;&nbsp;
                                 </td>
                                 <td>
                                   &nbsp;&nbsp;
                                   <Button pill theme="light" onClick={() => this.setState({ errorMensaje4: false })}>Understood</Button>
                                 </td>
                               </tr>
                             </tbody>
                           </table>
                         </Card>
                     </Col>
                   </Row>
                 </React.Fragment>
             )
           }
       }

       messageError5() {
          if (this.state.errorMensaje5){
            return (
                <React.Fragment>
                  <br></br>
                  <Row form>
                    <Col md="12">
                        <Card theme="secondary" style={{ color: "white" }}>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="fa fa-info-circle mx-2"></div>
                                </td>
                                <td>
                                  Error: API Server Data sensor not found &nbsp;&nbsp;&nbsp;
                                </td>
                                <td>
                                  &nbsp;&nbsp;
                                  <Button pill theme="light" onClick={() => this.setState({ errorMensaje5: false })}>Understood</Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Card>
                    </Col>
                  </Row>
                </React.Fragment>
            )
          }
      }
     //getDataSensor() {
     //  this.props.simulateDatossensores()
     //  const {sensores} = this.props;
     //}

     onPieEnter = (data, index) => {
       this.setState({
         activeIndex: index,
       });
     };

     render() {

          const {smallStats2} = this.state
          //const {chartData,title} = this.state.verificationRules
          const {dataCustomActiveShapePieChart} = this.state

          let jsonListContract=[]
          if (this.state.selectedSearch === "switchPACT") {
               jsonListContract = this.props.smartcontract;
          } else {
             if (this.state.selectedSearch === "switchBlockstack") {
               jsonListContract = this.state.jsonListContract;
             }
          }

          const renderActiveShape = (props) => {
            const RADIAN = Math.PI / 180;
            const {
              cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
              fill, payload, percent, value,
            } = props;
            const sin = Math.sin(-RADIAN * midAngle);
            const cos = Math.cos(-RADIAN * midAngle);
            const sx = cx + (outerRadius + 10) * cos;
            const sy = cy + (outerRadius + 10) * sin;
            const mx = cx + (outerRadius + 30) * cos;
            const my = cy + (outerRadius + 30) * sin;
            const ex = mx + (cos >= 0 ? 1 : -1) * 22;
            const ey = my;
            const textAnchor = cos >= 0 ? 'start' : 'end';

            return (
              <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                <Sector
                  cx={cx}
                  cy={cy}
                  innerRadius={innerRadius}
                  outerRadius={outerRadius}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  fill={fill}
                />
                <Sector
                  cx={cx}
                  cy={cy}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  innerRadius={outerRadius + 6}
                  outerRadius={outerRadius + 10}
                  fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Count ${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                  {`(Rate ${(percent * 100).toFixed(2)}%)`}
                </text>
              </g>
            );
          };


          return (
              <div>
                <DatosCryptoCurrency />
                <Container fluid className="main-content-container px-4">
                  {/* Page Header */}
                  <Row noGutters className="page-header py-4">
                    <TituloPagina title="Data Sensors" subtitle="Dashboard" className="text-sm-left mb-3" />
                  </Row>
                  <Card small>
                    <Row>
                      <Col>
                        <Form style={{fontSize:15}}>
                          <Row form>
                            <Col md="0"></Col>

                            <Col md="2">
                              <label style={{fontSize:11}}>&nbsp;&nbsp;Origin (*)</label>
                              <InputGroupAddon type="prepend">
                                <InputGroupText>
                                  <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                  <FormRadio
                                    name="optSearch"
                                    checked={this.state.selectedSearch === "switchPACT"}
                                    disabled={true}
                                    onChange={() => {
                                      this.handleChangeOption("switchPACT");
                                    }}
                                  >
                                    PACT
                                  </FormRadio>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <FormRadio
                                    name="optSearch"
                                    checked={this.state.selectedSearch === "switchBlockstack"}
                                    disabled={true}
                                    onChange={() => {
                                      this.handleChangeOption("switchBlockstack");
                                    }}
                                  >
                                    Blockstack
                                  </FormRadio>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </InputGroupText>
                              </InputGroupAddon>
                            </Col>
                            <Col md="1"></Col>
                            <Col md="3">
                              <label htmlFor="TypeContractIoTDevice" style={{fontSize:11}}>&nbsp;&nbsp;Tracking Contract (*)</label>
                              <FormSelect
                                  id="TypeContractIoTDevice"
                                  innerRef={this.c01Dashboard}
                                  placeholder="Select an Option"
                                  onChange={this.goDashboard}
                              >
                              <option value="SelectOptionDashboard">Select an Option</option>
                              {jsonListContract.map((todo, i) => (
                                  <option key={i} value={todo.configurationContractNumber}>{todo.configurationContractNumber}</option>
                              ))}
                              </FormSelect>
                            </Col>
                            <Col md="4">
                                <label>&nbsp;</label>
                                {this.state.showLoader ? <Loader /> : null }
                                {this.messageError()}
                                {this.messageError2()}
                                {this.messageError3()}
                                {this.messageError4()}
                                {this.messageError5()}
                            </Col>
                          </Row>
                          <Row>&nbsp;</Row>
                        </Form>
                      </Col>
                    </Row>
                  </Card>
                  {this.state.displayDashboard ?
                  <div>
                      <Row>&nbsp;</Row>
                      <Row>
                        {smallStats2.map((stats, idx) => (
                          <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
                            <SmallStats
                              id={`small-stats-${idx}`}
                              variation="1"
                              chartData={stats.datasets}
                              chartLabels={stats.chartLabels}
                              label={stats.label}
                              value={stats.value}
                              percentage={stats.percentage}
                              increase={stats.increase}
                              decrease={stats.decrease}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                          {/* Users Overview */}
                          <Col lg="8" md="12" sm="12" className="mb-4">
                            <DetailDataReceived dataJson={this.state.dataJsonX} />
                          </Col>

                          {/* Users by Device */}
                          <Col lg="4" md="6" sm="12" className="mb-2">
                            {/*chartData={this.state.verificationRules}*/}
                            {/*<UsersByDevice chartData={chartData} title={title} />*/}
                            <Card small>
                              <PieChart width={500} height={400}>
                                <Pie
                                  activeIndex={this.state.activeIndex}
                                  activeShape={renderActiveShape}
                                  data={dataCustomActiveShapePieChart}
                                  cx={220}
                                  cy={200}
                                  innerRadius={70}
                                  outerRadius={90}
                                  fill={this.state.colorCustomActiveShapePieChart}
                                  dataKey="value"
                                  onMouseEnter={this.onPieEnter}
                                />
                              </PieChart>
                            </Card>
                          </Col>

                          {/* New Draft */}
                          <Col lg="4" md="6" sm="12" className="mb-4">
                            <NewDraft />
                          </Col>

                          {/* Discussions */}
                          <Col lg="5" md="12" sm="12" className="mb-4">
                            <Discussions />
                          </Col>

                          {/* Top Referrals */}
                          <Col lg="3" md="12" sm="12" className="mb-4">
                            <TopReferrals datasensor={this.state.referralData} />
                          </Col>
                      </Row>
                    </div>
                  : null }
                </Container>
              </div>
          );
    }
}

Dashboard.propTypes = {
    simulateDatossensores : PropTypes.func.isRequired,
    saveNewPactDataSensorTemperature: PropTypes.func.isRequired,
    saveNewPactDataSensor: PropTypes.func.isRequired,
    saveNewPactDataSensorHumidity: PropTypes.func.isRequired,
    saveNewPactDataSensorGascontrol: PropTypes.func.isRequired,
    fetchPactLogisticConfig: PropTypes.func.isRequired,
    smallStats: PropTypes.array
}

// state
const mapStateToProps = state => ({
     sensores: state.sensores.sensores,
     smartcontract: state.smartcontract.smartcontract,
     datasensorx: state.smartcontract.datasensorx
})

const mapDispatchToProps = dispatch => {
  return {
    simulateDatossensores: simulateDatossensores,
    fetchPactLogisticConfig: () => {dispatch(fetchPactLogisticConfig());},
    saveNewPactDataSensorTemperature: (idPact,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor) => {
        dispatch(saveNewPactDataSensorTemperature(idPact,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor));},
    saveNewPactDataSensorHumidity: (idPact,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor) => {
        dispatch(saveNewPactDataSensorHumidity(idPact,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor));},
    saveNewPactDataSensorGascontrol: (idPact,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor) => {
        dispatch(saveNewPactDataSensorGascontrol(idPact,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor));},
        saveNewPactDataSensor: (idPact,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor,statusRule) => {
            dispatch(saveNewPactDataSensor(idPact,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor,statusRule));},
  };
};
export default ReactTimeout(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
