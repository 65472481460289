import React, { Component } from 'react';
import { i18n, Spreadsheet as SpreadsheetBase } from "dhx-spreadsheet";
import "../../styles/spreadsheet.css";

class Spreadsheet extends Component {

  componentDidMount() {
    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English',mycontact: 'My Contacts',mytag: 'My Tags',newcontract: 'Select here for new contract' })
    }else{
      this.setState({ language: languageX })
    }
    let isocode = {}
    if (languageX === 'English'){
       isocode = { undo: "Undo", redo: "Redo", textColor: "Text Color", backgroundColor: "Background Color", bold: "Bold", italic: "Italic",
                   lockCell: "Lock Cell", unlockCell: "Unlock Cell", format: "Format", edit: "Edit", clear: "Clear",
                   clearValue: "Clear Value", clearStyles: "Clear Styles", clearAll: "Clear All", insert: "Insert", columns: "Columns",
                   rows: "Rows", addColumn: "Add Column", removeColumn: "Remove Column", addRow: "Add Row", removeRow: "Remove Row",
                   configuration: "Configuration", underline: "Underline", align: "Align", left: "Left", right: "Right",
                   center: "Center", help: "Help",  common: "Common",  number: "Number", currency: "Currency", percent: "Percent",
                   downloadAs: "Download As...", importAs: "Import As...", import: "Import", export: "Export", file: "File", numberFormat: "Format Number"
       };
    }
    if (languageX === 'French'){
       isocode = { undo: "Annuler", redo: "Refaire", textColor: "Couleur du texte", backgroundColor: "Couleur de fond", bold: "Audacieux", italic: "Italique",
                   lockCell: "Verrouiller la cellule", unlockCell: "Déverrouiller la cellule", format: "Format", edit: "Éditer", clear: "Clair",
                   clearValue: "Effacer la valeur", clearStyles: "Styles clairs", clearAll: "Tout Nettoyer", insert: "Insérer", columns: "Colonnes",
                   rows: "Rangées", addColumn: "Ajouter une Colonne", removeColumn: "Supprimer la Colonne", addRow: "Ajouter une Ligne", removeRow: "Supprimer la Ligne",
                   configuration: "Configuration", underline: "Souligné", align: "Aligner", left: "Gauche", right: "Droite",
                   center: "Centre", help: "Aide",  common: "Commun",  number: "Le numéro", currency: "Devise", percent: "Pourcentage",
                   downloadAs: "Télécharger en tant que...", importAs: "Importer en tant que...", import: "Importer", export: "Télécharger", file: "Archiver", numberFormat: "Format Numéro"
       };
    }
    if (languageX === 'Spanish'){
       isocode = { undo: "Deshacer", redo: "Rehacer", textColor: "Color del Texto", backgroundColor: "Color de Fondo", bold: "Negrita", italic: "Cursiva",
                   lockCell: "Bloquear la celda", unlockCell: "Desbloquear la celda", format: "Formato", edit: "Editar", clear: "Borrar",
                   clearValue: "Limpiar Valor", clearStyles: "Limpiar Estilos", clearAll: "Limpiar Todo", insert: "Insertar", columns: "Columnas",
                   rows: "Filas", addColumn: "Agregar Columna", removeColumn: "Remover Columna", addRow: "Agregar Fila", removeRow: "Remover Fila",
                   configuration: "Configuración", underline: "Subrayado", align: "Alinear", left: "Izquierda", right: "Derecha",
                   center: "Centro", help: "Ayuda",  common: "Común",  number: "Número", currency: "Moneda", percent: "Porcentaje",
                   downloadAs: "Descargar como...", importAs: "Importar como...", import: "Importar", export: "Exportar", file: "Archivo", numberFormat: "Formato Número"
       };
    }
    if (languageX === 'Portuguese'){
       isocode = { undo: "Desfazer", redo: "Refazer", textColor: "Cor do Texto", backgroundColor: "Cor de Fundo", bold: "Negrito", italic: "Itálico",
                   lockCell: "Bloquear Célula", unlockCell: "Desbloquear Célula", format: "Formatar", edit: "Editar", clear: "Excluir",
                   clearValue: "Limpar Valor", clearStyles: "Limpar Estilos", clearAll: "Limpar Tudo", insert: "Inserir", columns: "Colunas",
                   rows: "Fileiras", addColumn: "Adicionar Coluna", removeColumn: "Remover Coluna", addRow: "Adicionar Fileira", removeRow: "Remover Fileira",
                   configuration: "Configuração", underline: "Sublinhado", align: "Alinhar", left: "Esquerda", right: "Direita",
                   center: "Centro", help: "Ajuda",  common: "Comum",  number: "Número", currency: "Moeda", percent: "Por Cento",
                   downloadAs: "Transferir como...", importAs: "Importar como...", import: "Importar", export: "Exportar", file: "Arquivo", numberFormat: "Formato Numérico"
       };
    }
    if (languageX === 'Swedish'){
        isocode = { undo: "Ångra", redo: "Göra om", textColor: "Text färg", backgroundColor: "Bakgrundsfärg", bold: "Djärv", italic: "Kursiv",
                    lockCell: "Lås cell", unlockCell: "Lås upp cell", format: "Formatera", edit: "Redigera", clear: "Klar",
                    clearValue: "Rensa värde", clearStyles: "Rensa stilar", clearAll: "Rensa alla", insert: "Föra in", columns: "Kolumner",
                    rows: "Rader", addColumn: "Lägg till kolumn", removeColumn: "Ta bort kolumn", addRow: "Lägg till rad", removeRow: "Ta bort raden",
                    configuration: "Konfiguration", underline: "Understrukna ", align: "Justera", left: "Vänster", right: "Rätt",
                    center: "Centrum", help: "Hjälp",  common: "Allmänning",  number: "Nummer", currency: "Valuta", percent: "Procent",
                    downloadAs: "Ladda ner som...", importAs: "Importera som...", import: "Importera", export: "Exportera", file: "Fil", numberFormat: "Formatnummer"
        };
    }
    if (languageX === 'Netherlands'){
        isocode = { undo: "Ongedaan maken", redo: "Opnieuw doen", textColor: "Tekst kleur", backgroundColor: "Achtergrond kleur", bold: "Stoutmoedig", italic: "Cursief",
                    lockCell: "Cel vergrendelen", unlockCell: "Ontgrendel cel", format: "Formaat", edit: "Bewerk", clear: "Doorzichtig",
                    clearValue: "Duidelijke waarde", clearStyles: "Duidelijke stijlen", clearAll: "Wis alles", insert: "Invoegen", columns: "Kolommen",
                    rows: "Rijen", addColumn: "Kolom toevoegen", removeColumn: "Kolom verwijderen", addRow: "Voeg een rij toe", removeRow: "Rij verwijderen",
                    configuration: "Configuratie", underline: "Onderstreept", align: "Uitlijnen", left: "Links", right: "Rechtsaf",
                    center: "Centrum", help: "Helpen",  common: "Gemeenschappelijk",  number: "Aantal", currency: "Valuta", percent: "Procent",
                    downloadAs: "Download als...", importAs: "Importeren als...", import: "Importeren", export: "Exporteren", file: "Het dossier", numberFormat: "Formaatnummer"
        };
    }
    if (languageX === 'Russian'){
        isocode = { undo: "Отменить", redo: "Применить", textColor: "Цвет текста", backgroundColor: "Цвет фона", bold: "Жирный", italic: "Курсив",
                    lockCell: "Блокировать ячейку", unlockCell: "Разблокировать ячейку", format: "Формат", edit: "Редактировать", clear: "Очистить",
                    clearValue: "Очистить значение", clearStyles: "Очистить стили", clearAll: "Очистить все", insert: "Вставка", columns: "Столбцы",
                    rows: "Строки", addColumn: "Добавить столбец", removeColumn: "Удалить столбец", addRow: "Добавить строку", removeRow: "Удалить строку",
                    configuration: "Конфигурация", underline: "Подчеркнутый", align: "Выровнять", left: "по левому краю", right: "по правому краю",
                    center: "по центру", help: "Помощь",  common: "общий",  number: "числовой", currency: "денежный", percent: "процентный",
                    downloadAs: "Скачать как...", importAs: "Импортировать как...", import: "Импорт", export: "Экспорт", file: "Файл", numberFormat: "Числовой формат"
        };
    }
    if (languageX === 'Japanese'){
        isocode = { undo: "元に戻す", redo: "やり直し", textColor: "テキストの色", backgroundColor: "背景色", bold: "大胆な", italic: "イタリック",
                    lockCell: "ロックセル", unlockCell: "セルのロック解除", format: "フォーマット", edit: "編集", clear: "晴れ",
                    clearValue: "値をクリア", clearStyles: "クリアスタイル", clearAll: "すべてクリア", insert: "インサート", columns: "列",
                    rows: "行", addColumn: "列を追加", removeColumn: "列を削除", addRow: "行を追加する", removeRow: "行を削除",
                    configuration: "構成", underline: "下線", align: "整列", left: "左", right: "正しい",
                    center: "センター", help: "助けて",  common: "一般",  number: "数", currency: "通貨", percent: "パーセント",
                    downloadAs: "としてダウンロード...", importAs: "名前を付けてインポート...", import: "インポート", export: "書き出す", file: "ファイル", numberFormat: "数値形式"
        };
    }
    if (languageX === 'Chinese'){
        isocode = { undo: "撤消", redo: "重做", textColor: "文字顏色", backgroundColor: "背景顏色", bold: "膽大", italic: "斜體",
                    lockCell: "鎖芯", unlockCell: "解鎖單元", format: "格式", edit: "編輯", clear: "明確",
                    clearValue: "明確的價值", clearStyles: "清晰的風格", clearAll: "全部清除", insert: "插入", columns: "列",
                    rows: "行數", addColumn: "添加欄", removeColumn: "刪除列", addRow: "添加行", removeRow: "刪除行",
                    configuration: "組態", underline: "強調", align: "對齊", left: "剩下", right: "對啊",
                    center: "中央", help: "幫忙",  common: "共同",  number: "數", currency: "貨幣", percent: "百分",
                    downloadAs: "下載為...", importAs: "匯入為...", import: "進口", export: "出口", file: "文件", numberFormat: "數字格式"
        };
    }
    if (languageX === 'German'){
        isocode = { undo: "Rückgängig machen", redo: "Wiederholen", textColor: "Textfarbe", backgroundColor: "Hintergrundfarbe", bold: "Fett gedruckt", italic: "Kursiv",
                    lockCell: "Zelle sperren", unlockCell: "Zelle entsperren", format: "Format", edit: "Bearbeiten", clear: "Klar",
                    clearValue: "Wert löschen", clearStyles: "Stile löschen", clearAll: "Alles löschen", insert: "Einfügen", columns: "Säulen",
                    rows: "Reihen", addColumn: "Spalte hinzufügen", removeColumn: "Spalte entfernen", addRow: "Zeile hinzufügen", removeRow: "Zeile entfernen",
                    configuration: "Aufbau", underline: "Unterstreichen", align: "Ausrichten", left: "Links", right: "Richtig",
                    center: "Center", help: "Hilfe",  common: "Verbreitet",  number: "Nummer", currency: "Währung", percent: "Prozent",
                    downloadAs: "Herunterladen als...", importAs: "Importieren als...", import: "Importieren", export: "Export", file: "Datei", numberFormat: "Formatnummer"
        };
    }
    if (languageX === 'Italian'){
        isocode = { undo: "Disfare", redo: "Rifare", textColor: "Colore del Testo", backgroundColor: "Colore di Sfondo", bold: "Grassetto", italic: "Corsiva",
                    lockCell: "Blocca Cella", unlockCell: "Sblocca Cella", format: "Formato", edit: "Modificare", clear: "Chiara",
                    clearValue: "Chiara Valore", clearStyles: "Chiara Stili", clearAll: "Chiara Tutto", insert: "Inserire", columns: "Colonne",
                    rows: "Righe", addColumn: "Aggiungi Colonna", removeColumn: "Rimuovi Colonna", addRow: "Aggiungi Riga", removeRow: "Rimuovi Riga",
                    configuration: "Configurazione", underline: "Sottolineata", align: "Allineare", left: "Sinistra", right: "Destra",
                    center: "Centro", help: "Aiuto",  common: "Comune",  number: "Numero", currency: "Moneta", percent: "Per Cento",
                    downloadAs: "Scarica Come...", importAs: "Importa Come...", import: "Importare", export: "Esportare", file: "File", numberFormat: "Numero Formato"
        };
    }
    if (languageX !== 'English'){
       i18n.setLocale("spreadsheet", isocode)
    }
    require("excel2json-wasm")
    this.spreadsheet = new SpreadsheetBase(this.el, {
        menu: true,
        editLine: true,
        toolbarBlocks: [
            "undo", "colors", "decoration", "align", "lock", "clear",
            "rows", "columns", "help", "format", "file"
        ],
        rowsCount: 100,
        colsCount: 50,
        importModulePath: "../../utils/worker.js",
        exportModulePath: "../../utils/worker.js"
    })
  }

  componentWillUnmount() {
    this.spreadsheet.destructor();
  }

  render() {
    return (
      <div ref={el => this.el = el} className="widget-box" style={{ width: '100%', height: 400 }}></div>
    );
  }
}

export default Spreadsheet;
