import React, { Component } from 'react';
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  Link,
} from '@react-pdf/renderer';

//translate
import { FormattedMessage } from 'react-intl';

//base64
import { Base64 } from 'js-base64';

//Clarity
import {getTransactionRegister} from "../../clarity/clarityfunctions"

const styles = StyleSheet.create({
  body: {
    paddingTop: 65,
    paddingBottom: 65,
    paddingVertical: 1,
    paddingHorizontal: 35,
  },

  tittle: {
    fontSize: 14,
    textAlign: 'center',
    fontFamily: 'Oswald',
    color: '#909497',
  },

  description: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 30,
    color: '#909497',
  },

  subtitle: {
    fontSize: 12,
    margin: 12,
    fontFamily: 'Oswald',
  },

  text: {
    margin: 10,
    fontSize: 9,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },

  text2: {
    margin: 10,
    fontSize: 8,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },

  text3: {
    margin: 10,
    fontSize: 9,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },

  text5: {
    margin: 10,
    fontSize: 8,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },

  text6: {
    margin: 10,
    fontSize: 8,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
  },

  text7: {
    margin: 10,
    fontSize: 8,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
  },

  text8: {
    margin: 10,
    fontSize: 13,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },

  link: {
    position: 'absolute',
    bottom: 50,
    left: 0,
    right: 0,
    margin: 10,
    fontSize: 9,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    color: 'blue',
  },

  sign: {
    margin: 10,
    fontSize: 8,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
    lineHeight : '0.05px',
  },

  image: {
    marginVertical: 10,
    marginHorizontal: 220,
  },

  imagesign: {
    minWidth:60,
    maxWidth:100,
    minHeight:30,
    maxHeight:50,
    width:150,
    height:50
  },

  imagedigital: {
    minWidth:100,
    maxWidth:100,
    minHeight:100,
    maxHeight:100,
    width:100,
    height:100
  },

  header: {
    fontSize: 14,
    marginBottom: 20,
    textAlign: 'center',
    color: '#ECF0F1',
  },

  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  table: {
      width: '100%',
      fontSize: 8,
      textAlign: 'center',
    },
    rowtable: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: 'none',
      paddingTop: 2,
      paddingBottom: 2,
    },
    headertable: {
      borderTop: 'none',
      color: 'grey',
    },
    // So Declarative and unDRY 👌
    row1table: {
      width: '16%',
    },
    row2table: {
      width: '14%',
    },
    row3table: {
      width: '14%',
    },
    row4table: {
      width: '14%',
    },
    row5table: {
      width: '14%',
    },
    row6table: {
      width: '14%',
    },
    row7table: {
      width: '14%',
  },

  table2: {
      width: '100%',
      fontSize: 8,
      textAlign: 'center',
    },
    headertable2: {
      borderTop: 'none',
      color: 'grey',
    },
    rowtable2: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: 'none',
      paddingTop: 1,
      paddingBottom: 1,
    },
    row10table: {
      width: '50%',
    },
    row11table: {
      width: '50%',
  },

  table3: {
      width: '100%',
      fontSize: 8,
      textAlign: 'center',
    },
    headertable3: {
      borderTop: 'none',
      color: 'grey',
    },
    rowtable3: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: 'none',
      paddingTop: 1,
      paddingBottom: 4,
    },
    row101table: {
      width: '15%',
    },
    row102table: {
      width: '15%',
    },
    row103table: {
      width: '15%',
    },
    row104table: {
      width: '9%',
    },
    row105table: {
      width: '9%',
    },
    row106table: {
      width: '9%',
    },
    row107table: {
      width: '9%',
    },
    row108table: {
      width: '9%',
    },
    row109table: {
      width: '10%',
  },

  table4: {
      width: '100%',
      fontSize: 8,
      textAlign: 'center',
    },
    rowtable4: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 2,
      paddingBottom: 2,
    },
    rowtable4a: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      fontSize: 7,
      paddingTop: 2,
      paddingBottom: 2,
    },
    headertable4: {
      borderTop: '1px solid #EEE',
      color: 'grey',
    },
    // So Declarative and unDRY 👌
    row401table: {
      width: '7%',
      color: 'orange',
    },
    row401atable: {
      width: '7%',
      color: 'blue',
    },
    row401btable: {
      width: '7%',
    },
    row402table: {
      width: '13%',
    },
    row403table: {
      width: '7%',
    },
    row404table: {
      width: '7%',
    },
    row405table: {
      width: '7%',
    },
    row406table: {
      width: '7%',
    },
    row407table: {
      width: '7%',
    },
    row408table: {
      width: '7%',
    },
    row409table: {
      width: '5%',
    },
    row410table: {
      width: '5%',
    },
    row411table: {
      width: '7%',
    },
    row412table: {
      width: '11%',
    },
    row413table: {
      width: '5%',
    },
    row414table: {
      width: '5%',
  },
});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const Subtitle = ({ children, ...props }) => (
  <Text style={styles.subtitle} {...props}>
    {children}
  </Text>
);

class ReporteTurno extends Component {

    constructor(props) {
        super(props);

        this.state = {
          language: 'English',
          isocode: 'en',
          dateFormatX: 'dd/MM/yyyy',
        }
    }

    UNSAFE_componentWillMount() {
      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English'})
      }else{
        this.setState({ language: languageX })
      }
      if (languageX === 'English'){this.setState({ isocode: "en", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'French'){this.setState({ isocode:  "fr", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Spanish'){this.setState({ isocode:  "es", dateFormatX:"dd/MM/yyyy"})}
      if (languageX === 'Portuguese'){this.setState({ isocode:  "pt", dateFormatX:"dd/MM/yyyy"})}
      if (languageX === 'Swedish'){this.setState({ isocode:  "sv", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Netherlands'){this.setState({ isocode:  "nl", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Russian'){this.setState({ isocode:  "ru", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Japanese'){this.setState({ isocode:  "ja_JP", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Chinese'){this.setState({ isocode: "zh", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'German'){this.setState({ isocode:  "de", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Italian'){this.setState({ isocode:  "it", dateFormatX:"MM/dd/yyyy"})}

    }

    render() {
      let parrafo1X = ""
      let parrafo2X = ''
      let parrafo3X = ''
      let certificateTittleX = "REPORTE DE TURNO"

      const optionsDate = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric"
      };

      const fechaHoyX = new Date()

      let imageFirmadoDigitalmenteX = "images/digitally_signed_english.png"
      if (this.state.language === 'Spanish'){
        imageFirmadoDigitalmenteX = "images/firmadodigitalmente.png"
      }

      let pdfReporteTurnoDiaX = JSON.parse(this.props.pdfReporteTurnoDiaX)
      let pdfReporteTurnoNocheX = JSON.parse(this.props.pdfReporteTurnoNocheX)

      let a = ''
      let p = ''

      p = pdfReporteTurnoDiaX.vReporteTurnoDia12; a = p.split('&&&'); let vReporteTurnoDia12 = a[1]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia13; a = p.split('&&&'); let vReporteTurnoDia13 = a[0]

      let vReporteTurnoDia08a09_01 = ''
      let vReporteTurnoDia08a09_01_2 = ''
      let vReporteTurnoDia09a10_01 = ''
      let vReporteTurnoDia09a10_01_2 = ''
      let vReporteTurnoDia10a11_01 = ''
      let vReporteTurnoDia10a11_01_2 = ''
      let vReporteTurnoDia11a12_01 = ''
      let vReporteTurnoDia11a12_01_2 = ''
      let vReporteTurnoDia12a13_01 = ''
      let vReporteTurnoDia12a13_01_2 = ''
      let vReporteTurnoDia13a14_01 = ''
      let vReporteTurnoDia13a14_01_2 = ''
      let vReporteTurnoDia14a15_01 = ''
      let vReporteTurnoDia14a15_01_2 = ''
      let vReporteTurnoDia15a16_01 = ''
      let vReporteTurnoDia15a16_01_2 = ''
      let vReporteTurnoDia16a17_01 = ''
      let vReporteTurnoDia16a17_01_2 = ''
      let vReporteTurnoDia17a18_01 = ''
      let vReporteTurnoDia17a18_01_2 = ''
      let vReporteTurnoDia18a19_01 = ''
      let vReporteTurnoDia18a19_01_2 = ''
      let vReporteTurnoDia19a20_01 = ''
      let vReporteTurnoDia19a20_01_2 = ''

      let vReporteTurnoEstadoSondaje = ''
      if (pdfReporteTurnoDiaX.vReporteTurnoEstadoSondaje !== undefined){
         p = pdfReporteTurnoDiaX.vReporteTurnoEstadoSondaje;
         a = p.split('&&&');
         vReporteTurnoEstadoSondaje = a[0]
      }

      p = pdfReporteTurnoDiaX.vReporteTurnoDia08a09_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia08a09_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia08a09_02; a = p.split('&&&'); let vReporteTurnoDia08a09_02 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia08a09_03; a = p.split('&&&'); let vReporteTurnoDia08a09_03 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia08a09_06; a = p.split('&&&'); let vReporteTurnoDia08a09_06 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia08a09_04; a = p.split('&&&'); let vReporteTurnoDia08a09_04 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia08a09_05; a = p.split('&&&'); let vReporteTurnoDia08a09_05 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia08a09_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia08a09_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia08a09_02_2; a = p.split('&&&'); let vReporteTurnoDia08a09_02_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia08a09_03_2; a = p.split('&&&'); let vReporteTurnoDia08a09_03_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia08a09_06_2; a = p.split('&&&'); let vReporteTurnoDia08a09_06_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia08a09_04_2; a = p.split('&&&'); let vReporteTurnoDia08a09_04_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia08a09_05_2; a = p.split('&&&'); let vReporteTurnoDia08a09_05_2 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia09a10_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia09a10_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia09a10_02; a = p.split('&&&'); let vReporteTurnoDia09a10_02 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia09a10_03; a = p.split('&&&'); let vReporteTurnoDia09a10_03 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia09a10_06; a = p.split('&&&'); let vReporteTurnoDia09a10_06 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia09a10_04; a = p.split('&&&'); let vReporteTurnoDia09a10_04 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia09a10_05; a = p.split('&&&'); let vReporteTurnoDia09a10_05 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia09a10_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia09a10_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia09a10_02_2; a = p.split('&&&'); let vReporteTurnoDia09a10_02_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia09a10_03_2; a = p.split('&&&'); let vReporteTurnoDia09a10_03_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia09a10_06_2; a = p.split('&&&'); let vReporteTurnoDia09a10_06_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia09a10_04_2; a = p.split('&&&'); let vReporteTurnoDia09a10_04_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia09a10_05_2; a = p.split('&&&'); let vReporteTurnoDia09a10_05_2 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia10a11_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia10a11_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia10a11_02; a = p.split('&&&'); let vReporteTurnoDia10a11_02 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia10a11_03; a = p.split('&&&'); let vReporteTurnoDia10a11_03 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia10a11_06; a = p.split('&&&'); let vReporteTurnoDia10a11_06 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia10a11_04; a = p.split('&&&'); let vReporteTurnoDia10a11_04 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia10a11_05; a = p.split('&&&'); let vReporteTurnoDia10a11_05 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia10a11_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia10a11_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia10a11_02_2; a = p.split('&&&'); let vReporteTurnoDia10a11_02_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia10a11_03_2; a = p.split('&&&'); let vReporteTurnoDia10a11_03_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia10a11_06_2; a = p.split('&&&'); let vReporteTurnoDia10a11_06_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia10a11_04_2; a = p.split('&&&'); let vReporteTurnoDia10a11_04_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia10a11_05_2; a = p.split('&&&'); let vReporteTurnoDia10a11_05_2 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia11a12_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia11a12_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia11a12_02; a = p.split('&&&'); let vReporteTurnoDia11a12_02 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia11a12_03; a = p.split('&&&'); let vReporteTurnoDia11a12_03 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia11a12_06; a = p.split('&&&'); let vReporteTurnoDia11a12_06 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia11a12_04; a = p.split('&&&'); let vReporteTurnoDia11a12_04 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia11a12_05; a = p.split('&&&'); let vReporteTurnoDia11a12_05 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia11a12_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia11a12_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia11a12_02_2; a = p.split('&&&'); let vReporteTurnoDia11a12_02_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia11a12_03_2; a = p.split('&&&'); let vReporteTurnoDia11a12_03_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia11a12_06_2; a = p.split('&&&'); let vReporteTurnoDia11a12_06_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia11a12_04_2; a = p.split('&&&'); let vReporteTurnoDia11a12_04_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia11a12_05_2; a = p.split('&&&'); let vReporteTurnoDia11a12_05_2 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia12a13_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia12a13_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia12a13_02; a = p.split('&&&'); let vReporteTurnoDia12a13_02 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia12a13_03; a = p.split('&&&'); let vReporteTurnoDia12a13_03 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia12a13_06; a = p.split('&&&'); let vReporteTurnoDia12a13_06 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia12a13_04; a = p.split('&&&'); let vReporteTurnoDia12a13_04 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia12a13_05; a = p.split('&&&'); let vReporteTurnoDia12a13_05 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia12a13_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia12a13_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia12a13_02_2; a = p.split('&&&'); let vReporteTurnoDia12a13_02_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia12a13_03_2; a = p.split('&&&'); let vReporteTurnoDia12a13_03_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia12a13_06_2; a = p.split('&&&'); let vReporteTurnoDia12a13_06_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia12a13_04_2; a = p.split('&&&'); let vReporteTurnoDia12a13_04_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia12a13_05_2; a = p.split('&&&'); let vReporteTurnoDia12a13_05_2 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia13a14_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia13a14_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia13a14_02; a = p.split('&&&'); let vReporteTurnoDia13a14_02 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia13a14_03; a = p.split('&&&'); let vReporteTurnoDia13a14_03 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia13a14_06; a = p.split('&&&'); let vReporteTurnoDia13a14_06 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia13a14_04; a = p.split('&&&'); let vReporteTurnoDia13a14_04 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia13a14_05; a = p.split('&&&'); let vReporteTurnoDia13a14_05 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia13a14_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia13a14_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia13a14_02_2; a = p.split('&&&'); let vReporteTurnoDia13a14_02_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia13a14_03_2; a = p.split('&&&'); let vReporteTurnoDia13a14_03_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia13a14_06_2; a = p.split('&&&'); let vReporteTurnoDia13a14_06_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia13a14_04_2; a = p.split('&&&'); let vReporteTurnoDia13a14_04_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia13a14_05_2; a = p.split('&&&'); let vReporteTurnoDia13a14_05_2 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia14a15_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia14a15_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia14a15_02; a = p.split('&&&'); let vReporteTurnoDia14a15_02 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia14a15_03; a = p.split('&&&'); let vReporteTurnoDia14a15_03 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia14a15_06; a = p.split('&&&'); let vReporteTurnoDia14a15_06 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia14a15_04; a = p.split('&&&'); let vReporteTurnoDia14a15_04 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia14a15_05; a = p.split('&&&'); let vReporteTurnoDia14a15_05 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia14a15_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia14a15_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia14a15_02_2; a = p.split('&&&'); let vReporteTurnoDia14a15_02_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia14a15_03_2; a = p.split('&&&'); let vReporteTurnoDia14a15_03_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia14a15_06_2; a = p.split('&&&'); let vReporteTurnoDia14a15_06_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia14a15_04_2; a = p.split('&&&'); let vReporteTurnoDia14a15_04_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia14a15_05_2; a = p.split('&&&'); let vReporteTurnoDia14a15_05_2 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia15a16_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia15a16_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia15a16_02; a = p.split('&&&'); let vReporteTurnoDia15a16_02 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia15a16_03; a = p.split('&&&'); let vReporteTurnoDia15a16_03 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia15a16_06; a = p.split('&&&'); let vReporteTurnoDia15a16_06 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia15a16_04; a = p.split('&&&'); let vReporteTurnoDia15a16_04 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia15a16_05; a = p.split('&&&'); let vReporteTurnoDia15a16_05 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia15a16_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia15a16_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia15a16_02_2; a = p.split('&&&'); let vReporteTurnoDia15a16_02_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia15a16_03_2; a = p.split('&&&'); let vReporteTurnoDia15a16_03_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia15a16_06_2; a = p.split('&&&'); let vReporteTurnoDia15a16_06_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia15a16_04_2; a = p.split('&&&'); let vReporteTurnoDia15a16_04_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia15a16_05_2; a = p.split('&&&'); let vReporteTurnoDia15a16_05_2 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia16a17_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia16a17_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia16a17_02; a = p.split('&&&'); let vReporteTurnoDia16a17_02 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia16a17_03; a = p.split('&&&'); let vReporteTurnoDia16a17_03 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia16a17_06; a = p.split('&&&'); let vReporteTurnoDia16a17_06 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia16a17_04; a = p.split('&&&'); let vReporteTurnoDia16a17_04 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia16a17_05; a = p.split('&&&'); let vReporteTurnoDia16a17_05 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia16a17_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia16a17_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia16a17_02_2; a = p.split('&&&'); let vReporteTurnoDia16a17_02_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia16a17_03_2; a = p.split('&&&'); let vReporteTurnoDia16a17_03_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia16a17_06_2; a = p.split('&&&'); let vReporteTurnoDia16a17_06_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia16a17_04_2; a = p.split('&&&'); let vReporteTurnoDia16a17_04_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia16a17_05_2; a = p.split('&&&'); let vReporteTurnoDia16a17_05_2 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia17a18_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia17a18_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia17a18_02; a = p.split('&&&'); let vReporteTurnoDia17a18_02 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia17a18_03; a = p.split('&&&'); let vReporteTurnoDia17a18_03 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia17a18_06; a = p.split('&&&'); let vReporteTurnoDia17a18_06 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia17a18_04; a = p.split('&&&'); let vReporteTurnoDia17a18_04 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia17a18_05; a = p.split('&&&'); let vReporteTurnoDia17a18_05 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia17a18_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia17a18_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia17a18_02_2; a = p.split('&&&'); let vReporteTurnoDia17a18_02_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia17a18_03_2; a = p.split('&&&'); let vReporteTurnoDia17a18_03_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia17a18_06_2; a = p.split('&&&'); let vReporteTurnoDia17a18_06_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia17a18_04_2; a = p.split('&&&'); let vReporteTurnoDia17a18_04_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia17a18_05_2; a = p.split('&&&'); let vReporteTurnoDia17a18_05_2 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia18a19_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia18a19_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia18a19_02; a = p.split('&&&'); let vReporteTurnoDia18a19_02 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia18a19_03; a = p.split('&&&'); let vReporteTurnoDia18a19_03 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia18a19_06; a = p.split('&&&'); let vReporteTurnoDia18a19_06 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia18a19_04; a = p.split('&&&'); let vReporteTurnoDia18a19_04 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia18a19_05; a = p.split('&&&'); let vReporteTurnoDia18a19_05 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia18a19_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia18a19_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia18a19_02_2; a = p.split('&&&'); let vReporteTurnoDia18a19_02_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia18a19_03_2; a = p.split('&&&'); let vReporteTurnoDia18a19_03_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia18a19_06_2; a = p.split('&&&'); let vReporteTurnoDia18a19_06_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia18a19_04_2; a = p.split('&&&'); let vReporteTurnoDia18a19_04_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia18a19_05_2; a = p.split('&&&'); let vReporteTurnoDia18a19_05_2 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia19a20_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia19a20_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia19a20_02; a = p.split('&&&'); let vReporteTurnoDia19a20_02 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia19a20_03; a = p.split('&&&'); let vReporteTurnoDia19a20_03 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia19a20_06; a = p.split('&&&'); let vReporteTurnoDia19a20_06 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia19a20_04; a = p.split('&&&'); let vReporteTurnoDia19a20_04 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia19a20_05; a = p.split('&&&'); let vReporteTurnoDia19a20_05 = a[0]

      p = pdfReporteTurnoDiaX.vReporteTurnoDia19a20_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoDia19a20_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoDiaX.vReporteTurnoDia19a20_02_2; a = p.split('&&&'); let vReporteTurnoDia19a20_02_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia19a20_03_2; a = p.split('&&&'); let vReporteTurnoDia19a20_03_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia19a20_06_2; a = p.split('&&&'); let vReporteTurnoDia19a20_06_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia19a20_04_2; a = p.split('&&&'); let vReporteTurnoDia19a20_04_2 = a[0]
      p = pdfReporteTurnoDiaX.vReporteTurnoDia19a20_05_2; a = p.split('&&&'); let vReporteTurnoDia19a20_05_2 = a[0]

      let vReporteTurnoDia08a09_11 = ''
      let vReporteTurnoDia08a09_11_2 = ''
      let vReporteTurnoDia09a10_11 = ''
      let vReporteTurnoDia09a10_11_2 = ''
      let vReporteTurnoDia10a11_11 = ''
      let vReporteTurnoDia10a11_11_2 = ''
      let vReporteTurnoDia11a12_11 = ''
      let vReporteTurnoDia11a12_11_2 = ''
      let vReporteTurnoDia12a13_11 = ''
      let vReporteTurnoDia12a13_11_2 = ''
      let vReporteTurnoDia13a14_11 = ''
      let vReporteTurnoDia13a14_11_2 = ''
      let vReporteTurnoDia14a15_11 = ''
      let vReporteTurnoDia14a15_11_2 = ''
      let vReporteTurnoDia15a16_11 = ''
      let vReporteTurnoDia15a16_11_2 = ''
      let vReporteTurnoDia16a17_11 = ''
      let vReporteTurnoDia16a17_11_2 = ''
      let vReporteTurnoDia17a18_11 = ''
      let vReporteTurnoDia17a18_11_2 = ''
      let vReporteTurnoDia18a19_11 = ''
      let vReporteTurnoDia18a19_11_2 = ''
      let vReporteTurnoDia19a20_11 = ''
      let vReporteTurnoDia19a20_11_2 = ''
      if (pdfReporteTurnoDiaX.vReporteTurnoDia08a09_11 === 'X'){vReporteTurnoDia08a09_11='0.5'}else{vReporteTurnoDia08a09_11=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia08a09_11_2 === 'X'){vReporteTurnoDia08a09_11_2='0.5'}else{vReporteTurnoDia08a09_11_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia09a10_11 === 'X'){vReporteTurnoDia09a10_11='0.5'}else{vReporteTurnoDia09a10_11=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia09a10_11_2 === 'X'){vReporteTurnoDia09a10_11_2='0.5'}else{vReporteTurnoDia09a10_11_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia10a11_11 === 'X'){vReporteTurnoDia10a11_11='0.5'}else{vReporteTurnoDia10a11_11=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia10a11_11_2 === 'X'){vReporteTurnoDia10a11_11_2='0.5'}else{vReporteTurnoDia10a11_11_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia11a12_11 === 'X'){vReporteTurnoDia11a12_11='0.5'}else{vReporteTurnoDia11a12_11=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia11a12_11_2 === 'X'){vReporteTurnoDia11a12_11_2='0.5'}else{vReporteTurnoDia11a12_11_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia12a13_11 === 'X'){vReporteTurnoDia12a13_11='0.5'}else{vReporteTurnoDia12a13_11=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia12a13_11_2 === 'X'){vReporteTurnoDia12a13_11_2='0.5'}else{vReporteTurnoDia12a13_11_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia13a14_11 === 'X'){vReporteTurnoDia13a14_11='0.5'}else{vReporteTurnoDia13a14_11=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia13a14_11_2 === 'X'){vReporteTurnoDia13a14_11_2='0.5'}else{vReporteTurnoDia13a14_11_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia14a15_11 === 'X'){vReporteTurnoDia14a15_11='0.5'}else{vReporteTurnoDia14a15_11=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia14a15_11_2 === 'X'){vReporteTurnoDia14a15_11_2='0.5'}else{vReporteTurnoDia14a15_11_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia15a16_11 === 'X'){vReporteTurnoDia15a16_11='0.5'}else{vReporteTurnoDia15a16_11=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia15a16_11_2 === 'X'){vReporteTurnoDia15a16_11_2='0.5'}else{vReporteTurnoDia15a16_11_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia16a17_11 === 'X'){vReporteTurnoDia16a17_11='0.5'}else{vReporteTurnoDia16a17_11=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia16a17_11_2 === 'X'){vReporteTurnoDia16a17_11_2='0.5'}else{vReporteTurnoDia16a17_11_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia17a18_11 === 'X'){vReporteTurnoDia17a18_11='0.5'}else{vReporteTurnoDia17a18_11=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia17a18_11_2 === 'X'){vReporteTurnoDia17a18_11_2='0.5'}else{vReporteTurnoDia17a18_11_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia18a19_11 === 'X'){vReporteTurnoDia18a19_11='0.5'}else{vReporteTurnoDia18a19_11=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia18a19_11_2 === 'X'){vReporteTurnoDia18a19_11_2='0.5'}else{vReporteTurnoDia18a19_11_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia19a20_11 === 'X'){vReporteTurnoDia19a20_11='0.5'}else{vReporteTurnoDia19a20_11=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia19a20_11_2 === 'X'){vReporteTurnoDia19a20_11_2='0.5'}else{vReporteTurnoDia19a20_11_2=''}

      let vReporteTurnoDia08a09_12 = ''
      let vReporteTurnoDia08a09_12_2 = ''
      let vReporteTurnoDia09a10_12 = ''
      let vReporteTurnoDia09a10_12_2 = ''
      let vReporteTurnoDia10a11_12 = ''
      let vReporteTurnoDia10a11_12_2 = ''
      let vReporteTurnoDia11a12_12 = ''
      let vReporteTurnoDia11a12_12_2 = ''
      let vReporteTurnoDia12a13_12 = ''
      let vReporteTurnoDia12a13_12_2 = ''
      let vReporteTurnoDia13a14_12 = ''
      let vReporteTurnoDia13a14_12_2 = ''
      let vReporteTurnoDia14a15_12 = ''
      let vReporteTurnoDia14a15_12_2 = ''
      let vReporteTurnoDia15a16_12 = ''
      let vReporteTurnoDia15a16_12_2 = ''
      let vReporteTurnoDia16a17_12 = ''
      let vReporteTurnoDia16a17_12_2 = ''
      let vReporteTurnoDia17a18_12 = ''
      let vReporteTurnoDia17a18_12_2 = ''
      let vReporteTurnoDia18a19_12 = ''
      let vReporteTurnoDia18a19_12_2 = ''
      let vReporteTurnoDia19a20_12 = ''
      let vReporteTurnoDia19a20_12_2 = ''
      if (pdfReporteTurnoDiaX.vReporteTurnoDia08a09_12 === 'X'){vReporteTurnoDia08a09_12='0.5'}else{vReporteTurnoDia08a09_12=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia08a09_12_2 === 'X'){vReporteTurnoDia08a09_12_2='0.5'}else{vReporteTurnoDia08a09_12_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia09a10_12 === 'X'){vReporteTurnoDia09a10_12='0.5'}else{vReporteTurnoDia09a10_12=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia09a10_12_2 === 'X'){vReporteTurnoDia09a10_12_2='0.5'}else{vReporteTurnoDia09a10_12_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia10a11_12 === 'X'){vReporteTurnoDia10a11_12='0.5'}else{vReporteTurnoDia10a11_12=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia10a11_12_2 === 'X'){vReporteTurnoDia10a11_12_2='0.5'}else{vReporteTurnoDia10a11_12_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia11a12_12 === 'X'){vReporteTurnoDia11a12_12='0.5'}else{vReporteTurnoDia11a12_12=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia11a12_12_2 === 'X'){vReporteTurnoDia11a12_12_2='0.5'}else{vReporteTurnoDia11a12_12_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia12a13_12 === 'X'){vReporteTurnoDia12a13_12='0.5'}else{vReporteTurnoDia12a13_12=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia12a13_12_2 === 'X'){vReporteTurnoDia12a13_12_2='0.5'}else{vReporteTurnoDia12a13_12_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia13a14_12 === 'X'){vReporteTurnoDia13a14_12='0.5'}else{vReporteTurnoDia13a14_12=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia13a14_12_2 === 'X'){vReporteTurnoDia13a14_12_2='0.5'}else{vReporteTurnoDia13a14_12_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia14a15_12 === 'X'){vReporteTurnoDia14a15_12='0.5'}else{vReporteTurnoDia14a15_12=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia14a15_12_2 === 'X'){vReporteTurnoDia14a15_12_2='0.5'}else{vReporteTurnoDia14a15_12_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia15a16_12 === 'X'){vReporteTurnoDia15a16_12='0.5'}else{vReporteTurnoDia15a16_12=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia15a16_12_2 === 'X'){vReporteTurnoDia15a16_12_2='0.5'}else{vReporteTurnoDia15a16_12_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia16a17_12 === 'X'){vReporteTurnoDia16a17_12='0.5'}else{vReporteTurnoDia16a17_12=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia16a17_12_2 === 'X'){vReporteTurnoDia16a17_12_2='0.5'}else{vReporteTurnoDia16a17_12_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia17a18_12 === 'X'){vReporteTurnoDia17a18_12='0.5'}else{vReporteTurnoDia17a18_12=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia17a18_12_2 === 'X'){vReporteTurnoDia17a18_12_2='0.5'}else{vReporteTurnoDia17a18_12_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia18a19_12 === 'X'){vReporteTurnoDia18a19_12='0.5'}else{vReporteTurnoDia18a19_12=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia18a19_12_2 === 'X'){vReporteTurnoDia18a19_12_2='0.5'}else{vReporteTurnoDia18a19_12_2=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia19a20_12 === 'X'){vReporteTurnoDia19a20_12='0.5'}else{vReporteTurnoDia19a20_12=''}
      if (pdfReporteTurnoDiaX.vReporteTurnoDia19a20_12_2 === 'X'){vReporteTurnoDia19a20_12_2='0.5'}else{vReporteTurnoDia19a20_12_2=''}

      //-----------------------------------------------------------------------------------------------------------

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche12; a = p.split('&&&'); let vReporteTurnoNoche12 = a[1]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche13; a = p.split('&&&'); let vReporteTurnoNoche13 = a[0]

      let vReporteTurnoNoche20a21_01 = ''
      let vReporteTurnoNoche20a21_01_2 = ''
      let vReporteTurnoNoche21a22_01 = ''
      let vReporteTurnoNoche21a22_01_2 = ''
      let vReporteTurnoNoche22a23_01 = ''
      let vReporteTurnoNoche22a23_01_2 = ''
      let vReporteTurnoNoche23a24_01 = ''
      let vReporteTurnoNoche23a24_01_2 = ''
      let vReporteTurnoNoche24a01_01 = ''
      let vReporteTurnoNoche24a01_01_2 = ''
      let vReporteTurnoNoche01a02_01 = ''
      let vReporteTurnoNoche01a02_01_2 = ''
      let vReporteTurnoNoche02a03_01 = ''
      let vReporteTurnoNoche02a03_01_2 = ''
      let vReporteTurnoNoche03a04_01 = ''
      let vReporteTurnoNoche03a04_01_2 = ''
      let vReporteTurnoNoche04a05_01 = ''
      let vReporteTurnoNoche04a05_01_2 = ''
      let vReporteTurnoNoche05a06_01 = ''
      let vReporteTurnoNoche05a06_01_2 = ''
      let vReporteTurnoNoche06a07_01 = ''
      let vReporteTurnoNoche06a07_01_2 = ''
      let vReporteTurnoNoche07a08_01 = ''
      let vReporteTurnoNoche07a08_01_2 = ''

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche20a21_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_02; a = p.split('&&&'); let vReporteTurnoNoche20a21_02 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_03; a = p.split('&&&'); let vReporteTurnoNoche20a21_03 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_06; a = p.split('&&&'); let vReporteTurnoNoche20a21_06 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_04; a = p.split('&&&'); let vReporteTurnoNoche20a21_04 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_05; a = p.split('&&&'); let vReporteTurnoNoche20a21_05 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche20a21_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_02_2; a = p.split('&&&'); let vReporteTurnoNoche20a21_02_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_03_2; a = p.split('&&&'); let vReporteTurnoNoche20a21_03_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_06_2; a = p.split('&&&'); let vReporteTurnoNoche20a21_06_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_04_2; a = p.split('&&&'); let vReporteTurnoNoche20a21_04_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_05_2; a = p.split('&&&'); let vReporteTurnoNoche20a21_05_2 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche21a22_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_02; a = p.split('&&&'); let vReporteTurnoNoche21a22_02 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_03; a = p.split('&&&'); let vReporteTurnoNoche21a22_03 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_06; a = p.split('&&&'); let vReporteTurnoNoche21a22_06 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_04; a = p.split('&&&'); let vReporteTurnoNoche21a22_04 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_05; a = p.split('&&&'); let vReporteTurnoNoche21a22_05 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche21a22_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_02_2; a = p.split('&&&'); let vReporteTurnoNoche21a22_02_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_03_2; a = p.split('&&&'); let vReporteTurnoNoche21a22_03_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_06_2; a = p.split('&&&'); let vReporteTurnoNoche21a22_06_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_04_2; a = p.split('&&&'); let vReporteTurnoNoche21a22_04_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_05_2; a = p.split('&&&'); let vReporteTurnoNoche21a22_05_2 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche22a23_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_02; a = p.split('&&&'); let vReporteTurnoNoche22a23_02 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_03; a = p.split('&&&'); let vReporteTurnoNoche22a23_03 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_06; a = p.split('&&&'); let vReporteTurnoNoche22a23_06 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_04; a = p.split('&&&'); let vReporteTurnoNoche22a23_04 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_05; a = p.split('&&&'); let vReporteTurnoNoche22a23_05 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche22a23_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_02_2; a = p.split('&&&'); let vReporteTurnoNoche22a23_02_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_03_2; a = p.split('&&&'); let vReporteTurnoNoche22a23_03_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_06_2; a = p.split('&&&'); let vReporteTurnoNoche22a23_06_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_04_2; a = p.split('&&&'); let vReporteTurnoNoche22a23_04_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_05_2; a = p.split('&&&'); let vReporteTurnoNoche22a23_05_2 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche23a24_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_02; a = p.split('&&&'); let vReporteTurnoNoche23a24_02 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_03; a = p.split('&&&'); let vReporteTurnoNoche23a24_03 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_06; a = p.split('&&&'); let vReporteTurnoNoche23a24_06 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_04; a = p.split('&&&'); let vReporteTurnoNoche23a24_04 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_05; a = p.split('&&&'); let vReporteTurnoNoche23a24_05 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche23a24_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_02_2; a = p.split('&&&'); let vReporteTurnoNoche23a24_02_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_03_2; a = p.split('&&&'); let vReporteTurnoNoche23a24_03_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_06_2; a = p.split('&&&'); let vReporteTurnoNoche23a24_06_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_04_2; a = p.split('&&&'); let vReporteTurnoNoche23a24_04_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_05_2; a = p.split('&&&'); let vReporteTurnoNoche23a24_05_2 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche24a01_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_02; a = p.split('&&&'); let vReporteTurnoNoche24a01_02 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_03; a = p.split('&&&'); let vReporteTurnoNoche24a01_03 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_06; a = p.split('&&&'); let vReporteTurnoNoche24a01_06 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_04; a = p.split('&&&'); let vReporteTurnoNoche24a01_04 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_05; a = p.split('&&&'); let vReporteTurnoNoche24a01_05 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche24a01_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_02_2; a = p.split('&&&'); let vReporteTurnoNoche24a01_02_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_03_2; a = p.split('&&&'); let vReporteTurnoNoche24a01_03_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_06_2; a = p.split('&&&'); let vReporteTurnoNoche24a01_06_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_04_2; a = p.split('&&&'); let vReporteTurnoNoche24a01_04_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_05_2; a = p.split('&&&'); let vReporteTurnoNoche24a01_05_2 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche01a02_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_02; a = p.split('&&&'); let vReporteTurnoNoche01a02_02 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_03; a = p.split('&&&'); let vReporteTurnoNoche01a02_03 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_06; a = p.split('&&&'); let vReporteTurnoNoche01a02_06 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_04; a = p.split('&&&'); let vReporteTurnoNoche01a02_04 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_05; a = p.split('&&&'); let vReporteTurnoNoche01a02_05 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche01a02_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_02_2; a = p.split('&&&'); let vReporteTurnoNoche01a02_02_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_03_2; a = p.split('&&&'); let vReporteTurnoNoche01a02_03_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_06_2; a = p.split('&&&'); let vReporteTurnoNoche01a02_06_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_04_2; a = p.split('&&&'); let vReporteTurnoNoche01a02_04_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_05_2; a = p.split('&&&'); let vReporteTurnoNoche01a02_05_2 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche02a03_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_02; a = p.split('&&&'); let vReporteTurnoNoche02a03_02 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_03; a = p.split('&&&'); let vReporteTurnoNoche02a03_03 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_06; a = p.split('&&&'); let vReporteTurnoNoche02a03_06 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_04; a = p.split('&&&'); let vReporteTurnoNoche02a03_04 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_05; a = p.split('&&&'); let vReporteTurnoNoche02a03_05 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche02a03_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_02_2; a = p.split('&&&'); let vReporteTurnoNoche02a03_02_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_03_2; a = p.split('&&&'); let vReporteTurnoNoche02a03_03_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_06_2; a = p.split('&&&'); let vReporteTurnoNoche02a03_06_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_04_2; a = p.split('&&&'); let vReporteTurnoNoche02a03_04_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_05_2; a = p.split('&&&'); let vReporteTurnoNoche02a03_05_2 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche03a04_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_02; a = p.split('&&&'); let vReporteTurnoNoche03a04_02 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_03; a = p.split('&&&'); let vReporteTurnoNoche03a04_03 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_06; a = p.split('&&&'); let vReporteTurnoNoche03a04_06 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_04; a = p.split('&&&'); let vReporteTurnoNoche03a04_04 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_05; a = p.split('&&&'); let vReporteTurnoNoche03a04_05 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche03a04_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_02_2; a = p.split('&&&'); let vReporteTurnoNoche03a04_02_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_03_2; a = p.split('&&&'); let vReporteTurnoNoche03a04_03_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_06_2; a = p.split('&&&'); let vReporteTurnoNoche03a04_06_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_04_2; a = p.split('&&&'); let vReporteTurnoNoche03a04_04_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_05_2; a = p.split('&&&'); let vReporteTurnoNoche03a04_05_2 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche04a05_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_02; a = p.split('&&&'); let vReporteTurnoNoche04a05_02 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_03; a = p.split('&&&'); let vReporteTurnoNoche04a05_03 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_06; a = p.split('&&&'); let vReporteTurnoNoche04a05_06 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_04; a = p.split('&&&'); let vReporteTurnoNoche04a05_04 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_05; a = p.split('&&&'); let vReporteTurnoNoche04a05_05 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche04a05_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_02_2; a = p.split('&&&'); let vReporteTurnoNoche04a05_02_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_03_2; a = p.split('&&&'); let vReporteTurnoNoche04a05_03_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_06_2; a = p.split('&&&'); let vReporteTurnoNoche04a05_06_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_04_2; a = p.split('&&&'); let vReporteTurnoNoche04a05_04_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_05_2; a = p.split('&&&'); let vReporteTurnoNoche04a05_05_2 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche05a06_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_02; a = p.split('&&&'); let vReporteTurnoNoche05a06_02 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_03; a = p.split('&&&'); let vReporteTurnoNoche05a06_03 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_06; a = p.split('&&&'); let vReporteTurnoNoche05a06_06 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_04; a = p.split('&&&'); let vReporteTurnoNoche05a06_04 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_05; a = p.split('&&&'); let vReporteTurnoNoche05a06_05 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche05a06_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_02_2; a = p.split('&&&'); let vReporteTurnoNoche05a06_02_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_03_2; a = p.split('&&&'); let vReporteTurnoNoche05a06_03_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_06_2; a = p.split('&&&'); let vReporteTurnoNoche05a06_06_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_04_2; a = p.split('&&&'); let vReporteTurnoNoche05a06_04_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_05_2; a = p.split('&&&'); let vReporteTurnoNoche05a06_05_2 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche06a07_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_02; a = p.split('&&&'); let vReporteTurnoNoche06a07_02 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_03; a = p.split('&&&'); let vReporteTurnoNoche06a07_03 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_06; a = p.split('&&&'); let vReporteTurnoNoche06a07_06 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_04; a = p.split('&&&'); let vReporteTurnoNoche06a07_04 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_05; a = p.split('&&&'); let vReporteTurnoNoche06a07_05 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche06a07_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_02_2; a = p.split('&&&'); let vReporteTurnoNoche06a07_02_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_03_2; a = p.split('&&&'); let vReporteTurnoNoche06a07_03_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_06_2; a = p.split('&&&'); let vReporteTurnoNoche06a07_06_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_04_2; a = p.split('&&&'); let vReporteTurnoNoche06a07_04_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_05_2; a = p.split('&&&'); let vReporteTurnoNoche06a07_05_2 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_01; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche07a08_01 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_02; a = p.split('&&&'); let vReporteTurnoNoche07a08_02 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_03; a = p.split('&&&'); let vReporteTurnoNoche07a08_03 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_06; a = p.split('&&&'); let vReporteTurnoNoche07a08_06 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_04; a = p.split('&&&'); let vReporteTurnoNoche07a08_04 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_05; a = p.split('&&&'); let vReporteTurnoNoche07a08_05 = a[0]

      p = pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_01_2; a = p.split('&&&'); if (a[1] !== undefined){vReporteTurnoNoche07a08_01_2 = a[0] + ')' + a[1]}
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_02_2; a = p.split('&&&'); let vReporteTurnoNoche07a08_02_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_03_2; a = p.split('&&&'); let vReporteTurnoNoche07a08_03_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_06_2; a = p.split('&&&'); let vReporteTurnoNoche07a08_06_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_04_2; a = p.split('&&&'); let vReporteTurnoNoche07a08_04_2 = a[0]
      p = pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_05_2; a = p.split('&&&'); let vReporteTurnoNoche07a08_05_2 = a[0]

      let vReporteTurnoNoche20a21_11 = ''
      let vReporteTurnoNoche20a21_11_2 = ''
      let vReporteTurnoNoche21a22_11 = ''
      let vReporteTurnoNoche21a22_11_2 = ''
      let vReporteTurnoNoche22a23_11 = ''
      let vReporteTurnoNoche22a23_11_2 = ''
      let vReporteTurnoNoche23a24_11 = ''
      let vReporteTurnoNoche23a24_11_2 = ''
      let vReporteTurnoNoche24a01_11 = ''
      let vReporteTurnoNoche24a01_11_2 = ''
      let vReporteTurnoNoche01a02_11 = ''
      let vReporteTurnoNoche01a02_11_2 = ''
      let vReporteTurnoNoche02a03_11 = ''
      let vReporteTurnoNoche02a03_11_2 = ''
      let vReporteTurnoNoche03a04_11 = ''
      let vReporteTurnoNoche03a04_11_2 = ''
      let vReporteTurnoNoche04a05_11 = ''
      let vReporteTurnoNoche04a05_11_2 = ''
      let vReporteTurnoNoche05a06_11 = ''
      let vReporteTurnoNoche05a06_11_2 = ''
      let vReporteTurnoNoche06a07_11 = ''
      let vReporteTurnoNoche06a07_11_2 = ''
      let vReporteTurnoNoche07a08_11 = ''
      let vReporteTurnoNoche07a08_11_2 = ''
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_11 === 'X'){vReporteTurnoNoche20a21_11='0.5'}else{vReporteTurnoNoche20a21_11=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_11_2 === 'X'){vReporteTurnoNoche20a21_11_2='0.5'}else{vReporteTurnoNoche20a21_11_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_11 === 'X'){vReporteTurnoNoche21a22_11='0.5'}else{vReporteTurnoNoche21a22_11=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_11_2 === 'X'){vReporteTurnoNoche21a22_11_2='0.5'}else{vReporteTurnoNoche21a22_11_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_11 === 'X'){vReporteTurnoNoche22a23_11='0.5'}else{vReporteTurnoNoche22a23_11=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_11_2 === 'X'){vReporteTurnoNoche22a23_11_2='0.5'}else{vReporteTurnoNoche22a23_11_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_11 === 'X'){vReporteTurnoNoche23a24_11='0.5'}else{vReporteTurnoNoche23a24_11=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_11_2 === 'X'){vReporteTurnoNoche23a24_11_2='0.5'}else{vReporteTurnoNoche23a24_11_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_11 === 'X'){vReporteTurnoNoche24a01_11='0.5'}else{vReporteTurnoNoche24a01_11=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_11_2 === 'X'){vReporteTurnoNoche24a01_11_2='0.5'}else{vReporteTurnoNoche24a01_11_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_11 === 'X'){vReporteTurnoNoche01a02_11='0.5'}else{vReporteTurnoNoche01a02_11=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_11_2 === 'X'){vReporteTurnoNoche01a02_11_2='0.5'}else{vReporteTurnoNoche01a02_11_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_11 === 'X'){vReporteTurnoNoche02a03_11='0.5'}else{vReporteTurnoNoche02a03_11=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_11_2 === 'X'){vReporteTurnoNoche02a03_11_2='0.5'}else{vReporteTurnoNoche02a03_11_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_11 === 'X'){vReporteTurnoNoche03a04_11='0.5'}else{vReporteTurnoNoche03a04_11=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_11_2 === 'X'){vReporteTurnoNoche03a04_11_2='0.5'}else{vReporteTurnoNoche03a04_11_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_11 === 'X'){vReporteTurnoNoche04a05_11='0.5'}else{vReporteTurnoNoche04a05_11=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_11_2 === 'X'){vReporteTurnoNoche04a05_11_2='0.5'}else{vReporteTurnoNoche04a05_11_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_11 === 'X'){vReporteTurnoNoche05a06_11='0.5'}else{vReporteTurnoNoche05a06_11=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_11_2 === 'X'){vReporteTurnoNoche05a06_11_2='0.5'}else{vReporteTurnoNoche05a06_11_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_11 === 'X'){vReporteTurnoNoche06a07_11='0.5'}else{vReporteTurnoNoche06a07_11=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_11_2 === 'X'){vReporteTurnoNoche06a07_11_2='0.5'}else{vReporteTurnoNoche06a07_11_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_11 === 'X'){vReporteTurnoNoche07a08_11='0.5'}else{vReporteTurnoNoche07a08_11=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_11_2 === 'X'){vReporteTurnoNoche07a08_11_2='0.5'}else{vReporteTurnoNoche07a08_11_2=''}

      let vReporteTurnoNoche20a21_12 = ''
      let vReporteTurnoNoche20a21_12_2 = ''
      let vReporteTurnoNoche21a22_12 = ''
      let vReporteTurnoNoche21a22_12_2 = ''
      let vReporteTurnoNoche22a23_12 = ''
      let vReporteTurnoNoche22a23_12_2 = ''
      let vReporteTurnoNoche23a24_12 = ''
      let vReporteTurnoNoche23a24_12_2 = ''
      let vReporteTurnoNoche24a01_12 = ''
      let vReporteTurnoNoche24a01_12_2 = ''
      let vReporteTurnoNoche01a02_12 = ''
      let vReporteTurnoNoche01a02_12_2 = ''
      let vReporteTurnoNoche02a03_12 = ''
      let vReporteTurnoNoche02a03_12_2 = ''
      let vReporteTurnoNoche03a04_12 = ''
      let vReporteTurnoNoche03a04_12_2 = ''
      let vReporteTurnoNoche04a05_12 = ''
      let vReporteTurnoNoche04a05_12_2 = ''
      let vReporteTurnoNoche05a06_12 = ''
      let vReporteTurnoNoche05a06_12_2 = ''
      let vReporteTurnoNoche06a07_12 = ''
      let vReporteTurnoNoche06a07_12_2 = ''
      let vReporteTurnoNoche07a08_12 = ''
      let vReporteTurnoNoche07a08_12_2 = ''
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_12 === 'X'){vReporteTurnoNoche20a21_12='0.5'}else{vReporteTurnoNoche20a21_12=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_12_2 === 'X'){vReporteTurnoNoche20a21_12_2='0.5'}else{vReporteTurnoNoche20a21_12_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_12 === 'X'){vReporteTurnoNoche21a22_12='0.5'}else{vReporteTurnoNoche21a22_12=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_12_2 === 'X'){vReporteTurnoNoche21a22_12_2='0.5'}else{vReporteTurnoNoche21a22_12_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_12 === 'X'){vReporteTurnoNoche22a23_12='0.5'}else{vReporteTurnoNoche22a23_12=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_12_2 === 'X'){vReporteTurnoNoche22a23_12_2='0.5'}else{vReporteTurnoNoche22a23_12_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_12 === 'X'){vReporteTurnoNoche23a24_12='0.5'}else{vReporteTurnoNoche23a24_12=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_12_2 === 'X'){vReporteTurnoNoche23a24_12_2='0.5'}else{vReporteTurnoNoche23a24_12_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_12 === 'X'){vReporteTurnoNoche24a01_12='0.5'}else{vReporteTurnoNoche24a01_12=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_12_2 === 'X'){vReporteTurnoNoche24a01_12_2='0.5'}else{vReporteTurnoNoche24a01_12_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_12 === 'X'){vReporteTurnoNoche01a02_12='0.5'}else{vReporteTurnoNoche01a02_12=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_12_2 === 'X'){vReporteTurnoNoche01a02_12_2='0.5'}else{vReporteTurnoNoche01a02_12_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_12 === 'X'){vReporteTurnoNoche02a03_12='0.5'}else{vReporteTurnoNoche02a03_12=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_12_2 === 'X'){vReporteTurnoNoche02a03_12_2='0.5'}else{vReporteTurnoNoche02a03_12_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_12 === 'X'){vReporteTurnoNoche03a04_12='0.5'}else{vReporteTurnoNoche03a04_12=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_12_2 === 'X'){vReporteTurnoNoche03a04_12_2='0.5'}else{vReporteTurnoNoche03a04_12_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_12 === 'X'){vReporteTurnoNoche04a05_12='0.5'}else{vReporteTurnoNoche04a05_12=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_12_2 === 'X'){vReporteTurnoNoche04a05_12_2='0.5'}else{vReporteTurnoNoche04a05_12_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_12 === 'X'){vReporteTurnoNoche05a06_12='0.5'}else{vReporteTurnoNoche05a06_12=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_12_2 === 'X'){vReporteTurnoNoche05a06_12_2='0.5'}else{vReporteTurnoNoche05a06_12_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_12 === 'X'){vReporteTurnoNoche06a07_12='0.5'}else{vReporteTurnoNoche06a07_12=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_12_2 === 'X'){vReporteTurnoNoche06a07_12_2='0.5'}else{vReporteTurnoNoche06a07_12_2=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_12 === 'X'){vReporteTurnoNoche07a08_12='0.5'}else{vReporteTurnoNoche07a08_12=''}
      if (pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_12_2 === 'X'){vReporteTurnoNoche07a08_12_2='0.5'}else{vReporteTurnoNoche07a08_12_2=''}

      //let consolidadoFondoX2 = parseInt(this.props.consolidadoX) + parseInt(this.props.consolidadoFondoX)
      let consolidadoFondoX2 = parseInt(this.props.consolidadoFondoX2)

      let borradorX = true
      if (this.props.cReporteTurnoSignatureFirmante1 &&
          this.props.cReporteTurnoSignatureFirmante2 &&
          this.props.cReporteTurnoSignatureFirmante3 &&
          this.props.cReporteTurnoSignatureFirmante4 &&
          this.props.cReporteTurnoSignatureFirmante5 &&
          this.props.cReporteTurnoSignatureFirmante6 &&
          this.props.cReporteTurnoSignatureFirmante7){
          borradorX = false
      }

      return (
        <Document>
           <Page size="A4" orientation="landscape" style={styles.body} wrap>
             {borradorX ?
               <Text style={styles.header} fixed> ~ B O R R A D O R ~ </Text>
             :
               null
             }
             <View>
               <Text style={styles.row10table}>
                 <Image
                   style={styles.imagesign}
                   src="images/mineraldrilling.png"
                 />
               </Text>
             </View>
             <Text style={styles.tittle}>{certificateTittleX}</Text>
             <Text style={styles.text8}>{`Fecha: ${this.props.fecha}`}</Text>

             <View style={styles.table}>
                 <View style={[styles.rowtable, styles.boldtable, styles.headertable]}>
                   <Text style={styles.row1table}>{'Cliente'}</Text>
                   <Text style={styles.row2table}>{'N° Contrato'}</Text>
                   <Text style={styles.row3table}>{'Campaña'}</Text>
                   <Text style={styles.row4table}>{'Sondaje'}</Text>
                   <Text style={styles.row5table}>{'Tipo Perforación'}</Text>
                   <Text style={styles.row6table}>{'Fase'}</Text>
                   <Text style={styles.row7table}>{'Inclinación'}</Text>
                 </View>

                 <View style={styles.rowtable} wrap={false}>
                   <Text style={styles.row1table}><Text style={styles.boldtable}>{this.props.workordercliente}</Text></Text>
                   <Text style={styles.row2table}>{this.props.workordernumerocontrato}</Text>
                   <Text style={styles.row3table}>{this.props.workordercampaña}</Text>
                   <Text style={styles.row4table}>{this.props.workordersondaje}</Text>
                   <Text style={styles.row5table}>{this.props.workordertipoperforacion}</Text>
                   <Text style={styles.row6table}>{this.props.workorderfase}</Text>
                   <Text style={styles.row7table}>{this.props.workorderdeclinacion}</Text>
                 </View>
             </View>
             <Text style={styles.text}>{``}</Text>
             <View style={styles.table}>
                 <View style={[styles.rowtable, styles.boldtable, styles.headertable]}>
                   <Text style={styles.row1table}>{''}</Text>
                   <Text style={styles.row2table}>{''}</Text>
                   <Text style={styles.row3table}>{''}</Text>
                   <Text style={styles.row4table}>{''}</Text>
                   <Text style={styles.row5table}>{''}</Text>
                   <Text style={styles.row6table}>{'MTS. TURNO:'}</Text>
                   <Text style={styles.row7table}>{this.props.consolidadoX}</Text>
                 </View>

                 <View style={[styles.rowtable, styles.boldtable, styles.headertable]}>
                   <Text style={styles.row1table}></Text>
                   <Text style={styles.row2table}></Text>
                   <Text style={styles.row3table}></Text>
                   <Text style={styles.row4table}></Text>
                   <Text style={styles.row5table}></Text>
                   <Text style={styles.row6table}>{'MTS. FONDO:'}</Text>
                   <Text style={styles.row7table}>{consolidadoFondoX2}</Text>
                 </View>

                 <View style={[styles.rowtable, styles.boldtable, styles.headertable]}>
                   <Text style={styles.row1table}></Text>
                   <Text style={styles.row2table}></Text>
                   <Text style={styles.row3table}></Text>
                   <Text style={styles.row4table}></Text>
                   <Text style={styles.row5table}></Text>
                   <Text style={styles.row6table}>{'ESTADO SONDAJE:'}</Text>
                   <Text style={styles.row7table}>{vReporteTurnoEstadoSondaje}</Text>
                 </View>
             </View>

             <Text style={styles.text8}>{`TURNO DIA`}</Text>

             <View style={styles.table3}>
                 <View style={[styles.rowtable3, styles.boldtable, styles.headertable3]}>
                   <Text style={styles.row101table}>{'SUPERVISOR'}</Text>
                   <Text style={styles.row102table}>{'JEFE TURNO'}</Text>
                   <Text style={styles.row103table}>{'ASESOR HSE'}</Text>
                   <Text style={styles.row104table}>{'MTS.DESDE'}</Text>
                   <Text style={styles.row105table}>{'MTS.HASTA'}</Text>
                   <Text style={styles.row106table}>{'PERFORADOS'}</Text>
                   <Text style={styles.row107table}>{''}</Text>
                   <Text style={styles.row108table}>{''}</Text>
                   <Text style={styles.row109table}>{''}</Text>
                 </View>

                 <View style={styles.rowtable3} wrap={false}>
                   <Text style={styles.row101table}>{pdfReporteTurnoDiaX.vReporteTurnoDia04}</Text>
                   <Text style={styles.row102table}>{pdfReporteTurnoDiaX.vReporteTurnoDia01}</Text>
                   <Text style={styles.row103table}>{pdfReporteTurnoDiaX.vReporteTurnoDia05}</Text>
                   <Text style={styles.row104table}>{pdfReporteTurnoDiaX.vReporteTurnoDia06}</Text>
                   <Text style={styles.row105table}>{pdfReporteTurnoDiaX.vReporteTurnoDia07}</Text>
                   <Text style={styles.row106table}>{pdfReporteTurnoDiaX.vReporteTurnoDia08}</Text>
                   <Text style={styles.row107table}>{''}</Text>
                   <Text style={styles.row108table}>{''}</Text>
                   <Text style={styles.row109table}>{''}</Text>
                 </View>
             </View>

             <View style={styles.table3}>
                 <View style={[styles.rowtable3, styles.boldtable, styles.headertable3]}>
                   <Text style={styles.row101table}>{'OPERADOR'}</Text>
                   <Text style={styles.row102table}>{'AYUDANTE 1'}</Text>
                   <Text style={styles.row103table}>{'AYUDANTE 2'}</Text>
                   <Text style={styles.row104table}>{'HOROM.DESDE'}</Text>
                   <Text style={styles.row105table}>{'HOROM.HASTA'}</Text>
                   <Text style={styles.row106table}>{'META'}</Text>
                   <Text style={styles.row107table}>{'ESTADO POZO'}</Text>
                   <Text style={styles.row108table}>{''}</Text>
                   <Text style={styles.row109table}>{''}</Text>
                 </View>

                 <View style={styles.rowtable3} wrap={false}>
                   <Text style={styles.row101table}>{vReporteTurnoDia12}</Text>
                   <Text style={styles.row102table}>{pdfReporteTurnoDiaX.vReporteTurnoDia02}</Text>
                   <Text style={styles.row103table}>{pdfReporteTurnoDiaX.vReporteTurnoDia03}</Text>
                   <Text style={styles.row104table}>{pdfReporteTurnoDiaX.vReporteTurnoDia09}</Text>
                   <Text style={styles.row105table}>{pdfReporteTurnoDiaX.vReporteTurnoDia10}</Text>
                   <Text style={styles.row106table}>{pdfReporteTurnoDiaX.vReporteTurnoDia11}</Text>
                   <Text style={styles.row107table}>{vReporteTurnoDia13}</Text>
                   <Text style={styles.row108table}>{''}</Text>
                   <Text style={styles.row109table}>{''}</Text>
                 </View>
             </View>

             <View style={styles.table4}>
                 <View style={[styles.rowtable4, styles.boldtable, styles.headertable4]}>
                   <Text style={styles.row401btable}>{'Horario'}</Text>
                   <Text style={styles.row402table}>{'Actividad'}</Text>
                   <Text style={styles.row403table}>{'Perforación'}</Text>
                   <Text style={styles.row404table}>{'Cond.Humedad'}</Text>
                   <Text style={styles.row405table}>{'Acero Perf.'}</Text>
                   <Text style={styles.row407table}>{'Herramienta'}</Text>
                   <Text style={styles.row408table}>{'Diam. Ocup.'}</Text>
                   <Text style={styles.row409table}>{'D.O.N°Serie'}</Text>
                   <Text style={styles.row410table}>{'Mt.Inicio'}</Text>
                   <Text style={styles.row411table}>{'Mt.Fin'}</Text>
                   <Text style={styles.row412table}>{'Aclaración'}</Text>
                   <Text style={styles.row413table}>{'HH MD'}</Text>
                   <Text style={styles.row414table}>{'HH AA'}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'8:00 a 8:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia08a09_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia08a09_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia08a09_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia08a09_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia08a09_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia08a09_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia08a09_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia08a09_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia08a09_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia08a09_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia08a09_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia08a09_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'8:30 a 9:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia08a09_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia08a09_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia08a09_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia08a09_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia08a09_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia08a09_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia08a09_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia08a09_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia08a09_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia08a09_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia08a09_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia08a09_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'9:00 a 9:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia09a10_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia09a10_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia09a10_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia09a10_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia09a10_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia09a10_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia09a10_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia09a10_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia09a10_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia09a10_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia09a10_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia09a10_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'9:30 a 10:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia09a10_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia09a10_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia09a10_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia09a10_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia09a10_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia09a10_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia09a10_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia09a10_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia09a10_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia09a10_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia09a10_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia09a10_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'10:00 a 10:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia10a11_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia10a11_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia10a11_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia10a11_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia10a11_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia10a11_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia10a11_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia10a11_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia10a11_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia10a11_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia10a11_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia10a11_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'10:30 a 11:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia10a11_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia10a11_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia10a11_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia10a11_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia10a11_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia10a11_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia10a11_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia10a11_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia10a11_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia10a11_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia10a11_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia10a11_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'11:00 a 11:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia11a12_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia11a12_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia11a12_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia11a12_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia11a12_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia11a12_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia11a12_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia11a12_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia11a12_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia11a12_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia11a12_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia11a12_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'11:30 a 12:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia11a12_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia11a12_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia11a12_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia11a12_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia11a12_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia11a12_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia11a12_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia11a12_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia11a12_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia11a12_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia11a12_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia11a12_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'12:00 a 12:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia12a13_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia12a13_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia12a13_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia12a13_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia12a13_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia12a13_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia12a13_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia12a13_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia12a13_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia12a13_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia12a13_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia12a13_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'12:30 a 13:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia12a13_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia12a13_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia12a13_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia12a13_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia12a13_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia12a13_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia12a13_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia12a13_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia12a13_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia12a13_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia12a13_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia12a13_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'13:00 a 13:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia13a14_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia13a14_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia13a14_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia13a14_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia13a14_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia13a14_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia13a14_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia13a14_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia13a14_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia13a14_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia13a14_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia13a14_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'13:30 a 14:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia13a14_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia13a14_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia13a14_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia13a14_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia13a14_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia13a14_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia13a14_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia13a14_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia13a14_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia13a14_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia13a14_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia13a14_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'14:00 a 14:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia14a15_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia14a15_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia14a15_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia14a15_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia14a15_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia14a15_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia14a15_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia14a15_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia14a15_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia14a15_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia14a15_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia14a15_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'14:30 a 15:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia14a15_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia14a15_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia14a15_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia14a15_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia14a15_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia14a15_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia14a15_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia14a15_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia14a15_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia14a15_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia14a15_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia14a15_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'15:00 a 15:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia15a16_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia15a16_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia15a16_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia15a16_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia15a16_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia15a16_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia15a16_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia15a16_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia15a16_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia15a16_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia15a16_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia15a16_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'15:30 a 16:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia15a16_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia15a16_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia15a16_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia15a16_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia15a16_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia15a16_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia15a16_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia15a16_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia15a16_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia15a16_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia15a16_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia15a16_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'16:00 a 16:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia16a17_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia16a17_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia16a17_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia16a17_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia16a17_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia16a17_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia16a17_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia16a17_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia16a17_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia16a17_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia16a17_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia16a17_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'16:30 a 17:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia16a17_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia16a17_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia16a17_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia16a17_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia16a17_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia16a17_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia16a17_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia16a17_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia16a17_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia16a17_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia16a17_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia16a17_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'17:00 a 17:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia17a18_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia17a18_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia17a18_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia17a18_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia17a18_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia17a18_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia17a18_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia17a18_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia17a18_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia17a18_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia17a18_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia17a18_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'17:30 a 18:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia17a18_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia17a18_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia17a18_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia17a18_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia17a18_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia17a18_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia17a18_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia17a18_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia17a18_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia17a18_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia17a18_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia17a18_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'18:00 a 18:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia18a19_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia18a19_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia18a19_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia18a19_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia18a19_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia18a19_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia18a19_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia18a19_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia18a19_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia18a19_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia18a19_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia18a19_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'18:30 a 19:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia18a19_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia18a19_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia18a19_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia18a19_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia18a19_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia18a19_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia18a19_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia18a19_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia18a19_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia18a19_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia18a19_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia18a19_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'19:00 a 19:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia19a20_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia19a20_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia19a20_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia19a20_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia19a20_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia19a20_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia19a20_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia19a20_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia19a20_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia19a20_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia19a20_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia19a20_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401table}>{'19:30 a 20:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoDia19a20_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoDia19a20_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoDia19a20_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoDia19a20_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoDia19a20_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoDia19a20_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoDiaX.vReporteTurnoDia19a20_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoDiaX.vReporteTurnoDia19a20_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoDiaX.vReporteTurnoDia19a20_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoDiaX.vReporteTurnoDia19a20_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoDia19a20_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoDia19a20_12_2}</Text>
                 </View>

                 <View><Text>{" "}</Text></View>
             </View>

             <Text style={styles.text8}>{`TURNO NOCHE`}</Text>

             <View style={styles.table3}>
                 <View style={[styles.rowtable3, styles.boldtable, styles.headertable3]}>
                   <Text style={styles.row101table}>{'SUPERVISOR'}</Text>
                   <Text style={styles.row102table}>{'JEFE TURNO'}</Text>
                   <Text style={styles.row103table}>{'ASESOR HSE'}</Text>
                   <Text style={styles.row104table}>{'MTS.DESDE'}</Text>
                   <Text style={styles.row105table}>{'MTS.HASTA'}</Text>
                   <Text style={styles.row106table}>{'PERFORADOS'}</Text>
                   <Text style={styles.row107table}>{''}</Text>
                   <Text style={styles.row108table}>{''}</Text>
                   <Text style={styles.row109table}>{''}</Text>
                 </View>

                 <View style={styles.rowtable3} wrap={false}>
                   <Text style={styles.row101table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche04}</Text>
                   <Text style={styles.row102table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche01}</Text>
                   <Text style={styles.row103table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche05}</Text>
                   <Text style={styles.row104table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche06}</Text>
                   <Text style={styles.row105table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche07}</Text>
                   <Text style={styles.row106table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche08}</Text>
                   <Text style={styles.row107table}>{''}</Text>
                   <Text style={styles.row108table}>{''}</Text>
                   <Text style={styles.row109table}>{''}</Text>
                 </View>
             </View>

             <View style={styles.table3}>
                 <View style={[styles.rowtable3, styles.boldtable, styles.headertable3]}>
                   <Text style={styles.row101table}>{'OPERADOR'}</Text>
                   <Text style={styles.row102table}>{'AYUDANTE 1'}</Text>
                   <Text style={styles.row103table}>{'AYUDANTE 2'}</Text>
                   <Text style={styles.row104table}>{'HOROM.DESDE'}</Text>
                   <Text style={styles.row105table}>{'HOROM.HASTA'}</Text>
                   <Text style={styles.row106table}>{'META'}</Text>
                   <Text style={styles.row107table}>{'ESTADO POZO'}</Text>
                   <Text style={styles.row108table}>{''}</Text>
                   <Text style={styles.row109table}>{''}</Text>
                 </View>

                 <View style={styles.rowtable3} wrap={false}>
                   <Text style={styles.row101table}>{vReporteTurnoNoche12}</Text>
                   <Text style={styles.row102table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche02}</Text>
                   <Text style={styles.row103table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche03}</Text>
                   <Text style={styles.row104table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche09}</Text>
                   <Text style={styles.row105table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche10}</Text>
                   <Text style={styles.row106table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche11}</Text>
                   <Text style={styles.row107table}>{vReporteTurnoNoche13}</Text>
                   <Text style={styles.row108table}>{''}</Text>
                   <Text style={styles.row109table}>{''}</Text>
                 </View>
             </View>

             <View style={styles.table4}>
                 <View style={[styles.rowtable4, styles.boldtable, styles.headertable4]}>
                   <Text style={styles.row401btable}>{'Horario'}</Text>
                   <Text style={styles.row402table}>{'Actividad'}</Text>
                   <Text style={styles.row403table}>{'Perforación'}</Text>
                   <Text style={styles.row404table}>{'Cond.Humedad'}</Text>
                   <Text style={styles.row405table}>{'Acero Perf.'}</Text>
                   <Text style={styles.row407table}>{'Herramienta'}</Text>
                   <Text style={styles.row408table}>{'Diam. Ocup.'}</Text>
                   <Text style={styles.row409table}>{'D.O.N°Serie'}</Text>
                   <Text style={styles.row410table}>{'Mt.Inicio'}</Text>
                   <Text style={styles.row411table}>{'Mt.Fin'}</Text>
                   <Text style={styles.row412table}>{'Aclaración'}</Text>
                   <Text style={styles.row413table}>{'HH MD'}</Text>
                   <Text style={styles.row414table}>{'HH AA'}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'20:00 a 20:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche20a21_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche20a21_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche20a21_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche20a21_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche20a21_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche20a21_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche20a21_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche20a21_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'20:30 a 21:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche20a21_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche20a21_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche20a21_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche20a21_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche20a21_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche20a21_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche20a21_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche20a21_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche20a21_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'21:00 a 21:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche21a22_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche21a22_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche21a22_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche21a22_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche21a22_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche21a22_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche21a22_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche21a22_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'21:30 a 22:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche21a22_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche21a22_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche21a22_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche21a22_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche21a22_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche21a22_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche21a22_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche21a22_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche21a22_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'22:00 a 22:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche22a23_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche22a23_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche22a23_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche22a23_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche22a23_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche22a23_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche22a23_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche22a23_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'22:30 a 23:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche22a23_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche22a23_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche22a23_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche22a23_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche22a23_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche22a23_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche22a23_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche22a23_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche22a23_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'23:00 a 23:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche23a24_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche23a24_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche23a24_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche23a24_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche23a24_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche23a24_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche23a24_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche23a24_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'23:30 a 24:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche23a24_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche23a24_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche23a24_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche23a24_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche23a24_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche23a24_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche23a24_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche23a24_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche23a24_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'24:00 a 00:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche24a01_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche24a01_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche24a01_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche24a01_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche24a01_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche24a01_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche24a01_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche24a01_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'00:30 a 01:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche24a01_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche24a01_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche24a01_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche24a01_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche24a01_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche24a01_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche24a01_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche24a01_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche24a01_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'01:00 a 01:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche01a02_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche01a02_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche01a02_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche01a02_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche01a02_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche01a02_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche01a02_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche01a02_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'01:30 a 02:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche01a02_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche01a02_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche01a02_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche01a02_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche01a02_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche01a02_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche01a02_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche01a02_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche01a02_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'02:00 a 02:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche02a03_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche02a03_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche02a03_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche02a03_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche02a03_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche02a03_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche02a03_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche02a03_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'02:30 a 03:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche02a03_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche02a03_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche02a03_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche02a03_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche02a03_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche02a03_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche02a03_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche02a03_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche02a03_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'03:00 a 03:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche03a04_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche03a04_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche03a04_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche03a04_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche03a04_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche03a04_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche03a04_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche03a04_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'03:30 a 04:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche03a04_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche03a04_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche03a04_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche03a04_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche03a04_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche03a04_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche03a04_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche03a04_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche03a04_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'04:00 a 04:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche04a05_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche04a05_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche04a05_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche04a05_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche04a05_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche04a05_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche04a05_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche04a05_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'04:30 a 05:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche04a05_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche04a05_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche04a05_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche04a05_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche04a05_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche04a05_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche04a05_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche04a05_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche04a05_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'05:00 a 05:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche05a06_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche05a06_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche05a06_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche05a06_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche05a06_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche05a06_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche05a06_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche05a06_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'05:30 a 06:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche05a06_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche05a06_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche05a06_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche05a06_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche05a06_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche05a06_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche05a06_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche05a06_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche05a06_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'06:00 a 06:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche06a07_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche06a07_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche06a07_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche06a07_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche06a07_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche06a07_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche06a07_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche06a07_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'06:30 a 07:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche06a07_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche06a07_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche06a07_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche06a07_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche06a07_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche06a07_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche06a07_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche06a07_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche06a07_12_2}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'07:00 a 07:30'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche07a08_01}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche07a08_02}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche07a08_03}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche07a08_06}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche07a08_04}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche07a08_05}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_13}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_08}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_09}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_10}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche07a08_11}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche07a08_12}</Text>
                 </View>

                 <View style={styles.rowtable4a} wrap={false}>
                   <Text style={styles.row401atable}>{'07:30 a 08:00'}</Text>
                   <Text style={styles.row402table}>{vReporteTurnoNoche07a08_01_2}</Text>
                   <Text style={styles.row403table}>{vReporteTurnoNoche07a08_02_2}</Text>
                   <Text style={styles.row404table}>{vReporteTurnoNoche07a08_03_2}</Text>
                   <Text style={styles.row405table}>{vReporteTurnoNoche07a08_06_2}</Text>
                   <Text style={styles.row407table}>{vReporteTurnoNoche07a08_04_2}</Text>
                   <Text style={styles.row408table}>{vReporteTurnoNoche07a08_05_2}</Text>
                   <Text style={styles.row409table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_13_2}</Text>
                   <Text style={styles.row410table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_08_2}</Text>
                   <Text style={styles.row411table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_09_2}</Text>
                   <Text style={styles.row412table}>{pdfReporteTurnoNocheX.vReporteTurnoNoche07a08_10_2}</Text>
                   <Text style={styles.row413table}>{vReporteTurnoNoche07a08_11_2}</Text>
                   <Text style={styles.row414table}>{vReporteTurnoNoche07a08_12_2}</Text>
                 </View>


                 <Text style={styles.text}>{`${parrafo3X}`}</Text>
             </View>
             <Text style={styles.text}>{`${parrafo3X}`}</Text>

             <View style={styles.table2}>
               <View style={[styles.rowtable2, styles.boldtable, styles.headertable2]}>
                  <Text style={styles.row10table}>
                    {this.props.cReporteTurnoSignatureFirmante1 ?
                      <Image
                        style={styles.imagesign}
                        src={this.props.cReporteTurnotrimmedDataURLFirmante1}
                      />
                    :
                      <Image
                        style={styles.imagesign}
                        src="images/signGrey25p.png"
                      />
                    }
                  </Text>
                  <Text style={styles.row11table}>
                    {this.props.cReporteTurnoSignatureFirmante6 ?
                      <Image
                        style={styles.imagesign}
                        src={this.props.cReporteTurnotrimmedDataURLFirmante6}
                      />
                    :
                      <Image
                        style={styles.imagesign}
                        src="images/signGrey25p.png"
                      />
                    }
                  </Text>
               </View>
               <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`SUPERVISOR TURNO DÍA`}</Text>
                  <Text style={styles.row11table}>{`JEFE TURNO DÍA`}</Text>
               </View>
               <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`Firmado por: ${this.props.cReporteTurnoNameFirmante1}`}</Text>
                  <Text style={styles.row11table}>{`Firmado por: ${this.props.cReporteTurnoNameFirmante6}`}</Text>
               </View>
               <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`El día: ${this.props.cReporteTurnoDateTimeFirmante1}`}</Text>
                  <Text style={styles.row11table}>{`El día: ${this.props.cReporteTurnoDateTimeFirmante6}`}</Text>
               </View>
            </View>
            <View><Text>{" "}</Text></View>
            <View style={styles.table}>
               <View style={[styles.rowtable2, styles.boldtable, styles.headertable2]}>
                  <Text style={styles.row10table}>
                    {this.props.cReporteTurnoSignatureFirmante2 ?
                      <Image
                        style={styles.imagesign}
                        src={this.props.cReporteTurnotrimmedDataURLFirmante2}
                      />
                    :
                      <Image
                        style={styles.imagesign}
                        src="images/signGrey25p.png"
                      />
                    }
                  </Text>
                  <Text style={styles.row11table}>
                    {this.props.cReporteTurnoSignatureFirmante7 ?
                      <Image
                        style={styles.imagesign}
                        src={this.props.cReporteTurnotrimmedDataURLFirmante7}
                      />
                    :
                      <Image
                        style={styles.imagesign}
                        src="images/signGrey25p.png"
                      />
                    }
                  </Text>
              </View>
              <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`SUPERVISOR TURNO NOCHE`}</Text>
                  <Text style={styles.row11table}>{`JEFE TURNO NOCHE`}</Text>
              </View>
              <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`Firmado por: ${this.props.cReporteTurnoNameFirmante2}`}</Text>
                  <Text style={styles.row11table}>{`Firmado por: ${this.props.cReporteTurnoNameFirmante7}`}</Text>
              </View>
              <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`El día: ${this.props.cReporteTurnoDateTimeFirmante2}`}</Text>
                  <Text style={styles.row11table}>{`El día: ${this.props.cReporteTurnoDateTimeFirmante7}`}</Text>
              </View>
            </View>
            <View><Text>{" "}</Text></View>

            <View style={styles.table}>
              <View style={[styles.rowtable2, styles.boldtable, styles.headertable2]}>
                <Text style={styles.row10table}>
                  {this.props.cReporteTurnoSignatureFirmante4 ?
                    <Image
                      style={styles.imagesign}
                      src={this.props.cReporteTurnotrimmedDataURLFirmante4}
                    />
                  :
                    <Image
                      style={styles.imagesign}
                      src="images/signGrey25p.png"
                    />
                  }
                </Text>
                <Text style={styles.row11table}>
                  {this.props.cReporteTurnoSignatureFirmante3 ?
                    <Image
                      style={styles.imagesign}
                      src={this.props.cReporteTurnotrimmedDataURLFirmante3}
                    />
                  :
                    <Image
                      style={styles.imagesign}
                      src="images/signGrey25p.png"
                    />
                  }
                </Text>
              </View>
              <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`JEFE TURNO CONTROL SONDAJE`}</Text>
                  <Text style={styles.row11table}>{`ADM. CTO. MD`}</Text>
              </View>
              <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`Firmado por: ${this.props.cReporteTurnoNameFirmante4}`}</Text>
                  <Text style={styles.row11table}>{`Firmado por: ${this.props.cReporteTurnoNameFirmante3}`}</Text>
              </View>
              <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`El día: ${this.props.cReporteTurnoDateTimeFirmante4}`}</Text>
                  <Text style={styles.row11table}>{`El día: ${this.props.cReporteTurnoDateTimeFirmante3}`}</Text>
              </View>
            </View>
            <View><Text>{" "}</Text></View>

            <View style={styles.table}>
               <View style={[styles.rowtable2, styles.boldtable, styles.headertable2]}>
                  <Text style={styles.row10table}>
                    {this.props.cReporteTurnoSignatureFirmante5 ?
                      <Image
                        style={styles.imagesign}
                        src={this.props.cReporteTurnotrimmedDataURLFirmante5}
                      />
                    :
                      <Image
                        style={styles.imagesign}
                        src="images/signGrey25p.png"
                      />
                    }
                  </Text>
               </View>
               <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`ADM. CTO. AA`}</Text>
               </View>
                <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`Firmado por: ${this.props.cReporteTurnoNameFirmante5}`}</Text>
               </View>
               <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`El día: ${this.props.cReporteTurnoDateTimeFirmante5}`}</Text>
               </View>
            </View>
            <View><Text>{" "}</Text></View>


             <Text style={styles.text6}>{`Crosscheck ID: ${this.props.typeContract}/${this.props.numberContract}/ReporteTurno`}</Text>
          </Page>
        </Document>
      )
    }
}
export default ReporteTurno;
