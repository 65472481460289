//React
import React, { Component } from 'react';

//Redux
import { withRouter } from "react-router-dom";

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Form, Card, Container, FormSelect, Button, InputGroupText, InputGroupAddon, FormRadio, FormCheckbox } from "shards-react";
import { Table } from 'reactstrap';
import { Dialog,DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';

import NewMasterFile from "../masterfiles/NewMasterFile";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//translate
import { FormattedMessage } from 'react-intl';

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

class ConceptManagement extends Component {

    constructor(props) {
        super(props);

        this.goMasterFile = this.goMasterFile.bind(this);
        this.clickRemoveMasterFile = this.clickRemoveMasterFile.bind(this);
        this.clickEditMasterFile = this.clickEditMasterFile.bind(this);
        this.crearMasterFile = this.crearMasterFile.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          opcionMasterFile: '',
          displayMasterFile: false,
          jsonBlockstack: [],
          jsonBlockstack2: [],
          typeMasterFile: '',
          selectedConfiguration: '',

          dialogOpen: false,
          dialogMessage: '',
          dialogTitle: '',
          language: 'English',
          templatesAllLocal: [],
          code: '',
          description: '',
          modifying: false,
          file: '',
          jsonRol: '',
        }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val},()=>{
          Promise.all([this.goMasterFile(val)])
             .then(
                (resolve) => {},
                (reject) => {}
             )
        })) ;
      }else{
        this.setState({ username },()=>{
          Promise.all([this.goMasterFile(username)])
             .then(
                (resolve) => {},
                (reject) => {}
             )

        })
      }
      this.setState({code:'',description:''})
      this.setState({typeMasterFile: "concepts_payment_forms"})
    }

    goMasterFile= (username) => {
      this.setState({modifying: false})
      let typeMasterFile2 = ''
      this.setState({opcionMasterFile: 'Concepts for Payment Forms',typeMasterFile: 'concepts_payment_forms'})
      typeMasterFile2 = 'concepts_payment_forms'

      this.setState({jsonBlockstack: [],displayMasterFile: true})
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      localStorage.removeItem('jsonBlockstack20')
      getFileDB(username, `${typeMasterFile2}.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             localStorage.setItem('jsonBlockstack20',jsonBlockstack5)
             this.setState({jsonBlockstack: jsonBlockstack6})
           } else {}
         })
        .catch(error => {});
    }

    clickRemoveMasterFile = (e,optx, codex) => {
        const masterfile = this.state.jsonBlockstack
        masterfile.splice(optx,1);
        this.setState({jsonBlockstack: masterfile})
        const jsonBlockstack1 = JSON.stringify(masterfile)
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
        putFileDB(this.state.username, `${this.state.typeMasterFile}.json`, 'Crosscheck_PaymentForm', jsonBlockstack1, JSON.stringify(options))
          .then(() => {
             localStorage.setItem('jsonBlockstack20',jsonBlockstack1)
          })
        this.setState({modifying: false})
    }

    crearMasterFile = (infoMasterFile) => {
      this.setState({jsonBlockstack: JSON.parse(infoMasterFile),
                     code: '',
                     description: '',
                     modifying: false,
                     jsonRol: {}})
      localStorage.setItem('jsonBlockstack20',infoMasterFile)
    }

    clickEditMasterFile = (e,optx) => {
        const {jsonBlockstack} = this.state
        const {code} = jsonBlockstack[optx]
        const {description} = jsonBlockstack[optx]
        this.setState({
            code: code,
            description: description,
            modifying: true,
        })
    }

    exitConceptManager = (e) => {
      this.props.exitManagementConceptPaymentForm()
    }

    render() {
        const {jsonBlockstack} = this.state
        return (
          <div>
            <Container fluid className="main-content-container px-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
              <Row>
                <Col>
                  <Form style={{fontSize:13}}>
                    <Row form>
                      <Col md="3"> </Col>
                      <Col md="9">
                        <label>&nbsp;</label>
                        <NewMasterFile typeMasterFile={this.state.typeMasterFile} crearMasterFile={this.crearMasterFile} opcionMasterFile={this.state.opcionMasterFile} jsonBlockstack={this.state.jsonBlockstack} code={this.state.code} description={this.state.description} jsonRol={this.state.jsonRol} modifying={this.state.modifying} colorDark={this.props.colorDark} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="1"></Col>
                      <Col md="10">
                         <label>&nbsp;</label>
                         <Table size="sm" className="text-center" responsive hover striped>
                          <thead>
                            <tr>
                              <th style={{ width: "30px",fontSize:16}}><FormattedMessage id="configuration.code" /></th>
                              <th style={{ width: "160px",fontSize:16 }}><FormattedMessage id="configuration.description" /></th>
                              <th style={{ width: "20px",fontSize:16 }}><FormattedMessage id="configuration.edit" /></th>
                              <th style={{ width: "20px",fontSize:16 }}><FormattedMessage id="configuration.delete" /></th>
                            </tr>
                          </thead>
                          <tbody>
                            {jsonBlockstack.map((todo, i) => {
                              let bEditDelete = true
                              if (todo.code === 'Deliverable' || todo.code === 'Advance' || todo.code === 'Payment'){
                                 bEditDelete = false
                              }
                              return (
                                  <tr key={i} style={ this.props.colorDark ? { color:'white'} : {color:'black'}}>
                                      <td>{todo.code}</td>
                                      <td>{todo.description}</td>
                                      {bEditDelete ?
                                        <>
                                          <td><Button pill theme="success" onClick={e=>this.clickEditMasterFile(e, i)}><FontAwesomeIcon icon={faEdit} /></Button></td>
                                          <td><Button pill theme="danger" onClick={e=>this.clickRemoveMasterFile(e, i, todo.code)}><FontAwesomeIcon icon={faTimes} /></Button></td>
                                        </>
                                      :
                                        <>
                                          <td></td>
                                          <td></td>
                                        </>
                                      }
                                  </tr>
                               )})}
                          </tbody>
                        </Table>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                    <td><Button pill theme="secondary" onClick={e=>this.exitConceptManager(e)}><FormattedMessage id="pay.exit" /></Button></td>
                  </Form>
                </Col>
              </Row>
              <br></br>
            </Container>
          </div>
        );
    }
}

export default withRouter(ConceptManagement);
