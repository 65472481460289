//React
import React, { Component } from 'react';

//UUID
import uuid from 'uuid';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit, faEye, faDownload } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Container, Button, Card, FormCheckbox } from "shards-react";
import { Table } from 'reactstrap';
import { Divider, Typography, TextField, Grid, Dialog,
        DialogContent, DialogActions, DialogTitle, DialogContentText,
        Button as ButtonMaterialUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';

import TituloPagina2 from "../common/TituloPagina2";

// blockstack
//import { UserSession } from 'blockstack';
import { writeFile, readFile } from "blockstack-large-storage";
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//Uploader
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

//translate
import { FormattedMessage } from 'react-intl';

//base64
import { Base64 } from 'js-base64';

//viewer
import MyViewer from './MyViewer';

//download
import MyDownload from './MyDownload';

//Axios
import axios from 'axios';

//adblocker
//import AdBlockDetect from 'react-ad-block-detect';

//downloader
import { triggerBase64Download } from 'react-base64-downloader';
import { FileSaver } from 'file-saver';

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB, deleteFileDB} from '../../actions/mongoDBActions'

//Configuración
import { MAINNET_STACKS_API_URL,
         TESTNET_STACKS_API_URL } from '../../config.js'

function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionError(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class ContractAttachFile extends Component {

    constructor(props) {
        super(props);

        this.getUploadParams = this.getUploadParams.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmit2 = this.handleSubmit2.bind(this);
        this.deleteAttachConfirm = this.deleteAttachConfirm.bind(this);
        this.clickRemoveAttachFiles = this.clickRemoveAttachFiles.bind(this);
        this.verAttach = this.verAttach.bind(this);
        this.shareAttach = this.shareAttach.bind(this);
        this.downloadAttach = this.downloadAttach.bind(this);
        this.downloadAttach2 = this.downloadAttach2.bind(this);
        this.downloadAttach3 = this.downloadAttach3.bind(this);
        this.exitProgram = this.exitProgram.bind(this);
        this.exitAttach = this.exitAttach.bind(this);
        this.lenghtFileError = this.lenghtFileError.bind(this);
        this.returnProps = this.returnProps.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          displayAttach: false,
          jsonAttachFile: [],
          jsonAttachFile1: [],
          jsonAttachFile2: [],
          language: 'English',
          seeAttachX: false,
          downloadAttachX: false,
          fileAttachX: '',
          filedecodeAttachX: '',
          typeAttachX: '',
          nameAttachX: '',
          numPages: null,
          pageNumber: 1,
          dialogMessage: '',
          dialogOpen: false,
          dialogOpenError: false,
          dialogTitle: '',
          jsonAttachFileX: [],
          jsonAttachFileXY: '',
          typeAttachText: false,
          typeAttachImage: false,
          typeAttachAudio: false,
          typeAttachVideo: false,
          typeAttachPdf: false,
          displayFormatAccepted: false,
          networkUrlX: '',
        }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }
      this.setState({networkUrlX })

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
    }

    returnProps(){
      this.setState({seeAttachX:false})
      this.setState({downloadAttachX:false})
    }

    handleDialog = (type, action) => {
       if (type === 'Confirm: 3001') {
          if (action === 'confirm') {
               this.setState({ dialogOpen: false });
               let e = ''
               if (localStorage["correlativoAttach"]) {
                 const i = localStorage.getItem('correlativoAttach')
                 const id = localStorage.getItem('idAttach')
                 this.clickRemoveAttach(e, i, id)
               }
          }else{
            if (action === 'cancel') {
             this.setState({ dialogOpen: false });
            }
          }
       }
       if (type === 'Error: 1001') {
          if (action === 'cancel') {
           this.setState({ dialogOpenError: false });
          }
       }
    }

    deleteAttachConfirm = (e,i,id) => {
      localStorage.setItem('correlativoAttach',i)
      localStorage.setItem('idAttach',id)
      if (this.state.language === 'English'){this.setState({ dialogOpen: true,  dialogMessage: 'Are you sure of delete the attach file?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'French'){this.setState({ dialogOpen: true,  dialogMessage: 'Êtes-vous sûr de supprimer le fichier joint?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Spanish'){this.setState({ dialogOpen: true,  dialogMessage: '¿Estás seguro de eliminar el archivo adjunto?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Portuguese'){this.setState({ dialogOpen: true,  dialogMessage: 'Você tem certeza de que deseja excluir o arquivo anexado?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Swedish'){this.setState({ dialogOpen: true,  dialogMessage: 'Är du säker på att ta bort bilagan?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Netherlands'){this.setState({ dialogOpen: true,  dialogMessage: 'Weet u zeker dat u het bijgevoegde bestand wilt verwijderen?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Russian'){this.setState({ dialogOpen: true,  dialogMessage: 'Вы уверены, что хотите удалить прикрепленный файл?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Japanese'){this.setState({ dialogOpen: true,  dialogMessage: '添付ファイルを削除してもよろしいですか？',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Chinese'){this.setState({ dialogOpen: true,  dialogMessage: '你确定要删除附件吗？',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'German'){this.setState({ dialogOpen: true,  dialogMessage: 'Sind Sie sicher, dass Sie die angehängte Datei löschen möchten?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Italian'){this.setState({ dialogOpen: true,  dialogMessage: 'Sei sicuro di eliminare il file allegato?',dialogTitle: 'Confirm: 3001',seeAttachX: false,downloadAttachX: false,})}
    }

    lenghtFileError() {
      if (this.state.language === 'English'){this.setState({ dialogOpenError: true,  dialogMessage: 'Long file exceeds the maximum allowed',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'French'){this.setState({ dialogOpenError: true,  dialogMessage: 'Le fichier long dépasse le maximum autorisé',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Spanish'){this.setState({ dialogOpenError: true,  dialogMessage: 'Largo archivo supera el máximo permitido',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Portuguese'){this.setState({ dialogOpenError: true,  dialogMessage: 'O arquivo longo excede o máximo permitido',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Swedish'){this.setState({ dialogOpenError: true,  dialogMessage: 'Lång fil överstiger det maximalt tillåtna',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Netherlands'){this.setState({ dialogOpenError: true,  dialogMessage: 'Lang bestand overschrijdt het toegestane maximum',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Russian'){this.setState({ dialogOpenError: true,  dialogMessage: 'Длина файла превышает максимально допустимый',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Japanese'){this.setState({ dialogOpenError: true,  dialogMessage: '長いファイルが許可されている最大値を超えています',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Chinese'){this.setState({ dialogOpenError: true,  dialogMessage: '长文件超过了允许的最大值',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'German'){this.setState({ dialogOpenError: true,  dialogMessage: 'Lange Datei überschreitet die maximal zulässige',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})}
      if (this.state.language === 'Italian'){this.setState({ dialogOpenError: true,  dialogMessage: 'Il file lungo supera il massimo consentito',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})}
    }

    verAttach = (e,id,type,filename,username) => {
      if (type === 'text/plain' || type.substring(0,5) === 'image' || type.substring(0,5) === 'audio' || type.substring(0,5) === 'video' || type === 'application/pdf'){
          this.setState({ typeAttachText: false })
          this.setState({ typeAttachImage: false })
          this.setState({ typeAttachAudio: false })
          this.setState({ typeAttachVideo: false })
          this.setState({ typeAttachPdf: false })

          let username2 = username
          if (username === '' || username === null || username === undefined ){
            username2 = this.state.username
          }
          getFileDB(username2, `${this.props.typeContract}_${this.props.numberContract}_${id}_attachlist_files.json`)
             .then((fileContents) => {
               if(fileContents) {
                   const jsonBlockstack1 = JSON.parse(fileContents)
                   const jsonBlockstack2 = jsonBlockstack1.data
                   let jsonBlockstack5 = '[]'
                   if (jsonBlockstack2 !== null){
                      const jsonBlockstack3 = jsonBlockstack2.dataobject
                      const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                      if (jsonBlockstack4.substring(0,1) === '"') {
                         jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                      } else {
                         jsonBlockstack5 = jsonBlockstack4
                      }
                   }
                   let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                   let file = jsonBlockstack6.file
                   let filebtoa = jsonBlockstack6.filebtoa
                   const fileDecode = Base64.decode(file)
                   this.setState({fileAttachX:file})
                   this.setState({filedecodeAttachX:fileDecode})
                   this.setState({typeAttachX:type})
                   this.setState({nameAttachX:filename})
                   this.setState({downloadAttachX:false})
                   this.setState({seeAttachX:true})

                   if (type === 'text/plain'){
                     this.setState({ typeAttachText: true })
                   }
                   if (type.substring(0,5) === 'image'){
                     this.setState({ typeAttachImage: true })
                   }
                   if (type.substring(0,5) === 'audio'){
                     this.setState({ typeAttachAudio: true })
                   }
                   if (type.substring(0,5) === 'video'){
                     this.setState({ typeAttachVideo: true })
                   }
                   if (type === 'application/pdf'){
                     this.setState({ typeAttachPdf: true })
                   }
               }
             })
             .catch(error => {
             });
      }else{
        this.downloadAttach(e,id,type,filename,username)
      }
    }

    downloadAttach = (e,id,type,filename,username) => {
      this.setState({ typeAttachText: false })
      this.setState({ typeAttachImage: false })
      this.setState({ typeAttachAudio: false })
      this.setState({ typeAttachVideo: false })
      this.setState({ typeAttachPdf: false })

      let username2 = username
      if (username === '' || username === null || username === undefined ){
        username2 = this.state.username
      }
      getFileDB(username2, `${this.props.typeContract}_${this.props.numberContract}_${id}_attachlist_files.json`)
         .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               let file = jsonBlockstack6.file
               let filebtoa = jsonBlockstack6.filebtoa
               const fileDecode = Base64.decode(file)
               this.setState({fileAttachX:file})
               this.setState({filedecodeAttachX:fileDecode})
               this.setState({typeAttachX:type})
               this.setState({nameAttachX:filename})
               this.setState({seeAttachX:false})
               this.setState({downloadAttachX:true})

               if (type === 'text/plain'){
                 this.setState({ typeAttachText: true })
               }
               if (type.substring(0,5) === 'image'){
                 this.setState({ typeAttachImage: true })
               }
               if (type.substring(0,5) === 'audio'){
                 this.setState({ typeAttachAudio: true })
               }
               if (type.substring(0,5) === 'video'){
                 this.setState({ typeAttachVideo: true })
               }
               if (type === 'application/pdf'){
                 this.setState({ typeAttachPdf: true })
               }
           }
         })
         .catch(error => {
         });
    }

    downloadAttach2 = (e,id,type,filename,username) => {
      let username2 = username
      if (username === '' || username === null || username === undefined ){
        username2 = this.state.username
      }
      getFileDB(username2, `${this.props.typeContract}_${this.props.numberContract}_${id}_attachlist_files.json`)
         .then((fileContents) => {
           if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               let file = jsonBlockstack6.file
               let filebtoa = jsonBlockstack6.filebtoa
               const fileDecode = Base64.decode(file)
               this.setState({fileAttachX:file})
               this.setState({filedecodeAttachX:fileDecode})
               this.setState({typeAttachX:type})
               this.setState({nameAttachX:filename})
               this.setState({seeAttachX:false})
               this.setState({downloadAttachX:false})

               triggerBase64Download(fileDecode, filename)

           }
         })
         .catch(error => {
         });
    }

    downloadAttach3 = (e,id,type,filename,username) => {
      let username2 = username
      if (username === '' || username === null || username === undefined ){
        username2 = this.state.username
      }
      getFileDB(username2, `${this.props.typeContract}_${this.props.numberContract}_${id}_attachlist_files.json`)
         .then((fileContents) => {
           if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               let file = jsonBlockstack6.file
               let filebtoa = jsonBlockstack6.filebtoa
               const fileDecode = Base64.decode(file)
               this.setState({fileAttachX:file})
               this.setState({filedecodeAttachX:fileDecode})
               this.setState({typeAttachX:type})
               this.setState({nameAttachX:filename})
               this.setState({seeAttachX:false})
               this.setState({downloadAttachX:false})

               var blob = new Blob([fileDecode], {type: `${type};charset=utf-8`});
               FileSaver.saveAs(blob, `"${filename}"`);
           }
         })
         .catch(error => {
         });
    }

    shareAttach = (e,id,type,name) => {
    }

    clickRemoveAttach = (e,optx,id) => {
        let masterfile = {}
        if (this.state.jsonAttachFile2.length===0){
           masterfile = this.props.jsonAttachFile2
        }else{
           masterfile = this.state.jsonAttachFile2
        }
        masterfile.splice(optx,1);
        this.setState({jsonAttachFile2: masterfile})
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
        putFileDB(this.state.username,`${this.props.typeContract}_${this.props.numberContract}_attachlist.json`, 'Crosscheck_AttachFile', JSON.stringify(masterfile), JSON.stringify(options))
          .then(() => {
             this.clickRemoveAttachFiles(id)
          })
    }

    clickRemoveAttachFiles(id) {
      let jsonAttachFileX = this.state.jsonAttachFileX
      let jsonAttachFileX2 = ''
      jsonAttachFileX.map((todoattach2,keyattach2)=>{
          if (todoattach2.id !== id){
            if (jsonAttachFileX2 === ''){
              jsonAttachFileX2=`{"id":"${todoattach2.id}","file":"${todoattach2.file}","filebtoa":"${todoattach2.filebtoa}"}`
            }else{
              jsonAttachFileX2=`${jsonAttachFileX2},{"id":"${todoattach2.id}","file":"${todoattach2.file}","filebtoa":"${todoattach2.filebtoa}"}`
            }
          }
      })
      if (jsonAttachFileX2 === ''){
        jsonAttachFileX = `[]`
        jsonAttachFileX2 = `{}`
      }else{
        jsonAttachFileX=`[${jsonAttachFileX2}]`
        jsonAttachFileX2=`${jsonAttachFileX2}`
      }
      jsonAttachFileX = JSON.parse(jsonAttachFileX)
      this.setState({jsonAttachFileX:jsonAttachFileX})
      this.setState({jsonAttachFileXY:jsonAttachFileX2})
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      deleteFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_${id}_attachlist_files.json`)
        .then(() => {
        })
        .catch(error => {
        })

    }

    getUploadParams = () => {
        return { url: 'https://httpbin.org/post' }
    }

    handleChangeStatus = ({ meta }, status) => {
        //console.log(status, meta)
    }

    handleSubmit  = (files, allFiles) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
        let jsonAttachFile = []
        let jsonAttachFileX= this.state.jsonAttachFileXY
        if (this.state.jsonAttachFile2.length===0){
          jsonAttachFile = this.props.jsonAttachFile2
          let jsonBlockstack3 = JSON.stringify(jsonAttachFile)
          if (jsonBlockstack3.substring(0,1)==='"') {
             jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
          }
          if (jsonBlockstack3.substring(0,1)==='[') {
             jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
          }
          jsonAttachFile = jsonBlockstack3
        }else{
           jsonAttachFile = this.state.jsonAttachFile2
           let jsonBlockstack3 = JSON.stringify(jsonAttachFile)
           if (jsonBlockstack3.substring(0,1)==='"') {
              jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
           }
           if (jsonBlockstack3.substring(0,1)==='[') {
              jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
           }
           jsonAttachFile = jsonBlockstack3
        }
        let jsonBlockstackAttach = {}
        let jsonBlockstackAttach2 = {}
        let status = 'Uploaded'
        files.map((todo,i)=>{
          jsonBlockstackAttach = files[i].meta
          jsonBlockstackAttach2 = files[i].xhr.response
          jsonBlockstackAttach2 = JSON.parse(jsonBlockstackAttach2)
          //try {
            let {file} = jsonBlockstackAttach2.files
            if (file.substring(0,22)==='data:text/plain;base64'){
               file = Base64.decode(file)
            }
            const file64 = Base64.encode(file);
            //const file64btoa = window.btoa(file);
            const {id,lastModifiedDate,name,type,uploadedDate } = jsonBlockstackAttach
            if (jsonAttachFile.length === 0){
              jsonAttachFile =`{"id":"${id}","name":"${name}","type":"${type}","status":"${status}","lastModifiedDate":"${lastModifiedDate}","uploadedDate":"${uploadedDate}","userName":"${this.state.username}","deleteAttach":${true}}`
              jsonAttachFileX=`{"id":"${id}","file":"${file64}"}`
            }else{
              jsonAttachFile =`${jsonAttachFile},{"id":"${id}","name":"${name}","type":"${type}","status":"${status}","lastModifiedDate":"${lastModifiedDate}","uploadedDate":"${uploadedDate}","userName":"${this.state.username}","deleteAttach":${true}}`
              jsonAttachFileX=`${jsonAttachFileX},{"id":"${id}","file":"${file64}"}`
            }

            let jsonAttachFileX2=`{"id":"${id}","file":"${file64}"}`
            //jsonAttachFileX2=`[${jsonAttachFileX2}]`
            jsonAttachFileX2 = JSON.parse(jsonAttachFileX2)
            Promise.all(
               [this.handleSubmit2(jsonAttachFileX2,id)]
            ).then(
              (resolve) =>{},
              (reject) =>{}
            )

        })
        jsonAttachFile =`[${jsonAttachFile}]`
        jsonAttachFile = JSON.parse(jsonAttachFile)
        this.setState({jsonAttachFile2:jsonAttachFile})
        putFileDB(this.state.username,`${this.props.typeContract}_${this.props.numberContract}_attachlist.json`, 'Crosscheck_AttachFile', JSON.stringify(jsonAttachFile), JSON.stringify(options))
          .then(keyUrl => {
          })
        allFiles.forEach(f => f.remove())
    }

    handleSubmit2(jsonAttachFileX,id) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      return new Promise ((resolve, reject) =>{
        putFileDB(this.state.username,`${this.props.typeContract}_${this.props.numberContract}_${id}_attachlist_files.json`, 'Crosscheck_AttachFile', JSON.stringify(jsonAttachFileX), JSON.stringify(options))
          .then(keyUrl => {
              //console.log(`Grabación Exitosa (${this.props.typeContract}_${this.props.numberContract}_attachlist_files.json)`)
              resolve()
           })
          .catch(error => {
              reject()
           });
      })
    }

    formatAccepted = (e) =>{
      e.preventDefault();
      if (this.state.displayFormatAccepted === false){
         this.setState({displayFormatAccepted:true})
      }else{
         this.setState({displayFormatAccepted:false})
      }
    }

    exitProgram(){
       //window.location = '/'
       this.props.returnProps();
    }

    exitAttach(){
      this.setState({seeAttachX:false})
      this.setState({downloadAttachX:false})
    }

    render() {
          const {typeContract} = this.props
          const {subjectContract} = this.props
          const messageSubtittle = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`
          const messageSubtittle2 = `${this.props.typeContract}/${this.props.numberContract}`

          let jsonAttachFile1 = this.props.jsonAttachFile1
          let jsonAttachFile2 = []
          if (this.state.jsonAttachFile2.length===0){
             jsonAttachFile2 = this.props.jsonAttachFile2
          }else{
             jsonAttachFile2 = this.state.jsonAttachFile2
          }
          const displayAttachX = this.state.displayAttach
          const displayFullChargeX = true
          let correlativo = 0
          let activate = false
          let titleX = ''
          let inputContentX = ''
          let submitButtonContentX = ''
          let inputWithFilesContentX = ''
          if (this.state.language === 'English'){
            titleX = "Attach file using your private and secure storage"
            inputContentX = 'Drag Files or Click to Browse'
            submitButtonContentX = 'Submit'
            inputWithFilesContentX = 'Add Files'
          }
          if (this.state.language === 'French'){
            titleX = "Joignez un fichier à l'aide de votre stockage privé et sécurisé"
            inputContentX = 'Faites glisser les fichiers ou cliquez pour parcourir'
            submitButtonContentX = 'Soumettre'
            inputWithFilesContentX = 'Ajouter des fichiers'
          }
          if (this.state.language === 'Spanish'){
            titleX = "Adjunte archivos usando su almacenamiento privado y seguro"
            inputContentX = 'Arrastre archivos o haga clic para examinar'
            submitButtonContentX = 'Subir'
            inputWithFilesContentX = 'Agregar Archivos'
          }
          if (this.state.language === 'Portuguese'){
            titleX = "Anexar arquivo usando seu armazenamento privado e seguro"
            inputContentX = 'Arraste arquivos ou clique para procurar'
            submitButtonContentX = 'Enviar'
            inputWithFilesContentX = 'Adicionar arquivos'
          }
          if (this.state.language === 'Swedish'){
            titleX = "Bifoga filen med din privata och säkra lagring"
            inputContentX = 'Dra filer eller klicka för att bläddra'
            submitButtonContentX = 'Lämna'
            inputWithFilesContentX = 'Lägga till filer'
          }
          if (this.state.language === 'Netherlands'){
            titleX = "Voeg een bestand toe met uw privé en beveiligde opslag"
            inputContentX = 'Sleep bestanden of klik om te bladeren'
            submitButtonContentX = 'Voorleggen'
            inputWithFilesContentX = 'Bestanden toevoegen'
          }
          if (this.state.language === 'Russian'){
            titleX = "Прикрепите файл, используя ваше личное и безопасное хранилище"
            inputContentX = 'Перетащите файлы или нажмите, чтобы просмотреть'
            submitButtonContentX = 'представить'
            inputWithFilesContentX = 'Добавить файлы'
          }
          if (this.state.language === 'Japanese'){
            titleX = "プライベートで安全なストレージを使用してファイルを添付"
            inputContentX = 'ファイルをドラッグまたはクリックして参照'
            submitButtonContentX = '提出する'
            inputWithFilesContentX = '追加ファイル'
          }
          if (this.state.language === 'Chinese'){
            titleX = "使用私人和安全存储附加文件"
            inputContentX = '拖动文件或单击浏览'
            submitButtonContentX = '提交'
            inputWithFilesContentX = '新增档案'
          }
          if (this.state.language === 'German'){
            titleX = "Hängen Sie die Datei unter Verwendung Ihres privaten und sicheren Speichers an"
            inputContentX = 'Ziehen Sie Dateien oder klicken Sie zum Durchsuchen'
            submitButtonContentX = 'Einreichen'
            inputWithFilesContentX = 'Dateien hinzufügen'
          }
          if (this.state.language === 'Italian'){
            titleX = "Allega file usando il tuo archivio privato e sicuro"
            inputContentX = 'Trascina i file o fai clic per sfogliare'
            submitButtonContentX = 'Sottoscrivi'
            inputWithFilesContentX = 'Aggiungere i file'
          }

          const { filedecodeAttachX, fileAttachX, typeAttachX, nameAttachX } = this.state;
          const { typeAttachText, typeAttachImage, typeAttachPdf, typeAttachAudio, typeAttachVideo } = this.state;
          const scale = 1

          return (
            <>
              <Container fluid className="main-content-container px-4">

                  <Confirmacion
                      handleDialog={this.handleDialog}
                      dialogMessage={this.state.dialogMessage}
                      dialogOpen={this.state.dialogOpen}
                      dialogTitle={this.state.dialogTitle}
                  />

                  <ConfirmacionError
                      handleDialog={this.handleDialog}
                      dialogMessage={this.state.dialogMessage}
                      dialogOpen={this.state.dialogOpenError}
                      dialogTitle={this.state.dialogTitle}
                  />

                  <Row noGutters className="page-header py-4">
                    <TituloPagina2
                      sm="8"
                      title={titleX}
                      subtitle={messageSubtittle}
                      className="text-sm-left"
                    />
                  </Row>
                  <Row form style={{fontSize:13}}>
                     <Col md="1"></Col>
                     <Col md="3">
                       <Row>
                         <Dropzone
                            getUploadParams={this.getUploadParams}
                            onChangeStatus={this.handleChangeStatus}
                            onSubmit={this.handleSubmit}
                            style={ this.props.colorDark ? { backgroundColor:'#0b151d', dropzone: { minHeight: 200, maxHeight: 250 }} : {dropzone: { minHeight: 200, maxHeight: 250 }}}
                            inputContent={inputContentX}
                            submitButtonContent={submitButtonContentX}
                            inputWithFilesContent={inputWithFilesContentX}
                            multiple={true}
                            maxFilesize={4}
                            accept="image/jpeg,image/jpg,image/png,image/gif,image/svg+xml,video/*,audio/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.oasis.opendocument.text,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.presentation,text/plain,application/x-compressed,application/x-zip-compressed,application/zip,multipart/x-zip,application/acad, application/autocad_dwg, application/dwg, application/x-acad, application/x-autocad, application/x-dwg, image/vnd.dwg, image/x-dwg, application/x-rar-compressed, application/octet-stream"
                          />
                        </Row>
                        <Row>
                          <div className="fa fa-info-circle mx-2" style={{fontSize:10, color:'black'}}>
                            &nbsp;
                            <a id="myLink" title="Click here to see" href="#" onClick={e=>this.formatAccepted(e)}><FormattedMessage id="upload.formatsccepted" /></a>
                          </div>
                        </Row>
                        {this.state.displayFormatAccepted ?
                          <>
                            <Row>&nbsp;</Row>
                            <Row>
                              <Card small style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                                <div>
                                  <br></br>
                                  &nbsp;&nbsp;<FormattedMessage id="upload.typeformatsaccepted" />:&nbsp;&nbsp;
                                  <br></br>
                                  <br></br>
                                  <ul>
                                    <li type="disc">Adobe PDF (.pdf)</li>
                                    <li type="disc">Microsoft PowerPoint (.ppt - .pptx)</li>
                                    <li type="disc">Microsoft Word (.doc - .docx)</li>
                                    <li type="disc">Microsoft Excel (.xls -.xlsx)</li>
                                    <li type="disc">OpenOffice (.odp - .ods - .odt)</li>
                                    <li type="disc">Text (.txt/.text)</li>
                                    <li type="disc">Image (.jpg/.jpeg - .png - .gif)</li>
                                    <li type="disc">Video (.mp4)</li>
                                    <li type="disc">Audio (.mp3)</li>
                                    <li type="disc">Compressed (.zip)</li>
                                  </ul>
                                  <br></br>
                                  &nbsp;&nbsp;<FormattedMessage id="upload.important" />:&nbsp;&nbsp;
                                  <br></br>
                                  &nbsp;&nbsp;<FormattedMessage id="upload.size" />.&nbsp;&nbsp;
                                  <br></br>
                                  <br></br>
                                </div>
                              </Card>
                            </Row>
                          </>
                        : null }
                     </Col>
                     <Col md="1"></Col>
                     <Col md="6">
                       <Card theme="warning" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {color: "white"}}>
                           <table>
                             <tbody>
                               <tr>
                                 <td>
                                   <div className="fa fa-info mx-2"></div>
                                 </td>
                                 <td>
                                    <FormattedMessage id="attach.localattach" />
                                 </td>
                               </tr>
                             </tbody>
                           </table>
                       </Card>

                       <Table size="sm">
                           <thead>
                             <tr>
                               <th style={{ width: "10px" }}>#</th>
                               <th style={{ width: "30px" }}></th>
                               <th style={{ width: "50px" }}><FormattedMessage id="sign.identification" /></th>
                               <th style={{ width: "10px" }}><FormattedMessage id="sign.see" /></th>
                               <th style={{ width: "10px" }}><FormattedMessage id="sign.download" /></th>
                               <th style={{ width: "10px" }}><FormattedMessage id="sign.delete" /></th>
                             </tr>
                           </thead>
                           <tbody>
                             {jsonAttachFile2.map((todo, i) => {
                               correlativo = i
                               let mismoUser = false
                               if (todo.userName === this.state.username || todo.deleteAttach){
                                  mismoUser = true
                               }
                               let nameX = todo.name
                               if (todo.name.length > 30){
                                  nameX = `${todo.name.substring(0,27)}...${todo.name.substring(todo.name.length-4,todo.name.length)}`
                               }
                               let video=false
                               let audio=false
                               let pdf=false
                               let docx=false
                               let doc=false
                               let xlsx=false
                               let xls=false
                               let ppt=false
                               let pptx=false
                               let odt=false
                               let ods=false
                               let odp=false
                               let text=false
                               let svg=false
                               let zip=false
                               let dwg=false
                               let rar=false
                               let other=true
                               if (todo.type.substring(0,5) === "video"){video=true;other=false}
                               if (todo.type.substring(0,5) === "audio"){audio=true;other=false}
                               if (todo.type=== "application/pdf"){pdf=true;other=false}
                               if (todo.type=== "application/msword"){doc=true;other=false}
                               if (todo.type=== "application/vnd.openxmlformats-officedocument.wordprocessingml.document"){docx=true;other=false}
                               if (todo.type=== "application/vnd.ms-excel"){xls=true;other=false}
                               if (todo.type=== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){xlsx=true;other=false}
                               if (todo.type=== "application/vnd.ms-powerpoint"){ppt=true;other=false}
                               if (todo.type=== "application/vnd.openxmlformats-officedocument.presentationml.presentation"){pptx=true;other=false}
                               if (todo.type=== "application/vnd.oasis.opendocument.text"){odt=true;other=false}
                               if (todo.type=== "application/vnd.oasis.opendocument.spreadsheet"){ods=true;other=false}
                               if (todo.type=== "application/vnd.oasis.opendocument.presentation"){odp=true;other=false}
                               if (todo.type=== "text/plain"){text=true;other=false}
                               if (todo.type=== "application/zip"){zip=true;other=false}

                               if (todo.type=== "application/x-compressed"){zip=true;other=false}
                               if (todo.type=== "application/x-zip-compressed"){zip=true;other=false}
                               if (todo.type=== "application/zip"){zip=true;other=false}
                               if (todo.type=== "multipart/x-zip"){zip=true;other=false}

                               if (todo.type=== "application/acad"){dwg=true;other=false}
                               if (todo.type=== "application/autocad_dwg"){dwg=true;other=false}
                               if (todo.type=== "application/dwg"){dwg=true;other=false}
                               if (todo.type=== "application/x-acad"){dwg=true;other=false}
                               if (todo.type=== "application/x-autocad"){dwg=true;other=false}
                               if (todo.type=== "application/x-dwg"){dwg=true;other=false}
                               if (todo.type=== "image/vnd.dwg"){dwg=true;other=false}
                               if (todo.type=== "image/x-dwg"){dwg=true;other=false}

                               if (todo.type=== "application/x-rar-compressed"){rar=true;other=false}
                               if (todo.type=== "application/octet-stream"){rar=true;other=false}

                               if (todo.type=== "image/svg+xml"){svg=true;other=false}
                               return (
                                   <tr key={i}>
                                       <td>{++correlativo}</td>
                                       {other ? <td><img src="images/imgimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {video ? <td><img src="images/videoimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {audio ? <td><img src="images/audioimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {pdf ? <td><img src="images/pdfimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {text ? <td><img src="images/txtimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {svg ? <td><img src="images/svgimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {doc ? <td><img src="images/docimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {docx ? <td><img src="images/docximage.png" weight="100" height="100" alt=""/></td> : null }
                                       {xls ? <td><img src="images/xlsimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {xlsx ? <td><img src="images/xlsximage.png" weight="100" height="100" alt=""/></td> : null }
                                       {ppt ? <td><img src="images/pptimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {pptx ? <td><img src="images/pptximage.png" weight="100" height="100" alt=""/></td> : null }
                                       {odt ? <td><img src="images/odtimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {ods ? <td><img src="images/odsimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {odp ? <td><img src="images/odpimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {zip ? <td><img src="images/zipimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {rar ? <td><img src="images/rarimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {dwg ? <td><img src="images/dwgimage.png" weight="100" height="100" alt=""/></td> : null }

                                       <td>{nameX}</td>
                                       <td><Button pill theme="success" onClick={e=>this.verAttach(e,todo.id,todo.type,todo.name,todo.userName)}><FontAwesomeIcon icon={faEye} /></Button></td>
                                       <td><Button pill theme="warning" onClick={e=>this.downloadAttach(e,todo.id,todo.type,todo.name,todo.userName)}><FontAwesomeIcon icon={faDownload} /></Button></td>
                                       {/*<td><Button pill theme="warning" onClick={e=>this.downloadAttach2(e,todo.id,todo.type,todo.name,todo.userName)}><FontAwesomeIcon icon={faDownload} /></Button></td>*/}
                                       {mismoUser ?
                                         <td><Button pill theme="danger" onClick={e=>this.deleteAttachConfirm(e,i,todo.id)}><FontAwesomeIcon icon={faTimes} /></Button></td>
                                       :
                                         <td></td>
                                       }
                                 </tr>
                                )})}
                           </tbody>
                       </Table>
                       <br></br>
                       <Card theme="warning" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {color: "white"}}>
                           <table>
                             <tbody>
                               <tr>
                                 <td>
                                   <div className="fa fa-info mx-2"></div>
                                 </td>
                                 <td>
                                    <FormattedMessage id="attach.remoteattach" />
                                 </td>
                               </tr>
                             </tbody>
                           </table>
                       </Card>
                       <Table size="sm">
                           <thead>
                             <tr>
                               <th style={{ width: "10px" }}>#</th>
                               <th style={{ width: "30px" }}></th>
                               <th style={{ width: "50px" }}><FormattedMessage id="sign.identification" /></th>
                               <th style={{ width: "10px" }}><FormattedMessage id="sign.see" /></th>
                               <th style={{ width: "10px" }}><FormattedMessage id="sign.download" /></th>
                               <th style={{ width: "10px" }}><FormattedMessage id="sign.delete" /></th>
                             </tr>
                           </thead>
                           <tbody>
                             {jsonAttachFile1.map((todo, i) => {
                               correlativo = i
                               let mismoUser = false
                               if (todo.userName === this.state.username || todo.deleteAttach){
                                  mismoUser = true
                               }
                               let nameX = todo.name
                               if (todo.name.length > 30){
                                  nameX = `${todo.name.substring(0,27)}...${todo.name.substring(todo.name.length-4,todo.name.length)}`
                               }
                               let video=false
                               let audio=false
                               let pdf=false
                               let docx=false
                               let doc=false
                               let xlsx=false
                               let xls=false
                               let ppt=false
                               let pptx=false
                               let odt=false
                               let ods=false
                               let odp=false
                               let text=false
                               let svg=false
                               let zip=false
                               let rar=false
                               let dwg=false
                               let other=true
                               if (todo.type.substring(0,5) === "video"){video=true;other=false}
                               if (todo.type.substring(0,5) === "audio"){audio=true;other=false}
                               if (todo.type=== "application/pdf"){pdf=true;other=false}
                               if (todo.type=== "application/msword"){doc=true;other=false}
                               if (todo.type=== "application/vnd.openxmlformats-officedocument.wordprocessingml.document"){docx=true;other=false}
                               if (todo.type=== "application/vnd.ms-excel"){xls=true;other=false}
                               if (todo.type=== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){xlsx=true;other=false}
                               if (todo.type=== "application/vnd.ms-powerpoint"){ppt=true;other=false}
                               if (todo.type=== "application/vnd.openxmlformats-officedocument.presentationml.presentation"){pptx=true;other=false}
                               if (todo.type=== "application/vnd.oasis.opendocument.text"){odt=true;other=false}
                               if (todo.type=== "application/vnd.oasis.opendocument.spreadsheet"){ods=true;other=false}
                               if (todo.type=== "application/vnd.oasis.opendocument.presentation"){odp=true;other=false}
                               if (todo.type=== "text/plain"){text=true;other=false}

                               if (todo.type=== "application/x-compressed"){zip=true;other=false}
                               if (todo.type=== "application/x-zip-compressed"){zip=true;other=false}
                               if (todo.type=== "application/zip"){zip=true;other=false}
                               if (todo.type=== "multipart/x-zip"){zip=true;other=false}

                               if (todo.type=== "application/acad"){dwg=true;other=false}
                               if (todo.type=== "application/autocad_dwg"){dwg=true;other=false}
                               if (todo.type=== "application/dwg"){dwg=true;other=false}
                               if (todo.type=== "application/x-acad"){dwg=true;other=false}
                               if (todo.type=== "application/x-autocad"){dwg=true;other=false}
                               if (todo.type=== "application/x-dwg"){dwg=true;other=false}
                               if (todo.type=== "image/vnd.dwg"){dwg=true;other=false}
                               if (todo.type=== "image/x-dwg"){dwg=true;other=false}

                               if (todo.type=== "application/x-rar-compressed"){rar=true;other=false}
                               if (todo.type=== "application/octet-stream"){rar=true;other=false}

                               if (todo.type=== "image/svg+xml"){svg=true;other=false}

                               return (
                                   <tr key={i}>
                                       <td>{++correlativo}</td>
                                       {other ? <td><img src="images/imgimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {video ? <td><img src="images/videoimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {audio ? <td><img src="images/audioimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {pdf ? <td><img src="images/pdfimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {text ? <td><img src="images/txtimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {svg ? <td><img src="images/svgimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {doc ? <td><img src="images/docimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {docx ? <td><img src="images/docximage.png" weight="100" height="100" alt=""/></td> : null }
                                       {xls ? <td><img src="images/xlsimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {xlsx ? <td><img src="images/xlsximage.png" weight="100" height="100" alt=""/></td> : null }
                                       {ppt ? <td><img src="images/pptimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {pptx ? <td><img src="images/pptximage.png" weight="100" height="100" alt=""/></td> : null }
                                       {odt ? <td><img src="images/odtimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {ods ? <td><img src="images/odsimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {odp ? <td><img src="images/odpimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {zip ? <td><img src="images/zipimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {rar ? <td><img src="images/rarimage.png" weight="100" height="100" alt=""/></td> : null }
                                       {dwg ? <td><img src="images/dwgimage.png" weight="100" height="100" alt=""/></td> : null }

                                       <td>{nameX}
                                           <br></br>
                                           [{todo.userName}]
                                       </td>
                                       <td><Button pill theme="success" onClick={e=>this.verAttach(e,todo.id,todo.type,todo.name,todo.userName)}><FontAwesomeIcon icon={faEye} /></Button></td>
                                       <td><Button pill theme="warning" onClick={e=>this.downloadAttach(e,todo.id,todo.type,todo.name,todo.userName)}><FontAwesomeIcon icon={faDownload} /></Button></td>
                                       {/*<td><Button pill theme="warning" onClick={e=>this.downloadAttach2(e,todo.id,todo.type,todo.name,todo.userName)}><FontAwesomeIcon icon={faDownload} /></Button></td>*/}
                                       {mismoUser ?
                                         <td><Button pill theme="danger" onClick={e=>this.deleteAttachConfirm(e,i,todo.id)}><FontAwesomeIcon icon={faTimes} /></Button></td>
                                       :
                                         <td></td>
                                       }
                                 </tr>
                                )})}
                           </tbody>
                       </Table>
                     </Col>
                     <Col md="1"></Col>
                  </Row>
                  <br></br>
                  <Row form>
                     <Col md="1"></Col>
                     <Col md="10">
                       {this.state.seeAttachX ?
                         <Card small text-center style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                           <Col md="1"></Col>
                           <Col md="10">
                             <Row noGutters className="page-header py-4">
                               <TituloPagina2
                                 sm="8"
                                 title={nameAttachX}
                                 subtitle={''}
                                 className="text-sm-left"
                               />
                             </Row>
                           </Col>
                           <MyViewer filedecodeAttachX={filedecodeAttachX} fileAttachX={fileAttachX} typeAttachX={typeAttachX} returnProps={this.returnProps} typeAttachText={typeAttachText} typeAttachImage={typeAttachImage} typeAttachPdf={typeAttachPdf} typeAttachAudio={typeAttachAudio} typeAttachVideo={typeAttachVideo} scale={scale} origen={"attachment"} colorDark={this.props.colorDark} />
                           <br></br>
                         </Card>
                       : null }
                       {this.state.downloadAttachX ?
                         <React.Fragment>
                           <Col md="2">
                             <Card small style={{ maxWidth: "600px", backgroundColor:"#F0EFEC" }}>
                               <MyDownload filedecodeAttachX={filedecodeAttachX} fileAttachX={fileAttachX} nameAttachX={nameAttachX} typeAttachX={typeAttachX} returnProps={this.returnProps} typeAttachText={typeAttachText} typeAttachImage={typeAttachImage} typeAttachPdf={typeAttachPdf} typeAttachAudio={typeAttachAudio} typeAttachVideo={typeAttachVideo} colorDark={this.props.colorDark} />
                             </Card>
                           </Col>
                         </React.Fragment>
                       : null }
                     </Col>
                     <Col md="1"></Col>
                  </Row>
                  <br></br>
                  <Row form>
                     <Col md="1"></Col>
                     <Col md="5">
                       <Row form>
                          {this.state.seeAttachX ?
                             <React.Fragment>
                               <Button pill theme="warning" onClick={this.exitAttach}><FormattedMessage id="button.attachlist" /></Button>
                               &nbsp;
                             </React.Fragment>
                          : null }
                          <Button pill theme="secondary" onClick={this.exitProgram}><FormattedMessage id="button.contractlist" /></Button>
                       </Row>
                     </Col>
                  </Row>
                  <br></br>
              </Container>
            </>
        );
     }
}

export default ContractAttachFile;
