import {
  FETCH_PACT_LOGISTIC_CONFIG,
  FETCH_PACT_LOGISTIC_CONFIG_CONTRACTID,
  FETCH_DELETED_PACT_LOGISTIC_CONFIG,
  SAVE_NEW_PACT_LOGISTIC_CONFIG,
  SAVE_NEW_PACT_LOGISTIC_RULES,
  REMOVE_PACT_LOGISTIC_CONFIG,
  UPDATE_PACT_LOGISTIC_CONFIG,
  TOGGLE_PACT_STATE_LOGISTIC_CONFIG,
  SAVE_NEW_PACT_DATA_SENSOR_TEMPERATURE,
  SAVE_NEW_PACT_DATA_SENSOR_HUMIDITY,
  SAVE_NEW_PACT_DATA_SENSOR_GASCONTROL,
  SAVE_NEW_PACT_DATA_SENSOR,
  SAVE_NEW_PACT_MAINTENANCE_CONFIG,
  SAVE_NEW_PACT_MAINTENANCE_RULES,
  SAVE_NEW_PACT_PROFORMA_CONFIG,
  SAVE_NEW_PACT_PROFORMA_RULES
} from './types';

export function fetchPactLogisticConfig() {
  return { type: FETCH_PACT_LOGISTIC_CONFIG };
}

export function fetchPactLogisticConfigContractId(configurationContractNumber) {
  return { type: FETCH_PACT_LOGISTIC_CONFIG_CONTRACTID };
}

export function fetchDeletedPactLogisticConfig() {
  return { type: FETCH_DELETED_PACT_LOGISTIC_CONFIG };
}

export function saveNewPactLogisticConfig(idParadigma, typeContract, configurationContractNumber, startDate, endDate, transportOriginDestination, conditionsIntegrityMaterials, useAssets, statusBlockchain, usernameInsert, dateInsert, usernameUpdate, dateUpdate, usernameDelete, dateDelete, idTypeBillOfLading, dateContract, shipper, consignee, notifyParty, carrier, placeOfAceptance, portOfLading, vessel, portOfDischarge, placeOfDelivery, finalDestination, amount, currency, signature, statusLifeCycle, contractPolicy) {
    return { type: SAVE_NEW_PACT_LOGISTIC_CONFIG, idParadigma, typeContract, configurationContractNumber, startDate, endDate, transportOriginDestination, conditionsIntegrityMaterials, useAssets, statusBlockchain, usernameInsert, dateInsert, usernameUpdate, dateUpdate, usernameDelete, dateDelete, idTypeBillOfLading, dateContract, shipper, consignee, notifyParty, carrier, placeOfAceptance, portOfLading, vessel, portOfDischarge, placeOfDelivery, finalDestination, amount, currency, signature, statusLifeCycle, contractPolicy };
}

export function saveNewPactLogisticRules(idParadigma, typeContract, configurationContractNumber, idContainer, idTypeBillOfLading, temperature, humidity, gascontrol, impact, vibration, proximity, sealIntegrity, gps, temperatureLowerThreshold, temperatureUpperThreshold, temperatureFrecuency, temperatureUnitFrecuency, temperatureUnitMeasurement, temperatureErrorTime, temperatureErrorTimeUnit, temperatureErrorAction, temperatureActiveRule, humidityLowerThreshold, humidityUpperThreshold, humidityFrecuency, humidityUnitFrecuency, humidityUnitMeasurement, humidityErrorTime, humidityErrorTimeUnit, humidityErrorAction, humidityActiveRule, gascontrolLowerThreshold, gascontrolUpperThreshold, gascontrolFrecuency, gascontrolUnitFrecuency, gascontrolUnitMeasurement, gascontrolErrorTime, gascontrolErrorTimeUnit, gascontrolErrorAction, gascontrolActiveRule, gpsFrecuency, gpsUnitFrecuency, gpsUnitMeasurement, gpsErrorTime, gpsErrorTimeUnit, gpsErrorAction, gpsActiveRule) {
    return { type: SAVE_NEW_PACT_LOGISTIC_RULES, idParadigma, typeContract, configurationContractNumber, idContainer, idTypeBillOfLading, temperature, humidity, gascontrol, impact, vibration, proximity, sealIntegrity, gps, temperatureLowerThreshold, temperatureUpperThreshold, temperatureFrecuency, temperatureUnitFrecuency, temperatureUnitMeasurement, temperatureErrorTime, temperatureErrorTimeUnit, temperatureErrorAction, temperatureActiveRule, humidityLowerThreshold, humidityUpperThreshold, humidityFrecuency, humidityUnitFrecuency, humidityUnitMeasurement, humidityErrorTime, humidityErrorTimeUnit, humidityErrorAction, humidityActiveRule, gascontrolLowerThreshold, gascontrolUpperThreshold, gascontrolFrecuency, gascontrolUnitFrecuency, gascontrolUnitMeasurement, gascontrolErrorTime, gascontrolErrorTimeUnit, gascontrolErrorAction, gascontrolActiveRule, gpsFrecuency, gpsUnitFrecuency, gpsUnitMeasurement, gpsErrorTime, gpsErrorTimeUnit, gpsErrorAction, gpsActiveRule };
}

export function saveNewPactDataSensorTemperature(idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor) {
    return { type: SAVE_NEW_PACT_DATA_SENSOR_TEMPERATURE, idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor };
}

export function saveNewPactDataSensorHumidity(idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor) {
    return { type: SAVE_NEW_PACT_DATA_SENSOR_HUMIDITY, idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor };
}

export function saveNewPactDataSensorGascontrol(idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor) {
    return { type: SAVE_NEW_PACT_DATA_SENSOR_GASCONTROL, idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor };
}

export function saveNewPactDataSensor(idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor,statusRule) {
    return { type: SAVE_NEW_PACT_DATA_SENSOR, idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor,statusRule };
}

export function updatePactLogisticConfig(todo) {
  return { type: UPDATE_PACT_LOGISTIC_CONFIG, todo };
}

export function toggleStateLogisticConfig(id, state) {
  return { type: TOGGLE_PACT_STATE_LOGISTIC_CONFIG, id, state };
}

export function removePactLogisticConfig(id) {
  return { type: REMOVE_PACT_LOGISTIC_CONFIG, id };
}

export function saveNewPactMaintenanceConfig(idParadigma, typeContract, configurationContractNumber, startDate, endDate, statusBlockchain, usernameInsert, dateInsert, usernameUpdate, dateUpdate, usernameDelete, dateDelete, idTypeBillOfLading, dateContract, yourNameCompany, yourAddress, clientNameCompany, clientAddress, amount, currency, signature, statusLifeCycle, contractPolicy ) {
  return { type: SAVE_NEW_PACT_MAINTENANCE_CONFIG, idParadigma, typeContract, configurationContractNumber, startDate, endDate, statusBlockchain, usernameInsert, dateInsert, usernameUpdate, dateUpdate, usernameDelete, dateDelete, idTypeBillOfLading, dateContract, yourNameCompany, yourAddress, clientNameCompany, clientAddress, amount, currency, signature, statusLifeCycle, contractPolicy };
}

export function saveNewPactMaintenanceRules(idParadigma, typeContract, configurationContractNumber, idContainer, idTypeBillOfLading, fire, flood, sealintegrity, motion, smoke, fireErrorAction, fireActiveRule, floodErrorAction, floodActiveRule, sealintegrityErrorAction, sealintegrityActiveRule, motionErrorAction, motionActiveRule, smokeErrorAction, smokeActiveRule ) {
  return { type: SAVE_NEW_PACT_MAINTENANCE_RULES, idParadigma, typeContract, configurationContractNumber, idContainer, idTypeBillOfLading, fire, flood, sealintegrity, motion, smoke, fireErrorAction, fireActiveRule, floodErrorAction, floodActiveRule, sealintegrityErrorAction, sealintegrityActiveRule, motionErrorAction, motionActiveRule, smokeErrorAction, smokeActiveRule };
}

export function saveNewPactProformaConfig(idParadigma, typeContract, configurationContractNumber, startDate, endDate, statusBlockchain, usernameInsert, dateInsert, usernameUpdate, dateUpdate, usernameDelete, dateDelete, idTypeBillOfLading, dateContract, shippername, shippercompanyname, shippercontactperson, shipperstreetaddress, shippercitystzipcode, shipperphone, shipperemail, receivername, receivercompanyname, receivercontactperson, currency, receiverstreetaddress, receivercitystzipcode, receiverphone, receiveremail, reference, modeoftransport, totalnumberpackages, totalgrossweight, productid, description, quantity, unitprice, proformasubtotal, proformadiscount, proformataxrate, proformasalesrate, proformashipping, proformatotal, countryoforigin, portofembarcation, reasonforexport, portofdischarge, termsaleothercomments, gps, gpsFrecuency, gpsUnitFrecuency, gpsUnitMeasurement, gpsErrorTime, gpsErrorTimeUnit, gpsErrorAction, gpsActiveRule, signature, statusLifeCycle, contractPolicy ) {
    return { type: SAVE_NEW_PACT_PROFORMA_CONFIG, idParadigma, typeContract, configurationContractNumber, startDate, endDate, statusBlockchain, usernameInsert, dateInsert, usernameUpdate, dateUpdate, usernameDelete, dateDelete, idTypeBillOfLading, dateContract, shippername, shippercompanyname, shippercontactperson, shipperstreetaddress, shippercitystzipcode, shipperphone, shipperemail, receivername, receivercompanyname, receivercontactperson, currency, receiverstreetaddress, receivercitystzipcode, receiverphone, receiveremail, reference, modeoftransport, totalnumberpackages, totalgrossweight, productid, description, quantity, unitprice, proformasubtotal, proformadiscount, proformataxrate, proformasalesrate, proformashipping, proformatotal, countryoforigin, portofembarcation, reasonforexport, portofdischarge, termsaleothercomments, gps, gpsFrecuency, gpsUnitFrecuency, gpsUnitMeasurement, gpsErrorTime, gpsErrorTimeUnit, gpsErrorAction, gpsActiveRule, signature, statusLifeCycle, contractPolicy };
}

export function saveNewPactProformaRules(idParadigma, typeContract, configurationContractNumber, startDate, endDate, statusBlockchain, usernameInsert, dateInsert, usernameUpdate, dateUpdate, usernameDelete, dateDelete, idTypeBillOfLading, dateContract, gps, gpsFrecuency, gpsUnitFrecuency, gpsUnitMeasurement, gpsErrorTime, gpsErrorTimeUnit, gpsErrorAction, gpsActiveRule ) {
    return { type: SAVE_NEW_PACT_PROFORMA_RULES, idParadigma, typeContract, configurationContractNumber, startDate, endDate, statusBlockchain, usernameInsert, dateInsert, usernameUpdate, dateUpdate, usernameDelete, dateDelete, idTypeBillOfLading, dateContract, gps, gpsFrecuency, gpsUnitFrecuency, gpsUnitMeasurement, gpsErrorTime, gpsErrorTimeUnit, gpsErrorAction, gpsActiveRule };
}
