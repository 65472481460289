import React, { Component } from 'react';
import { Card, Content } from 'react-bulma-components';
import { Table } from 'reactstrap';
import axios from 'axios';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import YoutubeEmbed from './youtubeembed'

import {
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormInput,
    Collapse,
    Tooltip,
    Button,
} from "shards-react";

import Loader from '../loader'

//translate
import { FormattedMessage } from 'react-intl';

import Signin from './signin'

import './stylelanding.css';

const API = 'https://core.blockstack.org/v1/node/ping'

class Login extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);

        this.state = {
            open: false,
            loadingUser: false,
            status: '',
            dataping: null,
            isLoading: false,
            error: null,
            detail: false,
            colorX: 'white',
            youtubeX: false,
        }
    }

    componentDidMount() {
        axios.get(API)
            .then(result => {
                this.setState({
                    dataping: result,
                    status: result.status,
                    isLoading: true
                })
            })
            .catch(error => {
                this.setState({
                    error,
                    isLoading: false
                })
            });
    }

    toggleNavbar() {
        this.setState({
            ...this.state,
            ...{
                collapseOpen: !this.state.collapseOpen
            }
        });
    }

    toggle() {
        this.setState({
            open: !this.state.open
        });
    }

    signIn = (e) => {

        const { userSession } = this.props

        e.preventDefault()
        //userSession.redirectToSignIn()
        this.setState({ loadingUser: true })
    }

    detail = (e) => {
      this.setState({ detail: !this.state.detail })
    }

    back = (e) => {
      if (this.props.language === 'es'){
        window.location = 'https://welcome.xck.app'
      }else{
        window.location = 'https://welcome.xck.app/english'
      }
    }

    onMouseEnterHandler = () => {
      this.setState({ colorX: 'orange' })
    }

    onMouseLeaveHandler = () => {
      this.setState({ colorX: 'white' })
    }

    displayButtonsTabRed(){
    }

    videoYoutube =(e) => {
      e.preventDefault()
      this.setState({ youtubeX: !this.state.youtubeX })
    }

    render() {
        const { loadingUser } = this.state;
        const detailX = this.state.detail
        let urlImg = 'images/background_profile.png'

        let originWelcome = false
        const originWelcomeX = localStorage.getItem('landingPage')
        if (originWelcomeX === 'Yes'){
          originWelcome = true
        }

        return (
            <>
                  <div className="bienvenidos bg-fondosection1">
                      <div id='body'>
                          <div className="section slider bg-fondologin" >
                              <div className="container">
                                  <div className="row">
                                     <div className="col-lg-12 m-auto">
                                          <div><img src="images/imagotipov3.png" height="110" alt="" /></div>
                                          <br /><br />
                                          <div className="block">
                                              <h3><FormattedMessage id="login.subtitle" /></h3>
                                              <h4 style={{color: "#fff"}}><FormattedMessage id="login.subtitle2" /></h4>
                                              <br />
                                              <Button
                                                outline pill
                                                theme="light"
                                                id="TooltipBlockstack"
                                                style={{ color: 'orange', width: 300, height: 60, marginBottom: 10,textTransform: 'uppercase' }}
                                                onClick={e=>this.signIn(e)}><FormattedMessage id="login.detail3" />
                                              </Button>
                                              <h6>
                                                <a
                                                  onMouseEnter={()=>this.onMouseEnterHandler()}
                                                  onMouseLeave={()=>this.onMouseLeaveHandler()}
                                                  style={{color: this.state.colorX}}
                                                  href="https://domains.paradigma.global" target="_blank" rel="noopener noreferrer"><FormattedMessage id="profile.claim" />
                                                </a>
                                              </h6>
                                              <br />
                                              <strong style={{cursor: 'pointer'}} onClick={e=>this.videoYoutube(e)}><FormattedMessage id="login.video2" /></strong>
                                              <br />
                                              {this.state.youtubeX ?
                                                <YoutubeEmbed />
                                              : null
                                              }
                                          </div>
                                     </div>
                                     <br /><br />
                                     <div className="col-lg-5 m-auto"></div>
                                     <div className="col-lg-2 m-auto">
                                       <div className="block">
                                           { originWelcome ?
                                             <Table size="sm" borderless responsive className="text-center">
                                                <tbody>
                                                  <tr>
                                                    <td style={{ width: "50%" }} className="text-left" >
                                                      <strong style={{cursor: 'pointer'}} onClick={e=>this.back(e)}>{'<'}<FormattedMessage id="simulator.return" /></strong>
                                                    </td>
                                                    <td style={{ width: "50%" }} className="text-right" >
                                                      <strong style={{cursor: 'pointer'}} onClick={e=>this.detail(e)}>{'Faq>'}</strong>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                             </Table>
                                           :
                                             null
                                           }
                                       </div>
                                     </div>
                                     <div className="col-lg-5 m-auto"></div>
                                  </div>
                              </div>
                          </div>
                          <br></br>
                          <br></br>
                          <br></br>
                          {detailX ?
                            <>
                              <div className="feature section bg-fondosection1 pt-0">
                                  <div className="container">
                                      <div className="row">
                                          <div className="col-lg-6 ml-auto justify-content-center">
                                                  <img className="img-fluid" src="images/landingpage1.png" alt="" />
                                          </div>
                                          <div className="col-lg-6 mr-auto align-self-center">
                                              <div className="feature-content">
                                                  <p style={{fontSize:35, color: "#fff"}}><FormattedMessage id="login.section1a" /></p>
                                                  <p className="desc" style={{color: "#eeeeee"}}><FormattedMessage id="login.section1b" /></p>
                                              </div>
                                              <div className="testimonial">
                                                  <p>
                                                      "<FormattedMessage id="login.section1c" />"
                                                  </p>
                                                  <ul className="list-inline meta">
                                                      <li className="list-inline-item">
                                                          <img src="images/philliproe.png" alt="" />
                                                      </li>
                                                      <li className="list-inline-item">Phillip Roe-Smithson, CEO</li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <br></br>
                              <br></br>

                              <div className="feature section pt-0">
                                  <div className="container">
                                      <div className="row">
                                          <div className="col-lg-6 ml-auto align-self-center">
                                              <div className="feature-content">
                                                  <p style={{fontSize:35, color: "#fff"}}><FormattedMessage id="login.section2a" /></p>
                                                  <p style={{color: "#eeeeee"}}><FormattedMessage id="login.section2b" /></p>
                                              </div>
                                              <div className="testimonial">
                                                  <p>
                                                      "<FormattedMessage id="login.section2c" />"
                                                  </p>
                                                  <ul className="list-inline meta">
                                                      <li className="list-inline-item">
                                                          <img src="images/josefontirroig.png" width="100%" height="100%" alt="" />
                                                      </li>
                                                      <li className="list-inline-item">Josep Fontirroig, CTO</li>
                                                  </ul>
                                              </div>
                                          </div>
                                          <div className="col-lg-6 mr-auto justify-content-center">
                                              <img className="img-fluid" src="images/landingpage2.png" width="230%" height="230%" alt="" />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <br></br>
                              <br></br>

                              <div className="service section " style={{ backgroundColor: '#141414'}} >
                                  <div className="container-fluid p-0">
                                      <div className="row">
                                          <div className="col-lg-12">
                                              <div className="section-title">
                                                 <p style={{fontSize:35, color: "#fff"}}><FormattedMessage id="login.section5a" /></p>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="row no-gutters">
                                          <div className="col-lg-2"></div>
                                          <div className="col-lg-8 mr-auto align-self-center">
                                              <div className="service-box">
                                                  <div className="row align-items-center">
                                                      <div className="col-md-6 col-xs-12">
                                                          <div className="service-item">
                                                              <i className="tf-ribbon"></i>
                                                              <h3><FormattedMessage id="login.section5b" /></h3>
                                                              <p><FormattedMessage id="login.section5c" /></p>
                                                          </div>
                                                      </div>
                                                      <div className="col-md-6 col-xs-12">
                                                          <div className="service-item">
                                                              <i className="tf-puzzle"></i>
                                                              <h3><FormattedMessage id="login.section5d" /></h3>
                                                              <p><FormattedMessage id="login.section5e" /></p>
                                                          </div>
                                                      </div>
                                                      <div className="col-md-6 col-xs-12">
                                                          <div className="service-item">
                                                              <i className="tf-grid"></i>
                                                              <h3><FormattedMessage id="login.section5f" /></h3>
                                                              <p><FormattedMessage id="login.section5g" /></p>
                                                          </div>
                                                      </div>
                                                      <div className="col-md-6 col-xs-12">
                                                          <div className="service-item">
                                                              <i className="tf-expand2"></i>
                                                              <h3><FormattedMessage id="login.section5h" /></h3>
                                                              <p><FormattedMessage id="login.section5i" /></p>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="video-promo section bg-1">
                                  <br></br>
                                  <div className="container">
                                      <div className="row">
                                          <div className="col-lg-12">
                                              <div className="content-block">
                                                  <h2><FormattedMessage id="login.video" /></h2>
                                                  <a data-fancybox href="https://www.youtube.com/watch?v=cWnIGMGOhWA" target='_blank'>
                                                      <i className="tf-ion-play video"></i>
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="page-section" style={{ backgroundColor: '#141414', color: "#fff"}} id="team">
                                  <br></br>
                                  <br></br>
                                  <div className="container">
                                      <div className="row">
                                          <div className="col-lg-12 text-center">
                                              <h2 className="section-heading" style={{color: "#fff"}}><FormattedMessage id="login.team" /></h2>
                                          </div>
                                      </div>
                                      <br></br>
                                      <br></br>
                                      <div className="row">
                                          <div className="col-sm-4">
                                              <div className="team-member text-center">
                                                  <img className="mx-auto rounded-circle" src="images/team_philliproe2.png" height="100" alt="" />
                                                  <h6>&nbsp;</h6>
                                                  <h4 style={{color: "#fff"}}>Phillip Roe-Smithson</h4>
                                                  <p className="text-muted"><FormattedMessage id="login.team1" /></p>
                                                  <ul className="list-inline social-buttons">
                                                      <li className="list-inline-item">
                                                          <a href="#">
                                                              <i className="fab fa-twitter"></i>
                                                          </a>
                                                      </li>
                                                      <li className="list-inline-item">
                                                          <a href="#">
                                                              <i className="fab fa-facebook-f"></i>
                                                          </a>
                                                      </li>
                                                      <li className="list-inline-item">
                                                          <a href="#">
                                                              <i className="fab fa-linkedin-in"></i>
                                                          </a>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                          <div className="col-sm-4">
                                              <div className="team-member text-center">
                                                  <img className="mx-auto rounded-circle" src="images/team_rodrigotapia.png" height="100" alt="" />
                                                  <h6>&nbsp;</h6>
                                                  <h4 style={{color: "#fff"}}>Rodrigo Tapia Stocker</h4>
                                                  <p className="text-muted"><FormattedMessage id="login.team3" /></p>
                                                  <ul className="list-inline social-buttons">
                                                      <li className="list-inline-item">
                                                          <a href="#">
                                                              <i className="fab fa-twitter"></i>
                                                          </a>
                                                      </li>
                                                      <li className="list-inline-item">
                                                          <a href="#">
                                                              <i className="fab fa-facebook-f"></i>
                                                          </a>
                                                      </li>
                                                      <li className="list-inline-item">
                                                          <a href="#">
                                                              <i className="fab fa-linkedin-in"></i>
                                                          </a>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                          <div className="col-sm-4">
                                              <div className="team-member text-center">
                                                  <img className="mx-auto rounded-circle" src="images/team_josefontirroig.jpg" height="100" alt="" />
                                                  <h6>&nbsp;</h6>
                                                  <h4 style={{color: "#fff"}}>Josep Fontirroig Romanque</h4>
                                                  <p className="text-muted"><FormattedMessage id="login.team2" /></p>
                                                  <ul className="list-inline social-buttons">
                                                      <li className="list-inline-item">
                                                          <a href="#">
                                                              <i className="fab fa-twitter"></i>
                                                          </a>
                                                      </li>
                                                      <li className="list-inline-item">
                                                          <a href="#">
                                                              <i className="fab fa-facebook-f"></i>
                                                          </a>
                                                      </li>
                                                      <li className="list-inline-item">
                                                          <a href="#">
                                                              <i className="fab fa-linkedin-in"></i>
                                                          </a>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                      <br></br>
                                  </div>
                              </div>
                              <br></br>
                              <footer className="footer-main" style={{ backgroundColor: '#000000'}}>
                                  <div className="container">
                                      <div className="row">
                                          <div className="col-lg-3 col-md-3 m-md-auto align-self-center">
                                              <div className="block">
                                                  <ul className="social-icon list-inline">
                                                      <li className="list-inline-item">
                                                          <a href="#"><i className="tf-ion-social-facebook"></i></a>
                                                      </li>
                                                      <li className="list-inline-item">
                                                          <a href="#"><i className="tf-ion-social-twitter"></i></a>
                                                      </li>
                                                      <li className="list-inline-item">
                                                          <a href="#"><i className="tf-ion-social-instagram"></i></a>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-center">
                                              <div className="block-2">
                                                  <h6>© 2022 Paradigma Global</h6>
                                              </div>
                                          </div>
                                          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-center">
                                              <div className="block-2">
                                                  <span><a style={{fontSize:15}} href="https://paradigma.global" target='_blank'>Our Company</a></span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <br></br>
                                  <div className="text-center">
                                      <div>
                                          <span style={{fontSize:12}}>Powered by</span> <a style={{fontSize:12}} href="https://www.stacks.co" target='_blank'>Stacks Blockchain</a>
                                      </div>
                                  </div>
                              </footer>
                            </>
                          :
                            null
                          }

                  </div>
              </div>
              { loadingUser ? <Signin language={this.props.language}/> : null }
           </>
        );
    }
}

export default Login;
