//React
import React, { Component } from 'react';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Container, Button, Card, FormSelect } from "shards-react";
import { Table } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";
import { Divider, Typography, TextField, Grid, Dialog,
        DialogContent, DialogActions, DialogTitle, DialogContentText,
        Button as ButtonMaterialUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';

//UUID
import uuid from 'uuid';

//base64
import { Base64 } from 'js-base64';

//loader
import ContractLoader from "../contracts/ContractLoader";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { makeRandomPrivKey } from '@stacks/transactions';
import { Storage } from '@stacks/storage'
import { StacksTestnet, StacksMainnet } from '@stacks/network';


//translate
import { FormattedMessage } from 'react-intl';

//Axios
import axios from 'axios';

//Configuracion
import {token} from '../../config.js'

//Number Format
import NumberFormat from 'react-number-format';

//Email
import emailjs from 'emailjs-com'

//Currency
import currencyPayment from '../../utils/currency-payment';


//Configuración
import { SMART_CONTRACT_ADDRESS_MAINNET_STX,
        SMART_CONTRACT_NAME_MAINNET_STX,
        SMART_CONTRACT_ADDRESS_TESTNET_STX,
        SMART_CONTRACT_NAME_TESTNET_STX,
        SMART_CONTRACT_ADDRESS_MAINNET_XCK,
        SMART_CONTRACT_NAME_MAINNET_XCK,
        SMART_CONTRACT_ADDRESS_MAINNET_MXM,
        SMART_CONTRACT_NAME_MAINNET_MXM,
        SMART_CONTRACT_ADDRESS_MAINNET_MIA,
        SMART_CONTRACT_NAME_MAINNET_MIA,
        SMART_CONTRACT_ADDRESS_MAINNET_NYC,
        SMART_CONTRACT_NAME_MAINNET_NYC,
        SMART_CONTRACT_ADDRESS_MAINNET_FRIE,
        SMART_CONTRACT_NAME_MAINNET_FRIE,
        MAINNET_STACKS_API_URL,
        TESTNET_STACKS_API_URL } from '../../config.js'

//Clarity
import {transferCloseShiftReport, balanceOf, getBalance, service_fee} from "../../clarity/clarityfunctions"

import {username_new} from '../../actions/userActions'

function ConfirmacionConfirmRegister(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Container fluid className="px-0">
                    <Card theme="warning" >
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              &nbsp;&nbsp;<div className="fa fa-info"></div>
                            </td>
                            <td>
                               &nbsp;&nbsp;Transacción Registro y Cierre
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                </Container>
                <Table size="sm" className="text-left" responsive hover striped bordered style={{ color: 'blue', fontSize:11 }}>
                    <tbody>
                      <tr>
                        <th style={{ width: "40%", textAlign:"left", color: 'blue', fontSize:11 }}><FormattedMessage id="useraction.network" /></th>
                        <th style={{ width: "60%", textAlign:"right", color: 'black', fontSize:13 }}>{props.addressX}</th>
                      </tr>
                    </tbody>
                </Table>
                <Table size="sm" className="text-center" responsive hover striped bordered style={props.colorDark ? { color: 'white', fontSize:11 } : { color: 'black', fontSize:11 }}>
                    <thead>
                      <tr style={props.colorDark ? { textAlign:"center", color: 'white', fontSize:15} : {textAlign:"center", color: 'blue', fontSize:15}}>
                        <th><FormattedMessage id="usergroup.concept" /></th>
                        <th>{props.cryptoPactClarityEthereumX}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}>Token Transacción</td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}>
                          <FormSelect
                              id="cryptocurrency"
                              value={props.c01CryptoCurrency}
                              placeholder=""
                              disabled={false}
                              onChange={props.handleChangeCryptoCurrency}
                          >
                              {props.currencyPaymentX.map((todo, i) => {
                                  return (
                                     <option style={{color:'darkblue'}} key={i} value={`${todo.Ccy} - ${todo.CcyNm}`}>{`${todo.Ccy} - ${todo.CcyNm}`}</option>
                                  )
                              })}
                          </FormSelect>
                        </td>
                      </tr>

                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}>Saldo para Transacciones</td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}><NumberFormat value={props.balanceX} displayType={'text'} thousandSeparator={true} /> {props.cryptoPactClarityEthereum2X}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}>Costo Transacción</td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}>{props.transactionX} {props.cryptoPactClarityEthereum2X}</td>
                      </tr>
                    </tbody>
                </Table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', props.cryptoPactClarityEthereum2X, props.saldoX)} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button disabled={props.disableTransferX} onClick={() => props.handleDialog(props.dialogTitle, 'pay', props.cryptoPactClarityEthereum2X, props.saldoX)} theme="success">
                    Realizar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionWarning(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class CloseReporteTurno extends Component {

    constructor(props) {
        super(props);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          person: '',
          exist: true,
          pubKey: '',
          publicKey: '',
          opcionContractIoTDevice: '',
          displayContractIoTDevice: false,
          jsonBlockstack: [],
          jsonBlockstackY: [],
          jsonBlockstackZ: [],
          jsonBlockstack2: [],
          jsonBlockstackZ50c: [],
          typeContractIoTDevice: '',
          idx: '',
          url: 'https://gaia.blockstack.org/hub/',
          url2: 'https://core.blockstack.org/v1/users/',
          url3: 'https://core.blockstack.org/v1/names/',
          identityAddress: undefined,
          decentralizedID: undefined,
          language: 'English',
          nameBlockstack: '',
          displayLoader: false,
          goConfirmSign: false,
          dialogOpen: false,
          dialogOpenPaymentForm: false,
          dialogOpenSign: false,
          dialogOpenUnsign: false,
          dialogOpenWarning: false,
          dialogOpenConfirmSign: false,
          dialogMessage: '',
          dialogTitle: '',
          balanceCta: 0,
          resultTransfer: '',
          stacksAddress: '',
          privateKey: '',
          contractAddressX: '',
          addressKeyX: '',
          networkUrlX: '',
          contractNameX: '',
          contractAddressX2: '',
          serverStacksX: '',
          usernameX: '',
          jsonPaymentForm: '',
          buttonErrorPaymentForm: false,
          getTransaction: '',
          published: false,
          amount: 0.01,
          stacksAddressRecipient: '',
          stacksAddressSender: '',
          registerTransactionRegister: '',
          resultTransfer: '',
          disabledButton: false,
          balanceCtaSTX: null,
          balanceCtaXCK: null,
          balanceCtaMXM: null,
          balanceCtaMIA: null,
          balanceCtaNYC: null,
          balanceCtaFRIE: null,
          cryptoCurrencyX: 'STX - Stacks',
          symbolCriptoCurrency: 'STX',
          priceSTX: 0,
          priceXCK: 0,
          priceMXM: 0,
          priceMIA: 0,
          priceNYC: 0,
          priceFRIE: 0,
          messageRegisterX: '',
          signTodosX: true,
        }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
           stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
        }
        if (serverStacksX === 'TestNet'){
           stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      const {identityAddress} = userSession.loadUserData();
      const {decentralizedID} = userSession.loadUserData();

      this.setState({ identityAddress, decentralizedID, networkUrlX })

      this.setState({stacksAddressSender: stacksNetX, stacksAddressRecipient: 'SPRK2JVQ988PYT19JSAJNR3K9YZAZGVY04XMC2Z7'}) //cta_platcc_paradigma_id

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
    }

    handleChangeCryptoCurrency = (e) => {
      const palabra = e.target.value
      const ArrayIdValue = palabra.split(' - ')
      const symbolCurrencyX = ArrayIdValue[0]
      this.setState({cryptoCurrencyX: e.target.value, symbolCriptoCurrency: symbolCurrencyX})
      if (symbolCurrencyX === 'STX') {this.setState({amount:this.state.priceSTX})}
      if (symbolCurrencyX === 'XCK') {this.setState({amount:this.state.priceXCK})}
      if (symbolCurrencyX === 'MXM') {this.setState({amount:this.state.priceMXM})}
      if (symbolCurrencyX === 'MIA') {this.setState({amount:this.state.priceMIA})}
      if (symbolCurrencyX === 'NYC') {this.setState({amount:this.state.priceNYC})}
      if (symbolCurrencyX === 'FRIE') {this.setState({amount:this.state.priceFRIE})}
    }

    handleDialog = (type, action) => {
      if (type === 'Error: 1001') {
         if (action === 'cancel') {
              this.setState({ dialogOpenWarning: false });
              this.exitProgram()
         }
      }
    }

    handleDialog2 = (type, action, cryptoX, saldoX) => {
      if (type === 'Cerrar Reporte de Turno') {
        if (action === 'pay') {
          const message64 = Base64.encode(this.state.messageRegisterX)
          transferCloseShiftReport(this.state.stacksAddressSender, this.state.serverStacksX, this.state.stacksAddressRecipient, parseFloat(this.state.amount), this.props.typeContract, this.props.numberContract, this.state.symbolCriptoCurrency, message64, this.state.jsonBlockstackZ50c, uuid(), this.state.username, this.props.fecha, this.props.userTurnoDiaX, this.props.userTurnoNocheX, this.props.jsonData)
           .then(result => {
              this.setState({ dialogOpenConfirmSign: false, resultTransfer: result, disabledButton: true })
              this.exitProgram()
          })
        }
        if (action === 'cancel') {
          this.setState({ dialogOpenConfirmSign: false, disabledButton: false })
          this.exitProgram()
        }
      }
    }

    exitProgram(){
      this.props.returnCerrarTurno()
    }

    confirmSign = (e) => {
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''
      let contractNameX = ''
      let contractAddressX = ''
      let contractAddressX2 = ''
      let addressKeyX = ''

      let stacksAddressSender = ''

      let networkX = ''
      let contractNameX_XCK = ''
      let contractAddressX_XCK = ''
      let contractAddressX2_XCK = ''
      let contractNameX_MXM = ''
      let contractAddressX_MXM = ''
      let contractAddressX2_MXM = ''
      let contractNameX_MIA = ''
      let contractAddressX_MIA = ''
      let contractAddressX2_MIA = ''
      let contractNameX_NYC = ''
      let contractAddressX_NYC = ''
      let contractAddressX2_NYC = ''
      let contractNameX_FRIE = ''
      let contractAddressX_FRIE = ''
      let contractAddressX2_FRIE = ''

      const privateKey = makeRandomPrivKey();
      this.setState({privateKey})

      const { userSession } = this.state
      const user = userSession.loadUserData()
      this.setState({ user })
      if (!localStorage["serverStacks"]) {
        serverStacksX = 'MainNet'
      }else{
        serverStacksX = localStorage.getItem('serverStacks')
      }
      if (serverStacksX === 'MainNet'){
         networkUrlX = MAINNET_STACKS_API_URL
         contractNameX = SMART_CONTRACT_NAME_MAINNET_STX
         contractAddressX = SMART_CONTRACT_ADDRESS_MAINNET_STX
         contractAddressX2 = SMART_CONTRACT_ADDRESS_MAINNET_STX
         contractNameX_XCK = SMART_CONTRACT_NAME_MAINNET_XCK
         contractAddressX_XCK = SMART_CONTRACT_ADDRESS_MAINNET_XCK
         contractAddressX2_XCK = SMART_CONTRACT_ADDRESS_MAINNET_XCK
         contractNameX_MXM = SMART_CONTRACT_NAME_MAINNET_MXM
         contractAddressX_MXM = SMART_CONTRACT_ADDRESS_MAINNET_MXM
         contractAddressX2_MXM = SMART_CONTRACT_ADDRESS_MAINNET_MXM
         contractNameX_MIA = SMART_CONTRACT_NAME_MAINNET_MIA
         contractAddressX_MIA = SMART_CONTRACT_ADDRESS_MAINNET_MIA
         contractAddressX2_MIA = SMART_CONTRACT_ADDRESS_MAINNET_MIA
         contractNameX_NYC = SMART_CONTRACT_NAME_MAINNET_NYC
         contractAddressX_NYC = SMART_CONTRACT_ADDRESS_MAINNET_NYC
         contractAddressX2_NYC = SMART_CONTRACT_ADDRESS_MAINNET_NYC
         contractNameX_FRIE = SMART_CONTRACT_NAME_MAINNET_FRIE
         contractAddressX_FRIE = SMART_CONTRACT_ADDRESS_MAINNET_FRIE
         contractAddressX2_FRIE = SMART_CONTRACT_ADDRESS_MAINNET_FRIE
         addressKeyX =  user.profile.stxAddress.mainnet
         stacksAddressSender =  user.profile.stxAddress.mainnet
         stacksNetX =  user.profile.stxAddress.mainnet
         networkX = new StacksMainnet()
      }
      if (serverStacksX === 'TestNet'){
         networkUrlX = TESTNET_STACKS_API_URL
         contractNameX = SMART_CONTRACT_NAME_TESTNET_STX
         contractAddressX = SMART_CONTRACT_ADDRESS_TESTNET_STX
         contractAddressX2 = SMART_CONTRACT_ADDRESS_MAINNET_STX
         addressKeyX =  user.profile.stxAddress.testnet
      }
      const  {username} = this.state;

      this.props.exportReporteTurno(1)

      this.setState({privateKey, contractAddressX, stacksAddressSender, networkUrlX, contractNameX,contractAddressX2, serverStacksX, usernameX: username})

      balanceOf(contractAddressX, stacksAddressSender, networkUrlX, contractNameX,contractAddressX2, serverStacksX, username)
          .then(val => this.setState({balanceCtaSTX: val}))

      getBalance(addressKeyX, contractNameX_XCK,contractAddressX_XCK, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaXCK: val}))

      getBalance(addressKeyX, contractNameX_MXM,contractAddressX_MXM, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaMXM: val}))

      getBalance(addressKeyX, contractNameX_MIA,contractAddressX_MIA, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaMIA: val}))

      getBalance(addressKeyX, contractNameX_NYC,contractAddressX_NYC, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaNYC: val}))

      getBalance(addressKeyX, contractNameX_FRIE,contractAddressX_FRIE, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaFRIE: val}))

      this.setState({ dialogOpenConfirmSign: true, dialogMessage: '', dialogTitle: 'Cerrar Reporte de Turno', })

      this.priceCrypto(stacksAddressSender)
    }

    confirmSignRechazado = (e) => {
      e.preventDefault();
      this.setState({ dialogOpenWarning: true, dialogMessage: 'No es posible cerrar turno, faltan firmas', dialogTitle: 'Error: 1001', })
    }

    priceCrypto = (stacksAddressSender) => {
      this.setState({priceMXM: 5})
      this.setState({priceXCK: 0.01})
      this.setState({priceMIA: 206})
      this.setState({priceNYC: 216})
      this.setState({priceFRIE: 0.5})
      this.setState({priceSTX: 0.01})
    }

    render() {
        const {typeContract} = this.props
        let titleX = 'Cerrar Reporte de Turno'

        let cryptoPactClarityEthereumX = ''
        let cryptoPactClarityEthereum2X = ''
        let myUserConfig = this.props.myUserConfig
        let balanceX = 0
        let transactionX = this.state.amount
        let amountpaymentX=parseFloat(this.state.amount)
        let commissionX = 0
        let rateX = 0
        let saldoX = 0
        cryptoPactClarityEthereumX = 'Stacks'
        cryptoPactClarityEthereum2X = this.state.symbolCriptoCurrency
        if (cryptoPactClarityEthereum2X === 'STX'){cryptoPactClarityEthereumX = 'Stacks'; balanceX = parseFloat(this.state.balanceCtaSTX)}
        if (cryptoPactClarityEthereum2X === 'XCK'){cryptoPactClarityEthereumX = 'Crosscheck'; balanceX = parseFloat(this.state.balanceCtaXCK)}
        if (cryptoPactClarityEthereum2X === 'MXM'){cryptoPactClarityEthereumX = 'Mixmi'; balanceX = parseFloat(this.state.balanceCtaMXM)}
        if (cryptoPactClarityEthereum2X === 'MIA'){cryptoPactClarityEthereumX = 'Miami Coin'; balanceX = parseFloat(this.state.balanceCtaMIA)}
        if (cryptoPactClarityEthereum2X === 'NYC'){cryptoPactClarityEthereumX = 'New York Coin'; balanceX = parseFloat(this.state.balanceCtaNYC)}
        if (cryptoPactClarityEthereum2X === 'FRIE'){cryptoPactClarityEthereumX = 'Friedger Token'; balanceX = parseFloat(this.state.balanceCtaFRIE)}
        let disableTransferX = true
        if (balanceX > 0){
          if (amountpaymentX !== undefined && amountpaymentX !== null && amountpaymentX !== 0 && amountpaymentX !== ''){
            if (balanceX >= (amountpaymentX + commissionX + rateX)) {
                saldoX = balanceX - (amountpaymentX + commissionX + rateX)
                disableTransferX = false
            }else{
                saldoX = balanceX
            }
          }
        }

        const tx_id = this.props.cierreReporteTurnoX.registerTxId
        const url_tx = `https://explorer.stacks.co/txid/${tx_id}?chain=mainnet`
        const tx_status = this.state.getTransaction.tx_status
        const tx_type = this.state.getTransaction.tx_type
        const sender_address = this.state.getTransaction.sender_address
        const url_sender = `https://explorer.stacks.co/address/${sender_address}?chain=mainnet`
        const fee_rate = parseFloat(this.state.getTransaction.fee_rate) / 1000000
        const contract_call_function_name = 'transfer'
        const block_hash = this.state.getTransaction.block_hash
        const url_block_hash = `https://explorer.stacks.co/block/${block_hash}?chain=mainnet`
        const burn_block_time_iso = this.state.getTransaction.burn_block_time_iso

        const currencyPaymentX = currencyPayment.CcyNtry

        let registrarCerrarTurnoDiaX = true
        let registrarCerrarTurnoDiaXRojo = true
        let cerradoReporteTurno = false
        if (this.props.cierreReporteTurnoX.close){
           registrarCerrarTurnoDiaX = false
           cerradoReporteTurno = true
        }else{
          if (this.props.active_after_publishedX === 'false'){
            registrarCerrarTurnoDiaX = false
          }else{
            if (this.props.authRoleX === 'Adm MD'){
               if (this.props.cReporteTurnoSignatureFirmante1 === true &&
                   this.props.cReporteTurnoSignatureFirmante2 === true &&
                   this.props.cReporteTurnoSignatureFirmante6 === true &&
                   this.props.cReporteTurnoSignatureFirmante7 === true &&
                   this.props.cReporteTurnoSignatureFirmante3 === true &&
                   this.props.cReporteTurnoSignatureFirmante4 === true &&
                   this.props.cReporteTurnoSignatureFirmante5 === true){
                   registrarCerrarTurnoDiaX = true
                   registrarCerrarTurnoDiaXRojo = false
               }
            }else{
              registrarCerrarTurnoDiaX = false
            }
          }
        }

        return (
          <div>
            <Container fluid className="main-content-container px-4">
                <ConfirmacionConfirmRegister
                    handleDialog={this.handleDialog2}
                    dialogMessage={this.state.dialogMessage}
                    dialogOpen={this.state.dialogOpenConfirmSign}
                    dialogTitle={this.state.dialogTitle}
                    cryptoPactClarityEthereumX={cryptoPactClarityEthereumX}
                    cryptoPactClarityEthereum2X={cryptoPactClarityEthereum2X}
                    addressX={this.state.serverStacksX}
                    balanceX={balanceX}
                    transactionX={transactionX}
                    saldoX={saldoX}
                    disableTransferX={disableTransferX}
                    rateX={rateX}
                    commissionX={commissionX}
                    handleChangeCryptoCurrency={this.handleChangeCryptoCurrency}
                    currencyPaymentX={currencyPaymentX}
                    c01CryptoCurrency={this.state.cryptoCurrencyX}
                    colorDark={this.props.colorDark}
                />
                <ConfirmacionWarning
                    handleDialog={this.handleDialog}
                    dialogMessage={this.state.dialogMessage}
                    dialogOpen={this.state.dialogOpenWarning}
                    dialogTitle={this.state.dialogTitle}
                />

                {registrarCerrarTurnoDiaX ?
                  <>
                  <div>
                    <Table size="sm" borderless responsive >
                      <thead>
                        <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                            <th style={{ width: "100%", color:'#3a3e41', textAlign: 'center', fontSize:16}} >
                              {registrarCerrarTurnoDiaXRojo ?
                                 <strong style={{cursor: 'pointer'}} onClick={(e)=>this.confirmSignRechazado(e)}><img src="images/registrarcerrarRed.png" weight="70" height="70" alt=""/></strong>
                              :
                                 <strong style={{cursor: 'pointer'}} onClick={(e)=>this.confirmSign(e)}><img src="images/registrarcerrarGreen.png" weight="70" height="70" alt=""/></strong>
                              }
                            </th>
                        </tr>
                        <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                            <th style={{ width: "100%", color:'#3a3e41', textAlign: 'center', fontSize:16}}>
                               CERRAR REPORTE TURNO
                            </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  </>
                : null }
                {cerradoReporteTurno ?
                  <>
                  <div>
                    <Table size="sm" borderless responsive >
                      <thead>
                        <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                            <th style={{ width: "100%", color:'#3a3e41', textAlign: 'center', fontSize:16}} >
                               <img src="images/certificaterulesGreen.png" weight="70" height="70" alt=""/>
                            </th>
                        </tr>
                        <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                            <th style={{ width: "100%", color:'#3a3e41', textAlign: 'center', fontSize:16}}>
                               REPORTE TURNO CERRADO Y REGISTRADO
                            </th>
                        </tr>
                        <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                            <th style={{ width: "100%", color:'#3a3e41', textAlign: 'center', fontSize:11}}>
                               Id.Transacción de Cierre: <a href={url_tx} target="_blank" rel="noopener noreferrer"> {this.props.cierreReporteTurnoX.registerTxId}</a>
                            </th>
                        </tr>
                        <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                            <th style={{ width: "100%", color:'#3a3e41', textAlign: 'center', fontSize:11}}>
                               Fecha de Cierre: {this.props.cierreReporteTurnoX.datetime}
                            </th>
                        </tr>
                        <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                            <th style={{ width: "100%", color:'#3a3e41', textAlign: 'center', fontSize:11}}>
                               Sha256: {this.props.cierreReporteTurnoX.jsonDataSha256}
                            </th>
                        </tr>
                        <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                            <th style={{ width: "100%", color:'#3a3e41', textAlign: 'center', fontSize:11}}>
                               Reporte de Turno Válido. Verificado con Blockchain.
                            </th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  </>
                : null }
                <div>
                  <Table size="sm" borderless responsive >
                    <thead>
                      <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                          <th style={{ width: "100%", color:'#3a3e41', textAlign: 'center', fontSize:16}} >
                             <img src="images/isotipo2.svg" weight="50" height="50" alt=""/>
                          </th>
                      </tr>
                    </thead>
                  </Table>
                </div>
            </Container>
          </div>
        );
    }
}

export default CloseReporteTurno;
