import { AGREGAR_CONTRACT, MOSTRAR_CONTRACT, MODIFICAR_CONTRACT, ELIMINAR_CONTRACT } from '../actions/types';

// cada reducer tiene su propio state

const initialState = {
     contracts: []
}

export default function(state = initialState, action) {
     switch(action.type) {
          case AGREGAR_CONTRACT:
               return {
                    ...state,
                    contracts: [...state.contracts, action.payload]
               }

          case MOSTRAR_CONTRACT:
               return {
                    ...state,
                    contracts: [...state.contracts, action.payload]
               }

          case MODIFICAR_CONTRACT:
               return {
                   ...state,
                   contracts: [...state.contracts, action.payload]
               }

          case ELIMINAR_CONTRACT:
               return {
                   ...state,
                   contracts: [...state.contracts, action.payload]
               }
                                           
          default:
            return state;
      }
}
