import React from "react";
import { withRouter } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, NavItem, NavLink } from "shards-react";

//translate
import { FormattedMessage } from 'react-intl';

class Blog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
    this.toggleFormatActions = this.toggleFormatActions.bind(this);
  }

  toggleFormatActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return (
        <NavItem
            tag={Dropdown}
            caret
            dropup
            //toggle={this.toggleUserActions}
            >
            <DropdownToggle
                caret
                tag={NavLink}
                className="text-nowrap px-1"
                onClick={this.toggleFormatActions}
                >
                <img
                  className="ml-5 mr-2" style={{width:25, height:25}}
                  src={require("./../../../images/blog.png")}
                  alt="Blog"
                />{" "}
            </DropdownToggle>

            <Collapse
                tag={DropdownMenu}
                right
                small
                open={this.state.visible}
                style={ this.props.colorDark ? { backgroundColor:'#4e6893'} : {}}
                >
                <DropdownItem >
                    <FormattedMessage id="blog.createanewblog" />
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem >
                    <FormattedMessage id="blog.viewallposts" />
                </DropdownItem>
                <DropdownItem >
                    <FormattedMessage id="blog.editblogdetails" />
                </DropdownItem>
                <DropdownItem >
                    <FormattedMessage id="blog.writeanewpost" />
                </DropdownItem>
            </Collapse>
        </NavItem>
      )
  }
}

export default withRouter(Blog)
