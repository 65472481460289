import React from "react";
import { withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";

//translate
import { FormattedMessage } from 'react-intl';

class FormatDateTime extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleFormatActions = this.toggleFormatActions.bind(this);
  }

  toggleFormatActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  settings = () => {
    const path = '/masters'
    this.props.history.push(path);
  }

  render() {
    return (
      <NavItem
          tag={Dropdown}
          caret
          dropup
          //toggle={this.toggleUserActions}
          >
          <DropdownToggle
              caret
              tag={NavLink}
              className="text-nowrap px-1"
              onClick={this.toggleFormatActions}
              >
              <img
                className="ml-5 mr-2" style={{width:25, height:25}}
                src={require("./../../../images/configGrey.png")}
                alt="Config"
              />{" "}
          </DropdownToggle>

          <Collapse
              tag={DropdownMenu}
              right
              small
              open={this.state.visible}
              style={ this.props.colorDark ? { backgroundColor:'#4ce3ff'} : {}}
              >
              <DropdownItem tag="a" style={{cursor: 'pointer', textDecoration: 'none'}}>
                  <div onClick={this.settings}>
                    <i class="material-icons">settings</i> <FormattedMessage id="useraction.setting" />
                  </div>
              </DropdownItem>
          </Collapse>
      </NavItem>
    );
  }
}

export default withRouter(FormatDateTime)
