//React
import React, { Component } from 'react';

//Proptype
import PropTypes from 'prop-types';

//UI
import {Row, Col, Container, Button, Card } from "shards-react";
import { Table } from 'reactstrap';
import {Dialog,DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';

import TituloPagina2 from "../common/TituloPagina2";

// blockstack
import { UserSession } from 'blockstack';

//translate
import { FormattedMessage } from 'react-intl';

//components
import NewContractRules from "./NewContractRules";

import {username_new} from '../../actions/userActions'

function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionSaveDraft(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class ContractRules extends Component {

    constructor(props) {
        super(props);

        this.displayEnrollmentConfirm = this.displayEnrollmentConfirm.bind(this);
        this.exitProgram = this.exitProgram.bind(this);
        this.returnRules = this.returnRules.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          dialogOpen: false,
          dialogMessage: '',
          dialogTitle: '',
          language: 'English',
          clickRules: false,
          displayRules: false,
          indiceRules: '',
          displayRulesDisable: false,
          displayRulesEnrollment: '',
          isErrorWrite: false,
          jsonEnrollment: [],

          //----------------------------------------
          //Datos Fijos para checkbox de Sensores
          //----------------------------------------
          c01SensorContract:true,
          c02SensorContract:true,
          c03SensorContract:true,
          c04SensorContract:false,
          c05SensorContract:false,
          c06SensorContract:false,
          c07SensorContract:false,
          c08SensorContract:true,
          c09SensorContract:true,
          c10SensorContract:true,
          c11SensorContract:true,
          c12SensorContract:true,
          c13SensorContract:true,
        }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
      this.setState({c01SensorContract: this.props.c01SensorContract})
      this.setState({c02SensorContract: this.props.c02SensorContract})
      this.setState({c03SensorContract: this.props.c03SensorContract})
      this.setState({c04SensorContract: this.props.c04SensorContract})
      this.setState({c05SensorContract: this.props.c05SensorContract})
      this.setState({c06SensorContract: this.props.c06SensorContract})
      this.setState({c07SensorContract: this.props.c07SensorContract})
      this.setState({c08SensorContract: this.props.c08SensorContract})
      this.setState({c09SensorContract: this.props.c09SensorContract})
      this.setState({c10SensorContract: this.props.c10SensorContract})
      this.setState({c11SensorContract: this.props.c11SensorContract})
      this.setState({c12SensorContract: this.props.c12SensorContract})
      this.setState({c13SensorContract: this.props.c13SensorContract})

      this.setState({jsonEnrollment: this.props.jsonEnrollment})

    }

    handleDialog = (type, action) => {
      if (type === 'Warning: 2001') {
         if (action === 'confirm') {
              this.setState({ dialogOpen: false });
              let e = ''
              if (localStorage["correlativoEnrollment"]) {
                 const i = localStorage.getItem('correlativoEnrollment')
                 if (!this.state.clickRules){
                    this.clickRulesEnrollment(e, i)
                 }else{
                    this.closeEnrollmentConfirm()
                 }
              }
         }else{
           if (action === 'cancel') {
            this.setState({ dialogOpen: false });
           }
         }
      }
      if (type === 'Error: 1001') {
         if (action === 'cancel') {
           this.setState({ dialogOpenSaveDraft: false });
         }
      }
    }

    displayEnrollmentConfirm = (e,i) => {
      localStorage.setItem('correlativoEnrollment',i)
      if (this.state.language === 'English'){
        this.setState({dialogOpen: true,dialogMessage: 'The item rules will be displayed',dialogTitle: 'Warning: 2001',})
      }
      if (this.state.language === 'French'){
        this.setState({dialogOpen: true,dialogMessage: "Les règles de l'article seront affichées",dialogTitle: 'Warning: 2001',})
      }
      if (this.state.language === 'Spanish'){
        this.setState({dialogOpen: true,dialogMessage: 'Se mostrarán las reglas del artículo',dialogTitle: 'Warning: 2001',})
      }
      if (this.state.language === 'Portuguese'){
        this.setState({dialogOpen: true,dialogMessage: 'As regras do item serão exibidas',dialogTitle: 'Warning: 2001',})
      }
      if (this.state.language === 'Swedish'){
        this.setState({dialogOpen: true,dialogMessage: 'Artikelreglerna visas',dialogTitle: 'Warning: 2001',})
      }
      if (this.state.language === 'Netherlands'){
        this.setState({dialogOpen: true,dialogMessage: 'De itemregels worden weergegeven',dialogTitle: 'Warning: 2001',})
      }
      if (this.state.language === 'Russian'){
        this.setState({dialogOpen: true,dialogMessage: 'Правила предмета будут отображены',dialogTitle: 'Warning: 2001',})
      }
      if (this.state.language === 'Japanese'){
        this.setState({dialogOpen: true,dialogMessage: 'アイテムルールが表示されます',dialogTitle: 'Warning: 2001',})
      }
      if (this.state.language === 'Chinese'){
        this.setState({dialogOpen: true,dialogMessage: '将显示项目规则',dialogTitle: 'Warning: 2001',})
      }
      if (this.state.language === 'German'){
        this.setState({dialogOpen: true,dialogMessage: 'Die Artikelregeln werden angezeigt',dialogTitle: 'Warning: 2001',})
      }
      if (this.state.language === 'Italian'){
        this.setState({dialogOpen: true,dialogMessage: "Verranno visualizzate le regole dell'oggetto",dialogTitle: 'Warning: 2001',})
      }
    }

    closeEnrollmentConfirm(){
      if (this.state.language === 'English'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'You must save or close the rules, before deploying another',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'French'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Vous devez enregistrer ou fermer les règles avant de déployer une autre',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Spanish'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Debe guardar o cerrar las reglas, antes de implementar otro',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Portuguese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Você deve salvar ou fechar as regras antes de implantar outro',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Swedish'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Du måste spara eller stänga reglerna innan du installerar en annan',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Netherlands'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'U moet de regels opslaan of sluiten voordat u een andere implementeert',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Russian'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Вы должны сохранить или закрыть правила перед развертыванием другого',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Japanese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: '別のルールを展開する前に、ルールを保存または閉じる必要があります',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Chinese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: '在部署另一个规则之前，必须保存或关闭规则',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'German'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Sie müssen die Regeln speichern oder schließen, bevor Sie eine andere bereitstellen',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Italian'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'È necessario salvare o chiudere le regole prima di distribuirne un altro',dialogTitle: 'Error: 1001',})
      }
    }

    clickRulesEnrollment = (e,optx) => {
      this.setState({displayRules: true})
      this.setState({indiceRules:optx})
      this.setState({displayRulesDisable: false})
      this.setState({clickRules: true})
      const {jsonEnrollment} = this.state
      jsonEnrollment.map((todo,i) => {
        if (parseInt(i) === parseInt(optx)){
          this.setState({displayRulesEnrollment: todo.enrollment})
          return true
        }
        return false
      })
    }

    returnRules(){
      this.setState({clickRules: false})
      this.setState({displayRules: false})
    }

    exitProgram(){
       //window.location = '/'
       this.props.returnProps();
    }

    render() {

          const messageSubtittle = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`

          let jsonEnrollment = []
          if (this.state.jsonEnrollment.length > 0) {
             jsonEnrollment = this.state.jsonEnrollment
          }else{
             jsonEnrollment = this.props.jsonEnrollment
          }

          const displayFullChargeX = true
          const {displayRules} = this.state

          let correlativo = 0
          let titleX = ''
          if (this.state.language === 'English'){
            titleX = "Rules"
          }
          if (this.state.language === 'French'){
            titleX = "Règles"
          }
          if (this.state.language === 'Spanish'){
            titleX = "Reglas"
          }
          if (this.state.language === 'Portuguese'){
            titleX = "Regras"
          }
          if (this.state.language === 'Swedish'){
            titleX = "Regler"
          }
          if (this.state.language === 'Netherlands'){
            titleX = "Regels"
          }
          if (this.state.language === 'Russian'){
            titleX = "правила"
          }
          if (this.state.language === 'Japanese'){
            titleX = "ルール"
          }
          if (this.state.language === 'Chinese'){
            titleX = "规则"
          }
          if (this.state.language === 'German'){
            titleX = "Regeln"
          }
          if (this.state.language === 'Italian'){
            titleX = "Regole"
          }

          return (
            <div>
              <Container fluid className="main-content-container px-4">

                  <Row noGutters className="page-header py-4">
                    <TituloPagina2
                      sm="8"
                      title={titleX}
                      subtitle={messageSubtittle}
                      className="text-sm-left"
                    />
                  </Row>
                  <Confirmacion
                      handleDialog={this.handleDialog}
                      dialogMessage={this.state.dialogMessage}
                      dialogOpen={this.state.dialogOpen}
                      dialogTitle={this.state.dialogTitle}
                  />
                  <ConfirmacionSaveDraft
                      handleDialog={this.handleDialog}
                      dialogMessage={this.state.dialogMessage}
                      dialogOpen={this.state.dialogOpenSaveDraft}
                      dialogTitle={this.state.dialogTitle}
                  />
                  <Row>
                    {displayFullChargeX ?
                      <Col md="3">
                        <Card small className="mb-12 pt-3">
                          <label>&nbsp;</label>
                          <Table size="sm" className="text-center" responsive hover striped style={{fontSize:12}}>
                              <thead>
                                <tr>
                                  <th style={{ width: "30px" }}>#</th>
                                  <th stylactivatee={{ width: "160px" }}><FormattedMessage id="iotdevice.identification" /></th>
                                  <th style={{ width: "80px" }}><FormattedMessage id="iotdevice.status" /></th>
                                </tr>
                              </thead>
                              <tbody>
                                {jsonEnrollment.map((todo, i) => {
                                  correlativo = i
                                  return (
                                      <tr key={i}>
                                          <td>{++correlativo}</td>
                                          <td>{todo.enrollment}</td>
                                          {todo.active ?
                                            <td><strong style={{cursor: 'pointer'}} onClick={e=>this.displayEnrollmentConfirm(e,i)}><img src={"images/rulesGreen.png"} weight="25" height="25" alt=""/></strong></td>
                                          :
                                            <td><strong style={{cursor: 'pointer'}} onClick={e=>this.displayEnrollmentConfirm(e,i)}><img src={"images/rulesRed.png"} weight="25" height="25" alt=""/></strong></td>
                                          }
                                      </tr>
                                   )})}
                              </tbody>
                          </Table>

                        </Card>
                      </Col>
                    : null }

                    { displayRules
                      ?
                      <React.Fragment>
                         <Col md="9">
                            <NewContractRules typeContract={this.props.typeContract} numberContract={this.props.numberContract} jsonEnrollment={this.state.jsonEnrollment} templateName={this.props.templateName} templateNameMaintenance={this.props.templateNameMaintenance} templateNameDelivery={this.props.templateNameDelivery} templateNameProforma={this.props.templateNameProforma} templateNameBlank={this.props.templateNameBlank} displayRoleX={this.props.displayRoleX} rules2X={this.props.rules2X} rules3X={this.props.rules3X} temperature2X={this.props.temperature2X} humidity2X={this.props.humidity2X} gascontrol2X={this.props.gascontrol2X} gps2X={this.props.gps2X} fire2X={this.props.fire2X} flood2X={this.props.flood2X} sealintegrity2X={this.props.sealintegrity2X} motion2X={this.props.motion2X} smoke2X={this.props.smoke2X} displaySaveRulesX={this.props.displaySaveRulesX} todosSignX={this.props.todosSignX} ningunoSignX={this.props.ningunoSignX} indiceRules={this.props.indiceRules} algunosSignX={this.props.algunosSignX} JSONTemplateName={this.props.JSONTemplateName} c01SensorContract={this.state.c01SensorContract} c02SensorContract={this.state.c02SensorContract} c03SensorContract={this.state.c03SensorContract} c04SensorContract={this.state.c04SensorContract} c05SensorContract={this.state.c05SensorContract} c06SensorContract={this.state.c06SensorContract} c07SensorContract={this.state.c07SensorContract} c08SensorContract={this.state.c08SensorContract} c09SensorContract={this.state.c09SensorContract} c10SensorContract={this.state.c10SensorContract} c11SensorContract={this.state.c11SensorContract} c12SensorContract={this.state.c12SensorContract} c13SensorContract={this.state.c01SensorContract} displayRulesEnrollment={this.state.displayRulesEnrollment} displayRulesDisable={this.state.displayRulesDisable} returnRules={this.returnRules} />
                         </Col>
                      </React.Fragment>
                    : null }
                  </Row>

                  <br></br>
                  <Row form>
                     <Col md="4">
                       <Button pill theme="secondary" onClick={this.exitProgram}><FormattedMessage id="button.contractlist" /></Button>
                     </Col>
                  </Row>
                  <br></br>
              </Container>
            </div>
        );
     }
}


export default ContractRules;
