//React
import React, { Component } from 'react';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Card, CardHeader, Container, FormSelect, Button, FormInput,
        InputGroupAddon, InputGroupText, InputGroup, ListGroup, ListGroupItem,
        FormTextarea} from "shards-react";

import { Table } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";
import { Divider, Typography, TextField, Grid, Dialog,
         DialogContent, DialogActions, DialogTitle, DialogContentText,
         Button as ButtonMaterialUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';

//Number Format
import NumberFormat from 'react-number-format';

//Tittle
import TituloPagina2 from "../common/TituloPagina2";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { makeRandomPrivKey } from '@stacks/transactions';
import { Storage } from '@stacks/storage'

//Axios
import axios from 'axios';

//translate
import { FormattedMessage } from 'react-intl';

//Components
import ContractUserGroupSendEmail from './ContractUserGroupSendEmail';

//Configuración
import {token} from '../../config.js'

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

//Configuración
import { SMART_CONTRACT_ADDRESS_MAINNET_STX,
         SMART_CONTRACT_NAME_MAINNET_STX,
         SMART_CONTRACT_ADDRESS_TESTNET_STX,
         SMART_CONTRACT_NAME_TESTNET_STX,
         MAINNET_STACKS_API_URL,
         TESTNET_STACKS_API_URL } from '../../config.js'

//
import {CopyToClipboard} from 'react-copy-to-clipboard';

//Clarity
import {transfer, balanceOf} from "../../clarity/clarityfunctions"

//Email
import emailjs from 'emailjs-com'

//base64
import { Base64 } from 'js-base64';


function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionWarning(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'accept')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionAccept(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('accept')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'accept')} theme="warning" autoFocus>
                   <FormattedMessage id="alert.accept" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Confirmacion2(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Confirmacion3(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'continue')} theme="success">
                    <FormattedMessage id="alert.continue" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionViewProfile(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                  {props.dialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-center">
                      <Table size="sm" className="text-center" responsive borderless>
                          <tbody>
                            <tr>
                              <td>
                                <div className="mb-3 mx-auto">
                                    <ReactImageFallback
                                         src={props.avatar}
                                         fallbackImage={props.avatar2}
                                         initialImage={props.avatar2}
                                         alt={" "}
                                         className="rounded-circle"
                                         width="45"
                                    />
                                </div>
                              </td>
                            </tr>
                            <tr><td style={{fontSize:18, color: 'darkred'}}><strong>{props.person}</strong></td></tr>
                            <tr><td style={{fontSize:13, color: 'grey'}}><span className="text-muted d-block mb-2">{props.username}</span></td></tr>
                         </tbody>
                      </Table>
                    </CardHeader>
                    <ListGroup flush>
                      <ListGroupItem className="p-4 text-center">
                        <Row>
                          <Table size="sm" className="text-center" responsive borderless  style={{fontSize:11}}>
                              <tbody>
                                <tr>
                                  <td style={{ width: "90%" }}>
                                      <FormTextarea
                                        id="feMessage"
                                        rows="2"
                                        innerRef={props.aboutMe}
                                        style={{backgroundColor:"#F4F4F4"}}
                                        placeholder={props.aboutMe}
                                        disabled={true}
                                      />
                                  </td>
                                </tr>
                             </tbody>
                          </Table>
                        </Row>
                        <Row>
                          <Table size="sm" responsive borderless>
                              <tbody>
                                <tr>
                                  <td style={{ width: "17%" }}><a href={props.facebook} target="_blank" rel="noopener noreferrer"><img src="images/profile_facebook.png" weight="40" height="40" alt=""/></a></td>
                                  <td style={{ width: "17%" }}><a href={props.twitter} target="_blank" rel="noopener noreferrer"><img src="images/profile_twitter.png" weight="40" height="40" alt=""/></a></td>
                                  <td style={{ width: "17%" }}><a href={props.youtube} target="_blank" rel="noopener noreferrer"><img src="images/profile_youtube.png" weight="40" height="40" alt=""/></a></td>
                                  <td style={{ width: "17%" }}><a href={props.instagram} target="_blank" rel="noopener noreferrer"><img src="images/profile_instagram.png" weight="40" height="40" alt=""/></a></td>
                                  <td style={{ width: "17%" }}><a href={props.linkedin} target="_blank" rel="noopener noreferrer"><img src="images/profile_linkedin.png" weight="40" height="40" alt=""/></a></td>
                                  <td style={{ width: "15%" }}><a href={props.pinterest} target="_blank" rel="noopener noreferrer"><img src="images/profile_pinterest.png" weight="40" height="40" alt=""/></a></td>
                                </tr>
                              </tbody>
                          </Table>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>

                    <ListGroup flush>
                      <ListGroupItem className="p-4 text-center">
                        <Row>
                          <Table size="sm" className="text-center" responsive borderless style={{fontSize:13}}>
                              <tbody>
                                {props.bStxScope ?
                                  <>
                                    <tr><td style={{fontSize:11, color: 'grey'}}>STX Address</td></tr>
                                    <tr><td style={{fontSize:13, color: 'darkblue'}}><strong>{props.stxAddress}</strong></td></tr>
                                  </>
                                :
                                   null
                                }
                                {props.bEmailScope ?
                                  <>
                                    <tr><td style={{fontSize:11, color: 'grey'}}>eMail</td></tr>
                                    <tr><td style={{fontSize:13, color: 'darkblue'}}><strong>{props.email}</strong></td></tr>
                                  </>
                                :
                                   null
                                }
                                {props.bSmsScope ?
                                   <>
                                     <tr><td style={{fontSize:11, color: 'grey'}}>Phone</td></tr>
                                     <tr><td style={{fontSize:13, color: 'darkblue'}}><strong>{`${props.smsPrefix}${props.phone}`}</strong></td></tr>
                                   </>
                                :
                                   null
                                }
                                <tr><td style={{fontSize:13}}>{props.espacio.repeat(100)}</td></tr>
                             </tbody>
                          </Table>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>


                  </Card>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'continue')} theme="success">
                    <FormattedMessage id="alert.continue" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionActiveUserGroup(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Container fluid className="px-0">
                    <Card theme="warning" >
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              &nbsp;&nbsp;<div className="fa fa-info"></div>
                            </td>
                            <td>
                               &nbsp;&nbsp;<FormattedMessage id="usergroup.message" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                </Container>
                <Table size="sm" className="text-left" responsive hover striped bordered style={{ color: 'blue', fontSize:11 }}>
                    <tbody>
                      <tr>
                        <th style={{ width: "40%", textAlign:"left", color: 'blue', fontSize:11 }}><FormattedMessage id="useraction.network" /></th>
                        <th style={{ width: "60%", textAlign:"right", color: 'black', fontSize:13 }}>{props.addressX}</th>
                      </tr>
                    </tbody>
                </Table>
                <Table size="sm" className="text-center" responsive hover striped bordered style={{ color: 'black', fontSize:11 }}>
                    <thead>
                      <tr style={{ textAlign:"center", color: 'blue', fontSize:15 }}>
                        <th><FormattedMessage id="usergroup.concept" /></th>
                        <th>{props.cryptoPactClarityEthereumX}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="pay.previousbalance" /></td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}><NumberFormat value={props.balanceX} displayType={'text'} thousandSeparator={true} /> {props.cryptoPactClarityEthereum2X}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="pay.amounttransfer" /></td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}>{props.transactionX} {props.cryptoPactClarityEthereum2X}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="pay.commission" /></td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}>{props.commissionX} {props.cryptoPactClarityEthereum2X}</td>
                      </tr>
                    </tbody>
                </Table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', props.cryptoPactClarityEthereum2X, props.saldoX)} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button disabled={props.disableTransferX} onClick={() => props.handleDialog(props.dialogTitle, 'pay', props.cryptoPactClarityEthereum2X, props.saldoX)} theme="success">
                    <FormattedMessage id="alert.pay" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionSendLink(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"><FormattedMessage id="invitation.senddescription" /></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Container fluid className="px-0">
                    <Card theme="warning" >
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              &nbsp;&nbsp;<div className="fa fa-info"></div>
                            </td>
                            <td>
                               &nbsp;&nbsp;<FormattedMessage id="invitation.sendtitle" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                </Container>

                <Table size="sm" className="text-center" responsive hover striped style={{ color: 'black', fontSize:11 }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "100%" }}>
                          <label style={{fontSize:18}}><FormattedMessage id="usergroup.entermailof" />{` ${props.idx}`}</label>
                          <FormInput
                            style={{fontSize:14}}
                            className="text-center"
                            id="email"
                            type="text"
                            disabled={false}
                            placeholder={props.placeholderEmail}
                            alt={props.emailx}
                            value={props.emailx}
                            onChange={e=>props.handleChangeInput(e)}
                          />
                        </td>
                      </tr>
                      <tr onMouseEnter={()=>props.onMouseEnterHandler(0)} onMouseLeave={()=>props.onMouseLeaveHandler(0)}>
                        {props.setSendInfo ?
                          <td style={{ width: "100%" }}><strong style={{cursor: 'pointer'}} onClick={() => props.handleDialog(props.dialogTitle, 'send')}><img src="images/emailpredeterminadoGreen.png" title="Click here to invite the participant" weight="65" height="65" alt=""/></strong></td>
                        :
                          <td style={{ width: "100%" }}><strong style={{cursor: 'pointer'}} onClick={() => props.handleDialog(props.dialogTitle, 'send')}><img src="images/emailpredeterminado.png" title="Click here to invite the participant" weight="65" height="65" alt=""/></strong></td>
                        }
                      </tr>
                      <tr>
                        <td style={{ textAlign:"center", color: 'black', fontSize:13 }}><FormattedMessage id="invitation.predeterminado" /></td>
                      </tr>
                    </tbody>
                </Table>

                <Table size="sm" className="text-center" responsive hover striped style={{ color: 'black', fontSize:11 }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "800px" }}>
                          <label htmlFor="feLink" style={{fontSize:18}}><FormattedMessage id="invitation.copylink" /></label>
                          <InputGroup className="mb-2">
                            <FormInput
                              id="feLink"
                              disabled={true}
                              onChange={() => {this.setState({value:'', copied: false})}}
                              placeholder={props.linkX}
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText>
                                <strong style={{cursor: 'pointer'}}  onClick={()=>props.copyToClipBoard(props.linkX)}><img src="images/copypaste.png" weight="20" height="20" alt="" title="Copy to Clipboard" /></strong>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </td>
                      </tr>
                    </tbody>
                </Table>

                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="danger" autoFocus>
                <FormattedMessage id="alert.cancel" />
            </Button>
            </DialogActions>
        </Dialog>
    );
}

class ContractUserGroup extends Component {

    constructor(props) {
        super(props);

        this.clickRemove = this.clickRemove.bind(this);
        this.clickRemoveRemote = this.clickRemoveRemote.bind(this);
        this.readUserRemoveRemote = this.readUserRemoveRemote.bind(this);
        this.clickSendContract = this.clickSendContract.bind(this);
        this.clickEdit = this.clickEdit.bind(this);
        this.clickInvite = this.clickInvite.bind(this);
        this.goUserGroup = this.goUserGroup.bind(this);
        this.addUserGroup = this.addUserGroup.bind(this);
        this.addUserGroup2 = this.addUserGroup2.bind(this);
        this.addUserGroupRemote = this.addUserGroupRemote.bind(this);
        this.addUserGroupRemote2 = this.addUserGroupRemote2.bind(this);
        this.readUserGroupRemote = this.readUserGroupRemote.bind(this);
        this.goRole = this.goRole.bind(this);
        this.exitProgram = this.exitProgram.bind(this);
        this.handleChangeUserGroup = this.handleChangeUserGroup.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        this.handleDialog2 = this.handleDialog2.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.dataUser = this.dataUser.bind(this);
        this.dataUser2 = this.dataUser2.bind(this);
        this.sendInvite = this.sendInvite.bind(this);
        this.sendInvite2 = this.sendInvite2.bind(this);
        this.sendInvite3 = this.sendInvite3.bind(this);
        this.exitUserGroup = this.exitUserGroup.bind(this);
        this.printConfirm = this.printConfirm.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.existeUserConfirm = this.existeUserConfirm.bind(this);
        this.existePayerPayToConfirm = this.existePayerPayToConfirm.bind(this);
        this.existeUserRoleConfirm = this.existeUserRoleConfirm.bind(this);
        this.existeNotSamePayToConfirm = this.existeNotSamePayToConfirm.bind(this);
        this.nonRoleConfirm = this.nonRoleConfirm.bind(this);
        this.responseConfirm = this.responseConfirm.bind(this);
        this.responseErrorSend = this.responseErrorSend.bind(this);
        this.userIdConfirm = this.userIdConfirm.bind(this);
        this.userAppConfirm = this.userAppConfirm.bind(this);
        this.userUndefinedConfirm = this.userUndefinedConfirm.bind(this);
        this.userIdUsernameConfirm = this.userIdUsernameConfirm.bind(this);
        this.saveMyInvitation = this.saveMyInvitation.bind(this);
        this.readMyInvitationUserId = this.readMyInvitationUserId.bind(this);
        this.readMyInvitationEmail = this.readMyInvitationEmail.bind(this);
        this.readMyInvitation = this.readMyInvitation.bind(this);
        this.writeRemote = this.writeRemote.bind(this);
        this.advancedSearch = this.advancedSearch.bind(this);
        this.warningConfirmUserGroup = this.warningConfirmUserGroup.bind(this);
        this.activeParticipant = this.activeParticipant.bind(this);
        this.actualizarActiveUserGroup = this.actualizarActiveUserGroup.bind(this);
        this.actualizarActiveUserGroup2 = this.actualizarActiveUserGroup2.bind(this);
        this.actualizarActiveUserGroup3 = this.actualizarActiveUserGroup3.bind(this);
        this.readContractList = this.readContractList.bind(this);
        this.errorRoleNoAcceptConfirm = this.errorRoleNoAcceptConfirm.bind(this);
        this.copyText = this.copyText.bind(this);
        this.copyToClipBoard = this.copyToClipBoard.bind(this);
        this.idProvisoryConfirm = this.idProvisoryConfirm.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          name: '',
          user: {},
          opcionUserGroup: '',
          displayUserGroup: false,
          displayrole: false,
          jsonUserGroup: [],
          jsonRole: [],
          jsonBlockstack2: [],
          typeUserGroup: '',
          url: 'https://gaia.blockstack.org/hub/',
          url2: 'https://api.hiro.so/v1/names/',
          url3: 'https://api.hiro.so/v1/names/',
          identityAddress: undefined,
          decentralizedID: undefined,
          owneraddress: '',
          displayInvite: false,
          idx: '',
          namex: '',
          rolex: '',
          emailx: '',
          dialogOpen: false,
          dialogOpenPrint: false,
          dialogOpenWarning: false,
          dialogOpenContinue: false,
          dialogOpenAccept: false,
          dialogOpenActiveUserGroup: false,
          dialogOpenViewProfile: false,
          dialogOpenSendLink: false,
          dialogMessage: '',
          dialogTitle: '',
          jsonUserGroupRemote: [],
          languaje: 'English',
          emailMyInvitation: '',
          advancedSearch: false,
          jsonContactList: [],
          displayActiveUserGroup: false,
          displayActiveUserGroupImpide: null,
          displaySendLink: null,
          goActiveParticipant: false,
          value: '',
          copied: false,
          porpagar: 0,
          balanceCta: 0,
          resultTransfer: '',
          stacksAddress: '',
          privateKey: '',
          contractAddressX: '',
          addressKeyX: '',
          networkUrlX: '',
          contractNameX: '',
          contractAddressX2: '',
          serverStacksX: '',
          usernameX: '',
          nameX: '',
          avatarX: '',
          avatar3X: '',
          avatar2X: '',
          emailRX: '',
          setItemInfo: null,
          setSendInfo: false,
          name2X: '',
          user2X: '',
          aboutmeX: '',
          facebookX: '',
          twitterX: '',
          youtubeX: '',
          instagramX: '',
          linkedinX: '',
          pinterestX: '',
          emailX: '',
          email2X: '',
          stxAddress2X: '',
          phoneX: '',
          smsScope: '',
          stxScope: '',
          emailScope: '',
          smsPrefix: '',
          stacksNetX: '',
        }
    }

    c01UserGroup = React.createRef();
    c02UserGroup = React.createRef();
    c03UserGroup = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''

      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }

      let {name} = userSession.loadUserData().profile;
      if (name === '' || name === undefined || name === null){
        name = this.props.myUserConfig.profileName
      }
      this.setState({ name, networkUrlX, stacksNetX })

      const {identityAddress} = userSession.loadUserData(),
            {decentralizedID} = userSession.loadUserData()
      this.setState({ identityAddress, decentralizedID, displayUserGroup: true})

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
      this.setState({displayActiveUserGroupImpide: null})

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => {
          this.setState({username: val})
          if (this.props.activeUserGroupX === false){
            Promise.all(
               [this.readContractList(val),
                this.readMyInvitation(val)
            ]
            ).then(
              (resolve) =>{
              },
              (reject) =>{
              }
            )
          }else{
             this.readMyInvitation(val)
          }
        })
      }else{
        this.setState({ username })
        if (this.props.activeUserGroupX === false){
          Promise.all(
             [this.readContractList(username),
              this.readMyInvitation(username)
          ]
          ).then(
            (resolve) =>{
            },
            (reject) =>{
            }
          )
        }else{
           this.readMyInvitation(username)
        }
      }
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps() {
      this.completaNameAndAvatar(this.props.jsonUserGroup)
    }

    completaNameAndAvatar = async (jsonUserGroup,mode) => {
      const salida = []
      const resultado = await Promise.all(jsonUserGroup.map( async (todo) => {
         let userX = ''
         if (todo.id !== this.state.username){
            userX = todo.id
         }
         return await Promise.all([this.readRemoteNameAvatar(userX)])
           .then(
             (resolve) =>{
                 let emailRX = todo.email
                 if (todo.email === null || todo.email === '' || todo.email === undefined){
                   emailRX = resolve[0].emailRX
                 }
                 salida.push({active:todo.active, email:emailRX, id:todo.id, identityAddress:todo.identityAddress, publicKey:todo.publicKey, role:todo.role, signature:todo.signature, statusInvite:todo.statusInvite, name:resolve[0].nameX, avatar:resolve[0].avatarX})
             },
             (reject)  =>{
                 salida.push({active:todo.active, email:todo.email, id:todo.id, identityAddress:todo.identityAddress, publicKey:todo.publicKey, role:todo.role, signature:todo.signature, statusInvite:todo.statusInvite, name:'', avatar:''})
             }
           )
      }))
      this.setState({jsonUserGroup: salida},()=>{})
    }

    onMouseEnterHandler = (i) =>{
      this.setState({setItemInfo: i, setSendInfo: true})
    }

    onMouseLeaveHandler = (i) => {
      this.setState({setSendInfo: false})
    }

    copyText = (e,linkX) => {
      this.setState({value: linkX})
      return(
        <CopyToClipboard
           text={linkX}
           onCopy={() => this.setState({copied: true})}>
        </CopyToClipboard>
      )
    }

    copyToClipBoard = async linkX => {
      try {
        await navigator.clipboard.writeText(linkX);
        this.setState({copied: true})
      } catch (err) {
        this.setState({copied: false})
      }
    };

    readRemoteNameAvatar = (userX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      let options = {}
      if (userX === '') {
         options = { decrypt: false, verify: false }
      }else{
        options = { username: userX, decrypt: false, verify: false }
      }
      let user2X = userX
      if (userX === '') { user2X = this.state.username}
      return new Promise ((resolve, reject) =>{
        getFileDB(user2X, `myUserConfig.json`)
           .then((fileContents) => {
             if (fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               resolve({nameX: jsonBlockstack6.profileName, avatarX: jsonBlockstack6.profileAvatar, emailRX: jsonBlockstack6.emailNotifications})
             }else{
               reject()
             }
           })
          .catch(error => {
              reject()
        });
      });
    }

    readContractList(username){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack6 = []
      return new Promise ((resolve, reject) =>{
          getFileDB(username, `contractlist.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 let largo = jsonBlockstack6.length
                 let encontro = false
                 jsonBlockstack6.map((todo, i) => {
                    if (todo.typeContract === this.props.typeContract && todo.configurationContractNumber === this.props.numberContract) {
                       if (todo.activeUserGroup === true){
                         this.setState({displayActiveUserGroupImpide: true})
                         encontro = true
                       }else{
                         this.setState({displayActiveUserGroupImpide: false})
                         encontro = true
                       }
                    }
                    if (encontro === false && (i === largo-1)){
                      this.setState({displayActiveUserGroupImpide: false})
                      resolve();
                    }else{
                      if (encontro === true && (i === largo-1)){
                        resolve();
                      }
                    }
                 })
               }else{
                 this.setState({displayActiveUserGroupImpide: false})
                 resolve();
               }
             })
            .catch(error => {
                resolve();
            });
      });
    }

    handleChangeUserGroup = (e) => {
      this.setState({ advancedSearch: false });
      this.c01UserGroup.current.value = this.c03UserGroup.current.value
    }

    handleChangeRole = (e,role) => {
      const palabra = this.c02UserGroup.current.value
      const ArrayIdValue = palabra.split(' - ')
      const sRole = ArrayIdValue[1]
      if (this.props.confirmSignX === true && (sRole === 'Authorizer' || sRole === 'Authorizer-Inviter' || sRole === 'Payer' || sRole === 'PayTo')){
         this.errorRoleNoAcceptConfirm()
      }
    }

    handleChangeInput = (e) => {
      this.setState({emailx: e.target.value})
    }

    handleDialog = (type, action) => {
      if (type === 'Error: 1001') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1002') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1003') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1006') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1007') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1004') {
          if (action === 'accept') {
            this.setState({ dialogOpenWarning: false });
          }
      }
      if (type === 'Error: 1005') {
          if (action === 'cancel') {
            this.setState({ dialogOpenContinue: false });
          }else{
            if (action === 'continue') {
              this.setState({ dialogOpenContinue: false });
              this.addUserGroup('', '', '','')
            }
          }
      }
      if (type === 'Error: 1006') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Warning: 2001') {
          if (action === 'accept') {
            this.setState({ dialogOpenWarning: false });
          }
      }
      if (type === 'Warning: 2002') {
        if (action === 'confirm') {
          this.setState({ dialogOpenPrint: false });
          this.dataUser2(this.c01UserGroup.current.value)
        }
        if (action === 'cancel') {
          this.setState({ dialogOpenPrint: false });
        }
      }
      if (type === 'Warning: 2003') {
          if (action === 'accept') {
            this.setState({ dialogOpenWarning: false });
          }
      }
      if (type === 'Warning: 2004') {
          if (action === 'accept') {
            this.setState({ dialogOpenWarning: false });
          }
      }
      if (type === 'Confirm: 3001') {
          if (action === 'accept') {
            this.setState({ dialogOpenAccept: false });
          }
      }
      if (type === 'Confirm: 3002') {
        if (action === 'confirm') {
          this.setState({ dialogOpenPrint: false });
          let name = ''
          let email = ''
          if (localStorage["printUserGroupEmail"]) {
             if (localStorage["printUserGroupName"]) {
                name = localStorage.getItem('printUserGroupName')
             }
             email = localStorage.getItem('printUserGroupEmail')
             let e = ''
             this.sendInvite2(e,email,name)
          }
        }
        if (action === 'cancel') {
          this.setState({ dialogOpenPrint: false });
        }
      }
      if (type === 'Send Invitation') {
        if (action === 'send') {
          this.setState({ dialogOpenSendLink: false, displaySendLink: false, });
          this.sendInvite3()
        }
        if (action === 'cancel') {
          this.setState({ dialogOpenSendLink: false, displaySendLink: false, });
        }
      }

      if (type === 'View Profile') {
        if (action === 'continue') {
          this.setState({ dialogOpenViewProfile: false, });
        }
      }

    }

    handleDialog2 = (type, action, cryptoX, saldoX) => {
      if (type === 'Activate Participants') {
        if (action === 'pay') {
          this.setState({ dialogOpenActiveUserGroup: false });
          this.actualizarActiveUserGroup(cryptoX, saldoX)
        }
        if (action === 'cancel') {
          this.setState({ dialogOpenActiveUserGroup: false, displayActiveUserGroupImpide: false, });
        }
      }
    }

    actualizarSendLink(){
    }

    actualizarActiveUserGroup(cryptoX, saldoX){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      let myUserConfig = this.props.myUserConfig
      let balanceSTX2 = myUserConfig.balanceSTX
      let balanceKDA2 = myUserConfig.balanceKDA
      let balanceETH2 = myUserConfig.balanceETH
      if (cryptoX==='STX'){balanceSTX2 = saldoX}
      if (cryptoX==='KDA'){balanceKDA2 = saldoX}
      if (cryptoX==='ETH'){balanceETH2 = saldoX}
      let SMSNotifications = myUserConfig.smsNotifications
      let EmailNotifications = myUserConfig.emailNotifications
      let cryptoSTX = myUserConfig.STX
      let cryptoKDA = myUserConfig.KDA
      let cryptoETH = myUserConfig.ETH
      let cryptoBTC = myUserConfig.BTC
      let profileName = myUserConfig.profileName
      let profileAvatar = myUserConfig.profileAvatar
      const jsonBlockstack =`{"emailNotifications":"${EmailNotifications}","smsNotifications":"${SMSNotifications}","STX":"${cryptoSTX}","BTC":"${cryptoBTC}","KDA":"${cryptoKDA}","ETH":"${cryptoETH}","balanceSTX":"${balanceSTX2}","balanceKDA":"${balanceKDA2}","balanceETH":"${balanceETH2}","profileName":"${profileName}","profileAvatar":"${profileAvatar}","facebook":"${myUserConfig.facebook}","twitter":"${myUserConfig.twitter}","youtube":"${myUserConfig.youtube}","instagram":"${myUserConfig.instagram}","linkedin":"${myUserConfig.linkedin}","pinterest":"${myUserConfig.pinterest}","aboutme":"${myUserConfig.aboutme}","smsScope":"${myUserConfig.smsScope}","emailScope":"${myUserConfig.emailScope}"","emailScope":"${myUserConfig.emailScope}","smsCountry":"${myUserConfig.smsCountry}","smsPrefix":"${myUserConfig.smsPrefix}","stxScope":"${myUserConfig.stxScope}","btcScope":"${myUserConfig.btcScope}","webSite":"${myUserConfig.webSite}","applicationsScope":"${myUserConfig.applicationsScope}"}`;
      putFileDB(this.state.username,`myUserConfig.json`, 'Crosscheck_UserGroup', JSON.stringify(jsonBlockstack), JSON.stringify(options))
       .then(() => {
         this.actualizarActiveUserGroup2()
       })
    }

    actualizarActiveUserGroup2(){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4 = []
      getFileDB(this.state.username, `contractlist.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             jsonBlockstack6.map((todo, i) => {
                if (todo.typeContract === this.props.typeContract && todo.configurationContractNumber === this.props.numberContract) {
                   todo.activeUserGroup = true
                   this.actualizarActiveUserGroup3(jsonBlockstack6)
                }
             })
           }
         })
        .catch(error => {
        });
    }

    actualizarActiveUserGroup3(jsonBlockstack6){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      putFileDB(this.state.username, `contractlist.json`, 'Crosscheck_UserGroup', JSON.stringify(jsonBlockstack6), JSON.stringify(options))
        .then(() => {
           this.setState({displayActiveUserGroupImpide: true})
        })
        .catch(error => {});
    }

    printConfirm(email){
      if (this.state.language === 'English'){this.setState({dialogOpenPrint: true,dialogMessage: `The email ${email} associated with the User ID was found, do you want to send the invitation?`,dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'French'){this.setState({dialogOpenPrint: true,dialogMessage: `L'e-mail ${email} associé à l'ID utilisateur a été trouvé, souhaitez-vous envoyer l'invitation?`,dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenPrint: true,dialogMessage: `Se ha encontrado el correo electrónico ${email} asociado al Id de Usuario, ¿desea enviar la invitación?`,dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenPrint: true,dialogMessage: `O email ${email} associado ao ID do usuário foi encontrado. Deseja enviar o convite?`,dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenPrint: true,dialogMessage: `E-postmeddelandet ${email} som är kopplat till användar-ID hittades, vill du skicka inbjudan?`,dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenPrint: true,dialogMessage: `Het e-mailadres ${email} dat aan de gebruikers-ID is gekoppeld, is gevonden. Wilt u de uitnodiging verzenden?`,dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenPrint: true,dialogMessage: `Электронная почта ${email}, связанная с идентификатором пользователя, была найдена. Вы хотите отправить приглашение?`,dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenPrint: true,dialogMessage: `ユーザーIDに関連付けられたメール ${email} が見つかりました。招待状を送信しますか？`,dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenPrint: true,dialogMessage: `找到与用户ID关联的电子邮件 ${email}，您是否要发送邀请？`,dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'German'){this.setState({dialogOpenPrint: true,dialogMessage: `Die mit der Benutzer-ID verknüpfte E-Mail-Adresse ${email} wurde gefunden. Möchten Sie die Einladung senden?`,dialogTitle: 'Confirm: 3002',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenPrint: true,dialogMessage: `L'email ${email} associata all'ID utente è stata trovata, vuoi inviare l'invito?`,dialogTitle: 'Confirm: 3002',})}
    }

    deleteConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'Cannot be deleted, an user has signed the contract',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "Ne peut être supprimé, un utilisateur a signé le contrat",dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'No se puede eliminar, un usuario ha firmado el contrato',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'Não pode ser excluído, um usuário assinou o contrato',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Det går inte att radera, en användare har tecknat avtalet',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'Kan niet worden verwijderd, een gebruiker heeft het contract ondertekend',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Невозможно удалить, пользователь подписал контракт',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '削除できません。ユーザーが契約に署名しています',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '无法删除，用户已签署合同',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Kann nicht gelöscht werden, ein Benutzer hat den Vertrag unterschrieben',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: 'Non può essere eliminato, un utente ha firmato il contratto',dialogTitle: 'Error: 1001',})}
    }

    idProvisoryConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'You cannot add a new participant, you have a Provisional Decentralized Domain. You must acquire a domain.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "Vous ne pouvez pas ajouter de nouveau participant, vous disposez d'un Domaine Décentralisé Provisoire. Vous devez acquérir un domaine.",dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'No se puede agregar nuevo participante, usted tiene un Dominio Descentralizado Provisorio. Debe adquirir un Dominio',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'Você não pode adicionar um novo participante, você tem um Domínio Descentralizado Provisório. Você deve adquirir um domínio.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Du kan inte lägga till en ny deltagare, du har en provisorisk decentraliserad domän. Du måste skaffa en domän.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'U kunt geen nieuwe deelnemer toevoegen, u heeft een Voorlopig Decentraal Domein. U moet een domein verwerven.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Вы не можете добавить нового участника, у вас есть Временный децентрализованный домен. Вы должны приобрести домен.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '新しい参加者を追加することはできません。暫定的な分散ドメインがあります。 ドメインを取得する必要があります。',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '您不能添加新參與者，您有一個臨時分散域。 您必須獲得一個域。',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Sie können keinen neuen Teilnehmer hinzufügen, Sie haben eine vorläufige dezentrale Domäne. Sie müssen eine Domain erwerben.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: 'Non puoi aggiungere un nuovo partecipante, hai un dominio decentralizzato provvisorio. Devi acquisire un dominio.',dialogTitle: 'Error: 1001',})}
    }

    nonRoleConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'You must select a Role',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "Vous devez sélectionner un rôle",dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'Debe seleccionar un Rol',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'Você deve selecionar uma função',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Du måste välja en roll',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'U moet een rol selecteren',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Вы должны выбрать роль',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '役割を選択する必要があります',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '您必须选择一个角色',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Sie müssen eine Rolle auswählen',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: 'Devi selezionare un ruolo',dialogTitle: 'Error: 1001',})}
    }

    userIdConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenContinue: true,dialogMessage: 'User does not have a Stacks ID',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'French'){this.setState({dialogOpenContinue: true,dialogMessage: "L'utilisateur n'a pas d'identifiant Stacks",dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenContinue: true,dialogMessage: 'El usuario no tiene un ID de Stacks',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenContinue: true,dialogMessage: 'O usuário não possui um ID do Stacks',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenContinue: true,dialogMessage: 'Användaren har inte ett Stacks-ID',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenContinue: true,dialogMessage: 'Gebruiker heeft geen Stacks-ID',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenContinue: true,dialogMessage: 'У пользователя нет Stacks-ID',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenContinue: true,dialogMessage: 'ユーザーはStacks-IDを持っていません ',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenContinue: true,dialogMessage: '用户没有Stacks ID',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'German'){this.setState({dialogOpenContinue: true,dialogMessage: 'Der Benutzer hat keine Stacks-ID',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenContinue: true,dialogMessage: "L'utente non ha un ID Stacks",dialogTitle: 'Error: 1005',})}
    }

    existeUserConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenPrint: true,dialogMessage: 'The user already exists, do you want to continue to add it?',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'French'){this.setState({dialogOpenPrint: true,dialogMessage: `L'utilisateur existe déjà, voulez-vous continuer à l'ajouter?`,dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenPrint: true,dialogMessage: 'El usuario ya existe, desea continuar para agregarlo?',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenPrint: true,dialogMessage: 'O usuário já existe. Deseja continuar a adicioná-lo?',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenPrint: true,dialogMessage: 'Användaren finns redan, vill du fortsätta lägga till den?',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenPrint: true,dialogMessage: 'De gebruiker bestaat al. Wilt u deze blijven toevoegen?',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenPrint: true,dialogMessage: 'Пользователь уже существует, вы хотите продолжить его добавление?',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenPrint: true,dialogMessage: 'ユーザーは既に存在します。追加し続けますか？',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenPrint: true,dialogMessage: '该用户已经存在，您要继续添加吗？',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'German'){this.setState({dialogOpenPrint: true,dialogMessage: 'Der Benutzer ist bereits vorhanden. Möchten Sie ihn weiterhin hinzufügen?',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenContinue: true,dialogMessage: "L'utente esiste già, vuoi continuare ad aggiungerlo?",dialogTitle: 'Warning: 2002',})}
    }

    existePayerPayToConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'Role (Payer or PayTo) already exists ',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "Le rôle (Payer ou PayTo) existe déjà",dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'Rol (Payer o PayTo) ya existe',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'A função (Payer ou PayTo) já existe ',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Roll (Payer eller PayTo) finns redan',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'Rol (Payer of PayTo) bestaat al ',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Роль (Payer или PayTo) уже существует',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '役割（Payer / PayTo）はすでに存在します',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '角色（Payer / PayTo）已經存在 ',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Die Rolle (Payer oder PayTo) ist bereits vorhanden ',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: "Il ruolo (Payer o PayTo) esiste già ",dialogTitle: 'Error: 1006',})}
    }

    existeNotSamePayToConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'User "PayTo" must not be the same user "Creator" or "Payer" ',dialogTitle: 'Error: 1007',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: `L'utilisateur "PayTo" ne doit pas être le même utilisateur "Creator" ou "Payer" `,dialogTitle: 'Error: 1007',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'Usuario "PayTo" no debe ser el mismo usuario "Creator" o "Payer" ',dialogTitle: 'Error: 1007',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'O usuário "PayTo" não deve ser o mesmo usuário "Creator" ou "Payer"  ',dialogTitle: 'Error: 1007',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Användaren "PayTo" får inte vara samma användare "Creator" eller "Payer" ',dialogTitle: 'Error: 1007',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'Gebruiker "PayTo" mag niet dezelfde gebruiker "Creator" of "Payer" zijn ',dialogTitle: 'Error: 1007',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Пользователь "PayTo" не должен быть одним и тем же пользователем "Creator" или "Payer" ',dialogTitle: 'Error: 1007',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: 'ユーザー「PayTo」は、同じユーザー「Creator」または「Payer」であってはなりません ',dialogTitle: 'Error: 1007',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '用戶“ PayTo”不得與“創建者”或“付款人”相同 ',dialogTitle: 'Error: 1007',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Benutzer "PayTo" darf nicht derselbe Benutzer "Creator" oder "Payer" sein. ',dialogTitle: 'Error: 1007',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: `L'utente "PayTo" non deve essere lo stesso utente "Creator" o "Payer" `,dialogTitle: 'Error: 1007',})}
    }

    existeUserRoleConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'User with the same role is already created',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "L'utilisateur avec le même rôle est déjà créé",dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'Usuario con el mismo rol ya está creado',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'Usuário com a mesma função já está criado',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Användare med samma roll har redan skapats',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'Gebruiker met dezelfde rol is al gemaakt',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Пользователь с той же ролью уже создан',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '同じ役割を持つユーザーが既に作成されています',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '具有相同角色的用户已创建',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Benutzer mit derselben Rolle ist bereits angelegt',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: `L'utente con lo stesso ruolo è già stato creato`,dialogTitle: 'Error: 1003',})}
    }

    userIdUsernameConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'The user cannot be the same as the login username',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "L'utilisateur ne peut pas être identique au nom d'utilisateur de connexion",dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'El usuario no puede ser igual al username del login',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'O usuário não pode ser igual ao nome de usuário de login',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Användaren kan inte vara densamma som användarnamnet för inloggning',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'De gebruiker kan niet dezelfde zijn als de log-in gebruikersnaam',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Пользователь не может совпадать с логином',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: 'ユーザーはログインユーザー名と同じにすることはできません',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '用户不能与登录用户名相同',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Der Benutzer darf nicht mit dem Login-Benutzernamen identisch sein',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: "L'utente non può essere uguale al nome utente di accesso",dialogTitle: 'Error: 1002',})}
    }

    userAppConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenWarning: true,dialogMessage: 'User not exist in Dapp CrossCheck',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'French'){this.setState({dialogOpenWarning: true,dialogMessage: "L'utilisateur n'existe pas dans Dapp CrossCheck",dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenWarning: true,dialogMessage: 'El usuario no existe en Dapp CrossCheck',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenWarning: true,dialogMessage: 'O usuário não existe no Dapp CrossCheck',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenWarning: true,dialogMessage: 'Användaren finns inte i Dapp CrossCheck',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenWarning: true,dialogMessage: 'Gebruiker bestaat niet in Dapp CrossCheck',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenWarning: true,dialogMessage: 'Пользователь не существует в Dapp CrossCheck',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenWarning: true,dialogMessage: 'Dapp CrossCheckにユーザーが存在しません',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenWarning: true,dialogMessage: 'Dapp CrossCheck中不存在用户',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'German'){this.setState({dialogOpenWarning: true,dialogMessage: 'UBenutzer existiert nicht in Dapp CrossCheck',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenWarning: true,dialogMessage: "L'utente non esiste in Dapp CrossCheck",dialogTitle: 'Warning: 2001',})}
    }

    userUndefinedConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenWarning: true,dialogMessage: 'User must reconnect to Dapp CrossCheck',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'French'){this.setState({dialogOpenWarning: true,dialogMessage: "L'utilisateur doit se reconnecter à Dapp CrossCheck",dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenWarning: true,dialogMessage: 'El usuario debe volver a conectarse a Dapp CrossCheck',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenWarning: true,dialogMessage: 'O usuário deve se reconectar ao Dapp CrossCheck',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenWarning: true,dialogMessage: 'Användaren måste ansluta till Dapp CrossCheck igen',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenWarning: true,dialogMessage: 'Gebruiker moet opnieuw verbinding maken met Dapp CrossCheck',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenWarning: true,dialogMessage: 'Пользователь должен повторно подключиться к Dapp CrossCheck',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenWarning: true,dialogMessage: 'ユーザーはDapp CrossCheckに再接続する必要があります',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenWarning: true,dialogMessage: '用户必须重新连接到Dapp CrossCheck',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'German'){this.setState({dialogOpenWarning: true,dialogMessage: 'Der Benutzer muss erneut eine Verbindung zu Dapp CrossCheck herstellen',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenWarning: true,dialogMessage: "L'utente deve riconnettersi a Dapp CrossCheck",dialogTitle: 'Warning: 2003',})}
    }

    responseConfirm(){
      if (this.state.language === 'English'){this.setState({ dialogOpenAccept: true, dialogMessage: 'Email was sent successfully', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'French'){this.setState({ dialogOpenAccept: true, dialogMessage: 'Email a été envoyé avec succès', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Spanish'){this.setState({ dialogOpenAccept: true, dialogMessage: 'Email fue enviado satisfactoriamente', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Portuguese'){this.setState({ dialogOpenAccept: true, dialogMessage: 'O email foi enviado com sucesso', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Swedish'){this.setState({ dialogOpenAccept: true, dialogMessage: 'E-postmeddelandet skickades', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Netherlands'){this.setState({ dialogOpenAccept: true, dialogMessage: 'E-mail is succesvol verzonden', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Russian'){this.setState({ dialogOpenAccept: true, dialogMessage: 'Письмо было успешно отправлено', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Japanese'){this.setState({ dialogOpenAccept: true, dialogMessage: 'メールは正常に送信されました', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Chinese'){this.setState({ dialogOpenAccept: true, dialogMessage: '电子邮件已成功发送', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'German'){this.setState({ dialogOpenAccept: true, dialogMessage: 'Die Email wurde erfolgreich versendet', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Italian'){this.setState({ dialogOpenAccept: true, dialogMessage: "L'email è stata inviata con successo", dialogTitle: 'Confirm: 3001', })}
    }

    responseErrorSend(){
      if (this.state.language === 'English'){this.setState({ dialogOpenWarning: true, dialogMessage: 'Email could not be sent, try again', dialogTitle: 'Error: 1004', })}
      if (this.state.language === 'French'){this.setState({ dialogOpenWarning: true, dialogMessage: "Impossible d'envoyer l'e-mail, essayez à nouveau", dialogTitle: 'Error: 1004', })}
      if (this.state.language === 'Spanish'){this.setState({ dialogOpenWarning: true, dialogMessage: 'Email no pudo ser enviado, inténtelo nuevamente', dialogTitle: 'Error: 1004', })}
      if (this.state.language === 'Portuguese'){this.setState({ dialogOpenWarning: true, dialogMessage: 'Não foi possível enviar o e-mail, tente novamente', dialogTitle: 'Error: 1004', })}
      if (this.state.language === 'Swedish'){this.setState({ dialogOpenWarning: true, dialogMessage: 'E-post kunde inte skickas, försök igen', dialogTitle: 'Error: 1004', })}
      if (this.state.language === 'Netherlands'){this.setState({ dialogOpenWarning: true, dialogMessage: 'E-mail kon niet worden verzonden, probeer het opnieuw', dialogTitle: 'Error: 1004', })}
      if (this.state.language === 'Russian'){this.setState({ dialogOpenWarning: true, dialogMessage: 'Не удалось отправить письмо, попробуйте еще раз', dialogTitle: 'Error: 1004', })}
      if (this.state.language === 'Japanese'){this.setState({ dialogOpenWarning: true, dialogMessage: 'メールを送信できませんでした。もう一度お試しください', dialogTitle: 'Error: 1004', })}
      if (this.state.language === 'Chinese'){this.setState({ dialogOpenWarning: true, dialogMessage: '无法发送电子邮件，请重试', dialogTitle: 'Error: 1004', })}
      if (this.state.language === 'German'){this.setState({ dialogOpenWarning: true, dialogMessage: 'E-Mail konnte nicht gesendet werden. Versuchen Sie es erneut', dialogTitle: 'Error: 1004', })}
      if (this.state.language === 'Italian'){this.setState({ dialogOpenWarning: true, dialogMessage: "Impossibile inviare l'e-mail, riprovare", dialogTitle: 'Error: 1004', })}
    }

    warningConfirmUserGroup(){
      if (this.state.language === 'English'){this.setState({ dialogOpenWarning: true, dialogMessage: 'The option to add more participants is disabled. To enable it you must click on the button «Activate Participants»', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'French'){this.setState({ dialogOpenWarning: true, dialogMessage: `L'option pour ajouter plus de participants est désactivée. Pour l'activer, vous devez cliquer sur le bouton «Activer les participants»`, dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Spanish'){this.setState({ dialogOpenWarning: true, dialogMessage: 'La opción para agregar más participantes está deshabilitada. Para habilitarla debe hacer click en el botón «Activar Participantes»', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Portuguese'){this.setState({ dialogOpenWarning: true, dialogMessage: 'A opção de adicionar mais participantes está desativada. Para habilitá-lo, você deve clicar no botão «Ativar Participantes»', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Swedish'){this.setState({ dialogOpenWarning: true, dialogMessage: 'Alternativet att lägga till fler deltagare är inaktiverat. För att aktivera det måste du klicka på knappen «Aktivera Deltagare»', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Netherlands'){this.setState({ dialogOpenWarning: true, dialogMessage: 'De optie om meer deelnemers toe te voegen is uitgeschakeld. Om dit in te schakelen, moet u op de knop «Deelnemers activeren» klikken', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Russian'){this.setState({ dialogOpenWarning: true, dialogMessage: 'Возможность добавить больше участников отключена. Чтобы включить его, необходимо нажать на кнопку «Активировать участников»', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Japanese'){this.setState({ dialogOpenWarning: true, dialogMessage: '参加者をさらに追加するオプションは無効になっています。 これを有効にするには、「参加者をアクティブにする」ボタンをクリックする必要があります', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Chinese'){this.setState({ dialogOpenWarning: true, dialogMessage: '添加更多參與者的選項被禁用。 要啟用它，您必須單擊按鈕“激活參與者”', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'German'){this.setState({ dialogOpenWarning: true, dialogMessage: 'Die Option zum Hinzufügen weiterer Teilnehmer ist deaktiviert. Um es zu aktivieren, müssen Sie auf die Schaltfläche «Teilnehmer aktivieren» klicken.', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Italian'){this.setState({ dialogOpenWarning: true, dialogMessage: `L'opzione per aggiungere più partecipanti è disabilitata. Per abilitarlo è necessario fare clic sul pulsante «Attiva partecipanti»`, dialogTitle: 'Warning: 2004', })}
    }

    errorRoleNoAcceptConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'Document is published, authoriter user, payer or payment recipient cannot be added',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "Le document est publié, l'utilisateur autorité, le payeur ou le destinataire du paiement ne peut pas être ajouté",dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'Documento está publicado, no se puede agregar usuario autorizador, pagador o receptor de pago',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'O documento é publicado, o usuário autorizador, pagador ou destinatário do pagamento não podem ser adicionados',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Dokumentet publiceras, författaranvändare, betalare eller betalningsmottagare kan inte läggas till',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'Document is gepubliceerd, authoriterende gebruiker, betaler of betalingsontvanger kan niet worden toegevoegd',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Документ опубликован, пользователь-автор, плательщик или получатель платежа не могут быть добавлены',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: 'ドキュメントが公開され、承認者ユーザー、支払人、または支払受取人を追加できません',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '文檔已發布，無法添加授權用戶、付款人或收款人',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Dokument ist veröffentlicht, Autoritierender Benutzer, Zahler oder Zahlungsempfänger kann nicht hinzugefügt werden',dialogTitle: 'Error: 1006',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: "Il documento è pubblicato, l'utente autore, il pagatore o il destinatario del pagamento non possono essere aggiunti",dialogTitle: 'Error: 1006',})}
    }

    idProvisoryConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'You cannot add a new participant, you have a Provisional Decentralized Domain. You must acquire a domain.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "Vous ne pouvez pas ajouter de nouveau participant, vous disposez d'un Domaine Décentralisé Provisoire. Vous devez acquérir un domaine.",dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'No se puede agregar nuevo participante, usted tiene un Dominio Descentralizado Provisorio. Debe adquirir un Dominio',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'Você não pode adicionar um novo participante, você tem um Domínio Descentralizado Provisório. Você deve adquirir um domínio.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Du kan inte lägga till en ny deltagare, du har en provisorisk decentraliserad domän. Du måste skaffa en domän.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'U kunt geen nieuwe deelnemer toevoegen, u heeft een Voorlopig Decentraal Domein. U moet een domein verwerven.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Вы не можете добавить нового участника, у вас есть Временный децентрализованный домен. Вы должны приобрести домен.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '新しい参加者を追加することはできません。暫定的な分散ドメインがあります。 ドメインを取得する必要があります。',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '您不能添加新參與者，您有一個臨時分散域。 您必須獲得一個域。',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Sie können keinen neuen Teilnehmer hinzufügen, Sie haben eine vorläufige dezentrale Domäne. Sie müssen eine Domain erwerben.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: 'Non puoi aggiungere un nuovo partecipante, hai un dominio decentralizzato provvisorio. Devi acquisire un dominio.',dialogTitle: 'Error: 1001',})}
    }

    goUserGroup() {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({displayUserGroup: true})
      return new Promise((resolve1, reject1) => {
          getFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_usergroup.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.completaNameAndAvatar(jsonBlockstack6)
                 resolve1()
               } else {
                 resolve1()
               }
             })
              .catch(error => {
                resolve1()
              });
      });
    }

    goRole() {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({jsonRole: [], displayRole: true})
      return new Promise((resolve2, reject2) => {
          getFileDB(this.state.username, `role_${this.props.typeContract}.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.setState({jsonRole: jsonBlockstack6})
                 resolve2()
               } else {
                 resolve2()
               }
             })
              .catch(error => {
                getFileDB(this.state.username, `role.json`)
                   .then((fileContents) => {
                     if(fileContents) {
                       const jsonBlockstack1 = JSON.parse(fileContents)
                       const jsonBlockstack2 = jsonBlockstack1.data
                       let jsonBlockstack5 = '[]'
                       if (jsonBlockstack2 !== null){
                          const jsonBlockstack3 = jsonBlockstack2.dataobject
                          const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                          if (jsonBlockstack4.substring(0,1) === '"') {
                             jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                          } else {
                             jsonBlockstack5 = jsonBlockstack4
                          }
                       }
                       let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                       this.setState({jsonRole: jsonBlockstack6})
                       resolve2()
                     } else {
                       resolve2()
                     }
                   })
                 .catch(error => {
                     resolve2()
                   })
              });
       });
    }

    clickInvite = (e,idx,namex,rolex,emailx) => {
        this.setState({displayInvite: false, idx: idx, namex: namex, rolex: rolex, emailx: emailx})
        this.setState({ dialogOpenSendLink: true, dialogMessage: '', dialogTitle: 'Send Invitation', })
    }

    clickSendContract = (e,idx,namex,rolex,emailx) => {
    }

    sendInvite = (e,emailx) => {
      this.setState({displayInvite: false, emailx: emailx})
      const {userSession} = this.state
      const  {username} = userSession.loadUserData();
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }

      let {jsonUserGroup} = this.state
      if (this.state.jsonUserGroup.length===0){
         jsonUserGroup = this.props.jsonUserGroup
      }

      jsonUserGroup.map((todo2, j) => {
        if(todo2.id===this.state.idx){
          todo2.email=emailx
          todo2.statusInvite=true
        }
      })
      let nameZ
      if (this.state.name === null || this.state.name === undefined){
         nameZ = ''
      }else{
         nameZ = this.state.name
      }
      const jsonBlockstack1 = JSON.stringify(jsonUserGroup)
      putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_usergroup.json`, 'Crosscheck_UserGroup', jsonBlockstack1, JSON.stringify(options))
        .then(() => {
        })
    }

    sendInvite2 = (e,emailx,nameX) => {
      const subject = 'A business agreement has been shared with you in CrossCheck'
      const palabra = this.c02UserGroup.current.value,
            ArrayIdValue = palabra.split(' - '),
            sRole = ArrayIdValue[1]
      const receiverUserId = this.c01UserGroup.current.value

      emailjs.send("service_9dv38as", "template_nf270qf", {receiverUserName: nameX, address: emailx, senderUserName: this.state.username, senderName: this.state.name, typeContract: this.props.typeContract, numberContract: this.props.numberContract, role: sRole, link: `https://xck.app?username=${this.state.username}`, subject: subject, receiverUserId: receiverUserId}, "user_djYXBdBTeG1Km5qtyqkDl")
          .then(response => {
             console.log('SUCCESS!', response.status, response.text)
             this.sendInvite(e,emailx)
             this.readMyInvitationEmail(emailx)
             this.responseConfirm()
          }, error => {
             console.log('FAILED...', error)
             this.responseErrorSend()
          });

      /*
      fetch("https://xck.app:5443/sendContractInvitation",
            {
              method: "POST",
              headers:{
                'Content-Type': 'application/json',
                "token": `${token}`
              },
              body: JSON.stringify({
                receiverUserName: nameX,
                address: emailx,
                senderUserName: this.state.name,
                typeContract: this.props.typeContract,
                numberContract: this.props.numberContract,
                role: this.c02UserGroup.current.value,
                link: `https://xck.app?username=${this.state.username}`
              })
            }
      ).then((response)=>{
        if (response.status === 200) {
          this.responseConfirm()
        }
        else{
          this.responseErrorSend()
        }
      }).catch(()=>{
        this.responseErrorSend()
      })
      */
    }

    sendInvite3 = () => {
      const subject = 'Invitation to participate in a business contract using Crosscheck'

      emailjs.send("service_9dv38as", "template_nf270qf", {receiverUserName: this.state.namex, address: this.state.emailx, senderUserName: this.state.username, senderName: this.state.name, typeContract: this.props.typeContract, numberContract: this.props.numberContract, role: this.state.rolex, link: `https://xck.app?username=${this.state.username}`, subject: subject, receiverUserId: this.state.idx}, "user_djYXBdBTeG1Km5qtyqkDl")
          .then(response => {
             console.log('SUCCESS!', response.status, response.text)
             let e=''
             this.sendInvite(e,this.state.emailx)
             this.readMyInvitationEmail(this.state.emailx)
             this.responseConfirm()
          }, error => {
             console.log('FAILED...', error)
             this.responseErrorSend()
          });
    }


    clickRemove = (e,optx,signature, sRole) => {
        if (signature === ''){
            const {userSession} = this.state
            const storage = new Storage({ userSession });
            const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
            let iotdevice = []
            let userX = []
            let idX = null
            if (this.state.jsonUserGroup.length===0){
               iotdevice = this.props.jsonUserGroup
               userX = iotdevice[optx]
               let {id} = userX
               idX = id
            }else{
               iotdevice = this.state.jsonUserGroup
               userX = iotdevice[optx]
               let {id} = userX
               idX = id
            }
            iotdevice.splice(optx,1);
            const jsonBlockstack1 = JSON.stringify(iotdevice)
            putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_usergroup.json`, 'Crosscheck_UserGroup', jsonBlockstack1, JSON.stringify(options))
              .then(() => {
                 this.setState({jsonUserGroup: iotdevice})
                 this.readUserRemoveRemote(idX, sRole)
              })
        }else{
            this.deleteConfirm()
        }
    }

    readUserRemoveRemote(idUserX, sRole){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      getFileDB(this.state.username, `${idUserX}_remote.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             this.setState({jsonUserGroupRemote: jsonBlockstack6})
             this.clickRemoveRemote(jsonBlockstack6,idUserX,sRole)
           } else {
           }
         })
          .catch(error => {
          });
    }

    clickRemoveRemote(jsonBlockstack4,idUserX,sRole){
      let booleanExist = false
      let optx = null
      let largo = jsonBlockstack4.length
      jsonBlockstack4.map((todo,i) =>{
        if (todo !== null){
          if (todo.typeContract === this.props.typeContract &&
              todo.configurationContractNumber === this.props.numberContract &&
              todo.role === sRole){
              jsonBlockstack4.splice(i,1);
            optx = i
            booleanExist = true
          }
          if (i === (largo - 1)){
             this.writeRemote(jsonBlockstack4,idUserX,booleanExist)
          }
        }
      })
    }

    writeRemote(jsonBlockstack4,idUserX,booleanExist){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      if (booleanExist === true){
         putFileDB(this.state.username, `${idUserX}_remote.json`, 'Crosscheck_UserGroup', JSON.stringify(jsonBlockstack4), JSON.stringify(options))
             .then(() => {
             })
             .catch(error => {
             });
      }
    }

    addUserGroup(owner_address, name, publicKey, email){
        let {jsonUserGroup} = this.state
        if (this.state.jsonUserGroup.length===0){
           jsonUserGroup = this.props.jsonUserGroup
        }
        this.addUserGroup2(owner_address, name, publicKey, email, jsonUserGroup)
    }

    addUserGroup2(owner_address, name, publicKey, email, jsonUserGroup){
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }

        const palabra = this.c02UserGroup.current.value,
              ArrayIdValue = palabra.split(' - '),
              sRole = ArrayIdValue[1]
        if ((this.props.userRole === 'Creator' && (sRole === 'Observer' || sRole === 'Authorizer' || sRole === 'Support' || sRole === 'Authorizer-Inviter' || sRole === 'Payer' || sRole === 'PayTo')) ||
            (this.props.userRole === 'Creator' && (sRole !== 'Observer' && sRole !== 'Authorizer' && sRole !== 'Support' && sRole !== 'Authorizer-Inviter' && sRole !== 'Payer' && sRole !== 'PayTo')) ||
            (this.props.userRole === 'Authorizer' && sRole === 'Authorizer-Inviter')) {
            const newUser = {"id":this.c01UserGroup.current.value,"name":name,"role":sRole,"active":"true","signature":"","identityAddress":owner_address,"statusInvite":false,"email":email, "publicKey":publicKey}
            let infoUserGoupX=[...jsonUserGroup, newUser]
            putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_usergroup.json`, 'Crosscheck_UserGroup', JSON.stringify(infoUserGoupX), JSON.stringify(options))
               .then(() => {
                  this.readUserGroupRemote(sRole)
                  this.completaNameAndAvatar(infoUserGoupX)
               })
        }
    }

    readMyInvitationUserId(owner_address, name, publicKey1) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      localStorage.removeItem('printUserGroupName')
      localStorage.removeItem('printUserGroupEmail')
      this.setState({emailMyInvitation:''})
      let emailX = ''
      let nameX = ''
      getFileDB(this.state.username, `my_invitations.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             let e = ''
             let existMyInvitationUserId = false
             jsonBlockstack6.map((todo,i)=>{
               if (todo.userid === this.c01UserGroup.current.value){
                 this.setState({emailMyInvitation:todo.email})
                 existMyInvitationUserId = true
                 emailX = todo.email
                 nameX = todo.name
               }
             })
             if (existMyInvitationUserId === true){
               if (name === null || name === undefined){
                 name = nameX
               }
               localStorage.setItem('printUserGroupName',name)
               localStorage.setItem('printUserGroupEmail',emailX)
               this.printConfirm(emailX)
             }
             this.addUserGroup(owner_address, name, publicKey1,emailX)
           } else {
             this.addUserGroup(owner_address, name, publicKey1,emailX)
           }
         })
          .catch(error => {
            this.addUserGroup(owner_address, name, publicKey1,emailX)
          });
    }

    readMyInvitationEmail(emailX) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let infoInvitation = ''
      getFileDB(this.state.username, `my_invitations.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             let existMyInvitation = false
             jsonBlockstack6.map((todo,i)=>{
                if (todo.email === emailX){
                  existMyInvitation = true
                  if (this.state.idx !== '' && this.state.idx !== null){
                     todo.userid = this.state.idx
                  }
                }
             })
             if (existMyInvitation === false) {
                 if (jsonBlockstack5 === '' || jsonBlockstack5 === '[]') {
                    infoInvitation =`[{"email":"${emailX}","name":"${this.state.namex}","userid":"${this.state.idx}"}]`
                 }else{
                    infoInvitation =`[${jsonBlockstack5},{"email":"${emailX}","name":"${this.state.namex}","userid":"${this.state.idx}"}]`
                 }
                 this.saveMyInvitation(infoInvitation)
             }else{
                 infoInvitation = JSON.stringify(jsonBlockstack6)
                 this.saveMyInvitation(infoInvitation)
             }
           } else {
             infoInvitation =`[{"email":"${emailX}","name":"${this.state.namex}","userid":"${this.state.idx}"}]`
             this.saveMyInvitation(infoInvitation)
           }
         })
          .catch(error => {
            infoInvitation =`[{"email":"${this.c03UserGroup.current.value}","name":"${this.state.namex}","userid":"${this.state.idx}"}]`
            this.saveMyInvitation(infoInvitation)
          });
    }

    readMyInvitation(username) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      return new Promise ((resolve, reject) =>{
          getFileDB(username, `my_invitations.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack3 = ''
                 let jsonBlockstack4 = ''
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    jsonBlockstack3 = jsonBlockstack2.dataobject
                    jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 const resultado = Array.from(new Set(jsonBlockstack6.map(s => s.userid)))
                   .map(userid => {
                       return {
                         userid: jsonBlockstack6.find(s => s.userid === userid).userid,
                         name: jsonBlockstack6.find(s => s.userid === userid).name
                       };
                   });
                 jsonBlockstack4 = resultado

                 this.setState({jsonContactList: jsonBlockstack6.sort(function(a, b){
                     const bandA = a.userid;
                     const bandB = b.userid;
                     let comparison = 0;
                     if (bandA > bandB) {
                       comparison = 1;
                     } else if (bandA < bandB) {
                       comparison = -1;
                     }
                     return comparison})
                 })
                 resolve()
               }else{
                 resolve()
               }
             })
              .catch(error => {
                 resolve()
             });
      });
    }

    advancedSearch = (e) => {
      this.setState({ advancedSearch: true })
    }

    saveMyInvitation(infoInvitation){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      putFileDB(this.state.username, `my_invitations.json`, 'Crosscheck_UserGroup', JSON.stringify(infoInvitation), JSON.stringify(options))
        .then(() => {
        })
    }

    readUserGroupRemote(sRole){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      getFileDB(this.state.username, `${this.c01UserGroup.current.value}_remote.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             this.setState({jsonUserGroupRemote: jsonBlockstack6})
             this.addUserGroupRemote(jsonBlockstack6,sRole)
           } else {
             this.addUserGroupRemote([],sRole)
           }
         })
          .catch(error => {
            this.addUserGroupRemote([],sRole)
          });
    }

    addUserGroupRemote(jsonBlockstack4,sRole){
      if (jsonBlockstack4.length > 0){
          let booleanExist = false
          let largo = jsonBlockstack4.length
          jsonBlockstack4.map((todo,i) =>{
            if (todo !== null){
               if (todo.typeContract === this.props.typeContract &&
                   todo.configurationContractNumber === this.props.numberContract &&
                   todo.role === sRole){
                   booleanExist = true
               }
               if (i === (largo-1)){
                 if (booleanExist === false){
                   this.addUserGroupRemote2(jsonBlockstack4,sRole)
                 }else{
                    this.exitUserGroup()
                 }
               }
            }else{
              this.addUserGroupRemote2([],sRole)
            }
          })
      }else{
        this.addUserGroupRemote2([],sRole)
      }
    }

    addUserGroupRemote2(jsonBlockstack4,sRole){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      if (jsonBlockstack4.length > 0){
         jsonBlockstack4=[...jsonBlockstack4, {"typeContract":this.props.typeContract,"configurationContractNumber":this.props.numberContract,"role":sRole,"active":"true","subject":""}]
      }else{
        jsonBlockstack4=[{"typeContract":this.props.typeContract,"configurationContractNumber":this.props.numberContract,"role":sRole,"active":"true","subject":""}]
      }
      putFileDB(this.state.username, `${this.c01UserGroup.current.value}_remote.json`, 'Crosscheck_UserGroup', JSON.stringify(jsonBlockstack4), JSON.stringify(options))
         .then(() => {
            this.exitUserGroup()
         })
         .catch(error => {
           this.exitUserGroup()
         });
    }

    dataUser(){
      if (this.c01UserGroup.current.value.length>0){
       if (this.c01UserGroup.current.value !== this.state.username ){
         if (this.c02UserGroup.current.value !== 'SelectOptionRole'){
           if (this.state.username === this.state.stacksNetX.substring(0,5).toLowerCase()+'...'+this.state.stacksNetX.substring(this.state.stacksNetX.length-5).toLowerCase()+'.xck.app'){
             this.idProvisoryConfirm()
           }else{
             const palabra = this.c02UserGroup.current.value
             const ArrayIdValue = palabra.split(' - ')
             const sRole = ArrayIdValue[1]
             let {jsonUserGroup} = this.state
             if (this.state.jsonUserGroup.length===0){
                 jsonUserGroup = this.props.jsonUserGroup
             }
             let existeUser = false
             let existeUserRole = false
             let existePayer = false
             let existePayTo = false
             let idPayer = ''
             let idCreator = ''
             let idPayTo = ''
             const largoUser = jsonUserGroup.length
             jsonUserGroup.map((todo,i)=>{
               if (todo.id === this.c01UserGroup.current.value){
                 existeUser = true
                 if (todo.role === sRole){
                    existeUserRole = true
                 }
               }
               if (todo.role === 'Payer'){
                  idPayer = todo.id
               }
               if (todo.role === 'Creator'){
                  idCreator = todo.id
               }
               if (todo.role === 'PayTo'){
                  idPayTo = todo.id
               }
               if (todo.role === 'Payer' && sRole === 'Payer'){
                  existePayer = true
               }
               if (todo.role === 'PayTo' && sRole === 'PayTo'){
                  existePayTo = true
               }
               if (i === (largoUser - 1)){
               if ((sRole === 'PayTo' && idPayer !== '' && idPayer === this.c01UserGroup.current.value) ||
                     (sRole === 'PayTo' && idCreator !== '' && idCreator === this.c01UserGroup.current.value) ||
                     (sRole === 'Payer' && idPayTo !== '' && idPayTo === this.c01UserGroup.current.value)
                    ){
                    this.existeNotSamePayToConfirm()
                 }else{
                   if (existePayer === true ) {
                      this.existePayerPayToConfirm()
                   }else{
                     if (existePayTo === true ) {
                        this.existePayerPayToConfirm()
                     }else{
                       if (existeUser === false && existeUserRole === false ) {
                         this.dataUser2(this.c01UserGroup.current.value)
                       }else{
                         if (existeUser === true && existeUserRole === false ) {
                            this.existeUserConfirm()
                         }else{
                            if (existeUser === true && existeUserRole === true ) {
                               this.existeUserRoleConfirm()
                            }
                         }
                       }
                     }
                   }
                 }
               }
             })
           }
         }else{
           this.nonRoleConfirm()
         }
       }else{

          this.userIdUsernameConfirm()
       }
      }
    }

    dataUser2(userX){
           const {userSession} = this.state
           const storage = new Storage({ userSession });
           const options = { username: userX, decrypt: false, verify: false }
           const publicKeyPath = "publickey2.json"
           getFileDB(userX, publicKeyPath)
              .then(fileContents => {
                  const jsonBlockstack1 = JSON.parse(fileContents)
                  const jsonBlockstack2 = jsonBlockstack1.data
                  let jsonBlockstack5 = '[]'
                  if (jsonBlockstack2 !== null){
                     const jsonBlockstack3 = jsonBlockstack2.dataobject
                     const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                     if (jsonBlockstack4.substring(0,1) === '"') {
                        jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                     } else {
                        jsonBlockstack5 = jsonBlockstack4
                     }
                     let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                     this.readMyInvitationUserId(this.state.identityAddress, '', jsonBlockstack6.publicKey2)
                  }else {
                    this.userUndefinedConfirm()
                    this.addUserGroup(this.state.identityAddress, '', '','')
                  }
             })
             .catch(error => {
                 console.log(error)
             });
    }

    clickEdit = (e,optx) => {
    }

    activeSendLink = () => {
      this.setState({ dialogOpenSendLink: true, dialogMessage: '', dialogTitle: 'Send Invitation', })
    }

    activeParticipant = () => {

      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''
      let contractNameX = ''
      let contractAddressX = ''
      let contractAddressX2 = ''
      let addressKeyX = ''

      const privateKey = makeRandomPrivKey();
      this.setState({privateKey})

      const { userSession } = this.state
      const user = userSession.loadUserData()
      const {username} = this.state;

      if (!localStorage["serverStacks"]) {
        serverStacksX = 'MainNet'
      }else{
        serverStacksX = localStorage.getItem('serverStacks')
      }

      if (serverStacksX === 'MainNet'){
         networkUrlX = MAINNET_STACKS_API_URL
         contractNameX = SMART_CONTRACT_NAME_MAINNET_STX
         contractAddressX = SMART_CONTRACT_ADDRESS_MAINNET_STX
         contractAddressX2 = SMART_CONTRACT_ADDRESS_MAINNET_STX
         addressKeyX =  user.profile.stxAddress.mainnet
      }
      if (serverStacksX === 'TestNet'){
         networkUrlX = TESTNET_STACKS_API_URL
         contractNameX = SMART_CONTRACT_NAME_TESTNET_STX
         contractAddressX = SMART_CONTRACT_ADDRESS_TESTNET_STX
         contractAddressX2 = SMART_CONTRACT_ADDRESS_MAINNET_STX
         addressKeyX =  user.profile.stxAddress.testnet
      }

      this.setState({privateKey, contractAddressX, addressKeyX, networkUrlX, contractNameX,contractAddressX2, serverStacksX, usernameX: username})

      balanceOf(contractAddressX, addressKeyX, networkUrlX, contractNameX,contractAddressX2, serverStacksX, username)
        .then(val => this.setState({balanceCta: val}))


      this.setState({ dialogOpenActiveUserGroup: true, dialogMessage: '', dialogTitle: 'Activate Participants', })
    }

    displayProfile = (e,user2X,avatar2X,name2X,emailX) =>{
      e.preventDefault();
      Promise.all([this.readRemoteNameAvatarNames(user2X)]).then((resolve1) => {this.setState({dialogOpenViewProfile: true, dialogMessage: '', dialogTitle: 'View Profile', avatar2X, name2X, user2X, emailX })},(reject1) => {})
    }

    exitUserGroup(){
      //----------------------------------------------------------
      Promise.all(
         [this.goUserGroup(),
          this.goRole()]
      ).then(
        (resolve) =>{},
        (reject) =>{}
      )
      //----------------------------------------------------------
    }

    exitProgram(){
       this.props.returnProps();
    }

    render() {

        let link1 = `https://xck.app?username=${this.state.username}`
        let displayContacX = false
        let displayActiveUserGroup = false
        let displayActiveUserGroupMessage = false
        let {jsonRole} = this.state
        if (this.props.typeContract !== 'simulate'){
          if (this.props.userRole === 'Creator'){
            if (this.props.confirmSignX === true){
              displayActiveUserGroupMessage = true
              displayContacX = true
              if (this.state.jsonRole.length===0){
                 jsonRole = this.props.jsonRole
              }
            }else{
              if (this.props.activeUserGroupX === true){
                displayContacX = true
                if (this.state.jsonRole.length===0){
                   jsonRole = this.props.jsonRole
                }
              }else{
                if (this.state.displayActiveUserGroupImpide === false){
                  // Para activar pago debe descomentar linea siguiente y comentar las cinco que siguen
                  //displayActiveUserGroup = true
                  displayActiveUserGroup = false
                  displayContacX = true
                  if (this.state.jsonRole.length===0){
                     jsonRole = this.props.jsonRole
                  }
                }else{
                  displayContacX = true
                  if (this.state.jsonRole.length===0){
                     jsonRole = this.props.jsonRole
                  }
                }
              }
            }
          }
        }else{
          if (this.props.userRole === 'Creator'){
             displayContacX = true
             if (this.state.jsonRole.length===0){
                jsonRole = this.props.jsonRole
             }
          }
        }
        let {jsonUserGroup} = this.state
        if (this.state.jsonUserGroup.length===0){
           jsonUserGroup = this.props.jsonUserGroup
        }
        const clickInviteX = this.state.displayInvite
        const advancedSearchX = this.state.advancedSearch
        const jsonContactListX = this.state.jsonContactList
        let titleX = 'User Group Manager'
        let placeholderX1 = "Select an Option"
        let placeholderEmail = "Enter the email"
        if (this.state.language === 'English'){titleX = "User Group Manager";placeholderX1 = "Select an Option"; placeholderEmail = "Enter the email"}
        if (this.state.language === 'French'){titleX = "Gestionnaire de groupe d'utilisateurs";placeholderX1 = "Sélectionner unproe.ide Option"; placeholderEmail = "Entrez l'e-mail"}
        if (this.state.language === 'Spanish'){titleX = "Mantenedor Grupo de Usuarios";placeholderX1 = "Seleccione una Opción"; placeholderEmail = "Ingresar el correo electrónico"}
        if (this.state.language === 'Portuguese'){titleX = "Gerenciador de grupo de usuários";placeholderX1 = "Selecione uma Opção"; placeholderEmail = "Digite o e-mail"}
        if (this.state.language === 'Swedish'){titleX = "Användargruppschef";placeholderX1 = "Välj ett Alternativ"; placeholderEmail = "Ange e-postmeddelandet"}
        if (this.state.language === 'Netherlands'){titleX = "Beheerder van gebruikersgroepen";placeholderX1 = "Kies een Optie"; placeholderEmail = "Voer de e-mail in"}
        if (this.state.language === 'Russian'){titleX = "Менеджер группы пользователей";placeholderX1 = "Выберите опцию"; placeholderEmail = "Введите адрес электронной почты "}
        if (this.state.language === 'Japanese'){titleX = "ユーザーグループマネージャー";placeholderX1 = "オプションを選択"; placeholderEmail = "メールアドレスを入力してください"}
        if (this.state.language === 'Chinese'){titleX = "用戶組管理員";placeholderX1 = "选择一个选项"; placeholderEmail = "輸入電子郵件"}
        if (this.state.language === 'German'){titleX = "Benutzergruppenmanager";placeholderX1 = "Wähle eine Option"; placeholderEmail = "Geben Sie die E-Mail ein"}
        if (this.state.language === 'Italian'){titleX = "Gestore del gruppo di utenti";placeholderX1 = "Seleziona un'opzione"; placeholderEmail = "Inserisci l'email"}

        const messageSubtittle = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`
        const {username} = this.state
        let correlativo = 0
        let avatar = ''
        let imageInvite = ''
        let imageSend = ''
        const avatar2 = 'images/avatar.png'


        let cryptoPactClarityEthereumX = ''
        let cryptoPactClarityEthereum2X = ''
        let myUserConfig = this.props.myUserConfig
        let balanceX = parseFloat(this.state.balanceCta)
        let transactionX = 0
        let commissionX = 0
        let rateX = 0
        let saldoX = 0
        cryptoPactClarityEthereumX = 'Stacks'
        cryptoPactClarityEthereum2X = 'STX'
        let disableTransferX = false
        if (balanceX < (transactionX + commissionX + rateX)) {
            saldoX = balanceX
            disableTransferX = true
        }else{
            saldoX = balanceX - (transactionX + commissionX + rateX)
        }

        let bSmsScope = false
        if(this.state.smsScope === 'Public'){
          bSmsScope = true
        }
        let bEmailScope = false
        if(this.state.emailScope === 'Public'){
          bEmailScope = true
        }
        let bStxScope = false
        if(this.state.stxScope === 'Public'){
          bStxScope = true
        }

        return (
          <div>
            <Container fluid className="main-content-container px-4">
              <Row noGutters className="page-header py-4">
                <TituloPagina2
                  sm="4"
                  title={titleX}
                  subtitle={messageSubtittle}
                  className="text-sm-left"
                />
              </Row>
              <Confirmacion
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpen}
                  dialogTitle={this.state.dialogTitle}
              />
              <Confirmacion2
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenPrint}
                  dialogTitle={this.state.dialogTitle}
              />
              <Confirmacion3
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenContinue}
                  dialogTitle={this.state.dialogTitle}
              />
              <ConfirmacionViewProfile
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenViewProfile}
                  dialogTitle={this.state.dialogTitle}
                  avatar={this.state.avatar3X}
                  avatar2={this.state.avatar2X}
                  username={this.state.user2X}
                  person={this.state.name2X}
                  aboutMe={this.state.aboutmeX}
                  facebook={this.state.facebookX}
                  twitter={this.state.twitterX}
                  youtube={this.state.youtubeX}
                  instagram={this.state.instagramX}
                  linkedin={this.state.linkedinX}
                  pinterest={this.state.pinterestX}
                  stxAddress={this.state.stxAddress2X}
                  email={this.state.emailX}
                  phone={this.state.phoneX}
                  espacio={' '}
                  smsScope={this.state.smsScope}
                  bSmsScope={bSmsScope}
                  bStxScope={bStxScope}
                  bEmailScope={bEmailScope}
                  smsPrefix={this.state.smsPrefix}

              />
              <ConfirmacionWarning
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenWarning}
                  dialogTitle={this.state.dialogTitle}
              />
              <ConfirmacionAccept
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenAccept}
                  dialogTitle={this.state.dialogTitle}
              />
              <ConfirmacionActiveUserGroup
                  handleDialog={this.handleDialog2}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenActiveUserGroup}
                  dialogTitle={this.state.dialogTitle}
                  cryptoPactClarityEthereumX={cryptoPactClarityEthereumX}
                  cryptoPactClarityEthereum2X={cryptoPactClarityEthereum2X}
                  addressX={this.state.serverStacksX}
                  balanceX={this.state.balanceCta}
                  transactionX={transactionX}
                  saldoX={saldoX}
                  disableTransferX={disableTransferX}
                  rateX={rateX}
                  commissionX={commissionX}
              />
              <ConfirmacionSendLink
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenSendLink}
                  dialogTitle={this.state.dialogTitle}
                  linkX={link1}
                  copyText={this.copyText}
                  copyToClipBoard={this.copyToClipBoard}
                  idx={this.state.idx}
                  emailx={this.state.emailx}
                  handleChangeInput={this.handleChangeInput}
                  placeholderEmail={placeholderEmail}
                  setSendInfo={this.state.setSendInfo}
                  onMouseEnterHandler={this.onMouseEnterHandler}
                  onMouseLeaveHandler={this.onMouseLeaveHandler}

              />

              <Row>
                <Col>
                  <Form style={{fontSize:12}}>
                    {displayContacX ?
                      <Row form>
                        <Col md="3">
                          <label htmlFor="Contact"><FormattedMessage id="usergroup.contact" /></label>
                          <InputGroup className="mb-2">
                            <FormInput
                               id="Contact"
                               type="text"
                               innerRef={this.c01UserGroup}
                               placeholder="Id Stacks"
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText>
                                <strong style={{cursor: 'pointer'}}  onClick={e=>this.advancedSearch(e)}><img src="images/searchUser.png" weight="20" height="20" alt=""/></strong>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>

                        {advancedSearchX ?
                          <Col md="3">
                            <label htmlFor="listUserGroup"><FormattedMessage id="usergroup.contactlist" /></label>
                            <FormSelect
                                id="listUserGroup"
                                innerRef={this.c03UserGroup}
                                placeholder="Select an Option"
                                onChange={e=>this.handleChangeUserGroup(e)}
                            >
                                <option value="SelectOptionRole">{placeholderX1}</option>
                                {jsonContactListX.map((todo, i) => {
                                   let sCode = todo.code
                                   if (todo.userid !== undefined && todo.userid !== null && todo.userid !== ''){
                                      return (
                                        <option style={{color:'darkblue'}} key={i} value={todo.userid}>{todo.userid} - {todo.name}</option>
                                      )
                                   }
                                })}
                            </FormSelect>
                          </Col>
                        :
                          null
                        }

                        <Col md="3">
                          <label htmlFor="roleUserGroup"><FormattedMessage id="usergroup.role" /></label>
                          <FormSelect
                              id="roleUserGroup"
                              innerRef={this.c02UserGroup}
                              placeholder="Select an Option"
                              onChange={e=>this.handleChangeRole(e, "Role")}
                          >
                              <option value="SelectOptionRole">{placeholderX1}</option>
                              {jsonRole.map((todo, i) => {
                                let sCode = todo.code
                                if (todo.isActive === undefined){todo.isActive = true}
                                if (todo.description !== "Creator" && todo.isActive){
                                 return (
                                   <option style={{color:'darkblue'}} key={i} value={`${todo.code} - ${todo.description}`}>{todo.description}</option>
                              )}})}
                          </FormSelect>
                        </Col>
                        <Col md="1">
                          <div>
                            <label htmlFor="roleUserGroup">&nbsp;</label>
                            <br></br>
                            <Button pill theme="success" onClick={this.dataUser}>&nbsp;&nbsp;<FormattedMessage id="usergroup.add" />&nbsp;&nbsp;</Button>
                          </div>
                        </Col>
                      </Row>
                    : null }
                    {displayActiveUserGroup ?
                      <>
                        <Row>&nbsp;</Row>
                        <Row>
                          <Col md="12">
                            <Card theme="light" style={{ color: "red" }}>
                                <Table size="sm" style={{fontSize:14}} className="text-center" borderless responsive >
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div className="fa fa-info-circle mx-2">&nbsp;&nbsp;<FormattedMessage id="usergroup.active" /></div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <Button outline pill theme="warning" size="sm" className="mb-2" onClick={this.activeParticipant}>
                                          <i className="material-icons mr-1">replay</i> <FormattedMessage id="usergroup.buttonactive" />
                                      </Button>
                                    </tr>
                                  </tbody>
                                </Table>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    : null }
                    {displayActiveUserGroupMessage ?
                      <Container fluid className="px-0">
                          <Card theme="warning" >
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    &nbsp;&nbsp;<div className="fa fa-info"></div>
                                  </td>
                                  <td>
                                     &nbsp;&nbsp;<FormattedMessage id="document.published1" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Card>
                      </Container>
                    : null }

                    <Row>&nbsp;</Row>
                    <Row>
                      <Col md="12">
                        <label htmlFor="listUserGroup"><FormattedMessage id="usergroup.group" /></label>
                        {this.state.displayUserGroup ?
                               <Table size="sm" className="text-center" responsive hover striped>
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th style={{fontSize:16}}><FormattedMessage id="usergroup.avatar" /></th>
                                      <th style={{fontSize:16}} style={{fontSize:16}}><FormattedMessage id="usergroup.userid" /></th>
                                      <th style={{fontSize:16}}><FormattedMessage id="usergroup.name" /></th>
                                      <th style={{fontSize:16}}><FormattedMessage id="usergroup.role" /></th>
                                      <th style={{fontSize:16}}><FormattedMessage id="usergroup.email" /></th>
                                      <th style={{fontSize:16}}><FormattedMessage id="usergroup.invite" /></th>
                                      <th style={{fontSize:16}}><FormattedMessage id="usergroup.send" /></th>
                                      <th style={{fontSize:16}}><FormattedMessage id="usergroup.delete" /></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {jsonUserGroup.map((todo, i) => {
                                      imageInvite = "images/inviteRed.svg"
                                      if (todo.publicKey === '' || todo.publicKey === undefined){
                                         imageSend = "images/sendContractRed.png"
                                      }else{
                                         imageSend = "images/sendContractGreen.png"
                                      }
                                      if (todo.statusInvite===true){
                                        imageInvite = "images/inviteGreen.svg"
                                      }
                                      const palabra = todo.id
                                      const ArrayIdValue = palabra.split('.blockstack')
                                      avatar = ''
                                      if (todo.avatar === undefined || todo.avatar === null || todo.avatar === ''){
                                        if (ArrayIdValue.length===2){
                                           avatar = `${this.state.url}${todo.identityAddress}//avatar-0`
                                        }else{
                                           avatar = `${this.state.url}${todo.identityAddress}/0//avatar-0`
                                        }
                                      }else{
                                        avatar = Base64.decode(todo.avatar)
                                      }
                                      let displayDelete = true
                                      correlativo = i
                                      if (todo.role === "Creator" || todo.signature !== ""){
                                        displayDelete = false
                                      }
                                      if (todo.name === undefined || todo.name === null || todo.name === 'undefined' ){
                                        todo.name = ''

                                        jsonContactListX.map((todo2, i2) => {
                                          if (todo.email = todo2.email){
                                            todo.name = todo2.name
                                          }
                                        })
                                      }
                                      if (todo.id === this.state.username && (todo.email === null || todo.email === '' || todo.amail === undefined)){
                                        todo.email = this.props.myUserConfig.emailNotifications
                                      }
                                      let setColorInfo = false
                                      let withAvatar = "30"
                                      if (this.state.setItemInfo === i) {
                                        setColorInfo = true
                                        withAvatar = "45"
                                        if (todo.statusInvite===false){
                                          imageInvite = "images/inviteOrange.svg"
                                        }

                                      }
                                      return (
                                          <tr key={i} onMouseEnter={()=>this.onMouseEnterHandler(i)} onMouseLeave={()=>this.onMouseLeaveHandler(i)} style={ this.props.colorDark ? { color:'white'} : {color:'black'}}>
                                              <td>{++correlativo}</td>
                                              <td>
                                                 <strong style={{cursor: 'pointer'}}  onClick={e=>this.displayProfile(e,todo.id,avatar2,todo.name, todo.email)}>
                                                   <div className="mb-3 mx-auto">
                                                      <ReactImageFallback
                                                           src={avatar}
                                                           fallbackImage={avatar2}
                                                           initialImage={avatar2}
                                                           alt={" "}
                                                           className="rounded-circle"
                                                           width={withAvatar}
                                                           title="View Profile"
                                                      />
                                                   </div>
                                                  </strong>
                                              </td>
                                              <td>{todo.id}</td>
                                              <td>{todo.name}</td>
                                              <td>{todo.role}</td>
                                              <td>{todo.email}</td>
                                              {displayDelete ?
                                                <React.Fragment>
                                                  {displayContacX ?
                                                    <React.Fragment>
                                                      { setColorInfo ?
                                                        <td><strong style={{cursor: 'pointer'}}  onClick={e=>this.clickInvite(e,todo.id,todo.name,todo.role,todo.email)}><img src={imageInvite} title="Click here to invite the participant" weight="40" height="50" alt="Click here to invite the participant"/></strong></td>
                                                      :
                                                        <td><strong style={{cursor: 'pointer'}}  onClick={e=>this.clickInvite(e,todo.id,todo.name,todo.role,todo.email)}><img src={imageInvite} title="Click here to Reinvite the participant" weight="30" height="40" alt="Click here to Reinvite the participant"/></strong></td>
                                                      }
                                                      <td><strong style={{cursor: 'pointer'}}  onClick={e=>this.clickSendContract(e,todo.id,todo.name,todo.role,todo.email)}><img src={imageSend} weight="40" height="50" alt=""/></strong></td>
                                                      <td><Button pill theme="danger" onClick={e=>this.clickRemove(e, i, todo.signature, todo.role)}><FontAwesomeIcon icon={faTimes} /></Button></td>
                                                    </React.Fragment>
                                                  :
                                                    <React.Fragment>
                                                      <td></td>
                                                      <td></td>
                                                      <td></td>
                                                    </React.Fragment>
                                                  }
                                                </React.Fragment>
                                              :
                                                <React.Fragment>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                </React.Fragment>
                                              }
                                          </tr>
                                    )})}
                                  </tbody>
                                </Table>
                       :
                          <input type="hidden" ref={this.sensorGPSActiveRule} />
                       }
                      </Col>
                    </Row>
                    <Row></Row>
                    <Row>
                    {clickInviteX ?
                      <Container fluid className="main-content-container px-4">
                        <Card>
                        <Row>&nbsp;</Row>
                          <Row>
                            <ContractUserGroupSendEmail emailx={this.state.emailx} idx={this.state.idx} sendInvite={this.sendInvite} />
                          </Row>
                          <Row>&nbsp;</Row>
                         </Card>
                        </Container>

                    :
                        null
                    }
                    </Row>

                  </Form>
                </Col>

              </Row>
              <Row form>
              <Row form>
                 <Button pill theme="secondary" onClick={this.exitProgram}><FormattedMessage id="button.contractlist" /></Button>
              </Row>
              </Row>

              <br></br>
            </Container>
          </div>
        );
    }
}

export default ContractUserGroup;
