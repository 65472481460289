import React, { Component } from 'react';
import { PDFDocument, StandardFonts, rgb, degrees, LineCapStyle, PDFName, PDFString } from 'pdf-lib'
import { Button,Card, Row, Col } from "shards-react";
import { saveAs } from 'file-saver'
import { FormattedMessage } from 'react-intl';
import { Base64 } from 'js-base64';

class MyDocumentUploaded extends Component {

    constructor(props) {
        super(props);
         this.ref = React.createRef();

        this.state = {
          blobUrl: null,
          languageX: 'English',
        }
    }

    UNSAFE_componentWillMount() {
      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English'})
      }else{
        this.setState({ language: languageX })
      }
    }

    componentDidMount() {
       this.createPdf()
    }

    createPdf = async () => {
      let paymentFormX = 'Payment Form'
      let signatoriesX = 'Signatories'
      let signatureX = 'Signature'
      let nameX = 'Name'
      let titleX = "Agreement Document"
      let itemX = "Item"
      let conceptX = "Concept"
      let estimatedDateX = "Estimated Date"
      let percentX = "Percent"
      let amountX = "Amount"
      let checkInX = 'Check in: '
      let digitallysignedX = 'Digitally signed by '
      let withdateX = 'With date '
      let totalsX = 'Totals'
      let registrationX = 'Blockchain Registration'
      let paymentForm1X = 'The total amount of this agreement is'
      let paymentForm2X = 'and will be paid in'
      let paymentForm3X = 'at the exchange rate at the time of payment'

      if (this.state.language === 'English'){ paymentFormX = 'Payment Method'; signatoriesX = 'Signatories'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Agreement Document"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'French'){ paymentFormX = 'Mode de Paiement'; signatoriesX = 'Signataires'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Document d'accord"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Spanish'){ paymentFormX = 'Forma de Pago'; signatoriesX = 'Firmantes'; signatureX = 'Firma'; nameX = 'Nombre'; titleX = "Documento de Acuerdo"; itemX = "Item"; conceptX = "Concepto"; estimatedDateX = "Fecha Estimada"; percentX = "Porcentaje"; amountX = "Monto"; checkInX = "Verificar en"}
      if (this.state.language === 'Portuguese'){ paymentFormX = 'Forma de pagamento'; signatoriesX = 'Signatários'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Documento de Acuerdo"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Swedish'){ paymentFormX = 'Betalningsmetod'; signatoriesX = 'Undertecknare'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Avtalsdokument"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Netherlands'){ paymentFormX = 'Betalingswijze'; signatoriesX = 'Ondertekenaars'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Overeenkomst document"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Russian'){ paymentFormX = 'Способ оплаты'; signatoriesX = 'Подписавшие'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Документ соглашения"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Japanese'){ paymentFormX = '支払方法'; signatoriesX = '署名者'; signatureX = 'Signature'; nameX = 'Name'; titleX = "契約書"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Chinese'){ paymentFormX = '付款方法'; signatoriesX = '簽署人'; signatureX = 'Signature'; nameX = 'Name'; titleX = "协议文件"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'German'){ paymentFormX = 'Zahlungsmethode'; signatoriesX = 'Unterzeichner'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Einverständniserklärung"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}
      if (this.state.language === 'Italian'){ paymentFormX = 'Metodo di pagamento'; signatoriesX = 'Firmatari'; signatureX = 'Signature'; nameX = 'Name'; titleX = "Documento di accordo"; itemX = "Item"; conceptX = "Concept"; estimatedDateX = "Estimated Date"; percentX = "Percent"; amountX = "Amount"; checkInX = "Check in"}

      if (this.state.language === 'English'){ digitallysignedX = 'Digitally signed by '; withdateX = 'With date '; totalsX = 'Totals'; registrationX = 'Blockchain Registration'}
      if (this.state.language === 'French'){ digitallysignedX = 'Signé numériquement par '; withdateX = 'Avec date '; totalsX = 'Totaux'; registrationX = 'Enregistrement de la blockchain'}
      if (this.state.language === 'Spanish'){ digitallysignedX = 'Firmado digitalmente por '; withdateX = 'Con fecha '; totalsX = 'Totales'; registrationX = 'Registro Blockchain'}
      if (this.state.language === 'Portuguese'){ digitallysignedX = 'Assinado digitalmente por '; withdateX = 'Com data '; totalsX = 'Totais'; registrationX = 'Registro de Blockchain'}
      if (this.state.language === 'Swedish'){ digitallysignedX = 'Digitalt signerad av '; withdateX = 'Med datum '; totalsX = 'Summor'; registrationX = 'Blockchain registrering'}
      if (this.state.language === 'Netherlands'){ digitallysignedX = 'Digitaal ondertekend door '; withdateX = 'Met datum '; totalsX = 'Totalen'; registrationX = 'Blockchain-registratie'}
      if (this.state.language === 'Russian'){ digitallysignedX = 'Цифровая подпись '; withdateX = 'С датой '; totalsX = 'Итоги'; registrationX = 'Блокчейн Регистрация'}
      if (this.state.language === 'Japanese'){ digitallysignedX = 'デジタル署名者 '; withdateX = '日付付き '; totalsX = '合計'; registrationX = 'ブロックチェーン登録'}
      if (this.state.language === 'Chinese'){ digitallysignedX = '數字簽名者 '; withdateX = '有日期 '; totalsX = '總計'; registrationX = '區塊鏈註冊'}
      if (this.state.language === 'German'){ digitallysignedX = 'Digital signiert von '; withdateX = 'Mit Datum '; totalsX = 'Summen'; registrationX = 'Blockchain-Registrierung'}
      if (this.state.language === 'Italian'){ digitallysignedX = 'Firmato digitalmente da '; withdateX = 'Con data '; totalsX = 'Totali'; registrationX = 'Registrazione Blockchain'}

      if (this.state.language === 'English'){ paymentForm1X = "The total amount of this agreement is"; paymentForm2X = "and will be paid in"; paymentForm3X = "at the exchange rate at the time of payment"}
      if (this.state.language === 'French'){ paymentForm1X = "Le montant total de cet accord est"; paymentForm2X = "aet sera payé en"; paymentForm3X = "au taux de change au moment du paiement"}
      if (this.state.language === 'Spanish'){ paymentForm1X = "El monto total de este acuerdo es de"; paymentForm2X = "y será pagado en"; paymentForm3X = "al tipo de cambio del momento del pago"}
      if (this.state.language === 'Portuguese'){ paymentForm1X = "O valor total deste contrato é"; paymentForm2X = "e será pago em"; paymentForm3X = "à taxa de câmbio no momento do pagamento"}
      if (this.state.language === 'Swedish'){ paymentForm1X = "Det totala beloppet för detta avtal är"; paymentForm2X = "och kommer att betalas in"; paymentForm3X = "till växelkursen vid betalningstillfällett"}
      if (this.state.language === 'Netherlands'){ paymentForm1X = "Het totale bedrag van deze overeenkomst is"; paymentForm2X = "en wordt uitbetaald"; paymentForm3X = "tegen de wisselkoers op het moment van betaling"}
      if (this.state.language === 'Russian'){ paymentForm1X = "Общая сумма этого договора составляет"; paymentForm2X = "и будет оплачен в"; paymentForm3X = "по курсу на момент оплаты"}
      if (this.state.language === 'Japanese'){ paymentForm1X = "この契約の総額は、"; paymentForm2X = "で支払われます"; paymentForm3X = "支払い時の為替レートで"}
      if (this.state.language === 'Chinese'){ paymentForm1X = "本協議總金額為"; paymentForm2X = "並將支付"; paymentForm3X = "按付款時的匯率"}
      if (this.state.language === 'German'){ paymentForm1X = "Der Gesamtbetrag dieser Vereinbarung beträgt"; paymentForm2X = "und wird einbezahlt"; paymentForm3X = "zum Wechselkurs zum Zeitpunkt der Zahlung"}
      if (this.state.language === 'Italian'){ paymentForm1X = "L'importo totale di questo accordo è"; paymentForm2X = "e verrà pagato"; paymentForm3X = "al tasso di cambio al momento del pagamento"}

      const base64LinkX = Base64.encode(`${this.props.typeContract}>>>${this.props.numberContract}>>>${this.props.userOrigin}>>>${this.props.whatUserRole}>>>${this.props.description}`)
      const linkX = `https://${this.props.usernameX}?pdf:${base64LinkX}`


      let imageFirmadoDigitalmenteX = "https://xck.app/images/digitally_signed_english.png"
      if (this.state.language === 'Spanish'){
        imageFirmadoDigitalmenteX = "https://xck.app/images/firmadodigitalmente.png"
      }

      let displayFormaPagoX = false
      let formapagoX = []
      if (this.props.jsonBlockstackFormPago.length > 0){
         formapagoX = this.props.jsonBlockstackFormPago
         displayFormaPagoX = true
      }
      let jsonBlockstackY = this.props.jsonBlockstackY
      for (var i = jsonBlockstackY.length - 1; i >= 0; i--) {
          jsonBlockstackY[i].avatar = null
          if (jsonBlockstackY[i].role !== 'Creator' && jsonBlockstackY[i].role !== 'Authorizer' && jsonBlockstackY[i].role !== 'Authorizer-Inviter') {
              jsonBlockstackY.splice(i, 1);
          }
      }
      let linkX1 = ''
      let linkX2 = ''
      if (linkX.length > 140){
         linkX1 = linkX.substring(0,139)
         linkX2 = linkX.substring(140)
      }else{
         linkX1 = linkX
      }

      // Create a new PDFDocument

      const pdfDoc = await PDFDocument.load(this.props.filedecodeAttachX, {
         updateMetadata: false,
      });

      // Embed the Helvetica font
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

      // Get the first page of the document
      let pages = pdfDoc.getPages()
      const firstPage = pages[0]

      // Get the width and height of the first page
      const { width, height } = firstPage.getSize()

      // Draw a string of text vertical in the first page
      //firstPage.drawText("~ Created with Crosscheck by Paradigma ~", {
      //  x: 10,
      //  y: height / 2 + 100,
      //  size: 10,
      //  font: helveticaFont,
      //  color: rgb(0.4, 0.5, 0.6),
      //  rotate: degrees(90),
      //})

      const page = pdfDoc.addPage([width, height])

      pdfDoc.setTitle('Uploaded Document PDF');
      pdfDoc.setAuthor(this.props.usernameX);
      pdfDoc.setSubject(this.props.description);
      pdfDoc.setKeywords([this.props.typeContract, this.props.numberContract]);
      pdfDoc.setProducer(this.props.userOrigin);
      pdfDoc.setCreator('Paradigma Crosscheck');
      pdfDoc.setCreationDate(new Date());
      pdfDoc.setModificationDate(new Date());

      // Embed the Times Roman font
      const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

      page.drawText("~ Created with Crosscheck by Paradigma ~", {
        x: 10,
        y: height / 2 + 100,
        size: 10,
        font: helveticaFont,
        color: rgb(0.4, 0.5, 0.6),
        rotate: degrees(90),
      })

      let linea = -50
      let fontSize = 13
      if (displayFormaPagoX) {

         page.drawText(`${paymentFormX}`, { x: 50, y: height - 50, size: fontSize, font: timesRomanFont.bold, color: rgb(0, 0, 0), })

         page.drawText(`${paymentForm1X} ${this.props.totaldocumento} ${this.props.symbolcurrency} ${paymentForm2X} ${this.props.cryptoCurrency}`, { x: 50, y: height - 65, size: 10, font: timesRomanFont.bold, color: rgb(0, 0, 0), })
         page.drawText(`${paymentForm3X}`, { x: 50, y: height - 75, size: 10, font: timesRomanFont.bold, color: rgb(0, 0, 0), })

         fontSize = 11
         page.drawText(`${itemX}`, { x: 50, y: height - 95, size: fontSize, font: timesRomanFont, color: rgb(0, 0, 0), })
         page.drawText(`${conceptX}`, { x: 100, y: height - 95, size: fontSize, font: timesRomanFont, color: rgb(0, 0, 0), })
         page.drawText(`${estimatedDateX}`, { x: 250, y: height - 95, size: fontSize, font: timesRomanFont, color: rgb(0, 0, 0), })
         page.drawText(`${percentX}`, { x: 350, y: height - 95, size: fontSize, font: timesRomanFont, color: rgb(0, 0, 0), })
         page.drawText(`${amountX}`, { x:  430, y: height - 95, size: fontSize, font: timesRomanFont, color: rgb(0, 0, 0), })

         page.drawLine({ start: { x: 50, y: height - 105, }, end: { x: 500, y: height - 105, }, color: rgb(0, 0, 0), thickness: 0.1, lineCap: LineCapStyle.Flat, });

         fontSize = 10
         linea = 120
         let totalpercentX = 0;
         let totalamountX = 0;

         this.props.jsonBlockstackFormPago.map((todo, i) => {
              page.drawText(`${++i}`, { x: 50, y: height - linea, size: fontSize, font: timesRomanFont, color: rgb(0, 0, 0), })
              page.drawText(todo.concept, { x: 100, y: height - linea, size: fontSize, font: timesRomanFont, color: rgb(0, 0, 0), })
              page.drawText(todo.estimateddate, { x: 250, y: height - linea, size: fontSize, font: timesRomanFont, color: rgb(0, 0, 0), })
              page.drawText(`${todo.percent} %`, { x: 350, y: height - linea, size: fontSize, font: timesRomanFont, color: rgb(0, 0, 0), })
              page.drawText(`${todo.amount} ${this.props.symbolcurrency}`, { x: 430, y: height - linea, size: fontSize, font: timesRomanFont, color: rgb(0, 0, 0), })
              page.drawLine({ start: { x: 50, y: height - linea - 8, }, end: { x: 500, y: height - linea - 8, }, color: rgb(0.4, 0.4, 0.4), thickness: 0.1, lineCap: LineCapStyle.Flat, });
              linea = linea + 20
              totalpercentX = totalpercentX + +(Math.round(todo.percent + "e+2")  + "e-2");
              totalamountX =  totalamountX + +(Math.round(todo.amount + "e+2")  + "e-2");
         })
         page.drawText(totalsX, { x: 100, y: height - linea, size: fontSize, font: timesRomanFont.bold, color: rgb(0, 0, 0), })
         page.drawText(`${totalpercentX} %`, { x: 350, y: height - linea, size: fontSize, font: timesRomanFont.bold, color: rgb(0, 0, 0), })
         page.drawText(`${totalamountX} ${this.props.symbolcurrency}`, { x: 430, y: height - linea, size: fontSize, font: timesRomanFont.bold, color: rgb(0, 0, 0), })
       }
       fontSize = 13
       page.drawText(`${signatoriesX}`, { x: 50, y:  height - linea - 50, size: fontSize, font: timesRomanFont.bold, color: rgb(0, 0, 0), })

       //--------------------------------------------------------------------------------------------------------
       // Embed the JPG image bytes and PNG image bytes
       const pngUrl = imageFirmadoDigitalmenteX
       const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())

       //--------------------------------------------------------------------------------------------------------

       fontSize = 11

       jsonBlockstackY.map(async (todo2, i2) => {
          const pngImage = await pdfDoc.embedPng(pngImageBytes)
          const pngDims = pngImage.scale(0.30)
          page.drawImage(pngImage, {
            x: 50,
            y: height - linea - 55,
            width: pngDims.width,
            height: pngDims.height,
            opacity: 0.75,
          })

          if (todo2.signpad !== undefined){
            const pngImage2 = await pdfDoc.embedPng(todo2.signpad)
            const pngDims2 = pngImage2.scale(0.20)
            page.drawImage(pngImage2, {
              x: 140,
              y: height - linea - 55,
              width: pngDims2.width,
              height: pngDims2.height,
              opacity: 0.90,
            })
          }

          page.drawText(`${digitallysignedX} ${todo2.name}`, { x: 50, y: height - linea - 70, size: fontSize, font: timesRomanFont, color: rgb(0, 0, 0), })
          page.drawText(`${withdateX} ${todo2.signaturedatetime}`, { x: 50, y: height - linea - 85, size: fontSize, font: timesRomanFont, color: rgb(0, 0, 0), })
          page.drawText(`${signatureX}: ${todo2.signature}`, { x: 50, y: height - linea - 100, size: fontSize, font: timesRomanFont, color: rgb(0, 0, 0), })
          page.drawText(`did:web: ${todo2.id}`, { x: 50, y: height - linea - 115, size: fontSize, font: timesRomanFont, color: rgb(0, 0, 0), })
          linea = linea + 40
       })
       linea = linea + 50
       page.drawText(`Crosscheck ID: ${this.props.userOrigin}/${this.props.typeContract}/${this.props.numberContract}`, { x: 50, y: height - 180 - linea, size: 8, font: timesRomanFont, color: rgb(0, 0, 0), })

       linea = linea + 25
       page.drawText(`${registrationX}: ${this.props.getTransaction.block_hash}`, { x: 50, y: height - linea - 180, size: 8, font: timesRomanFont, color: rgb(0, 0, 0), })
       page.drawText(`${withdateX}: ${this.props.getTransaction.burn_block_time_iso}`, { x: 50, y: height - linea - 180 - 15, size: 8, font: timesRomanFont, color: rgb(0, 0, 0), })
       page.drawText(`URL: https://explorer.stacks.co/block/${this.props.getTransaction.block_hash}?chain=mainnet`, { x: 50, y: height - 180 - linea - 30, size: 8, font: timesRomanFont, color: rgb(0, 0, 0), })

       page.drawText(`${checkInX}`, { x: width / 2 - 20, y: 42, size: 9, font: timesRomanFont, color: rgb(0.4, 0.4, 0.4), })
       page.drawText(`${linkX1}`, { x: 30, y: 30, size: 7, font: timesRomanFont, color: rgb(0, 0, 0.5), })
       page.drawText(`${linkX2}`, { x: 30, y: 20, size: 7, font: timesRomanFont, color: rgb(0, 0, 0.5), })


       const link = this.createPageLinkAnnotation(page, linkX, width, height);
       page.node.set(PDFName.of('Annots'), pdfDoc.context.obj([link]));


       // Serialize the PDFDocument to bytes (a Uint8Array)
       const pdfBytes = await pdfDoc.save()
       this.renderInIframe(pdfBytes);
    }

    createPageLinkAnnotation = (page: PDFPage, uri: string, width, height) =>
      page.doc.context.register(
        page.doc.context.obj({
          Type: 'Annot',
          Subtype: 'Link',
          Rect: [30, 30, width - 30 , 40],
          //Border: [0, 0, 2],
          C: [0, 0, 0.5],
          A: {
            Type: 'Action',
            S: 'URI',
            URI: PDFString.of(uri),
          },
        }
      ),
    );

    renderInIframe = (pdfBytes) => {
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      if (this.ref.current) {
         this.setState({blobUrl})
      }
    };

    render() {

      return (
        <div>
          <div ref={this.ref}>
            <Card style={ this.props.colorDark ? { backgroundColor:'#0b151d', color: 'orange'} : { backgroundColor:"#F2EACE", color: 'blue'}}  small>
              <React.Fragment>
                <Row form></Row>
                <Row form>
                  <Col md="12" style={{fontSize:18, textAlign:"center"}}>
                      <a href={this.state.blobUrl} rel="noopener" target="_blank"> {'<<<<<<'} <FormattedMessage id="contract.desplegarpdf" /> {'>>>>>>'} </a>
                  </Col>
                </Row>
                <Row form></Row>
              </React.Fragment>
            </Card>
          </div>
        </div>
      )
    }
}
export default MyDocumentUploaded;
