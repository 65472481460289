//React
import React, { Component } from 'react';

//Redux
import { withRouter } from "react-router-dom";

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Form, Card, Container, FormSelect, Button, InputGroupText, InputGroupAddon, FormRadio, FormCheckbox } from "shards-react";
import { Table, Nav, NavItem, NavLink } from 'reactstrap';
import { Dialog,DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';
import classnames from 'classnames';

import TituloPagina2 from "../common/TituloPagina2";

import NewMasterFileDeliverable from "./NewMasterFileDeliverable";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//translate
import { FormattedMessage } from 'react-intl';

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

import ContractLoader from "../contracts/ContractLoader";

import "../contracts/tab.css";

//Axios
import axios from 'axios';

//Configuración
import { MAINNET_STACKS_API_URL,
         TESTNET_STACKS_API_URL } from '../../config.js'

function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Confirmacion2(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Confirmacion3(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('exit')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'exit')} theme="success" autoFocus>
                    <FormattedMessage id="pay.exit" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class MasterFileDeliverable extends Component {

    constructor(props) {
        super(props);

        this.clickRemoveMasterFile = this.clickRemoveMasterFile.bind(this);
        this.clickEditMasterFile = this.clickEditMasterFile.bind(this);
        this.crearMasterFile = this.crearMasterFile.bind(this);
        this.handleChangeOption = this.handleChangeOption.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.goDetailRole = this.goDetailRole.bind(this);
        this.goDetailTag = this.goDetailTag.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          name: '',
          user: {},
          opcionMasterFile: '',
          displayMasterFile: false,
          jsonBlockstack: [],
          jsonBlockstack2: [],
          typeMasterFile: '',

          activeTab: '1',
          opcionRole: '',
          displayRole: false,
          typeRole: '',

          opcionSetting: '',
          displaySetting: false,
          jsonBlockstackY: [],
          jsonBlockstackZ: [],
          typeSetting: '',
          idx: '',
          networkUrlX: '',

          opcionIoTDevice: '',
          displayIoTDevice: false,
          displayIoTDevice2: false,
          typeIoTDevice: '',
          dialogOpen: false,
          dialogOpenInfo: false,
          dialogMessage: '',
          dialogTitle: '',
          language: 'English',
          templatesAllLocal: [],
          code: '',
          description: '',
          colorletter: '',
          hex: '',
          modifying: false,
          jsonRol: {},
          displayOther: false,
          nameOther: '',
          titleOther: '',
          priorityOther: '',
          file: '',
          secondaryName: '',
          colorDark: false,
          displayLoader: false,
        }
    }

    c01MasterFile = React.createRef();
    c01Setting = React.createRef();
    c02Setting = React.createRef();
    c01IoTDevice = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''

      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      let {name} = userSession.loadUserData().profile;
      if (name === '' || name === undefined || name === null){
        name = this.props.myUserConfig.profileName
      }
      this.setState({ name, networkUrlX, stacksNetX })

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      let colorThemeX = 'Blue'
      if (localStorage["colorTheme"]) {
        colorThemeX = localStorage.getItem('colorTheme')
        if (colorThemeX === 'Dark') {
           this.setState({colorDark: true})
        }
      }
      this.setState({code:'', description:'', typeMasterFile:this.props.typeMasterFile})
    }

    componentDidMount() {
      this.setState({displayLoader: false})
      this.setState({code:'', description:'', typeMasterFile:this.props.typeMasterFile})
      if (this.props.username === this.props.userOrigin){
        if (this.props.typeMasterFile === 'role'){
          this.goDetailRole(this.props.username)
        }
        if (this.props.typeMasterFile === 'tag'){
          this.goDetailTag(this.props.username)
        }
      }else{
        if (this.props.typeMasterFile === 'role'){
          this.goDetailRole(this.props.userOrigin)
        }
        if (this.props.typeMasterFile === 'tag'){
          this.goDetailTag(this.props.userOrigin)
        }
      }
      this.setState({displayLoader: true})
    }

    onMouseEnterHandler = (i) =>{
      this.setState({setItemInfo: i})
    }

    onMouseLeaveHandler = (i) => {
    }


    handleChangeOption(optSearch) {
        this.setState({
          jsonBlockstack: [],
          displayMasterFile: false,
          modifying: false,
        });
        if (optSearch==="Role"){
          this.setState({activeTab: '2'});
          this.goDetailRole()
          this.setState({typeMasterFile: "role"})
        }
        if (optSearch==="Tag"){
          this.setState({activeTab: '6'});
          this.goDetailTag()
          this.setState({typeMasterFile: "tag"})
        }
    }

    handleDialog = (type, action) => {
      if (type === 'Error: 1001') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Warning: 2001') {
        if (action === 'confirm') {
          this.setState({ dialogOpenServer: false });
          const infoSetting = localStorage.getItem('infoSetting')
          this.saveSetting(infoSetting,false)
        }
        if (action === 'cancel') {
          this.setState({ dialogOpenServer: false });
        }
      }
      if (type === 'Warning: 2002') {
        if (action === 'confirm') {
          this.setState({ dialogOpenServer: false });
          const path = '/simulator'
          this.setState({activeTab: '7'});
          this.props.history.push(path);
        }
        if (action === 'cancel') {
          this.setState({ dialogOpenServer: false });
          this.setState({ selectedConfiguration: "General" })
          this.setState({activeTab: '1'});
          this.goDetailSetting()
        }
      }

      if (type === 'Confirm: 3001') {
         if (action === 'exit') {
            this.setState({ dialogOpenInfo: false });
         }
      }

    }

    handleChange(e,chk,i,codeX) {
      const newState = {};
      newState[chk] = !this.state[chk];
      this.setState({...this.state, ...newState, isErrorWrite: false})

    }

    handleChangeRole(e,chk,i,codeX) {
      if (codeX === 'Jefe Turno MD' || codeX === 'Supervisor MD' || codeX === 'Asesor HSE MD' ||
          codeX === 'Adm MD' || codeX === 'Gerente MD' || codeX === 'Ing Control Gestion' || codeX === 'Jefe Turno Control Sondaje' ||
          codeX === 'Adm AA' || codeX === 'Superintendente' || codeX === 'Gerente' || codeX === 'Auditoria' || codeX === 'Operador MD') {
          return
      }

      if (chk==='visualize'){
        const newState = {};
        newState[chk] = !this.state[chk];
        this.setState({...this.state, ...newState, isErrorWrite: false})

        const {jsonBlockstack} = this.state
        jsonBlockstack.map((todo2, y) => {
          if (y === i) {
            if (todo2.visualize === true){
              todo2.visualize = false
            }else{
              if (todo2.visualize === false){
                todo2.visualize = true
              }
            }
            const {userSession} = this.state
            const storage = new Storage({ userSession });
            const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
            putFileDB(this.state.username, `role_deliverable.json`, 'Crosscheck_Deliverable', JSON.stringify(jsonBlockstack), JSON.stringify(options))
              .then(() => {
                 //console.log(`Grabación Exitosa (role.json)`)
              })
          }
        })
      }
    }

    goDetailRole(username) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let typeRole2 = 'role'
      this.setState({opcionRole: 'Role',typeRole: 'role',jsonBlockstack: [],displayRole: true})
      localStorage.removeItem('jsonBlockstack20')
      getFileDB(username, `${typeRole2}_deliverable.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             localStorage.setItem('jsonBlockstack20',jsonBlockstack5)
             this.setState({jsonBlockstack: jsonBlockstack6})
           } else {}
         })
        .catch(error => {console.log(error)});
    }

    goDetailTag(username) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let typeRole2 = 'tag'
      this.setState({opcionRole: 'Tag',typeRole: 'tag',jsonBlockstack: [],displayTag: true})
      localStorage.removeItem('jsonBlockstack20')
      getFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_tag_deliverable.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             localStorage.setItem('jsonBlockstack20',jsonBlockstack5)
             this.setState({jsonBlockstack: jsonBlockstack6})
           } else {}
         })
        .catch(error => {});
    }

    clickRemoveMasterFile = (e,optx, codex) => {
        if (this.state.typeMasterFile === 'role'){
            const masterfile = this.state.jsonBlockstack
            masterfile.splice(optx,1);
            this.setState({jsonBlockstack: masterfile})
            const jsonBlockstack1 = JSON.stringify(masterfile)
            const {userSession} = this.state
            const storage = new Storage({ userSession });
            const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
            putFileDB(this.state.username, `${this.state.typeMasterFile}_deliverable.json`, 'Crosscheck_Deliverable', jsonBlockstack1, JSON.stringify(options))
              .then(() => {
                 localStorage.setItem('jsonBlockstack20',jsonBlockstack1)
              })
        }
        if (this.state.typeMasterFile === 'tag'){
            const masterfile = this.state.jsonBlockstack
            masterfile.splice(optx,1);
            this.setState({jsonBlockstack: masterfile})
            const jsonBlockstack1 = JSON.stringify(masterfile)
            const {userSession} = this.state
            const storage = new Storage({ userSession });
            const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
            putFileDB(this.state.username`${this.props.typeContract}_${this.props.numberContract}_${this.state.typeMasterFile}_deliverable.json`, 'Crosscheck_Deliverable', jsonBlockstack1, JSON.stringify(options))
              .then(() => {
                 localStorage.setItem('jsonBlockstack20',jsonBlockstack1)
              })
        }
        this.setState({modifying: false})
    }


    crearMasterFile = (infoMasterFile) => {
      this.setState({displayLoader: false})
      this.setState({code:'', description:'', typeMasterFile:this.props.typeMasterFile})
      if (this.props.typeMasterFile === 'role'){
        this.goDetailRole(this.state.username)
      }
      if (this.props.typeMasterFile === 'tag'){
        this.goDetailTag(this.state.username)
      }
      this.setState({displayLoader: true})
    }

    clickEditMasterFile = (e,optx) => {
        const {jsonBlockstack} = this.state
        const {code} = jsonBlockstack[optx]
        const {description} = jsonBlockstack[optx]
        this.setState({
            code: code,
            description: description,
            modifying: true,
        })
        if (this.state.selectedConfiguration === "Tag"){
          const {colorletter} = jsonBlockstack[optx]
          const {hex} = jsonBlockstack[optx]
          this.setState({colorletter, hex })
        }
        if (this.state.selectedConfiguration === "Role"){
          const {generate} = jsonBlockstack[optx]
          const {modify} = jsonBlockstack[optx]
          const {agregaobservacion} = jsonBlockstack[optx]
          const {signature} = jsonBlockstack[optx]
          const {visualize} = jsonBlockstack[optx]
          const {close} = jsonBlockstack[optx]

          this.setState({
            jsonRol: {"generate":generate,"modify":modify,"agregaobservacion":agregaobservacion,"signature":signature,"visualize":visualize,"close":close}
          })
        }
    }

    render() {
        const {jsonBlockstack} = this.state
        const {jsonBlockstackY} = this.state
        const {jsonBlockstackZ} = this.state
        const {templatesAllLocal} = this.state
        const {idx} = this.state
        const fileX2 = this.state.file
        const idxX2 = this.state.idx
        const secondaryNameX2 = this.state.secondaryName
        let selectedRole = false
        let selectedTag = false
        let titleX = ''
        const subtitleX = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`

        let placeholderX1 = "Select an Option"
        if (this.state.language === 'English'){
          placeholderX1 = "Select an Option"
          if (this.props.typeMasterFile === 'role'){titleX = 'Roles and Flows'}
          if (this.props.typeMasterFile === 'tag'){titleX = 'Deliverable Labels'}
        }
        if (this.state.language === 'French'){
          placeholderX1 = "Sélectionner une Option"
          if (this.props.typeMasterFile === 'role'){titleX = 'Roles and Flows'}
          if (this.props.typeMasterFile === 'tag'){titleX = 'Deliverable Labels'}
        }
        if (this.state.language === 'Spanish'){
          placeholderX1 = "Seleccione una Opción"
          if (this.props.typeMasterFile === 'role'){titleX = 'Roles y Flujos'}
          if (this.props.typeMasterFile === 'tag'){titleX = 'Etiquetas para los Entregables'}
        }
        if (this.state.language === 'Portuguese'){
          placeholderX1 = "Selecione uma Opção"
          if (this.props.typeMasterFile === 'role'){titleX = 'Roles and Flows'}
          if (this.props.typeMasterFile === 'tag'){titleX = 'Deliverable Labels'}
        }
        if (this.state.language === 'Swedish'){
          placeholderX1 = "Välj ett Alternativ"
          if (this.props.typeMasterFile === 'role'){titleX = 'Roles and Flows'}
          if (this.props.typeMasterFile === 'tag'){titleX = 'Deliverable Labels'}
        }
        if (this.state.language === 'Netherlands'){
          placeholderX1 = "Kies een Optie"
          if (this.props.typeMasterFile === 'role'){titleX = 'Roles and Flows'}
          if (this.props.typeMasterFile === 'tag'){titleX = 'Deliverable Labels'}
        }
        if (this.state.language === 'Russian'){
          placeholderX1 = "Выберите опцию"
          if (this.props.typeMasterFile === 'role'){titleX = 'Roles and Flows'}
          if (this.props.typeMasterFile === 'tag'){titleX = 'Deliverable Labels'}
        }
        if (this.state.language === 'Japanese'){
          placeholderX1 = "オプションを選択"
          if (this.props.typeMasterFile === 'role'){titleX = 'Roles and Flows'}
          if (this.props.typeMasterFile === 'tag'){titleX = 'Deliverable Labels'}
        }
        if (this.state.language === 'Chinese'){
          placeholderX1 = "选择一个选项"
          if (this.props.typeMasterFile === 'role'){titleX = 'Roles and Flows'}
          if (this.props.typeMasterFile === 'tag'){titleX = 'Deliverable Labels'}
        }
        if (this.state.language === 'German'){
          placeholderX1 = "Wähle eine Option"
        }
        if (this.state.language === 'Italian'){
          placeholderX1 = "Seleziona un'opzione"
          if (this.props.typeMasterFile === 'role'){titleX = 'Roles and Flows'}
          if (this.props.typeMasterFile === 'tag'){titleX = 'Deliverable Labels'}
        }

        if (this.props.typeMasterFile === 'role'){
           selectedRole = true
        }
        if (this.props.typeMasterFile === 'tag'){
           selectedTag = true
        }

        let bloquedX = false
        if (this.props.whatUserRole !== 'Creator'){
          bloquedX = true
        }

        return (
          <>
            <Container fluid className="main-content-container px-4" style={ this.state.colorDark ? { backgroundColor:'#0b151d'} : {}} >
              <Row noGutters className="page-header py-4">
                <TituloPagina2
                  sm="4"
                  title={titleX}
                  subtitle={subtitleX}
                  className="text-sm-left"
                />
              </Row>
              <Confirmacion
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpen}
                  dialogTitle={this.state.dialogTitle}
              />
              <Confirmacion2
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenServer}
                  dialogTitle={this.state.dialogTitle}
              />
              <Confirmacion3
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenInfo}
                  dialogTitle={this.state.dialogTitle}
              />

              {selectedRole ?
                  <Row>
                    <Col>
                      <Form style={{fontSize:13}}>
                        {!bloquedX ?
                          <Row form>
                            <Col md="12">
                              <label>&nbsp;</label>
                              <NewMasterFileDeliverable typeContract={this.props.typeContract} numberContract={this.props.numberContract} typeMasterFile={this.state.typeRole} crearMasterFile={this.crearMasterFile} opcionMasterFile={this.state.opcionRole} jsonBlockstack={this.state.jsonBlockstack} code={this.state.code} colorletter={this.state.colorletter} hex={this.state.hex} description={this.state.description} jsonRol={this.state.jsonRol} modifying={this.state.modifying} colorDark={this.state.colorDark} />
                            </Col>
                          </Row>
                        : null }
                        <Row>
                          <Col md="12">
                            <label>&nbsp;</label>
                            {this.state.displayRole ?
                                   <Table size="sm" className="text-center" responsive hover striped >
                                      <thead>
                                        <tr>
                                          <th style={{ width: "30px", fontSize:16 }}><FormattedMessage id="configuration.code" /></th>
                                          <th style={{ width: "160px", fontSize:16 }}><FormattedMessage id="configuration.description" /></th>
                                          <th style={{ width: "120px", fontSize:16 }}><FormattedMessage id="tab.level" /></th>
                                          <th style={{ width: "120px", fontSize:16 }}><FormattedMessage id="tab.ingresa" /></th>
                                          <th style={{ width: "120px", fontSize:16 }}><FormattedMessage id="tab.revisa" /></th>
                                          <th style={{ width: "120px", fontSize:16 }}><FormattedMessage id="tab.agregaobservacion" /></th>
                                          <th style={{ width: "120px", fontSize:16 }}><FormattedMessage id="tab.autoriza" /></th>
                                          <th style={{ width: "120px", fontSize:16 }}><FormattedMessage id="tab.visualiza" /></th>
                                          <th style={{ width: "120px", fontSize:16 }}><FormattedMessage id="tab.cierra" /></th>
                                          <th style={{ width: "20px", fontSize:16 }}><FormattedMessage id="configuration.edit" /></th>
                                          <th style={{ width: "20px", fontSize:16 }}><FormattedMessage id="configuration.delete" /></th>
                                         </tr>
                                      </thead>
                                      <tbody>
                                        {jsonBlockstack.map((todo, i) => {
                                          let buttons = false
                                          if (!bloquedX) {
                                            let disables = false
                                            if (todo.code === 'Jefe Turno MD' || todo.code === 'Supervisor MD' || todo.code === 'Asesor HSE MD' ||
                                               todo.code === 'Adm MD' || todo.code === 'Gerente MD' || todo.code === 'Ing Control Gestion' || todo.code === 'Jefe Turno Control Sondaje' ||
                                               todo.code === 'Adm AA' || todo.code === 'Superintendente' || todo.code === 'Gerente' || todo.code === 'Auditoria'  || todo.code === 'Operador MD') {

                                               buttons = false
                                               disables = true
                                            }else{
                                               buttons = true
                                            }
                                          }
                                          if (todo.generate === undefined){ todo.generate = true }
                                          if (todo.modify === undefined){ todo.modify = true }
                                          if (todo.agregaobservacion === undefined){ todo.agregaobservacion = true }
                                          if (todo.signature === undefined){ todo.signature = true }
                                          if (todo.visualize === undefined){ todo.visualize = true }
                                          if (todo.close === undefined){ todo.close = true }
                                          let setColorInfo = false
                                          if (this.state.setItemInfo === i) {
                                            setColorInfo = true
                                          }

                                          return (
                                              <tr key={i} onMouseEnter={()=>this.onMouseEnterHandler(i)} onMouseLeave={()=>this.onMouseLeaveHandler(i)} style={this.props.colorDark ? {color: 'white'} : {}}>
                                               {this.state.displayLoader ?
                                                 <>
                                                  <td style={{textAlign: 'left'}}>{todo.code}</td>
                                                  <td style={{textAlign: 'left'}}>{todo.description}</td>
                                                  <td>{todo.level}</td>
                                                  <td>
                                                      <FormCheckbox
                                                          inline
                                                          checked={todo.generate}
                                                          onChange={e=>this.handleChangeRole(e, "generate", i, todo.code)}
                                                          disabled={false}
                                                      >
                                                      </FormCheckbox>
                                                  </td>
                                                  <td>
                                                      <FormCheckbox
                                                          inline
                                                          checked={todo.modify}
                                                          onChange={e=>this.handleChangeRole(e, "modify", i, todo.code)}
                                                          disabled={false}
                                                      >
                                                      </FormCheckbox>
                                                  </td>
                                                  <td>
                                                      <FormCheckbox
                                                          inline
                                                          checked={todo.agregaobservacion}
                                                          onChange={e=>this.handleChangeRole(e, "agregaobservacion", i, todo.code)}
                                                          disabled={false}
                                                      >
                                                      </FormCheckbox>
                                                  </td>
                                                  <td>
                                                      <FormCheckbox
                                                          inline
                                                          checked={todo.signature}
                                                          onChange={e=>this.handleChangeRole(e, "signature", i, todo.code)}
                                                          disabled={false}
                                                      >
                                                      </FormCheckbox>
                                                  </td>
                                                  <td>
                                                      <FormCheckbox
                                                          inline
                                                          checked={todo.visualize}
                                                          onChange={e=>this.handleChangeRole(e, "visualize", i, todo.code)}
                                                          disabled={false}
                                                      >
                                                      </FormCheckbox>
                                                  </td>
                                                  <td>
                                                      <FormCheckbox
                                                          inline
                                                          checked={todo.close}
                                                          onChange={e=>this.handleChangeRole(e, "close", i, todo.code)}
                                                          disabled={false}
                                                      >
                                                      </FormCheckbox>
                                                  </td>
                                                  {buttons ?
                                                    <React.Fragment>
                                                      <td><Button pill theme="success" onClick={e=>this.clickEditMasterFile(e, i)}><FontAwesomeIcon icon={faEdit} /></Button></td>
                                                      <td><Button pill theme="danger" onClick={e=>this.clickRemoveMasterFile(e, i, todo.code)}><FontAwesomeIcon icon={faTimes} /></Button></td>
                                                    </React.Fragment>
                                                  :
                                                    <React.Fragment>
                                                      <td></td>
                                                      <td></td>
                                                    </React.Fragment>
                                                  }
                                                 </>
                                                :
                                                 <>
                                                  <td><ContractLoader colorDark={this.props.colorDark}/></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                 </>
                                                }
                                              </tr>
                                           )})}
                                      </tbody>
                                    </Table>
                           : null }
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
              : null }

              {selectedTag ?
                  <Row>
                    <Col>
                      <Form style={{fontSize:13}}>
                        {!bloquedX ?
                          <Row form>
                            <Col md="12">
                              <label>&nbsp;</label>
                              <NewMasterFileDeliverable typeContract={this.props.typeContract} numberContract={this.props.numberContract} typeMasterFile={this.state.typeRole} crearMasterFile={this.crearMasterFile} opcionMasterFile={this.state.opcionRole} jsonBlockstack={this.state.jsonBlockstack} code={this.state.code} colorletter={this.state.colorletter} hex={this.state.hex} description={this.state.description} color={this.state.color} jsonRol={this.state.jsonRol} modifying={this.state.modifying} colorDark={this.state.colorDark} />
                            </Col>
                          </Row>
                        : null }
                        <Row>
                          <Col md="12">
                            <label>&nbsp;</label>
                            {this.state.displayTag ?
                                   <Table size="sm" className="text-center" responsive hover striped>
                                      <thead>
                                        <tr>
                                          <th style={{ width: "30px", fontSize:16 }}><FormattedMessage id="configuration.description" /></th>
                                          <th style={{ width: "160px", fontSize:16 }}><FormattedMessage id="configuration.color" /></th>
                                          <th style={{ width: "160px", fontSize:16 }}>Hex</th>
                                          <th style={{ width: "20px", fontSize:16 }}><FormattedMessage id="configuration.edit" /></th>
                                          <th style={{ width: "20px", fontSize:16 }}><FormattedMessage id="configuration.delete" /></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {jsonBlockstack.map((todo, i) => {
                                          if (todo.colorletter === undefined){todo.colorletter = 'blank'}
                                          return (
                                              <tr key={i} style={this.props.colorDark ? {color: 'white'} : {}}>
                                               {this.state.displayLoader ?
                                                 <>
                                                  <td>{todo.code}</td>
                                                  <td>{todo.description}</td>
                                                  <td><Card small style={{ backgroundColor: todo.hex, color: todo.colorletter }}>{todo.code}</Card></td>
                                                  {!bloquedX ?
                                                    <>
                                                      <td><Button pill theme="success" onClick={e=>this.clickEditMasterFile(e, i)}><FontAwesomeIcon icon={faEdit} /></Button></td>
                                                      <td><Button pill theme="danger" onClick={e=>this.clickRemoveMasterFile(e, i, todo.code)}><FontAwesomeIcon icon={faTimes} /></Button></td>
                                                    </>
                                                  :
                                                    <>
                                                      <td></td>
                                                      <td></td>
                                                    </>
                                                  }
                                                 </>
                                                :
                                                 <>
                                                  <td><ContractLoader colorDark={this.props.colorDark}/></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                 </>
                                                }
                                              </tr>
                                           )})}
                                      </tbody>
                                    </Table>
                           : null }
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
              : null }

              <br></br>
            </Container>
          </>
        );
    }
}

export default withRouter(MasterFileDeliverable);
