//React
import React, { Component } from 'react';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Card, CardHeader, Container, FormSelect, Button, FormInput,
        InputGroupAddon, InputGroupText, InputGroup, ListGroup, ListGroupItem,
        FormTextarea} from "shards-react";

import { Table } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";
import { Divider, Typography, TextField, Grid, Dialog,
         DialogContent, DialogActions, DialogTitle, DialogContentText,
         Button as ButtonMaterialUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';

//Number Format
import NumberFormat from 'react-number-format';

//Tittle
import TituloPagina2 from "../common/TituloPagina2";

import ContractLoader from "../contracts/ContractLoader";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { makeRandomPrivKey } from '@stacks/transactions';
import { Storage } from '@stacks/storage'

//Axios
import axios from 'axios';

//translate
import { FormattedMessage } from 'react-intl';

//Configuración
import {token} from '../../config.js'

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'


//Configuración
import { SMART_CONTRACT_ADDRESS_MAINNET_STX,
         SMART_CONTRACT_NAME_MAINNET_STX,
         SMART_CONTRACT_ADDRESS_TESTNET_STX,
         SMART_CONTRACT_NAME_TESTNET_STX,
         MAINNET_STACKS_API_URL,
         TESTNET_STACKS_API_URL } from '../../config.js'

//
import {CopyToClipboard} from 'react-copy-to-clipboard';

//base64
import { Base64 } from 'js-base64';


function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionWarning(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'accept')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionAccept(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('accept')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'accept')} theme="warning" autoFocus>
                   <FormattedMessage id="alert.accept" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Confirmacion2(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Confirmacion3(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'continue')} theme="success">
                    <FormattedMessage id="alert.continue" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionViewProfile(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                  {props.dialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-center">
                      <Table size="sm" className="text-center" responsive borderless>
                          <tbody>
                            <tr>
                              <td>
                                <div className="mb-3 mx-auto">
                                    <ReactImageFallback
                                         src={props.avatar}
                                         fallbackImage={props.avatar2}
                                         initialImage={props.avatar2}
                                         alt={" "}
                                         className="rounded-circle"
                                         width="45"
                                    />
                                </div>
                              </td>
                            </tr>
                            <tr><td style={{fontSize:18, color: 'darkred'}}><strong>{props.person}</strong></td></tr>
                            <tr><td style={{fontSize:13, color: 'grey'}}><span className="text-muted d-block mb-2">{props.username}</span></td></tr>
                         </tbody>
                      </Table>
                    </CardHeader>
                    <ListGroup flush>
                      <ListGroupItem className="p-4 text-center">
                        <Row>
                          <Table size="sm" className="text-center" responsive borderless  style={{fontSize:11}}>
                              <tbody>
                                <tr>
                                  <td style={{ width: "90%" }}>
                                      <FormTextarea
                                        id="feMessage"
                                        rows="2"
                                        innerRef={props.aboutMe}
                                        style={{backgroundColor:"#F4F4F4"}}
                                        placeholder={props.aboutMe}
                                        disabled={true}
                                      />
                                  </td>
                                </tr>
                             </tbody>
                          </Table>
                        </Row>
                        <Row>
                          <Table size="sm" responsive borderless>
                              <tbody>
                                <tr>
                                  <td style={{ width: "17%" }}><a href={props.facebook} target="_blank" rel="noopener noreferrer"><img src="images/profile_facebook.png" weight="40" height="40" alt=""/></a></td>
                                  <td style={{ width: "17%" }}><a href={props.twitter} target="_blank" rel="noopener noreferrer"><img src="images/profile_twitter.png" weight="40" height="40" alt=""/></a></td>
                                  <td style={{ width: "17%" }}><a href={props.youtube} target="_blank" rel="noopener noreferrer"><img src="images/profile_youtube.png" weight="40" height="40" alt=""/></a></td>
                                  <td style={{ width: "17%" }}><a href={props.instagram} target="_blank" rel="noopener noreferrer"><img src="images/profile_instagram.png" weight="40" height="40" alt=""/></a></td>
                                  <td style={{ width: "17%" }}><a href={props.linkedin} target="_blank" rel="noopener noreferrer"><img src="images/profile_linkedin.png" weight="40" height="40" alt=""/></a></td>
                                  <td style={{ width: "15%" }}><a href={props.pinterest} target="_blank" rel="noopener noreferrer"><img src="images/profile_pinterest.png" weight="40" height="40" alt=""/></a></td>
                                </tr>
                              </tbody>
                          </Table>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>

                    <ListGroup flush>
                      <ListGroupItem className="p-4 text-center">
                        <Row>
                          <Table size="sm" className="text-center" responsive borderless style={{fontSize:13}}>
                              <tbody>
                                {props.bStxScope ?
                                  <>
                                    <tr><td style={{fontSize:11, color: 'grey'}}>STX Address</td></tr>
                                    <tr><td style={{fontSize:13, color: 'darkblue'}}><strong>{props.stxAddress}</strong></td></tr>
                                  </>
                                :
                                   null
                                }
                                {props.bEmailScope ?
                                  <>
                                    <tr><td style={{fontSize:11, color: 'grey'}}>eMail</td></tr>
                                    <tr><td style={{fontSize:13, color: 'darkblue'}}><strong>{props.email}</strong></td></tr>
                                  </>
                                :
                                   null
                                }
                                {props.bSmsScope ?
                                   <>
                                     <tr><td style={{fontSize:11, color: 'grey'}}>Phone</td></tr>
                                     <tr><td style={{fontSize:13, color: 'darkblue'}}><strong>{`${props.smsPrefix}${props.phone}`}</strong></td></tr>
                                   </>
                                :
                                   null
                                }
                                <tr><td style={{fontSize:13}}>{props.espacio.repeat(100)}</td></tr>
                             </tbody>
                          </Table>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>


                  </Card>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'continue')} theme="success">
                    <FormattedMessage id="alert.continue" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class AuthorizersDeliverable extends Component {

    constructor(props) {
        super(props);

        this.clickRemove = this.clickRemove.bind(this);
        this.clickSendContract = this.clickSendContract.bind(this);
        this.clickEdit = this.clickEdit.bind(this);
        this.goUserGroup = this.goUserGroup.bind(this);
        this.addUserGroup = this.addUserGroup.bind(this);
        this.exitProgram = this.exitProgram.bind(this);
        this.handleChangeUserGroup = this.handleChangeUserGroup.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.dataUser = this.dataUser.bind(this);
        this.existeUserConfirm = this.existeUserConfirm.bind(this);
        this.existeRoleConfirm = this.existeRoleConfirm.bind(this);
        this.userIdConfirm = this.userIdConfirm.bind(this);
        this.userAppConfirm = this.userAppConfirm.bind(this);
        this.faltaDataConfirm = this.faltaDataConfirm.bind(this);
        this.userUndefinedConfirm = this.userUndefinedConfirm.bind(this);
        this.userIdUsernameConfirm = this.userIdUsernameConfirm.bind(this);
        this.advancedSearch = this.advancedSearch.bind(this);
        this.warningConfirmUserGroup = this.warningConfirmUserGroup.bind(this);
        this.copyText = this.copyText.bind(this);
        this.copyToClipBoard = this.copyToClipBoard.bind(this);
        this.idProvisoryConfirm = this.idProvisoryConfirm.bind(this);
        this.getDeliverableForms = this.getDeliverableForms.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          name: '',
          user: {},
          opcionUserGroup: '',
          displayUserGroup: false,
          displayrole: false,
          jsonUserGroup: [],
          jsonRole: [],
          jsonBlockstack2: [],
          jsonFormsDeliverableList: [],
          typeUserGroup: '',
          url: 'https://gaia.blockstack.org/hub/',
          url2: 'https://api.hiro.so/v1/names/',
          url3: 'https://api.hiro.so/v1/names/',
          identityAddress: undefined,
          decentralizedID: undefined,
          owneraddress: '',
          displayInvite: false,
          idx: '',
          namex: '',
          rolex: '',
          emailx: '',
          dialogOpen: false,
          dialogOpenPrint: false,
          dialogOpenWarning: false,
          dialogOpenContinue: false,
          dialogOpenAccept: false,
          dialogOpenActiveUserGroup: false,
          dialogOpenViewProfile: false,
          dialogOpenSendLink: false,
          dialogMessage: '',
          dialogTitle: '',
          jsonUserGroupRemote: [],
          languaje: 'English',
          advancedSearch: false,
          jsonContactList: [],
          displayActiveUserGroup: false,
          displayActiveUserGroupImpide: null,
          displaySendLink: null,
          goActiveParticipant: false,
          value: '',
          copied: false,
          porpagar: 0,
          balanceCta: 0,
          resultTransfer: '',
          stacksAddress: '',
          privateKey: '',
          contractAddressX: '',
          addressKeyX: '',
          networkUrlX: '',
          contractNameX: '',
          contractAddressX2: '',
          serverStacksX: '',
          usernameX: '',
          nameX: '',
          avatarX: '',
          avatar3X: '',
          avatar2X: '',
          emailRX: '',
          setItemInfo: null,
          setSendInfo: false,
          name2X: '',
          user2X: '',
          aboutmeX: '',
          facebookX: '',
          twitterX: '',
          youtubeX: '',
          instagramX: '',
          linkedinX: '',
          pinterestX: '',
          emailX: '',
          email2X: '',
          stxAddress2X: '',
          phoneX: '',
          smsScope: '',
          stxScope: '',
          emailScope: '',
          smsPrefix: '',
          stacksNetX: '',
          formsDeliverable2X: false,
          formsDeliverable3X: '',
          formsDeliverable4X: '',
          displayLoader: true,
        }
    }

    c01UserGroup = React.createRef();
    c02UserGroup = React.createRef();
    c03UserGroup = React.createRef();
    c04UserGroup = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''

      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      let {name} = userSession.loadUserData().profile;
      if (name === '' || name === undefined || name === null){
        name = this.props.myUserConfig.profileName
      }
      this.setState({ name, networkUrlX, stacksNetX })

      const {identityAddress} = userSession.loadUserData(),
            {decentralizedID} = userSession.loadUserData()
      this.setState({ identityAddress, decentralizedID, displayUserGroup: true})

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
      this.setState({displayActiveUserGroupImpide: null})
    }

    componentDidMount() {
      if (this.props.username === this.props.userOrigin){
        Promise.all(
           [this.getDeliverableForms(this.props.username),
            this.goRole(this.props.username),
            this.goUserGroupWorkOrder(this.props.username)]
        ).then(
          (resolve) =>{},
          (reject) =>{}
        )
      }else{
        Promise.all(
          [this.getDeliverableForms(this.props.userOrigin),
           this.goRole(this.props.userOrigin)]
        ).then(
          (resolve) =>{},
          (reject) =>{}
        )
      }
    }

    UNSAFE_componentWillReceiveProps() {
    }

    goUserGroupWorkOrder = (username) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      this.setState({displayUserGroup: true})
      return new Promise((resolve1, reject1) => {
          getFileDB(username,`${this.props.typeContract}_${this.props.numberContract}_usergroup.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.setState({jsonContactList: jsonBlockstack6.sort(function(a, b){
                     const bandA = a.id;
                     const bandB = b.id;
                     let comparison = 0;
                     if (bandA > bandB) {
                       comparison = 1;
                     } else if (bandA < bandB) {
                       comparison = -1;
                     }
                     return comparison})
                 })
                 resolve1()
               } else {
                 resolve1()
               }
             })
              .catch(error => {
                console.log(error)
                resolve1()
              });
      });
    }

    completaNameAndAvatar = async (jsonUserGroup) => {
      const salida = []
      const resultado = await Promise.all(jsonUserGroup.map( async (todo) => {
         let userX = this.state.username
         if (todo.id !== this.state.username){
            userX = todo.id
         }
         return await Promise.all([this.readRemoteNameAvatar(userX)])
           .then(
             (resolve) =>{
                 let emailRX = todo.email
                 if (todo.email === null || todo.email === '' || todo.email === undefined){
                   emailRX = resolve[0].emailRX
                 }
                 salida.push({codeFormsDeliverable:todo.codeFormsDeliverable, active:todo.active, email:emailRX, id:todo.id, identityAddress:todo.identityAddress, publicKey:todo.publicKey, conceptFormsDeliverable:todo.conceptFormsDeliverable, role:todo.role, signature:todo.signature, statusInvite:todo.statusInvite, name:resolve[0].nameX, avatar:resolve[0].avatarX})
             },
             (reject)  =>{
                 salida.push({codeFormsDeliverable:todo.codeFormsDeliverable, active:todo.active, email:todo.email, id:todo.id, identityAddress:todo.identityAddress, publicKey:todo.publicKey, conceptFormsDeliverable:todo.conceptFormsDeliverable, role:todo.role, signature:todo.signature, statusInvite:todo.statusInvite, name:'', avatar:''})
             }
           )
      }))
      this.setState({jsonUserGroup: salida},()=>{})
      this.setState({displayLoader: true})
    }

    onMouseEnterHandler = (i) =>{
      this.setState({setItemInfo: i, setSendInfo: true})
    }

    onMouseLeaveHandler = (i) => {
      this.setState({setSendInfo: false})
    }

    copyText = (e,linkX) => {
      this.setState({value: linkX})
      return(
        <CopyToClipboard
           text={linkX}
           onCopy={() => this.setState({copied: true})}>
        </CopyToClipboard>
      )
    }

    copyToClipBoard = async linkX => {
      try {
        await navigator.clipboard.writeText(linkX);
        this.setState({copied: true})
      } catch (err) {
        this.setState({copied: false})
      }
    };

    readRemoteNameAvatar = (userX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      let options = {}
      if (userX === '') {
         options = { decrypt: false, verify: false }
      }else{
        options = { username: userX, decrypt: false, verify: false }
      }
      return new Promise ((resolve, reject) =>{
        getFileDB(userX, `myUserConfig.json`)
           .then((fileContents) => {
             if (fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               let nameX2 = jsonBlockstack6.profileName
               if (jsonBlockstack6.profileName === '' || jsonBlockstack6.profileName === null || jsonBlockstack6.profileName === undefined){nameX2 = 'Sin Nombre ' + '(' + userX + ')'}
               resolve({nameX: nameX2, avatarX: jsonBlockstack6.profileAvatar, emailRX: jsonBlockstack6.emailNotifications})
             }else{
               reject()
             }
           })
          .catch(error => {
        });
      });
    }

    handleChangeUserGroup = (e) => {
      this.setState({ advancedSearch: false });
      this.c01UserGroup.current.value = this.c03UserGroup.current.value
    }

    handleChangeInput = (e) => {
      e.preventDefault();
    }

    handleChangeFormsDeliverable = (e) => {
      e.preventDefault();
      this.setState({displayLoader: false})
      const palabra = this.c02UserGroup.current.value
      const ArrayIdValue = palabra.split('&&&')
      const conceptX = ArrayIdValue[0]
      const codeX = ArrayIdValue[1]
      this.setState({formsDeliverable2X: true, formsDeliverable3X: codeX, formsDeliverable4X: conceptX})
      Promise.all(
         [this.goUserGroup()]
      ).then(
        (resolve) =>{  this.setState({displayLoader: true})},
        (reject) =>{  this.setState({displayLoader: true})}
      )
    }

    resetSetting = (e) => {
      e.preventDefault();
      this.setState({formsDeliverable2X: false, formsDeliverable3X: '', formsDeliverable4X: ''})
      this.c02UserGroup.current.value = ''
      this.setState({jsonUserGroup:[]},()=>{})
      this.setState({displayLoader: true})
    }

    handleChangeRole = (e,role) => {
      const palabra = this.c04UserGroup.current.value
      const ArrayIdValue = palabra.split(' - ')
      const sRole = ArrayIdValue[0]
    }

    handleDialog = (type, action) => {
      if (type === 'Error: 1001') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1002') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1003') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1006') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1007') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1004') {
          if (action === 'accept') {
            this.setState({ dialogOpenWarning: false });
          }
      }
      if (type === 'Error: 1005') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1008') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Warning: 2001') {
          if (action === 'accept') {
            this.setState({ dialogOpenWarning: false });
          }
      }
      if (type === 'Warning: 2002') {
        if (action === 'cancel') {
          this.setState({ dialogOpen: false });
        }
      }
      if (type === 'Warning: 2003') {
          if (action === 'accept') {
            this.setState({ dialogOpenWarning: false });
          }
      }
      if (type === 'Warning: 2004') {
          if (action === 'accept') {
            this.setState({ dialogOpenWarning: false });
          }
      }
      if (type === 'Warning: 2005') {
          if (action === 'accept') {
            this.setState({ dialogOpenWarning: false });
          }
      }
      if (type === 'Confirm: 3001') {
          if (action === 'accept') {
            this.setState({ dialogOpenAccept: false });
          }
      }
      if (type === 'View Profile') {
        if (action === 'continue') {
          this.setState({ dialogOpenViewProfile: false, });
        }
      }
    }

    actualizarSendLink(){
    }


    userIdConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'User does not have a Stacks ID',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "L'utilisateur n'a pas d'identifiant Stacks",dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'El usuario no tiene un ID de Stacks',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'O usuário não possui um ID do Stacks',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Användaren har inte ett Stacks-ID',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'Gebruiker heeft geen Stacks-ID',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'У пользователя нет Stacks-ID',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: 'ユーザーはStacks-IDを持っていません ',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '用户没有Stacks ID',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Der Benutzer hat keine Stacks-ID',dialogTitle: 'Error: 1005',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: "L'utente non ha un ID Stacks",dialogTitle: 'Error: 1005',})}
    }

    existeUserConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'The user already exists...',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: `L'utilisateur existe déjà...`,dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'El usuario ya existe...',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'O usuário já existe...',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Användaren finns redan...',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'De gebruiker bestaat al...',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Пользователь уже существует---',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: 'ユーザーは既に存在します。',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '该用户已经存在，',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Der Benutzer ist bereits vorhanden...',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: "L'utente esiste già...",dialogTitle: 'Warning: 2002',})}
    }

    existeRoleConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenWarning: true,dialogMessage: 'A user with this role already exists (only one user is allowed for this role, you can delete it and enter the new user)...',dialogTitle: 'Warning: 2005',})}
      if (this.state.language === 'French'){this.setState({dialogOpenWarning: true,dialogMessage: `Un utilisateur avec ce rôle existe déjà (un seul utilisateur est autorisé pour ce rôle, vous pouvez le supprimer et saisir le nouvel utilisateur)...`,dialogTitle: 'Warning: 2005',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenWarning: true,dialogMessage: 'Un usuario con este rol ya existe (solo es perminido un usuario para este rol, puede eliminarlo e ingresar el nuevo usuario)...',dialogTitle: 'Warning: 2005',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenWarning: true,dialogMessage: 'Já existe um usuário com esta função (apenas um usuário é permitido para esta função, você pode excluí-lo e inserir o novo usuário)...',dialogTitle: 'Warning: 2005',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenWarning: true,dialogMessage: 'En användare med denna roll finns redan (endast en användare är tillåten för denna roll, du kan ta bort den och ange den nya användaren)...',dialogTitle: 'Warning: 2005',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenWarning: true,dialogMessage: 'Er bestaat al een gebruiker met deze rol (er is slechts één gebruiker toegestaan ​​voor deze rol, u kunt deze verwijderen en de nieuwe gebruiker invoeren)...',dialogTitle: 'Warning: 2005',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenWarning: true,dialogMessage: 'Пользователь с этой ролью уже существует (для этой роли разрешен только один пользователь, его можно удалить и ввести нового пользователя)...',dialogTitle: 'Warning: 2005',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenWarning: true,dialogMessage: 'この役割を持つユーザーは既に存在します (この役割には 1 人のユーザーのみが許可されています。削除して新しいユーザーを入力できます)...',dialogTitle: 'Warning: 2005',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenWarning: true,dialogMessage: '已經存在具有該角色的用戶（該角色只允許一個用戶，您可以將其刪除並輸入新用戶）...',dialogTitle: 'Warning: 2005',})}
      if (this.state.language === 'German'){this.setState({dialogOpenWarning: true,dialogMessage: 'Ein Benutzer mit dieser Rolle existiert bereits (für diese Rolle ist nur ein Benutzer erlaubt, Sie können ihn löschen und den neuen Benutzer eingeben)...',dialogTitle: 'Warning: 2005',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenWarning: true,dialogMessage: "Esiste già un utente con questo ruolo (è consentito un solo utente per questo ruolo, è possibile eliminarlo e inserire il nuovo utente)...",dialogTitle: 'Warning: 2005',})}
    }

    userAppConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenWarning: true,dialogMessage: 'User not exist in Dapp CrossCheck',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'French'){this.setState({dialogOpenWarning: true,dialogMessage: "L'utilisateur n'existe pas dans Dapp CrossCheck",dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenWarning: true,dialogMessage: 'El usuario no existe en Dapp CrossCheck',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenWarning: true,dialogMessage: 'O usuário não existe no Dapp CrossCheck',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenWarning: true,dialogMessage: 'Användaren finns inte i Dapp CrossCheck',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenWarning: true,dialogMessage: 'Gebruiker bestaat niet in Dapp CrossCheck',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenWarning: true,dialogMessage: 'Пользователь не существует в Dapp CrossCheck',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenWarning: true,dialogMessage: 'Dapp CrossCheckにユーザーが存在しません',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenWarning: true,dialogMessage: 'Dapp CrossCheck中不存在用户',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'German'){this.setState({dialogOpenWarning: true,dialogMessage: 'UBenutzer existiert nicht in Dapp CrossCheck',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenWarning: true,dialogMessage: "L'utente non esiste in Dapp CrossCheck",dialogTitle: 'Warning: 2001',})}
    }

    userUndefinedConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenWarning: true,dialogMessage: 'User must reconnect to Dapp CrossCheck',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'French'){this.setState({dialogOpenWarning: true,dialogMessage: "L'utilisateur doit se reconnecter à Dapp CrossCheck",dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenWarning: true,dialogMessage: 'El usuario debe volver a conectarse a Dapp CrossCheck',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenWarning: true,dialogMessage: 'O usuário deve se reconectar ao Dapp CrossCheck',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenWarning: true,dialogMessage: 'Användaren måste ansluta till Dapp CrossCheck igen',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenWarning: true,dialogMessage: 'Gebruiker moet opnieuw verbinding maken met Dapp CrossCheck',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenWarning: true,dialogMessage: 'Пользователь должен повторно подключиться к Dapp CrossCheck',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenWarning: true,dialogMessage: 'ユーザーはDapp CrossCheckに再接続する必要があります',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenWarning: true,dialogMessage: '用户必须重新连接到Dapp CrossCheck',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'German'){this.setState({dialogOpenWarning: true,dialogMessage: 'Der Benutzer muss erneut eine Verbindung zu Dapp CrossCheck herstellen',dialogTitle: 'Warning: 2003',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenWarning: true,dialogMessage: "L'utente deve riconnettersi a Dapp CrossCheck",dialogTitle: 'Warning: 2003',})}
    }

    warningConfirmUserGroup(){
      if (this.state.language === 'English'){this.setState({ dialogOpenWarning: true, dialogMessage: 'The option to add more participants is disabled. To enable it you must click on the button «Activate Participants»', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'French'){this.setState({ dialogOpenWarning: true, dialogMessage: `L'option pour ajouter plus de participants est désactivée. Pour l'activer, vous devez cliquer sur le bouton «Activer les participants»`, dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Spanish'){this.setState({ dialogOpenWarning: true, dialogMessage: 'La opción para agregar más participantes está deshabilitada. Para habilitarla debe hacer click en el botón «Activar Participantes»', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Portuguese'){this.setState({ dialogOpenWarning: true, dialogMessage: 'A opção de adicionar mais participantes está desativada. Para habilitá-lo, você deve clicar no botão «Ativar Participantes»', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Swedish'){this.setState({ dialogOpenWarning: true, dialogMessage: 'Alternativet att lägga till fler deltagare är inaktiverat. För att aktivera det måste du klicka på knappen «Aktivera Deltagare»', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Netherlands'){this.setState({ dialogOpenWarning: true, dialogMessage: 'De optie om meer deelnemers toe te voegen is uitgeschakeld. Om dit in te schakelen, moet u op de knop «Deelnemers activeren» klikken', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Russian'){this.setState({ dialogOpenWarning: true, dialogMessage: 'Возможность добавить больше участников отключена. Чтобы включить его, необходимо нажать на кнопку «Активировать участников»', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Japanese'){this.setState({ dialogOpenWarning: true, dialogMessage: '参加者をさらに追加するオプションは無効になっています。 これを有効にするには、「参加者をアクティブにする」ボタンをクリックする必要があります', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Chinese'){this.setState({ dialogOpenWarning: true, dialogMessage: '添加更多參與者的選項被禁用。 要啟用它，您必須單擊按鈕“激活參與者”', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'German'){this.setState({ dialogOpenWarning: true, dialogMessage: 'Die Option zum Hinzufügen weiterer Teilnehmer ist deaktiviert. Um es zu aktivieren, müssen Sie auf die Schaltfläche «Teilnehmer aktivieren» klicken.', dialogTitle: 'Warning: 2004', })}
      if (this.state.language === 'Italian'){this.setState({ dialogOpenWarning: true, dialogMessage: `L'opzione per aggiungere più partecipanti è disabilitata. Per abilitarlo è necessario fare clic sul pulsante «Attiva partecipanti»`, dialogTitle: 'Warning: 2004', })}
    }

    idProvisoryConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: 'You cannot add a new participant, you have a Provisional Decentralized Domain. You must acquire a domain.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "Vous ne pouvez pas ajouter de nouveau participant, vous disposez d'un Domaine Décentralisé Provisoire. Vous devez acquérir un domaine.",dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: 'No se puede agregar nuevo participante, usted tiene un Dominio Descentralizado Provisorio. Debe adquirir un Dominio',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: 'Você não pode adicionar um novo participante, você tem um Domínio Descentralizado Provisório. Você deve adquirir um domínio.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: 'Du kan inte lägga till en ny deltagare, du har en provisorisk decentraliserad domän. Du måste skaffa en domän.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: 'U kunt geen nieuwe deelnemer toevoegen, u heeft een Voorlopig Decentraal Domein. U moet een domein verwerven.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: 'Вы не можете добавить нового участника, у вас есть Временный децентрализованный домен. Вы должны приобрести домен.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '新しい参加者を追加することはできません。暫定的な分散ドメインがあります。 ドメインを取得する必要があります。',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '您不能添加新參與者，您有一個臨時分散域。 您必須獲得一個域。',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: 'Sie können keinen neuen Teilnehmer hinzufügen, Sie haben eine vorläufige dezentrale Domäne. Sie müssen eine Domain erwerben.',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: 'Non puoi aggiungere un nuovo partecipante, hai un dominio decentralizzato provvisorio. Devi acquisire un dominio.',dialogTitle: 'Error: 1001',})}
    }

    faltaDataConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: '------ Missing data ------',dialogTitle: 'Error: 1008',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "------ Données manquantes ------",dialogTitle: 'Error: 1008',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: '------ Faltan Datos ------',dialogTitle: 'Error: 1008',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: '------ Dados ausentes ------',dialogTitle: 'Error: 1008',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: '------ Saknar data ------',dialogTitle: 'Error: 1008',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: '------ Ontbrekende gegevens ------',dialogTitle: 'Error: 1008',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: '------ Потерянная информация ------',dialogTitle: 'Error: 1008',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '------ 欠測データ ------',dialogTitle: 'Error: 1008',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '------ 缺失數據 ------',dialogTitle: 'Error: 1008',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: '------ Fehlende Daten ------',dialogTitle: 'Error: 1008',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: '------ Dati mancanti ------',dialogTitle: 'Error: 1008',})}
    }

    goUserGroup() {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({displayUserGroup: true})
      return new Promise((resolve1, reject1) => {
          getFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_authorizer_deliverable.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 Promise.all(
                    [this.completaNameAndAvatar(jsonBlockstack6)]
                 ).then(
                   (resolve) =>{resolve1()},
                   (reject) =>{resolve1()}
                 )
               } else {
                 resolve1()
               }
             })
              .catch(error => {
                resolve1()
              });
      });
    }

    getDeliverableForms(username){
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = { decrypt: false, verify: false }
       let jsonBlockstack4 = []
       return new Promise ((resolve, reject) =>{
         getFileDB(username, `${this.props.typeContract}_${this.props.numberContract}_deliverable_form.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonFormsDeliverableList: jsonBlockstack6})
              resolve(true);
            } else {
              resolve(true);
            }
          })
           .catch(error => {
               resolve(true);
          });
       });
    }

    goRole(username) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({jsonRole: [], displayRole: true})
      return new Promise((resolve2, reject2) => {
          getFileDB(username, `role_deliverable.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.setState({jsonRole: jsonBlockstack6})
                 resolve2()
               } else {
                 resolve2()
               }
             })
              .catch(error => {
                console.log(error)
                resolve2()
              });
       });
    }

    clickSendContract = (e,idx,namex,rolex,emailx) => {
    }

    clickRemove = (e,optx,signature, sRole) => {
            const {userSession} = this.state
            const storage = new Storage({ userSession });
            const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
            this.setState({displayLoader: false})
            let iotdevice = []
            let userX = []
            let idX = null
            if (this.state.jsonUserGroup.length===0){
               iotdevice = this.props.jsonUserGroup
               userX = iotdevice[optx]
               let {id} = userX
               idX = id
            }else{
               iotdevice = this.state.jsonUserGroup
               userX = iotdevice[optx]
               let {id} = userX
               idX = id
            }
            iotdevice.splice(optx,1);
            const jsonBlockstack1 = JSON.stringify(iotdevice)
            putFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_authorizer_deliverable.json`, 'Crosscheck_Deliverable', jsonBlockstack1, JSON.stringify(options))
              .then(() => {
                let iotdevice2 = []
                iotdevice.map((todo, i) => {
                      if (todo.role === 'Supervisor MD' || todo.role === 'Adm MD' || todo.role === 'Jefe Turno Control Sondaje' || todo.role === 'Adm AA'){
                        iotdevice2.push({"codeFormsDeliverable":todo.codeFormsDeliverable,"id":todo.id,"name":todo.name,"role":todo.role,"active":todo.active,"signature":todo.signature,"identityAddress":todo.identityAddress,"statusInvite":todo.statusInvite,"email":todo.email, "publicKey":todo.publicKey, "conceptFormsDeliverable":todo.conceptFormsDeliverable})
                      }
                })
                const jsonBlockstack1 = JSON.stringify(iotdevice2)
                putFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_authorizer_deliverable_2.json`, 'Crosscheck_Deliverable', jsonBlockstack1, JSON.stringify(options))
                  .then(() => {
                    this.setState({displayLoader: true})
                    this.setState({jsonUserGroup: iotdevice})
                })
              })
    }

    advancedSearch = (e) => {
      this.setState({ advancedSearch: true })
    }

    dataUser(){
      if (this.c01UserGroup.current.value.length>0){
         if (this.state.username === this.state.stacksNetX.substring(0,5).toLowerCase()+'...'+this.state.stacksNetX.substring(this.state.stacksNetX.length-5).toLowerCase()+'.xck.app'){
           this.idProvisoryConfirm()
         }else{
           if (this.c04UserGroup.current.value !== 'SelectOptionRole') {
             let palabra = this.c04UserGroup.current.value
             let ArrayIdValue = palabra.split(' - ')
             const sRole = ArrayIdValue[0]

             palabra = this.c02UserGroup.current.value
             ArrayIdValue = palabra.split('&&&')
             const conceptX = ArrayIdValue[0]
             const codeX = ArrayIdValue[1]


             this.setState({displayLoader: false})
             let {jsonUserGroup} = this.state
             if (this.state.jsonUserGroup.length===0){
                 jsonUserGroup = this.props.jsonUserGroup
             }
             let existeUser = false
             let existeRole = false
             const largoUser = jsonUserGroup.length
             jsonUserGroup.map((todo,i)=>{
               if (todo.codeFormsDeliverable === codeX && todo.id === this.c01UserGroup.current.value){
                 existeUser = true
               }
               if (sRole === todo.role && ( todo.role === 'Contract' || todo.role === 'Quality' || todo.role === 'Administrator')) {
                 existeRole = true
               }
             })
             if (existeRole === true ) {
                this.setState({displayLoader: true})
                this.existeRoleConfirm()
             }else{
               if (existeUser === false ) {
                   this.dataUser2()
               }else{
                 this.setState({displayLoader: true})
                 this.existeUserConfirm()
               }
             }
           }else{
             this.faltaDataConfirm()
           }
         }
      }
    }

    userIdUsernameConfirm(){
    }

    dataUser2(username) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      return new Promise((resolve4e, reject4e) => {
          getFileDB(this.c01UserGroup.current.value, `publickey2.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    const publicKey1 = jsonBlockstack6.data
                    this.addUserGroup('', '', publicKey1, '')
                 }else{
                   this.userUndefinedConfirm()
                   this.addUserGroup('', '', '','')
                 }
                 resolve4e()
               } else {
                 this.userAppConfirm()
                 this.addUserGroup('', '', '','')
                 resolve4e()
               }
             })
              .catch(error => {
                console.log(error)
                this.userAppConfirm()
                this.addUserGroup('', '', '','')
                resolve4e()
              });
       });
    }

    addUserGroup(owner_address, name, publicKey, email){
        let {jsonUserGroup} = this.state
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }

        let palabra = this.c04UserGroup.current.value
        let ArrayIdValue = palabra.split(' - ')
        const sRole = ArrayIdValue[0]

        palabra = this.c02UserGroup.current.value
        ArrayIdValue = palabra.split('&&&')
        const conceptX = ArrayIdValue[0]
        const codeX = ArrayIdValue[1]
        const newUser = {"codeFormsDeliverable":codeX,"id":this.c01UserGroup.current.value,"name":name,"role":sRole,"active":"true","signature":"","identityAddress":owner_address,"statusInvite":false,"email":email, "publicKey":publicKey, "conceptFormsDeliverable":conceptX}
        let infoUserGoupX=[...jsonUserGroup, newUser]
        putFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_authorizer_deliverable.json`, 'Crosscheck_Deliverable', JSON.stringify(infoUserGoupX), JSON.stringify(options))
           .then(() => {
               let iotdevice2 = []
               infoUserGoupX.map((todo, i) => {
                   if (todo.role === 'Supervisor MD' || todo.role === 'Adm MD' || todo.role === 'Jefe Turno Control Sondaje' || todo.role === 'Adm AA'){
                     iotdevice2.push({"codeFormsDeliverable":todo.codeFormsDeliverable,"id":todo.id,"name":todo.name,"role":todo.role,"active":todo.active,"signature":todo.signature,"identityAddress":todo.identityAddress,"statusInvite":todo.statusInvite,"email":todo.email, "publicKey":todo.publicKey, "conceptFormsDeliverable":conceptX})
                   }
               })
               const jsonBlockstack1 = JSON.stringify(iotdevice2)
               putFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_authorizer_deliverable_2.json`, 'Crosscheck_Deliverable', jsonBlockstack1, JSON.stringify(options))
                 .then(() => {
                   this.completaNameAndAvatar(infoUserGoupX)
               })
          })
    }

    clickEdit = (e,optx) => {
    }

    displayProfile = (e,user2X,avatar2X,name2X,emailX) =>{
      e.preventDefault();
      Promise.all([this.readRemoteNameAvatarNames(user2X)]).then((resolve1) => {this.setState({dialogOpenViewProfile: true, dialogMessage: '', dialogTitle: 'View Profile', avatar2X, name2X, user2X, emailX })},(reject1) => {})
    }

    exitProgram(){
       this.props.returnProps();
    }

    render() {
        let link1 = `https://xck.app?username=${this.state.username}`
        let displayContacX = false
        let displayActiveUserGroup = false
        let displayActiveUserGroupMessage = false
        let {jsonUserGroup} = this.state
        if (this.state.jsonUserGroup.length===0){
           jsonUserGroup = this.props.jsonUserGroup
        }
        const advancedSearchX = this.state.advancedSearch
        const jsonContactListX = this.state.jsonContactList
        const jsonFormsDeliverableListX = this.state.jsonFormsDeliverableList

        let titleX = 'Users, Roles and Functions'
        let placeholderX1 = "Select an Option"
        if (this.state.language === 'English'){titleX = "Users, Roles and Functions";placeholderX1 = "Select an Option"}
        if (this.state.language === 'French'){titleX = "Utilisateurs, rôles et fonctions";placeholderX1 = "Sélectionner unproe.ide Option"}
        if (this.state.language === 'Spanish'){titleX = "Usuarios, Roles y Funcionalidades";placeholderX1 = "Seleccione una Opción"}
        if (this.state.language === 'Portuguese'){titleX = "Usuários, papéis e funções";placeholderX1 = "Selecione uma Opção"}
        if (this.state.language === 'Swedish'){titleX = "Användare, roller och funktioner";placeholderX1 = "Välj ett Alternativ"}
        if (this.state.language === 'Netherlands'){titleX = "Gebruikers, Rollen en Functies";placeholderX1 = "Kies een Optie"}
        if (this.state.language === 'Russian'){titleX = "Пользователи, роли и функции";placeholderX1 = "Выберите опцию"}
        if (this.state.language === 'Japanese'){titleX = "ユーザー、役割、機能";placeholderX1 = "オプションを選択"}
        if (this.state.language === 'Chinese'){titleX = "用戶、角色和職能";placeholderX1 = "选择一个选项"}
        if (this.state.language === 'German'){titleX = "Benutzer, Rollen und Funktionen";placeholderX1 = "Wähle eine Option"}
        if (this.state.language === 'Italian'){titleX = "Utenti, ruoli e funzioni";placeholderX1 = "Seleziona un'opzione"}

        const messageSubtittle = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`
        const {username, jsonRole} = this.state
        let correlativo = 0
        let avatar = ''
        let imageInvite = ''
        let imageSend = ''
        const avatar2 = 'images/avatar.png'


        let cryptoPactClarityEthereumX = ''
        let cryptoPactClarityEthereum2X = ''
        let myUserConfig = this.props.myUserConfig
        let balanceX = parseFloat(this.state.balanceCta)
        let transactionX = 0
        let commissionX = 0
        let rateX = 0
        let saldoX = 0
        cryptoPactClarityEthereumX = 'Stacks'
        cryptoPactClarityEthereum2X = 'STX'
        let disableTransferX = false
        if (balanceX < (transactionX + commissionX + rateX)) {
            saldoX = balanceX
            disableTransferX = true
        }else{
            saldoX = balanceX - (transactionX + commissionX + rateX)
        }

        let bSmsScope = false
        if(this.state.smsScope === 'Public'){
          bSmsScope = true
        }
        let bEmailScope = false
        if(this.state.emailScope === 'Public'){
          bEmailScope = true
        }
        let bStxScope = false
        if(this.state.stxScope === 'Public'){
          bStxScope = true
        }

        let bloquedX = false
        if (this.props.whatUserRole !== 'Creator' || !this.state.formsDeliverable2X){
          bloquedX = true
        }

        return (
          <div>
            <Container fluid className="main-content-container px-4">
              <Row noGutters className="page-header py-4">
                <TituloPagina2
                  sm="4"
                  title={titleX}
                  subtitle={messageSubtittle}
                  className="text-sm-left"
                />
              </Row>
              <Confirmacion
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpen}
                  dialogTitle={this.state.dialogTitle}
              />
              <Confirmacion2
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenPrint}
                  dialogTitle={this.state.dialogTitle}
              />
              <Confirmacion3
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenContinue}
                  dialogTitle={this.state.dialogTitle}
              />
              <ConfirmacionViewProfile
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenViewProfile}
                  dialogTitle={this.state.dialogTitle}
                  avatar={this.state.avatar3X}
                  avatar2={this.state.avatar2X}
                  username={this.state.user2X}
                  person={this.state.name2X}
                  aboutMe={this.state.aboutmeX}
                  facebook={this.state.facebookX}
                  twitter={this.state.twitterX}
                  youtube={this.state.youtubeX}
                  instagram={this.state.instagramX}
                  linkedin={this.state.linkedinX}
                  pinterest={this.state.pinterestX}
                  stxAddress={this.state.stxAddress2X}
                  email={this.state.emailX}
                  phone={this.state.phoneX}
                  espacio={' '}
                  smsScope={this.state.smsScope}
                  bSmsScope={bSmsScope}
                  bStxScope={bStxScope}
                  bEmailScope={bEmailScope}
                  smsPrefix={this.state.smsPrefix}

              />
              <ConfirmacionWarning
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenWarning}
                  dialogTitle={this.state.dialogTitle}
              />
              <ConfirmacionAccept
                  handleDialog={this.handleDialog}
                  dialogMessage={this.state.dialogMessage}
                  dialogOpen={this.state.dialogOpenAccept}
                  dialogTitle={this.state.dialogTitle}
              />

              <Row>
                <Col>
                  <Form style={{fontSize:12}}>
                    <Row form>
                       <Col md="3">
                         <label htmlFor="listFormsDeliverable"><FormattedMessage id="contract.listcol28" /></label>
                         <FormSelect
                             id="listUserGroup"
                             innerRef={this.c02UserGroup}
                             placeholder="Select an Option"
                             onChange={e=>this.handleChangeFormsDeliverable(e)}
                             disabled={this.state.formsDeliverable2X}
                             style={{backgroundColor:"#F2EACE"}}
                         >
                             <option value="SelectOptionRole">{placeholderX1}</option>
                             {jsonFormsDeliverableListX.map((todo, i) => {
                                   return (
                                     <option style={{color:'darkblue'}} key={i} value={`${todo.concept}&&&${todo.code}`}>{todo.code}</option>
                                   )
                             })}
                         </FormSelect>
                       </Col>
                       <Col md="1">
                          <div>
                            <label htmlFor="roleUserGroup">&nbsp;</label>
                            <br></br>
                            <Button outline pill theme="warning" size="sm" className="mb-2" onClick={this.resetSetting}>
                                <i className="material-icons mr-1">replay</i><FormattedMessage id="configuration.reset" />
                            </Button>
                          </div>
                       </Col>
                       {!bloquedX ?
                          <>
                            <Col md="1"></Col>
                            <Col md="3">
                              <label htmlFor="Contact"><FormattedMessage id="usergroup.contact" /></label>
                              <InputGroup className="mb-2">
                                <FormInput
                                   id="Contact"
                                   type="text"
                                   innerRef={this.c01UserGroup}
                                   placeholder="Id Stacks"
                                />
                                <InputGroupAddon type="append">
                                  <InputGroupText>
                                    <strong style={{cursor: 'pointer'}}  onClick={e=>this.advancedSearch(e)}><img src="images/searchUser.png" weight="20" height="20" alt=""/></strong>
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </Col>

                            {advancedSearchX ?
                              <>
                                <Col md="3">
                                  <label htmlFor="listUserGroup"><FormattedMessage id="usergroup.contactlist" /></label>
                                  <FormSelect
                                      id="listUserGroup"
                                      style={{fontSize:16}}
                                      innerRef={this.c03UserGroup}
                                      placeholder="Select an Option"
                                      onChange={e=>this.handleChangeUserGroup(e)}
                                  >
                                      <option value="SelectOptionRole">{placeholderX1}</option>
                                      {jsonContactListX.map((todo, i) => {
                                         return (
                                            <option style={{color:'darkblue'}} key={i} value={todo.id}>{todo.id}</option>
                                         )
                                      })}
                                  </FormSelect>
                                </Col>
                              </>
                            :
                              null
                            }
                            <Col md="2">
                              <label htmlFor="roleUserGroup"><FormattedMessage id="usergroup.role" /></label>
                              <FormSelect
                                  id="roleUserGroup"
                                  style={{fontSize:16}}
                                  innerRef={this.c04UserGroup}
                                  placeholder="Select an Option"
                                  onChange={e=>this.handleChangeRole(e, "Role")}
                              >
                                  <option value="SelectOptionRole">{placeholderX1}</option>
                                  {jsonRole.map((todo, i) => {
                                    let sCode = todo.code
                                    if (todo.description !== "Creator"){
                                     return (
                                       <option style={{color:'darkblue'}} key={i} value={`${todo.code} - ${todo.description}`}>{todo.description}</option>
                                  )}})}
                              </FormSelect>
                            </Col>


                            <Col md="1">
                              <div>
                                <label htmlFor="roleUserGroup">&nbsp;</label>
                                <br></br>
                                {!this.state.displayLoader ?
                                  <td><ContractLoader colorDark={this.props.colorDark}/></td>
                                :
                                  <Button pill theme="success" onClick={this.dataUser}>&nbsp;&nbsp;<FormattedMessage id="usergroup.add" />&nbsp;&nbsp;</Button>
                                }
                              </div>
                            </Col>
                          </>
                       : null }
                    </Row>
                    <Row>&nbsp;</Row>
                    <Row>
                      <Col md="12">
                        <label htmlFor="listUserGroup"><FormattedMessage id="usergroup.group" /></label>
                        {this.state.displayUserGroup ?
                               <Table size="sm" className="text-center" responsive hover striped>
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th style={{fontSize:16}}><FormattedMessage id="usergroup.avatar" /></th>
                                      <th style={{fontSize:16}} style={{fontSize:16}}><FormattedMessage id="usergroup.userid" /></th>
                                      <th style={{fontSize:16}}><FormattedMessage id="usergroup.name" /></th>
                                      <th style={{fontSize:16}}><FormattedMessage id="usergroup.role" /></th>
                                      <th style={{fontSize:16}}><FormattedMessage id="usergroup.delete" /></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {jsonUserGroup.map((todo, i) => {
                                      const palabra = todo.id
                                      const ArrayIdValue = palabra.split('.blockstack')
                                      avatar = ''
                                      if (todo.avatar === undefined || todo.avatar === null || todo.avatar === ''){
                                        if (ArrayIdValue.length===2){
                                           avatar = `${this.state.url}${todo.identityAddress}//avatar-0`
                                        }else{
                                           avatar = `${this.state.url}${todo.identityAddress}/0//avatar-0`
                                        }
                                      }else{
                                        avatar = Base64.decode(todo.avatar)
                                      }
                                      let displayDelete = true
                                      if (todo.role === "Creator" || todo.signature !== ""){
                                        displayDelete = false
                                      }
                                      let setColorInfo = false
                                      let withAvatar = "30"
                                      if (this.state.setItemInfo === i) {
                                        setColorInfo = true
                                        withAvatar = "45"
                                        if (todo.statusInvite===false){
                                          imageInvite = "images/inviteOrange.svg"
                                        }

                                      }
                                      if (todo.codeFormsDeliverable === this.state.formsDeliverable3X){
                                        return (
                                          <tr key={i} onMouseEnter={()=>this.onMouseEnterHandler(i)} onMouseLeave={()=>this.onMouseLeaveHandler(i)} style={ this.props.colorDark ? { color:'white'} : {color:'black'}}>
                                            <td>{++correlativo}</td>
                                            <td>
                                               <strong style={{cursor: 'pointer'}}  onClick={e=>this.displayProfile(e,todo.id,avatar2,todo.name, todo.email)}>
                                                 <div className="mb-3 mx-auto">
                                                    <ReactImageFallback
                                                         src={avatar}
                                                         fallbackImage={avatar2}
                                                         initialImage={avatar2}
                                                         alt={" "}
                                                         className="rounded-circle"
                                                         width={withAvatar}
                                                         title="View Profile"
                                                    />
                                                 </div>
                                                </strong>
                                            </td>
                                            <td>{todo.id}</td>
                                            <td>{todo.name}</td>
                                            <td>{todo.role}</td>
                                            {displayDelete ?
                                              <React.Fragment>
                                                {!bloquedX ?
                                                  <React.Fragment>
                                                    <td><Button pill theme="danger" onClick={e=>this.clickRemove(e, i, todo.signature, todo.role)}><FontAwesomeIcon icon={faTimes} /></Button></td>
                                                  </React.Fragment>
                                                :
                                                  <React.Fragment>
                                                    <td></td>
                                                  </React.Fragment>
                                                }
                                              </React.Fragment>
                                            :
                                              <React.Fragment>
                                                <td></td>
                                              </React.Fragment>
                                            }
                                          </tr>
                                      )}})}
                                  </tbody>
                                </Table>
                       :
                          <input type="hidden" ref={this.sensorGPSActiveRule} />
                       }
                      </Col>
                    </Row>
                    <Row></Row>

                  </Form>
                </Col>

              </Row>

              <br></br>
            </Container>
          </div>
        );
    }
}

export default AuthorizersDeliverable;
