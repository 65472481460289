import React from "react";
import { Nav, FormCheckbox } from "shards-react";

import Notifications from "./Notifications";
import UserActions from "./UserActions";
//import Logout from "./Logout";
import Network from "./Network";

let colorDark = false
let colorThemeX = 'Blue'
let iconMode = "images/light2.png"
if (localStorage["colorTheme"]) {
    colorThemeX = localStorage.getItem('colorTheme')
    if (colorThemeX === 'Dark') {
       iconMode = "images/dark2.png"
       colorDark = true
    }
}

function handleChangeMode(e,colorDark){
  if (colorDark){
     localStorage.setItem('colorTheme','Blue')
  }else{
    localStorage.setItem('colorTheme','Dark')
  }
  window.location = '/'
}

export default () => (
  <Nav navbar
       className="border-left flex-row"
       style={ colorDark ? { backgroundColor:'#0c151d'} : {}}>
    <Notifications colorDark={colorDark} />
    <UserActions colorDark={colorDark} />
    {/*<Logout />*/}
    {<Network colorDark={colorDark} />}
    &nbsp;&nbsp;&nbsp;&nbsp;
    <div className="text-center"
        style={{marginLeft:70, marginTop:15, maxWidth: "100px"}}
    >
      <FormCheckbox
        toggle
        checked={colorDark}
        onChange={e=>handleChangeMode(e,colorDark)}
      >
        <img src={iconMode} weight="25" height="25" alt=""/>        
      </FormCheckbox>
    </div>
  </Nav>
);
