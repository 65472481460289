import React from "react";
import { Container, Row, Col, Card } from "shards-react";
import { Table, Nav, NavItem, NavLink } from 'reactstrap';

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Person } from '@stacks/profile';
import { Storage } from '@stacks/storage'
import { StacksTestnet, StacksMainnet } from '@stacks/network';

import axios from 'axios';

import { parseZoneFile } from 'zone-file'

import { Base64 } from 'js-base64';

import { FormattedMessage } from 'react-intl';

import {username_new} from '../actions/userActions'
import {getFileDB} from '../actions/mongoDBActions'
import {profileUser} from '../actions/gaiaActions'

import TituloPagina from "../components/common/TituloPagina";
import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";
import NationalIdentity from "../components/user-profile-lite/NationalIdentity";

import "../components/contracts/tab.css";
import classnames from 'classnames';

import forge from 'node-forge'

class UserProfileLite extends React.Component {
  constructor() {
    super();

    this.state = {
      userSession: new UserSession(),
      identityAddress: undefined,
      decentralizedID: undefined,
      name: undefined,
      avatar:'',
      avatar2: 'images/avatar.png',
      username: undefined,
      user: {},
      url: 'https://gaia.blockstack.org/hub/',
      url2: 'https://core.blockstack.org/v1/users/',
      url3: 'https://core.blockstack.org/v1/names/',
      url4: 'https://api.hiro.so/v1/names/',
      error: undefined,
      exist: false,
      languaje: 'English',
      pubKey: '',
      zonefile_hash: '',
      status: '',
      jsonProfileUser: '',
      stacksNetX: '',
      serverStacksX: '',
      SMS: ' ',
      EMAIL: ' ',
      STX: ' ',
      BTC: ' ',
      KDA: ' ',
      ETH: ' ',
      aboutMe: '',
      facebbok: '',
      twitter: '',
      youtube: '',
      instagram: '',
      linkedin: '',
      pinterest: '',
      smsScope: '',
      smsCountry: '',
      smsPrefix: '',
      emailScope: '',
      applicationsScope: '',
      stxScope: '',
      btcScope: '',
      colorDark: false,
      storageX: '',
      zonefileX: '',
      stxAddress2X: '',
      networkX: '',
      activeTab: '10',
      mode: 'Light',
      modeVcard: 'Active',
      background: '',
      checkedMode: false,
      checkedModeVcard: true,
      checkedModeVcardTelephone: false,
      checkedModeVcardEmail: false,
      checkedModeVcardSocialNetwork: false,
      checkedModeVcardWeb: false,
      checkedModeVcardId: false,
      checkedModeVcardNotes: false,
      profileName: '',
      settingProfileMode: 'Simple',
      person: {
         name() {
           return 'Anonymous';
         },
         avatarUrl() {
           return this.state.avatar2;
         },
      },
    };
  }

  UNSAFE_componentWillMount() {
    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English' })
    }else{
      this.setState({ language: languageX })
    }
    let colorThemeX = 'Blue'
    if (localStorage["colorTheme"]) {
      colorThemeX = localStorage.getItem('colorTheme')
      if (colorThemeX === 'Dark') {
         this.setState({colorDark: true})
      }
    }
    this.componentDidMount()
  }

  componentDidMount() {
    const {userSession} = this.state
    let stacksNetX = ''
    let serverStacksX = ''
    let networkX = ''
    if (userSession.isUserSignedIn()) {
      const user = userSession.loadUserData()
      if (!localStorage["serverStacks"]) {
        serverStacksX = 'MainNet'
      }else{
        serverStacksX = localStorage.getItem('serverStacks')
      }
      if (serverStacksX === 'MainNet'){
	       stacksNetX =  user.profile.stxAddress.mainnet
         networkX = new StacksMainnet()
      }
      if (serverStacksX === 'TestNet'){
	       stacksNetX =  user.profile.stxAddress.testnet
         networkX = new StacksTestnet()
      }

      this.setState({ user })
      this.setState({ stacksNetX })
      this.setState({ serverStacksX })
      this.setState({ networkX })
    }

    const {identityAddress} = userSession.loadUserData();
    const {decentralizedID} = userSession.loadUserData();
    const {name} = userSession.loadUserData().profile;

    this.setState({ identityAddress, decentralizedID, name })


    const {username} = userSession.loadUserData();
    if (username === '' || username === undefined || username === null){
      username_new(stacksNetX).then(val => this.setState({username: val},()=>{
        Promise.all([this.getSettings3(val),this.getUsername(val,identityAddress)])
           .then(
              (resolve) => {},
              (reject) => {}
           )
      })) ;
    }else{
      this.setState({ username },()=>{
        Promise.all([this.getSettings3(username),this.getUsername(username,identityAddress)])
           .then(
              (resolve) => {},
              (reject) => {}
           )

      })
    }
  }

  onMouseEnterHandler = (i) =>{
    this.setState({setItemInfo: i})
  }

  onMouseLeaveHandler = (i) => {
  }


  handleChangeOption(optSearch) {
      this.setState({
        selectedConfiguration: optSearch,
        jsonBlockstack: [],
        displayMasterFile: false,
        displayOther: false,
        displayIoTDevice: false,
        displayIoTDevice2: false,
        modifying: false,
      });
      if (optSearch==="Avatar"){
        this.setState({activeTab: '1'});
      }
      if (optSearch==="NameId"){
        this.setState({activeTab: '2'});
      }
      if (optSearch==="Description"){
        this.setState({activeTab: '3'});
      }
      if (optSearch==="SocialNetwork"){
        this.setState({activeTab: '4'});
      }
      if (optSearch==="EmailPhone"){
        this.setState({activeTab: '5'});
      }
      if (optSearch==="StacksNetwork"){
        this.setState({activeTab: '6'});
      }
      if (optSearch==="Signature"){
        this.setState({activeTab: '7'});
      }
      if (optSearch==="ThemeProfile"){
        Promise.all([this.getThemeProfile(this.state.username),
                     this.getVcardProfile(this.state.username)
                    ])
           .then(
              (resolve) => {this.setState({activeTab: '8'})},
              () => {this.setState({activeTab: '8'})}
           )
      }
      if (optSearch==="Vcard"){
        Promise.all([this.getThemeProfile(this.state.username),
                     this.getVcardProfile(this.state.username)
                    ])
           .then(
              (resolve) => {this.setState({activeTab: '9'})},
              () => {this.setState({activeTab: '9'})}
           )
      }
      if (optSearch==="NationalIdentityAttribution"){
        this.setState({activeTab: '11'});
      }
      if (optSearch==="DisplayAll"){
        Promise.all([this.getThemeProfile(this.state.username),
                     this.getVcardProfile(this.state.username)
                    ])
           .then(
              (resolve) => {this.setState({activeTab: '10'})},
              () => {this.setState({activeTab: '10'})}
           )
      }
  }

  getUsername = (username,identityAddress) => {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    let person;
    if(username) {
       person = new Person(username.profile);
    } else {
       person = ""
    }
    this.setState({person})
    getFileDB(username, `myUserConfig.json`)
       .then((fileContents) => {
         if(fileContents) {
           const jsonBlockstack1 = JSON.parse(fileContents)
           const jsonBlockstack2 = jsonBlockstack1.data
           let jsonBlockstack5 = '[]'
           if (jsonBlockstack2 !== null){
              const jsonBlockstack3 = jsonBlockstack2.dataobject
              const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
              if (jsonBlockstack4.substring(0,1) === '"') {
                 jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
              } else {
                 jsonBlockstack5 = jsonBlockstack4
              }
           }
           let jsonBlockstack6 = JSON.parse(jsonBlockstack5)

           if (jsonBlockstack6.facebook === undefined || jsonBlockstack6.facebook === null || jsonBlockstack6.facebook === 'undefined'){ jsonBlockstack6.facebook = ''}
           if (jsonBlockstack6.twitter === undefined || jsonBlockstack6.twitter === null || jsonBlockstack6.twitter === 'undefined'){ jsonBlockstack6.twitter = ''}
           if (jsonBlockstack6.youtube === undefined || jsonBlockstack6.youtube === null || jsonBlockstack6.youtube === 'undefined'){ jsonBlockstack6.youtube = ''}
           if (jsonBlockstack6.instagram === undefined || jsonBlockstack6.instagram === null || jsonBlockstack6.instagram === 'undefined'){ jsonBlockstack6.instagram = ''}
           if (jsonBlockstack6.linkedin === undefined || jsonBlockstack6.linkedin === null || jsonBlockstack6.linkedin === 'undefined'){ jsonBlockstack6.linkedin = ''}
           if (jsonBlockstack6.pinterest === undefined || jsonBlockstack6.pinterest === null || jsonBlockstack6.pinterest === 'undefined'){ jsonBlockstack6.pinterest = ''}
           if (jsonBlockstack6.aboutme === undefined || jsonBlockstack6.aboutme === null || jsonBlockstack6.aboutme === 'undefined'){ jsonBlockstack6.aboutme = ''}
           if (jsonBlockstack6.smsScope === undefined || jsonBlockstack6.smsScope === null || jsonBlockstack6.smsScope === 'undefined'){ jsonBlockstack6.smsScope = 'Private'}
           if (jsonBlockstack6.stxScope === undefined || jsonBlockstack6.stxScope === null || jsonBlockstack6.stxScope === 'undefined'){ jsonBlockstack6.stxScope = 'Private'}
           if (jsonBlockstack6.btcScope === undefined || jsonBlockstack6.btcScope === null || jsonBlockstack6.btcScope === 'undefined'){ jsonBlockstack6.btcScope = 'Private'}
           if (jsonBlockstack6.emailScope === undefined || jsonBlockstack6.emailScope === null || jsonBlockstack6.emailScope === 'undefined'){ jsonBlockstack6.emailScope = 'Public'}
           if (jsonBlockstack6.profileName === undefined || jsonBlockstack6.profileName === null || jsonBlockstack6.profileName === 'undefined'){ jsonBlockstack6.profileName = ''}
           if (jsonBlockstack6.applicationsScope === undefined || jsonBlockstack6.applicationsScope === null || jsonBlockstack6.applicationsScope === 'undefined'){ jsonBlockstack6.applicationsScope = 'Public'}
           if (jsonBlockstack6.smsCountry === undefined || jsonBlockstack6.smsCountry === null || jsonBlockstack6.smsCountry === 'undefined'){ jsonBlockstack6.smsCountry = 'United States'}
           if (jsonBlockstack6.smsPrefix === undefined || jsonBlockstack6.smsPrefix === null || jsonBlockstack6.smsPrefix === 'undefined'){ jsonBlockstack6.smsPrefix = '+1'}
           this.setState({ SMS:jsonBlockstack6.smsNotifications, EMAIL:jsonBlockstack6.emailNotifications, STX:jsonBlockstack6.STX, BTC:jsonBlockstack6.BTC, KDA:jsonBlockstack6.KDA, ETH:jsonBlockstack6.ETH });
           this.setState({ aboutme: jsonBlockstack6.aboutme, facebook: jsonBlockstack6.facebook, twitter: jsonBlockstack6.twitter, youtube: jsonBlockstack6.youtube, instagram: jsonBlockstack6.instagram, linkedin: jsonBlockstack6.linkedin, pinterest: jsonBlockstack6.pinterest, smsScope: jsonBlockstack6.smsScope, emailScope: jsonBlockstack6.emailScope, smsCountry: jsonBlockstack6.smsCountry, smsPrefix: jsonBlockstack6.smsPrefix, stxScope: jsonBlockstack6.stxScope, btcScope: jsonBlockstack6.btcScope, applicationsScope: jsonBlockstack6.applicationsScope, profileName: jsonBlockstack6.profileName });
           localStorage.removeItem('sms2')
           localStorage.removeItem('email2')
           localStorage.setItem('sms2',jsonBlockstack6.smsNotifications)
           localStorage.setItem('email2',jsonBlockstack6.emailNotifications)
         } else {
           console.log("No Data");
         }
        })
        .catch(error => {
          console.log(error);
        });

        Promise.all([this.getThemeProfile(username),
                     this.getVcardProfile(username)
                    ])
           .then(
              (resolve) => {},
              () => {}
           )
  }

  getSettings3 = (username) =>{
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    this.setState({settingProfileMode: 'Simple'})
    let settingProfileMode = 'Simple'

    return new Promise ((resolve1, reject1) =>{
      getFileDB(username,`settings3.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             let jsonBlockstack8 = '[]'
             let jsonBlockstack10 = '[]'
             if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }

                 const jsonBlockstack6 = jsonBlockstack2.options
                 const jsonBlockstack7 = jsonBlockstack6.replace(/\\/g,"");
                 if (jsonBlockstack7.substring(0,1) === '"') {
                    jsonBlockstack8 = jsonBlockstack7.substring(1,jsonBlockstack7.length - 1)
                 } else {
                    jsonBlockstack8 = jsonBlockstack7
                 }
                 let jsonBlockstack9 = JSON.parse(jsonBlockstack8)

                 if (jsonBlockstack9.encrypt === true) {
                   Promise.all([profileUser(username)])
                    .then(
                      (publicKey) =>{
                        const vi = jsonBlockstack5

                        const data = jsonBlockstack5,
                              iv = '1111111111111111',
                              password = publicKey

                        const key = forge.md.sha256.create().update(password).digest().getBytes();

                        const encryptedBytes = forge.util.decode64(data);
                        const decipher = forge.cipher.createDecipher('AES-CTR', key)
                        decipher.start({ iv });
                        decipher.update(forge.util.createBuffer(encryptedBytes));
                        decipher.finish();
                        const decryptedBytes = decipher.output.getBytes();
                        const decryptedString = forge.util.encodeUtf8(decryptedBytes);
                        let jsonBlockstack4 = decryptedString.replace(/\\/g,"");
                        if (jsonBlockstack4.substring(0,1) === '"') {
                           jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                        } else {
                           jsonBlockstack5 = jsonBlockstack4
                        }
                        jsonBlockstack10 = JSON.parse(jsonBlockstack5)
                        const largo = jsonBlockstack10.length
                        jsonBlockstack10.map((todo,i)=>{
                          if(todo.code==='ProfileMode'){
                            settingProfileMode = todo.description
                          }
                        })
                        this.setState({settingProfileMode: settingProfileMode})
                        resolve1()
                      },
                      (reject) =>{
                        reject1()
                      }
                    )
                 }else{
                   jsonBlockstack10 = JSON.parse(jsonBlockstack5)
                   const largo = jsonBlockstack10.length
                   jsonBlockstack10.map((todo,i)=>{
                     if(todo.code==='ProfileMode'){
                       settingProfileMode = todo.description
                     }
                   })
                   this.setState({settingProfileMode: settingProfileMode})
                   resolve1()
                 }
             } else {
               reject1()
             }
          } else {
            reject1()
          }
        })
        .catch(error => {
          console.log(error)
          reject1()
        });
    });
  }

  getThemeProfile = (username) =>{
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    return new Promise ((resolve1, reject1) =>{
      getFileDB(username, `themeprofile.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
                let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                this.setState({ mode: jsonBlockstack6.mode  });
                this.setState({ background: Base64.decode(jsonBlockstack6.background) });
                if (jsonBlockstack6.mode === 'Dark'){
                    this.setState({checkedMode: true});
                }else{
                    this.setState({checkedMode: false});
                }
             }
             resolve1()
           } else {
             resolve1()
           }
         })
        .catch(error => {
          console.log(error)
          resolve1()
         });
    });
  }

  getVcardProfile = (username) =>{
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    return new Promise ((resolve1, reject1) =>{
      getFileDB(username, `vcardprofile.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               this.setState({ modeVcard: jsonBlockstack6.mode, checkedModeVcardTelephone: jsonBlockstack6.telephone, checkedModeVcardEmail: jsonBlockstack6.email });
               this.setState({ checkedModeVcardSocialNetwork: jsonBlockstack6.socialnetwork, checkedModeVcardWeb: jsonBlockstack6.web, checkedModeVcardId: jsonBlockstack6.id, checkedModeVcardNotes: jsonBlockstack6.notes });
               if (jsonBlockstack6.mode === 'Active'){
                  this.setState({checkedModeVcard: true});
               }else{
                  this.setState({checkedModeVcard: false});
               }

                if (jsonBlockstack6.telephone === 'true'){
                   this.setState({checkedModeVcardTelephone: true});
                }else{
                   this.setState({checkedModeVcardTelephone: false});
                }
                if (jsonBlockstack6.email === 'true'){
                   this.setState({checkedModeVcardEmail: true});
                }else{
                   this.setState({checkedModeVcardEmail: false});
                }
                if (jsonBlockstack6.socialnetwork === 'true'){
                   this.setState({checkedModeVcardSocialNetwork: true});
                }else{
                   this.setState({checkedModeVcardSocialNetwork: false});
                }
                if (jsonBlockstack6.web === 'true'){
                   this.setState({checkedModeVcardWeb: true});
                }else{
                   this.setState({checkedModeVcardWeb: false});
                }
                if (jsonBlockstack6.id === 'true'){
                   this.setState({checkedModeVcardId: true});
                }else{
                   this.setState({checkedModeVcardId: false});
                }
                if (jsonBlockstack6.notes === 'true'){
                   this.setState({checkedModeVcardNotes: true});
                }else{
                   this.setState({checkedModeVcardNotes: false});
                }
             }
             resolve1()
           } else {
             resolve1()
           }
         })
        .catch(error => {
          console.log(error)
          resolve1()
         })
    })
  }

  render() {
    let titleX = ''
    let subtitleX = ''
    let languageX = 'en'
    if (this.state.language === 'English'){ titleX = "My Profile"; languageX = 'en'}
    if (this.state.language === 'French'){ titleX = "Mon profil"; languageX = 'fr' }
    if (this.state.language === 'Spanish'){ titleX = "Mi perfil"; languageX = 'es' }
    if (this.state.language === 'Portuguese'){ titleX = "Meu perfil"; languageX = 'pt' }
    if (this.state.language === 'Swedish'){ titleX = "Min profil"; languageX = 'sv' }
    if (this.state.language === 'Netherlands'){ titleX = "Mijn profiel"; languageX = 'nl' }
    if (this.state.language === 'Russian'){ titleX = "Мой профайл"; languageX = 'ru' }
    if (this.state.language === 'Japanese'){ titleX = "私のプロフィール"; languageX = 'jp' }
    if (this.state.language === 'Chinese'){ titleX = "我的簡歷"; languageX = 'cn' }
    if (this.state.language === 'German'){ titleX = "Mein Profil"; languageX = 'de' }
    if (this.state.language === 'Italian'){ titleX = "Il mio profilo"; languageX = 'it' }

    let orderProfile1X = true
    let orderProfile2X = false
    let orderProfile3X = false
    let orderProfile4X = false
    if (this.state.activeTab === '10'){orderProfile1X = true; orderProfile2X = false; orderProfile3X = false}
    if (this.state.activeTab === '1' || this.state.activeTab === '2' || this.state.activeTab === '3' || this.state.activeTab === '4'){orderProfile1X = false; orderProfile2X = true; orderProfile3X = false}
    if (this.state.activeTab === '5' || this.state.activeTab === '6' || this.state.activeTab === '7' || this.state.activeTab === '8' || this.state.activeTab === '9'){orderProfile1X = false; orderProfile2X = false; orderProfile3X = true}
    if (this.state.activeTab === '11'){orderProfile4X = true}

    let profileNameX = ''
    if (this.state.profileName !== '' && this.state.profileName!== null && this.state.profileName !== undefined) {
      profileNameX = this.state.profileName
    }else{
      profileNameX = this.state.name
      if (profileNameX === undefined || profileNameX === null){profileNameX = ''}
    }

    let settingProfileModeFull = false
    if (this.state.settingProfileMode === 'Full'){
      settingProfileModeFull = true
    }
    return (
        <Container fluid className="main-content-container px-4" style={ this.state.colorDark ? { backgroundColor:'#060a0f'} : {}}>
          <Row noGutters className="page-header py-4">
            <TituloPagina title={titleX} subtitle={subtitleX} md="12" className="ml-sm-auto mr-sm-auto" />
          </Row>

          <Card style={ this.state.colorDark ? { backgroundColor:'#0b151d', color: 'White'} : {color: 'Black', backgroundColor:"#F2EACE" }} >
            <Row>
              <Col md="1"></Col>
              <Col md="10">
                <br></br>
                <Nav tabs pills className="myTab" style={ this.state.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => this.handleChangeOption("Avatar")}
                    >
                      <FormattedMessage id="profile.tab.avatar" />
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => this.handleChangeOption("NameId")}
                    >
                      <FormattedMessage id="profile.tab.nameid" />
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '3' })}
                      onClick={() => this.handleChangeOption("Description")}
                    >
                      <FormattedMessage id="profile.tab.description" />
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '4' })}
                      onClick={() => this.handleChangeOption("SocialNetwork")}
                    >
                      <FormattedMessage id="profile.tab.socialnetwork" />
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '5' })}
                      onClick={() => this.handleChangeOption("EmailPhone")}
                    >
                      <FormattedMessage id="profile.tab.emailphone" />
                    </NavLink>
                  </NavItem>
                  {settingProfileModeFull ?
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '6' })}
                        onClick={() => this.handleChangeOption("StacksNetwork")}
                      >
                        <FormattedMessage id="profile.tab.stacksnetwork" />
                      </NavLink>
                    </NavItem>
                  : null
                  }
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '7' })}
                      onClick={() => this.handleChangeOption("Signature")}
                    >
                      <FormattedMessage id="profile.tab.signature" />
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '8' })}
                      onClick={() => this.handleChangeOption("ThemeProfile")}
                    >
                      <FormattedMessage id="profile.tab.themeprofile" />
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '9' })}
                      onClick={() => this.handleChangeOption("Vcard")}
                    >
                      <FormattedMessage id="profile.tab.vcard" />
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '11' })}
                      onClick={() => this.handleChangeOption("NationalIdentityAttribution")}
                    >
                      <FormattedMessage id="profile.tab.nationalidentitityattribution" />
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '10' })}
                      onClick={() => this.handleChangeOption("DisplayAll")}
                    >
                      <FormattedMessage id="profile.tab.displayall" />
                    </NavLink>
                  </NavItem>

                </Nav>
              </Col>
              <Col md="1"></Col>
            </Row>
            <Row>&nbsp;</Row>
          </Card>
          {orderProfile1X ?
            <Row>
              <Col lg="5">
                <UserDetails username={this.state.username} url={this.state.url} identityAddress={this.state.identityAddress} zonefile_hash={this.state.zonefile_hash} person={this.state.person}  avatar={this.state.avatar} name={this.state.name} aboutme={this.state.aboutme} facebook={this.state.facebook} twitter={this.state.twitter} youtube={this.state.youtube} instagram={this.state.instagram} linkedin={this.state.linkedin} pinterest={this.state.pinterest} smsScope={this.state.smsScope} emailScope={this.state.emailScope} smsCountry={this.state.smsCountry} smsPrefix={this.state.smsPrefix} stxScope={this.state.stxScope} colorDark={ this.state.colorDark} STX={this.state.stacksNetX} applicationsScope={this.state.applicationsScope} storageX={this.state.storageX} zonefileX={this.state.zonefileX} networkX={this.state.networkX} activeTab={this.state.activeTab} settingProfileMode={this.state.settingProfileMode} />
              </Col>
              <Col lg="7">
                <UserAccountDetails username={this.state.username} userSession={this.state.userSession} publicKey={this.state.pubKey} identityAddress={this.state.identityAddress} decentralizedID={this.state.decentralizedID} zonefile_hash={this.state.zonefile_hash} SMS={this.state.SMS} EMAIL={this.state.EMAIL} STX={this.state.STX} BTC={this.state.BTC} KDA={this.state.KDA} ETH={this.state.ETH} stacksNetX={this.state.stacksNetX} serverStacksX={this.state.serverStacksX} smsScope={this.state.smsScope} emailScope={this.state.emailScope} smsCountry={this.state.smsCountry} smsPrefix={this.state.smsPrefix} stxScope={this.state.stxScope} btcScope={this.state.btcScope} colorDark={ this.state.colorDark} STX={this.state.stacksNetX} applicationsScope={this.state.applicationsScope} activeTab={this.state.activeTab} mode={this.state.mode} modeVcard={this.state.modeVcard} background={this.state.background} checkedMode={this.state.checkedMode} checkedModeVcard={this.state.checkedModeVcard} checkedModeVcardTelephone={this.state.checkedModeVcardTelephone} checkedModeVcardEmail={this.state.checkedModeVcardEmail} checkedModeVcardSocialNetwork={this.state.checkedModeVcardSocialNetwork} checkedModeVcardWeb={this.state.checkedModeVcardWeb} checkedModeVcardId={this.state.checkedModeVcardId} checkedModeVcardNotes={this.state.checkedModeVcardNotes} settingProfileMode={this.state.settingProfileMode} />
              </Col>
            </Row>
          : null }
          {orderProfile2X ?
            <Row>
              <Col lg="2"></Col>
              <Col lg="8">
                <UserDetails username={this.state.username} url={this.state.url} identityAddress={this.state.identityAddress} zonefile_hash={this.state.zonefile_hash} person={this.state.person}  avatar={this.state.avatar} name={this.state.name} aboutme={this.state.aboutme} facebook={this.state.facebook} twitter={this.state.twitter} youtube={this.state.youtube} instagram={this.state.instagram} linkedin={this.state.linkedin} pinterest={this.state.pinterest} smsScope={this.state.smsScope} emailScope={this.state.emailScope} smsCountry={this.state.smsCountry} smsPrefix={this.state.smsPrefix} stxScope={this.state.stxScope} colorDark={ this.state.colorDark} STX={this.state.stacksNetX} applicationsScope={this.state.applicationsScope} storageX={this.state.storageX} zonefileX={this.state.zonefileX} networkX={this.state.networkX} activeTab={this.state.activeTab} settingProfileMode={this.state.settingProfileMode} />
              </Col>
              <Col lg="2"></Col>
            </Row>
          : null }
          {orderProfile3X ?
            <Row>
              <Col lg="2"></Col>
              <Col lg="8">
                <UserAccountDetails username={this.state.username} userSession={this.state.userSession} publicKey={this.state.pubKey} identityAddress={this.state.identityAddress} decentralizedID={this.state.decentralizedID} zonefile_hash={this.state.zonefile_hash} SMS={this.state.SMS} EMAIL={this.state.EMAIL} STX={this.state.STX} BTC={this.state.BTC} KDA={this.state.KDA} ETH={this.state.ETH} stacksNetX={this.state.stacksNetX} serverStacksX={this.state.serverStacksX} smsScope={this.state.smsScope} emailScope={this.state.emailScope} smsCountry={this.state.smsCountry} smsPrefix={this.state.smsPrefix} stxScope={this.state.stxScope} btcScope={this.state.btcScope} colorDark={ this.state.colorDark} STX={this.state.stacksNetX} applicationsScope={this.state.applicationsScope} activeTab={this.state.activeTab} mode={this.state.mode} modeVcard={this.state.modeVcard} background={this.state.background} checkedMode={this.state.checkedMode} checkedModeVcard={this.state.checkedModeVcard} checkedModeVcardTelephone={this.state.checkedModeVcardTelephone} checkedModeVcardEmail={this.state.checkedModeVcardEmail} checkedModeVcardSocialNetwork={this.state.checkedModeVcardSocialNetwork} checkedModeVcardWeb={this.state.checkedModeVcardWeb} checkedModeVcardId={this.state.checkedModeVcardId} checkedModeVcardNotes={this.state.checkedModeVcardNotes} settingProfileMode={this.state.settingProfileMode} />
              </Col>
              <Col lg="2"></Col>
            </Row>
          : null }
          {orderProfile4X ?
            <Row>
              <Col lg="2"></Col>
              <Col lg="8">
                <NationalIdentity username={this.state.username} profileName={profileNameX} userSession={this.state.userSession} publicKey={this.state.pubKey} colorDark={ this.state.colorDark} smsCountry={this.state.smsCountry} smsPrefix={this.state.smsPrefix} SMS={this.state.SMS} EMAIL={this.state.EMAIL} STX={this.state.STX} language={languageX}/>
              </Col>
              <Col lg="2"></Col>
            </Row>
          : null }
        </Container>
    )
  }
};

export default UserProfileLite;
