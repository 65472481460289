import { MOSTRAR_TEMPLATEALL, TOKEN_TEMPLATE, MOSTRAR_TEMPLATE } from '../actions/types';

// cada reducer tiene su propio state

const initialState = {
  templates: [],
  templatesAll: [],
  templatesToken: [],
}

export default function(state = initialState, action) {
     switch(action.type) {
          case MOSTRAR_TEMPLATEALL:
               return {
                    ...state,
                    templatesAll: [...state.templatesAll, action.payload]
               }

          case TOKEN_TEMPLATE:
               return {
                    ...state,
                    templatesToken: [...state.temptemplatesTokenlatesAll, action.payload]
               }

          case MOSTRAR_TEMPLATE:
               return {
                   ...state,
                   templates: [...state.templates, action.payload]
               }

          default:
            return state;
      }
}
