import React, { Component } from 'react';

//UI
import {ListGroup, ListGroupItem, Row, Col, FormInput, Button, Card} from "shards-react";
import { Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';

// blockstack
import { UserSession } from 'blockstack';

//translate
import { FormattedMessage } from 'react-intl';

import {username_new} from '../../actions/userActions'
import {putFileDB} from '../../actions/mongoDBActions'

function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={ props.colorDark ? { backgroundColor:'#343539'} : {}}
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionAccept(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('accept')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={ props.colorDark ? { backgroundColor:'#343539'} : {}}
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'accept')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.accept" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Confirmacion2(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={ props.colorDark ? { backgroundColor:'#343539'} : {}}
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="danger" autoFocus>
                     <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="warning">
                     <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}


class NewInvitation extends Component {
  constructor(props) {
      super(props);

      this.newInvitation = this.newInvitation.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleChangeOption = this.handleChangeOption.bind(this);
      this.profileConfirm = this.profileConfirm.bind(this);
      this.emailConfirm = this.emailConfirm.bind(this);
      this.responseConfirm = this.responseConfirm.bind(this);
      this.sendConfirm = this.sendConfirm.bind(this);

      this.state = {
        userSession: new UserSession(),
        username: '',
        user: {},
        code: '',
        description: '',
        error: false,
        infoInvitation: '',
        status: false,
        dialogOpen: false,
        dialogOpen2: false,
        dialogOpenAccept: false,
        dialogMessage: '',
        dialogTitle: '',
        languaje: 'English',
       }
    }

    c01Invitation = React.createRef();
    c02Invitation = React.createRef();
    c03Invitation = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
    }

    handleChange(e,chk) {
      const newState = {};
      newState[chk] = !this.state[chk];
      this.setState({...this.state, ...newState});
      this.setState({isErrorWrite: false})
    }

    handleChangeOption(optSearch) {
    }

    handleDialog = (type, action) => {
        if (type === 'Error: 1001') {
            if (action === 'cancel') {
              this.setState({ dialogOpen: false });
            }
        }
        if (type === 'Error: 1002') {
            if (action === 'cancel') {
              this.setState({ dialogOpen: false });
            }
        }
        if (type === 'Confirm: 3001') {
            if (action === 'accept') {
              this.setState({ dialogOpenAccept: false });
            }
        }
        if (type === 'Confirm: 3002') {
            if (action === 'cancel') {
              this.setState({ dialogOpen2: false });
              this.newInvitation(1)
            }
            if (action === 'confirm') {
              this.setState({ dialogOpen2: false });
              this.newInvitation(2)
            }
        }
    }

    handleCaracteresEspeciales = (e) => {
      let palabra = e.target.value
      let ArrayIdValue = palabra.split(`"`)
      e.target.value = ArrayIdValue[0]
      palabra = e.target.value
      ArrayIdValue = palabra.split("'")
      e.target.value = ArrayIdValue[0]
    }

    sendConfirm(e){
      if (this.state.language === 'English'){this.setState({ dialogOpen2: true, dialogMessage: 'Confirm the sending of the email?', dialogTitle: 'Confirm: 3002', })}
      if (this.state.language === 'French'){this.setState({ dialogOpen2: true, dialogMessage: "Confirmer l'envoi de l'email?", dialogTitle: 'Confirm: 3002', })}
      if (this.state.language === 'Spanish'){this.setState({ dialogOpen2: true, dialogMessage: '¿Confirmar el envío del correo electrónico?', dialogTitle: 'Confirm: 3002', })}
      if (this.state.language === 'Portuguese'){this.setState({ dialogOpen2: true, dialogMessage: 'Confirmar o envio do email?', dialogTitle: 'Confirm: 3002', })}
      if (this.state.language === 'Swedish'){this.setState({ dialogOpen2: true, dialogMessage: 'Bekräfta att e-postmeddelandet skickas?', dialogTitle: 'Confirm: 3002', })}
      if (this.state.language === 'Netherlands'){this.setState({ dialogOpen2: true, dialogMessage: 'Bevestig het verzenden van de e-mail?', dialogTitle: 'Confirm: 3002', })}
      if (this.state.language === 'Russian'){this.setState({ dialogOpen2: true, dialogMessage: 'Подтвердить отправку электронного письма?', dialogTitle: 'Confirm: 3002', })}
      if (this.state.language === 'Japanese'){this.setState({ dialogOpen2: true, dialogMessage: 'メールの送信を確認しますか？', dialogTitle: 'Confirm: 3002', })}
      if (this.state.language === 'Chinese'){this.setState({ dialogOpen2: true, dialogMessage: '确认发送电子邮件？', dialogTitle: 'Confirm: 3002', })}
      if (this.state.language === 'German'){this.setState({ dialogOpen2: true, dialogMessage: 'Bestätigen Sie das Versenden der E-Mail?', dialogTitle: 'Confirm: 3002', })}
      if (this.state.language === 'Italian'){this.setState({ dialogOpen2: true, dialogMessage: "Conferma l'invio dell'email?", dialogTitle: 'Confirm: 3002', })}
    }

    profileConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenCancel: true, dialogMessage: 'You must enter the email address in your user profile', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'French'){this.setState({dialogOpenCancel: true, dialogMessage: "Vous devez entrer l'adresse email dans votre profil d'utilisateur", dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenCancel: true, dialogMessage: 'Debe ingresar la dirección de correo electrónico en su perfil de usuario', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenCancel: true, dialogMessage: 'Você deve inserir o endereço de email no seu perfil de usuário', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenCancel: true, dialogMessage: 'Du måste ange e-postadressen i din användarprofil', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenCancel: true, dialogMessage: 'U moet het e-mailadres in uw gebruikersprofiel invoeren', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Russian'){this.setState({dialogOpenCancel: true, dialogMessage: 'Вы должны ввести адрес электронной почты в своем профиле пользователя', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenCancel: true, dialogMessage: 'ユーザープロフィールにメールアドレスを入力する必要があります', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenCancel: true, dialogMessage: '您必须在用户个人资料中输入电子邮件地址', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'German'){this.setState({dialogOpenCancel: true, dialogMessage: 'Sie müssen die E-Mail-Adresse in Ihr Benutzerprofil eingeben', dialogTitle: 'Error: 1001', })}
      if (this.state.language === 'Italian'){this.setState({dialogOpenCancel: true, dialogMessage: `Devi inserire l'indirizzo e-mail nel tuo profilo utente`, dialogTitle: 'Error: 1001', })}
    }

    emailConfirm(){
      if (this.state.language === 'English'){this.setState({ dialogOpen: true, dialogMessage: 'You must enter a correct email', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'French'){this.setState({ dialogOpen: true, dialogMessage: 'Vous devez entrer un email correct', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Spanish'){this.setState({ dialogOpen: true, dialogMessage: 'Debe ingresar un correo electrónico válido', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Portuguese'){this.setState({ dialogOpen: true, dialogMessage: 'Você deve inserir um email correto', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Swedish'){this.setState({ dialogOpen: true, dialogMessage: 'Du måste ange ett korrekt e-postmeddelande', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Netherlands'){this.setState({ dialogOpen: true, dialogMessage: 'U moet een correct e-mailadres invoeren', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Russian'){this.setState({ dialogOpen: true, dialogMessage: 'Вы должны ввести правильный адрес электронной почты', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Japanese'){this.setState({ dialogOpen: true, dialogMessage: '正しいメールアドレスを入力する必要があります', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Chinese'){this.setState({ dialogOpen: true, dialogMessage: '您必须输入正确的电子邮件', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'German'){this.setState({ dialogOpen: true, dialogMessage: 'Sie müssen eine korrekte E-Mail-Adresse eingeben', dialogTitle: 'Error: 1002', })}
      if (this.state.language === 'Italian'){this.setState({ dialogOpen: true, dialogMessage: `Devi inserire un'e-mail corretta`, dialogTitle: 'Error: 1002', })}
    }

    responseConfirm(){
      if (this.state.language === 'English'){this.setState({ dialogOpenAccept: true, dialogMessage: 'Email was sent successfully', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'French'){this.setState({ dialogOpenAccept: true, dialogMessage: 'Email a été envoyé avec succès', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Spanish'){this.setState({ dialogOpenAccept: true, dialogMessage: 'Email fue enviado satisfactoriamente', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Portuguese'){this.setState({ dialogOpenAccept: true, dialogMessage: 'O email foi enviado com sucesso', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Swedish'){this.setState({ dialogOpenAccept: true, dialogMessage: 'E-postmeddelandet skickades', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Netherlands'){this.setState({ dialogOpenAccept: true, dialogMessage: 'E-mail is succesvol verzonden', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Russian'){this.setState({ dialogOpenAccept: true, dialogMessage: 'Письмо было успешно отправлено', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Japanese'){this.setState({ dialogOpenAccept: true, dialogMessage: 'メールは正常に送信されました', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Chinese'){this.setState({ dialogOpenAccept: true, dialogMessage: '电子邮件发送成功', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'German'){this.setState({ dialogOpenAccept: true, dialogMessage: 'E-Mail wurde erfolgreich gesendet', dialogTitle: 'Confirm: 3001', })}
      if (this.state.language === 'Italian'){this.setState({ dialogOpenAccept: true, dialogMessage: `L'email è stata inviata con successo`, dialogTitle: 'Confirm: 3001', })}
    }

    newInvitation = (modeX) => {
              const emailNotifications = this.props.jsonBlockstackZ.email
              const { userSession } = this.state
              const email = this.c01Invitation.current.value
              const name = this.c02Invitation.current.value
              const idstx = this.c03Invitation.current.value
              if(email === '' || email === undefined || email.indexOf('@') === -1) {
                   this.emailConfirm()
                   return;
              }
              this.setState({error: false})

              if (modeX === 2){
                fetch("https://xck.app:5443/sendCrosscheckInvitation",
                      {
                        method: "POST",
                        headers:{
                          'Content-Type': 'application/json',
                          "token": `${this.props.token}`
                        },
                        body: JSON.stringify({
                          name: name,
                          email: email,
                          senderEmail: emailNotifications
                        })
                      }
                ).then((response)=>{
                  if (response.status === 200) {
                    console.log('email sended');
                    let e = ''
                    this.responseConfirm(e)
                  }
                  else{
                    console.log('error sending the email')
                  }
                }).catch(()=>{
                  console.log('Catch: error sending the email')
                })
              }

              //-------------------------------------------
              // enviar a Blockstack (Cloud Storage)
              //-------------------------------------------
              // Crear el objeto
              let jsonBlockstack2 = ''
              let infoInvitation = ''
              let reviewInvitation = ''
              if (localStorage["jsonBlockstack30I"]) {
                jsonBlockstack2 = localStorage.getItem('jsonBlockstack30I')
                const jsonBlockstack3 = jsonBlockstack2.substring(1,jsonBlockstack2.length - 1)
                if (jsonBlockstack3 === '') {
                   infoInvitation =`[{"email":"${email}","name":"${name}","userid":"${idstx}"}]`
                   reviewInvitation ='[]'
                }else{
                  infoInvitation =`[${jsonBlockstack3},{"email":"${email}","name":"${name}","userid":"${idstx}"}]`
                  reviewInvitation =`[${jsonBlockstack3}]`
                }
              }else{
                infoInvitation =`[{"email":"${email}","name":"${name}","userid":"${idstx}"}]`
                reviewInvitation ='[]'
              }
              this.setState({ infoInvitation })
              const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }

              //let existInvitation3 = false
              //const infoInvitationJSON = JSON.parse(reviewInvitation)
              //infoInvitationJSON.map((todo, i) => {
              //   if (todo.email === email) {
              //      existInvitation3 = true
              //      return(true)
              //   }
              //   return false
              //})

              //if (existInvitation3===true){
              //   return
              //}

              putFileDB(this.props.username,`my_invitations.json`, 'Crosscheck_MyInvitations', JSON.stringify(infoInvitation), JSON.stringify(options))
                .then(() => {
                   this.setState({
                      status: true
                   })
                   //console.log(`Grabación Exitosa Invitation (my_invitations.json)`)
                })

              // enviar por props.
              this.props.crearInvitation(infoInvitation);
     }

     render() {
          return (
            <React.Fragment>
              <ListGroup flush>
                <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d', color: 'White'} : {}}>
                    <FormattedMessage id="invitation.sendnew" />
                    <Card style={ this.props.colorDark ? { backgroundColor:'#0b151d', color: 'White'} : {color: 'Black', backgroundColor:"#F2EACE"}}>
                      <Row>
                        <Confirmacion
                            handleDialog={this.handleDialog}
                            dialogMessage={this.state.dialogMessage}
                            dialogOpen={this.state.dialogOpen}
                            dialogTitle={this.state.dialogTitle}
                            colorDark={this.props.colorDark}
                        />
                        <Confirmacion2
                            handleDialog={this.handleDialog}
                            dialogMessage={this.state.dialogMessage}
                            dialogOpen={this.state.dialogOpen2}
                            dialogTitle={this.state.dialogTitle}
                            colorDark={this.props.colorDark}
                        />
                        <ConfirmacionAccept
                            handleDialog={this.handleDialog}
                            dialogMessage={this.state.dialogMessage}
                            dialogOpen={this.state.dialogOpenAccept}
                            dialogTitle={this.state.dialogTitle}
                            colorDark={this.props.colorDark}
                        />
                        <Col>
                            <Row form style={{fontSize:13}}>
                              <Col md="1"></Col>
                              <Col md="3">
                                  <label htmlFor="Email"><FormattedMessage id="invitation.email" /> (*)</label>
                                  <FormInput
                                    id="Email"
                                    style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}
                                    innerRef={this.c01Invitation}
                                    onChange={e=>this.handleCaracteresEspeciales(e)}
                                    placeholder="Email"
                                  />
                              </Col>
                              <Col md="3">
                                  <label htmlFor="Name"><FormattedMessage id="invitation.name" /> (*)</label>
                                  <FormInput
                                    id="Name"
                                    style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}
                                    innerRef={this.c02Invitation}
                                    onChange={e=>this.handleCaracteresEspeciales(e)}
                                    placeholder="Name"
                                  />
                               </Col>
                               <Col md="3">
                                   <label htmlFor="Name"><FormattedMessage id="usergroup.userid" /></label>
                                   <FormInput
                                     id="Id"
                                     style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}
                                     innerRef={this.c03Invitation}
                                     onChange={e=>this.handleCaracteresEspeciales(e)}
                                     placeholder="Id"
                                   />
                                </Col>
                               <Col md="1">
                                  <label htmlFor="Button">&nbsp;</label>
                                  <br></br>
                                  <Button pill theme="warning" onClick={e=>this.sendConfirm(e)}><FormattedMessage id="invitation.submit" /></Button>
                               </Col>
                            </Row>
                        </Col>
                      </Row>
                      <Row>&nbsp;</Row>
                    </Card>
                </ListGroupItem>
              </ListGroup>
            </React.Fragment>
           );
     }
}

export default NewInvitation;
