import React, { Component } from 'react';

//UI
import { Container, Row, Col, FormInput, Button, Card, InputGroupAddon, InputGroupText, InputGroup } from "shards-react";

// blockstack
import { UserSession } from '@stacks/connect';
import { StacksTestnet, StacksMainnet } from '@stacks/network';
import { StackingClient } from '@stacks/stacking';

//translate
import { FormattedMessage } from 'react-intl';

//
import {CopyToClipboard} from 'react-copy-to-clipboard';

//
import { QRCode } from 'react-qrcode-logo';

import {username_new} from '../../actions/userActions'

class ContractDrawerFinancesReceive extends Component {
  constructor(props) {
      super(props);

      this.state = {
        userSession: new UserSession(),
        username: '',
        user: {},
        code: '',
        stacksNetX: '',
        serverStacksX: '',
        stacksNetX2: '',
        networkX: '',
        clientX: '',
        balanceX: 0,
        arrow: false,
       }

    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state

      let stacksNetX = ''
      let serverStacksX = ''
      let stacksNetX2 = ''
      if (userSession.isUserSignedIn()) {

        let networkX = ''
        let clientX = ''
        let balanceX = 0

        const user = userSession.loadUserData()
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
           networkX = new StacksMainnet()
           stacksNetX2 = 'mainnet';
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
           networkX = new StacksTestnet();
           stacksNetX2 = 'testnet';
        }

        clientX = new StackingClient(stacksNetX, networkX, stacksNetX2)
        balanceX = clientX.getAccountBalance()
        //clientX = this.getStackingClient(stacksNetX,networkX)
        //balanceX = this.getStackingBalance(clientX)

        this.setState({ user })
        this.setState({ stacksNetX })
        this.setState({ serverStacksX })
        this.setState({ networkX })
        this.setState({ clientX })
        this.setState({ balanceX })

      }

      const {username} = userSession.loadUserData();

      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({ username })
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
    }

    //getStackingClient = (stacksNetX,networkX) => {return new Promise((resolve1, reject1) => {resolve1(new StackingClient(stacksNetX, networkX))});}

    //getStackingBalance = (clientX) => {return new Promise((resolve1, reject1) => {resolve1(clientX.getAccountBalance())});}

    handleChange(e,chk) {
      const newState = {};
      newState[chk] = !this.state[chk];

      if (chk==='arrow' && this.state.arrow===true){
        this.setState({arrow:false})
      }else{
        if (chk==='arrow' && this.state.arrow===false){
          this.setState({arrow:true})
        }
      }
    }

    copyToClipBoard = async () => {
      let value=this.state.stacksNetX
      try {
        await navigator.clipboard.writeText(value);
        this.setState({copied: true})
      } catch (err) {
        this.setState({copied: false})
      }
    };

    render() {
      let imgarrow = ''
      if (this.state.arrow===true){imgarrow='images/arrowdown.png'}else{imgarrow='images/arrowup.png'}
      return (
        <div>
          <Container fluid className="main-content-container px-2" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                <Card small className="text-uppercase" style={ this.props.colorDark ? { backgroundColor:'#233b58' } : { backgroundColor:'#e9ecef' }}>
                    <table>
                      <tbody>
                        <tr>
                        <td style={ this.props.colorDark ? {width: "95%", color: "grey"} : {width: "95%"}}>
                             &nbsp;&nbsp;
                             <strong style={{cursor: 'pointer'}} onClick={e => this.handleChange(e,"arrow")}><img src={imgarrow} weight="20" height="20" alt=""/></strong>
                             &nbsp;&nbsp;
                             <FormattedMessage id="finances.receive" />
                          </td>
                          <td style={{width: "5%"}}>
                             <strong style={{cursor: 'pointer'}} onClick={e => this.handleChange(e,"arrow")}><img src={imgarrow} weight="20" height="20" alt=""/></strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </Card>
                {this.state.arrow ?
                  <>
                    <div className="text-center">
                      <QRCode value={this.state.stacksNetX} />
                      <Row form>&nbsp;</Row>
                      <Row form>&nbsp;</Row>
                      <Row form>
                          <label htmlFor="feServerSTX" className="text-center" style={ this.props.colorDark ? { color:'#505459', width: "100%", fontSize:14} : {width: "100%", fontSize:14}}><FormattedMessage id="finances.address" /></label>
                          <InputGroup className="mb-2" style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                <strong style={{cursor: 'pointer'}}>
                                   <a href={`https://explorer.stacks.co/address/${this.state.stacksNetX}/?chain=${this.state.stacksNetX2}`} target="_blank" rel="noopener noreferrer"><img src="images/stx.svg" weight="20" height="20" alt="" title="STX" /></a>
                                </strong>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              style={ this.props.colorDark ? { backgroundColor:'#111f2c', fontSize:9} : {fontSize:9}}
                              className="text-center"
                              id="feServerSTX"
                              disabled={true}
                              placeholder={this.state.stacksNetX}
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
                                <strong style={{cursor: 'pointer'}} onClick={()=>this.copyToClipBoard()}>
                                  { this.props.colorDark ?
                                     <img src="images/copypasteGrey.png" weight="25" height="25" alt=""/>
                                  :
                                     <img src="images/copypaste.png" weight="25" height="25" alt=""/>
                                  }
                                </strong>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                      </Row>
                      <Row form>&nbsp;</Row>
                    </div>
                  </>
              : null }
          </Container>
        </div>
      );
    }
}

export default ContractDrawerFinancesReceive;
