import { takeLatest } from 'redux-saga/effects';

import {
  FETCH_PACT_LOGISTIC_CONFIG,
  FETCH_PACT_LOGISTIC_CONFIG_CONTRACTID,
  FETCH_DELETED_PACT_LOGISTIC_CONFIG,
  SAVE_NEW_PACT_LOGISTIC_CONFIG,
  SAVE_NEW_PACT_LOGISTIC_RULES,
  UPDATE_PACT_LOGISTIC_CONFIG,
  REMOVE_PACT_LOGISTIC_CONFIG,
  TOGGLE_PACT_STATE_LOGISTIC_CONFIG,
  SAVE_NEW_PACT_DATA_SENSOR_TEMPERATURE,
  SAVE_NEW_PACT_DATA_SENSOR_HUMIDITY,
  SAVE_NEW_PACT_DATA_SENSOR_GASCONTROL,
  SAVE_NEW_PACT_DATA_SENSOR,
  SAVE_NEW_PACT_MAINTENANCE_CONFIG,
  SAVE_NEW_PACT_MAINTENANCE_RULES,
  SAVE_NEW_PACT_PROFORMA_CONFIG,
  SAVE_NEW_PACT_PROFORMA_RULES
} from '../actions/types';

import {
  fetchPactLogisticConfig,
  fetchPactLogisticConfigContractId,
  fetchDeletedPactLogisticConfig,
  saveNewPactLogisticConfig,
  saveNewPactLogisticRules,
  saveNewPactDataSensorTemperature,
  saveNewPactDataSensor,
  saveNewPactDataSensorHumidity,
  saveNewPactDataSensorGascontrol,
  updatePactLogisticConfig,
  togglePactStateLogisticConfig,
  removePactLogisticConfig,
  saveNewPactMaintenanceConfig,
  saveNewPactMaintenanceRules,
  saveNewPactProformaConfig,
  saveNewPactProformaRules
} from './logistics';

export default function* rootSaga() {
  yield takeLatest(FETCH_PACT_LOGISTIC_CONFIG, fetchPactLogisticConfig);
  yield takeLatest(FETCH_PACT_LOGISTIC_CONFIG_CONTRACTID, fetchPactLogisticConfigContractId);
  yield takeLatest(FETCH_DELETED_PACT_LOGISTIC_CONFIG, fetchDeletedPactLogisticConfig);
  yield takeLatest(SAVE_NEW_PACT_LOGISTIC_CONFIG, saveNewPactLogisticConfig);
  yield takeLatest(SAVE_NEW_PACT_LOGISTIC_RULES, saveNewPactLogisticRules);
  yield takeLatest(SAVE_NEW_PACT_DATA_SENSOR_TEMPERATURE, saveNewPactDataSensorTemperature);
  yield takeLatest(SAVE_NEW_PACT_DATA_SENSOR, saveNewPactDataSensor);
  yield takeLatest(SAVE_NEW_PACT_DATA_SENSOR_HUMIDITY, saveNewPactDataSensorHumidity);
  yield takeLatest(SAVE_NEW_PACT_DATA_SENSOR_GASCONTROL, saveNewPactDataSensorGascontrol);
  yield takeLatest(UPDATE_PACT_LOGISTIC_CONFIG, updatePactLogisticConfig);
  yield takeLatest(TOGGLE_PACT_STATE_LOGISTIC_CONFIG, togglePactStateLogisticConfig);
  yield takeLatest(REMOVE_PACT_LOGISTIC_CONFIG, removePactLogisticConfig);
  yield takeLatest(SAVE_NEW_PACT_MAINTENANCE_CONFIG, saveNewPactMaintenanceConfig);
  yield takeLatest(SAVE_NEW_PACT_MAINTENANCE_RULES, saveNewPactMaintenanceRules);
  yield takeLatest(SAVE_NEW_PACT_PROFORMA_CONFIG, saveNewPactProformaConfig);
  yield takeLatest(SAVE_NEW_PACT_PROFORMA_RULES, saveNewPactProformaRules);
}
