import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarSearch from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";

import MainNavbar from "../MainNavbar/MainNavbar";
import MainFooter from "../Footer/MainFooter";

import { Store } from "../../../flux";

class MainSidebar extends React.Component {
  constructor(props) {
    super(props);

    let compressedSidebarX = true
    if (localStorage["compressedSidebar"]) {
      const cSidebarX = localStorage.getItem('compressedSidebar')
      if (cSidebarX === 'Yes'){compressedSidebarX = false}
      if (cSidebarX === 'No'){compressedSidebarX = true}
    }

    this.state = {
      menuVisible: compressedSidebarX,
      sidebarNavItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems()
    });
  }

  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      this.state.menuVisible && "open"
    );
    const { children, noNavbar, noFooter } = this.props
    const menuVisibleX = this.state.menuVisible
    const items = this.state.sidebarNavItems
    return (
      <React.Fragment>
        { menuVisibleX ?
          <React.Fragment>
            <Col
              tag="aside"
              className={classes}
              style={ this.props.colorDark ? { backgroundColor:'#0e0b1f'} : {}}
              lg={{ size: 2 }}
              md={{ size: 3 }}
            >
              <SidebarMainNavbar hideLogoText={this.props.hideLogoText} menuVisibleX={menuVisibleX} colorDark={this.props.colorDark} />
              <SidebarSearch />
              <SidebarNavItems menuVisibleX={menuVisibleX} items={items} colorDark={this.props.colorDark} />
            </Col>
            <Col className="main-content p-0"
                 style={ this.props.colorDark ? { backgroundColor:'#0c151d'} : {}}
                 lg={{ size: 10, offset: 2 }}
                 md={{ size: 9, offset: 3 }}
                 sm="12"
                 tag="main">
              {!noNavbar && <MainNavbar colorDark={this.props.colorDark} />}
              {children}
              {!noFooter && <MainFooter colorDark={this.props.colorDark} />}
            </Col>
          </React.Fragment>
        :
          <React.Fragment>
            <Col
              tag="aside"
              className={classes}
              style={ this.props.colorDark ? { backgroundColor:'#180b2a'} : {}}
              lg={{ size: 1 }}
              md={{ size: 1 }}
            >
              <SidebarMainNavbar hideLogoText={this.props.hideLogoText} menuVisibleX={menuVisibleX} colorDark={this.props.colorDark} />
              <SidebarSearch />
              <SidebarNavItems menuVisibleX={menuVisibleX} items={items} colorDark={this.props.colorDark} />
            </Col>
            <Col className="main-content p-0"
                 style={ this.props.colorDark ? { backgroundColor:'#180b2a'} : {}}
                 lg={{ size: 11, offset: 1 }}
                 md={{ size: 11, offset: 1 }}
                 sm="12"
                 tag="main">
              {!noNavbar && <MainNavbar colorDark={this.props.colorDark} />}
              {children}
              {!noFooter && <MainFooter colorDark={this.props.colorDark} />}
            </Col>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default MainSidebar;
