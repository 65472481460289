//React
import React, { Component } from 'react';

//UI
import { Table } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";
import { Container, Card } from "shards-react";

//translate
import { FormattedMessage } from 'react-intl';

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//Axios
import axios from 'axios';

import {username_new} from '../../actions/userActions'
import {getFileDB} from '../../actions/mongoDBActions'

//base64
import { Base64 } from 'js-base64';

//Configuración
import { MAINNET_STACKS_API_URL,
         TESTNET_STACKS_API_URL } from '../../config.js'

class ContractDrawerUserGroup extends Component {

    constructor(props) {
        super(props);

        this.goUserGroup = this.goUserGroup.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.goUserGroupOrigin = this.goUserGroupOrigin.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          name: '',
          user: {},
          opcionUserGroup: '',
          displayUserGroup: false,
          jsonUserGroup: [],
          url: 'https://gaia.blockstack.org/hub/',
          url2: 'https://core.blockstack.org/v1/users/',
          url3: 'https://core.blockstack.org/v1/names/',
          languaje: 'English',
          arrow: true,
          networkUrlX: '',
          nameX: '',
          avatarX: '',
        }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }
      const {name} = userSession.loadUserData().profile;
      this.setState({ name, networkUrlX })

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val},()=>{this.goUserGroupInit(val)})) ;
      }else{
        this.setState({ username },()=>{this.goUserGroupInit(username)})
      }
    }

    goUserGroupInit = (userX) => {
      this.goUserGroup(userX)
      if (this.props.userRole==="Creator"){
         this.goUserGroup(userX)
      }else{
        if (this.props.userRole==="Authorizer" || this.props.userRole==="Authorizer-Inviter" || this.props.userRole==="Observer" || this.props.userRole==="Support" || this.props.userRole==="Payer"  || this.props.userRole==="PayTo"){
            this.goUserGroupOrigin(this.props.typeContract,this.props.numberContract,this.props.userOrigin);
        }
      }
    }

    handleChange(e,chk) {
      const newState = {};
      newState[chk] = !this.state[chk];

      if (chk==='arrow' && this.state.arrow===true){
        this.setState({arrow:false})
      }else{
        if (chk==='arrow' && this.state.arrow===false){
          this.setState({arrow:true})
        }
      }
    }

    completaNameAndAvatar = async (jsonUserGroup,userX) => {
      const salida = []
      const resultado = await Promise.all(jsonUserGroup.map( async (todo) => {
         let user2X = userX
         if (todo.id !== userX){
            user2X = todo.id
         }
         return await Promise.all([this.readRemoteNameAvatar(user2X)])
           .then(
             (resolve) =>{
               salida.push({id:todo.id, identityAddress: todo.identityAddress, name:resolve[0].nameX, avatar:resolve[0].avatarX})
             },
             (reject)  =>{
               salida.push({id:todo.id, identityAddress: todo.identityAddress, name:'', avatar:''})
             }
           )
      }))
      this.setState({jsonUserGroup: salida},()=>{})
    }

    readRemoteNameAvatar = (userX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      let options = {}
      if (userX === '') {
         options = { decrypt: false, verify: false }
      }else{
        options = { username: userX, decrypt: false, verify: false }
      }
      return new Promise ((resolve, reject) =>{
        getFileDB(userX, `myUserConfig.json`)
           .then((fileContents) => {
             if (fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               resolve({nameX: jsonBlockstack6.profileName, avatarX: jsonBlockstack6.profileAvatar})
             }else{
               reject()
             }
           })
          .catch(error => {
        });
      });
    }

    goUserGroup(userX) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({displayUserGroup: true})
      return new Promise((resolve1, reject1) => {
          getFileDB(userX,`${this.props.typeContract}_${this.props.numberContract}_usergroup.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 const resultado = Array.from(new Set(jsonBlockstack6.map(s => s.id)))
                    .map(id => {
                        return {
                            id: jsonBlockstack6.find(s => s.id === id).id,
                            identityAddress: jsonBlockstack6.find(s => s.id === id).identityAddress
                        };
                    });
                 jsonBlockstack6 = resultado
                 this.setState({jsonUserGroup: jsonBlockstack6})
                 this.completaNameAndAvatar(jsonBlockstack6,userX)
                 resolve1()
               } else {
                 resolve1()
               }
             })
              .catch(error => {
                resolve1()
              });
      });
    }

    goUserGroupOrigin(typeContract,numberContract, userX) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { username: userX, decrypt: false, verify: false }
      this.setState({jsonUserGroup: []})
      return new Promise ((resolve, reject) =>{
        getFileDB(userX, `${typeContract}_${numberContract}_usergroup.json`)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               const resultado = Array.from(new Set(jsonBlockstack6.map(s => s.id)))
                  .map(id => {
                      return {
                          id: jsonBlockstack6.find(s => s.id === id).id,
                          identityAddress: jsonBlockstack6.find(s => s.id === id).identityAddress
                      };
                  });
               jsonBlockstack6 = resultado
               this.setState({jsonUserGroup: jsonBlockstack6})
               this.completaNameAndAvatar(jsonBlockstack6)
               resolve()
             } else {
               resolve()
             }
           })
            .catch(error => {
              resolve()
            });
        })
    }

    render() {
        let displayContacX = true
        let {jsonUserGroup} = this.state
        const {username} = this.state
        let avatar = ''
        const avatar2 = 'images/avatar.png'
        let imgarrow = ''
        if (this.state.arrow===true){imgarrow='images/arrowdown.png'}else{imgarrow='images/arrowup.png'}
        return (
          <Container fluid className="main-content-container px-2" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
             <Card className="text-uppercase" style={ this.props.colorDark ? { backgroundColor:'#233b58' } : { backgroundColor:'#e9ecef' }}>
                <table>
                  <tbody>
                    <tr>
                      <td style={ this.props.colorDark ? {width: "95%", color: "grey"} : {width: "95%"}}>
                         &nbsp;&nbsp;
                         <strong style={{cursor: 'pointer'}} onClick={e => this.handleChange(e,"arrow")}><img src={imgarrow} weight="20" height="20" alt=""/></strong>
                         &nbsp;&nbsp;
                         <FormattedMessage id="contract.listcol10" />
                      </td>
                      <td style={{width: "5%"}}>
                         <strong style={{cursor: 'pointer'}} onClick={e => this.handleChange(e,"arrow")}><img src={imgarrow} weight="20" height="20" alt=""/></strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </Card>
            {this.state.arrow ?
              <>
                 <Table size="sm" className="text-left" responsive  borderless>
                    <tbody>
                      {jsonUserGroup.map((todo, i) => {
                        const palabra = todo.id
                        const ArrayIdValue = palabra.split('.blockstack')
                        avatar = ''
                        if (todo.avatar === undefined || todo.avatar === null || todo.avatar === ''){
                          if (ArrayIdValue.length===2){
                             avatar = `${this.state.url}${todo.identityAddress}//avatar-0`
                          }else{
                             avatar = `${this.state.url}${todo.identityAddress}/0//avatar-0`
                          }
                        }else{
                          avatar = Base64.decode(todo.avatar)
                        }
                        if (todo.name === undefined || todo.name === null || todo.name === 'undefined' ){todo.name = ''}
                        return (
                            <tr key={i}>
                                <td style={{width: "10%"}}>
                                   <div className="mb-3 mx-auto">
                                      <ReactImageFallback
                                           src={avatar}
                                           fallbackImage={avatar2}
                                           initialImage={avatar2}
                                           alt={" "}
                                           className="rounded-circle"
                                           width="25"
                                      />
                                   </div>
                                </td>
                                <td className="page-subtitle" style={{width: "90%", fontSize:15, color:"Grey"}}>{todo.id}</td>
                            </tr>
                      )})}
                    </tbody>
                  </Table>
              </>
            : null }
          </Container>
        );
    }
}

export default ContractDrawerUserGroup;
