const currencyCrypto =
    {
    "CcyNtry": [
    {
        "CcyNm": "Stacks",
        "Ccy": "STX",
        "usym": "₿",
        "gecko": "stacks"
    },
    {
        "CcyNm": "Bitcoin",
        "Ccy": "BTC",
        "usym": "₿",
        "gecko": "bitcoin"
    },
    {
        "CcyNm": "Ethereum",
        "Ccy": "ETH",
        "usym": "Ξ",
        "gecko": "ethereum"
    },
    {
        "CcyNm": "Tether",
        "Ccy": "USDT",
        "usym": "₮",
        "gecko": "tether"
    },
    {
        "CcyNm": "Cardano",
        "Ccy": "ADA",
        "usym": "₳",
        "gecko": "cardano"
    },
    {
        "CcyNm": "XRP",
        "Ccy": "XRP",
        "usym": "✕",
        "gecko": "ripple"
    },
    {
        "CcyNm": "Solana",
        "Ccy": "SOL",
        "usym": "◎",
        "gecko": "solana"
    },
    {
        "CcyNm": "Polkadot",
        "Ccy": "DOT",
        "usym": "●",
        "gecko": "polkadot"
    },
    {
        "CcyNm": "Dogecoin",
        "Ccy": "DOGE",
        "usym": "Ð",
        "gecko": "dogecoin"
    },
    {
        "CcyNm": "Litecoin",
        "Ccy": "LTC",
        "usym": "Ł",
        "gecko": "litecoin"
    },
    {
        "CcyNm": "Algorand",
        "Ccy": "ALGO",
        "usym": "Ⱥ",
        "gecko": "algorand"
    },
    {
        "CcyNm": "Bitcoin Cash",
        "Ccy": "BCH",
        "usym": "Ƀ",
        "gecko": "bitcoin-cash"
    },
    {
        "CcyNm": "Ethereum Classic",
        "Ccy": "ETC",
        "usym": "ξ",
        "gecko": "ethereum-classic"
    },
    {
        "CcyNm": "Monero",
        "Ccy": "XMR",
        "usym": "ɱ",
        "gecko": "monero"
    },
    {
        "CcyNm": "Tezos",
        "Ccy": "XTZ",
        "usym": "ꜩ",
        "gecko": "tezos"
    },
    {
        "CcyNm": "Iota",
        "Ccy": "MIOTA",
        "usym": "ɨ",
        "gecko": "iota"
    },
    {
        "CcyNm": "Bitcoin SV",
        "Ccy": "BSV",
        "usym": "Ɓ",
        "gecko": "bitcoin-cash-sv"
    },
    {
        "CcyNm": "Zcash",
        "Ccy": "ZEC",
        "usym": "ⓩ",
        "gecko": "zcash"
    },
    {
        "CcyNm": "Dash",
        "Ccy": "DASH",
        "usym": "Đ",
        "gecko": "dash"
    }
    ]
}

export default currencyCrypto;
