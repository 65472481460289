//React
import React, { Component } from 'react';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Container, Button, Card, FormCheckbox, ListGroup, ListGroupItem, FormInput } from "shards-react";
import { Table } from 'reactstrap';
import { Divider, Typography, TextField, Grid, Dialog,
        DialogContent, DialogActions, DialogTitle, DialogContentText,
        Button as ButtonMaterialUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';

import TituloPagina2 from "../common/TituloPagina2";

// blockstack
//import { UserSession } from 'blockstack';
import { writeFile, readFile } from "blockstack-large-storage";
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//Uploader
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

//translate
import { FormattedMessage } from 'react-intl';

import {username_new} from '../../actions/userActions'
import {putFileDB} from '../../actions/mongoDBActions'

function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionSaveDraft(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class ContractAssociated extends Component {

    constructor(props) {
        super(props);

        this.addEnrollmentConfirm = this.addEnrollmentConfirm.bind(this);
        this.deleteEnrollmentConfirm = this.deleteEnrollmentConfirm.bind(this);
        this.deleteEnrollmentConfirm2 = this.deleteEnrollmentConfirm2.bind(this);
        this.closeEnrollmentConfirm = this.closeEnrollmentConfirm.bind(this);
        this.displayEnrollmentConfirm = this.displayEnrollmentConfirm.bind(this);
        this.newEnrollment = this.newEnrollment.bind(this);
        this.clickRemoveEnrollment = this.clickRemoveEnrollment.bind(this);
        this.displayEnrollmentConfirm = this.displayEnrollmentConfirm.bind(this);
        this.exitProgram = this.exitProgram.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          jsonEnrollment: [],
          jsonEnrollmentChange: [],
          enrollmentContractTable: '',
          displayRulesEnrollment: '',
          dialogOpenSaveDraft: false,
          dialogOpen: false,
          language: 'English',
        }
    }

    c02FieldContract = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
      this.setState({jsonEnrollment: this.props.jsonEnrollment})
    }

    handleDialog = (type, action) => {
        if (type === 'Error: 1001') {
          if (action === 'cancel') {
            this.setState({ dialogOpenSaveDraft: false });
          }
        }
        if (type === 'Error: 1002') {
           if (action === 'cancel') {
             this.setState({ dialogOpenSaveDraft: false });
           }
        }
        if (type === 'Confirm: 3001') {
           if (action === 'confirm') {
                this.setState({ dialogOpen: false });
                let e = ''
                if (localStorage["correlativoEnrollment"]) {
                   const i = localStorage.getItem('correlativoEnrollment')
                  this.clickRemoveEnrollment(e, i)
                }
           }else{
             if (action === 'cancel') {
              this.setState({ dialogOpen: false });
             }
           }
        }
    }


    addEnrollmentConfirm(){
      if (this.state.language === 'English'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract contains signatures, you must delete the signatures before adding new elements',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'French'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: "Smart Contract contient des signatures, vous devez les supprimer avant d'ajouter de nouveaux éléments",dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Spanish'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract contiene firmas, debe eliminar las firmas antes de agregar nuevos elementos',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Portuguese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Contrato inteligente contém assinaturas, você deve excluir as assinaturas antes de adicionar novos elementos',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Swedish'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract innehåller signaturer, du måste radera signaturerna innan du lägger till nya element',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Netherlands'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract bevat handtekeningen, u moet de handtekeningen verwijderen voordat u nieuwe elementen toevoegt',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Russian'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract содержит подписи, вы должны удалить подписи перед добавлением новых элементов',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Japanese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'スマートコントラクトには署名が含まれています。新しい要素を追加する前に署名を削除する必要があります',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Chinese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: '智能合约包含签名，必须在添加新元素之前删除签名',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'German'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract enthält Signaturen. Sie müssen die Signaturen löschen, bevor Sie neue Elemente hinzufügen',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Italian'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Il contratto intelligente contiene firme, è necessario eliminare le firme prima di aggiungere nuovi elementi',dialogTitle: 'Error: 1001',})
      }
    }

    closeEnrollmentConfirm(){
      if (this.state.language === 'English'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'You must save or close the rules, before deploying another',dialogTitle: 'Error: 1002',})
      }
      if (this.state.language === 'French'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Vous devez enregistrer ou fermer les règles avant de déployer une autre',dialogTitle: 'Error: 1002',})
      }
      if (this.state.language === 'Spanish'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Debe guardar o cerrar las reglas, antes de implementar otro',dialogTitle: 'Error: 1002',})
      }
      if (this.state.language === 'Portuguese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Você deve salvar ou fechar as regras antes de implantar outro',dialogTitle: 'Error: 1002',})
      }
      if (this.state.language === 'Swedish'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Du måste spara eller stänga reglerna innan du installerar en annan',dialogTitle: 'Error: 1002',})
      }
      if (this.state.language === 'Netherlands'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'U moet de regels opslaan of sluiten voordat u een andere implementeert',dialogTitle: 'Error: 1002',})
      }
      if (this.state.language === 'Russian'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Вы должны сохранить или закрыть правила перед развертыванием другого',dialogTitle: 'Error: 1002',})
      }
      if (this.state.language === 'Japanese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: '別のルールを展開する前に、ルールを保存または閉じる必要があります',dialogTitle: 'Error: 1002',})
      }
      if (this.state.language === 'Chinese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: '在部署另一个规则之前，必须保存或关闭规则',dialogTitle: 'Error: 1002',})
      }
      if (this.state.language === 'German'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Sie müssen die Regeln speichern oder schließen, bevor Sie eine andere bereitstellen',dialogTitle: 'Error: 1002',})
      }
      if (this.state.language === 'Italian'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'È necessario salvare o chiudere le regole prima di distribuirne un altro',dialogTitle: 'Error: 1002',})
      }
    }

    deleteEnrollmentConfirm(){
      if (this.state.language === 'English'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract contains signatures, you must delete the signatures before deleting elements',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'French'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract contient des signatures, vous devez les supprimer avant de supprimer des éléments',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Spanish'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract contiene firmas, debe eliminar las firmas antes de eliminar elementos',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Portuguese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Contrato inteligente contém assinaturas, você deve excluir as assinaturas antes de excluir elementos',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Swedish'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract innehåller signaturer, du måste radera signaturerna innan du raderar element',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Netherlands'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract bevat handtekeningen, u moet de handtekeningen verwijderen voordat u elementen verwijdert',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Russian'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract содержит подписи, вы должны удалить подписи перед удалением элементов',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Japanese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'スマートコントラクトには署名が含まれています。要素を削除する前に署名を削除する必要があります',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Chinese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: '智能合约包含签名，删除元素之前必须先删除签名',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'German'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract enthält Signaturen. Sie müssen die Signaturen löschen, bevor Sie Elemente löschen',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Italian'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Il contratto intelligente contiene firme, è necessario eliminare le firme prima di eliminare gli elementit',dialogTitle: 'Error: 1001',})
      }
    }

    deleteEnrollmentConfirm2 = (e,i) => {
      localStorage.setItem('correlativoEnrollment',i)
      if (this.state.language === 'English'){
        this.setState({dialogOpen: true,dialogMessage: 'Are you sure of delete the element?',dialogTitle: 'Confirm: 3001',displayRules: true,})
      }
      if (this.state.language === 'French'){
        this.setState({dialogOpen: true,dialogMessage: "Êtes-vous sûr de supprimer l'élément?",dialogTitle: 'Confirm: 3001',displayRules: true,})
      }
      if (this.state.language === 'Spanish'){
        this.setState({dialogOpen: true,dialogMessage: '¿Está seguro de eliminar el elemento?',dialogTitle: 'Confirm: 3001',displayRules: true,})
      }
      if (this.state.language === 'Portuguese'){
        this.setState({dialogOpen: true,dialogMessage: 'Tem certeza de que deseja excluir o elemento?',dialogTitle: 'Confirm: 3001',displayRules: true,})
      }
      if (this.state.language === 'Swedish'){
        this.setState({dialogOpen: true,dialogMessage: 'Är du säker på att radera elementet?',dialogTitle: 'Confirm: 3001',displayRules: true,})
      }
      if (this.state.language === 'Netherlands'){
        this.setState({dialogOpen: true,dialogMessage: 'Weet u zeker dat u het element wilt verwijderen?',dialogTitle: 'Confirm: 3001',displayRules: true,})
      }
      if (this.state.language === 'Russian'){
        this.setState({dialogOpen: true,dialogMessage: 'Вы уверены, что хотите удалить элемент?',dialogTitle: 'Confirm: 3001',displayRules: true,})
      }
      if (this.state.language === 'Japanese'){
        this.setState({dialogOpen: true,dialogMessage: '要素を削除してもよろしいですか？',dialogTitle: 'Confirm: 3001',displayRules: true,})
      }
      if (this.state.language === 'Chinese'){
        this.setState({dialogOpen: true,dialogMessage: '您确定要删除该元素吗？',dialogTitle: 'Confirm: 3001',displayRules: true,})
      }
      if (this.state.language === 'German'){
        this.setState({dialogOpen: true,dialogMessage: 'Sind Sie sicher, dass Sie das Element löschen möchten?',dialogTitle: 'Confirm: 3001',displayRules: true,})
      }
      if (this.state.language === 'Italian'){
        this.setState({dialogOpen: true,dialogMessage: "Sei sicuro di eliminare l'elemento?",dialogTitle: 'Confirm: 3001',displayRules: true,})
      }
    }

    displayEnrollmentConfirm = (e,i) => {
      localStorage.setItem('correlativoEnrollment',i)
      if (this.state.language === 'English'){
        this.setState({dialogOpen: true,dialogMessage: 'The item rules will be displayed',dialogTitle: 'Display Rules Element',})
      }
      if (this.state.language === 'French'){
        this.setState({dialogOpen: true,dialogMessage: "Les règles de l'article seront affichées",dialogTitle: 'Display Rules Element',})
      }
      if (this.state.language === 'Spanish'){
        this.setState({dialogOpen: true,dialogMessage: 'Se mostrarán las reglas del artículo',dialogTitle: 'Display Rules Element',})
      }
      if (this.state.language === 'Portuguese'){
        this.setState({dialogOpen: true,dialogMessage: 'As regras do item serão exibidas',dialogTitle: 'Display Rules Element',})
      }
      if (this.state.language === 'Swedish'){
        this.setState({dialogOpen: true,dialogMessage: 'Artikelreglerna visas',dialogTitle: 'Display Rules Element',})
      }
      if (this.state.language === 'Netherlands'){
        this.setState({dialogOpen: true,dialogMessage: 'De itemregels worden weergegeven',dialogTitle: 'Display Rules Element',})
      }
      if (this.state.language === 'Russian'){
        this.setState({dialogOpen: true,dialogMessage: 'Правила предмета будут отображены',dialogTitle: 'Display Rules Element',})
      }
      if (this.state.language === 'Japanese'){
        this.setState({dialogOpen: true,dialogMessage: 'アイテムルールが表示されます',dialogTitle: 'Display Rules Element',})
      }
      if (this.state.language === 'Chinese'){
        this.setState({dialogOpen: true,dialogMessage: '将显示项目规则',dialogTitle: 'Display Rules Element',})
      }
      if (this.state.language === 'German'){
        this.setState({dialogOpen: true,dialogMessage: 'Die Artikelregeln werden angezeigt',dialogTitle: 'Display Rules Element',})
      }
      if (this.state.language === 'Italian'){
        this.setState({dialogOpen: true,dialogMessage: "Verranno visualizzate le regole dell'oggetto",dialogTitle: 'Display Rules Element',})
      }
    }

    exitProgram(){
       //window.location = '/'
       this.props.returnProps();
    }

    newEnrollment(e,templateName){
      if (this.c02FieldContract.current.value.length > 0){
        if (this.props.JSONTemplateName === 'delivery' || this.props.JSONTemplateName === 'simulate'){
            var   temperature = 'true',
                  humidity = 'true',
                  gascontrol = 'true',
                  impact = 'false',
                  vibration = 'false',
                  proximity = 'false',
                  sealIntegrity = 'false',
                  gps = 'true'
            var   TemperatureLowerThreshold = '',
                  TemperatureUpperThreshold = '',
                  TemperatureFrecuency = '',
                  TemperatureUnitFrecuency = '',
                  TemperatureUnitMeasurement = '',
                  TemperatureErrorTime = '',
                  TemperatureErrorTimeUnit = '',
                  TemperatureErrorAction = '',
                  TemperatureActiveRule  = false
            var   HumidityLowerThreshold = '',
                  HumidityUpperThreshold = '',
                  HumidityFrecuency = '',
                  HumidityUnitFrecuency = '',
                  HumidityUnitMeasurement = '',
                  HumidityErrorTime = '',
                  HumidityErrorTimeUnit = '',
                  HumidityErrorAction = '',
                  HumidityActiveRule = false
            var   GascontrolLowerThreshold = '',
                  GascontrolUpperThreshold = '',
                  GascontrolFrecuency = '',
                  GascontrolUnitFrecuency = '',
                  GascontrolUnitMeasurement = '',
                  GascontrolErrorTime = '',
                  GascontrolErrorTimeUnit = '',
                  GascontrolErrorAction = '',
                  GascontrolActiveRule = false
            var   GPSFrecuency = '',
                  GPSUnitFrecuency = '',
                  GPSUnitMeasurement = '',
                  GPSErrorTime = '',
                  GPSErrorTimeUnit = '',
                  GPSErrorAction = '',
                  GPSActiveRule = false
            let jsonEnrollment = [...this.state.jsonEnrollment,
                {"enrollment":this.c02FieldContract.current.value,
                 "temperatureLowerThreshold":TemperatureLowerThreshold,
                 "temperatureUpperThreshold":TemperatureUpperThreshold,
                 "temperatureFrecuency":TemperatureFrecuency,
                 "temperatureUnitFrecuency":TemperatureUnitFrecuency,
                 "temperatureUnitMeasurement":TemperatureUnitMeasurement,
                 "temperatureErrorTime":TemperatureErrorTime,
                 "temperatureErrorTimeUnit":TemperatureErrorTimeUnit,
                 "temperatureErrorAction":TemperatureErrorAction,
                 "temperatureActiveRule":TemperatureActiveRule,
                 "humidityLowerThreshold":HumidityLowerThreshold,
                 "humidityUpperThreshold":HumidityUpperThreshold,
                 "humidityFrecuency":HumidityFrecuency,
                 "humidityUnitFrecuency":HumidityUnitFrecuency,
                 "humidityUnitMeasurement":HumidityUnitMeasurement,
                 "humidityErrorTime":HumidityErrorTime,
                 "humidityErrorTimeUnit":HumidityErrorTimeUnit,
                 "humidityErrorAction":HumidityErrorAction,
                 "humidityActiveRule":HumidityActiveRule,
                 "gascontrolLowerThreshold":GascontrolLowerThreshold,
                 "gascontrolUpperThreshold":GascontrolUpperThreshold,
                 "gascontrolFrecuency":GascontrolFrecuency,
                 "gascontrolUnitFrecuency":GascontrolUnitFrecuency,
                 "gascontrolUnitMeasurement":GascontrolUnitMeasurement,
                 "gascontrolErrorTime":GascontrolErrorTime,
                 "gascontrolErrorTimeUnit":GascontrolErrorTimeUnit,
                 "gascontrolErrorAction":GascontrolErrorAction,
                 "gascontrolActiveRule":GascontrolActiveRule,
                 "GPSFrecuency":GPSFrecuency,
                 "GPSUnitFrecuency":GPSUnitFrecuency,
                 "GPSUnitMeasurement":GPSUnitMeasurement,
                 "GPSErrorTime":GPSErrorTime,
                 "GPSErrorTimeUnit":GPSErrorTimeUnit,
                 "GPSErrorAction":GPSErrorAction,
                 "GPSActiveRule":GPSActiveRule,
                 "typeContract":this.props.JSONTemplateName,
                 "configurationContractNumber":this.props.numberContract,
                 "associatedContract":this.props.c04FieldContractX,
                 "gps":gps,
                 "temperature":temperature,
                 "humidity":humidity,
                 "gascontrol":gascontrol,
                 "impact":impact,
                 "vibration":vibration,
                 "proximity":proximity,
                 "sealIntegrity":sealIntegrity,
                 "link":"images/linkbrokenRed.png",
                 "dashboard":"images/eyeRed.png",
                 "sensor":"images/sensorRed.png",
                 "active":false}]
            this.setState({jsonEnrollment})

            const {userSession} = this.state
            const storage = new Storage({ userSession });
            const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
            putFileDB(this.state.username,`${this.props.typeContract}_${this.props.numberContract}_rules.json`, 'Crosscheck_Contract_AssociatedTo', JSON.stringify(jsonEnrollment), JSON.stringify(options))
              .then(() => {
                 //console.log(`Grabación Exitosa Blockstack (${this.props.typeContract}_${this.props.numberContract}_rules.json)`)
              })
        }else{
          if (this.props.JSONTemplateName === 'maintenance'){
            var   fire = 'true',
                  flood = 'true',
                  motion = 'true',
                  sealintegrity = 'true',
                  smoke = 'true'
            var   FireErrorAction = '',
                  FireActiveRule  = false
            var   FloodErrorAction = '',
                  FloodActiveRule = false
            var   MotionErrorAction = '',
                  MotionActiveRule = false
            var   SealIntegrityErrorAction = '',
                  SealIntegrityActiveRule = false
            var   SmokeErrorAction = '',
                  SmokeActiveRule = false
            let jsonEnrollment = [...this.state.jsonEnrollment,
                {"enrollment":this.c02FieldContract.current.value,
                 "fireErrorAction":FireErrorAction,
                 "fireActiveRule":FireActiveRule,
                 "floodErrorAction":FloodErrorAction,
                 "floodActiveRule":FloodActiveRule,
                 "motionErrorAction":MotionErrorAction,
                 "motionActiveRule":MotionActiveRule,
                 "sealintegrityErrorAction":SealIntegrityErrorAction,
                 "sealintegrityActiveRule":SealIntegrityActiveRule,
                 "smokeErrorAction":SmokeErrorAction,
                 "smokeActiveRule":SmokeActiveRule,
                 "typeContract":this.props.JSONTemplateName,
                 "configurationContractNumber":this.props.numberContract,
                 "associatedContract":this.props.c04FieldContractX,
                 "fire":fire,
                 "flood":temperature,
                 "motion":motion,
                 "sealintegrity":sealintegrity,
                 "smoke":smoke,
                 "link":"images/linkbrokenRed.png",
                 "dashboard":"images/eyeRed.png",
                 "sensor":"images/sensorRed.png",
                 "active":false}]
            this.setState({jsonEnrollment})

            const {userSession} = this.state
            const storage = new Storage({ userSession });
            const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
            putFileDB(`${this.state.username, this.props.typeContract}_${this.props.numberContract}_rules.json`, 'Crosscheck_Contract_AssociatedTo', JSON.stringify(jsonEnrollment), JSON.stringify(options))
              .then(() => {
                 console.log(`Grabación Exitosa Blockstack (${this.props.typeContract}_${this.props.numberContract}_rules.json)`)
              })
          }else{
            if (this.state.JSONTemplateName === 'proforma'){
            }else{
              if (this.state.JSONTemplateName === 'blank'){
              }
            }
          }
        }
      }
    }

    clickRemoveEnrollment = (e,optx) => {
        const masterfile = this.state.jsonEnrollment
        // borrarlo del objeto
        masterfile.splice(optx,1);
        // actualizar state
        this.setState({jsonEnrollment: masterfile})
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
        putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_rules.json`, 'Crosscheck_Contract_AssociatedTo', JSON.stringify(masterfile), JSON.stringify(options))
          .then(() => {
             //console.log(`Grabación Exitosa Blockstack (${this.props.typeContract}_${this.props.numberContract}_rules.json)`)
          })
    }

    render() {
          const {typeContract} = this.props
          const {numberContract} = this.props
          const {subjectContract} = this.props
          const messageSubtittle = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`
          const messageSubtittle2 = `${this.props.typeContract}/${this.props.numberContract}`
          let jsonEnrollment = {}
          if (this.state.jsonEnrollment.length===0){
             jsonEnrollment = this.props.jsonEnrollment
          }else{
             jsonEnrollment = this.state.jsonEnrollment
          }
          const displayAssociatedX = this.state.displayAssociated
          const displayFullChargeX = true
          const displayRoleX = this.props.displayRoleX
          const templateNameMaintenance = this.props.templateNameMaintenance
          const templateNameDelivery= this.props.templateNameDelivery
          const noEnrollmentX = this.props.noEnrollmentX
          const templateName = this.props.templateName
          let correlativo = 0
          let activate = false
          let titleX = ''
          let inputContentX = ''
          let submitButtonContentX = ''
          let inputWithFilesContentX = ''
          if (this.state.language === 'English'){
            titleX = "Associated to"
          }
          if (this.state.language === 'French'){
            titleX = "Associé à"
          }
          if (this.state.language === 'Spanish'){
            titleX = "Asociado a"
          }
          if (this.state.language === 'Portuguese'){
            titleX = "Associado a"
          }
          if (this.state.language === 'Swedish'){
            titleX = "Associerad med"
          }
          if (this.state.language === 'Netherlands'){
            titleX = "Geassocieerd met"
          }
          if (this.state.language === 'Russian'){
            titleX = "Связаны с"
          }
          if (this.state.language === 'Japanese'){
            titleX = "に関連付けられている"
          }
          if (this.state.language === 'Chinese'){
            titleX = "相关联"
          }
          if (this.state.language === 'German'){
            titleX = "Verbunden sein mit"
          }
          if (this.state.language === 'Italian'){
            titleX = "Associato a"
          }
          return (
            <div>
              <Container fluid className="main-content-container px-4">

                  <Confirmacion
                      handleDialog={this.handleDialog}
                      dialogMessage={this.state.dialogMessage}
                      dialogOpen={this.state.dialogOpen}
                      dialogTitle={this.state.dialogTitle}
                  />
                  <ConfirmacionSaveDraft
                      handleDialog={this.handleDialog}
                      dialogMessage={this.state.dialogMessage}
                      dialogOpen={this.state.dialogOpenSaveDraft}
                      dialogTitle={this.state.dialogTitle}
                  />

                  <Row noGutters className="page-header py-4">
                    <TituloPagina2
                      sm="8"
                      title={titleX}
                      subtitle={messageSubtittle}
                      className="text-sm-left"
                    />
                  </Row>

                  <Card>
                     <Form style={{fontSize:13}}>
                     <ListGroup flush>
                       <ListGroupItem className="p-3">
                         <Row>
                             <Col>
                                 <Row form style={{fontSize:13}}>
                                   <Col md="1"></Col>
                                   {displayRoleX ?
                                     <React.Fragment>
                                       <Col md="3">
                                         { templateNameDelivery
                                         ?
                                           <label htmlFor="ContainerNumber" style={{color:"red"}}><FormattedMessage id="contract.containeridentification" /> </label>
                                         : null
                                         }
                                         { templateNameMaintenance
                                         ?
                                           <label htmlFor="ContainerNumber" style={{color:"red"}}><FormattedMessage id="contract.warehouseidentification" /> {this.props.codeAssociatedToMaintenance}</label>
                                         : null
                                         }
                                         <FormInput
                                           id="ContainerNumber"
                                           innerRef={this.c02FieldContract}
                                           placeholder=""
                                         />
                                       </Col>
                                        <Col md="1">
                                             <label htmlFor="ContainerNumber" style={{color:"red"}}>&nbsp;</label>
                                             <div>
                                               {noEnrollmentX ?
                                                 <Button pill theme="warning" onClick={this.addEnrollmentConfirm}>---<FormattedMessage id="contract.add" />---</Button>
                                               :
                                                 <Button pill theme="warning" onClick={e=>this.newEnrollment(e,templateName)}>---<FormattedMessage id="contract.add" />---</Button>
                                               }
                                             </div>
                                        </Col>
                                        <Col md="1"></Col>
                                        <Col md="4">
                                          <label>&nbsp;</label>
                                          <Table size="sm">
                                              <thead>
                                                <tr>
                                                  <th style={{ width: "30px" }}>#</th>
                                                  <th style={{ width: "160px" }}><FormattedMessage id="contract.identification" /></th>
                                                  <th style={{ width: "20px" }}><FormattedMessage id="contract.delete" /></th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {jsonEnrollment.map((todo, i) => {
                                                  correlativo = i
                                                  return (
                                                      <tr key={i}>
                                                          <td>{++correlativo}</td>
                                                          <td>{todo.enrollment}</td>
                                                          {noEnrollmentX ?
                                                            <td><Button pill theme="danger" onClick={this.deleteEnrollmentConfirm}><FontAwesomeIcon icon={faTimes} /></Button></td>
                                                          :
                                                            <td><Button pill theme="danger" onClick={e=>this.deleteEnrollmentConfirm2(e,i)}><FontAwesomeIcon icon={faTimes} /></Button></td>
                                                          }
                                                      </tr>
                                                   )})}
                                              </tbody>
                                          </Table>
                                        </Col>

                                      </React.Fragment>
                                    :
                                      <React.Fragment>
                                        <input type="hidden" ref={this.c02FieldContract} />
                                        <Col md="4">
                                          <label>&nbsp;</label>
                                          <Table size="sm">
                                              <thead>
                                                <tr>
                                                  <th style={{ width: "30px" }}>#</th>
                                                  <th style={{ width: "160px" }}><FormattedMessage id="contract.identification" /></th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {jsonEnrollment.map((todo, i) => {
                                                  correlativo = i
                                                  return (
                                                      <tr key={i}>
                                                          <td>{++correlativo}</td>
                                                          <td>{todo.enrollment}</td>
                                                      </tr>
                                                   )})}
                                              </tbody>
                                          </Table>
                                        </Col>
                                      </React.Fragment>
                                    }
                                 </Row>

                             </Col>
                           </Row>
                           </ListGroupItem>
                       </ListGroup>
                       <Row>&nbsp;</Row>
                     </Form>
                  </Card>

                  <br></br>
                  <Row form>
                     <Col md="4">
                       <Button pill theme="secondary" onClick={this.exitProgram}><FormattedMessage id="button.contractlist" /></Button>
                     </Col>
                  </Row>
                  <br></br>
              </Container>
            </div>
        );
     }
}

export default ContractAssociated;
