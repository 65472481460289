// React
import React, { PureComponent } from 'react';
//import { Container, Row, Col } from 'reactstrap';
import { HashRouter as Router, Route } from 'react-router-dom';

// Cookies
import Cookies from 'js-cookie';

// Components
import routes from "./routes";
import withTracker from "./withTracker";
import Login from './components/login';

// Redux
import { Provider } from 'react-redux';
//import { store, persistor } from './store';
import { store } from './store';

// Stacks

import { AppConfig, UserSession } from '@stacks/connect';
import { Connect } from '@stacks/connect-react';

// ed25519 (cryptographic)
import { getMasterKeyFromSeed } from '@hawkingnetwork/ed25519-hd-key-rn';

// Paypal
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

// CCC
import 'bootstrap/dist/css/bootstrap.css';
import './styles/shards-dashboards.1.1.0Blue.min.css';

import { withRouter } from 'next/router';

//loader
import Loader from './components/loader'

//Ethereum
import getWeb3 from './getWeb3'
import contractCrosscheck  from './contractCrosscheck';

// Translate
import { IntlProvider } from "react-intl";
import messages_es from "./assets/i18n/es.json";
import messages_en from "./assets/i18n/en.json";
import messages_fr from "./assets/i18n/fr.json";
import messages_pt from "./assets/i18n/pt.json";
import messages_sv from "./assets/i18n/sv.json";
import messages_nl from "./assets/i18n/nl.json";
import messages_ru from "./assets/i18n/ru.json";
import messages_jp from "./assets/i18n/jp.json";
import messages_cn from "./assets/i18n/cn.json";
import messages_de from "./assets/i18n/de.json";
import messages_it from "./assets/i18n/it.json";

const converter = (web3) =>{
   return (value) =>{
     return web3.utils.fromWei(value.toString(), 'ether')
   }
}

//----------------------------
class App extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userSession: new UserSession({
        appConfig: new AppConfig(['store_write', 'publish_data'])
      }),

      userData: null,
      messages: {
        'es': messages_es,
        'en': messages_en,
        'fr': messages_fr,
        'pt': messages_pt,
        'sv': messages_sv,
        'nl': messages_nl,
        'ru': messages_ru,
        'jp': messages_jp,
        'cn': messages_cn,
        'de': messages_de,
        'it': messages_it
      },
      language: navigator.language.split(/[-_]/)[0],
      language2: '',
      language3: '',
      accountEthereum: '',
      balanceEthereum: '',
      originWelcome: true,
    }

    const getQueryStringValue = (key) => {
      return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }
    const userName = getQueryStringValue("username");
    //window.history.replaceState({}, document.title, "/");
    let previousCookies = Cookies.get("userName");
    //let previousCookies = localStorage.getItem('userName')
    if (!previousCookies) {
      previousCookies = new Set();
    }
    else {
      previousCookies = new Set(JSON.parse(previousCookies));
    }

    if (userName) {
      previousCookies.add(userName);
      Cookies.set("userName",JSON.stringify(previousCookies));
      //localStorage.setItem('userName', JSON.stringify(previousCookies))
      JSON.parse(Cookies.get("userName"));
      //JSON.parse(localStorage.getItem('userName'));
    }
    if (!localStorage["language"]) {
      localStorage.setItem('language', 'English')
    }
  }

  UNSAFE_componentWillMount(){
    let colorThemeX = 'Blue'
    if (localStorage["colorTheme"]) {
      colorThemeX = localStorage.getItem('colorTheme')
      if (colorThemeX !== 'Blue'){
         import(`./styles/shards-dashboards.1.1.0${colorThemeX}.min.css`).then(module => {})
      }
    }else{
      localStorage.setItem('colorTheme',colorThemeX)
    }
    if (!localStorage["serverStacks"]) {
      localStorage.setItem('serverStacks','MainNet')
    }
    if (!localStorage["languageCryptocurrency"]) {
      localStorage.setItem('languageCryptocurrency','Stacks (STX)')
    }
    if (!localStorage["cryptoCurrency"]) {
      localStorage.setItem('cryptoCurrency','Stacks (STX)')
    }
    if (!localStorage["languageOrigin"]) {
      localStorage.setItem('languageOrigin','From Browser')
    }
    let originWelcome = false
    if (!localStorage["landingPage"]) {
      localStorage.setItem('landingPage','Yes')
      if (window.location.search === '?origin=welcome'){
          originWelcome = true
      }
    }else{
      const originWelcomeX = localStorage.getItem('landingPage')
      if (originWelcomeX === 'No'){
        originWelcome = true
      }else {
        if (window.location.search === '?origin=welcome'){
            originWelcome = true
        }
      }
    }
    this.setState({originWelcome})
  }

  componentDidMount = async () => {
    const { userSession } = this.state

    if (!userSession.isUserSignedIn() && userSession.isSignInPending()) {
      const userData = await userSession.handlePendingSignIn()
      if (!userData.username || userData.username === null) {
        throw new Error('This app requires a username')
      }
      //window.location = '/'
      //window.location = window.location.origin
    } else {
      const languageX = localStorage.getItem('language')
      let language3 = ''
      if (languageX === undefined || languageX === null || languageX === '') {
        language3 = 'en'
      } else {
        if (languageX === 'English') {language3 = 'en'}
        if (languageX === 'Spanish') {language3 = 'es'}
        if (languageX === 'French') {language3 = 'fr'}
        if (languageX === 'Portuguese') {language3 = 'pt'}
        if (languageX === 'Swedish') {language3 = 'sv'}
        if (languageX === 'Netherlands') {language3 = 'nl'}
        if (languageX === 'Russian') {language3 = 'ru'}
        if (languageX === 'Japanese') {language3 = 'jp'}
        if (languageX === 'Chinese') {language3 = 'cn'}
        if (languageX === 'German') {language3 = 'de'}
        if (languageX === 'Italian') {language3 = 'it'}
      }

      const languageCryptocurrency = localStorage.getItem('languageCryptocurrency')
      if (languageCryptocurrency === 'Ethereum Solidity (ETH)'){
          localStorage.setItem('metamask','true');
          this.web3 = await getWeb3();
          this.toEther = converter(this.web3);
          var accountEthereum = (await this.web3.eth.getAccounts())[0];
          if (accountEthereum !== undefined){
            this.contractCrosscheck = await contractCrosscheck(this.web3.currentProvider);
            this.setState({accountEthereum: accountEthereum.toLowerCase()},() =>{this.load();});
          }
      }
      if (userSession.isUserSignedIn()) {
         window.location = window.location.origin + `?lang=${language3}#/start`
      }
    }
  }

  async getBalanceEthereum(){
    let weiBalance = await this.web3.eth.getBalance(this.state.accountEthereum)
    this.setState({balanceEthereum: this.toEther(weiBalance)})
    localStorage.setItem('accountbalanceethereum',`{"account":"${this.state.accountEthereum}","balance":${this.toEther(weiBalance)}}`)
  }

  async load(){
    this.getBalanceEthereum()
  }

  render() {
    const { userSession } = this.state
    //Estos datos provienen del login de blockstack------------------------------------
    const pubKey = 'd8dd254d020beb7b2265de1ac07990b67393d3a6d16e40e787a2c8d4d5cc01bb';
    const hexSeed = 'cc0720c7264eef6524b2d4f65af06d5f8cb33706f0e04cd4e929b77696ad946ad8dd254d020beb7b2265de1ac07990b67393d3a6d16e40e787a2c8d4d5cc01bb';
    //---------------------------------------------------------------------------------
    const { key } = getMasterKeyFromSeed(hexSeed);
    const secKey = key.toString("hex");
    const load_pact = `../load-pact.js -cf ../pact/globaLetterMeshl-connect.pact -n globalconnect -s ${secKey} -p ${pubKey} -d '{"admin-keyset":{"keys":["${pubKey}"],"pred":"="}}' | curl -X POST -d @- http://localhost:9001/api/v1/send`
    //console.log(load_pact);
    localStorage.removeItem('jsonRules4')
    //-----------------------------------------------
    let language3 = 'en'
    if (!userSession.isUserSignedIn()) {
      let languageOriginX = ''
      if (localStorage["languageOrigin"]) {
         languageOriginX = localStorage.getItem('languageOrigin')
      }else{
         languageOriginX = 'From Browser'
      }
      if (languageOriginX === 'From Browser'){
        language3 = window.navigator.language||navigator.browserLanguage;
        language3 = language3.substring(0,2);
        let languageX = ''
        if (language3 !=='en' && language3 !=='es' && language3 !=='fr' && language3 !=='pt' && language3 !=='sv' && language3 !=='nl' && language3 !=='ru' && language3 !=='jp' && language3 !=='cn' && language3 !=='de' && language3 !=='it'){
          language3 ='en'
        }
        if (language3 === 'en') {languageX = 'English'}
        if (language3 === 'es') {languageX = 'Spanish'}
        if (language3 === 'fr') {languageX = 'French'}
        if (language3 === 'pt') {languageX = 'Portuguese'}
        if (language3 === 'sv') {languageX = 'Swedish'}
        if (language3 === 'nl') {languageX = 'Netherlands'}
        if (language3 === 'ru') {languageX = 'Russian'}
        if (language3 === 'jp') {languageX = 'Japanese'}
        if (language3 === 'cn') {languageX = 'Chinese'}
        if (language3 === 'de') {languageX = 'German'}
        if (language3 === 'it') {languageX = 'Italian'}
        localStorage.setItem('language',languageX)
      }else{
        const languageX = localStorage.getItem('language')
        language3 = ''
        if (languageX === undefined || languageX === null || languageX === '') {
          language3 = 'en'
        } else {
          if (languageX === 'English') {language3 = 'en'}
          if (languageX === 'Spanish') {language3 = 'es'}
          if (languageX === 'French') {language3 = 'fr'}
          if (languageX === 'Portuguese') {language3 = 'pt'}
          if (languageX === 'Swedish') {language3 = 'sv'}
          if (languageX === 'Netherlands') {language3 = 'nl'}
          if (languageX === 'Russian') {language3 = 'ru'}
          if (languageX === 'Japanese') {language3 = 'jp'}
          if (languageX === 'Chinese') {language3 = 'cn'}
          if (languageX === 'German') {language3 = 'de'}
          if (languageX === 'Italian') {language3 = 'it'}
        }
      }
    }else{
      const languageX = localStorage.getItem('language')
      language3 = ''
      if (languageX === undefined || languageX === null || languageX === '') {
        language3 = 'en'
      } else {
        if (languageX === 'English') {language3 = 'en'}
        if (languageX === 'Spanish') {language3 = 'es'}
        if (languageX === 'French') {language3 = 'fr'}
        if (languageX === 'Portuguese') {language3 = 'pt'}
        if (languageX === 'Swedish') {language3 = 'sv'}
        if (languageX === 'Netherlands') {language3 = 'nl'}
        if (languageX === 'Russian') {language3 = 'ru'}
        if (languageX === 'Japanese') {language3 = 'jp'}
        if (languageX === 'Chinese') {language3 = 'cn'}
        if (languageX === 'German') {language3 = 'de'}
        if (languageX === 'Italian') {language3 = 'it'}
      }
    }

    const { userData } = this.state;
    const authOptions = {
      appDetails: {
        name: "CrossCheck",
        icon: 'https://xck.app/images/isotipo1.png',
      },
      onFinish: () => {
        let userData = userSession.loadUserData();
        window.location.reload();
      },
      onCancel: () => {
        console.log('oops, user cancels/closes pop-up');
      },
      userSession: userSession,
    };

    const {originWelcome} = this.state

    let languageEsX = false
    if (language3 === 'es'){
      languageEsX = true
    }

    //-----------------------------------------------
    return (
      <Connect authOptions={authOptions}>
        <Provider store={store}>
          <IntlProvider locale={language3} messages={this.state.messages[language3]}>
            <PayPalScriptProvider deferLoading={true}>
              {userSession.isUserSignedIn()
                ?
                  <Router>
                    <React.Fragment>
                      <div >
                        {routes.map((route, index) => {
                          return (
                            <Route
                              key={index}
                              path={route.path}
                              exact={route.exact}
                              component={withTracker(props => {
                                return (
                                  <route.layout userSession={userSession} {...props}>
                                    <route.component {...props} />
                                  </route.layout>
                                );
                              })}
                            />
                          );
                        })}
                      </div>
                    </React.Fragment>
                  </Router>
                :
                  <React.Fragment>
                    {userSession.isSignInPending() ?
                      <Loader />
                    :
                      <>
                        {originWelcome ?
                          <Login userSession={userSession} language={language3}/>
                        :
                          <>
                            {languageEsX ?
                              <>
                               {window.location = 'https://welcome.xck.app/spanish'}
                              </>
                            :
                              <>
                               {window.location = 'https://welcome.xck.app'}
                              </>
                            }
                          </>
                        }
                      </>
                    }
                  </React.Fragment>
                }
            </PayPalScriptProvider>
          </IntlProvider>
        </Provider>
      </Connect>
    );
  }
}

export default withRouter(App);
