import React, { Component } from 'react';

//UI
import {ListGroup, ListGroupItem, Row, Col, FormInput, Button, Form, FormCheckbox, FormSelect, Card} from "shards-react";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//translate
import { FormattedMessage } from 'react-intl';

import {username_new} from '../../actions/userActions'
import {putFileDB} from '../../actions/mongoDBActions'


class NewMasterFileDeliverable extends Component {
  constructor(props) {
      super(props);

      this.newMasterFile = this.newMasterFile.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleChangeLevel = this.handleChangeLevel.bind(this);

      this.state = {
        userSession: new UserSession(),
        username: '',
        user: {},
        code: '',
        description: '',
        error: false,
        infoMasterFile: '',
        status: false,
        c01Role: false,
        c02Role: false,
        c03Role: false,
        c04Role: false,
        c05Role: "13",
        c06Role: false,
        c07Role: false,
        language: 'English',
        c02MasterFileColour: '',
        c02MasterFileColourX: '',
        c03MasterFileColourLetterX: '',
        backgroundColor: ''
       }
    }

    c01MasterFile = React.createRef();
    c02MasterFile = React.createRef();
    c03MasterFile = React.createRef();
    c05MasterFile = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
    }

    componentDidMount = () => {
      if (this.props.modifying === true){
           if (this.props.typeMasterFile==="tag"){
              if (this.props.description !== undefined && this.props.hex !== undefined){
                 this.setState({ backgroundColor: this.props.hex, c02MasterFileColourX: this.props.description})
              }
              if (this.props.colorletter !== undefined){
                 this.setState({ c03MasterFileColourLetterX: this.props.colorletter})
              }
           }
      }
      if (this.props.typeMasterFile==="role"){
        if (this.props.modifying === true){
           const {jsonRol} = this.props
        }
      }
    }

    componentDidUpdate = () => {
          if (this.props.modifying === true){
               this.c01MasterFile.current.value = this.props.code;
               this.c02MasterFile.current.value = this.props.description;
               if (this.props.typeMasterFile==="tag"){
                  if (this.state.c02MasterFileColourX !== '' && this.state.backgroundColor !== '' && this.state.c03MasterFileColourLetterX !== ''){
                     this.c02MasterFile.current.value = `${this.state.c02MasterFileColourX}#$%${this.state.backgroundColor}`
                     this.c03MasterFile.current.value = this.state.c03MasterFileColourLetterX;
                  }else{
                     this.c02MasterFile.current.value = `${this.props.description}#$%${this.props.hex}`
                     this.c03MasterFile.current.value = this.props.colorletter;

                  }
               }
          }
          if (this.props.typeMasterFile==="role"){
            if (this.props.modifying === true){
               const {jsonRol} = this.props
            }
          }
    }

    handleChange(e,chk) {
        const newState = {};
        newState[chk] = !this.state[chk];
        this.setState({...this.state, ...newState, isErrorWrite: false})
    }

    handleChangeLevel(e) {
      this.setState({c05Role: this.c05MasterFile.current.value})
    }


    handleChangeColour = (e) => {
        const palabra = this.c02MasterFile.current.value
        const ArrayIdValue = palabra.split('#$%')
        this.setState({ backgroundColor: ArrayIdValue[1], c02MasterFileColourX: ArrayIdValue[0]})
    }

    handleChangeColourLetter = (e) => {
        this.setState({ c03MasterFileColourLetterX: this.c03MasterFile.current.value})
    }

    newMasterFile = e => {
          const {userSession} = this.state
          const storage = new Storage({ userSession });
          const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
          e.preventDefault();
          const code = this.c01MasterFile.current.value
          const description = this.c02MasterFile.current.value
          if(code === '' || description === '') {
               this.setState({error: true});
               return;
          }
          let color = ''
          let hex = ''
          let colorletter = ''
          if (this.props.typeMasterFile==="tag"){
            const ArrayIdValue = description.split('#$%')
            color = ArrayIdValue[0]
            hex = ArrayIdValue[1]
            colorletter = this.state.c03MasterFileColourLetterX
          }
          this.setState({error: false})

          let jsonBlockstack2 = ''
          let infoMasterFile = ''
          let reviewMasterFile = ''
          if (!this.props.modifying){
            const jsonBlockstack3 = this.props.jsonBlockstack
            let jsonBlockstack2 = JSON.stringify(jsonBlockstack3)
            let jsonBlockstack1 = jsonBlockstack2.substring(1,jsonBlockstack2.length - 1);
            if (jsonBlockstack3.length === 0) {
                 if (this.props.typeMasterFile==="role"){
                   infoMasterFile =`[{"code":"${code}","description":"${description}","level":${this.state.c05Role},"generate":${this.state.c01Role},"modify":${this.state.c02Role},"agregaobservacion":${this.state.c03Role},"signature":${this.state.c04Role},"visualize":${this.state.c06Role},"close":${this.state.c07Role}}]`
                 }else{
                   if (this.props.typeMasterFile==="tag"){
                     infoMasterFile =`[{"code":"${code}","description":"${color}","hex":"${hex}","colorletter":"${colorletter}"}]`
                   }else{
                     infoMasterFile =`[{"code":"${code}","description":"${description}"}]`
                   }
                 }
                 reviewMasterFile ='[]'
            }else{
                if (this.props.typeMasterFile==="role"){
                  infoMasterFile =`[${jsonBlockstack1},{"code":"${code}","description":"${description}","level":${this.state.c05Role},"generate":${this.state.c01Role},"modify":${this.state.c02Role},"agregaobservacion":${this.state.c03Role},"signature":${this.state.c04Role},"visualize":${this.state.c06Role},"close":${this.state.c07Role}}]`
                }else{
                  if (this.props.typeMasterFile==="tag"){
                    infoMasterFile =`[${jsonBlockstack1},{"code":"${code}","description":"${color}","hex":"${hex}","colorletter":"${colorletter}"}]`
                  }else{
                    infoMasterFile =`[${jsonBlockstack1},{"code":"${code}","description":"${description}"}]`
                  }
                }
                reviewMasterFile =`[${jsonBlockstack1}]`
            }
            this.setState({ infoMasterFile })
            let existNewMasterFile3 = false
            const infoMasterFileJSON = JSON.parse(reviewMasterFile)
            infoMasterFileJSON.map((todo, i) => {
               if (todo.code === code) {
                   existNewMasterFile3 = true
                   return(true)
               }
               return false
            })

            if (existNewMasterFile3===true){
               return
            }
          }else{
            const jsonBlockstack = this.props.jsonBlockstack
            jsonBlockstack.map((todo, i) => {
               if (todo.code === code) {
                   if (this.props.typeMasterFile==="role"){
                     todo.description = description
                   }else{
                     if (this.props.typeMasterFile==="tag"){

                       todo.description = color
                       todo.hex = hex
                       todo.colorletter = colorletter
                     }else{
                       todo.description = description
                     }
                   }
               }
            })
            infoMasterFile = JSON.stringify(jsonBlockstack)
          }
          if (this.props.typeMasterFile==="role"){
            putFileDB(this.state.username, `${this.props.typeMasterFile}_deliverable.json`, 'Crosscheck_Deliverable', JSON.stringify(infoMasterFile), JSON.stringify(options))
              .then(() => {this.props.crearMasterFile();})
          }
          if (this.props.typeMasterFile==="tag"){
            putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_${this.props.typeMasterFile}_deliverable.json`, 'Crosscheck_Deliverable', JSON.stringify(infoMasterFile), JSON.stringify(options))
              .then(() => {this.props.crearMasterFile();})
          }

          this.c01MasterFile.current.value = ''
          this.c02MasterFile.current.value = ''
          if (this.props.typeMasterFile==="tag"){
             this.c03MasterFile.current.value = ''
          }
          this.setState({c01Role:false,c02Role:false,c03Role:false,c04Role:false})
          this.setState({c02MasterFileColour: '', c02MasterFileColourX: '', c03MasterFileColourLetterX: '',backgroundColor: '#ffffff'})
     }

     render() {
          const {error} = this.state;
          let bRole = false
          let bTag = false
          let bOtro = true

          if (this.props.typeMasterFile==="role"){
            bRole = true
            bOtro = false
          }
          if (this.props.typeMasterFile==="tag"){
            bTag = true
            bOtro = false
          }
          let titleX = ' '
          if (this.props.modifying === false){
            titleX = 'Add New '
            if (this.state.language === 'English'){titleX = "Add New "}
            if (this.state.language === 'French'){titleX = "Ajouter un nouveau "}
            if (this.state.language === 'Spanish'){titleX = "Agrega Nuevo "}
            if (this.state.language === 'Portuguese'){titleX = "Adicionar novo "}
            if (this.state.language === 'Swedish'){titleX = "Lägg till ny "}
            if (this.state.language === 'Netherlands'){titleX = "Nieuw toevoegen "}
            if (this.state.language === 'Russian'){titleX = "Добавить новое "}
            if (this.state.language === 'Japanese'){titleX = "新しく追加する "}
            if (this.state.language === 'Chinese'){titleX = "添新 "}
            if (this.state.language === 'German'){titleX = "Neue hinzufügen "}
            if (this.state.language === 'Italian'){titleX = "Aggiungere nuova "}
          }else{
            titleX = 'Modify '
            if (this.state.language === 'English'){titleX = "Modify "}
            if (this.state.language === 'French'){titleX = "Modifier "}
            if (this.state.language === 'Spanish'){titleX = "Modificar "}
            if (this.state.language === 'Portuguese'){titleX = "Modificar "}
            if (this.state.language === 'Swedish'){titleX = "Ändra "}
            if (this.state.language === 'Netherlands'){titleX = "Aanpassen "}
            if (this.state.language === 'Russian'){titleX = "Изменить "}
            if (this.state.language === 'Japanese'){titleX = "変更する "}
            if (this.state.language === 'Chinese'){titleX = "修改 "}
            if (this.state.language === 'German'){titleX = "Ändern "}
            if (this.state.language === 'Italian'){titleX = "Modificare "}
          }

          const jsonColours = [
          {"name":"AliceBlue","hex":"#F0F8FF"},
          {"name":"AntiqueWhite","hex":"#FAEBD7"},
          {"name":"Aqua","hex":"#00FFFF"},
          {"name":"Aquamarine","hex":"#7FFFD4"},
          {"name":"Azure","hex":"#F0FFFF"},
          {"name":"Beige","hex":"#F5F5DC"},
          {"name":"Bisque","hex":"#FFE4C4"},
          {"name":"Black","hex":"#000000"},
          {"name":"BlanchedAlmond","hex":"#FFEBCD"},
          {"name":"Blue","hex":"#0000FF"},
          {"name":"BlueViolet","hex":"#8A2BE2"},
          {"name":"Brown","hex":"#A52A2A"},
          {"name":"BurlyWood","hex":"#DEB887"},
          {"name":"CadetBlue","hex":"#5F9EA0"},
          {"name":"Chartreuse","hex":"#7FFF00"},
          {"name":"Chocolate","hex":"#D2691E"},
          {"name":"Coral","hex":"#FF7F50"},
          {"name":"CornflowerBlue","hex":"#6495ED"},
          {"name":"Cornsilk","hex":"#FFF8DC"},
          {"name":"Crimson","hex":"#DC143C"},
          {"name":"Cyan","hex":"#00FFFF"},
          {"name":"DarkBlue","hex":"#00008B"},
          {"name":"DarkCyan","hex":"#008B8B"},
          {"name":"DarkGoldenRod","hex":"#B8860B"},
          {"name":"DarkGray","hex":"#A9A9A9"},
          {"name":"DarkGrey","hex":"#A9A9A9"},
          {"name":"DarkGreen","hex":"#006400"},
          {"name":"DarkKhaki","hex":"#BDB76B"},
          {"name":"DarkMagenta","hex":"#8B008B"},
          {"name":"DarkOliveGreen","hex":"#556B2F"},
          {"name":"Darkorange","hex":"#FF8C00"},
          {"name":"DarkOrchid","hex":"#9932CC"},
          {"name":"DarkRed","hex":"#8B0000"},
          {"name":"DarkSalmon","hex":"#E9967A"},
          {"name":"DarkSeaGreen","hex":"#8FBC8F"},
          {"name":"DarkSlateBlue","hex":"#483D8B"},
          {"name":"DarkSlateGray","hex":"#2F4F4F"},
          {"name":"DarkSlateGrey","hex":"#2F4F4F"},
          {"name":"DarkTurquoise","hex":"#00CED1"},
          {"name":"DarkViolet","hex":"#9400D3"},
          {"name":"DeepPink","hex":"#FF1493"},
          {"name":"DeepSkyBlue","hex":"#00BFFF"},
          {"name":"DimGray","hex":"#696969"},
          {"name":"DimGrey","hex":"#696969"},
          {"name":"DodgerBlue","hex":"#1E90FF"},
          {"name":"FireBrick","hex":"#B22222"},
          {"name":"FloralWhite","hex":"#FFFAF0"},
          {"name":"ForestGreen","hex":"#228B22"},
          {"name":"Fuchsia","hex":"#FF00FF"},
          {"name":"Gainsboro","hex":"#DCDCDC"},
          {"name":"GhostWhite","hex":"#F8F8FF"},
          {"name":"Gold","hex":"#FFD700"},
          {"name":"GoldenRod","hex":"#DAA520"},
          {"name":"Gray","hex":"#808080"},
          {"name":"Grey","hex":"#808080"},
          {"name":"Green","hex":"#008000"},
          {"name":"GreenYellow","hex":"#ADFF2F"},
          {"name":"HoneyDew","hex":"#F0FFF0"},
          {"name":"HotPink","hex":"#FF69B4"},
          {"name":"IndianRed","hex":"#CD5C5C"},
          {"name":"Indigo","hex":"#4B0082"},
          {"name":"Ivory","hex":"#FFFFF0"},
          {"name":"Khaki","hex":"#F0E68C"},
          {"name":"Lavender","hex":"#E6E6FA"},
          {"name":"LavenderBlush","hex":"	#FFF0F5"},
          {"name":"LawnGreen","hex":"#7CFC00"},
          {"name":"LemonChiffon","hex":"#FFFACD"},
          {"name":"LightBlue","hex":"#ADD8E6"},
          {"name":"LightCoral","hex":"#F08080"},
          {"name":"LightCyan","hex":"#E0FFFF"},
          {"name":"LightGoldenRodYellow","hex":"#FAFAD2"},
          {"name":"LightGray","hex":"#D3D3D3"},
          {"name":"LightGrey","hex":"#D3D3D3"},
          {"name":"LightGreen","hex":"#90EE90"},
          {"name":"LightPink","hex":"#FFB6C1"},
          {"name":"LightSalmon","hex":"#FFA07A"},
          {"name":"LightSeaGreen","hex":"#20B2AA"},
          {"name":"LightSkyBlue","hex":"#87CEFA"},
          {"name":"LightSlateGray","hex":"#778899"},
          {"name":"LightSlateGrey","hex":"#778899"},
          {"name":"LightSteelBlue","hex":"#B0C4DE"},
          {"name":"LightYellow","hex":"#FFFFE0"},
          {"name":"Lime","hex":"#00FF00"},
          {"name":"LimeGreen","hex":"#32CD32"},
          {"name":"Linen","hex":"#FAF0E6"},
          {"name":"Magenta","hex":"#FF00FF"},
          {"name":"Maroon","hex":"#800000"},
          {"name":"MediumAquaMarine","hex":"#66CDAA"},
          {"name":"MediumBlue","hex":"#0000CD"},
          {"name":"MediumOrchid","hex":"#BA55D3"},
          {"name":"MediumPurple","hex":"#9370D8"},
          {"name":"MediumSeaGreen","hex":"#3CB371"},
          {"name":"MediumSlateBlue","hex":"#7B68EE"},
          {"name":"MediumSpringGreen","hex":"#00FA9A"},
          {"name":"MediumTurquoise","hex":"#48D1CC"},
          {"name":"MediumVioletRed","hex":"#C71585"},
          {"name":"MidnightBlue","hex":"#191970"},
          {"name":"MintCream","hex":"#F5FFFA"},
          {"name":"MistyRose","hex":"#FFE4E1"},
          {"name":"Moccasin","hex":"#FFE4B5"},
          {"name":"NavajoWhite","hex":"#FFDEAD"},
          {"name":"Navy","hex":"#000080"},
          {"name":"OldLace","hex":"#FDF5E6"},
          {"name":"Olive","hex":"#808000"},
          {"name":"OliveDrab","hex":"#6B8E23"},
          {"name":"Orange","hex":"#FFA500"},
          {"name":"OrangeRed","hex":"#FF4500"},
          {"name":"Orchid","hex":"#DA70D6"},
          {"name":"PaleGoldenRod","hex":"#EEE8AA"},
          {"name":"PaleGreen","hex":"#98FB98"},
          {"name":"PaleTurquoise","hex":"#AFEEEE"},
          {"name":"PaleVioletRed","hex":"#D87093"},
          {"name":"PapayaWhip","hex":"#FFEFD5"},
          {"name":"PeachPuff","hex":"#FFDAB9"},
          {"name":"Peru","hex":"#CD853F"},
          {"name":"Pink","hex":"#FFC0CB"},
          {"name":"Plum","hex":"#DDA0DD"},
          {"name":"PowderBlue","hex":"#B0E0E6"},
          {"name":"Purple","hex":"#800080"},
          {"name":"Red","hex":"#FF0000"},
          {"name":"RosyBrown","hex":"#BC8F8F"},
          {"name":"RoyalBlue","hex":"#4169E1"},
          {"name":"SaddleBrown","hex":"#8B4513"},
          {"name":"Salmon","hex":"#FA8072"},
          {"name":"SandyBrown","hex":"#F4A460"},
          {"name":"SeaGreen","hex":"#2E8B57"},
          {"name":"SeaShell","hex":"#FFF5EE"},
          {"name":"Sienna","hex":"#A0522D"},
          {"name":"Silver","hex":"#C0C0C0"},
          {"name":"SkyBlue","hex":"#87CEEB"},
          {"name":"SlateBlue","hex":"#6A5ACD"},
          {"name":"SlateGray","hex":"#708090"},
          {"name":"SlateGrey","hex":"#708090"},
          {"name":"Snow","hex":"#FFFAFA"},
          {"name":"SpringGreen","hex":"#00FF7F"},
          {"name":"SteelBlue","hex":"#4682B4"},
          {"name":"Tan","hex":"#D2B48C"},
          {"name":"Teal","hex":"#008080"},
          {"name":"Thistle","hex":"#D8BFD8"},
          {"name":"Tomato","hex":"#FF6347"},
          {"name":"Turquoise","hex":"#40E0D0"},
          {"name":"Violet","hex":"#EE82EE"},
          {"name":"Wheat","hex":"#F5DEB3"},
          {"name":"White","hex":"#FFFFFF"},
          {"name":"WhiteSmoke","hex":"#F5F5F5"},
          {"name":"Yellow","hex":"#FFFF00"},
          {"name":"YellowGreen","hex":"#9ACD32"}
          ]

          let placeholderX3 = "Select an Option"
          if (this.state.language === 'English'){placeholderX3 = "Select an Option"}
          if (this.state.language === 'French'){placeholderX3 = "Sélectionner une Option"}
          if (this.state.language === 'Spanish'){placeholderX3 = "Seleccione una Opción"}
          if (this.state.language === 'Portuguese'){placeholderX3 = "Selecione uma Opção"}
          if (this.state.language === 'Swedish'){placeholderX3 = "Välj ett Alternativ"}
          if (this.state.language === 'Netherlands'){placeholderX3 = "Kies een Optie"}
          if (this.state.language === 'Russian'){placeholderX3 = "Выберите опцию"}
          if (this.state.language === 'Japanese'){placeholderX3 = "オプションを選択"}
          if (this.state.language === 'Chinese'){placeholderX3 = "选择一个选项"}
          if (this.state.language === 'German'){placeholderX3 = "Wähle eine Option"}
          if (this.state.language === 'Italian'){placeholderX3 = "Seleziona un'opzione"}

          return (
            <React.Fragment>
              <ListGroup flush>
                <ListGroupItem className="p-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}} >
                    {titleX} {this.props.opcionMasterFile}
                    {bOtro ?
                      <Row>
                         <Col>
                             <Row form style={{fontSize:13}}>
                               <Col md="2">
                                   <label htmlFor="Code"><FormattedMessage id="configuration.code" /> (*)</label>
                                   <FormInput
                                     id="Code"
                                     style={ this.props.colorDark ? { color: 'white', backgroundColor:'#111f2c'} : {color: 'black'}}
                                     innerRef={this.c01MasterFile}
                                     placeholder="Code"
                                     disabled={this.props.modifying}
                                   />
                               </Col>
                               <Col md="8">
                                   <label htmlFor="Description"><FormattedMessage id="configuration.description" /> (*)</label>
                                   <FormInput
                                     id="Description"
                                     style={ this.props.colorDark ? { color: 'white', backgroundColor:'#111f2c'} : {color: 'black'}}
                                     innerRef={this.c02MasterFile}
                                     placeholder="Description"
                                   />
                                </Col>
                                <Col md="1"></Col>
                             </Row>
                         </Col>
                       </Row>
                    : null }
                    {bRole ?
                      <React.Fragment>
                        <Row>
                          <Col>
                              <Row form style={{fontSize:13}}>
                                <Col md="2">
                                    <label htmlFor="Code"><FormattedMessage id="configuration.code" /> (*)</label>
                                    <FormInput
                                      id="Code"
                                      style={ this.props.colorDark ? { color: 'white', backgroundColor:'#111f2c'} : {color: 'black'}}
                                      innerRef={this.c01MasterFile}
                                      placeholder="Code"
                                      disabled={this.props.modifying}
                                    />
                                </Col>
                                <Col md="8">
                                    <label htmlFor="Description"><FormattedMessage id="configuration.description" /> (*)</label>
                                    <FormInput
                                      id="Description"
                                      style={ this.props.colorDark ? { color: 'white', backgroundColor:'#111f2c'} : {color: 'black'}}
                                      innerRef={this.c02MasterFile}
                                      placeholder="Description"
                                    />
                                 </Col>
                                 <Col md="1"></Col>
                              </Row>
                          </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                        {!this.props.modifying ?
                          <Row>
                            <Col>
                              <Card small style={ this.props.colorDark ? { backgroundColor:'#0b151d', fontSize:12} : {fontSize:12}} >
                                <ListGroup>
                                  <ListGroupItem style={ this.props.colorDark ? { backgroundColor:'#0b151d', fontSize:12} : {fontSize:12}}>
                                    <Form style={ this.props.colorDark ? { backgroundColor:'#0b151d', fontSize:12} : {fontSize:12}}>
                                      <Row form>
                                          <Col md="1">
                                             <FormSelect
                                                id="Level"
                                                style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {backgroundColor:"#F0EFEC"}}
                                                innerRef={this.c05MasterFile}
                                                placeholder=""
                                                onChange={e=>this.handleChangeLevel(e)}
                                                disabled={true}
                                             >
                                               <option value="13" selected>13</option>
                                             </FormSelect>
                                          </Col>
                                          <Col md="1"></Col>
                                          <Col md="9">
                                             <FormCheckbox
                                                  inline
                                                  style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}
                                                  checked={this.state.c01Role}
                                                  onChange={e=>this.handleChange(e, "c01Role")}
                                                  disabled={true}
                                              >
                                                  <FormattedMessage id="tab.ingresa" />
                                              </FormCheckbox>
                                              <FormCheckbox
                                                  inline
                                                  style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}
                                                  checked={this.state.c02Role}
                                                  onChange={e=>this.handleChange(e, "c02Role")}
                                                  disabled={true}
                                              >
                                                  <FormattedMessage id="tab.revisa" />
                                              </FormCheckbox>
                                              <FormCheckbox
                                                  inline
                                                  style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}
                                                  checked={this.state.c03Role}
                                                  onChange={e=>this.handleChange(e, "c03Role")}
                                                  disabled={true}
                                              >
                                                  <FormattedMessage id="tab.agregaobservacion" />
                                              </FormCheckbox>
                                              <FormCheckbox
                                                  inline
                                                  style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}
                                                  checked={this.state.c04Role}
                                                  onChange={e=>this.handleChange(e, "c04Role")}
                                                  disabled={true}
                                              >
                                                  <FormattedMessage id="tab.autoriza" />
                                              </FormCheckbox>
                                              <FormCheckbox
                                                  inline
                                                  style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}
                                                  checked={this.state.c06Role}
                                                  onChange={e=>this.handleChange(e, "c06Role")}
                                                  disabled={false}
                                              >
                                                  <FormattedMessage id="tab.visualiza" />
                                              </FormCheckbox>
                                              <FormCheckbox
                                                  inline
                                                  style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}
                                                  checked={this.state.c07Role}
                                                  onChange={e=>this.handleChange(e, "c07Role")}
                                                  disabled={true}
                                              >
                                                  <FormattedMessage id="tab.cierra" />
                                              </FormCheckbox>
                                           </Col>
                                           <Col md="1"></Col>
                                      </Row>
                                  </Form>
                                </ListGroupItem>
                              </ListGroup>
                            </Card>
                          </Col>
                        </Row>
                      : null }
                    </React.Fragment>
                  : null }

                  {bTag ?
                    <React.Fragment>
                      <Row>
                        <Col>
                            <Row form style={{fontSize:13}}>
                              <Col md="3">
                                  <label htmlFor="Code"><FormattedMessage id="configuration.description" /> (*)</label>
                                  <FormInput
                                    id="Code"
                                    style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'orange'} : {}}
                                    innerRef={this.c01MasterFile}
                                    placeholder="Name Tag"
                                    disabled={this.props.modifying}
                                  />
                              </Col>
                              <Col md="2">
                                  <label htmlFor="Description"><FormattedMessage id="configuration.colortag" /> (*)</label>
                                  <FormSelect
                                      id="Description"
                                      style={ this.props.colorDark ? { backgroundColor:'#111f2c', color:'white'} : {}}
                                      innerRef={this.c02MasterFile}
                                      placeholder="Select an Option"
                                      onChange={e=>this.handleChangeColour(e)}
                                    >
                                    <option value={``} selected >{placeholderX3}</option>
                                    {jsonColours.map((todo, key) => {
                                        return (
                                          <option style={{ color:`${todo.hex}`}} key={key} value={`${todo.name}#$%${todo.hex}`}>{todo.name}</option>
                                        )
                                    })}
                                  </FormSelect>
                               </Col>
                               <Col md="2">
                                   <label htmlFor="Description"><FormattedMessage id="configuration.colorfont" /> (*)</label>
                                   <FormSelect
                                       id="Description"
                                       style={ this.props.colorDark ? { backgroundColor:'#111f2c', color:'white'} : {}}
                                       innerRef={this.c03MasterFile}
                                       placeholder="Select an Option"
                                       onChange={e=>this.handleChangeColourLetter(e)}
                                     >
                                     <option value={``} selected >{placeholderX3}</option>
                                     <option style={{color:'maroon'}} value={`${'maroon'}`}>Maroon</option>
                                     <option style={{color:'darkred'}} value={`${'darkred'}`}>Dark Red</option>
                                     <option style={{color:'brown'}} value={`${'brown'}`}>Brown</option>
                                     <option style={{color:'firebrick'}} value={`${'firebrick'}`}>Firebrick</option>
                                     <option style={{color:'crimson'}} value={`${'crimson'}`}>Crimson</option>
                                     <option style={{color:'red'}} value={`${'red'}`}>Red</option>
                                     <option style={{color:'tomato'}} value={`${'tomato'}`}>Tomato</option>
                                     <option style={{color:'coral'}} value={`${'coral'}`}>Coral</option>
                                     <option style={{color:'indianred'}} value={`${'indianred'}`}>Maroon</option>
                                     <option style={{color:'salmon'}} value={`${'salmon'}`}>Salmon</option>
                                     <option style={{color:'orange'}} value={`${'orange'}`}>Orange</option>
                                     <option style={{color:'gold'}} value={`${'gold'}`}>Gold</option>
                                     <option style={{color:'khaki'}} value={`${'khaki'}`}>Khaki</option>
                                     <option style={{color:'olive'}} value={`${'olive'}`}>Olive</option>
                                     <option style={{color:'yellow'}} value={`${'yellow'}`}>Yellow</option>
                                     <option style={{color:'darkgreen'}} value={`${'darkgreen'}`}>Dark Green</option>
                                     <option style={{color:'green'}} value={`${'green'}`}>Green</option>
                                     <option style={{color:'lime'}} value={`${'lime'}`}>Lime</option>
                                     <option style={{color:'teal'}} value={`${'teal'}`}>Teal</option>
                                     <option style={{color:'aqua'}} value={`${'aqua'}`}>Aqua</option>
                                     <option style={{color:'cyan'}} value={`${'cyan'}`}>Cyan</option>
                                     <option style={{color:'turquoise'}} value={`${'turquoise'}`}>Turquoise</option>
                                     <option style={{color:'navy'}} value={`${'navy'}`}>Navy</option>
                                     <option style={{color:'darkblue'}} value={`${'darkblue'}`}>Dark Blue</option>
                                     <option style={{color:'blue'}} value={`${'blue'}`}>Blue</option>
                                     <option style={{color:'indigo'}} value={`${'indigo'}`}>Indigo</option>
                                     <option style={{color:'purple'}} value={`${'purple'}`}>Purple</option>
                                     <option style={{color:'thistle'}} value={`${'thistle'}`}>Thistle</option>
                                     <option style={{color:'plum'}} value={`${'plum'}`}>Plum</option>
                                     <option style={{color:'violet'}} value={`${'violet'}`}>Violet</option>
                                     <option style={{color:'orchid'}} value={`${'orchid'}`}>Orchid</option>
                                     <option style={{color:'pink'}} value={`${'pink'}`}>Pink</option>
                                     <option style={{color:'beige'}} value={`${'beige'}`}>Beige</option>
                                     <option style={{color:'bisque'}} value={`${'bisque'}`}>Bisque</option>
                                     <option style={{color:'wheat'}} value={`${'wheat'}`}>Wheat</option>
                                     <option style={{color:'sienna'}} value={`${'sienna'}`}>Sienna</option>
                                     <option style={{color:'chocolate'}} value={`${'chocolate'}`}>Chocolate</option>
                                     <option style={{color:'peru'}} value={`${'peru'}`}>Peru</option>
                                     <option style={{color:'tan'}} value={`${'tan'}`}>Tan</option>
                                     <option style={{color:'moccasin'}} value={`${'moccasin'}`}>Moccasin</option>
                                     <option style={{color:'linen'}} value={`${'linen'}`}>Linen</option>
                                     <option style={{color:'lavender'}} value={`${'lavender'}`}>Lavender</option>
                                     <option style={{color:'honeydew'}} value={`${'honeydew'}`}>Honeydew</option>
                                     <option style={{color:'ivory'}} value={`${'ivory'}`}>Ivory</option>
                                     <option style={{color:'azure'}} value={`${'azure'}`}>Azure</option>
                                     <option style={{color:'snow'}} value={`${'snow'}`}>Snow</option>
                                     <option style={{color:'black'}} value={`${'black'}`}>Black</option>
                                     <option style={{color:'grey'}} value={`${'grey'}`}>Grey</option>
                                     <option style={{color:'darkgrey'}} value={`${'darkgrey'}`}>Dark Grey</option>
                                     <option style={{color:'silver'}} value={`${'silver'}`}>Silver</option>
                                     <option style={{color:'gainsboro'}} value={`${'gainsboro'}`}>Gainsboro</option>
                                     <option style={{color:'white'}} value={`${'white'}`}>White</option>
                                   </FormSelect>
                                </Col>
                               <Col md="2">
                                <label>&nbsp;</label>
                                <Card small style={{ fontSize: 22, backgroundColor: this.state.backgroundColor, color: this.state.c03MasterFileColourLetterX }}>&nbsp;&nbsp;{this.state.c02MasterFileColourX}</Card>
                               </Col>

                            </Row>
                        </Col>
                      </Row>
                    </React.Fragment>
                  : null }
                  <Row>&nbsp;</Row>
                  <Row>
                    <Col md="1">
                      {!this.props.modifying ?
                         <Button pill theme="warning" onClick={e=>this.newMasterFile(e)}>---<FormattedMessage id="configuration.add" />---</Button>
                      :
                         <Button pill theme="warning" onClick={e=>this.newMasterFile(e)}>---<FormattedMessage id="configuration.modify" />---</Button>
                      }
                    </Col>
                  </Row>
                  <Row form>
                      {error ?
                           <div className="font-weight-bold alert alert-danger text-center mt-4 ">
                                <FormattedMessage id="configuration.allfieldsarerequired" />
                           </div>
                           : ''
                      }
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </React.Fragment>
           );
     }
}

export default NewMasterFileDeliverable;
