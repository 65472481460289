//React
import React, { Component } from 'react';

import { Container, Card, CardHeader, CardImg, CardBody, Button, Form, Row, Col, FormCheckbox, FormRadio, FormInput, FormSelect, FormTextarea, DatePicker, ListGroup, ListGroupItem } from "shards-react";
import { Table } from 'reactstrap';
import {  Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';

import DateFnsUtils from "@date-io/date-fns";
import { es } from "date-fns/locale";

import axios from 'axios';

import uuid from 'uuid';

import { UserSession, AppConfig } from '@stacks/auth';
import { Storage } from '@stacks/storage'


import { FormattedMessage } from 'react-intl';

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB, deleteFileDB} from '../../actions/mongoDBActions'

import ContractLoader from "../contracts/ContractLoader";

import { MAINNET_STACKS_API_URL,
         TESTNET_STACKS_API_URL } from '../../config.js'

import CloseReporteTurno from "./CloseReporteTurno";

import TimerMixin from 'react-timer-mixin'

import ViewerPdfReporteTurno from "../pdf/ViewerPdfReporteTurno";

import { CSVLink, CSVDownload } from "react-csv";
import JsonFormatter from 'react-json-formatter';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { sha256 } from 'js-sha256';


function Confirmacion(props) {
   return (
       <Dialog
           open={props.dialogOpen}
           onClose={() => props.handleDialog('cancel')}
           aria-labelledby="alert-dialog-title"
           aria-describedby="alert-dialog-description"
       >
           <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
           <DialogContent>
               <DialogContentText id="alert-dialog-description">
                   {props.dialogMessage}
               </DialogContentText>
           </DialogContent>
           <DialogActions>
               <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', 0)} theme="danger" autoFocus>
                   <FormattedMessage id="alert.cancel" />
               </Button>
               <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm', 0)} theme="success">
                   <FormattedMessage id="alert.confirm" />
               </Button>
           </DialogActions>
       </Dialog>
   );
}

function Confirmacion2(props) {
   return (
       <Dialog
           open={props.dialogOpen}
           onClose={() => props.handleDialog('cancel')}
           aria-labelledby="alert-dialog-title"
           aria-describedby="alert-dialog-description"
       >
           <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
           <DialogContent>
               <DialogContentText id="alert-dialog-description">
                   {props.dialogMessage}
               </DialogContentText>
           </DialogContent>
           <DialogActions>
               <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', 0)} theme="danger" autoFocus>
                   <FormattedMessage id="alert.cancel" />
               </Button>
               <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm', 0)} theme="success">
                   Continuar
               </Button>
           </DialogActions>
       </Dialog>
   );
}

function Observaciones(props) {
   return (
       <Dialog
           open={props.dialogOpen}
           onClose={() => props.handleDialog('cancel')}
           aria-labelledby="alert-dialog-title"
           aria-describedby="alert-dialog-description"
       >
           <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
           <DialogContent>
               <DialogContentText id="alert-dialog-description">
                   {props.dialogMessage}
                   <FormTextarea
                     size="sm"
                     style={{fontSize:13, width: '500px', height: '300px'}}
                     id="observaciones"
                     value={props.cReporteTurnoObservacion}
                     onChange={e=>props.handleChangeObservacion(e, props.opcionObservacion)}
                     placeholder=""
                     disabled={props.disabledX}
                   />
               </DialogContentText>
           </DialogContent>
           <DialogActions>
               <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', props.opcionObservacion)} theme="danger" autoFocus>
                   <FormattedMessage id="alert.cancel" />
               </Button>
               <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm', props.opcionObservacion)} theme="success" disabled={props.disabledX}>
                   <FormattedMessage id="alert.confirm" />
               </Button>
           </DialogActions>
       </Dialog>
   );
}

function Versiones(props) {
   return (
       <Dialog
           open={props.dialogOpen}
           onClose={() => props.handleDialog('cancel')}
           aria-labelledby="alert-dialog-title"
           aria-describedby="alert-dialog-description"
           fullWidth={true}
           maxWidth="md"
       >
           <DialogTitle id="alert-dialog-title">{props.dialogTitle} </DialogTitle>
           <DialogContent>
               <DialogContentText id="alert-dialog-description">
                   <div style={{ color: 'red', fontSize:15 }}>{props.dialogMessage} {'  '} {props.fecha}</div>
                   <Container fluid className="px-0">
                       <Card theme="warning" >
                         <table>
                           <tbody>
                             <tr>
                               <td>
                                 &nbsp;&nbsp;<div className="fa fa-info"></div>
                               </td>
                               <td>
                                  &nbsp;&nbsp;TURNO DIA
                               </td>
                             </tr>
                           </tbody>
                         </table>
                       </Card>
                   </Container>
                   <Table size="sm" className="text-center" responsive hover striped bordered style={{ color: 'black', fontSize:11 }}>
                       <thead>
                         <tr style={{ textAlign:"center", color: 'blue', fontSize:15 }}>
                           <th>Usuario</th>
                           <th>Nombre</th>
                           <th>Rol</th>
                           <th>Fecha Digitación</th>
                           <th>F.Sup</th>
                           <th>F.JT</th>
                           <th>F.AdmMD</th>
                           <th>F.CG</th>
                           <th>F.AdmAA</th>
                           <th>Cierre</th>
                         </tr>
                       </thead>
                       <tbody>
                           {props.jsonVersionesTurnoDiaX.map((todo,i) => {
                             if ((props.existeFirmaDiaX && todo.firma1) || !props.existeFirmaDiaX || props.jsonVersionesTurnoDiaX.length === 1){
                               if (todo.cierre === true){
                                 todo.firma1 = true
                                 todo.firma2 = true
                                 todo.firma3 = true
                                 todo.firma4 = true
                                 todo.firma5 = true
                               }
                               return (
                                 <tr>
                                   <td style={{ width: "15%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                       <FormRadio
                                            inline
                                            checked={props.checkTurnoDiaX[i]}
                                            onChange={e=>props.handleChangeCheckTurno(e, todo.id, 'dia', i, todo.token)}
                                            disabled={false}
                                            placeholder=""
                                        >
                                            {todo.id}
                                        </FormRadio>
                                   </td>
                                   <td style={{ width: "15%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>{todo.name}</td>
                                   <td style={{ width: "15%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>{todo.rol}</td>
                                   <td style={{ width: "15%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>{todo.fecha}</td>
                                   <td style={{ width: "5%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                       <FormCheckbox
                                            inline
                                            checked={todo.firma1}
                                            disabled={false}
                                            placeholder=""
                                        >
                                        </FormCheckbox>
                                   </td>
                                   <td style={{ width: "5%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                       <FormCheckbox
                                            inline
                                            checked={todo.firma2}
                                            disabled={false}
                                            placeholder=""
                                        >
                                        </FormCheckbox>
                                   </td>
                                   <td style={{ width: "5%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                       <FormCheckbox
                                            inline
                                            checked={todo.firma3}
                                            disabled={false}
                                            placeholder=""
                                        >
                                        </FormCheckbox>
                                   </td>
                                   <td style={{ width: "5%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                       <FormCheckbox
                                            inline
                                            checked={todo.firma4}
                                            disabled={false}
                                            placeholder=""
                                        >
                                        </FormCheckbox>
                                   </td>
                                   <td style={{ width: "5%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                       <FormCheckbox
                                            inline
                                            checked={todo.firma5}
                                            disabled={false}
                                            placeholder=""
                                        >
                                        </FormCheckbox>
                                   </td>
                                   <td style={{ width: "5%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                       <FormCheckbox
                                            inline
                                            checked={todo.cierre}
                                            disabled={false}
                                            placeholder=""
                                        >
                                        </FormCheckbox>
                                   </td>
                                 </tr>
                               )
                             }
                           })}
                       </tbody>
                   </Table>
                   <Container fluid className="px-0">
                       <Card theme="warning" >
                         <table>
                           <tbody>
                             <tr>
                               <td>
                                 &nbsp;&nbsp;<div className="fa fa-info"></div>
                               </td>
                               <td>
                                  &nbsp;&nbsp;TURNO NOCHE
                               </td>
                             </tr>
                           </tbody>
                         </table>
                       </Card>
                   </Container>
                   <Table size="sm" className="text-center" responsive hover striped bordered style={{ color: 'black', fontSize:11 }}>
                       <thead>
                         <tr style={{ textAlign:"center", color: 'blue', fontSize:15 }}>
                           <th>Usuario</th>
                           <th>Nombre</th>
                           <th>Rol</th>
                           <th>Fecha Digitación</th>
                           <th>F.Sup</th>
                           <th>F.JT</th>
                           <th>F.AdmMD</th>
                           <th>F.CG</th>
                           <th>F.AdmAA</th>
                           <th>Cierre</th>
                         </tr>
                       </thead>
                       <tbody>
                           {props.jsonVersionesTurnoNocheX.map((todo,i) => {
                             if ((props.existeFirmaNocheX && todo.firma1) || !props.existeFirmaNocheX || props.jsonVersionesTurnoNocheX.length === 1){
                               if (todo.cierre === true){
                                 todo.firma1 = true
                                 todo.firma2 = true
                                 todo.firma3 = true
                                 todo.firma4 = true
                                 todo.firma5 = true
                               }
                               return (
                                 <tr>
                                   <td style={{ width: "15%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                     <FormRadio
                                          inline
                                          checked={props.checkTurnoNocheX[i]}
                                          onChange={e=>props.handleChangeCheckTurno(e, todo.id, 'noche', i, todo.token)}
                                          disabled={false}
                                          placeholder=""
                                      >
                                          {todo.id}
                                      </FormRadio>
                                   </td>
                                   <td style={{ width: "15%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>{todo.name}</td>
                                   <td style={{ width: "15%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>{todo.rol}</td>
                                   <td style={{ width: "15%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>{todo.fecha}</td>
                                   <td style={{ width: "5%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                       <FormCheckbox
                                            inline
                                            checked={todo.firma1}
                                            disabled={false}
                                            placeholder=""
                                        >
                                        </FormCheckbox>
                                   </td>
                                   <td style={{ width: "5%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                       <FormCheckbox
                                            inline
                                            checked={todo.firma2}
                                            disabled={false}
                                            placeholder=""
                                        >
                                        </FormCheckbox>
                                   </td>
                                   <td style={{ width: "5%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                       <FormCheckbox
                                            inline
                                            checked={todo.firma3}
                                            disabled={false}
                                            placeholder=""
                                        >
                                        </FormCheckbox>
                                   </td>
                                   <td style={{ width: "5%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                       <FormCheckbox
                                            inline
                                            checked={todo.firma4}
                                            disabled={false}
                                            placeholder=""
                                        >
                                        </FormCheckbox>
                                   </td>
                                   <td style={{ width: "5%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                       <FormCheckbox
                                            inline
                                            checked={todo.firma5}
                                            disabled={false}
                                            placeholder=""
                                        >
                                        </FormCheckbox>
                                   </td>
                                   <td style={{ width: "5%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                       <FormCheckbox
                                            inline
                                            checked={todo.cierre}
                                            disabled={false}
                                            placeholder=""
                                        >
                                        </FormCheckbox>
                                   </td>
                                 </tr>
                               )
                             }
                           })}
                       </tbody>
                   </Table>

               </DialogContentText>
           </DialogContent>
             <DialogActions>
               <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', null)} theme="danger" autoFocus>
                   <FormattedMessage id="alert.cancel" />
               </Button>
               <Button onClick={() => props.handleDialogDisplay(props.dialogTitle, props.idTurnoDiaX, props.idTurnoNocheX, props.tokenMayorDiaX, props.tokenMayorNocheX)} theme="success" autoFocus>
                   <FormattedMessage id="alert.accept" />
               </Button>
             </DialogActions>
       </Dialog>
   );
}

function GenerarPdf(props) {
   return (
       <Dialog
           open={props.dialogOpen}
           onClose={() => props.handleDialog('cancel')}
           aria-labelledby="alert-dialog-title"
           aria-describedby="alert-dialog-description"
           fullWidth={true}
           maxWidth="lg"
       >
           <DialogTitle id="alert-dialog-title">{props.dialogTitle} </DialogTitle>
           <DialogContent>
               <DialogContentText id="alert-dialog-description">
                   <Container fluid className="px-0">
                       <Card theme="warning" >
                         <table>
                           <tbody>
                             <tr>
                               <td>
                                 &nbsp;&nbsp;<div className="fa fa-info"></div>
                               </td>
                               <td>
                                  &nbsp;&nbsp;SELECCIONE PDF A GENERAR
                               </td>
                             </tr>
                           </tbody>
                         </table>
                       </Card>
                   </Container>
                   <Table size="sm" className="text-center" responsive hover striped bordered style={{ color: 'black', fontSize:11 }}>
                       <tbody>
                         <tr>
                           <td style={{ width: "15%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                             <FormRadio
                                  inline
                                  checked={props.checkCertificadoX}
                                  onChange={e=>props.handleChangeCheckPdf(e, 1)}
                                  disabled={false}
                                  placeholder=""
                              >
                                  Certificado
                              </FormRadio>
                            </td>
                        </tr>
                        <tr>
                          <td style={{ width: "15%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                              <FormRadio
                                   inline
                                   checked={props.checkReporteTurnoX}
                                   onChange={e=>props.handleChangeCheckPdf(e, 2)}
                                   disabled={false}
                                   placeholder=""
                               >
                                   Reporte de Turno
                               </FormRadio>
                           </td>
                         </tr>
                         <tr>
                           <td style={{ width: "15%", textAlign:"center", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                             {props.displayPdfX ?
                               <ViewerPdfReporteTurno typeContract={props.typeContract}
                                                      numberContract={props.numberContract}
                                                      colorDark={props.colorDark}
                                                      displayCertificate={props.checkCertificadoX}
                                                      cReporteTurnoNameFirmante1={props.cReporteTurnoNameFirmante1}
                                                      cReporteTurnoIdFirmante1={props.cReporteTurnoIdFirmante1}
                                                      cReporteTurnoPublicKeyFirmante1={props.cReporteTurnoPublicKeyFirmante1}
                                                      cReporteTurnoDateTimeFirmante1={props.cReporteTurnoDateTimeFirmante1}
                                                      cReporteTurnoSignatureFirmante1={props.cReporteTurnoSignatureFirmante1}
                                                      cReporteTurnotrimmedDataURLFirmante1={props.cReporteTurnotrimmedDataURLFirmante1}
                                                      cReporteTurnoNameFirmante2={props.cReporteTurnoNameFirmante2}
                                                      cReporteTurnoIdFirmante2={props.cReporteTurnoIdFirmante2}
                                                      cReporteTurnoPublicKeyFirmante2={props.cReporteTurnoPublicKeyFirmante2}
                                                      cReporteTurnoDateTimeFirmante2={props.cReporteTurnoDateTimeFirmante2}
                                                      cReporteTurnoSignatureFirmante2={props.cReporteTurnoSignatureFirmante2}
                                                      cReporteTurnotrimmedDataURLFirmante2={props.cReporteTurnotrimmedDataURLFirmante2}
                                                      cReporteTurnoNameFirmante3={props.cReporteTurnoNameFirmante3}
                                                      cReporteTurnoIdFirmante3={props.cReporteTurnoIdFirmante3}
                                                      cReporteTurnoPublicKeyFirmante3={props.cReporteTurnoPublicKeyFirmante3}
                                                      cReporteTurnoDateTimeFirmante3={props.cReporteTurnoDateTimeFirmante3}
                                                      cReporteTurnoSignatureFirmante3={props.cReporteTurnoSignatureFirmante3}
                                                      cReporteTurnotrimmedDataURLFirmante3={props.cReporteTurnotrimmedDataURLFirmante3}
                                                      cReporteTurnoNameFirmante4={props.cReporteTurnoNameFirmante4}
                                                      cReporteTurnoIdFirmante4={props.cReporteTurnoIdFirmante4}
                                                      cReporteTurnoPublicKeyFirmante4={props.cReporteTurnoPublicKeyFirmante4}
                                                      cReporteTurnoDateTimeFirmante4={props.cReporteTurnoDateTimeFirmante4}
                                                      cReporteTurnoSignatureFirmante4={props.cReporteTurnoSignatureFirmante4}
                                                      cReporteTurnotrimmedDataURLFirmante4={props.cReporteTurnotrimmedDataURLFirmante4}
                                                      cReporteTurnoNameFirmante5={props.cReporteTurnoNameFirmante5}
                                                      cReporteTurnoIdFirmante5={props.cReporteTurnoIdFirmante5}
                                                      cReporteTurnoPublicKeyFirmante5={props.cReporteTurnoPublicKeyFirmante5}
                                                      cReporteTurnoDateTimeFirmante5={props.cReporteTurnoDateTimeFirmante5}
                                                      cReporteTurnoSignatureFirmante5={props.cReporteTurnoSignatureFirmante5}
                                                      cReporteTurnotrimmedDataURLFirmante5={props.cReporteTurnotrimmedDataURLFirmante5}
                                                      cReporteTurnoNameFirmante6={props.cReporteTurnoNameFirmante6}
                                                      cReporteTurnoIdFirmante6={props.cReporteTurnoIdFirmante6}
                                                      cReporteTurnoPublicKeyFirmante6={props.cReporteTurnoPublicKeyFirmante6}
                                                      cReporteTurnoDateTimeFirmante6={props.cReporteTurnoDateTimeFirmante6}
                                                      cReporteTurnoSignatureFirmante6={props.cReporteTurnoSignatureFirmante6}
                                                      cReporteTurnotrimmedDataURLFirmante6={props.cReporteTurnotrimmedDataURLFirmante6}
                                                      cReporteTurnoNameFirmante7={props.cReporteTurnoNameFirmante6}
                                                      cReporteTurnoIdFirmante7={props.cReporteTurnoIdFirmante7}
                                                      cReporteTurnoPublicKeyFirmante7={props.cReporteTurnoPublicKeyFirmante7}
                                                      cReporteTurnoDateTimeFirmante7={props.cReporteTurnoDateTimeFirmante7}
                                                      cReporteTurnoSignatureFirmante7={props.cReporteTurnoSignatureFirmante7}
                                                      cReporteTurnotrimmedDataURLFirmante7={props.cReporteTurnotrimmedDataURLFirmante7}
                                                      fecha={props.fecha}
                                                      workordercliente={props.workordercliente}
                                                      workordercampaña={props.workordercampaña}
                                                      workordersondaje={props.workordersondaje}
                                                      workordertipoperforacion={props.workordertipoperforacion}
                                                      workorderfase={props.workorderfase}
                                                      workordernumerocontrato={props.workordernumerocontrato}
                                                      workorderdeclinacion={props.workorderdeclinacion}
                                                      pdfReporteTurnoDiaX={props.pdfReporteTurnoDiaX}
                                                      pdfReporteTurnoNocheX={props.pdfReporteTurnoNocheX}
                                                      consolidadoFondoX={props.consolidadoFondoX}
                                                      consolidadoX={props.consolidadoX}
                                                      workorderobservacion={props.workorderobservacion}
                                                      consolidadoFondoX2={props.consolidadoFondoX2}
                                  />
                             : null }
                           </td>
                         </tr>
                       </tbody>
                   </Table>
               </DialogContentText>
           </DialogContent>
             <DialogActions>
               <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', null)} theme="danger" autoFocus>
                   <FormattedMessage id="alert.cancel" />
               </Button>
               <Button onClick={() => props.handleDialogPdf(props.dialogTitle, props.checkCertificadoX, props.checkReporteTurnoX)} theme="success" autoFocus>
                   Generar
               </Button>
             </DialogActions>
       </Dialog>
   );
}

function Exportar(props) {
   return (
       <Dialog
           open={props.dialogOpen}
           onClose={() => props.handleDialog('cancel')}
           aria-labelledby="alert-dialog-title"
           aria-describedby="alert-dialog-description"
           fullWidth={true}
           maxWidth="md"
       >
           <DialogTitle id="alert-dialog-title">{props.dialogTitle} </DialogTitle>
           <DialogContent>
               <DialogContentText id="alert-dialog-description">
                   <Container fluid className="px-0">
                       <Card theme="warning" >
                         <table>
                           <tbody>
                             <tr>
                               <td>
                                 &nbsp;&nbsp;<div className="fa fa-info"></div>
                               </td>
                               <td>
                                  &nbsp;&nbsp;SELECCIONE FORMATO DE ARCHIVO A EXPORTAR
                               </td>
                             </tr>
                           </tbody>
                         </table>
                       </Card>
                   </Container>
                   <Table size="sm" className="text-center" responsive hover striped bordered style={{ color: 'black', fontSize:11 }}>
                       <tbody>
                         <tr>
                           <td style={{ width: "15%", textAlign:"left", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                             <FormRadio
                                  inline
                                  checked={props.checkExportJsonX}
                                  disabled={false}
                                  placeholder=""
                                  onChange={e=>props.handleChangeCheckExport(e, 1)}
                              >
                                  Formato JSON (archivo plano)
                              </FormRadio>
                            </td>
                        </tr>
                        <tr>
                          <td style={{ width: "15%", textAlign:"left", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                              <FormRadio
                                   inline
                                   checked={props.checkExportCsvX}
                                   disabled={false}
                                   placeholder=""
                                   onChange={e=>props.handleChangeCheckExport(e, 2)}
                               >
                                   Formato CSV (Excel)
                               </FormRadio>
                           </td>
                         </tr>
                         {props.checkExportCsvX ?
                           <tr>
                             <td style={{ width: "15%", textAlign:"left", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                 <FormRadio
                                      inline
                                      checked={props.checkExportCsvVerticalX}
                                      disabled={false}
                                      placeholder=""
                                      onChange={e=>props.handleChangeCheckExportCsv(e, 1)}
                                  >
                                      CSV Vertical
                                  </FormRadio>
                                  &nbsp;&nbsp;&nbsp;
                                  <FormRadio
                                       inline
                                       checked={props.checkExportCsvHorizontalX}
                                       disabled={false}
                                       placeholder=""
                                       onChange={e=>props.handleChangeCheckExportCsv(e, 2)}
                                   >
                                       CSV Horizontal
                                   </FormRadio>
                               </td>
                            </tr>
                         : null
                         }
                         <tr>
                           <td style={{ width: "15%", textAlign:"left", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                             {props.displayExportX ?
                               <>
                                 {props.checkExportCsvX ?
                                   <CSVLink data={props.csvData} filename={"Reporte_Turno_Digital_"+props.typeContract+"_"+props.numberContract+"_"+props.fecha+".csv"}>Descargar Archivo CSV</CSVLink>
                                 :
                                   <JsonFormatter json={props.jsonData} tabWith={4} jsonStyle={props.jsonStyle} />
                                 }
                               </>
                             : null }
                           </td>
                         </tr>

                       </tbody>
                   </Table>

               </DialogContentText>
           </DialogContent>
             <DialogActions>
               <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', null)} theme="danger" autoFocus>
                   <FormattedMessage id="alert.cancel" />
               </Button>
               <Button onClick={() => props.handleDialogExport(props.dialogTitle, props.checkExportJsonX, props.checkExportCsvX, props.checkExportCsvVerticalX, props.checkExportCsvHorizontalX)} theme="success" autoFocus>
                   Generar
               </Button>
               {props.checkExportJsonX ?
                 <>
                   <strong style={{cursor: 'pointer'}}  onClick={()=>props.copyToClipBoard()}>
                       { props.colorDark ?
                          <img src="images/copypasteGrey.png" weight="35" height="35" alt=""/>
                       :
                          <img src="images/copypaste.png" weight="35" height="35" alt=""/>
                       }
                   </strong>
                   {props.copied ?
                       <div> Archivo JSON copiado al portapapeles </div>
                   : null
                   }
                 </>
               : null
               }
               {props.displayExportX ?
                 <>
                   {props.checkExportCsvX ?
                     <CSVLink data={props.csvData} filename={"Reporte_Turno_Digital_"+props.typeContract+"_"+props.numberContract+"_"+props.fecha+".csv"}> Descargar Archivo CSV</CSVLink>
                   :
                     null
                   }
                 </>
               : null }

             </DialogActions>
       </Dialog>
   );
}

function EstadoPago(props) {
   return (
       <Dialog
           open={props.dialogOpen}
           onClose={() => props.handleDialog('cancel')}
           aria-labelledby="alert-dialog-title"
           aria-describedby="alert-dialog-description"
       >
           <DialogTitle id="alert-dialog-title">{props.dialogTitle} </DialogTitle>
           <DialogContent>
               <DialogContentText id="alert-dialog-description">
                   <Container fluid className="px-0">
                       <Card theme="warning" >
                         <table>
                           <tbody>
                             <tr>
                               <td>
                                 &nbsp;&nbsp;<div className="fa fa-info"></div>
                               </td>
                               <td>
                                 {props.dialogMessage}
                               </td>
                             </tr>
                           </tbody>
                         </table>
                       </Card>
                   </Container>
                   <Table size="sm" className="text-center" responsive hover striped bordered style={{ color: 'black', fontSize:11 }}>
                       <tbody>
                         <tr>
                           <td style={{ width: "15%", textAlign:"left", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}>
                             <FormRadio
                                  inline
                                  checked={props.checkEstadoPagoX}
                                  disabled={false}
                                  placeholder=""
                              >
                                  Estado de Pago
                              </FormRadio>
                            </td>
                        </tr>
                       </tbody>
                   </Table>
               </DialogContentText>
           </DialogContent>
             <DialogActions>
               <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', null)} theme="danger" autoFocus>
                   <FormattedMessage id="alert.cancel" />
               </Button>
               <Button onClick={() => props.handleDialogEstadoPago(props.dialogTitle, props.checkEstadoPagoX)} theme="success" autoFocus>
                   Generar
               </Button>
             </DialogActions>
       </Dialog>
   );
}


function Error(props) {
  return (
      <Dialog
          open={props.dialogOpen}
          onClose={() => props.handleDialog('cancel')}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  {props.dialogMessage}
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', 0)} theme="danger" autoFocus>
                  <FormattedMessage id="alert.cancel" />
              </Button>
          </DialogActions>
      </Dialog>
  );
}

class ReporteTurnoDeliverable extends Component {

    constructor(props) {
        super(props);

        this.state = {
          userSession: new UserSession(new AppConfig()),
          username: '',
          user: '',
          fechaReporte: new Date(),
          isocode: 'en',
          dateFormatX: 'dd/MM/yyyy',
          language: '',
          nameBlockstack: '',
          networkUrlX: '',
          authorizersX:[],
          roleX:[],
          actividadesX:[],
          tipoperforacionX:[],
          sobrecarganaturalX: [],
          sobrecarganonaturalX: [],
          rocaX: [],
          rocaMuestraSecaX: [],
          rocaMuestraHumedaX: [],
          rocaMuestraHumedaMartilloX: [],
          estadopozoX: [],
          estadosondajeX: [],
          monitoreoX: [],
          sobrecargaX1: [],
          sobrecargaX2: [],
          sobrecargaX3: [],
          sobrecargaX4: [],
          sobrecargaX5: [],
          sobrecargaX6: [],
          sobrecargaX7: [],
          sobrecargaX8: [],
          sobrecargaX9: [],
          sobrecargaX10: [],
          sobrecargaX11: [],
          sobrecargaX12: [],
          sobrecargaX13: [],
          sobrecargaX14: [],
          sobrecargaX15: [],
          sobrecargaX16: [],
          sobrecargaX17: [],
          sobrecargaX18: [],
          sobrecargaX19: [],
          sobrecargaX20: [],
          sobrecargaX21: [],
          sobrecargaX22: [],
          sobrecargaX23: [],
          sobrecargaX24: [],
          sobrecargaX25: [],
          sobrecargaX26: [],
          sobrecargaX27: [],
          sobrecargaX28: [],
          sobrecargaX29: [],
          sobrecargaX30: [],
          sobrecargaX31: [],
          sobrecargaX32: [],
          sobrecargaX33: [],
          sobrecargaX34: [],
          sobrecargaX35: [],
          sobrecargaX36: [],
          sobrecargaX37: [],
          sobrecargaX38: [],
          sobrecargaX39: [],
          sobrecargaX40: [],
          sobrecargaX41: [],
          sobrecargaX42: [],
          sobrecargaX43: [],
          sobrecargaX44: [],
          sobrecargaX45: [],
          sobrecargaX46: [],
          sobrecargaX47: [],
          sobrecargaX48: [],
          herramientaX:[],
          perforacionX:[],
          diametroocupadoX: [],
          tipomuestraX: [],
          authIdX: '',
          authNameX: '',
          authName2X: '',
          authRoleX: '',
          authPublicKey: '',
          roleGenerateX: '',
          roleModifyX: '',
          roleSignatureX: '',
          roleCloseX: '',
          roleVisualizeX: '',
          roleLevelX: '',
          dialogOpen: false,
          dialogOpenError: false,
          dialogOpenPdf: false,
          dialogOpenExport: false,
          dialogOpenEstadoPago: false,
          dialogOpenVersion: false,
          dialogOpenObservaciones: false,
          dialogOpenNoHayDiaAnterior: false,
          dialogMessage: '',
          dialogTitle: '',
          dialogMessageError: '',
          dialogTitleError: '',
          confirmaSeguirNoHayDiaAnterior: true,
          displayDetalle1X: true,
          displayDetalle2X: true,
          displayDetalle3X: true,
          displayDetalle4X: true,
          displayDetalle5X: true,
          displayDetalle6X: true,
          displayDetalle7X: true,
          displayDetalle8X: true,
          displayDetalle9X: true,
          displayDetalle10X: true,
          displayDetalle11X: true,
          displayDetalle12X: true,
          displayDetalle13X: true,
          displayDetalle14X: true,
          displayDetalle15X: true,
          displayDetalle16X: true,
          displayDetalle17X: true,
          displayDetalle18X: true,
          displayDetalle19X: true,
          displayDetalle20X: true,
          displayDetalle21X: true,
          displayDetalle22X: true,
          displayDetalle23X: true,
          displayDetalle24X: true,
          displayDetalle25X: true,
          displayDetalle26X: true,
          displayDetalle27X: true,
          displayDetalle28X: true,
          displayDetalle29X: true,
          displayDetalle30X: true,
          displayDetalle31X: true,
          displayDetalle32X: true,
          displayDetalle33X: true,
          displayDetalle34X: true,
          displayDetalle35X: true,
          displayDetalle36X: true,
          displayDetalle37X: true,
          displayDetalle38X: true,
          displayDetalle39X: true,
          displayDetalle40X: true,
          displayDetalle41X: true,
          displayDetalle42X: true,
          displayDetalle43X: true,
          displayDetalle44X: true,
          displayDetalle45X: true,
          displayDetalle46X: true,
          displayDetalle47X: true,
          displayDetalle48X: true,
          folio: '',
          fecha: '',
          folioDiaAnterior: '',
          fechaDiaAnterior: '',
          fondoPozoDiaX: '',
          fondoPozoNocheX: '',
          bFondoPozoExiste: false,
          reporteTurnoX: [],
          eliminarTurnoDiaX:false,
          eliminarTurnoNocheX:false,
          pdfReporteTurnoDiaX: {},
          pdfReporteTurnoNocheX: {},
          cReporteTurnoObservacion: '',
          cReporteTurnoObservacionAsesorTurnoDia: '',
          cReporteTurnoObservacionAsesorTurnoNoche: '',
          cReporteTurnoObservacionFirmante1: '',
          cReporteTurnoObservacionFirmante2: '',
          cReporteTurnoObservacionFirmante3: '',
          cReporteTurnoObservacionFirmante4: '',
          cReporteTurnoObservacionFirmante5: '',
          cReporteTurnoNameFirmante1: '',
          cReporteTurnoIdFirmante1: '',
          cReporteTurnoPublicKeyFirmante1: '',
          cReporteTurnoDateTimeFirmante1: '',
          cReporteTurnoSignatureFirmante1: false,
          cReporteTurnoNameFirmante2: '',
          cReporteTurnoIdFirmante2: '',
          cReporteTurnoPublicKeyFirmante2: '',
          cReporteTurnoDateTimeFirmante2: '',
          cReporteTurnoSignatureFirmante2: false,
          cReporteTurnoNameFirmante3: '',
          cReporteTurnoIdFirmante3: '',
          cReporteTurnoPublicKeyFirmante3: '',
          cReporteTurnoDateTimeFirmante3: '',
          cReporteTurnoSignatureFirmante3: false,
          cReporteTurnoNameFirmante4: '',
          cReporteTurnoIdFirmante4: '',
          cReporteTurnoPublicKeyFirmante4: '',
          cReporteTurnoDateTimeFirmante4: '',
          cReporteTurnoSignatureFirmante4: false,
          cReporteTurnoNameFirmante5: '',
          cReporteTurnoIdFirmante5: '',
          cReporteTurnoPublicKeyFirmante5: '',
          cReporteTurnoDateTimeFirmante5: '',
          cReporteTurnoSignatureFirmante5: false,
          cReporteTurnoNameFirmante6: '',
          cReporteTurnoIdFirmante6: '',
          cReporteTurnoPublicKeyFirmante6: '',
          cReporteTurnoDateTimeFirmante6: '',
          cReporteTurnoSignatureFirmante6: false,
          cReporteTurnoNameFirmante7: '',
          cReporteTurnoIdFirmante7: '',
          cReporteTurnoPublicKeyFirmante7: '',
          cReporteTurnoDateTimeFirmante7: '',
          cReporteTurnoSignatureFirmante7: false,
          cReporteTurnotrimmedDataURLFirmante1: '',
          cReporteTurnotrimmedDataURLFirmante2: '',
          cReporteTurnotrimmedDataURLFirmante3: '',
          cReporteTurnotrimmedDataURLFirmante4: '',
          cReporteTurnotrimmedDataURLFirmante5: '',
          cReporteTurnotrimmedDataURLFirmante6: '',
          cReporteTurnotrimmedDataURLFirmante7: '',

          opcionObservacion: null,
          firma_1: false,
          firma_2: false,
          firma_3: false,
          firma_4: false,
          firma_5: false,
          firma_6: false,
          firma_7: false,
          guarda_1: false,
          elimina_1: false,
          cabecera_1: false,
          cabecera_2: false,
          cabecera_3: false,
          observacion_1: false,
          observacion_2: false,
          observacion_3: false,
          observacion_4: false,
          observacion_5: false,
          observacion_6: false,
          observacion_7: false,
          observacion_8: false,
          observacion_9: false,
          vReporteTurnoDia01: '',
          vReporteTurnoDia01_ID: '',
          vReporteTurnoDia04: '',
          vReporteTurnoDia04_ID: '',
          vReporteTurnoDia05: '',
          vReporteTurnoDia05_ID: '',
          vReporteTurnoNoche01: '',
          vReporteTurnoNoche01_ID: '',
          vReporteTurnoNoche04: '',
          vReporteTurnoNoche04_ID: '',
          vReporteTurnoNoche05: '',
          vReporteTurnoNoche05_ID: '',
          observacionDisabled: false,
          consolidadoX: 0,
          consolidadoFondoX: 0,
          consolidadoFondoOrigenX: 0,
          consolidadoFondoOrigenDiaX: 0,
          consolidadoFondoOrigenNocheX: 0,
          consolidadoFondoOrigenDiaLlenaX: 0,
          consolidadoFondoOrigenNocheLlenaX: 0,
          consolidadoRenderX: false,
          starDia1: false,
          starDia2: false,
          starDia3: false,
          starDia4: false,
          starDia5: false,
          starNoche1: false,
          starNoche2: false,
          starNoche3: false,
          starNoche4: false,
          starNoche5: false,
          starConsolidado1: false,
          starConsolidado2: false,
          starConsolidado3: false,
          starConsolidado4: false,
          starConsolidado5: false,
          displayLoader: false,
          workordercliente: '',
          workordercampaña: '',
          workordersondaje: '',
          workordertipoperforacion: '',
          workorderfase: '',
          workordernumerocontrato: '',
          workorderdeclinacion: '',
          workorderrecomendado: '',
          workordermetaturnodia: 0,
          workordermetaturnonoche: 0,
          workorderfecharealinicio: '',
          workorderobservacion: '',
          workorderfechainiciopozo: '',
          workorderfechaterminoperforacion: '',
          uuidX: uuid(),
          jsonVersionesTurnoDiaX: [],
          jsonVersionesTurnoNocheX: [],
          idTurnoDiaX: '',
          idTurnoNocheX: '',
          existeFirmaDiaX: false,
          existeFirmaNocheX: false,
          cierreReporteTurnoX: {},
          nuevoReporteTurnoX: true,
          fondoPozoTurnoDiaX: [],
          fondoPozoTurnoNocheX: [],
          userTurnoDiaX: '',
          userTurnoNocheX: '',
          userTurnoDiaXFondoPozo: '',
          userTurnoNocheFondoPozoX: '',
          linea01X: true,
          linea02X: false,
          linea03X: false,
          linea04X: false,
          linea05X: false,
          linea06X: false,
          linea07X: false,
          linea08X: false,
          linea09X: false,
          linea10X: false,
          linea11X: false,
          linea12X: false,
          linea13X: false,
          linea14X: false,
          linea15X: false,
          linea16X: false,
          linea17X: false,
          linea18X: false,
          linea19X: false,
          linea20X: false,
          linea21X: false,
          linea22X: false,
          linea23X: false,
          linea24X: false,
          linea25X: true,
          linea26X: false,
          linea27X: false,
          linea28X: false,
          linea29X: false,
          linea30X: false,
          linea31X: false,
          linea32X: false,
          linea33X: false,
          linea34X: false,
          linea35X: false,
          linea36X: false,
          linea37X: false,
          linea38X: false,
          linea39X: false,
          linea40X: false,
          linea41X: false,
          linea43X: false,
          linea43X: false,
          linea44X: false,
          linea45X: false,
          linea46X: false,
          linea47X: false,
          linea48X: false,
          checkCertificadoX: true,
          checkReporteTurnoX: false,
          checkExportCsvX:true,
          checkExportCsvVerticalX:true,
          checkExportCsvHorizontalX:false,
          checkExportJsonX:false,
          displayExportX: false,
          checkJsonX: false,
          checkCsvX: false,
          checkEstadoPagoX: true,
          displayPdfX: false,
          tokenMayorDiaX: 0,
          tokenMayorNocheX: 0,
          cancelVersion: false,
          csvData: [],
          jsonData: [],
          jsonDataSha256: '',
          copied: false,
          herramientaCodeX1: '',
          herramientaCodeX2: '',
          herramientaCodeX3: '',
          herramientaCodeX4: '',
          herramientaCodeX5: '',
          herramientaCodeX6: '',
          herramientaCodeX7: '',
          herramientaCodeX8: '',
          herramientaCodeX9: '',
          herramientaCodeX10: '',
          herramientaCodeX11: '',
          herramientaCodeX12: '',
          herramientaCodeX13: '',
          herramientaCodeX14: '',
          herramientaCodeX15: '',
          herramientaCodeX16: '',
          herramientaCodeX17: '',
          herramientaCodeX18: '',
          herramientaCodeX19: '',
          herramientaCodeX20: '',
          herramientaCodeX21: '',
          herramientaCodeX22: '',
          herramientaCodeX23: '',
          herramientaCodeX24: '',
          herramientaCodeX25: '',
          herramientaCodeX26: '',
          herramientaCodeX27: '',
          herramientaCodeX28: '',
          herramientaCodeX29: '',
          herramientaCodeX30: '',
          herramientaCodeX31: '',
          herramientaCodeX32: '',
          herramientaCodeX33: '',
          herramientaCodeX34: '',
          herramientaCodeX35: '',
          herramientaCodeX36: '',
          herramientaCodeX37: '',
          herramientaCodeX38: '',
          herramientaCodeX39: '',
          herramientaCodeX40: '',
          herramientaCodeX41: '',
          herramientaCodeX42: '',
          herramientaCodeX43: '',
          herramientaCodeX44: '',
          herramientaCodeX45: '',
          herramientaCodeX46: '',
          herramientaCodeX47: '',
          herramientaCodeX48: '',
          opcionPerforacionX1: '',
          opcionPerforacionX2: '',
          opcionPerforacionX3: '',
          opcionPerforacionX4: '',
          opcionPerforacionX5: '',
          opcionPerforacionX6: '',
          opcionPerforacionX7: '',
          opcionPerforacionX8: '',
          opcionPerforacionX9: '',
          opcionPerforacionX10: '',
          opcionPerforacionX11: '',
          opcionPerforacionX12: '',
          opcionPerforacionX13: '',
          opcionPerforacionX14: '',
          opcionPerforacionX15: '',
          opcionPerforacionX16: '',
          opcionPerforacionX17: '',
          opcionPerforacionX18: '',
          opcionPerforacionX19: '',
          opcionPerforacionX20: '',
          opcionPerforacionX21: '',
          opcionPerforacionX22: '',
          opcionPerforacionX23: '',
          opcionPerforacionX24: '',
          opcionPerforacionX25: '',
          opcionPerforacionX26: '',
          opcionPerforacionX27: '',
          opcionPerforacionX28: '',
          opcionPerforacionX29: '',
          opcionPerforacionX30: '',
          opcionPerforacionX31: '',
          opcionPerforacionX32: '',
          opcionPerforacionX33: '',
          opcionPerforacionX34: '',
          opcionPerforacionX35: '',
          opcionPerforacionX36: '',
          opcionPerforacionX37: '',
          opcionPerforacionX38: '',
          opcionPerforacionX39: '',
          opcionPerforacionX40: '',
          opcionPerforacionX41: '',
          opcionPerforacionX42: '',
          opcionPerforacionX43: '',
          opcionPerforacionX44: '',
          opcionPerforacionX45: '',
          opcionPerforacionX46: '',
          opcionPerforacionX47: '',
          opcionPerforacionX48: '',
          checkTurnoDiaX: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
          checkTurnoNocheX: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]
        }
    }

    cReporteTurnoEstadoSondaje = React.createRef();

    cReporteTurnoDia01 = React.createRef();
    cReporteTurnoDia02 = React.createRef();
    cReporteTurnoDia03 = React.createRef();
    cReporteTurnoDia04 = React.createRef();
    cReporteTurnoDia05 = React.createRef();
    cReporteTurnoDia06 = React.createRef();
    cReporteTurnoDia07 = React.createRef();
    cReporteTurnoDia08 = React.createRef();
    cReporteTurnoDia09 = React.createRef();
    cReporteTurnoDia10 = React.createRef();
    cReporteTurnoDia11 = React.createRef();
    cReporteTurnoDia12 = React.createRef();
    cReporteTurnoDia13 = React.createRef();

    cReporteTurnoNoche01 = React.createRef();
    cReporteTurnoNoche02 = React.createRef();
    cReporteTurnoNoche03 = React.createRef();
    cReporteTurnoNoche04 = React.createRef();
    cReporteTurnoNoche05 = React.createRef();
    cReporteTurnoNoche06 = React.createRef();
    cReporteTurnoNoche07 = React.createRef();
    cReporteTurnoNoche08 = React.createRef();
    cReporteTurnoNoche09 = React.createRef();
    cReporteTurnoNoche10 = React.createRef();
    cReporteTurnoNoche11 = React.createRef();
    cReporteTurnoNoche12 = React.createRef();
    cReporteTurnoNoche13 = React.createRef();

    cReporteTurnoDia08a09_01 = React.createRef();
    cReporteTurnoDia08a09_02 = React.createRef();
    cReporteTurnoDia08a09_03 = React.createRef();
    cReporteTurnoDia08a09_04 = React.createRef();
    cReporteTurnoDia08a09_05 = React.createRef();
    cReporteTurnoDia08a09_06 = React.createRef();
    cReporteTurnoDia08a09_07 = React.createRef();
    cReporteTurnoDia08a09_08 = React.createRef();
    cReporteTurnoDia08a09_09 = React.createRef();
    cReporteTurnoDia08a09_10 = React.createRef();
    cReporteTurnoDia08a09_11 = React.createRef();
    cReporteTurnoDia08a09_12 = React.createRef();
    cReporteTurnoDia08a09_13 = React.createRef();

    cReporteTurnoDia09a10_01 = React.createRef();
    cReporteTurnoDia09a10_02 = React.createRef();
    cReporteTurnoDia09a10_03 = React.createRef();
    cReporteTurnoDia09a10_04 = React.createRef();
    cReporteTurnoDia09a10_05 = React.createRef();
    cReporteTurnoDia09a10_06 = React.createRef();
    cReporteTurnoDia09a10_07 = React.createRef();
    cReporteTurnoDia09a10_08 = React.createRef();
    cReporteTurnoDia09a10_09 = React.createRef();
    cReporteTurnoDia09a10_10 = React.createRef();
    cReporteTurnoDia09a10_11 = React.createRef();
    cReporteTurnoDia09a10_12 = React.createRef();
    cReporteTurnoDia09a10_13 = React.createRef();

    cReporteTurnoDia10a11_01 = React.createRef();
    cReporteTurnoDia10a11_02 = React.createRef();
    cReporteTurnoDia10a11_03 = React.createRef();
    cReporteTurnoDia10a11_04 = React.createRef();
    cReporteTurnoDia10a11_05 = React.createRef();
    cReporteTurnoDia10a11_06 = React.createRef();
    cReporteTurnoDia10a11_07 = React.createRef();
    cReporteTurnoDia10a11_08 = React.createRef();
    cReporteTurnoDia10a11_09 = React.createRef();
    cReporteTurnoDia10a11_10 = React.createRef();
    cReporteTurnoDia10a11_11 = React.createRef();
    cReporteTurnoDia10a11_12 = React.createRef();
    cReporteTurnoDia10a11_13 = React.createRef();

    cReporteTurnoDia11a12_01 = React.createRef();
    cReporteTurnoDia11a12_02 = React.createRef();
    cReporteTurnoDia11a12_03 = React.createRef();
    cReporteTurnoDia11a12_04 = React.createRef();
    cReporteTurnoDia11a12_05 = React.createRef();
    cReporteTurnoDia11a12_06 = React.createRef();
    cReporteTurnoDia11a12_07 = React.createRef();
    cReporteTurnoDia11a12_08 = React.createRef();
    cReporteTurnoDia11a12_09 = React.createRef();
    cReporteTurnoDia11a12_10 = React.createRef();
    cReporteTurnoDia11a12_11 = React.createRef();
    cReporteTurnoDia11a12_12 = React.createRef();
    cReporteTurnoDia11a12_13 = React.createRef();

    cReporteTurnoDia12a13_01 = React.createRef();
    cReporteTurnoDia12a13_02 = React.createRef();
    cReporteTurnoDia12a13_03 = React.createRef();
    cReporteTurnoDia12a13_04 = React.createRef();
    cReporteTurnoDia12a13_05 = React.createRef();
    cReporteTurnoDia12a13_06 = React.createRef();
    cReporteTurnoDia12a13_07 = React.createRef();
    cReporteTurnoDia12a13_08 = React.createRef();
    cReporteTurnoDia12a13_09 = React.createRef();
    cReporteTurnoDia12a13_10 = React.createRef();
    cReporteTurnoDia12a13_11 = React.createRef();
    cReporteTurnoDia12a13_12 = React.createRef();
    cReporteTurnoDia12a13_13 = React.createRef();

    cReporteTurnoDia13a14_01 = React.createRef();
    cReporteTurnoDia13a14_02 = React.createRef();
    cReporteTurnoDia13a14_03 = React.createRef();
    cReporteTurnoDia13a14_04 = React.createRef();
    cReporteTurnoDia13a14_05 = React.createRef();
    cReporteTurnoDia13a14_06 = React.createRef();
    cReporteTurnoDia13a14_07 = React.createRef();
    cReporteTurnoDia13a14_08 = React.createRef();
    cReporteTurnoDia13a14_09 = React.createRef();
    cReporteTurnoDia13a14_10 = React.createRef();
    cReporteTurnoDia13a14_11 = React.createRef();
    cReporteTurnoDia13a14_12 = React.createRef();
    cReporteTurnoDia13a14_13 = React.createRef();

    cReporteTurnoDia14a15_01 = React.createRef();
    cReporteTurnoDia14a15_02 = React.createRef();
    cReporteTurnoDia14a15_03 = React.createRef();
    cReporteTurnoDia14a15_04 = React.createRef();
    cReporteTurnoDia14a15_05 = React.createRef();
    cReporteTurnoDia14a15_06 = React.createRef();
    cReporteTurnoDia14a15_07 = React.createRef();
    cReporteTurnoDia14a15_08 = React.createRef();
    cReporteTurnoDia14a15_09 = React.createRef();
    cReporteTurnoDia14a15_10 = React.createRef();
    cReporteTurnoDia14a15_11 = React.createRef();
    cReporteTurnoDia14a15_12 = React.createRef();
    cReporteTurnoDia14a15_13 = React.createRef();

    cReporteTurnoDia15a16_01 = React.createRef();
    cReporteTurnoDia15a16_02 = React.createRef();
    cReporteTurnoDia15a16_03 = React.createRef();
    cReporteTurnoDia15a16_04 = React.createRef();
    cReporteTurnoDia15a16_05 = React.createRef();
    cReporteTurnoDia15a16_06 = React.createRef();
    cReporteTurnoDia15a16_07 = React.createRef();
    cReporteTurnoDia15a16_08 = React.createRef();
    cReporteTurnoDia15a16_09 = React.createRef();
    cReporteTurnoDia15a16_10 = React.createRef();
    cReporteTurnoDia15a16_11 = React.createRef();
    cReporteTurnoDia15a16_12 = React.createRef();
    cReporteTurnoDia15a16_13 = React.createRef();

    cReporteTurnoDia16a17_01 = React.createRef();
    cReporteTurnoDia16a17_02 = React.createRef();
    cReporteTurnoDia16a17_03 = React.createRef();
    cReporteTurnoDia16a17_04 = React.createRef();
    cReporteTurnoDia16a17_05 = React.createRef();
    cReporteTurnoDia16a17_06 = React.createRef();
    cReporteTurnoDia16a17_07 = React.createRef();
    cReporteTurnoDia16a17_08 = React.createRef();
    cReporteTurnoDia16a17_09 = React.createRef();
    cReporteTurnoDia16a17_10 = React.createRef();
    cReporteTurnoDia16a17_11 = React.createRef();
    cReporteTurnoDia16a17_12 = React.createRef();
    cReporteTurnoDia16a17_13 = React.createRef();

    cReporteTurnoDia17a18_01 = React.createRef();
    cReporteTurnoDia17a18_02 = React.createRef();
    cReporteTurnoDia17a18_03 = React.createRef();
    cReporteTurnoDia17a18_04 = React.createRef();
    cReporteTurnoDia17a18_05 = React.createRef();
    cReporteTurnoDia17a18_06 = React.createRef();
    cReporteTurnoDia17a18_07 = React.createRef();
    cReporteTurnoDia17a18_08 = React.createRef();
    cReporteTurnoDia17a18_09 = React.createRef();
    cReporteTurnoDia17a18_10 = React.createRef();
    cReporteTurnoDia17a18_11 = React.createRef();
    cReporteTurnoDia17a18_12 = React.createRef();
    cReporteTurnoDia17a18_13 = React.createRef();

    cReporteTurnoDia18a19_01 = React.createRef();
    cReporteTurnoDia18a19_02 = React.createRef();
    cReporteTurnoDia18a19_03 = React.createRef();
    cReporteTurnoDia18a19_04 = React.createRef();
    cReporteTurnoDia18a19_05 = React.createRef();
    cReporteTurnoDia18a19_06 = React.createRef();
    cReporteTurnoDia18a19_07 = React.createRef();
    cReporteTurnoDia18a19_08 = React.createRef();
    cReporteTurnoDia18a19_09 = React.createRef();
    cReporteTurnoDia18a19_10 = React.createRef();
    cReporteTurnoDia18a19_11 = React.createRef();
    cReporteTurnoDia18a19_12 = React.createRef();
    cReporteTurnoDia18a19_13 = React.createRef();

    cReporteTurnoDia19a20_01 = React.createRef();
    cReporteTurnoDia19a20_02 = React.createRef();
    cReporteTurnoDia19a20_03 = React.createRef();
    cReporteTurnoDia19a20_04 = React.createRef();
    cReporteTurnoDia19a20_05 = React.createRef();
    cReporteTurnoDia19a20_06 = React.createRef();
    cReporteTurnoDia19a20_07 = React.createRef();
    cReporteTurnoDia19a20_08 = React.createRef();
    cReporteTurnoDia19a20_09 = React.createRef();
    cReporteTurnoDia19a20_10 = React.createRef();
    cReporteTurnoDia19a20_11 = React.createRef();
    cReporteTurnoDia19a20_12 = React.createRef();
    cReporteTurnoDia19a20_13 = React.createRef();

//------------------------------------------------------------------

    cReporteTurnoDia08a09_01_2 = React.createRef();
    cReporteTurnoDia08a09_02_2 = React.createRef();
    cReporteTurnoDia08a09_03_2 = React.createRef();
    cReporteTurnoDia08a09_04_2 = React.createRef();
    cReporteTurnoDia08a09_05_2 = React.createRef();
    cReporteTurnoDia08a09_06_2 = React.createRef();
    cReporteTurnoDia08a09_07_2 = React.createRef();
    cReporteTurnoDia08a09_08_2 = React.createRef();
    cReporteTurnoDia08a09_09_2 = React.createRef();
    cReporteTurnoDia08a09_10_2 = React.createRef();
    cReporteTurnoDia08a09_11_2 = React.createRef();
    cReporteTurnoDia08a09_12_2 = React.createRef();
    cReporteTurnoDia08a09_13_2 = React.createRef();

    cReporteTurnoDia09a10_01_2 = React.createRef();
    cReporteTurnoDia09a10_02_2 = React.createRef();
    cReporteTurnoDia09a10_03_2 = React.createRef();
    cReporteTurnoDia09a10_04_2 = React.createRef();
    cReporteTurnoDia09a10_05_2 = React.createRef();
    cReporteTurnoDia09a10_06_2 = React.createRef();
    cReporteTurnoDia09a10_07_2 = React.createRef();
    cReporteTurnoDia09a10_08_2 = React.createRef();
    cReporteTurnoDia09a10_09_2 = React.createRef();
    cReporteTurnoDia09a10_10_2 = React.createRef();
    cReporteTurnoDia09a10_11_2 = React.createRef();
    cReporteTurnoDia09a10_12_2 = React.createRef();
    cReporteTurnoDia09a10_13_2 = React.createRef();

    cReporteTurnoDia10a11_01_2 = React.createRef();
    cReporteTurnoDia10a11_02_2 = React.createRef();
    cReporteTurnoDia10a11_03_2 = React.createRef();
    cReporteTurnoDia10a11_04_2 = React.createRef();
    cReporteTurnoDia10a11_05_2 = React.createRef();
    cReporteTurnoDia10a11_06_2 = React.createRef();
    cReporteTurnoDia10a11_07_2 = React.createRef();
    cReporteTurnoDia10a11_08_2 = React.createRef();
    cReporteTurnoDia10a11_09_2 = React.createRef();
    cReporteTurnoDia10a11_10_2 = React.createRef();
    cReporteTurnoDia10a11_11_2 = React.createRef();
    cReporteTurnoDia10a11_12_2 = React.createRef();
    cReporteTurnoDia10a11_13_2 = React.createRef();

    cReporteTurnoDia11a12_01_2 = React.createRef();
    cReporteTurnoDia11a12_02_2 = React.createRef();
    cReporteTurnoDia11a12_03_2 = React.createRef();
    cReporteTurnoDia11a12_04_2 = React.createRef();
    cReporteTurnoDia11a12_05_2 = React.createRef();
    cReporteTurnoDia11a12_06_2 = React.createRef();
    cReporteTurnoDia11a12_07_2 = React.createRef();
    cReporteTurnoDia11a12_08_2 = React.createRef();
    cReporteTurnoDia11a12_09_2 = React.createRef();
    cReporteTurnoDia11a12_10_2 = React.createRef();
    cReporteTurnoDia11a12_11_2 = React.createRef();
    cReporteTurnoDia11a12_12_2 = React.createRef();
    cReporteTurnoDia11a12_13_2 = React.createRef();

    cReporteTurnoDia12a13_01_2 = React.createRef();
    cReporteTurnoDia12a13_02_2 = React.createRef();
    cReporteTurnoDia12a13_03_2 = React.createRef();
    cReporteTurnoDia12a13_04_2 = React.createRef();
    cReporteTurnoDia12a13_05_2 = React.createRef();
    cReporteTurnoDia12a13_06_2 = React.createRef();
    cReporteTurnoDia12a13_07_2 = React.createRef();
    cReporteTurnoDia12a13_08_2 = React.createRef();
    cReporteTurnoDia12a13_09_2 = React.createRef();
    cReporteTurnoDia12a13_10_2 = React.createRef();
    cReporteTurnoDia12a13_11_2 = React.createRef();
    cReporteTurnoDia12a13_12_2 = React.createRef();
    cReporteTurnoDia12a13_13_2 = React.createRef();

    cReporteTurnoDia13a14_01_2 = React.createRef();
    cReporteTurnoDia13a14_02_2 = React.createRef();
    cReporteTurnoDia13a14_03_2 = React.createRef();
    cReporteTurnoDia13a14_04_2 = React.createRef();
    cReporteTurnoDia13a14_05_2 = React.createRef();
    cReporteTurnoDia13a14_06_2 = React.createRef();
    cReporteTurnoDia13a14_07_2 = React.createRef();
    cReporteTurnoDia13a14_08_2 = React.createRef();
    cReporteTurnoDia13a14_09_2 = React.createRef();
    cReporteTurnoDia13a14_10_2 = React.createRef();
    cReporteTurnoDia13a14_11_2 = React.createRef();
    cReporteTurnoDia13a14_12_2 = React.createRef();
    cReporteTurnoDia13a14_13_2 = React.createRef();

    cReporteTurnoDia14a15_01_2 = React.createRef();
    cReporteTurnoDia14a15_02_2 = React.createRef();
    cReporteTurnoDia14a15_03_2 = React.createRef();
    cReporteTurnoDia14a15_04_2 = React.createRef();
    cReporteTurnoDia14a15_05_2 = React.createRef();
    cReporteTurnoDia14a15_06_2 = React.createRef();
    cReporteTurnoDia14a15_07_2 = React.createRef();
    cReporteTurnoDia14a15_08_2 = React.createRef();
    cReporteTurnoDia14a15_09_2 = React.createRef();
    cReporteTurnoDia14a15_10_2 = React.createRef();
    cReporteTurnoDia14a15_11_2 = React.createRef();
    cReporteTurnoDia14a15_12_2 = React.createRef();
    cReporteTurnoDia14a15_13_2 = React.createRef();

    cReporteTurnoDia15a16_01_2 = React.createRef();
    cReporteTurnoDia15a16_02_2 = React.createRef();
    cReporteTurnoDia15a16_03_2 = React.createRef();
    cReporteTurnoDia15a16_04_2 = React.createRef();
    cReporteTurnoDia15a16_05_2 = React.createRef();
    cReporteTurnoDia15a16_06_2 = React.createRef();
    cReporteTurnoDia15a16_07_2 = React.createRef();
    cReporteTurnoDia15a16_08_2 = React.createRef();
    cReporteTurnoDia15a16_09_2 = React.createRef();
    cReporteTurnoDia15a16_10_2 = React.createRef();
    cReporteTurnoDia15a16_11_2 = React.createRef();
    cReporteTurnoDia15a16_12_2 = React.createRef();
    cReporteTurnoDia15a16_13_2 = React.createRef();

    cReporteTurnoDia16a17_01_2 = React.createRef();
    cReporteTurnoDia16a17_02_2 = React.createRef();
    cReporteTurnoDia16a17_03_2 = React.createRef();
    cReporteTurnoDia16a17_04_2 = React.createRef();
    cReporteTurnoDia16a17_05_2 = React.createRef();
    cReporteTurnoDia16a17_06_2 = React.createRef();
    cReporteTurnoDia16a17_07_2 = React.createRef();
    cReporteTurnoDia16a17_08_2 = React.createRef();
    cReporteTurnoDia16a17_09_2 = React.createRef();
    cReporteTurnoDia16a17_10_2 = React.createRef();
    cReporteTurnoDia16a17_11_2 = React.createRef();
    cReporteTurnoDia16a17_12_2 = React.createRef();
    cReporteTurnoDia16a17_13_2 = React.createRef();

    cReporteTurnoDia17a18_01_2 = React.createRef();
    cReporteTurnoDia17a18_02_2 = React.createRef();
    cReporteTurnoDia17a18_03_2 = React.createRef();
    cReporteTurnoDia17a18_04_2 = React.createRef();
    cReporteTurnoDia17a18_05_2 = React.createRef();
    cReporteTurnoDia17a18_06_2 = React.createRef();
    cReporteTurnoDia17a18_07_2 = React.createRef();
    cReporteTurnoDia17a18_08_2 = React.createRef();
    cReporteTurnoDia17a18_09_2 = React.createRef();
    cReporteTurnoDia17a18_10_2 = React.createRef();
    cReporteTurnoDia17a18_11_2 = React.createRef();
    cReporteTurnoDia17a18_12_2 = React.createRef();
    cReporteTurnoDia17a18_13_2 = React.createRef();

    cReporteTurnoDia18a19_01_2 = React.createRef();
    cReporteTurnoDia18a19_02_2 = React.createRef();
    cReporteTurnoDia18a19_03_2 = React.createRef();
    cReporteTurnoDia18a19_04_2 = React.createRef();
    cReporteTurnoDia18a19_05_2 = React.createRef();
    cReporteTurnoDia18a19_06_2 = React.createRef();
    cReporteTurnoDia18a19_07_2 = React.createRef();
    cReporteTurnoDia18a19_08_2 = React.createRef();
    cReporteTurnoDia18a19_09_2 = React.createRef();
    cReporteTurnoDia18a19_10_2 = React.createRef();
    cReporteTurnoDia18a19_11_2 = React.createRef();
    cReporteTurnoDia18a19_12_2 = React.createRef();
    cReporteTurnoDia18a19_13_2 = React.createRef();

    cReporteTurnoDia19a20_01_2 = React.createRef();
    cReporteTurnoDia19a20_02_2 = React.createRef();
    cReporteTurnoDia19a20_03_2 = React.createRef();
    cReporteTurnoDia19a20_04_2 = React.createRef();
    cReporteTurnoDia19a20_05_2 = React.createRef();
    cReporteTurnoDia19a20_06_2 = React.createRef();
    cReporteTurnoDia19a20_07_2 = React.createRef();
    cReporteTurnoDia19a20_08_2 = React.createRef();
    cReporteTurnoDia19a20_09_2 = React.createRef();
    cReporteTurnoDia19a20_10_2 = React.createRef();
    cReporteTurnoDia19a20_11_2 = React.createRef();
    cReporteTurnoDia19a20_12_2 = React.createRef();
    cReporteTurnoDia19a20_13_2 = React.createRef();

//------------------------------------------------------------

    cReporteTurnoNoche20a21_01 = React.createRef();
    cReporteTurnoNoche20a21_02 = React.createRef();
    cReporteTurnoNoche20a21_03 = React.createRef();
    cReporteTurnoNoche20a21_04 = React.createRef();
    cReporteTurnoNoche20a21_05 = React.createRef();
    cReporteTurnoNoche20a21_06 = React.createRef();
    cReporteTurnoNoche20a21_07 = React.createRef();
    cReporteTurnoNoche20a21_08 = React.createRef();
    cReporteTurnoNoche20a21_09 = React.createRef();
    cReporteTurnoNoche20a21_10 = React.createRef();
    cReporteTurnoNoche20a21_11 = React.createRef();
    cReporteTurnoNoche20a21_12 = React.createRef();
    cReporteTurnoNoche20a21_13 = React.createRef();

    cReporteTurnoNoche21a22_01 = React.createRef();
    cReporteTurnoNoche21a22_02 = React.createRef();
    cReporteTurnoNoche21a22_03 = React.createRef();
    cReporteTurnoNoche21a22_04 = React.createRef();
    cReporteTurnoNoche21a22_05 = React.createRef();
    cReporteTurnoNoche21a22_06 = React.createRef();
    cReporteTurnoNoche21a22_07 = React.createRef();
    cReporteTurnoNoche21a22_08 = React.createRef();
    cReporteTurnoNoche21a22_09 = React.createRef();
    cReporteTurnoNoche21a22_10 = React.createRef();
    cReporteTurnoNoche21a22_11 = React.createRef();
    cReporteTurnoNoche21a22_12 = React.createRef();
    cReporteTurnoNoche21a22_13 = React.createRef();

    cReporteTurnoNoche22a23_01 = React.createRef();
    cReporteTurnoNoche22a23_02 = React.createRef();
    cReporteTurnoNoche22a23_03 = React.createRef();
    cReporteTurnoNoche22a23_04 = React.createRef();
    cReporteTurnoNoche22a23_05 = React.createRef();
    cReporteTurnoNoche22a23_06 = React.createRef();
    cReporteTurnoNoche22a23_07 = React.createRef();
    cReporteTurnoNoche22a23_08 = React.createRef();
    cReporteTurnoNoche22a23_09 = React.createRef();
    cReporteTurnoNoche22a23_10 = React.createRef();
    cReporteTurnoNoche22a23_11 = React.createRef();
    cReporteTurnoNoche22a23_12 = React.createRef();
    cReporteTurnoNoche22a23_13 = React.createRef();

    cReporteTurnoNoche23a24_01 = React.createRef();
    cReporteTurnoNoche23a24_02 = React.createRef();
    cReporteTurnoNoche23a24_03 = React.createRef();
    cReporteTurnoNoche23a24_04 = React.createRef();
    cReporteTurnoNoche23a24_05 = React.createRef();
    cReporteTurnoNoche23a24_06 = React.createRef();
    cReporteTurnoNoche23a24_07 = React.createRef();
    cReporteTurnoNoche23a24_08 = React.createRef();
    cReporteTurnoNoche23a24_09 = React.createRef();
    cReporteTurnoNoche23a24_10 = React.createRef();
    cReporteTurnoNoche23a24_11 = React.createRef();
    cReporteTurnoNoche23a24_12 = React.createRef();
    cReporteTurnoNoche23a24_13 = React.createRef();

    cReporteTurnoNoche24a01_01 = React.createRef();
    cReporteTurnoNoche24a01_02 = React.createRef();
    cReporteTurnoNoche24a01_03 = React.createRef();
    cReporteTurnoNoche24a01_04 = React.createRef();
    cReporteTurnoNoche24a01_05 = React.createRef();
    cReporteTurnoNoche24a01_06 = React.createRef();
    cReporteTurnoNoche24a01_07 = React.createRef();
    cReporteTurnoNoche24a01_08 = React.createRef();
    cReporteTurnoNoche24a01_09 = React.createRef();
    cReporteTurnoNoche24a01_10 = React.createRef();
    cReporteTurnoNoche24a01_11 = React.createRef();
    cReporteTurnoNoche24a01_12 = React.createRef();
    cReporteTurnoNoche24a01_13 = React.createRef();

    cReporteTurnoNoche01a02_01 = React.createRef();
    cReporteTurnoNoche01a02_02 = React.createRef();
    cReporteTurnoNoche01a02_03 = React.createRef();
    cReporteTurnoNoche01a02_04 = React.createRef();
    cReporteTurnoNoche01a02_05 = React.createRef();
    cReporteTurnoNoche01a02_06 = React.createRef();
    cReporteTurnoNoche01a02_07 = React.createRef();
    cReporteTurnoNoche01a02_08 = React.createRef();
    cReporteTurnoNoche01a02_09 = React.createRef();
    cReporteTurnoNoche01a02_10 = React.createRef();
    cReporteTurnoNoche01a02_11 = React.createRef();
    cReporteTurnoNoche01a02_12 = React.createRef();
    cReporteTurnoNoche01a02_13 = React.createRef();

    cReporteTurnoNoche02a03_01 = React.createRef();
    cReporteTurnoNoche02a03_02 = React.createRef();
    cReporteTurnoNoche02a03_03 = React.createRef();
    cReporteTurnoNoche02a03_04 = React.createRef();
    cReporteTurnoNoche02a03_05 = React.createRef();
    cReporteTurnoNoche02a03_06 = React.createRef();
    cReporteTurnoNoche02a03_07 = React.createRef();
    cReporteTurnoNoche02a03_08 = React.createRef();
    cReporteTurnoNoche02a03_09 = React.createRef();
    cReporteTurnoNoche02a03_10 = React.createRef();
    cReporteTurnoNoche02a03_11 = React.createRef();
    cReporteTurnoNoche02a03_12 = React.createRef();
    cReporteTurnoNoche02a03_13 = React.createRef();

    cReporteTurnoNoche03a04_01 = React.createRef();
    cReporteTurnoNoche03a04_02 = React.createRef();
    cReporteTurnoNoche03a04_03 = React.createRef();
    cReporteTurnoNoche03a04_04 = React.createRef();
    cReporteTurnoNoche03a04_05 = React.createRef();
    cReporteTurnoNoche03a04_06 = React.createRef();
    cReporteTurnoNoche03a04_07 = React.createRef();
    cReporteTurnoNoche03a04_08 = React.createRef();
    cReporteTurnoNoche03a04_09 = React.createRef();
    cReporteTurnoNoche03a04_10 = React.createRef();
    cReporteTurnoNoche03a04_11 = React.createRef();
    cReporteTurnoNoche03a04_12 = React.createRef();
    cReporteTurnoNoche03a04_13 = React.createRef();

    cReporteTurnoNoche04a05_01 = React.createRef();
    cReporteTurnoNoche04a05_02 = React.createRef();
    cReporteTurnoNoche04a05_03 = React.createRef();
    cReporteTurnoNoche04a05_04 = React.createRef();
    cReporteTurnoNoche04a05_05 = React.createRef();
    cReporteTurnoNoche04a05_06 = React.createRef();
    cReporteTurnoNoche04a05_07 = React.createRef();
    cReporteTurnoNoche04a05_08 = React.createRef();
    cReporteTurnoNoche04a05_09 = React.createRef();
    cReporteTurnoNoche04a05_10 = React.createRef();
    cReporteTurnoNoche04a05_11 = React.createRef();
    cReporteTurnoNoche04a05_12 = React.createRef();
    cReporteTurnoNoche04a05_13 = React.createRef();

    cReporteTurnoNoche05a06_01 = React.createRef();
    cReporteTurnoNoche05a06_02 = React.createRef();
    cReporteTurnoNoche05a06_03 = React.createRef();
    cReporteTurnoNoche05a06_04 = React.createRef();
    cReporteTurnoNoche05a06_05 = React.createRef();
    cReporteTurnoNoche05a06_06 = React.createRef();
    cReporteTurnoNoche05a06_07 = React.createRef();
    cReporteTurnoNoche05a06_08 = React.createRef();
    cReporteTurnoNoche05a06_09 = React.createRef();
    cReporteTurnoNoche05a06_10 = React.createRef();
    cReporteTurnoNoche05a06_11 = React.createRef();
    cReporteTurnoNoche05a06_12 = React.createRef();
    cReporteTurnoNoche05a06_13 = React.createRef();

    cReporteTurnoNoche06a07_01 = React.createRef();
    cReporteTurnoNoche06a07_02 = React.createRef();
    cReporteTurnoNoche06a07_03 = React.createRef();
    cReporteTurnoNoche06a07_04 = React.createRef();
    cReporteTurnoNoche06a07_05 = React.createRef();
    cReporteTurnoNoche06a07_06 = React.createRef();
    cReporteTurnoNoche06a07_07 = React.createRef();
    cReporteTurnoNoche06a07_08 = React.createRef();
    cReporteTurnoNoche06a07_09 = React.createRef();
    cReporteTurnoNoche06a07_10 = React.createRef();
    cReporteTurnoNoche06a07_11 = React.createRef();
    cReporteTurnoNoche06a07_12 = React.createRef();
    cReporteTurnoNoche06a07_13 = React.createRef();

    cReporteTurnoNoche07a08_01 = React.createRef();
    cReporteTurnoNoche07a08_02 = React.createRef();
    cReporteTurnoNoche07a08_03 = React.createRef();
    cReporteTurnoNoche07a08_04 = React.createRef();
    cReporteTurnoNoche07a08_05 = React.createRef();
    cReporteTurnoNoche07a08_06 = React.createRef();
    cReporteTurnoNoche07a08_07 = React.createRef();
    cReporteTurnoNoche07a08_08 = React.createRef();
    cReporteTurnoNoche07a08_09 = React.createRef();
    cReporteTurnoNoche07a08_10 = React.createRef();
    cReporteTurnoNoche07a08_11 = React.createRef();
    cReporteTurnoNoche07a08_12 = React.createRef();
    cReporteTurnoNoche07a08_13 = React.createRef();

    //------------------------------------------------------------

    cReporteTurnoNoche20a21_01_2 = React.createRef();
    cReporteTurnoNoche20a21_02_2 = React.createRef();
    cReporteTurnoNoche20a21_03_2 = React.createRef();
    cReporteTurnoNoche20a21_04_2 = React.createRef();
    cReporteTurnoNoche20a21_05_2 = React.createRef();
    cReporteTurnoNoche20a21_06_2 = React.createRef();
    cReporteTurnoNoche20a21_07_2 = React.createRef();
    cReporteTurnoNoche20a21_08_2 = React.createRef();
    cReporteTurnoNoche20a21_09_2 = React.createRef();
    cReporteTurnoNoche20a21_10_2 = React.createRef();
    cReporteTurnoNoche20a21_11_2 = React.createRef();
    cReporteTurnoNoche20a21_12_2 = React.createRef();
    cReporteTurnoNoche20a21_13_2 = React.createRef();

    cReporteTurnoNoche21a22_01_2 = React.createRef();
    cReporteTurnoNoche21a22_02_2 = React.createRef();
    cReporteTurnoNoche21a22_03_2 = React.createRef();
    cReporteTurnoNoche21a22_04_2 = React.createRef();
    cReporteTurnoNoche21a22_05_2 = React.createRef();
    cReporteTurnoNoche21a22_06_2 = React.createRef();
    cReporteTurnoNoche21a22_07_2 = React.createRef();
    cReporteTurnoNoche21a22_08_2 = React.createRef();
    cReporteTurnoNoche21a22_09_2 = React.createRef();
    cReporteTurnoNoche21a22_10_2 = React.createRef();
    cReporteTurnoNoche21a22_11_2 = React.createRef();
    cReporteTurnoNoche21a22_12_2 = React.createRef();
    cReporteTurnoNoche21a22_13_2 = React.createRef();

    cReporteTurnoNoche22a23_01_2 = React.createRef();
    cReporteTurnoNoche22a23_02_2 = React.createRef();
    cReporteTurnoNoche22a23_03_2 = React.createRef();
    cReporteTurnoNoche22a23_04_2 = React.createRef();
    cReporteTurnoNoche22a23_05_2 = React.createRef();
    cReporteTurnoNoche22a23_06_2 = React.createRef();
    cReporteTurnoNoche22a23_07_2 = React.createRef();
    cReporteTurnoNoche22a23_08_2 = React.createRef();
    cReporteTurnoNoche22a23_09_2 = React.createRef();
    cReporteTurnoNoche22a23_10_2 = React.createRef();
    cReporteTurnoNoche22a23_11_2 = React.createRef();
    cReporteTurnoNoche22a23_12_2 = React.createRef();
    cReporteTurnoNoche22a23_13_2 = React.createRef();

    cReporteTurnoNoche23a24_01_2 = React.createRef();
    cReporteTurnoNoche23a24_02_2 = React.createRef();
    cReporteTurnoNoche23a24_03_2 = React.createRef();
    cReporteTurnoNoche23a24_04_2 = React.createRef();
    cReporteTurnoNoche23a24_05_2 = React.createRef();
    cReporteTurnoNoche23a24_06_2 = React.createRef();
    cReporteTurnoNoche23a24_07_2 = React.createRef();
    cReporteTurnoNoche23a24_08_2 = React.createRef();
    cReporteTurnoNoche23a24_09_2 = React.createRef();
    cReporteTurnoNoche23a24_10_2 = React.createRef();
    cReporteTurnoNoche23a24_11_2 = React.createRef();
    cReporteTurnoNoche23a24_12_2 = React.createRef();
    cReporteTurnoNoche23a24_13_2 = React.createRef();

    cReporteTurnoNoche24a01_01_2 = React.createRef();
    cReporteTurnoNoche24a01_02_2 = React.createRef();
    cReporteTurnoNoche24a01_03_2 = React.createRef();
    cReporteTurnoNoche24a01_04_2 = React.createRef();
    cReporteTurnoNoche24a01_05_2 = React.createRef();
    cReporteTurnoNoche24a01_06_2 = React.createRef();
    cReporteTurnoNoche24a01_07_2 = React.createRef();
    cReporteTurnoNoche24a01_08_2 = React.createRef();
    cReporteTurnoNoche24a01_09_2 = React.createRef();
    cReporteTurnoNoche24a01_10_2 = React.createRef();
    cReporteTurnoNoche24a01_11_2 = React.createRef();
    cReporteTurnoNoche24a01_12_2 = React.createRef();
    cReporteTurnoNoche24a01_13_2 = React.createRef();

    cReporteTurnoNoche01a02_01_2 = React.createRef();
    cReporteTurnoNoche01a02_02_2 = React.createRef();
    cReporteTurnoNoche01a02_03_2 = React.createRef();
    cReporteTurnoNoche01a02_04_2 = React.createRef();
    cReporteTurnoNoche01a02_05_2 = React.createRef();
    cReporteTurnoNoche01a02_06_2 = React.createRef();
    cReporteTurnoNoche01a02_07_2 = React.createRef();
    cReporteTurnoNoche01a02_08_2 = React.createRef();
    cReporteTurnoNoche01a02_09_2 = React.createRef();
    cReporteTurnoNoche01a02_10_2 = React.createRef();
    cReporteTurnoNoche01a02_11_2 = React.createRef();
    cReporteTurnoNoche01a02_12_2 = React.createRef();
    cReporteTurnoNoche01a02_13_2 = React.createRef();

    cReporteTurnoNoche02a03_01_2 = React.createRef();
    cReporteTurnoNoche02a03_02_2 = React.createRef();
    cReporteTurnoNoche02a03_03_2 = React.createRef();
    cReporteTurnoNoche02a03_04_2 = React.createRef();
    cReporteTurnoNoche02a03_05_2 = React.createRef();
    cReporteTurnoNoche02a03_06_2 = React.createRef();
    cReporteTurnoNoche02a03_07_2 = React.createRef();
    cReporteTurnoNoche02a03_08_2 = React.createRef();
    cReporteTurnoNoche02a03_09_2 = React.createRef();
    cReporteTurnoNoche02a03_10_2 = React.createRef();
    cReporteTurnoNoche02a03_11_2 = React.createRef();
    cReporteTurnoNoche02a03_12_2 = React.createRef();
    cReporteTurnoNoche02a03_13_2 = React.createRef();

    cReporteTurnoNoche03a04_01_2 = React.createRef();
    cReporteTurnoNoche03a04_02_2 = React.createRef();
    cReporteTurnoNoche03a04_03_2 = React.createRef();
    cReporteTurnoNoche03a04_04_2 = React.createRef();
    cReporteTurnoNoche03a04_05_2 = React.createRef();
    cReporteTurnoNoche03a04_06_2 = React.createRef();
    cReporteTurnoNoche03a04_07_2 = React.createRef();
    cReporteTurnoNoche03a04_08_2 = React.createRef();
    cReporteTurnoNoche03a04_09_2 = React.createRef();
    cReporteTurnoNoche03a04_10_2 = React.createRef();
    cReporteTurnoNoche03a04_11_2 = React.createRef();
    cReporteTurnoNoche03a04_12_2 = React.createRef();
    cReporteTurnoNoche03a04_13_2 = React.createRef();

    cReporteTurnoNoche04a05_01_2 = React.createRef();
    cReporteTurnoNoche04a05_02_2 = React.createRef();
    cReporteTurnoNoche04a05_03_2 = React.createRef();
    cReporteTurnoNoche04a05_04_2 = React.createRef();
    cReporteTurnoNoche04a05_05_2 = React.createRef();
    cReporteTurnoNoche04a05_06_2 = React.createRef();
    cReporteTurnoNoche04a05_07_2 = React.createRef();
    cReporteTurnoNoche04a05_08_2 = React.createRef();
    cReporteTurnoNoche04a05_09_2 = React.createRef();
    cReporteTurnoNoche04a05_10_2 = React.createRef();
    cReporteTurnoNoche04a05_11_2 = React.createRef();
    cReporteTurnoNoche04a05_12_2 = React.createRef();
    cReporteTurnoNoche04a05_13_2 = React.createRef();

    cReporteTurnoNoche05a06_01_2 = React.createRef();
    cReporteTurnoNoche05a06_02_2 = React.createRef();
    cReporteTurnoNoche05a06_03_2 = React.createRef();
    cReporteTurnoNoche05a06_04_2 = React.createRef();
    cReporteTurnoNoche05a06_05_2 = React.createRef();
    cReporteTurnoNoche05a06_06_2 = React.createRef();
    cReporteTurnoNoche05a06_07_2 = React.createRef();
    cReporteTurnoNoche05a06_08_2 = React.createRef();
    cReporteTurnoNoche05a06_09_2 = React.createRef();
    cReporteTurnoNoche05a06_10_2 = React.createRef();
    cReporteTurnoNoche05a06_11_2 = React.createRef();
    cReporteTurnoNoche05a06_12_2 = React.createRef();
    cReporteTurnoNoche05a06_13_2 = React.createRef();

    cReporteTurnoNoche06a07_01_2 = React.createRef();
    cReporteTurnoNoche06a07_02_2 = React.createRef();
    cReporteTurnoNoche06a07_03_2 = React.createRef();
    cReporteTurnoNoche06a07_04_2 = React.createRef();
    cReporteTurnoNoche06a07_05_2 = React.createRef();
    cReporteTurnoNoche06a07_06_2 = React.createRef();
    cReporteTurnoNoche06a07_07_2 = React.createRef();
    cReporteTurnoNoche06a07_08_2 = React.createRef();
    cReporteTurnoNoche06a07_09_2 = React.createRef();
    cReporteTurnoNoche06a07_10_2 = React.createRef();
    cReporteTurnoNoche06a07_11_2 = React.createRef();
    cReporteTurnoNoche06a07_12_2 = React.createRef();
    cReporteTurnoNoche06a07_13_2 = React.createRef();

    cReporteTurnoNoche07a08_01_2 = React.createRef();
    cReporteTurnoNoche07a08_02_2 = React.createRef();
    cReporteTurnoNoche07a08_03_2 = React.createRef();
    cReporteTurnoNoche07a08_04_2 = React.createRef();
    cReporteTurnoNoche07a08_05_2 = React.createRef();
    cReporteTurnoNoche07a08_06_2 = React.createRef();
    cReporteTurnoNoche07a08_07_2 = React.createRef();
    cReporteTurnoNoche07a08_08_2 = React.createRef();
    cReporteTurnoNoche07a08_09_2 = React.createRef();
    cReporteTurnoNoche07a08_10_2 = React.createRef();
    cReporteTurnoNoche07a08_11_2 = React.createRef();
    cReporteTurnoNoche07a08_12_2 = React.createRef();
    cReporteTurnoNoche07a08_13_2 = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = 'MainNet'
      let networkUrlX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
           stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
        }
        if (serverStacksX === 'TestNet'){
           stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({ username })
      }
      const {name} = userSession.loadUserData().profile;
      this.setState({ nameBlockstack: name, networkUrlX })
      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      if (languageX === 'English'){this.setState({ isocode: "en", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'French'){this.setState({ isocode:  "fr", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Spanish'){this.setState({ isocode:  "es", dateFormatX:"dd/MM/yyyy"})}
      if (languageX === 'Portuguese'){this.setState({ isocode:  "pt", dateFormatX:"dd/MM/yyyy"})}
      if (languageX === 'Swedish'){this.setState({ isocode:  "sv", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Netherlands'){this.setState({ isocode:  "nl", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Russian'){this.setState({ isocode:  "ru", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Japanese'){this.setState({ isocode:  "ja_JP", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Chinese'){this.setState({ isocode: "zh", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'German'){this.setState({ isocode:  "de", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Italian'){this.setState({ isocode:  "it", dateFormatX:"MM/dd/yyyy"})}

      this.setState({fechaReporte: new Date()})
      const isocode = this.state.isocode
      const optionsDate = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
      };
      let folio = new Date()

      let diaX = folio.getDate()
      let mesX = folio.getMonth()+1
      let anioX = folio.getFullYear()
      let dia2X = 0
      let mes2X = 0
      if (diaX<10){dia2X = '0'+ diaX.toString()}else{ dia2X = diaX.toString()}
      if (mesX<10){mes2X = '0'+ mesX.toString()}else{ mes2X = mesX.toString()}
      folio = anioX.toString()+mes2X+dia2X
      let fecha = dia2X+'-'+mes2X+'-'+anioX.toString()
      this.setState({fecha,folio})
      this.setState({displayLoader:true})
      this.setState({displayUpdate:true})
      Promise.all(
         [this.goAuthorizersNames(networkUrlX),
          this.goActividadesNames(networkUrlX),
          this.goTipo_PerforacionNames(networkUrlX),
          this.goHerramientaNames(networkUrlX),
          this.goPerforacionNames(networkUrlX),
          this.goTipoMuestraNames(networkUrlX),
          this.getSobrecargaNatural(networkUrlX),
          this.getSobrecargaNoNatural(networkUrlX),
          this.getRocaMuestraSeca(networkUrlX),
          this.getRocaMuestraHumeda(networkUrlX),
          this.getRocaMuestraHumedaMartillo(networkUrlX),
          this.getMonitoreo(networkUrlX),
          this.getWorkOrder(networkUrlX),
          this.getWorkOrderDatoExtra(networkUrlX),
          this.getEstadoPozo(networkUrlX),
          this.getEstadoSondaje(networkUrlX),
          this.getDiametroOcupado(networkUrlX)
         ]
      ).then(
        (resolve) =>{},
        (reject) =>{}
      )
    }

    componentDidMount() {
    }

    componentDidUpdate = () => {
          if (this.state.authorizersX.length > 0 && this.state.roleX.length > 0 && this.state.displayUpdate === true){
            this.setState({displayUpdate:false})
            this.handleDate(null, 'fechaReporte', 1)
          }
    }

    handleDialog = (type, action, optX) => {
      if (type === 'Guardar Reporte Turno Día') {
          if (action === 'confirm') {
              this.setState({ dialogOpen: false });
              this.guardarturnodia2('guardar')
          }
          else if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Guardar Reporte Turno Noche') {
          if (action === 'confirm') {
              this.setState({ dialogOpen: false });
              this.guardarturnonoche2('guardar')
          }
          else if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Eliminar Reporte Turno Día') {
          if (action === 'confirm') {
              this.setState({ dialogOpen: false });
              this.eliminarturnodia2()
          }
          else if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Eliminar Reporte Turno Noche') {
          if (action === 'confirm') {
              this.setState({ dialogOpen: false });
              this.eliminarturnonoche2()
          }
          else if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Firmar Reporte') {
          if (action === 'confirm') {
              this.firmar3(true)
              this.setState({ dialogOpen: false });
          }
          else if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Anular Firma Reporte') {
          if (action === 'confirm') {
              this.firmar3(false)
              this.setState({ dialogOpen: false });
          }
          else if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Aclaraciones') {
          if (action === 'confirm') {
              this.guardar3(optX)
              this.setState({ dialogOpenObservaciones: false });
              this.setState({ observacionDisabled: false })
          }
          else if (action === 'cancel') {
            this.setState({ dialogOpenObservaciones: false });
            this.setState({ observacionDisabled: false })
          }
      }
      if (type === 'Precaución') {
          if (action === 'confirm') {
            this.setState({ dialogOpenNoHayDiaAnterior: false });
            this.setState({ confirmaSeguirNoHayDiaAnterior: true });
          }
          else if (action === 'cancel') {
            this.setState({ dialogOpenNoHayDiaAnterior: false });
            this.setState({ confirmaSeguirNoHayDiaAnterior: false })
            this.setState({ dialogOpenPdf: false });
            this.setState({ dialogOpenExport: false });
            this.setState({ dialogOpenEstadoPago: false });
            this.setState({ dialogOpen: false });
            this.setState({ dialogOpenVersion: false});
            this.setState({ copied: false});
          }
      }
      if (type === 'Error') {
          if (action === 'cancel') {
            this.setState({ dialogOpenError: false });
          }
      }
      if (type === 'Resumen') {
          if (action === 'cancel') {
            this.setState({ dialogOpenVersion: false, cancelVersion: true });
          }
      }
      if (type === 'PDF') {
          if (action === 'cancel') {
            this.setState({ dialogOpenPdf: false });
            this.setState({ displayPdfX: false});
          }
      }
      if (type === 'Exportar') {
          if (action === 'cancel') {
            this.setState({ dialogOpenExport: false });
            this.setState({ copied: false});
          }
      }
      if (type === 'Estado Pago') {
          if (action === 'cancel') {
            this.setState({ dialogOpenEstadoPago: false });
          }
      }
      if (type === 'Cerrar Reporte Turno') {
          if (action === 'confirm') {
              this.cerrarreporte2()
              this.setState({ dialogOpen: false });
          }
          else if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
    }

    handleInputNumber = (e, typeX) => {
      if (e.target.value < 0 || isNaN(parseFloat(e.target.value)) || !isFinite(e.target.value)){
          e.target.value = ''
      }
    }

    handleCaracteresEspeciales = (e) => {
      let palabra = e.target.value
      let ArrayIdValue = palabra.split(`"`)
      e.target.value = ArrayIdValue[0]
      palabra = e.target.value
      ArrayIdValue = palabra.split("'")
      e.target.value = ArrayIdValue[0]
    }

    handleDialogPdf = (type, checkCertificadoX, checkReporteTurnoX) => {
      if (checkReporteTurnoX) {
        this.guardarturnodia2('pdf')
        this.guardarturnonoche2('pdf')
      }
      this.setState({displayPdfX: true})
    }

    handleChangeCheckPdf = (e, iCheckX) => {
      if (iCheckX === 1) {
          this.setState({checkCertificadoX:true})
          this.setState({checkReporteTurnoX:false})
      }
      if (iCheckX === 2) {
           this.setState({checkReporteTurnoX:true})
           this.setState({checkCertificadoX:false})
      }
      this.setState({displayPdfX: false})
    }

    handleChangeCheckExport= (e, iCheckX) => {
      if (iCheckX === 1) {
          this.setState({checkExportJsonX:true})
          this.setState({checkExportCsvX:false})
      }
      if (iCheckX === 2) {
           this.setState({checkExportCsvX:true})
           this.setState({checkExportJsonX:false})
      }
      this.setState({displayExportX: false, copied: false})
    }

    handleChangeCheckExportCsv= (e, iCheckX) => {
      if (iCheckX === 1) {
          this.setState({checkExportCsvVerticalX:true})
          this.setState({checkExportCsvHorizontalX:false})
      }
      if (iCheckX === 2) {
           this.setState({checkExportCsvHorizontalX:true})
           this.setState({checkExportCsvVerticalX:false})
      }
      this.setState({displayExportX: false, copied: false})
    }

    handleChangeObservacion = (e,opcionObservacion) => {

      let palabra = e.target.value
      let ArrayIdValue = palabra.split(`"`)
      e.target.value = ArrayIdValue[0]
      palabra = e.target.value
      ArrayIdValue = palabra.split("'")
      e.target.value = ArrayIdValue[0]

      let cReporteTurnoObservacion = e.target.value

      this.setState({cReporteTurnoObservacion})
      if (opcionObservacion  === 1){this.setState({cReporteTurnoObservacionFirmante1: cReporteTurnoObservacion})}
      if (opcionObservacion  === 2){this.setState({cReporteTurnoObservacionFirmante2: cReporteTurnoObservacion})}
      if (opcionObservacion  === 3){this.setState({cReporteTurnoObservacionFirmante3: cReporteTurnoObservacion})}
      if (opcionObservacion  === 4){this.setState({cReporteTurnoObservacionFirmante4: cReporteTurnoObservacion})}
      if (opcionObservacion  === 5){this.setState({cReporteTurnoObservacionFirmante5: cReporteTurnoObservacion})}
      if (opcionObservacion  === 6){this.setState({cReporteTurnoObservacionAsesorTurnoDia: cReporteTurnoObservacion})}
      if (opcionObservacion  === 7){this.setState({cReporteTurnoObservacionAsesorTurnoNoche: cReporteTurnoObservacion})}
      if (opcionObservacion  === 8){this.setState({cReporteTurnoObservacionJefeTurnoDia: cReporteTurnoObservacion})}
      if (opcionObservacion  === 9){this.setState({cReporteTurnoObservacionJefeTurnoNoche: cReporteTurnoObservacion})}
    }

    handleDialogExport = (type, checkJsonX, checkCsvX, checkCsvVerticalX, checkCsvHorizontalX) => {
      if (checkJsonX) {
        this.exportReporteTurno(1)
      }
      if (checkCsvX) {
        if (checkCsvVerticalX){
           this.exportReporteTurno(2)
        }
        if (checkCsvHorizontalX){
           this.exportReporteTurno(3)
        }
      }
      this.setState({displayExportX: true})
      this.setState({ copied: false});
    }

    handleDialogEstadoPago = (type, checkEstadoPagoX) => {
      this.setState({ dialogOpenEstadoPago: false });
    }

    handleDialogDisplay = (type, userTurnoDiaX, userTurnoNocheX, tokenDiaX, tokenNocheX) => {
      if (type === 'Resumen') {
         if ((this.state.jsonVersionesTurnoDiaX.length > 0 && userTurnoDiaX === '') && (this.state.jsonVersionesTurnoNocheX.length === 0)){
           this.setState({dialogOpenError: true, dialogMessageError: 'Debe seleccionar una opción para Reporte de Turno Día ', dialogTitleError: 'Error' })
         }else{
           if ((this.state.jsonVersionesTurnoDiaX.length === 0) && (this.state.jsonVersionesTurnoNocheX.length > 0 && userTurnoNocheX === '')){
             this.setState({dialogOpenError: true, dialogMessageError: 'Debe seleccionar una opción para Reporte de Turno Noche', dialogTitleError: 'Error' })
           }else{
             if ((this.state.jsonVersionesTurnoDiaX.length > 0 && userTurnoDiaX === '') || (this.state.jsonVersionesTurnoNocheX.length > 0 && userTurnoNocheX === '')){
               this.setState({dialogOpenError: true, dialogMessageError: 'Debe seleccionar una opción para Reporte de Turno Día y Noche ', dialogTitleError: 'Error' })
             }else{
                this.setState({ dialogOpenVersion: false })
                this.setState({displayLoader:true})
                this.setState({nuevoReporteTurnoX: false})
                this.setState({userTurnoDiaX, userTurnoNocheX})
                this.setState({userTurnoDiaFondoPozoX:userTurnoDiaX, userTurnoNocheFondoPozoX:userTurnoNocheX})
                this.setState({linea01X:true,linea02X:true,linea03X:true,linea04X:true,linea05X:true,linea06X:true,linea07X:true,linea08X:true,linea09X:true,linea10X:true})
                this.setState({linea11X:true,linea12X:true,linea13X:true,linea14X:true,linea15X:true,linea16X:true,linea17X:true,linea18X:true,linea19X:true,linea20X:true})
                this.setState({linea21X:true,linea22X:true,linea23X:true,linea24X:true,linea25X:true,linea26X:true,linea27X:true,linea28X:true,linea29X:true,linea30X:true})
                this.setState({linea31X:true,linea32X:true,linea33X:true,linea34X:true,linea35X:true,linea36X:true,linea37X:true,linea38X:true,linea39X:true,linea40X:true})
                this.setState({linea41X:true,linea42X:true,linea43X:true,linea44X:true,linea45X:true,linea46X:true,linea47X:true,linea48X:true})

                Promise.all(
                   [this.inicializa(),
                    this.llenaObservacionesFirmantes(this.state.fecha),
                    this.llenaFirmasFirmantes(this.state.fecha),
                    this.cierreReporteTurno(this.state.fecha)
                   ]
                ).then(
                  (resolve) =>{},
                  (reject) =>{}
                )
                //-----------------------------------------------------------------------------------
                const fechaX = this.state.fecha.substring(6, 10)+'-'+this.state.fecha.substring(3, 5)+'-'+this.state.fecha.substring(0, 2)+' 12:00:00'
                let fechaX2 = new Date(fechaX)
                fechaX2.setDate(fechaX2.getDate() - 1);
                let diaXDiaAnterior = fechaX2.getDate()
                let mesXDiaAnterior = fechaX2.getMonth()+1
                let anioXDiaAnterior = fechaX2.getFullYear()
                let dia2XDiaAnterior = 0
                let mes2XDiaAnterior = 0
                if (diaXDiaAnterior<10){dia2XDiaAnterior = '0'+ diaXDiaAnterior.toString()}else{ dia2XDiaAnterior = diaXDiaAnterior.toString()}
                if (mesXDiaAnterior<10){mes2XDiaAnterior = '0'+ mesXDiaAnterior.toString()}else{ mes2XDiaAnterior = mesXDiaAnterior.toString()}
                let fechaDiaAnterior = dia2XDiaAnterior+'-'+mes2XDiaAnterior+'-'+anioXDiaAnterior.toString()
                let fecha = this.state.fecha

                let jsonVersionesTurnoDiaAnteriorX = []
              	let jsonVersionesTurnoNocheAnteriorX = []
                let jsonVersionesTurnoDiaAnteriorFondoPozoX = []
              	let jsonVersionesTurnoNocheAnteriorFondoPozoX = []
              	if (this.state.authorizersX.length > 0){
              	    let authorizersXAdmSup = []
              	    this.state.authorizersX.map((todoauth,i) => {
              		    if (todoauth.conceptFormsDeliverable === 'ReporteTurno'){
              		        if (todoauth.role === 'Adm MD' || todoauth.role === 'Supervisor MD' || todoauth.role === 'Jefe Turno MD'){
              			       authorizersXAdmSup.push({conceptFormsDeliverable:todoauth.conceptFormsDeliverable,role:todoauth.role,id:todoauth.id,name:todoauth.name})
              		        }
              	    	}
              	    })
                		if (authorizersXAdmSup.length > 0){
    										let largoXDiaAnterior = authorizersXAdmSup.length
    										let fondoPozoTurnoDiaAnteriorX = 0
    										authorizersXAdmSup.map((todoauthDiaAnterior,iDiaAnterior) => {
    											Promise.all([this.goReporteTurnoDiaNames(fechaDiaAnterior,todoauthDiaAnterior.id,'version',jsonVersionesTurnoDiaAnteriorX,jsonVersionesTurnoNocheAnteriorX,todoauthDiaAnterior.role,todoauthDiaAnterior.name)]
    											  ).then(
    												(resolve) =>{
                              jsonVersionesTurnoDiaAnteriorX = resolve[0].arrayDiaX
                              if (resolve[0].arrayDiaFondoPozoX !== ''){
                                 jsonVersionesTurnoDiaAnteriorFondoPozoX.push(resolve[0].arrayDiaFondoPozoX)
                              }
    													if (iDiaAnterior === largoXDiaAnterior - 1){
    														TimerMixin.setTimeout( () => {
    															if (jsonVersionesTurnoDiaAnteriorFondoPozoX.length > 0){
    																let jsonVersionesTurnoDiaXAnterior2 = []
    																let largoTurnoDiaXAnterior2 = jsonVersionesTurnoDiaAnteriorFondoPozoX.length
    																let indiceTokenDiaXAnterior2 = 0
    																let auxTokenMayorDiaXAnterior2 = 0
    																let idTokenMayorDiaXAnterior2 = 0
    																jsonVersionesTurnoDiaAnteriorFondoPozoX.map((todoDiaAnterior,iDiaAnterior) => {
    																	if (todoDiaAnterior.token === undefined){
    																	   todoDiaAnterior.token = 0
    																	}
    																	if (iDiaAnterior === 0){
    																	   auxTokenMayorDiaXAnterior2 = todoDiaAnterior.token
    																	   indiceTokenDiaXAnterior2 = iDiaAnterior
    																	   idTokenMayorDiaXAnterior2 =  todoDiaAnterior.id
    																	}else{
    																		if (todoDiaAnterior.token > auxTokenMayorDiaXAnterior2){
    																			auxTokenMayorDiaXAnterior2 = todoDiaAnterior.token
    																			indiceTokenDiaXAnterior2 = iDiaAnterior
    																			idTokenMayorDiaXAnterior2 =  todoDiaAnterior.id
    																		}
    																	}
    																	if (iDiaAnterior === largoTurnoDiaXAnterior2 - 1){
    																		this.setState({userTurnoDiaFondoPozoX:idTokenMayorDiaXAnterior2})
    																		Promise.all([this.goFondoPozoTurnoDiaNames(fecha,idTokenMayorDiaXAnterior2, null, 'version')]
    																		   ).then(
    																			(resolve) =>{
    																				fondoPozoTurnoDiaAnteriorX = resolve[0].totalFondoPozoDia},
    																			(reject) =>{}
    																		)
    																	}
    																})
    															}
    														}, 2000);
    													}
    												},
    												(reject) =>{}
    												)
    										})
    										//-----------------------------------------------------------------------------------
    										let largoXNocheAnterior = authorizersXAdmSup.length
    										authorizersXAdmSup.map((todoauthNocheAnterior,iNocheAnterior) => {
    											Promise.all([this.goReporteTurnoNocheNames(fechaDiaAnterior,todoauthNocheAnterior.id,'version',jsonVersionesTurnoDiaAnteriorX,jsonVersionesTurnoNocheAnteriorX,todoauthNocheAnterior.role,todoauthNocheAnterior.name)]
    											  ).then(
    												(resolve) =>{
    													jsonVersionesTurnoNocheAnteriorX = resolve[0].arrayNocheX
                              if (resolve[0].arrayNocheFondoPozoX !== ''){
                                 jsonVersionesTurnoNocheAnteriorFondoPozoX.push(resolve[0].arrayNocheFondoPozoX)
                              }

    													if (iNocheAnterior === largoXNocheAnterior - 1){
    														TimerMixin.setTimeout( () => {
    															if (jsonVersionesTurnoNocheAnteriorFondoPozoX.length > 0){
    																let jsonVersionesTurnoNocheXAnterior2 = []
    																let largoTurnoNocheXAnterior2 = jsonVersionesTurnoNocheAnteriorFondoPozoX.length
    																let indiceTokenNocheXAnterior2 = 0
    																let auxTokenMayorNocheXAnterior2 = 0
    																let idTokenMayorNocheXAnterior2 = 0
    																jsonVersionesTurnoNocheAnteriorFondoPozoX.map((todoNocheAnterior,iNocheAnterior) => {
    																	if (todoNocheAnterior.token === undefined){
    																		todoNocheAnterior.token = 0
    																	}
    																	if (iNocheAnterior === 0){
    																		auxTokenMayorNocheXAnterior2 = todoNocheAnterior.token
    																		indiceTokenNocheXAnterior2 = iNocheAnterior
    																		idTokenMayorNocheXAnterior2 =  todoNocheAnterior.id
    																	}else{
    																		if (todoNocheAnterior.token > auxTokenMayorNocheXAnterior2){
    																			auxTokenMayorNocheXAnterior2 = todoNocheAnterior.token
    																			indiceTokenNocheXAnterior2 = iNocheAnterior
    																			idTokenMayorNocheXAnterior2 =  todoNocheAnterior.id
    																		}
    																	}
    																	if (iNocheAnterior === largoTurnoNocheXAnterior2 - 1){
    																		this.setState({userTurnoNocheFondoPozoX:idTokenMayorNocheXAnterior2})
    																		Promise.all([this.goFondoPozoTurnoNocheNames(fecha,idTokenMayorNocheXAnterior2,fondoPozoTurnoDiaAnteriorX,'version')]
    																		).then(
    																			(resolve) =>{},
    																			(reject) =>{}
    																		)
    																	}
    																})
    															}
    														}, 2000);
    													}
    												},
    												(reject) =>{}
    												)
    									  })
                        TimerMixin.setTimeout( () => {
                        }, 1000);
                		}
                }
                //-----------------------------------------------------------------------------------

                Promise.all(
                   [this.goReporteTurnoDiaNames(this.state.fecha,userTurnoDiaX,'display',null,null,null,null),
                    this.goReporteTurnoNocheNames(this.state.fecha,userTurnoNocheX,'display',null, null,null,null),
                    //this.goFondoPozoTurnoDiaNames(this.state.fecha,userTurnoDiaX, userTurnoNocheX, 'display'),
                    //this.goFondoPozoTurnoNocheNames(this.state.fecha,userTurnoNocheX,'display')
                   ]
                ).then(
                  (resolve) =>{
                    let e = ''
                    this.handlePerforados(e,1)
                    this.handlePerforados(e,2)
                    this.setState({displayLoader:false})
                    this.setState({ dialogOpenVersion: false })
                    this.setState({tokenMayorDiaX:tokenDiaX})
                    this.setState({tokenMayorNocheX:tokenNocheX})
                    if (userTurnoDiaX === this.props.username) {this.setState({eliminarTurnoDiaX:true})}else{this.setState({eliminarTurnoDiaX:false})}
                    if (userTurnoNocheX === this.props.username) {this.setState({eliminarTurnoNocheX:true})}else{this.setState({eliminarTurnoNocheX:false})}
                  },
                  (reject) =>{
                    let e = ''
                    this.handlePerforados(e,1)
                    this.handlePerforados(e,2)
                    this.setState({displayLoader:false})
                    this.setState({ dialogOpenVersion: false })
                    this.setState({tokenMayorDiaX:tokenDiaX})
                    this.setState({tokenMayorNocheX:tokenNocheX})
                    if (userTurnoDiaX === this.props.username) {this.setState({eliminarTurnoDiaX:true})}else{this.setState({eliminarTurnoDiaX:false})}
                    if (userTurnoNocheX === this.props.username) {this.setState({eliminarTurnoNocheX:true})}else{this.setState({eliminarTurnoNocheX:false})}
                  }
                )
              }
           }
         }
      }
    }

    handleChangeCheckTurno = (e, userX, modeTurnoX, indice, tokenX) => {
      if (modeTurnoX === 'dia') {
         let checkTurnoDiaX2 = this.state.checkTurnoDiaX
         for (let i = 0; i < checkTurnoDiaX2.length; i++) {
           if (i === indice){
             checkTurnoDiaX2[indice]=!checkTurnoDiaX2[indice]
             if (checkTurnoDiaX2[indice] === false){this.setState({idTurnoDiaX:''})}
             if (checkTurnoDiaX2[indice] === true){this.setState({idTurnoDiaX:userX, tokenMayorDiaX:tokenX})}
           }else{
             checkTurnoDiaX2[i]=false
           }
         }
         this.setState({checkTurnoDiaX:checkTurnoDiaX2})
      }
      if (modeTurnoX === 'noche') {
         let checkTurnoNocheX2 = this.state.checkTurnoNocheX
         for (let i = 0; i < checkTurnoNocheX2.length; i++) {
           if (i === indice){
              checkTurnoNocheX2[indice]=!checkTurnoNocheX2[indice]
              if (checkTurnoNocheX2[indice] === false){this.setState({idTurnoNocheX:''})}
              if (checkTurnoNocheX2[indice] === true){this.setState({idTurnoNocheX:userX, tokenMayorNocheX:tokenX})}
           }else{
              checkTurnoNocheX2[i]=false
           }
         }
         this.setState({checkTurnoNocheX:checkTurnoNocheX2})
      }
    }

    errorRangoFecha = () => {
      this.setState({dialogOpenError: true, dialogMessageError: 'Fuera de Rango de Fecha (según Orden de Trabajo) ', dialogTitleError: 'Error' })
    }

    handleDate = (val, chk, modeX) => {
      if (val !== null) {
        let fechaHoyX = new Date(val)
        let diaX = fechaHoyX.getDate()
        let mesX = fechaHoyX.getMonth()+1
        let anioX = fechaHoyX.getFullYear()
        let dia2X = 0
        let mes2X = 0
        if (diaX<10){dia2X = '0'+ diaX.toString()}else{ dia2X = diaX.toString()}
        if (mesX<10){mes2X = '0'+ mesX.toString()}else{ mes2X = mesX.toString()}
        let fecha_texto = anioX.toString()+'-'+mes2X+'-'+dia2X+'T13:00:00'
        let ms = Date.parse(fecha_texto);
        const fechaX = new Date(ms);
        const fechaHoy2X = anioX.toString()+mes2X+dia2X


        fechaHoyX = this.state.workorderfecharealinicio
        diaX = fechaHoyX.getDate()
        mesX = fechaHoyX.getMonth()+1
        anioX = fechaHoyX.getFullYear()
        dia2X = 0
        mes2X = 0
        if (diaX<10){dia2X = '0'+ diaX.toString()}else{ dia2X = diaX.toString()}
        if (mesX<10){mes2X = '0'+ mesX.toString()}else{ mes2X = mesX.toString()}
        const fechaInicio2X = anioX.toString()+mes2X+dia2X

        fechaHoyX = this.state.workorderfechaterminoperforacion
        diaX = fechaHoyX.getDate()
        mesX = fechaHoyX.getMonth()+1
        anioX = fechaHoyX.getFullYear()
        dia2X = 0
        mes2X = 0
        if (diaX<10){dia2X = '0'+ diaX.toString()}else{ dia2X = diaX.toString()}
        if (mesX<10){mes2X = '0'+ mesX.toString()}else{ mes2X = mesX.toString()}
        const fechaFin2X = anioX.toString()+mes2X+dia2X

        console.log(fechaX)
        console.log(this.state.workorderfecharealinicio)
        console.log(this.state.workorderfechaterminoperforacion)
        console.log(fechaHoy2X)
        console.log(fechaInicio2X)
        console.log(fechaFin2X)

        if (parseInt(fechaHoy2X) < parseInt(fechaInicio2X) || parseInt(fechaHoy2X) > parseInt(fechaFin2X) ){
           this.errorRangoFecha()
        }else{
           this.handleDate2(val, chk, modeX)
        }
      }else{
        this.handleDate2(val, chk, modeX)
      }
    }

    handleDate2 = (val, chk, modeX) => {
      this.setState({displayLoader:true})
      let folio = ''
      let folioDiaAnterior = ''
      if (chk==='fechaReporte'){
        if (modeX === 1){
          this.setState({fechaReporte: this.state.workorderfecharealinicio})
          folio = this.state.workorderfecharealinicio
          folioDiaAnterior = this.state.workorderfecharealinicio
        }
        if (modeX === 2){
          this.setState({fechaReporte: new Date(val)})
          folio = new Date(val)
          folioDiaAnterior = new Date(val)
        }

        let diaX = folio.getDate()
        let mesX = folio.getMonth()+1
        let anioX = folio.getFullYear()
        let dia2X = 0
        let mes2X = 0
        if (diaX<10){dia2X = '0'+ diaX.toString()}else{ dia2X = diaX.toString()}
        if (mesX<10){mes2X = '0'+ mesX.toString()}else{ mes2X = mesX.toString()}
        folio = anioX.toString()+mes2X+dia2X
        let fecha = dia2X+'-'+mes2X+'-'+anioX.toString()
        this.setState({fecha,folio})

        folioDiaAnterior.setDate(folioDiaAnterior.getDate() - 1);
        let diaXDiaAnterior = folioDiaAnterior.getDate()
        let mesXDiaAnterior = folioDiaAnterior.getMonth()+1
        let anioXDiaAnterior = folioDiaAnterior.getFullYear()
        let dia2XDiaAnterior = 0
        let mes2XDiaAnterior = 0
        if (diaXDiaAnterior<10){dia2XDiaAnterior = '0'+ diaXDiaAnterior.toString()}else{ dia2XDiaAnterior = diaXDiaAnterior.toString()}
        if (mesXDiaAnterior<10){mes2XDiaAnterior = '0'+ mesXDiaAnterior.toString()}else{ mes2XDiaAnterior = mesXDiaAnterior.toString()}
        folioDiaAnterior = anioXDiaAnterior.toString()+mes2XDiaAnterior+dia2XDiaAnterior
        let fechaDiaAnterior = dia2XDiaAnterior+'-'+mes2XDiaAnterior+'-'+anioXDiaAnterior.toString()
        this.setState({fechaDiaAnterior,folioDiaAnterior})
        this.setState({idTurnoDiaX:'', idTurnoNocheX:''})
        this.setState({eliminarTurnoDiaX:false, eliminarTurnoNocheX:false})
        this.setState({checkTurnoDiaX: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]})
        this.setState({checkTurnoNocheX: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]})
        this.setState({cierreReporteTurnoX: {}})
        this.setState({nuevoReporteTurnoX: true})
        this.setState({linea01X:true,linea02X:false,linea03X:false,linea04X:false,linea05X:false,linea06X:false,linea07X:false,linea08X:false,linea09X:false,linea10X:false})
        this.setState({linea11X:false,linea12X:false,linea13X:false,linea14X:false,linea15X:false,linea16X:false,linea17X:false,linea18X:false,linea19X:false,linea20X:false})
        this.setState({linea21X:false,linea22X:false,linea23X:false,linea24X:false,linea25X:true,linea26X:false,linea27X:false,linea28X:false,linea29X:false,linea30X:false})
        this.setState({linea31X:false,linea32X:false,linea33X:false,linea34X:false,linea35X:false,linea36X:false,linea37X:false,linea38X:false,linea39X:false,linea40X:false})
        this.setState({linea41X:false,linea42X:false,linea43X:false,linea44X:false,linea45X:false,linea46X:false,linea47X:false,linea48X:false})
        this.setState({jsonVersionesTurnoDiaX: [], jsonVersionesTurnoNocheX: []})
        this.setState({cancelVersion: false})


        Promise.all(
           [this.inicializa(),
            this.llenaFirmasFirmantes(fecha),
            this.cierreReporteTurno(fecha),
            this.llenaObservacionesFirmantes(fecha)
           ]
        ).then(
          (resolve) =>{},
          (reject) =>{}
        )
        let jsonVersionesTurnoDiaX = []
        let jsonVersionesTurnoNocheX = []
        let jsonVersionesTurnoDiaAnteriorX = []
        let jsonVersionesTurnoNocheAnteriorX = []
        if (this.state.authorizersX.length === 0){
          this.setState({displayLoader:false})
        }else{
          let authorizersXAdmSup = []
          this.state.authorizersX.map((todoauth,i) => {
            if (todoauth.conceptFormsDeliverable === 'ReporteTurno'){
              if (todoauth.role === 'Adm MD' || todoauth.role === 'Supervisor MD' || todoauth.role === 'Jefe Turno MD'){
                authorizersXAdmSup.push({conceptFormsDeliverable:todoauth.conceptFormsDeliverable,role:todoauth.role,id:todoauth.id,name:todoauth.name})
              }
            }
          })
          if (authorizersXAdmSup.length === 0){
            this.setState({displayLoader:false})
          }else{
            let largoX = authorizersXAdmSup.length
            authorizersXAdmSup.map((todoauth,i) => {
              Promise.all([this.goReporteTurnoDiaNames(fecha,todoauth.id,'version',jsonVersionesTurnoDiaX,jsonVersionesTurnoNocheX,todoauth.role,todoauth.name)]
                  ).then(
                    (resolve) =>{
                       jsonVersionesTurnoDiaX = resolve[0].arrayDiaX
                       Promise.all([this.goReporteTurnoNocheNames(fecha,todoauth.id,'version',jsonVersionesTurnoDiaX,jsonVersionesTurnoNocheX,todoauth.role,todoauth.name)]
                          ).then(
                            (resolve) =>{
                              jsonVersionesTurnoNocheX = resolve[0].arrayNocheX
                              if (i === largoX - 1){
                                this.setState({displayLoader:false})
                                this.setState({jsonVersionesTurnoDiaX:jsonVersionesTurnoDiaX},() =>{})
                                this.setState({jsonVersionesTurnoNocheX:jsonVersionesTurnoNocheX},() =>{})
                                //-----------------------------------------------------------------------------------
                                let largoXDiaAnterior = authorizersXAdmSup.length
                                let fondoPozoTurnoDiaAnteriorX = 0
                                authorizersXAdmSup.map((todoauthDiaAnterior,iDiaAnterior) => {
                                  Promise.all([this.goReporteTurnoDiaNames(fechaDiaAnterior,todoauthDiaAnterior.id,'version',jsonVersionesTurnoDiaAnteriorX,jsonVersionesTurnoNocheAnteriorX,todoauthDiaAnterior.role,todoauthDiaAnterior.name)]
                                      ).then(
                                        (resolve) =>{
                                            jsonVersionesTurnoDiaAnteriorX = resolve[0].arrayDiaX
                                            if (iDiaAnterior === largoXDiaAnterior - 1){
                                              TimerMixin.setTimeout( () => {
                                                if (jsonVersionesTurnoDiaAnteriorX.length > 0){
                                                  let jsonVersionesTurnoDiaXAnterior2 = []
                                                  let largoTurnoDiaXAnterior2 = jsonVersionesTurnoDiaAnteriorX.length
                                                  let indiceTokenDiaXAnterior2 = 0
                                                  let auxTokenMayorDiaXAnterior2 = 0
                                                  let idTokenMayorDiaXAnterior2 = 0
                                                  jsonVersionesTurnoDiaAnteriorX.map((todoDiaAnterior,iDiaAnterior) => {
                                                     if (todoDiaAnterior.token === undefined){todoDiaAnterior.token = 0}
                                                     if (iDiaAnterior === 0){
                                                       auxTokenMayorDiaXAnterior2 = todoDiaAnterior.token
                                                       indiceTokenDiaXAnterior2 = iDiaAnterior
                                                       idTokenMayorDiaXAnterior2 =  todoDiaAnterior.id
                                                     }else{
                                                       if (todoDiaAnterior.token > auxTokenMayorDiaXAnterior2){
                                                         auxTokenMayorDiaXAnterior2 = todoDiaAnterior.token
                                                         indiceTokenDiaXAnterior2 = iDiaAnterior
                                                         idTokenMayorDiaXAnterior2 =  todoDiaAnterior.id
                                                       }
                                                     }
                                                     if (iDiaAnterior === largoTurnoDiaXAnterior2 - 1){
                                                         this.setState({userTurnoDiaFondoPozoX:idTokenMayorDiaXAnterior2})
                                                         Promise.all([this.goFondoPozoTurnoDiaNames(fecha,idTokenMayorDiaXAnterior2, null, 'version')]
                                                         ).then(
                                                           (resolve) =>{

console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
console.log(resolve)
console.log(resolve[0].totalFondoPozoDia)

                                                             fondoPozoTurnoDiaAnteriorX = resolve[0].totalFondoPozoDia},
                                                           (reject) =>{}
                                                         )
                                                     }
                                                  })
                                                }
                                              }, 1000);
                                            }
                                        },
                                        (reject) =>{}
                                        )
                                })
                                //-----------------------------------------------------------------------------------
                                let largoXNocheAnterior = authorizersXAdmSup.length
                                authorizersXAdmSup.map((todoauthNocheAnterior,iNocheAnterior) => {

console.log('a1a1a1a1a1a1a1a1a1a1a1a1a1a1a1a1a1a')
console.log(todoauthNocheAnterior.id)

                                  Promise.all([this.goReporteTurnoNocheNames(fechaDiaAnterior,todoauthNocheAnterior.id,'version',jsonVersionesTurnoDiaAnteriorX,jsonVersionesTurnoNocheAnteriorX,todoauthNocheAnterior.role,todoauthNocheAnterior.name)]
                                      ).then(
                                        (resolve) =>{
                                            jsonVersionesTurnoNocheAnteriorX = resolve[0].arrayNocheX

                                            console.log('a2a2a2a2a2a2a2a2a2a2a2a2a2a2a2a2a2a2a')
                                            console.log(jsonVersionesTurnoNocheAnteriorX)


                                            if (iNocheAnterior === largoXNocheAnterior - 1){
                                              TimerMixin.setTimeout( () => {

                                                console.log('a3a3a3a3a3a3a3a3a3a3a3a3a3a3a3a3a3a')
                                                console.log(jsonVersionesTurnoNocheAnteriorX)
                                                console.log(jsonVersionesTurnoNocheAnteriorX.length)

                                                if (jsonVersionesTurnoNocheAnteriorX.length > 0){
                                                  let jsonVersionesTurnoNocheXAnterior2 = []
                                                  let largoTurnoNocheXAnterior2 = jsonVersionesTurnoNocheAnteriorX.length
                                                  let indiceTokenNocheXAnterior2 = 0
                                                  let auxTokenMayorNocheXAnterior2 = 0
                                                  let idTokenMayorNocheXAnterior2 = 0
                                                  jsonVersionesTurnoNocheAnteriorX.map((todoNocheAnterior,iNocheAnterior) => {
                                                     if (todoNocheAnterior.token === undefined){todoNocheAnterior.token = 0}
                                                     if (iNocheAnterior === 0){
                                                       auxTokenMayorNocheXAnterior2 = todoNocheAnterior.token
                                                       indiceTokenNocheXAnterior2 = iNocheAnterior
                                                       idTokenMayorNocheXAnterior2 =  todoNocheAnterior.id
                                                     }else{
                                                       if (todoNocheAnterior.token > auxTokenMayorNocheXAnterior2){
                                                         auxTokenMayorNocheXAnterior2 = todoNocheAnterior.token
                                                         indiceTokenNocheXAnterior2 = iNocheAnterior
                                                         idTokenMayorNocheXAnterior2 =  todoNocheAnterior.id
                                                       }
                                                     }
                                                     if (iNocheAnterior === largoTurnoNocheXAnterior2 - 1){

                                                         console.log('a4a4a4a4a4a4a4a4a4a4a4a4a4a4a4a')
                                                         console.log(idTokenMayorNocheXAnterior2)
                                                         console.log(fondoPozoTurnoDiaAnteriorX)

                                                         this.setState({userTurnoNocheFondoPozoX:idTokenMayorNocheXAnterior2})
                                                         Promise.all([this.goFondoPozoTurnoNocheNames(fecha,idTokenMayorNocheXAnterior2,fondoPozoTurnoDiaAnteriorX,'version')]
                                                         ).then(
                                                           (resolve) =>{},
                                                           (reject) =>{}
                                                         )
                                                     }
                                                  })
                                                }
                                              }, 1400);
                                            }
                                        },
                                        (reject) =>{}
                                        )
                                })
                                //-----------------------------------------------------------------------------------

                                TimerMixin.setTimeout( () => {
                                  if (jsonVersionesTurnoDiaX.length > 0 || jsonVersionesTurnoNocheX.length > 0){
                                    if (jsonVersionesTurnoDiaX.length > 0){
                                      let jsonVersionesTurnoDiaX2 = []
                                      let largoTurnoDiaX2 = jsonVersionesTurnoDiaX.length
                                      let indiceTokenDiaX2 = 0
                                      let auxTokenMayorDiaX2 = 0
                                      jsonVersionesTurnoDiaX.map((todoDia,i) => {
                                         if (todoDia.token === undefined){todoDia.token = 0}
                                         if (i === 0){
                                           auxTokenMayorDiaX2 = todoDia.token
                                           indiceTokenDiaX2 = i
                                         }else{
                                           if (todoDia.token > auxTokenMayorDiaX2){
                                             auxTokenMayorDiaX2 = todoDia.token
                                             indiceTokenDiaX2 = i
                                           }
                                         }
                                         if (i === largoTurnoDiaX2 - 1){
                                             jsonVersionesTurnoDiaX2.push(jsonVersionesTurnoDiaX[indiceTokenDiaX2])
                                         }
                                      })
                                      let existeFirmaDiaX = false
                                      let largoX2 = jsonVersionesTurnoDiaX2.length
                                      let archivoX = ''
                                      jsonVersionesTurnoDiaX2.map((todoDia2,i2) => {
                                        archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fecha}_reporte_turno_firma_supervisor_md_dia.json`
                                        Promise.all([this.getFirma(archivoX,todoDia2.vReporteTurnoDia04_ID,1,'version')]).then((resolve) =>{
                                          if (resolve[0] === undefined || resolve[0] === null || resolve[0] === ''){
                                            todoDia2.firma1 = false
                                          }else{
                                            todoDia2.firma1 = resolve[0]
                                            if (resolve[0] === true){existeFirmaDiaX = true}
                                          }
                                          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fecha}_reporte_turno_firma_jefe_turno_dia.json`
                                          Promise.all([this.getFirma(archivoX,todoDia2.vReporteTurnoDia01_ID,6,'version')]).then((resolve) =>{
                                            if (resolve[0] === undefined || resolve[0] === null || resolve[0] === ''){
                                              todoDia2.firma2 = false
                                            }else{
                                              todoDia2.firma2 = resolve[0]
                                              if (resolve[0] === true){existeFirmaDiaX = true}
                                            }
                                            if (this.state.cierreReporteTurnoX.close){
                                              todoDia2.firma3 = true
                                              todoDia2.firma4 = true
                                              todoDia2.firma5 = true
                                              todoDia2.cierre = true
                                              existeFirmaDiaX = true
                                            }else{
                                              todoDia2.firma3 = this.state.cReporteTurnoSignatureFirmante3
                                              todoDia2.firma4 = this.state.cReporteTurnoSignatureFirmante4
                                              todoDia2.firma5 = this.state.cReporteTurnoSignatureFirmante5
                                              todoDia2.cierre = this.state.cierreReporteTurnoX.close
                                            }
                                            if (i2 === largoX2 - 1){
                                              this.setState({jsonVersionesTurnoDiaX:jsonVersionesTurnoDiaX2,existeFirmaDiaX},() =>{})
                                            }
                                          },(reject) =>{})
                                        },(reject) =>{})
                                      })
                                    }
                                    TimerMixin.setTimeout( () => {
                                      if (jsonVersionesTurnoNocheX.length > 0 || jsonVersionesTurnoNocheX !== []){
                                        let jsonVersionesTurnoNocheX2 = []
                                        let largoTurnoNocheX2 = jsonVersionesTurnoNocheX.length
                                        let indiceTokenNocheX2 = 0
                                        let auxTokenMayorNocheX2 = 0
                                        jsonVersionesTurnoNocheX.map((todoNoche,j) => {
                                           if (todoNoche.token === undefined){todoNoche.token = 0}
                                           if (j === 0){
                                             auxTokenMayorNocheX2 = todoNoche.token
                                             indiceTokenNocheX2 = j
                                           }else{
                                             if (todoNoche.token > auxTokenMayorNocheX2){
                                               auxTokenMayorNocheX2 = todoNoche.token
                                               indiceTokenNocheX2 = j
                                             }
                                           }
                                           if (j === largoTurnoNocheX2 - 1){
                                               jsonVersionesTurnoNocheX2.push(jsonVersionesTurnoNocheX[indiceTokenNocheX2])
                                           }
                                        })
                                        let existeFirmaNocheX = false
                                        let largoX3 = jsonVersionesTurnoNocheX2.length
                                        let archivoX3 = ''
                                        jsonVersionesTurnoNocheX2.map((todoNoche2,j2) => {
                                          archivoX3 = `${this.props.typeContract}_${this.props.numberContract}_${fecha}_reporte_turno_firma_supervisor_md_noche.json`
                                          Promise.all([this.getFirma(archivoX3,todoNoche2.vReporteTurnoNoche04_ID,2,'version')]).then((resolve) =>{
                                            if (resolve[0] === undefined || resolve[0] === null || resolve[0] === ''){
                                              todoNoche2.firma1 = false
                                            }else{
                                              todoNoche2.firma1 = resolve[0]
                                              if (resolve[0] === true){existeFirmaNocheX = true}
                                            }
                                            archivoX3 = `${this.props.typeContract}_${this.props.numberContract}_${fecha}_reporte_turno_firma_jefe_turno_noche.json`
                                            Promise.all([this.getFirma(archivoX3,todoNoche2.vReporteTurnoNoche01_ID,7,'version')]).then((resolve) =>{
                                              if (resolve[0] === undefined || resolve[0] === null || resolve[0] === ''){
                                                todoNoche2.firma2 = false
                                              }else{
                                                todoNoche2.firma2 = resolve[0]
                                                if (resolve[0] === true){existeFirmaNocheX = true}
                                              }
                                              if (this.state.cierreReporteTurnoX.close){
                                                todoNoche2.firma3 = true
                                                todoNoche2.firma4 = true
                                                todoNoche2.firma5 = true
                                                todoNoche2.cierre = true
                                                existeFirmaNocheX = true
                                              }else{
                                                todoNoche2.firma3 = this.state.cReporteTurnoSignatureFirmante3
                                                todoNoche2.firma4 = this.state.cReporteTurnoSignatureFirmante4
                                                todoNoche2.firma5 = this.state.cReporteTurnoSignatureFirmante5
                                                todoNoche2.cierre = this.state.cierreReporteTurnoX.close
                                              }
                                              if (j2 === largoX3 - 1){
                                                this.setState({jsonVersionesTurnoNocheX:jsonVersionesTurnoNocheX2, existeFirmaNocheX},() =>{})
                                              }
                                            },(reject) =>{})
                                          },(reject) =>{})
                                        })
                                      }
                                    }, 1400);
                                    TimerMixin.setTimeout( () => {
                                         this.setState({dialogOpenVersion: true, dialogMessage: 'Resumen Reporte de Turno ', dialogTitle: 'Resumen' })
                                    }, 700);
                                  }else{
                                    TimerMixin.setTimeout( () => {
                                      if (jsonVersionesTurnoDiaAnteriorX.length === 0 && jsonVersionesTurnoNocheAnteriorX.length === 0){
                                        if (this.state.authRoleX === 'Supervisor MD' || this.state.authRoleX === 'Adm MD' || this.state.authRoleX === 'Jefe Turno MD'){
                                           this.setState({dialogOpenNoHayDiaAnterior: true, dialogMessage: 'Advertencia: No hay datos ingresados en el día anterior, ¿desea continuar?', dialogTitle: 'Precaución' })
                                        }
                                      }
                                    }, 1400);
                                  }
                                }, 1000);
                              }
                          },
                          (reject) =>{}
                          )
                    },
                    (reject) =>{}
                    )
            })
          }
        }
      }
    }

    handleActividades = (e,optX) => {

      this.limpiaLinea(optX)

      let palabra = ''
      let ArrayIdValue = ''
      palabra = e.target.value
      ArrayIdValue = palabra.split('&&&');
      if (ArrayIdValue[2] !== undefined){
        if (optX === 1) { palabra = this.cReporteTurnoDia08a09_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia08a09_11.current.value = ArrayIdValue[2]; this.cReporteTurnoDia08a09_12.current.value = ArrayIdValue[3]};
        if (optX === 2) { palabra = this.cReporteTurnoDia08a09_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia08a09_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoDia08a09_12_2.current.value = ArrayIdValue[3]};
        if (optX === 3) { palabra = this.cReporteTurnoDia09a10_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia09a10_11.current.value = ArrayIdValue[2]; this.cReporteTurnoDia09a10_12.current.value = ArrayIdValue[3]};
        if (optX === 4) { palabra = this.cReporteTurnoDia09a10_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia09a10_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoDia09a10_12_2.current.value = ArrayIdValue[3]};
        if (optX === 5) { palabra = this.cReporteTurnoDia10a11_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia10a11_11.current.value = ArrayIdValue[2]; this.cReporteTurnoDia10a11_12.current.value = ArrayIdValue[3]};
        if (optX === 6) { palabra = this.cReporteTurnoDia10a11_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia10a11_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoDia10a11_12_2.current.value = ArrayIdValue[3]};
        if (optX === 7) { palabra = this.cReporteTurnoDia11a12_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia11a12_11.current.value = ArrayIdValue[2]; this.cReporteTurnoDia11a12_12.current.value = ArrayIdValue[3]};
        if (optX === 8) { palabra = this.cReporteTurnoDia11a12_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia11a12_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoDia11a12_12_2.current.value = ArrayIdValue[3]};
        if (optX === 9) { palabra = this.cReporteTurnoDia12a13_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia12a13_11.current.value = ArrayIdValue[2]; this.cReporteTurnoDia12a13_12.current.value = ArrayIdValue[3]};
        if (optX === 10) { palabra = this.cReporteTurnoDia12a13_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia12a13_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoDia12a13_12_2.current.value = ArrayIdValue[3]};
        if (optX === 11) { palabra = this.cReporteTurnoDia13a14_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia13a14_11.current.value = ArrayIdValue[2]; this.cReporteTurnoDia13a14_12.current.value = ArrayIdValue[3]};
        if (optX === 12) { palabra = this.cReporteTurnoDia13a14_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia13a14_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoDia13a14_12_2.current.value = ArrayIdValue[3]};
        if (optX === 13) { palabra = this.cReporteTurnoDia14a15_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia14a15_11.current.value = ArrayIdValue[2]; this.cReporteTurnoDia14a15_12.current.value = ArrayIdValue[3]};
        if (optX === 14) { palabra = this.cReporteTurnoDia14a15_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia14a15_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoDia14a15_12_2.current.value = ArrayIdValue[3]};
        if (optX === 15) { palabra = this.cReporteTurnoDia15a16_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia15a16_11.current.value = ArrayIdValue[2]; this.cReporteTurnoDia15a16_12.current.value = ArrayIdValue[3]};
        if (optX === 16) { palabra = this.cReporteTurnoDia15a16_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia15a16_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoDia15a16_12_2.current.value = ArrayIdValue[3]};
        if (optX === 17) { palabra = this.cReporteTurnoDia16a17_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia16a17_11.current.value = ArrayIdValue[2]; this.cReporteTurnoDia16a17_12.current.value = ArrayIdValue[3]};
        if (optX === 18) { palabra = this.cReporteTurnoDia16a17_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia16a17_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoDia16a17_12_2.current.value = ArrayIdValue[3]};
        if (optX === 19) { palabra = this.cReporteTurnoDia17a18_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia17a18_11.current.value = ArrayIdValue[2]; this.cReporteTurnoDia17a18_12.current.value = ArrayIdValue[3]};
        if (optX === 20) { palabra = this.cReporteTurnoDia17a18_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia17a18_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoDia17a18_12_2.current.value = ArrayIdValue[3]};
        if (optX === 21) { palabra = this.cReporteTurnoDia18a19_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia18a19_11.current.value = ArrayIdValue[2]; this.cReporteTurnoDia18a19_12.current.value = ArrayIdValue[3]};
        if (optX === 22) { palabra = this.cReporteTurnoDia18a19_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia18a19_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoDia18a19_12_2.current.value = ArrayIdValue[3]};
        if (optX === 23) { palabra = this.cReporteTurnoDia19a20_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia19a20_11.current.value = ArrayIdValue[2]; this.cReporteTurnoDia19a20_12.current.value = ArrayIdValue[3]};
        if (optX === 24) { palabra = this.cReporteTurnoDia19a20_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoDia19a20_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoDia19a20_12_2.current.value = ArrayIdValue[3]};

        if (optX === 25) { palabra = this.cReporteTurnoNoche20a21_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche20a21_11.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche20a21_12.current.value = ArrayIdValue[3]};
        if (optX === 26) { palabra = this.cReporteTurnoNoche20a21_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche20a21_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche20a21_12_2.current.value = ArrayIdValue[3]};
        if (optX === 27) { palabra = this.cReporteTurnoNoche21a22_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche21a22_11.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche21a22_12.current.value = ArrayIdValue[3]};
        if (optX === 28) { palabra = this.cReporteTurnoNoche21a22_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche21a22_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche21a22_12_2.current.value = ArrayIdValue[3]};
        if (optX === 29) { palabra = this.cReporteTurnoNoche22a23_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche22a23_11.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche22a23_12.current.value = ArrayIdValue[3]};
        if (optX === 30) { palabra = this.cReporteTurnoNoche22a23_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche22a23_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche22a23_12_2.current.value = ArrayIdValue[3]};
        if (optX === 31) { palabra = this.cReporteTurnoNoche23a24_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche23a24_11.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche23a24_12.current.value = ArrayIdValue[3]};
        if (optX === 32) { palabra = this.cReporteTurnoNoche23a24_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche23a24_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche23a24_12_2.current.value = ArrayIdValue[3]};
        if (optX === 33) { palabra = this.cReporteTurnoNoche24a01_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche24a01_11.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche24a01_12.current.value = ArrayIdValue[3]};
        if (optX === 34) { palabra = this.cReporteTurnoNoche24a01_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche24a01_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche24a01_12_2.current.value = ArrayIdValue[3]};
        if (optX === 35) { palabra = this.cReporteTurnoNoche01a02_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche01a02_11.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche01a02_12.current.value = ArrayIdValue[3]};
        if (optX === 36) { palabra = this.cReporteTurnoNoche01a02_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche01a02_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche01a02_12_2.current.value = ArrayIdValue[3]};
        if (optX === 37) { palabra = this.cReporteTurnoNoche02a03_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche02a03_11.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche02a03_12.current.value = ArrayIdValue[3]};
        if (optX === 38) { palabra = this.cReporteTurnoNoche02a03_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche02a03_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche02a03_12_2.current.value = ArrayIdValue[3]};
        if (optX === 39) { palabra = this.cReporteTurnoNoche03a04_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche03a04_11.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche03a04_12.current.value = ArrayIdValue[3]};
        if (optX === 40) { palabra = this.cReporteTurnoNoche03a04_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche03a04_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche03a04_12_2.current.value = ArrayIdValue[3]};
        if (optX === 41) { palabra = this.cReporteTurnoNoche04a05_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche04a05_11.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche04a05_12.current.value = ArrayIdValue[3]};
        if (optX === 42) { palabra = this.cReporteTurnoNoche04a05_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche04a05_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche04a05_12_2.current.value = ArrayIdValue[3]};
        if (optX === 43) { palabra = this.cReporteTurnoNoche05a06_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche05a06_11.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche05a06_12.current.value = ArrayIdValue[3]};
        if (optX === 44) { palabra = this.cReporteTurnoNoche05a06_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche05a06_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche05a06_12_2.current.value = ArrayIdValue[3]};
        if (optX === 45) { palabra = this.cReporteTurnoNoche06a07_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche06a07_11.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche06a07_12.current.value = ArrayIdValue[3]};
        if (optX === 46) { palabra = this.cReporteTurnoNoche06a07_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche06a07_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche06a07_12_2.current.value = ArrayIdValue[3]};
        if (optX === 47) { palabra = this.cReporteTurnoNoche07a08_01.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche07a08_11.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche07a08_12.current.value = ArrayIdValue[3]};
        if (optX === 48) { palabra = this.cReporteTurnoNoche07a08_01_2.current.value; ArrayIdValue = palabra.split('&&&'); this.cReporteTurnoNoche07a08_11_2.current.value = ArrayIdValue[2]; this.cReporteTurnoNoche07a08_12_2.current.value = ArrayIdValue[3]};

        if (optX===1){let displayDetalle1X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle1X=false};this.setState({displayDetalle1X})}
        if (optX===2){let displayDetalle2X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle2X=false};this.setState({displayDetalle2X})}
        if (optX===3){let displayDetalle3X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle3X=false};this.setState({displayDetalle3X})}
        if (optX===4){let displayDetalle4X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle4X=false};this.setState({displayDetalle4X})}
        if (optX===5){let displayDetalle5X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle5X=false};this.setState({displayDetalle5X})}
        if (optX===6){let displayDetalle6X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle6X=false};this.setState({displayDetalle6X})}
        if (optX===7){let displayDetalle7X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle7X=false};this.setState({displayDetalle7X})}
        if (optX===8){let displayDetalle8X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle8X=false};this.setState({displayDetalle8X})}
        if (optX===9){let displayDetalle9X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle9X=false};this.setState({displayDetalle9X})}
        if (optX===10){let displayDetalle10X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle10X=false};this.setState({displayDetalle10X})}
        if (optX===11){let displayDetalle11X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle11X=false};this.setState({displayDetalle11X})}
        if (optX===12){let displayDetalle12X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle12X=false};this.setState({displayDetalle12X})}
        if (optX===13){let displayDetalle13X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle13X=false};this.setState({displayDetalle13X})}
        if (optX===14){let displayDetalle14X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle14X=false};this.setState({displayDetalle14X})}
        if (optX===15){let displayDetalle15X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle15X=false};this.setState({displayDetalle15X})}
        if (optX===16){let displayDetalle16X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle16X=false};this.setState({displayDetalle16X})}
        if (optX===17){let displayDetalle17X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle17X=false};this.setState({displayDetalle17X})}
        if (optX===18){let displayDetalle18X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle18X=false};this.setState({displayDetalle18X})}
        if (optX===19){let displayDetalle19X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle19X=false};this.setState({displayDetalle19X})}
        if (optX===20){let displayDetalle20X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle20X=false};this.setState({displayDetalle20X})}
        if (optX===21){let displayDetalle21X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle21X=false};this.setState({displayDetalle21X})}
        if (optX===22){let displayDetalle22X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle22X=false};this.setState({displayDetalle22X})}
        if (optX===23){let displayDetalle23X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle23X=false};this.setState({displayDetalle23X})}
        if (optX===24){let displayDetalle24X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle24X=false};this.setState({displayDetalle24X})}
        if (optX===25){let displayDetalle25X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle25X=false};this.setState({displayDetalle25X})}
        if (optX===26){let displayDetalle26X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle26X=false};this.setState({displayDetalle26X})}
        if (optX===27){let displayDetalle27X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle27X=false};this.setState({displayDetalle27X})}
        if (optX===28){let displayDetalle28X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle28X=false};this.setState({displayDetalle28X})}
        if (optX===29){let displayDetalle29X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle29X=false};this.setState({displayDetalle29X})}
        if (optX===30){let displayDetalle30X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle30X=false};this.setState({displayDetalle30X})}
        if (optX===31){let displayDetalle31X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle31X=false};this.setState({displayDetalle31X})}
        if (optX===32){let displayDetalle32X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle32X=false};this.setState({displayDetalle32X})}
        if (optX===33){let displayDetalle33X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle33X=false};this.setState({displayDetalle33X})}
        if (optX===34){let displayDetalle34X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle34X=false};this.setState({displayDetalle34X})}
        if (optX===35){let displayDetalle35X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle35X=false};this.setState({displayDetalle35X})}
        if (optX===36){let displayDetalle36X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle36X=false};this.setState({displayDetalle36X})}
        if (optX===37){let displayDetalle37X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle37X=false};this.setState({displayDetalle37X})}
        if (optX===38){let displayDetalle38X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle38X=false};this.setState({displayDetalle38X})}
        if (optX===39){let displayDetalle39X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle39X=false};this.setState({displayDetalle39X})}
        if (optX===40){let displayDetalle40X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle40X=false};this.setState({displayDetalle40X})}
        if (optX===41){let displayDetalle41X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle41X=false};this.setState({displayDetalle41X})}
        if (optX===42){let displayDetalle42X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle42X=false};this.setState({displayDetalle42X})}
        if (optX===43){let displayDetalle43X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle43X=false};this.setState({displayDetalle43X})}
        if (optX===44){let displayDetalle44X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle44X=false};this.setState({displayDetalle44X})}
        if (optX===45){let displayDetalle45X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle45X=false};this.setState({displayDetalle45X})}
        if (optX===46){let displayDetalle46X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle46X=false};this.setState({displayDetalle46X})}
        if (optX===47){let displayDetalle47X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle47X=false};this.setState({displayDetalle47X})}
        if (optX===48){let displayDetalle48X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle48X=false};this.setState({displayDetalle48X})}
      }
    }

    handleMetroInicio = (e, modeX, optX) => {

      let palabra = ''
      let ArrayIdValue = ''
      let bSigueX = true
      if (optX === 1) { palabra = this.cReporteTurnoDia08a09_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 2) { palabra = this.cReporteTurnoDia08a09_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 3) { palabra = this.cReporteTurnoDia09a10_01.current.value; ArrayIdValue = palabra.split('&&&');if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 4) { palabra = this.cReporteTurnoDia09a10_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 5) { palabra = this.cReporteTurnoDia10a11_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 6) { palabra = this.cReporteTurnoDia10a11_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 7) { palabra = this.cReporteTurnoDia11a12_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 8) { palabra = this.cReporteTurnoDia11a12_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 9) { palabra = this.cReporteTurnoDia12a13_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 10) { palabra = this.cReporteTurnoDia12a13_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 11) { palabra = this.cReporteTurnoDia13a14_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 12) { palabra = this.cReporteTurnoDia13a14_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 13) { palabra = this.cReporteTurnoDia14a15_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 14) { palabra = this.cReporteTurnoDia14a15_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 15) { palabra = this.cReporteTurnoDia15a16_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 16) { palabra = this.cReporteTurnoDia15a16_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 17) { palabra = this.cReporteTurnoDia16a17_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 18) { palabra = this.cReporteTurnoDia16a17_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 19) { palabra = this.cReporteTurnoDia17a18_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 20) { palabra = this.cReporteTurnoDia17a18_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 21) { palabra = this.cReporteTurnoDia18a19_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 22) { palabra = this.cReporteTurnoDia18a19_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 23) { palabra = this.cReporteTurnoDia19a20_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 24) { palabra = this.cReporteTurnoDia19a20_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};

      if (optX === 25) { palabra = this.cReporteTurnoNoche20a21_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 26) { palabra = this.cReporteTurnoNoche20a21_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 27) { palabra = this.cReporteTurnoNoche21a22_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 28) { palabra = this.cReporteTurnoNoche21a22_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 29) { palabra = this.cReporteTurnoNoche22a23_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 30) { palabra = this.cReporteTurnoNoche22a23_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 31) { palabra = this.cReporteTurnoNoche23a24_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 32) { palabra = this.cReporteTurnoNoche23a24_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 33) { palabra = this.cReporteTurnoNoche24a01_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 34) { palabra = this.cReporteTurnoNoche24a01_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 35) { palabra = this.cReporteTurnoNoche01a02_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 36) { palabra = this.cReporteTurnoNoche01a02_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 37) { palabra = this.cReporteTurnoNoche02a03_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 38) { palabra = this.cReporteTurnoNoche02a03_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 39) { palabra = this.cReporteTurnoNoche03a04_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 40) { palabra = this.cReporteTurnoNoche03a04_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 41) { palabra = this.cReporteTurnoNoche04a05_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 42) { palabra = this.cReporteTurnoNoche04a05_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 43) { palabra = this.cReporteTurnoNoche05a06_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 44) { palabra = this.cReporteTurnoNoche05a06_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 45) { palabra = this.cReporteTurnoNoche06a07_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 46) { palabra = this.cReporteTurnoNoche06a07_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 47) { palabra = this.cReporteTurnoNoche07a08_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 48) { palabra = this.cReporteTurnoNoche07a08_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};

      if (bSigueX) {
        let bPasa = true
        if (modeX !== 'Duplicar'){
          if (e.target.value !== ''){
             let mtIniX = parseFloat(e.target.value)
             if (mtIniX < 0 || isNaN(mtIniX) || mtIniX < this.state.consolidadoFondoOrigenDiaX ||
                (modeX === 'Dia' && !isNaN(parseFloat(this.cReporteTurnoDia06.current.value)) && mtIniX < parseFloat(this.cReporteTurnoDia06.current.value)) ||
                (modeX === 'Noche' && !isNaN(parseFloat(this.cReporteTurnoNoche06.current.value)) && mtIniX < parseFloat(this.cReporteTurnoNoche06.current.value))) {
                     bPasa=false
                     e.target.value = ''
             }else{
                 if (optX === 1){if (mtIniX > parseFloat(this.cReporteTurnoDia08a09_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 2){if (mtIniX > parseFloat(this.cReporteTurnoDia08a09_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 3){if (mtIniX > parseFloat(this.cReporteTurnoDia09a10_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 4){if (mtIniX > parseFloat(this.cReporteTurnoDia09a10_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 5){if (mtIniX > parseFloat(this.cReporteTurnoDia10a11_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 6){if (mtIniX > parseFloat(this.cReporteTurnoDia10a11_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 7){if (mtIniX > parseFloat(this.cReporteTurnoDia11a12_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 8){if (mtIniX > parseFloat(this.cReporteTurnoDia11a12_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 9){if (mtIniX > parseFloat(this.cReporteTurnoDia12a13_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 10){if (mtIniX > parseFloat(this.cReporteTurnoDia12a13_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 11){if (mtIniX > parseFloat(this.cReporteTurnoDia13a14_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 12){if (mtIniX > parseFloat(this.cReporteTurnoDia13a14_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 13){if (mtIniX > parseFloat(this.cReporteTurnoDia14a15_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 14){if (mtIniX > parseFloat(this.cReporteTurnoDia14a15_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 15){if (mtIniX > parseFloat(this.cReporteTurnoDia15a16_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 16){if (mtIniX > parseFloat(this.cReporteTurnoDia15a16_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 17){if (mtIniX > parseFloat(this.cReporteTurnoDia16a17_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 18){if (mtIniX > parseFloat(this.cReporteTurnoDia16a17_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 19){if (mtIniX > parseFloat(this.cReporteTurnoDia17a18_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 20){if (mtIniX > parseFloat(this.cReporteTurnoDia17a18_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 21){if (mtIniX > parseFloat(this.cReporteTurnoDia18a19_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 22){if (mtIniX > parseFloat(this.cReporteTurnoDia18a19_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 23){if (mtIniX > parseFloat(this.cReporteTurnoDia19a20_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 24){if (mtIniX > parseFloat(this.cReporteTurnoDia19a20_09_2.current.value)){e.target.value = '';bPasa=false}}

                 if (optX === 25){if (mtIniX > parseFloat(this.cReporteTurnoNoche20a21_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 26){if (mtIniX > parseFloat(this.cReporteTurnoNoche20a21_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 27){if (mtIniX > parseFloat(this.cReporteTurnoNoche21a22_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 28){if (mtIniX > parseFloat(this.cReporteTurnoNoche21a22_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 29){if (mtIniX > parseFloat(this.cReporteTurnoNoche22a23_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 30){if (mtIniX > parseFloat(this.cReporteTurnoNoche22a23_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 31){if (mtIniX > parseFloat(this.cReporteTurnoNoche23a24_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 32){if (mtIniX > parseFloat(this.cReporteTurnoNoche23a24_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 33){if (mtIniX > parseFloat(this.cReporteTurnoNoche24a01_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 34){if (mtIniX > parseFloat(this.cReporteTurnoNoche24a01_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 35){if (mtIniX > parseFloat(this.cReporteTurnoNoche01a02_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 36){if (mtIniX > parseFloat(this.cReporteTurnoNoche01a02_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 37){if (mtIniX > parseFloat(this.cReporteTurnoNoche02a03_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 38){if (mtIniX > parseFloat(this.cReporteTurnoNoche02a03_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 39){if (mtIniX > parseFloat(this.cReporteTurnoNoche03a04_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 40){if (mtIniX > parseFloat(this.cReporteTurnoNoche03a04_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 41){if (mtIniX > parseFloat(this.cReporteTurnoNoche04a05_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 42){if (mtIniX > parseFloat(this.cReporteTurnoNoche04a05_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 43){if (mtIniX > parseFloat(this.cReporteTurnoNoche05a06_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 44){if (mtIniX > parseFloat(this.cReporteTurnoNoche05a06_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 45){if (mtIniX > parseFloat(this.cReporteTurnoNoche06a07_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 46){if (mtIniX > parseFloat(this.cReporteTurnoNoche06a07_09_2.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 47){if (mtIniX > parseFloat(this.cReporteTurnoNoche07a08_09.current.value)){e.target.value = '';bPasa=false}}
                 if (optX === 48){if (mtIniX > parseFloat(this.cReporteTurnoNoche07a08_09_2.current.value)){e.target.value = '';bPasa=false}}
             }
             if (bPasa === false){
                this.setState({dialogOpenError: true, dialogMessageError: 'Metros Inicio Erróneo ', dialogTitleError: 'Error' });
                modeX = ''
             }
           }
         }

         if (modeX==='Dia'){
           let mtIniX = parseFloat(e.target.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
           e.target.value = mtIniX
           mtIniX = ''
           let btIniX = false
           this.cReporteTurnoDia06.current.value = ''
           if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = ''}
           this.cReporteTurnoDia08.current.value = 0

           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia08a09_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia08a09_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia08a09_08.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia08a09_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia08a09_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia08a09_08_2.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia09a10_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia09a10_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia09a10_08.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia09a10_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia09a10_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia09a10_08_2.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia10a11_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia10a11_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia10a11_08.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia10a11_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia10a11_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia10a11_08_2.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia11a12_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia11a12_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia11a12_08.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia11a12_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia11a12_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia11a12_08_2.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia12a13_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia12a13_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia12a13_08.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia12a13_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia12a13_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia12a13_08_2.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia13a14_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia13a14_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia13a14_08.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia13a14_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia13a14_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia13a14_08_2.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia14a15_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia14a15_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia14a15_08.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia14a15_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia14a15_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia14a15_08_2.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia15a16_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia15a16_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia15a16_08.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia15a16_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia15a16_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia15a16_08_2.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia16a17_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia16a17_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia16a17_08.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia16a17_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia16a17_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia16a17_08_2.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia17a18_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia17a18_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia17a18_08.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia17a18_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia17a18_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia17a18_08_2.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia18a19_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia18a19_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia18a19_08.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia18a19_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia18a19_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia18a19_08_2.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia19a20_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia19a20_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia19a20_08.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoDia19a20_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia19a20_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia19a20_08_2.current.value = mtIniX
                this.cReporteTurnoDia06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia07.current.value))){this.cReporteTurnoDia07.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                btIniX = true
           }
         }

         if (modeX==='Noche'){
           let mtIniX = parseFloat(e.target.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
           e.target.value = mtIniX
           mtIniX = ''
           let btIniX = false

           this.cReporteTurnoNoche06.current.value = ''
           if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = ''}
           this.cReporteTurnoNoche08.current.value = 0

           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche20a21_08.current.value))) {
                mtIniX = parseFloat(this.cReporteTurnoNoche20a21_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche20a21_08.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche20a21_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche20a21_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche20a21_08_2.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche21a22_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche21a22_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche21a22_08.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche21a22_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche21a22_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche21a22_08_2.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche22a23_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche22a23_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche22a23_08.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche22a23_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche22a23_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche22a23_08_2.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche23a24_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche23a24_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche23a24_08.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche23a24_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche23a24_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche23a24_08_2.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche24a01_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche24a01_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche24a01_08.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche24a01_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche24a01_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche24a01_08_2.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche01a02_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche01a02_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche01a02_08.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche01a02_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche01a02_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche01a02_08_2.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche02a03_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche02a03_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche02a03_08.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche02a03_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche02a03_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche02a03_08_2.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche03a04_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche03a04_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche03a04_08.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche03a04_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche03a04_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche03a04_08_2.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche04a05_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche04a05_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche04a05_08.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche04a05_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche04a05_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche04a05_08_2.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche05a06_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche05a06_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche05a06_08.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche05a06_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche05a06_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche05a06_08_2.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche06a07_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche06a07_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche06a07_08.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche06a07_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche06a07_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche06a07_08_2.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche07a08_08.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche07a08_08.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche07a08_08.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
           if (btIniX === false && !isNaN(parseFloat(this.cReporteTurnoNoche07a08_08_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche07a08_08_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche07a08_08_2.current.value = mtIniX
                this.cReporteTurnoNoche06.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche07.current.value))){this.cReporteTurnoNoche07.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
                btIniX = true
           }
         }

         let mtIniXD = 0
         let mtIniXN = 0
         if (!isNaN(parseFloat(this.cReporteTurnoDia08.current.value))) { mtIniXD = parseFloat(this.cReporteTurnoDia08.current.value)}
         if (!isNaN(parseFloat(this.cReporteTurnoNoche08.current.value))) { mtIniXN = parseFloat(this.cReporteTurnoNoche08.current.value)}
         let consolidadoX = mtIniXD.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}) + mtIniXN.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
         let consolidadoFondoX = this.state.consolidadoFondoOrigenDiaX.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}) + this.state.consolidadoFondoOrigenNocheX.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
         consolidadoFondoX = consolidadoFondoX + consolidadoX
         this.setState({consolidadoX, consolidadoFondoX})

         this.handlePerforados(e,1)
         this.handlePerforados(e,2)
      }
    }


    handleMetroFin = (e, modeX, optX) => {

      let palabra = ''
      let ArrayIdValue = ''
      let bSigueX = true
      if (optX === 1) { palabra = this.cReporteTurnoDia08a09_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 2) { palabra = this.cReporteTurnoDia08a09_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 3) { palabra = this.cReporteTurnoDia09a10_01.current.value; ArrayIdValue = palabra.split('&&&');if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 4) { palabra = this.cReporteTurnoDia09a10_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 5) { palabra = this.cReporteTurnoDia10a11_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 6) { palabra = this.cReporteTurnoDia10a11_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 7) { palabra = this.cReporteTurnoDia11a12_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 8) { palabra = this.cReporteTurnoDia11a12_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 9) { palabra = this.cReporteTurnoDia12a13_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 10) { palabra = this.cReporteTurnoDia12a13_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 11) { palabra = this.cReporteTurnoDia13a14_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 12) { palabra = this.cReporteTurnoDia13a14_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 13) { palabra = this.cReporteTurnoDia14a15_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 14) { palabra = this.cReporteTurnoDia14a15_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 15) { palabra = this.cReporteTurnoDia15a16_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 16) { palabra = this.cReporteTurnoDia15a16_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 17) { palabra = this.cReporteTurnoDia16a17_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 18) { palabra = this.cReporteTurnoDia16a17_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 19) { palabra = this.cReporteTurnoDia17a18_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 20) { palabra = this.cReporteTurnoDia17a18_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 21) { palabra = this.cReporteTurnoDia18a19_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 22) { palabra = this.cReporteTurnoDia18a19_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 23) { palabra = this.cReporteTurnoDia19a20_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 24) { palabra = this.cReporteTurnoDia19a20_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};

      if (optX === 25) { palabra = this.cReporteTurnoNoche20a21_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 26) { palabra = this.cReporteTurnoNoche20a21_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 27) { palabra = this.cReporteTurnoNoche21a22_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 28) { palabra = this.cReporteTurnoNoche21a22_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 29) { palabra = this.cReporteTurnoNoche22a23_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 30) { palabra = this.cReporteTurnoNoche22a23_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 31) { palabra = this.cReporteTurnoNoche23a24_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 32) { palabra = this.cReporteTurnoNoche23a24_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 33) { palabra = this.cReporteTurnoNoche24a01_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 34) { palabra = this.cReporteTurnoNoche24a01_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 35) { palabra = this.cReporteTurnoNoche01a02_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 36) { palabra = this.cReporteTurnoNoche01a02_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 37) { palabra = this.cReporteTurnoNoche02a03_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 38) { palabra = this.cReporteTurnoNoche02a03_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 39) { palabra = this.cReporteTurnoNoche03a04_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 40) { palabra = this.cReporteTurnoNoche03a04_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 41) { palabra = this.cReporteTurnoNoche04a05_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 42) { palabra = this.cReporteTurnoNoche04a05_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 43) { palabra = this.cReporteTurnoNoche05a06_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 44) { palabra = this.cReporteTurnoNoche05a06_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 45) { palabra = this.cReporteTurnoNoche06a07_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 46) { palabra = this.cReporteTurnoNoche06a07_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 47) { palabra = this.cReporteTurnoNoche07a08_01.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};
      if (optX === 48) { palabra = this.cReporteTurnoNoche07a08_01_2.current.value; ArrayIdValue = palabra.split('&&&'); if (ArrayIdValue[0]==='17' || ArrayIdValue[0]==='19'){bSigueX = false}};

      if (bSigueX){
        let bPasa = true
        if (modeX !== 'Duplicar'){
          if (e.target.value !== ''){
             let mtIniX = parseFloat(e.target.value)
             if (mtIniX < 0 || isNaN(mtIniX) ||
                (modeX === 'Dia' && !isNaN(parseFloat(this.cReporteTurnoDia07.current.value)) && mtIniX < parseFloat(this.cReporteTurnoDia07.current.value)) ||
                (modeX === 'Noche' && !isNaN(parseFloat(this.cReporteTurnoNoche07.current.value)) && mtIniX < parseFloat(this.cReporteTurnoNoche07.current.value))) {
                   bPasa=false
                   e.target.value = ''
             }else{
               if (optX === 1){if (mtIniX < parseFloat(this.cReporteTurnoDia08a09_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 2){if (mtIniX < parseFloat(this.cReporteTurnoDia08a09_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 3){if (mtIniX < parseFloat(this.cReporteTurnoDia09a10_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 4){if (mtIniX < parseFloat(this.cReporteTurnoDia09a10_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 5){if (mtIniX < parseFloat(this.cReporteTurnoDia10a11_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 6){if (mtIniX < parseFloat(this.cReporteTurnoDia10a11_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 7){if (mtIniX < parseFloat(this.cReporteTurnoDia11a12_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 8){if (mtIniX < parseFloat(this.cReporteTurnoDia11a12_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 9){if (mtIniX < parseFloat(this.cReporteTurnoDia12a13_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 10){if (mtIniX < parseFloat(this.cReporteTurnoDia12a13_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 11){if (mtIniX < parseFloat(this.cReporteTurnoDia13a14_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 12){if (mtIniX < parseFloat(this.cReporteTurnoDia13a14_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 13){if (mtIniX < parseFloat(this.cReporteTurnoDia14a15_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 14){if (mtIniX < parseFloat(this.cReporteTurnoDia14a15_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 15){if (mtIniX < parseFloat(this.cReporteTurnoDia15a16_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 16){if (mtIniX < parseFloat(this.cReporteTurnoDia15a16_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 17){if (mtIniX < parseFloat(this.cReporteTurnoDia16a17_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 18){if (mtIniX < parseFloat(this.cReporteTurnoDia16a17_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 19){if (mtIniX < parseFloat(this.cReporteTurnoDia17a18_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 20){if (mtIniX < parseFloat(this.cReporteTurnoDia17a18_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 21){if (mtIniX < parseFloat(this.cReporteTurnoDia18a19_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 22){if (mtIniX < parseFloat(this.cReporteTurnoDia18a19_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 23){if (mtIniX < parseFloat(this.cReporteTurnoDia19a20_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 24){if (mtIniX < parseFloat(this.cReporteTurnoDia19a20_08_2.current.value)){e.target.value = '';bPasa=false}}

               if (optX === 25){if (mtIniX < parseFloat(this.cReporteTurnoNoche20a21_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 26){if (mtIniX < parseFloat(this.cReporteTurnoNoche20a21_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 27){if (mtIniX < parseFloat(this.cReporteTurnoNoche21a22_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 28){if (mtIniX < parseFloat(this.cReporteTurnoNoche21a22_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 29){if (mtIniX < parseFloat(this.cReporteTurnoNoche22a23_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 30){if (mtIniX < parseFloat(this.cReporteTurnoNoche22a23_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 31){if (mtIniX < parseFloat(this.cReporteTurnoNoche23a24_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 32){if (mtIniX < parseFloat(this.cReporteTurnoNoche23a24_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 33){if (mtIniX < parseFloat(this.cReporteTurnoNoche24a01_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 34){if (mtIniX < parseFloat(this.cReporteTurnoNoche24a01_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 35){if (mtIniX < parseFloat(this.cReporteTurnoNoche01a02_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 36){if (mtIniX < parseFloat(this.cReporteTurnoNoche01a02_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 37){if (mtIniX < parseFloat(this.cReporteTurnoNoche02a03_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 38){if (mtIniX < parseFloat(this.cReporteTurnoNoche02a03_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 39){if (mtIniX < parseFloat(this.cReporteTurnoNoche03a04_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 40){if (mtIniX < parseFloat(this.cReporteTurnoNoche03a04_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 41){if (mtIniX < parseFloat(this.cReporteTurnoNoche04a05_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 42){if (mtIniX < parseFloat(this.cReporteTurnoNoche04a05_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 43){if (mtIniX < parseFloat(this.cReporteTurnoNoche05a06_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 44){if (mtIniX < parseFloat(this.cReporteTurnoNoche05a06_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 45){if (mtIniX < parseFloat(this.cReporteTurnoNoche06a07_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 46){if (mtIniX < parseFloat(this.cReporteTurnoNoche06a07_08_2.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 47){if (mtIniX < parseFloat(this.cReporteTurnoNoche07a08_08.current.value)){e.target.value = '';bPasa=false}}
               if (optX === 48){if (mtIniX < parseFloat(this.cReporteTurnoNoche07a08_08_2.current.value)){e.target.value = '';bPasa=false}}
             }
             if (bPasa === false){
                this.setState({dialogOpenError: true, dialogMessageError: 'Metros Término Erróneo ', dialogTitleError: 'Error' });
             }
           }
         }

         if (modeX==='Dia'){
           let mtIniX = parseFloat(e.target.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
           e.target.value = mtIniX
           mtIniX = ''
           this.cReporteTurnoDia07.current.value = ''
           this.cReporteTurnoDia08.current.value = 0

           if (!isNaN(parseFloat(this.cReporteTurnoDia08a09_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia08a09_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia08a09_09.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia08a09_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia08a09_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia08a09_09_2.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
                this.cReporteTurnoDia08.current.value = this.cReporteTurnoDia08.current.value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia09a10_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia09a10_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia09a10_09.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia09a10_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia09a10_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia09a10_09_2.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia10a11_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia10a11_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia10a11_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia10a11_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia10a11_09_2.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia11a12_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia11a12_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia11a12_09.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia11a12_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia11a12_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia11a12_09_2.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia12a13_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia12a13_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia12a13_09.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia12a13_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia12a13_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia12a13_09_2.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia13a14_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia13a14_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia13a14_09.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia13a14_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia13a14_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia13a14_09_2.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia14a15_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia14a15_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia14a15_09.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia14a15_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia14a15_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia14a15_09_2.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia15a16_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia15a16_09.current.value)
                this.cReporteTurnoDia15a16_09.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia15a16_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia15a16_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia15a16_09_2.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia16a17_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia16a17_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia16a17_09.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia16a17_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia16a17_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia16a17_09_2.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia17a18_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia17a18_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia17a18_09.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia17a18_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia17a18_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia17a18_09_2.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia18a19_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia18a19_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia18a19_09.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia18a19_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia18a19_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia18a19_09_2.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia19a20_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia19a20_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia19a20_09.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoDia19a20_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoDia19a20_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoDia19a20_09_2.current.value = mtIniX
                this.cReporteTurnoDia07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoDia06.current.value))){this.cReporteTurnoDia06.current.value = mtIniX}
                this.cReporteTurnoDia08.current.value = parseFloat(this.cReporteTurnoDia07.current.value) - parseFloat(this.cReporteTurnoDia06.current.value)
           }
         }
         if (modeX==='Noche'){
           let mtIniX = parseFloat(e.target.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
           e.target.value = mtIniX
           mtIniX = ''
           this.cReporteTurnoNoche07.current.value = ''
           if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = ''}
           this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche06.current.value)

           if (!isNaN(parseFloat(this.cReporteTurnoNoche20a21_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche20a21_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche20a21_09.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche20a21_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche20a21_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche20a21_09_2.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche21a22_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche21a22_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche21a22_09.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche21a22_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche21a22_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche21a22_09_2.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche22a23_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche22a23_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche22a23_09.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche22a23_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche22a23_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche22a23_09_2.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche23a24_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche23a24_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche23a24_09.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche23a24_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche23a24_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche23a24_09_2.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche24a01_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche24a01_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche24a01_09.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche24a01_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche24a01_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche24a01_09_2.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche01a02_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche01a02_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche01a02_09.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche01a02_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche01a02_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche01a02_09_2.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche02a03_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche02a03_09.current.value)
                this.cReporteTurnoNoche02a03_09.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche02a03_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche02a03_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche02a03_09_2.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche03a04_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche03a04_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche03a04_09.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche03a04_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche03a04_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche03a04_09_2.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche04a05_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche04a05_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche04a05_09.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche076current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche04a05_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche04a05_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche04a05_09_2.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche05a06_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche05a06_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche05a06_09.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche05a06_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche05a06_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche05a06_09_2.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche06a07_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche06a07_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche06a07_09.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche06a07_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche06a07_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche06a07_09_2.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche07a08_09.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche07a08_09.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche07a08_09.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
           if (!isNaN(parseFloat(this.cReporteTurnoNoche07a08_09_2.current.value))){
                mtIniX = parseFloat(this.cReporteTurnoNoche07a08_09_2.current.value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
                this.cReporteTurnoNoche07a08_09_2.current.value = mtIniX
                this.cReporteTurnoNoche07.current.value = mtIniX
                if(isNaN(parseFloat(this.cReporteTurnoNoche06.current.value))){this.cReporteTurnoNoche06.current.value = mtIniX}
                this.cReporteTurnoNoche08.current.value = parseFloat(this.cReporteTurnoNoche07.current.value) - parseFloat(this.cReporteTurnoNoche06.current.value)
           }
         }

         let mtIniXD = 0
         let mtIniXN = 0
         if (!isNaN(parseFloat(this.cReporteTurnoDia08.current.value))) { mtIniXD = parseFloat(this.cReporteTurnoDia08.current.value)}
         if (!isNaN(parseFloat(this.cReporteTurnoNoche08.current.value))) { mtIniXN = parseFloat(this.cReporteTurnoNoche08.current.value)}
         let consolidadoX = mtIniXD.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}) + mtIniXN.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
         let consolidadoFondoX = this.state.consolidadoFondoOrigenDiaX.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2}) + this.state.consolidadoFondoOrigenNocheX.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})
         consolidadoFondoX = consolidadoFondoX + consolidadoX
         this.setState({consolidadoX, consolidadoFondoX})


         this.handlePerforados(e,1)
         this.handlePerforados(e,2)
      }
    }

    handlePerforacion = (e,optX,modeX) => {
      let palabra = ''
      if (optX === 1) { palabra = this.cReporteTurnoDia08a09_02.current.value; if (modeX===1){this.cReporteTurnoDia08a09_04.current.value = ''; this.cReporteTurnoDia08a09_06.current.value = ''}};
      if (optX === 2) { palabra = this.cReporteTurnoDia09a10_02.current.value; if (modeX===1){this.cReporteTurnoDia09a10_04.current.value = ''; this.cReporteTurnoDia09a10_06.current.value = ''}};
      if (optX === 3) { palabra = this.cReporteTurnoDia10a11_02.current.value; if (modeX===1){this.cReporteTurnoDia10a11_04.current.value = ''; this.cReporteTurnoDia10a11_06.current.value = ''}};
      if (optX === 4) { palabra = this.cReporteTurnoDia11a12_02.current.value; if (modeX===1){this.cReporteTurnoDia11a12_04.current.value = ''; this.cReporteTurnoDia11a12_06.current.value = ''}};
      if (optX === 5) { palabra = this.cReporteTurnoDia12a13_02.current.value; if (modeX===1){this.cReporteTurnoDia12a13_04.current.value = ''; this.cReporteTurnoDia12a13_06.current.value = ''}};
      if (optX === 6) { palabra = this.cReporteTurnoDia13a14_02.current.value; if (modeX===1){this.cReporteTurnoDia13a14_04.current.value = ''; this.cReporteTurnoDia13a14_06.current.value = ''}};
      if (optX === 7) { palabra = this.cReporteTurnoDia14a15_02.current.value; if (modeX===1){this.cReporteTurnoDia14a15_04.current.value = ''; this.cReporteTurnoDia14a15_06.current.value = ''}};
      if (optX === 8) { palabra = this.cReporteTurnoDia15a16_02.current.value; if (modeX===1){this.cReporteTurnoDia15a16_04.current.value = ''; this.cReporteTurnoDia15a16_06.current.value = ''}};
      if (optX === 9) { palabra = this.cReporteTurnoDia16a17_02.current.value; if (modeX===1){this.cReporteTurnoDia16a17_04.current.value = ''; this.cReporteTurnoDia16a17_06.current.value = ''}};
      if (optX === 10) { palabra = this.cReporteTurnoDia17a18_02.current.value; if (modeX===1){this.cReporteTurnoDia17a18_04.current.value = ''; this.cReporteTurnoDia17a18_06.current.value = ''}};
      if (optX === 11) { palabra = this.cReporteTurnoDia18a19_02.current.value; if (modeX===1){this.cReporteTurnoDia18a19_04.current.value = ''; this.cReporteTurnoDia18a19_06.current.value = ''}};
      if (optX === 12) { palabra = this.cReporteTurnoDia19a20_02.current.value; if (modeX===1){this.cReporteTurnoDia19a20_04.current.value = ''; this.cReporteTurnoDia19a20_06.current.value = ''}};
      if (optX === 13) { palabra = this.cReporteTurnoNoche20a21_02.current.value; if (modeX===1){this.cReporteTurnoNoche20a21_04.current.value = ''; this.cReporteTurnoNoche20a21_06.current.value = ''}};
      if (optX === 14) { palabra = this.cReporteTurnoNoche21a22_02.current.value; if (modeX===1){this.cReporteTurnoNoche21a22_04.current.value = ''; this.cReporteTurnoNoche21a22_06.current.value = ''}};
      if (optX === 15) { palabra = this.cReporteTurnoNoche22a23_02.current.value; if (modeX===1){this.cReporteTurnoNoche22a23_04.current.value = ''; this.cReporteTurnoNoche22a23_06.current.value = ''}};
      if (optX === 16) { palabra = this.cReporteTurnoNoche23a24_02.current.value; if (modeX===1){this.cReporteTurnoNoche23a24_04.current.value = ''; this.cReporteTurnoNoche23a24_06.current.value = ''}};
      if (optX === 17) { palabra = this.cReporteTurnoNoche24a01_02.current.value; if (modeX===1){this.cReporteTurnoNoche24a01_04.current.value = ''; this.cReporteTurnoNoche24a01_06.current.value = ''}};
      if (optX === 18) { palabra = this.cReporteTurnoNoche01a02_02.current.value; if (modeX===1){this.cReporteTurnoNoche01a02_04.current.value = ''; this.cReporteTurnoNoche01a02_06.current.value = ''}};
      if (optX === 19) { palabra = this.cReporteTurnoNoche02a03_02.current.value; if (modeX===1){this.cReporteTurnoNoche02a03_04.current.value = ''; this.cReporteTurnoNoche02a03_06.current.value = ''}};
      if (optX === 20) { palabra = this.cReporteTurnoNoche03a04_02.current.value; if (modeX===1){this.cReporteTurnoNoche03a04_04.current.value = ''; this.cReporteTurnoNoche03a04_06.current.value = ''}};
      if (optX === 21) { palabra = this.cReporteTurnoNoche04a05_02.current.value; if (modeX===1){this.cReporteTurnoNoche04a05_04.current.value = ''; this.cReporteTurnoNoche04a05_06.current.value = ''}};
      if (optX === 22) { palabra = this.cReporteTurnoNoche05a06_02.current.value; if (modeX===1){this.cReporteTurnoNoche05a06_04.current.value = ''; this.cReporteTurnoNoche05a06_06.current.value = ''}};
      if (optX === 23) { palabra = this.cReporteTurnoNoche06a07_02.current.value; if (modeX===1){this.cReporteTurnoNoche06a07_04.current.value = ''; this.cReporteTurnoNoche06a07_06.current.value = ''}};
      if (optX === 24) { palabra = this.cReporteTurnoNoche07a08_02.current.value; if (modeX===1){this.cReporteTurnoNoche07a08_04.current.value = ''; this.cReporteTurnoNoche07a08_06.current.value = ''}};

      if (optX === 101) { palabra = this.cReporteTurnoDia08a09_02_2.current.value; if (modeX===1){this.cReporteTurnoDia08a09_04_2.current.value = ''; this.cReporteTurnoDia08a09_06_2.current.value = ''}};
      if (optX === 102) { palabra = this.cReporteTurnoDia09a10_02_2.current.value; if (modeX===1){this.cReporteTurnoDia09a10_04_2.current.value = ''; this.cReporteTurnoDia09a10_06_2.current.value = ''}};
      if (optX === 103) { palabra = this.cReporteTurnoDia10a11_02_2.current.value; if (modeX===1){this.cReporteTurnoDia10a11_04_2.current.value = ''; this.cReporteTurnoDia10a11_06_2.current.value = ''}};
      if (optX === 104) { palabra = this.cReporteTurnoDia11a12_02_2.current.value; if (modeX===1){this.cReporteTurnoDia11a12_04_2.current.value = ''; this.cReporteTurnoDia11a12_06_2.current.value = ''}};
      if (optX === 105) { palabra = this.cReporteTurnoDia12a13_02_2.current.value; if (modeX===1){this.cReporteTurnoDia12a13_04_2.current.value = ''; this.cReporteTurnoDia12a13_06_2.current.value = ''}};
      if (optX === 106) { palabra = this.cReporteTurnoDia13a14_02_2.current.value; if (modeX===1){this.cReporteTurnoDia13a14_04_2.current.value = ''; this.cReporteTurnoDia13a14_06_2.current.value = ''}};
      if (optX === 107) { palabra = this.cReporteTurnoDia14a15_02_2.current.value; if (modeX===1){this.cReporteTurnoDia14a15_04_2.current.value = ''; this.cReporteTurnoDia14a15_06_2.current.value = ''}};
      if (optX === 108) { palabra = this.cReporteTurnoDia15a16_02_2.current.value; if (modeX===1){this.cReporteTurnoDia15a16_04_2.current.value = ''; this.cReporteTurnoDia15a16_06_2.current.value = ''}};
      if (optX === 109) { palabra = this.cReporteTurnoDia16a17_02_2.current.value; if (modeX===1){this.cReporteTurnoDia16a17_04_2.current.value = ''; this.cReporteTurnoDia16a17_06_2.current.value = ''}};
      if (optX === 110) { palabra = this.cReporteTurnoDia17a18_02_2.current.value; if (modeX===1){this.cReporteTurnoDia17a18_04_2.current.value = ''; this.cReporteTurnoDia17a18_06_2.current.value = ''}};
      if (optX === 111) { palabra = this.cReporteTurnoDia18a19_02_2.current.value; if (modeX===1){this.cReporteTurnoDia18a19_04_2.current.value = ''; this.cReporteTurnoDia18a19_06_2.current.value = ''}};
      if (optX === 112) { palabra = this.cReporteTurnoDia19a20_02_2.current.value; if (modeX===1){this.cReporteTurnoDia19a20_04_2.current.value = ''; this.cReporteTurnoDia19a20_06_2.current.value = ''}};
      if (optX === 113) { palabra = this.cReporteTurnoNoche20a21_02_2.current.value; if (modeX===1){this.cReporteTurnoNoche20a21_04_2.current.value = ''; this.cReporteTurnoNoche20a21_06_2.current.value = ''}};
      if (optX === 114) { palabra = this.cReporteTurnoNoche21a22_02_2.current.value; if (modeX===1){this.cReporteTurnoNoche21a22_04_2.current.value = ''; this.cReporteTurnoNoche21a22_06_2.current.value = ''}};
      if (optX === 115) { palabra = this.cReporteTurnoNoche22a23_02_2.current.value; if (modeX===1){this.cReporteTurnoNoche22a23_04_2.current.value = ''; this.cReporteTurnoNoche22a23_06_2.current.value = ''}};
      if (optX === 116) { palabra = this.cReporteTurnoNoche23a24_02_2.current.value; if (modeX===1){this.cReporteTurnoNoche23a24_04_2.current.value = ''; this.cReporteTurnoNoche23a24_06_2.current.value = ''}};
      if (optX === 117) { palabra = this.cReporteTurnoNoche24a01_02_2.current.value; if (modeX===1){this.cReporteTurnoNoche24a01_04_2.current.value = ''; this.cReporteTurnoNoche24a01_06_2.current.value = ''}};
      if (optX === 118) { palabra = this.cReporteTurnoNoche01a02_02_2.current.value; if (modeX===1){this.cReporteTurnoNoche01a02_04_2.current.value = ''; this.cReporteTurnoNoche01a02_06_2.current.value = ''}};
      if (optX === 119) { palabra = this.cReporteTurnoNoche02a03_02_2.current.value; if (modeX===1){this.cReporteTurnoNoche02a03_04_2.current.value = ''; this.cReporteTurnoNoche02a03_06_2.current.value = ''}};
      if (optX === 120) { palabra = this.cReporteTurnoNoche03a04_02_2.current.value; if (modeX===1){this.cReporteTurnoNoche03a04_04_2.current.value = ''; this.cReporteTurnoNoche03a04_06_2.current.value = ''}};
      if (optX === 121) { palabra = this.cReporteTurnoNoche04a05_02_2.current.value; if (modeX===1){this.cReporteTurnoNoche04a05_04_2.current.value = ''; this.cReporteTurnoNoche04a05_06_2.current.value = ''}};
      if (optX === 122) { palabra = this.cReporteTurnoNoche05a06_02_2.current.value; if (modeX===1){this.cReporteTurnoNoche05a06_04_2.current.value = ''; this.cReporteTurnoNoche05a06_06_2.current.value = ''}};
      if (optX === 123) { palabra = this.cReporteTurnoNoche06a07_02_2.current.value; if (modeX===1){this.cReporteTurnoNoche06a07_04_2.current.value = ''; this.cReporteTurnoNoche06a07_06_2.current.value = ''}};
      if (optX === 124) { palabra = this.cReporteTurnoNoche07a08_02_2.current.value; if (modeX===1){this.cReporteTurnoNoche07a08_04_2.current.value = ''; this.cReporteTurnoNoche07a08_06_2.current.value = ''}};

      let  ArrayIdValue = palabra.split('&&&')
      let sobrecargaX = ''
      let opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}

      if (optX === 1){this.setState({sobrecargaX1:sobrecargaX, opcionPerforacionX1: opcionPerforacionX})}
      if (optX === 2){this.setState({sobrecargaX3:sobrecargaX, opcionPerforacionX3: opcionPerforacionX})}
      if (optX === 3){this.setState({sobrecargaX5:sobrecargaX, opcionPerforacionX5: opcionPerforacionX})}
      if (optX === 4){this.setState({sobrecargaX7:sobrecargaX, opcionPerforacionX7: opcionPerforacionX})}
      if (optX === 5){this.setState({sobrecargaX9:sobrecargaX, opcionPerforacionX9: opcionPerforacionX})}
      if (optX === 6){this.setState({sobrecargaX11:sobrecargaX, opcionPerforacionX11: opcionPerforacionX})}
      if (optX === 7){this.setState({sobrecargaX13:sobrecargaX, opcionPerforacionX13: opcionPerforacionX})}
      if (optX === 8){this.setState({sobrecargaX15:sobrecargaX, opcionPerforacionX15: opcionPerforacionX})}
      if (optX === 9){this.setState({sobrecargaX17:sobrecargaX, opcionPerforacionX17: opcionPerforacionX})}
      if (optX === 10){this.setState({sobrecargaX19:sobrecargaX, opcionPerforacionX19: opcionPerforacionX})}
      if (optX === 11){this.setState({sobrecargaX21:sobrecargaX, opcionPerforacionX21: opcionPerforacionX})}
      if (optX === 12){this.setState({sobrecargaX23:sobrecargaX, opcionPerforacionX23: opcionPerforacionX})}
      if (optX === 13){this.setState({sobrecargaX25:sobrecargaX, opcionPerforacionX25: opcionPerforacionX})}
      if (optX === 14){this.setState({sobrecargaX27:sobrecargaX, opcionPerforacionX27: opcionPerforacionX})}
      if (optX === 15){this.setState({sobrecargaX29:sobrecargaX, opcionPerforacionX29: opcionPerforacionX})}
      if (optX === 16){this.setState({sobrecargaX31:sobrecargaX, opcionPerforacionX31: opcionPerforacionX})}
      if (optX === 17){this.setState({sobrecargaX33:sobrecargaX, opcionPerforacionX33: opcionPerforacionX})}
      if (optX === 18){this.setState({sobrecargaX35:sobrecargaX, opcionPerforacionX35: opcionPerforacionX})}
      if (optX === 19){this.setState({sobrecargaX37:sobrecargaX, opcionPerforacionX37: opcionPerforacionX})}
      if (optX === 20){this.setState({sobrecargaX39:sobrecargaX, opcionPerforacionX39: opcionPerforacionX})}
      if (optX === 21){this.setState({sobrecargaX41:sobrecargaX, opcionPerforacionX41: opcionPerforacionX})}
      if (optX === 22){this.setState({sobrecargaX43:sobrecargaX, opcionPerforacionX43: opcionPerforacionX})}
      if (optX === 23){this.setState({sobrecargaX45:sobrecargaX, opcionPerforacionX45: opcionPerforacionX})}
      if (optX === 24){this.setState({sobrecargaX47:sobrecargaX, opcionPerforacionX47: opcionPerforacionX})}

      if (optX === 101){this.setState({sobrecargaX2:sobrecargaX, opcionPerforacionX2: opcionPerforacionX})}
      if (optX === 102){this.setState({sobrecargaX4:sobrecargaX, opcionPerforacionX4: opcionPerforacionX})}
      if (optX === 103){this.setState({sobrecargaX6:sobrecargaX, opcionPerforacionX6: opcionPerforacionX})}
      if (optX === 104){this.setState({sobrecargaX8:sobrecargaX, opcionPerforacionX8: opcionPerforacionX})}
      if (optX === 105){this.setState({sobrecargaX10:sobrecargaX, opcionPerforacionX10: opcionPerforacionX})}
      if (optX === 106){this.setState({sobrecargaX12:sobrecargaX, opcionPerforacionX12: opcionPerforacionX})}
      if (optX === 107){this.setState({sobrecargaX14:sobrecargaX, opcionPerforacionX14: opcionPerforacionX})}
      if (optX === 108){this.setState({sobrecargaX16:sobrecargaX, opcionPerforacionX16: opcionPerforacionX})}
      if (optX === 109){this.setState({sobrecargaX18:sobrecargaX, opcionPerforacionX18: opcionPerforacionX})}
      if (optX === 110){this.setState({sobrecargaX20:sobrecargaX, opcionPerforacionX20: opcionPerforacionX})}
      if (optX === 111){this.setState({sobrecargaX22:sobrecargaX, opcionPerforacionX22: opcionPerforacionX})}
      if (optX === 112){this.setState({sobrecargaX24:sobrecargaX, opcionPerforacionX24: opcionPerforacionX})}
      if (optX === 113){this.setState({sobrecargaX26:sobrecargaX, opcionPerforacionX26: opcionPerforacionX})}
      if (optX === 114){this.setState({sobrecargaX28:sobrecargaX, opcionPerforacionX28: opcionPerforacionX})}
      if (optX === 115){this.setState({sobrecargaX30:sobrecargaX, opcionPerforacionX30: opcionPerforacionX})}
      if (optX === 116){this.setState({sobrecargaX32:sobrecargaX, opcionPerforacionX32: opcionPerforacionX})}
      if (optX === 117){this.setState({sobrecargaX34:sobrecargaX, opcionPerforacionX34: opcionPerforacionX})}
      if (optX === 118){this.setState({sobrecargaX36:sobrecargaX, opcionPerforacionX36: opcionPerforacionX})}
      if (optX === 119){this.setState({sobrecargaX38:sobrecargaX, opcionPerforacionX38: opcionPerforacionX})}
      if (optX === 120){this.setState({sobrecargaX40:sobrecargaX, opcionPerforacionX40: opcionPerforacionX})}
      if (optX === 121){this.setState({sobrecargaX42:sobrecargaX, opcionPerforacionX42: opcionPerforacionX})}
      if (optX === 122){this.setState({sobrecargaX44:sobrecargaX, opcionPerforacionX44: opcionPerforacionX})}
      if (optX === 123){this.setState({sobrecargaX46:sobrecargaX, opcionPerforacionX46: opcionPerforacionX})}
      if (optX === 124){this.setState({sobrecargaX48:sobrecargaX, opcionPerforacionX48: opcionPerforacionX})}
    }

    handleAceroPerforacion = (e,optX,modeX) => {
      let palabra = ''
      if (optX === 1) { palabra = this.cReporteTurnoDia08a09_02.current.value};
      if (optX === 2) { palabra = this.cReporteTurnoDia09a10_02.current.value};
      if (optX === 3) { palabra = this.cReporteTurnoDia10a11_02.current.value};
      if (optX === 4) { palabra = this.cReporteTurnoDia11a12_02.current.value};
      if (optX === 5) { palabra = this.cReporteTurnoDia12a13_02.current.value};
      if (optX === 6) { palabra = this.cReporteTurnoDia13a14_02.current.value};
      if (optX === 7) { palabra = this.cReporteTurnoDia14a15_02.current.value};
      if (optX === 8) { palabra = this.cReporteTurnoDia15a16_02.current.value};
      if (optX === 9) { palabra = this.cReporteTurnoDia16a17_02.current.value};
      if (optX === 10) { palabra = this.cReporteTurnoDia17a18_02.current.value};
      if (optX === 11) { palabra = this.cReporteTurnoDia18a19_02.current.value};
      if (optX === 12) { palabra = this.cReporteTurnoDia19a20_02.current.value};
      if (optX === 13) { palabra = this.cReporteTurnoNoche20a21_02.current.value};
      if (optX === 14) { palabra = this.cReporteTurnoNoche21a22_02.current.value};
      if (optX === 15) { palabra = this.cReporteTurnoNoche22a23_02.current.value};
      if (optX === 16) { palabra = this.cReporteTurnoNoche23a24_02.current.value};
      if (optX === 17) { palabra = this.cReporteTurnoNoche24a01_02.current.value};
      if (optX === 18) { palabra = this.cReporteTurnoNoche01a02_02.current.value};
      if (optX === 19) { palabra = this.cReporteTurnoNoche02a03_02.current.value};
      if (optX === 20) { palabra = this.cReporteTurnoNoche03a04_02.current.value};
      if (optX === 21) { palabra = this.cReporteTurnoNoche04a05_02.current.value};
      if (optX === 22) { palabra = this.cReporteTurnoNoche05a06_02.current.value};
      if (optX === 23) { palabra = this.cReporteTurnoNoche06a07_02.current.value};
      if (optX === 24) { palabra = this.cReporteTurnoNoche07a08_02.current.value};

      if (optX === 101) { palabra = this.cReporteTurnoDia08a09_02_2.current.value};
      if (optX === 102) { palabra = this.cReporteTurnoDia09a10_02_2.current.value};
      if (optX === 103) { palabra = this.cReporteTurnoDia10a11_02_2.current.value};
      if (optX === 104) { palabra = this.cReporteTurnoDia11a12_02_2.current.value};
      if (optX === 105) { palabra = this.cReporteTurnoDia12a13_02_2.current.value};
      if (optX === 106) { palabra = this.cReporteTurnoDia13a14_02_2.current.value};
      if (optX === 107) { palabra = this.cReporteTurnoDia14a15_02_2.current.value};
      if (optX === 108) { palabra = this.cReporteTurnoDia15a16_02_2.current.value};
      if (optX === 109) { palabra = this.cReporteTurnoDia16a17_02_2.current.value};
      if (optX === 110) { palabra = this.cReporteTurnoDia17a18_02_2.current.value};
      if (optX === 111) { palabra = this.cReporteTurnoDia18a19_02_2.current.value};
      if (optX === 112) { palabra = this.cReporteTurnoDia19a20_02_2.current.value};
      if (optX === 113) { palabra = this.cReporteTurnoNoche20a21_02_2.current.value};
      if (optX === 114) { palabra = this.cReporteTurnoNoche21a22_02_2.current.value};
      if (optX === 115) { palabra = this.cReporteTurnoNoche22a23_02_2.current.value};
      if (optX === 116) { palabra = this.cReporteTurnoNoche23a24_02_2.current.value};
      if (optX === 117) { palabra = this.cReporteTurnoNoche24a01_02_2.current.value};
      if (optX === 118) { palabra = this.cReporteTurnoNoche01a02_02_2.current.value};
      if (optX === 119) { palabra = this.cReporteTurnoNoche02a03_02_2.current.value};
      if (optX === 120) { palabra = this.cReporteTurnoNoche03a04_02_2.current.value};
      if (optX === 121) { palabra = this.cReporteTurnoNoche04a05_02_2.current.value};
      if (optX === 122) { palabra = this.cReporteTurnoNoche05a06_02_2.current.value};
      if (optX === 123) { palabra = this.cReporteTurnoNoche06a07_02_2.current.value};
      if (optX === 124) { palabra = this.cReporteTurnoNoche07a08_02_2.current.value};

      let  ArrayIdValue = palabra.split('&&&')
      let sobrecargaX = ''
      let opcionPerforacionX = ''
      let perforacionX = ArrayIdValue[0]
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}

      if (optX === 1) { palabra = this.cReporteTurnoDia08a09_06.current.value; if (modeX===1){this.cReporteTurnoDia08a09_04.current.value = ''}};
      if (optX === 2) { palabra = this.cReporteTurnoDia09a10_06.current.value; if (modeX===1){this.cReporteTurnoDia09a10_04.current.value = ''}};
      if (optX === 3) { palabra = this.cReporteTurnoDia10a11_06.current.value; if (modeX===1){this.cReporteTurnoDia10a11_04.current.value = ''}};
      if (optX === 4) { palabra = this.cReporteTurnoDia11a12_06.current.value; if (modeX===1){this.cReporteTurnoDia11a12_04.current.value = ''}};
      if (optX === 5) { palabra = this.cReporteTurnoDia12a13_06.current.value; if (modeX===1){this.cReporteTurnoDia12a13_04.current.value = ''}};
      if (optX === 6) { palabra = this.cReporteTurnoDia13a14_06.current.value; if (modeX===1){this.cReporteTurnoDia13a14_04.current.value = ''}};
      if (optX === 7) { palabra = this.cReporteTurnoDia14a15_06.current.value; if (modeX===1){this.cReporteTurnoDia14a15_04.current.value = ''}};
      if (optX === 8) { palabra = this.cReporteTurnoDia15a16_06.current.value; if (modeX===1){this.cReporteTurnoDia15a16_04.current.value = ''}};
      if (optX === 9) { palabra = this.cReporteTurnoDia16a17_06.current.value; if (modeX===1){this.cReporteTurnoDia16a17_04.current.value = ''}};
      if (optX === 10) { palabra = this.cReporteTurnoDia17a18_06.current.value; if (modeX===1){this.cReporteTurnoDia17a18_04.current.value = ''}};
      if (optX === 11) { palabra = this.cReporteTurnoDia18a19_06.current.value; if (modeX===1){this.cReporteTurnoDia18a19_04.current.value = ''}};
      if (optX === 12) { palabra = this.cReporteTurnoDia19a20_06.current.value; if (modeX===1){this.cReporteTurnoDia19a20_04.current.value = ''}};
      if (optX === 13) { palabra = this.cReporteTurnoNoche20a21_06.current.value; if (modeX===1){this.cReporteTurnoNoche20a21_04.current.value = ''}};
      if (optX === 14) { palabra = this.cReporteTurnoNoche21a22_06.current.value; if (modeX===1){this.cReporteTurnoNoche21a22_04.current.value = ''}};
      if (optX === 15) { palabra = this.cReporteTurnoNoche22a23_06.current.value; if (modeX===1){this.cReporteTurnoNoche22a23_04.current.value = ''}};
      if (optX === 16) { palabra = this.cReporteTurnoNoche23a24_06.current.value; if (modeX===1){this.cReporteTurnoNoche23a24_04.current.value = ''}};
      if (optX === 17) { palabra = this.cReporteTurnoNoche24a01_06.current.value; if (modeX===1){this.cReporteTurnoNoche24a01_04.current.value = ''}};
      if (optX === 18) { palabra = this.cReporteTurnoNoche01a02_06.current.value; if (modeX===1){this.cReporteTurnoNoche01a02_04.current.value = ''}};
      if (optX === 19) { palabra = this.cReporteTurnoNoche02a03_06.current.value; if (modeX===1){this.cReporteTurnoNoche02a03_04.current.value = ''}};
      if (optX === 20) { palabra = this.cReporteTurnoNoche03a04_06.current.value; if (modeX===1){this.cReporteTurnoNoche03a04_04.current.value = ''}};
      if (optX === 21) { palabra = this.cReporteTurnoNoche04a05_06.current.value; if (modeX===1){this.cReporteTurnoNoche04a05_04.current.value = ''}};
      if (optX === 22) { palabra = this.cReporteTurnoNoche05a06_06.current.value; if (modeX===1){this.cReporteTurnoNoche05a06_04.current.value = ''}};
      if (optX === 23) { palabra = this.cReporteTurnoNoche06a07_06.current.value; if (modeX===1){this.cReporteTurnoNoche06a07_04.current.value = ''}};
      if (optX === 24) { palabra = this.cReporteTurnoNoche07a08_06.current.value; if (modeX===1){this.cReporteTurnoNoche07a08_04.current.value = ''}};

      if (optX === 101) { palabra = this.cReporteTurnoDia08a09_06_2.current.value; if (modeX===1){this.cReporteTurnoDia08a09_04_2.current.value = ''}};
      if (optX === 102) { palabra = this.cReporteTurnoDia09a10_06_2.current.value; if (modeX===1){this.cReporteTurnoDia09a10_04_2.current.value = ''}};
      if (optX === 103) { palabra = this.cReporteTurnoDia10a11_06_2.current.value; if (modeX===1){this.cReporteTurnoDia10a11_04_2.current.value = ''}};
      if (optX === 104) { palabra = this.cReporteTurnoDia11a12_06_2.current.value; if (modeX===1){this.cReporteTurnoDia11a12_04_2.current.value = ''}};
      if (optX === 105) { palabra = this.cReporteTurnoDia12a13_06_2.current.value; if (modeX===1){this.cReporteTurnoDia12a13_04_2.current.value = ''}};
      if (optX === 106) { palabra = this.cReporteTurnoDia13a14_06_2.current.value; if (modeX===1){this.cReporteTurnoDia13a14_04_2.current.value = ''}};
      if (optX === 107) { palabra = this.cReporteTurnoDia14a15_06_2.current.value; if (modeX===1){this.cReporteTurnoDia14a15_04_2.current.value = ''}};
      if (optX === 108) { palabra = this.cReporteTurnoDia15a16_06_2.current.value; if (modeX===1){this.cReporteTurnoDia15a16_04_2.current.value = ''}};
      if (optX === 109) { palabra = this.cReporteTurnoDia16a17_06_2.current.value; if (modeX===1){this.cReporteTurnoDia16a17_04_2.current.value = ''}};
      if (optX === 110) { palabra = this.cReporteTurnoDia17a18_06_2.current.value; if (modeX===1){this.cReporteTurnoDia17a18_04_2.current.value = ''}};
      if (optX === 111) { palabra = this.cReporteTurnoDia18a19_06_2.current.value; if (modeX===1){this.cReporteTurnoDia18a19_04_2.current.value = ''}};
      if (optX === 112) { palabra = this.cReporteTurnoDia19a20_06_2.current.value; if (modeX===1){this.cReporteTurnoDia19a20_04_2.current.value = ''}};
      if (optX === 113) { palabra = this.cReporteTurnoNoche20a21_06_2.current.value; if (modeX===1){this.cReporteTurnoNoche20a21_04_2.current.value = ''}};
      if (optX === 114) { palabra = this.cReporteTurnoNoche21a22_06_2.current.value; if (modeX===1){this.cReporteTurnoNoche21a22_04_2.current.value = ''}};
      if (optX === 115) { palabra = this.cReporteTurnoNoche22a23_06_2.current.value; if (modeX===1){this.cReporteTurnoNoche22a23_04_2.current.value = ''}};
      if (optX === 116) { palabra = this.cReporteTurnoNoche23a24_06_2.current.value; if (modeX===1){this.cReporteTurnoNoche23a24_04_2.current.value = ''}};
      if (optX === 117) { palabra = this.cReporteTurnoNoche24a01_06_2.current.value; if (modeX===1){this.cReporteTurnoNoche24a01_04_2.current.value = ''}};
      if (optX === 118) { palabra = this.cReporteTurnoNoche01a02_06_2.current.value; if (modeX===1){this.cReporteTurnoNoche01a02_04_2.current.value = ''}};
      if (optX === 119) { palabra = this.cReporteTurnoNoche02a03_06_2.current.value; if (modeX===1){this.cReporteTurnoNoche02a03_04_2.current.value = ''}};
      if (optX === 120) { palabra = this.cReporteTurnoNoche03a04_06_2.current.value; if (modeX===1){this.cReporteTurnoNoche03a04_04_2.current.value = ''}};
      if (optX === 121) { palabra = this.cReporteTurnoNoche04a05_06_2.current.value; if (modeX===1){this.cReporteTurnoNoche04a05_04_2.current.value = ''}};
      if (optX === 122) { palabra = this.cReporteTurnoNoche05a06_06_2.current.value; if (modeX===1){this.cReporteTurnoNoche05a06_04_2.current.value = ''}};
      if (optX === 123) { palabra = this.cReporteTurnoNoche06a07_06_2.current.value; if (modeX===1){this.cReporteTurnoNoche06a07_04_2.current.value = ''}};
      if (optX === 124) { palabra = this.cReporteTurnoNoche07a08_06_2.current.value; if (modeX===1){this.cReporteTurnoNoche07a08_04_2.current.value = ''}};

      ArrayIdValue = palabra.split('&&&')
      if (ArrayIdValue[0] === "MARTILLO" && perforacionX === "ROCA - MUESTRA CON AGUA"){
         sobrecargaX = this.state.rocaMuestraHumedaMartilloX;
         opcionPerforacionX = "RocaMuestraHumedaMartillo"}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}

      if (optX === 1){this.setState({sobrecargaX1:sobrecargaX, opcionPerforacionX1: opcionPerforacionX})}
      if (optX === 2){this.setState({sobrecargaX3:sobrecargaX, opcionPerforacionX3: opcionPerforacionX})}
      if (optX === 3){this.setState({sobrecargaX5:sobrecargaX, opcionPerforacionX5: opcionPerforacionX})}
      if (optX === 4){this.setState({sobrecargaX7:sobrecargaX, opcionPerforacionX7: opcionPerforacionX})}
      if (optX === 5){this.setState({sobrecargaX9:sobrecargaX, opcionPerforacionX9: opcionPerforacionX})}
      if (optX === 6){this.setState({sobrecargaX11:sobrecargaX, opcionPerforacionX11: opcionPerforacionX})}
      if (optX === 7){this.setState({sobrecargaX13:sobrecargaX, opcionPerforacionX13: opcionPerforacionX})}
      if (optX === 8){this.setState({sobrecargaX15:sobrecargaX, opcionPerforacionX15: opcionPerforacionX})}
      if (optX === 9){this.setState({sobrecargaX17:sobrecargaX, opcionPerforacionX17: opcionPerforacionX})}
      if (optX === 10){this.setState({sobrecargaX19:sobrecargaX, opcionPerforacionX19: opcionPerforacionX})}
      if (optX === 11){this.setState({sobrecargaX21:sobrecargaX, opcionPerforacionX21: opcionPerforacionX})}
      if (optX === 12){this.setState({sobrecargaX23:sobrecargaX, opcionPerforacionX23: opcionPerforacionX})}
      if (optX === 13){this.setState({sobrecargaX25:sobrecargaX, opcionPerforacionX25: opcionPerforacionX})}
      if (optX === 14){this.setState({sobrecargaX27:sobrecargaX, opcionPerforacionX27: opcionPerforacionX})}
      if (optX === 15){this.setState({sobrecargaX29:sobrecargaX, opcionPerforacionX29: opcionPerforacionX})}
      if (optX === 16){this.setState({sobrecargaX31:sobrecargaX, opcionPerforacionX31: opcionPerforacionX})}
      if (optX === 17){this.setState({sobrecargaX33:sobrecargaX, opcionPerforacionX33: opcionPerforacionX})}
      if (optX === 18){this.setState({sobrecargaX35:sobrecargaX, opcionPerforacionX35: opcionPerforacionX})}
      if (optX === 19){this.setState({sobrecargaX37:sobrecargaX, opcionPerforacionX37: opcionPerforacionX})}
      if (optX === 20){this.setState({sobrecargaX39:sobrecargaX, opcionPerforacionX39: opcionPerforacionX})}
      if (optX === 21){this.setState({sobrecargaX41:sobrecargaX, opcionPerforacionX41: opcionPerforacionX})}
      if (optX === 22){this.setState({sobrecargaX43:sobrecargaX, opcionPerforacionX43: opcionPerforacionX})}
      if (optX === 23){this.setState({sobrecargaX45:sobrecargaX, opcionPerforacionX45: opcionPerforacionX})}
      if (optX === 24){this.setState({sobrecargaX47:sobrecargaX, opcionPerforacionX47: opcionPerforacionX})}

      if (optX === 101){this.setState({sobrecargaX2:sobrecargaX, opcionPerforacionX2: opcionPerforacionX})}
      if (optX === 102){this.setState({sobrecargaX4:sobrecargaX, opcionPerforacionX4: opcionPerforacionX})}
      if (optX === 103){this.setState({sobrecargaX6:sobrecargaX, opcionPerforacionX6: opcionPerforacionX})}
      if (optX === 104){this.setState({sobrecargaX8:sobrecargaX, opcionPerforacionX8: opcionPerforacionX})}
      if (optX === 105){this.setState({sobrecargaX10:sobrecargaX, opcionPerforacionX10: opcionPerforacionX})}
      if (optX === 106){this.setState({sobrecargaX12:sobrecargaX, opcionPerforacionX12: opcionPerforacionX})}
      if (optX === 107){this.setState({sobrecargaX14:sobrecargaX, opcionPerforacionX14: opcionPerforacionX})}
      if (optX === 108){this.setState({sobrecargaX16:sobrecargaX, opcionPerforacionX16: opcionPerforacionX})}
      if (optX === 109){this.setState({sobrecargaX18:sobrecargaX, opcionPerforacionX18: opcionPerforacionX})}
      if (optX === 110){this.setState({sobrecargaX20:sobrecargaX, opcionPerforacionX20: opcionPerforacionX})}
      if (optX === 111){this.setState({sobrecargaX22:sobrecargaX, opcionPerforacionX22: opcionPerforacionX})}
      if (optX === 112){this.setState({sobrecargaX24:sobrecargaX, opcionPerforacionX24: opcionPerforacionX})}
      if (optX === 113){this.setState({sobrecargaX26:sobrecargaX, opcionPerforacionX26: opcionPerforacionX})}
      if (optX === 114){this.setState({sobrecargaX28:sobrecargaX, opcionPerforacionX28: opcionPerforacionX})}
      if (optX === 115){this.setState({sobrecargaX30:sobrecargaX, opcionPerforacionX30: opcionPerforacionX})}
      if (optX === 116){this.setState({sobrecargaX32:sobrecargaX, opcionPerforacionX32: opcionPerforacionX})}
      if (optX === 117){this.setState({sobrecargaX34:sobrecargaX, opcionPerforacionX34: opcionPerforacionX})}
      if (optX === 118){this.setState({sobrecargaX36:sobrecargaX, opcionPerforacionX36: opcionPerforacionX})}
      if (optX === 119){this.setState({sobrecargaX38:sobrecargaX, opcionPerforacionX38: opcionPerforacionX})}
      if (optX === 120){this.setState({sobrecargaX40:sobrecargaX, opcionPerforacionX40: opcionPerforacionX})}
      if (optX === 121){this.setState({sobrecargaX42:sobrecargaX, opcionPerforacionX42: opcionPerforacionX})}
      if (optX === 122){this.setState({sobrecargaX44:sobrecargaX, opcionPerforacionX44: opcionPerforacionX})}
      if (optX === 123){this.setState({sobrecargaX46:sobrecargaX, opcionPerforacionX46: opcionPerforacionX})}
      if (optX === 124){this.setState({sobrecargaX48:sobrecargaX, opcionPerforacionX48: opcionPerforacionX})}
    }

    handleHerramienta = (e,optX,modeX) => {
      let palabra = ''
      if (optX === 1) { palabra = this.cReporteTurnoDia08a09_04.current.value; if (modeX===1){this.cReporteTurnoDia08a09_05.current.value = ''; this.cReporteTurnoDia08a09_13.current.value = ''}};
      if (optX === 2) { palabra = this.cReporteTurnoDia09a10_04.current.value; if (modeX===1){this.cReporteTurnoDia09a10_05.current.value = ''; this.cReporteTurnoDia09a10_13.current.value = ''}};
      if (optX === 3) { palabra = this.cReporteTurnoDia10a11_04.current.value; if (modeX===1){this.cReporteTurnoDia10a11_05.current.value = ''; this.cReporteTurnoDia10a11_13.current.value = ''}};
      if (optX === 4) { palabra = this.cReporteTurnoDia11a12_04.current.value; if (modeX===1){this.cReporteTurnoDia11a12_05.current.value = ''; this.cReporteTurnoDia11a12_13.current.value = ''}};
      if (optX === 5) { palabra = this.cReporteTurnoDia12a13_04.current.value; if (modeX===1){this.cReporteTurnoDia12a13_05.current.value = ''; this.cReporteTurnoDia12a13_13.current.value = ''}};
      if (optX === 6) { palabra = this.cReporteTurnoDia13a14_04.current.value; if (modeX===1){this.cReporteTurnoDia13a14_05.current.value = ''; this.cReporteTurnoDia13a14_13.current.value = ''}};
      if (optX === 7) { palabra = this.cReporteTurnoDia14a15_04.current.value; if (modeX===1){this.cReporteTurnoDia14a15_05.current.value = ''; this.cReporteTurnoDia14a15_13.current.value = ''}};
      if (optX === 8) { palabra = this.cReporteTurnoDia15a16_04.current.value; if (modeX===1){this.cReporteTurnoDia15a16_05.current.value = ''; this.cReporteTurnoDia15a16_13.current.value = ''}};
      if (optX === 9) { palabra = this.cReporteTurnoDia16a17_04.current.value; if (modeX===1){this.cReporteTurnoDia16a17_05.current.value = ''; this.cReporteTurnoDia16a17_13.current.value = ''}};
      if (optX === 10) { palabra = this.cReporteTurnoDia17a18_04.current.value; if (modeX===1){this.cReporteTurnoDia17a18_05.current.value = ''; this.cReporteTurnoDia17a18_13.current.value = ''}};
      if (optX === 11) { palabra = this.cReporteTurnoDia18a19_04.current.value; if (modeX===1){this.cReporteTurnoDia18a19_05.current.value = ''; this.cReporteTurnoDia18a19_13.current.value = ''}};
      if (optX === 12) { palabra = this.cReporteTurnoDia19a20_04.current.value; if (modeX===1){this.cReporteTurnoDia19a20_05.current.value = ''; this.cReporteTurnoDia19a20_13.current.value = ''}};
      if (optX === 13) { palabra = this.cReporteTurnoNoche20a21_04.current.value; if (modeX===1){this.cReporteTurnoNoche20a21_05.current.value = ''; this.cReporteTurnoNoche20a21_13.current.value = ''}};
      if (optX === 14) { palabra = this.cReporteTurnoNoche21a22_04.current.value; if (modeX===1){this.cReporteTurnoNoche21a22_05.current.value = ''; this.cReporteTurnoNoche21a22_13.current.value = ''}};
      if (optX === 15) { palabra = this.cReporteTurnoNoche22a23_04.current.value; if (modeX===1){this.cReporteTurnoNoche22a23_05.current.value = ''; this.cReporteTurnoNoche22a23_13.current.value = ''}};
      if (optX === 16) { palabra = this.cReporteTurnoNoche23a24_04.current.value; if (modeX===1){this.cReporteTurnoNoche23a24_05.current.value = ''; this.cReporteTurnoNoche23a24_13.current.value = ''}};
      if (optX === 17) { palabra = this.cReporteTurnoNoche24a01_04.current.value; if (modeX===1){this.cReporteTurnoNoche24a01_05.current.value = ''; this.cReporteTurnoNoche24a01_13.current.value = ''}};
      if (optX === 18) { palabra = this.cReporteTurnoNoche01a02_04.current.value; if (modeX===1){this.cReporteTurnoNoche01a02_05.current.value = ''; this.cReporteTurnoNoche01a02_13.current.value = ''}};
      if (optX === 19) { palabra = this.cReporteTurnoNoche02a03_04.current.value; if (modeX===1){this.cReporteTurnoNoche02a03_05.current.value = ''; this.cReporteTurnoNoche02a03_13.current.value = ''}};
      if (optX === 20) { palabra = this.cReporteTurnoNoche03a04_04.current.value; if (modeX===1){this.cReporteTurnoNoche03a04_05.current.value = ''; this.cReporteTurnoNoche03a04_13.current.value = ''}};
      if (optX === 21) { palabra = this.cReporteTurnoNoche04a05_04.current.value; if (modeX===1){this.cReporteTurnoNoche04a05_05.current.value = ''; this.cReporteTurnoNoche04a05_13.current.value = ''}};
      if (optX === 22) { palabra = this.cReporteTurnoNoche05a06_04.current.value; if (modeX===1){this.cReporteTurnoNoche05a06_05.current.value = ''; this.cReporteTurnoNoche05a06_13.current.value = ''}};
      if (optX === 23) { palabra = this.cReporteTurnoNoche06a07_04.current.value; if (modeX===1){this.cReporteTurnoNoche06a07_05.current.value = ''; this.cReporteTurnoNoche06a07_13.current.value = ''}};
      if (optX === 24) { palabra = this.cReporteTurnoNoche07a08_04.current.value; if (modeX===1){this.cReporteTurnoNoche07a08_05.current.value = ''; this.cReporteTurnoNoche07a08_13.current.value = ''}};

      if (optX === 101) { palabra = this.cReporteTurnoDia08a09_04_2.current.value; if (modeX===1){this.cReporteTurnoDia08a09_05_2.current.value = ''; this.cReporteTurnoDia08a09_13_2.current.value = ''}};
      if (optX === 102) { palabra = this.cReporteTurnoDia09a10_04_2.current.value; if (modeX===1){this.cReporteTurnoDia09a10_05_2.current.value = ''; this.cReporteTurnoDia09a10_13_2.current.value = ''}};
      if (optX === 103) { palabra = this.cReporteTurnoDia10a11_04_2.current.value; if (modeX===1){this.cReporteTurnoDia10a11_05_2.current.value = ''; this.cReporteTurnoDia10a11_13_2.current.value = ''}};
      if (optX === 104) { palabra = this.cReporteTurnoDia11a12_04_2.current.value; if (modeX===1){this.cReporteTurnoDia11a12_05_2.current.value = ''; this.cReporteTurnoDia11a12_13_2.current.value = ''}};
      if (optX === 105) { palabra = this.cReporteTurnoDia12a13_04_2.current.value; if (modeX===1){this.cReporteTurnoDia12a13_05_2.current.value = ''; this.cReporteTurnoDia12a13_13_2.current.value = ''}};
      if (optX === 106) { palabra = this.cReporteTurnoDia13a14_04_2.current.value; if (modeX===1){this.cReporteTurnoDia13a14_05_2.current.value = ''; this.cReporteTurnoDia13a14_13_2.current.value = ''}};
      if (optX === 107) { palabra = this.cReporteTurnoDia14a15_04_2.current.value; if (modeX===1){this.cReporteTurnoDia14a15_05_2.current.value = ''; this.cReporteTurnoDia14a15_13_2.current.value = ''}};
      if (optX === 108) { palabra = this.cReporteTurnoDia15a16_04_2.current.value; if (modeX===1){this.cReporteTurnoDia15a16_05_2.current.value = ''; this.cReporteTurnoDia15a16_13_2.current.value = ''}};
      if (optX === 109) { palabra = this.cReporteTurnoDia16a17_04_2.current.value; if (modeX===1){this.cReporteTurnoDia16a17_05_2.current.value = ''; this.cReporteTurnoDia16a17_13_2.current.value = ''}};
      if (optX === 110) { palabra = this.cReporteTurnoDia17a18_04_2.current.value; if (modeX===1){this.cReporteTurnoDia17a18_05_2.current.value = ''; this.cReporteTurnoDia17a18_13_2.current.value = ''}};
      if (optX === 111) { palabra = this.cReporteTurnoDia18a19_04_2.current.value; if (modeX===1){this.cReporteTurnoDia18a19_05_2.current.value = ''; this.cReporteTurnoDia18a19_13_2.current.value = ''}};
      if (optX === 112) { palabra = this.cReporteTurnoDia19a20_04_2.current.value; if (modeX===1){this.cReporteTurnoDia19a20_05_2.current.value = ''; this.cReporteTurnoDia19a20_13_2.current.value = ''}};
      if (optX === 113) { palabra = this.cReporteTurnoNoche20a21_04_2.current.value; if (modeX===1){this.cReporteTurnoNoche20a21_05_2.current.value = ''; this.cReporteTurnoNoche20a21_13_2.current.value = ''}};
      if (optX === 114) { palabra = this.cReporteTurnoNoche21a22_04_2.current.value; if (modeX===1){this.cReporteTurnoNoche21a22_05_2.current.value = ''; this.cReporteTurnoNoche21a22_13_2.current.value = ''}};
      if (optX === 115) { palabra = this.cReporteTurnoNoche22a23_04_2.current.value; if (modeX===1){this.cReporteTurnoNoche22a23_05_2.current.value = ''; this.cReporteTurnoNoche22a23_13_2.current.value = ''}};
      if (optX === 116) { palabra = this.cReporteTurnoNoche23a24_04_2.current.value; if (modeX===1){this.cReporteTurnoNoche23a24_05_2.current.value = ''; this.cReporteTurnoNoche23a24_13_2.current.value = ''}};
      if (optX === 117) { palabra = this.cReporteTurnoNoche24a01_04_2.current.value; if (modeX===1){this.cReporteTurnoNoche24a01_05_2.current.value = ''; this.cReporteTurnoNoche24a01_13_2.current.value = ''}};
      if (optX === 118) { palabra = this.cReporteTurnoNoche01a02_04_2.current.value; if (modeX===1){this.cReporteTurnoNoche01a02_05_2.current.value = ''; this.cReporteTurnoNoche01a02_13_2.current.value = ''}};
      if (optX === 119) { palabra = this.cReporteTurnoNoche02a03_04_2.current.value; if (modeX===1){this.cReporteTurnoNoche02a03_05_2.current.value = ''; this.cReporteTurnoNoche02a03_13_2.current.value = ''}};
      if (optX === 120) { palabra = this.cReporteTurnoNoche03a04_04_2.current.value; if (modeX===1){this.cReporteTurnoNoche03a04_05_2.current.value = ''; this.cReporteTurnoNoche03a04_13_2.current.value = ''}};
      if (optX === 121) { palabra = this.cReporteTurnoNoche04a05_04_2.current.value; if (modeX===1){this.cReporteTurnoNoche04a05_05_2.current.value = ''; this.cReporteTurnoNoche04a05_13_2.current.value = ''}};
      if (optX === 122) { palabra = this.cReporteTurnoNoche05a06_04_2.current.value; if (modeX===1){this.cReporteTurnoNoche05a06_05_2.current.value = ''; this.cReporteTurnoNoche05a06_13_2.current.value = ''}};
      if (optX === 123) { palabra = this.cReporteTurnoNoche06a07_04_2.current.value; if (modeX===1){this.cReporteTurnoNoche06a07_05_2.current.value = ''; this.cReporteTurnoNoche06a07_13_2.current.value = ''}};
      if (optX === 124) { palabra = this.cReporteTurnoNoche07a08_04_2.current.value; if (modeX===1){this.cReporteTurnoNoche07a08_05_2.current.value = ''; this.cReporteTurnoNoche07a08_13_2.current.value = ''}};

      let  ArrayIdValue = palabra.split('&&&')
      if (optX === 1){this.setState({herramientaCodeX1:ArrayIdValue[0]})}
      if (optX === 2){this.setState({herramientaCodeX3:ArrayIdValue[0]})}
      if (optX === 3){this.setState({herramientaCodeX5:ArrayIdValue[0]})}
      if (optX === 4){this.setState({herramientaCodeX7:ArrayIdValue[0]})}
      if (optX === 5){this.setState({herramientaCodeX9:ArrayIdValue[0]})}
      if (optX === 6){this.setState({herramientaCodeX11:ArrayIdValue[0]})}
      if (optX === 7){this.setState({herramientaCodeX13:ArrayIdValue[0]})}
      if (optX === 8){this.setState({herramientaCodeX15:ArrayIdValue[0]})}
      if (optX === 9){this.setState({herramientaCodeX17:ArrayIdValue[0]})}
      if (optX === 10){this.setState({herramientaCodeX19:ArrayIdValue[0]})}
      if (optX === 11){this.setState({herramientaCodeX21:ArrayIdValue[0]})}
      if (optX === 12){this.setState({herramientaCodeX23:ArrayIdValue[0]})}
      if (optX === 13){this.setState({herramientaCodeX25:ArrayIdValue[0]})}
      if (optX === 14){this.setState({herramientaCodeX27:ArrayIdValue[0]})}
      if (optX === 15){this.setState({herramientaCodeX29:ArrayIdValue[0]})}
      if (optX === 16){this.setState({herramientaCodeX31:ArrayIdValue[0]})}
      if (optX === 17){this.setState({herramientaCodeX33:ArrayIdValue[0]})}
      if (optX === 18){this.setState({herramientaCodeX35:ArrayIdValue[0]})}
      if (optX === 19){this.setState({herramientaCodeX37:ArrayIdValue[0]})}
      if (optX === 20){this.setState({herramientaCodeX39:ArrayIdValue[0]})}
      if (optX === 21){this.setState({herramientaCodeX41:ArrayIdValue[0]})}
      if (optX === 22){this.setState({herramientaCodeX43:ArrayIdValue[0]})}
      if (optX === 23){this.setState({herramientaCodeX45:ArrayIdValue[0]})}
      if (optX === 24){this.setState({herramientaCodeX47:ArrayIdValue[0]})}

      if (optX === 101){this.setState({herramientaCodeX2:ArrayIdValue[0]})}
      if (optX === 102){this.setState({herramientaCodeX4:ArrayIdValue[0]})}
      if (optX === 103){this.setState({herramientaCodeX6:ArrayIdValue[0]})}
      if (optX === 104){this.setState({herramientaCodeX8:ArrayIdValue[0]})}
      if (optX === 105){this.setState({herramientaCodeX10:ArrayIdValue[0]})}
      if (optX === 106){this.setState({herramientaCodeX12:ArrayIdValue[0]})}
      if (optX === 107){this.setState({herramientaCodeX14:ArrayIdValue[0]})}
      if (optX === 108){this.setState({herramientaCodeX16:ArrayIdValue[0]})}
      if (optX === 109){this.setState({herramientaCodeX18:ArrayIdValue[0]})}
      if (optX === 110){this.setState({herramientaCodeX20:ArrayIdValue[0]})}
      if (optX === 111){this.setState({herramientaCodeX22:ArrayIdValue[0]})}
      if (optX === 112){this.setState({herramientaCodeX24:ArrayIdValue[0]})}
      if (optX === 113){this.setState({herramientaCodeX26:ArrayIdValue[0]})}
      if (optX === 114){this.setState({herramientaCodeX28:ArrayIdValue[0]})}
      if (optX === 115){this.setState({herramientaCodeX30:ArrayIdValue[0]})}
      if (optX === 116){this.setState({herramientaCodeX32:ArrayIdValue[0]})}
      if (optX === 117){this.setState({herramientaCodeX34:ArrayIdValue[0]})}
      if (optX === 118){this.setState({herramientaCodeX36:ArrayIdValue[0]})}
      if (optX === 119){this.setState({herramientaCodeX38:ArrayIdValue[0]})}
      if (optX === 120){this.setState({herramientaCodeX40:ArrayIdValue[0]})}
      if (optX === 121){this.setState({herramientaCodeX42:ArrayIdValue[0]})}
      if (optX === 122){this.setState({herramientaCodeX44:ArrayIdValue[0]})}
      if (optX === 123){this.setState({herramientaCodeX46:ArrayIdValue[0]})}
      if (optX === 124){this.setState({herramientaCodeX48:ArrayIdValue[0]})}
    }

    handlePerforados = (e, optX) => {
        if (optX === 2){
          let subtotalX = parseFloat(this.state.workordermetaturnonoche) / 5
          if (!isNaN(parseFloat(this.cReporteTurnoNoche08.current.value))){
            if  (parseFloat(this.cReporteTurnoNoche08.current.value) === 0){
                this.setState({starNoche1:false,starNoche2:false,starNoche3:false,starNoche4:false,starNoche5:false})
            }
            if  (parseFloat(this.cReporteTurnoNoche08.current.value) >= 1  && parseFloat(this.cReporteTurnoNoche08.current.value) <= subtotalX * 1 ){
                this.setState({starNoche1:true,starNoche2:false,starNoche3:false,starNoche4:false,starNoche5:false})
            }
            if  (parseFloat(this.cReporteTurnoNoche08.current.value) > subtotalX * 1 && parseFloat(this.cReporteTurnoNoche08.current.value) <= subtotalX * 2 ){
                this.setState({starNoche1:true,starNoche2:true,starNoche3:false,starNoche4:false,starNoche5:false})
            }
            if  (parseFloat(this.cReporteTurnoNoche08.current.value) > subtotalX * 2 && parseFloat(this.cReporteTurnoNoche08.current.value) <= subtotalX * 3 ){
                this.setState({starNoche1:true,starNoche2:true,starNoche3:true,starNoche4:false,starNoche5:false})
            }
            if  (parseFloat(this.cReporteTurnoNoche08.current.value) > subtotalX * 3 && parseFloat(this.cReporteTurnoNoche08.current.value) <= subtotalX * 4 ){
                this.setState({starNoche1:true,starNoche2:true,starNoche3:true,starNoche4:true,starNoche5:false})
            }
            if  (parseFloat(this.cReporteTurnoNoche08.current.value) > subtotalX * 4 && parseFloat(this.cReporteTurnoNoche08.current.value) <= subtotalX * 5 ){
                this.setState({starNoche1:true,starNoche2:true,starNoche3:true,starNoche4:true,starNoche5:true})
            }
            if  (parseFloat(this.cReporteTurnoNoche08.current.value) > subtotalX * 5 ){
                this.setState({starNoche1:true,starNoche2:true,starNoche3:true,starNoche4:true,starNoche5:true})
            }
          }else {
            this.setState({starNoche1:false,starNoche2:false,starNoche3:false,starNoche4:false,starNoche5:false})
          }
        }
        if (optX === 1){
          let subtotalX = parseFloat(this.state.workordermetaturnodia) / 5
          if (!isNaN(parseFloat(this.cReporteTurnoDia08.current.value))){
            if  (parseFloat(this.cReporteTurnoDia08.current.value) === 0){
                this.setState({starDia1:false,starDia2:false,starDia3:false,starDia4:false,starDia5:false})
            }
            if  (parseFloat(this.cReporteTurnoDia08.current.value) >= 1  && parseFloat(this.cReporteTurnoDia08.current.value) <= subtotalX * 1 ){
                this.setState({starDia1:true,starDia2:false,starDia3:false,starDia4:false,starDia5:false})
            }
            if  (parseFloat(this.cReporteTurnoDia08.current.value) > subtotalX * 1 && parseFloat(this.cReporteTurnoDia08.current.value) <= subtotalX * 2 ){
                this.setState({starDia1:true,starDia2:true,starDia3:false,starDia4:false,starDia5:false})
            }
            if  (parseFloat(this.cReporteTurnoDia08.current.value) > subtotalX * 2 && parseFloat(this.cReporteTurnoDia08.current.value) <= subtotalX * 3 ){
                this.setState({starDia1:true,starDia2:true,starDia3:true,starDia4:false,starDia5:false})
            }
            if  (parseFloat(this.cReporteTurnoDia08.current.value) > subtotalX * 3 && parseFloat(this.cReporteTurnoDia08.current.value) <= subtotalX * 4 ){
                this.setState({starDia1:true,starDia2:true,starDia3:true,starDia4:true,starDia5:false})
            }
            if  (parseFloat(this.cReporteTurnoDia08.current.value) > subtotalX * 4 && parseFloat(this.cReporteTurnoDia08.current.value) <= subtotalX * 5 ){
                this.setState({starDia1:true,starDia2:true,starDia3:true,starDia4:true,starDia5:true})
            }
            if  (parseFloat(this.cReporteTurnoDia08.current.value) > subtotalX * 5 ){
                this.setState({starDia1:true,starDia2:true,starDia3:true,starDia4:true,starDia5:true})
            }
          }else{
            this.setState({starDia1:false,starDia2:false,starDia3:false,starDia4:false,starDia5:false})
          }
        }

        let subtotalX = (parseFloat(this.state.workordermetaturnodia) + parseFloat(this.state.workordermetaturnonoche)) / 5
        let consolidadoX = parseFloat(this.cReporteTurnoDia08.current.value) + parseFloat(this.cReporteTurnoNoche08.current.value)
        this.setState({consolidadoX})
        if  (consolidadoX >= 1  && consolidadoX <= subtotalX * 1 ){
            this.setState({starConsolidado1:true,starConsolidado2:false,starConsolidado3:false,starConsolidado4:false,starConsolidado5:false})
        }
        if  (consolidadoX > subtotalX * 1 && consolidadoX <= subtotalX * 2 ){
            this.setState({starConsolidado1:true,starConsolidado2:true,starConsolidado3:false,starConsolidado4:false,starConsolidado5:false})
        }
        if  (consolidadoX > subtotalX * 2 && consolidadoX <= subtotalX * 3 ){
            this.setState({starConsolidado1:true,starConsolidado2:true,starConsolidado3:true,starConsolidado4:false,starConsolidado5:false})
        }
        if  (consolidadoX > subtotalX * 3 && consolidadoX <= subtotalX * 4 ){
            this.setState({starConsolidado1:true,starConsolidado2:true,starConsolidado3:true,starConsolidado4:true,starConsolidado5:false})
        }
        if  (consolidadoX > subtotalX * 4 && consolidadoX <= subtotalX * 5 ){
            this.setState({starConsolidado1:true,starConsolidado2:true,starConsolidado3:true,starConsolidado4:true,starConsolidado5:true})
        }
        if  (consolidadoX > subtotalX * 5 ){
            this.setState({starConsolidado1:true,starConsolidado2:true,starConsolidado3:true,starConsolidado4:true,starConsolidado5:true})
        }
    }

    handleChangeHHMD = (e, turno, horario) => {
      if (turno === 'Dia'){
        if (horario === 1){
          this.cReporteTurnoDia08a09_11.current.value = 'X'
          this.cReporteTurnoDia08a09_12.current.value = ''
        }
        if (horario === 2){
          this.cReporteTurnoDia09a10_11.current.value = 'X'
          this.cReporteTurnoDia09a10_12.current.value = ''
        }
        if (horario === 3){
          this.cReporteTurnoDia10a11_11.current.value = 'X'
          this.cReporteTurnoDia10a11_12.current.value = ''
        }
        if (horario === 4){
          this.cReporteTurnoDia11a12_11.current.value = 'X'
          this.cReporteTurnoDia11a12_12.current.value = ''
        }
        if (horario === 5){
          this.cReporteTurnoDia12a13_11.current.value = 'X'
          this.cReporteTurnoDia12a13_12.current.value = ''
        }
        if (horario === 6){
          this.cReporteTurnoDia13a14_11.current.value = 'X'
          this.cReporteTurnoDia13a14_12.current.value = ''
        }
        if (horario === 7){
          this.cReporteTurnoDia14a15_11.current.value = 'X'
          this.cReporteTurnoDia14a15_12.current.value = ''
        }
        if (horario === 8){
          this.cReporteTurnoDia15a16_11.current.value = 'X'
          this.cReporteTurnoDia15a16_12.current.value = ''
        }
        if (horario === 9){
          this.cReporteTurnoDia16a17_11.current.value = 'X'
          this.cReporteTurnoDia16a17_12.current.value = ''
        }
        if (horario === 10){
          this.cReporteTurnoDia17a18_11.current.value = 'X'
          this.cReporteTurnoDia17a18_12.current.value = ''
        }
        if (horario === 11){
          this.cReporteTurnoDia18a19_11.current.value = 'X'
          this.cReporteTurnoDia18a19_12.current.value = ''
        }
        if (horario === 12){
          this.cReporteTurnoDia19a20_11.current.value = 'X'
          this.cReporteTurnoDia19a20_12.current.value = ''
        }

        if (horario === 101){
          this.cReporteTurnoDia08a09_11_2.current.value = 'X'
          this.cReporteTurnoDia08a09_12_2.current.value = ''
        }
        if (horario === 102){
          this.cReporteTurnoDia09a10_11_2.current.value = 'X'
          this.cReporteTurnoDia09a10_12_2.current.value = ''
        }
        if (horario === 103){
          this.cReporteTurnoDia10a11_11_2.current.value = 'X'
          this.cReporteTurnoDia10a11_12_2.current.value = ''
        }
        if (horario === 104){
          this.cReporteTurnoDia11a12_11_2.current.value = 'X'
          this.cReporteTurnoDia11a12_12_2.current.value = ''
        }
        if (horario === 105){
          this.cReporteTurnoDia12a13_11_2.current.value = 'X'
          this.cReporteTurnoDia12a13_12_2.current.value = ''
        }
        if (horario === 106){
          this.cReporteTurnoDia13a14_11_2.current.value = 'X'
          this.cReporteTurnoDia13a14_12_2.current.value = ''
        }
        if (horario === 107){
          this.cReporteTurnoDia14a15_11_2.current.value = 'X'
          this.cReporteTurnoDia14a15_12_2.current.value = ''
        }
        if (horario === 108){
          this.cReporteTurnoDia15a16_11_2.current.value = 'X'
          this.cReporteTurnoDia15a16_12_2.current.value = ''
        }
        if (horario === 109){
          this.cReporteTurnoDia16a17_11_2.current.value = 'X'
          this.cReporteTurnoDia16a17_12_2.current.value = ''
        }
        if (horario === 110){
          this.cReporteTurnoDia17a18_11_2.current.value = 'X'
          this.cReporteTurnoDia17a18_12_2.current.value = ''
        }
        if (horario === 111){
          this.cReporteTurnoDia18a19_11_2.current.value = 'X'
          this.cReporteTurnoDia18a19_12_2.current.value = ''
        }
        if (horario === 112){
          this.cReporteTurnoDia19a20_11_2.current.value = 'X'
          this.cReporteTurnoDia19a20_12_2.current.value = ''
        }
      }
      if (turno === 'Noche'){
        if (horario === 1){
          this.cReporteTurnoNoche20a21_11.current.value = 'X'
          this.cReporteTurnoNoche20a21_12.current.value = ''
        }
        if (horario === 2){
          this.cReporteTurnoNoche21a22_11.current.value = 'X'
          this.cReporteTurnoNoche21a22_12.current.value = ''
        }
        if (horario === 3){
          this.cReporteTurnoNoche22a23_11.current.value = 'X'
          this.cReporteTurnoNoche22a23_12.current.value = ''
        }
        if (horario === 4){
          this.cReporteTurnoNoche23a24_11.current.value = 'X'
          this.cReporteTurnoNoche23a24_12.current.value = ''
        }
        if (horario === 5){
          this.cReporteTurnoNoche24a01_11.current.value = 'X'
          this.cReporteTurnoNoche24a01_12.current.value = ''
        }
        if (horario === 6){
          this.cReporteTurnoNoche01a02_11.current.value = 'X'
          this.cReporteTurnoNoche01a02_12.current.value = ''
        }
        if (horario === 7){
          this.cReporteTurnoNoche02a03_11.current.value = 'X'
          this.cReporteTurnoNoche02a03_12.current.value = ''
        }
        if (horario === 8){
          this.cReporteTurnoNoche03a04_11.current.value = 'X'
          this.cReporteTurnoNoche03a04_12.current.value = ''
        }
        if (horario === 9){
          this.cReporteTurnoNoche04a05_11.current.value = 'X'
          this.cReporteTurnoNoche04a05_12.current.value = ''
        }
        if (horario === 10){
          this.cReporteTurnoNoche05a06_11.current.value = 'X'
          this.cReporteTurnoNoche05a06_12.current.value = ''
        }
        if (horario === 11){
          this.cReporteTurnoNoche06a07_11.current.value = 'X'
          this.cReporteTurnoNoche06a07_12.current.value = ''
        }
        if (horario === 12){
          this.cReporteTurnoNoche07a08_11.current.value = 'X'
          this.cReporteTurnoNoche07a08_12.current.value = ''
        }

        if (horario === 101){
          this.cReporteTurnoNoche20a21_11_2.current.value = 'X'
          this.cReporteTurnoNoche20a21_12_2.current.value = ''
        }
        if (horario === 102){
          this.cReporteTurnoNoche21a22_11_2.current.value = 'X'
          this.cReporteTurnoNoche21a22_12_2.current.value = ''
        }
        if (horario === 103){
          this.cReporteTurnoNoche22a23_11_2.current.value = 'X'
          this.cReporteTurnoNoche22a23_12_2.current.value = ''
        }
        if (horario === 104){
          this.cReporteTurnoNoche23a24_11_2.current.value = 'X'
          this.cReporteTurnoNoche23a24_12_2.current.value = ''
        }
        if (horario === 105){
          this.cReporteTurnoNoche24a01_11_2.current.value = 'X'
          this.cReporteTurnoNoche24a01_12_2.current.value = ''
        }
        if (horario === 106){
          this.cReporteTurnoNoche01a02_11_2.current.value = 'X'
          this.cReporteTurnoNoche01a02_12_2.current.value = ''
        }
        if (horario === 107){
          this.cReporteTurnoNoche02a03_11_2.current.value = 'X'
          this.cReporteTurnoNoche02a03_12_2.current.value = ''
        }
        if (horario === 108){
          this.cReporteTurnoNoche03a04_11_2.current.value = 'X'
          this.cReporteTurnoNoche03a04_12_2.current.value = ''
        }
        if (horario === 109){
          this.cReporteTurnoNoche04a05_11_2.current.value = 'X'
          this.cReporteTurnoNoche04a05_12_2.current.value = ''
        }
        if (horario === 110){
          this.cReporteTurnoNoche05a06_11_2.current.value = 'X'
          this.cReporteTurnoNoche05a06_12_2.current.value = ''
        }
        if (horario === 111){
          this.cReporteTurnoNoche06a07_11_2.current.value = 'X'
          this.cReporteTurnoNoche06a07_12_2.current.value = ''
        }
        if (horario === 112){
          this.cReporteTurnoNoche07a08_11_2.current.value = 'X'
          this.cReporteTurnoNoche07a08_12_2.current.value = ''
        }


      }
    }

    handleChangeHHAA = (e, turno, horario) => {
      if (turno === 'Dia'){
        if (horario === 1){
          this.cReporteTurnoDia08a09_11.current.value = ''
          this.cReporteTurnoDia08a09_12.current.value = 'X'
        }
        if (horario === 2){
          this.cReporteTurnoDia09a10_11.current.value = ''
          this.cReporteTurnoDia09a10_12.current.value = 'X'
        }
        if (horario === 3){
          this.cReporteTurnoDia10a11_11.current.value = ''
          this.cReporteTurnoDia10a11_12.current.value = 'X'
        }
        if (horario === 4){
          this.cReporteTurnoDia11a12_11.current.value = ''
          this.cReporteTurnoDia11a12_12.current.value = 'X'
        }
        if (horario === 5){
          this.cReporteTurnoDia12a13_11.current.value = ''
          this.cReporteTurnoDia12a13_12.current.value = 'X'
        }
        if (horario === 6){
          this.cReporteTurnoDia13a14_11.current.value = ''
          this.cReporteTurnoDia13a14_12.current.value = 'X'
        }
        if (horario === 7){
          this.cReporteTurnoDia14a15_11.current.value = ''
          this.cReporteTurnoDia14a15_12.current.value = 'X'
        }
        if (horario === 8){
          this.cReporteTurnoDia15a16_11.current.value = ''
          this.cReporteTurnoDia15a16_12.current.value = 'X'
        }
        if (horario === 9){
          this.cReporteTurnoDia16a17_11.current.value = ''
          this.cReporteTurnoDia16a17_12.current.value = 'X'
        }
        if (horario === 10){
          this.cReporteTurnoDia17a18_11.current.value = ''
          this.cReporteTurnoDia17a18_12.current.value = 'X'
        }
        if (horario === 11){
          this.cReporteTurnoDia18a19_11.current.value = ''
          this.cReporteTurnoDia18a19_12.current.value = 'X'
        }
        if (horario === 12){
          this.cReporteTurnoDia19a20_11.current.value = ''
          this.cReporteTurnoDia19a20_12.current.value = 'X'
        }
        if (horario === 101){
          this.cReporteTurnoDia08a09_11_2.current.value = ''
          this.cReporteTurnoDia08a09_12_2.current.value = 'X'
        }
        if (horario === 102){
          this.cReporteTurnoDia09a10_11_2.current.value = ''
          this.cReporteTurnoDia09a10_12_2.current.value = 'X'
        }
        if (horario === 103){
          this.cReporteTurnoDia10a11_11_2.current.value = ''
          this.cReporteTurnoDia10a11_12_2.current.value = 'X'
        }
        if (horario === 104){
          this.cReporteTurnoDia11a12_11_2.current.value = ''
          this.cReporteTurnoDia11a12_12_2.current.value = 'X'
        }
        if (horario === 105){
          this.cReporteTurnoDia12a13_11_2.current.value = ''
          this.cReporteTurnoDia12a13_12_2.current.value = 'X'
        }
        if (horario === 106){
          this.cReporteTurnoDia13a14_11_2.current.value = ''
          this.cReporteTurnoDia13a14_12_2.current.value = 'X'
        }
        if (horario === 107){
          this.cReporteTurnoDia14a15_11_2.current.value = ''
          this.cReporteTurnoDia14a15_12_2.current.value = 'X'
        }
        if (horario === 108){
          this.cReporteTurnoDia15a16_11_2.current.value = ''
          this.cReporteTurnoDia15a16_12_2.current.value = 'X'
        }
        if (horario === 109){
          this.cReporteTurnoDia16a17_11_2.current.value = ''
          this.cReporteTurnoDia16a17_12_2.current.value = 'X'
        }
        if (horario === 110){
          this.cReporteTurnoDia17a18_11_2.current.value = ''
          this.cReporteTurnoDia17a18_12_2.current.value = 'X'
        }
        if (horario === 111){
          this.cReporteTurnoDia18a19_11_2.current.value = ''
          this.cReporteTurnoDia18a19_12_2.current.value = 'X'
        }
        if (horario === 112){
          this.cReporteTurnoDia19a20_11_2.current.value = ''
          this.cReporteTurnoDia19a20_12_2.current.value = 'X'
        }
      }
      if (turno === 'Noche'){
        if (horario === 1){
          this.cReporteTurnoNoche20a21_11.current.value = ''
          this.cReporteTurnoNoche20a21_12.current.value = 'X'
        }
        if (horario === 2){
          this.cReporteTurnoNoche21a22_11.current.value = ''
          this.cReporteTurnoNoche21a22_12.current.value = 'X'
        }
        if (horario === 3){
          this.cReporteTurnoNoche22a23_11.current.value = ''
          this.cReporteTurnoNoche22a23_12.current.value = 'X'
        }
        if (horario === 4){
          this.cReporteTurnoNoche23a24_11.current.value = ''
          this.cReporteTurnoNoche23a24_12.current.value = 'X'
        }
        if (horario === 5){
          this.cReporteTurnoNoche24a01_11.current.value = ''
          this.cReporteTurnoNoche24a01_12.current.value = 'X'
        }
        if (horario === 6){
          this.cReporteTurnoNoche01a02_11.current.value = ''
          this.cReporteTurnoNoche01a02_12.current.value = 'X'
        }
        if (horario === 7){
          this.cReporteTurnoNoche02a03_11.current.value = ''
          this.cReporteTurnoNoche02a03_12.current.value = 'X'
        }
        if (horario === 8){
          this.cReporteTurnoNoche03a04_11.current.value = ''
          this.cReporteTurnoNoche03a04_12.current.value = 'X'
        }
        if (horario === 9){
          this.cReporteTurnoNoche04a05_11.current.value = ''
          this.cReporteTurnoNoche04a05_12.current.value = 'X'
        }
        if (horario === 10){
          this.cReporteTurnoNoche05a06_11.current.value = ''
          this.cReporteTurnoNoche05a06_12.current.value = 'X'
        }
        if (horario === 11){
          this.cReporteTurnoNoche06a07_11.current.value = ''
          this.cReporteTurnoNoche06a07_12.current.value = 'X'
        }
        if (horario === 12){
          this.cReporteTurnoNoche07a08_11.current.value = ''
          this.cReporteTurnoNoche07a08_12.current.value = 'X'
        }
        if (horario === 101){
          this.cReporteTurnoNoche20a21_11_2.current.value = ''
          this.cReporteTurnoNoche20a21_12_2.current.value = 'X'
        }
        if (horario === 102){
          this.cReporteTurnoNoche21a22_11_2.current.value = ''
          this.cReporteTurnoNoche21a22_12_2.current.value = 'X'
        }
        if (horario === 103){
          this.cReporteTurnoNoche22a23_11_2.current.value = ''
          this.cReporteTurnoNoche22a23_12_2.current.value = 'X'
        }
        if (horario === 104){
          this.cReporteTurnoNoche23a24_11_2.current.value = ''
          this.cReporteTurnoNoche23a24_12_2.current.value = 'X'
        }
        if (horario === 105){
          this.cReporteTurnoNoche24a01_11_2.current.value = ''
          this.cReporteTurnoNoche24a01_12_2.current.value = 'X'
        }
        if (horario === 106){
          this.cReporteTurnoNoche01a02_11_2.current.value = ''
          this.cReporteTurnoNoche01a02_12_2.current.value = 'X'
        }
        if (horario === 107){
          this.cReporteTurnoNoche02a03_11_2.current.value = ''
          this.cReporteTurnoNoche02a03_12_2.current.value = 'X'
        }
        if (horario === 108){
          this.cReporteTurnoNoche03a04_11_2.current.value = ''
          this.cReporteTurnoNoche03a04_12_2.current.value = 'X'
        }
        if (horario === 109){
          this.cReporteTurnoNoche04a05_11_2.current.value = ''
          this.cReporteTurnoNoche04a05_12_2.current.value = 'X'
        }
        if (horario === 110){
          this.cReporteTurnoNoche05a06_11_2.current.value = ''
          this.cReporteTurnoNoche05a06_12_2.current.value = 'X'
        }
        if (horario === 111){
          this.cReporteTurnoNoche06a07_11_2.current.value = ''
          this.cReporteTurnoNoche06a07_12_2.current.value = 'X'
        }
        if (horario === 112){
          this.cReporteTurnoNoche07a08_11_2.current.value = ''
          this.cReporteTurnoNoche07a08_12_2.current.value = 'X'
        }
      }
    }

    inicializa = () => {
      return new Promise((resolve1, reject1) => {

        this.cReporteTurnoEstadoSondaje.current.value = '';

        this.cReporteTurnoDia01.current.value = '';
        this.cReporteTurnoDia02.current.value = '';
        this.cReporteTurnoDia03.current.value = '';
        this.cReporteTurnoDia04.current.value = '';
        this.cReporteTurnoDia05.current.value = '';
        this.cReporteTurnoDia06.current.value = '';
        this.cReporteTurnoDia07.current.value = '';
        this.cReporteTurnoDia08.current.value = 0;
        this.cReporteTurnoDia09.current.value = '';
        this.cReporteTurnoDia10.current.value = '';
        if (!isNaN(parseFloat(this.state.workordermetaturnodia))){
          this.cReporteTurnoDia11.current.value =  parseFloat(this.state.workordermetaturnodia);
        }else{
          this.cReporteTurnoDia11.current.value =  0;
        }
        this.cReporteTurnoDia12.current.value = '';
        this.cReporteTurnoDia13.current.value = '';

        this.cReporteTurnoNoche01.current.value = '';
        this.cReporteTurnoNoche02.current.value = '';
        this.cReporteTurnoNoche03.current.value = '';
        this.cReporteTurnoNoche04.current.value = '';
        this.cReporteTurnoNoche05.current.value = '';
        this.cReporteTurnoNoche06.current.value = '';
        this.cReporteTurnoNoche07.current.value = '';
        this.cReporteTurnoNoche08.current.value = 0;
        this.cReporteTurnoNoche09.current.value = '';
        this.cReporteTurnoNoche10.current.value = '';
        if (!isNaN(parseFloat(this.state.workordermetaturnonoche))){
          this.cReporteTurnoNoche11.current.value = parseFloat(this.state.workordermetaturnonoche);
        }else{
          this.cReporteTurnoNoche11.current.value =  0;
        }
        this.cReporteTurnoNoche12.current.value = '';
        this.cReporteTurnoNoche13.current.value = '';

        this.cReporteTurnoDia08a09_01.current.value = '';
        this.cReporteTurnoDia08a09_02.current.value = '';
        this.cReporteTurnoDia08a09_03.current.value = '';
        this.cReporteTurnoDia08a09_04.current.value = '';
        this.cReporteTurnoDia08a09_05.current.value = '';
        this.cReporteTurnoDia08a09_06.current.value = '';
        this.cReporteTurnoDia08a09_07.current.value = '';
        this.cReporteTurnoDia08a09_08.current.value = '';
        this.cReporteTurnoDia08a09_09.current.value = '';
        this.cReporteTurnoDia08a09_10.current.value = '';
        this.cReporteTurnoDia08a09_11.current.value = '';
        this.cReporteTurnoDia08a09_12.current.value = '';
        this.cReporteTurnoDia08a09_13.current.value = '';

        this.cReporteTurnoDia09a10_01.current.value = '';
        this.cReporteTurnoDia09a10_02.current.value = '';
        this.cReporteTurnoDia09a10_03.current.value = '';
        this.cReporteTurnoDia09a10_04.current.value = '';
        this.cReporteTurnoDia09a10_05.current.value = '';
        this.cReporteTurnoDia09a10_06.current.value = '';
        this.cReporteTurnoDia09a10_07.current.value = '';
        this.cReporteTurnoDia09a10_08.current.value = '';
        this.cReporteTurnoDia09a10_09.current.value = '';
        this.cReporteTurnoDia09a10_10.current.value = '';
        this.cReporteTurnoDia09a10_11.current.value = '';
        this.cReporteTurnoDia09a10_12.current.value = '';
        this.cReporteTurnoDia09a10_13.current.value = '';

        this.cReporteTurnoDia10a11_01.current.value = '';
        this.cReporteTurnoDia10a11_02.current.value = '';
        this.cReporteTurnoDia10a11_03.current.value = '';
        this.cReporteTurnoDia10a11_04.current.value = '';
        this.cReporteTurnoDia10a11_05.current.value = '';
        this.cReporteTurnoDia10a11_06.current.value = '';
        this.cReporteTurnoDia10a11_07.current.value = '';
        this.cReporteTurnoDia10a11_08.current.value = '';
        this.cReporteTurnoDia10a11_09.current.value = '';
        this.cReporteTurnoDia10a11_10.current.value = '';
        this.cReporteTurnoDia10a11_11.current.value = '';
        this.cReporteTurnoDia10a11_12.current.value = '';
        this.cReporteTurnoDia10a11_13.current.value = '';

        this.cReporteTurnoDia11a12_01.current.value = '';
        this.cReporteTurnoDia11a12_02.current.value = '';
        this.cReporteTurnoDia11a12_03.current.value = '';
        this.cReporteTurnoDia11a12_04.current.value = '';
        this.cReporteTurnoDia11a12_05.current.value = '';
        this.cReporteTurnoDia11a12_06.current.value = '';
        this.cReporteTurnoDia11a12_07.current.value = '';
        this.cReporteTurnoDia11a12_08.current.value = '';
        this.cReporteTurnoDia11a12_09.current.value = '';
        this.cReporteTurnoDia11a12_10.current.value = '';
        this.cReporteTurnoDia11a12_11.current.value = '';
        this.cReporteTurnoDia11a12_12.current.value = '';
        this.cReporteTurnoDia11a12_13.current.value = '';

        this.cReporteTurnoDia12a13_01.current.value = '';
        this.cReporteTurnoDia12a13_02.current.value = '';
        this.cReporteTurnoDia12a13_03.current.value = '';
        this.cReporteTurnoDia12a13_04.current.value = '';
        this.cReporteTurnoDia12a13_05.current.value = '';
        this.cReporteTurnoDia12a13_06.current.value = '';
        this.cReporteTurnoDia12a13_07.current.value = '';
        this.cReporteTurnoDia12a13_08.current.value = '';
        this.cReporteTurnoDia12a13_09.current.value = '';
        this.cReporteTurnoDia12a13_10.current.value = '';
        this.cReporteTurnoDia12a13_11.current.value = '';
        this.cReporteTurnoDia12a13_12.current.value = '';
        this.cReporteTurnoDia12a13_13.current.value = '';

        this.cReporteTurnoDia13a14_01.current.value = '';
        this.cReporteTurnoDia13a14_02.current.value = '';
        this.cReporteTurnoDia13a14_03.current.value = '';
        this.cReporteTurnoDia13a14_04.current.value = '';
        this.cReporteTurnoDia13a14_05.current.value = '';
        this.cReporteTurnoDia13a14_06.current.value = '';
        this.cReporteTurnoDia13a14_07.current.value = '';
        this.cReporteTurnoDia13a14_08.current.value = '';
        this.cReporteTurnoDia13a14_09.current.value = '';
        this.cReporteTurnoDia13a14_10.current.value = '';
        this.cReporteTurnoDia13a14_11.current.value = '';
        this.cReporteTurnoDia13a14_12.current.value = '';
        this.cReporteTurnoDia13a14_13.current.value = '';

        this.cReporteTurnoDia14a15_01.current.value = '';
        this.cReporteTurnoDia14a15_02.current.value = '';
        this.cReporteTurnoDia14a15_03.current.value = '';
        this.cReporteTurnoDia14a15_04.current.value = '';
        this.cReporteTurnoDia14a15_05.current.value = '';
        this.cReporteTurnoDia14a15_06.current.value = '';
        this.cReporteTurnoDia14a15_07.current.value = '';
        this.cReporteTurnoDia14a15_08.current.value = '';
        this.cReporteTurnoDia14a15_09.current.value = '';
        this.cReporteTurnoDia14a15_10.current.value = '';
        this.cReporteTurnoDia14a15_11.current.value = '';
        this.cReporteTurnoDia14a15_12.current.value = '';
        this.cReporteTurnoDia14a15_13.current.value = '';

        this.cReporteTurnoDia15a16_01.current.value = '';
        this.cReporteTurnoDia15a16_02.current.value = '';
        this.cReporteTurnoDia15a16_03.current.value = '';
        this.cReporteTurnoDia15a16_04.current.value = '';
        this.cReporteTurnoDia15a16_05.current.value = '';
        this.cReporteTurnoDia15a16_06.current.value = '';
        this.cReporteTurnoDia15a16_07.current.value = '';
        this.cReporteTurnoDia15a16_08.current.value = '';
        this.cReporteTurnoDia15a16_09.current.value = '';
        this.cReporteTurnoDia15a16_10.current.value = '';
        this.cReporteTurnoDia15a16_11.current.value = '';
        this.cReporteTurnoDia15a16_12.current.value = '';
        this.cReporteTurnoDia15a16_13.current.value = '';

        this.cReporteTurnoDia16a17_01.current.value = '';
        this.cReporteTurnoDia16a17_02.current.value = '';
        this.cReporteTurnoDia16a17_03.current.value = '';
        this.cReporteTurnoDia16a17_04.current.value = '';
        this.cReporteTurnoDia16a17_05.current.value = '';
        this.cReporteTurnoDia16a17_06.current.value = '';
        this.cReporteTurnoDia16a17_07.current.value = '';
        this.cReporteTurnoDia16a17_08.current.value = '';
        this.cReporteTurnoDia16a17_09.current.value = '';
        this.cReporteTurnoDia16a17_10.current.value = '';
        this.cReporteTurnoDia16a17_11.current.value = '';
        this.cReporteTurnoDia16a17_12.current.value = '';
        this.cReporteTurnoDia16a17_13.current.value = '';

        this.cReporteTurnoDia17a18_01.current.value = '';
        this.cReporteTurnoDia17a18_02.current.value = '';
        this.cReporteTurnoDia17a18_03.current.value = '';
        this.cReporteTurnoDia17a18_04.current.value = '';
        this.cReporteTurnoDia17a18_05.current.value = '';
        this.cReporteTurnoDia17a18_06.current.value = '';
        this.cReporteTurnoDia17a18_07.current.value = '';
        this.cReporteTurnoDia17a18_08.current.value = '';
        this.cReporteTurnoDia17a18_09.current.value = '';
        this.cReporteTurnoDia17a18_10.current.value = '';
        this.cReporteTurnoDia17a18_11.current.value = '';
        this.cReporteTurnoDia17a18_12.current.value = '';
        this.cReporteTurnoDia17a18_13.current.value = '';

        this.cReporteTurnoDia18a19_01.current.value = '';
        this.cReporteTurnoDia18a19_02.current.value = '';
        this.cReporteTurnoDia18a19_03.current.value = '';
        this.cReporteTurnoDia18a19_04.current.value = '';
        this.cReporteTurnoDia18a19_05.current.value = '';
        this.cReporteTurnoDia18a19_06.current.value = '';
        this.cReporteTurnoDia18a19_07.current.value = '';
        this.cReporteTurnoDia18a19_08.current.value = '';
        this.cReporteTurnoDia18a19_09.current.value = '';
        this.cReporteTurnoDia18a19_10.current.value = '';
        this.cReporteTurnoDia18a19_11.current.value = '';
        this.cReporteTurnoDia18a19_12.current.value = '';
        this.cReporteTurnoDia18a19_13.current.value = '';

        this.cReporteTurnoDia19a20_01.current.value = '';
        this.cReporteTurnoDia19a20_02.current.value = '';
        this.cReporteTurnoDia19a20_03.current.value = '';
        this.cReporteTurnoDia19a20_04.current.value = '';
        this.cReporteTurnoDia19a20_05.current.value = '';
        this.cReporteTurnoDia19a20_06.current.value = '';
        this.cReporteTurnoDia19a20_07.current.value = '';
        this.cReporteTurnoDia19a20_08.current.value = '';
        this.cReporteTurnoDia19a20_09.current.value = '';
        this.cReporteTurnoDia19a20_10.current.value = '';
        this.cReporteTurnoDia19a20_11.current.value = '';
        this.cReporteTurnoDia19a20_12.current.value = '';
        this.cReporteTurnoDia19a20_13.current.value = '';

        this.cReporteTurnoDia08a09_01_2.current.value = '';
        this.cReporteTurnoDia08a09_02_2.current.value = '';
        this.cReporteTurnoDia08a09_03_2.current.value = '';
        this.cReporteTurnoDia08a09_04_2.current.value = '';
        this.cReporteTurnoDia08a09_05_2.current.value = '';
        this.cReporteTurnoDia08a09_06_2.current.value = '';
        this.cReporteTurnoDia08a09_07_2.current.value = '';
        this.cReporteTurnoDia08a09_08_2.current.value = '';
        this.cReporteTurnoDia08a09_09_2.current.value = '';
        this.cReporteTurnoDia08a09_10_2.current.value = '';
        this.cReporteTurnoDia08a09_11_2.current.value = '';
        this.cReporteTurnoDia08a09_12_2.current.value = '';
        this.cReporteTurnoDia08a09_13_2.current.value = '';

        this.cReporteTurnoDia09a10_01_2.current.value = '';
        this.cReporteTurnoDia09a10_02_2.current.value = '';
        this.cReporteTurnoDia09a10_03_2.current.value = '';
        this.cReporteTurnoDia09a10_04_2.current.value = '';
        this.cReporteTurnoDia09a10_05_2.current.value = '';
        this.cReporteTurnoDia09a10_06_2.current.value = '';
        this.cReporteTurnoDia09a10_07_2.current.value = '';
        this.cReporteTurnoDia09a10_08_2.current.value = '';
        this.cReporteTurnoDia09a10_09_2.current.value = '';
        this.cReporteTurnoDia09a10_10_2.current.value = '';
        this.cReporteTurnoDia09a10_11_2.current.value = '';
        this.cReporteTurnoDia09a10_12_2.current.value = '';
        this.cReporteTurnoDia09a10_13_2.current.value = '';

        this.cReporteTurnoDia10a11_01_2.current.value = '';
        this.cReporteTurnoDia10a11_02_2.current.value = '';
        this.cReporteTurnoDia10a11_03_2.current.value = '';
        this.cReporteTurnoDia10a11_04_2.current.value = '';
        this.cReporteTurnoDia10a11_05_2.current.value = '';
        this.cReporteTurnoDia10a11_06_2.current.value = '';
        this.cReporteTurnoDia10a11_07_2.current.value = '';
        this.cReporteTurnoDia10a11_08_2.current.value = '';
        this.cReporteTurnoDia10a11_09_2.current.value = '';
        this.cReporteTurnoDia10a11_10_2.current.value = '';
        this.cReporteTurnoDia10a11_11_2.current.value = '';
        this.cReporteTurnoDia10a11_12_2.current.value = '';
        this.cReporteTurnoDia10a11_13_2.current.value = '';

        this.cReporteTurnoDia11a12_01_2.current.value = '';
        this.cReporteTurnoDia11a12_02_2.current.value = '';
        this.cReporteTurnoDia11a12_03_2.current.value = '';
        this.cReporteTurnoDia11a12_04_2.current.value = '';
        this.cReporteTurnoDia11a12_05_2.current.value = '';
        this.cReporteTurnoDia11a12_06_2.current.value = '';
        this.cReporteTurnoDia11a12_07_2.current.value = '';
        this.cReporteTurnoDia11a12_08_2.current.value = '';
        this.cReporteTurnoDia11a12_09_2.current.value = '';
        this.cReporteTurnoDia11a12_10_2.current.value = '';
        this.cReporteTurnoDia11a12_11_2.current.value = '';
        this.cReporteTurnoDia11a12_12_2.current.value = '';
        this.cReporteTurnoDia11a12_13_2.current.value = '';

        this.cReporteTurnoDia12a13_01_2.current.value = '';
        this.cReporteTurnoDia12a13_02_2.current.value = '';
        this.cReporteTurnoDia12a13_03_2.current.value = '';
        this.cReporteTurnoDia12a13_04_2.current.value = '';
        this.cReporteTurnoDia12a13_05_2.current.value = '';
        this.cReporteTurnoDia12a13_06_2.current.value = '';
        this.cReporteTurnoDia12a13_07_2.current.value = '';
        this.cReporteTurnoDia12a13_08_2.current.value = '';
        this.cReporteTurnoDia12a13_09_2.current.value = '';
        this.cReporteTurnoDia12a13_10_2.current.value = '';
        this.cReporteTurnoDia12a13_11_2.current.value = '';
        this.cReporteTurnoDia12a13_12_2.current.value = '';
        this.cReporteTurnoDia12a13_13_2.current.value = '';

        this.cReporteTurnoDia13a14_01_2.current.value = '';
        this.cReporteTurnoDia13a14_02_2.current.value = '';
        this.cReporteTurnoDia13a14_03_2.current.value = '';
        this.cReporteTurnoDia13a14_04_2.current.value = '';
        this.cReporteTurnoDia13a14_05_2.current.value = '';
        this.cReporteTurnoDia13a14_06_2.current.value = '';
        this.cReporteTurnoDia13a14_07_2.current.value = '';
        this.cReporteTurnoDia13a14_08_2.current.value = '';
        this.cReporteTurnoDia13a14_09_2.current.value = '';
        this.cReporteTurnoDia13a14_10_2.current.value = '';
        this.cReporteTurnoDia13a14_11_2.current.value = '';
        this.cReporteTurnoDia13a14_12_2.current.value = '';
        this.cReporteTurnoDia13a14_13_2.current.value = '';

        this.cReporteTurnoDia14a15_01_2.current.value = '';
        this.cReporteTurnoDia14a15_02_2.current.value = '';
        this.cReporteTurnoDia14a15_03_2.current.value = '';
        this.cReporteTurnoDia14a15_04_2.current.value = '';
        this.cReporteTurnoDia14a15_05_2.current.value = '';
        this.cReporteTurnoDia14a15_06_2.current.value = '';
        this.cReporteTurnoDia14a15_07_2.current.value = '';
        this.cReporteTurnoDia14a15_08_2.current.value = '';
        this.cReporteTurnoDia14a15_09_2.current.value = '';
        this.cReporteTurnoDia14a15_10_2.current.value = '';
        this.cReporteTurnoDia14a15_11_2.current.value = '';
        this.cReporteTurnoDia14a15_12_2.current.value = '';
        this.cReporteTurnoDia14a15_13_2.current.value = '';

        this.cReporteTurnoDia15a16_01_2.current.value = '';
        this.cReporteTurnoDia15a16_02_2.current.value = '';
        this.cReporteTurnoDia15a16_03_2.current.value = '';
        this.cReporteTurnoDia15a16_04_2.current.value = '';
        this.cReporteTurnoDia15a16_05_2.current.value = '';
        this.cReporteTurnoDia15a16_06_2.current.value = '';
        this.cReporteTurnoDia15a16_07_2.current.value = '';
        this.cReporteTurnoDia15a16_08_2.current.value = '';
        this.cReporteTurnoDia15a16_09_2.current.value = '';
        this.cReporteTurnoDia15a16_10_2.current.value = '';
        this.cReporteTurnoDia15a16_11_2.current.value = '';
        this.cReporteTurnoDia15a16_12_2.current.value = '';
        this.cReporteTurnoDia15a16_13_2.current.value = '';

        this.cReporteTurnoDia16a17_01_2.current.value = '';
        this.cReporteTurnoDia16a17_02_2.current.value = '';
        this.cReporteTurnoDia16a17_03_2.current.value = '';
        this.cReporteTurnoDia16a17_04_2.current.value = '';
        this.cReporteTurnoDia16a17_05_2.current.value = '';
        this.cReporteTurnoDia16a17_06_2.current.value = '';
        this.cReporteTurnoDia16a17_07_2.current.value = '';
        this.cReporteTurnoDia16a17_08_2.current.value = '';
        this.cReporteTurnoDia16a17_09_2.current.value = '';
        this.cReporteTurnoDia16a17_10_2.current.value = '';
        this.cReporteTurnoDia16a17_11_2.current.value = '';
        this.cReporteTurnoDia16a17_12_2.current.value = '';
        this.cReporteTurnoDia16a17_13_2.current.value = '';

        this.cReporteTurnoDia17a18_01_2.current.value = '';
        this.cReporteTurnoDia17a18_02_2.current.value = '';
        this.cReporteTurnoDia17a18_03_2.current.value = '';
        this.cReporteTurnoDia17a18_04_2.current.value = '';
        this.cReporteTurnoDia17a18_05_2.current.value = '';
        this.cReporteTurnoDia17a18_06_2.current.value = '';
        this.cReporteTurnoDia17a18_07_2.current.value = '';
        this.cReporteTurnoDia17a18_08_2.current.value = '';
        this.cReporteTurnoDia17a18_09_2.current.value = '';
        this.cReporteTurnoDia17a18_10_2.current.value = '';
        this.cReporteTurnoDia17a18_11_2.current.value = '';
        this.cReporteTurnoDia17a18_12_2.current.value = '';
        this.cReporteTurnoDia17a18_13_2.current.value = '';

        this.cReporteTurnoDia18a19_01_2.current.value = '';
        this.cReporteTurnoDia18a19_02_2.current.value = '';
        this.cReporteTurnoDia18a19_03_2.current.value = '';
        this.cReporteTurnoDia18a19_04_2.current.value = '';
        this.cReporteTurnoDia18a19_05_2.current.value = '';
        this.cReporteTurnoDia18a19_06_2.current.value = '';
        this.cReporteTurnoDia18a19_07_2.current.value = '';
        this.cReporteTurnoDia18a19_08_2.current.value = '';
        this.cReporteTurnoDia18a19_09_2.current.value = '';
        this.cReporteTurnoDia18a19_10_2.current.value = '';
        this.cReporteTurnoDia18a19_11_2.current.value = '';
        this.cReporteTurnoDia18a19_12_2.current.value = '';
        this.cReporteTurnoDia18a19_13_2.current.value = '';

        this.cReporteTurnoDia19a20_01_2.current.value = '';
        this.cReporteTurnoDia19a20_02_2.current.value = '';
        this.cReporteTurnoDia19a20_03_2.current.value = '';
        this.cReporteTurnoDia19a20_04_2.current.value = '';
        this.cReporteTurnoDia19a20_05_2.current.value = '';
        this.cReporteTurnoDia19a20_06_2.current.value = '';
        this.cReporteTurnoDia19a20_07_2.current.value = '';
        this.cReporteTurnoDia19a20_08_2.current.value = '';
        this.cReporteTurnoDia19a20_09_2.current.value = '';
        this.cReporteTurnoDia19a20_10_2.current.value = '';
        this.cReporteTurnoDia19a20_11_2.current.value = '';
        this.cReporteTurnoDia19a20_12_2.current.value = '';
        this.cReporteTurnoDia19a20_13_2.current.value = '';


        this.cReporteTurnoNoche20a21_01.current.value = '';
        this.cReporteTurnoNoche20a21_02.current.value = '';
        this.cReporteTurnoNoche20a21_03.current.value = '';
        this.cReporteTurnoNoche20a21_04.current.value = '';
        this.cReporteTurnoNoche20a21_05.current.value = '';
        this.cReporteTurnoNoche20a21_06.current.value = '';
        this.cReporteTurnoNoche20a21_07.current.value = '';
        this.cReporteTurnoNoche20a21_08.current.value = '';
        this.cReporteTurnoNoche20a21_09.current.value = '';
        this.cReporteTurnoNoche20a21_10.current.value = '';
        this.cReporteTurnoNoche20a21_11.current.value = '';
        this.cReporteTurnoNoche20a21_12.current.value = '';
        this.cReporteTurnoNoche20a21_13.current.value = '';

        this.cReporteTurnoNoche21a22_01.current.value = '';
        this.cReporteTurnoNoche21a22_02.current.value = '';
        this.cReporteTurnoNoche21a22_03.current.value = '';
        this.cReporteTurnoNoche21a22_04.current.value = '';
        this.cReporteTurnoNoche21a22_05.current.value = '';
        this.cReporteTurnoNoche21a22_06.current.value = '';
        this.cReporteTurnoNoche21a22_07.current.value = '';
        this.cReporteTurnoNoche21a22_08.current.value = '';
        this.cReporteTurnoNoche21a22_09.current.value = '';
        this.cReporteTurnoNoche21a22_10.current.value = '';
        this.cReporteTurnoNoche21a22_11.current.value = '';
        this.cReporteTurnoNoche21a22_12.current.value = '';
        this.cReporteTurnoNoche21a22_13.current.value = '';

        this.cReporteTurnoNoche22a23_01.current.value = '';
        this.cReporteTurnoNoche22a23_02.current.value = '';
        this.cReporteTurnoNoche22a23_03.current.value = '';
        this.cReporteTurnoNoche22a23_04.current.value = '';
        this.cReporteTurnoNoche22a23_05.current.value = '';
        this.cReporteTurnoNoche22a23_06.current.value = '';
        this.cReporteTurnoNoche22a23_07.current.value = '';
        this.cReporteTurnoNoche22a23_08.current.value = '';
        this.cReporteTurnoNoche22a23_09.current.value = '';
        this.cReporteTurnoNoche22a23_10.current.value = '';
        this.cReporteTurnoNoche22a23_11.current.value = '';
        this.cReporteTurnoNoche22a23_12.current.value = '';
        this.cReporteTurnoNoche22a23_13.current.value = '';

        this.cReporteTurnoNoche23a24_01.current.value = '';
        this.cReporteTurnoNoche23a24_02.current.value = '';
        this.cReporteTurnoNoche23a24_03.current.value = '';
        this.cReporteTurnoNoche23a24_04.current.value = '';
        this.cReporteTurnoNoche23a24_05.current.value = '';
        this.cReporteTurnoNoche23a24_06.current.value = '';
        this.cReporteTurnoNoche23a24_07.current.value = '';
        this.cReporteTurnoNoche23a24_08.current.value = '';
        this.cReporteTurnoNoche23a24_09.current.value = '';
        this.cReporteTurnoNoche23a24_10.current.value = '';
        this.cReporteTurnoNoche23a24_11.current.value = '';
        this.cReporteTurnoNoche23a24_12.current.value = '';
        this.cReporteTurnoNoche23a24_13.current.value = '';

        this.cReporteTurnoNoche24a01_01.current.value = '';
        this.cReporteTurnoNoche24a01_02.current.value = '';
        this.cReporteTurnoNoche24a01_03.current.value = '';
        this.cReporteTurnoNoche24a01_04.current.value = '';
        this.cReporteTurnoNoche24a01_05.current.value = '';
        this.cReporteTurnoNoche24a01_06.current.value = '';
        this.cReporteTurnoNoche24a01_07.current.value = '';
        this.cReporteTurnoNoche24a01_08.current.value = '';
        this.cReporteTurnoNoche24a01_09.current.value = '';
        this.cReporteTurnoNoche24a01_10.current.value = '';
        this.cReporteTurnoNoche24a01_11.current.value = '';
        this.cReporteTurnoNoche24a01_12.current.value = '';
        this.cReporteTurnoNoche24a01_13.current.value = '';

        this.cReporteTurnoNoche01a02_01.current.value = '';
        this.cReporteTurnoNoche01a02_02.current.value = '';
        this.cReporteTurnoNoche01a02_03.current.value = '';
        this.cReporteTurnoNoche01a02_04.current.value = '';
        this.cReporteTurnoNoche01a02_05.current.value = '';
        this.cReporteTurnoNoche01a02_06.current.value = '';
        this.cReporteTurnoNoche01a02_07.current.value = '';
        this.cReporteTurnoNoche01a02_08.current.value = '';
        this.cReporteTurnoNoche01a02_09.current.value = '';
        this.cReporteTurnoNoche01a02_10.current.value = '';
        this.cReporteTurnoNoche01a02_11.current.value = '';
        this.cReporteTurnoNoche01a02_12.current.value = '';
        this.cReporteTurnoNoche01a02_13.current.value = '';

        this.cReporteTurnoNoche02a03_01.current.value = '';
        this.cReporteTurnoNoche02a03_02.current.value = '';
        this.cReporteTurnoNoche02a03_03.current.value = '';
        this.cReporteTurnoNoche02a03_04.current.value = '';
        this.cReporteTurnoNoche02a03_05.current.value = '';
        this.cReporteTurnoNoche02a03_06.current.value = '';
        this.cReporteTurnoNoche02a03_07.current.value = '';
        this.cReporteTurnoNoche02a03_08.current.value = '';
        this.cReporteTurnoNoche02a03_09.current.value = '';
        this.cReporteTurnoNoche02a03_10.current.value = '';
        this.cReporteTurnoNoche02a03_11.current.value = '';
        this.cReporteTurnoNoche02a03_12.current.value = '';
        this.cReporteTurnoNoche02a03_13.current.value = '';

        this.cReporteTurnoNoche03a04_01.current.value = '';
        this.cReporteTurnoNoche03a04_02.current.value = '';
        this.cReporteTurnoNoche03a04_03.current.value = '';
        this.cReporteTurnoNoche03a04_04.current.value = '';
        this.cReporteTurnoNoche03a04_05.current.value = '';
        this.cReporteTurnoNoche03a04_06.current.value = '';
        this.cReporteTurnoNoche03a04_07.current.value = '';
        this.cReporteTurnoNoche03a04_08.current.value = '';
        this.cReporteTurnoNoche03a04_09.current.value = '';
        this.cReporteTurnoNoche03a04_10.current.value = '';
        this.cReporteTurnoNoche03a04_11.current.value = '';
        this.cReporteTurnoNoche03a04_12.current.value = '';
        this.cReporteTurnoNoche03a04_13.current.value = '';

        this.cReporteTurnoNoche04a05_01.current.value = '';
        this.cReporteTurnoNoche04a05_02.current.value = '';
        this.cReporteTurnoNoche04a05_03.current.value = '';
        this.cReporteTurnoNoche04a05_04.current.value = '';
        this.cReporteTurnoNoche04a05_05.current.value = '';
        this.cReporteTurnoNoche04a05_06.current.value = '';
        this.cReporteTurnoNoche04a05_07.current.value = '';
        this.cReporteTurnoNoche04a05_08.current.value = '';
        this.cReporteTurnoNoche04a05_09.current.value = '';
        this.cReporteTurnoNoche04a05_10.current.value = '';
        this.cReporteTurnoNoche04a05_11.current.value = '';
        this.cReporteTurnoNoche04a05_12.current.value = '';
        this.cReporteTurnoNoche04a05_13.current.value = '';

        this.cReporteTurnoNoche05a06_01.current.value = '';
        this.cReporteTurnoNoche05a06_02.current.value = '';
        this.cReporteTurnoNoche05a06_03.current.value = '';
        this.cReporteTurnoNoche05a06_04.current.value = '';
        this.cReporteTurnoNoche05a06_05.current.value = '';
        this.cReporteTurnoNoche05a06_06.current.value = '';
        this.cReporteTurnoNoche05a06_07.current.value = '';
        this.cReporteTurnoNoche05a06_08.current.value = '';
        this.cReporteTurnoNoche05a06_09.current.value = '';
        this.cReporteTurnoNoche05a06_10.current.value = '';
        this.cReporteTurnoNoche05a06_11.current.value = '';
        this.cReporteTurnoNoche05a06_12.current.value = '';
        this.cReporteTurnoNoche05a06_13.current.value = '';

        this.cReporteTurnoNoche06a07_01.current.value = '';
        this.cReporteTurnoNoche06a07_02.current.value = '';
        this.cReporteTurnoNoche06a07_03.current.value = '';
        this.cReporteTurnoNoche06a07_04.current.value = '';
        this.cReporteTurnoNoche06a07_05.current.value = '';
        this.cReporteTurnoNoche06a07_06.current.value = '';
        this.cReporteTurnoNoche06a07_07.current.value = '';
        this.cReporteTurnoNoche06a07_08.current.value = '';
        this.cReporteTurnoNoche06a07_09.current.value = '';
        this.cReporteTurnoNoche06a07_10.current.value = '';
        this.cReporteTurnoNoche06a07_11.current.value = '';
        this.cReporteTurnoNoche06a07_12.current.value = '';
        this.cReporteTurnoNoche06a07_13.current.value = '';

        this.cReporteTurnoNoche07a08_01.current.value = '';
        this.cReporteTurnoNoche07a08_02.current.value = '';
        this.cReporteTurnoNoche07a08_03.current.value = '';
        this.cReporteTurnoNoche07a08_04.current.value = '';
        this.cReporteTurnoNoche07a08_05.current.value = '';
        this.cReporteTurnoNoche07a08_06.current.value = '';
        this.cReporteTurnoNoche07a08_07.current.value = '';
        this.cReporteTurnoNoche07a08_08.current.value = '';
        this.cReporteTurnoNoche07a08_09.current.value = '';
        this.cReporteTurnoNoche07a08_10.current.value = '';
        this.cReporteTurnoNoche07a08_11.current.value = '';
        this.cReporteTurnoNoche07a08_12.current.value = '';
        this.cReporteTurnoNoche07a08_13.current.value = '';


        this.cReporteTurnoNoche20a21_01_2.current.value = '';
        this.cReporteTurnoNoche20a21_02_2.current.value = '';
        this.cReporteTurnoNoche20a21_03_2.current.value = '';
        this.cReporteTurnoNoche20a21_04_2.current.value = '';
        this.cReporteTurnoNoche20a21_05_2.current.value = '';
        this.cReporteTurnoNoche20a21_06_2.current.value = '';
        this.cReporteTurnoNoche20a21_07_2.current.value = '';
        this.cReporteTurnoNoche20a21_08_2.current.value = '';
        this.cReporteTurnoNoche20a21_09_2.current.value = '';
        this.cReporteTurnoNoche20a21_10_2.current.value = '';
        this.cReporteTurnoNoche20a21_11_2.current.value = '';
        this.cReporteTurnoNoche20a21_12_2.current.value = '';
        this.cReporteTurnoNoche20a21_13_2.current.value = '';

        this.cReporteTurnoNoche21a22_01_2.current.value = '';
        this.cReporteTurnoNoche21a22_02_2.current.value = '';
        this.cReporteTurnoNoche21a22_03_2.current.value = '';
        this.cReporteTurnoNoche21a22_04_2.current.value = '';
        this.cReporteTurnoNoche21a22_05_2.current.value = '';
        this.cReporteTurnoNoche21a22_06_2.current.value = '';
        this.cReporteTurnoNoche21a22_07_2.current.value = '';
        this.cReporteTurnoNoche21a22_08_2.current.value = '';
        this.cReporteTurnoNoche21a22_09_2.current.value = '';
        this.cReporteTurnoNoche21a22_10_2.current.value = '';
        this.cReporteTurnoNoche21a22_11_2.current.value = '';
        this.cReporteTurnoNoche21a22_12_2.current.value = '';
        this.cReporteTurnoNoche21a22_13_2.current.value = '';

        this.cReporteTurnoNoche22a23_01_2.current.value = '';
        this.cReporteTurnoNoche22a23_02_2.current.value = '';
        this.cReporteTurnoNoche22a23_03_2.current.value = '';
        this.cReporteTurnoNoche22a23_04_2.current.value = '';
        this.cReporteTurnoNoche22a23_05_2.current.value = '';
        this.cReporteTurnoNoche22a23_06_2.current.value = '';
        this.cReporteTurnoNoche22a23_07_2.current.value = '';
        this.cReporteTurnoNoche22a23_08_2.current.value = '';
        this.cReporteTurnoNoche22a23_09_2.current.value = '';
        this.cReporteTurnoNoche22a23_10_2.current.value = '';
        this.cReporteTurnoNoche22a23_11_2.current.value = '';
        this.cReporteTurnoNoche22a23_12_2.current.value = '';
        this.cReporteTurnoNoche22a23_13_2.current.value = '';

        this.cReporteTurnoNoche23a24_01_2.current.value = '';
        this.cReporteTurnoNoche23a24_02_2.current.value = '';
        this.cReporteTurnoNoche23a24_03_2.current.value = '';
        this.cReporteTurnoNoche23a24_04_2.current.value = '';
        this.cReporteTurnoNoche23a24_05_2.current.value = '';
        this.cReporteTurnoNoche23a24_06_2.current.value = '';
        this.cReporteTurnoNoche23a24_07_2.current.value = '';
        this.cReporteTurnoNoche23a24_08_2.current.value = '';
        this.cReporteTurnoNoche23a24_09_2.current.value = '';
        this.cReporteTurnoNoche23a24_10_2.current.value = '';
        this.cReporteTurnoNoche23a24_11_2.current.value = '';
        this.cReporteTurnoNoche23a24_12_2.current.value = '';
        this.cReporteTurnoNoche23a24_13_2.current.value = '';

        this.cReporteTurnoNoche24a01_01_2.current.value = '';
        this.cReporteTurnoNoche24a01_02_2.current.value = '';
        this.cReporteTurnoNoche24a01_03_2.current.value = '';
        this.cReporteTurnoNoche24a01_04_2.current.value = '';
        this.cReporteTurnoNoche24a01_05_2.current.value = '';
        this.cReporteTurnoNoche24a01_06_2.current.value = '';
        this.cReporteTurnoNoche24a01_07_2.current.value = '';
        this.cReporteTurnoNoche24a01_08_2.current.value = '';
        this.cReporteTurnoNoche24a01_09_2.current.value = '';
        this.cReporteTurnoNoche24a01_10_2.current.value = '';
        this.cReporteTurnoNoche24a01_11_2.current.value = '';
        this.cReporteTurnoNoche24a01_12_2.current.value = '';
        this.cReporteTurnoNoche24a01_13_2.current.value = '';

        this.cReporteTurnoNoche01a02_01_2.current.value = '';
        this.cReporteTurnoNoche01a02_02_2.current.value = '';
        this.cReporteTurnoNoche01a02_03_2.current.value = '';
        this.cReporteTurnoNoche01a02_04_2.current.value = '';
        this.cReporteTurnoNoche01a02_05_2.current.value = '';
        this.cReporteTurnoNoche01a02_06_2.current.value = '';
        this.cReporteTurnoNoche01a02_07_2.current.value = '';
        this.cReporteTurnoNoche01a02_08_2.current.value = '';
        this.cReporteTurnoNoche01a02_09_2.current.value = '';
        this.cReporteTurnoNoche01a02_10_2.current.value = '';
        this.cReporteTurnoNoche01a02_11_2.current.value = '';
        this.cReporteTurnoNoche01a02_12_2.current.value = '';
        this.cReporteTurnoNoche01a02_13_2.current.value = '';

        this.cReporteTurnoNoche02a03_01_2.current.value = '';
        this.cReporteTurnoNoche02a03_02_2.current.value = '';
        this.cReporteTurnoNoche02a03_03_2.current.value = '';
        this.cReporteTurnoNoche02a03_04_2.current.value = '';
        this.cReporteTurnoNoche02a03_05_2.current.value = '';
        this.cReporteTurnoNoche02a03_06_2.current.value = '';
        this.cReporteTurnoNoche02a03_07_2.current.value = '';
        this.cReporteTurnoNoche02a03_08_2.current.value = '';
        this.cReporteTurnoNoche02a03_09_2.current.value = '';
        this.cReporteTurnoNoche02a03_10_2.current.value = '';
        this.cReporteTurnoNoche02a03_11_2.current.value = '';
        this.cReporteTurnoNoche02a03_12_2.current.value = '';
        this.cReporteTurnoNoche02a03_13_2.current.value = '';

        this.cReporteTurnoNoche03a04_01_2.current.value = '';
        this.cReporteTurnoNoche03a04_02_2.current.value = '';
        this.cReporteTurnoNoche03a04_03_2.current.value = '';
        this.cReporteTurnoNoche03a04_04_2.current.value = '';
        this.cReporteTurnoNoche03a04_05_2.current.value = '';
        this.cReporteTurnoNoche03a04_06_2.current.value = '';
        this.cReporteTurnoNoche03a04_07_2.current.value = '';
        this.cReporteTurnoNoche03a04_08_2.current.value = '';
        this.cReporteTurnoNoche03a04_09_2.current.value = '';
        this.cReporteTurnoNoche03a04_10_2.current.value = '';
        this.cReporteTurnoNoche03a04_11_2.current.value = '';
        this.cReporteTurnoNoche03a04_12_2.current.value = '';
        this.cReporteTurnoNoche03a04_13_2.current.value = '';

        this.cReporteTurnoNoche04a05_01_2.current.value = '';
        this.cReporteTurnoNoche04a05_02_2.current.value = '';
        this.cReporteTurnoNoche04a05_03_2.current.value = '';
        this.cReporteTurnoNoche04a05_04_2.current.value = '';
        this.cReporteTurnoNoche04a05_05_2.current.value = '';
        this.cReporteTurnoNoche04a05_06_2.current.value = '';
        this.cReporteTurnoNoche04a05_07_2.current.value = '';
        this.cReporteTurnoNoche04a05_08_2.current.value = '';
        this.cReporteTurnoNoche04a05_09_2.current.value = '';
        this.cReporteTurnoNoche04a05_10_2.current.value = '';
        this.cReporteTurnoNoche04a05_11_2.current.value = '';
        this.cReporteTurnoNoche04a05_12_2.current.value = '';
        this.cReporteTurnoNoche04a05_13_2.current.value = '';

        this.cReporteTurnoNoche05a06_01_2.current.value = '';
        this.cReporteTurnoNoche05a06_02_2.current.value = '';
        this.cReporteTurnoNoche05a06_03_2.current.value = '';
        this.cReporteTurnoNoche05a06_04_2.current.value = '';
        this.cReporteTurnoNoche05a06_05_2.current.value = '';
        this.cReporteTurnoNoche05a06_06_2.current.value = '';
        this.cReporteTurnoNoche05a06_07_2.current.value = '';
        this.cReporteTurnoNoche05a06_08_2.current.value = '';
        this.cReporteTurnoNoche05a06_09_2.current.value = '';
        this.cReporteTurnoNoche05a06_10_2.current.value = '';
        this.cReporteTurnoNoche05a06_11_2.current.value = '';
        this.cReporteTurnoNoche05a06_12_2.current.value = '';
        this.cReporteTurnoNoche05a06_13_2.current.value = '';

        this.cReporteTurnoNoche06a07_01_2.current.value = '';
        this.cReporteTurnoNoche06a07_02_2.current.value = '';
        this.cReporteTurnoNoche06a07_03_2.current.value = '';
        this.cReporteTurnoNoche06a07_04_2.current.value = '';
        this.cReporteTurnoNoche06a07_05_2.current.value = '';
        this.cReporteTurnoNoche06a07_06_2.current.value = '';
        this.cReporteTurnoNoche06a07_07_2.current.value = '';
        this.cReporteTurnoNoche06a07_08_2.current.value = '';
        this.cReporteTurnoNoche06a07_09_2.current.value = '';
        this.cReporteTurnoNoche06a07_10_2.current.value = '';
        this.cReporteTurnoNoche06a07_11_2.current.value = '';
        this.cReporteTurnoNoche06a07_12_2.current.value = '';
        this.cReporteTurnoNoche06a07_13_2.current.value = '';

        this.cReporteTurnoNoche07a08_01_2.current.value = '';
        this.cReporteTurnoNoche07a08_02_2.current.value = '';
        this.cReporteTurnoNoche07a08_03_2.current.value = '';
        this.cReporteTurnoNoche07a08_04_2.current.value = '';
        this.cReporteTurnoNoche07a08_05_2.current.value = '';
        this.cReporteTurnoNoche07a08_06_2.current.value = '';
        this.cReporteTurnoNoche07a08_07_2.current.value = '';
        this.cReporteTurnoNoche07a08_08_2.current.value = '';
        this.cReporteTurnoNoche07a08_09_2.current.value = '';
        this.cReporteTurnoNoche07a08_10_2.current.value = '';
        this.cReporteTurnoNoche07a08_11_2.current.value = '';
        this.cReporteTurnoNoche07a08_12_2.current.value = '';
        this.cReporteTurnoNoche07a08_13_2.current.value = '';

        this.setState({cReporteTurnoNameFirmante1: ''})
        this.setState({cReporteTurnoIdFirmante1: ''})
        this.setState({cReporteTurnoPublicKeyFirmante1: ''})
        this.setState({cReporteTurnoDateTimeFirmante1: ''})
        this.setState({cReporteTurnoSignatureFirmante1: false})
        this.setState({cReporteTurnoNameFirmante2: ''})
        this.setState({cReporteTurnoIdFirmante2: ''})
        this.setState({cReporteTurnoPublicKeyFirmante2: ''})
        this.setState({cReporteTurnoDateTimeFirmante2: ''})
        this.setState({cReporteTurnoSignatureFirmante2: false})
        this.setState({cReporteTurnoNameFirmante3: ''})
        this.setState({cReporteTurnoIdFirmante3: ''})
        this.setState({cReporteTurnoPublicKeyFirmante3: ''})
        this.setState({cReporteTurnoDateTimeFirmante3: ''})
        this.setState({cReporteTurnoSignatureFirmante3: false})
        this.setState({cReporteTurnoNameFirmante4: ''})
        this.setState({cReporteTurnoIdFirmante4: ''})
        this.setState({cReporteTurnoPublicKeyFirmante4: ''})
        this.setState({cReporteTurnoDateTimeFirmante4: ''})
        this.setState({cReporteTurnoSignatureFirmante4: false})
        this.setState({cReporteTurnoNameFirmante5: ''})
        this.setState({cReporteTurnoIdFirmante5: ''})
        this.setState({cReporteTurnoPublicKeyFirmante5: ''})
        this.setState({cReporteTurnoDateTimeFirmante5: ''})
        this.setState({cReporteTurnoSignatureFirmante5: false})
        this.setState({cReporteTurnoNameFirmante6: ''})
        this.setState({cReporteTurnoIdFirmante6: ''})
        this.setState({cReporteTurnoPublicKeyFirmante6: ''})
        this.setState({cReporteTurnoDateTimeFirmante6: ''})
        this.setState({cReporteTurnoSignatureFirmante6: false})
        this.setState({cReporteTurnoNameFirmante7: ''})
        this.setState({cReporteTurnoIdFirmante7: ''})
        this.setState({cReporteTurnoPublicKeyFirmante7: ''})
        this.setState({cReporteTurnoDateTimeFirmante7: ''})
        this.setState({cReporteTurnoSignatureFirmante7: false})
        this.setState({cReporteTurnotrimmedDataURLFirmante1: ''})
        this.setState({cReporteTurnotrimmedDataURLFirmante2: ''})
        this.setState({cReporteTurnotrimmedDataURLFirmante3: ''})
        this.setState({cReporteTurnotrimmedDataURLFirmante4: ''})
        this.setState({cReporteTurnotrimmedDataURLFirmante5: ''})
        this.setState({cReporteTurnotrimmedDataURLFirmante6: ''})
        this.setState({cReporteTurnotrimmedDataURLFirmante7: ''})

        this.setState({cReporteTurnoObservacionAsesorTurnoDia: ''})
        this.setState({cReporteTurnoObservacionAsesorTurnoNoche: ''})
        this.setState({cReporteTurnoObservacionJefeTurnoDia: ''})
        this.setState({cReporteTurnoObservacionJefeTurnoNoche: ''})
        this.setState({cReporteTurnoObservacionFirmante1: ''})
        this.setState({cReporteTurnoObservacionFirmante2: ''})
        this.setState({cReporteTurnoObservacionFirmante3: ''})
        this.setState({cReporteTurnoObservacionFirmante4: ''})
        this.setState({cReporteTurnoObservacionFirmante5: ''})
        this.setState({cReporteTurnoObservacionFirmante6: ''})
        this.setState({cReporteTurnoObservacionFirmante7: ''})

        this.setState({tokenMayorDiaX:0})
        this.setState({tokenMayorNocheX:0})
        this.setState({fondoPozoTurnoDiaX:[]})
        this.setState({fondoPozoTurnoNocheX:[]})
        this.setState({cancelVersion: false})
        this.setState({consolidadoX: 0})
        this.setState({consolidadoFondoX: 0})
        this.setState({consolidadoFondoOrigenDiaX: 0})
        this.setState({consolidadoFondoOrigenNocheX: 0})
        this.setState({consolidadoFondoOrigenDiaLlenaX: 0})
        this.setState({consolidadoFondoOrigenNocheLlenaX: 0})
        this.setState({pdfReporteTurnoDiaX: {}})
        this.setState({pdfReporteTurnoNocheX: {}})
        this.setState({bFondoPozoExiste: false, fondoPozoDiaX: 0,  fondoPozoNocheX: 0})
        this.setState({displayExportX: false})
        this.setState({displayPdfX: false})

        this.setState({ confirmaSeguirNoHayDiaAnterior: true })

        resolve1()
      })
    }

    limpiaLinea = (optX) => {
        return new Promise((resolve1, reject1) => {
          if (optX === 1){
            this.cReporteTurnoDia08a09_02.current.value = '';
            this.cReporteTurnoDia08a09_03.current.value = '';
            this.cReporteTurnoDia08a09_04.current.value = '';
            this.cReporteTurnoDia08a09_05.current.value = '';
            this.cReporteTurnoDia08a09_06.current.value = '';
            this.cReporteTurnoDia08a09_07.current.value = '';
            this.cReporteTurnoDia08a09_08.current.value = '';
            this.cReporteTurnoDia08a09_09.current.value = '';
            this.cReporteTurnoDia08a09_10.current.value = '';
            this.cReporteTurnoDia08a09_11.current.value = '';
            this.cReporteTurnoDia08a09_12.current.value = '';
            this.cReporteTurnoDia08a09_13.current.value = '';
          }
          if (optX === 3){
            this.cReporteTurnoDia09a10_02.current.value = '';
            this.cReporteTurnoDia09a10_03.current.value = '';
            this.cReporteTurnoDia09a10_04.current.value = '';
            this.cReporteTurnoDia09a10_05.current.value = '';
            this.cReporteTurnoDia09a10_06.current.value = '';
            this.cReporteTurnoDia09a10_07.current.value = '';
            this.cReporteTurnoDia09a10_08.current.value = '';
            this.cReporteTurnoDia09a10_09.current.value = '';
            this.cReporteTurnoDia09a10_10.current.value = '';
            this.cReporteTurnoDia09a10_11.current.value = '';
            this.cReporteTurnoDia09a10_12.current.value = '';
            this.cReporteTurnoDia09a10_13.current.value = '';
          }
          if (optX === 5){
            this.cReporteTurnoDia10a11_02.current.value = '';
            this.cReporteTurnoDia10a11_03.current.value = '';
            this.cReporteTurnoDia10a11_04.current.value = '';
            this.cReporteTurnoDia10a11_05.current.value = '';
            this.cReporteTurnoDia10a11_06.current.value = '';
            this.cReporteTurnoDia10a11_07.current.value = '';
            this.cReporteTurnoDia10a11_08.current.value = '';
            this.cReporteTurnoDia10a11_09.current.value = '';
            this.cReporteTurnoDia10a11_10.current.value = '';
            this.cReporteTurnoDia10a11_11.current.value = '';
            this.cReporteTurnoDia10a11_12.current.value = '';
            this.cReporteTurnoDia10a11_13.current.value = '';
          }
          if (optX === 7){
            this.cReporteTurnoDia11a12_02.current.value = '';
            this.cReporteTurnoDia11a12_03.current.value = '';
            this.cReporteTurnoDia11a12_04.current.value = '';
            this.cReporteTurnoDia11a12_05.current.value = '';
            this.cReporteTurnoDia11a12_06.current.value = '';
            this.cReporteTurnoDia11a12_07.current.value = '';
            this.cReporteTurnoDia11a12_08.current.value = '';
            this.cReporteTurnoDia11a12_09.current.value = '';
            this.cReporteTurnoDia11a12_10.current.value = '';
            this.cReporteTurnoDia11a12_11.current.value = '';
            this.cReporteTurnoDia11a12_12.current.value = '';
            this.cReporteTurnoDia11a12_13.current.value = '';
          }
          if (optX === 9){
            this.cReporteTurnoDia12a13_02.current.value = '';
            this.cReporteTurnoDia12a13_03.current.value = '';
            this.cReporteTurnoDia12a13_04.current.value = '';
            this.cReporteTurnoDia12a13_05.current.value = '';
            this.cReporteTurnoDia12a13_06.current.value = '';
            this.cReporteTurnoDia12a13_07.current.value = '';
            this.cReporteTurnoDia12a13_08.current.value = '';
            this.cReporteTurnoDia12a13_09.current.value = '';
            this.cReporteTurnoDia12a13_10.current.value = '';
            this.cReporteTurnoDia12a13_11.current.value = '';
            this.cReporteTurnoDia12a13_12.current.value = '';
            this.cReporteTurnoDia12a13_13.current.value = '';
          }
          if (optX === 11){
            this.cReporteTurnoDia13a14_02.current.value = '';
            this.cReporteTurnoDia13a14_03.current.value = '';
            this.cReporteTurnoDia13a14_04.current.value = '';
            this.cReporteTurnoDia13a14_05.current.value = '';
            this.cReporteTurnoDia13a14_06.current.value = '';
            this.cReporteTurnoDia13a14_07.current.value = '';
            this.cReporteTurnoDia13a14_08.current.value = '';
            this.cReporteTurnoDia13a14_09.current.value = '';
            this.cReporteTurnoDia13a14_10.current.value = '';
            this.cReporteTurnoDia13a14_11.current.value = '';
            this.cReporteTurnoDia13a14_12.current.value = '';
            this.cReporteTurnoDia13a14_13.current.value = '';
          }
          if (optX === 13){
            this.cReporteTurnoDia14a15_02.current.value = '';
            this.cReporteTurnoDia14a15_03.current.value = '';
            this.cReporteTurnoDia14a15_04.current.value = '';
            this.cReporteTurnoDia14a15_05.current.value = '';
            this.cReporteTurnoDia14a15_06.current.value = '';
            this.cReporteTurnoDia14a15_07.current.value = '';
            this.cReporteTurnoDia14a15_08.current.value = '';
            this.cReporteTurnoDia14a15_09.current.value = '';
            this.cReporteTurnoDia14a15_10.current.value = '';
            this.cReporteTurnoDia14a15_11.current.value = '';
            this.cReporteTurnoDia14a15_12.current.value = '';
            this.cReporteTurnoDia14a15_13.current.value = '';
          }
          if (optX === 15){
            this.cReporteTurnoDia15a16_02.current.value = '';
            this.cReporteTurnoDia15a16_03.current.value = '';
            this.cReporteTurnoDia15a16_04.current.value = '';
            this.cReporteTurnoDia15a16_05.current.value = '';
            this.cReporteTurnoDia15a16_06.current.value = '';
            this.cReporteTurnoDia15a16_07.current.value = '';
            this.cReporteTurnoDia15a16_08.current.value = '';
            this.cReporteTurnoDia15a16_09.current.value = '';
            this.cReporteTurnoDia15a16_10.current.value = '';
            this.cReporteTurnoDia15a16_11.current.value = '';
            this.cReporteTurnoDia15a16_12.current.value = '';
            this.cReporteTurnoDia15a16_13.current.value = '';
          }
          if (optX === 17){
            this.cReporteTurnoDia16a17_02.current.value = '';
            this.cReporteTurnoDia16a17_03.current.value = '';
            this.cReporteTurnoDia16a17_04.current.value = '';
            this.cReporteTurnoDia16a17_05.current.value = '';
            this.cReporteTurnoDia16a17_06.current.value = '';
            this.cReporteTurnoDia16a17_07.current.value = '';
            this.cReporteTurnoDia16a17_08.current.value = '';
            this.cReporteTurnoDia16a17_09.current.value = '';
            this.cReporteTurnoDia16a17_10.current.value = '';
            this.cReporteTurnoDia16a17_11.current.value = '';
            this.cReporteTurnoDia16a17_12.current.value = '';
            this.cReporteTurnoDia16a17_13.current.value = '';
          }
          if (optX === 19){
            this.cReporteTurnoDia17a18_02.current.value = '';
            this.cReporteTurnoDia17a18_03.current.value = '';
            this.cReporteTurnoDia17a18_04.current.value = '';
            this.cReporteTurnoDia17a18_05.current.value = '';
            this.cReporteTurnoDia17a18_06.current.value = '';
            this.cReporteTurnoDia17a18_07.current.value = '';
            this.cReporteTurnoDia17a18_08.current.value = '';
            this.cReporteTurnoDia17a18_09.current.value = '';
            this.cReporteTurnoDia17a18_10.current.value = '';
            this.cReporteTurnoDia17a18_11.current.value = '';
            this.cReporteTurnoDia17a18_12.current.value = '';
            this.cReporteTurnoDia17a18_13.current.value = '';
          }
          if (optX === 21){
            this.cReporteTurnoDia18a19_02.current.value = '';
            this.cReporteTurnoDia18a19_03.current.value = '';
            this.cReporteTurnoDia18a19_04.current.value = '';
            this.cReporteTurnoDia18a19_05.current.value = '';
            this.cReporteTurnoDia18a19_06.current.value = '';
            this.cReporteTurnoDia18a19_07.current.value = '';
            this.cReporteTurnoDia18a19_08.current.value = '';
            this.cReporteTurnoDia18a19_09.current.value = '';
            this.cReporteTurnoDia18a19_10.current.value = '';
            this.cReporteTurnoDia18a19_11.current.value = '';
            this.cReporteTurnoDia18a19_12.current.value = '';
            this.cReporteTurnoDia18a19_13.current.value = '';
          }
          if (optX === 23){
            this.cReporteTurnoDia19a20_02.current.value = '';
            this.cReporteTurnoDia19a20_03.current.value = '';
            this.cReporteTurnoDia19a20_04.current.value = '';
            this.cReporteTurnoDia19a20_05.current.value = '';
            this.cReporteTurnoDia19a20_06.current.value = '';
            this.cReporteTurnoDia19a20_07.current.value = '';
            this.cReporteTurnoDia19a20_08.current.value = '';
            this.cReporteTurnoDia19a20_09.current.value = '';
            this.cReporteTurnoDia19a20_10.current.value = '';
            this.cReporteTurnoDia19a20_11.current.value = '';
            this.cReporteTurnoDia19a20_12.current.value = '';
            this.cReporteTurnoDia19a20_13.current.value = '';
          }
          if (optX === 2){
            this.cReporteTurnoDia08a09_02_2.current.value = '';
            this.cReporteTurnoDia08a09_03_2.current.value = '';
            this.cReporteTurnoDia08a09_04_2.current.value = '';
            this.cReporteTurnoDia08a09_05_2.current.value = '';
            this.cReporteTurnoDia08a09_06_2.current.value = '';
            this.cReporteTurnoDia08a09_07_2.current.value = '';
            this.cReporteTurnoDia08a09_08_2.current.value = '';
            this.cReporteTurnoDia08a09_09_2.current.value = '';
            this.cReporteTurnoDia08a09_10_2.current.value = '';
            this.cReporteTurnoDia08a09_11_2.current.value = '';
            this.cReporteTurnoDia08a09_12_2.current.value = '';
            this.cReporteTurnoDia08a09_13_2.current.value = '';
          }
          if (optX === 4){
            this.cReporteTurnoDia09a10_02_2.current.value = '';
            this.cReporteTurnoDia09a10_03_2.current.value = '';
            this.cReporteTurnoDia09a10_04_2.current.value = '';
            this.cReporteTurnoDia09a10_05_2.current.value = '';
            this.cReporteTurnoDia09a10_06_2.current.value = '';
            this.cReporteTurnoDia09a10_07_2.current.value = '';
            this.cReporteTurnoDia09a10_08_2.current.value = '';
            this.cReporteTurnoDia09a10_09_2.current.value = '';
            this.cReporteTurnoDia09a10_10_2.current.value = '';
            this.cReporteTurnoDia09a10_11_2.current.value = '';
            this.cReporteTurnoDia09a10_12_2.current.value = '';
            this.cReporteTurnoDia09a10_13_2.current.value = '';
          }
          if (optX === 6){
            this.cReporteTurnoDia10a11_02_2.current.value = '';
            this.cReporteTurnoDia10a11_03_2.current.value = '';
            this.cReporteTurnoDia10a11_04_2.current.value = '';
            this.cReporteTurnoDia10a11_05_2.current.value = '';
            this.cReporteTurnoDia10a11_06_2.current.value = '';
            this.cReporteTurnoDia10a11_07_2.current.value = '';
            this.cReporteTurnoDia10a11_08_2.current.value = '';
            this.cReporteTurnoDia10a11_09_2.current.value = '';
            this.cReporteTurnoDia10a11_10_2.current.value = '';
            this.cReporteTurnoDia10a11_11_2.current.value = '';
            this.cReporteTurnoDia10a11_12_2.current.value = '';
            this.cReporteTurnoDia10a11_13_2.current.value = '';
          }
          if (optX === 8){
            this.cReporteTurnoDia11a12_02_2.current.value = '';
            this.cReporteTurnoDia11a12_03_2.current.value = '';
            this.cReporteTurnoDia11a12_04_2.current.value = '';
            this.cReporteTurnoDia11a12_05_2.current.value = '';
            this.cReporteTurnoDia11a12_06_2.current.value = '';
            this.cReporteTurnoDia11a12_07_2.current.value = '';
            this.cReporteTurnoDia11a12_08_2.current.value = '';
            this.cReporteTurnoDia11a12_09_2.current.value = '';
            this.cReporteTurnoDia11a12_10_2.current.value = '';
            this.cReporteTurnoDia11a12_11_2.current.value = '';
            this.cReporteTurnoDia11a12_12_2.current.value = '';
            this.cReporteTurnoDia11a12_13_2.current.value = '';
          }
          if (optX === 10){
            this.cReporteTurnoDia12a13_02_2.current.value = '';
            this.cReporteTurnoDia12a13_03_2.current.value = '';
            this.cReporteTurnoDia12a13_04_2.current.value = '';
            this.cReporteTurnoDia12a13_05_2.current.value = '';
            this.cReporteTurnoDia12a13_06_2.current.value = '';
            this.cReporteTurnoDia12a13_07_2.current.value = '';
            this.cReporteTurnoDia12a13_08_2.current.value = '';
            this.cReporteTurnoDia12a13_09_2.current.value = '';
            this.cReporteTurnoDia12a13_10_2.current.value = '';
            this.cReporteTurnoDia12a13_11_2.current.value = '';
            this.cReporteTurnoDia12a13_12_2.current.value = '';
            this.cReporteTurnoDia12a13_13_2.current.value = '';
          }
          if (optX === 12){
            this.cReporteTurnoDia13a14_02_2.current.value = '';
            this.cReporteTurnoDia13a14_03_2.current.value = '';
            this.cReporteTurnoDia13a14_04_2.current.value = '';
            this.cReporteTurnoDia13a14_05_2.current.value = '';
            this.cReporteTurnoDia13a14_06_2.current.value = '';
            this.cReporteTurnoDia13a14_07_2.current.value = '';
            this.cReporteTurnoDia13a14_08_2.current.value = '';
            this.cReporteTurnoDia13a14_09_2.current.value = '';
            this.cReporteTurnoDia13a14_10_2.current.value = '';
            this.cReporteTurnoDia13a14_11_2.current.value = '';
            this.cReporteTurnoDia13a14_12_2.current.value = '';
            this.cReporteTurnoDia13a14_13_2.current.value = '';
          }
          if (optX === 14){
            this.cReporteTurnoDia14a15_02_2.current.value = '';
            this.cReporteTurnoDia14a15_03_2.current.value = '';
            this.cReporteTurnoDia14a15_04_2.current.value = '';
            this.cReporteTurnoDia14a15_05_2.current.value = '';
            this.cReporteTurnoDia14a15_06_2.current.value = '';
            this.cReporteTurnoDia14a15_07_2.current.value = '';
            this.cReporteTurnoDia14a15_08_2.current.value = '';
            this.cReporteTurnoDia14a15_09_2.current.value = '';
            this.cReporteTurnoDia14a15_10_2.current.value = '';
            this.cReporteTurnoDia14a15_11_2.current.value = '';
            this.cReporteTurnoDia14a15_12_2.current.value = '';
            this.cReporteTurnoDia14a15_13_2.current.value = '';
          }
          if (optX === 16){
            this.cReporteTurnoDia15a16_02_2.current.value = '';
            this.cReporteTurnoDia15a16_03_2.current.value = '';
            this.cReporteTurnoDia15a16_04_2.current.value = '';
            this.cReporteTurnoDia15a16_05_2.current.value = '';
            this.cReporteTurnoDia15a16_06_2.current.value = '';
            this.cReporteTurnoDia15a16_07_2.current.value = '';
            this.cReporteTurnoDia15a16_08_2.current.value = '';
            this.cReporteTurnoDia15a16_09_2.current.value = '';
            this.cReporteTurnoDia15a16_10_2.current.value = '';
            this.cReporteTurnoDia15a16_11_2.current.value = '';
            this.cReporteTurnoDia15a16_12_2.current.value = '';
            this.cReporteTurnoDia15a16_13_2.current.value = '';
          }
          if (optX === 18){
            this.cReporteTurnoDia16a17_02_2.current.value = '';
            this.cReporteTurnoDia16a17_03_2.current.value = '';
            this.cReporteTurnoDia16a17_04_2.current.value = '';
            this.cReporteTurnoDia16a17_05_2.current.value = '';
            this.cReporteTurnoDia16a17_06_2.current.value = '';
            this.cReporteTurnoDia16a17_07_2.current.value = '';
            this.cReporteTurnoDia16a17_08_2.current.value = '';
            this.cReporteTurnoDia16a17_09_2.current.value = '';
            this.cReporteTurnoDia16a17_10_2.current.value = '';
            this.cReporteTurnoDia16a17_11_2.current.value = '';
            this.cReporteTurnoDia16a17_12_2.current.value = '';
            this.cReporteTurnoDia16a17_13_2.current.value = '';
          }
          if (optX === 20){
            this.cReporteTurnoDia17a18_02_2.current.value = '';
            this.cReporteTurnoDia17a18_03_2.current.value = '';
            this.cReporteTurnoDia17a18_04_2.current.value = '';
            this.cReporteTurnoDia17a18_05_2.current.value = '';
            this.cReporteTurnoDia17a18_06_2.current.value = '';
            this.cReporteTurnoDia17a18_07_2.current.value = '';
            this.cReporteTurnoDia17a18_08_2.current.value = '';
            this.cReporteTurnoDia17a18_09_2.current.value = '';
            this.cReporteTurnoDia17a18_10_2.current.value = '';
            this.cReporteTurnoDia17a18_11_2.current.value = '';
            this.cReporteTurnoDia17a18_12_2.current.value = '';
            this.cReporteTurnoDia17a18_13_2.current.value = '';
          }
          if (optX === 22){
            this.cReporteTurnoDia18a19_02_2.current.value = '';
            this.cReporteTurnoDia18a19_03_2.current.value = '';
            this.cReporteTurnoDia18a19_04_2.current.value = '';
            this.cReporteTurnoDia18a19_05_2.current.value = '';
            this.cReporteTurnoDia18a19_06_2.current.value = '';
            this.cReporteTurnoDia18a19_07_2.current.value = '';
            this.cReporteTurnoDia18a19_08_2.current.value = '';
            this.cReporteTurnoDia18a19_09_2.current.value = '';
            this.cReporteTurnoDia18a19_10_2.current.value = '';
            this.cReporteTurnoDia18a19_11_2.current.value = '';
            this.cReporteTurnoDia18a19_12_2.current.value = '';
            this.cReporteTurnoDia18a19_13_2.current.value = '';
          }
          if (optX === 24){
            this.cReporteTurnoDia19a20_02_2.current.value = '';
            this.cReporteTurnoDia19a20_03_2.current.value = '';
            this.cReporteTurnoDia19a20_04_2.current.value = '';
            this.cReporteTurnoDia19a20_05_2.current.value = '';
            this.cReporteTurnoDia19a20_06_2.current.value = '';
            this.cReporteTurnoDia19a20_07_2.current.value = '';
            this.cReporteTurnoDia19a20_08_2.current.value = '';
            this.cReporteTurnoDia19a20_09_2.current.value = '';
            this.cReporteTurnoDia19a20_10_2.current.value = '';
            this.cReporteTurnoDia19a20_11_2.current.value = '';
            this.cReporteTurnoDia19a20_12_2.current.value = '';
            this.cReporteTurnoDia19a20_13_2.current.value = '';
          }
          if (optX === 25){
            this.cReporteTurnoNoche20a21_02.current.value = '';
            this.cReporteTurnoNoche20a21_03.current.value = '';
            this.cReporteTurnoNoche20a21_04.current.value = '';
            this.cReporteTurnoNoche20a21_05.current.value = '';
            this.cReporteTurnoNoche20a21_06.current.value = '';
            this.cReporteTurnoNoche20a21_07.current.value = '';
            this.cReporteTurnoNoche20a21_08.current.value = '';
            this.cReporteTurnoNoche20a21_09.current.value = '';
            this.cReporteTurnoNoche20a21_10.current.value = '';
            this.cReporteTurnoNoche20a21_11.current.value = '';
            this.cReporteTurnoNoche20a21_12.current.value = '';
            this.cReporteTurnoNoche20a21_13.current.value = '';
          }
          if (optX === 27){
            this.cReporteTurnoNoche21a22_02.current.value = '';
            this.cReporteTurnoNoche21a22_03.current.value = '';
            this.cReporteTurnoNoche21a22_04.current.value = '';
            this.cReporteTurnoNoche21a22_05.current.value = '';
            this.cReporteTurnoNoche21a22_06.current.value = '';
            this.cReporteTurnoNoche21a22_07.current.value = '';
            this.cReporteTurnoNoche21a22_08.current.value = '';
            this.cReporteTurnoNoche21a22_09.current.value = '';
            this.cReporteTurnoNoche21a22_10.current.value = '';
            this.cReporteTurnoNoche21a22_11.current.value = '';
            this.cReporteTurnoNoche21a22_12.current.value = '';
            this.cReporteTurnoNoche21a22_13.current.value = '';
          }
          if (optX === 29){
            this.cReporteTurnoNoche22a23_02.current.value = '';
            this.cReporteTurnoNoche22a23_03.current.value = '';
            this.cReporteTurnoNoche22a23_04.current.value = '';
            this.cReporteTurnoNoche22a23_05.current.value = '';
            this.cReporteTurnoNoche22a23_06.current.value = '';
            this.cReporteTurnoNoche22a23_07.current.value = '';
            this.cReporteTurnoNoche22a23_08.current.value = '';
            this.cReporteTurnoNoche22a23_09.current.value = '';
            this.cReporteTurnoNoche22a23_10.current.value = '';
            this.cReporteTurnoNoche22a23_11.current.value = '';
            this.cReporteTurnoNoche22a23_12.current.value = '';
            this.cReporteTurnoNoche22a23_13.current.value = '';
          }
          if (optX === 31){
            this.cReporteTurnoNoche23a24_02.current.value = '';
            this.cReporteTurnoNoche23a24_03.current.value = '';
            this.cReporteTurnoNoche23a24_04.current.value = '';
            this.cReporteTurnoNoche23a24_05.current.value = '';
            this.cReporteTurnoNoche23a24_06.current.value = '';
            this.cReporteTurnoNoche23a24_07.current.value = '';
            this.cReporteTurnoNoche23a24_08.current.value = '';
            this.cReporteTurnoNoche23a24_09.current.value = '';
            this.cReporteTurnoNoche23a24_10.current.value = '';
            this.cReporteTurnoNoche23a24_11.current.value = '';
            this.cReporteTurnoNoche23a24_12.current.value = '';
            this.cReporteTurnoNoche23a24_13.current.value = '';
          }
          if (optX === 33){
            this.cReporteTurnoNoche24a01_02.current.value = '';
            this.cReporteTurnoNoche24a01_03.current.value = '';
            this.cReporteTurnoNoche24a01_04.current.value = '';
            this.cReporteTurnoNoche24a01_05.current.value = '';
            this.cReporteTurnoNoche24a01_06.current.value = '';
            this.cReporteTurnoNoche24a01_07.current.value = '';
            this.cReporteTurnoNoche24a01_08.current.value = '';
            this.cReporteTurnoNoche24a01_09.current.value = '';
            this.cReporteTurnoNoche24a01_10.current.value = '';
            this.cReporteTurnoNoche24a01_11.current.value = '';
            this.cReporteTurnoNoche24a01_12.current.value = '';
            this.cReporteTurnoNoche24a01_13.current.value = '';
          }
          if (optX === 35){
            this.cReporteTurnoNoche01a02_02.current.value = '';
            this.cReporteTurnoNoche01a02_03.current.value = '';
            this.cReporteTurnoNoche01a02_04.current.value = '';
            this.cReporteTurnoNoche01a02_05.current.value = '';
            this.cReporteTurnoNoche01a02_06.current.value = '';
            this.cReporteTurnoNoche01a02_07.current.value = '';
            this.cReporteTurnoNoche01a02_08.current.value = '';
            this.cReporteTurnoNoche01a02_09.current.value = '';
            this.cReporteTurnoNoche01a02_10.current.value = '';
            this.cReporteTurnoNoche01a02_11.current.value = '';
            this.cReporteTurnoNoche01a02_12.current.value = '';
            this.cReporteTurnoNoche01a02_13.current.value = '';
          }
          if (optX === 37){
            this.cReporteTurnoNoche02a03_02.current.value = '';
            this.cReporteTurnoNoche02a03_03.current.value = '';
            this.cReporteTurnoNoche02a03_04.current.value = '';
            this.cReporteTurnoNoche02a03_05.current.value = '';
            this.cReporteTurnoNoche02a03_06.current.value = '';
            this.cReporteTurnoNoche02a03_07.current.value = '';
            this.cReporteTurnoNoche02a03_08.current.value = '';
            this.cReporteTurnoNoche02a03_09.current.value = '';
            this.cReporteTurnoNoche02a03_10.current.value = '';
            this.cReporteTurnoNoche02a03_11.current.value = '';
            this.cReporteTurnoNoche02a03_12.current.value = '';
            this.cReporteTurnoNoche02a03_13.current.value = '';
          }
          if (optX === 39){
            this.cReporteTurnoNoche03a04_02.current.value = '';
            this.cReporteTurnoNoche03a04_03.current.value = '';
            this.cReporteTurnoNoche03a04_04.current.value = '';
            this.cReporteTurnoNoche03a04_05.current.value = '';
            this.cReporteTurnoNoche03a04_06.current.value = '';
            this.cReporteTurnoNoche03a04_07.current.value = '';
            this.cReporteTurnoNoche03a04_08.current.value = '';
            this.cReporteTurnoNoche03a04_09.current.value = '';
            this.cReporteTurnoNoche03a04_10.current.value = '';
            this.cReporteTurnoNoche03a04_11.current.value = '';
            this.cReporteTurnoNoche03a04_12.current.value = '';
            this.cReporteTurnoNoche03a04_13.current.value = '';
          }
          if (optX === 41){
            this.cReporteTurnoNoche04a05_02.current.value = '';
            this.cReporteTurnoNoche04a05_03.current.value = '';
            this.cReporteTurnoNoche04a05_04.current.value = '';
            this.cReporteTurnoNoche04a05_05.current.value = '';
            this.cReporteTurnoNoche04a05_06.current.value = '';
            this.cReporteTurnoNoche04a05_07.current.value = '';
            this.cReporteTurnoNoche04a05_08.current.value = '';
            this.cReporteTurnoNoche04a05_09.current.value = '';
            this.cReporteTurnoNoche04a05_10.current.value = '';
            this.cReporteTurnoNoche04a05_11.current.value = '';
            this.cReporteTurnoNoche04a05_12.current.value = '';
            this.cReporteTurnoNoche04a05_13.current.value = '';
          }
          if (optX === 43){
            this.cReporteTurnoNoche05a06_02.current.value = '';
            this.cReporteTurnoNoche05a06_03.current.value = '';
            this.cReporteTurnoNoche05a06_04.current.value = '';
            this.cReporteTurnoNoche05a06_05.current.value = '';
            this.cReporteTurnoNoche05a06_06.current.value = '';
            this.cReporteTurnoNoche05a06_07.current.value = '';
            this.cReporteTurnoNoche05a06_08.current.value = '';
            this.cReporteTurnoNoche05a06_09.current.value = '';
            this.cReporteTurnoNoche05a06_10.current.value = '';
            this.cReporteTurnoNoche05a06_11.current.value = '';
            this.cReporteTurnoNoche05a06_12.current.value = '';
            this.cReporteTurnoNoche05a06_13.current.value = '';
          }
          if (optX === 45){
            this.cReporteTurnoNoche06a07_02.current.value = '';
            this.cReporteTurnoNoche06a07_03.current.value = '';
            this.cReporteTurnoNoche06a07_04.current.value = '';
            this.cReporteTurnoNoche06a07_05.current.value = '';
            this.cReporteTurnoNoche06a07_06.current.value = '';
            this.cReporteTurnoNoche06a07_07.current.value = '';
            this.cReporteTurnoNoche06a07_08.current.value = '';
            this.cReporteTurnoNoche06a07_09.current.value = '';
            this.cReporteTurnoNoche06a07_10.current.value = '';
            this.cReporteTurnoNoche06a07_11.current.value = '';
            this.cReporteTurnoNoche06a07_12.current.value = '';
            this.cReporteTurnoNoche06a07_13.current.value = '';
          }
          if (optX === 47){
            this.cReporteTurnoNoche07a08_02.current.value = '';
            this.cReporteTurnoNoche07a08_03.current.value = '';
            this.cReporteTurnoNoche07a08_04.current.value = '';
            this.cReporteTurnoNoche07a08_05.current.value = '';
            this.cReporteTurnoNoche07a08_06.current.value = '';
            this.cReporteTurnoNoche07a08_07.current.value = '';
            this.cReporteTurnoNoche07a08_08.current.value = '';
            this.cReporteTurnoNoche07a08_09.current.value = '';
            this.cReporteTurnoNoche07a08_10.current.value = '';
            this.cReporteTurnoNoche07a08_11.current.value = '';
            this.cReporteTurnoNoche07a08_12.current.value = '';
            this.cReporteTurnoNoche07a08_13.current.value = '';
          }
          if (optX === 26){
            this.cReporteTurnoNoche20a21_02_2.current.value = '';
            this.cReporteTurnoNoche20a21_03_2.current.value = '';
            this.cReporteTurnoNoche20a21_04_2.current.value = '';
            this.cReporteTurnoNoche20a21_05_2.current.value = '';
            this.cReporteTurnoNoche20a21_06_2.current.value = '';
            this.cReporteTurnoNoche20a21_07_2.current.value = '';
            this.cReporteTurnoNoche20a21_08_2.current.value = '';
            this.cReporteTurnoNoche20a21_09_2.current.value = '';
            this.cReporteTurnoNoche20a21_10_2.current.value = '';
            this.cReporteTurnoNoche20a21_11_2.current.value = '';
            this.cReporteTurnoNoche20a21_12_2.current.value = '';
            this.cReporteTurnoNoche20a21_13_2.current.value = '';
          }
          if (optX === 28){
            this.cReporteTurnoNoche21a22_02_2.current.value = '';
            this.cReporteTurnoNoche21a22_03_2.current.value = '';
            this.cReporteTurnoNoche21a22_04_2.current.value = '';
            this.cReporteTurnoNoche21a22_05_2.current.value = '';
            this.cReporteTurnoNoche21a22_06_2.current.value = '';
            this.cReporteTurnoNoche21a22_07_2.current.value = '';
            this.cReporteTurnoNoche21a22_08_2.current.value = '';
            this.cReporteTurnoNoche21a22_09_2.current.value = '';
            this.cReporteTurnoNoche21a22_10_2.current.value = '';
            this.cReporteTurnoNoche21a22_11_2.current.value = '';
            this.cReporteTurnoNoche21a22_12_2.current.value = '';
            this.cReporteTurnoNoche21a22_13_2.current.value = '';
          }
          if (optX === 30){
            this.cReporteTurnoNoche22a23_02_2.current.value = '';
            this.cReporteTurnoNoche22a23_03_2.current.value = '';
            this.cReporteTurnoNoche22a23_04_2.current.value = '';
            this.cReporteTurnoNoche22a23_05_2.current.value = '';
            this.cReporteTurnoNoche22a23_06_2.current.value = '';
            this.cReporteTurnoNoche22a23_07_2.current.value = '';
            this.cReporteTurnoNoche22a23_08_2.current.value = '';
            this.cReporteTurnoNoche22a23_09_2.current.value = '';
            this.cReporteTurnoNoche22a23_10_2.current.value = '';
            this.cReporteTurnoNoche22a23_11_2.current.value = '';
            this.cReporteTurnoNoche22a23_12_2.current.value = '';
            this.cReporteTurnoNoche22a23_13_2.current.value = '';
          }
          if (optX === 32){
            this.cReporteTurnoNoche23a24_02_2.current.value = '';
            this.cReporteTurnoNoche23a24_03_2.current.value = '';
            this.cReporteTurnoNoche23a24_04_2.current.value = '';
            this.cReporteTurnoNoche23a24_05_2.current.value = '';
            this.cReporteTurnoNoche23a24_06_2.current.value = '';
            this.cReporteTurnoNoche23a24_07_2.current.value = '';
            this.cReporteTurnoNoche23a24_08_2.current.value = '';
            this.cReporteTurnoNoche23a24_09_2.current.value = '';
            this.cReporteTurnoNoche23a24_10_2.current.value = '';
            this.cReporteTurnoNoche23a24_11_2.current.value = '';
            this.cReporteTurnoNoche23a24_12_2.current.value = '';
            this.cReporteTurnoNoche23a24_13_2.current.value = '';
          }
          if (optX === 34){
            this.cReporteTurnoNoche24a01_02_2.current.value = '';
            this.cReporteTurnoNoche24a01_03_2.current.value = '';
            this.cReporteTurnoNoche24a01_04_2.current.value = '';
            this.cReporteTurnoNoche24a01_05_2.current.value = '';
            this.cReporteTurnoNoche24a01_06_2.current.value = '';
            this.cReporteTurnoNoche24a01_07_2.current.value = '';
            this.cReporteTurnoNoche24a01_08_2.current.value = '';
            this.cReporteTurnoNoche24a01_09_2.current.value = '';
            this.cReporteTurnoNoche24a01_10_2.current.value = '';
            this.cReporteTurnoNoche24a01_11_2.current.value = '';
            this.cReporteTurnoNoche24a01_12_2.current.value = '';
            this.cReporteTurnoNoche24a01_13_2.current.value = '';
          }
          if (optX === 36){
            this.cReporteTurnoNoche01a02_02_2.current.value = '';
            this.cReporteTurnoNoche01a02_03_2.current.value = '';
            this.cReporteTurnoNoche01a02_04_2.current.value = '';
            this.cReporteTurnoNoche01a02_05_2.current.value = '';
            this.cReporteTurnoNoche01a02_06_2.current.value = '';
            this.cReporteTurnoNoche01a02_07_2.current.value = '';
            this.cReporteTurnoNoche01a02_08_2.current.value = '';
            this.cReporteTurnoNoche01a02_09_2.current.value = '';
            this.cReporteTurnoNoche01a02_10_2.current.value = '';
            this.cReporteTurnoNoche01a02_11_2.current.value = '';
            this.cReporteTurnoNoche01a02_12_2.current.value = '';
            this.cReporteTurnoNoche01a02_13_2.current.value = '';
          }
          if (optX === 38){
            this.cReporteTurnoNoche02a03_02_2.current.value = '';
            this.cReporteTurnoNoche02a03_03_2.current.value = '';
            this.cReporteTurnoNoche02a03_04_2.current.value = '';
            this.cReporteTurnoNoche02a03_05_2.current.value = '';
            this.cReporteTurnoNoche02a03_06_2.current.value = '';
            this.cReporteTurnoNoche02a03_07_2.current.value = '';
            this.cReporteTurnoNoche02a03_08_2.current.value = '';
            this.cReporteTurnoNoche02a03_09_2.current.value = '';
            this.cReporteTurnoNoche02a03_10_2.current.value = '';
            this.cReporteTurnoNoche02a03_11_2.current.value = '';
            this.cReporteTurnoNoche02a03_12_2.current.value = '';
            this.cReporteTurnoNoche02a03_13_2.current.value = '';
          }
          if (optX === 40){
            this.cReporteTurnoNoche03a04_02_2.current.value = '';
            this.cReporteTurnoNoche03a04_03_2.current.value = '';
            this.cReporteTurnoNoche03a04_04_2.current.value = '';
            this.cReporteTurnoNoche03a04_05_2.current.value = '';
            this.cReporteTurnoNoche03a04_06_2.current.value = '';
            this.cReporteTurnoNoche03a04_07_2.current.value = '';
            this.cReporteTurnoNoche03a04_08_2.current.value = '';
            this.cReporteTurnoNoche03a04_09_2.current.value = '';
            this.cReporteTurnoNoche03a04_10_2.current.value = '';
            this.cReporteTurnoNoche03a04_11_2.current.value = '';
            this.cReporteTurnoNoche03a04_12_2.current.value = '';
            this.cReporteTurnoNoche03a04_13_2.current.value = '';
          }
          if (optX === 42){
            this.cReporteTurnoNoche04a05_02_2.current.value = '';
            this.cReporteTurnoNoche04a05_03_2.current.value = '';
            this.cReporteTurnoNoche04a05_04_2.current.value = '';
            this.cReporteTurnoNoche04a05_05_2.current.value = '';
            this.cReporteTurnoNoche04a05_06_2.current.value = '';
            this.cReporteTurnoNoche04a05_07_2.current.value = '';
            this.cReporteTurnoNoche04a05_08_2.current.value = '';
            this.cReporteTurnoNoche04a05_09_2.current.value = '';
            this.cReporteTurnoNoche04a05_10_2.current.value = '';
            this.cReporteTurnoNoche04a05_11_2.current.value = '';
            this.cReporteTurnoNoche04a05_12_2.current.value = '';
            this.cReporteTurnoNoche04a05_13_2.current.value = '';
          }
          if (optX === 44){
            this.cReporteTurnoNoche05a06_02_2.current.value = '';
            this.cReporteTurnoNoche05a06_03_2.current.value = '';
            this.cReporteTurnoNoche05a06_04_2.current.value = '';
            this.cReporteTurnoNoche05a06_05_2.current.value = '';
            this.cReporteTurnoNoche05a06_06_2.current.value = '';
            this.cReporteTurnoNoche05a06_07_2.current.value = '';
            this.cReporteTurnoNoche05a06_08_2.current.value = '';
            this.cReporteTurnoNoche05a06_09_2.current.value = '';
            this.cReporteTurnoNoche05a06_10_2.current.value = '';
            this.cReporteTurnoNoche05a06_11_2.current.value = '';
            this.cReporteTurnoNoche05a06_12_2.current.value = '';
            this.cReporteTurnoNoche05a06_13_2.current.value = '';
          }
          if (optX === 46){
            this.cReporteTurnoNoche06a07_02_2.current.value = '';
            this.cReporteTurnoNoche06a07_03_2.current.value = '';
            this.cReporteTurnoNoche06a07_04_2.current.value = '';
            this.cReporteTurnoNoche06a07_05_2.current.value = '';
            this.cReporteTurnoNoche06a07_06_2.current.value = '';
            this.cReporteTurnoNoche06a07_07_2.current.value = '';
            this.cReporteTurnoNoche06a07_08_2.current.value = '';
            this.cReporteTurnoNoche06a07_09_2.current.value = '';
            this.cReporteTurnoNoche06a07_10_2.current.value = '';
            this.cReporteTurnoNoche06a07_11_2.current.value = '';
            this.cReporteTurnoNoche06a07_12_2.current.value = '';
            this.cReporteTurnoNoche06a07_13_2.current.value = '';
          }
          if (optX === 48){
            this.cReporteTurnoNoche07a08_02_2.current.value = '';
            this.cReporteTurnoNoche07a08_03_2.current.value = '';
            this.cReporteTurnoNoche07a08_04_2.current.value = '';
            this.cReporteTurnoNoche07a08_05_2.current.value = '';
            this.cReporteTurnoNoche07a08_06_2.current.value = '';
            this.cReporteTurnoNoche07a08_07_2.current.value = '';
            this.cReporteTurnoNoche07a08_08_2.current.value = '';
            this.cReporteTurnoNoche07a08_09_2.current.value = '';
            this.cReporteTurnoNoche07a08_10_2.current.value = '';
            this.cReporteTurnoNoche07a08_11_2.current.value = '';
            this.cReporteTurnoNoche07a08_12_2.current.value = '';
            this.cReporteTurnoNoche07a08_13_2.current.value = '';
          }
          resolve1()
      })
    }

    goReporteTurnoDiaNames = (fechaX,userX,modeX,jsonVersionesTurnoDiaX,jsonVersionesTurnoNocheX,rolX, nameX) => {


console.log('goReporteTurnoDiaNames')
console.log(userX)
console.log(fechaX,userX,modeX,jsonVersionesTurnoDiaX,jsonVersionesTurnoNocheX,rolX, nameX)


      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(userX,`${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_dia.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4a = jsonBlockstack3.replace(/\\n/g,"");
                    const jsonBlockstack4 = jsonBlockstack4a.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)

                    console.log('qqqqqqqqqqqqqqqqqqqqqqqq')
                    console.log(modeX)


                    if (modeX === 'version'){

                      let arrayDiaX = jsonVersionesTurnoDiaX
                      let arrayDiaFondoPozoX = {}
                      Promise.all([this.goTokenReporteTurnoDiaNames(fechaX,userX)]
                          ).then(
                            (resolve) =>{
                              const tokenTurnoDiaX = resolve[0].tokenDiaX

                              arrayDiaX.push({"id":userX, "fecha":jsonBlockstack4d.vReporteTurnoDiaFechaHora, "rol":rolX, "name":nameX, "firma1":false, "firma2":false, "firma3":this.state.cReporteTurnoSignatureFirmante3, "firma4":this.state.cReporteTurnoSignatureFirmante4, "firma5":this.state.cReporteTurnoSignatureFirmante5, "cierre":this.state.cierreReporteTurnoX.close, "vReporteTurnoDia01_ID":jsonBlockstack4d.vReporteTurnoDia01_ID, "vReporteTurnoDia04_ID":jsonBlockstack4d.vReporteTurnoDia04_ID, "token":tokenTurnoDiaX})

                              arrayDiaFondoPozoX = {"id":userX, "fecha":jsonBlockstack4d.vReporteTurnoDiaFechaHora, "rol":rolX, "name":nameX, "firma1":false, "firma2":false, "firma3":this.state.cReporteTurnoSignatureFirmante3, "firma4":this.state.cReporteTurnoSignatureFirmante4, "firma5":this.state.cReporteTurnoSignatureFirmante5, "cierre":this.state.cierreReporteTurnoX.close, "vReporteTurnoDia01_ID":jsonBlockstack4d.vReporteTurnoDia01_ID, "vReporteTurnoDia04_ID":jsonBlockstack4d.vReporteTurnoDia04_ID, "token":tokenTurnoDiaX}

                              resolve1({arrayDiaX:arrayDiaX, arrayNocheX:jsonVersionesTurnoNocheX, arrayDiaFondoPozoX:arrayDiaFondoPozoX })
                            },
                            (reject) =>{
                              resolve1({arrayDiaX:jsonVersionesTurnoDiaX, arrayNocheX:jsonVersionesTurnoNocheX, arrayDiaFondoPozoX:''})
                            }
                          )
                    }else{
                      this.setState({reporteTurnoX:jsonBlockstack4d })
                      this.llenaReporteTurnoDia(jsonBlockstack4d)
                      this.llenaObservacionesTurnoDia(fechaX,jsonBlockstack4d)
                      this.llenaFirmasTurnoDia(fechaX,jsonBlockstack4d)
                      resolve1()
                    }
                 }else{
                   if (modeX === 'version'){
                     resolve1({arrayDiaX:jsonVersionesTurnoDiaX, arrayNocheX:jsonVersionesTurnoNocheX, arrayDiaFondoPozoX:''})
                   }else {
                     resolve1()
                   }
                 }
               } else {
                 if (modeX === 'version'){
                   resolve1({arrayDiaX:jsonVersionesTurnoDiaX, arrayNocheX:jsonVersionesTurnoNocheX, arrayDiaFondoPozoX:''})
                 }else {
                   resolve1()
                 }
               }
             })
            .catch(error => {
              console.log(error)
              if (modeX === 'version'){
                resolve1({arrayDiaX:jsonVersionesTurnoDiaX, arrayNocheX:jsonVersionesTurnoNocheX, arrayDiaFondoPozoX:''})
              }else {
                resolve1()
              }
            });
      })
    }

    goTokenReporteTurnoDiaNames = (fechaX,userX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      return new Promise ((resolve1, reject1) =>{
          getFileDB(userX,`${this.props.typeContract}_${this.props.numberContract}_${fechaX}_token_turno_dia.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    this.setState({tokenMayorDiaX: jsonBlockstack6.token})
                    resolve1({tokenDiaX:jsonBlockstack6.token})
                 } else {
                   reject1()
                 }
               } else {
                 reject1()
               }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    goFondoPozoTurnoDiaNames = (fechaX,userX, userXNoche, modeX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let totalFondoPozo = 0
      return new Promise ((resolve1, reject1) =>{
          getFileDB(userX,`${this.props.typeContract}_${this.props.numberContract}_fondo_pozo_turno_dia.json`)
             .then((fileContents) => {
               if(fileContents) {
                   const jsonBlockstack1 = JSON.parse(fileContents)
                   const jsonBlockstack2 = jsonBlockstack1.data
                   let jsonBlockstack5 = '[]'
                   if (jsonBlockstack2 !== null){
                      const jsonBlockstack3 = jsonBlockstack2.dataobject
                      const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                      if (jsonBlockstack4.substring(0,1) === '"') {
                         jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                      } else {
                         jsonBlockstack5 = jsonBlockstack4
                      }
                      let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                      this.setState({fondoPozoTurnoDiaX:jsonBlockstack6})
                      let largoX = jsonBlockstack6.length
                      const idX = fechaX.substring(6, 10)+fechaX.substring(3, 5)+fechaX.substring(0, 2)
                      jsonBlockstack6.map((todo,i) => {
                        if (todo.id < idX){
                          totalFondoPozo = totalFondoPozo + parseFloat(todo.perforado)
                        }
                        if (i === largoX - 1){
                          this.setState({consolidadoFondoOrigenDiaX:totalFondoPozo})
                          if (modeX === 'display'){
                            Promise.all([this.goFondoPozoTurnoNocheNames(fechaX,userXNoche,totalFondoPozo,modeX)])
                               .then((resolve) =>{resolve1()},(reject) =>{resolve1()})
                          }else{
                            resolve1({"totalFondoPozoDia":totalFondoPozo})
                          }
                        }
                      })
                   }else{
                      if (modeX === 'display'){
                        Promise.all([this.goFondoPozoTurnoNocheNames(fechaX,userXNoche,totalFondoPozo,modeX)])
                           .then((resolve) =>{resolve1()},(reject) =>{resolve1()})
                      }else{
                           resolve1({"totalFondoPozoDia":0})
                      }
                   }
               } else {
                 if (modeX === 'display'){
                   Promise.all([this.goFondoPozoTurnoNocheNames(fechaX,userXNoche,totalFondoPozo,modeX)])
                      .then((resolve) =>{resolve1()},(reject) =>{resolve1()})
                 }else{
                      resolve1({"totalFondoPozoDia":0})
                 }
               }
             })
            .catch(error => {
              console.log(error)
              if (modeX === 'display'){
                Promise.all([this.goFondoPozoTurnoNocheNames(fechaX,userXNoche,totalFondoPozo,modeX)])
                   .then((resolve) =>{resolve1()},(reject) =>{resolve1()})
              }else{
                resolve1({"totalFondoPozoDia":0})
              }
            });
      })
    }

    goReporteTurnoNocheNames = (fechaX,userX, modeX,jsonVersionesTurnoDiaX,jsonVersionesTurnoNocheX,rolX,nameX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(userX,`${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_noche.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4a = jsonBlockstack3.replace(/\\n/g,"");
                    const jsonBlockstack4 = jsonBlockstack4a.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({reporteTurnoX:jsonBlockstack4d })
                    if (modeX === 'version'){
                      let arrayNocheX = jsonVersionesTurnoNocheX
                      let arrayNocheFondoPozoX = {}
                      Promise.all([this.goTokenReporteTurnoNocheNames(fechaX,userX)]
                          ).then(
                            (resolve) =>{
                              const tokenTurnoNocheX = resolve[0].tokenNocheX
                              arrayNocheX.push({"id":userX, "fecha":jsonBlockstack4d.vReporteTurnoNocheFechaHora,"rol":rolX,"name":nameX, "firma1":false, "firma2":false, "firma3":this.state.cReporteTurnoSignatureFirmante3, "firma4":this.state.cReporteTurnoSignatureFirmante4, "firma5":this.state.cReporteTurnoSignatureFirmante5, "cierre":this.state.cierreReporteTurnoX.close, "vReporteTurnoNoche01_ID":jsonBlockstack4d.vReporteTurnoNoche01_ID, "vReporteTurnoNoche04_ID":jsonBlockstack4d.vReporteTurnoNoche04_ID, "token":tokenTurnoNocheX })

                              arrayNocheFondoPozoX = {"id":userX, "fecha":jsonBlockstack4d.vReporteTurnoNocheFechaHora,"rol":rolX,"name":nameX, "firma1":false, "firma2":false, "firma3":this.state.cReporteTurnoSignatureFirmante3, "firma4":this.state.cReporteTurnoSignatureFirmante4, "firma5":this.state.cReporteTurnoSignatureFirmante5, "cierre":this.state.cierreReporteTurnoX.close, "vReporteTurnoNoche01_ID":jsonBlockstack4d.vReporteTurnoNoche01_ID, "vReporteTurnoNoche04_ID":jsonBlockstack4d.vReporteTurnoNoche04_ID, "token":tokenTurnoNocheX }

                              resolve1({arrayDiaX:jsonVersionesTurnoDiaX, arrayNocheX:arrayNocheX, arrayNocheFondoPozoX:arrayNocheFondoPozoX})

                            },
                            (reject) =>{
                              resolve1({arrayDiaX:jsonVersionesTurnoDiaX, arrayNocheX:jsonVersionesTurnoNocheX, arrayNocheFondoPozoX:''})
                            }
                          )
                    }else{
                      this.llenaReporteTurnoNoche(jsonBlockstack4d)
                      this.llenaObservacionesTurnoNoche(fechaX,jsonBlockstack4d)
                      this.llenaFirmasTurnoNoche(fechaX,jsonBlockstack4d)
                      resolve1()
                    }
                  }else{
                    if (modeX === 'version'){
                      resolve1({arrayDiaX:jsonVersionesTurnoDiaX, arrayNocheX:jsonVersionesTurnoNocheX, arrayNocheFondoPozoX:''})
                    }else {
                      resolve1()
                    }
                  }
               } else {
                 if (modeX === 'version'){
                   resolve1({arrayDiaX:jsonVersionesTurnoDiaX, arrayNocheX:jsonVersionesTurnoNocheX, arrayNocheFondoPozoX:''})
                 }else {
                   resolve1()
                 }
               }
             })
            .catch(error => {
              if (modeX === 'version'){
                resolve1({arrayDiaX:jsonVersionesTurnoDiaX, arrayNocheX:jsonVersionesTurnoNocheX, arrayNocheFondoPozoX:''})
              }else {
                resolve1()
              }
            });
      })
    }

    goTokenReporteTurnoNocheNames = (fechaX,userX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(userX,`${this.props.typeContract}_${this.props.numberContract}_${fechaX}_token_turno_noche.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({tokenMayorNocheX: jsonBlockstack4d.token})
                    resolve1({tokenNocheX:jsonBlockstack4d.token})
                 }else{
                   resolve1({tokenNocheX:0})
                 }
               } else {
                 resolve1({tokenNocheX:0})
               }
             })
            .catch(error => {
              resolve1({tokenNocheX:0})
            });
      })
    }

    goFondoPozoTurnoNocheNames = (fechaX,userX,totalFondoPozoDia,modeX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      let totalFondoPozoNoche = 0
      return new Promise ((resolve1, reject1) =>{
          getFileDB(userX,`${this.props.typeContract}_${this.props.numberContract}_fondo_pozo_turno_noche.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({fondoPozoTurnoNocheX:jsonBlockstack4d})
                    let largoX = jsonBlockstack4d.length
                    const idX = fechaX.substring(6, 10)+fechaX.substring(3, 5)+fechaX.substring(0, 2)
                    jsonBlockstack4d.map((todo,i) => {
                      if (todo.id < idX){
                        totalFondoPozoNoche = totalFondoPozoNoche + parseFloat(todo.perforado)
                      }
                      if (i === largoX - 1){
                        this.setState({consolidadoFondoOrigenNocheX:totalFondoPozoNoche})
                        let totalFondoPozo = totalFondoPozoDia + totalFondoPozoNoche
                        this.setState({consolidadoFondoX:  totalFondoPozo})
                        resolve1({"totalFondoPozoNoche":totalFondoPozoNoche})
                      }
                    })
                  }else{
                    let totalFondoPozo = totalFondoPozoDia + totalFondoPozoNoche
                    this.setState({consolidadoFondoX:  totalFondoPozo})
                    resolve1({"totalFondoPozoNoche":totalFondoPozoNoche})
                  }
               } else {
                 let totalFondoPozo = totalFondoPozoDia + totalFondoPozoNoche
                 this.setState({consolidadoFondoX:  totalFondoPozo})
                 resolve1({"totalFondoPozoNoche":totalFondoPozoNoche})
               }
             })
            .catch(error => {
              console.log(error)
              let totalFondoPozo = totalFondoPozoDia + totalFondoPozoNoche
              this.setState({consolidadoFondoX:  totalFondoPozo})
              resolve1({"totalFondoPozoNoche":totalFondoPozoNoche})
            });
      })
    }

    llenaReporteTurnoDia = (reporteTurnoX) => {
      let fondoPozoDiaX = 0

      this.cReporteTurnoEstadoSondaje.current.value = reporteTurnoX.vReporteTurnoEstadoSondaje;
      let ArrayIdValue = ''
      let sobrecargaX = ''; let opcionPerforacionX = ''

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia08a09_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX1:sobrecargaX, opcionPerforacionX1: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia09a10_02
      if (ArrayIdValue === ''){ArrayIdValue = ' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX3:sobrecargaX, opcionPerforacionX3: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia10a11_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX5:sobrecargaX, opcionPerforacionX5: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia11a12_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX7:sobrecargaX, opcionPerforacionX7: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia12a13_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX9:sobrecargaX, opcionPerforacionX9: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia13a14_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX11:sobrecargaX, opcionPerforacionX11: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia14a15_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX13:sobrecargaX, opcionPerforacionX13: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia15a16_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX15:sobrecargaX, opcionPerforacionX15: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia16a17_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX17:sobrecargaX, opcionPerforacionX17: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia17a18_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX19:sobrecargaX, opcionPerforacionX19: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia18a19_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX21:sobrecargaX, opcionPerforacionX21: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia19a20_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX23:sobrecargaX, opcionPerforacionX23: opcionPerforacionX})


      ArrayIdValue = reporteTurnoX.vReporteTurnoDia08a09_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX2:sobrecargaX, opcionPerforacionX2: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia09a10_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX4:sobrecargaX, opcionPerforacionX4: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia10a11_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX6:sobrecargaX, opcionPerforacionX6: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia11a12_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX8:sobrecargaX, opcionPerforacionX8: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia12a13_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX10:sobrecargaX, opcionPerforacionX10: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia13a14_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX12:sobrecargaX, opcionPerforacionX12: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia14a15_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX14:sobrecargaX, opcionPerforacionX14: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia15a16_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX16:sobrecargaX, opcionPerforacionX16: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia16a17_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX18:sobrecargaX, opcionPerforacionX18: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia17a18_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX20:sobrecargaX, opcionPerforacionX20: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia18a19_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX22:sobrecargaX, opcionPerforacionX22: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoDia19a20_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX24:sobrecargaX, opcionPerforacionX24: opcionPerforacionX})


      ArrayIdValue = reporteTurnoX.vReporteTurnoDia08a09_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX1:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia09a10_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX3:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia10a11_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX5:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia11a12_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX7:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia12a13_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX9:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia13a14_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX11:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia14a15_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX13:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia15a16_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX15:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia16a17_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX17:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia17a18_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX19:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia18a19_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX21:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia19a20_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX23:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia08a09_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX2:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia09a10_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX4:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia10a11_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX6:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia11a12_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX8:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia12a13_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX10:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia13a14_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX12:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia14a15_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX14:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia15a16_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX16:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia16a17_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX18:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia17a18_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX20:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia18a19_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX22:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoDia19a20_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX24:ArrayIdValue[0]})

      this.cReporteTurnoDia01.current.value = `${reporteTurnoX.vReporteTurnoDia01_ID}&&&${reporteTurnoX.vReporteTurnoDia01}`;
      this.cReporteTurnoDia02.current.value = reporteTurnoX.vReporteTurnoDia02;
      this.cReporteTurnoDia03.current.value = reporteTurnoX.vReporteTurnoDia03;
      this.cReporteTurnoDia04.current.value = `${reporteTurnoX.vReporteTurnoDia04_ID}&&&${reporteTurnoX.vReporteTurnoDia04}`;
      this.cReporteTurnoDia05.current.value = `${reporteTurnoX.vReporteTurnoDia05_ID}&&&${reporteTurnoX.vReporteTurnoDia05}`;
      this.cReporteTurnoDia06.current.value = reporteTurnoX.vReporteTurnoDia06;
      this.cReporteTurnoDia07.current.value = reporteTurnoX.vReporteTurnoDia07;
      this.cReporteTurnoDia08.current.value = reporteTurnoX.vReporteTurnoDia08;
      this.cReporteTurnoDia09.current.value = reporteTurnoX.vReporteTurnoDia09;
      this.cReporteTurnoDia10.current.value = reporteTurnoX.vReporteTurnoDia10;

      if (!isNaN(parseFloat(this.state.workordermetaturnodia))){
        this.cReporteTurnoDia11.current.value =  parseFloat(this.state.workordermetaturnodia);
      }else{
        this.cReporteTurnoDia11.current.value =  reporteTurnoX.vReporteTurnoDia11;
      }
      this.cReporteTurnoDia12.current.value = reporteTurnoX.vReporteTurnoDia12;
      this.cReporteTurnoDia13.current.value = reporteTurnoX.vReporteTurnoDia13;

      this.cReporteTurnoDia08a09_01.current.value = reporteTurnoX.vReporteTurnoDia08a09_01;
      this.cReporteTurnoDia08a09_02.current.value = reporteTurnoX.vReporteTurnoDia08a09_02;
      this.cReporteTurnoDia08a09_03.current.value = reporteTurnoX.vReporteTurnoDia08a09_03;
      this.cReporteTurnoDia08a09_04.current.value = reporteTurnoX.vReporteTurnoDia08a09_04;
      this.cReporteTurnoDia08a09_05.current.value = reporteTurnoX.vReporteTurnoDia08a09_05;
      this.cReporteTurnoDia08a09_06.current.value = reporteTurnoX.vReporteTurnoDia08a09_06;
      this.cReporteTurnoDia08a09_07.current.value = reporteTurnoX.vReporteTurnoDia08a09_07;
      this.cReporteTurnoDia08a09_08.current.value = reporteTurnoX.vReporteTurnoDia08a09_08;
      this.cReporteTurnoDia08a09_09.current.value = reporteTurnoX.vReporteTurnoDia08a09_09;
      this.cReporteTurnoDia08a09_10.current.value = reporteTurnoX.vReporteTurnoDia08a09_10;
      this.cReporteTurnoDia08a09_11.current.value = reporteTurnoX.vReporteTurnoDia08a09_11;
      this.cReporteTurnoDia08a09_12.current.value = reporteTurnoX.vReporteTurnoDia08a09_12;
      this.cReporteTurnoDia08a09_13.current.value = reporteTurnoX.vReporteTurnoDia08a09_13;

      if (parseFloat(this.cReporteTurnoDia08a09_08.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia08a09_08.current.value)}
      if (parseFloat(this.cReporteTurnoDia08a09_09.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia08a09_09.current.value)}

      this.cReporteTurnoDia09a10_01.current.value = reporteTurnoX.vReporteTurnoDia09a10_01;
      this.cReporteTurnoDia09a10_02.current.value = reporteTurnoX.vReporteTurnoDia09a10_02;
      this.cReporteTurnoDia09a10_03.current.value = reporteTurnoX.vReporteTurnoDia09a10_03;
      this.cReporteTurnoDia09a10_04.current.value = reporteTurnoX.vReporteTurnoDia09a10_04;
      this.cReporteTurnoDia09a10_05.current.value = reporteTurnoX.vReporteTurnoDia09a10_05;
      this.cReporteTurnoDia09a10_06.current.value = reporteTurnoX.vReporteTurnoDia09a10_06;
      this.cReporteTurnoDia09a10_07.current.value = reporteTurnoX.vReporteTurnoDia09a10_07;
      this.cReporteTurnoDia09a10_08.current.value = reporteTurnoX.vReporteTurnoDia09a10_08;
      this.cReporteTurnoDia09a10_09.current.value = reporteTurnoX.vReporteTurnoDia09a10_09;
      this.cReporteTurnoDia09a10_10.current.value = reporteTurnoX.vReporteTurnoDia09a10_10;
      this.cReporteTurnoDia09a10_11.current.value = reporteTurnoX.vReporteTurnoDia09a10_11;
      this.cReporteTurnoDia09a10_12.current.value = reporteTurnoX.vReporteTurnoDia09a10_12;
      this.cReporteTurnoDia09a10_13.current.value = reporteTurnoX.vReporteTurnoDia09a10_13;

      if (parseFloat(this.cReporteTurnoDia09a10_08.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia09a10_08.current.value)}
      if (parseFloat(this.cReporteTurnoDia09a10_09.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia09a10_09.current.value)}

      this.cReporteTurnoDia10a11_01.current.value = reporteTurnoX.vReporteTurnoDia10a11_01;
      this.cReporteTurnoDia10a11_02.current.value = reporteTurnoX.vReporteTurnoDia10a11_02;
      this.cReporteTurnoDia10a11_03.current.value = reporteTurnoX.vReporteTurnoDia10a11_03;
      this.cReporteTurnoDia10a11_04.current.value = reporteTurnoX.vReporteTurnoDia10a11_04;
      this.cReporteTurnoDia10a11_05.current.value = reporteTurnoX.vReporteTurnoDia10a11_05;
      this.cReporteTurnoDia10a11_06.current.value = reporteTurnoX.vReporteTurnoDia10a11_06;
      this.cReporteTurnoDia10a11_07.current.value = reporteTurnoX.vReporteTurnoDia10a11_07;
      this.cReporteTurnoDia10a11_08.current.value = reporteTurnoX.vReporteTurnoDia10a11_08;
      this.cReporteTurnoDia10a11_09.current.value = reporteTurnoX.vReporteTurnoDia10a11_09;
      this.cReporteTurnoDia10a11_10.current.value = reporteTurnoX.vReporteTurnoDia10a11_10;
      this.cReporteTurnoDia10a11_11.current.value = reporteTurnoX.vReporteTurnoDia10a11_11;
      this.cReporteTurnoDia10a11_12.current.value = reporteTurnoX.vReporteTurnoDia10a11_12;
      this.cReporteTurnoDia10a11_13.current.value = reporteTurnoX.vReporteTurnoDia10a11_13;

      if (parseFloat(this.cReporteTurnoDia10a11_08.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia10a11_08.current.value)}
      if (parseFloat(this.cReporteTurnoDia10a11_09.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia09a10_09.current.value)}

      this.cReporteTurnoDia11a12_01.current.value = reporteTurnoX.vReporteTurnoDia11a12_01;
      this.cReporteTurnoDia11a12_02.current.value = reporteTurnoX.vReporteTurnoDia11a12_02;
      this.cReporteTurnoDia11a12_03.current.value = reporteTurnoX.vReporteTurnoDia11a12_03;
      this.cReporteTurnoDia11a12_04.current.value = reporteTurnoX.vReporteTurnoDia11a12_04;
      this.cReporteTurnoDia11a12_05.current.value = reporteTurnoX.vReporteTurnoDia11a12_05;
      this.cReporteTurnoDia11a12_06.current.value = reporteTurnoX.vReporteTurnoDia11a12_06;
      this.cReporteTurnoDia11a12_07.current.value = reporteTurnoX.vReporteTurnoDia11a12_07;
      this.cReporteTurnoDia11a12_08.current.value = reporteTurnoX.vReporteTurnoDia11a12_08;
      this.cReporteTurnoDia11a12_09.current.value = reporteTurnoX.vReporteTurnoDia11a12_09;
      this.cReporteTurnoDia11a12_10.current.value = reporteTurnoX.vReporteTurnoDia11a12_10;
      this.cReporteTurnoDia11a12_11.current.value = reporteTurnoX.vReporteTurnoDia11a12_11;
      this.cReporteTurnoDia11a12_12.current.value = reporteTurnoX.vReporteTurnoDia11a12_12;
      this.cReporteTurnoDia11a12_13.current.value = reporteTurnoX.vReporteTurnoDia11a12_13;

      if (parseFloat(this.cReporteTurnoDia11a12_08.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia11a12_08.current.value)}
      if (parseFloat(this.cReporteTurnoDia11a12_09.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia11a12_09.current.value)}

      this.cReporteTurnoDia12a13_01.current.value = reporteTurnoX.vReporteTurnoDia12a13_01;
      this.cReporteTurnoDia12a13_02.current.value = reporteTurnoX.vReporteTurnoDia12a13_02;
      this.cReporteTurnoDia12a13_03.current.value = reporteTurnoX.vReporteTurnoDia12a13_03;
      this.cReporteTurnoDia12a13_04.current.value = reporteTurnoX.vReporteTurnoDia12a13_04;
      this.cReporteTurnoDia12a13_05.current.value = reporteTurnoX.vReporteTurnoDia12a13_05;
      this.cReporteTurnoDia12a13_06.current.value = reporteTurnoX.vReporteTurnoDia12a13_06;
      this.cReporteTurnoDia12a13_07.current.value = reporteTurnoX.vReporteTurnoDia12a13_07;
      this.cReporteTurnoDia12a13_08.current.value = reporteTurnoX.vReporteTurnoDia12a13_08;
      this.cReporteTurnoDia12a13_09.current.value = reporteTurnoX.vReporteTurnoDia12a13_09;
      this.cReporteTurnoDia12a13_10.current.value = reporteTurnoX.vReporteTurnoDia12a13_10;
      this.cReporteTurnoDia12a13_11.current.value = reporteTurnoX.vReporteTurnoDia12a13_11;
      this.cReporteTurnoDia12a13_12.current.value = reporteTurnoX.vReporteTurnoDia12a13_12;
      this.cReporteTurnoDia12a13_13.current.value = reporteTurnoX.vReporteTurnoDia12a13_13;

      if (parseFloat(this.cReporteTurnoDia12a13_08.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia12a13_08.current.value)}
      if (parseFloat(this.cReporteTurnoDia12a13_09.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia12a13_09.current.value)}

      this.cReporteTurnoDia13a14_01.current.value = reporteTurnoX.vReporteTurnoDia13a14_01;
      this.cReporteTurnoDia13a14_02.current.value = reporteTurnoX.vReporteTurnoDia13a14_02;
      this.cReporteTurnoDia13a14_03.current.value = reporteTurnoX.vReporteTurnoDia13a14_03;
      this.cReporteTurnoDia13a14_04.current.value = reporteTurnoX.vReporteTurnoDia13a14_04;
      this.cReporteTurnoDia13a14_05.current.value = reporteTurnoX.vReporteTurnoDia13a14_05;
      this.cReporteTurnoDia13a14_06.current.value = reporteTurnoX.vReporteTurnoDia13a14_06;
      this.cReporteTurnoDia13a14_07.current.value = reporteTurnoX.vReporteTurnoDia13a14_07;
      this.cReporteTurnoDia13a14_08.current.value = reporteTurnoX.vReporteTurnoDia13a14_08;
      this.cReporteTurnoDia13a14_09.current.value = reporteTurnoX.vReporteTurnoDia13a14_09;
      this.cReporteTurnoDia13a14_10.current.value = reporteTurnoX.vReporteTurnoDia13a14_10;
      this.cReporteTurnoDia13a14_11.current.value = reporteTurnoX.vReporteTurnoDia13a14_11;
      this.cReporteTurnoDia13a14_12.current.value = reporteTurnoX.vReporteTurnoDia13a14_12;
      this.cReporteTurnoDia13a14_13.current.value = reporteTurnoX.vReporteTurnoDia13a14_13;

      if (parseFloat(this.cReporteTurnoDia13a14_08.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia13a14_08.current.value)}
      if (parseFloat(this.cReporteTurnoDia13a14_09.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia13a14_09.current.value)}

      this.cReporteTurnoDia14a15_01.current.value = reporteTurnoX.vReporteTurnoDia14a15_01;
      this.cReporteTurnoDia14a15_02.current.value = reporteTurnoX.vReporteTurnoDia14a15_02;
      this.cReporteTurnoDia14a15_03.current.value = reporteTurnoX.vReporteTurnoDia14a15_03;
      this.cReporteTurnoDia14a15_04.current.value = reporteTurnoX.vReporteTurnoDia14a15_04;
      this.cReporteTurnoDia14a15_05.current.value = reporteTurnoX.vReporteTurnoDia14a15_05;
      this.cReporteTurnoDia14a15_06.current.value = reporteTurnoX.vReporteTurnoDia14a15_06;
      this.cReporteTurnoDia14a15_07.current.value = reporteTurnoX.vReporteTurnoDia14a15_07;
      this.cReporteTurnoDia14a15_08.current.value = reporteTurnoX.vReporteTurnoDia14a15_08;
      this.cReporteTurnoDia14a15_09.current.value = reporteTurnoX.vReporteTurnoDia14a15_09;
      this.cReporteTurnoDia14a15_10.current.value = reporteTurnoX.vReporteTurnoDia14a15_10;
      this.cReporteTurnoDia14a15_11.current.value = reporteTurnoX.vReporteTurnoDia14a15_11;
      this.cReporteTurnoDia14a15_12.current.value = reporteTurnoX.vReporteTurnoDia14a15_12;
      this.cReporteTurnoDia14a15_13.current.value = reporteTurnoX.vReporteTurnoDia14a15_13;

      if (parseFloat(this.cReporteTurnoDia14a15_08.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia14a15_08.current.value)}
      if (parseFloat(this.cReporteTurnoDia14a15_09.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia14a15_09.current.value)}

      this.cReporteTurnoDia15a16_01.current.value = reporteTurnoX.vReporteTurnoDia15a16_01;
      this.cReporteTurnoDia15a16_02.current.value = reporteTurnoX.vReporteTurnoDia15a16_02;
      this.cReporteTurnoDia15a16_03.current.value = reporteTurnoX.vReporteTurnoDia15a16_03;
      this.cReporteTurnoDia15a16_04.current.value = reporteTurnoX.vReporteTurnoDia15a16_04;
      this.cReporteTurnoDia15a16_05.current.value = reporteTurnoX.vReporteTurnoDia15a16_05;
      this.cReporteTurnoDia15a16_06.current.value = reporteTurnoX.vReporteTurnoDia15a16_06;
      this.cReporteTurnoDia15a16_07.current.value = reporteTurnoX.vReporteTurnoDia15a16_07;
      this.cReporteTurnoDia15a16_08.current.value = reporteTurnoX.vReporteTurnoDia15a16_08;
      this.cReporteTurnoDia15a16_09.current.value = reporteTurnoX.vReporteTurnoDia15a16_09;
      this.cReporteTurnoDia15a16_10.current.value = reporteTurnoX.vReporteTurnoDia15a16_10;
      this.cReporteTurnoDia15a16_11.current.value = reporteTurnoX.vReporteTurnoDia15a16_11;
      this.cReporteTurnoDia15a16_12.current.value = reporteTurnoX.vReporteTurnoDia15a16_12;
      this.cReporteTurnoDia15a16_13.current.value = reporteTurnoX.vReporteTurnoDia15a16_13;

      if (parseFloat(this.cReporteTurnoDia15a16_08.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia15a16_08.current.value)}
      if (parseFloat(this.cReporteTurnoDia15a16_09.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia15a16_09.current.value)}

      this.cReporteTurnoDia16a17_01.current.value = reporteTurnoX.vReporteTurnoDia16a17_01;
      this.cReporteTurnoDia16a17_02.current.value = reporteTurnoX.vReporteTurnoDia16a17_02;
      this.cReporteTurnoDia16a17_03.current.value = reporteTurnoX.vReporteTurnoDia16a17_03;
      this.cReporteTurnoDia16a17_04.current.value = reporteTurnoX.vReporteTurnoDia16a17_04;
      this.cReporteTurnoDia16a17_05.current.value = reporteTurnoX.vReporteTurnoDia16a17_05;
      this.cReporteTurnoDia16a17_06.current.value = reporteTurnoX.vReporteTurnoDia16a17_06;
      this.cReporteTurnoDia16a17_07.current.value = reporteTurnoX.vReporteTurnoDia16a17_07;
      this.cReporteTurnoDia16a17_08.current.value = reporteTurnoX.vReporteTurnoDia16a17_08;
      this.cReporteTurnoDia16a17_09.current.value = reporteTurnoX.vReporteTurnoDia16a17_09;
      this.cReporteTurnoDia16a17_10.current.value = reporteTurnoX.vReporteTurnoDia16a17_10;
      this.cReporteTurnoDia16a17_11.current.value = reporteTurnoX.vReporteTurnoDia16a17_11;
      this.cReporteTurnoDia16a17_12.current.value = reporteTurnoX.vReporteTurnoDia16a17_12;
      this.cReporteTurnoDia16a17_13.current.value = reporteTurnoX.vReporteTurnoDia16a17_13;

      if (parseFloat(this.cReporteTurnoDia16a17_08.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia16a17_08.current.value)}
      if (parseFloat(this.cReporteTurnoDia16a17_09.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia16a17_09.current.value)}

      this.cReporteTurnoDia17a18_01.current.value = reporteTurnoX.vReporteTurnoDia17a18_01;
      this.cReporteTurnoDia17a18_02.current.value = reporteTurnoX.vReporteTurnoDia17a18_02;
      this.cReporteTurnoDia17a18_03.current.value = reporteTurnoX.vReporteTurnoDia17a18_03;
      this.cReporteTurnoDia17a18_04.current.value = reporteTurnoX.vReporteTurnoDia17a18_04;
      this.cReporteTurnoDia17a18_05.current.value = reporteTurnoX.vReporteTurnoDia17a18_05;
      this.cReporteTurnoDia17a18_06.current.value = reporteTurnoX.vReporteTurnoDia17a18_06;
      this.cReporteTurnoDia17a18_07.current.value = reporteTurnoX.vReporteTurnoDia17a18_07;
      this.cReporteTurnoDia17a18_08.current.value = reporteTurnoX.vReporteTurnoDia17a18_08;
      this.cReporteTurnoDia17a18_09.current.value = reporteTurnoX.vReporteTurnoDia17a18_09;
      this.cReporteTurnoDia17a18_10.current.value = reporteTurnoX.vReporteTurnoDia17a18_10;
      this.cReporteTurnoDia17a18_11.current.value = reporteTurnoX.vReporteTurnoDia17a18_11;
      this.cReporteTurnoDia17a18_12.current.value = reporteTurnoX.vReporteTurnoDia17a18_12;
      this.cReporteTurnoDia17a18_13.current.value = reporteTurnoX.vReporteTurnoDia17a18_13;

      if (parseFloat(this.cReporteTurnoDia17a18_08.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia17a18_08.current.value)}
      if (parseFloat(this.cReporteTurnoDia17a18_09.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia17a18_09.current.value)}

      this.cReporteTurnoDia18a19_01.current.value = reporteTurnoX.vReporteTurnoDia18a19_01;
      this.cReporteTurnoDia18a19_02.current.value = reporteTurnoX.vReporteTurnoDia18a19_02;
      this.cReporteTurnoDia18a19_03.current.value = reporteTurnoX.vReporteTurnoDia18a19_03;
      this.cReporteTurnoDia18a19_04.current.value = reporteTurnoX.vReporteTurnoDia18a19_04;
      this.cReporteTurnoDia18a19_05.current.value = reporteTurnoX.vReporteTurnoDia18a19_05;
      this.cReporteTurnoDia18a19_06.current.value = reporteTurnoX.vReporteTurnoDia18a19_06;
      this.cReporteTurnoDia18a19_07.current.value = reporteTurnoX.vReporteTurnoDia18a19_07;
      this.cReporteTurnoDia18a19_08.current.value = reporteTurnoX.vReporteTurnoDia18a19_08;
      this.cReporteTurnoDia18a19_09.current.value = reporteTurnoX.vReporteTurnoDia18a19_09;
      this.cReporteTurnoDia18a19_10.current.value = reporteTurnoX.vReporteTurnoDia18a19_10;
      this.cReporteTurnoDia18a19_11.current.value = reporteTurnoX.vReporteTurnoDia18a19_11;
      this.cReporteTurnoDia18a19_12.current.value = reporteTurnoX.vReporteTurnoDia18a19_12;
      this.cReporteTurnoDia18a19_13.current.value = reporteTurnoX.vReporteTurnoDia18a19_13;

      if (parseFloat(this.cReporteTurnoDia18a19_08.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia18a19_08.current.value)}
      if (parseFloat(this.cReporteTurnoDia18a19_09.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia18a19_09.current.value)}

      this.cReporteTurnoDia19a20_01.current.value = reporteTurnoX.vReporteTurnoDia19a20_01;
      this.cReporteTurnoDia19a20_02.current.value = reporteTurnoX.vReporteTurnoDia19a20_02;
      this.cReporteTurnoDia19a20_03.current.value = reporteTurnoX.vReporteTurnoDia19a20_03;
      this.cReporteTurnoDia19a20_04.current.value = reporteTurnoX.vReporteTurnoDia19a20_04;
      this.cReporteTurnoDia19a20_05.current.value = reporteTurnoX.vReporteTurnoDia19a20_05;
      this.cReporteTurnoDia19a20_06.current.value = reporteTurnoX.vReporteTurnoDia19a20_06;
      this.cReporteTurnoDia19a20_07.current.value = reporteTurnoX.vReporteTurnoDia19a20_07;
      this.cReporteTurnoDia19a20_08.current.value = reporteTurnoX.vReporteTurnoDia19a20_08;
      this.cReporteTurnoDia19a20_09.current.value = reporteTurnoX.vReporteTurnoDia19a20_09;
      this.cReporteTurnoDia19a20_10.current.value = reporteTurnoX.vReporteTurnoDia19a20_10;
      this.cReporteTurnoDia19a20_11.current.value = reporteTurnoX.vReporteTurnoDia19a20_11;
      this.cReporteTurnoDia19a20_12.current.value = reporteTurnoX.vReporteTurnoDia19a20_12;
      this.cReporteTurnoDia19a20_13.current.value = reporteTurnoX.vReporteTurnoDia19a20_13;

      if (parseFloat(this.cReporteTurnoDia19a20_08.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia19a20_08.current.value)}
      if (parseFloat(this.cReporteTurnoDia19a20_09.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia19a20_09.current.value)}

      this.cReporteTurnoDia08a09_01_2.current.value = reporteTurnoX.vReporteTurnoDia08a09_01_2;
      this.cReporteTurnoDia08a09_02_2.current.value = reporteTurnoX.vReporteTurnoDia08a09_02_2;
      this.cReporteTurnoDia08a09_03_2.current.value = reporteTurnoX.vReporteTurnoDia08a09_03_2;
      this.cReporteTurnoDia08a09_04_2.current.value = reporteTurnoX.vReporteTurnoDia08a09_04_2;
      this.cReporteTurnoDia08a09_05_2.current.value = reporteTurnoX.vReporteTurnoDia08a09_05_2;
      this.cReporteTurnoDia08a09_06_2.current.value = reporteTurnoX.vReporteTurnoDia08a09_06_2;
      this.cReporteTurnoDia08a09_07_2.current.value = reporteTurnoX.vReporteTurnoDia08a09_07_2;
      this.cReporteTurnoDia08a09_08_2.current.value = reporteTurnoX.vReporteTurnoDia08a09_08_2;
      this.cReporteTurnoDia08a09_09_2.current.value = reporteTurnoX.vReporteTurnoDia08a09_09_2;
      this.cReporteTurnoDia08a09_10_2.current.value = reporteTurnoX.vReporteTurnoDia08a09_10_2;
      this.cReporteTurnoDia08a09_11_2.current.value = reporteTurnoX.vReporteTurnoDia08a09_11_2;
      this.cReporteTurnoDia08a09_12_2.current.value = reporteTurnoX.vReporteTurnoDia08a09_12_2;
      this.cReporteTurnoDia08a09_13_2.current.value = reporteTurnoX.vReporteTurnoDia08a09_13_2;

      if (parseFloat(this.cReporteTurnoDia08a09_08_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia08a09_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoDia08a09_09_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia08a09_09_2.current.value)}

      this.cReporteTurnoDia09a10_01_2.current.value = reporteTurnoX.vReporteTurnoDia09a10_01_2;
      this.cReporteTurnoDia09a10_02_2.current.value = reporteTurnoX.vReporteTurnoDia09a10_02_2;
      this.cReporteTurnoDia09a10_03_2.current.value = reporteTurnoX.vReporteTurnoDia09a10_03_2;
      this.cReporteTurnoDia09a10_04_2.current.value = reporteTurnoX.vReporteTurnoDia09a10_04_2;
      this.cReporteTurnoDia09a10_05_2.current.value = reporteTurnoX.vReporteTurnoDia09a10_05_2;
      this.cReporteTurnoDia09a10_06_2.current.value = reporteTurnoX.vReporteTurnoDia09a10_06_2;
      this.cReporteTurnoDia09a10_07_2.current.value = reporteTurnoX.vReporteTurnoDia09a10_07_2;
      this.cReporteTurnoDia09a10_08_2.current.value = reporteTurnoX.vReporteTurnoDia09a10_08_2;
      this.cReporteTurnoDia09a10_09_2.current.value = reporteTurnoX.vReporteTurnoDia09a10_09_2;
      this.cReporteTurnoDia09a10_10_2.current.value = reporteTurnoX.vReporteTurnoDia09a10_10_2;
      this.cReporteTurnoDia09a10_11_2.current.value = reporteTurnoX.vReporteTurnoDia09a10_11_2;
      this.cReporteTurnoDia09a10_12_2.current.value = reporteTurnoX.vReporteTurnoDia09a10_12_2;
      this.cReporteTurnoDia09a10_13_2.current.value = reporteTurnoX.vReporteTurnoDia09a10_13_2;

      if (parseFloat(this.cReporteTurnoDia09a10_08_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia09a10_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoDia09a10_09_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia09a10_09_2.current.value)}

      this.cReporteTurnoDia10a11_01_2.current.value = reporteTurnoX.vReporteTurnoDia10a11_01_2;
      this.cReporteTurnoDia10a11_02_2.current.value = reporteTurnoX.vReporteTurnoDia10a11_02_2;
      this.cReporteTurnoDia10a11_03_2.current.value = reporteTurnoX.vReporteTurnoDia10a11_03_2;
      this.cReporteTurnoDia10a11_04_2.current.value = reporteTurnoX.vReporteTurnoDia10a11_04_2;
      this.cReporteTurnoDia10a11_05_2.current.value = reporteTurnoX.vReporteTurnoDia10a11_05_2;
      this.cReporteTurnoDia10a11_06_2.current.value = reporteTurnoX.vReporteTurnoDia10a11_06_2;
      this.cReporteTurnoDia10a11_07_2.current.value = reporteTurnoX.vReporteTurnoDia10a11_07_2;
      this.cReporteTurnoDia10a11_08_2.current.value = reporteTurnoX.vReporteTurnoDia10a11_08_2;
      this.cReporteTurnoDia10a11_09_2.current.value = reporteTurnoX.vReporteTurnoDia10a11_09_2;
      this.cReporteTurnoDia10a11_10_2.current.value = reporteTurnoX.vReporteTurnoDia10a11_10_2;
      this.cReporteTurnoDia10a11_11_2.current.value = reporteTurnoX.vReporteTurnoDia10a11_11_2;
      this.cReporteTurnoDia10a11_12_2.current.value = reporteTurnoX.vReporteTurnoDia10a11_12_2;
      this.cReporteTurnoDia10a11_13_2.current.value = reporteTurnoX.vReporteTurnoDia10a11_13_2;

      if (parseFloat(this.cReporteTurnoDia10a11_08_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia10a11_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoDia10a11_09_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia10a11_09_2.current.value)}

      this.cReporteTurnoDia11a12_01_2.current.value = reporteTurnoX.vReporteTurnoDia11a12_01_2;
      this.cReporteTurnoDia11a12_02_2.current.value = reporteTurnoX.vReporteTurnoDia11a12_02_2;
      this.cReporteTurnoDia11a12_03_2.current.value = reporteTurnoX.vReporteTurnoDia11a12_03_2;
      this.cReporteTurnoDia11a12_04_2.current.value = reporteTurnoX.vReporteTurnoDia11a12_04_2;
      this.cReporteTurnoDia11a12_05_2.current.value = reporteTurnoX.vReporteTurnoDia11a12_05_2;
      this.cReporteTurnoDia11a12_06_2.current.value = reporteTurnoX.vReporteTurnoDia11a12_06_2;
      this.cReporteTurnoDia11a12_07_2.current.value = reporteTurnoX.vReporteTurnoDia11a12_07_2;
      this.cReporteTurnoDia11a12_08_2.current.value = reporteTurnoX.vReporteTurnoDia11a12_08_2;
      this.cReporteTurnoDia11a12_09_2.current.value = reporteTurnoX.vReporteTurnoDia11a12_09_2;
      this.cReporteTurnoDia11a12_10_2.current.value = reporteTurnoX.vReporteTurnoDia11a12_10_2;
      this.cReporteTurnoDia11a12_11_2.current.value = reporteTurnoX.vReporteTurnoDia11a12_11_2;
      this.cReporteTurnoDia11a12_12_2.current.value = reporteTurnoX.vReporteTurnoDia11a12_12_2;
      this.cReporteTurnoDia11a12_13_2.current.value = reporteTurnoX.vReporteTurnoDia11a12_13_2;

      if (parseFloat(this.cReporteTurnoDia11a12_08_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia11a12_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoDia11a12_09_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia11a12_09_2.current.value)}

      this.cReporteTurnoDia12a13_01_2.current.value = reporteTurnoX.vReporteTurnoDia12a13_01_2;
      this.cReporteTurnoDia12a13_02_2.current.value = reporteTurnoX.vReporteTurnoDia12a13_02_2;
      this.cReporteTurnoDia12a13_03_2.current.value = reporteTurnoX.vReporteTurnoDia12a13_03_2;
      this.cReporteTurnoDia12a13_04_2.current.value = reporteTurnoX.vReporteTurnoDia12a13_04_2;
      this.cReporteTurnoDia12a13_05_2.current.value = reporteTurnoX.vReporteTurnoDia12a13_05_2;
      this.cReporteTurnoDia12a13_06_2.current.value = reporteTurnoX.vReporteTurnoDia12a13_06_2;
      this.cReporteTurnoDia12a13_07_2.current.value = reporteTurnoX.vReporteTurnoDia12a13_07_2;
      this.cReporteTurnoDia12a13_08_2.current.value = reporteTurnoX.vReporteTurnoDia12a13_08_2;
      this.cReporteTurnoDia12a13_09_2.current.value = reporteTurnoX.vReporteTurnoDia12a13_09_2;
      this.cReporteTurnoDia12a13_10_2.current.value = reporteTurnoX.vReporteTurnoDia12a13_10_2;
      this.cReporteTurnoDia12a13_11_2.current.value = reporteTurnoX.vReporteTurnoDia12a13_11_2;
      this.cReporteTurnoDia12a13_12_2.current.value = reporteTurnoX.vReporteTurnoDia12a13_12_2;
      this.cReporteTurnoDia12a13_13_2.current.value = reporteTurnoX.vReporteTurnoDia12a13_13_2;

      if (parseFloat(this.cReporteTurnoDia12a13_08_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia12a13_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoDia12a13_09_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia12a13_09_2.current.value)}

      this.cReporteTurnoDia13a14_01_2.current.value = reporteTurnoX.vReporteTurnoDia13a14_01_2;
      this.cReporteTurnoDia13a14_02_2.current.value = reporteTurnoX.vReporteTurnoDia13a14_02_2;
      this.cReporteTurnoDia13a14_03_2.current.value = reporteTurnoX.vReporteTurnoDia13a14_03_2;
      this.cReporteTurnoDia13a14_04_2.current.value = reporteTurnoX.vReporteTurnoDia13a14_04_2;
      this.cReporteTurnoDia13a14_05_2.current.value = reporteTurnoX.vReporteTurnoDia13a14_05_2;
      this.cReporteTurnoDia13a14_06_2.current.value = reporteTurnoX.vReporteTurnoDia13a14_06_2;
      this.cReporteTurnoDia13a14_07_2.current.value = reporteTurnoX.vReporteTurnoDia13a14_07_2;
      this.cReporteTurnoDia13a14_08_2.current.value = reporteTurnoX.vReporteTurnoDia13a14_08_2;
      this.cReporteTurnoDia13a14_09_2.current.value = reporteTurnoX.vReporteTurnoDia13a14_09_2;
      this.cReporteTurnoDia13a14_10_2.current.value = reporteTurnoX.vReporteTurnoDia13a14_10_2;
      this.cReporteTurnoDia13a14_11_2.current.value = reporteTurnoX.vReporteTurnoDia13a14_11_2;
      this.cReporteTurnoDia13a14_12_2.current.value = reporteTurnoX.vReporteTurnoDia13a14_12_2;
      this.cReporteTurnoDia13a14_13_2.current.value = reporteTurnoX.vReporteTurnoDia13a14_13_2;

      if (parseFloat(this.cReporteTurnoDia13a14_08_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia13a14_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoDia13a14_09_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia13a14_09_2.current.value)}

      this.cReporteTurnoDia14a15_01_2.current.value = reporteTurnoX.vReporteTurnoDia14a15_01_2;
      this.cReporteTurnoDia14a15_02_2.current.value = reporteTurnoX.vReporteTurnoDia14a15_02_2;
      this.cReporteTurnoDia14a15_03_2.current.value = reporteTurnoX.vReporteTurnoDia14a15_03_2;
      this.cReporteTurnoDia14a15_04_2.current.value = reporteTurnoX.vReporteTurnoDia14a15_04_2;
      this.cReporteTurnoDia14a15_05_2.current.value = reporteTurnoX.vReporteTurnoDia14a15_05_2;
      this.cReporteTurnoDia14a15_06_2.current.value = reporteTurnoX.vReporteTurnoDia14a15_06_2;
      this.cReporteTurnoDia14a15_07_2.current.value = reporteTurnoX.vReporteTurnoDia14a15_07_2;
      this.cReporteTurnoDia14a15_08_2.current.value = reporteTurnoX.vReporteTurnoDia14a15_08_2;
      this.cReporteTurnoDia14a15_09_2.current.value = reporteTurnoX.vReporteTurnoDia14a15_09_2;
      this.cReporteTurnoDia14a15_10_2.current.value = reporteTurnoX.vReporteTurnoDia14a15_10_2;
      this.cReporteTurnoDia14a15_11_2.current.value = reporteTurnoX.vReporteTurnoDia14a15_11_2;
      this.cReporteTurnoDia14a15_12_2.current.value = reporteTurnoX.vReporteTurnoDia14a15_12_2;
      this.cReporteTurnoDia14a15_13_2.current.value = reporteTurnoX.vReporteTurnoDia14a15_13_2;

      if (parseFloat(this.cReporteTurnoDia14a15_08_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia14a15_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoDia14a15_09_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia14a15_09_2.current.value)}

      this.cReporteTurnoDia15a16_01_2.current.value = reporteTurnoX.vReporteTurnoDia15a16_01_2;
      this.cReporteTurnoDia15a16_02_2.current.value = reporteTurnoX.vReporteTurnoDia15a16_02_2;
      this.cReporteTurnoDia15a16_03_2.current.value = reporteTurnoX.vReporteTurnoDia15a16_03_2;
      this.cReporteTurnoDia15a16_04_2.current.value = reporteTurnoX.vReporteTurnoDia15a16_04_2;
      this.cReporteTurnoDia15a16_05_2.current.value = reporteTurnoX.vReporteTurnoDia15a16_05_2;
      this.cReporteTurnoDia15a16_06_2.current.value = reporteTurnoX.vReporteTurnoDia15a16_06_2;
      this.cReporteTurnoDia15a16_07_2.current.value = reporteTurnoX.vReporteTurnoDia15a16_07_2;
      this.cReporteTurnoDia15a16_08_2.current.value = reporteTurnoX.vReporteTurnoDia15a16_08_2;
      this.cReporteTurnoDia15a16_09_2.current.value = reporteTurnoX.vReporteTurnoDia15a16_09_2;
      this.cReporteTurnoDia15a16_10_2.current.value = reporteTurnoX.vReporteTurnoDia15a16_10_2;
      this.cReporteTurnoDia15a16_11_2.current.value = reporteTurnoX.vReporteTurnoDia15a16_11_2;
      this.cReporteTurnoDia15a16_12_2.current.value = reporteTurnoX.vReporteTurnoDia15a16_12_2;
      this.cReporteTurnoDia15a16_13_2.current.value = reporteTurnoX.vReporteTurnoDia15a16_13_2;

      if (parseFloat(this.cReporteTurnoDia15a16_08_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia15a16_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoDia15a16_09_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia15a16_09_2.current.value)}

      this.cReporteTurnoDia16a17_01_2.current.value = reporteTurnoX.vReporteTurnoDia16a17_01_2;
      this.cReporteTurnoDia16a17_02_2.current.value = reporteTurnoX.vReporteTurnoDia16a17_02_2;
      this.cReporteTurnoDia16a17_03_2.current.value = reporteTurnoX.vReporteTurnoDia16a17_03_2;
      this.cReporteTurnoDia16a17_04_2.current.value = reporteTurnoX.vReporteTurnoDia16a17_04_2;
      this.cReporteTurnoDia16a17_05_2.current.value = reporteTurnoX.vReporteTurnoDia16a17_05_2;
      this.cReporteTurnoDia16a17_06_2.current.value = reporteTurnoX.vReporteTurnoDia16a17_06_2;
      this.cReporteTurnoDia16a17_07_2.current.value = reporteTurnoX.vReporteTurnoDia16a17_07_2;
      this.cReporteTurnoDia16a17_08_2.current.value = reporteTurnoX.vReporteTurnoDia16a17_08_2;
      this.cReporteTurnoDia16a17_09_2.current.value = reporteTurnoX.vReporteTurnoDia16a17_09_2;
      this.cReporteTurnoDia16a17_10_2.current.value = reporteTurnoX.vReporteTurnoDia16a17_10_2;
      this.cReporteTurnoDia16a17_11_2.current.value = reporteTurnoX.vReporteTurnoDia16a17_11_2;
      this.cReporteTurnoDia16a17_12_2.current.value = reporteTurnoX.vReporteTurnoDia16a17_12_2;
      this.cReporteTurnoDia16a17_13_2.current.value = reporteTurnoX.vReporteTurnoDia16a17_13_2;

      if (parseFloat(this.cReporteTurnoDia16a17_08_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia16a17_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoDia16a17_09_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia16a17_09_2.current.value)}

      this.cReporteTurnoDia17a18_01_2.current.value = reporteTurnoX.vReporteTurnoDia17a18_01_2;
      this.cReporteTurnoDia17a18_02_2.current.value = reporteTurnoX.vReporteTurnoDia17a18_02_2;
      this.cReporteTurnoDia17a18_03_2.current.value = reporteTurnoX.vReporteTurnoDia17a18_03_2;
      this.cReporteTurnoDia17a18_04_2.current.value = reporteTurnoX.vReporteTurnoDia17a18_04_2;
      this.cReporteTurnoDia17a18_05_2.current.value = reporteTurnoX.vReporteTurnoDia17a18_05_2;
      this.cReporteTurnoDia17a18_06_2.current.value = reporteTurnoX.vReporteTurnoDia17a18_06_2;
      this.cReporteTurnoDia17a18_07_2.current.value = reporteTurnoX.vReporteTurnoDia17a18_07_2;
      this.cReporteTurnoDia17a18_08_2.current.value = reporteTurnoX.vReporteTurnoDia17a18_08_2;
      this.cReporteTurnoDia17a18_09_2.current.value = reporteTurnoX.vReporteTurnoDia17a18_09_2;
      this.cReporteTurnoDia17a18_10_2.current.value = reporteTurnoX.vReporteTurnoDia17a18_10_2;
      this.cReporteTurnoDia17a18_11_2.current.value = reporteTurnoX.vReporteTurnoDia17a18_11_2;
      this.cReporteTurnoDia17a18_12_2.current.value = reporteTurnoX.vReporteTurnoDia17a18_12_2;
      this.cReporteTurnoDia17a18_13_2.current.value = reporteTurnoX.vReporteTurnoDia17a18_13_2;

      if (parseFloat(this.cReporteTurnoDia17a18_08_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia17a18_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoDia17a18_09_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia17a18_09_2.current.value)}

      this.cReporteTurnoDia18a19_01_2.current.value = reporteTurnoX.vReporteTurnoDia18a19_01_2;
      this.cReporteTurnoDia18a19_02_2.current.value = reporteTurnoX.vReporteTurnoDia18a19_02_2;
      this.cReporteTurnoDia18a19_03_2.current.value = reporteTurnoX.vReporteTurnoDia18a19_03_2;
      this.cReporteTurnoDia18a19_04_2.current.value = reporteTurnoX.vReporteTurnoDia18a19_04_2;
      this.cReporteTurnoDia18a19_05_2.current.value = reporteTurnoX.vReporteTurnoDia18a19_05_2;
      this.cReporteTurnoDia18a19_06_2.current.value = reporteTurnoX.vReporteTurnoDia18a19_06_2;
      this.cReporteTurnoDia18a19_07_2.current.value = reporteTurnoX.vReporteTurnoDia18a19_07_2;
      this.cReporteTurnoDia18a19_08_2.current.value = reporteTurnoX.vReporteTurnoDia18a19_08_2;
      this.cReporteTurnoDia18a19_09_2.current.value = reporteTurnoX.vReporteTurnoDia18a19_09_2;
      this.cReporteTurnoDia18a19_10_2.current.value = reporteTurnoX.vReporteTurnoDia18a19_10_2;
      this.cReporteTurnoDia18a19_11_2.current.value = reporteTurnoX.vReporteTurnoDia18a19_11_2;
      this.cReporteTurnoDia18a19_12_2.current.value = reporteTurnoX.vReporteTurnoDia18a19_12_2;
      this.cReporteTurnoDia18a19_13_2.current.value = reporteTurnoX.vReporteTurnoDia18a19_13_2;

      if (parseFloat(this.cReporteTurnoDia18a19_08_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia18a19_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoDia18a19_09_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia18a19_09_2.current.value)}

      this.cReporteTurnoDia19a20_01_2.current.value = reporteTurnoX.vReporteTurnoDia19a20_01_2;
      this.cReporteTurnoDia19a20_02_2.current.value = reporteTurnoX.vReporteTurnoDia19a20_02_2;
      this.cReporteTurnoDia19a20_03_2.current.value = reporteTurnoX.vReporteTurnoDia19a20_03_2;
      this.cReporteTurnoDia19a20_04_2.current.value = reporteTurnoX.vReporteTurnoDia19a20_04_2;
      this.cReporteTurnoDia19a20_05_2.current.value = reporteTurnoX.vReporteTurnoDia19a20_05_2;
      this.cReporteTurnoDia19a20_06_2.current.value = reporteTurnoX.vReporteTurnoDia19a20_06_2;
      this.cReporteTurnoDia19a20_07_2.current.value = reporteTurnoX.vReporteTurnoDia19a20_07_2;
      this.cReporteTurnoDia19a20_08_2.current.value = reporteTurnoX.vReporteTurnoDia19a20_08_2;
      this.cReporteTurnoDia19a20_09_2.current.value = reporteTurnoX.vReporteTurnoDia19a20_09_2;
      this.cReporteTurnoDia19a20_10_2.current.value = reporteTurnoX.vReporteTurnoDia19a20_10_2;
      this.cReporteTurnoDia19a20_11_2.current.value = reporteTurnoX.vReporteTurnoDia19a20_11_2;
      this.cReporteTurnoDia19a20_12_2.current.value = reporteTurnoX.vReporteTurnoDia19a20_12_2;
      this.cReporteTurnoDia19a20_13_2.current.value = reporteTurnoX.vReporteTurnoDia19a20_13_2;

      if (parseFloat(this.cReporteTurnoDia19a20_08_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia19a20_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoDia19a20_09_2.current.value) > fondoPozoDiaX){fondoPozoDiaX = parseFloat(this.cReporteTurnoDia19a20_09_2.current.value)}

      this.setState({bFondoPozoExiste: true, fondoPozoDiaX, consolidadoFondoOrigenDiaX: fondoPozoDiaX, consolidadoFondoOrigenDiaLlenaX: fondoPozoDiaX})

      let e = ''
      for (let i = 1; i <= 12; i++) {
          this.handleAceroPerforacion(e,i,2)
          this.handleHerramienta(e,i,2)
      }
      for (let i = 101; i <= 112; i++) {
          this.handleAceroPerforacion(e,i,2)
          this.handleHerramienta(e,i,2)
      }

      let palabra = ''
      palabra = this.cReporteTurnoDia08a09_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle1X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle1X=false};this.setState({displayDetalle1X})
      palabra = this.cReporteTurnoDia09a10_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle3X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle3X=false};this.setState({displayDetalle3X})
      palabra = this.cReporteTurnoDia10a11_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle5X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle5X=false};this.setState({displayDetalle5X})
      palabra = this.cReporteTurnoDia11a12_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle7X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle7X=false};this.setState({displayDetalle7X})
      palabra = this.cReporteTurnoDia12a13_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle9X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle9X=false};this.setState({displayDetalle9X})
      palabra = this.cReporteTurnoDia13a14_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle11X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle11X=false};this.setState({displayDetalle11X})
      palabra = this.cReporteTurnoDia14a15_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle13X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle13X=false};this.setState({displayDetalle13X})
      palabra = this.cReporteTurnoDia15a16_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle15X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle15X=false};this.setState({displayDetalle15X})
      palabra = this.cReporteTurnoDia16a17_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle17X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle17X=false};this.setState({displayDetalle17X})
      palabra = this.cReporteTurnoDia17a18_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle19X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle19X=false};this.setState({displayDetalle19X})
      palabra = this.cReporteTurnoDia18a19_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle21X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle21X=false};this.setState({displayDetalle21X})
      palabra = this.cReporteTurnoDia19a20_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle23X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle23X=false};this.setState({displayDetalle23X})

      palabra = this.cReporteTurnoDia08a09_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle2X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle2X=false};this.setState({displayDetalle2X})
      palabra = this.cReporteTurnoDia09a10_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle4X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle4X=false};this.setState({displayDetalle4X})
      palabra = this.cReporteTurnoDia10a11_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle6X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle6X=false};this.setState({displayDetalle6X})
      palabra = this.cReporteTurnoDia11a12_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle8X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle8X=false};this.setState({displayDetalle8X})
      palabra = this.cReporteTurnoDia12a13_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle10X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle10X=false};this.setState({displayDetalle10X})
      palabra = this.cReporteTurnoDia13a14_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle12X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle12X=false};this.setState({displayDetalle12X})
      palabra = this.cReporteTurnoDia14a15_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle14X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle14X=false};this.setState({displayDetalle14X})
      palabra = this.cReporteTurnoDia15a16_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle16X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle16X=false};this.setState({displayDetalle16X})
      palabra = this.cReporteTurnoDia16a17_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle18X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle18X=false};this.setState({displayDetalle18X})
      palabra = this.cReporteTurnoDia17a18_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle20X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle20X=false};this.setState({displayDetalle20X})
      palabra = this.cReporteTurnoDia18a19_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle22X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle22X=false};this.setState({displayDetalle22X})
      palabra = this.cReporteTurnoDia19a20_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle24X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle24X=false};this.setState({displayDetalle24X})


      this.setState({vReporteTurnoDia01:reporteTurnoX.vReporteTurnoDia01})
      this.setState({vReporteTurnoDia01_ID:reporteTurnoX.vReporteTurnoDia01_ID})
      this.setState({vReporteTurnoDia04:reporteTurnoX.vReporteTurnoDia04})
      this.setState({vReporteTurnoDia04_ID:reporteTurnoX.vReporteTurnoDia04_ID})
      this.setState({vReporteTurnoDia05:reporteTurnoX.vReporteTurnoDia05})
      this.setState({vReporteTurnoDia05_ID:reporteTurnoX.vReporteTurnoDia05_ID})
      this.setState({uuidX:reporteTurnoX.vUUID})
    }


    llenaReporteTurnoNoche = (reporteTurnoX) => {

      let fondoPozoNocheX = 0


      let ArrayIdValue = ''
      let sobrecargaX = ''; let opcionPerforacionX = ''

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche20a21_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX25:sobrecargaX, opcionPerforacionX25: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche21a22_02
      if (ArrayIdValue === ''){ArrayIdValue = ' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX27:sobrecargaX, opcionPerforacionX27: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche22a23_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX29:sobrecargaX, opcionPerforacionX29: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche23a24_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX31:sobrecargaX, opcionPerforacionX31: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche24a01_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX33:sobrecargaX, opcionPerforacionX33: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche01a02_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX35:sobrecargaX, opcionPerforacionX35: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche02a03_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX37:sobrecargaX, opcionPerforacionX37: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche03a04_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX39:sobrecargaX, opcionPerforacionX39: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche04a05_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX41:sobrecargaX, opcionPerforacionX41: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche05a06_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX43:sobrecargaX, opcionPerforacionX43: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche06a07_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX45:sobrecargaX, opcionPerforacionX45: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche07a08_02
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX47:sobrecargaX, opcionPerforacionX47: opcionPerforacionX})


      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche20a21_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX26:sobrecargaX, opcionPerforacionX26: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche21a22_02_2
      if (ArrayIdValue === ''){ArrayIdValue = ' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX28:sobrecargaX, opcionPerforacionX28: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche22a23_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX30:sobrecargaX, opcionPerforacionX30: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche23a24_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX32:sobrecargaX, opcionPerforacionX32: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche24a01_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX34:sobrecargaX, opcionPerforacionX34: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche01a02_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX36:sobrecargaX, opcionPerforacionX36: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche02a03_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX38:sobrecargaX, opcionPerforacionX38: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche03a04_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX40:sobrecargaX, opcionPerforacionX40: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche04a05_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX42:sobrecargaX, opcionPerforacionX42: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche05a06_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX44:sobrecargaX, opcionPerforacionX44: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche06a07_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX46:sobrecargaX, opcionPerforacionX46: opcionPerforacionX})

      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche07a08_02_2
      if (ArrayIdValue === ''){ArrayIdValue=' &&& '}
      ArrayIdValue = ArrayIdValue.split('&&&')
      sobrecargaX = ''; opcionPerforacionX = ''
      if (ArrayIdValue[0] === "SOBRECARGA NATURAL"){sobrecargaX = this.state.sobrecarganaturalX; opcionPerforacionX = 'SobrecargaNatural'}
      if (ArrayIdValue[0] === "SOBRECARGA NO NATURAL"){sobrecargaX = this.state.sobrecarganonaturalX; opcionPerforacionX = 'SobrecargaNoNatural'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA SECA"){sobrecargaX = this.state.rocaMuestraSecaX; opcionPerforacionX = 'RocaMuestraSeca'}
      if (ArrayIdValue[0] === "ROCA - MUESTRA CON AGUA"){sobrecargaX = this.state.rocaMuestraHumedaX; opcionPerforacionX = 'RocaMuestraHumeda'}
      if (ArrayIdValue[0] === "MONITOREO"){sobrecargaX = this.state.monitoreoX; opcionPerforacionX = 'PozoMonitoreo'}
      if (sobrecargaX === null || sobrecargaX === undefined || sobrecargaX === ''){sobrecargaX=[]}
      this.setState({sobrecargaX48:sobrecargaX, opcionPerforacionX48: opcionPerforacionX})


      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche20a21_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX25:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche21a22_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX27:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche22a23_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX29:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche23a24_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX31:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche24a01_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX33:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche01a02_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX35:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche02a03_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX37:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche03a04_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX39:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche04a05_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX41:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche05a06_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX43:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche06a07_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX45:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche07a08_04; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX47:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche20a21_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX26:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche21a22_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX28:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche22a23_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX30:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche23a24_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX32:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche24a01_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX34:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche01a02_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX36:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche02a03_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX38:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche03a04_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX40:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche04a05_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX42:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche05a06_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX44:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche06a07_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX46:ArrayIdValue[0]})
      ArrayIdValue = reporteTurnoX.vReporteTurnoNoche07a08_04_2; if (ArrayIdValue === ''){ArrayIdValue=' &&& '}; ArrayIdValue = ArrayIdValue.split('&&&'); this.setState({herramientaCodeX48:ArrayIdValue[0]})


      this.cReporteTurnoEstadoSondaje.current.value = reporteTurnoX.vReporteTurnoEstadoSondaje;

      this.cReporteTurnoNoche01.current.value = `${reporteTurnoX.vReporteTurnoNoche01_ID}&&&${reporteTurnoX.vReporteTurnoNoche01}`;
      this.cReporteTurnoNoche02.current.value = reporteTurnoX.vReporteTurnoNoche02;
      this.cReporteTurnoNoche03.current.value = reporteTurnoX.vReporteTurnoNoche03;
      this.cReporteTurnoNoche04.current.value = `${reporteTurnoX.vReporteTurnoNoche04_ID}&&&${reporteTurnoX.vReporteTurnoNoche04}`;
      this.cReporteTurnoNoche05.current.value = `${reporteTurnoX.vReporteTurnoNoche05_ID}&&&${reporteTurnoX.vReporteTurnoNoche05}`;
      this.cReporteTurnoNoche06.current.value = reporteTurnoX.vReporteTurnoNoche06;
      this.cReporteTurnoNoche07.current.value = reporteTurnoX.vReporteTurnoNoche07;
      this.cReporteTurnoNoche08.current.value = reporteTurnoX.vReporteTurnoNoche08;
      this.cReporteTurnoNoche09.current.value = reporteTurnoX.vReporteTurnoNoche09;
      this.cReporteTurnoNoche10.current.value = reporteTurnoX.vReporteTurnoNoche10;
      if (!isNaN(parseFloat(this.state.workordermetaturnonoche))){
        this.cReporteTurnoNoche11.current.value = parseFloat(this.state.workordermetaturnonoche);
      }else{
        this.cReporteTurnoNoche11.current.value =  reporteTurnoX.vReporteTurnoNoche11;
      }
      this.cReporteTurnoNoche12.current.value = reporteTurnoX.vReporteTurnoNoche12;
      this.cReporteTurnoNoche13.current.value = reporteTurnoX.vReporteTurnoNoche13;

      this.cReporteTurnoNoche20a21_01.current.value = reporteTurnoX.vReporteTurnoNoche20a21_01;
      this.cReporteTurnoNoche20a21_02.current.value = reporteTurnoX.vReporteTurnoNoche20a21_02;
      this.cReporteTurnoNoche20a21_03.current.value = reporteTurnoX.vReporteTurnoNoche20a21_03;
      this.cReporteTurnoNoche20a21_04.current.value = reporteTurnoX.vReporteTurnoNoche20a21_04;
      this.cReporteTurnoNoche20a21_05.current.value = reporteTurnoX.vReporteTurnoNoche20a21_05;
      this.cReporteTurnoNoche20a21_06.current.value = reporteTurnoX.vReporteTurnoNoche20a21_06;
      this.cReporteTurnoNoche20a21_07.current.value = reporteTurnoX.vReporteTurnoNoche20a21_07;
      this.cReporteTurnoNoche20a21_08.current.value = reporteTurnoX.vReporteTurnoNoche20a21_08;
      this.cReporteTurnoNoche20a21_09.current.value = reporteTurnoX.vReporteTurnoNoche20a21_09;
      this.cReporteTurnoNoche20a21_10.current.value = reporteTurnoX.vReporteTurnoNoche20a21_10;
      this.cReporteTurnoNoche20a21_11.current.value = reporteTurnoX.vReporteTurnoNoche20a21_11;
      this.cReporteTurnoNoche20a21_12.current.value = reporteTurnoX.vReporteTurnoNoche20a21_12;
      this.cReporteTurnoNoche20a21_13.current.value = reporteTurnoX.vReporteTurnoNoche20a21_13;

      if (parseFloat(this.cReporteTurnoNoche20a21_08.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche20a21_08.current.value)}
      if (parseFloat(this.cReporteTurnoNoche20a21_09.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche20a21_09.current.value)}

      this.cReporteTurnoNoche21a22_01.current.value = reporteTurnoX.vReporteTurnoNoche21a22_01;
      this.cReporteTurnoNoche21a22_02.current.value = reporteTurnoX.vReporteTurnoNoche21a22_02;
      this.cReporteTurnoNoche21a22_03.current.value = reporteTurnoX.vReporteTurnoNoche21a22_03;
      this.cReporteTurnoNoche21a22_04.current.value = reporteTurnoX.vReporteTurnoNoche21a22_04;
      this.cReporteTurnoNoche21a22_05.current.value = reporteTurnoX.vReporteTurnoNoche21a22_05;
      this.cReporteTurnoNoche21a22_06.current.value = reporteTurnoX.vReporteTurnoNoche21a22_06;
      this.cReporteTurnoNoche21a22_07.current.value = reporteTurnoX.vReporteTurnoNoche21a22_07;
      this.cReporteTurnoNoche21a22_08.current.value = reporteTurnoX.vReporteTurnoNoche21a22_08;
      this.cReporteTurnoNoche21a22_09.current.value = reporteTurnoX.vReporteTurnoNoche21a22_09;
      this.cReporteTurnoNoche21a22_10.current.value = reporteTurnoX.vReporteTurnoNoche21a22_10;
      this.cReporteTurnoNoche21a22_11.current.value = reporteTurnoX.vReporteTurnoNoche21a22_11;
      this.cReporteTurnoNoche21a22_12.current.value = reporteTurnoX.vReporteTurnoNoche21a22_12;
      this.cReporteTurnoNoche21a22_13.current.value = reporteTurnoX.vReporteTurnoNoche21a22_13;

      if (parseFloat(this.cReporteTurnoNoche21a22_08.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche21a22_08.current.value)}
      if (parseFloat(this.cReporteTurnoNoche20a21_09.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche20a21_09.current.value)}

      this.cReporteTurnoNoche22a23_01.current.value = reporteTurnoX.vReporteTurnoNoche22a23_01;
      this.cReporteTurnoNoche22a23_02.current.value = reporteTurnoX.vReporteTurnoNoche22a23_02;
      this.cReporteTurnoNoche22a23_03.current.value = reporteTurnoX.vReporteTurnoNoche22a23_03;
      this.cReporteTurnoNoche22a23_04.current.value = reporteTurnoX.vReporteTurnoNoche22a23_04;
      this.cReporteTurnoNoche22a23_05.current.value = reporteTurnoX.vReporteTurnoNoche22a23_05;
      this.cReporteTurnoNoche22a23_06.current.value = reporteTurnoX.vReporteTurnoNoche22a23_06;
      this.cReporteTurnoNoche22a23_07.current.value = reporteTurnoX.vReporteTurnoNoche22a23_07;
      this.cReporteTurnoNoche22a23_08.current.value = reporteTurnoX.vReporteTurnoNoche22a23_08;
      this.cReporteTurnoNoche22a23_09.current.value = reporteTurnoX.vReporteTurnoNoche22a23_09;
      this.cReporteTurnoNoche22a23_10.current.value = reporteTurnoX.vReporteTurnoNoche22a23_10;
      this.cReporteTurnoNoche22a23_11.current.value = reporteTurnoX.vReporteTurnoNoche22a23_11;
      this.cReporteTurnoNoche22a23_12.current.value = reporteTurnoX.vReporteTurnoNoche22a23_12;
      this.cReporteTurnoNoche22a23_13.current.value = reporteTurnoX.vReporteTurnoNoche22a23_13;

      if (parseFloat(this.cReporteTurnoNoche22a23_08.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche22a23_08.current.value)}
      if (parseFloat(this.cReporteTurnoNoche22a23_09.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche22a23_09.current.value)}

      this.cReporteTurnoNoche23a24_01.current.value = reporteTurnoX.vReporteTurnoNoche23a24_01;
      this.cReporteTurnoNoche23a24_02.current.value = reporteTurnoX.vReporteTurnoNoche23a24_02;
      this.cReporteTurnoNoche23a24_03.current.value = reporteTurnoX.vReporteTurnoNoche23a24_03;
      this.cReporteTurnoNoche23a24_04.current.value = reporteTurnoX.vReporteTurnoNoche23a24_04;
      this.cReporteTurnoNoche23a24_05.current.value = reporteTurnoX.vReporteTurnoNoche23a24_05;
      this.cReporteTurnoNoche23a24_06.current.value = reporteTurnoX.vReporteTurnoNoche23a24_06;
      this.cReporteTurnoNoche23a24_07.current.value = reporteTurnoX.vReporteTurnoNoche23a24_07;
      this.cReporteTurnoNoche23a24_08.current.value = reporteTurnoX.vReporteTurnoNoche23a24_08;
      this.cReporteTurnoNoche23a24_09.current.value = reporteTurnoX.vReporteTurnoNoche23a24_09;
      this.cReporteTurnoNoche23a24_10.current.value = reporteTurnoX.vReporteTurnoNoche23a24_10;
      this.cReporteTurnoNoche23a24_11.current.value = reporteTurnoX.vReporteTurnoNoche23a24_11;
      this.cReporteTurnoNoche23a24_12.current.value = reporteTurnoX.vReporteTurnoNoche23a24_12;
      this.cReporteTurnoNoche23a24_13.current.value = reporteTurnoX.vReporteTurnoNoche23a24_13;

      if (parseFloat(this.cReporteTurnoNoche23a24_08.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche23a24_08.current.value)}
      if (parseFloat(this.cReporteTurnoNoche23a24_09.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche23a24_09.current.value)}

      this.cReporteTurnoNoche24a01_01.current.value = reporteTurnoX.vReporteTurnoNoche24a01_01;
      this.cReporteTurnoNoche24a01_02.current.value = reporteTurnoX.vReporteTurnoNoche24a01_02;
      this.cReporteTurnoNoche24a01_03.current.value = reporteTurnoX.vReporteTurnoNoche24a01_03;
      this.cReporteTurnoNoche24a01_04.current.value = reporteTurnoX.vReporteTurnoNoche24a01_04;
      this.cReporteTurnoNoche24a01_05.current.value = reporteTurnoX.vReporteTurnoNoche24a01_05;
      this.cReporteTurnoNoche24a01_06.current.value = reporteTurnoX.vReporteTurnoNoche24a01_06;
      this.cReporteTurnoNoche24a01_07.current.value = reporteTurnoX.vReporteTurnoNoche24a01_07;
      this.cReporteTurnoNoche24a01_08.current.value = reporteTurnoX.vReporteTurnoNoche24a01_08;
      this.cReporteTurnoNoche24a01_09.current.value = reporteTurnoX.vReporteTurnoNoche24a01_09;
      this.cReporteTurnoNoche24a01_10.current.value = reporteTurnoX.vReporteTurnoNoche24a01_10;
      this.cReporteTurnoNoche24a01_11.current.value = reporteTurnoX.vReporteTurnoNoche24a01_11;
      this.cReporteTurnoNoche24a01_12.current.value = reporteTurnoX.vReporteTurnoNoche24a01_12;
      this.cReporteTurnoNoche24a01_13.current.value = reporteTurnoX.vReporteTurnoNoche24a01_13;

      if (parseFloat(this.cReporteTurnoNoche24a01_08.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche24a01_08.current.value)}
      if (parseFloat(this.cReporteTurnoNoche24a01_09.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche24a01_09.current.value)}

      this.cReporteTurnoNoche01a02_01.current.value = reporteTurnoX.vReporteTurnoNoche01a02_01;
      this.cReporteTurnoNoche01a02_02.current.value = reporteTurnoX.vReporteTurnoNoche01a02_02;
      this.cReporteTurnoNoche01a02_03.current.value = reporteTurnoX.vReporteTurnoNoche01a02_03;
      this.cReporteTurnoNoche01a02_04.current.value = reporteTurnoX.vReporteTurnoNoche01a02_04;
      this.cReporteTurnoNoche01a02_05.current.value = reporteTurnoX.vReporteTurnoNoche01a02_05;
      this.cReporteTurnoNoche01a02_06.current.value = reporteTurnoX.vReporteTurnoNoche01a02_06;
      this.cReporteTurnoNoche01a02_07.current.value = reporteTurnoX.vReporteTurnoNoche01a02_07;
      this.cReporteTurnoNoche01a02_08.current.value = reporteTurnoX.vReporteTurnoNoche01a02_08;
      this.cReporteTurnoNoche01a02_09.current.value = reporteTurnoX.vReporteTurnoNoche01a02_09;
      this.cReporteTurnoNoche01a02_10.current.value = reporteTurnoX.vReporteTurnoNoche01a02_10;
      this.cReporteTurnoNoche01a02_11.current.value = reporteTurnoX.vReporteTurnoNoche01a02_11;
      this.cReporteTurnoNoche01a02_12.current.value = reporteTurnoX.vReporteTurnoNoche01a02_12;
      this.cReporteTurnoNoche01a02_13.current.value = reporteTurnoX.vReporteTurnoNoche01a02_13;

      if (parseFloat(this.cReporteTurnoNoche01a02_08.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche01a02_08.current.value)}
      if (parseFloat(this.cReporteTurnoNoche01a02_09.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche01a02_09.current.value)}

      this.cReporteTurnoNoche02a03_01.current.value = reporteTurnoX.vReporteTurnoNoche02a03_01;
      this.cReporteTurnoNoche02a03_02.current.value = reporteTurnoX.vReporteTurnoNoche02a03_02;
      this.cReporteTurnoNoche02a03_03.current.value = reporteTurnoX.vReporteTurnoNoche02a03_03;
      this.cReporteTurnoNoche02a03_04.current.value = reporteTurnoX.vReporteTurnoNoche02a03_04;
      this.cReporteTurnoNoche02a03_05.current.value = reporteTurnoX.vReporteTurnoNoche02a03_05;
      this.cReporteTurnoNoche02a03_06.current.value = reporteTurnoX.vReporteTurnoNoche02a03_06;
      this.cReporteTurnoNoche02a03_07.current.value = reporteTurnoX.vReporteTurnoNoche02a03_07;
      this.cReporteTurnoNoche02a03_08.current.value = reporteTurnoX.vReporteTurnoNoche02a03_08;
      this.cReporteTurnoNoche02a03_09.current.value = reporteTurnoX.vReporteTurnoNoche02a03_09;
      this.cReporteTurnoNoche02a03_10.current.value = reporteTurnoX.vReporteTurnoNoche02a03_10;
      this.cReporteTurnoNoche02a03_11.current.value = reporteTurnoX.vReporteTurnoNoche02a03_11;
      this.cReporteTurnoNoche02a03_12.current.value = reporteTurnoX.vReporteTurnoNoche02a03_12;
      this.cReporteTurnoNoche02a03_13.current.value = reporteTurnoX.vReporteTurnoNoche02a03_13;

      if (parseFloat(this.cReporteTurnoNoche02a03_08.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche02a03_08.current.value)}
      if (parseFloat(this.cReporteTurnoNoche02a03_09.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche02a03_09.current.value)}

      this.cReporteTurnoNoche03a04_01.current.value = reporteTurnoX.vReporteTurnoNoche03a04_01;
      this.cReporteTurnoNoche03a04_02.current.value = reporteTurnoX.vReporteTurnoNoche03a04_02;
      this.cReporteTurnoNoche03a04_03.current.value = reporteTurnoX.vReporteTurnoNoche03a04_03;
      this.cReporteTurnoNoche03a04_04.current.value = reporteTurnoX.vReporteTurnoNoche03a04_04;
      this.cReporteTurnoNoche03a04_05.current.value = reporteTurnoX.vReporteTurnoNoche03a04_05;
      this.cReporteTurnoNoche03a04_06.current.value = reporteTurnoX.vReporteTurnoNoche03a04_06;
      this.cReporteTurnoNoche03a04_07.current.value = reporteTurnoX.vReporteTurnoNoche03a04_07;
      this.cReporteTurnoNoche03a04_08.current.value = reporteTurnoX.vReporteTurnoNoche03a04_08;
      this.cReporteTurnoNoche03a04_09.current.value = reporteTurnoX.vReporteTurnoNoche03a04_09;
      this.cReporteTurnoNoche03a04_10.current.value = reporteTurnoX.vReporteTurnoNoche03a04_10;
      this.cReporteTurnoNoche03a04_11.current.value = reporteTurnoX.vReporteTurnoNoche03a04_11;
      this.cReporteTurnoNoche03a04_12.current.value = reporteTurnoX.vReporteTurnoNoche03a04_12;
      this.cReporteTurnoNoche03a04_13.current.value = reporteTurnoX.vReporteTurnoNoche03a04_13;

      if (parseFloat(this.cReporteTurnoNoche03a04_08.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche03a04_08.current.value)}
      if (parseFloat(this.cReporteTurnoNoche03a04_09.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche03a04_09.current.value)}

      this.cReporteTurnoNoche04a05_01.current.value = reporteTurnoX.vReporteTurnoNoche04a05_01;
      this.cReporteTurnoNoche04a05_02.current.value = reporteTurnoX.vReporteTurnoNoche04a05_02;
      this.cReporteTurnoNoche04a05_03.current.value = reporteTurnoX.vReporteTurnoNoche04a05_03;
      this.cReporteTurnoNoche04a05_04.current.value = reporteTurnoX.vReporteTurnoNoche04a05_04;
      this.cReporteTurnoNoche04a05_05.current.value = reporteTurnoX.vReporteTurnoNoche04a05_05;
      this.cReporteTurnoNoche04a05_06.current.value = reporteTurnoX.vReporteTurnoNoche04a05_06;
      this.cReporteTurnoNoche04a05_07.current.value = reporteTurnoX.vReporteTurnoNoche04a05_07;
      this.cReporteTurnoNoche04a05_08.current.value = reporteTurnoX.vReporteTurnoNoche04a05_08;
      this.cReporteTurnoNoche04a05_09.current.value = reporteTurnoX.vReporteTurnoNoche04a05_09;
      this.cReporteTurnoNoche04a05_10.current.value = reporteTurnoX.vReporteTurnoNoche04a05_10;
      this.cReporteTurnoNoche04a05_11.current.value = reporteTurnoX.vReporteTurnoNoche04a05_11;
      this.cReporteTurnoNoche04a05_12.current.value = reporteTurnoX.vReporteTurnoNoche04a05_12;
      this.cReporteTurnoNoche04a05_13.current.value = reporteTurnoX.vReporteTurnoNoche04a05_13;

      if (parseFloat(this.cReporteTurnoNoche04a05_08.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche04a05_08.current.value)}
      if (parseFloat(this.cReporteTurnoNoche04a05_09.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche04a05_09.current.value)}

      this.cReporteTurnoNoche05a06_01.current.value = reporteTurnoX.vReporteTurnoNoche05a06_01;
      this.cReporteTurnoNoche05a06_02.current.value = reporteTurnoX.vReporteTurnoNoche05a06_02;
      this.cReporteTurnoNoche05a06_03.current.value = reporteTurnoX.vReporteTurnoNoche05a06_03;
      this.cReporteTurnoNoche05a06_04.current.value = reporteTurnoX.vReporteTurnoNoche05a06_04;
      this.cReporteTurnoNoche05a06_05.current.value = reporteTurnoX.vReporteTurnoNoche05a06_05;
      this.cReporteTurnoNoche05a06_06.current.value = reporteTurnoX.vReporteTurnoNoche05a06_06;
      this.cReporteTurnoNoche05a06_07.current.value = reporteTurnoX.vReporteTurnoNoche05a06_07;
      this.cReporteTurnoNoche05a06_08.current.value = reporteTurnoX.vReporteTurnoNoche05a06_08;
      this.cReporteTurnoNoche05a06_09.current.value = reporteTurnoX.vReporteTurnoNoche05a06_09;
      this.cReporteTurnoNoche05a06_10.current.value = reporteTurnoX.vReporteTurnoNoche05a06_10;
      this.cReporteTurnoNoche05a06_11.current.value = reporteTurnoX.vReporteTurnoNoche05a06_11;
      this.cReporteTurnoNoche05a06_12.current.value = reporteTurnoX.vReporteTurnoNoche05a06_12;
      this.cReporteTurnoNoche05a06_13.current.value = reporteTurnoX.vReporteTurnoNoche05a06_13;

      if (parseFloat(this.cReporteTurnoNoche05a06_08.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche05a06_08.current.value)}
      if (parseFloat(this.cReporteTurnoNoche05a06_09.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche05a06_09.current.value)}

      this.cReporteTurnoNoche06a07_01.current.value = reporteTurnoX.vReporteTurnoNoche06a07_01;
      this.cReporteTurnoNoche06a07_02.current.value = reporteTurnoX.vReporteTurnoNoche06a07_02;
      this.cReporteTurnoNoche06a07_03.current.value = reporteTurnoX.vReporteTurnoNoche06a07_03;
      this.cReporteTurnoNoche06a07_04.current.value = reporteTurnoX.vReporteTurnoNoche06a07_04;
      this.cReporteTurnoNoche06a07_05.current.value = reporteTurnoX.vReporteTurnoNoche06a07_05;
      this.cReporteTurnoNoche06a07_06.current.value = reporteTurnoX.vReporteTurnoNoche06a07_06;
      this.cReporteTurnoNoche06a07_07.current.value = reporteTurnoX.vReporteTurnoNoche06a07_07;
      this.cReporteTurnoNoche06a07_08.current.value = reporteTurnoX.vReporteTurnoNoche06a07_08;
      this.cReporteTurnoNoche06a07_09.current.value = reporteTurnoX.vReporteTurnoNoche06a07_09;
      this.cReporteTurnoNoche06a07_10.current.value = reporteTurnoX.vReporteTurnoNoche06a07_10;
      this.cReporteTurnoNoche06a07_11.current.value = reporteTurnoX.vReporteTurnoNoche06a07_11;
      this.cReporteTurnoNoche06a07_12.current.value = reporteTurnoX.vReporteTurnoNoche06a07_12;
      this.cReporteTurnoNoche06a07_13.current.value = reporteTurnoX.vReporteTurnoNoche06a07_13;

      if (parseFloat(this.cReporteTurnoNoche06a07_08.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche06a07_08.current.value)}
      if (parseFloat(this.cReporteTurnoNoche06a07_09.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche06a07_09.current.value)}

      this.cReporteTurnoNoche07a08_01.current.value = reporteTurnoX.vReporteTurnoNoche07a08_01;
      this.cReporteTurnoNoche07a08_02.current.value = reporteTurnoX.vReporteTurnoNoche07a08_02;
      this.cReporteTurnoNoche07a08_03.current.value = reporteTurnoX.vReporteTurnoNoche07a08_03;
      this.cReporteTurnoNoche07a08_04.current.value = reporteTurnoX.vReporteTurnoNoche07a08_04;
      this.cReporteTurnoNoche07a08_05.current.value = reporteTurnoX.vReporteTurnoNoche07a08_05;
      this.cReporteTurnoNoche07a08_06.current.value = reporteTurnoX.vReporteTurnoNoche07a08_06;
      this.cReporteTurnoNoche07a08_07.current.value = reporteTurnoX.vReporteTurnoNoche07a08_07;
      this.cReporteTurnoNoche07a08_08.current.value = reporteTurnoX.vReporteTurnoNoche07a08_08;
      this.cReporteTurnoNoche07a08_09.current.value = reporteTurnoX.vReporteTurnoNoche07a08_09;
      this.cReporteTurnoNoche07a08_10.current.value = reporteTurnoX.vReporteTurnoNoche07a08_10;
      this.cReporteTurnoNoche07a08_11.current.value = reporteTurnoX.vReporteTurnoNoche07a08_11;
      this.cReporteTurnoNoche07a08_12.current.value = reporteTurnoX.vReporteTurnoNoche07a08_12;
      this.cReporteTurnoNoche07a08_13.current.value = reporteTurnoX.vReporteTurnoNoche07a08_13;

      if (parseFloat(this.cReporteTurnoNoche07a08_08.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche07a08_08.current.value)}
      if (parseFloat(this.cReporteTurnoNoche07a08_09.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche07a08_09.current.value)}


      this.cReporteTurnoNoche20a21_01_2.current.value = reporteTurnoX.vReporteTurnoNoche20a21_01_2;
      this.cReporteTurnoNoche20a21_02_2.current.value = reporteTurnoX.vReporteTurnoNoche20a21_02_2;
      this.cReporteTurnoNoche20a21_03_2.current.value = reporteTurnoX.vReporteTurnoNoche20a21_03_2;
      this.cReporteTurnoNoche20a21_04_2.current.value = reporteTurnoX.vReporteTurnoNoche20a21_04_2;
      this.cReporteTurnoNoche20a21_05_2.current.value = reporteTurnoX.vReporteTurnoNoche20a21_05_2;
      this.cReporteTurnoNoche20a21_06_2.current.value = reporteTurnoX.vReporteTurnoNoche20a21_06_2;
      this.cReporteTurnoNoche20a21_07_2.current.value = reporteTurnoX.vReporteTurnoNoche20a21_07_2;
      this.cReporteTurnoNoche20a21_08_2.current.value = reporteTurnoX.vReporteTurnoNoche20a21_08_2;
      this.cReporteTurnoNoche20a21_09_2.current.value = reporteTurnoX.vReporteTurnoNoche20a21_09_2;
      this.cReporteTurnoNoche20a21_10_2.current.value = reporteTurnoX.vReporteTurnoNoche20a21_10_2;
      this.cReporteTurnoNoche20a21_11_2.current.value = reporteTurnoX.vReporteTurnoNoche20a21_11_2;
      this.cReporteTurnoNoche20a21_12_2.current.value = reporteTurnoX.vReporteTurnoNoche20a21_12_2;
      this.cReporteTurnoNoche20a21_13_2.current.value = reporteTurnoX.vReporteTurnoNoche20a21_13_2;

      if (parseFloat(this.cReporteTurnoNoche20a21_08_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche20a21_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoNoche20a21_09_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche20a21_09_2.current.value)}

      this.cReporteTurnoNoche21a22_01_2.current.value = reporteTurnoX.vReporteTurnoNoche21a22_01_2;
      this.cReporteTurnoNoche21a22_02_2.current.value = reporteTurnoX.vReporteTurnoNoche21a22_02_2;
      this.cReporteTurnoNoche21a22_03_2.current.value = reporteTurnoX.vReporteTurnoNoche21a22_03_2;
      this.cReporteTurnoNoche21a22_04_2.current.value = reporteTurnoX.vReporteTurnoNoche21a22_04_2;
      this.cReporteTurnoNoche21a22_05_2.current.value = reporteTurnoX.vReporteTurnoNoche21a22_05_2;
      this.cReporteTurnoNoche21a22_06_2.current.value = reporteTurnoX.vReporteTurnoNoche21a22_06_2;
      this.cReporteTurnoNoche21a22_07_2.current.value = reporteTurnoX.vReporteTurnoNoche21a22_07_2;
      this.cReporteTurnoNoche21a22_08_2.current.value = reporteTurnoX.vReporteTurnoNoche21a22_08_2;
      this.cReporteTurnoNoche21a22_09_2.current.value = reporteTurnoX.vReporteTurnoNoche21a22_09_2;
      this.cReporteTurnoNoche21a22_10_2.current.value = reporteTurnoX.vReporteTurnoNoche21a22_10_2;
      this.cReporteTurnoNoche21a22_11_2.current.value = reporteTurnoX.vReporteTurnoNoche21a22_11_2;
      this.cReporteTurnoNoche21a22_12_2.current.value = reporteTurnoX.vReporteTurnoNoche21a22_12_2;
      this.cReporteTurnoNoche21a22_13_2.current.value = reporteTurnoX.vReporteTurnoNoche21a22_13_2;

      if (parseFloat(this.cReporteTurnoNoche21a22_08_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche21a22_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoNoche21a22_09_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche21a22_09_2.current.value)}

      this.cReporteTurnoNoche22a23_01_2.current.value = reporteTurnoX.vReporteTurnoNoche22a23_01_2;
      this.cReporteTurnoNoche22a23_02_2.current.value = reporteTurnoX.vReporteTurnoNoche22a23_02_2;
      this.cReporteTurnoNoche22a23_03_2.current.value = reporteTurnoX.vReporteTurnoNoche22a23_03_2;
      this.cReporteTurnoNoche22a23_04_2.current.value = reporteTurnoX.vReporteTurnoNoche22a23_04_2;
      this.cReporteTurnoNoche22a23_05_2.current.value = reporteTurnoX.vReporteTurnoNoche22a23_05_2;
      this.cReporteTurnoNoche22a23_06_2.current.value = reporteTurnoX.vReporteTurnoNoche22a23_06_2;
      this.cReporteTurnoNoche22a23_07_2.current.value = reporteTurnoX.vReporteTurnoNoche22a23_07_2;
      this.cReporteTurnoNoche22a23_08_2.current.value = reporteTurnoX.vReporteTurnoNoche22a23_08_2;
      this.cReporteTurnoNoche22a23_09_2.current.value = reporteTurnoX.vReporteTurnoNoche22a23_09_2;
      this.cReporteTurnoNoche22a23_10_2.current.value = reporteTurnoX.vReporteTurnoNoche22a23_10_2;
      this.cReporteTurnoNoche22a23_11_2.current.value = reporteTurnoX.vReporteTurnoNoche22a23_11_2;
      this.cReporteTurnoNoche22a23_12_2.current.value = reporteTurnoX.vReporteTurnoNoche22a23_12_2;
      this.cReporteTurnoNoche22a23_13_2.current.value = reporteTurnoX.vReporteTurnoNoche22a23_13_2;

      if (parseFloat(this.cReporteTurnoNoche22a23_08_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche22a23_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoNoche22a23_09_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche22a23_09_2.current.value)}

      this.cReporteTurnoNoche23a24_01_2.current.value = reporteTurnoX.vReporteTurnoNoche23a24_01_2;
      this.cReporteTurnoNoche23a24_02_2.current.value = reporteTurnoX.vReporteTurnoNoche23a24_02_2;
      this.cReporteTurnoNoche23a24_03_2.current.value = reporteTurnoX.vReporteTurnoNoche23a24_03_2;
      this.cReporteTurnoNoche23a24_04_2.current.value = reporteTurnoX.vReporteTurnoNoche23a24_04_2;
      this.cReporteTurnoNoche23a24_05_2.current.value = reporteTurnoX.vReporteTurnoNoche23a24_05_2;
      this.cReporteTurnoNoche23a24_06_2.current.value = reporteTurnoX.vReporteTurnoNoche23a24_06_2;
      this.cReporteTurnoNoche23a24_07_2.current.value = reporteTurnoX.vReporteTurnoNoche23a24_07_2;
      this.cReporteTurnoNoche23a24_08_2.current.value = reporteTurnoX.vReporteTurnoNoche23a24_08_2;
      this.cReporteTurnoNoche23a24_09_2.current.value = reporteTurnoX.vReporteTurnoNoche23a24_09_2;
      this.cReporteTurnoNoche23a24_10_2.current.value = reporteTurnoX.vReporteTurnoNoche23a24_10_2;
      this.cReporteTurnoNoche23a24_11_2.current.value = reporteTurnoX.vReporteTurnoNoche23a24_11_2;
      this.cReporteTurnoNoche23a24_12_2.current.value = reporteTurnoX.vReporteTurnoNoche23a24_12_2;
      this.cReporteTurnoNoche23a24_13_2.current.value = reporteTurnoX.vReporteTurnoNoche23a24_13_2;

      if (parseFloat(this.cReporteTurnoNoche23a24_08_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche23a24_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoNoche23a24_09_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche23a24_09_2.current.value)}

      this.cReporteTurnoNoche24a01_01_2.current.value = reporteTurnoX.vReporteTurnoNoche24a01_01_2;
      this.cReporteTurnoNoche24a01_02_2.current.value = reporteTurnoX.vReporteTurnoNoche24a01_02_2;
      this.cReporteTurnoNoche24a01_03_2.current.value = reporteTurnoX.vReporteTurnoNoche24a01_03_2;
      this.cReporteTurnoNoche24a01_04_2.current.value = reporteTurnoX.vReporteTurnoNoche24a01_04_2;
      this.cReporteTurnoNoche24a01_05_2.current.value = reporteTurnoX.vReporteTurnoNoche24a01_05_2;
      this.cReporteTurnoNoche24a01_06_2.current.value = reporteTurnoX.vReporteTurnoNoche24a01_06_2;
      this.cReporteTurnoNoche24a01_07_2.current.value = reporteTurnoX.vReporteTurnoNoche24a01_07_2;
      this.cReporteTurnoNoche24a01_08_2.current.value = reporteTurnoX.vReporteTurnoNoche24a01_08_2;
      this.cReporteTurnoNoche24a01_09_2.current.value = reporteTurnoX.vReporteTurnoNoche24a01_09_2;
      this.cReporteTurnoNoche24a01_10_2.current.value = reporteTurnoX.vReporteTurnoNoche24a01_10_2;
      this.cReporteTurnoNoche24a01_11_2.current.value = reporteTurnoX.vReporteTurnoNoche24a01_11_2;
      this.cReporteTurnoNoche24a01_12_2.current.value = reporteTurnoX.vReporteTurnoNoche24a01_12_2;
      this.cReporteTurnoNoche24a01_13_2.current.value = reporteTurnoX.vReporteTurnoNoche24a01_13_2;

      if (parseFloat(this.cReporteTurnoNoche24a01_08_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche24a01_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoNoche24a01_09_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche24a01_09_2.current.value)}

      this.cReporteTurnoNoche01a02_01_2.current.value = reporteTurnoX.vReporteTurnoNoche01a02_01_2;
      this.cReporteTurnoNoche01a02_02_2.current.value = reporteTurnoX.vReporteTurnoNoche01a02_02_2;
      this.cReporteTurnoNoche01a02_03_2.current.value = reporteTurnoX.vReporteTurnoNoche01a02_03_2;
      this.cReporteTurnoNoche01a02_04_2.current.value = reporteTurnoX.vReporteTurnoNoche01a02_04_2;
      this.cReporteTurnoNoche01a02_05_2.current.value = reporteTurnoX.vReporteTurnoNoche01a02_05_2;
      this.cReporteTurnoNoche01a02_06_2.current.value = reporteTurnoX.vReporteTurnoNoche01a02_06_2;
      this.cReporteTurnoNoche01a02_07_2.current.value = reporteTurnoX.vReporteTurnoNoche01a02_07_2;
      this.cReporteTurnoNoche01a02_08_2.current.value = reporteTurnoX.vReporteTurnoNoche01a02_08_2;
      this.cReporteTurnoNoche01a02_09_2.current.value = reporteTurnoX.vReporteTurnoNoche01a02_09_2;
      this.cReporteTurnoNoche01a02_10_2.current.value = reporteTurnoX.vReporteTurnoNoche01a02_10_2;
      this.cReporteTurnoNoche01a02_11_2.current.value = reporteTurnoX.vReporteTurnoNoche01a02_11_2;
      this.cReporteTurnoNoche01a02_12_2.current.value = reporteTurnoX.vReporteTurnoNoche01a02_12_2;
      this.cReporteTurnoNoche01a02_13_2.current.value = reporteTurnoX.vReporteTurnoNoche01a02_13_2;

      if (parseFloat(this.cReporteTurnoNoche01a02_08_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche01a02_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoNoche01a02_09_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche01a02_09_2.current.value)}

      this.cReporteTurnoNoche02a03_01_2.current.value = reporteTurnoX.vReporteTurnoNoche02a03_01_2;
      this.cReporteTurnoNoche02a03_02_2.current.value = reporteTurnoX.vReporteTurnoNoche02a03_02_2;
      this.cReporteTurnoNoche02a03_03_2.current.value = reporteTurnoX.vReporteTurnoNoche02a03_03_2;
      this.cReporteTurnoNoche02a03_04_2.current.value = reporteTurnoX.vReporteTurnoNoche02a03_04_2;
      this.cReporteTurnoNoche02a03_05_2.current.value = reporteTurnoX.vReporteTurnoNoche02a03_05_2;
      this.cReporteTurnoNoche02a03_06_2.current.value = reporteTurnoX.vReporteTurnoNoche02a03_06_2;
      this.cReporteTurnoNoche02a03_07_2.current.value = reporteTurnoX.vReporteTurnoNoche02a03_07_2;
      this.cReporteTurnoNoche02a03_08_2.current.value = reporteTurnoX.vReporteTurnoNoche02a03_08_2;
      this.cReporteTurnoNoche02a03_09_2.current.value = reporteTurnoX.vReporteTurnoNoche02a03_09_2;
      this.cReporteTurnoNoche02a03_10_2.current.value = reporteTurnoX.vReporteTurnoNoche02a03_10_2;
      this.cReporteTurnoNoche02a03_11_2.current.value = reporteTurnoX.vReporteTurnoNoche02a03_11_2;
      this.cReporteTurnoNoche02a03_12_2.current.value = reporteTurnoX.vReporteTurnoNoche02a03_12_2;
      this.cReporteTurnoNoche02a03_13_2.current.value = reporteTurnoX.vReporteTurnoNoche02a03_13_2;

      if (parseFloat(this.cReporteTurnoNoche02a03_08_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche02a03_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoNoche02a03_09_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche02a03_09_2.current.value)}

      this.cReporteTurnoNoche03a04_01_2.current.value = reporteTurnoX.vReporteTurnoNoche03a04_01_2;
      this.cReporteTurnoNoche03a04_02_2.current.value = reporteTurnoX.vReporteTurnoNoche03a04_02_2;
      this.cReporteTurnoNoche03a04_03_2.current.value = reporteTurnoX.vReporteTurnoNoche03a04_03_2;
      this.cReporteTurnoNoche03a04_04_2.current.value = reporteTurnoX.vReporteTurnoNoche03a04_04_2;
      this.cReporteTurnoNoche03a04_05_2.current.value = reporteTurnoX.vReporteTurnoNoche03a04_05_2;
      this.cReporteTurnoNoche03a04_06_2.current.value = reporteTurnoX.vReporteTurnoNoche03a04_06_2;
      this.cReporteTurnoNoche03a04_07_2.current.value = reporteTurnoX.vReporteTurnoNoche03a04_07_2;
      this.cReporteTurnoNoche03a04_08_2.current.value = reporteTurnoX.vReporteTurnoNoche03a04_08_2;
      this.cReporteTurnoNoche03a04_09_2.current.value = reporteTurnoX.vReporteTurnoNoche03a04_09_2;
      this.cReporteTurnoNoche03a04_10_2.current.value = reporteTurnoX.vReporteTurnoNoche03a04_10_2;
      this.cReporteTurnoNoche03a04_11_2.current.value = reporteTurnoX.vReporteTurnoNoche03a04_11_2;
      this.cReporteTurnoNoche03a04_12_2.current.value = reporteTurnoX.vReporteTurnoNoche03a04_12_2;
      this.cReporteTurnoNoche03a04_13_2.current.value = reporteTurnoX.vReporteTurnoNoche03a04_13_2;

      if (parseFloat(this.cReporteTurnoNoche03a04_08_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche03a04_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoNoche03a04_09_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche03a04_09_2.current.value)}

      this.cReporteTurnoNoche04a05_01_2.current.value = reporteTurnoX.vReporteTurnoNoche04a05_01_2;
      this.cReporteTurnoNoche04a05_02_2.current.value = reporteTurnoX.vReporteTurnoNoche04a05_02_2;
      this.cReporteTurnoNoche04a05_03_2.current.value = reporteTurnoX.vReporteTurnoNoche04a05_03_2;
      this.cReporteTurnoNoche04a05_04_2.current.value = reporteTurnoX.vReporteTurnoNoche04a05_04_2;
      this.cReporteTurnoNoche04a05_05_2.current.value = reporteTurnoX.vReporteTurnoNoche04a05_05_2;
      this.cReporteTurnoNoche04a05_06_2.current.value = reporteTurnoX.vReporteTurnoNoche04a05_06_2;
      this.cReporteTurnoNoche04a05_07_2.current.value = reporteTurnoX.vReporteTurnoNoche04a05_07_2;
      this.cReporteTurnoNoche04a05_08_2.current.value = reporteTurnoX.vReporteTurnoNoche04a05_08_2;
      this.cReporteTurnoNoche04a05_09_2.current.value = reporteTurnoX.vReporteTurnoNoche04a05_09_2;
      this.cReporteTurnoNoche04a05_10_2.current.value = reporteTurnoX.vReporteTurnoNoche04a05_10_2;
      this.cReporteTurnoNoche04a05_11_2.current.value = reporteTurnoX.vReporteTurnoNoche04a05_11_2;
      this.cReporteTurnoNoche04a05_12_2.current.value = reporteTurnoX.vReporteTurnoNoche04a05_12_2;
      this.cReporteTurnoNoche04a05_13_2.current.value = reporteTurnoX.vReporteTurnoNoche04a05_13_2;

      if (parseFloat(this.cReporteTurnoNoche04a05_08_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche04a05_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoNoche04a05_09_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche04a05_09_2.current.value)}

      this.cReporteTurnoNoche05a06_01_2.current.value = reporteTurnoX.vReporteTurnoNoche05a06_01_2;
      this.cReporteTurnoNoche05a06_02_2.current.value = reporteTurnoX.vReporteTurnoNoche05a06_02_2;
      this.cReporteTurnoNoche05a06_03_2.current.value = reporteTurnoX.vReporteTurnoNoche05a06_03_2;
      this.cReporteTurnoNoche05a06_04_2.current.value = reporteTurnoX.vReporteTurnoNoche05a06_04_2;
      this.cReporteTurnoNoche05a06_05_2.current.value = reporteTurnoX.vReporteTurnoNoche05a06_05_2;
      this.cReporteTurnoNoche05a06_06_2.current.value = reporteTurnoX.vReporteTurnoNoche05a06_06_2;
      this.cReporteTurnoNoche05a06_07_2.current.value = reporteTurnoX.vReporteTurnoNoche05a06_07_2;
      this.cReporteTurnoNoche05a06_08_2.current.value = reporteTurnoX.vReporteTurnoNoche05a06_08_2;
      this.cReporteTurnoNoche05a06_09_2.current.value = reporteTurnoX.vReporteTurnoNoche05a06_09_2;
      this.cReporteTurnoNoche05a06_10_2.current.value = reporteTurnoX.vReporteTurnoNoche05a06_10_2;
      this.cReporteTurnoNoche05a06_11_2.current.value = reporteTurnoX.vReporteTurnoNoche05a06_11_2;
      this.cReporteTurnoNoche05a06_12_2.current.value = reporteTurnoX.vReporteTurnoNoche05a06_12_2;
      this.cReporteTurnoNoche05a06_13_2.current.value = reporteTurnoX.vReporteTurnoNoche05a06_13_2;

      if (parseFloat(this.cReporteTurnoNoche05a06_08_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche05a06_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoNoche05a06_09_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche05a06_09_2.current.value)}

      this.cReporteTurnoNoche06a07_01_2.current.value = reporteTurnoX.vReporteTurnoNoche06a07_01_2;
      this.cReporteTurnoNoche06a07_02_2.current.value = reporteTurnoX.vReporteTurnoNoche06a07_02_2;
      this.cReporteTurnoNoche06a07_03_2.current.value = reporteTurnoX.vReporteTurnoNoche06a07_03_2;
      this.cReporteTurnoNoche06a07_04_2.current.value = reporteTurnoX.vReporteTurnoNoche06a07_04_2;
      this.cReporteTurnoNoche06a07_05_2.current.value = reporteTurnoX.vReporteTurnoNoche06a07_05_2;
      this.cReporteTurnoNoche06a07_06_2.current.value = reporteTurnoX.vReporteTurnoNoche06a07_06_2;
      this.cReporteTurnoNoche06a07_07_2.current.value = reporteTurnoX.vReporteTurnoNoche06a07_07_2;
      this.cReporteTurnoNoche06a07_08_2.current.value = reporteTurnoX.vReporteTurnoNoche06a07_08_2;
      this.cReporteTurnoNoche06a07_09_2.current.value = reporteTurnoX.vReporteTurnoNoche06a07_09_2;
      this.cReporteTurnoNoche06a07_10_2.current.value = reporteTurnoX.vReporteTurnoNoche06a07_10_2;
      this.cReporteTurnoNoche06a07_11_2.current.value = reporteTurnoX.vReporteTurnoNoche06a07_11_2;
      this.cReporteTurnoNoche06a07_12_2.current.value = reporteTurnoX.vReporteTurnoNoche06a07_12_2;
      this.cReporteTurnoNoche06a07_13_2.current.value = reporteTurnoX.vReporteTurnoNoche06a07_13_2;

      if (parseFloat(this.cReporteTurnoNoche06a07_08_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche06a07_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoNoche06a07_09_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche06a07_09_2.current.value)}

      this.cReporteTurnoNoche07a08_01_2.current.value = reporteTurnoX.vReporteTurnoNoche07a08_01_2;
      this.cReporteTurnoNoche07a08_02_2.current.value = reporteTurnoX.vReporteTurnoNoche07a08_02_2;
      this.cReporteTurnoNoche07a08_03_2.current.value = reporteTurnoX.vReporteTurnoNoche07a08_03_2;
      this.cReporteTurnoNoche07a08_04_2.current.value = reporteTurnoX.vReporteTurnoNoche07a08_04_2;
      this.cReporteTurnoNoche07a08_05_2.current.value = reporteTurnoX.vReporteTurnoNoche07a08_05_2;
      this.cReporteTurnoNoche07a08_06_2.current.value = reporteTurnoX.vReporteTurnoNoche07a08_06_2;
      this.cReporteTurnoNoche07a08_07_2.current.value = reporteTurnoX.vReporteTurnoNoche07a08_07_2;
      this.cReporteTurnoNoche07a08_08_2.current.value = reporteTurnoX.vReporteTurnoNoche07a08_08_2;
      this.cReporteTurnoNoche07a08_09_2.current.value = reporteTurnoX.vReporteTurnoNoche07a08_09_2;
      this.cReporteTurnoNoche07a08_10_2.current.value = reporteTurnoX.vReporteTurnoNoche07a08_10_2;
      this.cReporteTurnoNoche07a08_11_2.current.value = reporteTurnoX.vReporteTurnoNoche07a08_11_2;
      this.cReporteTurnoNoche07a08_12_2.current.value = reporteTurnoX.vReporteTurnoNoche07a08_12_2;
      this.cReporteTurnoNoche07a08_13_2.current.value = reporteTurnoX.vReporteTurnoNoche07a08_13_2;

      if (parseFloat(this.cReporteTurnoNoche07a08_08_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche07a08_08_2.current.value)}
      if (parseFloat(this.cReporteTurnoNoche07a08_09_2.current.value) > fondoPozoNocheX){fondoPozoNocheX = parseFloat(this.cReporteTurnoNoche07a08_09_2.current.value)}

      this.setState({bFondoPozoExiste: true, fondoPozoNocheX, consolidadoFondoOrigenNocheX: fondoPozoNocheX, consolidadoFondoOrigenNocheLlenaX: fondoPozoNocheX})

      let e = ''
      for (let i = 13; i <= 24; i++) {
          this.handleAceroPerforacion(e,i,2)
          this.handleHerramienta(e,i,2)
      }
      for (let i = 113; i <= 124; i++) {
          this.handleAceroPerforacion(e,i,2)
          this.handleHerramienta(e,i,2)
      }


      let palabra = ''
      palabra = this.cReporteTurnoNoche20a21_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle25X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle25X=false};this.setState({displayDetalle25X})
      palabra = this.cReporteTurnoNoche21a22_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle27X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle27X=false};this.setState({displayDetalle27X})
      palabra = this.cReporteTurnoNoche22a23_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle29X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle29X=false};this.setState({displayDetalle29X})
      palabra = this.cReporteTurnoNoche23a24_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle31X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle31X=false};this.setState({displayDetalle31X})
      palabra = this.cReporteTurnoNoche24a01_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle33X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle33X=false};this.setState({displayDetalle33X})
      palabra = this.cReporteTurnoNoche01a02_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle35X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle35X=false};this.setState({displayDetalle35X})
      palabra = this.cReporteTurnoNoche02a03_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle37X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle37X=false};this.setState({displayDetalle37X})
      palabra = this.cReporteTurnoNoche03a04_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle39X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle39X=false};this.setState({displayDetalle39X})
      palabra = this.cReporteTurnoNoche04a05_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle41X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle41X=false};this.setState({displayDetalle41X})
      palabra = this.cReporteTurnoNoche05a06_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle43X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle43X=false};this.setState({displayDetalle43X})
      palabra = this.cReporteTurnoNoche06a07_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle45X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle45X=false};this.setState({displayDetalle45X})
      palabra = this.cReporteTurnoNoche07a08_01.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle47X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle47X=false};this.setState({displayDetalle47X})

      palabra = this.cReporteTurnoNoche20a21_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle26X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle26X=false};this.setState({displayDetalle26X})
      palabra = this.cReporteTurnoNoche21a22_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle28X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle28X=false};this.setState({displayDetalle28X})
      palabra = this.cReporteTurnoNoche22a23_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle30X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle30X=false};this.setState({displayDetalle30X})
      palabra = this.cReporteTurnoNoche23a24_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle32X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle32X=false};this.setState({displayDetalle32X})
      palabra = this.cReporteTurnoNoche24a01_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle34X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle34X=false};this.setState({displayDetalle34X})
      palabra = this.cReporteTurnoNoche01a02_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle36X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle36X=false};this.setState({displayDetalle36X})
      palabra = this.cReporteTurnoNoche02a03_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle38X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle38X=false};this.setState({displayDetalle38X})
      palabra = this.cReporteTurnoNoche03a04_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle40X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle40X=false};this.setState({displayDetalle40X})
      palabra = this.cReporteTurnoNoche04a05_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle42X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle42X=false};this.setState({displayDetalle42X})
      palabra = this.cReporteTurnoNoche05a06_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle44X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle44X=false};this.setState({displayDetalle44X})
      palabra = this.cReporteTurnoNoche06a07_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle46X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle46X=false};this.setState({displayDetalle46X})
      palabra = this.cReporteTurnoNoche07a08_01_2.current.value; ArrayIdValue = palabra.split('&&&');let displayDetalle48X=true; if (ArrayIdValue[0]==='09' || ArrayIdValue[0]==='10' || ArrayIdValue[0]==='11' || ArrayIdValue[0]==='12' || ArrayIdValue[0]==='13' || ArrayIdValue[0]==='14' || ArrayIdValue[0]==='17' || ArrayIdValue[0]==='18' || ArrayIdValue[0]==='19'){displayDetalle48X=false};this.setState({displayDetalle48X})



      this.setState({vReporteTurnoNoche01:reporteTurnoX.vReporteTurnoNoche01})
      this.setState({vReporteTurnoNoche01_ID:reporteTurnoX.vReporteTurnoNoche01_ID})
      this.setState({vReporteTurnoNoche04:reporteTurnoX.vReporteTurnoNoche04})
      this.setState({vReporteTurnoNoche04_ID:reporteTurnoX.vReporteTurnoNoche04_ID})
      this.setState({vReporteTurnoNoche05:reporteTurnoX.vReporteTurnoNoche05})
      this.setState({vReporteTurnoNoche05_ID:reporteTurnoX.vReporteTurnoNoche05_ID})
      this.setState({uuidX:reporteTurnoX.vUUID})
    }

    llenaObservacionesTurnoNoche = (fechaX,jTurnoNoche) => {
      return new Promise((resolve2, reject2) => {
          let archivoX = ''
          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_observacion_supervisor_noche.json`
          Promise.all([this.getObservacion(archivoX,jTurnoNoche.vReporteTurnoNoche04_ID,2)]).then((resolve) =>{
            archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_observacion_asesor_she_turno_noche.json`
            Promise.all([this.getObservacion(archivoX,jTurnoNoche.vReporteTurnoNoche05_ID,7)]).then((resolve) =>{
              archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_observacion_jefe_turno_noche.json`
              Promise.all([this.getObservacion(archivoX,jTurnoNoche.vReporteTurnoNoche01_ID,9)]).then((resolve) =>{
                resolve2()
            },(reject) =>{resolve2()})
          },(reject) =>{resolve2()})
        },(reject) =>{resolve2()})
      })
    }

    llenaObservacionesTurnoDia = (fechaX,jsonTurnoDia) => {
      return new Promise((resolve2, reject2) => {
        let archivoX = ''
        archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_observacion_supervisor_dia.json`
        Promise.all([this.getObservacion(archivoX,jsonTurnoDia.vReporteTurnoDia04_ID,1)]).then((resolve) =>{
          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_observacion_asesor_she_turno_dia.json`
          Promise.all([this.getObservacion(archivoX,jsonTurnoDia.vReporteTurnoDia05_ID,6)]).then((resolve) =>{
            archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_observacion_jefe_turno_dia.json`
            Promise.all([this.getObservacion(archivoX,jsonTurnoDia.vReporteTurnoDia01_ID,8)]).then((resolve) =>{
              resolve2()
            },(reject) =>{resolve2()})
          },(reject) =>{resolve2()})
        },(reject) =>{resolve2()})
      })
    }

    llenaObservacionesFirmantes = (fechaX) => {
      return new Promise((resolve2, reject2) => {
        let archivoX = ''
        let observacionAdmMD = ''
        let observacionControlGestion = ''
        let observacionAdmAA = ''
        let observacionJefeTurnoMD = ''
        this.state.authorizersX.map((todoauth,i) => {
          if (todoauth.conceptFormsDeliverable === 'ReporteTurno'){
              this.state.roleX.map((todorole,x) => {
                  if (todoauth.role === todorole.code && todoauth.role === 'Adm MD'){
                    observacionAdmMD = todoauth.id
                  }
                  if (todoauth.role === todorole.code && todoauth.role === 'Jefe Turno Control Sondaje'){
                    observacionControlGestion = todoauth.id
                  }
                  if (todoauth.role === todorole.code && todoauth.role === 'Adm AA'){
                    observacionAdmAA = todoauth.id
                  }
            })
          }
        })
        archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_observacion_adm_cto_md.json`
        Promise.all([this.getObservacion(archivoX,observacionAdmMD,3)]).then((resolve) =>{
          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_observacion_control_gestion.json`
          Promise.all([this.getObservacion(archivoX,observacionControlGestion,4)]).then((resolve) =>{
            archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_observacion_adm_cto_aa.json`
            Promise.all([this.getObservacion(archivoX,observacionAdmAA,5)]).then((resolve) =>{
              resolve2()
            },(reject) =>{resolve2()})
          },(reject) =>{resolve2()})
        },(reject) =>{resolve2()})
      })
    }

    getObservacion = (archiveX,userX,opcionObservacionX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(userX,archiveX)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    if (opcionObservacionX  === 1){
                        this.setState({cReporteTurnoObservacionFirmante1:jsonBlockstack4d.cReporteTurnoObservacion})
                    }
                    if (opcionObservacionX  === 2){
                        this.setState({cReporteTurnoObservacionFirmante2:jsonBlockstack4d.cReporteTurnoObservacion})
                    }
                    if (opcionObservacionX  === 3){
                        this.setState({cReporteTurnoObservacionFirmante3:jsonBlockstack4d.cReporteTurnoObservacion})
                    }
                    if (opcionObservacionX  === 4){
                        this.setState({cReporteTurnoObservacionFirmante4:jsonBlockstack4d.cReporteTurnoObservacion})
                    }
                    if (opcionObservacionX  === 5){
                        this.setState({cReporteTurnoObservacionFirmante5:jsonBlockstack4d.cReporteTurnoObservacion})
                    }
                    if (opcionObservacionX  === 6){
                        this.setState({cReporteTurnoObservacionAsesorTurnoDia:jsonBlockstack4d.cReporteTurnoObservacion})
                    }
                    if (opcionObservacionX  === 7){
                        this.setState({cReporteTurnoObservacionAsesorTurnoNoche:jsonBlockstack4d.cReporteTurnoObservacion})
                    }
                    if (opcionObservacionX  === 8){
                        this.setState({cReporteTurnoObservacionJefeTurnoDia:jsonBlockstack4d.cReporteTurnoObservacion})
                    }
                    if (opcionObservacionX  === 9){
                        this.setState({cReporteTurnoObservacionJefeTurnoNoche:jsonBlockstack4d.cReporteTurnoObservacion})
                    }
                    resolve1()
                  }else{
                    resolve1()
                  }
               } else {
                 resolve1()
               }
             })
            .catch(error => {
              resolve1()
            });
      })
    }

    llenaFirmasFirmantes = (fechaX) => {
      return new Promise((resolve2, reject2) => {
          let archivoX = ''
          let firmaAdmMD = ''
          let firmaControlGestion = ''
          let firmaAdmAA = ''
          this.state.authorizersX.map((todoauth,i) => {
            if (todoauth.conceptFormsDeliverable === 'ReporteTurno'){
                this.state.roleX.map((todorole,x) => {
                    if (todoauth.role === todorole.code && todoauth.role === 'Adm MD'){
                      firmaAdmMD = todoauth.id
                    }
                    if (todoauth.role === todorole.code && todoauth.role === 'Jefe Turno Control Sondaje'){
                      firmaControlGestion = todoauth.id
                    }
                    if (todoauth.role === todorole.code && todoauth.role === 'Adm AA'){
                      firmaAdmAA = todoauth.id
                    }
                })
            }
          })

          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_firma_adm_md.json`
          Promise.all([this.getFirma(archivoX,firmaAdmMD,3,'display')]).then((resolve) =>{
            archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_firma_control_gestion.json`
            Promise.all([this.getFirma(archivoX,firmaControlGestion,4,'display')]).then((resolve) =>{
              archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_firma_adm_aa.json`
              Promise.all([this.getFirma(archivoX,firmaAdmAA,5,'display')]).then((resolve) =>{
                resolve2()
              },(reject) =>{resolve2()})
            },(reject) =>{resolve2()})
          },(reject) =>{resolve2()})
      })
    }

    llenaFirmasTurnoDia = (fechaX,jsonFirmas) => {
      return new Promise((resolve2, reject2) => {
          let archivoX = ''
          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_firma_supervisor_md_dia.json`
          Promise.all([this.getFirma(archivoX,jsonFirmas.vReporteTurnoDia04_ID,1,'display')]).then((resolve) =>{
            archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_firma_jefe_turno_dia.json`
            Promise.all([this.getFirma(archivoX,jsonFirmas.vReporteTurnoDia01_ID,6,'display')]).then((resolve) =>{
              resolve2()
            },(reject) =>{resolve2()})
          },(reject) =>{resolve2()})
      })
    }

    llenaFirmasTurnoNoche = (fechaX,jsonFirmas) => {
      return new Promise((resolve2, reject2) => {
          let archivoX = ''
          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_firma_supervisor_md_noche.json`
          Promise.all([this.getFirma(archivoX,jsonFirmas.vReporteTurnoNoche04_ID,2,'display')]).then((resolve) =>{
            archivoX = `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_reporte_turno_firma_jefe_turno_noche.json`
            Promise.all([this.getFirma(archivoX,jsonFirmas.vReporteTurnoNoche01_ID,7,'display')]).then((resolve) =>{
              resolve2()
            },(reject) =>{resolve2()})
          },(reject) =>{resolve2()})
      })
    }

    getFirma = (archiveX,userX,opcionObservacionX,modeX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(userX,archiveX)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    if (modeX === 'display'){
                      if (opcionObservacionX  === 1){
                         this.setState({cReporteTurnoNameFirmante1:jsonBlockstack4d[0].name,
                                        cReporteTurnoIdFirmante1:jsonBlockstack4d[0].id,
                                        cReporteTurnoPublicKeyFirmante1:jsonBlockstack4d[0].publicKey,
                                        cReporteTurnoDateTimeFirmante1:jsonBlockstack4d[0].datetime,
                                        cReporteTurnoSignatureFirmante1:jsonBlockstack4d[0].signature,
                                       })
                      }
                      if (opcionObservacionX  === 2){
                          this.setState({cReporteTurnoNameFirmante2:jsonBlockstack4d[0].name,
                                         cReporteTurnoIdFirmante2:jsonBlockstack4d[0].id,
                                         cReporteTurnoPublicKeyFirmante2:jsonBlockstack4d[0].publicKey,
                                         cReporteTurnoDateTimeFirmante2:jsonBlockstack4d[0].datetime,
                                         cReporteTurnoSignatureFirmante2:jsonBlockstack4d[0].signature,
                                        })
                      }
                      if (opcionObservacionX  === 3){
                          this.setState({cReporteTurnoNameFirmante3:jsonBlockstack4d[0].name,
                                         cReporteTurnoIdFirmante3:jsonBlockstack4d[0].id,
                                         cReporteTurnoPublicKeyFirmante3:jsonBlockstack4d[0].publicKey,
                                         cReporteTurnoDateTimeFirmante3:jsonBlockstack4d[0].datetime,
                                         cReporteTurnoSignatureFirmante3:jsonBlockstack4d[0].signature,
                                        })
                      }
                      if (opcionObservacionX  === 4){
                          if (this.state.cReporteTurnoSignatureFirmante4 === false){
                            this.setState({cReporteTurnoNameFirmante4:jsonBlockstack4d[0].name,
                                           cReporteTurnoIdFirmante4:jsonBlockstack4d[0].id,
                                           cReporteTurnoPublicKeyFirmante4:jsonBlockstack4d[0].publicKey,
                                           cReporteTurnoDateTimeFirmante4:jsonBlockstack4d[0].datetime,
                                           cReporteTurnoSignatureFirmante4:jsonBlockstack4d[0].signature,
                                          })
                          }
                        }
                      if (opcionObservacionX  === 5){
                          this.setState({cReporteTurnoNameFirmante5:jsonBlockstack4d[0].name,
                                         cReporteTurnoIdFirmante5:jsonBlockstack4d[0].id,
                                         cReporteTurnoPublicKeyFirmante5:jsonBlockstack4d[0].publicKey,
                                         cReporteTurnoDateTimeFirmante5:jsonBlockstack4d[0].datetime,
                                         cReporteTurnoSignatureFirmante5:jsonBlockstack4d[0].signature,
                                        })
                      }
                      if (opcionObservacionX  === 6){
                          this.setState({cReporteTurnoNameFirmante6:jsonBlockstack4d[0].name,
                                         cReporteTurnoIdFirmante6:jsonBlockstack4d[0].id,
                                         cReporteTurnoPublicKeyFirmante6:jsonBlockstack4d[0].publicKey,
                                         cReporteTurnoDateTimeFirmante6:jsonBlockstack4d[0].datetime,
                                         cReporteTurnoSignatureFirmante6:jsonBlockstack4d[0].signature,
                                        })
                      }
                      if (opcionObservacionX  === 7){
                          this.setState({cReporteTurnoNameFirmante7:jsonBlockstack4d[0].name,
                                         cReporteTurnoIdFirmante7:jsonBlockstack4d[0].id,
                                         cReporteTurnoPublicKeyFirmante7:jsonBlockstack4d[0].publicKey,
                                         cReporteTurnoDateTimeFirmante7:jsonBlockstack4d[0].datetime,
                                         cReporteTurnoSignatureFirmante7:jsonBlockstack4d[0].signature,
                                        })
                      }
                      Promise.all([this.getSignature2Names(userX,opcionObservacionX)]).then((resolve2)=>{},(reject2)=>{})
                      resolve1(jsonBlockstack4d[0].signature)
                    }else{
                      resolve1(jsonBlockstack4d[0].signature)
                    }
                 } else {
                   resolve1(false)
                 }
               } else {
                 resolve1(false)
               }
             })
            .catch(error => {
              resolve1(false)
            });
      })
    }

    getSignature2Names = (userX,opcionObservacionX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(userX, `signature.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    if (opcionObservacionX  === 1){
                       this.setState({cReporteTurnotrimmedDataURLFirmante1:jsonBlockstack4.signature})
                    }
                    if (opcionObservacionX  === 2){
                       this.setState({cReporteTurnotrimmedDataURLFirmante2:jsonBlockstack4.signature})
                    }
                    if (opcionObservacionX  === 3){
                       this.setState({cReporteTurnotrimmedDataURLFirmante3:jsonBlockstack4.signature})
                    }
                    if (opcionObservacionX  === 4){
                       this.setState({cReporteTurnotrimmedDataURLFirmante4:jsonBlockstack4.signature})
                    }
                    if (opcionObservacionX  === 5){
                       this.setState({cReporteTurnotrimmedDataURLFirmante5:jsonBlockstack4.signature})
                    }
                    if (opcionObservacionX  === 6){
                       this.setState({cReporteTurnotrimmedDataURLFirmante6:jsonBlockstack4.signature})
                    }
                    if (opcionObservacionX  === 7){
                       this.setState({cReporteTurnotrimmedDataURLFirmante7:jsonBlockstack4.signature})
                    }
                    resolve1()
                 } else {
                   resolve1()
                 }
               } else {
                 resolve1()
               }
             })
            .catch(error => {
              resolve1()
            });
      })
    }

    getPublicKeyNames = (userX,opcionObservacionX) =>{
      return new Promise ((resolve1, reject1) =>{
        if (userX === undefined || userX === null || userX === ''){resolve1()}
        var nameLookupURL = "https://api.hiro.so" + "/v1/names/" + userX;
        var config = {
          method: 'get',
          url: nameLookupURL,
          headers: {'x-hiro-api-key': 'xcheck_WTpZ9LK4BzykUnbTfpPrEMdPiLjMsqPM'}
        };
        axios(config)
          .then(result => {
            const {zonefile} = result.data
            const zonefile1 = zonefile.split('"')
            const zonefile3 = zonefile1[1]
            const zonefile4 = zonefile3.replace(/\\/g,'')
            axios.get(zonefile4)
               .then(result => {
                  const jsonBlockstack1 = JSON.stringify(result.data[0].decodedToken.payload.claim.appsMeta)
                  let jsonBlockstack2 = jsonBlockstack1
                  let jsonBlockstack4 = {}
                  const var_a_remp = `"${window.location.origin}":`
                  if (window.location.origin === 'http://localhost:3000'){jsonBlockstack2 = jsonBlockstack1.replace(var_a_remp,`"localhost":`);}
                  if (window.location.origin === 'https://xck.app'){jsonBlockstack2 = jsonBlockstack1.replace(var_a_remp,`"xckapp":`);}
                  const jsonBlockstack3 = JSON.parse(jsonBlockstack2)
                  if (window.location.origin === 'http://localhost:3000'){jsonBlockstack4 = jsonBlockstack3.localhost}
                  if (window.location.origin === 'https://xck.app'){jsonBlockstack4 = jsonBlockstack3.xckapp}
                  const {publicKey} = jsonBlockstack4
                  if (opcionObservacionX  === 1){
                     this.setState({cReporteTurnoPublicKeyFirmante1:jsonBlockstack4.publicKey2})
                  }
                  if (opcionObservacionX  === 2){
                     this.setState({cReporteTurnoPublicKeyFirmante2:jsonBlockstack4.publicKey})
                  }
                  if (opcionObservacionX  === 3){
                     this.setState({cReporteTurnoPublicKeyFirmante3:jsonBlockstack4.publicKey})
                  }
                  if (opcionObservacionX  === 4){
                     this.setState({cReporteTurnoPublicKeyFirmante4:jsonBlockstack4.publicKey})
                  }
                  if (opcionObservacionX  === 5){
                     this.setState({cReporteTurnoPublicKeyFirmante5:jsonBlockstack4.publicKey})
                  }
                  if (opcionObservacionX  === 6){
                     this.setState({cReporteTurnoPublicKeyFirmante6:jsonBlockstack4.publicKey})
                  }
                  if (opcionObservacionX  === 7){
                     this.setState({cReporteTurnoPublicKeyFirmante7:jsonBlockstack4.publicKey})
                  }
                  resolve1()
               })
             .catch(error => {
               resolve1()
             });
          })
          .catch(error => {
            console.log(error)
            resolve1()
          });
      });
    }

    observacion = (e,optX) => {
      this.setState({dialogOpenObservaciones:true,dialogTitle:'Aclaraciones', opcionObservacion:optX})
      if (optX === 1){this.setState({dialogMessage:"Observación Firmante - Supervisor Día Mineral Drilling", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionFirmante1 })}
      if (optX === 2){this.setState({dialogMessage:"Observación Firmante - Supervisor Noche Mineral Drilling", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionFirmante2 })}
      if (optX === 3){this.setState({dialogMessage:"Observación Firmante - Administrador Contrato Mineral Drilling", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionFirmante3 })}
      if (optX === 4){this.setState({dialogMessage:"Observación Firmante - Jefe Turno Control Sondaje", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionFirmante4 })}
      if (optX === 5){this.setState({dialogMessage:"Observación Firmante - Administrador Contrato Anglo-American", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionFirmante5 })}
      if (optX === 6){this.setState({dialogMessage:"Observación Asesor HSE Mineral Drilling - Turno Día", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionAsesorTurnoDia })}
      if (optX === 7){this.setState({dialogMessage:"Observación Asesor HSE Mineral Drilling - Turno Noche", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionAsesorTurnoNoche })}
      if (optX === 8){this.setState({dialogMessage:"Observación Jefe Turno Mineral Drilling - Turno Día", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionJefeTurnoDia })}
      if (optX === 9){this.setState({dialogMessage:"Observación Jefe Turno Mineral Drilling - Turno Noche", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionJefeTurnoNoche })}
    }

    getWorkOrder = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `${this.props.typeContract}_${this.props.numberContract}.json`)
             .then((fileContents) => {
                 if(fileContents) {
                     const jsonBlockstack1 = JSON.parse(fileContents)
                     const jsonBlockstack2 = jsonBlockstack1.data
                     let jsonBlockstack5 = '[]'
                     if (jsonBlockstack2 !== null){
                        const jsonBlockstack3 = jsonBlockstack2.dataobject
                        const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                        if (jsonBlockstack4.substring(0,1) === '"') {
                           jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                        } else {
                           jsonBlockstack5 = jsonBlockstack4
                        }
                        let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                        jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                        const {idWorkOrder,idTypeWorkOrder,dateContract,startDate,endDate,
                                workordercampaña,workordersondaje,workorderrecomendado,workordertipoperforacion,
                                workorderfase,workordernumerocontrato,workorderprimerobjetivo,workordersegundoobjetivo,
                                workordereste,workordernorte,workordercotainicio,workordercotfinal,workordercliente,
                                workorderlargo,workorderantepozo,workorderfechainiciopozo,workorderfechaterminoperforacion,
                                workorderfechaterminosondaje,workorderdesde,workorderhasta,workorderazimut,
                                workorderdeclinacion,workordersobrecargadesde1,workordersobrecargahasta1,
                                workorderantepozodesde1,workorderantepozohasta1,workorderpolvodesde1,
                                workorderpolvohasta1,workorderdiamantinodesde1,workorderdiamantinohasta1,
                                workordersobrecargadesde2,workordersobrecargahasta2,workorderantepozodesde2,
                                workorderantepozohasta2,workorderpolvodesde2,workorderpolvohasta2,
                                workorderdiamantinodesde2,workorderdiamantinohasta2,workorderobservacion,
                                workorderfecharealinicio,workordermetaturnodia,workordermetaturnonoche} = jsonBlockstack4d

                        this.setState({workordercliente,workordercampaña,workordersondaje,workordertipoperforacion,workorderfase,
                                       workordernumerocontrato,workorderdeclinacion,workorderrecomendado,workordermetaturnodia,
                                       workordermetaturnonoche,workorderobservacion})
                        resolve1()
                    }else{
                      resolve1()
                    }
                 } else {
                   resolve1()
                 }
             })
            .catch(error => {
              resolve1()
            });
      })
    }

    getWorkOrderDatoExtra = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `${this.props.typeContract}_${this.props.numberContract}_workorder_datoextra.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    const {workorderfecharealinicio,workordermetaturnodia,workordermetaturnonoche,workorderobservacion,workorderfechaterminoperforacion,workorderfechaterminosondaje,workorderfechainiciopozo} = jsonBlockstack4d
                    this.setState({workordermetaturnodia,workordermetaturnonoche,workorderobservacion})

                    if (workorderfechainiciopozo !== undefined && workorderfechainiciopozo !== '' && workorderfechainiciopozo !== null){
                       const fechaHoyX = new Date(workorderfechainiciopozo)
                       let diaX = fechaHoyX.getDate()
                       let mesX = fechaHoyX.getMonth()+1
                       let mes0X = fechaHoyX.getMonth()
                       let anioX = fechaHoyX.getFullYear()
                       let dia2X = 0
                       let mes2X = 0
                       if (diaX<10){dia2X = '0'+ diaX.toString()}else{ dia2X = diaX.toString()}
                       if (mesX<10){mes2X = '0'+ mesX.toString()}else{ mes2X = mesX.toString()}
                       const fecha_texto = anioX.toString()+'-'+mes2X+'-'+dia2X+'T13:00:00'
                       const fechaX = new Date(fecha_texto);
                       fechaX.setDate(fechaX.getDate() + 1);
                       //fechaX.setMinutes(fechaX.getMinutes() + fechaX.getTimezoneOffset())
                       this.setState({workorderfecharealinicio: fechaX})
                       this.setState({fechaReporte: fechaX})
                    }else {
                       const fechaHoyX = new Date()
                       let diaX = fechaHoyX.getDate()
                       let mesX = fechaHoyX.getMonth()+1
                       let mes0X = fechaHoyX.getMonth()
                       let anioX = fechaHoyX.getFullYear()
                       let dia2X = 0
                       let mes2X = 0
                       if (diaX<10){dia2X = '0'+ diaX.toString()}else{ dia2X = diaX.toString()}
                       if (mesX<10){mes2X = '0'+ mesX.toString()}else{ mes2X = mesX.toString()}
                       const fecha_texto = anioX.toString()+'-'+mes2X+'-'+dia2X+'T13:00:00'
                       const fechaX = new Date(fecha_texto);
                       this.setState({workorderfecharealinicio: fechaX})
                       this.setState({fechaReporte: fechaX})
                    }

                    if (workorderfechaterminosondaje !== undefined && workorderfechaterminosondaje !== '' && workorderfechaterminosondaje !== null){
                        const fechaHoyX = new Date(workorderfechaterminosondaje)
                        let diaX = fechaHoyX.getDate()
                        let mesX = fechaHoyX.getMonth()+1
                        let anioX = fechaHoyX.getFullYear()
                        let dia2X = 0
                        let mes2X = 0
                        if (diaX<10){dia2X = '0'+ diaX.toString()}else{ dia2X = diaX.toString()}
                        if (mesX<10){mes2X = '0'+ mesX.toString()}else{ mes2X = mesX.toString()}
                        let fecha_texto = anioX.toString()+'-'+mes2X+'-'+dia2X+'T13:00:00'
                        const fechaX = new Date(fecha_texto);
                        this.setState({workorderfechaterminoperforacion:fechaX })
                    }else {
                        const fechaHoyX = new Date()
                        let diaX = fechaHoyX.getDate()
                        let mesX = fechaHoyX.getMonth()+1
                        let anioX = fechaHoyX.getFullYear()
                        let dia2X = 0
                        let mes2X = 0
                        if (diaX<10){dia2X = '0'+ diaX.toString()}else{ dia2X = diaX.toString()}
                        if (mesX<10){mes2X = '0'+ mesX.toString()}else{ mes2X = mesX.toString()}
                        let fecha_texto = anioX.toString()+'-'+mes2X+'-'+dia2X+'T13:00:00'
                        const fechaX = new Date(fecha_texto);
                        this.setState({workorderfechaterminoperforacion:fechaX })
                    }
                    resolve1()
                  } else {
                    resolve1()
                  }
                } else {
                 resolve1()
                }
             })
            .catch(error => {
              resolve1()
            });
      })
    }

    getWorkOrder2 = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `${this.props.typeContract}_${this.props.numberContract}.json`)
             .then((fileContents) => {
                 if(fileContents) {
                     const jsonBlockstack1 = JSON.parse(fileContents)
                     const jsonBlockstack2 = jsonBlockstack1.data
                     let jsonBlockstack5 = '[]'
                     if (jsonBlockstack2 !== null){
                        const jsonBlockstack3 = jsonBlockstack2.dataobject
                        const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                        if (jsonBlockstack4.substring(0,1) === '"') {
                           jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                        } else {
                           jsonBlockstack5 = jsonBlockstack4
                        }
                        let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                        jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                        const {workorderfechainiciopozo,workorderfechaterminoperforacion,workorderfechaterminosondaje} = jsonBlockstack4d

                        if (workorderfechainiciopozo !== undefined && workorderfechainiciopozo !== '' && workorderfechainiciopozo !== null){
                           const fechaHoyX = new Date(workorderfechainiciopozo)
                           let diaX = fechaHoyX.getDate()
                           let mesX = fechaHoyX.getMonth()+1
                           let anioX = fechaHoyX.getFullYear()
                           let dia2X = 0
                           let mes2X = 0
                           if (diaX<10){dia2X = '0'+ diaX.toString()}else{ dia2X = diaX.toString()}
                           if (mesX<10){mes2X = '0'+ mesX.toString()}else{ mes2X = mesX.toString()}
                           const fecha_texto = anioX.toString()+'-'+mes2X+'-'+dia2X+'T13:00:00'
                           const fechaX = new Date(fecha_texto);
                           fechaX.setDate(fechaX.getDate() + 1);
                           this.setState({workorderfecharealinicio: fechaX})
                           this.setState({fechaReporte: fechaX})
                        }else {
                           const fechaHoyX = new Date()
                           let diaX = fechaHoyX.getDate()
                           let mesX = fechaHoyX.getMonth()+1
                           let anioX = fechaHoyX.getFullYear()
                           let dia2X = 0
                           let mes2X = 0
                           if (diaX<10){dia2X = '0'+ diaX.toString()}else{ dia2X = diaX.toString()}
                           if (mesX<10){mes2X = '0'+ mesX.toString()}else{ mes2X = mesX.toString()}
                           const fecha_texto = anioX.toString()+'-'+mes2X+'-'+dia2X+'T13:00:00'
                           const fechaX = new Date(fecha_texto);
                           this.setState({workorderfecharealinicio: fechaX})
                           this.setState({fechaReporte: fechaX})
                        }

                        if (workorderfechaterminosondaje !== undefined && workorderfechaterminosondaje !== '' && workorderfechaterminosondaje !== null){
                            const fechaHoyX = new Date(workorderfechaterminosondaje)
                            let diaX = fechaHoyX.getDate()
                            let mesX = fechaHoyX.getMonth()+1
                            let anioX = fechaHoyX.getFullYear()
                            let dia2X = 0
                            let mes2X = 0
                            if (diaX<10){dia2X = '0'+ diaX.toString()}else{ dia2X = diaX.toString()}
                            if (mesX<10){mes2X = '0'+ mesX.toString()}else{ mes2X = mesX.toString()}
                            let fecha_texto = anioX.toString()+'-'+mes2X+'-'+dia2X+'T13:00:00'
                            const fechaX = new Date(fecha_texto);
                            this.setState({workorderfechaterminoperforacion:fechaX })
                        }else {
                            const fechaHoyX = new Date()
                            let diaX = fechaHoyX.getDate()
                            let mesX = fechaHoyX.getMonth()+1
                            let anioX = fechaHoyX.getFullYear()
                            let dia2X = 0
                            let mes2X = 0
                            if (diaX<10){dia2X = '0'+ diaX.toString()}else{ dia2X = diaX.toString()}
                            if (mesX<10){mes2X = '0'+ mesX.toString()}else{ mes2X = mesX.toString()}
                            let fecha_texto = anioX.toString()+'-'+mes2X+'-'+dia2X+'T13:00:00'
                            const fechaX = new Date(fecha_texto);
                            fechaX.setDate(fechaX.getDate());
                            this.setState({workorderfechaterminoperforacion:fechaX })
                        }
                        resolve1()
                    }else{
                      reject1()
                    }
                 } else {
                   reject1()
                 }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    cierreReporteTurno = (fechaX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `${this.props.typeContract}_${this.props.numberContract}_${fechaX}_cierre_turno.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({cierreReporteTurnoX:jsonBlockstack4d})
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    goAuthorizersNames = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin,`${this.props.typeContract}_${this.props.numberContract}_authorizer_deliverable.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    Promise.all([this.completaNameAndAvatar(jsonBlockstack6),
                                this.goRolesNames(networkUrlX,jsonBlockstack6),
                                this.goObservacionesNames(networkUrlX,jsonBlockstack6),
                               ]).then((resolve) =>{resolve1()},(reject) =>{resolve1()})
                 } else {
                   reject1()
                 }
               } else {
                 reject1()
               }
             })
            .catch(error => {
              reject1()
            });
        })
    }

    completaNameAndAvatar = async (jsonUserGroup) => {
      const salida = []
      const resultado = await Promise.all(jsonUserGroup.map( async (todo) => {
         let userX = todo.id
         return await Promise.all([this.readRemoteNameAvatar(userX)])
           .then(
             (resolve) =>{
                 let emailRX = todo.email
                 if (todo.email === null || todo.email === '' || todo.email === undefined){
                   emailRX = resolve[0].emailRX
                 }
                 salida.push({codeFormsDeliverable:todo.codeFormsDeliverable, active:todo.active, email:emailRX, id:todo.id, identityAddress:todo.identityAddress, publicKey:todo.publicKey, conceptFormsDeliverable:todo.conceptFormsDeliverable, role:todo.role, signature:todo.signature, statusInvite:todo.statusInvite, name:resolve[0].nameX, avatar:resolve[0].avatarX})
             },
             (reject)  =>{
                 salida.push({codeFormsDeliverable:todo.codeFormsDeliverable, active:todo.active, email:todo.email, id:todo.id, identityAddress:todo.identityAddress, publicKey:todo.publicKey, conceptFormsDeliverable:todo.conceptFormsDeliverable, role:todo.role, signature:todo.signature, statusInvite:todo.statusInvite, name:userX, avatar:''})
             }
           )
      }))
      this.setState({authorizersX: salida},()=>{})
      salida.map((todo,i) => {
        if (todo.conceptFormsDeliverable === 'ReporteTurno'){
          if (todo.id === this.props.username){
            this.setState({authIdX:todo.id,authNameX:todo.name,authRoleX:todo.role,authPublicKey:todo.publicKey})
          }
        }
      })
    }

    readRemoteNameAvatar = (userX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      return new Promise ((resolve1, reject1) =>{
          getFileDB(userX,`myUserConfig.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    let nameX2 = jsonBlockstack6.profileName
                    if (jsonBlockstack6.profileName === '' || jsonBlockstack6.profileName === undefined || jsonBlockstack6.profileName === null){
                      nameX2 = userX
                    }
                    resolve1({nameX: nameX2, avatarX: jsonBlockstack6.profileAvatar, emailRX: jsonBlockstack6.emailNotifications})
                 } else {
                   reject1()
                 }
               } else {
                 reject1()
               }
             })
            .catch(error => {
              reject1()
            });
        })
    }

    goRolesNames = (networkUrlX,authorizersX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin,`role_deliverable.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    this.setState({roleX:jsonBlockstack6 })
                    authorizersX.map((todoauth,i) => {
                      if (todoauth.conceptFormsDeliverable === 'ReporteTurno'){
                        if (todoauth.id === this.props.username){
                        jsonBlockstack6.map((todorole,x) => {
                            if (todoauth.role === todorole.code){
                              this.setState({roleGenerateX:todorole.generate,roleModifyX:todorole.modify,roleSignatureX:todorole.signature,roleCloseX:todorole.close,roleVisualizeX:todorole.visualize,roleLevelX:todorole.level})
                            }
                          })
                        }
                      }
                    })
                    resolve1()
                 } else {
                   reject1()
                 }
               } else {
                 reject1()
               }
             })
            .catch(error => {
              reject1()
            });
        })
    }

    goObservacionesNames = (networkUrlX,authorizersX) => {
    }

    goActividadesNames = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `actividades.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({actividadesX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    goTipo_PerforacionNames = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `tipo_perforacion.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({tipoperforacionX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    goHerramientaNames = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `tipo_perforacion.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({herramientaX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
       })
    }

    goPerforacionNames = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `perforacion.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({perforacionX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    goTipoMuestraNames = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `tipo_muestra.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({tipomuestraX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    getSobrecargaNatural = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `sobrecarga_natural.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({sobrecarganaturalX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    getSobrecargaNoNatural = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `sobrecarga_no_natural.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({sobrecarganonaturalX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    getRoca = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `roca.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({rocaX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    getRocaMuestraSeca = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `roca_muestra_seca.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({rocaMuestraSecaX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    getRocaMuestraHumeda = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `roca_muestra_humeda.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({rocaMuestraHumedaX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    getRocaMuestraHumedaMartillo = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `roca_muestra_humeda_martillo.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({rocaMuestraHumedaMartilloX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    getEstadoPozo = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `estado_pozo.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({estadopozoX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    getEstadoSondaje = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `estado_sondaje.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({estadosondajeX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    getDiametroOcupado = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `diametro_ocupado_kpi.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({diametroocupadoX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }


    getMonitoreo = (networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.props.userOrigin, `pozo_monitoreo.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.setState({monitoreoX:jsonBlockstack4d })
                    resolve1()
                  } else {
                    reject1()
                  }
                } else {
                 reject1()
                }
             })
            .catch(error => {
              reject1()
            });
      })
    }

    displayFolioFecha = () => {
      return (
        <div>
          <Table borderless size="sm">
            <tbody>
              <tr>
                <th style={{ width: "80%", textAlign: 'left'}}>
                  <div style={{ color:'#3a3e41', textAlign: 'left', fontSize:16}} >
                    &nbsp;&nbsp;{this.state.authNameX}
                    <br></br>
                    &nbsp;&nbsp;{this.state.authRoleX}
                  </div>
                </th>
                <th style={{ width: "10%", color:'#3a3e41', textAlign: 'center', fontSize:16}}>
                  <FormattedMessage id="reporteturno.fecha" />
                </th>
                <th style={{ width: "10%", color:'#3a3e41', textAlign: 'center', fontSize:16}}>
                  <FormattedMessage id="reporteturno.folio" />
                </th>
              </tr>
              <tr>
                <th style={{ width: "80%"}}> </th>
                {this.state.displayLoader ?
                  <th style={{ width: "10%", color:'#000080', textAlign: 'center', fontSize:15}}><ContractLoader colorDark={false}/></th>
                :
                  <th style={{ width: "10%", backgroundColor:'orange', color:'darkblue', textAlign: 'center', fontSize:15}}>
                      <DatePicker
                        id="fechaReporte"
                        dateFormat={this.state.dateFormatX}
                        innerRef={this.fechaReporte}
                        disabled={false}
                        locale={es} utils={DateFnsUtils}
                        placeholder=""
                        selected={this.state.fechaReporte}
                        onSelect={val=>this.handleDate(val,'fechaReporte',2)}
                        startDate={this.state.workorderfecharealinicio}
                        endDate={this.state.workorderfechaterminoperforacion}
                        dropdownMode="select"
                      />
                  </th>
                }
                <th style={{ width: "10%", color:'#000080', textAlign: 'center', fontSize:16}}>
                  <FormInput
                    size="sm"
                    id="folio"
                    innerRef={this.state.folio}
                    placeholder={this.state.folio}
                    disabled={true}
                  />
                </th>
              </tr>
            </tbody>
          </Table>
        </div>
      )
    }

    displayCabecera = () => {
      return (
        <div>
          <Table size="sm" borderless responsive >
            <thead>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "10%" }}>Cliente</th>
                <th style={{ width: "8%" }}>Orden Trabajo</th>
                <th style={{ width: "8%" }}>Número Contrato</th>
                <th style={{ width: "10%" }}>Campaña</th>
                <th style={{ width: "8%" }}>Fase</th>
                <th style={{ width: "8%" }}>Recomendado</th>
                <th style={{ width: "10%" }}>Tipo Perforación</th>
                <th style={{ width: "8%" }}>Sondaje</th>
                <th style={{ width: "8%" }}>Inclinación</th>
                <th style={{ width: "6%" }}></th>
                <th style={{ width: "6%" }}></th>
                <th style={{ width: "6%" }}></th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ color:'#000080', textAlign: 'left', fontSize:9}} >
                <th style={{ width: "10%"}}>
                  <FormInput
                    size="sm"
                    id="cliente"
                    placeholder={this.state.workordercliente}
                    disabled={true}
                  />
                </th>
                <th style={{ width: "8%"}}>
                  <FormInput
                    size="sm"
                    id="ordentrabajo"
                    placeholder={this.props.numberContract}
                    disabled={true}
                  />
                </th>
                <th style={{ width: "8%"}}>
                  <FormInput
                    size="sm"
                    id="contrato"
                    placeholder={this.state.workordernumerocontrato}
                    disabled={true}
                  />
                </th>
                <th style={{ width: "10%"}}>
                  <FormInput
                    size="sm"
                    id="campaña"
                    placeholder={this.state.workordercampaña}
                    disabled={true}
                  />
                </th>
                <th style={{ width: "8%"}}>
                  <FormInput
                    size="sm"
                    id="fase"
                    placeholder={this.state.workorderfase}
                    disabled={true}
                  />
                </th>
                <th style={{ width: "8%"}}>
                  <FormInput
                    size="sm"
                    id="recomendado"
                    placeholder={this.state.workorderrecomendado}
                    disabled={true}
                  />
                </th>
                <th style={{ width: "10%"}}>
                  <FormInput
                    size="sm"
                    id="perforacion"
                    placeholder={this.state.workordertipoperforacion}
                    disabled={true}
                  />
                </th>
                <th style={{ width: "8%"}}>
                  <FormInput
                    size="sm"
                    id="sondaje"
                    placeholder={this.state.workordersondaje}
                    disabled={true}
                  />
                </th>
                <th style={{ width: "8%"}}>
                  <FormInput
                    size="sm"
                    id="declinacion"
                    placeholder={this.state.workorderdeclinacion}
                    disabled={true}
                  />
                </th>
                <th style={{ width: "6%", textAlign: 'center'}} onMouseEnter={()=>this.onMouseEnterHandler(1,'cabecera')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'cabecera')}>
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.pdf(e)}>
                     {this.state.cabecera_1 ?
                       <img src="images/reporteturno02.png" weight="75" height="75" alt=""/>
                     :
                       <img src="images/reporteturno02.png" weight="60" height="60" alt=""/>
                     }
                   </strong>
                </th>
                <th style={{ width: "6%", textAlign: 'center'}} onMouseEnter={()=>this.onMouseEnterHandler(2,'cabecera')} onMouseLeave={()=>this.onMouseLeaveHandler(2,'cabecera')}>
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.grafico(e)}>
                     {this.state.cabecera_2 ?
                       <img src="images/reporteturno03.png" weight="45" height="65" alt=""/>
                     :
                       <img src="images/reporteturno03.png" weight="30" height="50" alt=""/>
                     }
                   </strong>
                </th>
                <th style={{ width: "6%", textAlign: 'center'}} onMouseEnter={()=>this.onMouseEnterHandler(3,'cabecera')} onMouseLeave={()=>this.onMouseLeaveHandler(3,'cabecera')}>
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.costo(e)}>
                     {this.state.cabecera_3 ?
                       <img src="images/reporteturno01.png" weight="65" height="65" alt=""/>
                     :
                       <img src="images/reporteturno01.png" weight="50" height="50" alt=""/>
                     }
                   </strong>
                </th>
              </tr>
            </tbody>
          </Table>
        </div>

      )
    }

    displayConsolidado = () => {
      let disabledX = false
      if (this.state.authRoleX === 'Supervisor MD'){
        if (this.state.cReporteTurnoSignatureFirmante1 === true){
           disabledX = true
        }
      }
      if (this.state.authRoleX === 'Adm MD'){
        if (this.state.cReporteTurnoSignatureFirmante3 === true){
           disabledX = true
        }
      }
      if (this.state.authRoleX === 'Jefe Turno MD'){
        if (this.state.cReporteTurnoSignatureFirmante6 === true){
           disabledX = true
        }
      }
      if (this.state.cReporteTurnoSignatureFirmante1 === true ||
          this.state.cReporteTurnoSignatureFirmante1 === true ||
          this.state.cReporteTurnoSignatureFirmante1 === true ||
          this.state.cReporteTurnoSignatureFirmante1 === true ||
          this.state.cReporteTurnoSignatureFirmante1 === true ||
          this.state.cReporteTurnoSignatureFirmante1 === true ||
          this.state.cReporteTurnoSignatureFirmante1 === true){
          disabledX = true
      }

      if (this.state.authRoleX !== 'Supervisor MD' && this.state.authRoleX !== 'Adm MD' && this.state.authRoleX !== 'Jefe Turno MD'){
        disabledX = true
      }

      let consolidadoX = this.state.consolidadoX
      if (isNaN(this.state.consolidadoX)) {
        consolidadoX = 0
      }

      let consolidadoFondoX2 = 0
      if (this.state.bFondoPozoExiste) {
        if (parseFloat(this.state.consolidadoFondoOrigenDiaLlenaX) > parseFloat(this.state.consolidadoFondoOrigenNocheLlenaX)){
          consolidadoFondoX2 = parseFloat(this.state.consolidadoFondoOrigenDiaLlenaX)
        }else{
          consolidadoFondoX2 = parseFloat(this.state.consolidadoFondoOrigenNocheLlenaX)
        }
      }else{
        consolidadoFondoX2 = parseFloat(consolidadoX) + parseFloat(this.state.consolidadoFondoOrigenDiaX) + parseFloat(this.state.consolidadoFondoOrigenNocheX)
      }


      return (
        <div>
          <Table size="sm" borderless responsive >
            <thead>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "53%" }}></th>
                <th style={{ width: "21%", textAlign: 'right', fontSize:20 }}>
                  MTS. TURNO
                </th>
                <th style={{ width: "10%" }}>
                  <FormInput
                    size="sm"
                    id="consolidado turno"
                    placeholder={consolidadoX}
                    disabled={true}
                  />
                </th>
                <th style={{ textAlign: 'right', width: "12%"}}>
                  {this.state.starConsolidado1 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                  {this.state.starConsolidado2 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                  {this.state.starConsolidado3 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                  {this.state.starConsolidado4 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                  {this.state.starConsolidado5 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                </th>
                <th style={{ textAlign: 'left', width: "4%"}}>
                  {this.state.starConsolidado5 ? <img src="images/reporteturno06.png" weight="40" height="35" alt=""/> : null }
                </th>
              </tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "53%" }}></th>
                <th style={{ width: "21%", textAlign: 'right', fontSize:20 }}>
                  MTS. FONDO
                </th>
                <th style={{ width: "10%" }}>
                  <FormInput
                    size="sm"
                    id="consolidado fondo"
                    placeholder={consolidadoFondoX2}
                    disabled={true}
                  />
                </th>
                <th style={{ textAlign: 'right', width: "12%"}}>
                </th>
                <th style={{ textAlign: 'left', width: "4%"}}>
                </th>
              </tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "53%" }}></th>
                <th style={{ width: "21%", textAlign: 'right', fontSize:20 }}>
                  ESTADO SONDAJE
                </th>
                <th style={{ width: "10%" }}>
                  <FormSelect
                    id="estado pozo"
                    innerRef={this.cReporteTurnoEstadoSondaje}
                    disabled={disabledX}
                  >
                    {this.state.estadosondajeX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{todo.description}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ textAlign: 'right', width: "12%"}}>
                </th>
                <th style={{ textAlign: 'left', width: "4%"}}>
                </th>
              </tr>
            </thead>
          </Table>
        </div>
      )
    }

    displayCabeceraTurnoDia = () => {
      let disabledX = false
      if (this.state.authRoleX === 'Supervisor MD'){
        if (this.state.cReporteTurnoSignatureFirmante1 === true){
           disabledX = true
        }
      }
      if (this.state.authRoleX === 'Adm MD'){
        if (this.state.cReporteTurnoSignatureFirmante3 === true){
           disabledX = true
        }
      }
      if (this.state.authRoleX === 'Jefe Turno MD'){
        if (this.state.cReporteTurnoSignatureFirmante6 === true){
           disabledX = true
        }
      }
      if (this.state.authRoleX !== 'Supervisor MD' && this.state.authRoleX !== 'Adm MD' && this.state.authRoleX !== 'Jefe Turno MD'){
        disabledX = true
      }

      if (this.state.cReporteTurnoSignatureFirmante1 === true ||
          this.state.cReporteTurnoSignatureFirmante3 === true ||
          this.state.cReporteTurnoSignatureFirmante4 === true ||
          this.state.cReporteTurnoSignatureFirmante5 === true ||
          this.state.cReporteTurnoSignatureFirmante6 === true){
          disabledX = true
      }

      if (this.props.active_after_publishedX === 'false'){
        disabledX = true
      }

      let bObservacion = false
      if (this.state.cReporteTurnoObservacionAsesorTurnoDia !== '' && this.state.cReporteTurnoObservacionAsesorTurnoDia !== null && this.state.cReporteTurnoObservacionAsesorTurnoDia !== undefined){
          bObservacion = true
      }

      return (
        <div>
          <Table size="sm" borderless responsive >
            <thead>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11 }} >
                <th style={{ width: "18%" }}>SUPERVISOR</th>
                <th style={{ width: "18%" }}>JEFE TURNO</th>
                <th style={{ width: "18%" }} onMouseEnter={()=>this.onMouseEnterHandler(6,'observacion')} onMouseLeave={()=>this.onMouseLeaveHandler(6,'observacion')}>
                   ASESOR HSE
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.observacion(e,6)}>
                     {this.state.observacion_6 ?
                       <>
                       {bObservacion ?
                           <img src="images/eyeGreen2.png" weight="30" height="30" alt=""/>
                       :
                           <img src="images/eyeGreen2.png" weight="30" height="30" alt=""/>
                       }
                       </>
                     :
                       <>
                       {bObservacion ?
                           <img src="images/eyeOrange.png" weight="30" height="30" alt=""/>
                       :
                           <img src="images/eyeGrey.png" weight="30" height="30" alt=""/>
                       }
                       </>
                     }
                   </strong>
                </th>
                <th style={{ width: "6%" }}></th>
                <th style={{ width: "6%" }}>DESDE</th>
                <th style={{ width: "6%" }}>HASTA</th>
                <th style={{ width: "6%" }}></th>
                <th style={{ width: "6%" }}></th>
                <th style={{ width: "12%" }}></th>
                <th style={{ width: "4%" }}></th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "18%"}}>
                  <FormSelect
                    id="supervisor"
                    innerRef={this.cReporteTurnoDia04}
                    placeholder={this.cReporteTurnoDia04}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.authorizersX.map((todo, key) => {
                      if (todo.conceptFormsDeliverable === 'ReporteTurno'){
                        if (todo.role === "Supervisor MD"){
                          return (
                            <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.id} disabled={false} value={`${todo.id}&&&${todo.name}`} >{todo.name}</option>
                          )
                        }
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "18%"}}>
                  <FormSelect
                    id="jefe turno"
                    innerRef={this.cReporteTurnoDia01}
                    placeholder={this.cReporteTurnoDia01}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.authorizersX.map((todo, key) => {
                      if (todo.conceptFormsDeliverable === 'ReporteTurno'){
                        if (todo.role === "Jefe Turno MD"){
                          return (
                           <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.id} disabled={false} value={`${todo.id}&&&${todo.name}`} >{todo.name}</option>
                          )
                        }
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "18%"}}>
                  <FormSelect
                    id="asesor"
                    innerRef={this.cReporteTurnoDia05}
                    placeholder={this.cReporteTurnoDia05}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.authorizersX.map((todo, key) => {
                      if (todo.conceptFormsDeliverable === 'ReporteTurno'){
                        if (todo.role === "Asesor HSE MD"){
                          return (
                            <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.id} disabled={false} value={`${todo.id}&&&${todo.name}`} >{todo.name}</option>
                          )
                        }
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "6%"}}>
                  MTS TURNO
                </th>
                <th style={{ width: "6%"}}>
                  <FormInput
                    size="sm"
                    id="mts turno desde"
                    innerRef={this.cReporteTurnoDia06}
                    placeholder=""
                    disabled={true}
                  />
                </th>
                <th style={{ width: "6%"}}>
                  <FormInput
                    size="sm"
                    id="mts turno hasta"
                    innerRef={this.cReporteTurnoDia07}
                    placeholder=""
                    disabled={true}
                  />
                </th>
                <th style={{ width: "6%"}}>
                  PERFORADOS
                </th>
                <th style={{ width: "6%"}}>
                  <FormInput
                    size="sm"
                    id="mts perforados"
                    innerRef={this.cReporteTurnoDia08}
                    onChange={e=>this.handlePerforados(e,1)}
                    placeholder=""
                    disabled={true}
                  />
                </th>
                <th style={{ textAlign: 'right', width: "12%"}}>
                  {this.state.starDia1 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                  {this.state.starDia2 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                  {this.state.starDia3 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                  {this.state.starDia4 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                  {this.state.starDia5 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                </th>
                <th style={{ textAlign: 'left', width: "4%"}}>
                  {this.state.starDia5 ? <img src="images/reporteturno06.png" weight="40" height="35" alt=""/> : null }
                </th>
              </tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "18%" }}>OPERADOR</th>
                <th style={{ width: "18%" }}>AYUDANTE 1</th>
                <th style={{ width: "18%" }}>AYUDANTE 2</th>
                <th style={{ width: "6%"}}></th>
                <th style={{ width: "6%"}}>DESDE</th>
                <th style={{ width: "6%"}}>HASTA</th>
                <th style={{ width: "6%"}}></th>
                <th style={{ width: "6%"}}></th>
                <th style={{ width: "8%"}}>ESTADO POZO</th>
                <th></th>
                <th></th>
              </tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "18%"}}>
                  <FormSelect
                    id="operador"
                    innerRef={this.cReporteTurnoDia12}
                    placeholder={this.cReporteTurnoDia12}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.authorizersX.map((todo, key) => {
                      if (todo.conceptFormsDeliverable === 'ReporteTurno'){
                        if (todo.role === "Operador MD"){
                          return (
                           <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.id} disabled={false} value={`${todo.id}&&&${todo.name}`} >{todo.name}</option>
                          )
                        }
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "18%"}}>
                  <FormInput
                    size="sm"
                    id="ayudante 1"
                    innerRef={this.cReporteTurnoDia02}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "18%"}}>
                  <FormInput
                    size="sm"
                    id="ayudante 2"
                    innerRef={this.cReporteTurnoDia03}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "6%"}}>
                  HOROMETRO
                </th>
                <th style={{ width: "6%"}}>
                  <FormInput
                    size="sm"
                    id="horometro desde"
                    innerRef={this.cReporteTurnoDia09}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "6%"}}>
                  <FormInput
                    size="sm"
                    id="horometro hasta"
                    innerRef={this.cReporteTurnoDia10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "6%"}}>
                  META
                </th>
                <th style={{ width: "6%"}}>
                  <FormInput
                    size="sm"
                    id="mts meta"
                    innerRef={this.cReporteTurnoDia11}
                    onChange={e=>this.handlePerforados(e,1)}
                    placeholder={this.state.workordermetaturnodia}
                    disabled={true}
                  />
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="estado pozo"
                    innerRef={this.cReporteTurnoDia13}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.estadopozoX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{todo.description}</option>
                        )
                    })}
                  </FormSelect>
                </th>
              </tr>
            </tbody>
          </Table>
        </div>
      )
    }

    displayDetalleTurnoDia = () => {
      let disabledX = true
      if (this.state.roleGenerateX || this.state.roleModifyX){
        disabledX = false
      }
      if (this.state.authRoleX === 'Supervisor MD'){
        if (this.state.cReporteTurnoSignatureFirmante1 === true){
           disabledX = true
        }
      }
      if (this.state.authRoleX === 'Adm MD'){
        if (this.state.cReporteTurnoSignatureFirmante3 === true){
           disabledX = true
        }
      }
      if (this.state.authRoleX === 'Jefe Turno MD'){
        if (this.state.cReporteTurnoSignatureFirmante6 === true){
           disabledX = true
        }
      }
      if (this.state.authRoleX !== 'Supervisor MD' && this.state.authRoleX !== 'Adm MD' && this.state.authRoleX !== 'Jefe Turno MD'){
        disabledX = true
      }
      if (this.state.cReporteTurnoSignatureFirmante1 === true ||
          this.state.cReporteTurnoSignatureFirmante3 === true ||
          this.state.cReporteTurnoSignatureFirmante4 === true ||
          this.state.cReporteTurnoSignatureFirmante5 === true ||
          this.state.cReporteTurnoSignatureFirmante6 === true){
          disabledX = true
      }

      if (this.props.active_after_publishedX === 'false'){
        disabledX = true
      }

      let disabled1X = this.state.displayDetalle1X
      let disabled2X = this.state.displayDetalle2X
      let disabled3X = this.state.displayDetalle3X
      let disabled4X = this.state.displayDetalle4X
      let disabled5X = this.state.displayDetalle5X
      let disabled6X = this.state.displayDetalle6X
      let disabled7X = this.state.displayDetalle7X
      let disabled8X = this.state.displayDetalle8X
      let disabled9X = this.state.displayDetalle9X
      let disabled10X = this.state.displayDetalle10X
      let disabled11X = this.state.displayDetalle11X
      let disabled12X = this.state.displayDetalle12X
      let disabled13X = this.state.displayDetalle13X
      let disabled14X = this.state.displayDetalle14X
      let disabled15X = this.state.displayDetalle15X
      let disabled16X = this.state.displayDetalle16X
      let disabled17X = this.state.displayDetalle17X
      let disabled18X = this.state.displayDetalle18X
      let disabled19X = this.state.displayDetalle19X
      let disabled20X = this.state.displayDetalle20X
      let disabled21X = this.state.displayDetalle21X
      let disabled22X = this.state.displayDetalle22X
      let disabled23X = this.state.displayDetalle23X
      let disabled24X = this.state.displayDetalle24X
      if (disabledX === true){disabled1X=true;disabled2X=true;disabled3X=true;disabled4X=true;disabled5X=true;disabled6X=true;disabled7X=true;disabled8X=true;disabled9X=true;disabled10X=true}
      if (disabledX === true){disabled11X=true;disabled12X=true;disabled13X=true;disabled14X=true;disabled15X=true;disabled16X=true;disabled17X=true;disabled18X=true;disabled19X=true;disabled20X=true}
      if (disabledX === true){disabled21X=true;disabled22X=true;disabled23X=true;disabled24X=true}

      return (
        <div>
          <Table size="sm" borderless responsive hover>
            <thead>
              <tr style={{ backgroundColor:'#ceca9e', color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "3%" }}>Horario</th>
                <th style={{ width: "14%" }}>Actividad</th>
                <th style={{ width: "10%" }}>Perforación</th>
                <th style={{ width: "7%" }}>Condición Humedad</th>
                <th style={{ width: "7%" }}>Acero Perforación</th>
                <th style={{ width: "10%" }}>Herramientas</th>
                <th style={{ width: "8%" }}>Diámetro Ocupado</th>
                <th style={{ width: "7%" }}>Diám. Ocup. Núm. Serie</th>
                <th style={{ width: "7%" }}>Mts. Inicio</th>
                <th style={{ width: "7%" }}>Mts. Fin</th>
                <th style={{ width: "11%" }}>Aclaraciones</th>
                <th style={{ width: "4%" }}>HH MD</th>
                <th style={{ width: "4%" }}>HH AA</th>
                <th style={{ width: "1%" }}></th>
              </tr>
            </thead>
            <tbody>
              {this.state.linea01X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", extAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   08:00 a 08:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia08a09_01}
                    onChange={e=>this.handleActividades(e,1)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia08a09_02}
                    disabled={disabled1X}
                    onChange={e=>this.handlePerforacion(e,1,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia08a09_03}
                    disabled={disabled1X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia08a09_06}
                    onChange={e=>this.handleAceroPerforacion(e,1,1)}
                    disabled={disabled1X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia08a09_04}
                    onChange={e=>this.handleHerramienta(e,1,1)}
                    disabled={disabled1X}
                  >
                    {this.state.sobrecargaX1.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia08a09_05}
                    disabled={disabled1X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX1 && todo.tramo === this.state.herramientaCodeX1 ){
                          return (
                             <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                          )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia08a09_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled1X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia08a09_08}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 1)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled1X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia08a09_09}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 1)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled1X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia08a09_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia08a09_11}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 1)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia08a09_12}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 1)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,1) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia08a09_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia08a09_01} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_02} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_03} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_04} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_05} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_06} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_07} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_08} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_09} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_10} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_11} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_12} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_13} />
              </>
              }
              {this.state.linea02X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", extAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   08:30 a 09:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia08a09_01_2}
                    onChange={e=>this.handleActividades(e,2)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia08a09_02_2}
                    disabled={disabled2X}
                    onChange={e=>this.handlePerforacion(e,101,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia08a09_03_2}
                    disabled={disabled2X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia08a09_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,101,1)}
                    disabled={disabled2X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia08a09_04_2}
                    onChange={e=>this.handleHerramienta(e,101,1)}
                    disabled={disabled2X}
                  >
                    {this.state.sobrecargaX2.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia08a09_05_2}
                    disabled={disabled2X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX2 && todo.tramo === this.state.herramientaCodeX2 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia08a09_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled2X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia08a09_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 2)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled2X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia08a09_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 2)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled2X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia08a09_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia08a09_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 101)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia08a09_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 101)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,2) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia08a09_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia08a09_01_2} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_02_2} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_03_2} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_04_2} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_05_2} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_06_2} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_07_2} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_08_2} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_09_2} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_10_2} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_11_2} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_12_2} />
                <input type="hidden" ref={this.cReporteTurnoDia08a09_13_2} />
              </>
              }
              {this.state.linea03X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   09:00 a 09:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia09a10_01}
                    onChange={e=>this.handleActividades(e,3)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia09a10_02}
                    disabled={disabled3X}
                    onChange={e=>this.handlePerforacion(e,2,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia09a10_03}
                    disabled={disabled3X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia09a10_06}
                    onChange={e=>this.handleAceroPerforacion(e,2,1)}
                    disabled={disabled3X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia09a10_04}
                    onChange={e=>this.handleHerramienta(e,2,1)}
                    disabled={disabled3X}
                  >
                    {this.state.sobrecargaX3.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia09a10_05}
                    disabled={disabled3X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX3 && todo.tramo === this.state.herramientaCodeX3 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia09a10_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled3X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia09a10_08}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 3)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled3X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia09a10_09}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 3)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled3X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia09a10_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia09a10_11}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 2)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia09a10_12}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 2)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,3) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia09a10_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia09a10_01} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_02} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_03} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_04} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_05} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_06} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_07} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_08} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_09} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_10} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_11} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_12} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_13} />
              </>
              }
              {this.state.linea04X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   09:30 a 10:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia09a10_01_2}
                    onChange={e=>this.handleActividades(e,4)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia09a10_02_2}
                    disabled={disabled4X}
                    onChange={e=>this.handlePerforacion(e,102,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia09a10_03_2}
                    disabled={disabled4X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia09a10_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,102,1)}
                    disabled={disabled4X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia09a10_04_2}
                    onChange={e=>this.handleHerramienta(e,102,1)}
                    disabled={disabled4X}
                  >
                    {this.state.sobrecargaX4.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia09a10_05_2}
                    disabled={disabled4X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX4 && todo.tramo === this.state.herramientaCodeX4 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia09a10_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled4X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia09a10_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 4)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled4X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia09a10_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 4)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled4X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia09a10_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia09a10_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 102)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia09a10_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 102)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,4) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia09a10_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia09a10_01_2} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_02_2} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_03_2} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_04_2} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_05_2} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_06_2} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_07_2} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_08_2} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_09_2} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_10_2} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_11_2} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_12_2} />
                <input type="hidden" ref={this.cReporteTurnoDia09a10_13_2} />
              </>
              }
              {this.state.linea05X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   10:00 a 10:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia10a11_01}
                    onChange={e=>this.handleActividades(e,5)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia10a11_02}
                    disabled={disabled5X}
                    onChange={e=>this.handlePerforacion(e,3,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia10a11_03}
                    disabled={disabled5X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia10a11_06}
                    onChange={e=>this.handleAceroPerforacion(e,3,1)}
                    disabled={disabled5X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia10a11_04}
                    onChange={e=>this.handleHerramienta(e,3,1)}
                    disabled={disabled5X}
                  >
                    {this.state.sobrecargaX5.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia10a11_05}
                    disabled={disabled5X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX5 && todo.tramo === this.state.herramientaCodeX5 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia10a11_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled5X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia10a11_08}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 5)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled5X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia10a11_09}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 5)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled5X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia10a11_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia10a11_11}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 3)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia10a11_12}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 3)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,5) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia10a11_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia10a11_01} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_02} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_03} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_04} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_05} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_06} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_07} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_08} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_09} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_10} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_11} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_12} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_13} />
              </>
              }
              {this.state.linea06X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   10:30 a 11:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia10a11_01_2}
                    onChange={e=>this.handleActividades(e,6)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia10a11_02_2}
                    disabled={disabled6X}
                    onChange={e=>this.handlePerforacion(e,103,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia10a11_03_2}
                    disabled={disabled6X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia10a11_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,103,1)}
                    disabled={disabled6X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia10a11_04_2}
                    onChange={e=>this.handleHerramienta(e,103,1)}
                    disabled={disabled6X}
                  >
                    {this.state.sobrecargaX6.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia10a11_05_2}
                    disabled={disabled6X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX6 && todo.tramo === this.state.herramientaCodeX6 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia10a11_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled6X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia10a11_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 6)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled6X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia10a11_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 6)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled6X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia10a11_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia10a11_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 103)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia10a11_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 103)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,6) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia10a11_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia10a11_01_2} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_02_2} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_03_2} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_04_2} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_05_2} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_06_2} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_07_2} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_08_2} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_09_2} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_10_2} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_11_2} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_12_2} />
                <input type="hidden" ref={this.cReporteTurnoDia10a11_13_2} />
              </>
              }
              {this.state.linea07X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   11:00 a 11:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia11a12_01}
                    onChange={e=>this.handleActividades(e,7)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia11a12_02}
                    disabled={disabled7X}
                    onChange={e=>this.handlePerforacion(e,4,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia11a12_03}
                    disabled={disabled7X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia11a12_06}
                    onChange={e=>this.handleAceroPerforacion(e,4,1)}
                    disabled={disabled7X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia11a12_04}
                    onChange={e=>this.handleHerramienta(e,4,1)}
                    disabled={disabled7X}
                  >
                    {this.state.sobrecargaX7.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia11a12_05}
                    disabled={disabled7X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX7 && todo.tramo === this.state.herramientaCodeX7 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia11a12_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled7X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia11a12_08}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 7)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled7X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia11a12_09}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 7)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled7X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia11a12_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia11a12_11}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 4)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia11a12_12}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 4)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,7) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia11a12_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia11a12_01} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_02} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_03} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_04} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_05} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_06} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_07} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_08} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_09} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_10} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_11} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_12} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_13} />
              </>
              }
              {this.state.linea08X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   11:30 a 12:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia11a12_01_2}
                    onChange={e=>this.handleActividades(e,8)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia11a12_02_2}
                    disabled={disabled8X}
                    onChange={e=>this.handlePerforacion(e,104,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia11a12_03_2}
                    disabled={disabled8X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia11a12_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,104,1)}
                    disabled={disabled8X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia11a12_04_2}
                    onChange={e=>this.handleHerramienta(e,104,1)}
                    disabled={disabled8X}
                  >
                    {this.state.sobrecargaX8.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia11a12_05_2}
                    disabled={disabled8X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX8 && todo.tramo === this.state.herramientaCodeX8 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia11a12_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled8X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia11a12_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 8)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled8X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia11a12_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 8)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled8X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia11a12_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia11a12_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 104)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia11a12_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 104)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,8) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia11a12_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia11a12_01_2} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_02_2} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_03_2} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_04_2} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_05_2} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_06_2} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_07_2} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_08_2} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_09_2} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_10_2} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_11_2} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_12_2} />
                <input type="hidden" ref={this.cReporteTurnoDia11a12_13_2} />
              </>
              }
              {this.state.linea09X ?
                <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   12:00 a 12:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia12a13_01}
                    onChange={e=>this.handleActividades(e,9)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia12a13_02}
                    disabled={disabled9X}
                    onChange={e=>this.handlePerforacion(e,5,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia12a13_03}
                    disabled={disabled9X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia12a13_06}
                    onChange={e=>this.handleAceroPerforacion(e,5,1)}
                    disabled={disabled9X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia12a13_04}
                    onChange={e=>this.handleHerramienta(e,5,1)}
                    disabled={disabled9X}
                  >
                    {this.state.sobrecargaX9.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia12a13_05}
                    disabled={disabled9X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX9 && todo.tramo === this.state.herramientaCodeX9 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia12a13_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled9X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia12a13_08}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 9)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled9X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia12a13_09}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 9)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled9X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia12a13_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia12a13_11}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 5)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia12a13_12}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 5)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,9) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia12a13_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia12a13_01} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_02} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_03} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_04} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_05} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_06} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_07} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_08} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_09} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_10} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_11} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_12} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_13} />
              </>
              }
              {this.state.linea10X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   12:30 a 13:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia12a13_01_2}
                    onChange={e=>this.handleActividades(e,10)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia12a13_02_2}
                    disabled={disabled10X}
                    onChange={e=>this.handlePerforacion(e,105,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia12a13_03_2}
                    disabled={disabled10X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia12a13_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,105,1)}
                    disabled={disabled10X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia12a13_04_2}
                    onChange={e=>this.handleHerramienta(e,105,1)}
                    disabled={disabled10X}
                  >
                    {this.state.sobrecargaX10.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia12a13_05_2}
                    disabled={disabled10X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX10 && todo.tramo === this.state.herramientaCodeX10 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia12a13_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled10X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia12a13_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 10)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled10X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia12a13_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 10)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled10X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia12a13_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia12a13_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 105)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia12a13_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 105)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,10) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia12a13_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia12a13_01_2} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_02_2} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_03_2} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_04_2} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_05_2} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_06_2} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_07_2} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_08_2} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_09_2} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_10_2} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_11_2} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_12_2} />
                <input type="hidden" ref={this.cReporteTurnoDia12a13_13_2} />
              </>
              }
              {this.state.linea11X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   13:00 a 13:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia13a14_01}
                    onChange={e=>this.handleActividades(e,11)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia13a14_02}
                    disabled={disabled11X}
                    onChange={e=>this.handlePerforacion(e,6,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia13a14_03}
                    disabled={disabled11X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia13a14_06}
                    onChange={e=>this.handleAceroPerforacion(e,6,1)}
                    disabled={disabled11X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia13a14_04}
                    onChange={e=>this.handleHerramienta(e,6,1)}
                    disabled={disabled11X}
                  >
                    {this.state.sobrecargaX11.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia13a14_05}
                    disabled={disabled11X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX11 && todo.tramo === this.state.herramientaCodeX11 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia13a14_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled11X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia13a14_08}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 11)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled11X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia13a14_09}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 11)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled11X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia13a14_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia13a14_11}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 6)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia13a14_12}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 6)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,11) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia13a14_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia13a14_01} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_02} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_03} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_04} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_05} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_06} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_07} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_08} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_09} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_10} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_11} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_12} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_13} />
              </>
              }
              {this.state.linea12X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   13:30 a 14:00
                </th>
                <th style={{ width: "13%"}}>
                <FormSelect
                  id="actividades"
                  style={{fontSize:11}}
                  innerRef={this.cReporteTurnoDia13a14_01_2}
                  onChange={e=>this.handleActividades(e,12)}
                  disabled={disabledX}
                >
                  <option value={``} >{``}</option>
                  {this.state.actividadesX.map((todo, key) => {
                      return (
                        <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                      )
                  })}
                </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia13a14_02_2}
                    disabled={disabled12X}
                    onChange={e=>this.handlePerforacion(e,106,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia13a14_03_2}
                    disabled={disabled12X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia13a14_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,106,1)}
                    disabled={disabled12X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia13a14_04_2}
                    onChange={e=>this.handleHerramienta(e,106,1)}
                    disabled={disabled12X}
                  >
                    {this.state.sobrecargaX12.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia13a14_05_2}
                    disabled={disabled12X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX12 && todo.tramo === this.state.herramientaCodeX12 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia13a14_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled12X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia13a14_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 12)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled12X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia13a14_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 12)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled12X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia13a14_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia13a14_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 106)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia13a14_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 106)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,12) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia13a14_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia13a14_01_2} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_02_2} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_03_2} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_04_2} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_05_2} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_06_2} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_07_2} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_08_2} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_09_2} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_10_2} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_11_2} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_12_2} />
                <input type="hidden" ref={this.cReporteTurnoDia13a14_13_2} />
              </>
              }
              {this.state.linea13X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   14:00 a 14:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia14a15_01}
                    onChange={e=>this.handleActividades(e,13)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia14a15_02}
                    disabled={disabled13X}
                    onChange={e=>this.handlePerforacion(e,7,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia14a15_03}
                    disabled={disabled13X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia14a15_06}
                    onChange={e=>this.handleAceroPerforacion(e,7,1)}
                    disabled={disabled13X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia14a15_04}
                    onChange={e=>this.handleHerramienta(e,7,1)}
                    disabled={disabled13X}
                  >
                    {this.state.sobrecargaX13.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia14a15_05}
                    disabled={disabled13X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX13 && todo.tramo === this.state.herramientaCodeX13 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia14a15_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled13X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia14a15_08}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 13)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled13X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia14a15_09}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 13)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled13X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia14a15_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia14a15_11}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 7)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia14a15_12}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 7)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,13) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia14a15_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia14a15_01} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_02} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_03} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_04} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_05} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_06} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_07} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_08} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_09} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_10} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_11} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_12} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_13} />
              </>
              }
              {this.state.linea14X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   14:30 a 15:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia14a15_01_2}
                    onChange={e=>this.handleActividades(e,14)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia14a15_02_2}
                    disabled={disabled14X}
                    onChange={e=>this.handlePerforacion(e,107,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia14a15_03_2}
                    disabled={disabled14X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia14a15_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,107,1)}
                    disabled={disabled14X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia14a15_04_2}
                    onChange={e=>this.handleHerramienta(e,107,1)}
                    disabled={disabled14X}
                  >
                    {this.state.sobrecargaX14.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia14a15_05_2}
                    disabled={disabled14X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX14 && todo.tramo === this.state.herramientaCodeX14 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia14a15_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled14X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia14a15_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 14)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled14X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia14a15_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 14)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled14X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia14a15_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia14a15_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 107)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia14a15_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 107)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,14) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia14a15_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia14a15_01_2} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_02_2} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_03_2} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_04_2} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_05_2} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_06_2} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_07_2} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_08_2} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_09_2} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_10_2} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_11_2} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_12_2} />
                <input type="hidden" ref={this.cReporteTurnoDia14a15_13_2} />
              </>
              }
              {this.state.linea15X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   15:00 a 15:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia15a16_01}
                    onChange={e=>this.handleActividades(e,15)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia15a16_02}
                    disabled={disabled15X}
                    onChange={e=>this.handlePerforacion(e,8,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia15a16_03}
                    disabled={disabled15X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia15a16_06}
                    onChange={e=>this.handleAceroPerforacion(e,8,1)}
                    disabled={disabled15X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia15a16_04}
                    onChange={e=>this.handleHerramienta(e,8,1)}
                    disabled={disabled15X}
                  >
                    {this.state.sobrecargaX15.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia15a16_05}
                    disabled={disabled15X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX15 && todo.tramo === this.state.herramientaCodeX15 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia15a16_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled15X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia15a16_08}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 15)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled15X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia15a16_09}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 15)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled15X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia15a16_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia15a16_11}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 8)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia15a16_12}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 8)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,15) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia15a16_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia15a16_01} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_02} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_03} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_04} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_05} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_06} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_07} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_08} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_09} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_10} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_11} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_12} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_13} />
              </>
              }
              {this.state.linea16X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   15:30 a 16:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia15a16_01_2}
                    onChange={e=>this.handleActividades(e,16)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia15a16_02_2}
                    disabled={disabled16X}
                    onChange={e=>this.handlePerforacion(e,108,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia15a16_03_2}
                    disabled={disabled16X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia15a16_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,108,1)}
                    disabled={disabled16X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia15a16_04_2}
                    onChange={e=>this.handleHerramienta(e,108,1)}
                    disabled={disabled16X}
                  >
                    {this.state.sobrecargaX16.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia15a16_05_2}
                    disabled={disabled16X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX16 && todo.tramo === this.state.herramientaCodeX16 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia15a16_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled16X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia15a16_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 16)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled16X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia15a16_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 16)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled16X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia15a16_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia15a16_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 108)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia15a16_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 108)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,16) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia15a16_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia15a16_01_2} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_02_2} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_03_2} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_04_2} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_05_2} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_06_2} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_07_2} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_08_2} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_09_2} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_10_2} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_11_2} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_12_2} />
                <input type="hidden" ref={this.cReporteTurnoDia15a16_13_2} />
              </>
              }
              {this.state.linea17X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   16:00 a 16:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia16a17_01}
                    onChange={e=>this.handleActividades(e,17)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia16a17_02}
                    disabled={disabled17X}
                    onChange={e=>this.handlePerforacion(e,9,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia16a17_03}
                    disabled={disabled17X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia16a17_06}
                    onChange={e=>this.handleAceroPerforacion(e,9,1)}
                    disabled={disabled17X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia16a17_04}
                    onChange={e=>this.handleHerramienta(e,9,1)}
                    disabled={disabled17X}
                  >
                    {this.state.sobrecargaX17.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia16a17_05}
                    disabled={disabled17X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX17 && todo.tramo === this.state.herramientaCodeX17 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia16a17_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled17X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia16a17_08}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 17)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled17X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia16a17_09}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 17)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled17X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia16a17_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia16a17_11}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 9)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia16a17_12}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 9)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,17) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia16a17_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia16a17_01} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_02} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_03} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_04} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_05} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_06} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_07} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_08} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_09} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_10} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_11} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_12} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_13} />
              </>
              }
              {this.state.linea18X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   16:30 a 17:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia16a17_01_2}
                    onChange={e=>this.handleActividades(e,18)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia16a17_02_2}
                    disabled={disabled18X}
                    onChange={e=>this.handlePerforacion(e,109,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia16a17_03_2}
                    disabled={disabled18X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia16a17_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,109,1)}
                    disabled={disabled18X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia16a17_04_2}
                    onChange={e=>this.handleHerramienta(e,109,1)}
                    disabled={disabled18X}
                  >
                    {this.state.sobrecargaX18.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia16a17_05_2}
                    disabled={disabled18X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX18 && todo.tramo === this.state.herramientaCodeX18 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia16a17_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled18X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia16a17_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 18)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled18X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia16a17_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 18)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled18X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia16a17_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia16a17_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 109)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia16a17_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 109)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,18) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia16a17_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia16a17_01_2} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_02_2} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_03_2} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_04_2} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_05_2} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_06_2} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_07_2} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_08_2} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_09_2} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_10_2} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_11_2} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_12_2} />
                <input type="hidden" ref={this.cReporteTurnoDia16a17_13_2} />
              </>
              }
              {this.state.linea19X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   17:00 a 17:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia17a18_01}
                    onChange={e=>this.handleActividades(e,19)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia17a18_02}
                    disabled={disabled19X}
                    onChange={e=>this.handlePerforacion(e,10,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia17a18_03}
                    disabled={disabled19X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia17a18_06}
                    onChange={e=>this.handleAceroPerforacion(e,10,1)}
                    disabled={disabled19X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia17a18_04}
                    onChange={e=>this.handleHerramienta(e,10,1)}
                    disabled={disabled19X}
                  >
                    {this.state.sobrecargaX19.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia17a18_05}
                    disabled={disabled19X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX19 && todo.tramo === this.state.herramientaCodeX19 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia17a18_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled19X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia17a18_08}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 19)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled19X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia17a18_09}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 19)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled19X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia17a18_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia17a18_11}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 10)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia17a18_12}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 10)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,19) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia17a18_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia17a18_01} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_02} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_03} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_04} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_05} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_06} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_07} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_08} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_09} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_10} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_11} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_12} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_13} />
              </>
              }
              {this.state.linea20X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   17:30 a 18:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia17a18_01_2}
                    onChange={e=>this.handleActividades(e,20)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia17a18_02_2}
                    disabled={disabled20X}
                    onChange={e=>this.handlePerforacion(e,110,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia17a18_03_2}
                    disabled={disabled20X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia17a18_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,110,1)}
                    disabled={disabled20X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia17a18_04_2}
                    onChange={e=>this.handleHerramienta(e,110,1)}
                    disabled={disabled20X}
                  >
                    {this.state.sobrecargaX20.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia17a18_05_2}
                    disabled={disabled20X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX20 && todo.tramo === this.state.herramientaCodeX20 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia17a18_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled20X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia17a18_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 20)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled20X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia17a18_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 20)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled20X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia17a18_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia17a18_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 110)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia17a18_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 110)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,20) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia17a18_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia17a18_01_2} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_02_2} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_03_2} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_04_2} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_05_2} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_06_2} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_07_2} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_08_2} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_09_2} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_10_2} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_11_2} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_12_2} />
                <input type="hidden" ref={this.cReporteTurnoDia17a18_13_2} />
              </>
              }
              {this.state.linea21X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   18:00 a 18:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia18a19_01}
                    onChange={e=>this.handleActividades(e,21)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia18a19_02}
                    disabled={disabled21X}
                    onChange={e=>this.handlePerforacion(e,11,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia18a19_03}
                    disabled={disabled21X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia18a19_06}
                    onChange={e=>this.handleAceroPerforacion(e,11,1)}
                    disabled={disabled21X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia18a19_04}
                    onChange={e=>this.handleHerramienta(e,11,1)}
                    disabled={disabled21X}
                  >
                    {this.state.sobrecargaX21.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia18a19_05}
                    disabled={disabled21X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX21 && todo.tramo === this.state.herramientaCodeX21 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia18a19_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled21X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia18a19_08}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 21)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled21X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia18a19_09}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 21)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled21X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia18a19_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia18a19_11}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 11)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia18a19_12}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 11)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,21) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia18a19_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia18a19_01} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_02} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_03} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_04} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_05} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_06} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_07} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_08} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_09} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_10} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_11} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_12} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_13} />
              </>
              }
              {this.state.linea22X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   18:30 a 19:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia18a19_01_2}
                    onChange={e=>this.handleActividades(e,22)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia18a19_02_2}
                    disabled={disabled22X}
                    onChange={e=>this.handlePerforacion(e,111,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia18a19_03_2}
                    disabled={disabled22X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia18a19_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,111,1)}
                    disabled={disabled22X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia18a19_04_2}
                    onChange={e=>this.handleHerramienta(e,111,1)}
                    disabled={disabled22X}
                  >
                    {this.state.sobrecargaX22.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia18a19_05_2}
                    disabled={disabled22X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX22 && todo.tramo === this.state.herramientaCodeX22 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia18a19_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled22X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia18a19_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 22)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled22X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia18a19_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 22)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled22X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia18a19_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia18a19_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 111)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia18a19_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 111)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,22) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia18a19_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia18a19_01_2} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_02_2} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_03_2} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_04_2} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_05_2} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_06_2} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_07_2} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_08_2} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_09_2} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_10_2} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_11_2} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_12_2} />
                <input type="hidden" ref={this.cReporteTurnoDia18a19_13_2} />
              </>
              }
              {this.state.linea23X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   19:00 a 19:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia19a20_01}
                    onChange={e=>this.handleActividades(e,23)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia19a20_02}
                    disabled={disabled23X}
                    onChange={e=>this.handlePerforacion(e,12,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia19a20_03}
                    disabled={disabled23X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia19a20_06}
                    onChange={e=>this.handleAceroPerforacion(e,12,1)}
                    disabled={disabled23X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia19a20_04}
                    onChange={e=>this.handleHerramienta(e,12,1)}
                    disabled={disabled23X}
                  >
                    {this.state.sobrecargaX23.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia19a20_05}
                    disabled={disabled23X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX23 && todo.tramo === this.state.herramientaCodeX23 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia19a20_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled23X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia19a20_08}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 23)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled23X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia19a20_09}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 23)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled23X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia19a20_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia19a20_11}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 12)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia19a20_12}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 12)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,23) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia19a20_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia19a20_01} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_02} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_03} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_04} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_05} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_06} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_07} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_08} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_09} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_10} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_11} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_12} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_13} />
              </>
              }
              {this.state.linea24X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkred'}}>
                   19:30 a 20:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoDia19a20_01_2}
                    onChange={e=>this.handleActividades(e,24)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia19a20_02_2}
                    disabled={disabled24X}
                    onChange={e=>this.handlePerforacion(e,112,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia19a20_03_2}
                    disabled={disabled24X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia19a20_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,112,1)}
                    disabled={disabled24X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoDia19a20_04_2}
                    onChange={e=>this.handleHerramienta(e,112,1)}
                    disabled={disabled24X}
                  >
                    {this.state.sobrecargaX24.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoDia19a20_05_2}
                    disabled={disabled24X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX24 && todo.tramo === this.state.herramientaCodeX24 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoDia19a20_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled24X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoDia19a20_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Dia", 24)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled24X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoDia19a20_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Dia", 24)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled24X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoDia19a20_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia19a20_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Dia", 112)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoDia19a20_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Dia", 112)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <input type="hidden" ref={this.cReporteTurnoDia19a20_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoDia19a20_01_2} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_02_2} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_03_2} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_04_2} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_05_2} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_06_2} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_07_2} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_08_2} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_09_2} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_10_2} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_11_2} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_12_2} />
                <input type="hidden" ref={this.cReporteTurnoDia19a20_13_2} />
              </>
              }

            </tbody>
          </Table>
        </div>
      )
    }

    displayCabeceraTurnoNoche = () => {
      let disabledX = false
      if (this.state.authRoleX === 'Supervisor MD'){
        if (this.state.cReporteTurnoSignatureFirmante2 === true){
           disabledX = true
        }
      }
      if (this.state.authRoleX === 'Adm MD'){
        if (this.state.cReporteTurnoSignatureFirmante3 === true){
           disabledX = true
        }
      }
      if (this.state.authRoleX === 'Jefe Turno MD'){
        if (this.state.cReporteTurnoSignatureFirmante7 === true){
           disabledX = true
        }
      }
      if (this.state.authRoleX !== 'Supervisor MD' && this.state.authRoleX !== 'Adm MD' && this.state.authRoleX !== 'Jefe Turno MD'){
        disabledX = true
      }
      if (this.state.cReporteTurnoSignatureFirmante2 === true ||
          this.state.cReporteTurnoSignatureFirmante3 === true ||
          this.state.cReporteTurnoSignatureFirmante4 === true ||
          this.state.cReporteTurnoSignatureFirmante5 === true ||
          this.state.cReporteTurnoSignatureFirmante7 === true){
          disabledX = true
      }

      if (this.props.active_after_publishedX === 'false'){
        disabledX = true
      }

      let bObservacion = false
      if (this.state.cReporteTurnoObservacionAsesorTurnoNoche !== '' && this.state.cReporteTurnoObservacionAsesorTurnoNoche !== null && this.state.cReporteTurnoObservacionAsesorTurnoNoche !== undefined){
          bObservacion = true
      }

      return (
        <div>
          <Table size="sm" borderless responsive >
            <thead>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "18%" }}>SUPERVISOR</th>
                <th style={{ width: "18%" }}>JEFE TURNO</th>
                <th style={{ width: "18%" }} onMouseEnter={()=>this.onMouseEnterHandler(7,'observacion')} onMouseLeave={()=>this.onMouseLeaveHandler(7,'observacion')}>
                   ASESOR HSE
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.observacion(e,7)}>
                   {this.state.observacion_7 ?
                     <>
                     {bObservacion ?
                         <img src="images/eyeGreen2.png" weight="30" height="30" alt=""/>
                     :
                         <img src="images/eyeGreen2.png" weight="30" height="30" alt=""/>
                     }
                     </>
                   :
                     <>
                     {bObservacion ?
                         <img src="images/eyeOrange.png" weight="30" height="30" alt=""/>
                     :
                         <img src="images/eyeGrey.png" weight="30" height="30" alt=""/>
                     }
                     </>
                   }
                   </strong>
                </th>
                <th style={{ width: "6%" }}></th>
                <th style={{ width: "6%" }}>DESDE</th>
                <th style={{ width: "6%" }}>HASTA</th>
                <th style={{ width: "6%" }}></th>
                <th style={{ width: "6%" }}></th>
                <th style={{ width: "12%" }}></th>
                <th style={{ width: "4%" }}></th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ color:'#3a3e41', textAlign: 'left', fontSize:11}} >
                <th style={{ width: "18%"}}>
                  <FormSelect
                    id="supervisor"
                    innerRef={this.cReporteTurnoNoche04}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.authorizersX.map((todo, key) => {
                      if (todo.conceptFormsDeliverable === 'ReporteTurno'){
                        if (todo.role === "Supervisor MD"){
                          return (
                            <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.id} disabled={false} value={`${todo.id}&&&${todo.name}`} >{todo.name}</option>
                          )
                        }
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "18%"}}>
                  <FormSelect
                    id="jefe turno"
                    innerRef={this.cReporteTurnoNoche01}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.authorizersX.map((todo, key) => {
                      if (todo.conceptFormsDeliverable === 'ReporteTurno'){
                        if (todo.role === "Jefe Turno MD"){
                          return (
                            <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.id} disabled={false} value={`${todo.id}&&&${todo.name}`} >{todo.name}</option>
                          )
                        }
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "18%"}}>
                  <FormSelect
                    id="asesor"
                    innerRef={this.cReporteTurnoNoche05}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.authorizersX.map((todo, key) => {
                      if (todo.conceptFormsDeliverable === 'ReporteTurno'){
                        if (todo.role === "Asesor HSE MD"){
                          return (
                            <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.id} disabled={false} value={`${todo.id}&&&${todo.name}`} >{todo.name}</option>
                          )
                        }
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "6%"}}>
                  MTS TURNO
                </th>
                <th style={{ width: "6%"}}>
                  <FormInput
                    size="sm"
                    id="mts turno desde"
                    innerRef={this.cReporteTurnoNoche06}
                    placeholder=""
                    disabled={true}
                  />
                </th>
                <th style={{ width: "6%"}}>
                  <FormInput
                    size="sm"
                    id="mts turno hasta"
                    innerRef={this.cReporteTurnoNoche07}
                    placeholder=""
                    disabled={true}
                  />
                </th>
                <th style={{ width: "6%"}}>
                  PERFORADOS
                </th>
                <th style={{ width: "6%"}}>
                  <FormInput
                    size="sm"
                    id="mts perforados"
                    innerRef={this.cReporteTurnoNoche08}
                    onChange={e=>this.handlePerforados(e,2)}
                    placeholder=""
                    disabled={true}
                  />
                </th>
                <th style={{ textAlign: 'right', width: "12%"}}>
                  {this.state.starNoche1 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                  {this.state.starNoche2 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                  {this.state.starNoche3 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                  {this.state.starNoche4 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                  {this.state.starNoche5 ? <img src="images/reporteturno07.png" weight="20" height="20" alt=""/> : <img src="images/reporteturno07Grey.png" weight="20" height="20" alt=""/> }
                </th>
                <th style={{ textAlign: 'left', width: "4%"}}>
                  {this.state.starNoche5 ? <img src="images/reporteturno06.png" weight="40" height="35" alt=""/> : null }
                </th>
              </tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                  <th style={{ width: "18%" }}>OPERADOR</th>
                  <th style={{ width: "18%" }}>AYUDANTE 1</th>
                  <th style={{ width: "18%" }}>AYUDANTE 2</th>
                  <th style={{ width: "6%"}}></th>
                  <th style={{ width: "6%"}}>DESDE</th>
                  <th style={{ width: "6%"}}>HASTA</th>
                  <th style={{ width: "6%"}}></th>
                  <th style={{ width: "6%"}}></th>
                  <th style={{ width: "8%"}}>ESTADO POZO</th>
                  <th></th>
                  <th></th>
              </tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "18%"}}>
                  <FormSelect
                    id="operador"
                    innerRef={this.cReporteTurnoNoche12}
                    placeholder={this.cReporteTurnoNoche12}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.authorizersX.map((todo, key) => {
                      if (todo.conceptFormsDeliverable === 'ReporteTurno'){
                        if (todo.role === "Operador MD"){
                          return (
                           <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.id} disabled={false} value={`${todo.id}&&&${todo.name}`} >{todo.name}</option>
                          )
                        }
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "18%"}}>
                  <FormInput
                    size="sm"
                    id="ayudante 1"
                    innerRef={this.cReporteTurnoNoche02}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "18%"}}>
                  <FormInput
                    size="sm"
                    id="ayudante 2"
                    innerRef={this.cReporteTurnoNoche03}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "6%"}}>
                   HOROMETRO
                </th>
                <th style={{ width: "6%"}}>
                  <FormInput
                    size="sm"
                    id="horometro desde"
                    innerRef={this.cReporteTurnoNoche09}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "6%"}}>
                  <FormInput
                    size="sm"
                    id="horometro hasta"
                    innerRef={this.cReporteTurnoNoche10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "6%"}}>
                  META
                </th>
                <th style={{ width: "6%"}}>
                  <FormInput
                    size="sm"
                    id="mts meta"
                    innerRef={this.cReporteTurnoNoche11}
                    onChange={e=>this.handlePerforados(e,2)}
                    placeholder={this.state.workordermetaturnonoche}
                    disabled={true}
                  />
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="estado pozo"
                    innerRef={this.cReporteTurnoNoche13}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.estadopozoX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{todo.description}</option>
                        )
                    })}
                  </FormSelect>
                </th>
              </tr>
            </tbody>
          </Table>
        </div>
      )
    }

    displayDetalleTurnoNoche = () => {
      let disabledX = true
      if (this.state.roleGenerateX || this.state.roleModifyX){
        disabledX = false
      }
      if (this.state.authRoleX === 'Supervisor MD'){
        if (this.state.cReporteTurnoSignatureFirmante2 === true){
           disabledX = true
        }
      }
      if (this.state.authRoleX === 'Adm MD'){
        if (this.state.cReporteTurnoSignatureFirmante3 === true){
           disabledX = true
        }
      }
      if (this.state.authRoleX === 'Jefe Turno MD'){
        if (this.state.cReporteTurnoSignatureFirmante7 === true){
           disabledX = true
        }
      }
      if (this.state.authRoleX !== 'Supervisor MD' && this.state.authRoleX !== 'Adm MD' && this.state.authRoleX !== 'Jefe Turno MD'){
        disabledX = true
      }
      if (this.state.cReporteTurnoSignatureFirmante2 === true ||
          this.state.cReporteTurnoSignatureFirmante3 === true ||
          this.state.cReporteTurnoSignatureFirmante4 === true ||
          this.state.cReporteTurnoSignatureFirmante5 === true ||
          this.state.cReporteTurnoSignatureFirmante7 === true){
          disabledX = true
      }

      if (this.props.active_after_publishedX === 'false'){
        disabledX = true
      }

      let disabled2X = this.state.displayDetalleX
      if (disabledX === true){disabled2X = true}

      let disabled25X = this.state.displayDetalle25X
      let disabled26X = this.state.displayDetalle26X
      let disabled27X = this.state.displayDetalle27X
      let disabled28X = this.state.displayDetalle28X
      let disabled29X = this.state.displayDetalle29X
      let disabled30X = this.state.displayDetalle30X
      let disabled31X = this.state.displayDetalle31X
      let disabled32X = this.state.displayDetalle32X
      let disabled33X = this.state.displayDetalle33X
      let disabled34X = this.state.displayDetalle34X
      let disabled35X = this.state.displayDetalle35X
      let disabled36X = this.state.displayDetalle36X
      let disabled37X = this.state.displayDetalle37X
      let disabled38X = this.state.displayDetalle38X
      let disabled39X = this.state.displayDetalle39X
      let disabled40X = this.state.displayDetalle40X
      let disabled41X = this.state.displayDetalle41X
      let disabled42X = this.state.displayDetalle42X
      let disabled43X = this.state.displayDetalle43X
      let disabled44X = this.state.displayDetalle44X
      let disabled45X = this.state.displayDetalle45X
      let disabled46X = this.state.displayDetalle46X
      let disabled47X = this.state.displayDetalle47X
      let disabled48X = this.state.displayDetalle48X
      if (disabledX === true){disabled25X=true;disabled26X=true;disabled27X=true;disabled28X=true;disabled29X=true;disabled30X=true;disabled31X=true;disabled32X=true;disabled33X=true;disabled34X=true}
      if (disabledX === true){disabled35X=true;disabled36X=true;disabled37X=true;disabled38X=true;disabled39X=true;disabled40X=true;disabled41X=true;disabled42X=true;disabled43X=true;disabled44X=true}
      if (disabledX === true){disabled45X=true;disabled46X=true;disabled47X=true;disabled48X=true}


      return (
        <div>
          <Table size="sm" borderless responsive hover>
            <thead>
              <tr style={{ backgroundColor:'#ceca9e', color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "3%" }}>Horario</th>
                <th style={{ width: "14%" }}>Actividad</th>
                <th style={{ width: "10%" }}>Perforación</th>
                <th style={{ width: "7%" }}>Condición Humedad</th>
                <th style={{ width: "7%" }}>Acero Perforación</th>
                <th style={{ width: "10%" }}>Herramientas</th>
                <th style={{ width: "8%" }}>Diámetro Ocupado</th>
                <th style={{ width: "7%" }}>Diám. Ocup. Núm. Serie</th>
                <th style={{ width: "7%" }}>Mts. Inicio</th>
                <th style={{ width: "7%" }}>Mts. Fin</th>
                <th style={{ width: "11%" }}>Aclaraciones</th>
                <th style={{ width: "4%" }}>HH MD</th>
                <th style={{ width: "4%" }}>HH AA</th>
                <th style={{ width: "1%" }}></th>
              </tr>
            </thead>
            <tbody>
              {this.state.linea25X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   20:00 a 20:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche20a21_01}
                    onChange={e=>this.handleActividades(e,25)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche20a21_02}
                    disabled={disabled25X}
                    onChange={e=>this.handlePerforacion(e,13,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche20a21_03}
                    disabled={disabled25X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche20a21_06}
                    onChange={e=>this.handleAceroPerforacion(e,13,1)}
                    disabled={disabled25X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche20a21_04}
                    onChange={e=>this.handleHerramienta(e,13,1)}
                    disabled={disabled25X}
                  >
                    {this.state.sobrecargaX25.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche20a21_05}
                    disabled={disabled25X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX25 && todo.tramo === this.state.herramientaCodeX25 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche20a21_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled25X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche20a21_08}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 25)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled25X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche20a21_09}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 25)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled25X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche20a21_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche20a21_11}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 1)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche20a21_12}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 1)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,25) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_01} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_02} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_03} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_04} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_05} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_06} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_07} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_08} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_09} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_10} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_11} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_12} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_13} />
              </>
              }
              {this.state.linea26X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   20:30 a 21:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche20a21_01_2}
                    onChange={e=>this.handleActividades(e,26)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche20a21_02_2}
                    disabled={disabled26X}
                    onChange={e=>this.handlePerforacion(e,113,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche20a21_03_2}
                    disabled={disabled26X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche20a21_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,113,1)}
                    disabled={disabled26X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche20a21_04_2}
                    onChange={e=>this.handleHerramienta(e,113,1)}
                    disabled={disabled26X}
                  >
                    {this.state.sobrecargaX26.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche20a21_05_2}
                    disabled={disabled26X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX26 && todo.tramo === this.state.herramientaCodeX26 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche20a21_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled26X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche20a21_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 26)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled26X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche20a21_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 26)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled26X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche20a21_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche20a21_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 101)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche20a21_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 101)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,26) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_01_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_02_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_03_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_04_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_05_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_06_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_07_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_08_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_09_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_10_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_11_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_12_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche20a21_13_2} />
              </>
              }
              {this.state.linea27X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   21:00 a 21:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche21a22_01}
                    onChange={e=>this.handleActividades(e,27)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche21a22_02}
                    disabled={disabled27X}
                    onChange={e=>this.handlePerforacion(e,14,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche21a22_03}
                    disabled={disabled27X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche21a22_06}
                    onChange={e=>this.handleAceroPerforacion(e,14,1)}
                    disabled={disabled27X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche21a22_04}
                    onChange={e=>this.handleHerramienta(e,14,1)}
                    disabled={disabled27X}
                  >
                    {this.state.sobrecargaX27.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche21a22_05}
                    disabled={disabled27X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX27 && todo.tramo === this.state.herramientaCodeX27 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche21a22_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled27X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche21a22_08}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 27)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled27X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche21a22_09}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 27)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled27X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche21a22_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche21a22_11}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 2)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche21a22_12}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 2)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,27) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_01} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_02} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_03} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_04} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_05} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_06} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_07} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_08} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_09} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_10} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_11} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_12} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_13} />
              </>
              }
              {this.state.linea28X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   21:30 a 22:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche21a22_01_2}
                    onChange={e=>this.handleActividades(e,28)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche21a22_02_2}
                    disabled={disabled28X}
                    onChange={e=>this.handlePerforacion(e,114,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche21a22_03_2}
                    disabled={disabled28X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche21a22_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,114,1)}
                    disabled={disabled28X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche21a22_04_2}
                    onChange={e=>this.handleHerramienta(e,114,1)}
                    disabled={disabled28X}
                  >
                    {this.state.sobrecargaX28.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche21a22_05_2}
                    disabled={disabled28X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX28 && todo.tramo === this.state.herramientaCodeX28 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche21a22_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled28X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche21a22_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 28)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled28X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche21a22_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 28)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled28X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche21a22_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche21a22_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 102)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche21a22_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 102)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,28) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_01_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_02_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_03_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_04_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_05_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_06_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_07_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_08_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_09_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_10_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_11_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_12_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche21a22_13_2} />
              </>
              }
              {this.state.linea29X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   22:00 a 22:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche22a23_01}
                    onChange={e=>this.handleActividades(e,29)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche22a23_02}
                    disabled={disabled29X}
                    onChange={e=>this.handlePerforacion(e,15,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche22a23_03}
                    disabled={disabled29X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche22a23_06}
                    onChange={e=>this.handleAceroPerforacion(e,15,1)}
                    disabled={disabled29X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche22a23_04}
                    onChange={e=>this.handleHerramienta(e,15,1)}
                    disabled={disabled29X}
                  >
                    {this.state.sobrecargaX29.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche22a23_05}
                    disabled={disabled29X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX29 && todo.tramo === this.state.herramientaCodeX29 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche22a23_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled29X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche22a23_08}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 29)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled29X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche22a23_09}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 29)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled29X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche22a23_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche22a23_11}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 3)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche22a23_12}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 3)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,29) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_01} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_02} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_03} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_04} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_05} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_06} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_07} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_08} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_09} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_10} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_11} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_12} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_13} />
              </>
              }
              {this.state.linea30X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   22:30 a 23:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche22a23_01_2}
                    onChange={e=>this.handleActividades(e,30)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche22a23_02_2}
                    disabled={disabled30X}
                    onChange={e=>this.handlePerforacion(e,115,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche22a23_03_2}
                    disabled={disabled30X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche22a23_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,115,1)}
                    disabled={disabled30X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche22a23_04_2}
                    onChange={e=>this.handleHerramienta(e,115,1)}
                    disabled={disabled30X}
                  >
                    {this.state.sobrecargaX30.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche22a23_05_2}
                    disabled={disabled30X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX30 && todo.tramo === this.state.herramientaCodeX30 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche22a23_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled30X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche22a23_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 30)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled30X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche22a23_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 30)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled30X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche22a23_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche22a23_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 103)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche22a23_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 103)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,30) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_01_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_02_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_03_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_04_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_05_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_06_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_07_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_08_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_09_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_10_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_11_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_12_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche22a23_13_2} />
              </>
              }
              {this.state.linea31X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   23:00 a 23:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche23a24_01}
                    onChange={e=>this.handleActividades(e,31)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche23a24_02}
                    disabled={disabled31X}
                    onChange={e=>this.handlePerforacion(e,16,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche23a24_03}
                    disabled={disabled31X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche23a24_06}
                    onChange={e=>this.handleAceroPerforacion(e,16,1)}
                    disabled={disabled31X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche23a24_04}
                    onChange={e=>this.handleHerramienta(e,16,1)}
                    disabled={disabled31X}
                  >
                    {this.state.sobrecargaX31.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche23a24_05}
                    disabled={disabled31X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX31 && todo.tramo === this.state.herramientaCodeX31 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche23a24_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled31X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche23a24_08}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 31)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled31X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche23a24_09}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 31)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche23a24_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche23a24_11}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 4)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche23a24_12}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 4)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,31) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_01} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_02} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_03} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_04} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_05} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_06} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_07} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_08} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_09} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_10} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_11} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_12} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_13} />
              </>
              }
              {this.state.linea32X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   23:30 a 24:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche23a24_01_2}
                    onChange={e=>this.handleActividades(e,32)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche23a24_02_2}
                    disabled={disabled32X}
                    onChange={e=>this.handlePerforacion(e,116,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche23a24_03_2}
                    disabled={disabled32X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche23a24_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,116,1)}
                    disabled={disabled32X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche23a24_04_2}
                    onChange={e=>this.handleHerramienta(e,116,1)}
                    disabled={disabled32X}
                  >
                    {this.state.sobrecargaX32.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche23a24_05_2}
                    disabled={disabled32X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX32 && todo.tramo === this.state.herramientaCodeX32 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche23a24_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled32X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche23a24_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 32)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled32X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche23a24_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 32)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled32X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche23a24_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche23a24_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 104)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche23a24_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 104)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,32) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_01_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_02_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_03_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_04_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_05_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_06_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_07_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_08_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_09_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_10_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_11_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_12_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche23a24_13_2} />
              </>
              }
              {this.state.linea33X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   24:00 a 00:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche24a01_01}
                    onChange={e=>this.handleActividades(e,33)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche24a01_02}
                    disabled={disabled33X}
                    onChange={e=>this.handlePerforacion(e,17,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche24a01_03}
                    disabled={disabled33X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche24a01_06}
                    onChange={e=>this.handleAceroPerforacion(e,17,1)}
                    disabled={disabled33X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche24a01_04}
                    onChange={e=>this.handleHerramienta(e,17,1)}
                    disabled={disabled33X}
                  >
                    {this.state.sobrecargaX33.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche24a01_05}
                    disabled={disabled33X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX33 && todo.tramo === this.state.herramientaCodeX33 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche24a01_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled33X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche24a01_08}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 33)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled33X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche24a01_09}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 33)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled33X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche24a01_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche24a01_11}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 5)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche24a01_12}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 5)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,33) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_01} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_02} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_03} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_04} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_05} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_06} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_07} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_08} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_09} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_10} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_11} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_12} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_13} />
              </>
              }
              {this.state.linea34X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   00:30 a 01:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche24a01_01_2}
                    onChange={e=>this.handleActividades(e,34)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche24a01_02_2}
                    disabled={disabled34X}
                    onChange={e=>this.handlePerforacion(e,117,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche24a01_03_2}
                    disabled={disabled34X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche24a01_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,117,1)}
                    disabled={disabled34X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche24a01_04_2}
                    onChange={e=>this.handleHerramienta(e,117,1)}
                    disabled={disabled34X}
                  >
                    {this.state.sobrecargaX34.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche24a01_05_2}
                    disabled={disabled34X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX34 && todo.tramo === this.state.herramientaCodeX34 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche24a01_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled34X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche24a01_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 34)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled34X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche24a01_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 34)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled34X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche24a01_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche24a01_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 105)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche24a01_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 105)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,34) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_01_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_02_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_03_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_04_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_05_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_06_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_07_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_08_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_09_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_10_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_11_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_12_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche24a01_13_2} />
              </>
              }
              {this.state.linea35X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   01:00 a 01:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche01a02_01}
                    onChange={e=>this.handleActividades(e,35)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche01a02_02}
                    disabled={disabled35X}
                    onChange={e=>this.handlePerforacion(e,18,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche01a02_03}
                    disabled={disabled35X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche01a02_06}
                    onChange={e=>this.handleAceroPerforacion(e,18,1)}
                    disabled={disabled35X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche01a02_04}
                    onChange={e=>this.handleHerramienta(e,18,1)}
                    disabled={disabled35X}
                  >
                    {this.state.sobrecargaX35.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche01a02_05}
                    disabled={disabled35X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX35 && todo.tramo === this.state.herramientaCodeX35 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche01a02_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled35X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche01a02_08}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 35)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled35X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche01a02_09}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 35)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled35X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche01a02_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche01a02_11}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 6)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche01a02_12}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 6)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,35) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_01} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_02} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_03} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_04} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_05} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_06} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_07} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_08} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_09} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_10} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_11} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_12} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_13} />
              </>
              }
              {this.state.linea36X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   01:30 a 02:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche01a02_01_2}
                    onChange={e=>this.handleActividades(e,36)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche01a02_02_2}
                    disabled={disabled36X}
                    onChange={e=>this.handlePerforacion(e,118,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche01a02_03_2}
                    disabled={disabled36X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche01a02_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,118,1)}
                    disabled={disabled36X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche01a02_04_2}
                    onChange={e=>this.handleHerramienta(e,118,1)}
                    disabled={disabled36X}
                  >
                    {this.state.sobrecargaX36.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche01a02_05_2}
                    disabled={disabled36X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX36 && todo.tramo === this.state.herramientaCodeX36 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche01a02_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled36X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche01a02_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 36)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled36X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche01a02_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 36)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled36X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche01a02_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche01a02_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 106)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche01a02_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 106)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,36) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_01_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_02_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_03_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_04_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_05_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_06_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_07_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_08_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_09_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_10_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_11_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_12_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche01a02_13_2} />
              </>
              }
              {this.state.linea37X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   02:00 a 02:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche02a03_01}
                    onChange={e=>this.handleActividades(e,37)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche02a03_02}
                    disabled={disabled37X}
                    onChange={e=>this.handlePerforacion(e,19,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche02a03_03}
                    disabled={disabled37X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche02a03_06}
                    onChange={e=>this.handleAceroPerforacion(e,19,1)}
                    disabled={disabled37X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche02a03_04}
                    onChange={e=>this.handleHerramienta(e,19,1)}
                    disabled={disabled37X}
                  >
                    {this.state.sobrecargaX37.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche02a03_05}
                    disabled={disabled37X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX37 && todo.tramo === this.state.herramientaCodeX37 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche02a03_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled37X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche02a03_08}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 37)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled37X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche02a03_09}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 37)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled37X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche02a03_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche02a03_11}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 7)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche02a03_12}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 7)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,37) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_01} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_02} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_03} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_04} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_05} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_06} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_07} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_08} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_09} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_10} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_11} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_12} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_13} />
              </>
              }
              {this.state.linea38X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   02:30 a 03:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche02a03_01_2}
                    onChange={e=>this.handleActividades(e,38)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche02a03_02_2}
                    disabled={disabled38X}
                    onChange={e=>this.handlePerforacion(e,119,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche02a03_03_2}
                    disabled={disabled38X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche02a03_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,119,1)}
                    disabled={disabled38X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche02a03_04_2}
                    onChange={e=>this.handleHerramienta(e,119,1)}
                    disabled={disabled38X}
                  >
                    {this.state.sobrecargaX38.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche02a03_05_2}
                    disabled={disabled38X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX38 && todo.tramo === this.state.herramientaCodeX38 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche02a03_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled38X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche02a03_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 38)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled38X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche02a03_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 38)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled38X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche02a03_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche02a03_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 107)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche02a03_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 107)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,38) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_01_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_02_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_03_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_04_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_05_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_06_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_07_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_08_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_09_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_10_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_11_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_12_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche02a03_13_2} />
              </>
              }
              {this.state.linea39X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   03:00 a 03:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche03a04_01}
                    onChange={e=>this.handleActividades(e,39)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche03a04_02}
                    disabled={disabled39X}
                    onChange={e=>this.handlePerforacion(e,20,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche03a04_03}
                    disabled={disabled39X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche03a04_06}
                    onChange={e=>this.handleAceroPerforacion(e,20,1)}
                    disabled={disabled39X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche03a04_04}
                    onChange={e=>this.handleHerramienta(e,20,1)}
                    disabled={disabled39X}
                  >
                    {this.state.sobrecargaX39.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche03a04_05}
                    disabled={disabled39X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX39 && todo.tramo === this.state.herramientaCodeX39 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche03a04_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled39X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche03a04_08}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 39)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled39X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche03a04_09}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 39)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled39X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche03a04_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche03a04_11}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 8)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche03a04_12}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 8)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,39) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_01} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_02} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_03} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_04} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_05} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_06} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_07} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_08} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_09} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_10} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_11} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_12} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_13} />
              </>
              }
              {this.state.linea40X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   03:30 a 04:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche03a04_01_2}
                    onChange={e=>this.handleActividades(e,40)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche03a04_02_2}
                    disabled={disabled40X}
                    onChange={e=>this.handlePerforacion(e,120,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche03a04_03_2}
                    disabled={disabled40X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche03a04_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,120,1)}
                    disabled={disabled40X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche03a04_04_2}
                    onChange={e=>this.handleHerramienta(e,120,1)}
                    disabled={disabled40X}
                  >
                    {this.state.sobrecargaX40.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche03a04_05_2}
                    disabled={disabled40X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX40 && todo.tramo === this.state.herramientaCodeX40 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche03a04_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled40X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche03a04_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 40)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled40X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche03a04_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 40)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled40X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche03a04_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche03a04_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 108)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche03a04_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 108)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,40) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_01_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_02_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_03_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_04_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_05_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_06_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_07_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_08_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_09_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_10_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_11_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_12_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche03a04_13_2} />
              </>
              }
              {this.state.linea41X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   04:00 a 04:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche04a05_01}
                    onChange={e=>this.handleActividades(e,41)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche04a05_02}
                    disabled={disabled41X}
                    onChange={e=>this.handlePerforacion(e,21,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche04a05_03}
                    disabled={disabled41X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche04a05_06}
                    onChange={e=>this.handleAceroPerforacion(e,21,1)}
                    disabled={disabled41X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche04a05_04}
                    onChange={e=>this.handleHerramienta(e,21,1)}
                    disabled={disabled41X}
                  >
                    {this.state.sobrecargaX41.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche04a05_05}
                    disabled={disabled41X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX41 && todo.tramo === this.state.herramientaCodeX41 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche04a05_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled41X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche04a05_08}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 41)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled41X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche04a05_09}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 41)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled41X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche04a05_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche04a05_11}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 9)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche04a05_12}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 9)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,41) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_01} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_02} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_03} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_04} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_05} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_06} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_07} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_08} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_09} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_10} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_11} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_12} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_13} />
              </>
              }
              {this.state.linea42X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   04:30 a 05:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche04a05_01_2}
                    onChange={e=>this.handleActividades(e,42)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche04a05_02_2}
                    disabled={disabled42X}
                    onChange={e=>this.handlePerforacion(e,121,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche04a05_03_2}
                    disabled={disabled42X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche04a05_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,121,1)}
                    disabled={disabled42X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche04a05_04_2}
                    onChange={e=>this.handleHerramienta(e,121,1)}
                    disabled={disabled42X}
                  >
                    {this.state.sobrecargaX42.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche04a05_05_2}
                    disabled={disabled42X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX42 && todo.tramo === this.state.herramientaCodeX42 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche04a05_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled42X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche04a05_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 42)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled42X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche04a05_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 42)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled42X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche04a05_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche04a05_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 109)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche04a05_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 109)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,42) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_01_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_02_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_03_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_04_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_05_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_06_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_07_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_08_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_09_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_10_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_11_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_12_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche04a05_13_2} />
              </>
              }
              {this.state.linea43X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   05:00 a 05:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche05a06_01}
                    onChange={e=>this.handleActividades(e,43)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche05a06_02}
                    disabled={disabled43X}
                    onChange={e=>this.handlePerforacion(e,22,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche05a06_03}
                    disabled={disabled43X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche05a06_06}
                    onChange={e=>this.handleAceroPerforacion(e,22,1)}
                    disabled={disabled43X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche05a06_04}
                    onChange={e=>this.handleHerramienta(e,22,1)}
                    disabled={disabled43X}
                  >
                    {this.state.sobrecargaX43.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche05a06_05}
                    disabled={disabled43X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX43 && todo.tramo === this.state.herramientaCodeX43 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche05a06_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled43X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche05a06_08}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 43)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled43X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche05a06_09}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 43)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled43X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche05a06_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche05a06_11}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 10)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche05a06_12}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 10)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,43) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_01} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_02} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_03} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_04} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_05} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_06} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_07} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_08} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_09} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_10} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_11} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_12} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_13} />
              </>
              }
              {this.state.linea44X ?
              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   05:30 a 06:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche05a06_01_2}
                    onChange={e=>this.handleActividades(e,44)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche05a06_02_2}
                    disabled={disabled44X}
                    onChange={e=>this.handlePerforacion(e,122,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche05a06_03_2}
                    disabled={disabled44X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche05a06_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,122,1)}
                    disabled={disabled44X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche05a06_04_2}
                    onChange={e=>this.handleHerramienta(e,122,1)}
                    disabled={disabled44X}
                  >
                    {this.state.sobrecargaX44.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche05a06_05_2}
                    disabled={disabled44X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX44 && todo.tramo === this.state.herramientaCodeX44 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche05a06_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled44X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche05a06_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 44)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled44X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche05a06_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 44)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche05a06_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche05a06_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 110)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche05a06_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 110)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,44) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_01_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_02_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_03_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_04_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_05_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_06_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_07_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_08_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_09_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_10_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_11_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_12_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche05a06_13_2} />
              </>
              }
              {this.state.linea45X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   06:00 a 06:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche06a07_01}
                    onChange={e=>this.handleActividades(e,45)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche06a07_02}
                    disabled={disabled45X}
                    onChange={e=>this.handlePerforacion(e,23,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche06a07_03}
                    disabled={disabled45X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche06a07_06}
                    onChange={e=>this.handleAceroPerforacion(e,23,1)}
                    disabled={disabled45X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche06a07_04}
                    onChange={e=>this.handleHerramienta(e,23,1)}
                    disabled={disabled45X}
                  >
                    {this.state.sobrecargaX45.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche06a07_05}
                    disabled={disabled45X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX45 && todo.tramo === this.state.herramientaCodeX45 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche06a07_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled45X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche06a07_08}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 45)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled45X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche06a07_09}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 45)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled45X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche06a07_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche06a07_11}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 11)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche06a07_12}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 11)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,45) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_01} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_02} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_03} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_04} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_05} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_06} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_07} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_08} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_09} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_10} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_11} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_12} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_13} />
              </>
              }
              {this.state.linea46X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   06:30 a 07:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche06a07_01_2}
                    onChange={e=>this.handleActividades(e,46)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche06a07_02_2}
                    disabled={disabled46X}
                    onChange={e=>this.handlePerforacion(e,123,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche06a07_03_2}
                    disabled={disabled46X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche06a07_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,123,1)}
                    disabled={disabled46X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche06a07_04_2}
                    onChange={e=>this.handleHerramienta(e,123,1)}
                    disabled={disabled46X}
                  >
                    {this.state.sobrecargaX46.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche06a07_05_2}
                    disabled={disabled46X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX46 && todo.tramo === this.state.herramientaCodeX46 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche06a07_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled46X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche06a07_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 46)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled46X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche06a07_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 46)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled46X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche06a07_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche06a07_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 111)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche06a07_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 111)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,46) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_01_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_02_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_03_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_04_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_05_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_06_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_07_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_08_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_09_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_10_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_11_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_12_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche06a07_13_2} />
              </>
              }
              {this.state.linea47X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   07:00 a 07:30
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche07a08_01}
                    onChange={e=>this.handleActividades(e,47)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche07a08_02}
                    disabled={disabled47X}
                    onChange={e=>this.handlePerforacion(e,24,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche07a08_03}
                    disabled={disabled47X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche07a08_06}
                    onChange={e=>this.handleAceroPerforacion(e,24,1)}
                    disabled={disabled47X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche07a08_04}
                    onChange={e=>this.handleHerramienta(e,24,1)}
                    disabled={disabled47X}
                  >
                    {this.state.sobrecargaX47.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche07a08_05}
                    disabled={disabled47X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX47 && todo.tramo === this.state.herramientaCodeX47 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche07a08_13}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled47X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche07a08_08}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 47)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled47X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche07a08_09}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 47)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled47X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche07a08_10}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche07a08_11}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 12)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche07a08_12}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 12)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "1%"}}>
                  <strong style={{cursor: 'pointer'}} onClick={!disabledX ? e=>this.duplicar(e,47) : null}><img src="images/masGreen.png" weight="20" height="20" alt=""/></strong>
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_07} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_01} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_02} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_03} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_04} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_05} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_06} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_07} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_08} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_09} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_10} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_11} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_12} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_13} />
              </>
              }
              {this.state.linea48X ?

              <tr style={{ color:'#3a3e41', backgroundColor: "#F2EACE", textAlign: 'left', fontSize:10}} >
                <th style={{ width: "3%", color:'darkblue'}}>
                   07:30 a 08:00
                </th>
                <th style={{ width: "13%"}}>
                  <FormSelect
                    id="actividades"
                    style={{fontSize:11}}
                    innerRef={this.cReporteTurnoNoche07a08_01_2}
                    onChange={e=>this.handleActividades(e,48)}
                    disabled={disabledX}
                  >
                    <option value={``} >{``}</option>
                    {this.state.actividadesX.map((todo, key) => {
                        return (
                          <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}&&&${todo.hhmd}&&&${todo.hhaa}`} >{`${todo.code}) ${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "10%"}}>
                  <FormSelect
                    id="tipo perforación"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche07a08_02_2}
                    disabled={disabled48X}
                    onChange={e=>this.handlePerforacion(e,124,1)}
                  >
                    <option value={``} >{``}</option>
                    {this.state.perforacionX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    id="condición humedad"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche07a08_03_2}
                    disabled={disabled48X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.tipomuestraX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "8%"}}>
                  <FormSelect
                    id="acero perforacion"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche07a08_06_2}
                    onChange={e=>this.handleAceroPerforacion(e,124,1)}
                    disabled={disabled48X}
                  >
                    <option value={``} >{``}</option>
                    {this.state.herramientaX.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "9%"}}>
                  <FormSelect
                    id="herramienta"
                    style={{fontSize:9}}
                    innerRef={this.cReporteTurnoNoche07a08_04_2}
                    onChange={e=>this.handleHerramienta(e,124,1)}
                    disabled={disabled48X}
                  >
                    {this.state.sobrecargaX48.map((todo, key) => {
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormSelect
                    size="sm"
                    style={{fontSize:9}}
                    id="diametro"
                    innerRef={this.cReporteTurnoNoche07a08_05_2}
                    disabled={disabled48X}
                  >
                    {this.state.diametroocupadoX.map((todo, key) => {
                      if (todo.perforacion === this.state.opcionPerforacionX48 && todo.tramo === this.state.herramientaCodeX48 ){
                        return (
                         <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={todo.code} disabled={false} value={`${todo.code}&&&${todo.description}`} >{`${todo.description}`}</option>
                        )
                      }
                    })}
                  </FormSelect>
                </th>
                <th style={{ width: "7%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="serie diametro"
                    innerRef={this.cReporteTurnoNoche07a08_13_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabled48X}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="inicio"
                    innerRef={this.cReporteTurnoNoche07a08_08_2}
                    onBlur={e=>this.handleMetroInicio(e, "Noche", 48)}
                    onInput={e=>this.handleInputNumber(e, "metroinicio")}
                    placeholder=""
                    disabled={disabled48X}
                  />
                </th>
                  <th style={{ width: "4%"}}>
                  <FormInput
                    type="number"
                    size="sm"
                    style={{fontSize:9}}
                    id="fin"
                    innerRef={this.cReporteTurnoNoche07a08_09_2}
                    onBlur={e=>this.handleMetroFin(e, "Noche", 48)}
                    onInput={e=>this.handleInputNumber(e, "metrofin")}
                    placeholder=""
                    disabled={disabled48X}
                  />
                </th>
                <th style={{ width: "12%"}}>
                  <FormTextarea
                    size="sm"
                    style={{fontSize:9}}
                    id="aclaraciones"
                    innerRef={this.cReporteTurnoNoche07a08_10_2}
                    onChange={e=>this.handleCaracteresEspeciales(e)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche07a08_11_2}
                    onChange={e=>this.handleChangeHHMD(e, "Noche", 112)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <th style={{ width: "4%"}}>
                  <FormInput
                    size="sm"
                    style={{fontSize:9}}
                    id="hhmd"
                    innerRef={this.cReporteTurnoNoche07a08_12_2}
                    onChange={e=>this.handleChangeHHAA(e, "Noche", 212)}
                    placeholder=""
                    disabled={disabledX}
                  />
                </th>
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_07_2} />
              </tr>
              :
              <>
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_01_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_02_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_03_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_04_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_05_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_06_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_07_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_08_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_09_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_10_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_11_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_12_2} />
                <input type="hidden" ref={this.cReporteTurnoNoche07a08_13_2} />
              </>
              }

            </tbody>
          </Table>
        </div>
      )
    }

    displayGuardarTurnoDia = () => {
      let signature1X = ''
      let signature6X = ''
      if (this.state.cReporteTurnoPublicKeyFirmante1.publicKey2 === '' || this.state.cReporteTurnoPublicKeyFirmante1.publicKey2 === undefined || this.state.cReporteTurnoPublicKeyFirmante1.publicKey2 === null ){
         signature1X = `...`
      }else{
         let pubkeyX = this.state.cReporteTurnoPublicKeyFirmante1.publicKey2
         signature1X = `${pubkeyX.substring(0,10)}...${pubkeyX.substring(pubkeyX.length-5)}`
      }
      if (this.state.cReporteTurnoPublicKeyFirmante6.publicKey2 === '' || this.state.cReporteTurnoPublicKeyFirmante6.publicKey2 === undefined || this.state.cReporteTurnoPublicKeyFirmante6.publicKey2 === null ){
         signature6X = `...`
      }else{
         let pubkeyX = this.state.cReporteTurnoPublicKeyFirmante6.publicKey2
         signature6X = `${pubkeyX.substring(0,10)}...${pubkeyX.substring(pubkeyX.length-5)}`
      }

      let guardarTurnoDiaX = false
      if (this.state.roleGenerateX || this.state.roleModifyX){
        guardarTurnoDiaX = true
      }


      if (this.state.authRoleX === 'Supervisor MD'){
        if (this.state.cReporteTurnoSignatureFirmante1 === true){
           guardarTurnoDiaX = false
        }else{
           guardarTurnoDiaX = true
        }
      }
      let registrarCerrarTurnoDiaX = false
      if (this.state.authRoleX === 'Adm MD'){
        if (this.state.cReporteTurnoSignatureFirmante3 === true){
           guardarTurnoDiaX = false
        }else{
          if (this.state.cReporteTurnoSignatureFirmante1 === false){
             guardarTurnoDiaX = true
          }else{
             guardarTurnoDiaX = false
          }
        }
      }
      if (this.state.authRoleX === 'Jefe Turno MD'){
        if (this.state.cReporteTurnoSignatureFirmante6 === true){
           guardarTurnoDiaX = false
        }else{
          if (this.state.cReporteTurnoSignatureFirmante1 === false){
             guardarTurnoDiaX = true
          }else{
             guardarTurnoDiaX = false
          }
        }
      }
      if (this.state.authRoleX !== 'Supervisor MD' && this.state.authRoleX !== 'Adm MD'  && this.state.authRoleX !== 'Jefe Turno MD'){
        guardarTurnoDiaX = false
      }

      if (this.state.cancelVersion === true){
        guardarTurnoDiaX = false
      }

      if (this.state.confirmaSeguirNoHayDiaAnterior === true){
         guardarTurnoDiaX = guardarTurnoDiaX
      }else{
         guardarTurnoDiaX = false
      }

      let bObservacion1 = false
      if (this.state.cReporteTurnoObservacionFirmante1 !== '' && this.state.cReporteTurnoObservacionFirmante1 !== null && this.state.cReporteTurnoObservacionFirmante1 !== undefined){
          bObservacion1 = true
      }
      let bObservacion2 = false
      if (this.state.cReporteTurnoObservacionJefeTurnoDia !== '' && this.state.cReporteTurnoObservacionJefeTurnoDia !== null && this.state.cReporteTurnoObservacionJefeTurnoDia !== undefined){
          bObservacion2 = true
      }

      let bOjo = false
      if (this.state.authRoleX === 'Jefe Turno MD' || this.state.authRoleX === 'Supervisor MD' || this.state.authRoleX === 'Asesor HSE MD' ||
          this.state.authRoleX === 'Adm MD' || this.state.authRoleX === 'Operador MD' || this.props.userOrigin === this.props.username){
          bOjo = true
      }

      if (this.state.cReporteTurnoSignatureFirmante1 === true ||
          this.state.cReporteTurnoSignatureFirmante3 === true ||
          this.state.cReporteTurnoSignatureFirmante4 === true ||
          this.state.cReporteTurnoSignatureFirmante5 === true ||
          this.state.cReporteTurnoSignatureFirmante6 === true){
          guardarTurnoDiaX = false
      }

      if (this.props.active_after_publishedX === 'false'){
        guardarTurnoDiaX = false
      }

      return (
        <div>
          <Table size="sm" borderless responsive >
            <thead>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "20%" }} onMouseEnter={()=>this.onMouseEnterHandler(1,'firma')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'firma')}>
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.firmar(e,1)}>
                     {this.state.firma_1 ?
                       <img src="images/reporteturno04Green.png" weight="60" height="60" alt=""/>
                     :
                       <img src="images/reporteturno04.png" weight="60" height="60" alt=""/>
                     }
                   </strong>
                </th>
                <th style={{ width: "20%" }} onMouseEnter={()=>this.onMouseEnterHandler(6,'firma')} onMouseLeave={()=>this.onMouseLeaveHandler(6,'firma')}>
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.firmar(e,6)}>
                     {this.state.firma_6 ?
                       <img src="images/reporteturno04Green.png" weight="60" height="60" alt=""/>
                     :
                       <img src="images/reporteturno04.png" weight="60" height="60" alt=""/>
                     }
                   </strong>
                </th>
                <th style={{ width: "40%" }}></th>
                {guardarTurnoDiaX ?
                  <>
                  <th style={{ width: "15%", color:'#3a3e41', textAlign: 'center', fontSize:16}} onMouseEnter={()=>this.onMouseEnterHandler(1,'guarda')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'guarda')}>
                     <strong style={{cursor: 'pointer'}} onClick={e=>this.guardarturnodia(e)}>
                       {this.state.guarda_1 ?
                         <img src="images/reporteturno05Green.png" weight="70" height="70" alt=""/>
                       :
                         <img src="images/reporteturno05.png" weight="70" height="70" alt=""/>
                       }
                     </strong>
                  </th>
                  {this.state.eliminarTurnoDiaX ?
                    <th style={{ width: "5%", color:'#3a3e41', textAlign: 'center', fontSize:16}} onMouseEnter={()=>this.onMouseEnterHandler(1,'elimina')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'elimina')}>
                       <strong style={{cursor: 'pointer'}} onClick={e=>this.eliminarturnodia(e)}>
                         {this.state.elimina_1 ?
                           <img src="images/deleteGreen.png" weight="35" height="35" alt=""/>
                         :
                           <img src="images/deleteRed.png" weight="35" height="35" alt=""/>
                         }
                       </strong>
                    </th>
                  : null
                  }
                  </>
                :
                  <th></th>
                }
              </tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "20%" }} onMouseEnter={()=>this.onMouseEnterHandler(1,'observacion')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'observacion')}>
                   SUPERVISOR DÍA
                   {bOjo ?
                     <strong style={{cursor: 'pointer'}} onClick={e=>this.observacion(e,1)}>
                       {this.state.observacion_1 ?
                         <>
                         {bObservacion1 ?
                             <img src="images/eyeGreen2.png" weight="40" height="40" alt=""/>
                         :
                             <img src="images/eyeGreen2.png" weight="30" height="30" alt=""/>
                         }
                         </>
                       :
                         <>
                         {bObservacion1 ?
                             <img src="images/eyeOrange.png" weight="40" height="40" alt=""/>
                         :
                             <img src="images/eyeGrey.png" weight="30" height="30" alt=""/>
                         }
                         </>
                       }
                     </strong>
                   : null }
                </th>
                <th style={{ width: "20%" }} onMouseEnter={()=>this.onMouseEnterHandler(8,'observacion')} onMouseLeave={()=>this.onMouseLeaveHandler(8,'observacion')}>
                   JEFE DE TURNO DIA
                   {bOjo ?
                     <strong style={{cursor: 'pointer'}} onClick={e=>this.observacion(e,8)}>
                       {this.state.observacion_8 ?
                         <>
                         {bObservacion2 ?
                             <img src="images/eyeGreen2.png" weight="40" height="40" alt=""/>
                         :
                             <img src="images/eyeGreen2.png" weight="30" height="30" alt=""/>
                         }
                         </>
                       :
                         <>
                         {bObservacion2 ?
                             <img src="images/eyeOrange.png" weight="40" height="40" alt=""/>
                         :
                             <img src="images/eyeGrey.png" weight="30" height="30" alt=""/>
                         }
                         </>
                       }
                     </strong>
                   : null }
                </th>
                <th style={{ width: "40%" }}></th>
                {guardarTurnoDiaX ?
                  <th style={{ width: "20%", color:'#3a3e41', textAlign: 'center', fontSize:16}}>
                     GUARDAR TURNO DIA
                  </th>
                :
                  <th></th>
                }
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              <tr></tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                {this.state.cReporteTurnoSignatureFirmante1 ?
                    <th style={{ width: "20%"}}>Firma: <img src={this.state.cReporteTurnotrimmedDataURLFirmante1} style={{ width:65, height:45}} /></th>
                :
                    <th style={{ width: "20%"}}>Firma: _________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante6 ?
                    <th style={{ width: "20%"}}>Firma: <img src={this.state.cReporteTurnotrimmedDataURLFirmante6} style={{ width:65, height:45}} /></th>
                :
                    <th style={{ width: "20%"}}>Firma: _________________________</th>
                }
              </tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                {this.state.cReporteTurnoSignatureFirmante1 ?
                    <th style={{ width: "20%"}}>Firma Digital: {signature1X} </th>
                :
                    <th style={{ width: "20%"}}>Firma Digital: _________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante6 ?
                    <th style={{ width: "20%"}}>Firma Digital: {signature6X} </th>
                :
                    <th style={{ width: "20%"}}>Firma Digital: _________________________</th>
                }
              </tr>
              <tr></tr>
              <tr></tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                {this.state.cReporteTurnoSignatureFirmante1 ?
                   <th style={{ width: "20%"}}>Nombre: {this.state.cReporteTurnoNameFirmante1}</th>
                :
                   <th style={{ width: "20%"}}>Nombre: ________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante6 ?
                   <th style={{ width: "20%"}}>Nombre: {this.state.cReporteTurnoNameFirmante6}</th>
                :
                   <th style={{ width: "20%"}}>Nombre: ________________________</th>
                }
              </tr>
              <tr></tr>
              <tr></tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                {this.state.cReporteTurnoSignatureFirmante1 ?
                  <th style={{ width: "20%"}}>Fecha: {this.state.cReporteTurnoDateTimeFirmante1}</th>
                :
                  <th style={{ width: "20%"}}>Fecha: _________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante6 ?
                  <th style={{ width: "20%"}}>Fecha: {this.state.cReporteTurnoDateTimeFirmante6}</th>
                :
                  <th style={{ width: "20%"}}>Fecha: _________________________</th>
                }
              </tr>
            </tbody>
          </Table>
        </div>
      )
    }

    displayGuardarTurnoNoche = () => {
      let signature2X = ''
      let signature7X = ''
      if (this.state.cReporteTurnoPublicKeyFirmante2.publicKey2 === '' || this.state.cReporteTurnoPublicKeyFirmante2.publicKey2 === undefined || this.state.cReporteTurnoPublicKeyFirmante2.publicKey2 === null ){
         signature2X = `...`
      }else{
         let pubkeyX = this.state.cReporteTurnoPublicKeyFirmante2.publicKey2
         signature2X = `${pubkeyX.substring(0,10)}...${pubkeyX.substring(pubkeyX.length-5)}`
      }
      if (this.state.cReporteTurnoPublicKeyFirmante7.publicKey2 === '' || this.state.cReporteTurnoPublicKeyFirmante7.publicKey2 === undefined || this.state.cReporteTurnoPublicKeyFirmante7.publicKey2 === null ){
         signature7X = `...`
      }else{
         let pubkeyX = this.state.cReporteTurnoPublicKeyFirmante7.publicKey2
         signature7X = `${pubkeyX.substring(0,10)}...${pubkeyX.substring(pubkeyX.length-5)}`
      }
      let guardarTurnoNocheX = true
      if (this.state.roleGenerateX || this.state.roleModifyX){
        guardarTurnoNocheX = true
      }

      if (this.state.authRoleX === 'Supervisor MD'){
        if (this.state.cReporteTurnoSignatureFirmante2 === true){
           guardarTurnoNocheX = false
        }
      }
      if (this.state.authRoleX === 'Adm MD'){
        if (this.state.cReporteTurnoSignatureFirmante3 === true){
           guardarTurnoNocheX = false
        }else{
           if (this.state.cReporteTurnoSignatureFirmante2 === false){
              guardarTurnoNocheX = true
           }else{
             guardarTurnoNocheX = false
           }
        }
      }
      if (this.state.authRoleX === 'Jefe Turno MD'){
        if (this.state.cReporteTurnoSignatureFirmante7 === true){
           guardarTurnoNocheX = false
        }else{
          if (this.state.cReporteTurnoSignatureFirmante2 === false){
             guardarTurnoNocheX = true
          }else{
             guardarTurnoNocheX = false
          }
        }
      }
      if (this.state.authRoleX !== 'Supervisor MD' && this.state.authRoleX !== 'Adm MD'  && this.state.authRoleX !== 'Jefe Turno MD'){
        guardarTurnoNocheX = false
      }

      if (this.state.cancelVersion === true){
        guardarTurnoNocheX = false
      }

      let bObservacion1 = false
      if (this.state.cReporteTurnoObservacionFirmante2 !== '' && this.state.cReporteTurnoObservacionFirmante2 !== null && this.state.cReporteTurnoObservacionFirmante2 !== undefined){
          bObservacion1 = true
      }
      let bObservacion2 = false
      if (this.state.cReporteTurnoObservacionJefeTurnoNoche !== '' && this.state.cReporteTurnoObservacionJefeTurnoNoche !== null && this.state.cReporteTurnoObservacionJefeTurnoNoche !== undefined){
          bObservacion2 = true
      }

      let bOjo = false
      if (this.state.authRoleX === 'Jefe Turno MD' || this.state.authRoleX === 'Supervisor MD' || this.state.authRoleX === 'Asesor HSE MD' ||
          this.state.authRoleX === 'Adm MD' || this.state.authRoleX === 'Operador MD' || this.props.userOrigin === this.props.username){
          bOjo = true
      }

      if (this.state.confirmaSeguirNoHayDiaAnterior === true){
         guardarTurnoNocheX = guardarTurnoNocheX
      }else{
         guardarTurnoNocheX = false
      }

      if (this.state.cReporteTurnoSignatureFirmante2 === true ||
          this.state.cReporteTurnoSignatureFirmante3 === true ||
          this.state.cReporteTurnoSignatureFirmante4 === true ||
          this.state.cReporteTurnoSignatureFirmante5 === true ||
          this.state.cReporteTurnoSignatureFirmante7 === true){
          guardarTurnoNocheX = false
      }

      if (this.props.active_after_publishedX === 'false'){
        guardarTurnoNocheX = false
      }

      return (
        <div>
          <Table borderless size="sm">
            <thead>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "20%" }} onMouseEnter={()=>this.onMouseEnterHandler(2,'firma')} onMouseLeave={()=>this.onMouseLeaveHandler(2,'firma')}>
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.firmar(e,2)}>
                     {this.state.firma_2 ?
                       <img src="images/reporteturno04Green.png" weight="60" height="60" alt=""/>
                     :
                       <img src="images/reporteturno04.png" weight="60" height="60" alt=""/>
                     }
                   </strong>
                </th>
                <th style={{ width: "20%" }} onMouseEnter={()=>this.onMouseEnterHandler(7,'firma')} onMouseLeave={()=>this.onMouseLeaveHandler(7,'firma')}>
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.firmar(e,7)}>
                     {this.state.firma_7 ?
                       <img src="images/reporteturno04Green.png" weight="60" height="60" alt=""/>
                     :
                       <img src="images/reporteturno04.png" weight="60" height="60" alt=""/>
                     }
                   </strong>
                </th>
                <th style={{ width: "40%" }}></th>
                {guardarTurnoNocheX ?
                  <>
                  <th style={{ width: "20%", color:'#3a3e41', textAlign: 'center', fontSize:16}} onMouseEnter={()=>this.onMouseEnterHandler(1,'guarda')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'guarda')}>
                     <strong style={{cursor: 'pointer'}} onClick={e=>this.guardarturnonoche(e)}>
                       {this.state.guarda_1 ?
                         <img src="images/reporteturno05Green.png" weight="70" height="70" alt=""/>
                       :
                         <img src="images/reporteturno05.png" weight="70" height="70" alt=""/>
                       }
                     </strong>
                  </th>
                  {this.state.eliminarTurnoNocheX ?
                    <th style={{ width: "5%", color:'#3a3e41', textAlign: 'center', fontSize:16}} onMouseEnter={()=>this.onMouseEnterHandler(1,'elimina')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'elimina')}>
                       <strong style={{cursor: 'pointer'}} onClick={e=>this.eliminarturnonoche(e)}>
                         {this.state.elimina_1 ?
                           <img src="images/deleteGreen.png" weight="35" height="35" alt=""/>
                         :
                           <img src="images/deleteRed.png" weight="35" height="35" alt=""/>
                         }
                       </strong>
                    </th>
                  : null
                  }
                  </>
                :
                  <th></th>
                }
              </tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "20%" }} onMouseEnter={()=>this.onMouseEnterHandler(2,'observacion')} onMouseLeave={()=>this.onMouseLeaveHandler(2,'observacion')}>
                   SUPERVISOR NOCHE
                   {bOjo ?
                     <strong style={{cursor: 'pointer'}} onClick={e=>this.observacion(e,2)}>
                       {this.state.observacion_2 ?
                         <>
                         {bObservacion1 ?
                             <img src="images/eyeGreen2.png" weight="40" height="40" alt=""/>
                         :
                             <img src="images/eyeGreen2.png" weight="30" height="30" alt=""/>
                         }
                         </>
                       :
                         <>
                         {bObservacion1 ?
                             <img src="images/eyeOrange.png" weight="40" height="40" alt=""/>
                         :
                             <img src="images/eyeGrey.png" weight="30" height="30" alt=""/>
                         }
                         </>
                       }
                     </strong>
                   : null }
                </th>
                <th style={{ width: "20%" }} onMouseEnter={()=>this.onMouseEnterHandler(9,'observacion')} onMouseLeave={()=>this.onMouseLeaveHandler(9,'observacion')}>
                   JEFE DE TURNO NOCHE
                   {bOjo ?
                     <strong style={{cursor: 'pointer'}} onClick={e=>this.observacion(e,9)}>
                       {this.state.observacion_9 ?
                         <>
                         {bObservacion2 ?
                             <img src="images/eyeGreen2.png" weight="40" height="40" alt=""/>
                         :
                             <img src="images/eyeGreen2.png" weight="30" height="30" alt=""/>
                         }
                         </>
                       :
                         <>
                         {bObservacion2 ?
                             <img src="images/eyeOrange.png" weight="40" height="40" alt=""/>
                         :
                             <img src="images/eyeGrey.png" weight="30" height="30" alt=""/>
                         }
                         </>
                       }
                     </strong>
                   : null }
                </th>
                <th style={{ width: "40%" }}></th>
                {guardarTurnoNocheX ?
                  <th style={{ width: "20%", color:'#3a3e41', textAlign: 'center', fontSize:16}}>
                     GUARDAR TURNO NOCHE
                  </th>
                :
                  <th></th>
                }
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              <tr></tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                {this.state.cReporteTurnoSignatureFirmante2 ?
                    <th style={{ width: "20%"}}>Firma: <img src={this.state.cReporteTurnotrimmedDataURLFirmante2} style={{ width:65, height:45}} /></th>
                :
                    <th style={{ width: "20%"}}>Firma: _________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante7 ?
                    <th style={{ width: "20%"}}>Firma: <img src={this.state.cReporteTurnotrimmedDataURLFirmante7} style={{ width:65, height:45}} /></th>
                :
                    <th style={{ width: "20%"}}>Firma: _________________________</th>
                }
              </tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                {this.state.cReporteTurnoSignatureFirmante2 ?
                    <th style={{ width: "20%"}}>Firma Digital: {signature2X} </th>
                :
                    <th style={{ width: "20%"}}>Firma Digital: _________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante7 ?
                    <th style={{ width: "20%"}}>Firma Digital: {signature7X} </th>
                :
                    <th style={{ width: "20%"}}>Firma Digital: _________________________</th>
                }
              </tr>
              <tr></tr>
              <tr></tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                {this.state.cReporteTurnoSignatureFirmante2 ?
                   <th style={{ width: "20%"}}>Nombre: {this.state.cReporteTurnoNameFirmante2}</th>
                :
                   <th style={{ width: "20%"}}>Nombre: ________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante7 ?
                   <th style={{ width: "20%"}}>Nombre: {this.state.cReporteTurnoNameFirmante7}</th>
                :
                   <th style={{ width: "20%"}}>Nombre: ________________________</th>
                }
              </tr>
              <tr></tr>
              <tr></tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                {this.state.cReporteTurnoSignatureFirmante2 ?
                  <th style={{ width: "20%"}}>Fecha: {this.state.cReporteTurnoDateTimeFirmante2}</th>
                :
                  <th style={{ width: "20%"}}>Fecha: _________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante7 ?
                  <th style={{ width: "20%"}}>Fecha: {this.state.cReporteTurnoDateTimeFirmante7}</th>
                :
                  <th style={{ width: "20%"}}>Fecha: _________________________</th>
                }
              </tr>
            </tbody>
          </Table>
        </div>
      )
    }

    onMouseEnterHandler = (i,mode) =>{
      this.setState({ firma_1: false,firma_2: false,firma_3: false,firma_4: false,firma_5: false, guarda_1: false, elimina_1: false, cabecera_1: false, cabecera_2: false, cabecera_3: false, observacion_1: false, observacion_2: false, observacion_3: false, observacion_4: false, observacion_5: false, observacion_6: false})
      if (mode === 'firma'){
        if (i === 1){this.setState({firma_1: true})}
        if (i === 2){this.setState({firma_2: true})}
        if (i === 3){this.setState({firma_3: true})}
        if (i === 4){this.setState({firma_4: true})}
        if (i === 5){this.setState({firma_5: true})}
        if (i === 6){this.setState({firma_6: true})}
        if (i === 7){this.setState({firma_7: true})}
      }
      if (mode === 'guarda'){
        if (i === 1){this.setState({guarda_1: true})}
      }
      if (mode === 'elimina'){
        if (i === 1){this.setState({elimina_1: true})}
      }
      if (mode === 'cabecera'){
        if (i === 1){this.setState({cabecera_1: true})}
        if (i === 2){this.setState({cabecera_2: true})}
        if (i === 3){this.setState({cabecera_3: true})}
      }
      if (mode === 'observacion'){
        if (i === 1){this.setState({observacion_1: true})}
        if (i === 2){this.setState({observacion_2: true})}
        if (i === 3){this.setState({observacion_3: true})}
        if (i === 4){this.setState({observacion_4: true})}
        if (i === 5){this.setState({observacion_5: true})}
        if (i === 6){this.setState({observacion_6: true})}
        if (i === 7){this.setState({observacion_7: true})}
        if (i === 8){this.setState({observacion_8: true})}
        if (i === 9){this.setState({observacion_9: true})}
      }
    }

    onMouseLeaveHandler = (i,mode) => {
      if (mode === 'firma'){
        if (i === 1){this.setState({firma_1: false})}
        if (i === 2){this.setState({firma_2: false})}
        if (i === 3){this.setState({firma_3: false})}
        if (i === 4){this.setState({firma_4: false})}
        if (i === 5){this.setState({firma_5: false})}
        if (i === 6){this.setState({firma_6: false})}
        if (i === 7){this.setState({firma_7: false})}
      }
      if (mode === 'guarda'){
        if (i === 1){this.setState({guarda_1: false})}
      }
      if (mode === 'elimina'){
        if (i === 1){this.setState({elimina_1: false})}
      }
      if (mode === 'cabecera'){
        if (i === 1){this.setState({cabecera_1: false})}
        if (i === 2){this.setState({cabecera_2: false})}
        if (i === 3){this.setState({cabecera_3: false})}
      }
      if (mode === 'observacion'){
        if (i === 1){this.setState({observacion_1: false})}
        if (i === 2){this.setState({observacion_2: false})}
        if (i === 3){this.setState({observacion_3: false})}
        if (i === 4){this.setState({observacion_4: false})}
        if (i === 5){this.setState({observacion_5: false})}
        if (i === 6){this.setState({observacion_6: false})}
        if (i === 7){this.setState({observacion_7: false})}
        if (i === 8){this.setState({observacion_8: false})}
        if (i === 9){this.setState({observacion_9: false})}
      }
    }

    displayFirma = () => {
      let vReporteTurnoDia04X = true
      if (this.state.vReporteTurnoDia04 === '' || this.state.vReporteTurnoDia04 === undefined || this.state.vReporteTurnoDia04 === 'undefined'){vReporteTurnoDia04X = false}
      let vReporteTurnoNoche04X = true
      if (this.state.vReporteTurnoNoche04 === '' || this.state.vReporteTurnoNoche04 === undefined || this.state.vReporteTurnoNoche04 === 'undefined' ){vReporteTurnoNoche04X = false}

      let signature3X = ''
      let signature4X = ''
      let signature5X = ''
      if (this.state.cReporteTurnoPublicKeyFirmante3.publicKey2 === '' || this.state.cReporteTurnoPublicKeyFirmante3.publicKey2 === undefined || this.state.cReporteTurnoPublicKeyFirmante3.publicKey2 === null ){
         signature3X = `...`
      }else{
         let pubkeyX = this.state.cReporteTurnoPublicKeyFirmante3.publicKey2
         signature3X = `${pubkeyX.substring(0,10)}...${pubkeyX.substring(pubkeyX.length-5)}`
      }
      if (this.state.cReporteTurnoPublicKeyFirmante4.publicKey2 === '' || this.state.cReporteTurnoPublicKeyFirmante4.publicKey2 === undefined || this.state.cReporteTurnoPublicKeyFirmante4.publicKey2 === null ){
         signature4X = `...`
      }else{
         let pubkeyX = this.state.cReporteTurnoPublicKeyFirmante4.publicKey2
         signature4X = `${pubkeyX.substring(0,10)}...${pubkeyX.substring(pubkeyX.length-5)}`
      }
      if (this.state.cReporteTurnoPublicKeyFirmante5.publicKey2 === '' || this.state.cReporteTurnoPublicKeyFirmante5.publicKey2 === undefined || this.state.cReporteTurnoPublicKeyFirmante5.publicKey2 === null ){
         signature5X = `...`
      }else{
         let pubkeyX = this.state.cReporteTurnoPublicKeyFirmante5.publicKey2
         signature5X = `${pubkeyX.substring(0,10)}...${pubkeyX.substring(pubkeyX.length-5)}`
      }

      let bObservacion1 = false
      if (this.state.cReporteTurnoObservacionFirmante3 !== '' && this.state.cReporteTurnoObservacionFirmante3 !== null && this.state.cReporteTurnoObservacionFirmante3 !== undefined){
          bObservacion1 = true
      }
      let bObservacion2 = false
      if (this.state.cReporteTurnoObservacionFirmante4 !== '' && this.state.cReporteTurnoObservacionFirmante4 !== null && this.state.cReporteTurnoObservacionFirmante4 !== undefined){
          bObservacion2 = true
      }
      let bObservacion3 = false
      if (this.state.cReporteTurnoObservacionFirmante5 !== '' && this.state.cReporteTurnoObservacionFirmante5 !== null && this.state.cReporteTurnoObservacionFirmante5 !== undefined){
          bObservacion3 = true
      }

      return (
        <div>
          <Table size="sm" borderless responsive >
            <thead>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "33%" }} onMouseEnter={()=>this.onMouseEnterHandler(4,'firma')} onMouseLeave={()=>this.onMouseLeaveHandler(4,'firma')}>
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.firmar(e,4)}>
                     {this.state.firma_4 ?
                       <img src="images/reporteturno04Green.png" weight="60" height="60" alt=""/>
                     :
                       <img src="images/reporteturno04.png" weight="60" height="60" alt=""/>
                     }
                   </strong>
                </th>
                <th style={{ width: "33%" }} onMouseEnter={()=>this.onMouseEnterHandler(3,'firma')} onMouseLeave={()=>this.onMouseLeaveHandler(3,'firma')}>
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.firmar(e,3)}>
                     {this.state.firma_3 ?
                       <img src="images/reporteturno04Green.png" weight="60" height="60" alt=""/>
                     :
                       <img src="images/reporteturno04.png" weight="60" height="60" alt=""/>
                     }
                   </strong>
                </th>
                <th style={{ width: "33%" }} onMouseEnter={()=>this.onMouseEnterHandler(5,'firma')} onMouseLeave={()=>this.onMouseLeaveHandler(5,'firma')}>
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.firmar(e,5)}>
                     {this.state.firma_5 ?
                       <img src="images/reporteturno04Green.png" weight="60" height="60" alt=""/>
                     :
                       <img src="images/reporteturno04.png" weight="60" height="60" alt=""/>
                     }
                   </strong>
                </th>
              </tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                <th style={{ width: "33%" }} onMouseEnter={()=>this.onMouseEnterHandler(4,'observacion')} onMouseLeave={()=>this.onMouseLeaveHandler(4,'observacion')}>
                   JEFE TURNO CONTROL SONDAJE
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.observacion(e,4)}>
                     {this.state.observacion_4 ?
                       <>
                       {bObservacion2 ?
                           <img src="images/eyeGreen2.png" weight="40" height="40" alt=""/>
                       :
                           <img src="images/eyeGreen2.png" weight="30" height="30" alt=""/>
                       }
                       </>
                     :
                       <>
                       {bObservacion2 ?
                           <img src="images/eyeOrange.png" weight="40" height="40" alt=""/>
                       :
                           <img src="images/eyeGrey.png" weight="30" height="30" alt=""/>
                       }
                       </>
                     }
                   </strong>
                </th>
                <th style={{ width: "33%" }} onMouseEnter={()=>this.onMouseEnterHandler(3,'observacion')} onMouseLeave={()=>this.onMouseLeaveHandler(3,'observacion')}>
                   ADM CTO MINERAL DRILLING
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.observacion(e,3)}>
                     {this.state.observacion_3 ?
                       <>
                       {bObservacion1 ?
                           <img src="images/eyeGreen2.png" weight="40" height="40" alt=""/>
                       :
                           <img src="images/eyeGreen2.png" weight="30" height="30" alt=""/>
                       }
                       </>
                     :
                       <>
                       {bObservacion1 ?
                           <img src="images/eyeOrange.png" weight="40" height="40" alt=""/>
                       :
                           <img src="images/eyeGrey.png" weight="30" height="30" alt=""/>
                       }
                       </>
                     }
                   </strong>
                </th>
                <th style={{ width: "33%" }} onMouseEnter={()=>this.onMouseEnterHandler(5,'observacion')} onMouseLeave={()=>this.onMouseLeaveHandler(5,'observacion')}>
                   ADM. CTO. ANGLO AMERICAN
                   <strong style={{cursor: 'pointer'}} onClick={e=>this.observacion(e,5)}>
                     {this.state.observacion_5 ?
                       <>
                       {bObservacion3 ?
                           <img src="images/eyeGreen2.png" weight="40" height="40" alt=""/>
                       :
                           <img src="images/eyeGreen2.png" weight="30" height="30" alt=""/>
                       }
                       </>
                     :
                       <>
                       {bObservacion3 ?
                           <img src="images/eyeOrange.png" weight="40" height="40" alt=""/>
                       :
                           <img src="images/eyeGrey.png" weight="30" height="30" alt=""/>
                       }
                       </>
                     }
                   </strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              <tr></tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                {this.state.cReporteTurnoSignatureFirmante4 ?
                    <th style={{ width: "33%"}}>Firma: <img src={this.state.cReporteTurnotrimmedDataURLFirmante4} style={{ width:65, height:45}} /></th>
                :
                    <th style={{ width: "33%"}}>Firma: _________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante3 ?
                    <th style={{ width: "33%"}}>Firma: <img src={this.state.cReporteTurnotrimmedDataURLFirmante3} style={{ width:65, height:45}} /></th>
                :
                    <th style={{ width: "33%"}}>Firma: _________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante5 ?
                    <th style={{ width: "33%"}}>Firma: <img src={this.state.cReporteTurnotrimmedDataURLFirmante5} style={{ width:65, height:45}} /></th>
                :
                    <th style={{ width: "33%"}}>Firma: _________________________</th>
                }
              </tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                {this.state.cReporteTurnoSignatureFirmante4 ?
                    <th style={{ width: "33%"}}>Firma Digital: {signature4X} </th>
                :
                    <th style={{ width: "33%"}}>Firma Digital: _________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante3 ?
                    <th style={{ width: "33%"}}>Firma Digital: {signature3X} </th>
                :
                    <th style={{ width: "33%"}}>Firma Digital: _________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante5 ?
                    <th style={{ width: "33%"}}>Firma Digital: {signature5X} </th>
                :
                    <th style={{ width: "33%"}}>Firma Digital: _________________________</th>
                }
              </tr>
              <tr></tr>
              <tr></tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                {this.state.cReporteTurnoSignatureFirmante4 ?
                   <th style={{ width: "33%"}}>Nombre: {this.state.cReporteTurnoNameFirmante4}</th>
                :
                   <th style={{ width: "33%"}}>Nombre: ________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante3 ?
                   <th style={{ width: "33%"}}>Nombre: {this.state.cReporteTurnoNameFirmante3}</th>
                :
                   <th style={{ width: "33%"}}>Nombre: ________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante5 ?
                   <th style={{ width: "33%"}}>Nombre: {this.state.cReporteTurnoNameFirmante5}</th>
                :
                   <th style={{ width: "33%"}}>Nombre: ________________________</th>
                }
              </tr>
              <tr></tr>
              <tr></tr>
              <tr style={{ color:'#3a3e41', textAlign: 'center', fontSize:11}} >
                {this.state.cReporteTurnoSignatureFirmante4 ?
                  <th style={{ width: "33%"}}>Fecha: {this.state.cReporteTurnoDateTimeFirmante4}</th>
                :
                  <th style={{ width: "33%"}}>Fecha: _________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante3 ?
                  <th style={{ width: "33%"}}>Fecha: {this.state.cReporteTurnoDateTimeFirmante3}</th>
                :
                  <th style={{ width: "33%"}}>Fecha: _________________________</th>
                }
                {this.state.cReporteTurnoSignatureFirmante5 ?
                  <th style={{ width: "33%"}}>Fecha: {this.state.cReporteTurnoDateTimeFirmante5}</th>
                :
                  <th style={{ width: "33%"}}>Fecha: _________________________</th>
                }
              </tr>
            </tbody>
          </Table>
        </div>
      )
    }

    guardarturnodia = () => {
      this.setState({dialogOpen: true, dialogMessage: '¿Desea guardar el Reporte de Turno Día?. Al guardar usted asumirá los datos.', dialogTitle: 'Guardar Reporte Turno Día' })
    }

    guardarturnonoche = () => {
      this.setState({dialogOpen: true, dialogMessage: '¿Desea guardar el Reporte de Turno Noche?. Al guardar usted asumirá los datos.', dialogTitle: 'Guardar Reporte Turno Noche' })
    }

    eliminarturnodia = () => {
      this.setState({dialogOpen: true, dialogMessage: '¿Desea eliminar el Reporte de Turno Día?. Al eliminar usted borrará los datos.', dialogTitle: 'Eliminar Reporte Turno Día' })
    }

    eliminarturnonoche = () => {
      this.setState({dialogOpen: true, dialogMessage: '¿Desea eliminar el Reporte de Turno Noche?. Al eliminar usted borrará los datos.', dialogTitle: 'Eliminar Reporte Turno Noche' })
    }

    cerrarturno = () => {
      this.setState({dialogOpen: true, dialogMessage: '¿Desea cerrar el Reporte de Turno Día y Noche?. Al cerrar no será posible modificar el reporte', dialogTitle: 'Cerrar Reporte Turno' })
    }

    guardar3 = (opcionObservacion) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      let archivoX = ''
      let jsonAttachFile = ''

      if (opcionObservacion  === 1){
          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_observacion_supervisor_dia.json`
          jsonAttachFile = {"cReporteTurnoObservacion":this.state.cReporteTurnoObservacionFirmante1}
      }
      if (opcionObservacion  === 2){
          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_observacion_supervisor_noche.json`
          jsonAttachFile = {"cReporteTurnoObservacion":this.state.cReporteTurnoObservacionFirmante2}
      }
      if (opcionObservacion  === 3){
          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_observacion_adm_cto_md.json`
          jsonAttachFile = {"cReporteTurnoObservacion":this.state.cReporteTurnoObservacionFirmante3}
      }
      if (opcionObservacion  === 4){
          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_observacion_control_gestion.json`
          jsonAttachFile = {"cReporteTurnoObservacion":this.state.cReporteTurnoObservacionFirmante4}
      }
      if (opcionObservacion  === 5){
          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_observacion_adm_cto_aa.json`
          jsonAttachFile = {"cReporteTurnoObservacion":this.state.cReporteTurnoObservacionFirmante5}
      }
      if (opcionObservacion  === 6){
          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_observacion_asesor_she_turno_dia.json`
          jsonAttachFile = {"cReporteTurnoObservacion":this.state.cReporteTurnoObservacionAsesorTurnoDia}
      }
      if (opcionObservacion  === 7){
          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_observacion_asesor_she_turno_noche.json`
          jsonAttachFile = {"cReporteTurnoObservacion":this.state.cReporteTurnoObservacionAsesorTurnoNoche}
      }
      if (opcionObservacion  === 8){
          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_observacion_jefe_turno_dia.json`
          jsonAttachFile = {"cReporteTurnoObservacion":this.state.cReporteTurnoObservacionJefeTurnoDia}
      }
      if (opcionObservacion  === 9){
          archivoX = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_observacion_jefe_turno_noche.json`
          jsonAttachFile = {"cReporteTurnoObservacion":this.state.cReporteTurnoObservacionJefeTurnoNoche}
      }
      putFileDB(this.props.username, archivoX, 'Crosscheck_Deliverable', JSON.stringify(jsonAttachFile), JSON.stringify(options))
          .then(() => {
      })
    }

    exportReporteTurno = (modeX) => {

      let palabra = ''
      let ArrayIdValue = []

      let cDia04 = ''
      let cDia01 = ''
      let cDia05 = ''
      let cDia12 = ''
      let cDia13 = ''
      if (this.cReporteTurnoDia04.current.value !== ''){palabra = this.cReporteTurnoDia04.current.value; ArrayIdValue = palabra.split('&&&'); cDia04 = ArrayIdValue[1]}
      if (this.cReporteTurnoDia01.current.value !== ''){palabra = this.cReporteTurnoDia01.current.value; ArrayIdValue = palabra.split('&&&'); cDia01 = ArrayIdValue[1]}
      if (this.cReporteTurnoDia05.current.value !== ''){palabra = this.cReporteTurnoDia05.current.value; ArrayIdValue = palabra.split('&&&'); cDia05 = ArrayIdValue[1]}
      if (this.cReporteTurnoDia12.current.value !== ''){palabra = this.cReporteTurnoDia12.current.value; ArrayIdValue = palabra.split('&&&'); cDia12 = ArrayIdValue[1]}
      if (this.cReporteTurnoDia13.current.value !== ''){palabra = this.cReporteTurnoDia13.current.value; ArrayIdValue = palabra.split('&&&'); cDia13 = ArrayIdValue[1]}
      let cDia06 = this.cReporteTurnoDia06.current.value;
      let cDia07 = this.cReporteTurnoDia07.current.value;
      let cDia08 = this.cReporteTurnoDia08.current.value;
      let cDia02 = this.cReporteTurnoDia02.current.value;
      let cDia03 = this.cReporteTurnoDia03.current.value;
      let cDia09 = this.cReporteTurnoDia09.current.value;
      let cDia10 = this.cReporteTurnoDia10.current.value;
      let cDia11 = this.cReporteTurnoDia11.current.value;


      palabra = this.cReporteTurnoEstadoSondaje.current.value; ArrayIdValue = palabra.split('&&&'); let cEstadoSondaje = ArrayIdValue[0];

      let c08a09_01 = ''
      if (this.cReporteTurnoDia08a09_01.current.value !== ''){palabra = this.cReporteTurnoDia08a09_01.current.value; ArrayIdValue = palabra.split('&&&'); c08a09_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia08a09_02.current.value; ArrayIdValue = palabra.split('&&&'); let c08a09_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia08a09_03.current.value; ArrayIdValue = palabra.split('&&&'); let c08a09_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia08a09_06.current.value; ArrayIdValue = palabra.split('&&&'); let c08a09_06 = ArrayIdValue[0];
      let c08a09_07 = this.cReporteTurnoDia08a09_07.current.value;
      palabra = this.cReporteTurnoDia08a09_04.current.value; ArrayIdValue = palabra.split('&&&'); let c08a09_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia08a09_05.current.value; ArrayIdValue = palabra.split('&&&'); let c08a09_05 = ArrayIdValue[0];
      let c08a09_13 = this.cReporteTurnoDia08a09_13.current.value;
      let c08a09_08 = this.cReporteTurnoDia08a09_08.current.value;
      let c08a09_09 = this.cReporteTurnoDia08a09_09.current.value;
      let c08a09_10 = this.cReporteTurnoDia08a09_10.current.value;
      let c08a09_11 = ''
      if (this.cReporteTurnoDia08a09_11.current.value === 'X'){c08a09_11 = '0.5'}
      let c08a09_12 = ''
      if (this.cReporteTurnoDia08a09_12.current.value === 'X'){c08a09_12 = '0.5'}

      let c08a09_01_2 = ''
      if (this.cReporteTurnoDia08a09_01_2.current.value !== ''){palabra = this.cReporteTurnoDia08a09_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c08a09_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia08a09_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c08a09_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia08a09_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c08a09_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia08a09_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c08a09_06_2 = ArrayIdValue[0];
      let c08a09_07_2 = this.cReporteTurnoDia08a09_07_2.current.value;
      palabra = this.cReporteTurnoDia08a09_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c08a09_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia08a09_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c08a09_05_2 = ArrayIdValue[0];
      let c08a09_13_2 = this.cReporteTurnoDia08a09_13_2.current.value;
      let c08a09_08_2 = this.cReporteTurnoDia08a09_08_2.current.value;
      let c08a09_09_2 = this.cReporteTurnoDia08a09_09_2.current.value;
      let c08a09_10_2 = this.cReporteTurnoDia08a09_10_2.current.value;
      let c08a09_11_2 = ''
      if (this.cReporteTurnoDia08a09_11_2.current.value === 'X'){c08a09_11_2 = '0.5'}
      let c08a09_12_2 = ''
      if (this.cReporteTurnoDia08a09_12_2.current.value === 'X'){c08a09_12_2 = '0.5'}

      let c09a10_01 = ''
      if (this.cReporteTurnoDia09a10_01.current.value !== ''){palabra = this.cReporteTurnoDia09a10_01.current.value; ArrayIdValue = palabra.split('&&&'); c09a10_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia09a10_02.current.value; ArrayIdValue = palabra.split('&&&'); let c09a10_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia09a10_03.current.value; ArrayIdValue = palabra.split('&&&'); let c09a10_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia09a10_06.current.value; ArrayIdValue = palabra.split('&&&'); let c09a10_06 = ArrayIdValue[0];
      let c09a10_07 = this.cReporteTurnoDia09a10_07.current.value;
      palabra = this.cReporteTurnoDia09a10_04.current.value; ArrayIdValue = palabra.split('&&&'); let c09a10_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia09a10_05.current.value; ArrayIdValue = palabra.split('&&&'); let c09a10_05 = ArrayIdValue[0];
      let c09a10_13 = this.cReporteTurnoDia09a10_13.current.value;
      let c09a10_08 = this.cReporteTurnoDia09a10_08.current.value;
      let c09a10_09 = this.cReporteTurnoDia09a10_09.current.value;
      let c09a10_10 = this.cReporteTurnoDia09a10_10.current.value;
      let c09a10_11 = ''
      if (this.cReporteTurnoDia09a10_11.current.value === 'X'){c09a10_11 = '0.5'}
      let c09a10_12 = ''
      if (this.cReporteTurnoDia09a10_12.current.value === 'X'){c09a10_12 = '0.5'}

      let c09a10_01_2 = ''
      if (this.cReporteTurnoDia09a10_01_2.current.value !== ''){palabra = this.cReporteTurnoDia09a10_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c09a10_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia09a10_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c09a10_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia09a10_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c09a10_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia09a10_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c09a10_06_2 = ArrayIdValue[0];
      let c09a10_07_2 = this.cReporteTurnoDia09a10_07_2.current.value;
      palabra = this.cReporteTurnoDia09a10_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c09a10_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia09a10_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c09a10_05_2 = ArrayIdValue[0];
      let c09a10_13_2 = this.cReporteTurnoDia09a10_13_2.current.value;
      let c09a10_08_2 = this.cReporteTurnoDia09a10_08_2.current.value;
      let c09a10_09_2 = this.cReporteTurnoDia09a10_09_2.current.value;
      let c09a10_10_2 = this.cReporteTurnoDia09a10_10_2.current.value;
      let c09a10_11_2 = ''
      if (this.cReporteTurnoDia09a10_11_2.current.value === 'X'){c09a10_11_2 = '0.5'}
      let c09a10_12_2 = ''
      if (this.cReporteTurnoDia09a10_12_2.current.value === 'X'){c09a10_12_2 = '0.5'}

      let c10a11_01 = ''
      if (this.cReporteTurnoDia10a11_01.current.value !== ''){palabra = this.cReporteTurnoDia10a11_01.current.value; ArrayIdValue = palabra.split('&&&'); c10a11_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia10a11_02.current.value; ArrayIdValue = palabra.split('&&&'); let c10a11_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia10a11_03.current.value; ArrayIdValue = palabra.split('&&&'); let c10a11_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia10a11_06.current.value; ArrayIdValue = palabra.split('&&&'); let c10a11_06 = ArrayIdValue[0];
      let c10a11_07 = this.cReporteTurnoDia10a11_07.current.value;
      palabra = this.cReporteTurnoDia10a11_04.current.value; ArrayIdValue = palabra.split('&&&'); let c10a11_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia10a11_05.current.value; ArrayIdValue = palabra.split('&&&'); let c10a11_05 = ArrayIdValue[0];
      let c10a11_13 = this.cReporteTurnoDia10a11_13.current.value;
      let c10a11_08 = this.cReporteTurnoDia10a11_08.current.value;
      let c10a11_09 = this.cReporteTurnoDia10a11_09.current.value;
      let c10a11_10 = this.cReporteTurnoDia10a11_10.current.value;
      let c10a11_11 = ''
      if (this.cReporteTurnoDia10a11_11.current.value === 'X'){c10a11_11 = '0.5'}
      let c10a11_12 = ''
      if (this.cReporteTurnoDia10a11_12.current.value === 'X'){c10a11_12 = '0.5'}

      let c10a11_01_2 = ''
      if (this.cReporteTurnoDia10a11_01_2.current.value !== ''){palabra = this.cReporteTurnoDia10a11_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c10a11_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia10a11_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c10a11_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia10a11_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c10a11_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia10a11_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c10a11_06_2 = ArrayIdValue[0];
      let c10a11_07_2 = this.cReporteTurnoDia10a11_07_2.current.value;
      palabra = this.cReporteTurnoDia10a11_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c10a11_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia10a11_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c10a11_05_2 = ArrayIdValue[0];
      let c10a11_13_2 = this.cReporteTurnoDia10a11_13_2.current.value;
      let c10a11_08_2 = this.cReporteTurnoDia10a11_08_2.current.value;
      let c10a11_09_2 = this.cReporteTurnoDia10a11_09_2.current.value;
      let c10a11_10_2 = this.cReporteTurnoDia10a11_10_2.current.value;
      let c10a11_11_2 = ''
      if (this.cReporteTurnoDia10a11_11_2.current.value === 'X'){c10a11_11_2 = '0.5'}
      let c10a11_12_2 = ''
      if (this.cReporteTurnoDia10a11_12_2.current.value === 'X'){c10a11_12_2 = '0.5'}

      let c11a12_01 = ''
      if (this.cReporteTurnoDia11a12_01.current.value !== ''){palabra = this.cReporteTurnoDia11a12_01.current.value; ArrayIdValue = palabra.split('&&&'); c11a12_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia11a12_02.current.value; ArrayIdValue = palabra.split('&&&'); let c11a12_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia11a12_03.current.value; ArrayIdValue = palabra.split('&&&'); let c11a12_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia11a12_06.current.value; ArrayIdValue = palabra.split('&&&'); let c11a12_06 = ArrayIdValue[0];
      let c11a12_07 = this.cReporteTurnoDia11a12_07.current.value;
      palabra = this.cReporteTurnoDia11a12_04.current.value; ArrayIdValue = palabra.split('&&&'); let c11a12_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia11a12_05.current.value; ArrayIdValue = palabra.split('&&&'); let c11a12_05 = ArrayIdValue[0];
      let c11a12_13 = this.cReporteTurnoDia11a12_13.current.value;
      let c11a12_08 = this.cReporteTurnoDia11a12_08.current.value;
      let c11a12_09 = this.cReporteTurnoDia11a12_09.current.value;
      let c11a12_10 = this.cReporteTurnoDia11a12_10.current.value;
      let c11a12_11 = ''
      if (this.cReporteTurnoDia11a12_11.current.value === 'X'){c11a12_11 = '0.5'}
      let c11a12_12 = ''
      if (this.cReporteTurnoDia11a12_12.current.value === 'X'){c11a12_12 = '0.5'}

      let c11a12_01_2 = ''
      if (this.cReporteTurnoDia11a12_01_2.current.value !== ''){palabra = this.cReporteTurnoDia11a12_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c11a12_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia11a12_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c11a12_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia11a12_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c11a12_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia11a12_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c11a12_06_2 = ArrayIdValue[0];
      let c11a12_07_2 = this.cReporteTurnoDia11a12_07_2.current.value;
      palabra = this.cReporteTurnoDia11a12_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c11a12_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia11a12_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c11a12_05_2 = ArrayIdValue[0];
      let c11a12_13_2 = this.cReporteTurnoDia11a12_13_2.current.value;
      let c11a12_08_2 = this.cReporteTurnoDia11a12_08_2.current.value;
      let c11a12_09_2 = this.cReporteTurnoDia11a12_09_2.current.value;
      let c11a12_10_2 = this.cReporteTurnoDia11a12_10_2.current.value;
      let c11a12_11_2 = ''
      if (this.cReporteTurnoDia11a12_11_2.current.value === 'X'){c11a12_11_2 = '0.5'}
      let c11a12_12_2 = ''
      if (this.cReporteTurnoDia11a12_12_2.current.value === 'X'){c11a12_12_2 = '0.5'}

      let c12a13_01 = ''
      if (this.cReporteTurnoDia12a13_01.current.value !== ''){palabra = this.cReporteTurnoDia12a13_01.current.value; ArrayIdValue = palabra.split('&&&'); c12a13_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia12a13_02.current.value; ArrayIdValue = palabra.split('&&&'); let c12a13_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia12a13_03.current.value; ArrayIdValue = palabra.split('&&&'); let c12a13_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia12a13_06.current.value; ArrayIdValue = palabra.split('&&&'); let c12a13_06 = ArrayIdValue[0];
      let c12a13_07 = this.cReporteTurnoDia12a13_07.current.value;
      palabra = this.cReporteTurnoDia12a13_04.current.value; ArrayIdValue = palabra.split('&&&'); let c12a13_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia12a13_05.current.value; ArrayIdValue = palabra.split('&&&'); let c12a13_05 = ArrayIdValue[0];
      let c12a13_13 = this.cReporteTurnoDia12a13_13.current.value;
      let c12a13_08 = this.cReporteTurnoDia12a13_08.current.value;
      let c12a13_09 = this.cReporteTurnoDia12a13_09.current.value;
      let c12a13_10 = this.cReporteTurnoDia12a13_10.current.value;
      let c12a13_11 = ''
      if (this.cReporteTurnoDia12a13_11.current.value === 'X'){c12a13_11 = '0.5'}
      let c12a13_12 = ''
      if (this.cReporteTurnoDia12a13_12.current.value === 'X'){c12a13_12 = '0.5'}

      let c12a13_01_2 = ''
      if (this.cReporteTurnoDia12a13_01_2.current.value !== ''){palabra = this.cReporteTurnoDia12a13_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c12a13_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia12a13_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c12a13_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia12a13_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c12a13_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia12a13_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c12a13_06_2 = ArrayIdValue[0];
      let c12a13_07_2 = this.cReporteTurnoDia12a13_07_2.current.value;
      palabra = this.cReporteTurnoDia12a13_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c12a13_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia12a13_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c12a13_05_2 = ArrayIdValue[0];
      let c12a13_13_2 = this.cReporteTurnoDia12a13_13_2.current.value;
      let c12a13_08_2 = this.cReporteTurnoDia12a13_08_2.current.value;
      let c12a13_09_2 = this.cReporteTurnoDia12a13_09_2.current.value;
      let c12a13_10_2 = this.cReporteTurnoDia12a13_10_2.current.value;
      let c12a13_11_2 = ''
      if (this.cReporteTurnoDia12a13_11_2.current.value === 'X'){c12a13_11_2 = '0.5'}
      let c12a13_12_2 = ''
      if (this.cReporteTurnoDia12a13_12_2.current.value === 'X'){c12a13_12_2 = '0.5'}

      let c13a14_01 = ''
      if (this.cReporteTurnoDia13a14_01.current.value !== ''){palabra = this.cReporteTurnoDia13a14_01.current.value; ArrayIdValue = palabra.split('&&&'); c13a14_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia13a14_02.current.value; ArrayIdValue = palabra.split('&&&'); let c13a14_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia13a14_03.current.value; ArrayIdValue = palabra.split('&&&'); let c13a14_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia13a14_06.current.value; ArrayIdValue = palabra.split('&&&'); let c13a14_06 = ArrayIdValue[0];
      let c13a14_07 = this.cReporteTurnoDia13a14_07.current.value;
      palabra = this.cReporteTurnoDia13a14_04.current.value; ArrayIdValue = palabra.split('&&&'); let c13a14_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia13a14_05.current.value; ArrayIdValue = palabra.split('&&&'); let c13a14_05 = ArrayIdValue[0];
      let c13a14_13 = this.cReporteTurnoDia13a14_13.current.value;
      let c13a14_08 = this.cReporteTurnoDia13a14_08.current.value;
      let c13a14_09 = this.cReporteTurnoDia13a14_09.current.value;
      let c13a14_10 = this.cReporteTurnoDia13a14_10.current.value;
      let c13a14_11 = ''
      if (this.cReporteTurnoDia13a14_11.current.value === 'X'){c13a14_11 = '0.5'}
      let c13a14_12 = ''
      if (this.cReporteTurnoDia13a14_12.current.value === 'X'){c13a14_12 = '0.5'}

      let c13a14_01_2 = ''
      if (this.cReporteTurnoDia13a14_01_2.current.value !== ''){palabra = this.cReporteTurnoDia13a14_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c13a14_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia13a14_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c13a14_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia13a14_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c13a14_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia13a14_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c13a14_06_2 = ArrayIdValue[0];
      let c13a14_07_2 = this.cReporteTurnoDia13a14_07_2.current.value;
      palabra = this.cReporteTurnoDia13a14_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c13a14_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia13a14_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c13a14_05_2 = ArrayIdValue[0];
      let c13a14_13_2 = this.cReporteTurnoDia13a14_13_2.current.value;
      let c13a14_08_2 = this.cReporteTurnoDia13a14_08_2.current.value;
      let c13a14_09_2 = this.cReporteTurnoDia13a14_09_2.current.value;
      let c13a14_10_2 = this.cReporteTurnoDia13a14_10_2.current.value;
      let c13a14_11_2 = ''
      if (this.cReporteTurnoDia13a14_11_2.current.value === 'X'){c13a14_11_2 = '0.5'}
      let c13a14_12_2 = ''
      if (this.cReporteTurnoDia13a14_12_2.current.value === 'X'){c13a14_12_2 = '0.5'}

      let c14a15_01 = ''
      if (this.cReporteTurnoDia14a15_01.current.value !== ''){palabra = this.cReporteTurnoDia14a15_01.current.value; ArrayIdValue = palabra.split('&&&'); c14a15_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia14a15_02.current.value; ArrayIdValue = palabra.split('&&&'); let c14a15_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia14a15_03.current.value; ArrayIdValue = palabra.split('&&&'); let c14a15_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia14a15_06.current.value; ArrayIdValue = palabra.split('&&&'); let c14a15_06 = ArrayIdValue[0];
      let c14a15_07 = this.cReporteTurnoDia14a15_07.current.value;
      palabra = this.cReporteTurnoDia14a15_04.current.value; ArrayIdValue = palabra.split('&&&'); let c14a15_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia14a15_05.current.value; ArrayIdValue = palabra.split('&&&'); let c14a15_05 = ArrayIdValue[0];
      let c14a15_13 = this.cReporteTurnoDia14a15_13.current.value;
      let c14a15_08 = this.cReporteTurnoDia14a15_08.current.value;
      let c14a15_09 = this.cReporteTurnoDia14a15_09.current.value;
      let c14a15_10 = this.cReporteTurnoDia14a15_10.current.value;
      let c14a15_11 = ''
      if (this.cReporteTurnoDia14a15_11.current.value === 'X'){c14a15_11 = '0.5'}
      let c14a15_12 = ''
      if (this.cReporteTurnoDia14a15_12.current.value === 'X'){c14a15_12 = '0.5'}

      let c14a15_01_2 = ''
      if (this.cReporteTurnoDia14a15_01_2.current.value !== ''){palabra = this.cReporteTurnoDia14a15_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c14a15_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia14a15_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c14a15_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia14a15_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c14a15_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia14a15_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c14a15_06_2 = ArrayIdValue[0];
      let c14a15_07_2 = this.cReporteTurnoDia14a15_07_2.current.value;
      palabra = this.cReporteTurnoDia14a15_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c14a15_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia14a15_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c14a15_05_2 = ArrayIdValue[0];
      let c14a15_13_2 = this.cReporteTurnoDia14a15_13_2.current.value;
      let c14a15_08_2 = this.cReporteTurnoDia14a15_08_2.current.value;
      let c14a15_09_2 = this.cReporteTurnoDia14a15_09_2.current.value;
      let c14a15_10_2 = this.cReporteTurnoDia14a15_10_2.current.value;
      let c14a15_11_2 = ''
      if (this.cReporteTurnoDia14a15_11_2.current.value === 'X'){c14a15_11_2 = '0.5'}
      let c14a15_12_2 = ''
      if (this.cReporteTurnoDia14a15_12_2.current.value === 'X'){c14a15_12_2 = '0.5'}

      let c15a16_01 = ''
      if (this.cReporteTurnoDia15a16_01.current.value !== ''){palabra = this.cReporteTurnoDia15a16_01.current.value; ArrayIdValue = palabra.split('&&&'); c15a16_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia15a16_02.current.value; ArrayIdValue = palabra.split('&&&'); let c15a16_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia15a16_03.current.value; ArrayIdValue = palabra.split('&&&'); let c15a16_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia15a16_06.current.value; ArrayIdValue = palabra.split('&&&'); let c15a16_06 = ArrayIdValue[0];
      let c15a16_07 = this.cReporteTurnoDia15a16_07.current.value;
      palabra = this.cReporteTurnoDia15a16_04.current.value; ArrayIdValue = palabra.split('&&&'); let c15a16_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia15a16_05.current.value; ArrayIdValue = palabra.split('&&&'); let c15a16_05 = ArrayIdValue[0];
      let c15a16_13 = this.cReporteTurnoDia15a16_13.current.value;
      let c15a16_08 = this.cReporteTurnoDia15a16_08.current.value;
      let c15a16_09 = this.cReporteTurnoDia15a16_09.current.value;
      let c15a16_10 = this.cReporteTurnoDia15a16_10.current.value;
      let c15a16_11 = ''
      if (this.cReporteTurnoDia15a16_11.current.value === 'X'){c15a16_11 = '0.5'}
      let c15a16_12 = ''
      if (this.cReporteTurnoDia15a16_12.current.value === 'X'){c15a16_12 = '0.5'}

      let c15a16_01_2 = ''
      if (this.cReporteTurnoDia15a16_01_2.current.value !== ''){palabra = this.cReporteTurnoDia15a16_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c15a16_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia15a16_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c15a16_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia15a16_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c15a16_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia15a16_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c15a16_06_2 = ArrayIdValue[0];
      let c15a16_07_2 = this.cReporteTurnoDia15a16_07_2.current.value;
      palabra = this.cReporteTurnoDia15a16_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c15a16_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia15a16_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c15a16_05_2 = ArrayIdValue[0];
      let c15a16_13_2 = this.cReporteTurnoDia15a16_13_2.current.value;
      let c15a16_08_2 = this.cReporteTurnoDia15a16_08_2.current.value;
      let c15a16_09_2 = this.cReporteTurnoDia15a16_09_2.current.value;
      let c15a16_10_2 = this.cReporteTurnoDia15a16_10_2.current.value;
      let c15a16_11_2 = ''
      if (this.cReporteTurnoDia15a16_11_2.current.value === 'X'){c15a16_11_2 = '0.5'}
      let c15a16_12_2 = ''
      if (this.cReporteTurnoDia15a16_12_2.current.value === 'X'){c15a16_12_2 = '0.5'}

      let c16a17_01 = ''
      if (this.cReporteTurnoDia16a17_01.current.value !== ''){palabra = this.cReporteTurnoDia16a17_01.current.value; ArrayIdValue = palabra.split('&&&'); c16a17_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia16a17_02.current.value; ArrayIdValue = palabra.split('&&&'); let c16a17_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia16a17_03.current.value; ArrayIdValue = palabra.split('&&&'); let c16a17_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia16a17_06.current.value; ArrayIdValue = palabra.split('&&&'); let c16a17_06 = ArrayIdValue[0];
      let c16a17_07 = this.cReporteTurnoDia16a17_07.current.value;
      palabra = this.cReporteTurnoDia16a17_04.current.value; ArrayIdValue = palabra.split('&&&'); let c16a17_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia16a17_05.current.value; ArrayIdValue = palabra.split('&&&'); let c16a17_05 = ArrayIdValue[0];
      let c16a17_13 = this.cReporteTurnoDia16a17_13.current.value;
      let c16a17_08 = this.cReporteTurnoDia16a17_08.current.value;
      let c16a17_09 = this.cReporteTurnoDia16a17_09.current.value;
      let c16a17_10 = this.cReporteTurnoDia16a17_10.current.value;
      let c16a17_11 = ''
      if (this.cReporteTurnoDia16a17_11.current.value === 'X'){c16a17_11 = '0.5'}
      let c16a17_12 = ''
      if (this.cReporteTurnoDia16a17_12.current.value === 'X'){c16a17_12 = '0.5'}

      let c16a17_01_2 = ''
      if (this.cReporteTurnoDia16a17_01_2.current.value !== ''){palabra = this.cReporteTurnoDia16a17_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c16a17_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia16a17_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c16a17_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia16a17_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c16a17_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia16a17_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c16a17_06_2 = ArrayIdValue[0];
      let c16a17_07_2 = this.cReporteTurnoDia16a17_07_2.current.value;
      palabra = this.cReporteTurnoDia16a17_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c16a17_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia16a17_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c16a17_05_2 = ArrayIdValue[0];
      let c16a17_13_2 = this.cReporteTurnoDia16a17_13_2.current.value;
      let c16a17_08_2 = this.cReporteTurnoDia16a17_08_2.current.value;
      let c16a17_09_2 = this.cReporteTurnoDia16a17_09_2.current.value;
      let c16a17_10_2 = this.cReporteTurnoDia16a17_10_2.current.value;
      let c16a17_11_2 = ''
      if (this.cReporteTurnoDia16a17_11_2.current.value === 'X'){c16a17_11_2 = '0.5'}
      let c16a17_12_2 = ''
      if (this.cReporteTurnoDia16a17_12_2.current.value === 'X'){c16a17_12_2 = '0.5'}

      let c17a18_01 = ''
      if (this.cReporteTurnoDia17a18_01.current.value !== ''){palabra = this.cReporteTurnoDia17a18_01.current.value; ArrayIdValue = palabra.split('&&&'); c17a18_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia17a18_02.current.value; ArrayIdValue = palabra.split('&&&'); let c17a18_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia17a18_03.current.value; ArrayIdValue = palabra.split('&&&'); let c17a18_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia17a18_06.current.value; ArrayIdValue = palabra.split('&&&'); let c17a18_06 = ArrayIdValue[0];
      let c17a18_07 = this.cReporteTurnoDia17a18_07.current.value;
      palabra = this.cReporteTurnoDia17a18_04.current.value; ArrayIdValue = palabra.split('&&&'); let c17a18_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia17a18_05.current.value; ArrayIdValue = palabra.split('&&&'); let c17a18_05 = ArrayIdValue[0];
      let c17a18_13 = this.cReporteTurnoDia17a18_13.current.value;
      let c17a18_08 = this.cReporteTurnoDia17a18_08.current.value;
      let c17a18_09 = this.cReporteTurnoDia17a18_09.current.value;
      let c17a18_10 = this.cReporteTurnoDia17a18_10.current.value;
      let c17a18_11 = ''
      if (this.cReporteTurnoDia17a18_11.current.value === 'X'){c17a18_11 = '0.5'}
      let c17a18_12 = ''
      if (this.cReporteTurnoDia17a18_12.current.value === 'X'){c17a18_12 = '0.5'}

      let c17a18_01_2 = ''
      if (this.cReporteTurnoDia17a18_01_2.current.value !== ''){palabra = this.cReporteTurnoDia17a18_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c17a18_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia17a18_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c17a18_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia17a18_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c17a18_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia17a18_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c17a18_06_2 = ArrayIdValue[0];
      let c17a18_07_2 = this.cReporteTurnoDia17a18_07_2.current.value;
      palabra = this.cReporteTurnoDia17a18_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c17a18_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia17a18_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c17a18_05_2 = ArrayIdValue[0];
      let c17a18_13_2 = this.cReporteTurnoDia17a18_13_2.current.value;
      let c17a18_08_2 = this.cReporteTurnoDia17a18_08_2.current.value;
      let c17a18_09_2 = this.cReporteTurnoDia17a18_09_2.current.value;
      let c17a18_10_2 = this.cReporteTurnoDia17a18_10_2.current.value;
      let c17a18_11_2 = ''
      if (this.cReporteTurnoDia17a18_11_2.current.value === 'X'){c17a18_11_2 = '0.5'}
      let c17a18_12_2 = ''
      if (this.cReporteTurnoDia17a18_12_2.current.value === 'X'){c17a18_12_2 = '0.5'}

      let c18a19_01 = ''
      if (this.cReporteTurnoDia18a19_01.current.value !== ''){palabra = this.cReporteTurnoDia18a19_01.current.value; ArrayIdValue = palabra.split('&&&'); c18a19_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia18a19_02.current.value; ArrayIdValue = palabra.split('&&&'); let c18a19_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia18a19_03.current.value; ArrayIdValue = palabra.split('&&&'); let c18a19_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia18a19_06.current.value; ArrayIdValue = palabra.split('&&&'); let c18a19_06 = ArrayIdValue[0];
      let c18a19_07 = this.cReporteTurnoDia18a19_07.current.value;
      palabra = this.cReporteTurnoDia18a19_04.current.value; ArrayIdValue = palabra.split('&&&'); let c18a19_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia18a19_05.current.value; ArrayIdValue = palabra.split('&&&'); let c18a19_05 = ArrayIdValue[0];
      let c18a19_13 = this.cReporteTurnoDia18a19_13.current.value;
      let c18a19_08 = this.cReporteTurnoDia18a19_08.current.value;
      let c18a19_09 = this.cReporteTurnoDia18a19_09.current.value;
      let c18a19_10 = this.cReporteTurnoDia18a19_10.current.value;
      let c18a19_11 = ''
      if (this.cReporteTurnoDia18a19_11.current.value === 'X'){c18a19_11 = '0.5'}
      let c18a19_12 = ''
      if (this.cReporteTurnoDia18a19_12.current.value === 'X'){c18a19_12 = '0.5'}

      let c18a19_01_2 = ''
      if (this.cReporteTurnoDia18a19_01_2.current.value !== ''){palabra = this.cReporteTurnoDia18a19_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c18a19_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia18a19_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c18a19_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia18a19_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c18a19_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia18a19_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c18a19_06_2 = ArrayIdValue[0];
      let c18a19_07_2 = this.cReporteTurnoDia18a19_07_2.current.value;
      palabra = this.cReporteTurnoDia18a19_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c18a19_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia18a19_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c18a19_05_2 = ArrayIdValue[0];
      let c18a19_13_2 = this.cReporteTurnoDia18a19_13_2.current.value;
      let c18a19_08_2 = this.cReporteTurnoDia18a19_08_2.current.value;
      let c18a19_09_2 = this.cReporteTurnoDia18a19_09_2.current.value;
      let c18a19_10_2 = this.cReporteTurnoDia18a19_10_2.current.value;
      let c18a19_11_2 = ''
      if (this.cReporteTurnoDia18a19_11_2.current.value === 'X'){c18a19_11_2 = '0.5'}
      let c18a19_12_2 = ''
      if (this.cReporteTurnoDia18a19_12_2.current.value === 'X'){c18a19_12_2 = '0.5'}

      let c19a20_01 = ''
      if (this.cReporteTurnoDia19a20_01.current.value !== ''){palabra = this.cReporteTurnoDia19a20_01.current.value; ArrayIdValue = palabra.split('&&&'); c19a20_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia19a20_02.current.value; ArrayIdValue = palabra.split('&&&'); let c19a20_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia19a20_03.current.value; ArrayIdValue = palabra.split('&&&'); let c19a20_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia19a20_06.current.value; ArrayIdValue = palabra.split('&&&'); let c19a20_06 = ArrayIdValue[0];
      let c19a20_07 = this.cReporteTurnoDia19a20_07.current.value;
      palabra = this.cReporteTurnoDia19a20_04.current.value; ArrayIdValue = palabra.split('&&&'); let c19a20_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia19a20_05.current.value; ArrayIdValue = palabra.split('&&&'); let c19a20_05 = ArrayIdValue[0];
      let c19a20_13 = this.cReporteTurnoDia19a20_13.current.value;
      let c19a20_08 = this.cReporteTurnoDia19a20_08.current.value;
      let c19a20_09 = this.cReporteTurnoDia19a20_09.current.value;
      let c19a20_10 = this.cReporteTurnoDia19a20_10.current.value;
      let c19a20_11 = ''
      if (this.cReporteTurnoDia19a20_11.current.value === 'X'){c19a20_11 = '0.5'}
      let c19a20_12 = ''
      if (this.cReporteTurnoDia19a20_12.current.value === 'X'){c19a20_12 = '0.5'}

      let c19a20_01_2 = ''
      if (this.cReporteTurnoDia19a20_01_2.current.value !== ''){palabra = this.cReporteTurnoDia19a20_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c19a20_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoDia19a20_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c19a20_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia19a20_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c19a20_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia19a20_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c19a20_06_2 = ArrayIdValue[0];
      let c19a20_07_2 = this.cReporteTurnoDia19a20_07_2.current.value;
      palabra = this.cReporteTurnoDia19a20_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c19a20_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoDia19a20_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c19a20_05_2 = ArrayIdValue[0];
      let c19a20_13_2 = this.cReporteTurnoDia19a20_13_2.current.value;
      let c19a20_08_2 = this.cReporteTurnoDia19a20_08_2.current.value;
      let c19a20_09_2 = this.cReporteTurnoDia19a20_09_2.current.value;
      let c19a20_10_2 = this.cReporteTurnoDia19a20_10_2.current.value;
      let c19a20_11_2 = ''
      if (this.cReporteTurnoDia19a20_11_2.current.value === 'X'){c19a20_11_2 = '0.5'}
      let c19a20_12_2 = ''
      if (this.cReporteTurnoDia19a20_12_2.current.value === 'X'){c19a20_12_2 = '0.5'}


      let cNoche04 = ''
      let cNoche01 = ''
      let cNoche05 = ''
      let cNoche12 = ''
      let cNoche13 = ''
      if (this.cReporteTurnoNoche04.current.value !== ''){palabra = this.cReporteTurnoNoche04.current.value; ArrayIdValue = palabra.split('&&&'); cNoche04 = ArrayIdValue[1]}
      if (this.cReporteTurnoNoche01.current.value !== ''){palabra = this.cReporteTurnoNoche01.current.value; ArrayIdValue = palabra.split('&&&'); cNoche01 = ArrayIdValue[1]}
      if (this.cReporteTurnoNoche05.current.value !== ''){palabra = this.cReporteTurnoNoche05.current.value; ArrayIdValue = palabra.split('&&&'); cNoche05 = ArrayIdValue[1]}
      if (this.cReporteTurnoNoche12.current.value !== ''){palabra = this.cReporteTurnoNoche12.current.value; ArrayIdValue = palabra.split('&&&'); cNoche12 = ArrayIdValue[1]}
      if (this.cReporteTurnoNoche13.current.value !== ''){palabra = this.cReporteTurnoNoche13.current.value; ArrayIdValue = palabra.split('&&&'); cNoche13 = ArrayIdValue[1]}
      let cNoche06 = this.cReporteTurnoNoche06.current.value;
      let cNoche07 = this.cReporteTurnoNoche07.current.value;
      let cNoche08 = this.cReporteTurnoNoche08.current.value;
      let cNoche02 = this.cReporteTurnoNoche02.current.value;
      let cNoche03 = this.cReporteTurnoNoche03.current.value;
      let cNoche09 = this.cReporteTurnoNoche09.current.value;
      let cNoche10 = this.cReporteTurnoNoche10.current.value;
      let cNoche11 = this.cReporteTurnoNoche11.current.value;


      let c20a21_01 = ''
      if (this.cReporteTurnoNoche20a21_01.current.value !== ''){palabra = this.cReporteTurnoNoche20a21_01.current.value; ArrayIdValue = palabra.split('&&&'); c20a21_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche20a21_02.current.value; ArrayIdValue = palabra.split('&&&'); let c20a21_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche20a21_03.current.value; ArrayIdValue = palabra.split('&&&'); let c20a21_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche20a21_06.current.value; ArrayIdValue = palabra.split('&&&'); let c20a21_06 = ArrayIdValue[0];
      let c20a21_07 = this.cReporteTurnoNoche20a21_07.current.value;
      palabra = this.cReporteTurnoNoche20a21_04.current.value; ArrayIdValue = palabra.split('&&&'); let c20a21_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche20a21_05.current.value; ArrayIdValue = palabra.split('&&&'); let c20a21_05 = ArrayIdValue[0];
      let c20a21_13 = this.cReporteTurnoNoche20a21_13.current.value;
      let c20a21_08 = this.cReporteTurnoNoche20a21_08.current.value;
      let c20a21_09 = this.cReporteTurnoNoche20a21_09.current.value;
      let c20a21_10 = this.cReporteTurnoNoche20a21_10.current.value;
      let c20a21_11 = ''
      if (this.cReporteTurnoNoche20a21_11.current.value === 'X'){c20a21_11 = '0.5'}
      let c20a21_12 = ''
      if (this.cReporteTurnoNoche20a21_12.current.value === 'X'){c20a21_12 = '0.5'}

      let c20a21_01_2 = ''
      if (this.cReporteTurnoNoche20a21_01_2.current.value !== ''){palabra = this.cReporteTurnoNoche20a21_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c20a21_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche20a21_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c20a21_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche20a21_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c20a21_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche20a21_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c20a21_06_2 = ArrayIdValue[0];
      let c20a21_07_2 = this.cReporteTurnoNoche20a21_07_2.current.value;
      palabra = this.cReporteTurnoNoche20a21_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c20a21_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche20a21_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c20a21_05_2 = ArrayIdValue[0];
      let c20a21_13_2 = this.cReporteTurnoNoche20a21_13_2.current.value;
      let c20a21_08_2 = this.cReporteTurnoNoche20a21_08_2.current.value;
      let c20a21_09_2 = this.cReporteTurnoNoche20a21_09_2.current.value;
      let c20a21_10_2 = this.cReporteTurnoNoche20a21_10_2.current.value;
      let c20a21_11_2 = ''
      if (this.cReporteTurnoNoche20a21_11_2.current.value === 'X'){c20a21_11_2 = '0.5'}
      let c20a21_12_2 = ''
      if (this.cReporteTurnoNoche20a21_12_2.current.value === 'X'){c20a21_12_2 = '0.5'}

      let c21a22_01 = ''
      if (this.cReporteTurnoNoche21a22_01.current.value !== ''){palabra = this.cReporteTurnoNoche21a22_01.current.value; ArrayIdValue = palabra.split('&&&'); c21a22_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche21a22_02.current.value; ArrayIdValue = palabra.split('&&&'); let c21a22_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche21a22_03.current.value; ArrayIdValue = palabra.split('&&&'); let c21a22_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche21a22_06.current.value; ArrayIdValue = palabra.split('&&&'); let c21a22_06 = ArrayIdValue[0];
      let c21a22_07 = this.cReporteTurnoNoche21a22_07.current.value;
      palabra = this.cReporteTurnoNoche21a22_04.current.value; ArrayIdValue = palabra.split('&&&'); let c21a22_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche21a22_05.current.value; ArrayIdValue = palabra.split('&&&'); let c21a22_05 = ArrayIdValue[0];
      let c21a22_13 = this.cReporteTurnoNoche21a22_13.current.value;
      let c21a22_08 = this.cReporteTurnoNoche21a22_08.current.value;
      let c21a22_09 = this.cReporteTurnoNoche21a22_09.current.value;
      let c21a22_10 = this.cReporteTurnoNoche21a22_10.current.value;
      let c21a22_11 = ''
      if (this.cReporteTurnoNoche21a22_11.current.value === 'X'){c21a22_11 = '0.5'}
      let c21a22_12 = ''
      if (this.cReporteTurnoNoche21a22_12.current.value === 'X'){c21a22_12 = '0.5'}

      let c21a22_01_2 = ''
      if (this.cReporteTurnoNoche21a22_01_2.current.value !== ''){palabra = this.cReporteTurnoNoche21a22_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c21a22_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche21a22_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c21a22_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche21a22_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c21a22_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche21a22_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c21a22_06_2 = ArrayIdValue[0];
      let c21a22_07_2 = this.cReporteTurnoNoche21a22_07_2.current.value;
      palabra = this.cReporteTurnoNoche21a22_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c21a22_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche21a22_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c21a22_05_2 = ArrayIdValue[0];
      let c21a22_13_2 = this.cReporteTurnoNoche21a22_13_2.current.value;
      let c21a22_08_2 = this.cReporteTurnoNoche21a22_08_2.current.value;
      let c21a22_09_2 = this.cReporteTurnoNoche21a22_09_2.current.value;
      let c21a22_10_2 = this.cReporteTurnoNoche21a22_10_2.current.value;
      let c21a22_11_2 = ''
      if (this.cReporteTurnoNoche21a22_11_2.current.value === 'X'){c21a22_11_2 = '0.5'}
      let c21a22_12_2 = ''
      if (this.cReporteTurnoNoche21a22_12_2.current.value === 'X'){c21a22_12_2 = '0.5'}

      let c22a23_01 = ''
      if (this.cReporteTurnoNoche22a23_01.current.value !== ''){palabra = this.cReporteTurnoNoche22a23_01.current.value; ArrayIdValue = palabra.split('&&&'); c22a23_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche22a23_02.current.value; ArrayIdValue = palabra.split('&&&'); let c22a23_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche22a23_03.current.value; ArrayIdValue = palabra.split('&&&'); let c22a23_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche22a23_06.current.value; ArrayIdValue = palabra.split('&&&'); let c22a23_06 = ArrayIdValue[0];
      let c22a23_07 = this.cReporteTurnoNoche22a23_07.current.value;
      palabra = this.cReporteTurnoNoche22a23_04.current.value; ArrayIdValue = palabra.split('&&&'); let c22a23_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche22a23_05.current.value; ArrayIdValue = palabra.split('&&&'); let c22a23_05 = ArrayIdValue[0];
      let c22a23_13 = this.cReporteTurnoNoche22a23_13.current.value;
      let c22a23_08 = this.cReporteTurnoNoche22a23_08.current.value;
      let c22a23_09 = this.cReporteTurnoNoche22a23_09.current.value;
      let c22a23_10 = this.cReporteTurnoNoche22a23_10.current.value;
      let c22a23_11 = ''
      if (this.cReporteTurnoNoche22a23_11.current.value === 'X'){c22a23_11 = '0.5'}
      let c22a23_12 = ''
      if (this.cReporteTurnoNoche22a23_12.current.value === 'X'){c22a23_12 = '0.5'}

      let c22a23_01_2 = ''
      if (this.cReporteTurnoNoche22a23_01_2.current.value !== ''){palabra = this.cReporteTurnoNoche22a23_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c22a23_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche22a23_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c22a23_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche22a23_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c22a23_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche22a23_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c22a23_06_2 = ArrayIdValue[0];
      let c22a23_07_2 = this.cReporteTurnoNoche22a23_07_2.current.value;
      palabra = this.cReporteTurnoNoche22a23_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c22a23_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche22a23_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c22a23_05_2 = ArrayIdValue[0];
      let c22a23_13_2 = this.cReporteTurnoNoche22a23_13_2.current.value;
      let c22a23_08_2 = this.cReporteTurnoNoche22a23_08_2.current.value;
      let c22a23_09_2 = this.cReporteTurnoNoche22a23_09_2.current.value;
      let c22a23_10_2 = this.cReporteTurnoNoche22a23_10_2.current.value;
      let c22a23_11_2 = ''
      if (this.cReporteTurnoNoche22a23_11_2.current.value === 'X'){c22a23_11_2 = '0.5'}
      let c22a23_12_2 = ''
      if (this.cReporteTurnoNoche22a23_12_2.current.value === 'X'){c22a23_12_2 = '0.5'}

      let c23a24_01 = ''
      if (this.cReporteTurnoNoche23a24_01.current.value !== ''){palabra = this.cReporteTurnoNoche23a24_01.current.value; ArrayIdValue = palabra.split('&&&'); c23a24_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche23a24_02.current.value; ArrayIdValue = palabra.split('&&&'); let c23a24_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche23a24_03.current.value; ArrayIdValue = palabra.split('&&&'); let c23a24_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche23a24_06.current.value; ArrayIdValue = palabra.split('&&&'); let c23a24_06 = ArrayIdValue[0];
      let c23a24_07 = this.cReporteTurnoNoche23a24_07.current.value;
      palabra = this.cReporteTurnoNoche23a24_04.current.value; ArrayIdValue = palabra.split('&&&'); let c23a24_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche23a24_05.current.value; ArrayIdValue = palabra.split('&&&'); let c23a24_05 = ArrayIdValue[0];
      let c23a24_13 = this.cReporteTurnoNoche23a24_13.current.value;
      let c23a24_08 = this.cReporteTurnoNoche23a24_08.current.value;
      let c23a24_09 = this.cReporteTurnoNoche23a24_09.current.value;
      let c23a24_10 = this.cReporteTurnoNoche23a24_10.current.value;
      let c23a24_11 = ''
      if (this.cReporteTurnoNoche23a24_11.current.value === 'X'){c23a24_11 = '0.5'}
      let c23a24_12 = ''
      if (this.cReporteTurnoNoche23a24_12.current.value === 'X'){c23a24_12 = '0.5'}

      let c23a24_01_2 = ''
      if (this.cReporteTurnoNoche23a24_01_2.current.value !== ''){palabra = this.cReporteTurnoNoche23a24_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c23a24_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche23a24_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c23a24_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche23a24_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c23a24_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche23a24_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c23a24_06_2 = ArrayIdValue[0];
      let c23a24_07_2 = this.cReporteTurnoNoche23a24_07_2.current.value;
      palabra = this.cReporteTurnoNoche23a24_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c23a24_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche23a24_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c23a24_05_2 = ArrayIdValue[0];
      let c23a24_13_2 = this.cReporteTurnoNoche23a24_13_2.current.value;
      let c23a24_08_2 = this.cReporteTurnoNoche23a24_08_2.current.value;
      let c23a24_09_2 = this.cReporteTurnoNoche23a24_09_2.current.value;
      let c23a24_10_2 = this.cReporteTurnoNoche23a24_10_2.current.value;
      let c23a24_11_2 = ''
      if (this.cReporteTurnoNoche23a24_11_2.current.value === 'X'){c23a24_11_2 = '0.5'}
      let c23a24_12_2 = ''
      if (this.cReporteTurnoNoche23a24_12_2.current.value === 'X'){c23a24_12_2 = '0.5'}

      let c24a01_01 = ''
      if (this.cReporteTurnoNoche24a01_01.current.value !== ''){palabra = this.cReporteTurnoNoche24a01_01.current.value; ArrayIdValue = palabra.split('&&&'); c24a01_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche24a01_02.current.value; ArrayIdValue = palabra.split('&&&'); let c24a01_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche24a01_03.current.value; ArrayIdValue = palabra.split('&&&'); let c24a01_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche24a01_06.current.value; ArrayIdValue = palabra.split('&&&'); let c24a01_06 = ArrayIdValue[0];
      let c24a01_07 = this.cReporteTurnoNoche24a01_07.current.value;
      palabra = this.cReporteTurnoNoche24a01_04.current.value; ArrayIdValue = palabra.split('&&&'); let c24a01_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche24a01_05.current.value; ArrayIdValue = palabra.split('&&&'); let c24a01_05 = ArrayIdValue[0];
      let c24a01_13 = this.cReporteTurnoNoche24a01_13.current.value;
      let c24a01_08 = this.cReporteTurnoNoche24a01_08.current.value;
      let c24a01_09 = this.cReporteTurnoNoche24a01_09.current.value;
      let c24a01_10 = this.cReporteTurnoNoche24a01_10.current.value;
      let c24a01_11 = ''
      if (this.cReporteTurnoNoche24a01_11.current.value === 'X'){c24a01_11 = '0.5'}
      let c24a01_12 = ''
      if (this.cReporteTurnoNoche24a01_12.current.value === 'X'){c24a01_12 = '0.5'}

      let c24a01_01_2 = ''
      if (this.cReporteTurnoNoche24a01_01_2.current.value !== ''){palabra = this.cReporteTurnoNoche24a01_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c24a01_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche24a01_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c24a01_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche24a01_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c24a01_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche24a01_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c24a01_06_2 = ArrayIdValue[0];
      let c24a01_07_2 = this.cReporteTurnoNoche24a01_07_2.current.value;
      palabra = this.cReporteTurnoNoche24a01_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c24a01_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche24a01_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c24a01_05_2 = ArrayIdValue[0];
      let c24a01_13_2 = this.cReporteTurnoNoche24a01_13_2.current.value;
      let c24a01_08_2 = this.cReporteTurnoNoche24a01_08_2.current.value;
      let c24a01_09_2 = this.cReporteTurnoNoche24a01_09_2.current.value;
      let c24a01_10_2 = this.cReporteTurnoNoche24a01_10_2.current.value;
      let c24a01_11_2 = ''
      if (this.cReporteTurnoNoche24a01_11_2.current.value === 'X'){c24a01_11_2 = '0.5'}
      let c24a01_12_2 = ''
      if (this.cReporteTurnoNoche24a01_12_2.current.value === 'X'){c24a01_12_2 = '0.5'}

      let c01a02_01 = ''
      if (this.cReporteTurnoNoche01a02_01.current.value !== ''){palabra = this.cReporteTurnoNoche01a02_01.current.value; ArrayIdValue = palabra.split('&&&'); c01a02_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche01a02_02.current.value; ArrayIdValue = palabra.split('&&&'); let c01a02_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche01a02_03.current.value; ArrayIdValue = palabra.split('&&&'); let c01a02_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche01a02_06.current.value; ArrayIdValue = palabra.split('&&&'); let c01a02_06 = ArrayIdValue[0];
      let c01a02_07 = this.cReporteTurnoNoche01a02_07.current.value;
      palabra = this.cReporteTurnoNoche01a02_04.current.value; ArrayIdValue = palabra.split('&&&'); let c01a02_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche01a02_05.current.value; ArrayIdValue = palabra.split('&&&'); let c01a02_05 = ArrayIdValue[0];
      let c01a02_13 = this.cReporteTurnoNoche01a02_13.current.value;
      let c01a02_08 = this.cReporteTurnoNoche01a02_08.current.value;
      let c01a02_09 = this.cReporteTurnoNoche01a02_09.current.value;
      let c01a02_10 = this.cReporteTurnoNoche01a02_10.current.value;
      let c01a02_11 = ''
      if (this.cReporteTurnoNoche01a02_11.current.value === 'X'){c01a02_11 = '0.5'}
      let c01a02_12 = ''
      if (this.cReporteTurnoNoche01a02_12.current.value === 'X'){c01a02_12 = '0.5'}

      let c01a02_01_2 = ''
      if (this.cReporteTurnoNoche01a02_01_2.current.value !== ''){palabra = this.cReporteTurnoNoche01a02_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c01a02_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche01a02_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c01a02_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche01a02_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c01a02_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche01a02_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c01a02_06_2 = ArrayIdValue[0];
      let c01a02_07_2 = this.cReporteTurnoNoche01a02_07_2.current.value;
      palabra = this.cReporteTurnoNoche01a02_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c01a02_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche01a02_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c01a02_05_2 = ArrayIdValue[0];
      let c01a02_13_2 = this.cReporteTurnoNoche01a02_13_2.current.value;
      let c01a02_08_2 = this.cReporteTurnoNoche01a02_08_2.current.value;
      let c01a02_09_2 = this.cReporteTurnoNoche01a02_09_2.current.value;
      let c01a02_10_2 = this.cReporteTurnoNoche01a02_10_2.current.value;
      let c01a02_11_2 = ''
      if (this.cReporteTurnoNoche01a02_11_2.current.value === 'X'){c01a02_11_2 = '0.5'}
      let c01a02_12_2 = ''
      if (this.cReporteTurnoNoche01a02_12_2.current.value === 'X'){c01a02_12_2 = '0.5'}

      let c02a03_01 = ''
      if (this.cReporteTurnoNoche02a03_01.current.value !== ''){palabra = this.cReporteTurnoNoche02a03_01.current.value; ArrayIdValue = palabra.split('&&&'); c02a03_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche02a03_02.current.value; ArrayIdValue = palabra.split('&&&'); let c02a03_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche02a03_03.current.value; ArrayIdValue = palabra.split('&&&'); let c02a03_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche02a03_06.current.value; ArrayIdValue = palabra.split('&&&'); let c02a03_06 = ArrayIdValue[0];
      let c02a03_07 = this.cReporteTurnoNoche02a03_07.current.value;
      palabra = this.cReporteTurnoNoche02a03_04.current.value; ArrayIdValue = palabra.split('&&&'); let c02a03_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche02a03_05.current.value; ArrayIdValue = palabra.split('&&&'); let c02a03_05 = ArrayIdValue[0];
      let c02a03_13 = this.cReporteTurnoNoche02a03_13.current.value;
      let c02a03_08 = this.cReporteTurnoNoche02a03_08.current.value;
      let c02a03_09 = this.cReporteTurnoNoche02a03_09.current.value;
      let c02a03_10 = this.cReporteTurnoNoche02a03_10.current.value;
      let c02a03_11 = ''
      if (this.cReporteTurnoNoche02a03_11.current.value === 'X'){c02a03_11 = '0.5'}
      let c02a03_12 = ''
      if (this.cReporteTurnoNoche02a03_12.current.value === 'X'){c02a03_12 = '0.5'}

      let c02a03_01_2 = ''
      if (this.cReporteTurnoNoche02a03_01_2.current.value !== ''){palabra = this.cReporteTurnoNoche02a03_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c02a03_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche02a03_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c02a03_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche02a03_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c02a03_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche02a03_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c02a03_06_2 = ArrayIdValue[0];
      let c02a03_07_2 = this.cReporteTurnoNoche02a03_07_2.current.value;
      palabra = this.cReporteTurnoNoche02a03_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c02a03_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche02a03_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c02a03_05_2 = ArrayIdValue[0];
      let c02a03_13_2 = this.cReporteTurnoNoche02a03_13_2.current.value;
      let c02a03_08_2 = this.cReporteTurnoNoche02a03_08_2.current.value;
      let c02a03_09_2 = this.cReporteTurnoNoche02a03_09_2.current.value;
      let c02a03_10_2 = this.cReporteTurnoNoche02a03_10_2.current.value;
      let c02a03_11_2 = ''
      if (this.cReporteTurnoNoche02a03_11_2.current.value === 'X'){c02a03_11_2 = '0.5'}
      let c02a03_12_2 = ''
      if (this.cReporteTurnoNoche02a03_12_2.current.value === 'X'){c02a03_12_2 = '0.5'}

      let c03a04_01 = ''
      if (this.cReporteTurnoNoche03a04_01.current.value !== ''){palabra = this.cReporteTurnoNoche03a04_01.current.value; ArrayIdValue = palabra.split('&&&'); c03a04_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche03a04_02.current.value; ArrayIdValue = palabra.split('&&&'); let c03a04_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche03a04_03.current.value; ArrayIdValue = palabra.split('&&&'); let c03a04_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche03a04_06.current.value; ArrayIdValue = palabra.split('&&&'); let c03a04_06 = ArrayIdValue[0];
      let c03a04_07 = this.cReporteTurnoNoche03a04_07.current.value;
      palabra = this.cReporteTurnoNoche03a04_04.current.value; ArrayIdValue = palabra.split('&&&'); let c03a04_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche03a04_05.current.value; ArrayIdValue = palabra.split('&&&'); let c03a04_05 = ArrayIdValue[0];
      let c03a04_13 = this.cReporteTurnoNoche03a04_13.current.value;
      let c03a04_08 = this.cReporteTurnoNoche03a04_08.current.value;
      let c03a04_09 = this.cReporteTurnoNoche03a04_09.current.value;
      let c03a04_10 = this.cReporteTurnoNoche03a04_10.current.value;
      let c03a04_11 = ''
      if (this.cReporteTurnoNoche03a04_11.current.value === 'X'){c03a04_11 = '0.5'}
      let c03a04_12 = ''
      if (this.cReporteTurnoNoche03a04_12.current.value === 'X'){c03a04_12 = '0.5'}

      let c03a04_01_2 = ''
      if (this.cReporteTurnoNoche03a04_01_2.current.value !== ''){palabra = this.cReporteTurnoNoche03a04_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c03a04_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche03a04_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c03a04_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche03a04_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c03a04_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche03a04_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c03a04_06_2 = ArrayIdValue[0];
      let c03a04_07_2 = this.cReporteTurnoNoche03a04_07_2.current.value;
      palabra = this.cReporteTurnoNoche03a04_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c03a04_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche03a04_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c03a04_05_2 = ArrayIdValue[0];
      let c03a04_13_2 = this.cReporteTurnoNoche03a04_13_2.current.value;
      let c03a04_08_2 = this.cReporteTurnoNoche03a04_08_2.current.value;
      let c03a04_09_2 = this.cReporteTurnoNoche03a04_09_2.current.value;
      let c03a04_10_2 = this.cReporteTurnoNoche03a04_10_2.current.value;
      let c03a04_11_2 = ''
      if (this.cReporteTurnoNoche03a04_11_2.current.value === 'X'){c03a04_11_2 = '0.5'}
      let c03a04_12_2 = ''
      if (this.cReporteTurnoNoche03a04_12_2.current.value === 'X'){c03a04_12_2 = '0.5'}

      let c04a05_01 = ''
      if (this.cReporteTurnoNoche04a05_01.current.value !== ''){palabra = this.cReporteTurnoNoche04a05_01.current.value; ArrayIdValue = palabra.split('&&&'); c04a05_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche04a05_02.current.value; ArrayIdValue = palabra.split('&&&'); let c04a05_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche04a05_03.current.value; ArrayIdValue = palabra.split('&&&'); let c04a05_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche04a05_06.current.value; ArrayIdValue = palabra.split('&&&'); let c04a05_06 = ArrayIdValue[0];
      let c04a05_07 = this.cReporteTurnoNoche04a05_07.current.value;
      palabra = this.cReporteTurnoNoche04a05_04.current.value; ArrayIdValue = palabra.split('&&&'); let c04a05_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche04a05_05.current.value; ArrayIdValue = palabra.split('&&&'); let c04a05_05 = ArrayIdValue[0];
      let c04a05_13 = this.cReporteTurnoNoche04a05_13.current.value;
      let c04a05_08 = this.cReporteTurnoNoche04a05_08.current.value;
      let c04a05_09 = this.cReporteTurnoNoche04a05_09.current.value;
      let c04a05_10 = this.cReporteTurnoNoche04a05_10.current.value;
      let c04a05_11 = ''
      if (this.cReporteTurnoNoche04a05_11.current.value === 'X'){c04a05_11 = '0.5'}
      let c04a05_12 = ''
      if (this.cReporteTurnoNoche04a05_12.current.value === 'X'){c04a05_12 = '0.5'}

      let c04a05_01_2 = ''
      if (this.cReporteTurnoNoche04a05_01_2.current.value !== ''){palabra = this.cReporteTurnoNoche04a05_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c04a05_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche04a05_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c04a05_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche04a05_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c04a05_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche04a05_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c04a05_06_2 = ArrayIdValue[0];
      let c04a05_07_2 = this.cReporteTurnoNoche04a05_07_2.current.value;
      palabra = this.cReporteTurnoNoche04a05_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c04a05_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche04a05_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c04a05_05_2 = ArrayIdValue[0];
      let c04a05_13_2 = this.cReporteTurnoNoche04a05_13_2.current.value;
      let c04a05_08_2 = this.cReporteTurnoNoche04a05_08_2.current.value;
      let c04a05_09_2 = this.cReporteTurnoNoche04a05_09_2.current.value;
      let c04a05_10_2 = this.cReporteTurnoNoche04a05_10_2.current.value;
      let c04a05_11_2 = ''
      if (this.cReporteTurnoNoche04a05_11_2.current.value === 'X'){c04a05_11_2 = '0.5'}
      let c04a05_12_2 = ''
      if (this.cReporteTurnoNoche04a05_12_2.current.value === 'X'){c04a05_12_2 = '0.5'}

      let c05a06_01 = ''
      if (this.cReporteTurnoNoche05a06_01.current.value !== ''){palabra = this.cReporteTurnoNoche05a06_01.current.value; ArrayIdValue = palabra.split('&&&'); c05a06_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche05a06_02.current.value; ArrayIdValue = palabra.split('&&&'); let c05a06_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche05a06_03.current.value; ArrayIdValue = palabra.split('&&&'); let c05a06_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche05a06_06.current.value; ArrayIdValue = palabra.split('&&&'); let c05a06_06 = ArrayIdValue[0];
      let c05a06_07 = this.cReporteTurnoNoche05a06_07.current.value;
      palabra = this.cReporteTurnoNoche05a06_04.current.value; ArrayIdValue = palabra.split('&&&'); let c05a06_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche05a06_05.current.value; ArrayIdValue = palabra.split('&&&'); let c05a06_05 = ArrayIdValue[0];
      let c05a06_13 = this.cReporteTurnoNoche05a06_13.current.value;
      let c05a06_08 = this.cReporteTurnoNoche05a06_08.current.value;
      let c05a06_09 = this.cReporteTurnoNoche05a06_09.current.value;
      let c05a06_10 = this.cReporteTurnoNoche05a06_10.current.value;
      let c05a06_11 = ''
      if (this.cReporteTurnoNoche05a06_11.current.value === 'X'){c05a06_11 = '0.5'}
      let c05a06_12 = ''
      if (this.cReporteTurnoNoche05a06_12.current.value === 'X'){c05a06_12 = '0.5'}

      let c05a06_01_2 = ''
      if (this.cReporteTurnoNoche05a06_01_2.current.value !== ''){palabra = this.cReporteTurnoNoche05a06_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c05a06_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche05a06_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c05a06_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche05a06_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c05a06_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche05a06_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c05a06_06_2 = ArrayIdValue[0];
      let c05a06_07_2 = this.cReporteTurnoNoche05a06_07_2.current.value;
      palabra = this.cReporteTurnoNoche05a06_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c05a06_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche05a06_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c05a06_05_2 = ArrayIdValue[0];
      let c05a06_13_2 = this.cReporteTurnoNoche05a06_13_2.current.value;
      let c05a06_08_2 = this.cReporteTurnoNoche05a06_08_2.current.value;
      let c05a06_09_2 = this.cReporteTurnoNoche05a06_09_2.current.value;
      let c05a06_10_2 = this.cReporteTurnoNoche05a06_10_2.current.value;
      let c05a06_11_2 = ''
      if (this.cReporteTurnoNoche05a06_11_2.current.value === 'X'){c05a06_11_2 = '0.5'}
      let c05a06_12_2 = ''
      if (this.cReporteTurnoNoche05a06_12_2.current.value === 'X'){c05a06_12_2 = '0.5'}

      let c06a07_01 = ''
      if (this.cReporteTurnoNoche06a07_01.current.value !== ''){palabra = this.cReporteTurnoNoche06a07_01.current.value; ArrayIdValue = palabra.split('&&&'); c06a07_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche06a07_02.current.value; ArrayIdValue = palabra.split('&&&'); let c06a07_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche06a07_03.current.value; ArrayIdValue = palabra.split('&&&'); let c06a07_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche06a07_06.current.value; ArrayIdValue = palabra.split('&&&'); let c06a07_06 = ArrayIdValue[0];
      let c06a07_07 = this.cReporteTurnoNoche06a07_07.current.value;
      palabra = this.cReporteTurnoNoche06a07_04.current.value; ArrayIdValue = palabra.split('&&&'); let c06a07_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche06a07_05.current.value; ArrayIdValue = palabra.split('&&&'); let c06a07_05 = ArrayIdValue[0];
      let c06a07_13 = this.cReporteTurnoNoche06a07_13.current.value;
      let c06a07_08 = this.cReporteTurnoNoche06a07_08.current.value;
      let c06a07_09 = this.cReporteTurnoNoche06a07_09.current.value;
      let c06a07_10 = this.cReporteTurnoNoche06a07_10.current.value;
      let c06a07_11 = ''
      if (this.cReporteTurnoNoche06a07_11.current.value === 'X'){c06a07_11 = '0.5'}
      let c06a07_12 = ''
      if (this.cReporteTurnoNoche06a07_12.current.value === 'X'){c06a07_12 = '0.5'}

      let c06a07_01_2 = ''
      if (this.cReporteTurnoNoche06a07_01_2.current.value !== ''){palabra = this.cReporteTurnoNoche06a07_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c06a07_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche06a07_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c06a07_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche06a07_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c06a07_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche06a07_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c06a07_06_2 = ArrayIdValue[0];
      let c06a07_07_2 = this.cReporteTurnoNoche06a07_07_2.current.value;
      palabra = this.cReporteTurnoNoche06a07_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c06a07_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche06a07_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c06a07_05_2 = ArrayIdValue[0];
      let c06a07_13_2 = this.cReporteTurnoNoche06a07_13_2.current.value;
      let c06a07_08_2 = this.cReporteTurnoNoche06a07_08_2.current.value;
      let c06a07_09_2 = this.cReporteTurnoNoche06a07_09_2.current.value;
      let c06a07_10_2 = this.cReporteTurnoNoche06a07_10_2.current.value;
      let c06a07_11_2 = ''
      if (this.cReporteTurnoNoche06a07_11_2.current.value === 'X'){c06a07_11_2 = '0.5'}
      let c06a07_12_2 = ''
      if (this.cReporteTurnoNoche06a07_12_2.current.value === 'X'){c06a07_12_2 = '0.5'}

      let c07a08_01 = ''
      if (this.cReporteTurnoNoche07a08_01.current.value !== ''){palabra = this.cReporteTurnoNoche07a08_01.current.value; ArrayIdValue = palabra.split('&&&'); c07a08_01 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche07a08_02.current.value; ArrayIdValue = palabra.split('&&&'); let c07a08_02 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche07a08_03.current.value; ArrayIdValue = palabra.split('&&&'); let c07a08_03 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche07a08_06.current.value; ArrayIdValue = palabra.split('&&&'); let c07a08_06 = ArrayIdValue[0];
      let c07a08_07 = this.cReporteTurnoNoche07a08_07.current.value;
      palabra = this.cReporteTurnoNoche07a08_04.current.value; ArrayIdValue = palabra.split('&&&'); let c07a08_04 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche07a08_05.current.value; ArrayIdValue = palabra.split('&&&'); let c07a08_05 = ArrayIdValue[0];
      let c07a08_13 = this.cReporteTurnoNoche07a08_13.current.value;
      let c07a08_08 = this.cReporteTurnoNoche07a08_08.current.value;
      let c07a08_09 = this.cReporteTurnoNoche07a08_09.current.value;
      let c07a08_10 = this.cReporteTurnoNoche07a08_10.current.value;
      let c07a08_11 = ''
      if (this.cReporteTurnoNoche07a08_11.current.value === 'X'){c07a08_11 = '0.5'}
      let c07a08_12 = ''
      if (this.cReporteTurnoNoche07a08_12.current.value === 'X'){c07a08_12 = '0.5'}

      let c07a08_01_2 = ''
      if (this.cReporteTurnoNoche07a08_01_2.current.value !== ''){palabra = this.cReporteTurnoNoche07a08_01_2.current.value; ArrayIdValue = palabra.split('&&&'); c07a08_01_2 = ArrayIdValue[0] + ') ' + ArrayIdValue[1]}
      palabra = this.cReporteTurnoNoche07a08_02_2.current.value; ArrayIdValue = palabra.split('&&&'); let c07a08_02_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche07a08_03_2.current.value; ArrayIdValue = palabra.split('&&&'); let c07a08_03_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche07a08_06_2.current.value; ArrayIdValue = palabra.split('&&&'); let c07a08_06_2 = ArrayIdValue[0];
      let c07a08_07_2 = this.cReporteTurnoNoche07a08_07_2.current.value;
      palabra = this.cReporteTurnoNoche07a08_04_2.current.value; ArrayIdValue = palabra.split('&&&'); let c07a08_04_2 = ArrayIdValue[0];
      palabra = this.cReporteTurnoNoche07a08_05_2.current.value; ArrayIdValue = palabra.split('&&&'); let c07a08_05_2 = ArrayIdValue[0];
      let c07a08_13_2 = this.cReporteTurnoNoche07a08_13_2.current.value;
      let c07a08_08_2 = this.cReporteTurnoNoche07a08_08_2.current.value;
      let c07a08_09_2 = this.cReporteTurnoNoche07a08_09_2.current.value;
      let c07a08_10_2 = this.cReporteTurnoNoche07a08_10_2.current.value;
      let c07a08_11_2 = ''
      if (this.cReporteTurnoNoche07a08_11_2.current.value === 'X'){c07a08_11_2 = '0.5'}
      let c07a08_12_2 = ''
      if (this.cReporteTurnoNoche07a08_12_2.current.value === 'X'){c07a08_12_2 = '0.5'}

      let consolidadoFondoX2 = 0
      if (this.state.bFondoPozoExiste) {
        if (parseFloat(this.state.consolidadoFondoOrigenDiaLlenaX) > parseFloat(this.state.consolidadoFondoOrigenNocheLlenaX)){
          consolidadoFondoX2 = parseFloat(this.state.consolidadoFondoOrigenDiaLlenaX)
        }else{
          consolidadoFondoX2 = parseFloat(this.state.consolidadoFondoOrigenNocheLlenaX)
        }
      }else{
        consolidadoFondoX2 = parseFloat(this.state.consolidadoX) + parseFloat(this.state.consolidadoFondoOrigenDiaX) + parseFloat(this.state.consolidadoFondoOrigenNocheX)
      }

      if (modeX === 2){
        const csvData = [
          ["REPORTE TURNO DIGITAL"],
          ["FECHA", this.state.fecha],
          ["Cliente","Orden Trabajo","Numero Contrato","Campana","Fase","Recomendado","Tipo Perforacion","Sondaje","Inclinacion"],
          [this.state.workordercliente,this.props.numberContract,this.state.workordernumerocontrato,this.state.workordercampaña,this.state.workorderfase,this.state.workorderrecomendado,this.state.workordertipoperforacion,this.state.workordersondaje,this.state.workorderdeclinacion],
          ["MTS. TURNO", this.state.consolidadoX],
          ["MTS. FONDO", consolidadoFondoX2],
          ["ESTADO SONDAJE", cEstadoSondaje],
          ["TURNO DIA"],
          ["Supervisor","Jefe Turno","Asesor HSE","Operador","Ayudante1","Ayudante2","Metros Desde","Metros Hasta","Metros Perforados","Meta","Horometro Desde","Horometro Hasta","Estado Pozo"],
          [cDia04, cDia01, cDia05, cDia12, cDia02, cDia03, cDia06, cDia07, cDia08, cDia11, cDia09, cDia10, cDia13],
          ["Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA"],
          ["8:00 a 8:30", c08a09_01, c08a09_02, c08a09_03, c08a09_06, c08a09_04, c08a09_05, c08a09_13, c08a09_08, c08a09_09, c08a09_10, c08a09_11, c08a09_12],
          ["8:30 a 9:00", c08a09_01_2, c08a09_02_2, c08a09_03_2, c08a09_06_2, c08a09_04_2, c08a09_05_2, c08a09_13_2, c08a09_08_2, c08a09_09_2, c08a09_10_2, c08a09_11_2, c08a09_12_2],
          ["9:00 a 9:30", c09a10_01, c09a10_02, c09a10_03, c09a10_06, c09a10_04, c09a10_05, c09a10_13, c09a10_08, c09a10_09, c09a10_10, c09a10_11, c09a10_12],
          ["9:30 a 10:00", c09a10_01_2, c09a10_02_2, c09a10_03_2, c09a10_06_2, c09a10_04_2, c09a10_05_2, c09a10_13_2, c09a10_08_2, c09a10_09_2, c09a10_10_2, c09a10_11_2, c09a10_12_2],
          ["10:00 a 10:30", c10a11_01, c10a11_02, c10a11_03, c10a11_06, c10a11_04, c10a11_05, c10a11_13, c10a11_08, c10a11_09, c10a11_10, c10a11_11, c10a11_12],
          ["10:30 a 11:00", c10a11_01_2, c10a11_02_2, c10a11_03_2, c10a11_06_2, c10a11_04_2, c10a11_05_2, c10a11_13_2, c10a11_08_2, c10a11_09_2, c10a11_10_2, c10a11_11_2, c10a11_12_2],
          ["11:00 a 11:30", c11a12_01, c11a12_02, c11a12_03, c11a12_06, c11a12_04, c11a12_05, c11a12_13, c11a12_08, c11a12_09, c11a12_10, c11a12_11, c11a12_12],
          ["11:30 a 12:00", c11a12_01_2, c11a12_02_2, c11a12_03_2, c11a12_06_2, c11a12_04_2, c11a12_05_2, c11a12_13_2, c11a12_08_2, c11a12_09_2, c11a12_10_2, c11a12_11_2, c11a12_12_2],
          ["12:00 a 12:30", c12a13_01, c12a13_02, c12a13_03, c12a13_06, c12a13_04, c12a13_05, c12a13_13, c12a13_08, c12a13_09, c12a13_10, c12a13_11, c12a13_12],
          ["12:30 a 13:00", c12a13_01_2, c12a13_02_2, c12a13_03_2, c12a13_06_2, c12a13_04_2, c12a13_05_2, c12a13_13_2, c12a13_08_2, c12a13_09_2, c12a13_10_2, c12a13_11_2, c12a13_12_2],
          ["13:00 a 13:30", c13a14_01, c13a14_02, c13a14_03, c13a14_06, c13a14_04, c13a14_05, c13a14_13, c13a14_08, c13a14_09, c13a14_10, c13a14_11, c13a14_12],
          ["13:30 a 14:00", c13a14_01_2, c13a14_02_2, c13a14_03_2, c13a14_06_2, c13a14_04_2, c13a14_05_2, c13a14_13_2, c13a14_08_2, c13a14_09_2, c13a14_10_2, c13a14_11_2, c13a14_12_2],
          ["14:00 a 14:30", c14a15_01, c14a15_02, c14a15_03, c14a15_06, c14a15_04, c14a15_05, c14a15_13, c14a15_08, c14a15_09, c14a15_10, c14a15_11, c14a15_12],
          ["14:30 a 15:00", c14a15_01_2, c14a15_02_2, c14a15_03_2, c14a15_06_2, c14a15_04_2, c14a15_05_2, c14a15_13_2, c14a15_08_2, c14a15_09_2, c14a15_10_2, c14a15_11_2, c14a15_12_2],
          ["15:00 a 15:30", c15a16_01, c15a16_02, c15a16_03, c15a16_06, c15a16_04, c15a16_05, c15a16_13, c15a16_08, c15a16_09, c15a16_10, c15a16_11, c15a16_12],
          ["15:30 a 16:00", c15a16_01_2, c15a16_02_2, c15a16_03_2, c15a16_06_2, c15a16_04_2, c15a16_05_2, c15a16_13_2, c15a16_08_2, c15a16_09_2, c15a16_10_2, c15a16_11_2, c15a16_12_2],
          ["16:00 a 16:30", c16a17_01, c16a17_02, c16a17_03, c16a17_06, c16a17_04, c16a17_05, c16a17_13, c16a17_08, c16a17_09, c16a17_10, c16a17_11, c16a17_12],
          ["16:30 a 17:00", c16a17_01_2, c16a17_02_2, c16a17_03_2, c16a17_06_2, c16a17_04_2, c16a17_05_2, c16a17_13_2, c16a17_08_2, c16a17_09_2, c16a17_10_2, c16a17_11_2, c16a17_12_2],
          ["17:00 a 17:30", c17a18_01, c17a18_02, c17a18_03, c17a18_06, c17a18_04, c17a18_05, c17a18_13, c17a18_08, c17a18_09, c17a18_10, c17a18_11, c17a18_12],
          ["17:30 a 18:00", c17a18_01_2, c17a18_02_2, c17a18_03_2, c17a18_06_2, c17a18_04_2, c17a18_05_2, c17a18_13_2, c17a18_08_2, c17a18_09_2, c17a18_10_2, c17a18_11_2, c17a18_12_2],
          ["18:00 a 18:30", c18a19_01, c18a19_02, c18a19_03, c18a19_06, c18a19_04, c18a19_05, c18a19_13, c18a19_08, c18a19_09, c18a19_10, c18a19_11, c18a19_12],
          ["18:30 a 19:00", c18a19_01_2, c18a19_02_2, c18a19_03_2, c18a19_06_2, c18a19_04_2, c18a19_05_2, c18a19_13_2, c18a19_08_2, c18a19_09_2, c18a19_10_2, c18a19_11_2, c18a19_12_2],
          ["19:00 a 19:30", c19a20_01, c19a20_02, c19a20_03, c19a20_06, c19a20_04, c19a20_05, c19a20_13, c19a20_08, c19a20_09, c19a20_10, c19a20_11, c19a20_12],
          ["19:30 a 20:00", c19a20_01_2, c19a20_02_2, c19a20_03_2, c19a20_06_2, c19a20_04_2, c19a20_05_2, c19a20_13_2, c19a20_08_2, c19a20_09_2, c19a20_10_2, c19a20_11_2, c19a20_12_2],
          ["TURNO NOCHE"],
          ["Supervisor","Jefe Turno","Asesor HSE","Operador","Ayudante1","Ayudante2","Metros Desde","Metros Hasta","Metros Perforados","Meta","Horometro Desde","Horometro Hasta","Estado Pozo"],
          [cNoche04, cNoche01, cNoche05, cNoche12, cNoche02, cNoche03, cNoche06, cNoche07, cNoche08, cNoche11, cNoche09, cNoche10, cNoche13],
          ["Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA"],
          ["20:00 a 20:30", c20a21_01, c20a21_02, c20a21_03, c20a21_06, c20a21_04, c20a21_05, c20a21_13, c20a21_08, c20a21_09, c20a21_10, c20a21_11, c20a21_12],
          ["20:30 a 21:00", c20a21_01_2, c20a21_02_2, c20a21_03_2, c20a21_06_2, c20a21_04_2, c20a21_05_2, c20a21_13_2, c20a21_08_2, c20a21_09_2, c20a21_10_2, c20a21_11_2, c20a21_12_2],
          ["21:00 a 21:30", c21a22_01, c21a22_02, c21a22_03, c21a22_06, c21a22_04, c21a22_05, c21a22_13, c21a22_08, c21a22_09, c21a22_10, c21a22_11, c21a22_12],
          ["21:30 a 22:00", c21a22_01_2, c21a22_02_2, c21a22_03_2, c21a22_06_2, c21a22_04_2, c21a22_05_2, c21a22_13_2, c21a22_08_2, c21a22_09_2, c21a22_10_2, c21a22_11_2, c21a22_12_2],
          ["22:00 a 22:30", c22a23_01, c22a23_02, c22a23_03, c22a23_06, c22a23_04, c22a23_05, c22a23_13, c22a23_08, c22a23_09, c22a23_10, c22a23_11, c22a23_12],
          ["22:30 a 23:00", c22a23_01_2, c22a23_02_2, c22a23_03_2, c22a23_06_2, c22a23_04_2, c22a23_05_2, c22a23_13_2, c22a23_08_2, c22a23_09_2, c22a23_10_2, c22a23_11_2, c22a23_12_2],
          ["23:00 a 23:30", c23a24_01, c23a24_02, c23a24_03, c23a24_06, c23a24_04, c23a24_05, c23a24_13, c23a24_08, c23a24_09, c23a24_10, c23a24_11, c23a24_12],
          ["23:30 a 24:00", c23a24_01_2, c23a24_02_2, c23a24_03_2, c23a24_06_2, c23a24_04_2, c23a24_05_2, c23a24_13_2, c23a24_08_2, c23a24_09_2, c23a24_10_2, c23a24_11_2, c23a24_12_2],
          ["24:00 a 00:30", c24a01_01, c24a01_02, c24a01_03, c24a01_06, c24a01_04, c24a01_05, c24a01_13, c24a01_08, c24a01_09, c24a01_10, c24a01_11, c24a01_12],
          ["00:30 a 01:00", c24a01_01_2, c24a01_02_2, c24a01_03_2, c24a01_06_2, c24a01_04_2, c24a01_05_2, c24a01_13_2, c24a01_08_2, c24a01_09_2, c24a01_10_2, c24a01_11_2, c24a01_12_2],
          ["01:00 a 01:30", c01a02_01, c01a02_02, c01a02_03, c01a02_06, c01a02_04, c01a02_05, c01a02_13, c01a02_08, c01a02_09, c01a02_10, c01a02_11, c01a02_12],
          ["01:30 a 02:00", c01a02_01_2, c01a02_02_2, c01a02_03_2, c01a02_06_2, c01a02_04_2, c01a02_05_2, c01a02_13_2, c01a02_08_2, c01a02_09_2, c01a02_10_2, c01a02_11_2, c01a02_12_2],
          ["02:00 a 02:30", c02a03_01, c02a03_02, c02a03_03, c02a03_06, c02a03_04, c02a03_05, c02a03_13, c02a03_08, c02a03_09, c02a03_10, c02a03_11, c02a03_12],
          ["02:30 a 03:00", c02a03_01_2, c02a03_02_2, c02a03_03_2, c02a03_06_2, c02a03_04_2, c02a03_05_2, c02a03_13_2, c02a03_08_2, c02a03_09_2, c02a03_10_2, c02a03_11_2, c02a03_12_2],
          ["03:00 a 03:30", c03a04_01, c03a04_02, c03a04_03, c03a04_06, c03a04_04, c03a04_05, c03a04_13, c03a04_08, c03a04_09, c03a04_10, c03a04_11, c03a04_12],
          ["03:30 a 04:00", c03a04_01_2, c03a04_02_2, c03a04_03_2, c03a04_06_2, c03a04_04_2, c03a04_05_2, c03a04_13_2, c03a04_08_2, c03a04_09_2, c03a04_10_2, c03a04_11_2, c03a04_12_2],
          ["04:00 a 04:30", c04a05_01, c04a05_02, c04a05_03, c04a05_06, c04a05_04, c04a05_05, c04a05_13, c04a05_08, c04a05_09, c04a05_10, c04a05_11, c04a05_12],
          ["04:30 a 05:00", c04a05_01_2, c04a05_02_2, c04a05_03_2, c04a05_06_2, c04a05_04_2, c04a05_05_2, c04a05_13_2, c04a05_08_2, c04a05_09_2, c04a05_10_2, c04a05_11_2, c04a05_12_2],
          ["05:00 a 05:30", c05a06_01, c05a06_02, c05a06_03, c05a06_06, c05a06_04, c05a06_05, c05a06_13, c05a06_08, c05a06_09, c05a06_10, c05a06_11, c05a06_12],
          ["05:30 a 06:00", c05a06_01_2, c05a06_02_2, c05a06_03_2, c05a06_06_2, c05a06_04_2, c05a06_05_2, c05a06_13_2, c05a06_08_2, c05a06_09_2, c05a06_10_2, c05a06_11_2, c05a06_12_2],
          ["06:00 a 06:30", c06a07_01, c06a07_02, c06a07_03, c06a07_06, c06a07_04, c06a07_05, c06a07_13, c06a07_08, c06a07_09, c06a07_10, c06a07_11, c06a07_12],
          ["06:30 a 07:00", c06a07_01_2, c06a07_02_2, c06a07_03_2, c06a07_06_2, c06a07_04_2, c06a07_05_2, c06a07_13_2, c06a07_08_2, c06a07_09_2, c06a07_10_2, c06a07_11_2, c06a07_12_2],
          ["07:00 a 07:30", c07a08_01, c07a08_02, c07a08_03, c07a08_06, c07a08_04, c07a08_05, c07a08_13, c07a08_08, c07a08_09, c07a08_10, c07a08_11, c07a08_12],
          ["07:30 a 08:00", c07a08_01_2, c07a08_02_2, c07a08_03_2, c07a08_06_2, c07a08_04_2, c07a08_05_2, c07a08_13_2, c07a08_08_2, c07a08_09_2, c07a08_10_2, c07a08_11_2, c07a08_12_2],
        ];
        this.setState({csvData})
      }

      if (modeX === 3){
        const csvData = [
          ["REPORTE TURNO DIGITAL","FECHA","Cliente","Orden Trabajo","Numero Contrato","Campana","Fase","Recomendado","Tipo Perforacion","Sondaje","Inclinacion","Mts Turno","Mts Fondo","Estado Sondaje",
          "TURNO DIA",
          "Supervisor","Jefe Turno","Asesor HSE","Operador","Ayudante1","Ayudante2","Metros Desde","Metros Hasta","Metros Perforados","Meta","Horometro Desde","Horometro Hasta","Estado Pozo",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "TURNO NOCHE",
          "Supervisor","Jefe Turno","Asesor HSE","Operador","Ayudante1","Ayudante2","Metros Desde","Metros Hasta","Metros Perforados","Meta","Horometro Desde","Horometro Hasta","Estado Pozo",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA",
          "Horario", "Actividad", "Perforacion", "Condicion Humedad", "Acero Perforacion", "Herramienta", "Diametro Ocupado", "Num.Serie Diam.Ocup.","Mts.Inicio", "Mts.Fin", "Aclaracion", "HH MD", "HH AA"],
          ['',this.state.fecha,this.state.workordercliente,this.props.numberContract,this.state.workordernumerocontrato,this.state.workordercampaña,this.state.workorderfase,this.state.workorderrecomendado,this.state.workordertipoperforacion,this.state.workordersondaje,this.state.workorderdeclinacion,this.state.consolidadoX,consolidadoFondoX2,cEstadoSondaje,
          "",
          cDia04, cDia01, cDia05, cDia12, cDia02, cDia03, cDia06, cDia07, cDia08, cDia11, cDia09, cDia10, cDia13,
          "8:00 a 8:30", c08a09_01, c08a09_02, c08a09_03, c08a09_06, c08a09_04, c08a09_05, c08a09_13, c08a09_08, c08a09_09, c08a09_10, c08a09_11, c08a09_12,
          "8:30 a 9:00", c08a09_01_2, c08a09_02_2, c08a09_03_2, c08a09_06_2, c08a09_04_2, c08a09_05_2, c08a09_13_2, c08a09_08_2, c08a09_09_2, c08a09_10_2, c08a09_11_2, c08a09_12_2,
          "9:00 a 9:30", c09a10_01, c09a10_02, c09a10_03, c09a10_06, c09a10_04, c09a10_05, c09a10_13, c09a10_08, c09a10_09, c09a10_10, c09a10_11, c09a10_12,
          "9:30 a 10:00", c09a10_01_2, c09a10_02_2, c09a10_03_2, c09a10_06_2, c09a10_04_2, c09a10_05_2, c09a10_13_2, c09a10_08_2, c09a10_09_2, c09a10_10_2, c09a10_11_2, c09a10_12_2,
          "10:00 a 10:30", c10a11_01, c10a11_02, c10a11_03, c10a11_06, c10a11_04, c10a11_05, c10a11_13, c10a11_08, c10a11_09, c10a11_10, c10a11_11, c10a11_12,
          "10:30 a 11:00", c10a11_01_2, c10a11_02_2, c10a11_03_2, c10a11_06_2, c10a11_04_2, c10a11_05_2, c10a11_13_2, c10a11_08_2, c10a11_09_2, c10a11_10_2, c10a11_11_2, c10a11_12_2,
          "11:00 a 11:30", c11a12_01, c11a12_02, c11a12_03, c11a12_06, c11a12_04, c11a12_05, c11a12_13, c11a12_08, c11a12_09, c11a12_10, c11a12_11, c11a12_12,
          "11:30 a 12:00", c11a12_01_2, c11a12_02_2, c11a12_03_2, c11a12_06_2, c11a12_04_2, c11a12_05_2, c11a12_13_2, c11a12_08_2, c11a12_09_2, c11a12_10_2, c11a12_11_2, c11a12_12_2,
          "12:00 a 12:30", c12a13_01, c12a13_02, c12a13_03, c12a13_06, c12a13_04, c12a13_05, c12a13_13, c12a13_08, c12a13_09, c12a13_10, c12a13_11, c12a13_12,
          "12:30 a 13:00", c12a13_01_2, c12a13_02_2, c12a13_03_2, c12a13_06_2, c12a13_04_2, c12a13_05_2, c12a13_13_2, c12a13_08_2, c12a13_09_2, c12a13_10_2, c12a13_11_2, c12a13_12_2,
          "13:00 a 13:30", c13a14_01, c13a14_02, c13a14_03, c13a14_06, c13a14_04, c13a14_05, c13a14_13, c13a14_08, c13a14_09, c13a14_10, c13a14_11, c13a14_12,
          "13:30 a 14:00", c13a14_01_2, c13a14_02_2, c13a14_03_2, c13a14_06_2, c13a14_04_2, c13a14_05_2, c13a14_13_2, c13a14_08_2, c13a14_09_2, c13a14_10_2, c13a14_11_2, c13a14_12_2,
          "14:00 a 14:30", c14a15_01, c14a15_02, c14a15_03, c14a15_06, c14a15_04, c14a15_05, c14a15_13, c14a15_08, c14a15_09, c14a15_10, c14a15_11, c14a15_12,
          "14:30 a 15:00", c14a15_01_2, c14a15_02_2, c14a15_03_2, c14a15_06_2, c14a15_04_2, c14a15_05_2, c14a15_13_2, c14a15_08_2, c14a15_09_2, c14a15_10_2, c14a15_11_2, c14a15_12_2,
          "15:00 a 15:30", c15a16_01, c15a16_02, c15a16_03, c15a16_06, c15a16_04, c15a16_05, c15a16_13, c15a16_08, c15a16_09, c15a16_10, c15a16_11, c15a16_12,
          "15:30 a 16:00", c15a16_01_2, c15a16_02_2, c15a16_03_2, c15a16_06_2, c15a16_04_2, c15a16_05_2, c15a16_13_2, c15a16_08_2, c15a16_09_2, c15a16_10_2, c15a16_11_2, c15a16_12_2,
          "16:00 a 16:30", c16a17_01, c16a17_02, c16a17_03, c16a17_06, c16a17_04, c16a17_05, c16a17_13, c16a17_08, c16a17_09, c16a17_10, c16a17_11, c16a17_12,
          "16:30 a 17:00", c16a17_01_2, c16a17_02_2, c16a17_03_2, c16a17_06_2, c16a17_04_2, c16a17_05_2, c16a17_13_2, c16a17_08_2, c16a17_09_2, c16a17_10_2, c16a17_11_2, c16a17_12_2,
          "17:00 a 17:30", c17a18_01, c17a18_02, c17a18_03, c17a18_06, c17a18_04, c17a18_05, c17a18_13, c17a18_08, c17a18_09, c17a18_10, c17a18_11, c17a18_12,
          "17:30 a 18:00", c17a18_01_2, c17a18_02_2, c17a18_03_2, c17a18_06_2, c17a18_04_2, c17a18_05_2, c17a18_13_2, c17a18_08_2, c17a18_09_2, c17a18_10_2, c17a18_11_2, c17a18_12_2,
          "18:00 a 18:30", c18a19_01, c18a19_02, c18a19_03, c18a19_06, c18a19_04, c18a19_05, c18a19_13, c18a19_08, c18a19_09, c18a19_10, c18a19_11, c18a19_12,
          "18:30 a 19:00", c18a19_01_2, c18a19_02_2, c18a19_03_2, c18a19_06_2, c18a19_04_2, c18a19_05_2, c18a19_13_2, c18a19_08_2, c18a19_09_2, c18a19_10_2, c18a19_11_2, c18a19_12_2,
          "19:00 a 19:30", c19a20_01, c19a20_02, c19a20_03, c19a20_06, c19a20_04, c19a20_05, c19a20_13, c19a20_08, c19a20_09, c19a20_10, c19a20_11, c19a20_12,
          "19:30 a 20:00", c19a20_01_2, c19a20_02_2, c19a20_03_2, c19a20_06_2, c19a20_04_2, c19a20_05_2, c19a20_13_2, c19a20_08_2, c19a20_09_2, c19a20_10_2, c19a20_11_2, c19a20_12_2,
          "",
          cNoche04, cNoche01, cNoche05, cNoche12, cNoche02, cNoche03, cNoche06, cNoche07, cNoche08, cNoche11, cNoche09, cNoche10, cNoche13,
          "20:00 a 20:30", c20a21_01, c20a21_02, c20a21_03, c20a21_06, c20a21_04, c20a21_05, c20a21_13, c20a21_08, c20a21_09, c20a21_10, c20a21_11, c20a21_12,
          "20:30 a 21:00", c20a21_01_2, c20a21_02_2, c20a21_03_2, c20a21_06_2, c20a21_04_2, c20a21_05_2, c20a21_13_2, c20a21_08_2, c20a21_09_2, c20a21_10_2, c20a21_11_2, c20a21_12_2,
          "21:00 a 21:30", c21a22_01, c21a22_02, c21a22_03, c21a22_06, c21a22_04, c21a22_05, c21a22_13, c21a22_08, c21a22_09, c21a22_10, c21a22_11, c21a22_12,
          "21:30 a 22:00", c21a22_01_2, c21a22_02_2, c21a22_03_2, c21a22_06_2, c21a22_04_2, c21a22_05_2, c21a22_13_2, c21a22_08_2, c21a22_09_2, c21a22_10_2, c21a22_11_2, c21a22_12_2,
          "22:00 a 22:30", c22a23_01, c22a23_02, c22a23_03, c22a23_06, c22a23_04, c22a23_05, c22a23_13, c22a23_08, c22a23_09, c22a23_10, c22a23_11, c22a23_12,
          "22:30 a 23:00", c22a23_01_2, c22a23_02_2, c22a23_03_2, c22a23_06_2, c22a23_04_2, c22a23_05_2, c22a23_13_2, c22a23_08_2, c22a23_09_2, c22a23_10_2, c22a23_11_2, c22a23_12_2,
          "23:00 a 23:30", c23a24_01, c23a24_02, c23a24_03, c23a24_06, c23a24_04, c23a24_05, c23a24_13, c23a24_08, c23a24_09, c23a24_10, c23a24_11, c23a24_12,
          "23:30 a 24:00", c23a24_01_2, c23a24_02_2, c23a24_03_2, c23a24_06_2, c23a24_04_2, c23a24_05_2, c23a24_13_2, c23a24_08_2, c23a24_09_2, c23a24_10_2, c23a24_11_2, c23a24_12_2,
          "24:00 a 00:30", c24a01_01, c24a01_02, c24a01_03, c24a01_06, c24a01_04, c24a01_05, c24a01_13, c24a01_08, c24a01_09, c24a01_10, c24a01_11, c24a01_12,
          "00:30 a 01:00", c24a01_01_2, c24a01_02_2, c24a01_03_2, c24a01_06_2, c24a01_04_2, c24a01_05_2, c24a01_13_2, c24a01_08_2, c24a01_09_2, c24a01_10_2, c24a01_11_2, c24a01_12_2,
          "01:00 a 01:30", c01a02_01, c01a02_02, c01a02_03, c01a02_06, c01a02_04, c01a02_05, c01a02_13, c01a02_08, c01a02_09, c01a02_10, c01a02_11, c01a02_12,
          "01:30 a 02:00", c01a02_01_2, c01a02_02_2, c01a02_03_2, c01a02_06_2, c01a02_04_2, c01a02_05_2, c01a02_13_2, c01a02_08_2, c01a02_09_2, c01a02_10_2, c01a02_11_2, c01a02_12_2,
          "02:00 a 02:30", c02a03_01, c02a03_02, c02a03_03, c02a03_06, c02a03_04, c02a03_05, c02a03_13, c02a03_08, c02a03_09, c02a03_10, c02a03_11, c02a03_12,
          "02:30 a 03:00", c02a03_01_2, c02a03_02_2, c02a03_03_2, c02a03_06_2, c02a03_04_2, c02a03_05_2, c02a03_13_2, c02a03_08_2, c02a03_09_2, c02a03_10_2, c02a03_11_2, c02a03_12_2,
          "03:00 a 03:30", c03a04_01, c03a04_02, c03a04_03, c03a04_06, c03a04_04, c03a04_05, c03a04_13, c03a04_08, c03a04_09, c03a04_10, c03a04_11, c03a04_12,
          "03:30 a 04:00", c03a04_01_2, c03a04_02_2, c03a04_03_2, c03a04_06_2, c03a04_04_2, c03a04_05_2, c03a04_13_2, c03a04_08_2, c03a04_09_2, c03a04_10_2, c03a04_11_2, c03a04_12_2,
          "04:00 a 04:30", c04a05_01, c04a05_02, c04a05_03, c04a05_06, c04a05_04, c04a05_05, c04a05_13, c04a05_08, c04a05_09, c04a05_10, c04a05_11, c04a05_12,
          "04:30 a 05:00", c04a05_01_2, c04a05_02_2, c04a05_03_2, c04a05_06_2, c04a05_04_2, c04a05_05_2, c04a05_13_2, c04a05_08_2, c04a05_09_2, c04a05_10_2, c04a05_11_2, c04a05_12_2,
          "05:00 a 05:30", c05a06_01, c05a06_02, c05a06_03, c05a06_06, c05a06_04, c05a06_05, c05a06_13, c05a06_08, c05a06_09, c05a06_10, c05a06_11, c05a06_12,
          "05:30 a 06:00", c05a06_01_2, c05a06_02_2, c05a06_03_2, c05a06_06_2, c05a06_04_2, c05a06_05_2, c05a06_13_2, c05a06_08_2, c05a06_09_2, c05a06_10_2, c05a06_11_2, c05a06_12_2,
          "06:00 a 06:30", c06a07_01, c06a07_02, c06a07_03, c06a07_06, c06a07_04, c06a07_05, c06a07_13, c06a07_08, c06a07_09, c06a07_10, c06a07_11, c06a07_12,
          "06:30 a 07:00", c06a07_01_2, c06a07_02_2, c06a07_03_2, c06a07_06_2, c06a07_04_2, c06a07_05_2, c06a07_13_2, c06a07_08_2, c06a07_09_2, c06a07_10_2, c06a07_11_2, c06a07_12_2,
          "07:00 a 07:30", c07a08_01, c07a08_02, c07a08_03, c07a08_06, c07a08_04, c07a08_05, c07a08_13, c07a08_08, c07a08_09, c07a08_10, c07a08_11, c07a08_12,
          "07:30 a 08:00", c07a08_01_2, c07a08_02_2, c07a08_03_2, c07a08_06_2, c07a08_04_2, c07a08_05_2, c07a08_13_2, c07a08_08_2, c07a08_09_2, c07a08_10_2, c07a08_11_2, c07a08_12_2],
        ];
        this.setState({csvData})
      }

      if (modeX === 1){
        const jsonData =
        {"tipo_formulario": "REPORTE DE TURNO DIGITAL",
          "fecha_reporte": this.state.fecha,
          "numero_reporte":"",
          "consolidado": {
             "metros_turno":this.state.consolidadoX,
             "metros_fondo":consolidadoFondoX2,
             "estado_sondaje":cEstadoSondaje
           },
          "orden_trabajo": {
             "cliente": this.state.workordercliente,
             "orden_trabajo":this.props.numberContract,
             "numero_contrato":this.state.workordernumerocontrato,
             "campana":this.state.workordercampaña,
             "fase":this.state.workorderfase,
             "recomendado":this.state.workorderrecomendado,
             "tipo_perforacion":this.state.workordertipoperforacion,
             "sondaje":this.state.workordersondaje,
             "inclinacion":this.state.workorderdeclinacion
           },
          "detalle_turno": {
            "turno_dia": {
              "cabecera": {
                "supervisor": cDia04,
                "jefe_turno": cDia01,
                "asesor_hse": cDia05,
                "operador": cDia12,
                "ayudante_1": cDia02,
                "ayudante_2": cDia03,
                "metros_desde": cDia06,
                "metros_hasta": cDia07,
                "metros_perforados": cDia08,
                "meta": cDia11,
                "horometro_desde": cDia09,
                "horometro_hasta": cDia10,
                "estado_pozo": cDia13
               },
               "detalle": [
                   {"horario":"Horario", "actividad":"Actividad", "perforacion":"Perforacion", "condicion_humedad":"Condicion Humedad", "acero_perforacion":"Acero Perforacion", "herramienta":"Herramienta", "diametro_ocupado":"Diametro Ocupado", "num_serie_diam_ocup":"Num.Serie Diam.Ocup.","mts_inicio":"Mts.Inicio", "mts_fin":"Mts.Fin", "aclaracion":"Aclaracion", "hh_md":"HH MD", "hh_aa":"HH AA"},
                   {"horario":"8:00 a 8:30", "actividad":c08a09_01, "perforacion":c08a09_02, "condicion_humedad":c08a09_03, "acero_perforacion":c08a09_06, "herramienta":c08a09_04, "diametro_ocupado":c08a09_05, "num_serie_diam_ocup":c08a09_13, "mts_inicio":c08a09_08, "mts_fin":c08a09_09, "aclaracion":c08a09_10, "hh_md":c08a09_11, "hh_aa":c08a09_12},
                   {"horario":"8:30 a 9:00", "actividad":c08a09_01_2, "perforacion":c08a09_02_2, "condicion_humedad":c08a09_03_2, "acero_perforacion":c08a09_06_2, "herramienta":c08a09_04_2, "diametro_ocupado":c08a09_05_2, "num_serie_diam_ocup":c08a09_13_2, "mts_inicio":c08a09_08_2, "mts_fin":c08a09_09_2, "aclaracion":c08a09_10_2, "hh_md":c08a09_11_2, "hh_aa":c08a09_12_2},
                   {"horario":"9:00 a 9:30", "actividad":c09a10_01, "perforacion":c09a10_02, "condicion_humedad":c09a10_03, "acero_perforacion":c09a10_06, "herramienta":c09a10_04, "diametro_ocupado":c09a10_05, "num_serie_diam_ocup":c09a10_13, "mts_inicio":c09a10_08, "mts_fin":c09a10_09, "aclaracion":c09a10_10, "hh_md":c09a10_11, "hh_aa":c09a10_12},
                   {"horario":"9:30 a 10:00", "actividad":c09a10_01_2, "perforacion":c09a10_02_2, "condicion_humedad":c09a10_03_2, "acero_perforacion":c09a10_06_2, "herramienta":c09a10_04_2, "diametro_ocupado":c09a10_05_2, "num_serie_diam_ocup":c09a10_13_2, "mts_inicio":c09a10_08_2, "mts_fin":c09a10_09_2, "aclaracion":c09a10_10_2, "hh_md":c09a10_11_2, "hh_aa":c09a10_12_2},
                   {"horario":"10:00 a 10:30", "actividad":c10a11_01, "perforacion":c10a11_02, "condicion_humedad":c10a11_03, "acero_perforacion":c10a11_06, "herramienta":c10a11_04, "diametro_ocupado":c10a11_05, "num_serie_diam_ocup":c10a11_13, "mts_inicio":c10a11_08, "mts_fin":c10a11_09, "aclaracion":c10a11_10, "hh_md":c10a11_11, "hh_aa":c10a11_12},
                   {"horario":"10:30 a 11:00", "actividad":c10a11_01_2, "perforacion":c10a11_02_2, "condicion_humedad":c10a11_03_2, "acero_perforacion":c10a11_06_2, "herramienta":c10a11_04_2, "diametro_ocupado":c10a11_05_2, "num_serie_diam_ocup":c10a11_13_2, "mts_inicio":c10a11_08_2, "mts_fin":c10a11_09_2, "aclaracion":c10a11_10_2, "hh_md":c10a11_11_2, "hh_aa":c10a11_12_2},
                   {"horario":"11:00 a 11:30", "actividad":c11a12_01, "perforacion":c11a12_02, "condicion_humedad":c11a12_03, "acero_perforacion":c11a12_06, "herramienta":c11a12_04, "diametro_ocupado":c11a12_05, "num_serie_diam_ocup":c11a12_13, "mts_inicio":c11a12_08, "mts_fin":c11a12_09, "aclaracion":c11a12_10, "hh_md":c11a12_11, "hh_aa":c11a12_12},
                   {"horario":"11:30 a 12:00", "actividad":c11a12_01_2, "perforacion":c11a12_02_2, "condicion_humedad":c11a12_03_2, "acero_perforacion":c11a12_06_2, "herramienta":c11a12_04_2, "diametro_ocupado":c11a12_05_2, "num_serie_diam_ocup":c11a12_13_2, "mts_inicio":c11a12_08_2, "mts_fin":c11a12_09_2, "aclaracion":c11a12_10_2, "hh_md":c11a12_11_2, "hh_aa":c11a12_12_2},
                   {"horario":"12:00 a 12:30", "actividad":c12a13_01, "perforacion":c12a13_02, "condicion_humedad":c12a13_03, "acero_perforacion":c12a13_06, "herramienta":c12a13_04, "diametro_ocupado":c12a13_05, "num_serie_diam_ocup":c12a13_13, "mts_inicio":c12a13_08, "mts_fin":c12a13_09, "aclaracion":c12a13_10, "hh_md":c12a13_11, "hh_aa":c12a13_12},
                   {"horario":"12:30 a 13:00", "actividad":c12a13_01_2, "perforacion":c12a13_02_2, "condicion_humedad":c12a13_03_2, "acero_perforacion":c12a13_06_2, "herramienta":c12a13_04_2, "diametro_ocupado":c12a13_05_2, "num_serie_diam_ocup":c12a13_13_2, "mts_inicio":c12a13_08_2, "mts_fin":c12a13_09_2, "aclaracion":c12a13_10_2, "hh_md":c12a13_11_2, "hh_aa":c12a13_12_2},
                   {"horario":"13:00 a 13:30", "actividad":c13a14_01, "perforacion":c13a14_02, "condicion_humedad":c13a14_03, "acero_perforacion":c13a14_06, "herramienta":c13a14_04, "diametro_ocupado":c13a14_05, "num_serie_diam_ocup":c13a14_13, "mts_inicio":c13a14_08, "mts_fin":c13a14_09, "aclaracion":c13a14_10, "hh_md":c13a14_11, "hh_aa":c13a14_12},
                   {"horario":"13:30 a 14:00", "actividad":c13a14_01_2, "perforacion":c13a14_02_2, "condicion_humedad":c13a14_03_2, "acero_perforacion":c13a14_06_2, "herramienta":c13a14_04_2, "diametro_ocupado":c13a14_05_2, "num_serie_diam_ocup":c13a14_13_2, "mts_inicio":c13a14_08_2, "mts_fin":c13a14_09_2, "aclaracion":c13a14_10_2, "hh_md":c13a14_11_2, "hh_aa":c13a14_12_2},
                   {"horario":"14:00 a 14:30", "actividad":c14a15_01, "perforacion":c14a15_02, "condicion_humedad":c14a15_03, "acero_perforacion":c14a15_06, "herramienta":c14a15_04, "diametro_ocupado":c14a15_05, "num_serie_diam_ocup":c14a15_13, "mts_inicio":c14a15_08, "mts_fin":c14a15_09, "aclaracion":c14a15_10, "hh_md":c14a15_11, "hh_aa":c14a15_12},
                   {"horario":"14:30 a 15:00", "actividad":c14a15_01_2, "perforacion":c14a15_02_2, "condicion_humedad":c14a15_03_2, "acero_perforacion":c14a15_06_2, "herramienta":c14a15_04_2, "diametro_ocupado":c14a15_05_2, "num_serie_diam_ocup":c14a15_13_2, "mts_inicio":c14a15_08_2, "mts_fin":c14a15_09_2, "aclaracion":c14a15_10_2, "hh_md":c14a15_11_2, "hh_aa":c14a15_12_2},
                   {"horario":"15:00 a 15:30", "actividad":c15a16_01, "perforacion":c15a16_02, "condicion_humedad":c15a16_03, "acero_perforacion":c15a16_06, "herramienta":c15a16_04, "diametro_ocupado":c15a16_05, "num_serie_diam_ocup":c15a16_13, "mts_inicio":c15a16_08, "mts_fin":c15a16_09, "aclaracion":c15a16_10, "hh_md":c15a16_11, "hh_aa":c15a16_12},
                   {"horario":"15:30 a 16:00", "actividad":c15a16_01_2, "perforacion":c15a16_02_2, "condicion_humedad":c15a16_03_2, "acero_perforacion":c15a16_06_2, "herramienta":c15a16_04_2, "diametro_ocupado":c15a16_05_2, "num_serie_diam_ocup":c15a16_13_2, "mts_inicio":c15a16_08_2, "mts_fin":c15a16_09_2, "aclaracion":c15a16_10_2, "hh_md":c15a16_11_2, "hh_aa":c15a16_12_2},
                   {"horario":"16:00 a 16:30", "actividad":c16a17_01, "perforacion":c16a17_02, "condicion_humedad":c16a17_03, "acero_perforacion":c16a17_06, "herramienta":c16a17_04, "diametro_ocupado":c16a17_05, "num_serie_diam_ocup":c16a17_13, "mts_inicio":c16a17_08, "mts_fin":c16a17_09, "aclaracion":c16a17_10, "hh_md":c16a17_11, "hh_aa":c16a17_12},
                   {"horario":"16:30 a 17:00", "actividad":c16a17_01_2, "perforacion":c16a17_02_2, "condicion_humedad":c16a17_03_2, "acero_perforacion":c16a17_06_2, "herramienta":c16a17_04_2, "diametro_ocupado":c16a17_05_2, "num_serie_diam_ocup":c16a17_13_2, "mts_inicio":c16a17_08_2, "mts_fin":c16a17_09_2, "aclaracion":c16a17_10_2, "hh_md":c16a17_11_2, "hh_aa":c16a17_12_2},
                   {"horario":"17:00 a 17:30", "actividad":c17a18_01, "perforacion":c17a18_02, "condicion_humedad":c17a18_03, "acero_perforacion":c17a18_06, "herramienta":c17a18_04, "diametro_ocupado":c17a18_05, "num_serie_diam_ocup":c17a18_13, "mts_inicio":c17a18_08, "mts_fin":c17a18_09, "aclaracion":c17a18_10, "hh_md":c17a18_11, "hh_aa":c17a18_12},
                   {"horario":"17:30 a 18:00", "actividad":c17a18_01_2, "perforacion":c17a18_02_2, "condicion_humedad":c17a18_03_2, "acero_perforacion":c17a18_06_2, "herramienta":c17a18_04_2, "diametro_ocupado":c17a18_05_2, "num_serie_diam_ocup":c17a18_13_2, "mts_inicio":c17a18_08_2, "mts_fin":c17a18_09_2, "aclaracion":c17a18_10_2, "hh_md":c17a18_11_2, "hh_aa":c17a18_12_2},
                   {"horario":"18:00 a 18:30", "actividad":c18a19_01, "perforacion":c18a19_02, "condicion_humedad":c18a19_03, "acero_perforacion":c18a19_06, "herramienta":c18a19_04, "diametro_ocupado":c18a19_05, "num_serie_diam_ocup":c18a19_13, "mts_inicio":c18a19_08, "mts_fin":c18a19_09, "aclaracion":c18a19_10, "hh_md":c18a19_11, "hh_aa":c18a19_12},
                   {"horario":"18:30 a 19:00", "actividad":c18a19_01_2, "perforacion":c18a19_02_2, "condicion_humedad":c18a19_03_2, "acero_perforacion":c18a19_06_2, "herramienta":c18a19_04_2, "diametro_ocupado":c18a19_05_2, "num_serie_diam_ocup":c18a19_13_2, "mts_inicio":c18a19_08_2, "mts_fin":c18a19_09_2, "aclaracion":c18a19_10_2, "hh_md":c18a19_11_2, "hh_aa":c18a19_12_2},
                   {"horario":"19:00 a 19:30", "actividad":c19a20_01, "perforacion":c19a20_02, "condicion_humedad":c19a20_03, "acero_perforacion":c19a20_06, "herramienta":c19a20_04, "diametro_ocupado":c19a20_05, "num_serie_diam_ocup":c19a20_13, "mts_inicio":c19a20_08, "mts_fin":c19a20_09, "aclaracion":c19a20_10, "hh_md":c19a20_11, "hh_aa":c19a20_12},
                   {"horario":"19:30 a 20:00", "actividad":c19a20_01_2, "perforacion":c19a20_02_2, "condicion_humedad":c19a20_03_2, "acero_perforacion":c19a20_06_2, "herramienta":c19a20_04_2, "diametro_ocupado":c19a20_05_2, "num_serie_diam_ocup":c19a20_13_2, "mts_inicio":c19a20_08_2, "mts_fin":c19a20_09_2, "aclaracion":c19a20_10_2, "hh_md":c19a20_11_2, "hh_aa":c19a20_12_2}
               ]
             },
            "turno_noche": {
              "cabecera": {
                "supervisor": cNoche04,
                "jefe_turno": cNoche01,
                "asesor_hse": cNoche05,
                "operador": cNoche12,
                "ayudante_1": cNoche02,
                "ayudante_2": cNoche03,
                "metros_desde": cNoche06,
                "metros_hasta": cNoche07,
                "metros_perforados": cNoche08,
                "meta": cNoche11,
                "horometro_desde": cNoche09,
                "horometro_hasta": cNoche10,
                "estado_pozo": cNoche13
              },
              "detalle": [
                  {"horario":"Horario", "actividad":"Actividad", "perforacion":"Perforacion", "condicion_humedad":"Condicion Humedad", "acero_perforacion":"Acero Perforacion", "herramienta":"Herramienta", "diametro_ocupado":"Diametro Ocupado", "num_serie_diam_ocup":"Num.Serie Diam.Ocup.","mts_inicio":"Mts.Inicio", "mts_fin":"Mts.Fin", "aclaracion":"Aclaracion", "hh_md":"HH MD", "hh_aa":"HH AA"},
                  {"horario":"20:00 a 20:30", "actividad":c20a21_01, "perforacion":c20a21_02, "condicion_humedad":c20a21_03, "acero_perforacion":c20a21_06, "herramienta":c20a21_04, "diametro_ocupado":c20a21_05, "num_serie_diam_ocup":c20a21_13, "mts_inicio":c20a21_08, "mts_fin":c20a21_09, "aclaracion":c20a21_10, "hh_md":c20a21_11, "hh_aa":c20a21_12},
                  {"horario":"20:30 a 21:00", "actividad":c20a21_01_2, "perforacion":c20a21_02_2, "condicion_humedad":c20a21_03_2, "acero_perforacion":c20a21_06_2, "herramienta":c20a21_04_2, "diametro_ocupado":c20a21_05_2, "num_serie_diam_ocup":c20a21_13_2, "mts_inicio":c20a21_08_2, "mts_fin":c20a21_09_2, "aclaracion":c20a21_10_2, "hh_md":c20a21_11_2, "hh_aa":c20a21_12_2},
                  {"horario":"21:00 a 21:30", "actividad":c21a22_01, "perforacion":c21a22_02, "condicion_humedad":c21a22_03, "acero_perforacion":c21a22_06, "herramienta":c21a22_04, "diametro_ocupado":c21a22_05, "num_serie_diam_ocup":c21a22_13, "mts_inicio":c21a22_08, "mts_fin":c21a22_09, "aclaracion":c21a22_10, "hh_md":c21a22_11, "hh_aa":c21a22_12},
                  {"horario":"21:30 a 22:00", "actividad":c21a22_01_2, "perforacion":c21a22_02_2, "condicion_humedad":c21a22_03_2, "acero_perforacion":c21a22_06_2, "herramienta":c21a22_04_2, "diametro_ocupado":c21a22_05_2, "num_serie_diam_ocup":c21a22_13_2, "mts_inicio":c21a22_08_2, "mts_fin":c21a22_09_2, "aclaracion":c21a22_10_2, "hh_md":c21a22_11_2, "hh_aa":c21a22_12_2},
                  {"horario":"22:00 a 22:30", "actividad":c22a23_01, "perforacion":c22a23_02, "condicion_humedad":c22a23_03, "acero_perforacion":c22a23_06, "herramienta":c22a23_04, "diametro_ocupado":c22a23_05, "num_serie_diam_ocup":c22a23_13, "mts_inicio":c22a23_08, "mts_fin":c22a23_09, "aclaracion":c22a23_10, "hh_md":c22a23_11, "hh_aa":c22a23_12},
                  {"horario":"22:30 a 23:00", "actividad":c22a23_01_2, "perforacion":c22a23_02_2, "condicion_humedad":c22a23_03_2, "acero_perforacion":c22a23_06_2, "herramienta":c22a23_04_2, "diametro_ocupado":c22a23_05_2, "num_serie_diam_ocup":c22a23_13_2, "mts_inicio":c22a23_08_2, "mts_fin":c22a23_09_2, "aclaracion":c22a23_10_2, "hh_md":c22a23_11_2, "hh_aa":c22a23_12_2},
                  {"horario":"23:00 a 23:30", "actividad":c23a24_01, "perforacion":c23a24_02, "condicion_humedad":c23a24_03, "acero_perforacion":c23a24_06, "herramienta":c23a24_04, "diametro_ocupado":c23a24_05, "num_serie_diam_ocup":c23a24_13, "mts_inicio":c23a24_08, "mts_fin":c23a24_09, "aclaracion":c23a24_10, "hh_md":c23a24_11, "hh_aa":c23a24_12},
                  {"horario":"23:30 a 24:00", "actividad":c23a24_01_2, "perforacion":c23a24_02_2, "condicion_humedad":c23a24_03_2, "acero_perforacion":c23a24_06_2, "herramienta":c23a24_04_2, "diametro_ocupado":c23a24_05_2, "num_serie_diam_ocup":c23a24_13_2, "mts_inicio":c23a24_08_2, "mts_fin":c23a24_09_2, "aclaracion":c23a24_10_2, "hh_md":c23a24_11_2, "hh_aa":c23a24_12_2},
                  {"horario":"24:00 a 00:30", "actividad":c24a01_01, "perforacion":c24a01_02, "condicion_humedad":c24a01_03, "acero_perforacion":c24a01_06, "herramienta":c24a01_04, "diametro_ocupado":c24a01_05, "num_serie_diam_ocup":c24a01_13, "mts_inicio":c24a01_08, "mts_fin":c24a01_09, "aclaracion":c24a01_10, "hh_md":c24a01_11, "hh_aa":c24a01_12},
                  {"horario":"00:30 a 01:00", "actividad":c24a01_01_2, "perforacion":c24a01_02_2, "condicion_humedad":c24a01_03_2, "acero_perforacion":c24a01_06_2, "herramienta":c24a01_04_2, "diametro_ocupado":c24a01_05_2, "num_serie_diam_ocup":c24a01_13_2, "mts_inicio":c24a01_08_2, "mts_fin":c24a01_09_2, "aclaracion":c24a01_10_2, "hh_md":c24a01_11_2, "hh_aa":c24a01_12_2},
                  {"horario":"01:00 a 01:30", "actividad":c01a02_01, "perforacion":c01a02_02, "condicion_humedad":c01a02_03, "acero_perforacion":c01a02_06, "herramienta":c01a02_04, "diametro_ocupado":c01a02_05, "num_serie_diam_ocup":c01a02_13, "mts_inicio":c01a02_08, "mts_fin":c01a02_09, "aclaracion":c01a02_10, "hh_md":c01a02_11, "hh_aa":c01a02_12},
                  {"horario":"01:30 a 02:00", "actividad":c01a02_01_2, "perforacion":c01a02_02_2, "condicion_humedad":c01a02_03_2, "acero_perforacion":c01a02_06_2, "herramienta":c01a02_04_2, "diametro_ocupado":c01a02_05_2, "num_serie_diam_ocup":c01a02_13_2, "mts_inicio":c01a02_08_2, "mts_fin":c01a02_09_2, "aclaracion":c01a02_10_2, "hh_md":c01a02_11_2, "hh_aa":c01a02_12_2},
                  {"horario":"02:00 a 02:30", "actividad":c02a03_01, "perforacion":c02a03_02, "condicion_humedad":c02a03_03, "acero_perforacion":c02a03_06, "herramienta":c02a03_04, "diametro_ocupado":c02a03_05, "num_serie_diam_ocup":c02a03_13, "mts_inicio":c02a03_08, "mts_fin":c02a03_09, "aclaracion":c02a03_10, "hh_md":c02a03_11, "hh_aa":c02a03_12},
                  {"horario":"02:30 a 03:00", "actividad":c02a03_01_2, "perforacion":c02a03_02_2, "condicion_humedad":c02a03_03_2, "acero_perforacion":c02a03_06_2, "herramienta":c02a03_04_2, "diametro_ocupado":c02a03_05_2, "num_serie_diam_ocup":c02a03_13_2, "mts_inicio":c02a03_08_2, "mts_fin":c02a03_09_2, "aclaracion":c02a03_10_2, "hh_md":c02a03_11_2, "hh_aa":c02a03_12_2},
                  {"horario":"03:00 a 03:30", "actividad":c03a04_01, "perforacion":c03a04_02, "condicion_humedad":c03a04_03, "acero_perforacion":c03a04_06, "herramienta":c03a04_04, "diametro_ocupado":c03a04_05, "num_serie_diam_ocup":c03a04_13, "mts_inicio":c03a04_08, "mts_fin":c03a04_09, "aclaracion":c03a04_10, "hh_md":c03a04_11, "hh_aa":c03a04_12},
                  {"horario":"03:30 a 04:00", "actividad":c03a04_01_2, "perforacion":c03a04_02_2, "condicion_humedad":c03a04_03_2, "acero_perforacion":c03a04_06_2, "herramienta":c03a04_04_2, "diametro_ocupado":c03a04_05_2, "num_serie_diam_ocup":c03a04_13_2, "mts_inicio":c03a04_08_2, "mts_fin":c03a04_09_2, "aclaracion":c03a04_10_2, "hh_md":c03a04_11_2, "hh_aa":c03a04_12_2},
                  {"horario":"04:00 a 04:30", "actividad":c04a05_01, "perforacion":c04a05_02, "condicion_humedad":c04a05_03, "acero_perforacion":c04a05_06, "herramienta":c04a05_04, "diametro_ocupado":c04a05_05, "num_serie_diam_ocup":c04a05_13, "mts_inicio":c04a05_08, "mts_fin":c04a05_09, "aclaracion":c04a05_10, "hh_md":c04a05_11, "hh_aa":c04a05_12},
                  {"horario":"04:30 a 05:00", "actividad":c04a05_01_2, "perforacion":c04a05_02_2, "condicion_humedad":c04a05_03_2, "acero_perforacion":c04a05_06_2, "herramienta":c04a05_04_2, "diametro_ocupado":c04a05_05_2, "num_serie_diam_ocup":c04a05_13_2, "mts_inicio":c04a05_08_2, "mts_fin":c04a05_09_2, "aclaracion":c04a05_10_2, "hh_md":c04a05_11_2, "hh_aa":c04a05_12_2},
                  {"horario":"05:00 a 05:30", "actividad":c05a06_01, "perforacion":c05a06_02, "condicion_humedad":c05a06_03, "acero_perforacion":c05a06_06, "herramienta":c05a06_04, "diametro_ocupado":c05a06_05, "num_serie_diam_ocup":c05a06_13, "mts_inicio":c05a06_08, "mts_fin":c05a06_09, "aclaracion":c05a06_10, "hh_md":c05a06_11, "hh_aa":c05a06_12},
                  {"horario":"05:30 a 06:00", "actividad":c05a06_01_2, "perforacion":c05a06_02_2, "condicion_humedad":c05a06_03_2, "acero_perforacion":c05a06_06_2, "herramienta":c05a06_04_2, "diametro_ocupado":c05a06_05_2, "num_serie_diam_ocup":c05a06_13_2, "mts_inicio":c05a06_08_2, "mts_fin":c05a06_09_2, "aclaracion":c05a06_10_2, "hh_md":c05a06_11_2, "hh_aa":c05a06_12_2},
                  {"horario":"06:00 a 06:30", "actividad":c06a07_01, "perforacion":c06a07_02, "condicion_humedad":c06a07_03, "acero_perforacion":c06a07_06, "herramienta":c06a07_04, "diametro_ocupado":c06a07_05, "num_serie_diam_ocup":c06a07_13, "mts_inicio":c06a07_08, "mts_fin":c06a07_09, "aclaracion":c06a07_10, "hh_md":c06a07_11, "hh_aa":c06a07_12},
                  {"horario":"06:30 a 07:00", "actividad":c06a07_01_2, "perforacion":c06a07_02_2, "condicion_humedad":c06a07_03_2, "acero_perforacion":c06a07_06_2, "herramienta":c06a07_04_2, "diametro_ocupado":c06a07_05_2, "num_serie_diam_ocup":c06a07_13_2, "mts_inicio":c06a07_08_2, "mts_fin":c06a07_09_2, "aclaracion":c06a07_10_2, "hh_md":c06a07_11_2, "hh_aa":c06a07_12_2},
                  {"horario":"07:00 a 07:30", "actividad":c07a08_01, "perforacion":c07a08_02, "condicion_humedad":c07a08_03, "acero_perforacion":c07a08_06, "herramienta":c07a08_04, "diametro_ocupado":c07a08_05, "num_serie_diam_ocup":c07a08_13, "mts_inicio":c07a08_08, "mts_fin":c07a08_09, "aclaracion":c07a08_10, "hh_md":c07a08_11, "hh_aa":c07a08_12},
                  {"horario":"07:30 a 08:00", "actividad":c07a08_01_2, "perforacion":c07a08_02_2, "condicion_humedad":c07a08_03_2, "acero_perforacion":c07a08_06_2, "herramienta":c07a08_04_2, "diametro_ocupado":c07a08_05_2, "num_serie_diam_ocup":c07a08_13_2, "mts_inicio":c07a08_08_2, "mts_fin":c07a08_09_2, "aclaracion":c07a08_10_2, "hh_md":c07a08_11_2, "hh_aa":c07a08_12_2}
              ]
            }
          }
        }

        this.setState({jsonData: JSON.stringify(jsonData)})
        this.setState({jsonDataSha256:sha256(JSON.stringify(jsonData))})
        localStorage.setItem('sha256',sha256(JSON.stringify(jsonData)))
      }
    }

    guardarturnodia2 = (modeX) => {
      if (modeX === 'guardar' &&
         (this.cReporteTurnoDia01.current.value === '' ||
          this.cReporteTurnoDia04.current.value === '' ||
          this.cReporteTurnoDia05.current.value === '' ||
          this.cReporteTurnoDia12.current.value === '')){
          this.setState({dialogOpenError: true, dialogMessageError: 'Faltan datos Supervisor, o Jefe de turno, o Asesor HSE, u Operador MD', dialogTitleError: 'Error' })
      }else{
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }

      const isocode = this.state.isocode
      const optionsDate = {
          weekday: "short",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour:	"2-digit",
          minute:	"2-digit",
          second:	"2-digit"
      };
      let reporteTurnoDateTimeX = new Date()
      reporteTurnoDateTimeX = reporteTurnoDateTimeX.toLocaleString(isocode,optionsDate)

      let palabra = this.cReporteTurnoDia01.current.value;
      let  ArrayIdValue = palabra.split('&&&')
      const	vReporteTurnoDia01 = ArrayIdValue[1]
      const vReporteTurnoDia01_ID = ArrayIdValue[0]
      this.setState({vReporteTurnoDia01,vReporteTurnoDia01_ID})
      const	vReporteTurnoDia02 = this.cReporteTurnoDia02.current.value;
      const	vReporteTurnoDia03 = this.cReporteTurnoDia03.current.value;

      let	vReporteTurnoDia04 = ''
      let vReporteTurnoDia04_ID = ''
      if (this.state.authRoleX === 'Supervisor MD'){
        	vReporteTurnoDia04 = this.state.authNameX
          vReporteTurnoDia04_ID = this.props.username
      }else{
        palabra = this.cReporteTurnoDia04.current.value;
        ArrayIdValue = palabra.split('&&&')
        	vReporteTurnoDia04 = ArrayIdValue[1]
          vReporteTurnoDia04_ID = ArrayIdValue[0]
      }
      this.setState({vReporteTurnoDia04,vReporteTurnoDia04_ID})

      palabra = this.cReporteTurnoDia05.current.value;
      ArrayIdValue = palabra.split('&&&')
      const	vReporteTurnoDia05 = ArrayIdValue[1]
      const vReporteTurnoDia05_ID = ArrayIdValue[0]
      this.setState({vReporteTurnoDia05,vReporteTurnoDia05_ID})

      const reporteTurnoEstadoSondajeX = this.cReporteTurnoEstadoSondaje.current.value;

      const	vReporteTurnoDia06 = this.cReporteTurnoDia06.current.value;
      const	vReporteTurnoDia07 = this.cReporteTurnoDia07.current.value;
      const	vReporteTurnoDia08 = this.cReporteTurnoDia08.current.value;
      const	vReporteTurnoDia09 = this.cReporteTurnoDia09.current.value;
      const	vReporteTurnoDia10 = this.cReporteTurnoDia10.current.value;
      const	vReporteTurnoDia11 = this.cReporteTurnoDia11.current.value;
      const	vReporteTurnoDia12 = this.cReporteTurnoDia12.current.value;
      const	vReporteTurnoDia13 = this.cReporteTurnoDia13.current.value;

      const	vReporteTurnoDia08a09_01 = this.cReporteTurnoDia08a09_01.current.value;
      const	vReporteTurnoDia08a09_02 = this.cReporteTurnoDia08a09_02.current.value;
      const	vReporteTurnoDia08a09_03 = this.cReporteTurnoDia08a09_03.current.value;
      const	vReporteTurnoDia08a09_04 = this.cReporteTurnoDia08a09_04.current.value;
      const	vReporteTurnoDia08a09_05 = this.cReporteTurnoDia08a09_05.current.value;
      const	vReporteTurnoDia08a09_06 = this.cReporteTurnoDia08a09_06.current.value;
      const	vReporteTurnoDia08a09_07 = this.cReporteTurnoDia08a09_07.current.value;
      const	vReporteTurnoDia08a09_08 = this.cReporteTurnoDia08a09_08.current.value;
      const	vReporteTurnoDia08a09_09 = this.cReporteTurnoDia08a09_09.current.value;
      const	vReporteTurnoDia08a09_10 = this.cReporteTurnoDia08a09_10.current.value;
      const	vReporteTurnoDia08a09_11 = this.cReporteTurnoDia08a09_11.current.value;
      const	vReporteTurnoDia08a09_12 = this.cReporteTurnoDia08a09_12.current.value;
      const	vReporteTurnoDia08a09_13 = this.cReporteTurnoDia08a09_13.current.value;

      const	vReporteTurnoDia09a10_01 = this.cReporteTurnoDia09a10_01.current.value;
      const	vReporteTurnoDia09a10_02 = this.cReporteTurnoDia09a10_02.current.value;
      const	vReporteTurnoDia09a10_03 = this.cReporteTurnoDia09a10_03.current.value;
      const	vReporteTurnoDia09a10_04 = this.cReporteTurnoDia09a10_04.current.value;
      const	vReporteTurnoDia09a10_05 = this.cReporteTurnoDia09a10_05.current.value;
      const	vReporteTurnoDia09a10_06 = this.cReporteTurnoDia09a10_06.current.value;
      const	vReporteTurnoDia09a10_07 = this.cReporteTurnoDia09a10_07.current.value;
      const	vReporteTurnoDia09a10_08 = this.cReporteTurnoDia09a10_08.current.value;
      const	vReporteTurnoDia09a10_09 = this.cReporteTurnoDia09a10_09.current.value;
      const	vReporteTurnoDia09a10_10 = this.cReporteTurnoDia09a10_10.current.value;
      const	vReporteTurnoDia09a10_11 = this.cReporteTurnoDia09a10_11.current.value;
      const	vReporteTurnoDia09a10_12 = this.cReporteTurnoDia09a10_12.current.value;
      const	vReporteTurnoDia09a10_13 = this.cReporteTurnoDia09a10_13.current.value;

      const	vReporteTurnoDia10a11_01 = this.cReporteTurnoDia10a11_01.current.value;
      const	vReporteTurnoDia10a11_02 = this.cReporteTurnoDia10a11_02.current.value;
      const	vReporteTurnoDia10a11_03 = this.cReporteTurnoDia10a11_03.current.value;
      const	vReporteTurnoDia10a11_04 = this.cReporteTurnoDia10a11_04.current.value;
      const	vReporteTurnoDia10a11_05 = this.cReporteTurnoDia10a11_05.current.value;
      const	vReporteTurnoDia10a11_06 = this.cReporteTurnoDia10a11_06.current.value;
      const	vReporteTurnoDia10a11_07 = this.cReporteTurnoDia10a11_07.current.value;
      const	vReporteTurnoDia10a11_08 = this.cReporteTurnoDia10a11_08.current.value;
      const	vReporteTurnoDia10a11_09 = this.cReporteTurnoDia10a11_09.current.value;
      const	vReporteTurnoDia10a11_10 = this.cReporteTurnoDia10a11_10.current.value;
      const	vReporteTurnoDia10a11_11 = this.cReporteTurnoDia10a11_11.current.value;
      const	vReporteTurnoDia10a11_12 = this.cReporteTurnoDia10a11_12.current.value;
      const	vReporteTurnoDia10a11_13 = this.cReporteTurnoDia10a11_13.current.value;

      const	vReporteTurnoDia11a12_01 = this.cReporteTurnoDia11a12_01.current.value;
      const	vReporteTurnoDia11a12_02 = this.cReporteTurnoDia11a12_02.current.value;
      const	vReporteTurnoDia11a12_03 = this.cReporteTurnoDia11a12_03.current.value;
      const	vReporteTurnoDia11a12_04 = this.cReporteTurnoDia11a12_04.current.value;
      const	vReporteTurnoDia11a12_05 = this.cReporteTurnoDia11a12_05.current.value;
      const	vReporteTurnoDia11a12_06 = this.cReporteTurnoDia11a12_06.current.value;
      const	vReporteTurnoDia11a12_07 = this.cReporteTurnoDia11a12_07.current.value;
      const	vReporteTurnoDia11a12_08 = this.cReporteTurnoDia11a12_08.current.value;
      const	vReporteTurnoDia11a12_09 = this.cReporteTurnoDia11a12_09.current.value;
      const	vReporteTurnoDia11a12_10 = this.cReporteTurnoDia11a12_10.current.value;
      const	vReporteTurnoDia11a12_11 = this.cReporteTurnoDia11a12_11.current.value;
      const	vReporteTurnoDia11a12_12 = this.cReporteTurnoDia11a12_12.current.value;
      const	vReporteTurnoDia11a12_13 = this.cReporteTurnoDia11a12_13.current.value;

      const	vReporteTurnoDia12a13_01 = this.cReporteTurnoDia12a13_01.current.value;
      const	vReporteTurnoDia12a13_02 = this.cReporteTurnoDia12a13_02.current.value;
      const	vReporteTurnoDia12a13_03 = this.cReporteTurnoDia12a13_03.current.value;
      const	vReporteTurnoDia12a13_04 = this.cReporteTurnoDia12a13_04.current.value;
      const	vReporteTurnoDia12a13_05 = this.cReporteTurnoDia12a13_05.current.value;
      const	vReporteTurnoDia12a13_06 = this.cReporteTurnoDia12a13_06.current.value;
      const	vReporteTurnoDia12a13_07 = this.cReporteTurnoDia12a13_07.current.value;
      const	vReporteTurnoDia12a13_08 = this.cReporteTurnoDia12a13_08.current.value;
      const	vReporteTurnoDia12a13_09 = this.cReporteTurnoDia12a13_09.current.value;
      const	vReporteTurnoDia12a13_10 = this.cReporteTurnoDia12a13_10.current.value;
      const	vReporteTurnoDia12a13_11 = this.cReporteTurnoDia12a13_11.current.value;
      const	vReporteTurnoDia12a13_12 = this.cReporteTurnoDia12a13_12.current.value;
      const	vReporteTurnoDia12a13_13 = this.cReporteTurnoDia12a13_13.current.value;

      const	vReporteTurnoDia13a14_01 = this.cReporteTurnoDia13a14_01.current.value;
      const	vReporteTurnoDia13a14_02 = this.cReporteTurnoDia13a14_02.current.value;
      const	vReporteTurnoDia13a14_03 = this.cReporteTurnoDia13a14_03.current.value;
      const	vReporteTurnoDia13a14_04 = this.cReporteTurnoDia13a14_04.current.value;
      const	vReporteTurnoDia13a14_05 = this.cReporteTurnoDia13a14_05.current.value;
      const	vReporteTurnoDia13a14_06 = this.cReporteTurnoDia13a14_06.current.value;
      const	vReporteTurnoDia13a14_07 = this.cReporteTurnoDia13a14_07.current.value;
      const	vReporteTurnoDia13a14_08 = this.cReporteTurnoDia13a14_08.current.value;
      const	vReporteTurnoDia13a14_09 = this.cReporteTurnoDia13a14_09.current.value;
      const	vReporteTurnoDia13a14_10 = this.cReporteTurnoDia13a14_10.current.value;
      const	vReporteTurnoDia13a14_11 = this.cReporteTurnoDia13a14_11.current.value;
      const	vReporteTurnoDia13a14_12 = this.cReporteTurnoDia13a14_12.current.value;
      const	vReporteTurnoDia13a14_13 = this.cReporteTurnoDia13a14_13.current.value;

      const	vReporteTurnoDia14a15_01 = this.cReporteTurnoDia14a15_01.current.value;
      const	vReporteTurnoDia14a15_02 = this.cReporteTurnoDia14a15_02.current.value;
      const	vReporteTurnoDia14a15_03 = this.cReporteTurnoDia14a15_03.current.value;
      const	vReporteTurnoDia14a15_04 = this.cReporteTurnoDia14a15_04.current.value;
      const	vReporteTurnoDia14a15_05 = this.cReporteTurnoDia14a15_05.current.value;
      const	vReporteTurnoDia14a15_06 = this.cReporteTurnoDia14a15_06.current.value;
      const	vReporteTurnoDia14a15_07 = this.cReporteTurnoDia14a15_07.current.value;
      const	vReporteTurnoDia14a15_08 = this.cReporteTurnoDia14a15_08.current.value;
      const	vReporteTurnoDia14a15_09 = this.cReporteTurnoDia14a15_09.current.value;
      const	vReporteTurnoDia14a15_10 = this.cReporteTurnoDia14a15_10.current.value;
      const	vReporteTurnoDia14a15_11 = this.cReporteTurnoDia14a15_11.current.value;
      const	vReporteTurnoDia14a15_12 = this.cReporteTurnoDia14a15_12.current.value;
      const	vReporteTurnoDia14a15_13 = this.cReporteTurnoDia14a15_13.current.value;

      const	vReporteTurnoDia15a16_01 = this.cReporteTurnoDia15a16_01.current.value;
      const	vReporteTurnoDia15a16_02 = this.cReporteTurnoDia15a16_02.current.value;
      const	vReporteTurnoDia15a16_03 = this.cReporteTurnoDia15a16_03.current.value;
      const	vReporteTurnoDia15a16_04 = this.cReporteTurnoDia15a16_04.current.value;
      const	vReporteTurnoDia15a16_05 = this.cReporteTurnoDia15a16_05.current.value;
      const	vReporteTurnoDia15a16_06 = this.cReporteTurnoDia15a16_06.current.value;
      const	vReporteTurnoDia15a16_07 = this.cReporteTurnoDia15a16_07.current.value;
      const	vReporteTurnoDia15a16_08 = this.cReporteTurnoDia15a16_08.current.value;
      const	vReporteTurnoDia15a16_09 = this.cReporteTurnoDia15a16_09.current.value;
      const	vReporteTurnoDia15a16_10 = this.cReporteTurnoDia15a16_10.current.value;
      const	vReporteTurnoDia15a16_11 = this.cReporteTurnoDia15a16_11.current.value;
      const	vReporteTurnoDia15a16_12 = this.cReporteTurnoDia15a16_12.current.value;
      const	vReporteTurnoDia15a16_13 = this.cReporteTurnoDia15a16_13.current.value;

      const	vReporteTurnoDia16a17_01 = this.cReporteTurnoDia16a17_01.current.value;
      const	vReporteTurnoDia16a17_02 = this.cReporteTurnoDia16a17_02.current.value;
      const	vReporteTurnoDia16a17_03 = this.cReporteTurnoDia16a17_03.current.value;
      const	vReporteTurnoDia16a17_04 = this.cReporteTurnoDia16a17_04.current.value;
      const	vReporteTurnoDia16a17_05 = this.cReporteTurnoDia16a17_05.current.value;
      const	vReporteTurnoDia16a17_06 = this.cReporteTurnoDia16a17_06.current.value;
      const	vReporteTurnoDia16a17_07 = this.cReporteTurnoDia16a17_07.current.value;
      const	vReporteTurnoDia16a17_08 = this.cReporteTurnoDia16a17_08.current.value;
      const	vReporteTurnoDia16a17_09 = this.cReporteTurnoDia16a17_09.current.value;
      const	vReporteTurnoDia16a17_10 = this.cReporteTurnoDia16a17_10.current.value;
      const	vReporteTurnoDia16a17_11 = this.cReporteTurnoDia16a17_11.current.value;
      const	vReporteTurnoDia16a17_12 = this.cReporteTurnoDia16a17_12.current.value;
      const	vReporteTurnoDia16a17_13 = this.cReporteTurnoDia16a17_13.current.value;

      const	vReporteTurnoDia17a18_01 = this.cReporteTurnoDia17a18_01.current.value;
      const	vReporteTurnoDia17a18_02 = this.cReporteTurnoDia17a18_02.current.value;
      const	vReporteTurnoDia17a18_03 = this.cReporteTurnoDia17a18_03.current.value;
      const	vReporteTurnoDia17a18_04 = this.cReporteTurnoDia17a18_04.current.value;
      const	vReporteTurnoDia17a18_05 = this.cReporteTurnoDia17a18_05.current.value;
      const	vReporteTurnoDia17a18_06 = this.cReporteTurnoDia17a18_06.current.value;
      const	vReporteTurnoDia17a18_07 = this.cReporteTurnoDia17a18_07.current.value;
      const	vReporteTurnoDia17a18_08 = this.cReporteTurnoDia17a18_08.current.value;
      const	vReporteTurnoDia17a18_09 = this.cReporteTurnoDia17a18_09.current.value;
      const	vReporteTurnoDia17a18_10 = this.cReporteTurnoDia17a18_10.current.value;
      const	vReporteTurnoDia17a18_11 = this.cReporteTurnoDia17a18_11.current.value;
      const	vReporteTurnoDia17a18_12 = this.cReporteTurnoDia17a18_12.current.value;
      const	vReporteTurnoDia17a18_13 = this.cReporteTurnoDia17a18_13.current.value;

      const	vReporteTurnoDia18a19_01 = this.cReporteTurnoDia18a19_01.current.value;
      const	vReporteTurnoDia18a19_02 = this.cReporteTurnoDia18a19_02.current.value;
      const	vReporteTurnoDia18a19_03 = this.cReporteTurnoDia18a19_03.current.value;
      const	vReporteTurnoDia18a19_04 = this.cReporteTurnoDia18a19_04.current.value;
      const	vReporteTurnoDia18a19_05 = this.cReporteTurnoDia18a19_05.current.value;
      const	vReporteTurnoDia18a19_06 = this.cReporteTurnoDia18a19_06.current.value;
      const	vReporteTurnoDia18a19_07 = this.cReporteTurnoDia18a19_07.current.value;
      const	vReporteTurnoDia18a19_08 = this.cReporteTurnoDia18a19_08.current.value;
      const	vReporteTurnoDia18a19_09 = this.cReporteTurnoDia18a19_09.current.value;
      const	vReporteTurnoDia18a19_10 = this.cReporteTurnoDia18a19_10.current.value;
      const	vReporteTurnoDia18a19_11 = this.cReporteTurnoDia18a19_11.current.value;
      const	vReporteTurnoDia18a19_12 = this.cReporteTurnoDia18a19_12.current.value;
      const	vReporteTurnoDia18a19_13 = this.cReporteTurnoDia18a19_13.current.value;

      const	vReporteTurnoDia19a20_01 = this.cReporteTurnoDia19a20_01.current.value;
      const	vReporteTurnoDia19a20_02 = this.cReporteTurnoDia19a20_02.current.value;
      const	vReporteTurnoDia19a20_03 = this.cReporteTurnoDia19a20_03.current.value;
      const	vReporteTurnoDia19a20_04 = this.cReporteTurnoDia19a20_04.current.value;
      const	vReporteTurnoDia19a20_05 = this.cReporteTurnoDia19a20_05.current.value;
      const	vReporteTurnoDia19a20_06 = this.cReporteTurnoDia19a20_06.current.value;
      const	vReporteTurnoDia19a20_07 = this.cReporteTurnoDia19a20_07.current.value;
      const	vReporteTurnoDia19a20_08 = this.cReporteTurnoDia19a20_08.current.value;
      const	vReporteTurnoDia19a20_09 = this.cReporteTurnoDia19a20_09.current.value;
      const	vReporteTurnoDia19a20_10 = this.cReporteTurnoDia19a20_10.current.value;
      const	vReporteTurnoDia19a20_11 = this.cReporteTurnoDia19a20_11.current.value;
      const	vReporteTurnoDia19a20_12 = this.cReporteTurnoDia19a20_12.current.value;
      const	vReporteTurnoDia19a20_13 = this.cReporteTurnoDia19a20_13.current.value;


      const	vReporteTurnoDia08a09_01_2 = this.cReporteTurnoDia08a09_01_2.current.value;
      const	vReporteTurnoDia08a09_02_2 = this.cReporteTurnoDia08a09_02_2.current.value;
      const	vReporteTurnoDia08a09_03_2 = this.cReporteTurnoDia08a09_03_2.current.value;
      const	vReporteTurnoDia08a09_04_2 = this.cReporteTurnoDia08a09_04_2.current.value;
      const	vReporteTurnoDia08a09_05_2 = this.cReporteTurnoDia08a09_05_2.current.value;
      const	vReporteTurnoDia08a09_06_2 = this.cReporteTurnoDia08a09_06_2.current.value;
      const	vReporteTurnoDia08a09_07_2 = this.cReporteTurnoDia08a09_07_2.current.value;
      const	vReporteTurnoDia08a09_08_2 = this.cReporteTurnoDia08a09_08_2.current.value;
      const	vReporteTurnoDia08a09_09_2 = this.cReporteTurnoDia08a09_09_2.current.value;
      const	vReporteTurnoDia08a09_10_2 = this.cReporteTurnoDia08a09_10_2.current.value;
      const	vReporteTurnoDia08a09_11_2 = this.cReporteTurnoDia08a09_11_2.current.value;
      const	vReporteTurnoDia08a09_12_2 = this.cReporteTurnoDia08a09_12_2.current.value;
      const	vReporteTurnoDia08a09_13_2 = this.cReporteTurnoDia08a09_13_2.current.value;

      const	vReporteTurnoDia09a10_01_2 = this.cReporteTurnoDia09a10_01_2.current.value;
      const	vReporteTurnoDia09a10_02_2 = this.cReporteTurnoDia09a10_02_2.current.value;
      const	vReporteTurnoDia09a10_03_2 = this.cReporteTurnoDia09a10_03_2.current.value;
      const	vReporteTurnoDia09a10_04_2 = this.cReporteTurnoDia09a10_04_2.current.value;
      const	vReporteTurnoDia09a10_05_2 = this.cReporteTurnoDia09a10_05_2.current.value;
      const	vReporteTurnoDia09a10_06_2 = this.cReporteTurnoDia09a10_06_2.current.value;
      const	vReporteTurnoDia09a10_07_2 = this.cReporteTurnoDia09a10_07_2.current.value;
      const	vReporteTurnoDia09a10_08_2 = this.cReporteTurnoDia09a10_08_2.current.value;
      const	vReporteTurnoDia09a10_09_2 = this.cReporteTurnoDia09a10_09_2.current.value;
      const	vReporteTurnoDia09a10_10_2 = this.cReporteTurnoDia09a10_10_2.current.value;
      const	vReporteTurnoDia09a10_11_2 = this.cReporteTurnoDia09a10_11_2.current.value;
      const	vReporteTurnoDia09a10_12_2 = this.cReporteTurnoDia09a10_12_2.current.value;
      const	vReporteTurnoDia09a10_13_2 = this.cReporteTurnoDia09a10_13_2.current.value;

      const	vReporteTurnoDia10a11_01_2 = this.cReporteTurnoDia10a11_01_2.current.value;
      const	vReporteTurnoDia10a11_02_2 = this.cReporteTurnoDia10a11_02_2.current.value;
      const	vReporteTurnoDia10a11_03_2 = this.cReporteTurnoDia10a11_03_2.current.value;
      const	vReporteTurnoDia10a11_04_2 = this.cReporteTurnoDia10a11_04_2.current.value;
      const	vReporteTurnoDia10a11_05_2 = this.cReporteTurnoDia10a11_05_2.current.value;
      const	vReporteTurnoDia10a11_06_2 = this.cReporteTurnoDia10a11_06_2.current.value;
      const	vReporteTurnoDia10a11_07_2 = this.cReporteTurnoDia10a11_07_2.current.value;
      const	vReporteTurnoDia10a11_08_2 = this.cReporteTurnoDia10a11_08_2.current.value;
      const	vReporteTurnoDia10a11_09_2 = this.cReporteTurnoDia10a11_09_2.current.value;
      const	vReporteTurnoDia10a11_10_2 = this.cReporteTurnoDia10a11_10_2.current.value;
      const	vReporteTurnoDia10a11_11_2 = this.cReporteTurnoDia10a11_11_2.current.value;
      const	vReporteTurnoDia10a11_12_2 = this.cReporteTurnoDia10a11_12_2.current.value;
      const	vReporteTurnoDia10a11_13_2 = this.cReporteTurnoDia10a11_13_2.current.value;

      const	vReporteTurnoDia11a12_01_2 = this.cReporteTurnoDia11a12_01_2.current.value;
      const	vReporteTurnoDia11a12_02_2 = this.cReporteTurnoDia11a12_02_2.current.value;
      const	vReporteTurnoDia11a12_03_2 = this.cReporteTurnoDia11a12_03_2.current.value;
      const	vReporteTurnoDia11a12_04_2 = this.cReporteTurnoDia11a12_04_2.current.value;
      const	vReporteTurnoDia11a12_05_2 = this.cReporteTurnoDia11a12_05_2.current.value;
      const	vReporteTurnoDia11a12_06_2 = this.cReporteTurnoDia11a12_06_2.current.value;
      const	vReporteTurnoDia11a12_07_2 = this.cReporteTurnoDia11a12_07_2.current.value;
      const	vReporteTurnoDia11a12_08_2 = this.cReporteTurnoDia11a12_08_2.current.value;
      const	vReporteTurnoDia11a12_09_2 = this.cReporteTurnoDia11a12_09_2.current.value;
      const	vReporteTurnoDia11a12_10_2 = this.cReporteTurnoDia11a12_10_2.current.value;
      const	vReporteTurnoDia11a12_11_2 = this.cReporteTurnoDia11a12_11_2.current.value;
      const	vReporteTurnoDia11a12_12_2 = this.cReporteTurnoDia11a12_12_2.current.value;
      const	vReporteTurnoDia11a12_13_2 = this.cReporteTurnoDia11a12_13_2.current.value;

      const	vReporteTurnoDia12a13_01_2 = this.cReporteTurnoDia12a13_01_2.current.value;
      const	vReporteTurnoDia12a13_02_2 = this.cReporteTurnoDia12a13_02_2.current.value;
      const	vReporteTurnoDia12a13_03_2 = this.cReporteTurnoDia12a13_03_2.current.value;
      const	vReporteTurnoDia12a13_04_2 = this.cReporteTurnoDia12a13_04_2.current.value;
      const	vReporteTurnoDia12a13_05_2 = this.cReporteTurnoDia12a13_05_2.current.value;
      const	vReporteTurnoDia12a13_06_2 = this.cReporteTurnoDia12a13_06_2.current.value;
      const	vReporteTurnoDia12a13_07_2 = this.cReporteTurnoDia12a13_07_2.current.value;
      const	vReporteTurnoDia12a13_08_2 = this.cReporteTurnoDia12a13_08_2.current.value;
      const	vReporteTurnoDia12a13_09_2 = this.cReporteTurnoDia12a13_09_2.current.value;
      const	vReporteTurnoDia12a13_10_2 = this.cReporteTurnoDia12a13_10_2.current.value;
      const	vReporteTurnoDia12a13_11_2 = this.cReporteTurnoDia12a13_11_2.current.value;
      const	vReporteTurnoDia12a13_12_2 = this.cReporteTurnoDia12a13_12_2.current.value;
      const	vReporteTurnoDia12a13_13_2 = this.cReporteTurnoDia12a13_13_2.current.value;

      const	vReporteTurnoDia13a14_01_2 = this.cReporteTurnoDia13a14_01_2.current.value;
      const	vReporteTurnoDia13a14_02_2 = this.cReporteTurnoDia13a14_02_2.current.value;
      const	vReporteTurnoDia13a14_03_2 = this.cReporteTurnoDia13a14_03_2.current.value;
      const	vReporteTurnoDia13a14_04_2 = this.cReporteTurnoDia13a14_04_2.current.value;
      const	vReporteTurnoDia13a14_05_2 = this.cReporteTurnoDia13a14_05_2.current.value;
      const	vReporteTurnoDia13a14_06_2 = this.cReporteTurnoDia13a14_06_2.current.value;
      const	vReporteTurnoDia13a14_07_2 = this.cReporteTurnoDia13a14_07_2.current.value;
      const	vReporteTurnoDia13a14_08_2 = this.cReporteTurnoDia13a14_08_2.current.value;
      const	vReporteTurnoDia13a14_09_2 = this.cReporteTurnoDia13a14_09_2.current.value;
      const	vReporteTurnoDia13a14_10_2 = this.cReporteTurnoDia13a14_10_2.current.value;
      const	vReporteTurnoDia13a14_11_2 = this.cReporteTurnoDia13a14_11_2.current.value;
      const	vReporteTurnoDia13a14_12_2 = this.cReporteTurnoDia13a14_12_2.current.value;
      const	vReporteTurnoDia13a14_13_2 = this.cReporteTurnoDia13a14_13_2.current.value;

      const	vReporteTurnoDia14a15_01_2 = this.cReporteTurnoDia14a15_01_2.current.value;
      const	vReporteTurnoDia14a15_02_2 = this.cReporteTurnoDia14a15_02_2.current.value;
      const	vReporteTurnoDia14a15_03_2 = this.cReporteTurnoDia14a15_03_2.current.value;
      const	vReporteTurnoDia14a15_04_2 = this.cReporteTurnoDia14a15_04_2.current.value;
      const	vReporteTurnoDia14a15_05_2 = this.cReporteTurnoDia14a15_05_2.current.value;
      const	vReporteTurnoDia14a15_06_2 = this.cReporteTurnoDia14a15_06_2.current.value;
      const	vReporteTurnoDia14a15_07_2 = this.cReporteTurnoDia14a15_07_2.current.value;
      const	vReporteTurnoDia14a15_08_2 = this.cReporteTurnoDia14a15_08_2.current.value;
      const	vReporteTurnoDia14a15_09_2 = this.cReporteTurnoDia14a15_09_2.current.value;
      const	vReporteTurnoDia14a15_10_2 = this.cReporteTurnoDia14a15_10_2.current.value;
      const	vReporteTurnoDia14a15_11_2 = this.cReporteTurnoDia14a15_11_2.current.value;
      const	vReporteTurnoDia14a15_12_2 = this.cReporteTurnoDia14a15_12_2.current.value;
      const	vReporteTurnoDia14a15_13_2 = this.cReporteTurnoDia14a15_13_2.current.value;

      const	vReporteTurnoDia15a16_01_2 = this.cReporteTurnoDia15a16_01_2.current.value;
      const	vReporteTurnoDia15a16_02_2 = this.cReporteTurnoDia15a16_02_2.current.value;
      const	vReporteTurnoDia15a16_03_2 = this.cReporteTurnoDia15a16_03_2.current.value;
      const	vReporteTurnoDia15a16_04_2 = this.cReporteTurnoDia15a16_04_2.current.value;
      const	vReporteTurnoDia15a16_05_2 = this.cReporteTurnoDia15a16_05_2.current.value;
      const	vReporteTurnoDia15a16_06_2 = this.cReporteTurnoDia15a16_06_2.current.value;
      const	vReporteTurnoDia15a16_07_2 = this.cReporteTurnoDia15a16_07_2.current.value;
      const	vReporteTurnoDia15a16_08_2 = this.cReporteTurnoDia15a16_08_2.current.value;
      const	vReporteTurnoDia15a16_09_2 = this.cReporteTurnoDia15a16_09_2.current.value;
      const	vReporteTurnoDia15a16_10_2 = this.cReporteTurnoDia15a16_10_2.current.value;
      const	vReporteTurnoDia15a16_11_2 = this.cReporteTurnoDia15a16_11_2.current.value;
      const	vReporteTurnoDia15a16_12_2 = this.cReporteTurnoDia15a16_12_2.current.value;
      const	vReporteTurnoDia15a16_13_2 = this.cReporteTurnoDia15a16_13_2.current.value;

      const	vReporteTurnoDia16a17_01_2 = this.cReporteTurnoDia16a17_01_2.current.value;
      const	vReporteTurnoDia16a17_02_2 = this.cReporteTurnoDia16a17_02_2.current.value;
      const	vReporteTurnoDia16a17_03_2 = this.cReporteTurnoDia16a17_03_2.current.value;
      const	vReporteTurnoDia16a17_04_2 = this.cReporteTurnoDia16a17_04_2.current.value;
      const	vReporteTurnoDia16a17_05_2 = this.cReporteTurnoDia16a17_05_2.current.value;
      const	vReporteTurnoDia16a17_06_2 = this.cReporteTurnoDia16a17_06_2.current.value;
      const	vReporteTurnoDia16a17_07_2 = this.cReporteTurnoDia16a17_07_2.current.value;
      const	vReporteTurnoDia16a17_08_2 = this.cReporteTurnoDia16a17_08_2.current.value;
      const	vReporteTurnoDia16a17_09_2 = this.cReporteTurnoDia16a17_09_2.current.value;
      const	vReporteTurnoDia16a17_10_2 = this.cReporteTurnoDia16a17_10_2.current.value;
      const	vReporteTurnoDia16a17_11_2 = this.cReporteTurnoDia16a17_11_2.current.value;
      const	vReporteTurnoDia16a17_12_2 = this.cReporteTurnoDia16a17_12_2.current.value;
      const	vReporteTurnoDia16a17_13_2 = this.cReporteTurnoDia16a17_13_2.current.value;

      const	vReporteTurnoDia17a18_01_2 = this.cReporteTurnoDia17a18_01_2.current.value;
      const	vReporteTurnoDia17a18_02_2 = this.cReporteTurnoDia17a18_02_2.current.value;
      const	vReporteTurnoDia17a18_03_2 = this.cReporteTurnoDia17a18_03_2.current.value;
      const	vReporteTurnoDia17a18_04_2 = this.cReporteTurnoDia17a18_04_2.current.value;
      const	vReporteTurnoDia17a18_05_2 = this.cReporteTurnoDia17a18_05_2.current.value;
      const	vReporteTurnoDia17a18_06_2 = this.cReporteTurnoDia17a18_06_2.current.value;
      const	vReporteTurnoDia17a18_07_2 = this.cReporteTurnoDia17a18_07_2.current.value;
      const	vReporteTurnoDia17a18_08_2 = this.cReporteTurnoDia17a18_08_2.current.value;
      const	vReporteTurnoDia17a18_09_2 = this.cReporteTurnoDia17a18_09_2.current.value;
      const	vReporteTurnoDia17a18_10_2 = this.cReporteTurnoDia17a18_10_2.current.value;
      const	vReporteTurnoDia17a18_11_2 = this.cReporteTurnoDia17a18_11_2.current.value;
      const	vReporteTurnoDia17a18_12_2 = this.cReporteTurnoDia17a18_12_2.current.value;
      const	vReporteTurnoDia17a18_13_2 = this.cReporteTurnoDia17a18_13_2.current.value;

      const	vReporteTurnoDia18a19_01_2 = this.cReporteTurnoDia18a19_01_2.current.value;
      const	vReporteTurnoDia18a19_02_2 = this.cReporteTurnoDia18a19_02_2.current.value;
      const	vReporteTurnoDia18a19_03_2 = this.cReporteTurnoDia18a19_03_2.current.value;
      const	vReporteTurnoDia18a19_04_2 = this.cReporteTurnoDia18a19_04_2.current.value;
      const	vReporteTurnoDia18a19_05_2 = this.cReporteTurnoDia18a19_05_2.current.value;
      const	vReporteTurnoDia18a19_06_2 = this.cReporteTurnoDia18a19_06_2.current.value;
      const	vReporteTurnoDia18a19_07_2 = this.cReporteTurnoDia18a19_07_2.current.value;
      const	vReporteTurnoDia18a19_08_2 = this.cReporteTurnoDia18a19_08_2.current.value;
      const	vReporteTurnoDia18a19_09_2 = this.cReporteTurnoDia18a19_09_2.current.value;
      const	vReporteTurnoDia18a19_10_2 = this.cReporteTurnoDia18a19_10_2.current.value;
      const	vReporteTurnoDia18a19_11_2 = this.cReporteTurnoDia18a19_11_2.current.value;
      const	vReporteTurnoDia18a19_12_2 = this.cReporteTurnoDia18a19_12_2.current.value;
      const	vReporteTurnoDia18a19_13_2 = this.cReporteTurnoDia18a19_13_2.current.value;

      const	vReporteTurnoDia19a20_01_2 = this.cReporteTurnoDia19a20_01_2.current.value;
      const	vReporteTurnoDia19a20_02_2 = this.cReporteTurnoDia19a20_02_2.current.value;
      const	vReporteTurnoDia19a20_03_2 = this.cReporteTurnoDia19a20_03_2.current.value;
      const	vReporteTurnoDia19a20_04_2 = this.cReporteTurnoDia19a20_04_2.current.value;
      const	vReporteTurnoDia19a20_05_2 = this.cReporteTurnoDia19a20_05_2.current.value;
      const	vReporteTurnoDia19a20_06_2 = this.cReporteTurnoDia19a20_06_2.current.value;
      const	vReporteTurnoDia19a20_07_2 = this.cReporteTurnoDia19a20_07_2.current.value;
      const	vReporteTurnoDia19a20_08_2 = this.cReporteTurnoDia19a20_08_2.current.value;
      const	vReporteTurnoDia19a20_09_2 = this.cReporteTurnoDia19a20_09_2.current.value;
      const	vReporteTurnoDia19a20_10_2 = this.cReporteTurnoDia19a20_10_2.current.value;
      const	vReporteTurnoDia19a20_11_2 = this.cReporteTurnoDia19a20_11_2.current.value;
      const	vReporteTurnoDia19a20_12_2 = this.cReporteTurnoDia19a20_12_2.current.value;
      const	vReporteTurnoDia19a20_13_2 = this.cReporteTurnoDia19a20_13_2.current.value;

      const jsonAttachFile =`{
"vReporteTurnoDia01":"${vReporteTurnoDia01}",
"vReporteTurnoDia02":"${vReporteTurnoDia02}",
"vReporteTurnoDia03":"${vReporteTurnoDia03}",
"vReporteTurnoDia04":"${vReporteTurnoDia04}",
"vReporteTurnoDia05":"${vReporteTurnoDia05}",
"vReporteTurnoDia06":"${vReporteTurnoDia06}",
"vReporteTurnoDia07":"${vReporteTurnoDia07}",
"vReporteTurnoDia08":"${vReporteTurnoDia08}",
"vReporteTurnoDia09":"${vReporteTurnoDia09}",
"vReporteTurnoDia10":"${vReporteTurnoDia10}",
"vReporteTurnoDia11":"${vReporteTurnoDia11}",
"vReporteTurnoDia12":"${vReporteTurnoDia12}",
"vReporteTurnoDia13":"${vReporteTurnoDia13}",
"vReporteTurnoDia08a09_01":"${vReporteTurnoDia08a09_01}",
"vReporteTurnoDia08a09_02":"${vReporteTurnoDia08a09_02}",
"vReporteTurnoDia08a09_03":"${vReporteTurnoDia08a09_03}",
"vReporteTurnoDia08a09_04":"${vReporteTurnoDia08a09_04}",
"vReporteTurnoDia08a09_05":"${vReporteTurnoDia08a09_05}",
"vReporteTurnoDia08a09_06":"${vReporteTurnoDia08a09_06}",
"vReporteTurnoDia08a09_07":"${vReporteTurnoDia08a09_07}",
"vReporteTurnoDia08a09_08":"${vReporteTurnoDia08a09_08}",
"vReporteTurnoDia08a09_09":"${vReporteTurnoDia08a09_09}",
"vReporteTurnoDia08a09_10":"${vReporteTurnoDia08a09_10}",
"vReporteTurnoDia08a09_11":"${vReporteTurnoDia08a09_11}",
"vReporteTurnoDia08a09_12":"${vReporteTurnoDia08a09_12}",
"vReporteTurnoDia08a09_13":"${vReporteTurnoDia08a09_13}",
"vReporteTurnoDia09a10_01":"${vReporteTurnoDia09a10_01}",
"vReporteTurnoDia09a10_02":"${vReporteTurnoDia09a10_02}",
"vReporteTurnoDia09a10_03":"${vReporteTurnoDia09a10_03}",
"vReporteTurnoDia09a10_04":"${vReporteTurnoDia09a10_04}",
"vReporteTurnoDia09a10_05":"${vReporteTurnoDia09a10_05}",
"vReporteTurnoDia09a10_06":"${vReporteTurnoDia09a10_06}",
"vReporteTurnoDia09a10_07":"${vReporteTurnoDia09a10_07}",
"vReporteTurnoDia09a10_08":"${vReporteTurnoDia09a10_08}",
"vReporteTurnoDia09a10_09":"${vReporteTurnoDia09a10_09}",
"vReporteTurnoDia09a10_10":"${vReporteTurnoDia09a10_10}",
"vReporteTurnoDia09a10_11":"${vReporteTurnoDia09a10_11}",
"vReporteTurnoDia09a10_12":"${vReporteTurnoDia09a10_12}",
"vReporteTurnoDia09a10_13":"${vReporteTurnoDia09a10_13}",
"vReporteTurnoDia10a11_01":"${vReporteTurnoDia10a11_01}",
"vReporteTurnoDia10a11_02":"${vReporteTurnoDia10a11_02}",
"vReporteTurnoDia10a11_03":"${vReporteTurnoDia10a11_03}",
"vReporteTurnoDia10a11_04":"${vReporteTurnoDia10a11_04}",
"vReporteTurnoDia10a11_05":"${vReporteTurnoDia10a11_05}",
"vReporteTurnoDia10a11_06":"${vReporteTurnoDia10a11_06}",
"vReporteTurnoDia10a11_07":"${vReporteTurnoDia10a11_07}",
"vReporteTurnoDia10a11_08":"${vReporteTurnoDia10a11_08}",
"vReporteTurnoDia10a11_09":"${vReporteTurnoDia10a11_09}",
"vReporteTurnoDia10a11_10":"${vReporteTurnoDia10a11_10}",
"vReporteTurnoDia10a11_11":"${vReporteTurnoDia10a11_11}",
"vReporteTurnoDia10a11_12":"${vReporteTurnoDia10a11_12}",
"vReporteTurnoDia10a11_13":"${vReporteTurnoDia10a11_13}",
"vReporteTurnoDia11a12_01":"${vReporteTurnoDia11a12_01}",
"vReporteTurnoDia11a12_02":"${vReporteTurnoDia11a12_02}",
"vReporteTurnoDia11a12_03":"${vReporteTurnoDia11a12_03}",
"vReporteTurnoDia11a12_04":"${vReporteTurnoDia11a12_04}",
"vReporteTurnoDia11a12_05":"${vReporteTurnoDia11a12_05}",
"vReporteTurnoDia11a12_06":"${vReporteTurnoDia11a12_06}",
"vReporteTurnoDia11a12_07":"${vReporteTurnoDia11a12_07}",
"vReporteTurnoDia11a12_08":"${vReporteTurnoDia11a12_08}",
"vReporteTurnoDia11a12_09":"${vReporteTurnoDia11a12_09}",
"vReporteTurnoDia11a12_10":"${vReporteTurnoDia11a12_10}",
"vReporteTurnoDia11a12_11":"${vReporteTurnoDia11a12_11}",
"vReporteTurnoDia11a12_12":"${vReporteTurnoDia11a12_12}",
"vReporteTurnoDia11a12_13":"${vReporteTurnoDia11a12_13}",
"vReporteTurnoDia12a13_01":"${vReporteTurnoDia12a13_01}",
"vReporteTurnoDia12a13_02":"${vReporteTurnoDia12a13_02}",
"vReporteTurnoDia12a13_03":"${vReporteTurnoDia12a13_03}",
"vReporteTurnoDia12a13_04":"${vReporteTurnoDia12a13_04}",
"vReporteTurnoDia12a13_05":"${vReporteTurnoDia12a13_05}",
"vReporteTurnoDia12a13_06":"${vReporteTurnoDia12a13_06}",
"vReporteTurnoDia12a13_07":"${vReporteTurnoDia12a13_07}",
"vReporteTurnoDia12a13_08":"${vReporteTurnoDia12a13_08}",
"vReporteTurnoDia12a13_09":"${vReporteTurnoDia12a13_09}",
"vReporteTurnoDia12a13_10":"${vReporteTurnoDia12a13_10}",
"vReporteTurnoDia12a13_11":"${vReporteTurnoDia12a13_11}",
"vReporteTurnoDia12a13_12":"${vReporteTurnoDia12a13_12}",
"vReporteTurnoDia12a13_13":"${vReporteTurnoDia12a13_13}",
"vReporteTurnoDia13a14_01":"${vReporteTurnoDia13a14_01}",
"vReporteTurnoDia13a14_02":"${vReporteTurnoDia13a14_02}",
"vReporteTurnoDia13a14_03":"${vReporteTurnoDia13a14_03}",
"vReporteTurnoDia13a14_04":"${vReporteTurnoDia13a14_04}",
"vReporteTurnoDia13a14_05":"${vReporteTurnoDia13a14_05}",
"vReporteTurnoDia13a14_06":"${vReporteTurnoDia13a14_06}",
"vReporteTurnoDia13a14_07":"${vReporteTurnoDia13a14_07}",
"vReporteTurnoDia13a14_08":"${vReporteTurnoDia13a14_08}",
"vReporteTurnoDia13a14_09":"${vReporteTurnoDia13a14_09}",
"vReporteTurnoDia13a14_10":"${vReporteTurnoDia13a14_10}",
"vReporteTurnoDia13a14_11":"${vReporteTurnoDia13a14_11}",
"vReporteTurnoDia13a14_12":"${vReporteTurnoDia13a14_12}",
"vReporteTurnoDia13a14_13":"${vReporteTurnoDia13a14_13}",
"vReporteTurnoDia14a15_01":"${vReporteTurnoDia14a15_01}",
"vReporteTurnoDia14a15_02":"${vReporteTurnoDia14a15_02}",
"vReporteTurnoDia14a15_03":"${vReporteTurnoDia14a15_03}",
"vReporteTurnoDia14a15_04":"${vReporteTurnoDia14a15_04}",
"vReporteTurnoDia14a15_05":"${vReporteTurnoDia14a15_05}",
"vReporteTurnoDia14a15_06":"${vReporteTurnoDia14a15_06}",
"vReporteTurnoDia14a15_07":"${vReporteTurnoDia14a15_07}",
"vReporteTurnoDia14a15_08":"${vReporteTurnoDia14a15_08}",
"vReporteTurnoDia14a15_09":"${vReporteTurnoDia14a15_09}",
"vReporteTurnoDia14a15_10":"${vReporteTurnoDia14a15_10}",
"vReporteTurnoDia14a15_11":"${vReporteTurnoDia14a15_11}",
"vReporteTurnoDia14a15_12":"${vReporteTurnoDia14a15_12}",
"vReporteTurnoDia14a15_13":"${vReporteTurnoDia14a15_13}",
"vReporteTurnoDia15a16_01":"${vReporteTurnoDia15a16_01}",
"vReporteTurnoDia15a16_02":"${vReporteTurnoDia15a16_02}",
"vReporteTurnoDia15a16_03":"${vReporteTurnoDia15a16_03}",
"vReporteTurnoDia15a16_04":"${vReporteTurnoDia15a16_04}",
"vReporteTurnoDia15a16_05":"${vReporteTurnoDia15a16_05}",
"vReporteTurnoDia15a16_06":"${vReporteTurnoDia15a16_06}",
"vReporteTurnoDia15a16_07":"${vReporteTurnoDia15a16_07}",
"vReporteTurnoDia15a16_08":"${vReporteTurnoDia15a16_08}",
"vReporteTurnoDia15a16_09":"${vReporteTurnoDia15a16_09}",
"vReporteTurnoDia15a16_10":"${vReporteTurnoDia15a16_10}",
"vReporteTurnoDia15a16_11":"${vReporteTurnoDia15a16_11}",
"vReporteTurnoDia15a16_12":"${vReporteTurnoDia15a16_12}",
"vReporteTurnoDia15a16_13":"${vReporteTurnoDia15a16_13}",
"vReporteTurnoDia16a17_01":"${vReporteTurnoDia16a17_01}",
"vReporteTurnoDia16a17_02":"${vReporteTurnoDia16a17_02}",
"vReporteTurnoDia16a17_03":"${vReporteTurnoDia16a17_03}",
"vReporteTurnoDia16a17_04":"${vReporteTurnoDia16a17_04}",
"vReporteTurnoDia16a17_05":"${vReporteTurnoDia16a17_05}",
"vReporteTurnoDia16a17_06":"${vReporteTurnoDia16a17_06}",
"vReporteTurnoDia16a17_07":"${vReporteTurnoDia16a17_07}",
"vReporteTurnoDia16a17_08":"${vReporteTurnoDia16a17_08}",
"vReporteTurnoDia16a17_09":"${vReporteTurnoDia16a17_09}",
"vReporteTurnoDia16a17_10":"${vReporteTurnoDia16a17_10}",
"vReporteTurnoDia16a17_11":"${vReporteTurnoDia16a17_11}",
"vReporteTurnoDia16a17_12":"${vReporteTurnoDia16a17_12}",
"vReporteTurnoDia16a17_13":"${vReporteTurnoDia16a17_13}",
"vReporteTurnoDia17a18_01":"${vReporteTurnoDia17a18_01}",
"vReporteTurnoDia17a18_02":"${vReporteTurnoDia17a18_02}",
"vReporteTurnoDia17a18_03":"${vReporteTurnoDia17a18_03}",
"vReporteTurnoDia17a18_04":"${vReporteTurnoDia17a18_04}",
"vReporteTurnoDia17a18_05":"${vReporteTurnoDia17a18_05}",
"vReporteTurnoDia17a18_06":"${vReporteTurnoDia17a18_06}",
"vReporteTurnoDia17a18_07":"${vReporteTurnoDia17a18_07}",
"vReporteTurnoDia17a18_08":"${vReporteTurnoDia17a18_08}",
"vReporteTurnoDia17a18_09":"${vReporteTurnoDia17a18_09}",
"vReporteTurnoDia17a18_10":"${vReporteTurnoDia17a18_10}",
"vReporteTurnoDia17a18_11":"${vReporteTurnoDia17a18_11}",
"vReporteTurnoDia17a18_12":"${vReporteTurnoDia17a18_12}",
"vReporteTurnoDia17a18_13":"${vReporteTurnoDia17a18_13}",
"vReporteTurnoDia18a19_01":"${vReporteTurnoDia18a19_01}",
"vReporteTurnoDia18a19_02":"${vReporteTurnoDia18a19_02}",
"vReporteTurnoDia18a19_03":"${vReporteTurnoDia18a19_03}",
"vReporteTurnoDia18a19_04":"${vReporteTurnoDia18a19_04}",
"vReporteTurnoDia18a19_05":"${vReporteTurnoDia18a19_05}",
"vReporteTurnoDia18a19_06":"${vReporteTurnoDia18a19_06}",
"vReporteTurnoDia18a19_07":"${vReporteTurnoDia18a19_07}",
"vReporteTurnoDia18a19_08":"${vReporteTurnoDia18a19_08}",
"vReporteTurnoDia18a19_09":"${vReporteTurnoDia18a19_09}",
"vReporteTurnoDia18a19_10":"${vReporteTurnoDia18a19_10}",
"vReporteTurnoDia18a19_11":"${vReporteTurnoDia18a19_11}",
"vReporteTurnoDia18a19_12":"${vReporteTurnoDia18a19_12}",
"vReporteTurnoDia18a19_13":"${vReporteTurnoDia18a19_13}",
"vReporteTurnoDia19a20_01":"${vReporteTurnoDia19a20_01}",
"vReporteTurnoDia19a20_02":"${vReporteTurnoDia19a20_02}",
"vReporteTurnoDia19a20_03":"${vReporteTurnoDia19a20_03}",
"vReporteTurnoDia19a20_04":"${vReporteTurnoDia19a20_04}",
"vReporteTurnoDia19a20_05":"${vReporteTurnoDia19a20_05}",
"vReporteTurnoDia19a20_06":"${vReporteTurnoDia19a20_06}",
"vReporteTurnoDia19a20_07":"${vReporteTurnoDia19a20_07}",
"vReporteTurnoDia19a20_08":"${vReporteTurnoDia19a20_08}",
"vReporteTurnoDia19a20_09":"${vReporteTurnoDia19a20_09}",
"vReporteTurnoDia19a20_10":"${vReporteTurnoDia19a20_10}",
"vReporteTurnoDia19a20_11":"${vReporteTurnoDia19a20_11}",
"vReporteTurnoDia19a20_12":"${vReporteTurnoDia19a20_12}",
"vReporteTurnoDia19a20_13":"${vReporteTurnoDia19a20_13}",
"vReporteTurnoDia08a09_01_2":"${vReporteTurnoDia08a09_01_2}",
"vReporteTurnoDia08a09_02_2":"${vReporteTurnoDia08a09_02_2}",
"vReporteTurnoDia08a09_03_2":"${vReporteTurnoDia08a09_03_2}",
"vReporteTurnoDia08a09_04_2":"${vReporteTurnoDia08a09_04_2}",
"vReporteTurnoDia08a09_05_2":"${vReporteTurnoDia08a09_05_2}",
"vReporteTurnoDia08a09_06_2":"${vReporteTurnoDia08a09_06_2}",
"vReporteTurnoDia08a09_07_2":"${vReporteTurnoDia08a09_07_2}",
"vReporteTurnoDia08a09_08_2":"${vReporteTurnoDia08a09_08_2}",
"vReporteTurnoDia08a09_09_2":"${vReporteTurnoDia08a09_09_2}",
"vReporteTurnoDia08a09_10_2":"${vReporteTurnoDia08a09_10_2}",
"vReporteTurnoDia08a09_11_2":"${vReporteTurnoDia08a09_11_2}",
"vReporteTurnoDia08a09_12_2":"${vReporteTurnoDia08a09_12_2}",
"vReporteTurnoDia08a09_13_2":"${vReporteTurnoDia08a09_13_2}",
"vReporteTurnoDia09a10_01_2":"${vReporteTurnoDia09a10_01_2}",
"vReporteTurnoDia09a10_02_2":"${vReporteTurnoDia09a10_02_2}",
"vReporteTurnoDia09a10_03_2":"${vReporteTurnoDia09a10_03_2}",
"vReporteTurnoDia09a10_04_2":"${vReporteTurnoDia09a10_04_2}",
"vReporteTurnoDia09a10_05_2":"${vReporteTurnoDia09a10_05_2}",
"vReporteTurnoDia09a10_06_2":"${vReporteTurnoDia09a10_06_2}",
"vReporteTurnoDia09a10_07_2":"${vReporteTurnoDia09a10_07_2}",
"vReporteTurnoDia09a10_08_2":"${vReporteTurnoDia09a10_08_2}",
"vReporteTurnoDia09a10_09_2":"${vReporteTurnoDia09a10_09_2}",
"vReporteTurnoDia09a10_10_2":"${vReporteTurnoDia09a10_10_2}",
"vReporteTurnoDia09a10_11_2":"${vReporteTurnoDia09a10_11_2}",
"vReporteTurnoDia09a10_12_2":"${vReporteTurnoDia09a10_12_2}",
"vReporteTurnoDia09a10_13_2":"${vReporteTurnoDia09a10_13_2}",
"vReporteTurnoDia10a11_01_2":"${vReporteTurnoDia10a11_01_2}",
"vReporteTurnoDia10a11_02_2":"${vReporteTurnoDia10a11_02_2}",
"vReporteTurnoDia10a11_03_2":"${vReporteTurnoDia10a11_03_2}",
"vReporteTurnoDia10a11_04_2":"${vReporteTurnoDia10a11_04_2}",
"vReporteTurnoDia10a11_05_2":"${vReporteTurnoDia10a11_05_2}",
"vReporteTurnoDia10a11_06_2":"${vReporteTurnoDia10a11_06_2}",
"vReporteTurnoDia10a11_07_2":"${vReporteTurnoDia10a11_07_2}",
"vReporteTurnoDia10a11_08_2":"${vReporteTurnoDia10a11_08_2}",
"vReporteTurnoDia10a11_09_2":"${vReporteTurnoDia10a11_09_2}",
"vReporteTurnoDia10a11_10_2":"${vReporteTurnoDia10a11_10_2}",
"vReporteTurnoDia10a11_11_2":"${vReporteTurnoDia10a11_11_2}",
"vReporteTurnoDia10a11_12_2":"${vReporteTurnoDia10a11_12_2}",
"vReporteTurnoDia10a11_13_2":"${vReporteTurnoDia10a11_13_2}",
"vReporteTurnoDia11a12_01_2":"${vReporteTurnoDia11a12_01_2}",
"vReporteTurnoDia11a12_02_2":"${vReporteTurnoDia11a12_02_2}",
"vReporteTurnoDia11a12_03_2":"${vReporteTurnoDia11a12_03_2}",
"vReporteTurnoDia11a12_04_2":"${vReporteTurnoDia11a12_04_2}",
"vReporteTurnoDia11a12_05_2":"${vReporteTurnoDia11a12_05_2}",
"vReporteTurnoDia11a12_06_2":"${vReporteTurnoDia11a12_06_2}",
"vReporteTurnoDia11a12_07_2":"${vReporteTurnoDia11a12_07_2}",
"vReporteTurnoDia11a12_08_2":"${vReporteTurnoDia11a12_08_2}",
"vReporteTurnoDia11a12_09_2":"${vReporteTurnoDia11a12_09_2}",
"vReporteTurnoDia11a12_10_2":"${vReporteTurnoDia11a12_10_2}",
"vReporteTurnoDia11a12_11_2":"${vReporteTurnoDia11a12_11_2}",
"vReporteTurnoDia11a12_12_2":"${vReporteTurnoDia11a12_12_2}",
"vReporteTurnoDia11a12_13_2":"${vReporteTurnoDia11a12_13_2}",
"vReporteTurnoDia12a13_01_2":"${vReporteTurnoDia12a13_01_2}",
"vReporteTurnoDia12a13_02_2":"${vReporteTurnoDia12a13_02_2}",
"vReporteTurnoDia12a13_03_2":"${vReporteTurnoDia12a13_03_2}",
"vReporteTurnoDia12a13_04_2":"${vReporteTurnoDia12a13_04_2}",
"vReporteTurnoDia12a13_05_2":"${vReporteTurnoDia12a13_05_2}",
"vReporteTurnoDia12a13_06_2":"${vReporteTurnoDia12a13_06_2}",
"vReporteTurnoDia12a13_07_2":"${vReporteTurnoDia12a13_07_2}",
"vReporteTurnoDia12a13_08_2":"${vReporteTurnoDia12a13_08_2}",
"vReporteTurnoDia12a13_09_2":"${vReporteTurnoDia12a13_09_2}",
"vReporteTurnoDia12a13_10_2":"${vReporteTurnoDia12a13_10_2}",
"vReporteTurnoDia12a13_11_2":"${vReporteTurnoDia12a13_11_2}",
"vReporteTurnoDia12a13_12_2":"${vReporteTurnoDia12a13_12_2}",
"vReporteTurnoDia12a13_13_2":"${vReporteTurnoDia12a13_13_2}",
"vReporteTurnoDia13a14_01_2":"${vReporteTurnoDia13a14_01_2}",
"vReporteTurnoDia13a14_02_2":"${vReporteTurnoDia13a14_02_2}",
"vReporteTurnoDia13a14_03_2":"${vReporteTurnoDia13a14_03_2}",
"vReporteTurnoDia13a14_04_2":"${vReporteTurnoDia13a14_04_2}",
"vReporteTurnoDia13a14_05_2":"${vReporteTurnoDia13a14_05_2}",
"vReporteTurnoDia13a14_06_2":"${vReporteTurnoDia13a14_06_2}",
"vReporteTurnoDia13a14_07_2":"${vReporteTurnoDia13a14_07_2}",
"vReporteTurnoDia13a14_08_2":"${vReporteTurnoDia13a14_08_2}",
"vReporteTurnoDia13a14_09_2":"${vReporteTurnoDia13a14_09_2}",
"vReporteTurnoDia13a14_10_2":"${vReporteTurnoDia13a14_10_2}",
"vReporteTurnoDia13a14_11_2":"${vReporteTurnoDia13a14_11_2}",
"vReporteTurnoDia13a14_12_2":"${vReporteTurnoDia13a14_12_2}",
"vReporteTurnoDia13a14_13_2":"${vReporteTurnoDia13a14_13_2}",
"vReporteTurnoDia14a15_01_2":"${vReporteTurnoDia14a15_01_2}",
"vReporteTurnoDia14a15_02_2":"${vReporteTurnoDia14a15_02_2}",
"vReporteTurnoDia14a15_03_2":"${vReporteTurnoDia14a15_03_2}",
"vReporteTurnoDia14a15_04_2":"${vReporteTurnoDia14a15_04_2}",
"vReporteTurnoDia14a15_05_2":"${vReporteTurnoDia14a15_05_2}",
"vReporteTurnoDia14a15_06_2":"${vReporteTurnoDia14a15_06_2}",
"vReporteTurnoDia14a15_07_2":"${vReporteTurnoDia14a15_07_2}",
"vReporteTurnoDia14a15_08_2":"${vReporteTurnoDia14a15_08_2}",
"vReporteTurnoDia14a15_09_2":"${vReporteTurnoDia14a15_09_2}",
"vReporteTurnoDia14a15_10_2":"${vReporteTurnoDia14a15_10_2}",
"vReporteTurnoDia14a15_11_2":"${vReporteTurnoDia14a15_11_2}",
"vReporteTurnoDia14a15_12_2":"${vReporteTurnoDia14a15_12_2}",
"vReporteTurnoDia14a15_13_2":"${vReporteTurnoDia14a15_13_2}",
"vReporteTurnoDia15a16_01_2":"${vReporteTurnoDia15a16_01_2}",
"vReporteTurnoDia15a16_02_2":"${vReporteTurnoDia15a16_02_2}",
"vReporteTurnoDia15a16_03_2":"${vReporteTurnoDia15a16_03_2}",
"vReporteTurnoDia15a16_04_2":"${vReporteTurnoDia15a16_04_2}",
"vReporteTurnoDia15a16_05_2":"${vReporteTurnoDia15a16_05_2}",
"vReporteTurnoDia15a16_06_2":"${vReporteTurnoDia15a16_06_2}",
"vReporteTurnoDia15a16_07_2":"${vReporteTurnoDia15a16_07_2}",
"vReporteTurnoDia15a16_08_2":"${vReporteTurnoDia15a16_08_2}",
"vReporteTurnoDia15a16_09_2":"${vReporteTurnoDia15a16_09_2}",
"vReporteTurnoDia15a16_10_2":"${vReporteTurnoDia15a16_10_2}",
"vReporteTurnoDia15a16_11_2":"${vReporteTurnoDia15a16_11_2}",
"vReporteTurnoDia15a16_12_2":"${vReporteTurnoDia15a16_12_2}",
"vReporteTurnoDia15a16_13_2":"${vReporteTurnoDia15a16_13_2}",
"vReporteTurnoDia16a17_01_2":"${vReporteTurnoDia16a17_01_2}",
"vReporteTurnoDia16a17_02_2":"${vReporteTurnoDia16a17_02_2}",
"vReporteTurnoDia16a17_03_2":"${vReporteTurnoDia16a17_03_2}",
"vReporteTurnoDia16a17_04_2":"${vReporteTurnoDia16a17_04_2}",
"vReporteTurnoDia16a17_05_2":"${vReporteTurnoDia16a17_05_2}",
"vReporteTurnoDia16a17_06_2":"${vReporteTurnoDia16a17_06_2}",
"vReporteTurnoDia16a17_07_2":"${vReporteTurnoDia16a17_07_2}",
"vReporteTurnoDia16a17_08_2":"${vReporteTurnoDia16a17_08_2}",
"vReporteTurnoDia16a17_09_2":"${vReporteTurnoDia16a17_09_2}",
"vReporteTurnoDia16a17_10_2":"${vReporteTurnoDia16a17_10_2}",
"vReporteTurnoDia16a17_11_2":"${vReporteTurnoDia16a17_11_2}",
"vReporteTurnoDia16a17_12_2":"${vReporteTurnoDia16a17_12_2}",
"vReporteTurnoDia16a17_13_2":"${vReporteTurnoDia16a17_13_2}",
"vReporteTurnoDia17a18_01_2":"${vReporteTurnoDia17a18_01_2}",
"vReporteTurnoDia17a18_02_2":"${vReporteTurnoDia17a18_02_2}",
"vReporteTurnoDia17a18_03_2":"${vReporteTurnoDia17a18_03_2}",
"vReporteTurnoDia17a18_04_2":"${vReporteTurnoDia17a18_04_2}",
"vReporteTurnoDia17a18_05_2":"${vReporteTurnoDia17a18_05_2}",
"vReporteTurnoDia17a18_06_2":"${vReporteTurnoDia17a18_06_2}",
"vReporteTurnoDia17a18_07_2":"${vReporteTurnoDia17a18_07_2}",
"vReporteTurnoDia17a18_08_2":"${vReporteTurnoDia17a18_08_2}",
"vReporteTurnoDia17a18_09_2":"${vReporteTurnoDia17a18_09_2}",
"vReporteTurnoDia17a18_10_2":"${vReporteTurnoDia17a18_10_2}",
"vReporteTurnoDia17a18_11_2":"${vReporteTurnoDia17a18_11_2}",
"vReporteTurnoDia17a18_12_2":"${vReporteTurnoDia17a18_12_2}",
"vReporteTurnoDia17a18_13_2":"${vReporteTurnoDia17a18_13_2}",
"vReporteTurnoDia18a19_01_2":"${vReporteTurnoDia18a19_01_2}",
"vReporteTurnoDia18a19_02_2":"${vReporteTurnoDia18a19_02_2}",
"vReporteTurnoDia18a19_03_2":"${vReporteTurnoDia18a19_03_2}",
"vReporteTurnoDia18a19_04_2":"${vReporteTurnoDia18a19_04_2}",
"vReporteTurnoDia18a19_05_2":"${vReporteTurnoDia18a19_05_2}",
"vReporteTurnoDia18a19_06_2":"${vReporteTurnoDia18a19_06_2}",
"vReporteTurnoDia18a19_07_2":"${vReporteTurnoDia18a19_07_2}",
"vReporteTurnoDia18a19_08_2":"${vReporteTurnoDia18a19_08_2}",
"vReporteTurnoDia18a19_09_2":"${vReporteTurnoDia18a19_09_2}",
"vReporteTurnoDia18a19_10_2":"${vReporteTurnoDia18a19_10_2}",
"vReporteTurnoDia18a19_11_2":"${vReporteTurnoDia18a19_11_2}",
"vReporteTurnoDia18a19_12_2":"${vReporteTurnoDia18a19_12_2}",
"vReporteTurnoDia18a19_13_2":"${vReporteTurnoDia18a19_13_2}",
"vReporteTurnoDia19a20_01_2":"${vReporteTurnoDia19a20_01_2}",
"vReporteTurnoDia19a20_02_2":"${vReporteTurnoDia19a20_02_2}",
"vReporteTurnoDia19a20_03_2":"${vReporteTurnoDia19a20_03_2}",
"vReporteTurnoDia19a20_04_2":"${vReporteTurnoDia19a20_04_2}",
"vReporteTurnoDia19a20_05_2":"${vReporteTurnoDia19a20_05_2}",
"vReporteTurnoDia19a20_06_2":"${vReporteTurnoDia19a20_06_2}",
"vReporteTurnoDia19a20_07_2":"${vReporteTurnoDia19a20_07_2}",
"vReporteTurnoDia19a20_08_2":"${vReporteTurnoDia19a20_08_2}",
"vReporteTurnoDia19a20_09_2":"${vReporteTurnoDia19a20_09_2}",
"vReporteTurnoDia19a20_10_2":"${vReporteTurnoDia19a20_10_2}",
"vReporteTurnoDia19a20_11_2":"${vReporteTurnoDia19a20_11_2}",
"vReporteTurnoDia19a20_12_2":"${vReporteTurnoDia19a20_12_2}",
"vReporteTurnoDia19a20_13_2":"${vReporteTurnoDia19a20_13_2}",
"vReporteTurnoDia01_ID":"${vReporteTurnoDia01_ID}",
"vReporteTurnoDia04_ID":"${vReporteTurnoDia04_ID}",
"vReporteTurnoDia05_ID":"${vReporteTurnoDia05_ID}",
"vReporteTurnoDiaFechaHora":"${reporteTurnoDateTimeX}",
"vReporteTurnoEstadoSondaje":"${reporteTurnoEstadoSondajeX}",
"vUUUID":"${this.state.uuidX}"}`

      if (modeX === 'guardar'){
        putFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_dia.json`, 'Crosscheck_Deliverable', JSON.stringify(jsonAttachFile), JSON.stringify(options))
            .then(() => {
              this.guardarTokenTurnoDia()
            })
      }else{
        this.setState({pdfReporteTurnoDiaX: jsonAttachFile})
      }
    }
  }

  guardarTokenTurnoDia(){
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
    const isocode = this.state.isocode
    const optionsDate = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour:	"2-digit",
        minute:	"2-digit",
        second:	"2-digit"
    };
    let tokenDateTimeX = new Date()
    tokenDateTimeX = tokenDateTimeX.toLocaleString(isocode,optionsDate)
    let tokenMayor = parseFloat(this.state.tokenMayorDiaX) + 1
    let jsonAttachFile ={"id":this.props.username,"role":this.state.authRoleX,"name":this.state.authNameX,"datetime":tokenDateTimeX,"token":tokenMayor}
    putFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_token_turno_dia.json`, 'Crosscheck_Deliverable', JSON.stringify(jsonAttachFile), JSON.stringify(options))
        .then(() => {
          this.guardarFondoPozoTurnoDia()
        })
  }

  guardarFondoPozoTurnoDia(){
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    let jsonBlockstack4d = []
    return new Promise ((resolve1, reject1) =>{
        getFileDB(this.state.userTurnoDiaFondoPozoX, `${this.props.typeContract}_${this.props.numberContract}_fondo_pozo_turno_dia.json`)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
                  let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                  jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                  this.guardarFondoPozoTurnoDia2(jsonBlockstack4d)
                  resolve1()
                } else {
                  this.guardarFondoPozoTurnoDia2(jsonBlockstack4d)
                  resolve1()
                }
              } else {
                this.guardarFondoPozoTurnoDia2(jsonBlockstack4d)
                resolve1()
              }
           })
          .catch(error => {
            this.guardarFondoPozoTurnoDia2(jsonBlockstack4d)
            resolve1()
          });
    })
  }

  guardarFondoPozoTurnoDia2(jsonBlockstack4d) {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
    let fechaX = this.state.fecha
    const idX = fechaX.substring(6, 10)+fechaX.substring(3, 5)+fechaX.substring(0, 2)
    let bEsta = false
    let fondoPozoTurnoDiaX2 = jsonBlockstack4d
    if (fondoPozoTurnoDiaX2.length > 0){
      fondoPozoTurnoDiaX2.map((todo,i) => {
        if (todo.fechafondopozo === this.state.fecha){
           todo.id = idX
           todo.fechafondopozo = this.state.fecha
           todo.inicio = this.cReporteTurnoDia06.current.value
           todo.fin = this.cReporteTurnoDia07.current.value
           todo.perforado = this.cReporteTurnoDia08.current.value
           bEsta = true
        }
      })
    }
    if (bEsta === false){
       fondoPozoTurnoDiaX2.push({"id":idX,"fechafondopozo":this.state.fecha,"inicio":this.cReporteTurnoDia06.current.value,"fin":this.cReporteTurnoDia07.current.value,"perforado":this.cReporteTurnoDia08.current.value})
    }
    putFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_fondo_pozo_turno_dia.json`, 'Crosscheck_Deliverable', JSON.stringify(fondoPozoTurnoDiaX2), JSON.stringify(options))
        .then(() => {
          TimerMixin.setTimeout( () => {
              this.handleDate(null, 'fechaReporte', 1)
          }, 700);
        })
  }

  eliminarturnodia2 = () =>{
    Promise.all(
        [this.deleteTurnoDia3(),
         this.deleteTurnoDia2(),
         this.deleteTurnoDia1()
        ]
      ).then(
        (resolve) =>{
          TimerMixin.setTimeout( () => {
              this.handleDate(null, 'fechaReporte', 1)
          }, 700);
        },
        (reject) =>{
        }
      )
  }

  deleteTurnoDia1 = () => {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    return new Promise ((resolve, reject) =>{
      deleteFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_fondo_pozo_turno_dia.json`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          resolve()
        })
    })
  }
  deleteTurnoDia2 = () => {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    return new Promise ((resolve, reject) =>{
      deleteFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_token_turno_dia.json`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          resolve()
        })
    })
  }
  deleteTurnoDia3 = () => {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    return new Promise ((resolve, reject) =>{
      deleteFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_dia.json`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          resolve()
        })
    })
  }

  eliminarturnonoche2 = () => {
    Promise.all(
        [this.deleteTurnoNoche3(),
         this.deleteTurnoNoche2(),
         this.deleteTurnoNoche1()
        ]
      ).then(
        (resolve) =>{
          TimerMixin.setTimeout( () => {
              this.handleDate(null, 'fechaReporte', 1)
          }, 700);
        },
        (reject) =>{
        }
      )
  }

  deleteTurnoNoche1 = () => {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    return new Promise ((resolve, reject) =>{
      deleteFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_fondo_pozo_turno_noche.json`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          resolve()
        })
    })
  }
  deleteTurnoNoche2 = () => {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    return new Promise ((resolve, reject) =>{
      deleteFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_token_turno_noche.json`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          resolve()
        })
    })
  }
  deleteTurnoNoche3 = () => {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    return new Promise ((resolve, reject) =>{
      deleteFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_noche.json`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          resolve()
        })
    })
  }

  guardarturnonoche2 = (modeX) => {
    if (modeX === 'guardar' &&
       (this.cReporteTurnoNoche01.current.value === '' ||
        this.cReporteTurnoNoche04.current.value === '' ||
        this.cReporteTurnoNoche05.current.value === '' ||
        this.cReporteTurnoNoche12.current.value === '')){
        this.setState({dialogOpenError: true, dialogMessageError: 'Faltan datos Supervisor, o Jefe de turno, o Asesor HSE, u Operador MD', dialogTitleError: 'Error' })
    }else{
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }

        const isocode = this.state.isocode
        const optionsDate = {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour:	"2-digit",
            minute:	"2-digit",
            second:	"2-digit"
    };
    let reporteTurnoDateTimeX = new Date()
    reporteTurnoDateTimeX = reporteTurnoDateTimeX.toLocaleString(isocode,optionsDate)

    let palabra = this.cReporteTurnoNoche01.current.value;
    let ArrayIdValue = palabra.split('&&&')
    const	vReporteTurnoNoche01 = ArrayIdValue[1]
    const vReporteTurnoNoche01_ID = ArrayIdValue[0]
    this.setState({vReporteTurnoNoche01,vReporteTurnoNoche01_ID})

    const	vReporteTurnoNoche02 = this.cReporteTurnoNoche02.current.value;
    const	vReporteTurnoNoche03 = this.cReporteTurnoNoche03.current.value;

    let	vReporteTurnoNoche04 = ''
    let vReporteTurnoNoche04_ID = ''
    if (this.state.authRoleX === 'Supervisor MD'){
      vReporteTurnoNoche04 = this.state.authNameX
      vReporteTurnoNoche04_ID = this.props.username
    }else{
      palabra = this.cReporteTurnoNoche04.current.value;
      ArrayIdValue = palabra.split('&&&')
      vReporteTurnoNoche04 = ArrayIdValue[1]
      vReporteTurnoNoche04_ID = ArrayIdValue[0]
    }
    this.setState({vReporteTurnoNoche04,vReporteTurnoNoche04_ID})

    palabra = this.cReporteTurnoNoche05.current.value;
    ArrayIdValue = palabra.split('&&&')
    const	vReporteTurnoNoche05 = ArrayIdValue[1]
    const vReporteTurnoNoche05_ID = ArrayIdValue[0]
    this.setState({vReporteTurnoNoche05,vReporteTurnoNoche05_ID})

    const reporteTurnoEstadoSondajeX = this.cReporteTurnoEstadoSondaje.current.value;

    const	vReporteTurnoNoche06 = this.cReporteTurnoNoche06.current.value;
    const	vReporteTurnoNoche07 = this.cReporteTurnoNoche07.current.value;
    const	vReporteTurnoNoche08 = this.cReporteTurnoNoche08.current.value;
    const	vReporteTurnoNoche09 = this.cReporteTurnoNoche09.current.value;
    const	vReporteTurnoNoche10 = this.cReporteTurnoNoche10.current.value;
    const	vReporteTurnoNoche11 = this.cReporteTurnoNoche11.current.value;
    const	vReporteTurnoNoche12 = this.cReporteTurnoNoche12.current.value;
    const	vReporteTurnoNoche13 = this.cReporteTurnoNoche13.current.value;

    const	vReporteTurnoNoche20a21_01 = this.cReporteTurnoNoche20a21_01.current.value;
    const	vReporteTurnoNoche20a21_02 = this.cReporteTurnoNoche20a21_02.current.value;
    const	vReporteTurnoNoche20a21_03 = this.cReporteTurnoNoche20a21_03.current.value;
    const	vReporteTurnoNoche20a21_04 = this.cReporteTurnoNoche20a21_04.current.value;
    const	vReporteTurnoNoche20a21_05 = this.cReporteTurnoNoche20a21_05.current.value;
    const	vReporteTurnoNoche20a21_06 = this.cReporteTurnoNoche20a21_06.current.value;
    const	vReporteTurnoNoche20a21_07 = this.cReporteTurnoNoche20a21_07.current.value;
    const	vReporteTurnoNoche20a21_08 = this.cReporteTurnoNoche20a21_08.current.value;
    const	vReporteTurnoNoche20a21_09 = this.cReporteTurnoNoche20a21_09.current.value;
    const	vReporteTurnoNoche20a21_10 = this.cReporteTurnoNoche20a21_10.current.value;
    const	vReporteTurnoNoche20a21_11 = this.cReporteTurnoNoche20a21_11.current.value;
    const	vReporteTurnoNoche20a21_12 = this.cReporteTurnoNoche20a21_12.current.value;
    const	vReporteTurnoNoche20a21_13 = this.cReporteTurnoNoche20a21_13.current.value;

    const	vReporteTurnoNoche21a22_01 = this.cReporteTurnoNoche21a22_01.current.value;
    const	vReporteTurnoNoche21a22_02 = this.cReporteTurnoNoche21a22_02.current.value;
    const	vReporteTurnoNoche21a22_03 = this.cReporteTurnoNoche21a22_03.current.value;
    const	vReporteTurnoNoche21a22_04 = this.cReporteTurnoNoche21a22_04.current.value;
    const	vReporteTurnoNoche21a22_05 = this.cReporteTurnoNoche21a22_05.current.value;
    const	vReporteTurnoNoche21a22_06 = this.cReporteTurnoNoche21a22_06.current.value;
    const	vReporteTurnoNoche21a22_07 = this.cReporteTurnoNoche21a22_07.current.value;
    const	vReporteTurnoNoche21a22_08 = this.cReporteTurnoNoche21a22_08.current.value;
    const	vReporteTurnoNoche21a22_09 = this.cReporteTurnoNoche21a22_09.current.value;
    const	vReporteTurnoNoche21a22_10 = this.cReporteTurnoNoche21a22_10.current.value;
    const	vReporteTurnoNoche21a22_11 = this.cReporteTurnoNoche21a22_11.current.value;
    const	vReporteTurnoNoche21a22_12 = this.cReporteTurnoNoche21a22_12.current.value;
    const	vReporteTurnoNoche21a22_13 = this.cReporteTurnoNoche21a22_13.current.value;

    const	vReporteTurnoNoche22a23_01 = this.cReporteTurnoNoche22a23_01.current.value;
    const	vReporteTurnoNoche22a23_02 = this.cReporteTurnoNoche22a23_02.current.value;
    const	vReporteTurnoNoche22a23_03 = this.cReporteTurnoNoche22a23_03.current.value;
    const	vReporteTurnoNoche22a23_04 = this.cReporteTurnoNoche22a23_04.current.value;
    const	vReporteTurnoNoche22a23_05 = this.cReporteTurnoNoche22a23_05.current.value;
    const	vReporteTurnoNoche22a23_06 = this.cReporteTurnoNoche22a23_06.current.value;
    const	vReporteTurnoNoche22a23_07 = this.cReporteTurnoNoche22a23_07.current.value;
    const	vReporteTurnoNoche22a23_08 = this.cReporteTurnoNoche22a23_08.current.value;
    const	vReporteTurnoNoche22a23_09 = this.cReporteTurnoNoche22a23_09.current.value;
    const	vReporteTurnoNoche22a23_10 = this.cReporteTurnoNoche22a23_10.current.value;
    const	vReporteTurnoNoche22a23_11 = this.cReporteTurnoNoche22a23_11.current.value;
    const	vReporteTurnoNoche22a23_12 = this.cReporteTurnoNoche22a23_12.current.value;
    const	vReporteTurnoNoche22a23_13 = this.cReporteTurnoNoche22a23_13.current.value;

    const	vReporteTurnoNoche23a24_01 = this.cReporteTurnoNoche23a24_01.current.value;
    const	vReporteTurnoNoche23a24_02 = this.cReporteTurnoNoche23a24_02.current.value;
    const	vReporteTurnoNoche23a24_03 = this.cReporteTurnoNoche23a24_03.current.value;
    const	vReporteTurnoNoche23a24_04 = this.cReporteTurnoNoche23a24_04.current.value;
    const	vReporteTurnoNoche23a24_05 = this.cReporteTurnoNoche23a24_05.current.value;
    const	vReporteTurnoNoche23a24_06 = this.cReporteTurnoNoche23a24_06.current.value;
    const	vReporteTurnoNoche23a24_07 = this.cReporteTurnoNoche23a24_07.current.value;
    const	vReporteTurnoNoche23a24_08 = this.cReporteTurnoNoche23a24_08.current.value;
    const	vReporteTurnoNoche23a24_09 = this.cReporteTurnoNoche23a24_09.current.value;
    const	vReporteTurnoNoche23a24_10 = this.cReporteTurnoNoche23a24_10.current.value;
    const	vReporteTurnoNoche23a24_11 = this.cReporteTurnoNoche23a24_11.current.value;
    const	vReporteTurnoNoche23a24_12 = this.cReporteTurnoNoche23a24_12.current.value;
    const	vReporteTurnoNoche23a24_13 = this.cReporteTurnoNoche23a24_13.current.value;

    const	vReporteTurnoNoche24a01_01 = this.cReporteTurnoNoche24a01_01.current.value;
    const	vReporteTurnoNoche24a01_02 = this.cReporteTurnoNoche24a01_02.current.value;
    const	vReporteTurnoNoche24a01_03 = this.cReporteTurnoNoche24a01_03.current.value;
    const	vReporteTurnoNoche24a01_04 = this.cReporteTurnoNoche24a01_04.current.value;
    const	vReporteTurnoNoche24a01_05 = this.cReporteTurnoNoche24a01_05.current.value;
    const	vReporteTurnoNoche24a01_06 = this.cReporteTurnoNoche24a01_06.current.value;
    const	vReporteTurnoNoche24a01_07 = this.cReporteTurnoNoche24a01_07.current.value;
    const	vReporteTurnoNoche24a01_08 = this.cReporteTurnoNoche24a01_08.current.value;
    const	vReporteTurnoNoche24a01_09 = this.cReporteTurnoNoche24a01_09.current.value;
    const	vReporteTurnoNoche24a01_10 = this.cReporteTurnoNoche24a01_10.current.value;
    const	vReporteTurnoNoche24a01_11 = this.cReporteTurnoNoche24a01_11.current.value;
    const	vReporteTurnoNoche24a01_12 = this.cReporteTurnoNoche24a01_12.current.value;
    const	vReporteTurnoNoche24a01_13 = this.cReporteTurnoNoche24a01_13.current.value;

    const	vReporteTurnoNoche01a02_01 = this.cReporteTurnoNoche01a02_01.current.value;
    const	vReporteTurnoNoche01a02_02 = this.cReporteTurnoNoche01a02_02.current.value;
    const	vReporteTurnoNoche01a02_03 = this.cReporteTurnoNoche01a02_03.current.value;
    const	vReporteTurnoNoche01a02_04 = this.cReporteTurnoNoche01a02_04.current.value;
    const	vReporteTurnoNoche01a02_05 = this.cReporteTurnoNoche01a02_05.current.value;
    const	vReporteTurnoNoche01a02_06 = this.cReporteTurnoNoche01a02_06.current.value;
    const	vReporteTurnoNoche01a02_07 = this.cReporteTurnoNoche01a02_07.current.value;
    const	vReporteTurnoNoche01a02_08 = this.cReporteTurnoNoche01a02_08.current.value;
    const	vReporteTurnoNoche01a02_09 = this.cReporteTurnoNoche01a02_09.current.value;
    const	vReporteTurnoNoche01a02_10 = this.cReporteTurnoNoche01a02_10.current.value;
    const	vReporteTurnoNoche01a02_11 = this.cReporteTurnoNoche01a02_11.current.value;
    const	vReporteTurnoNoche01a02_12 = this.cReporteTurnoNoche01a02_12.current.value;
    const	vReporteTurnoNoche01a02_13 = this.cReporteTurnoNoche01a02_13.current.value;

    const	vReporteTurnoNoche02a03_01 = this.cReporteTurnoNoche02a03_01.current.value;
    const	vReporteTurnoNoche02a03_02 = this.cReporteTurnoNoche02a03_02.current.value;
    const	vReporteTurnoNoche02a03_03 = this.cReporteTurnoNoche02a03_03.current.value;
    const	vReporteTurnoNoche02a03_04 = this.cReporteTurnoNoche02a03_04.current.value;
    const	vReporteTurnoNoche02a03_05 = this.cReporteTurnoNoche02a03_05.current.value;
    const	vReporteTurnoNoche02a03_06 = this.cReporteTurnoNoche02a03_06.current.value;
    const	vReporteTurnoNoche02a03_07 = this.cReporteTurnoNoche02a03_07.current.value;
    const	vReporteTurnoNoche02a03_08 = this.cReporteTurnoNoche02a03_08.current.value;
    const	vReporteTurnoNoche02a03_09 = this.cReporteTurnoNoche02a03_09.current.value;
    const	vReporteTurnoNoche02a03_10 = this.cReporteTurnoNoche02a03_10.current.value;
    const	vReporteTurnoNoche02a03_11 = this.cReporteTurnoNoche02a03_11.current.value;
    const	vReporteTurnoNoche02a03_12 = this.cReporteTurnoNoche02a03_12.current.value;
    const	vReporteTurnoNoche02a03_13 = this.cReporteTurnoNoche02a03_13.current.value;

    const	vReporteTurnoNoche03a04_01 = this.cReporteTurnoNoche03a04_01.current.value;
    const	vReporteTurnoNoche03a04_02 = this.cReporteTurnoNoche03a04_02.current.value;
    const	vReporteTurnoNoche03a04_03 = this.cReporteTurnoNoche03a04_03.current.value;
    const	vReporteTurnoNoche03a04_04 = this.cReporteTurnoNoche03a04_04.current.value;
    const	vReporteTurnoNoche03a04_05 = this.cReporteTurnoNoche03a04_05.current.value;
    const	vReporteTurnoNoche03a04_06 = this.cReporteTurnoNoche03a04_06.current.value;
    const	vReporteTurnoNoche03a04_07 = this.cReporteTurnoNoche03a04_07.current.value;
    const	vReporteTurnoNoche03a04_08 = this.cReporteTurnoNoche03a04_08.current.value;
    const	vReporteTurnoNoche03a04_09 = this.cReporteTurnoNoche03a04_09.current.value;
    const	vReporteTurnoNoche03a04_10 = this.cReporteTurnoNoche03a04_10.current.value;
    const	vReporteTurnoNoche03a04_11 = this.cReporteTurnoNoche03a04_11.current.value;
    const	vReporteTurnoNoche03a04_12 = this.cReporteTurnoNoche03a04_12.current.value;
    const	vReporteTurnoNoche03a04_13 = this.cReporteTurnoNoche03a04_13.current.value;

    const	vReporteTurnoNoche04a05_01 = this.cReporteTurnoNoche04a05_01.current.value;
    const	vReporteTurnoNoche04a05_02 = this.cReporteTurnoNoche04a05_02.current.value;
    const	vReporteTurnoNoche04a05_03 = this.cReporteTurnoNoche04a05_03.current.value;
    const	vReporteTurnoNoche04a05_04 = this.cReporteTurnoNoche04a05_04.current.value;
    const	vReporteTurnoNoche04a05_05 = this.cReporteTurnoNoche04a05_05.current.value;
    const	vReporteTurnoNoche04a05_06 = this.cReporteTurnoNoche04a05_06.current.value;
    const	vReporteTurnoNoche04a05_07 = this.cReporteTurnoNoche04a05_07.current.value;
    const	vReporteTurnoNoche04a05_08 = this.cReporteTurnoNoche04a05_08.current.value;
    const	vReporteTurnoNoche04a05_09 = this.cReporteTurnoNoche04a05_09.current.value;
    const	vReporteTurnoNoche04a05_10 = this.cReporteTurnoNoche04a05_10.current.value;
    const	vReporteTurnoNoche04a05_11 = this.cReporteTurnoNoche04a05_11.current.value;
    const	vReporteTurnoNoche04a05_12 = this.cReporteTurnoNoche04a05_12.current.value;
    const	vReporteTurnoNoche04a05_13 = this.cReporteTurnoNoche04a05_13.current.value;

    const	vReporteTurnoNoche05a06_01 = this.cReporteTurnoNoche05a06_01.current.value;
    const	vReporteTurnoNoche05a06_02 = this.cReporteTurnoNoche05a06_02.current.value;
    const	vReporteTurnoNoche05a06_03 = this.cReporteTurnoNoche05a06_03.current.value;
    const	vReporteTurnoNoche05a06_04 = this.cReporteTurnoNoche05a06_04.current.value;
    const	vReporteTurnoNoche05a06_05 = this.cReporteTurnoNoche05a06_05.current.value;
    const vReporteTurnoNoche05a06_06 = this.cReporteTurnoNoche05a06_06.current.value;
    const	vReporteTurnoNoche05a06_07 = this.cReporteTurnoNoche05a06_07.current.value;
    const	vReporteTurnoNoche05a06_08 = this.cReporteTurnoNoche05a06_08.current.value;
    const	vReporteTurnoNoche05a06_09 = this.cReporteTurnoNoche05a06_09.current.value;
    const	vReporteTurnoNoche05a06_10 = this.cReporteTurnoNoche05a06_10.current.value;
    const	vReporteTurnoNoche05a06_11 = this.cReporteTurnoNoche05a06_11.current.value;
    const	vReporteTurnoNoche05a06_12 = this.cReporteTurnoNoche05a06_12.current.value;
    const	vReporteTurnoNoche05a06_13 = this.cReporteTurnoNoche05a06_13.current.value;

    const	vReporteTurnoNoche06a07_01 = this.cReporteTurnoNoche06a07_01.current.value;
    const	vReporteTurnoNoche06a07_02 = this.cReporteTurnoNoche06a07_02.current.value;
    const	vReporteTurnoNoche06a07_03 = this.cReporteTurnoNoche06a07_03.current.value;
    const	vReporteTurnoNoche06a07_04 = this.cReporteTurnoNoche06a07_04.current.value;
    const	vReporteTurnoNoche06a07_05 = this.cReporteTurnoNoche06a07_05.current.value;
    const	vReporteTurnoNoche06a07_06 = this.cReporteTurnoNoche06a07_06.current.value;
    const	vReporteTurnoNoche06a07_07 = this.cReporteTurnoNoche06a07_07.current.value;
    const	vReporteTurnoNoche06a07_08 = this.cReporteTurnoNoche06a07_08.current.value;
    const	vReporteTurnoNoche06a07_09 = this.cReporteTurnoNoche06a07_09.current.value;
    const	vReporteTurnoNoche06a07_10 = this.cReporteTurnoNoche06a07_10.current.value;
    const	vReporteTurnoNoche06a07_11 = this.cReporteTurnoNoche06a07_11.current.value;
    const	vReporteTurnoNoche06a07_12 = this.cReporteTurnoNoche06a07_12.current.value;
    const	vReporteTurnoNoche06a07_13 = this.cReporteTurnoNoche06a07_13.current.value;

    const	vReporteTurnoNoche07a08_01 = this.cReporteTurnoNoche07a08_01.current.value;
    const	vReporteTurnoNoche07a08_02 = this.cReporteTurnoNoche07a08_02.current.value;
    const	vReporteTurnoNoche07a08_03 = this.cReporteTurnoNoche07a08_03.current.value;
    const	vReporteTurnoNoche07a08_04 = this.cReporteTurnoNoche07a08_04.current.value;
    const	vReporteTurnoNoche07a08_05 = this.cReporteTurnoNoche07a08_05.current.value;
    const	vReporteTurnoNoche07a08_06 = this.cReporteTurnoNoche07a08_06.current.value;
    const	vReporteTurnoNoche07a08_07 = this.cReporteTurnoNoche07a08_07.current.value;
    const	vReporteTurnoNoche07a08_08 = this.cReporteTurnoNoche07a08_08.current.value;
    const	vReporteTurnoNoche07a08_09 = this.cReporteTurnoNoche07a08_09.current.value;
    const	vReporteTurnoNoche07a08_10 = this.cReporteTurnoNoche07a08_10.current.value;
    const	vReporteTurnoNoche07a08_11 = this.cReporteTurnoNoche07a08_11.current.value;
    const	vReporteTurnoNoche07a08_12 = this.cReporteTurnoNoche07a08_12.current.value;
    const	vReporteTurnoNoche07a08_13 = this.cReporteTurnoNoche07a08_13.current.value;

    const	vReporteTurnoNoche20a21_01_2 = this.cReporteTurnoNoche20a21_01_2.current.value;
    const	vReporteTurnoNoche20a21_02_2 = this.cReporteTurnoNoche20a21_02_2.current.value;
    const	vReporteTurnoNoche20a21_03_2 = this.cReporteTurnoNoche20a21_03_2.current.value;
    const	vReporteTurnoNoche20a21_04_2 = this.cReporteTurnoNoche20a21_04_2.current.value;
    const	vReporteTurnoNoche20a21_05_2 = this.cReporteTurnoNoche20a21_05_2.current.value;
    const	vReporteTurnoNoche20a21_06_2 = this.cReporteTurnoNoche20a21_06_2.current.value;
    const	vReporteTurnoNoche20a21_07_2 = this.cReporteTurnoNoche20a21_07_2.current.value;
    const	vReporteTurnoNoche20a21_08_2 = this.cReporteTurnoNoche20a21_08_2.current.value;
    const	vReporteTurnoNoche20a21_09_2 = this.cReporteTurnoNoche20a21_09_2.current.value;
    const	vReporteTurnoNoche20a21_10_2 = this.cReporteTurnoNoche20a21_10_2.current.value;
    const	vReporteTurnoNoche20a21_11_2 = this.cReporteTurnoNoche20a21_11_2.current.value;
    const	vReporteTurnoNoche20a21_12_2 = this.cReporteTurnoNoche20a21_12_2.current.value;
    const	vReporteTurnoNoche20a21_13_2 = this.cReporteTurnoNoche20a21_13_2.current.value;

    const	vReporteTurnoNoche21a22_01_2 = this.cReporteTurnoNoche21a22_01_2.current.value;
    const	vReporteTurnoNoche21a22_02_2 = this.cReporteTurnoNoche21a22_02_2.current.value;
    const	vReporteTurnoNoche21a22_03_2 = this.cReporteTurnoNoche21a22_03_2.current.value;
    const	vReporteTurnoNoche21a22_04_2 = this.cReporteTurnoNoche21a22_04_2.current.value;
    const	vReporteTurnoNoche21a22_05_2 = this.cReporteTurnoNoche21a22_05_2.current.value;
    const	vReporteTurnoNoche21a22_06_2 = this.cReporteTurnoNoche21a22_06_2.current.value;
    const	vReporteTurnoNoche21a22_07_2 = this.cReporteTurnoNoche21a22_07_2.current.value;
    const	vReporteTurnoNoche21a22_08_2 = this.cReporteTurnoNoche21a22_08_2.current.value;
    const	vReporteTurnoNoche21a22_09_2 = this.cReporteTurnoNoche21a22_09_2.current.value;
    const	vReporteTurnoNoche21a22_10_2 = this.cReporteTurnoNoche21a22_10_2.current.value;
    const	vReporteTurnoNoche21a22_11_2 = this.cReporteTurnoNoche21a22_11_2.current.value;
    const	vReporteTurnoNoche21a22_12_2 = this.cReporteTurnoNoche21a22_12_2.current.value;
    const	vReporteTurnoNoche21a22_13_2 = this.cReporteTurnoNoche21a22_13_2.current.value;

    const	vReporteTurnoNoche22a23_01_2 = this.cReporteTurnoNoche22a23_01_2.current.value;
    const	vReporteTurnoNoche22a23_02_2 = this.cReporteTurnoNoche22a23_02_2.current.value;
    const	vReporteTurnoNoche22a23_03_2 = this.cReporteTurnoNoche22a23_03_2.current.value;
    const	vReporteTurnoNoche22a23_04_2 = this.cReporteTurnoNoche22a23_04_2.current.value;
    const	vReporteTurnoNoche22a23_05_2 = this.cReporteTurnoNoche22a23_05_2.current.value;
    const	vReporteTurnoNoche22a23_06_2 = this.cReporteTurnoNoche22a23_06_2.current.value;
    const	vReporteTurnoNoche22a23_07_2 = this.cReporteTurnoNoche22a23_07_2.current.value;
    const	vReporteTurnoNoche22a23_08_2 = this.cReporteTurnoNoche22a23_08_2.current.value;
    const	vReporteTurnoNoche22a23_09_2 = this.cReporteTurnoNoche22a23_09_2.current.value;
    const	vReporteTurnoNoche22a23_10_2 = this.cReporteTurnoNoche22a23_10_2.current.value;
    const	vReporteTurnoNoche22a23_11_2 = this.cReporteTurnoNoche22a23_11_2.current.value;
    const	vReporteTurnoNoche22a23_12_2 = this.cReporteTurnoNoche22a23_12_2.current.value;
    const	vReporteTurnoNoche22a23_13_2 = this.cReporteTurnoNoche22a23_13_2.current.value;

    const	vReporteTurnoNoche23a24_01_2 = this.cReporteTurnoNoche23a24_01_2.current.value;
    const	vReporteTurnoNoche23a24_02_2 = this.cReporteTurnoNoche23a24_02_2.current.value;
    const	vReporteTurnoNoche23a24_03_2 = this.cReporteTurnoNoche23a24_03_2.current.value;
    const	vReporteTurnoNoche23a24_04_2 = this.cReporteTurnoNoche23a24_04_2.current.value;
    const	vReporteTurnoNoche23a24_05_2 = this.cReporteTurnoNoche23a24_05_2.current.value;
    const	vReporteTurnoNoche23a24_06_2 = this.cReporteTurnoNoche23a24_06_2.current.value;
    const	vReporteTurnoNoche23a24_07_2 = this.cReporteTurnoNoche23a24_07_2.current.value;
    const	vReporteTurnoNoche23a24_08_2 = this.cReporteTurnoNoche23a24_08_2.current.value;
    const	vReporteTurnoNoche23a24_09_2 = this.cReporteTurnoNoche23a24_09_2.current.value;
    const	vReporteTurnoNoche23a24_10_2 = this.cReporteTurnoNoche23a24_10_2.current.value;
    const	vReporteTurnoNoche23a24_11_2 = this.cReporteTurnoNoche23a24_11_2.current.value;
    const	vReporteTurnoNoche23a24_12_2 = this.cReporteTurnoNoche23a24_12_2.current.value;
    const	vReporteTurnoNoche23a24_13_2 = this.cReporteTurnoNoche23a24_13_2.current.value;

    const	vReporteTurnoNoche24a01_01_2 = this.cReporteTurnoNoche24a01_01_2.current.value;
    const	vReporteTurnoNoche24a01_02_2 = this.cReporteTurnoNoche24a01_02_2.current.value;
    const	vReporteTurnoNoche24a01_03_2 = this.cReporteTurnoNoche24a01_03_2.current.value;
    const	vReporteTurnoNoche24a01_04_2 = this.cReporteTurnoNoche24a01_04_2.current.value;
    const	vReporteTurnoNoche24a01_05_2 = this.cReporteTurnoNoche24a01_05_2.current.value;
    const	vReporteTurnoNoche24a01_06_2 = this.cReporteTurnoNoche24a01_06_2.current.value;
    const	vReporteTurnoNoche24a01_07_2 = this.cReporteTurnoNoche24a01_07_2.current.value;
    const	vReporteTurnoNoche24a01_08_2 = this.cReporteTurnoNoche24a01_08_2.current.value;
    const	vReporteTurnoNoche24a01_09_2 = this.cReporteTurnoNoche24a01_09_2.current.value;
    const	vReporteTurnoNoche24a01_10_2 = this.cReporteTurnoNoche24a01_10_2.current.value;
    const	vReporteTurnoNoche24a01_11_2 = this.cReporteTurnoNoche24a01_11_2.current.value;
    const	vReporteTurnoNoche24a01_12_2 = this.cReporteTurnoNoche24a01_12_2.current.value;
    const	vReporteTurnoNoche24a01_13_2 = this.cReporteTurnoNoche24a01_13_2.current.value;

    const	vReporteTurnoNoche01a02_01_2 = this.cReporteTurnoNoche01a02_01_2.current.value;
    const	vReporteTurnoNoche01a02_02_2 = this.cReporteTurnoNoche01a02_02_2.current.value;
    const	vReporteTurnoNoche01a02_03_2 = this.cReporteTurnoNoche01a02_03_2.current.value;
    const	vReporteTurnoNoche01a02_04_2 = this.cReporteTurnoNoche01a02_04_2.current.value;
    const	vReporteTurnoNoche01a02_05_2 = this.cReporteTurnoNoche01a02_05_2.current.value;
    const	vReporteTurnoNoche01a02_06_2 = this.cReporteTurnoNoche01a02_06_2.current.value;
    const	vReporteTurnoNoche01a02_07_2 = this.cReporteTurnoNoche01a02_07_2.current.value;
    const	vReporteTurnoNoche01a02_08_2 = this.cReporteTurnoNoche01a02_08_2.current.value;
    const	vReporteTurnoNoche01a02_09_2 = this.cReporteTurnoNoche01a02_09_2.current.value;
    const	vReporteTurnoNoche01a02_10_2 = this.cReporteTurnoNoche01a02_10_2.current.value;
    const	vReporteTurnoNoche01a02_11_2 = this.cReporteTurnoNoche01a02_11_2.current.value;
    const	vReporteTurnoNoche01a02_12_2 = this.cReporteTurnoNoche01a02_12_2.current.value;
    const	vReporteTurnoNoche01a02_13_2 = this.cReporteTurnoNoche01a02_13_2.current.value;

    const	vReporteTurnoNoche02a03_01_2 = this.cReporteTurnoNoche02a03_01_2.current.value;
    const	vReporteTurnoNoche02a03_02_2 = this.cReporteTurnoNoche02a03_02_2.current.value;
    const	vReporteTurnoNoche02a03_03_2 = this.cReporteTurnoNoche02a03_03_2.current.value;
    const	vReporteTurnoNoche02a03_04_2 = this.cReporteTurnoNoche02a03_04_2.current.value;
    const	vReporteTurnoNoche02a03_05_2 = this.cReporteTurnoNoche02a03_05_2.current.value;
    const	vReporteTurnoNoche02a03_06_2 = this.cReporteTurnoNoche02a03_06_2.current.value;
    const	vReporteTurnoNoche02a03_07_2 = this.cReporteTurnoNoche02a03_07_2.current.value;
    const	vReporteTurnoNoche02a03_08_2 = this.cReporteTurnoNoche02a03_08_2.current.value;
    const	vReporteTurnoNoche02a03_09_2 = this.cReporteTurnoNoche02a03_09_2.current.value;
    const	vReporteTurnoNoche02a03_10_2 = this.cReporteTurnoNoche02a03_10_2.current.value;
    const	vReporteTurnoNoche02a03_11_2 = this.cReporteTurnoNoche02a03_11_2.current.value;
    const	vReporteTurnoNoche02a03_12_2 = this.cReporteTurnoNoche02a03_12_2.current.value;
    const	vReporteTurnoNoche02a03_13_2 = this.cReporteTurnoNoche02a03_13_2.current.value;

    const	vReporteTurnoNoche03a04_01_2 = this.cReporteTurnoNoche03a04_01_2.current.value;
    const	vReporteTurnoNoche03a04_02_2 = this.cReporteTurnoNoche03a04_02_2.current.value;
    const	vReporteTurnoNoche03a04_03_2 = this.cReporteTurnoNoche03a04_03_2.current.value;
    const	vReporteTurnoNoche03a04_04_2 = this.cReporteTurnoNoche03a04_04_2.current.value;
    const	vReporteTurnoNoche03a04_05_2 = this.cReporteTurnoNoche03a04_05_2.current.value;
    const	vReporteTurnoNoche03a04_06_2 = this.cReporteTurnoNoche03a04_06_2.current.value;
    const	vReporteTurnoNoche03a04_07_2 = this.cReporteTurnoNoche03a04_07_2.current.value;
    const	vReporteTurnoNoche03a04_08_2 = this.cReporteTurnoNoche03a04_08_2.current.value;
    const	vReporteTurnoNoche03a04_09_2 = this.cReporteTurnoNoche03a04_09_2.current.value;
    const	vReporteTurnoNoche03a04_10_2 = this.cReporteTurnoNoche03a04_10_2.current.value;
    const	vReporteTurnoNoche03a04_11_2 = this.cReporteTurnoNoche03a04_11_2.current.value;
    const	vReporteTurnoNoche03a04_12_2 = this.cReporteTurnoNoche03a04_12_2.current.value;
    const	vReporteTurnoNoche03a04_13_2 = this.cReporteTurnoNoche03a04_13_2.current.value;

    const	vReporteTurnoNoche04a05_01_2 = this.cReporteTurnoNoche04a05_01_2.current.value;
    const	vReporteTurnoNoche04a05_02_2 = this.cReporteTurnoNoche04a05_02_2.current.value;
    const	vReporteTurnoNoche04a05_03_2 = this.cReporteTurnoNoche04a05_03_2.current.value;
    const	vReporteTurnoNoche04a05_04_2 = this.cReporteTurnoNoche04a05_04_2.current.value;
    const	vReporteTurnoNoche04a05_05_2 = this.cReporteTurnoNoche04a05_05_2.current.value;
    const	vReporteTurnoNoche04a05_06_2 = this.cReporteTurnoNoche04a05_06_2.current.value;
    const	vReporteTurnoNoche04a05_07_2 = this.cReporteTurnoNoche04a05_07_2.current.value;
    const	vReporteTurnoNoche04a05_08_2 = this.cReporteTurnoNoche04a05_08_2.current.value;
    const	vReporteTurnoNoche04a05_09_2 = this.cReporteTurnoNoche04a05_09_2.current.value;
    const	vReporteTurnoNoche04a05_10_2 = this.cReporteTurnoNoche04a05_10_2.current.value;
    const	vReporteTurnoNoche04a05_11_2 = this.cReporteTurnoNoche04a05_11_2.current.value;
    const	vReporteTurnoNoche04a05_12_2 = this.cReporteTurnoNoche04a05_12_2.current.value;
    const	vReporteTurnoNoche04a05_13_2 = this.cReporteTurnoNoche04a05_13_2.current.value;

    const	vReporteTurnoNoche05a06_01_2 = this.cReporteTurnoNoche05a06_01_2.current.value;
    const	vReporteTurnoNoche05a06_02_2 = this.cReporteTurnoNoche05a06_02_2.current.value;
    const	vReporteTurnoNoche05a06_03_2 = this.cReporteTurnoNoche05a06_03_2.current.value;
    const	vReporteTurnoNoche05a06_04_2 = this.cReporteTurnoNoche05a06_04_2.current.value;
    const	vReporteTurnoNoche05a06_05_2 = this.cReporteTurnoNoche05a06_05_2.current.value;
    const vReporteTurnoNoche05a06_06_2 = this.cReporteTurnoNoche05a06_06_2.current.value;
    const	vReporteTurnoNoche05a06_07_2 = this.cReporteTurnoNoche05a06_07_2.current.value;
    const	vReporteTurnoNoche05a06_08_2 = this.cReporteTurnoNoche05a06_08_2.current.value;
    const	vReporteTurnoNoche05a06_09_2 = this.cReporteTurnoNoche05a06_09_2.current.value;
    const	vReporteTurnoNoche05a06_10_2 = this.cReporteTurnoNoche05a06_10_2.current.value;
    const	vReporteTurnoNoche05a06_11_2 = this.cReporteTurnoNoche05a06_11_2.current.value;
    const	vReporteTurnoNoche05a06_12_2 = this.cReporteTurnoNoche05a06_12_2.current.value;
    const	vReporteTurnoNoche05a06_13_2 = this.cReporteTurnoNoche05a06_13_2.current.value;

    const	vReporteTurnoNoche06a07_01_2 = this.cReporteTurnoNoche06a07_01_2.current.value;
    const	vReporteTurnoNoche06a07_02_2 = this.cReporteTurnoNoche06a07_02_2.current.value;
    const	vReporteTurnoNoche06a07_03_2 = this.cReporteTurnoNoche06a07_03_2.current.value;
    const	vReporteTurnoNoche06a07_04_2 = this.cReporteTurnoNoche06a07_04_2.current.value;
    const	vReporteTurnoNoche06a07_05_2 = this.cReporteTurnoNoche06a07_05_2.current.value;
    const	vReporteTurnoNoche06a07_06_2 = this.cReporteTurnoNoche06a07_06_2.current.value;
    const	vReporteTurnoNoche06a07_07_2 = this.cReporteTurnoNoche06a07_07_2.current.value;
    const	vReporteTurnoNoche06a07_08_2 = this.cReporteTurnoNoche06a07_08_2.current.value;
    const	vReporteTurnoNoche06a07_09_2 = this.cReporteTurnoNoche06a07_09_2.current.value;
    const	vReporteTurnoNoche06a07_10_2 = this.cReporteTurnoNoche06a07_10_2.current.value;
    const	vReporteTurnoNoche06a07_11_2 = this.cReporteTurnoNoche06a07_11_2.current.value;
    const	vReporteTurnoNoche06a07_12_2 = this.cReporteTurnoNoche06a07_12_2.current.value;
    const	vReporteTurnoNoche06a07_13_2 = this.cReporteTurnoNoche06a07_13_2.current.value;

    const	vReporteTurnoNoche07a08_01_2 = this.cReporteTurnoNoche07a08_01_2.current.value;
    const	vReporteTurnoNoche07a08_02_2 = this.cReporteTurnoNoche07a08_02_2.current.value;
    const	vReporteTurnoNoche07a08_03_2 = this.cReporteTurnoNoche07a08_03_2.current.value;
    const	vReporteTurnoNoche07a08_04_2 = this.cReporteTurnoNoche07a08_04_2.current.value;
    const	vReporteTurnoNoche07a08_05_2 = this.cReporteTurnoNoche07a08_05_2.current.value;
    const	vReporteTurnoNoche07a08_06_2 = this.cReporteTurnoNoche07a08_06_2.current.value;
    const	vReporteTurnoNoche07a08_07_2 = this.cReporteTurnoNoche07a08_07_2.current.value;
    const	vReporteTurnoNoche07a08_08_2 = this.cReporteTurnoNoche07a08_08_2.current.value;
    const	vReporteTurnoNoche07a08_09_2 = this.cReporteTurnoNoche07a08_09_2.current.value;
    const	vReporteTurnoNoche07a08_10_2 = this.cReporteTurnoNoche07a08_10_2.current.value;
    const	vReporteTurnoNoche07a08_11_2 = this.cReporteTurnoNoche07a08_11_2.current.value;
    const	vReporteTurnoNoche07a08_12_2 = this.cReporteTurnoNoche07a08_12_2.current.value;
    const	vReporteTurnoNoche07a08_13_2 = this.cReporteTurnoNoche07a08_13_2.current.value;

    const jsonAttachFile =`{
"vReporteTurnoNoche01":"${vReporteTurnoNoche01}",
"vReporteTurnoNoche02":"${vReporteTurnoNoche02}",
"vReporteTurnoNoche03":"${vReporteTurnoNoche03}",
"vReporteTurnoNoche04":"${vReporteTurnoNoche04}",
"vReporteTurnoNoche05":"${vReporteTurnoNoche05}",
"vReporteTurnoNoche06":"${vReporteTurnoNoche06}",
"vReporteTurnoNoche07":"${vReporteTurnoNoche07}",
"vReporteTurnoNoche08":"${vReporteTurnoNoche08}",
"vReporteTurnoNoche09":"${vReporteTurnoNoche09}",
"vReporteTurnoNoche10":"${vReporteTurnoNoche10}",
"vReporteTurnoNoche11":"${vReporteTurnoNoche11}",
"vReporteTurnoNoche12":"${vReporteTurnoNoche12}",
"vReporteTurnoNoche13":"${vReporteTurnoNoche13}",
"vReporteTurnoNoche20a21_01":"${vReporteTurnoNoche20a21_01}",
"vReporteTurnoNoche20a21_02":"${vReporteTurnoNoche20a21_02}",
"vReporteTurnoNoche20a21_03":"${vReporteTurnoNoche20a21_03}",
"vReporteTurnoNoche20a21_04":"${vReporteTurnoNoche20a21_04}",
"vReporteTurnoNoche20a21_05":"${vReporteTurnoNoche20a21_05}",
"vReporteTurnoNoche20a21_06":"${vReporteTurnoNoche20a21_06}",
"vReporteTurnoNoche20a21_07":"${vReporteTurnoNoche20a21_07}",
"vReporteTurnoNoche20a21_08":"${vReporteTurnoNoche20a21_08}",
"vReporteTurnoNoche20a21_09":"${vReporteTurnoNoche20a21_09}",
"vReporteTurnoNoche20a21_10":"${vReporteTurnoNoche20a21_10}",
"vReporteTurnoNoche20a21_11":"${vReporteTurnoNoche20a21_11}",
"vReporteTurnoNoche20a21_12":"${vReporteTurnoNoche20a21_12}",
"vReporteTurnoNoche20a21_13":"${vReporteTurnoNoche20a21_13}",
"vReporteTurnoNoche21a22_01":"${vReporteTurnoNoche21a22_01}",
"vReporteTurnoNoche21a22_02":"${vReporteTurnoNoche21a22_02}",
"vReporteTurnoNoche21a22_03":"${vReporteTurnoNoche21a22_03}",
"vReporteTurnoNoche21a22_04":"${vReporteTurnoNoche21a22_04}",
"vReporteTurnoNoche21a22_05":"${vReporteTurnoNoche21a22_05}",
"vReporteTurnoNoche21a22_06":"${vReporteTurnoNoche21a22_06}",
"vReporteTurnoNoche21a22_07":"${vReporteTurnoNoche21a22_07}",
"vReporteTurnoNoche21a22_08":"${vReporteTurnoNoche21a22_08}",
"vReporteTurnoNoche21a22_09":"${vReporteTurnoNoche21a22_09}",
"vReporteTurnoNoche21a22_10":"${vReporteTurnoNoche21a22_10}",
"vReporteTurnoNoche21a22_11":"${vReporteTurnoNoche21a22_11}",
"vReporteTurnoNoche21a22_12":"${vReporteTurnoNoche21a22_12}",
"vReporteTurnoNoche21a22_13":"${vReporteTurnoNoche21a22_13}",
"vReporteTurnoNoche22a23_01":"${vReporteTurnoNoche22a23_01}",
"vReporteTurnoNoche22a23_02":"${vReporteTurnoNoche22a23_02}",
"vReporteTurnoNoche22a23_03":"${vReporteTurnoNoche22a23_03}",
"vReporteTurnoNoche22a23_04":"${vReporteTurnoNoche22a23_04}",
"vReporteTurnoNoche22a23_05":"${vReporteTurnoNoche22a23_05}",
"vReporteTurnoNoche22a23_06":"${vReporteTurnoNoche22a23_06}",
"vReporteTurnoNoche22a23_07":"${vReporteTurnoNoche22a23_07}",
"vReporteTurnoNoche22a23_08":"${vReporteTurnoNoche22a23_08}",
"vReporteTurnoNoche22a23_09":"${vReporteTurnoNoche22a23_09}",
"vReporteTurnoNoche22a23_10":"${vReporteTurnoNoche22a23_10}",
"vReporteTurnoNoche22a23_11":"${vReporteTurnoNoche22a23_11}",
"vReporteTurnoNoche22a23_12":"${vReporteTurnoNoche22a23_12}",
"vReporteTurnoNoche22a23_13":"${vReporteTurnoNoche22a23_13}",
"vReporteTurnoNoche23a24_01":"${vReporteTurnoNoche23a24_01}",
"vReporteTurnoNoche23a24_02":"${vReporteTurnoNoche23a24_02}",
"vReporteTurnoNoche23a24_03":"${vReporteTurnoNoche23a24_03}",
"vReporteTurnoNoche23a24_04":"${vReporteTurnoNoche23a24_04}",
"vReporteTurnoNoche23a24_05":"${vReporteTurnoNoche23a24_05}",
"vReporteTurnoNoche23a24_06":"${vReporteTurnoNoche23a24_06}",
"vReporteTurnoNoche23a24_07":"${vReporteTurnoNoche23a24_07}",
"vReporteTurnoNoche23a24_08":"${vReporteTurnoNoche23a24_08}",
"vReporteTurnoNoche23a24_09":"${vReporteTurnoNoche23a24_09}",
"vReporteTurnoNoche23a24_10":"${vReporteTurnoNoche23a24_10}",
"vReporteTurnoNoche23a24_11":"${vReporteTurnoNoche23a24_11}",
"vReporteTurnoNoche23a24_12":"${vReporteTurnoNoche23a24_12}",
"vReporteTurnoNoche23a24_13":"${vReporteTurnoNoche23a24_13}",
"vReporteTurnoNoche24a01_01":"${vReporteTurnoNoche24a01_01}",
"vReporteTurnoNoche24a01_02":"${vReporteTurnoNoche24a01_02}",
"vReporteTurnoNoche24a01_03":"${vReporteTurnoNoche24a01_03}",
"vReporteTurnoNoche24a01_04":"${vReporteTurnoNoche24a01_04}",
"vReporteTurnoNoche24a01_05":"${vReporteTurnoNoche24a01_05}",
"vReporteTurnoNoche24a01_06":"${vReporteTurnoNoche24a01_06}",
"vReporteTurnoNoche24a01_07":"${vReporteTurnoNoche24a01_07}",
"vReporteTurnoNoche24a01_08":"${vReporteTurnoNoche24a01_08}",
"vReporteTurnoNoche24a01_09":"${vReporteTurnoNoche24a01_09}",
"vReporteTurnoNoche24a01_10":"${vReporteTurnoNoche24a01_10}",
"vReporteTurnoNoche24a01_11":"${vReporteTurnoNoche24a01_11}",
"vReporteTurnoNoche24a01_12":"${vReporteTurnoNoche24a01_12}",
"vReporteTurnoNoche24a01_13":"${vReporteTurnoNoche24a01_13}",
"vReporteTurnoNoche01a02_01":"${vReporteTurnoNoche01a02_01}",
"vReporteTurnoNoche01a02_02":"${vReporteTurnoNoche01a02_02}",
"vReporteTurnoNoche01a02_03":"${vReporteTurnoNoche01a02_03}",
"vReporteTurnoNoche01a02_04":"${vReporteTurnoNoche01a02_04}",
"vReporteTurnoNoche01a02_05":"${vReporteTurnoNoche01a02_05}",
"vReporteTurnoNoche01a02_06":"${vReporteTurnoNoche01a02_06}",
"vReporteTurnoNoche01a02_07":"${vReporteTurnoNoche01a02_07}",
"vReporteTurnoNoche01a02_08":"${vReporteTurnoNoche01a02_08}",
"vReporteTurnoNoche01a02_09":"${vReporteTurnoNoche01a02_09}",
"vReporteTurnoNoche01a02_10":"${vReporteTurnoNoche01a02_10}",
"vReporteTurnoNoche01a02_11":"${vReporteTurnoNoche01a02_11}",
"vReporteTurnoNoche01a02_12":"${vReporteTurnoNoche01a02_12}",
"vReporteTurnoNoche01a02_13":"${vReporteTurnoNoche01a02_13}",
"vReporteTurnoNoche02a03_01":"${vReporteTurnoNoche02a03_01}",
"vReporteTurnoNoche02a03_02":"${vReporteTurnoNoche02a03_02}",
"vReporteTurnoNoche02a03_03":"${vReporteTurnoNoche02a03_03}",
"vReporteTurnoNoche02a03_04":"${vReporteTurnoNoche02a03_04}",
"vReporteTurnoNoche02a03_05":"${vReporteTurnoNoche02a03_05}",
"vReporteTurnoNoche02a03_06":"${vReporteTurnoNoche02a03_06}",
"vReporteTurnoNoche02a03_07":"${vReporteTurnoNoche02a03_07}",
"vReporteTurnoNoche02a03_08":"${vReporteTurnoNoche02a03_08}",
"vReporteTurnoNoche02a03_09":"${vReporteTurnoNoche02a03_09}",
"vReporteTurnoNoche02a03_10":"${vReporteTurnoNoche02a03_10}",
"vReporteTurnoNoche02a03_11":"${vReporteTurnoNoche02a03_11}",
"vReporteTurnoNoche02a03_12":"${vReporteTurnoNoche02a03_12}",
"vReporteTurnoNoche02a03_13":"${vReporteTurnoNoche02a03_13}",
"vReporteTurnoNoche03a04_01":"${vReporteTurnoNoche03a04_01}",
"vReporteTurnoNoche03a04_02":"${vReporteTurnoNoche03a04_02}",
"vReporteTurnoNoche03a04_03":"${vReporteTurnoNoche03a04_03}",
"vReporteTurnoNoche03a04_04":"${vReporteTurnoNoche03a04_04}",
"vReporteTurnoNoche03a04_05":"${vReporteTurnoNoche03a04_05}",
"vReporteTurnoNoche03a04_06":"${vReporteTurnoNoche03a04_06}",
"vReporteTurnoNoche03a04_07":"${vReporteTurnoNoche03a04_07}",
"vReporteTurnoNoche03a04_08":"${vReporteTurnoNoche03a04_08}",
"vReporteTurnoNoche03a04_09":"${vReporteTurnoNoche03a04_09}",
"vReporteTurnoNoche03a04_10":"${vReporteTurnoNoche03a04_10}",
"vReporteTurnoNoche03a04_11":"${vReporteTurnoNoche03a04_11}",
"vReporteTurnoNoche03a04_12":"${vReporteTurnoNoche03a04_12}",
"vReporteTurnoNoche03a04_13":"${vReporteTurnoNoche03a04_13}",
"vReporteTurnoNoche04a05_01":"${vReporteTurnoNoche04a05_01}",
"vReporteTurnoNoche04a05_02":"${vReporteTurnoNoche04a05_02}",
"vReporteTurnoNoche04a05_03":"${vReporteTurnoNoche04a05_03}",
"vReporteTurnoNoche04a05_04":"${vReporteTurnoNoche04a05_04}",
"vReporteTurnoNoche04a05_05":"${vReporteTurnoNoche04a05_05}",
"vReporteTurnoNoche04a05_06":"${vReporteTurnoNoche04a05_06}",
"vReporteTurnoNoche04a05_07":"${vReporteTurnoNoche04a05_07}",
"vReporteTurnoNoche04a05_08":"${vReporteTurnoNoche04a05_08}",
"vReporteTurnoNoche04a05_09":"${vReporteTurnoNoche04a05_09}",
"vReporteTurnoNoche04a05_10":"${vReporteTurnoNoche04a05_10}",
"vReporteTurnoNoche04a05_11":"${vReporteTurnoNoche04a05_11}",
"vReporteTurnoNoche04a05_12":"${vReporteTurnoNoche04a05_12}",
"vReporteTurnoNoche04a05_13":"${vReporteTurnoNoche04a05_13}",
"vReporteTurnoNoche05a06_01":"${vReporteTurnoNoche05a06_01}",
"vReporteTurnoNoche05a06_02":"${vReporteTurnoNoche05a06_02}",
"vReporteTurnoNoche05a06_03":"${vReporteTurnoNoche05a06_03}",
"vReporteTurnoNoche05a06_04":"${vReporteTurnoNoche05a06_04}",
"vReporteTurnoNoche05a06_05":"${vReporteTurnoNoche05a06_05}",
"vReporteTurnoNoche05a06_06":"${vReporteTurnoNoche05a06_06}",
"vReporteTurnoNoche05a06_07":"${vReporteTurnoNoche05a06_07}",
"vReporteTurnoNoche05a06_08":"${vReporteTurnoNoche05a06_08}",
"vReporteTurnoNoche05a06_09":"${vReporteTurnoNoche05a06_09}",
"vReporteTurnoNoche05a06_10":"${vReporteTurnoNoche05a06_10}",
"vReporteTurnoNoche05a06_11":"${vReporteTurnoNoche05a06_11}",
"vReporteTurnoNoche05a06_12":"${vReporteTurnoNoche05a06_12}",
"vReporteTurnoNoche05a06_13":"${vReporteTurnoNoche05a06_13}",
"vReporteTurnoNoche06a07_01":"${vReporteTurnoNoche06a07_01}",
"vReporteTurnoNoche06a07_02":"${vReporteTurnoNoche06a07_02}",
"vReporteTurnoNoche06a07_03":"${vReporteTurnoNoche06a07_03}",
"vReporteTurnoNoche06a07_04":"${vReporteTurnoNoche06a07_04}",
"vReporteTurnoNoche06a07_05":"${vReporteTurnoNoche06a07_05}",
"vReporteTurnoNoche06a07_06":"${vReporteTurnoNoche06a07_06}",
"vReporteTurnoNoche06a07_07":"${vReporteTurnoNoche06a07_07}",
"vReporteTurnoNoche06a07_08":"${vReporteTurnoNoche06a07_08}",
"vReporteTurnoNoche06a07_09":"${vReporteTurnoNoche06a07_09}",
"vReporteTurnoNoche06a07_10":"${vReporteTurnoNoche06a07_10}",
"vReporteTurnoNoche06a07_11":"${vReporteTurnoNoche06a07_11}",
"vReporteTurnoNoche06a07_12":"${vReporteTurnoNoche06a07_12}",
"vReporteTurnoNoche06a07_13":"${vReporteTurnoNoche06a07_13}",
"vReporteTurnoNoche07a08_01":"${vReporteTurnoNoche07a08_01}",
"vReporteTurnoNoche07a08_02":"${vReporteTurnoNoche07a08_02}",
"vReporteTurnoNoche07a08_03":"${vReporteTurnoNoche07a08_03}",
"vReporteTurnoNoche07a08_04":"${vReporteTurnoNoche07a08_04}",
"vReporteTurnoNoche07a08_05":"${vReporteTurnoNoche07a08_05}",
"vReporteTurnoNoche07a08_06":"${vReporteTurnoNoche07a08_06}",
"vReporteTurnoNoche07a08_07":"${vReporteTurnoNoche07a08_07}",
"vReporteTurnoNoche07a08_08":"${vReporteTurnoNoche07a08_08}",
"vReporteTurnoNoche07a08_09":"${vReporteTurnoNoche07a08_09}",
"vReporteTurnoNoche07a08_10":"${vReporteTurnoNoche07a08_10}",
"vReporteTurnoNoche07a08_11":"${vReporteTurnoNoche07a08_11}",
"vReporteTurnoNoche07a08_12":"${vReporteTurnoNoche07a08_12}",
"vReporteTurnoNoche07a08_13":"${vReporteTurnoNoche07a08_13}",
"vReporteTurnoNoche20a21_01_2":"${vReporteTurnoNoche20a21_01_2}",
"vReporteTurnoNoche20a21_02_2":"${vReporteTurnoNoche20a21_02_2}",
"vReporteTurnoNoche20a21_03_2":"${vReporteTurnoNoche20a21_03_2}",
"vReporteTurnoNoche20a21_04_2":"${vReporteTurnoNoche20a21_04_2}",
"vReporteTurnoNoche20a21_05_2":"${vReporteTurnoNoche20a21_05_2}",
"vReporteTurnoNoche20a21_06_2":"${vReporteTurnoNoche20a21_06_2}",
"vReporteTurnoNoche20a21_07_2":"${vReporteTurnoNoche20a21_07_2}",
"vReporteTurnoNoche20a21_08_2":"${vReporteTurnoNoche20a21_08_2}",
"vReporteTurnoNoche20a21_09_2":"${vReporteTurnoNoche20a21_09_2}",
"vReporteTurnoNoche20a21_10_2":"${vReporteTurnoNoche20a21_10_2}",
"vReporteTurnoNoche20a21_11_2":"${vReporteTurnoNoche20a21_11_2}",
"vReporteTurnoNoche20a21_12_2":"${vReporteTurnoNoche20a21_12_2}",
"vReporteTurnoNoche20a21_13_2":"${vReporteTurnoNoche20a21_13_2}",
"vReporteTurnoNoche21a22_01_2":"${vReporteTurnoNoche21a22_01_2}",
"vReporteTurnoNoche21a22_02_2":"${vReporteTurnoNoche21a22_02_2}",
"vReporteTurnoNoche21a22_03_2":"${vReporteTurnoNoche21a22_03_2}",
"vReporteTurnoNoche21a22_04_2":"${vReporteTurnoNoche21a22_04_2}",
"vReporteTurnoNoche21a22_05_2":"${vReporteTurnoNoche21a22_05_2}",
"vReporteTurnoNoche21a22_06_2":"${vReporteTurnoNoche21a22_06_2}",
"vReporteTurnoNoche21a22_07_2":"${vReporteTurnoNoche21a22_07_2}",
"vReporteTurnoNoche21a22_08_2":"${vReporteTurnoNoche21a22_08_2}",
"vReporteTurnoNoche21a22_09_2":"${vReporteTurnoNoche21a22_09_2}",
"vReporteTurnoNoche21a22_10_2":"${vReporteTurnoNoche21a22_10_2}",
"vReporteTurnoNoche21a22_11_2":"${vReporteTurnoNoche21a22_11_2}",
"vReporteTurnoNoche21a22_12_2":"${vReporteTurnoNoche21a22_12_2}",
"vReporteTurnoNoche21a22_13_2":"${vReporteTurnoNoche21a22_13_2}",
"vReporteTurnoNoche22a23_01_2":"${vReporteTurnoNoche22a23_01_2}",
"vReporteTurnoNoche22a23_02_2":"${vReporteTurnoNoche22a23_02_2}",
"vReporteTurnoNoche22a23_03_2":"${vReporteTurnoNoche22a23_03_2}",
"vReporteTurnoNoche22a23_04_2":"${vReporteTurnoNoche22a23_04_2}",
"vReporteTurnoNoche22a23_05_2":"${vReporteTurnoNoche22a23_05_2}",
"vReporteTurnoNoche22a23_06_2":"${vReporteTurnoNoche22a23_06_2}",
"vReporteTurnoNoche22a23_07_2":"${vReporteTurnoNoche22a23_07_2}",
"vReporteTurnoNoche22a23_08_2":"${vReporteTurnoNoche22a23_08_2}",
"vReporteTurnoNoche22a23_09_2":"${vReporteTurnoNoche22a23_09_2}",
"vReporteTurnoNoche22a23_10_2":"${vReporteTurnoNoche22a23_10_2}",
"vReporteTurnoNoche22a23_11_2":"${vReporteTurnoNoche22a23_11_2}",
"vReporteTurnoNoche22a23_12_2":"${vReporteTurnoNoche22a23_12_2}",
"vReporteTurnoNoche22a23_13_2":"${vReporteTurnoNoche22a23_13_2}",
"vReporteTurnoNoche23a24_01_2":"${vReporteTurnoNoche23a24_01_2}",
"vReporteTurnoNoche23a24_02_2":"${vReporteTurnoNoche23a24_02_2}",
"vReporteTurnoNoche23a24_03_2":"${vReporteTurnoNoche23a24_03_2}",
"vReporteTurnoNoche23a24_04_2":"${vReporteTurnoNoche23a24_04_2}",
"vReporteTurnoNoche23a24_05_2":"${vReporteTurnoNoche23a24_05_2}",
"vReporteTurnoNoche23a24_06_2":"${vReporteTurnoNoche23a24_06_2}",
"vReporteTurnoNoche23a24_07_2":"${vReporteTurnoNoche23a24_07_2}",
"vReporteTurnoNoche23a24_08_2":"${vReporteTurnoNoche23a24_08_2}",
"vReporteTurnoNoche23a24_09_2":"${vReporteTurnoNoche23a24_09_2}",
"vReporteTurnoNoche23a24_10_2":"${vReporteTurnoNoche23a24_10_2}",
"vReporteTurnoNoche23a24_11_2":"${vReporteTurnoNoche23a24_11_2}",
"vReporteTurnoNoche23a24_12_2":"${vReporteTurnoNoche23a24_12_2}",
"vReporteTurnoNoche23a24_13_2":"${vReporteTurnoNoche23a24_13_2}",
"vReporteTurnoNoche24a01_01_2":"${vReporteTurnoNoche24a01_01_2}",
"vReporteTurnoNoche24a01_02_2":"${vReporteTurnoNoche24a01_02_2}",
"vReporteTurnoNoche24a01_03_2":"${vReporteTurnoNoche24a01_03_2}",
"vReporteTurnoNoche24a01_04_2":"${vReporteTurnoNoche24a01_04_2}",
"vReporteTurnoNoche24a01_05_2":"${vReporteTurnoNoche24a01_05_2}",
"vReporteTurnoNoche24a01_06_2":"${vReporteTurnoNoche24a01_06_2}",
"vReporteTurnoNoche24a01_07_2":"${vReporteTurnoNoche24a01_07_2}",
"vReporteTurnoNoche24a01_08_2":"${vReporteTurnoNoche24a01_08_2}",
"vReporteTurnoNoche24a01_09_2":"${vReporteTurnoNoche24a01_09_2}",
"vReporteTurnoNoche24a01_10_2":"${vReporteTurnoNoche24a01_10_2}",
"vReporteTurnoNoche24a01_11_2":"${vReporteTurnoNoche24a01_11_2}",
"vReporteTurnoNoche24a01_12_2":"${vReporteTurnoNoche24a01_12_2}",
"vReporteTurnoNoche24a01_13_2":"${vReporteTurnoNoche24a01_13_2}",
"vReporteTurnoNoche01a02_01_2":"${vReporteTurnoNoche01a02_01_2}",
"vReporteTurnoNoche01a02_02_2":"${vReporteTurnoNoche01a02_02_2}",
"vReporteTurnoNoche01a02_03_2":"${vReporteTurnoNoche01a02_03_2}",
"vReporteTurnoNoche01a02_04_2":"${vReporteTurnoNoche01a02_04_2}",
"vReporteTurnoNoche01a02_05_2":"${vReporteTurnoNoche01a02_05_2}",
"vReporteTurnoNoche01a02_06_2":"${vReporteTurnoNoche01a02_06_2}",
"vReporteTurnoNoche01a02_07_2":"${vReporteTurnoNoche01a02_07_2}",
"vReporteTurnoNoche01a02_08_2":"${vReporteTurnoNoche01a02_08_2}",
"vReporteTurnoNoche01a02_09_2":"${vReporteTurnoNoche01a02_09_2}",
"vReporteTurnoNoche01a02_10_2":"${vReporteTurnoNoche01a02_10_2}",
"vReporteTurnoNoche01a02_11_2":"${vReporteTurnoNoche01a02_11_2}",
"vReporteTurnoNoche01a02_12_2":"${vReporteTurnoNoche01a02_12_2}",
"vReporteTurnoNoche01a02_13_2":"${vReporteTurnoNoche01a02_13_2}",
"vReporteTurnoNoche02a03_01_2":"${vReporteTurnoNoche02a03_01_2}",
"vReporteTurnoNoche02a03_02_2":"${vReporteTurnoNoche02a03_02_2}",
"vReporteTurnoNoche02a03_03_2":"${vReporteTurnoNoche02a03_03_2}",
"vReporteTurnoNoche02a03_04_2":"${vReporteTurnoNoche02a03_04_2}",
"vReporteTurnoNoche02a03_05_2":"${vReporteTurnoNoche02a03_05_2}",
"vReporteTurnoNoche02a03_06_2":"${vReporteTurnoNoche02a03_06_2}",
"vReporteTurnoNoche02a03_07_2":"${vReporteTurnoNoche02a03_07_2}",
"vReporteTurnoNoche02a03_08_2":"${vReporteTurnoNoche02a03_08_2}",
"vReporteTurnoNoche02a03_09_2":"${vReporteTurnoNoche02a03_09_2}",
"vReporteTurnoNoche02a03_10_2":"${vReporteTurnoNoche02a03_10_2}",
"vReporteTurnoNoche02a03_11_2":"${vReporteTurnoNoche02a03_11_2}",
"vReporteTurnoNoche02a03_12_2":"${vReporteTurnoNoche02a03_12_2}",
"vReporteTurnoNoche02a03_13_2":"${vReporteTurnoNoche02a03_13_2}",
"vReporteTurnoNoche03a04_01_2":"${vReporteTurnoNoche03a04_01_2}",
"vReporteTurnoNoche03a04_01_2":"${vReporteTurnoNoche03a04_01_2}",
"vReporteTurnoNoche03a04_02_2":"${vReporteTurnoNoche03a04_02_2}",
"vReporteTurnoNoche03a04_03_2":"${vReporteTurnoNoche03a04_03_2}",
"vReporteTurnoNoche03a04_04_2":"${vReporteTurnoNoche03a04_04_2}",
"vReporteTurnoNoche03a04_05_2":"${vReporteTurnoNoche03a04_05_2}",
"vReporteTurnoNoche03a04_06_2":"${vReporteTurnoNoche03a04_06_2}",
"vReporteTurnoNoche03a04_07_2":"${vReporteTurnoNoche03a04_07_2}",
"vReporteTurnoNoche03a04_08_2":"${vReporteTurnoNoche03a04_08_2}",
"vReporteTurnoNoche03a04_09_2":"${vReporteTurnoNoche03a04_09_2}",
"vReporteTurnoNoche03a04_10_2":"${vReporteTurnoNoche03a04_10_2}",
"vReporteTurnoNoche03a04_11_2":"${vReporteTurnoNoche03a04_11_2}",
"vReporteTurnoNoche03a04_12_2":"${vReporteTurnoNoche03a04_12_2}",
"vReporteTurnoNoche03a04_13_2":"${vReporteTurnoNoche03a04_13_2}",
"vReporteTurnoNoche04a05_01_2":"${vReporteTurnoNoche04a05_01_2}",
"vReporteTurnoNoche04a05_02_2":"${vReporteTurnoNoche04a05_02_2}",
"vReporteTurnoNoche04a05_03_2":"${vReporteTurnoNoche04a05_03_2}",
"vReporteTurnoNoche04a05_04_2":"${vReporteTurnoNoche04a05_04_2}",
"vReporteTurnoNoche04a05_05_2":"${vReporteTurnoNoche04a05_05_2}",
"vReporteTurnoNoche04a05_06_2":"${vReporteTurnoNoche04a05_06_2}",
"vReporteTurnoNoche04a05_07_2":"${vReporteTurnoNoche04a05_07_2}",
"vReporteTurnoNoche04a05_08_2":"${vReporteTurnoNoche04a05_08_2}",
"vReporteTurnoNoche04a05_09_2":"${vReporteTurnoNoche04a05_09_2}",
"vReporteTurnoNoche04a05_10_2":"${vReporteTurnoNoche04a05_10_2}",
"vReporteTurnoNoche04a05_11_2":"${vReporteTurnoNoche04a05_11_2}",
"vReporteTurnoNoche04a05_12_2":"${vReporteTurnoNoche04a05_12_2}",
"vReporteTurnoNoche04a05_13_2":"${vReporteTurnoNoche04a05_13_2}",
"vReporteTurnoNoche05a06_01_2":"${vReporteTurnoNoche05a06_01_2}",
"vReporteTurnoNoche05a06_02_2":"${vReporteTurnoNoche05a06_02_2}",
"vReporteTurnoNoche05a06_03_2":"${vReporteTurnoNoche05a06_03_2}",
"vReporteTurnoNoche05a06_04_2":"${vReporteTurnoNoche05a06_04_2}",
"vReporteTurnoNoche05a06_05_2":"${vReporteTurnoNoche05a06_05_2}",
"vReporteTurnoNoche05a06_06_2":"${vReporteTurnoNoche05a06_06_2}",
"vReporteTurnoNoche05a06_07_2":"${vReporteTurnoNoche05a06_07_2}",
"vReporteTurnoNoche05a06_08_2":"${vReporteTurnoNoche05a06_08_2}",
"vReporteTurnoNoche05a06_09_2":"${vReporteTurnoNoche05a06_09_2}",
"vReporteTurnoNoche05a06_10_2":"${vReporteTurnoNoche05a06_10_2}",
"vReporteTurnoNoche05a06_11_2":"${vReporteTurnoNoche05a06_11_2}",
"vReporteTurnoNoche05a06_12_2":"${vReporteTurnoNoche05a06_12_2}",
"vReporteTurnoNoche05a06_13_2":"${vReporteTurnoNoche05a06_13_2}",
"vReporteTurnoNoche06a07_01_2":"${vReporteTurnoNoche06a07_01_2}",
"vReporteTurnoNoche06a07_02_2":"${vReporteTurnoNoche06a07_02_2}",
"vReporteTurnoNoche06a07_03_2":"${vReporteTurnoNoche06a07_03_2}",
"vReporteTurnoNoche06a07_04_2":"${vReporteTurnoNoche06a07_04_2}",
"vReporteTurnoNoche06a07_05_2":"${vReporteTurnoNoche06a07_05_2}",
"vReporteTurnoNoche06a07_06_2":"${vReporteTurnoNoche06a07_06_2}",
"vReporteTurnoNoche06a07_07_2":"${vReporteTurnoNoche06a07_07_2}",
"vReporteTurnoNoche06a07_08_2":"${vReporteTurnoNoche06a07_08_2}",
"vReporteTurnoNoche06a07_09_2":"${vReporteTurnoNoche06a07_09_2}",
"vReporteTurnoNoche06a07_10_2":"${vReporteTurnoNoche06a07_10_2}",
"vReporteTurnoNoche06a07_11_2":"${vReporteTurnoNoche06a07_11_2}",
"vReporteTurnoNoche06a07_12_2":"${vReporteTurnoNoche06a07_12_2}",
"vReporteTurnoNoche06a07_13_2":"${vReporteTurnoNoche06a07_13_2}",
"vReporteTurnoNoche07a08_01_2":"${vReporteTurnoNoche07a08_01_2}",
"vReporteTurnoNoche07a08_02_2":"${vReporteTurnoNoche07a08_02_2}",
"vReporteTurnoNoche07a08_03_2":"${vReporteTurnoNoche07a08_03_2}",
"vReporteTurnoNoche07a08_04_2":"${vReporteTurnoNoche07a08_04_2}",
"vReporteTurnoNoche07a08_05_2":"${vReporteTurnoNoche07a08_05_2}",
"vReporteTurnoNoche07a08_06_2":"${vReporteTurnoNoche07a08_06_2}",
"vReporteTurnoNoche07a08_07_2":"${vReporteTurnoNoche07a08_07_2}",
"vReporteTurnoNoche07a08_08_2":"${vReporteTurnoNoche07a08_08_2}",
"vReporteTurnoNoche07a08_09_2":"${vReporteTurnoNoche07a08_09_2}",
"vReporteTurnoNoche07a08_10_2":"${vReporteTurnoNoche07a08_10_2}",
"vReporteTurnoNoche07a08_11_2":"${vReporteTurnoNoche07a08_11_2}",
"vReporteTurnoNoche07a08_12_2":"${vReporteTurnoNoche07a08_12_2}",
"vReporteTurnoNoche07a08_13_2":"${vReporteTurnoNoche07a08_13_2}",
"vReporteTurnoNoche01_ID":"${vReporteTurnoNoche01_ID}",
"vReporteTurnoNoche04_ID":"${vReporteTurnoNoche04_ID}",
"vReporteTurnoNoche05_ID":"${vReporteTurnoNoche05_ID}",
"vReporteTurnoNocheFechaHora":"${reporteTurnoDateTimeX}",
"vReporteTurnoEstadoSondaje":"${reporteTurnoEstadoSondajeX}",
"vUUUID":"${this.state.uuidX}"}`

        if (modeX === 'guardar'){
          putFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_noche.json`, 'Crosscheck_Deliverable', JSON.stringify(jsonAttachFile), JSON.stringify(options))
            .then(() => {
              this.guardarTokenTurnoNoche()

            })
        }else{
          this.setState({pdfReporteTurnoNocheX: jsonAttachFile})
        }
      }
    }

    guardarTokenTurnoNoche(){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      const isocode = this.state.isocode
      const optionsDate = {
          weekday: "short",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour:	"2-digit",
          minute:	"2-digit",
          second:	"2-digit"
      };
      let tokenDateTimeX = new Date()
      tokenDateTimeX = tokenDateTimeX.toLocaleString(isocode,optionsDate)
      let tokenMayor = parseFloat(this.state.tokenMayorNocheX) + 1
      let jsonAttachFile ={"id":this.props.username,"role":this.state.authRoleX,"name":this.state.authNameX,"datetime":tokenDateTimeX,"token":tokenMayor}
      putFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_token_turno_noche.json`, 'Crosscheck_Deliverable', JSON.stringify(jsonAttachFile), JSON.stringify(options))
          .then(() => {
            this.guardarFondoPozoTurnoNoche()
          })
    }

    guardarFondoPozoTurnoNoche = () => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let jsonBlockstack4d = []
      return new Promise ((resolve1, reject1) =>{
          getFileDB(this.state.userTurnoNocheFondoPozoX, `${this.props.typeContract}_${this.props.numberContract}_fondo_pozo_turno_noche.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                    this.guardarFondoPozoTurnoNoche2(jsonBlockstack4d)
                    resolve1()
                  } else {
                    this.guardarFondoPozoTurnoNoche2(jsonBlockstack4d)
                    resolve1()
                  }
                } else {
                  this.guardarFondoPozoTurnoNoche2(jsonBlockstack4d)
                  resolve1()
                }
             })
            .catch(error => {
              this.guardarFondoPozoTurnoNoche2(jsonBlockstack4d)
              resolve1()
            });
      })
    }

    guardarFondoPozoTurnoNoche2(jsonBlockstack4d){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      let fechaX = this.state.fecha
      const idX = fechaX.substring(6, 10)+fechaX.substring(3, 5)+fechaX.substring(0, 2)
      let bEsta = false
      let fondoPozoTurnoNocheX2 = jsonBlockstack4d
      if (fondoPozoTurnoNocheX2.length > 0){
        let largoX = fondoPozoTurnoNocheX2.length
        fondoPozoTurnoNocheX2.map((todo,i) => {
          if (todo.fechafondopozo === this.state.fecha){
             todo.id = idX
             todo.fechafondopozo = this.state.fecha
             todo.inicio = this.cReporteTurnoNoche06.current.value
             todo.fin = this.cReporteTurnoNoche07.current.value
             todo.perforado = this.cReporteTurnoNoche08.current.value
             bEsta = true
          }
          if (i === largoX - 1){
          }
        })
      }
      if (bEsta === false){
        fondoPozoTurnoNocheX2.push({"id":idX,"fechafondopozo":this.state.fecha,"inicio":this.cReporteTurnoNoche06.current.value,"fin":this.cReporteTurnoNoche07.current.value,"perforado":this.cReporteTurnoNoche08.current.value})
      }

      putFileDB(this.props.username, `${this.props.typeContract}_${this.props.numberContract}_fondo_pozo_turno_noche.json`, 'Crosscheck_Deliverable', JSON.stringify(fondoPozoTurnoNocheX2), JSON.stringify(options))
          .then(() => {
            TimerMixin.setTimeout( () => {
                this.handleDate(null, 'fechaReporte', 1)
            }, 700);
          })
    }

    pdf = (e) => {
      this.setState({dialogOpenPdf: true, dialogMessage: 'Seleccione el PDF a Generar ', dialogTitle: 'PDF' })
    }

    grafico = (e) => {
      this.setState({dialogOpenExport: true, dialogMessage: 'Seleccione el tipo de dato a exportar ', dialogTitle: 'Exportar' })
    }

    costo = (e) => {
      this.setState({dialogOpenEstadoPago: true, dialogMessage: 'Seleccione el informe de pago a generar ', dialogTitle: 'Estado Pago' })
    }

    firmar = (e,optX) => {
      let role2X = ''
      let sigueX = false
      localStorage.setItem('optxfirma', optX)
      if (optX === 1){
          role2X = 'Supervisor MD'
          if (this.props.username === this.state.vReporteTurnoDia04_ID){
            sigueX = true
            if (this.state.cReporteTurnoSignatureFirmante1 === false){
               this.firmar2()
            }else{
              if (this.state.cReporteTurnoSignatureFirmante6 === false){
                 this.desfirmar2()
              }else{
                this.setState({dialogOpenError: true, dialogMessageError: 'No puede anular firma. Jefe Turno Mineral Drilling tiene firmado el Reporte ', dialogTitleError: 'Error' })
              }
            }
          }
      }
      if (optX === 2){
          role2X = 'Supervisor MD'
          if (this.props.username === this.state.vReporteTurnoNoche04_ID){
            sigueX = true
            if (this.state.cReporteTurnoSignatureFirmante2 === false){
               this.firmar2()
            }else{
              if (this.state.cReporteTurnoSignatureFirmante7 === false){
                 this.desfirmar2()
              }else{
                this.setState({dialogOpenError: true, dialogMessageError: 'No puede anular firma. Jefe Turno Mineral Drilling tiene firmado el Reporte ', dialogTitleError: 'Error' })
              }
            }
          }
      }
      if (optX === 6){
          role2X = 'Jefe Turno MD'
          if (this.props.username === this.state.vReporteTurnoDia01_ID){
            sigueX = true
            if (this.state.cReporteTurnoSignatureFirmante6 === false){
              if (this.state.cReporteTurnoSignatureFirmante1){
                 this.firmar2()
              }else{
                 this.setState({dialogOpenError: true, dialogMessageError: 'No puede firmar. Supervisor aun no firma el Reporte ', dialogTitleError: 'Error' })
              }
            }else{
              if (this.state.cReporteTurnoSignatureFirmante4 === false){
                 this.desfirmar2()
              }else{
                this.setState({dialogOpenError: true, dialogMessageError: 'No puede anular firma. Jefe Turno Control Sondaje tiene firmado el Reporte ', dialogTitleError: 'Error' })
              }
            }
          }
      }
      if (optX === 7){
          role2X = 'Jefe Turno MD'
          if (this.props.username === this.state.vReporteTurnoNoche01_ID){
            sigueX = true
            if (this.state.cReporteTurnoSignatureFirmante7 === false){
              if (this.state.cReporteTurnoSignatureFirmante2){
                 this.firmar2()
              }else{
                 this.setState({dialogOpenError: true, dialogMessageError: 'No puede firmar. Supervisor aun no firma el Reporte ', dialogTitleError: 'Error' })
              }
            }else{
              if (this.state.cReporteTurnoSignatureFirmante4 === false){
                 this.desfirmar2()
              }else{
                this.setState({dialogOpenError: true, dialogMessageError: 'No puede anular firma. Jefe Turno Control Sondaje tiene firmado el Reporte ', dialogTitleError: 'Error' })
              }
            }
          }
      }
      if (optX > 2 && optX < 6){
        if (optX === 4){role2X = 'Jefe Turno Control Sondaje'}
        if (optX === 3){role2X = 'Adm MD'}
        if (optX === 5){role2X = 'Adm AA'}
        this.state.authorizersX.map((todoauth,i) => {
          if (todoauth.conceptFormsDeliverable === 'ReporteTurno'){
              this.state.roleX.map((todorole,x) => {
                  if (todoauth.role === todorole.code && todoauth.role === role2X){
                    if (todoauth.id === this.props.username){
                      if (todorole.signature) {
                        sigueX = true
                        if (optX === 4){
                           if (this.state.cReporteTurnoSignatureFirmante4 === false){
                              if ((this.state.cReporteTurnoSignatureFirmante6 === true && this.state.cReporteTurnoSignatureFirmante7 === true) ||
                                  ((this.state.cReporteTurnoSignatureFirmante6 === false && this.state.jsonVersionesTurnoDiaX.length === 0) && (this.state.cReporteTurnoSignatureFirmante7 === true && this.state.jsonVersionesTurnoNocheX.length > 0)) ||
                                  ((this.state.cReporteTurnoSignatureFirmante6 === true && this.state.jsonVersionesTurnoDiaX.length > 0) && (this.state.cReporteTurnoSignatureFirmante7 === false && this.state.jsonVersionesTurnoNocheX.length === 0))
                                 ){
                                 this.firmar2()
                               }else{
                                  this.setState({dialogOpenError: true, dialogMessageError: 'No puede firmar. Los Jefes de Turno aun no firman el Reporte ', dialogTitleError: 'Error' })
                               }
                           }else{
                             if (this.state.cReporteTurnoSignatureFirmante3 === false){
                                this.desfirmar2()
                             }else{
                               this.setState({dialogOpenError: true, dialogMessageError: 'No puede anular firma. Usuario Administrador Contrato Mineral Drilling tiene firmado el Reporte ', dialogTitleError: 'Error' })
                             }
                           }
                        }
                        if (optX === 3){
                           if (this.state.cReporteTurnoSignatureFirmante3 === false){
                              if (this.state.cReporteTurnoSignatureFirmante4 === true){
                                 this.firmar2()
                              }else{
                                this.setState({dialogOpenError: true, dialogMessageError: 'No puede firmar. Jefe Turno Control Sondaje aun no firma el Reporte ', dialogTitleError: 'Error' })
                              }
                           }else{
                              if (this.state.cReporteTurnoSignatureFirmante5 === false){
                                 this.desfirmar2()
                              }else{
                                this.setState({dialogOpenError: true, dialogMessageError: 'No puede anular firma. Usuario Administrador Contrato Anglo-American tiene firmado el Reporte ', dialogTitleError: 'Error' })
                              }
                           }
                        }
                        if (optX === 5){
                           if (this.state.cReporteTurnoSignatureFirmante5 === false){
                             if (this.state.cReporteTurnoSignatureFirmante3 === true){
                                this.firmar2()
                              }else{
                                 this.setState({dialogOpenError: true, dialogMessageError: 'No puede firmar. Administrador Contrato Mineral Drilling aun no firma el Reporte ', dialogTitleError: 'Error' })
                              }
                           }else{
                              if (this.state.cierreReporteTurnoX !== {}){
                                if (this.state.cierreReporteTurnoX.close === true){
                                  this.setState({dialogOpenError: true, dialogMessageError: 'No puede anular firma. Reporte de Turno de encuentra cerrado ', dialogTitleError: 'Error' })
                                }else{
                                  this.desfirmar2()
                                }
                              }else{
                                this.desfirmar2()
                            }
                           }
                        }
                      }
                    }
                  }
              })
          }
        })
      }
      if (sigueX === false){
        this.setState({dialogOpenError: true, dialogMessageError: 'Usuario no puede firmar o anular firma de otro usuario ', dialogTitleError: 'Error' })
      }
    }

    firmar2 = () => {
      this.setState({dialogOpen: true, dialogMessage: '¿Desea firmar el Reporte de Turno?', dialogTitle: 'Firmar Reporte' })
    }

    desfirmar2 = () => {
      this.setState({dialogOpen: true, dialogMessage: '¿Desea anular la firma del Reporte de Turno?', dialogTitle: 'Anular Firma Reporte' })
    }

    firmar3 = (modofirma) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      const optX = localStorage.getItem('optxfirma')
      let archivo2X = ''
      if (optX === '1'){
        archivo2X = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_firma_supervisor_md_dia.json`
      }
      if (optX === '2'){
        archivo2X = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_firma_supervisor_md_noche.json`
      }
      if (optX === '3'){
        archivo2X = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_firma_adm_md.json`
      }
      if (optX === '4'){
        archivo2X = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_firma_control_gestion.json`
      }
      if (optX === '5'){
        archivo2X = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_firma_adm_aa.json`
      }
      if (optX === '6'){
        archivo2X = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_firma_jefe_turno_dia.json`
      }
      if (optX === '7'){
        archivo2X = `${this.props.typeContract}_${this.props.numberContract}_${this.state.fecha}_reporte_turno_firma_jefe_turno_noche.json`
      }

      const isocode = this.state.isocode
      const optionsDate = {
          weekday: "short",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour:	"2-digit",
          minute:	"2-digit",
          second:	"2-digit"
      };
      let signatureDateTimeX = new Date()
      signatureDateTimeX = signatureDateTimeX.toLocaleString(isocode,optionsDate)

      let jsonAttachFile =[{"id":this.props.username,"role":this.state.authRoleX,"name":this.state.authNameX,"publicKey":this.state.authPublicKey,"datetime":signatureDateTimeX,"signature":modofirma}]
      putFileDB(this.props.username, archivo2X, 'Crosscheck_Deliverable', JSON.stringify(jsonAttachFile), JSON.stringify(options))
        .then(() => {
          this.handleDialogDisplay('Resumen', this.state.idTurnoDiaX, this.state.idTurnoNocheX, this.state.tokenMayorDiaX, this.state.tokenMayorNocheX)
        })
    }


    observacion = (e,optX) => {

      let role2X = ''
      let disabledX = true
      if (this.state.cierreReporteTurnoX !== {} && this.state.cierreReporteTurnoX.close === true){
          disabledX = true
      }else{
        if (optX === 1){role2X = 'Supervisor MD'}
        if (optX === 2){role2X = 'Supervisor MD'}
        if (optX === 3){role2X = 'Adm MD'}
        if (optX === 4){role2X = 'Jefe Turno Control Sondaje'}
        if (optX === 5){role2X = 'Adm AA'}
        if (optX === 6){role2X = 'Asesor HSE MD'}
        if (optX === 7){role2X = 'Asesor HSE MD'}
        if (optX === 8){role2X = 'Jefe Turno MD'}
        if (optX === 9){role2X = 'Jefe Turno MD'}
        this.state.authorizersX.map((todoauth,i) => {
          if (todoauth.conceptFormsDeliverable === 'ReporteTurno'){
              this.state.roleX.map((todorole,x) => {
                  if (todoauth.role === todorole.code && todoauth.role === role2X){
                    if (todoauth.id === this.props.username){
                     disabledX = false
                    }
                  }
              })
          }
        })
      }

      this.setState({observacionDisabled:disabledX})

      this.setState({dialogOpenObservaciones:true,dialogTitle:'Aclaraciones', opcionObservacion:optX})
      if (optX === 1){this.setState({dialogMessage:"Observación Firmante - Supervisor Día Mineral Drilling", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionFirmante1 })}
      if (optX === 2){this.setState({dialogMessage:"Observación Firmante - Supervisor Noche Mineral Drilling", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionFirmante2 })}
      if (optX === 3){this.setState({dialogMessage:"Observación Firmante - Administrador Contrato Mineral Drilling", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionFirmante3 })}
      if (optX === 4){this.setState({dialogMessage:"Observación Firmante - Jefe Turno Control Sondaje", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionFirmante4 })}
      if (optX === 5){this.setState({dialogMessage:"Observación Firmante - Administrador Contrato Anglo-American", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionFirmante5 })}
      if (optX === 6){this.setState({dialogMessage:"Observación Asesor HSE Mineral Drilling - Turno Día", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionAsesorTurnoDia })}
      if (optX === 7){this.setState({dialogMessage:"Observación Asesor HSE Mineral Drilling - Turno Noche", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionAsesorTurnoNoche })}
      if (optX === 8){this.setState({dialogMessage:"Observación Jefe Turno Mineral Drilling - Turno Día", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionJefeTurnoDia })}
      if (optX === 9){this.setState({dialogMessage:"Observación Jefe Turno Mineral Drilling - Turno Noche", cReporteTurnoObservacion:this.state.cReporteTurnoObservacionJefeTurnoNoche })}
    }

    duplicar = (e,optX) => {
      if (optX === 1){
        this.cReporteTurnoDia08a09_01_2.current.value = this.cReporteTurnoDia08a09_01.current.value;
        this.cReporteTurnoDia08a09_02_2.current.value = this.cReporteTurnoDia08a09_02.current.value;
        this.cReporteTurnoDia08a09_03_2.current.value = this.cReporteTurnoDia08a09_03.current.value;
        this.cReporteTurnoDia08a09_04_2.current.value = this.cReporteTurnoDia08a09_04.current.value;
        this.cReporteTurnoDia08a09_05_2.current.value = this.cReporteTurnoDia08a09_05.current.value;
        this.cReporteTurnoDia08a09_06_2.current.value = this.cReporteTurnoDia08a09_06.current.value;
        this.cReporteTurnoDia08a09_07_2.current.value = this.cReporteTurnoDia08a09_07.current.value;
        this.cReporteTurnoDia08a09_08_2.current.value = this.cReporteTurnoDia08a09_08.current.value;
        this.cReporteTurnoDia08a09_09_2.current.value = this.cReporteTurnoDia08a09_09.current.value;
        this.cReporteTurnoDia08a09_10_2.current.value = this.cReporteTurnoDia08a09_10.current.value;
        this.cReporteTurnoDia08a09_11_2.current.value = this.cReporteTurnoDia08a09_11.current.value;
        this.cReporteTurnoDia08a09_12_2.current.value = this.cReporteTurnoDia08a09_12.current.value;
        this.cReporteTurnoDia08a09_13_2.current.value = this.cReporteTurnoDia08a09_13.current.value;
        this.setState({linea02X:true})
        this.setState({sobrecargaX2:this.state.sobrecargaX1,
                       opcionPerforacionX2:this.state.opcionPerforacionX1,
                       herramientaCodeX2:this.state.herramientaCodeX1})
      }
      if (optX === 2){
        this.cReporteTurnoDia09a10_01.current.value = this.cReporteTurnoDia08a09_01_2.current.value;
        this.cReporteTurnoDia09a10_02.current.value = this.cReporteTurnoDia08a09_02_2.current.value;
        this.cReporteTurnoDia09a10_03.current.value = this.cReporteTurnoDia08a09_03_2.current.value;
        this.cReporteTurnoDia09a10_04.current.value = this.cReporteTurnoDia08a09_04_2.current.value;
        this.cReporteTurnoDia09a10_05.current.value = this.cReporteTurnoDia08a09_05_2.current.value;
        this.cReporteTurnoDia09a10_06.current.value = this.cReporteTurnoDia08a09_06_2.current.value;
        this.cReporteTurnoDia09a10_07.current.value = this.cReporteTurnoDia08a09_07_2.current.value;
        this.cReporteTurnoDia09a10_08.current.value = this.cReporteTurnoDia08a09_08_2.current.value;
        this.cReporteTurnoDia09a10_09.current.value = this.cReporteTurnoDia08a09_09_2.current.value;
        this.cReporteTurnoDia09a10_10.current.value = this.cReporteTurnoDia08a09_10_2.current.value;
        this.cReporteTurnoDia09a10_11.current.value = this.cReporteTurnoDia08a09_11_2.current.value;
        this.cReporteTurnoDia09a10_12.current.value = this.cReporteTurnoDia08a09_12_2.current.value;
        this.cReporteTurnoDia09a10_13.current.value = this.cReporteTurnoDia08a09_13_2.current.value;
        this.setState({linea03X:true})
        this.setState({sobrecargaX3:this.state.sobrecargaX2,
                       opcionPerforacionX3:this.state.opcionPerforacionX2,
                       herramientaCodeX3:this.state.herramientaCodeX2})
      }
      if (optX === 3){
        this.cReporteTurnoDia09a10_01_2.current.value = this.cReporteTurnoDia09a10_01.current.value;
        this.cReporteTurnoDia09a10_02_2.current.value = this.cReporteTurnoDia09a10_02.current.value;
        this.cReporteTurnoDia09a10_03_2.current.value = this.cReporteTurnoDia09a10_03.current.value;
        this.cReporteTurnoDia09a10_04_2.current.value = this.cReporteTurnoDia09a10_04.current.value;
        this.cReporteTurnoDia09a10_05_2.current.value = this.cReporteTurnoDia09a10_05.current.value;
        this.cReporteTurnoDia09a10_06_2.current.value = this.cReporteTurnoDia09a10_06.current.value;
        this.cReporteTurnoDia09a10_07_2.current.value = this.cReporteTurnoDia09a10_07.current.value;
        this.cReporteTurnoDia09a10_08_2.current.value = this.cReporteTurnoDia09a10_08.current.value;
        this.cReporteTurnoDia09a10_09_2.current.value = this.cReporteTurnoDia09a10_09.current.value;
        this.cReporteTurnoDia09a10_10_2.current.value = this.cReporteTurnoDia09a10_10.current.value;
        this.cReporteTurnoDia09a10_11_2.current.value = this.cReporteTurnoDia09a10_11.current.value;
        this.cReporteTurnoDia09a10_12_2.current.value = this.cReporteTurnoDia09a10_12.current.value;
        this.cReporteTurnoDia09a10_13_2.current.value = this.cReporteTurnoDia09a10_13.current.value;
        this.setState({linea04X:true})
        this.setState({sobrecargaX4:this.state.sobrecargaX3,
                       opcionPerforacionX4:this.state.opcionPerforacionX3,
                       herramientaCodeX4:this.state.herramientaCodeX3})
      }
      if (optX === 4){
        this.cReporteTurnoDia10a11_01.current.value = this.cReporteTurnoDia09a10_01_2.current.value;
        this.cReporteTurnoDia10a11_02.current.value = this.cReporteTurnoDia09a10_02_2.current.value;
        this.cReporteTurnoDia10a11_03.current.value = this.cReporteTurnoDia09a10_03_2.current.value;
        this.cReporteTurnoDia10a11_04.current.value = this.cReporteTurnoDia09a10_04_2.current.value;
        this.cReporteTurnoDia10a11_05.current.value = this.cReporteTurnoDia09a10_05_2.current.value;
        this.cReporteTurnoDia10a11_06.current.value = this.cReporteTurnoDia09a10_06_2.current.value;
        this.cReporteTurnoDia10a11_07.current.value = this.cReporteTurnoDia09a10_07_2.current.value;
        this.cReporteTurnoDia10a11_08.current.value = this.cReporteTurnoDia09a10_08_2.current.value;
        this.cReporteTurnoDia10a11_09.current.value = this.cReporteTurnoDia09a10_09_2.current.value;
        this.cReporteTurnoDia10a11_10.current.value = this.cReporteTurnoDia09a10_10_2.current.value;
        this.cReporteTurnoDia10a11_11.current.value = this.cReporteTurnoDia09a10_11_2.current.value;
        this.cReporteTurnoDia10a11_12.current.value = this.cReporteTurnoDia09a10_12_2.current.value;
        this.cReporteTurnoDia10a11_13.current.value = this.cReporteTurnoDia09a10_13_2.current.value;
        this.setState({linea05X:true})
        this.setState({sobrecargaX5:this.state.sobrecargaX4,
                       opcionPerforacionX5:this.state.opcionPerforacionX4,
                       herramientaCodeX5:this.state.herramientaCodeX4})
      }
      if (optX === 5){
        this.cReporteTurnoDia10a11_01_2.current.value = this.cReporteTurnoDia10a11_01.current.value;
        this.cReporteTurnoDia10a11_02_2.current.value = this.cReporteTurnoDia10a11_02.current.value;
        this.cReporteTurnoDia10a11_03_2.current.value = this.cReporteTurnoDia10a11_03.current.value;
        this.cReporteTurnoDia10a11_04_2.current.value = this.cReporteTurnoDia10a11_04.current.value;
        this.cReporteTurnoDia10a11_05_2.current.value = this.cReporteTurnoDia10a11_05.current.value;
        this.cReporteTurnoDia10a11_06_2.current.value = this.cReporteTurnoDia10a11_06.current.value;
        this.cReporteTurnoDia10a11_07_2.current.value = this.cReporteTurnoDia10a11_07.current.value;
        this.cReporteTurnoDia10a11_08_2.current.value = this.cReporteTurnoDia10a11_08.current.value;
        this.cReporteTurnoDia10a11_09_2.current.value = this.cReporteTurnoDia10a11_09.current.value;
        this.cReporteTurnoDia10a11_10_2.current.value = this.cReporteTurnoDia10a11_10.current.value;
        this.cReporteTurnoDia10a11_11_2.current.value = this.cReporteTurnoDia10a11_11.current.value;
        this.cReporteTurnoDia10a11_12_2.current.value = this.cReporteTurnoDia10a11_12.current.value;
        this.cReporteTurnoDia10a11_13_2.current.value = this.cReporteTurnoDia10a11_13.current.value;
        this.setState({linea06X:true})
        this.setState({sobrecargaX6:this.state.sobrecargaX5,
                       opcionPerforacionX6:this.state.opcionPerforacionX5,
                       herramientaCodeX6:this.state.herramientaCodeX5})
      }
      if (optX === 6){
        this.cReporteTurnoDia11a12_01.current.value = this.cReporteTurnoDia10a11_01_2.current.value;
        this.cReporteTurnoDia11a12_02.current.value = this.cReporteTurnoDia10a11_02_2.current.value;
        this.cReporteTurnoDia11a12_03.current.value = this.cReporteTurnoDia10a11_03_2.current.value;
        this.cReporteTurnoDia11a12_04.current.value = this.cReporteTurnoDia10a11_04_2.current.value;
        this.cReporteTurnoDia11a12_05.current.value = this.cReporteTurnoDia10a11_05_2.current.value;
        this.cReporteTurnoDia11a12_06.current.value = this.cReporteTurnoDia10a11_06_2.current.value;
        this.cReporteTurnoDia11a12_07.current.value = this.cReporteTurnoDia10a11_07_2.current.value;
        this.cReporteTurnoDia11a12_08.current.value = this.cReporteTurnoDia10a11_08_2.current.value;
        this.cReporteTurnoDia11a12_09.current.value = this.cReporteTurnoDia10a11_09_2.current.value;
        this.cReporteTurnoDia11a12_10.current.value = this.cReporteTurnoDia10a11_10_2.current.value;
        this.cReporteTurnoDia11a12_11.current.value = this.cReporteTurnoDia10a11_11_2.current.value;
        this.cReporteTurnoDia11a12_12.current.value = this.cReporteTurnoDia10a11_12_2.current.value;
        this.cReporteTurnoDia11a12_13.current.value = this.cReporteTurnoDia10a11_13_2.current.value;
        this.setState({linea07X:true})
        this.setState({sobrecargaX7:this.state.sobrecargaX6,
                       opcionPerforacionX7:this.state.opcionPerforacionX6,
                       herramientaCodeX7:this.state.herramientaCodeX6})
      }
      if (optX === 7){
        this.cReporteTurnoDia11a12_01_2.current.value = this.cReporteTurnoDia11a12_01.current.value;
        this.cReporteTurnoDia11a12_02_2.current.value = this.cReporteTurnoDia11a12_02.current.value;
        this.cReporteTurnoDia11a12_03_2.current.value = this.cReporteTurnoDia11a12_03.current.value;
        this.cReporteTurnoDia11a12_04_2.current.value = this.cReporteTurnoDia11a12_04.current.value;
        this.cReporteTurnoDia11a12_05_2.current.value = this.cReporteTurnoDia11a12_05.current.value;
        this.cReporteTurnoDia11a12_06_2.current.value = this.cReporteTurnoDia11a12_06.current.value;
        this.cReporteTurnoDia11a12_07_2.current.value = this.cReporteTurnoDia11a12_07.current.value;
        this.cReporteTurnoDia11a12_08_2.current.value = this.cReporteTurnoDia11a12_08.current.value;
        this.cReporteTurnoDia11a12_09_2.current.value = this.cReporteTurnoDia11a12_09.current.value;
        this.cReporteTurnoDia11a12_10_2.current.value = this.cReporteTurnoDia11a12_10.current.value;
        this.cReporteTurnoDia11a12_11_2.current.value = this.cReporteTurnoDia11a12_11.current.value;
        this.cReporteTurnoDia11a12_12_2.current.value = this.cReporteTurnoDia11a12_12.current.value;
        this.cReporteTurnoDia11a12_13_2.current.value = this.cReporteTurnoDia11a12_13.current.value;
        this.setState({linea08X:true})
        this.setState({sobrecargaX8:this.state.sobrecargaX7,
                       opcionPerforacionX8:this.state.opcionPerforacionX7,
                       herramientaCodeX8:this.state.herramientaCodeX7})
      }
      if (optX === 8){
        this.cReporteTurnoDia12a13_01.current.value = this.cReporteTurnoDia11a12_01_2.current.value;
        this.cReporteTurnoDia12a13_02.current.value = this.cReporteTurnoDia11a12_02_2.current.value;
        this.cReporteTurnoDia12a13_03.current.value = this.cReporteTurnoDia11a12_03_2.current.value;
        this.cReporteTurnoDia12a13_04.current.value = this.cReporteTurnoDia11a12_04_2.current.value;
        this.cReporteTurnoDia12a13_05.current.value = this.cReporteTurnoDia11a12_05_2.current.value;
        this.cReporteTurnoDia12a13_06.current.value = this.cReporteTurnoDia11a12_06_2.current.value;
        this.cReporteTurnoDia12a13_07.current.value = this.cReporteTurnoDia11a12_07_2.current.value;
        this.cReporteTurnoDia12a13_08.current.value = this.cReporteTurnoDia11a12_08_2.current.value;
        this.cReporteTurnoDia12a13_09.current.value = this.cReporteTurnoDia11a12_09_2.current.value;
        this.cReporteTurnoDia12a13_10.current.value = this.cReporteTurnoDia11a12_10_2.current.value;
        this.cReporteTurnoDia12a13_11.current.value = this.cReporteTurnoDia11a12_11_2.current.value;
        this.cReporteTurnoDia12a13_12.current.value = this.cReporteTurnoDia11a12_12_2.current.value;
        this.cReporteTurnoDia12a13_13.current.value = this.cReporteTurnoDia11a12_13_2.current.value;
        this.setState({linea09X:true})
        this.setState({sobrecargaX9:this.state.sobrecargaX8,
                       opcionPerforacionX9:this.state.opcionPerforacionX8,
                       herramientaCodeX9:this.state.herramientaCodeX8})
      }
      if (optX === 9){
        this.cReporteTurnoDia12a13_01_2.current.value = this.cReporteTurnoDia12a13_01.current.value;
        this.cReporteTurnoDia12a13_02_2.current.value = this.cReporteTurnoDia12a13_02.current.value;
        this.cReporteTurnoDia12a13_03_2.current.value = this.cReporteTurnoDia12a13_03.current.value;
        this.cReporteTurnoDia12a13_04_2.current.value = this.cReporteTurnoDia12a13_04.current.value;
        this.cReporteTurnoDia12a13_05_2.current.value = this.cReporteTurnoDia12a13_05.current.value;
        this.cReporteTurnoDia12a13_06_2.current.value = this.cReporteTurnoDia12a13_06.current.value;
        this.cReporteTurnoDia12a13_07_2.current.value = this.cReporteTurnoDia12a13_07.current.value;
        this.cReporteTurnoDia12a13_08_2.current.value = this.cReporteTurnoDia12a13_08.current.value;
        this.cReporteTurnoDia12a13_09_2.current.value = this.cReporteTurnoDia12a13_09.current.value;
        this.cReporteTurnoDia12a13_10_2.current.value = this.cReporteTurnoDia12a13_10.current.value;
        this.cReporteTurnoDia12a13_11_2.current.value = this.cReporteTurnoDia12a13_11.current.value;
        this.cReporteTurnoDia12a13_12_2.current.value = this.cReporteTurnoDia12a13_12.current.value;
        this.cReporteTurnoDia12a13_13_2.current.value = this.cReporteTurnoDia12a13_13.current.value;
        this.setState({linea10X:true})
        this.setState({sobrecargaX10:this.state.sobrecargaX9,
                       opcionPerforacionX10:this.state.opcionPerforacionX9,
                       herramientaCodeX10:this.state.herramientaCodeX9})
      }
      if (optX === 10){
        this.cReporteTurnoDia13a14_01.current.value = this.cReporteTurnoDia12a13_01_2.current.value;
        this.cReporteTurnoDia13a14_02.current.value = this.cReporteTurnoDia12a13_02_2.current.value;
        this.cReporteTurnoDia13a14_03.current.value = this.cReporteTurnoDia12a13_03_2.current.value;
        this.cReporteTurnoDia13a14_04.current.value = this.cReporteTurnoDia12a13_04_2.current.value;
        this.cReporteTurnoDia13a14_05.current.value = this.cReporteTurnoDia12a13_05_2.current.value;
        this.cReporteTurnoDia13a14_06.current.value = this.cReporteTurnoDia12a13_06_2.current.value;
        this.cReporteTurnoDia13a14_07.current.value = this.cReporteTurnoDia12a13_07_2.current.value;
        this.cReporteTurnoDia13a14_08.current.value = this.cReporteTurnoDia12a13_08_2.current.value;
        this.cReporteTurnoDia13a14_09.current.value = this.cReporteTurnoDia12a13_09_2.current.value;
        this.cReporteTurnoDia13a14_10.current.value = this.cReporteTurnoDia12a13_10_2.current.value;
        this.cReporteTurnoDia13a14_11.current.value = this.cReporteTurnoDia12a13_11_2.current.value;
        this.cReporteTurnoDia13a14_12.current.value = this.cReporteTurnoDia12a13_12_2.current.value;
        this.cReporteTurnoDia13a14_13.current.value = this.cReporteTurnoDia12a13_13_2.current.value;
        this.setState({linea11X:true})
        this.setState({sobrecargaX11:this.state.sobrecargaX10,
                       opcionPerforacionX11:this.state.opcionPerforacionX10,
                       herramientaCodeX11:this.state.herramientaCodeX10})
      }
      if (optX === 11){
        this.cReporteTurnoDia13a14_01_2.current.value = this.cReporteTurnoDia13a14_01.current.value;
        this.cReporteTurnoDia13a14_02_2.current.value = this.cReporteTurnoDia13a14_02.current.value;
        this.cReporteTurnoDia13a14_03_2.current.value = this.cReporteTurnoDia13a14_03.current.value;
        this.cReporteTurnoDia13a14_04_2.current.value = this.cReporteTurnoDia13a14_04.current.value;
        this.cReporteTurnoDia13a14_05_2.current.value = this.cReporteTurnoDia13a14_05.current.value;
        this.cReporteTurnoDia13a14_06_2.current.value = this.cReporteTurnoDia13a14_06.current.value;
        this.cReporteTurnoDia13a14_07_2.current.value = this.cReporteTurnoDia13a14_07.current.value;
        this.cReporteTurnoDia13a14_08_2.current.value = this.cReporteTurnoDia13a14_08.current.value;
        this.cReporteTurnoDia13a14_09_2.current.value = this.cReporteTurnoDia13a14_09.current.value;
        this.cReporteTurnoDia13a14_10_2.current.value = this.cReporteTurnoDia13a14_10.current.value;
        this.cReporteTurnoDia13a14_11_2.current.value = this.cReporteTurnoDia13a14_11.current.value;
        this.cReporteTurnoDia13a14_12_2.current.value = this.cReporteTurnoDia13a14_12.current.value;
        this.cReporteTurnoDia13a14_13_2.current.value = this.cReporteTurnoDia13a14_13.current.value;
        this.setState({linea12X:true})
        this.setState({sobrecargaX12:this.state.sobrecargaX11,
                       opcionPerforacionX12:this.state.opcionPerforacionX11,
                       herramientaCodeX12:this.state.herramientaCodeX11})
      }
      if (optX === 12){
        this.cReporteTurnoDia14a15_01.current.value = this.cReporteTurnoDia13a14_01_2.current.value;
        this.cReporteTurnoDia14a15_02.current.value = this.cReporteTurnoDia13a14_02_2.current.value;
        this.cReporteTurnoDia14a15_03.current.value = this.cReporteTurnoDia13a14_03_2.current.value;
        this.cReporteTurnoDia14a15_04.current.value = this.cReporteTurnoDia13a14_04_2.current.value;
        this.cReporteTurnoDia14a15_05.current.value = this.cReporteTurnoDia13a14_05_2.current.value;
        this.cReporteTurnoDia14a15_06.current.value = this.cReporteTurnoDia13a14_06_2.current.value;
        this.cReporteTurnoDia14a15_07.current.value = this.cReporteTurnoDia13a14_07_2.current.value;
        this.cReporteTurnoDia14a15_08.current.value = this.cReporteTurnoDia13a14_08_2.current.value;
        this.cReporteTurnoDia14a15_09.current.value = this.cReporteTurnoDia13a14_09_2.current.value;
        this.cReporteTurnoDia14a15_10.current.value = this.cReporteTurnoDia13a14_10_2.current.value;
        this.cReporteTurnoDia14a15_11.current.value = this.cReporteTurnoDia13a14_11_2.current.value;
        this.cReporteTurnoDia14a15_12.current.value = this.cReporteTurnoDia13a14_12_2.current.value;
        this.cReporteTurnoDia14a15_13.current.value = this.cReporteTurnoDia13a14_13_2.current.value;
        this.setState({linea13X:true})
        this.setState({sobrecargaX13:this.state.sobrecargaX12,
                       opcionPerforacionX13:this.state.opcionPerforacionX12,
                       herramientaCodeX13:this.state.herramientaCodeX12})
      }
      if (optX === 13){
        this.cReporteTurnoDia14a15_01_2.current.value = this.cReporteTurnoDia14a15_01.current.value;
        this.cReporteTurnoDia14a15_02_2.current.value = this.cReporteTurnoDia14a15_02.current.value;
        this.cReporteTurnoDia14a15_03_2.current.value = this.cReporteTurnoDia14a15_03.current.value;
        this.cReporteTurnoDia14a15_04_2.current.value = this.cReporteTurnoDia14a15_04.current.value;
        this.cReporteTurnoDia14a15_05_2.current.value = this.cReporteTurnoDia14a15_05.current.value;
        this.cReporteTurnoDia14a15_06_2.current.value = this.cReporteTurnoDia14a15_06.current.value;
        this.cReporteTurnoDia14a15_07_2.current.value = this.cReporteTurnoDia14a15_07.current.value;
        this.cReporteTurnoDia14a15_08_2.current.value = this.cReporteTurnoDia14a15_08.current.value;
        this.cReporteTurnoDia14a15_09_2.current.value = this.cReporteTurnoDia14a15_09.current.value;
        this.cReporteTurnoDia14a15_10_2.current.value = this.cReporteTurnoDia14a15_10.current.value;
        this.cReporteTurnoDia14a15_11_2.current.value = this.cReporteTurnoDia14a15_11.current.value;
        this.cReporteTurnoDia14a15_12_2.current.value = this.cReporteTurnoDia14a15_12.current.value;
        this.cReporteTurnoDia14a15_13_2.current.value = this.cReporteTurnoDia14a15_13.current.value;
        this.setState({linea14X:true})
        this.setState({sobrecargaX14:this.state.sobrecargaX13,
                       opcionPerforacionX14:this.state.opcionPerforacionX13,
                       herramientaCodeX14:this.state.herramientaCodeX13})
      }
      if (optX === 14){
        this.cReporteTurnoDia15a16_01.current.value = this.cReporteTurnoDia14a15_01_2.current.value;
        this.cReporteTurnoDia15a16_02.current.value = this.cReporteTurnoDia14a15_02_2.current.value;
        this.cReporteTurnoDia15a16_03.current.value = this.cReporteTurnoDia14a15_03_2.current.value;
        this.cReporteTurnoDia15a16_04.current.value = this.cReporteTurnoDia14a15_04_2.current.value;
        this.cReporteTurnoDia15a16_05.current.value = this.cReporteTurnoDia14a15_05_2.current.value;
        this.cReporteTurnoDia15a16_06.current.value = this.cReporteTurnoDia14a15_06_2.current.value;
        this.cReporteTurnoDia15a16_07.current.value = this.cReporteTurnoDia14a15_07_2.current.value;
        this.cReporteTurnoDia15a16_08.current.value = this.cReporteTurnoDia14a15_08_2.current.value;
        this.cReporteTurnoDia15a16_09.current.value = this.cReporteTurnoDia14a15_09_2.current.value;
        this.cReporteTurnoDia15a16_10.current.value = this.cReporteTurnoDia14a15_10_2.current.value;
        this.cReporteTurnoDia15a16_11.current.value = this.cReporteTurnoDia14a15_11_2.current.value;
        this.cReporteTurnoDia15a16_12.current.value = this.cReporteTurnoDia14a15_12_2.current.value;
        this.cReporteTurnoDia15a16_13.current.value = this.cReporteTurnoDia14a15_13_2.current.value;
        this.setState({linea15X:true})
        this.setState({sobrecargaX15:this.state.sobrecargaX14,
                       opcionPerforacionX15:this.state.opcionPerforacionX14,
                       herramientaCodeX15:this.state.herramientaCodeX14})
      }
      if (optX === 15){
        this.cReporteTurnoDia15a16_01_2.current.value = this.cReporteTurnoDia15a16_01.current.value;
        this.cReporteTurnoDia15a16_02_2.current.value = this.cReporteTurnoDia15a16_02.current.value;
        this.cReporteTurnoDia15a16_03_2.current.value = this.cReporteTurnoDia15a16_03.current.value;
        this.cReporteTurnoDia15a16_04_2.current.value = this.cReporteTurnoDia15a16_04.current.value;
        this.cReporteTurnoDia15a16_05_2.current.value = this.cReporteTurnoDia15a16_05.current.value;
        this.cReporteTurnoDia15a16_06_2.current.value = this.cReporteTurnoDia15a16_06.current.value;
        this.cReporteTurnoDia15a16_07_2.current.value = this.cReporteTurnoDia15a16_07.current.value;
        this.cReporteTurnoDia15a16_08_2.current.value = this.cReporteTurnoDia15a16_08.current.value;
        this.cReporteTurnoDia15a16_09_2.current.value = this.cReporteTurnoDia15a16_09.current.value;
        this.cReporteTurnoDia15a16_10_2.current.value = this.cReporteTurnoDia15a16_10.current.value;
        this.cReporteTurnoDia15a16_11_2.current.value = this.cReporteTurnoDia15a16_11.current.value;
        this.cReporteTurnoDia15a16_12_2.current.value = this.cReporteTurnoDia15a16_12.current.value;
        this.cReporteTurnoDia15a16_13_2.current.value = this.cReporteTurnoDia15a16_13.current.value;
        this.setState({linea16X:true})
        this.setState({sobrecargaX16:this.state.sobrecargaX15,
                       opcionPerforacionX16:this.state.opcionPerforacionX15,
                       herramientaCodeX16:this.state.herramientaCodeX15})
      }
      if (optX === 16){
        this.cReporteTurnoDia16a17_01.current.value = this.cReporteTurnoDia15a16_01_2.current.value;
        this.cReporteTurnoDia16a17_02.current.value = this.cReporteTurnoDia15a16_02_2.current.value;
        this.cReporteTurnoDia16a17_03.current.value = this.cReporteTurnoDia15a16_03_2.current.value;
        this.cReporteTurnoDia16a17_04.current.value = this.cReporteTurnoDia15a16_04_2.current.value;
        this.cReporteTurnoDia16a17_05.current.value = this.cReporteTurnoDia15a16_05_2.current.value;
        this.cReporteTurnoDia16a17_06.current.value = this.cReporteTurnoDia15a16_06_2.current.value;
        this.cReporteTurnoDia16a17_07.current.value = this.cReporteTurnoDia15a16_07_2.current.value;
        this.cReporteTurnoDia16a17_08.current.value = this.cReporteTurnoDia15a16_08_2.current.value;
        this.cReporteTurnoDia16a17_09.current.value = this.cReporteTurnoDia15a16_09_2.current.value;
        this.cReporteTurnoDia16a17_10.current.value = this.cReporteTurnoDia15a16_10_2.current.value;
        this.cReporteTurnoDia16a17_11.current.value = this.cReporteTurnoDia15a16_11_2.current.value;
        this.cReporteTurnoDia16a17_12.current.value = this.cReporteTurnoDia15a16_12_2.current.value;
        this.cReporteTurnoDia16a17_13.current.value = this.cReporteTurnoDia15a16_13_2.current.value;
        this.setState({linea17X:true})
        this.setState({sobrecargaX17:this.state.sobrecargaX16,
                       opcionPerforacionX17:this.state.opcionPerforacionX16,
                       herramientaCodeX17:this.state.herramientaCodeX16})
      }
      if (optX === 17){
        this.cReporteTurnoDia16a17_01_2.current.value = this.cReporteTurnoDia16a17_01.current.value;
        this.cReporteTurnoDia16a17_02_2.current.value = this.cReporteTurnoDia16a17_02.current.value;
        this.cReporteTurnoDia16a17_03_2.current.value = this.cReporteTurnoDia16a17_03.current.value;
        this.cReporteTurnoDia16a17_04_2.current.value = this.cReporteTurnoDia16a17_04.current.value;
        this.cReporteTurnoDia16a17_05_2.current.value = this.cReporteTurnoDia16a17_05.current.value;
        this.cReporteTurnoDia16a17_06_2.current.value = this.cReporteTurnoDia16a17_06.current.value;
        this.cReporteTurnoDia16a17_07_2.current.value = this.cReporteTurnoDia16a17_07.current.value;
        this.cReporteTurnoDia16a17_08_2.current.value = this.cReporteTurnoDia16a17_08.current.value;
        this.cReporteTurnoDia16a17_09_2.current.value = this.cReporteTurnoDia16a17_09.current.value;
        this.cReporteTurnoDia16a17_10_2.current.value = this.cReporteTurnoDia16a17_10.current.value;
        this.cReporteTurnoDia16a17_11_2.current.value = this.cReporteTurnoDia16a17_11.current.value;
        this.cReporteTurnoDia16a17_12_2.current.value = this.cReporteTurnoDia16a17_12.current.value;
        this.cReporteTurnoDia16a17_13_2.current.value = this.cReporteTurnoDia16a17_13.current.value;
        this.setState({linea18X:true})
        this.setState({sobrecargaX18:this.state.sobrecargaX17,
                       opcionPerforacionX18:this.state.opcionPerforacionX17,
                       herramientaCodeX18:this.state.herramientaCodeX17})
      }
      if (optX === 18){
        this.cReporteTurnoDia17a18_01.current.value = this.cReporteTurnoDia16a17_01_2.current.value;
        this.cReporteTurnoDia17a18_02.current.value = this.cReporteTurnoDia16a17_02_2.current.value;
        this.cReporteTurnoDia17a18_03.current.value = this.cReporteTurnoDia16a17_03_2.current.value;
        this.cReporteTurnoDia17a18_04.current.value = this.cReporteTurnoDia16a17_04_2.current.value;
        this.cReporteTurnoDia17a18_05.current.value = this.cReporteTurnoDia16a17_05_2.current.value;
        this.cReporteTurnoDia17a18_06.current.value = this.cReporteTurnoDia16a17_06_2.current.value;
        this.cReporteTurnoDia17a18_07.current.value = this.cReporteTurnoDia16a17_07_2.current.value;
        this.cReporteTurnoDia17a18_08.current.value = this.cReporteTurnoDia16a17_08_2.current.value;
        this.cReporteTurnoDia17a18_09.current.value = this.cReporteTurnoDia16a17_09_2.current.value;
        this.cReporteTurnoDia17a18_10.current.value = this.cReporteTurnoDia16a17_10_2.current.value;
        this.cReporteTurnoDia17a18_11.current.value = this.cReporteTurnoDia16a17_11_2.current.value;
        this.cReporteTurnoDia17a18_12.current.value = this.cReporteTurnoDia16a17_12_2.current.value;
        this.cReporteTurnoDia17a18_13.current.value = this.cReporteTurnoDia16a17_13_2.current.value;
        this.setState({linea19X:true})
        this.setState({sobrecargaX19:this.state.sobrecargaX18,
                       opcionPerforacionX19:this.state.opcionPerforacionX18,
                       herramientaCodeX19:this.state.herramientaCodeX18})
      }
      if (optX === 19){
        this.cReporteTurnoDia17a18_01_2.current.value = this.cReporteTurnoDia17a18_01.current.value;
        this.cReporteTurnoDia17a18_02_2.current.value = this.cReporteTurnoDia17a18_02.current.value;
        this.cReporteTurnoDia17a18_03_2.current.value = this.cReporteTurnoDia17a18_03.current.value;
        this.cReporteTurnoDia17a18_04_2.current.value = this.cReporteTurnoDia17a18_04.current.value;
        this.cReporteTurnoDia17a18_05_2.current.value = this.cReporteTurnoDia17a18_05.current.value;
        this.cReporteTurnoDia17a18_06_2.current.value = this.cReporteTurnoDia17a18_06.current.value;
        this.cReporteTurnoDia17a18_07_2.current.value = this.cReporteTurnoDia17a18_07.current.value;
        this.cReporteTurnoDia17a18_08_2.current.value = this.cReporteTurnoDia17a18_08.current.value;
        this.cReporteTurnoDia17a18_09_2.current.value = this.cReporteTurnoDia17a18_09.current.value;
        this.cReporteTurnoDia17a18_10_2.current.value = this.cReporteTurnoDia17a18_10.current.value;
        this.cReporteTurnoDia17a18_11_2.current.value = this.cReporteTurnoDia17a18_11.current.value;
        this.cReporteTurnoDia17a18_12_2.current.value = this.cReporteTurnoDia17a18_12.current.value;
        this.cReporteTurnoDia17a18_13_2.current.value = this.cReporteTurnoDia17a18_13.current.value;
        this.setState({linea20X:true})
        this.setState({sobrecargaX20:this.state.sobrecargaX19,
                       opcionPerforacionX20:this.state.opcionPerforacionX19,
                       herramientaCodeX20:this.state.herramientaCodeX19})
      }
      if (optX === 20){
        this.cReporteTurnoDia18a19_01.current.value = this.cReporteTurnoDia17a18_01_2.current.value;
        this.cReporteTurnoDia18a19_02.current.value = this.cReporteTurnoDia17a18_02_2.current.value;
        this.cReporteTurnoDia18a19_03.current.value = this.cReporteTurnoDia17a18_03_2.current.value;
        this.cReporteTurnoDia18a19_04.current.value = this.cReporteTurnoDia17a18_04_2.current.value;
        this.cReporteTurnoDia18a19_05.current.value = this.cReporteTurnoDia17a18_05_2.current.value;
        this.cReporteTurnoDia18a19_06.current.value = this.cReporteTurnoDia17a18_06_2.current.value;
        this.cReporteTurnoDia18a19_07.current.value = this.cReporteTurnoDia17a18_07_2.current.value;
        this.cReporteTurnoDia18a19_08.current.value = this.cReporteTurnoDia17a18_08_2.current.value;
        this.cReporteTurnoDia18a19_09.current.value = this.cReporteTurnoDia17a18_09_2.current.value;
        this.cReporteTurnoDia18a19_10.current.value = this.cReporteTurnoDia17a18_10_2.current.value;
        this.cReporteTurnoDia18a19_11.current.value = this.cReporteTurnoDia17a18_11_2.current.value;
        this.cReporteTurnoDia18a19_12.current.value = this.cReporteTurnoDia17a18_12_2.current.value;
        this.cReporteTurnoDia18a19_13.current.value = this.cReporteTurnoDia17a18_13_2.current.value;
        this.setState({linea21X:true})
        this.setState({sobrecargaX21:this.state.sobrecargaX20,
                       opcionPerforacionX21:this.state.opcionPerforacionX20,
                       herramientaCodeX21:this.state.herramientaCodeX20})
      }
      if (optX === 21){
        this.cReporteTurnoDia18a19_01_2.current.value = this.cReporteTurnoDia18a19_01.current.value;
        this.cReporteTurnoDia18a19_02_2.current.value = this.cReporteTurnoDia18a19_02.current.value;
        this.cReporteTurnoDia18a19_03_2.current.value = this.cReporteTurnoDia18a19_03.current.value;
        this.cReporteTurnoDia18a19_04_2.current.value = this.cReporteTurnoDia18a19_04.current.value;
        this.cReporteTurnoDia18a19_05_2.current.value = this.cReporteTurnoDia18a19_05.current.value;
        this.cReporteTurnoDia18a19_06_2.current.value = this.cReporteTurnoDia18a19_06.current.value;
        this.cReporteTurnoDia18a19_07_2.current.value = this.cReporteTurnoDia18a19_07.current.value;
        this.cReporteTurnoDia18a19_08_2.current.value = this.cReporteTurnoDia18a19_08.current.value;
        this.cReporteTurnoDia18a19_09_2.current.value = this.cReporteTurnoDia18a19_09.current.value;
        this.cReporteTurnoDia18a19_10_2.current.value = this.cReporteTurnoDia18a19_10.current.value;
        this.cReporteTurnoDia18a19_11_2.current.value = this.cReporteTurnoDia18a19_11.current.value;
        this.cReporteTurnoDia18a19_12_2.current.value = this.cReporteTurnoDia18a19_12.current.value;
        this.cReporteTurnoDia18a19_13_2.current.value = this.cReporteTurnoDia18a19_13.current.value;
        this.setState({linea22X:true})
        this.setState({sobrecargaX22:this.state.sobrecargaX21,
                       opcionPerforacionX22:this.state.opcionPerforacionX21,
                       herramientaCodeX22:this.state.herramientaCodeX21})
      }
      if (optX === 22){
        this.cReporteTurnoDia19a20_01.current.value = this.cReporteTurnoDia18a19_01_2.current.value;
        this.cReporteTurnoDia19a20_02.current.value = this.cReporteTurnoDia18a19_02_2.current.value;
        this.cReporteTurnoDia19a20_03.current.value = this.cReporteTurnoDia18a19_03_2.current.value;
        this.cReporteTurnoDia19a20_04.current.value = this.cReporteTurnoDia18a19_04_2.current.value;
        this.cReporteTurnoDia19a20_05.current.value = this.cReporteTurnoDia18a19_05_2.current.value;
        this.cReporteTurnoDia19a20_06.current.value = this.cReporteTurnoDia18a19_06_2.current.value;
        this.cReporteTurnoDia19a20_07.current.value = this.cReporteTurnoDia18a19_07_2.current.value;
        this.cReporteTurnoDia19a20_08.current.value = this.cReporteTurnoDia18a19_08_2.current.value;
        this.cReporteTurnoDia19a20_09.current.value = this.cReporteTurnoDia18a19_09_2.current.value;
        this.cReporteTurnoDia19a20_10.current.value = this.cReporteTurnoDia18a19_10_2.current.value;
        this.cReporteTurnoDia19a20_11.current.value = this.cReporteTurnoDia18a19_11_2.current.value;
        this.cReporteTurnoDia19a20_12.current.value = this.cReporteTurnoDia18a19_12_2.current.value;
        this.cReporteTurnoDia19a20_13.current.value = this.cReporteTurnoDia18a19_13_2.current.value;
        this.setState({linea23X:true})
        this.setState({sobrecargaX23:this.state.sobrecargaX22,
                       opcionPerforacionX23:this.state.opcionPerforacionX22,
                       herramientaCodeX23:this.state.herramientaCodeX22})
      }
      if (optX === 23){
        this.cReporteTurnoDia19a20_01_2.current.value = this.cReporteTurnoDia19a20_01.current.value;
        this.cReporteTurnoDia19a20_02_2.current.value = this.cReporteTurnoDia19a20_02.current.value;
        this.cReporteTurnoDia19a20_03_2.current.value = this.cReporteTurnoDia19a20_03.current.value;
        this.cReporteTurnoDia19a20_04_2.current.value = this.cReporteTurnoDia19a20_04.current.value;
        this.cReporteTurnoDia19a20_05_2.current.value = this.cReporteTurnoDia19a20_05.current.value;
        this.cReporteTurnoDia19a20_06_2.current.value = this.cReporteTurnoDia19a20_06.current.value;
        this.cReporteTurnoDia19a20_07_2.current.value = this.cReporteTurnoDia19a20_07.current.value;
        this.cReporteTurnoDia19a20_08_2.current.value = this.cReporteTurnoDia19a20_08.current.value;
        this.cReporteTurnoDia19a20_09_2.current.value = this.cReporteTurnoDia19a20_09.current.value;
        this.cReporteTurnoDia19a20_10_2.current.value = this.cReporteTurnoDia19a20_10.current.value;
        this.cReporteTurnoDia19a20_11_2.current.value = this.cReporteTurnoDia19a20_11.current.value;
        this.cReporteTurnoDia19a20_12_2.current.value = this.cReporteTurnoDia19a20_12.current.value;
        this.cReporteTurnoDia19a20_13_2.current.value = this.cReporteTurnoDia19a20_13.current.value;
        this.setState({linea24X:true})
        this.setState({sobrecargaX24:this.state.sobrecargaX23,
                       opcionPerforacionX24:this.state.opcionPerforacionX23,
                       herramientaCodeX24:this.state.herramientaCodeX23})
      }
      if (optX === 25){
        this.cReporteTurnoNoche20a21_01_2.current.value = this.cReporteTurnoNoche20a21_01.current.value;
        this.cReporteTurnoNoche20a21_02_2.current.value = this.cReporteTurnoNoche20a21_02.current.value;
        this.cReporteTurnoNoche20a21_03_2.current.value = this.cReporteTurnoNoche20a21_03.current.value;
        this.cReporteTurnoNoche20a21_04_2.current.value = this.cReporteTurnoNoche20a21_04.current.value;
        this.cReporteTurnoNoche20a21_05_2.current.value = this.cReporteTurnoNoche20a21_05.current.value;
        this.cReporteTurnoNoche20a21_06_2.current.value = this.cReporteTurnoNoche20a21_06.current.value;
        this.cReporteTurnoNoche20a21_07_2.current.value = this.cReporteTurnoNoche20a21_07.current.value;
        this.cReporteTurnoNoche20a21_08_2.current.value = this.cReporteTurnoNoche20a21_08.current.value;
        this.cReporteTurnoNoche20a21_09_2.current.value = this.cReporteTurnoNoche20a21_09.current.value;
        this.cReporteTurnoNoche20a21_10_2.current.value = this.cReporteTurnoNoche20a21_10.current.value;
        this.cReporteTurnoNoche20a21_11_2.current.value = this.cReporteTurnoNoche20a21_11.current.value;
        this.cReporteTurnoNoche20a21_12_2.current.value = this.cReporteTurnoNoche20a21_12.current.value;
        this.cReporteTurnoNoche20a21_13_2.current.value = this.cReporteTurnoNoche20a21_13.current.value;
        this.setState({linea26X:true})
        this.setState({sobrecargaX26:this.state.sobrecargaX25,
                       opcionPerforacionX26:this.state.opcionPerforacionX25,
                       herramientaCodeX26:this.state.herramientaCodeX25})
      }
      if (optX === 26){
        this.cReporteTurnoNoche21a22_01.current.value = this.cReporteTurnoNoche20a21_01_2.current.value;
        this.cReporteTurnoNoche21a22_02.current.value = this.cReporteTurnoNoche20a21_02_2.current.value;
        this.cReporteTurnoNoche21a22_03.current.value = this.cReporteTurnoNoche20a21_03_2.current.value;
        this.cReporteTurnoNoche21a22_04.current.value = this.cReporteTurnoNoche20a21_04_2.current.value;
        this.cReporteTurnoNoche21a22_05.current.value = this.cReporteTurnoNoche20a21_05_2.current.value;
        this.cReporteTurnoNoche21a22_06.current.value = this.cReporteTurnoNoche20a21_06_2.current.value;
        this.cReporteTurnoNoche21a22_07.current.value = this.cReporteTurnoNoche20a21_07_2.current.value;
        this.cReporteTurnoNoche21a22_08.current.value = this.cReporteTurnoNoche20a21_08_2.current.value;
        this.cReporteTurnoNoche21a22_09.current.value = this.cReporteTurnoNoche20a21_09_2.current.value;
        this.cReporteTurnoNoche21a22_10.current.value = this.cReporteTurnoNoche20a21_10_2.current.value;
        this.cReporteTurnoNoche21a22_11.current.value = this.cReporteTurnoNoche20a21_11_2.current.value;
        this.cReporteTurnoNoche21a22_12.current.value = this.cReporteTurnoNoche20a21_12_2.current.value;
        this.cReporteTurnoNoche21a22_13.current.value = this.cReporteTurnoNoche20a21_13_2.current.value;
        this.setState({linea27X:true})
        this.setState({sobrecargaX27:this.state.sobrecargaX26,
                       opcionPerforacionX27:this.state.opcionPerforacionX26,
                       herramientaCodeX27:this.state.herramientaCodeX26})
      }
      if (optX === 27){
        this.cReporteTurnoNoche21a22_01_2.current.value = this.cReporteTurnoNoche21a22_01.current.value;
        this.cReporteTurnoNoche21a22_02_2.current.value = this.cReporteTurnoNoche21a22_02.current.value;
        this.cReporteTurnoNoche21a22_03_2.current.value = this.cReporteTurnoNoche21a22_03.current.value;
        this.cReporteTurnoNoche21a22_04_2.current.value = this.cReporteTurnoNoche21a22_04.current.value;
        this.cReporteTurnoNoche21a22_05_2.current.value = this.cReporteTurnoNoche21a22_05.current.value;
        this.cReporteTurnoNoche21a22_06_2.current.value = this.cReporteTurnoNoche21a22_06.current.value;
        this.cReporteTurnoNoche21a22_07_2.current.value = this.cReporteTurnoNoche21a22_07.current.value;
        this.cReporteTurnoNoche21a22_08_2.current.value = this.cReporteTurnoNoche21a22_08.current.value;
        this.cReporteTurnoNoche21a22_09_2.current.value = this.cReporteTurnoNoche21a22_09.current.value;
        this.cReporteTurnoNoche21a22_10_2.current.value = this.cReporteTurnoNoche21a22_10.current.value;
        this.cReporteTurnoNoche21a22_11_2.current.value = this.cReporteTurnoNoche21a22_11.current.value;
        this.cReporteTurnoNoche21a22_12_2.current.value = this.cReporteTurnoNoche21a22_12.current.value;
        this.cReporteTurnoNoche21a22_13_2.current.value = this.cReporteTurnoNoche21a22_13.current.value;
        this.setState({linea28X:true})
        this.setState({sobrecargaX28:this.state.sobrecargaX27,
                       opcionPerforacionX28:this.state.opcionPerforacionX27,
                       herramientaCodeX28:this.state.herramientaCodeX27})
      }
      if (optX === 28){
        this.cReporteTurnoNoche22a23_01.current.value = this.cReporteTurnoNoche21a22_01_2.current.value;
        this.cReporteTurnoNoche22a23_02.current.value = this.cReporteTurnoNoche21a22_02_2.current.value;
        this.cReporteTurnoNoche22a23_03.current.value = this.cReporteTurnoNoche21a22_03_2.current.value;
        this.cReporteTurnoNoche22a23_04.current.value = this.cReporteTurnoNoche21a22_04_2.current.value;
        this.cReporteTurnoNoche22a23_05.current.value = this.cReporteTurnoNoche21a22_05_2.current.value;
        this.cReporteTurnoNoche22a23_06.current.value = this.cReporteTurnoNoche21a22_06_2.current.value;
        this.cReporteTurnoNoche22a23_07.current.value = this.cReporteTurnoNoche21a22_07_2.current.value;
        this.cReporteTurnoNoche22a23_08.current.value = this.cReporteTurnoNoche21a22_08_2.current.value;
        this.cReporteTurnoNoche22a23_09.current.value = this.cReporteTurnoNoche21a22_09_2.current.value;
        this.cReporteTurnoNoche22a23_10.current.value = this.cReporteTurnoNoche21a22_10_2.current.value;
        this.cReporteTurnoNoche22a23_11.current.value = this.cReporteTurnoNoche21a22_11_2.current.value;
        this.cReporteTurnoNoche22a23_12.current.value = this.cReporteTurnoNoche21a22_12_2.current.value;
        this.cReporteTurnoNoche22a23_13.current.value = this.cReporteTurnoNoche21a22_13_2.current.value;
        this.setState({linea29X:true})
        this.setState({sobrecargaX29:this.state.sobrecargaX28,
                       opcionPerforacionX29:this.state.opcionPerforacionX28,
                       herramientaCodeX29:this.state.herramientaCodeX28})
      }
      if (optX === 29){
        this.cReporteTurnoNoche22a23_01_2.current.value = this.cReporteTurnoNoche22a23_01.current.value;
        this.cReporteTurnoNoche22a23_02_2.current.value = this.cReporteTurnoNoche22a23_02.current.value;
        this.cReporteTurnoNoche22a23_03_2.current.value = this.cReporteTurnoNoche22a23_03.current.value;
        this.cReporteTurnoNoche22a23_04_2.current.value = this.cReporteTurnoNoche22a23_04.current.value;
        this.cReporteTurnoNoche22a23_05_2.current.value = this.cReporteTurnoNoche22a23_05.current.value;
        this.cReporteTurnoNoche22a23_06_2.current.value = this.cReporteTurnoNoche22a23_06.current.value;
        this.cReporteTurnoNoche22a23_07_2.current.value = this.cReporteTurnoNoche22a23_07.current.value;
        this.cReporteTurnoNoche22a23_08_2.current.value = this.cReporteTurnoNoche22a23_08.current.value;
        this.cReporteTurnoNoche22a23_09_2.current.value = this.cReporteTurnoNoche22a23_09.current.value;
        this.cReporteTurnoNoche22a23_10_2.current.value = this.cReporteTurnoNoche22a23_10.current.value;
        this.cReporteTurnoNoche22a23_11_2.current.value = this.cReporteTurnoNoche22a23_11.current.value;
        this.cReporteTurnoNoche22a23_12_2.current.value = this.cReporteTurnoNoche22a23_12.current.value;
        this.cReporteTurnoNoche22a23_13_2.current.value = this.cReporteTurnoNoche22a23_13.current.value;
        this.setState({linea30X:true})
        this.setState({sobrecargaX30:this.state.sobrecargaX29,
                       opcionPerforacionX30:this.state.opcionPerforacionX29,
                       herramientaCodeX30:this.state.herramientaCodeX29})
      }
      if (optX === 30){
        this.cReporteTurnoNoche23a24_01.current.value = this.cReporteTurnoNoche22a23_01_2.current.value;
        this.cReporteTurnoNoche23a24_02.current.value = this.cReporteTurnoNoche22a23_02_2.current.value;
        this.cReporteTurnoNoche23a24_03.current.value = this.cReporteTurnoNoche22a23_03_2.current.value;
        this.cReporteTurnoNoche23a24_04.current.value = this.cReporteTurnoNoche22a23_04_2.current.value;
        this.cReporteTurnoNoche23a24_05.current.value = this.cReporteTurnoNoche22a23_05_2.current.value;
        this.cReporteTurnoNoche23a24_06.current.value = this.cReporteTurnoNoche22a23_06_2.current.value;
        this.cReporteTurnoNoche23a24_07.current.value = this.cReporteTurnoNoche22a23_07_2.current.value;
        this.cReporteTurnoNoche23a24_08.current.value = this.cReporteTurnoNoche22a23_08_2.current.value;
        this.cReporteTurnoNoche23a24_09.current.value = this.cReporteTurnoNoche22a23_09_2.current.value;
        this.cReporteTurnoNoche23a24_10.current.value = this.cReporteTurnoNoche22a23_10_2.current.value;
        this.cReporteTurnoNoche23a24_11.current.value = this.cReporteTurnoNoche22a23_11_2.current.value;
        this.cReporteTurnoNoche23a24_12.current.value = this.cReporteTurnoNoche22a23_12_2.current.value;
        this.cReporteTurnoNoche23a24_13.current.value = this.cReporteTurnoNoche22a23_13_2.current.value;
        this.setState({linea31X:true})
        this.setState({sobrecargaX31:this.state.sobrecargaX30,
                       opcionPerforacionX31:this.state.opcionPerforacionX30,
                       herramientaCodeX31:this.state.herramientaCodeX30})
      }
      if (optX === 31){
        this.cReporteTurnoNoche23a24_01_2.current.value = this.cReporteTurnoNoche23a24_01.current.value;
        this.cReporteTurnoNoche23a24_02_2.current.value = this.cReporteTurnoNoche23a24_02.current.value;
        this.cReporteTurnoNoche23a24_03_2.current.value = this.cReporteTurnoNoche23a24_03.current.value;
        this.cReporteTurnoNoche23a24_04_2.current.value = this.cReporteTurnoNoche23a24_04.current.value;
        this.cReporteTurnoNoche23a24_05_2.current.value = this.cReporteTurnoNoche23a24_05.current.value;
        this.cReporteTurnoNoche23a24_06_2.current.value = this.cReporteTurnoNoche23a24_06.current.value;
        this.cReporteTurnoNoche23a24_07_2.current.value = this.cReporteTurnoNoche23a24_07.current.value;
        this.cReporteTurnoNoche23a24_08_2.current.value = this.cReporteTurnoNoche23a24_08.current.value;
        this.cReporteTurnoNoche23a24_09_2.current.value = this.cReporteTurnoNoche23a24_09.current.value;
        this.cReporteTurnoNoche23a24_10_2.current.value = this.cReporteTurnoNoche23a24_10.current.value;
        this.cReporteTurnoNoche23a24_11_2.current.value = this.cReporteTurnoNoche23a24_11.current.value;
        this.cReporteTurnoNoche23a24_12_2.current.value = this.cReporteTurnoNoche23a24_12.current.value;
        this.cReporteTurnoNoche23a24_13_2.current.value = this.cReporteTurnoNoche23a24_13.current.value;
        this.setState({linea32X:true})
        this.setState({sobrecargaX32:this.state.sobrecargaX31,
                       opcionPerforacionX32:this.state.opcionPerforacionX31,
                       herramientaCodeX32:this.state.herramientaCodeX31})
      }
      if (optX === 32){
        this.cReporteTurnoNoche24a01_01.current.value = this.cReporteTurnoNoche23a24_01_2.current.value;
        this.cReporteTurnoNoche24a01_02.current.value = this.cReporteTurnoNoche23a24_02_2.current.value;
        this.cReporteTurnoNoche24a01_03.current.value = this.cReporteTurnoNoche23a24_03_2.current.value;
        this.cReporteTurnoNoche24a01_04.current.value = this.cReporteTurnoNoche23a24_04_2.current.value;
        this.cReporteTurnoNoche24a01_05.current.value = this.cReporteTurnoNoche23a24_05_2.current.value;
        this.cReporteTurnoNoche24a01_06.current.value = this.cReporteTurnoNoche23a24_06_2.current.value;
        this.cReporteTurnoNoche24a01_07.current.value = this.cReporteTurnoNoche23a24_07_2.current.value;
        this.cReporteTurnoNoche24a01_08.current.value = this.cReporteTurnoNoche23a24_08_2.current.value;
        this.cReporteTurnoNoche24a01_09.current.value = this.cReporteTurnoNoche23a24_09_2.current.value;
        this.cReporteTurnoNoche24a01_10.current.value = this.cReporteTurnoNoche23a24_10_2.current.value;
        this.cReporteTurnoNoche24a01_11.current.value = this.cReporteTurnoNoche23a24_11_2.current.value;
        this.cReporteTurnoNoche24a01_12.current.value = this.cReporteTurnoNoche23a24_12_2.current.value;
        this.cReporteTurnoNoche24a01_13.current.value = this.cReporteTurnoNoche23a24_13_2.current.value;
        this.setState({linea33X:true})
        this.setState({sobrecargaX33:this.state.sobrecargaX32,
                       opcionPerforacionX33:this.state.opcionPerforacionX32,
                       herramientaCodeX33:this.state.herramientaCodeX32})
      }
      if (optX === 33){
        this.cReporteTurnoNoche24a01_01_2.current.value = this.cReporteTurnoNoche24a01_01.current.value;
        this.cReporteTurnoNoche24a01_02_2.current.value = this.cReporteTurnoNoche24a01_02.current.value;
        this.cReporteTurnoNoche24a01_03_2.current.value = this.cReporteTurnoNoche24a01_03.current.value;
        this.cReporteTurnoNoche24a01_04_2.current.value = this.cReporteTurnoNoche24a01_04.current.value;
        this.cReporteTurnoNoche24a01_05_2.current.value = this.cReporteTurnoNoche24a01_05.current.value;
        this.cReporteTurnoNoche24a01_06_2.current.value = this.cReporteTurnoNoche24a01_06.current.value;
        this.cReporteTurnoNoche24a01_07_2.current.value = this.cReporteTurnoNoche24a01_07.current.value;
        this.cReporteTurnoNoche24a01_08_2.current.value = this.cReporteTurnoNoche24a01_08.current.value;
        this.cReporteTurnoNoche24a01_09_2.current.value = this.cReporteTurnoNoche24a01_09.current.value;
        this.cReporteTurnoNoche24a01_10_2.current.value = this.cReporteTurnoNoche24a01_10.current.value;
        this.cReporteTurnoNoche24a01_11_2.current.value = this.cReporteTurnoNoche24a01_11.current.value;
        this.cReporteTurnoNoche24a01_12_2.current.value = this.cReporteTurnoNoche24a01_12.current.value;
        this.cReporteTurnoNoche24a01_13_2.current.value = this.cReporteTurnoNoche24a01_13.current.value;
        this.setState({linea34X:true})
        this.setState({sobrecargaX34:this.state.sobrecargaX33,
                       opcionPerforacionX34:this.state.opcionPerforacionX33,
                       herramientaCodeX34:this.state.herramientaCodeX33})
      }
      if (optX === 34){
        this.cReporteTurnoNoche01a02_01.current.value = this.cReporteTurnoNoche24a01_01_2.current.value;
        this.cReporteTurnoNoche01a02_02.current.value = this.cReporteTurnoNoche24a01_02_2.current.value;
        this.cReporteTurnoNoche01a02_03.current.value = this.cReporteTurnoNoche24a01_03_2.current.value;
        this.cReporteTurnoNoche01a02_04.current.value = this.cReporteTurnoNoche24a01_04_2.current.value;
        this.cReporteTurnoNoche01a02_05.current.value = this.cReporteTurnoNoche24a01_05_2.current.value;
        this.cReporteTurnoNoche01a02_06.current.value = this.cReporteTurnoNoche24a01_06_2.current.value;
        this.cReporteTurnoNoche01a02_07.current.value = this.cReporteTurnoNoche24a01_07_2.current.value;
        this.cReporteTurnoNoche01a02_08.current.value = this.cReporteTurnoNoche24a01_08_2.current.value;
        this.cReporteTurnoNoche01a02_09.current.value = this.cReporteTurnoNoche24a01_09_2.current.value;
        this.cReporteTurnoNoche01a02_10.current.value = this.cReporteTurnoNoche24a01_10_2.current.value;
        this.cReporteTurnoNoche01a02_11.current.value = this.cReporteTurnoNoche24a01_11_2.current.value;
        this.cReporteTurnoNoche01a02_12.current.value = this.cReporteTurnoNoche24a01_12_2.current.value;
        this.cReporteTurnoNoche01a02_13.current.value = this.cReporteTurnoNoche24a01_13_2.current.value;
        this.setState({linea35X:true})
        this.setState({sobrecargaX35:this.state.sobrecargaX34,
                       opcionPerforacionX35:this.state.opcionPerforacionX34,
                       herramientaCodeX35:this.state.herramientaCodeX34})
      }
      if (optX === 35){
        this.cReporteTurnoNoche01a02_01_2.current.value = this.cReporteTurnoNoche01a02_01.current.value;
        this.cReporteTurnoNoche01a02_02_2.current.value = this.cReporteTurnoNoche01a02_02.current.value;
        this.cReporteTurnoNoche01a02_03_2.current.value = this.cReporteTurnoNoche01a02_03.current.value;
        this.cReporteTurnoNoche01a02_04_2.current.value = this.cReporteTurnoNoche01a02_04.current.value;
        this.cReporteTurnoNoche01a02_05_2.current.value = this.cReporteTurnoNoche01a02_05.current.value;
        this.cReporteTurnoNoche01a02_06_2.current.value = this.cReporteTurnoNoche01a02_06.current.value;
        this.cReporteTurnoNoche01a02_07_2.current.value = this.cReporteTurnoNoche01a02_07.current.value;
        this.cReporteTurnoNoche01a02_08_2.current.value = this.cReporteTurnoNoche01a02_08.current.value;
        this.cReporteTurnoNoche01a02_09_2.current.value = this.cReporteTurnoNoche01a02_09.current.value;
        this.cReporteTurnoNoche01a02_10_2.current.value = this.cReporteTurnoNoche01a02_10.current.value;
        this.cReporteTurnoNoche01a02_11_2.current.value = this.cReporteTurnoNoche01a02_11.current.value;
        this.cReporteTurnoNoche01a02_12_2.current.value = this.cReporteTurnoNoche01a02_12.current.value;
        this.cReporteTurnoNoche01a02_13_2.current.value = this.cReporteTurnoNoche01a02_13.current.value;
        this.setState({linea36X:true})
        this.setState({sobrecargaX36:this.state.sobrecargaX35,
                       opcionPerforacionX36:this.state.opcionPerforacionX35,
                       herramientaCodeX36:this.state.herramientaCodeX35})
      }
      if (optX === 36){
        this.cReporteTurnoNoche02a03_01.current.value = this.cReporteTurnoNoche01a02_01_2.current.value;
        this.cReporteTurnoNoche02a03_02.current.value = this.cReporteTurnoNoche01a02_02_2.current.value;
        this.cReporteTurnoNoche02a03_03.current.value = this.cReporteTurnoNoche01a02_03_2.current.value;
        this.cReporteTurnoNoche02a03_04.current.value = this.cReporteTurnoNoche01a02_04_2.current.value;
        this.cReporteTurnoNoche02a03_05.current.value = this.cReporteTurnoNoche01a02_05_2.current.value;
        this.cReporteTurnoNoche02a03_06.current.value = this.cReporteTurnoNoche01a02_06_2.current.value;
        this.cReporteTurnoNoche02a03_07.current.value = this.cReporteTurnoNoche01a02_07_2.current.value;
        this.cReporteTurnoNoche02a03_08.current.value = this.cReporteTurnoNoche01a02_08_2.current.value;
        this.cReporteTurnoNoche02a03_09.current.value = this.cReporteTurnoNoche01a02_09_2.current.value;
        this.cReporteTurnoNoche02a03_10.current.value = this.cReporteTurnoNoche01a02_10_2.current.value;
        this.cReporteTurnoNoche02a03_11.current.value = this.cReporteTurnoNoche01a02_11_2.current.value;
        this.cReporteTurnoNoche02a03_12.current.value = this.cReporteTurnoNoche01a02_12_2.current.value;
        this.cReporteTurnoNoche02a03_13.current.value = this.cReporteTurnoNoche01a02_13_2.current.value;
        this.setState({linea37X:true})
        this.setState({sobrecargaX37:this.state.sobrecargaX36,
                       opcionPerforacionX37:this.state.opcionPerforacionX36,
                       herramientaCodeX37:this.state.herramientaCodeX36})
      }
      if (optX === 37){
        this.cReporteTurnoNoche02a03_01_2.current.value = this.cReporteTurnoNoche02a03_01.current.value;
        this.cReporteTurnoNoche02a03_02_2.current.value = this.cReporteTurnoNoche02a03_02.current.value;
        this.cReporteTurnoNoche02a03_03_2.current.value = this.cReporteTurnoNoche02a03_03.current.value;
        this.cReporteTurnoNoche02a03_04_2.current.value = this.cReporteTurnoNoche02a03_04.current.value;
        this.cReporteTurnoNoche02a03_05_2.current.value = this.cReporteTurnoNoche02a03_05.current.value;
        this.cReporteTurnoNoche02a03_06_2.current.value = this.cReporteTurnoNoche02a03_06.current.value;
        this.cReporteTurnoNoche02a03_07_2.current.value = this.cReporteTurnoNoche02a03_07.current.value;
        this.cReporteTurnoNoche02a03_08_2.current.value = this.cReporteTurnoNoche02a03_08.current.value;
        this.cReporteTurnoNoche02a03_09_2.current.value = this.cReporteTurnoNoche02a03_09.current.value;
        this.cReporteTurnoNoche02a03_10_2.current.value = this.cReporteTurnoNoche02a03_10.current.value;
        this.cReporteTurnoNoche02a03_11_2.current.value = this.cReporteTurnoNoche02a03_11.current.value;
        this.cReporteTurnoNoche02a03_12_2.current.value = this.cReporteTurnoNoche02a03_12.current.value;
        this.cReporteTurnoNoche02a03_13_2.current.value = this.cReporteTurnoNoche02a03_13.current.value;
        this.setState({linea38X:true})
        this.setState({sobrecargaX38:this.state.sobrecargaX37,
                       opcionPerforacionX38:this.state.opcionPerforacionX37,
                       herramientaCodeX38:this.state.herramientaCodeX37})
      }
      if (optX === 38){
        this.cReporteTurnoNoche03a04_01.current.value = this.cReporteTurnoNoche02a03_01_2.current.value;
        this.cReporteTurnoNoche03a04_02.current.value = this.cReporteTurnoNoche02a03_02_2.current.value;
        this.cReporteTurnoNoche03a04_03.current.value = this.cReporteTurnoNoche02a03_03_2.current.value;
        this.cReporteTurnoNoche03a04_04.current.value = this.cReporteTurnoNoche02a03_04_2.current.value;
        this.cReporteTurnoNoche03a04_05.current.value = this.cReporteTurnoNoche02a03_05_2.current.value;
        this.cReporteTurnoNoche03a04_06.current.value = this.cReporteTurnoNoche02a03_06_2.current.value;
        this.cReporteTurnoNoche03a04_07.current.value = this.cReporteTurnoNoche02a03_07_2.current.value;
        this.cReporteTurnoNoche03a04_08.current.value = this.cReporteTurnoNoche02a03_08_2.current.value;
        this.cReporteTurnoNoche03a04_09.current.value = this.cReporteTurnoNoche02a03_09_2.current.value;
        this.cReporteTurnoNoche03a04_10.current.value = this.cReporteTurnoNoche02a03_10_2.current.value;
        this.cReporteTurnoNoche03a04_11.current.value = this.cReporteTurnoNoche02a03_11_2.current.value;
        this.cReporteTurnoNoche03a04_12.current.value = this.cReporteTurnoNoche02a03_12_2.current.value;
        this.cReporteTurnoNoche03a04_13.current.value = this.cReporteTurnoNoche02a03_13_2.current.value;
        this.setState({linea39X:true})
        this.setState({sobrecargaX39:this.state.sobrecargaX38,
                       opcionPerforacionX39:this.state.opcionPerforacionX38,
                       herramientaCodeX39:this.state.herramientaCodeX38})
      }
      if (optX === 39){
        this.cReporteTurnoNoche03a04_01_2.current.value = this.cReporteTurnoNoche03a04_01.current.value;
        this.cReporteTurnoNoche03a04_02_2.current.value = this.cReporteTurnoNoche03a04_02.current.value;
        this.cReporteTurnoNoche03a04_03_2.current.value = this.cReporteTurnoNoche03a04_03.current.value;
        this.cReporteTurnoNoche03a04_04_2.current.value = this.cReporteTurnoNoche03a04_04.current.value;
        this.cReporteTurnoNoche03a04_05_2.current.value = this.cReporteTurnoNoche03a04_05.current.value;
        this.cReporteTurnoNoche03a04_06_2.current.value = this.cReporteTurnoNoche03a04_06.current.value;
        this.cReporteTurnoNoche03a04_07_2.current.value = this.cReporteTurnoNoche03a04_07.current.value;
        this.cReporteTurnoNoche03a04_08_2.current.value = this.cReporteTurnoNoche03a04_08.current.value;
        this.cReporteTurnoNoche03a04_09_2.current.value = this.cReporteTurnoNoche03a04_09.current.value;
        this.cReporteTurnoNoche03a04_10_2.current.value = this.cReporteTurnoNoche03a04_10.current.value;
        this.cReporteTurnoNoche03a04_11_2.current.value = this.cReporteTurnoNoche03a04_11.current.value;
        this.cReporteTurnoNoche03a04_12_2.current.value = this.cReporteTurnoNoche03a04_12.current.value;
        this.cReporteTurnoNoche03a04_13_2.current.value = this.cReporteTurnoNoche03a04_13.current.value;
        this.setState({linea40X:true})
        this.setState({sobrecargaX40:this.state.sobrecargaX39,
                       opcionPerforacionX40:this.state.opcionPerforacionX39,
                       herramientaCodeX40:this.state.herramientaCodeX39})
      }
      if (optX === 40){
        this.cReporteTurnoNoche04a05_01.current.value = this.cReporteTurnoNoche03a04_01_2.current.value;
        this.cReporteTurnoNoche04a05_02.current.value = this.cReporteTurnoNoche03a04_02_2.current.value;
        this.cReporteTurnoNoche04a05_03.current.value = this.cReporteTurnoNoche03a04_03_2.current.value;
        this.cReporteTurnoNoche04a05_04.current.value = this.cReporteTurnoNoche03a04_04_2.current.value;
        this.cReporteTurnoNoche04a05_05.current.value = this.cReporteTurnoNoche03a04_05_2.current.value;
        this.cReporteTurnoNoche04a05_06.current.value = this.cReporteTurnoNoche03a04_06_2.current.value;
        this.cReporteTurnoNoche04a05_07.current.value = this.cReporteTurnoNoche03a04_07_2.current.value;
        this.cReporteTurnoNoche04a05_08.current.value = this.cReporteTurnoNoche03a04_08_2.current.value;
        this.cReporteTurnoNoche04a05_09.current.value = this.cReporteTurnoNoche03a04_09_2.current.value;
        this.cReporteTurnoNoche04a05_10.current.value = this.cReporteTurnoNoche03a04_10_2.current.value;
        this.cReporteTurnoNoche04a05_11.current.value = this.cReporteTurnoNoche03a04_11_2.current.value;
        this.cReporteTurnoNoche04a05_12.current.value = this.cReporteTurnoNoche03a04_12_2.current.value;
        this.cReporteTurnoNoche04a05_13.current.value = this.cReporteTurnoNoche03a04_13_2.current.value;
        this.setState({linea41X:true})
        this.setState({sobrecargaX41:this.state.sobrecargaX40,
                       opcionPerforacionX41:this.state.opcionPerforacionX40,
                       herramientaCodeX41:this.state.herramientaCodeX40})
      }
      if (optX === 41){
        this.cReporteTurnoNoche04a05_01_2.current.value = this.cReporteTurnoNoche04a05_01.current.value;
        this.cReporteTurnoNoche04a05_02_2.current.value = this.cReporteTurnoNoche04a05_02.current.value;
        this.cReporteTurnoNoche04a05_03_2.current.value = this.cReporteTurnoNoche04a05_03.current.value;
        this.cReporteTurnoNoche04a05_04_2.current.value = this.cReporteTurnoNoche04a05_04.current.value;
        this.cReporteTurnoNoche04a05_05_2.current.value = this.cReporteTurnoNoche04a05_05.current.value;
        this.cReporteTurnoNoche04a05_06_2.current.value = this.cReporteTurnoNoche04a05_06.current.value;
        this.cReporteTurnoNoche04a05_07_2.current.value = this.cReporteTurnoNoche04a05_07.current.value;
        this.cReporteTurnoNoche04a05_08_2.current.value = this.cReporteTurnoNoche04a05_08.current.value;
        this.cReporteTurnoNoche04a05_09_2.current.value = this.cReporteTurnoNoche04a05_09.current.value;
        this.cReporteTurnoNoche04a05_10_2.current.value = this.cReporteTurnoNoche04a05_10.current.value;
        this.cReporteTurnoNoche04a05_11_2.current.value = this.cReporteTurnoNoche04a05_11.current.value;
        this.cReporteTurnoNoche04a05_12_2.current.value = this.cReporteTurnoNoche04a05_12.current.value;
        this.cReporteTurnoNoche04a05_13_2.current.value = this.cReporteTurnoNoche04a05_13.current.value;
        this.setState({linea42X:true})
        this.setState({sobrecargaX42:this.state.sobrecargaX41,
                       opcionPerforacionX42:this.state.opcionPerforacionX41,
                       herramientaCodeX42:this.state.herramientaCodeX41})
      }
      if (optX === 42){
        this.cReporteTurnoNoche05a06_01.current.value = this.cReporteTurnoNoche04a05_01_2.current.value;
        this.cReporteTurnoNoche05a06_02.current.value = this.cReporteTurnoNoche04a05_02_2.current.value;
        this.cReporteTurnoNoche05a06_03.current.value = this.cReporteTurnoNoche04a05_03_2.current.value;
        this.cReporteTurnoNoche05a06_04.current.value = this.cReporteTurnoNoche04a05_04_2.current.value;
        this.cReporteTurnoNoche05a06_05.current.value = this.cReporteTurnoNoche04a05_05_2.current.value;
        this.cReporteTurnoNoche05a06_06.current.value = this.cReporteTurnoNoche04a05_06_2.current.value;
        this.cReporteTurnoNoche05a06_07.current.value = this.cReporteTurnoNoche04a05_07_2.current.value;
        this.cReporteTurnoNoche05a06_08.current.value = this.cReporteTurnoNoche04a05_08_2.current.value;
        this.cReporteTurnoNoche05a06_09.current.value = this.cReporteTurnoNoche04a05_09_2.current.value;
        this.cReporteTurnoNoche05a06_10.current.value = this.cReporteTurnoNoche04a05_10_2.current.value;
        this.cReporteTurnoNoche05a06_11.current.value = this.cReporteTurnoNoche04a05_11_2.current.value;
        this.cReporteTurnoNoche05a06_12.current.value = this.cReporteTurnoNoche04a05_12_2.current.value;
        this.cReporteTurnoNoche05a06_13.current.value = this.cReporteTurnoNoche04a05_13_2.current.value;
        this.setState({linea43X:true})
        this.setState({sobrecargaX42:this.state.sobrecargaX42,
                       opcionPerforacionX43:this.state.opcionPerforacionX42,
                       herramientaCodeX43:this.state.herramientaCodeX42})
      }
      if (optX === 43){
        this.cReporteTurnoNoche05a06_01_2.current.value = this.cReporteTurnoNoche05a06_01.current.value;
        this.cReporteTurnoNoche05a06_02_2.current.value = this.cReporteTurnoNoche05a06_02.current.value;
        this.cReporteTurnoNoche05a06_03_2.current.value = this.cReporteTurnoNoche05a06_03.current.value;
        this.cReporteTurnoNoche05a06_04_2.current.value = this.cReporteTurnoNoche05a06_04.current.value;
        this.cReporteTurnoNoche05a06_05_2.current.value = this.cReporteTurnoNoche05a06_05.current.value;
        this.cReporteTurnoNoche05a06_06_2.current.value = this.cReporteTurnoNoche05a06_06.current.value;
        this.cReporteTurnoNoche05a06_07_2.current.value = this.cReporteTurnoNoche05a06_07.current.value;
        this.cReporteTurnoNoche05a06_08_2.current.value = this.cReporteTurnoNoche05a06_08.current.value;
        this.cReporteTurnoNoche05a06_09_2.current.value = this.cReporteTurnoNoche05a06_09.current.value;
        this.cReporteTurnoNoche05a06_10_2.current.value = this.cReporteTurnoNoche05a06_10.current.value;
        this.cReporteTurnoNoche05a06_11_2.current.value = this.cReporteTurnoNoche05a06_11.current.value;
        this.cReporteTurnoNoche05a06_12_2.current.value = this.cReporteTurnoNoche05a06_12.current.value;
        this.cReporteTurnoNoche05a06_13_2.current.value = this.cReporteTurnoNoche05a06_13.current.value;
        this.setState({linea44X:true})
        this.setState({sobrecargaX44:this.state.sobrecargaX43,
                       opcionPerforacionX44:this.state.opcionPerforacionX43,
                       herramientaCodeX44:this.state.herramientaCodeX43})
      }
      if (optX === 44){
        this.cReporteTurnoNoche06a07_01.current.value = this.cReporteTurnoNoche05a06_01_2.current.value;
        this.cReporteTurnoNoche06a07_02.current.value = this.cReporteTurnoNoche05a06_02_2.current.value;
        this.cReporteTurnoNoche06a07_03.current.value = this.cReporteTurnoNoche05a06_03_2.current.value;
        this.cReporteTurnoNoche06a07_04.current.value = this.cReporteTurnoNoche05a06_04_2.current.value;
        this.cReporteTurnoNoche06a07_05.current.value = this.cReporteTurnoNoche05a06_05_2.current.value;
        this.cReporteTurnoNoche06a07_06.current.value = this.cReporteTurnoNoche05a06_06_2.current.value;
        this.cReporteTurnoNoche06a07_07.current.value = this.cReporteTurnoNoche05a06_07_2.current.value;
        this.cReporteTurnoNoche06a07_08.current.value = this.cReporteTurnoNoche05a06_08_2.current.value;
        this.cReporteTurnoNoche06a07_09.current.value = this.cReporteTurnoNoche05a06_09_2.current.value;
        this.cReporteTurnoNoche06a07_10.current.value = this.cReporteTurnoNoche05a06_10_2.current.value;
        this.cReporteTurnoNoche06a07_11.current.value = this.cReporteTurnoNoche05a06_11_2.current.value;
        this.cReporteTurnoNoche06a07_12.current.value = this.cReporteTurnoNoche05a06_12_2.current.value;
        this.cReporteTurnoNoche06a07_13.current.value = this.cReporteTurnoNoche05a06_13_2.current.value;
        this.setState({linea45X:true})
        this.setState({sobrecargaX45:this.state.sobrecargaX44,
                       opcionPerforacionX45:this.state.opcionPerforacionX44,
                       herramientaCodeX45:this.state.herramientaCodeX44})
      }
      if (optX === 45){
        this.cReporteTurnoNoche06a07_01_2.current.value = this.cReporteTurnoNoche06a07_01.current.value;
        this.cReporteTurnoNoche06a07_02_2.current.value = this.cReporteTurnoNoche06a07_02.current.value;
        this.cReporteTurnoNoche06a07_03_2.current.value = this.cReporteTurnoNoche06a07_03.current.value;
        this.cReporteTurnoNoche06a07_04_2.current.value = this.cReporteTurnoNoche06a07_04.current.value;
        this.cReporteTurnoNoche06a07_05_2.current.value = this.cReporteTurnoNoche06a07_05.current.value;
        this.cReporteTurnoNoche06a07_06_2.current.value = this.cReporteTurnoNoche06a07_06.current.value;
        this.cReporteTurnoNoche06a07_07_2.current.value = this.cReporteTurnoNoche06a07_07.current.value;
        this.cReporteTurnoNoche06a07_08_2.current.value = this.cReporteTurnoNoche06a07_08.current.value;
        this.cReporteTurnoNoche06a07_09_2.current.value = this.cReporteTurnoNoche06a07_09.current.value;
        this.cReporteTurnoNoche06a07_10_2.current.value = this.cReporteTurnoNoche06a07_10.current.value;
        this.cReporteTurnoNoche06a07_11_2.current.value = this.cReporteTurnoNoche06a07_11.current.value;
        this.cReporteTurnoNoche06a07_12_2.current.value = this.cReporteTurnoNoche06a07_12.current.value;
        this.cReporteTurnoNoche06a07_13_2.current.value = this.cReporteTurnoNoche06a07_13.current.value;
        this.setState({linea46X:true})
        this.setState({sobrecargaX46:this.state.sobrecargaX45,
                       opcionPerforacionX46:this.state.opcionPerforacionX45,
                       herramientaCodeX46:this.state.herramientaCodeX45})
      }
      if (optX === 46){
        this.cReporteTurnoNoche07a08_01.current.value = this.cReporteTurnoNoche06a07_01_2.current.value;
        this.cReporteTurnoNoche07a08_02.current.value = this.cReporteTurnoNoche06a07_02_2.current.value;
        this.cReporteTurnoNoche07a08_03.current.value = this.cReporteTurnoNoche06a07_03_2.current.value;
        this.cReporteTurnoNoche07a08_04.current.value = this.cReporteTurnoNoche06a07_04_2.current.value;
        this.cReporteTurnoNoche07a08_05.current.value = this.cReporteTurnoNoche06a07_05_2.current.value;
        this.cReporteTurnoNoche07a08_06.current.value = this.cReporteTurnoNoche06a07_06_2.current.value;
        this.cReporteTurnoNoche07a08_07.current.value = this.cReporteTurnoNoche06a07_07_2.current.value;
        this.cReporteTurnoNoche07a08_08.current.value = this.cReporteTurnoNoche06a07_08_2.current.value;
        this.cReporteTurnoNoche07a08_09.current.value = this.cReporteTurnoNoche06a07_09_2.current.value;
        this.cReporteTurnoNoche07a08_10.current.value = this.cReporteTurnoNoche06a07_10_2.current.value;
        this.cReporteTurnoNoche07a08_11.current.value = this.cReporteTurnoNoche06a07_11_2.current.value;
        this.cReporteTurnoNoche07a08_12.current.value = this.cReporteTurnoNoche06a07_12_2.current.value;
        this.cReporteTurnoNoche07a08_13.current.value = this.cReporteTurnoNoche06a07_13_2.current.value;
        this.setState({linea47X:true})
        this.setState({sobrecargaX47:this.state.sobrecargaX47,
                       opcionPerforacionX47:this.state.opcionPerforacionX46,
                       herramientaCodeX47:this.state.herramientaCodeX46})
      }
      if (optX === 47){
        this.cReporteTurnoNoche07a08_01_2.current.value = this.cReporteTurnoNoche07a08_01.current.value;
        this.cReporteTurnoNoche07a08_02_2.current.value = this.cReporteTurnoNoche07a08_02.current.value;
        this.cReporteTurnoNoche07a08_03_2.current.value = this.cReporteTurnoNoche07a08_03.current.value;
        this.cReporteTurnoNoche07a08_04_2.current.value = this.cReporteTurnoNoche07a08_04.current.value;
        this.cReporteTurnoNoche07a08_05_2.current.value = this.cReporteTurnoNoche07a08_05.current.value;
        this.cReporteTurnoNoche07a08_06_2.current.value = this.cReporteTurnoNoche07a08_06.current.value;
        this.cReporteTurnoNoche07a08_07_2.current.value = this.cReporteTurnoNoche07a08_07.current.value;
        this.cReporteTurnoNoche07a08_08_2.current.value = this.cReporteTurnoNoche07a08_08.current.value;
        this.cReporteTurnoNoche07a08_09_2.current.value = this.cReporteTurnoNoche07a08_09.current.value;
        this.cReporteTurnoNoche07a08_10_2.current.value = this.cReporteTurnoNoche07a08_10.current.value;
        this.cReporteTurnoNoche07a08_11_2.current.value = this.cReporteTurnoNoche07a08_11.current.value;
        this.cReporteTurnoNoche07a08_12_2.current.value = this.cReporteTurnoNoche07a08_12.current.value;
        this.cReporteTurnoNoche07a08_13_2.current.value = this.cReporteTurnoNoche07a08_13.current.value;
        this.setState({linea48X:true})
        this.setState({sobrecargaX48:this.state.sobrecargaX47,
                       opcionPerforacionX48:this.state.opcionPerforacionX47,
                       herramientaCodeX48:this.state.herramientaCodeX47})
      }
      this.handleMetroInicio(e, 'Duplicar', optX)
      this.handleMetroFin(e, 'Duplicar', optX)
    }

    returnCerrarTurno = () => {
      this.handleDate(null, 'fechaReporte', 1)
    }

    copyText = (e,textoX) => {
      let value=textoX
      return(
        <CopyToClipboard
           text={value}
           onCopy={() => this.setState({copied: true})}>
        </CopyToClipboard>
      )
    }

    copyToClipBoard = async () => {
      let value=this.state.jsonData
      try {
        await navigator.clipboard.writeText(value);
        this.setState({copied: true})
      } catch (err) {
        this.setState({copied: false})
      }
    };


    render() {

        const {csvData,jsonData} = this.state

        const jsonStyle = {
            propertyStyle: { color: 'red' },
            stringStyle: { color: 'green' },
            numberStyle: { color: 'darkorange' }
        }

        let consolidadoFondoX2 = 0
        if (this.state.bFondoPozoExiste) {
          if (parseFloat(this.state.consolidadoFondoOrigenDiaLlenaX) > parseFloat(this.state.consolidadoFondoOrigenNocheLlenaX)){
            consolidadoFondoX2 = parseFloat(this.state.consolidadoFondoOrigenDiaLlenaX)
          }else{
            consolidadoFondoX2 = parseFloat(this.state.consolidadoFondoOrigenNocheLlenaX)
          }
        }else{
          consolidadoFondoX2 = parseFloat(this.state.consolidadoX) + parseFloat(this.state.consolidadoFondoOrigenDiaX) + parseFloat(this.state.consolidadoFondoOrigenNocheX)
        }


        return (
          <div>
            <Container fluid className="main-content-container px-4">
                 <Confirmacion
                     handleDialog={this.handleDialog}
                     dialogMessage={this.state.dialogMessage}
                     dialogOpen={this.state.dialogOpen}
                     dialogTitle={this.state.dialogTitle}
                 />
                 <Confirmacion2
                     handleDialog={this.handleDialog}
                     dialogMessage={this.state.dialogMessage}
                     dialogOpen={this.state.dialogOpenNoHayDiaAnterior}
                     dialogTitle={this.state.dialogTitle}
                 />
                 <Observaciones
                     handleDialog={this.handleDialog}
                     dialogMessage={this.state.dialogMessage}
                     dialogOpen={this.state.dialogOpenObservaciones}
                     dialogTitle={this.state.dialogTitle}
                     handleChangeObservacion={this.handleChangeObservacion}
                     disabledX={this.state.observacionDisabled}
                     cReporteTurnoObservacion={this.state.cReporteTurnoObservacion}
                     opcionObservacion={this.state.opcionObservacion}
                 />
                 <Error
                     handleDialog={this.handleDialog}
                     dialogMessage={this.state.dialogMessageError}
                     dialogOpen={this.state.dialogOpenError}
                     dialogTitle={this.state.dialogTitleError}
                 />
                 <Versiones
                     handleDialog={this.handleDialog}
                     dialogMessage={this.state.dialogMessage}
                     dialogOpen={this.state.dialogOpenVersion}
                     dialogTitle={this.state.dialogTitle}
                     jsonVersionesTurnoDiaX={this.state.jsonVersionesTurnoDiaX}
                     jsonVersionesTurnoNocheX={this.state.jsonVersionesTurnoNocheX}
                     handleDialogDisplay={this.handleDialogDisplay}
                     idTurnoDiaX={this.state.idTurnoDiaX}
                     idTurnoNocheX={this.state.idTurnoNocheX}
                     checkTurnoNocheX={this.state.checkTurnoNocheX}
                     checkTurnoDiaX={this.state.checkTurnoDiaX}
                     handleChangeCheckTurno={this.handleChangeCheckTurno}
                     fecha={this.state.fecha}
                     existeFirmaDiaX={this.state.existeFirmaDiaX}
                     existeFirmaNocheX={this.state.existeFirmaNocheX}
                     tokenMayorDiaX={this.state.tokenMayorDiaX}
                     tokenMayorNocheX={this.state.tokenMayorNocheX}
                 />
                 <GenerarPdf
                     handleDialog={this.handleDialog}
                     dialogMessage={this.state.dialogMessage}
                     dialogOpen={this.state.dialogOpenPdf}
                     dialogTitle={this.state.dialogTitle}
                     handleDialogPdf={this.handleDialogPdf}
                     checkCertificadoX={this.state.checkCertificadoX}
                     checkReporteTurnoX={this.state.checkReporteTurnoX}
                     handleChangeCheckPdf={this.handleChangeCheckPdf}
                     displayPdfX={this.state.displayPdfX}
                     typeContract={this.props.typeContract}
                     numberContract={this.props.numberContract}
                     colorDark={this.props.colorDark}
                     cReporteTurnoNameFirmante1={this.state.cReporteTurnoNameFirmante1}
                     cReporteTurnoIdFirmante1={this.state.cReporteTurnoIdFirmante1}
                     cReporteTurnoPublicKeyFirmante1={this.state.cReporteTurnoPublicKeyFirmante1}
                     cReporteTurnoDateTimeFirmante1={this.state.cReporteTurnoDateTimeFirmante1}
                     cReporteTurnoSignatureFirmante1={this.state.cReporteTurnoSignatureFirmante1}
                     cReporteTurnotrimmedDataURLFirmante1={this.state.cReporteTurnotrimmedDataURLFirmante1}
                     cReporteTurnoNameFirmante2={this.state.cReporteTurnoNameFirmante2}
                     cReporteTurnoIdFirmante2={this.state.cReporteTurnoIdFirmante2}
                     cReporteTurnoPublicKeyFirmante2={this.state.cReporteTurnoPublicKeyFirmante2}
                     cReporteTurnoDateTimeFirmante2={this.state.cReporteTurnoDateTimeFirmante2}
                     cReporteTurnoSignatureFirmante2={this.state.cReporteTurnoSignatureFirmante2}
                     cReporteTurnotrimmedDataURLFirmante2={this.state.cReporteTurnotrimmedDataURLFirmante2}
                     cReporteTurnoNameFirmante3={this.state.cReporteTurnoNameFirmante3}
                     cReporteTurnoIdFirmante3={this.state.cReporteTurnoIdFirmante3}
                     cReporteTurnoPublicKeyFirmante3={this.state.cReporteTurnoPublicKeyFirmante3}
                     cReporteTurnoDateTimeFirmante3={this.state.cReporteTurnoDateTimeFirmante3}
                     cReporteTurnoSignatureFirmante3={this.state.cReporteTurnoSignatureFirmante3}
                     cReporteTurnotrimmedDataURLFirmante3={this.state.cReporteTurnotrimmedDataURLFirmante3}
                     cReporteTurnoNameFirmante4={this.state.cReporteTurnoNameFirmante4}
                     cReporteTurnoIdFirmante4={this.state.cReporteTurnoIdFirmante4}
                     cReporteTurnoPublicKeyFirmante4={this.state.cReporteTurnoPublicKeyFirmante4}
                     cReporteTurnoDateTimeFirmante4={this.state.cReporteTurnoDateTimeFirmante4}
                     cReporteTurnoSignatureFirmante4={this.state.cReporteTurnoSignatureFirmante4}
                     cReporteTurnotrimmedDataURLFirmante4={this.state.cReporteTurnotrimmedDataURLFirmante4}
                     cReporteTurnoNameFirmante5={this.state.cReporteTurnoNameFirmante5}
                     cReporteTurnoIdFirmante5={this.state.cReporteTurnoIdFirmante5}
                     cReporteTurnoPublicKeyFirmante5={this.state.cReporteTurnoPublicKeyFirmante5}
                     cReporteTurnoDateTimeFirmante5={this.state.cReporteTurnoDateTimeFirmante5}
                     cReporteTurnoSignatureFirmante5={this.state.cReporteTurnoSignatureFirmante5}
                     cReporteTurnotrimmedDataURLFirmante5={this.state.cReporteTurnotrimmedDataURLFirmante5}
                     cReporteTurnoNameFirmante6={this.state.cReporteTurnoNameFirmante6}
                     cReporteTurnoIdFirmante6={this.state.cReporteTurnoIdFirmante6}
                     cReporteTurnoPublicKeyFirmante6={this.state.cReporteTurnoPublicKeyFirmante6}
                     cReporteTurnoDateTimeFirmante6={this.state.cReporteTurnoDateTimeFirmante6}
                     cReporteTurnoSignatureFirmante6={this.state.cReporteTurnoSignatureFirmante6}
                     cReporteTurnotrimmedDataURLFirmante6={this.state.cReporteTurnotrimmedDataURLFirmante6}
                     cReporteTurnoNameFirmante7={this.state.cReporteTurnoNameFirmante6}
                     cReporteTurnoIdFirmante7={this.state.cReporteTurnoIdFirmante7}
                     cReporteTurnoPublicKeyFirmante7={this.state.cReporteTurnoPublicKeyFirmante7}
                     cReporteTurnoDateTimeFirmante7={this.state.cReporteTurnoDateTimeFirmante7}
                     cReporteTurnoSignatureFirmante7={this.state.cReporteTurnoSignatureFirmante7}
                     cReporteTurnotrimmedDataURLFirmante7={this.state.cReporteTurnotrimmedDataURLFirmante7}
                     fecha={this.state.fecha}
                     workordercliente={this.state.workordercliente}
                     workordercampaña={this.state.workordercampaña}
                     workordersondaje={this.state.workordersondaje}
                     workordertipoperforacion={this.state.workordertipoperforacion}
                     workorderfase={this.state.workorderfase}
                     workordernumerocontrato={this.state.workordernumerocontrato}
                     workorderdeclinacion={this.state.workorderdeclinacion}
                     pdfReporteTurnoDiaX={this.state.pdfReporteTurnoDiaX}
                     pdfReporteTurnoNocheX={this.state.pdfReporteTurnoNocheX}
                     consolidadoFondoX={this.state.consolidadoFondoX}
                     consolidadoX={this.state.consolidadoX}
                     workorderobservacion={this.state.workorderobservacion}
                     consolidadoFondoX2={consolidadoFondoX2}
                 />
                 <Exportar
                     handleDialog={this.handleDialog}
                     dialogMessage={this.state.dialogMessage}
                     dialogOpen={this.state.dialogOpenExport}
                     dialogTitle={this.state.dialogTitle}
                     checkExportJsonX={this.state.checkExportJsonX}
                     checkExportCsvX={this.state.checkExportCsvX}
                     checkExportCsvVerticalX={this.state.checkExportCsvVerticalX}
                     checkExportCsvHorizontalX={this.state.checkExportCsvHorizontalX}
                     displayExportX={this.state.displayExportX}
                     handleDialogExport={this.handleDialogExport}
                     handleChangeCheckExport={this.handleChangeCheckExport}
                     handleChangeCheckExportCsv={this.handleChangeCheckExportCsv}
                     csvData={csvData}
                     jsonData={jsonData}
                     fecha={this.state.fecha}
                     jsonStyle={jsonStyle}
                     copyToClipBoard={this.copyToClipBoard}
                     copied={this.state.copied}
                     colorDark={this.props.colorDark}
                     typeContract={this.props.typeContract}
                     numberContract={this.props.numberContract}
                 />
                 <EstadoPago
                     handleDialog={this.handleDialog}
                     dialogMessage={this.state.dialogMessage}
                     dialogOpen={this.state.dialogOpenEstadoPago}
                     dialogTitle={this.state.dialogTitle}
                     handleDialogEstadoPago={this.handleDialogEstadoPago}
                     checkEstadoPagoX={this.state.checkEstadoPagoX}
                 />
                 <Form style={{backgroundColor:"#F2EACE"}}>
                   <Card small style={{ backgroundColor:"#F2EACE" }}>
                      <CardBody>
                        <Row form>&nbsp;</Row>
                        <Row form>
                           <Col md="4">
                               {this.props.logoCompanyX ?
                                  <img src={`${this.props.logoCompany}`} style={{maxWidth: "300px"}} />
                               :
                                  <div className="fa fa-info-circle mx-2" style={{fontSize:10, color:'red'}}>
                                     <FormattedMessage id="contract.workorderlogodata" />
                                  </div>
                               }
                           </Col>
                           <Col md="4">
                              <div style={{ color:'#3a3e41', textAlign: 'center', fontSize:40}} >
                                 <FormattedMessage id="contract.listcol34" />
                              </div>
                           </Col>
                           <Col md="1"></Col>
                           <Col md="3">
                              <div style={{ color:'#acacac', textAlign: 'right', fontSize:22}} >
                                 SASSO & PARTNER'S
                              </div>
                           </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card style={{backgroundColor:'#F2EACE'}} small>
                     <ListGroup flush>
                       <ListGroupItem className="p-3" style={{ backgroundColor:'#F2EACE'}} >
                         <Row form>
                           <Col md="0"></Col>
                           <Col md="12">
                              {this.displayFolioFecha()}
                           </Col>
                         </Row>
                         <Row form></Row>
                         <Row form>
                           <Col md="0"></Col>
                           <Col md="12">
                              {this.displayCabecera()}
                           </Col>
                         </Row>
                         <Row form></Row>
                         <Row form>
                           <Col md="0"></Col>
                           <Col md="12">
                              {this.displayConsolidado()}
                           </Col>
                         </Row>
                         <Row form></Row>
                         <Row form>
                           <Col md="0">
                             <div style={{ color:'#3a3e41', textAlign: 'left', fontSize:30}} >
                               DETALLE TURNOS
                             </div>
                           </Col>
                         </Row>
                       </ListGroupItem>
                       <ListGroupItem className="p-3" style={{ backgroundColor:'#F2EACE'}} >
                         <Row form></Row>
                         <Row form>
                           <Col md="0">
                             <div style={{ color:'darkred', textAlign: 'center', fontSize:20}} >
                               TURNO DIA
                             </div>
                           </Col>
                         </Row>
                         <Row form></Row>
                         <Row form>
                           <Col md="0"></Col>
                           <Col md="12">
                              {this.displayCabeceraTurnoDia()}
                           </Col>
                         </Row>
                         <Row form></Row>
                         <Row form>
                           <Col md="0"></Col>
                           <Col md="12">
                              {this.displayDetalleTurnoDia()}
                           </Col>
                         </Row>
                       </ListGroupItem>
                         <ListGroupItem className="p-3" style={{ backgroundColor:'#F2EACE'}} >
                           <Row form>
                             <Col md="0{}"></Col>
                             <Col md="12">
                                {this.displayGuardarTurnoDia()}
                             </Col>
                           </Row>
                         </ListGroupItem>
                       <ListGroupItem className="p-3" style={{ backgroundColor:'#F2EACE'}} >
                         <Row form></Row>
                         <Row form>
                           <Col md="0">
                             <div style={{ color:'darkblue', textAlign: 'center', fontSize:20}} >
                               TURNO NOCHE
                             </div>
                           </Col>
                         </Row>
                         <Row form></Row>
                         <Row form>
                           <Col md="0"></Col>
                           <Col md="12">
                              {this.displayCabeceraTurnoNoche()}
                           </Col>
                         </Row>
                         <Row form></Row>
                         <Row form>
                           <Col md="0"></Col>
                           <Col md="12">
                              {this.displayDetalleTurnoNoche()}
                           </Col>
                         </Row>
                       </ListGroupItem>
                         <ListGroupItem className="p-3" style={{ backgroundColor:'#F2EACE'}} >
                           <Row form>
                             <Col md="0"></Col>
                             <Col md="12">
                                {this.displayGuardarTurnoNoche()}
                             </Col>
                           </Row>
                         </ListGroupItem>
                       <ListGroupItem className="p-3" style={{ backgroundColor:'#F2EACE'}} >
                         <Row form>
                           <Col md="0"></Col>
                           <Col md="12">
                              {this.displayFirma()}
                           </Col>
                         </Row>
                       </ListGroupItem>
                       <ListGroupItem className="p-3" style={{ backgroundColor:'#F2EACE'}} >
                         <Row form>
                           <Col md="0"></Col>
                           <Col md="12">
                              <CloseReporteTurno typeContract={this.props.typeContract}
                                                 numberContract={this.props.numberContract}
                                                 fecha={this.state.fecha}
                                                 authRoleX={this.state.authRoleX}
                                                 cReporteTurnoSignatureFirmante1={this.state.cReporteTurnoSignatureFirmante1}
                                                 cReporteTurnoSignatureFirmante2={this.state.cReporteTurnoSignatureFirmante2}
                                                 cReporteTurnoSignatureFirmante3={this.state.cReporteTurnoSignatureFirmante3}
                                                 cReporteTurnoSignatureFirmante4={this.state.cReporteTurnoSignatureFirmante4}
                                                 cReporteTurnoSignatureFirmante5={this.state.cReporteTurnoSignatureFirmante5}
                                                 cReporteTurnoSignatureFirmante6={this.state.cReporteTurnoSignatureFirmante6}
                                                 cReporteTurnoSignatureFirmante7={this.state.cReporteTurnoSignatureFirmante7}
                                                 cierreReporteTurnoX={this.state.cierreReporteTurnoX}
                                                 returnCerrarTurno={this.returnCerrarTurno}
                                                 exportReporteTurno={this.exportReporteTurno}
                                                 jsonData={this.state.jsonData}
                                                 jsonDataSha256={this.state.jsonDataSha256}
                                                 userTurnoDiaX={this.state.userTurnoDiaX}
                                                 userTurnoNocheX={this.state.userTurnoNocheX}
                                                 active_after_publishedX={this.props.active_after_publishedX}
                              />
                           </Col>
                         </Row>
                       </ListGroupItem>
                     </ListGroup>
                   </Card>
                 </Form>
            </Container>
          </div>
        );
    }
}

export default ReporteTurnoDeliverable;
