//React
import React, { Component } from 'react';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Container, Button, Card, FormSelect } from "shards-react";
import { Table } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";
import { Divider, Typography, TextField, Grid, Dialog,
        DialogContent, DialogActions, DialogTitle, DialogContentText,
        Button as ButtonMaterialUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';

//UUID
import uuid from 'uuid';

//base64
import { Base64 } from 'js-base64';

//loader
import ContractLoader from "../contracts/ContractLoader";

import TituloPagina2 from "../common/TituloPagina2";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { makeRandomPrivKey } from '@stacks/transactions';
import { Storage } from '@stacks/storage'
import { StacksTestnet, StacksMainnet } from '@stacks/network';


//translate
import { FormattedMessage } from 'react-intl';

//Axios
import axios from 'axios';

//Configuracion
import {token} from '../../config.js'

//Number Format
import NumberFormat from 'react-number-format';

//Email
import emailjs from 'emailjs-com'

//Currency
import currencyPayment from '../../utils/currency-payment';

//Configuración
import { SMART_CONTRACT_ADDRESS_MAINNET_STX,
        SMART_CONTRACT_NAME_MAINNET_STX,
        SMART_CONTRACT_ADDRESS_TESTNET_STX,
        SMART_CONTRACT_NAME_TESTNET_STX,
        SMART_CONTRACT_ADDRESS_MAINNET_XCK,
        SMART_CONTRACT_NAME_MAINNET_XCK,
        SMART_CONTRACT_ADDRESS_MAINNET_MXM,
        SMART_CONTRACT_NAME_MAINNET_MXM,
        SMART_CONTRACT_ADDRESS_MAINNET_MIA,
        SMART_CONTRACT_NAME_MAINNET_MIA,
        SMART_CONTRACT_ADDRESS_MAINNET_NYC,
        SMART_CONTRACT_NAME_MAINNET_NYC,
        SMART_CONTRACT_ADDRESS_MAINNET_FRIE,
        SMART_CONTRACT_NAME_MAINNET_FRIE,
        MAINNET_STACKS_API_URL,
        TESTNET_STACKS_API_URL } from '../../config.js'

//Clarity
import {transferRegister, balanceOf, getTransactionRegister, getBalance, service_fee} from "../../clarity/clarityfunctions"

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

function ConfirmacionConfirmRegister(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Container fluid className="px-0">
                    <Card theme="warning" >
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              &nbsp;&nbsp;<div className="fa fa-info"></div>
                            </td>
                            <td>
                               &nbsp;&nbsp;<FormattedMessage id="sign.message" />
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                </Container>
                <Table size="sm" className="text-left" responsive hover striped bordered style={{ color: 'blue', fontSize:11 }}>
                    <tbody>
                      <tr>
                        <th style={{ width: "40%", textAlign:"left", color: 'blue', fontSize:11 }}><FormattedMessage id="useraction.network" /></th>
                        <th style={{ width: "60%", textAlign:"right", color: 'black', fontSize:13 }}>{props.addressX}</th>
                      </tr>
                    </tbody>
                </Table>
                <Table size="sm" className="text-center" responsive hover striped bordered style={props.colorDark ? { color: 'white', fontSize:11 } : { color: 'black', fontSize:11 }}>
                    <thead>
                      <tr style={props.colorDark ? { textAlign:"center", color: 'white', fontSize:15} : {textAlign:"center", color: 'blue', fontSize:15}}>
                        <th><FormattedMessage id="usergroup.concept" /></th>
                        <th>{props.cryptoPactClarityEthereumX}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="payment.crytocurrency" /></td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}>
                          <FormSelect
                              id="cryptocurrency"
                              value={props.c01CryptoCurrency}
                              placeholder=""
                              disabled={false}
                              onChange={props.handleChangeCryptoCurrency}
                          >
                              {props.currencyPaymentX.map((todo, i) => {
                                  return (
                                     <option style={{color:'darkblue'}} key={i} value={`${todo.Ccy} - ${todo.CcyNm}`}>{`${todo.Ccy} - ${todo.CcyNm}`}</option>
                                  )
                              })}
                          </FormSelect>
                        </td>
                      </tr>

                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="pay.previousbalance" /></td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}><NumberFormat value={props.balanceX} displayType={'text'} thousandSeparator={true} /> {props.cryptoPactClarityEthereum2X}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="pay.amounttransfer" /></td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}>{props.transactionX} {props.cryptoPactClarityEthereum2X}</td>
                      </tr>
                    </tbody>
                </Table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', props.cryptoPactClarityEthereum2X, props.saldoX)} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button disabled={props.disableTransferX} onClick={() => props.handleDialog(props.dialogTitle, 'pay', props.cryptoPactClarityEthereum2X, props.saldoX)} theme="success">
                    <FormattedMessage id="alert.pay" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class ContractRegister extends Component {

    constructor(props) {
        super(props);

        this.exitProgram = this.exitProgram.bind(this);
        this.confirmSign = this.confirmSign.bind(this);
        this.actualizarConfirmSign2 = this.actualizarConfirmSign2.bind(this);
        this.actualizarConfirmSign3 = this.actualizarConfirmSign3.bind(this);
        this.actualizarConfirmSign4 = this.actualizarConfirmSign4.bind(this);
        this.actualizarConfirmSign5 = this.actualizarConfirmSign5.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          person: '',
          exist: true,
          pubKey: '',
          publicKey: '',
          opcionContractIoTDevice: '',
          displayContractIoTDevice: false,
          jsonBlockstack: [],
          jsonBlockstackY: [],
          jsonBlockstackZ: [],
          jsonBlockstack2: [],
          jsonBlockstackZ50c: [],
          typeContractIoTDevice: '',
          idx: '',
          url: 'https://gaia.blockstack.org/hub/',
          url2: 'https://core.blockstack.org/v1/users/',
          url3: 'https://core.blockstack.org/v1/names/',
          identityAddress: undefined,
          decentralizedID: undefined,
          language: 'English',
          nameBlockstack: '',
          displayLoader: false,
          goConfirmSign: false,
          dialogOpen: false,
          dialogOpenPaymentForm: false,
          dialogOpenSign: false,
          dialogOpenUnsign: false,
          dialogOpenConfirmSign: false,
          dialogMessage: '',
          dialogTitle: '',
          balanceCta: 0,
          resultTransfer: '',
          stacksAddress: '',
          privateKey: '',
          contractAddressX: '',
          addressKeyX: '',
          networkUrlX: '',
          contractNameX: '',
          contractAddressX2: '',
          serverStacksX: '',
          usernameX: '',
          jsonPaymentForm: '',
          buttonErrorPaymentForm: false,
          getTransaction: '',
          published: false,
          amount: 0.01,
          stacksAddressRecipient: '',
          stacksAddressSender: '',
          registerTransactionRegister: '',
          resultTransfer: '',
          disabledButton: false,
          balanceCtaSTX: null,
          balanceCtaXCK: null,
          balanceCtaMXM: null,
          balanceCtaMIA: null,
          balanceCtaNYC: null,
          balanceCtaFRIE: null,
          cryptoCurrencyX: 'STX - Stacks',
          symbolCriptoCurrency: 'STX',
          priceSTX: 0,
          priceXCK: 0,
          priceMXM: 0,
          priceMIA: 0,
          priceNYC: 0,
          priceFRIE: 0,
          messageRegisterX: '',
        }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''

      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })

        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }

        if (serverStacksX === 'MainNet'){
           networkUrlX = MAINNET_STACKS_API_URL
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
           networkUrlX = TESTNET_STACKS_API_URL
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }
      this.setState({stacksAddressSender: stacksNetX, stacksAddressRecipient: 'SPRK2JVQ988PYT19JSAJNR3K9YZAZGVY04XMC2Z7', networkUrlX}) //cta_platcc_paradigma_id

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val},()=>{
          Promise.all([this.getContract(val),this.listChat2(this.props.typeContract,this.props.numberContract,val)])
             .then(
                (resolve) => {},
                (reject) => {}
             )
        })) ;
      }else{
        this.setState({ username },()=>{
          Promise.all([this.getContract(username),this.listChat2(this.props.typeContract,this.props.numberContract,username)])
             .then(
                (resolve) => {},
                (reject) => {}
             )

        })
      }

      if (languageX === 'English'){this.setState({ messageRegisterX:`${'Document Registered in Blockchain'}`})}
      if (languageX === 'French'){this.setState({ messageRegisterX:`${'Document enregistré dans Blockchain'}`})}
      if (languageX === 'Spanish'){this.setState({ messageRegisterX:`${'Documento Registrado en Blockchain'}`})}
      if (languageX === 'Portuguese'){this.setState({ messageRegisterX:`${'Documento Registrado em Blockchain'}`})}
      if (languageX === 'Swedish'){this.setState({ messageRegisterX:`${'Dokument registrerat i Blockchain'}`})}
      if (languageX === 'Netherlands'){this.setState({ messageRegisterX:`${'Document geregistreerd in Blockchain'}`})}
      if (languageX === 'Russian'){this.setState({ messageRegisterX:`${'Документ зарегистрирован в блокчейне'}`})}
      if (languageX === 'Japanese'){this.setState({ messageRegisterX:`${'ブロックチェーンに登録されたドキュメント'}`})}
      if (languageX === 'Chinese'){this.setState({ messageRegisterX:`${'在區塊鏈中註冊的文件'}`})}
      if (languageX === 'German'){this.setState({ messageRegisterX:`${'Dokument in Blockchain registriert'}`})}
      if (languageX === 'Italian'){this.setState({ messageRegisterX:`${'Documento Registrato in Blockchain'}`})}

    }

    componentDidMount() {
    }

    listChat2 = (typeContract,numberContract,username) => {
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        let options = { decrypt: false, verify: false }
        let jsonBlockstack4 = []
        return new Promise ((resolve1, reject1) =>{
            getFileDB(username, `${typeContract}_${numberContract}_chat3.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.setState({jsonBlockstackZ50c: jsonBlockstack6})
                 resolve1()
               } else {
                 resolve1()
               }
             })
              .catch(error => {
                resolve1()
             })
        })
    }

    sendChat = (message,jsonBlockstackZ50c) =>{
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      const dateNow = new Date()
      const id = uuid()
      const message64 = Base64.encode(message);
      let jsonAttachFile = jsonBlockstackZ50c
      if (jsonAttachFile.length > 0){
          let jsonBlockstack3 = JSON.stringify(jsonAttachFile)
          if (jsonBlockstack3.substring(0,1)==='"') {
             jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
          }
          if (jsonBlockstack3.substring(0,1)==='[') {
             jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
          }
          jsonAttachFile =`${jsonBlockstack3},{"id":"${id}","origin":"${this.state.username}","date":"${dateNow}","message":"${message64}"}`
      }else{
          jsonAttachFile =`{"id":"${id}","origin":"${this.state.username}","date":"${dateNow}","message":"${message64}"}`
      }
      jsonAttachFile =`[${jsonAttachFile}]`
      jsonAttachFile = JSON.parse(jsonAttachFile)
      this.setState({jsonBlockstackZ50c: jsonAttachFile})
      putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_chat3.json`, 'Crosscheck_Register', JSON.stringify(jsonAttachFile), JSON.stringify(options))
        .then(keyUrl => {
        })
    }

    handleChangeCryptoCurrency = (e) => {
      const palabra = e.target.value
      const ArrayIdValue = palabra.split(' - ')
      const symbolCurrencyX = ArrayIdValue[0]
      this.setState({cryptoCurrencyX: e.target.value, symbolCriptoCurrency: symbolCurrencyX})
      if (symbolCurrencyX === 'STX') {this.setState({amount:this.state.priceSTX})}
      if (symbolCurrencyX === 'XCK') {this.setState({amount:this.state.priceXCK})}
      if (symbolCurrencyX === 'MXM') {this.setState({amount:this.state.priceMXM})}
      if (symbolCurrencyX === 'MIA') {this.setState({amount:this.state.priceMIA})}
      if (symbolCurrencyX === 'NYC') {this.setState({amount:this.state.priceNYC})}
      if (symbolCurrencyX === 'FRIE') {this.setState({amount:this.state.priceFRIE})}
    }

    handleDialog2 = (type, action, cryptoX, saldoX) => {
      if (type === 'Register Signatures') {
        if (action === 'pay') {
          const message64 = Base64.encode(this.state.messageRegisterX)
          transferRegister(this.state.stacksAddressSender, this.state.serverStacksX, this.state.stacksAddressRecipient, parseFloat(this.state.amount), this.props.typeContract, this.props.numberContract, this.state.symbolCriptoCurrency, message64, this.state.jsonBlockstackZ50c, uuid(), this.state.username)
           .then(result => {
              this.setState({ dialogOpenConfirmSign: false, resultTransfer: result, disabledButton: true })
              if (result !== undefined ){
                this.registerTransactionRegister(result)
              }
          })
        }
        if (action === 'cancel') {
          this.setState({ dialogOpenConfirmSign: false, disabledButton: false })
        }
      }
    }

    getContract = (username) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      let options = { decrypt: false, verify: false }
      let jsonBlockstack4 = []
      return new Promise ((resolve2, reject2) =>{
        getFileDB(username, `${this.props.typeContract}_${this.props.numberContract}.json`)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               const txId = jsonBlockstack6.registerTxId
               if (txId !== undefined){
                 if (txId !== null && txId !== '' ) {
                   getTransactionRegister(this.state.networkUrlX, txId,username)
                     .then(val => {
                         this.setState({ getTransaction: val })
                     })
                 }
               }
             }
             resolve2()
           })
           .catch(error => {
             resolve2()
          });
      })
    }


    actualizarConfirmSign2(txId){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      let options = { decrypt: false, verify: false }
      let jsonBlockstack4 = []
      getFileDB(this.state.username, `contractlist.json`)
         .then((fileContents) => {
           if(fileContents) {
             this.setState({existListContract: true})
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             jsonBlockstack6.map((todo, i) => {
                if (todo.typeContract === this.props.typeContract && todo.configurationContractNumber === this.props.numberContract) {
                   todo.confirmSign = true
                   todo.registerTxId = txId
                   this.actualizarConfirmSign3(jsonBlockstack6,txId)
                }
             })
           }
         })
        .catch(error => {
        });
    }

    actualizarConfirmSign3(jsonBlockstack4,txId){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      putFileDB(this.state.username, `contractlist.json`, 'Crosscheck_Register', JSON.stringify(jsonBlockstack4), JSON.stringify(options))
        .then(() => {
           this.actualizarConfirmSign4(txId)
        })
        .catch(error => {});
    }

    actualizarConfirmSign4(txId){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      let options = { decrypt: false, verify: false }
      let jsonBlockstack4 = []
      getFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             jsonBlockstack6.statusBlockchain = "Blockchain"
             jsonBlockstack6.statusLifeCycle = "Published"
             this.actualizarConfirmSign5(jsonBlockstack6)
           }else{
             this.exitProgram()
           }
         })
        .catch(error => {
            this.exitProgram()
        });
    }

    actualizarConfirmSign5(jsonBlockstack){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}.json`, 'Crosscheck_Register', JSON.stringify(jsonBlockstack), JSON.stringify(options))
          .then(() => {
            this.sendChat(this.state.messageRegisterX,this.state.jsonBlockstackZ50c)
           //this.setState({published: true})
           this.exitProgram()
        })
        .catch(error => {});
    }

    exitProgram(){
       this.props.returnProps('returnWithFilter',this.props.typeContract,this.props.numberContract);
    }

    confirmSign = () => {
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''
      let contractNameX = ''
      let contractAddressX = ''
      let contractAddressX2 = ''
      let addressKeyX = ''

      let stacksAddressSender = ''

      let networkX = ''
      let contractNameX_XCK = ''
      let contractAddressX_XCK = ''
      let contractAddressX2_XCK = ''
      let contractNameX_MXM = ''
      let contractAddressX_MXM = ''
      let contractAddressX2_MXM = ''
      let contractNameX_MIA = ''
      let contractAddressX_MIA = ''
      let contractAddressX2_MIA = ''
      let contractNameX_NYC = ''
      let contractAddressX_NYC = ''
      let contractAddressX2_NYC = ''
      let contractNameX_FRIE = ''
      let contractAddressX_FRIE = ''
      let contractAddressX2_FRIE = ''

      const privateKey = makeRandomPrivKey();
      this.setState({privateKey})

      const { userSession } = this.state
      const user = userSession.loadUserData()
      this.setState({ user })
      if (!localStorage["serverStacks"]) {
        serverStacksX = 'MainNet'
      }else{
        serverStacksX = localStorage.getItem('serverStacks')
      }
      if (serverStacksX === 'MainNet'){
         networkUrlX = MAINNET_STACKS_API_URL
         contractNameX = SMART_CONTRACT_NAME_MAINNET_STX
         contractAddressX = SMART_CONTRACT_ADDRESS_MAINNET_STX
         contractAddressX2 = SMART_CONTRACT_ADDRESS_MAINNET_STX
         contractNameX_XCK = SMART_CONTRACT_NAME_MAINNET_XCK
         contractAddressX_XCK = SMART_CONTRACT_ADDRESS_MAINNET_XCK
         contractAddressX2_XCK = SMART_CONTRACT_ADDRESS_MAINNET_XCK
         contractNameX_MXM = SMART_CONTRACT_NAME_MAINNET_MXM
         contractAddressX_MXM = SMART_CONTRACT_ADDRESS_MAINNET_MXM
         contractAddressX2_MXM = SMART_CONTRACT_ADDRESS_MAINNET_MXM
         contractNameX_MIA = SMART_CONTRACT_NAME_MAINNET_MIA
         contractAddressX_MIA = SMART_CONTRACT_ADDRESS_MAINNET_MIA
         contractAddressX2_MIA = SMART_CONTRACT_ADDRESS_MAINNET_MIA
         contractNameX_NYC = SMART_CONTRACT_NAME_MAINNET_NYC
         contractAddressX_NYC = SMART_CONTRACT_ADDRESS_MAINNET_NYC
         contractAddressX2_NYC = SMART_CONTRACT_ADDRESS_MAINNET_NYC
         contractNameX_FRIE = SMART_CONTRACT_NAME_MAINNET_FRIE
         contractAddressX_FRIE = SMART_CONTRACT_ADDRESS_MAINNET_FRIE
         contractAddressX2_FRIE = SMART_CONTRACT_ADDRESS_MAINNET_FRIE
         addressKeyX =  user.profile.stxAddress.mainnet
         stacksAddressSender =  user.profile.stxAddress.mainnet
         stacksNetX =  user.profile.stxAddress.mainnet
         networkX = new StacksMainnet()
      }
      if (serverStacksX === 'TestNet'){
         networkUrlX = TESTNET_STACKS_API_URL
         contractNameX = SMART_CONTRACT_NAME_TESTNET_STX
         contractAddressX = SMART_CONTRACT_ADDRESS_TESTNET_STX
         contractAddressX2 = SMART_CONTRACT_ADDRESS_MAINNET_STX
         addressKeyX =  user.profile.stxAddress.testnet
      }
      const  {username} = this.state;

      this.setState({privateKey, contractAddressX, stacksAddressSender, networkUrlX, contractNameX,contractAddressX2, serverStacksX, usernameX: username})

      balanceOf(contractAddressX, stacksAddressSender, networkUrlX, contractNameX,contractAddressX2, serverStacksX, username)
          .then(val => this.setState({balanceCtaSTX: val}))

      getBalance(addressKeyX, contractNameX_XCK,contractAddressX_XCK, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaXCK: val}))

      getBalance(addressKeyX, contractNameX_MXM,contractAddressX_MXM, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaMXM: val}))

      getBalance(addressKeyX, contractNameX_MIA,contractAddressX_MIA, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaMIA: val}))

      getBalance(addressKeyX, contractNameX_NYC,contractAddressX_NYC, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaNYC: val}))

      getBalance(addressKeyX, contractNameX_FRIE,contractAddressX_FRIE, 1000000, serverStacksX)
          .then(val => this.setState({balanceCtaFRIE: val}))

      this.setState({ dialogOpenConfirmSign: true, dialogMessage: '', dialogTitle: 'Register Signatures', })

      this.priceCrypto(stacksAddressSender)
    }

    priceCrypto = (stacksAddressSender) => {
      //service_fee(stacksAddressSender, 'stx', 1000000).then(val => this.setState({priceSTX: val}));
      //service_fee(stacksAddressSender, 'xck', 1000000).then(val => this.setState({priceXCK: val}));
      //service_fee(stacksAddressSender, 'mxm', 1000000).then(val => this.setState({priceMXM: val}));
      //service_fee(stacksAddressSender, 'mia', 1000000).then(val => this.setState({priceMIA: val}));
      //service_fee(stacksAddressSender, 'nyc', 1000000).then(val => this.setState({priceNYC: val}));
      //service_fee(stacksAddressSender, 'frie', 1000000).then(val => this.setState({priceFRIE: val}));
      this.setState({priceMXM: 5})
      this.setState({priceXCK: 0.01})
      this.setState({priceMIA: 206})
      this.setState({priceNYC: 216})
      this.setState({priceFRIE: 0.5})
      this.setState({priceSTX: 0.01})
    }

    render() {
        const {jsonBlockstackY} = this.props
        const {jsonBlockstackZ} = this.props
        const {jsonStatusBlockchain} = this.props
        let jsonStatusBlockchainX = true
        if (jsonStatusBlockchain === 'Blockchain'){
          jsonStatusBlockchainX = false
        }

        const {idx} = this.state
        const {typeContract} = this.props
        const messageSubtittle = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`
        let avatar = undefined
        let avatar1 = undefined
        const avatar2 = 'images/avatar.png'
        const username = this.props.username

        let titleX = ''
        if (this.state.language === 'English'){titleX = "Register"}
        if (this.state.language === 'French'){titleX = "S'inscrire"}
        if (this.state.language === 'Spanish'){titleX = "Registrar"}
        if (this.state.language === 'Portuguese'){titleX = "Registro"}
        if (this.state.language === 'Swedish'){titleX = "Registrera"}
        if (this.state.language === 'Netherlands'){titleX = "Registreren"}
        if (this.state.language === 'Russian'){titleX = "Pегистр"}
        if (this.state.language === 'Japanese'){titleX = "登録"}
        if (this.state.language === 'Chinese'){titleX = "登記"}
        if (this.state.language === 'German'){titleX = "Registrieren"}
        if (this.state.language === 'Italian'){titleX = "Registrati"}

        let displayConfirmSign = false
        let displayMessageCertified = false
        if (this.props.typeContract !== 'simulate'){
          if (this.props.userRole === 'Creator'){
            if (this.props.todosSign===true){
              if (this.props.confirmSignX === true){
                displayMessageCertified = true
              }else {

                displayConfirmSign = true
              }
            }
          }else{
            if (this.props.confirmSignX === true){
              displayMessageCertified = true
            }
          }
        }
        let cryptoPactClarityEthereumX = ''
        let cryptoPactClarityEthereum2X = ''
        let myUserConfig = this.props.myUserConfig
        let balanceX = 0
        let transactionX = this.state.amount
        let amountpaymentX=parseFloat(this.state.amount)
        let commissionX = 0
        let rateX = 0
        let saldoX = 0
        cryptoPactClarityEthereumX = 'Stacks'
        cryptoPactClarityEthereum2X = this.state.symbolCriptoCurrency
        if (cryptoPactClarityEthereum2X === 'STX'){cryptoPactClarityEthereumX = 'Stacks'; balanceX = parseFloat(this.state.balanceCtaSTX)}
        if (cryptoPactClarityEthereum2X === 'XCK'){cryptoPactClarityEthereumX = 'Crosscheck'; balanceX = parseFloat(this.state.balanceCtaXCK)}
        if (cryptoPactClarityEthereum2X === 'MXM'){cryptoPactClarityEthereumX = 'Mixmi'; balanceX = parseFloat(this.state.balanceCtaMXM)}
        if (cryptoPactClarityEthereum2X === 'MIA'){cryptoPactClarityEthereumX = 'Miami Coin'; balanceX = parseFloat(this.state.balanceCtaMIA)}
        if (cryptoPactClarityEthereum2X === 'NYC'){cryptoPactClarityEthereumX = 'New York Coin'; balanceX = parseFloat(this.state.balanceCtaNYC)}
        if (cryptoPactClarityEthereum2X === 'FRIE'){cryptoPactClarityEthereumX = 'Friedger Token'; balanceX = parseFloat(this.state.balanceCtaFRIE)}
        let disableTransferX = true
        if (balanceX > 0){
          if (amountpaymentX !== undefined && amountpaymentX !== null && amountpaymentX !== 0 && amountpaymentX !== ''){
            if (balanceX >= (amountpaymentX + commissionX + rateX)) {
                saldoX = balanceX - (amountpaymentX + commissionX + rateX)
                disableTransferX = false
            }else{
                saldoX = balanceX
            }
          }
        }

        let publishedX = false
        if (this.props.jsonStatusLifeCycle === 'Published' || this.state.published){
           publishedX = true
        }


        const tx_id = this.state.getTransaction.tx_id
        const url_tx = `https://explorer.stacks.co/txid/${tx_id}?chain=mainnet`
        const tx_status = this.state.getTransaction.tx_status
        const tx_type = this.state.getTransaction.tx_type
        const sender_address = this.state.getTransaction.sender_address
        const url_sender = `https://explorer.stacks.co/address/${sender_address}?chain=mainnet`
        const fee_rate = parseFloat(this.state.getTransaction.fee_rate) / 1000000
        const contract_call_function_name = 'transfer'
        const block_hash = this.state.getTransaction.block_hash
        const url_block_hash = `https://explorer.stacks.co/block/${block_hash}?chain=mainnet`
        const burn_block_time_iso = this.state.getTransaction.burn_block_time_iso

        const currencyPaymentX = currencyPayment.CcyNtry

        return (
          <div>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                  <TituloPagina2
                    sm="8"
                    title={titleX}
                    subtitle={messageSubtittle}
                    className="text-sm-left"
                  />
                </Row>
                <ConfirmacionConfirmRegister
                    handleDialog={this.handleDialog2}
                    dialogMessage={this.state.dialogMessage}
                    dialogOpen={this.state.dialogOpenConfirmSign}
                    dialogTitle={this.state.dialogTitle}
                    cryptoPactClarityEthereumX={cryptoPactClarityEthereumX}
                    cryptoPactClarityEthereum2X={cryptoPactClarityEthereum2X}
                    addressX={this.state.serverStacksX}
                    balanceX={balanceX}
                    transactionX={transactionX}
                    saldoX={saldoX}
                    disableTransferX={disableTransferX}
                    rateX={rateX}
                    commissionX={commissionX}
                    handleChangeCryptoCurrency={this.handleChangeCryptoCurrency}
                    currencyPaymentX={currencyPaymentX}
                    c01CryptoCurrency={this.state.cryptoCurrencyX}
                    colorDark={this.props.colorDark}
                />

                {displayConfirmSign ?
                  <>
                    <Row>&nbsp;</Row>
                    <Row>
                      <Col md="12">
                        <Card theme="light" style={{ color: "red" }}>
                            <Table size="sm" style={{fontSize:14}} className="text-center" borderless responsive >
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="fa fa-info-circle mx-2">&nbsp;&nbsp;<FormattedMessage id="sign.confirm" /></div>
                                  </td>
                                </tr>
                                <tr>
                                  <Button outline disabled={this.state.disabledButton} pill theme="warning" size="sm" className="mb-2" onClick={this.confirmSign}>
                                      <i className="material-icons mr-1">replay</i> <FormattedMessage id="sign.buttonconfirm" />
                                  </Button>
                                </tr>
                              </tbody>
                            </Table>
                        </Card>
                      </Col>
                    </Row>
                  </>
                : null }
                {displayMessageCertified ?
                  <Container fluid className="px-0">
                      <Card theme="warning" >
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                &nbsp;&nbsp;<div className="fa fa-info"></div>
                              </td>
                              <td>
                                 &nbsp;&nbsp;<FormattedMessage id="document.published3" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Card>
                  </Container>
                : null }

                { publishedX ?
                  <>
                    <Row>&nbsp;</Row>
                    <Row>
                      <Col>
                        <Form style={{fontSize:12}}>
                          <Row form>
                            <Col md="2"></Col>
                            <Col md="8">
                              <Table size="sm" className="text-center" responsive hover striped bordered style={this.props.colorDark ? {color: 'orange' } : { color: 'black' }}>
                                  <thead>
                                    <tr style={this.props.colorDark ? { textAlign:"center", color: 'white', fontSize:12 } : { textAlign:"center", color: 'blue', fontSize:12 }}>
                                      <th><FormattedMessage id="usergroup.concept" /></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Transaction Id:</td>
                                      <td style={{ width: "70%", textAlign:"right", fontSize:10 }}><a href={url_tx} target="_blank" rel="noopener noreferrer">{tx_id}</a></td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Status</td>
                                      <td style={{ width: "70%", textAlign:"right", fontSize:10 }}>{tx_status}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Type</td>
                                      <td style={{ width: "70%", textAlign:"right", fontSize:10 }}>{tx_type}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Function Name</td>
                                      <td style={{ width: "70%", textAlign:"right", fontSize:10 }}>{contract_call_function_name}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Sender Address</td>
                                      <td style={{ width: "70%", textAlign:"right", fontSize:10 }}><a href={url_sender} target="_blank" rel="noopener noreferrer">{sender_address}</a></td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Fee Rate</td>
                                      <td style={{ width: "70%", textAlign:"right", fontSize:10 }}>{fee_rate} STX</td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Block Hash</td>
                                      <td style={{ width: "70%", textAlign:"right", fontSize:10 }}><a href={url_block_hash} target="_blank" rel="noopener noreferrer">{block_hash}</a></td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "30%", textAlign:"left", fontSize:14 }}>Block Time</td>
                                      <td style={{ width: "70%", textAlign:"right", fontSize:9 }}>{burn_block_time_iso}</td>
                                    </tr>
                                  </tbody>
                              </Table>
                            </Col>
                            <Col md="2"></Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </>
                : null }


                <Row form> </Row>
                <Row form>
                  <Button pill theme="secondary" onClick={this.exitProgram}><FormattedMessage id="button.contractlist" /></Button>
                </Row>

                <br></br>
            </Container>
          </div>
        );
    }
}

export default ContractRegister;
