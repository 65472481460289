//React
import React, { Component, useEffect } from 'react';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Card, Container, FormSelect, Button, FormInput, InputGroupAddon, InputGroupText, InputGroup, DatePicker } from "shards-react";

import { Table } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";
import { Divider, Typography, TextField, Grid, Dialog,
         DialogContent, DialogActions, DialogTitle, DialogContentText,
         Button as ButtonMaterialUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import {PayPalButtons, usePayPalScriptReducer} from "@paypal/react-paypal-js";

//Number Format
import NumberFormat from 'react-number-format';

//Tittle
import TituloPagina2 from "../common/TituloPagina2";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { makeRandomPrivKey } from '@stacks/transactions';
import { Storage } from '@stacks/storage'
import { StacksTestnet, StacksMainnet } from '@stacks/network';

//Axios
import axios from 'axios';

//Zonefile (Gaia)
import { parseZoneFile } from 'zone-file'

//translate
import { FormattedMessage } from 'react-intl';

import {username_new} from '../../actions/userActions'
import {getFileDB} from '../../actions/mongoDBActions'

//UUID
import uuid from 'uuid';

//base64
import { Base64 } from 'js-base64';

//Loader
import ContractLoader from "../contracts/ContractLoader";

//Configuración
import { SMART_CONTRACT_ADDRESS_MAINNET_STX,
        SMART_CONTRACT_NAME_MAINNET_STX,
        SMART_CONTRACT_ADDRESS_TESTNET_STX,
        SMART_CONTRACT_NAME_TESTNET_STX,
        SMART_CONTRACT_ADDRESS_MAINNET_XCK,
        SMART_CONTRACT_NAME_MAINNET_XCK,
        SMART_CONTRACT_ADDRESS_MAINNET_MXM,
        SMART_CONTRACT_NAME_MAINNET_MXM,
        SMART_CONTRACT_ADDRESS_MAINNET_MIA,
        SMART_CONTRACT_NAME_MAINNET_MIA,
        SMART_CONTRACT_ADDRESS_MAINNET_NYC,
        SMART_CONTRACT_NAME_MAINNET_NYC,
        SMART_CONTRACT_ADDRESS_MAINNET_FRIE,
        SMART_CONTRACT_NAME_MAINNET_FRIE,
        SMART_CONTRACT_ADDRESS_MAINNET_DIKO,
        SMART_CONTRACT_NAME_MAINNET_DIKO,
        MAINNET_STACKS_API_URL,
        TESTNET_STACKS_API_URL } from '../../config.js'

//
import {CopyToClipboard} from 'react-copy-to-clipboard';

//Clarity
import {transfer, balanceOf, getTransaction, getBalance } from "../../clarity/clarityfunctions"

function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionPayTo(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionAccept(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('accept')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'accept')} theme="warning" autoFocus>
                   <FormattedMessage id="alert.accept" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Confirmacion2(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Confirmacion3(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'continue')} theme="success">
                    <FormattedMessage id="alert.continue" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionContractPay(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Container fluid className="px-0">
                      <Card theme="warning" >
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                &nbsp;&nbsp;<div className="fa fa-info"></div>
                              </td>
                              <td>
                                 &nbsp;&nbsp;<FormattedMessage id="pay.transferaccountof" /> {props.payToX2}  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Card>
                  </Container>
                  <Table size="sm" className="text-left" responsive hover striped bordered style={{ color: 'blue', fontSize:11 }}>
                      <tbody>
                        <tr>
                          <th style={{ width: "40%", textAlign:"left", color: 'blue', fontSize:11 }}><FormattedMessage id="useraction.network" /></th>
                          <th style={{ width: "60%", textAlign:"right", color: 'black', fontSize:13 }}>{props.addressX}</th>
                        </tr>
                      </tbody>
                  </Table>
                  <Table size="sm" className="text-center" responsive hover striped bordered style={{ color: 'black', fontSize:11 }}>
                      <thead>
                        <tr style={{ textAlign:"center", color: 'blue', fontSize:15 }}>
                          <th><FormattedMessage id="usergroup.concept" /></th>
                          <th>{props.cryptoPactClarityEthereumX}</th>
                        </tr>
                      </thead>
                      <tbody>
                        { props.typeCurrencyPaymentX ?
                          null
                        :
                          <tr>
                            <td style={{ width: "60%", textAlign:"left", color: 'darkblue', fontSize:13, fontStyle: 'italic' }}><FormattedMessage id="pay.previousbalance" /></td>
                            <td style={{ width: "40%", textAlign:"right", color: 'darkblue', fontSize:13 }}><NumberFormat value={props.balanceX} displayType={'text'} thousandSeparator={true} /> {props.cryptoPactClarityEthereum2X}</td>
                          </tr>
                        }
                        <tr>
                          <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="payment.amountorigin" /></td>
                          <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}><NumberFormat value={props.transactionX} displayType={'text'} thousandSeparator={true} /> {props.originMoneyX}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="payment.tipocambio" /></td>
                          <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}>
                            <FormInput
                              id="ExchangeRate"
                              type="number"
                              value={props.c01ExchangeRate}
                              placeholder={props.placeholderc01ExchangeRate}
                              alt={props.c01ExchangeRate}
                              className="text-right"
                              disabled={props.equalCurrencyX}
                              onChange={e=>props.handleChangeNumber(e, "ExchangeRate", props.transactionX)}
                              onBlur={e=>props.handleBlurNumber(e, "ExchangeRate")}
                              onInput={e=>props.handleInputNumber(e, "ExchangeRate")}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="pay.amounttransfer" /></td>
                          <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}>{props.amountpaymentX} {props.cryptoCurrencyX}</td>
                        </tr>
                      </tbody>
                  </Table>
                  { props.typeCurrencyPaymentX ?
                    <Table size="sm" className="text-center" responsive borderless>
                        <thead>
                          <tr >
                            <props.ButtonWrapper
                                currency={props.currencyPaypal}
                                showSpinner={false}
                            />
                          </tr>
                        </thead>
                    </Table>
                  :
                    null
                  }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
               { props.typeCurrencyPaymentX ?
                  <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', props.cryptoCurrencyX, props.saldoX, props.c01ExchangeRate, props.transactionX)} theme="danger" autoFocus>
                      <FormattedMessage id="alert.cancel" />
                  </Button>
                :
                  <>
                    <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', props.cryptoCurrencyX, props.saldoX, props.c01ExchangeRate, props.transactionX)} theme="danger" autoFocus>
                        <FormattedMessage id="alert.cancel" />
                    </Button>
                    <Button disabled={props.disableTransferX} onClick={() => props.handleDialog(props.dialogTitle, 'pay', props.cryptoCurrencyX, props.saldoX, props.c01ExchangeRate, props.transactionX)} theme="success">
                        <FormattedMessage id="alert.pay" />
                    </Button>
                  </>
                }
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionDisplayTransaction(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Container fluid className="px-0">
                    <Card theme="warning" >
                      <table>
                        <tbody>
                          <tr>
                            <td>
                               &nbsp;&nbsp;<div className="fa fa-info"></div>
                            </td>
                            <td>
                               &nbsp;&nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                </Container>
                <Table size="sm" className="text-center" responsive hover striped bordered style={{ color: 'black', fontSize:10 }}>
                    <thead>
                      <tr style={{ textAlign:"center", color: 'blue', fontSize:12 }}>
                        <th><FormattedMessage id="usergroup.concept" /></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Paid By:</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.usernameX}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Pay To:</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.payto}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Contract Type:</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.typeContract}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Contract Number:</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.numberContract}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Concept:</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.concept}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Origin Amount:</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.amount2X} {props.originMoneyX}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Exchange Rate:</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.tipocambio2X}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Amount Payable:</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.amountpayment2X} {props.cryptoCurrencyX}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Transaction Id:</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}><a href={props.url_tx} target="_blank" rel="noopener noreferrer">{props.tx_id}</a></td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Status</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.tx_status}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Type</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.tx_type}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Function Name</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.contract_call_function_name}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Sender Address</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}><a href={props.url_sender} target="_blank" rel="noopener noreferrer">{props.sender_address}</a></td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Fee Rate</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.fee_rate} STX</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Block Hash</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}><a href={props.url_block_hash} target="_blank" rel="noopener noreferrer">{props.block_hash}</a></td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:9 }}>Block Time</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:9 }}>{props.burn_block_time_iso}</td>
                      </tr>
                    </tbody>
                </Table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'exit', props.cryptoPactClarityEthereum2X, props.saldoX)} theme="success" autoFocus>
                    <FormattedMessage id="alert.continue" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class ContractPay extends Component {

    constructor(props) {
        super(props);

        this.exitProgram = this.exitProgram.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        this.faltaDataConfirm = this.faltaDataConfirm.bind(this);
        this.faltaPayToConfirm = this.faltaPayToConfirm.bind(this);
        this.faltaSTXAddressConfirm = this.faltaSTXAddressConfirm.bind(this);
        this.getHeadPaymentForms = this.getHeadPaymentForms.bind(this);
        this.getHeadPaymentForms2 = this.getHeadPaymentForms2.bind(this);
        this.getDetailPaymentForms = this.getDetailPaymentForms.bind(this);
        this.getDetailPaymentForms2 = this.getDetailPaymentForms2.bind(this);
        this.getConceptsPaymentForms = this.getConceptsPaymentForms.bind(this);
        this.getConceptsPaymentForms2 = this.getConceptsPaymentForms2.bind(this);
        this.paySend = this.paySend.bind(this);
        this.paySeeTransaction = this.paySeeTransaction.bind(this);
        this.keyForDecrypt = this.keyForDecrypt.bind(this);
        this.registerTransactionPay = this.registerTransactionPay.bind(this);
        this.handleChangeNumber = this.handleChangeNumber.bind(this);
        this.handleBlurNumber = this.handleBlurNumber.bind(this);
        this.handleInputNumber = this.handleInputNumber.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          name: '',
          user: '',
          dialogOpen: false,
          dialogOpenPayTo: false,
          dialogOpenAccept: false,
          dialogOpenContractPay: false,
          dialogOpenDisplayTransaction: false,
          dialogMessage: '',
          dialogTitle: '',
          modifying: false,
          jsonConceptPaymentForm: [],
          jsonPaymentForm: [],
          jsonHeadPaymentForm: [],
          jsonBlockstackZ50c: [],
          estimatedDate: new Date(),
          amount: 0,
          error: false,
          indice: 0,
          isocode: 'en',
          language: 'English',
          totalpercent: 0,
          cryptoCurrency: 'STX',
          originMoney: 'USD',
          payTo: '',
          pagado: 0,
          porpagar: 0,
          balanceCtaSTX: null,
          balanceCtaXCK: null,
          balanceCtaMXM: null,
          balanceCtaMIA: null,
          balanceCtaNYC: null,
          balanceCtaFRIE: null,
          balanceCtaDIKO: null,
          resultTransfer: '',
          resultGetTransaction: '',
          stacksAddressRecipient: '',
          privateKey: '',
          contractAddressX: '',
          stacksAddressSender: '',
          networkUrlX: '',
          contractNameX: '',
          contractAddressX2: '',
          serverStacksX: '',
          usernameX: '',
          commissionX: 0,
          rateX: 0,
          itemPay: 0,
          getTransaction: '',
          userCreatorX: '',
          userPayerX: '',
          userPayToX: '',
          amountpayment: 0,
          amountTarget: 0,
          tipocambio2X: '',
          conceptX: '',
          messagePayX: '',
          typeCurrencyPayment: 'Crypto Currency'
        }
    }

    c01ExchangeRate = React.createRef();

    componentDidMount() {
      this.c01ExchangeRate.current.value = ''
    }

    UNSAFE_componentWillMount() {
      let languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
        languageX = 'English'
      }else{
        this.setState({ language: languageX })
      }
      if (languageX === 'English'){this.setState({ isocode: "en"})}
      if (languageX === 'French'){this.setState({ isocode:  "fr"})}
      if (languageX === 'Spanish'){this.setState({ isocode:  "es"})}
      if (languageX === 'Portuguese'){this.setState({ isocode:  "pt"})}
      if (languageX === 'Swedish'){this.setState({ isocode:  "sv"})}
      if (languageX === 'Netherlands'){this.setState({ isocode:  "nl"})}
      if (languageX === 'Russian'){this.setState({ isocode:  "ru"})}
      if (languageX === 'Japanese'){this.setState({ isocode:  "ja_JP"})}
      if (languageX === 'Chinese'){this.setState({ isocode: "zh"})}
      if (languageX === 'German'){this.setState({ isocode:  "de"})}
      if (languageX === 'Italian'){this.setState({ isocode:  "it"})}

      const { userSession } = this.state

      if (userSession.isUserSignedIn()) {
          let stacksNetX = ''
          let serverStacksX = ''
          let networkUrlX = ''
          let contractNameX = ''
          let contractAddressX = ''
          let contractAddressX2 = ''
          let stacksAddressSender = ''

          let networkX = ''
          let contractNameX_XCK = ''
          let contractAddressX_XCK = ''
          let contractAddressX2_XCK = ''
          let contractNameX_MXM = ''
          let contractAddressX_MXM = ''
          let contractAddressX2_MXM = ''
          let contractNameX_MIA = ''
          let contractAddressX_MIA = ''
          let contractAddressX2_MIA = ''
          let contractNameX_NYC = ''
          let contractAddressX_NYC = ''
          let contractAddressX2_NYC = ''
          let contractNameX_FRIE = ''
          let contractAddressX_FRIE = ''
          let contractAddressX2_FRIE = ''
          let contractNameX_DIKO = ''
          let contractAddressX_DIKO = ''
          let contractAddressX2_DIKO = ''

          let addressKeyX = ''

          const privateKey = makeRandomPrivKey();
          this.setState({privateKey})

          const user = userSession.loadUserData()

          if (!localStorage["serverStacks"]) {
            serverStacksX = 'MainNet'
          }else{
            serverStacksX = localStorage.getItem('serverStacks')
          }

          if (serverStacksX === 'MainNet'){
             networkUrlX = MAINNET_STACKS_API_URL
             contractNameX = SMART_CONTRACT_NAME_MAINNET_STX
             contractAddressX = SMART_CONTRACT_ADDRESS_MAINNET_STX
             contractAddressX2 = SMART_CONTRACT_ADDRESS_MAINNET_STX
             contractNameX_XCK = SMART_CONTRACT_NAME_MAINNET_XCK
             contractAddressX_XCK = SMART_CONTRACT_ADDRESS_MAINNET_XCK
             contractAddressX2_XCK = SMART_CONTRACT_ADDRESS_MAINNET_XCK
             contractNameX_MXM = SMART_CONTRACT_NAME_MAINNET_MXM
             contractAddressX_MXM = SMART_CONTRACT_ADDRESS_MAINNET_MXM
             contractAddressX2_MXM = SMART_CONTRACT_ADDRESS_MAINNET_MXM
             contractNameX_MIA = SMART_CONTRACT_NAME_MAINNET_MIA
             contractAddressX_MIA = SMART_CONTRACT_ADDRESS_MAINNET_MIA
             contractAddressX2_MIA = SMART_CONTRACT_ADDRESS_MAINNET_MIA
             contractNameX_NYC = SMART_CONTRACT_NAME_MAINNET_NYC
             contractAddressX_NYC = SMART_CONTRACT_ADDRESS_MAINNET_NYC
             contractAddressX2_NYC = SMART_CONTRACT_ADDRESS_MAINNET_NYC
             contractNameX_FRIE = SMART_CONTRACT_NAME_MAINNET_FRIE
             contractAddressX_FRIE = SMART_CONTRACT_ADDRESS_MAINNET_FRIE
             contractAddressX2_FRIE = SMART_CONTRACT_ADDRESS_MAINNET_FRIE
             contractNameX_DIKO = SMART_CONTRACT_NAME_MAINNET_DIKO
             contractAddressX_DIKO = SMART_CONTRACT_ADDRESS_MAINNET_DIKO
             contractAddressX2_DIKO = SMART_CONTRACT_ADDRESS_MAINNET_DIKO
             addressKeyX =  user.profile.stxAddress.mainnet
             stacksAddressSender =  user.profile.stxAddress.mainnet
             stacksNetX =  user.profile.stxAddress.mainnet
             networkX = new StacksMainnet()
          }
          if (serverStacksX === 'TestNet'){
             networkUrlX = TESTNET_STACKS_API_URL
             contractNameX = SMART_CONTRACT_NAME_TESTNET_STX
             contractAddressX = SMART_CONTRACT_ADDRESS_TESTNET_STX
             contractAddressX2 = SMART_CONTRACT_ADDRESS_TESTNET_STX
             stacksAddressSender =  user.profile.stxAddress.testnet
             stacksNetX =  user.profile.stxAddress.testnet
             networkX = new StacksTestnet()
          }

          const {username} = userSession.loadUserData();
          if (username === '' || username === undefined || username === null){
            username_new(stacksNetX).then(val => this.setState({username: val},()=>{
              Promise.all([this.listChat2(this.props.typeContract,this.props.numberContract,val)])
                 .then(
                    (resolve) => {},
                    (reject) => {}
                 )
              this.setState({privateKey, contractAddressX, stacksAddressSender, networkUrlX, contractNameX,contractAddressX2, serverStacksX, usernameX: val})
            })) ;
          }else{
            this.setState({ username },()=>{
              Promise.all([this.listChat2(this.props.typeContract,this.props.numberContract,username)])
                 .then(
                    (resolve) => {},
                    (reject) => {}
                 )
              this.setState({privateKey, contractAddressX, stacksAddressSender, networkUrlX, contractNameX,contractAddressX2, serverStacksX, usernameX: username})
            })
          }

          if (languageX === 'English'){this.setState({ messagePayX:`${'A payment has been made'}`})}
          if (languageX === 'French'){this.setState({ messagePayX:`${'Un paiement a été effectué'}`})}
          if (languageX === 'Spanish'){this.setState({ messagePayX:`${'Se ha efectuado un pago'}`})}
          if (languageX === 'Portuguese'){this.setState({ messagePayX:`${'Foi feito um pagamento'}`})}
          if (languageX === 'Swedish'){this.setState({ messagePayX:`${'En betalning har gjorts'}`})}
          if (languageX === 'Netherlands'){this.setState({ messagePayX:`${'Er is een betaling gedaan'}`})}
          if (languageX === 'Russian'){this.setState({ messagePayX:`${'Платеж был произведен'}`})}
          if (languageX === 'Japanese'){this.setState({ messagePayX:`${'支払いが行われました'}`})}
          if (languageX === 'Chinese'){this.setState({ messagePayX:`${'已付款'}`})}
          if (languageX === 'German'){this.setState({ messagePayX:`${'Eine Zahlung ist erfolgt'}`})}
          if (languageX === 'Italian'){this.setState({ messagePayX:`${'È stato effettuato un pagamento'}`})}

          balanceOf(contractAddressX, stacksAddressSender, networkUrlX, contractNameX,contractAddressX2, serverStacksX, username)
            .then(val => this.setState({balanceCtaSTX: val}))

          getBalance(addressKeyX, contractNameX_XCK,contractAddressX_XCK, 1000000, serverStacksX)
            .then(val => this.setState({balanceCtaXCK: val}))

          getBalance(addressKeyX, contractNameX_MXM,contractAddressX_MXM, 1000000, serverStacksX)
            .then(val => this.setState({balanceCtaMXM: val}))

          getBalance(addressKeyX, contractNameX_MIA,contractAddressX_MIA, 1000000, serverStacksX)
            .then(val => this.setState({balanceCtaMIA: val}))

          getBalance(addressKeyX, contractNameX_NYC,contractAddressX_NYC, 1000000, serverStacksX)
            .then(val => this.setState({balanceCtaNYC: val}))

          getBalance(addressKeyX, contractNameX_FRIE,contractAddressX_FRIE, 1000000, serverStacksX)
            .then(val => this.setState({balanceCtaFRIE: val}))

          getBalance(addressKeyX, contractNameX_DIKO,contractAddressX_DIKO, 1000000, serverStacksX)
            .then(val => this.setState({balanceCtaDIKO: val}))

      }
    }

    UNSAFE_componentWillReceiveProps() {

      let userCreatorX = false
      let userPayerX = false
      let userPayToX = false
      let userPayTo = ''
      let userPayer = ''
      let userCreator = ''
      const largo = this.props.jsonUserGroup.length
      if (this.props.jsonUserGroup.length > 0){
        this.props.jsonUserGroup.map((todo, i) => {
            if (todo.role === 'PayTo'){
              userPayToX = true
              userPayTo = todo.id
              this.setState({userPayTo:todo.id },()=>{})
            }else{
              if (todo.role === 'Payer'){
                userPayerX = true
                userPayer = todo.id
                this.setState({userPayer:todo.id },()=>{})
              }else{
                if (todo.role === 'Creator'){
                  userCreatorX = true
                  userCreator = todo.id
                  this.setState({userCreator:todo.id },()=>{})
                }else{
                  userPayToX = true
                  userPayTo = todo.id
                  this.setState({userPayTo:todo.id },()=>{})
                }
              }
            }
            if (i === (largo -1)){
              if (!userPayToX){
                userPayTo = this.props.userOrigin
                this.setState({userPayTo:this.props.userOrigin },()=>{})
              }
              if (!userPayerX){
                userPayer = this.props.userOrigin
                this.setState({userPayer:this.props.userOrigin },()=>{})
              }
              this.fillDataPay(userPayer )
            }
        })
      }
    }

    listChat2 = (typeContract,numberContract,username) => {
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        let options = { decrypt: false, verify: false }
        let jsonBlockstack4 = []
        return new Promise ((resolve1, reject1) =>{
            getFileDB(username, `${typeContract}_${numberContract}_chat3.json`)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 this.setState({jsonBlockstackZ50c: jsonBlockstack6})
                 resolve1()
               } else {
                 resolve1()
               }
             })
              .catch(error => {
                resolve1()
             })
        })
    }

    handleDialog2 = (type, action, cryptoX, saldoX, tipoCambioX, montoOrigenX) => {
      if (type === 'To Pay') {
        if (action === 'pay') {
          this.setState({ dialogOpenContractPay: false });
          const message64 = Base64.encode(this.state.messagePayX)
          transfer(this.state.stacksAddressSender, this.state.networkUrlX, this.state.contractNameX, this.state.contractAddressX, this.state.serverStacksX, this.state.stacksAddressRecipient, parseFloat(this.state.amountpayment), this.state.commissionX, this.state.rateX, this.props.typeContract, this.props.numberContract, this.state.jsonPaymentForm, this.state.itemPay, cryptoX, tipoCambioX, montoOrigenX, message64, this.state.jsonBlockstackZ50c, uuid(), this.state.username)
           .then(result => {
              this.setState({resultTransfer: result})
              if (result !== undefined ){
                this.registerTransactionPay(result)
              }
          })
        }
        if (action === 'cancel') {
          this.setState({ dialogOpenContractPay: false, displayActiveContractPayImpide: false, });
        }
      }
      if (type === 'Transaction Detail') {
        if (action === 'exit') {
          this.setState({ dialogOpenDisplayTransaction: false, });
        }
      }
    }

    registerTransactionPay(result){
      this.state.jsonPaymentForm.map((todo, i) => {
        if (this.state.itemPay === i){
          todo.txid = result.txId
          todo.status = 'pending'
          todo.paid = true
        }
      })
    }

    handleDialog = (type, action) => {
      if (type === 'Error: 1001') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1002') {
          if (action === 'cancel') {
            this.setState({ dialogOpenPayTo: false });
            this.exitProgram()
          }
      }
      if (type === 'Warning: 2001') {
          if (action === 'accept') {
            this.setState({ dialogOpenContractPay: false });
          }
      }

      if (type === 'Warning: 2002') {
          if (action === 'accept') {
            this.setState({ dialogOpenAccept: false });
          }
      }

      if (type === 'Error: 1003') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
            this.exitProgram()
          }
      }
    }

    handleChangeNumber = (e,chk,amountOrigin) => {

      if (chk==='ExchangeRate'){

            let amountTarget = e.target.value
            if (e.target.value === ''){
              amountTarget = '0'
            }
            if (parseFloat(e.target.value) < 0){
              amountTarget = '0'
            }

            this.setState({amountTarget: amountTarget, amountpayment: (parseFloat(amountOrigin) * parseFloat(amountTarget))})
      }
    }

    handleBlurNumber = (e,chk) => {
    }

    handleInputNumber = (e,chk) => {
      if (chk==='ExchangeRate'){
        if (e.target.value.length===0){
          this.setState({amountTarget: ''})
        }
      }
    }

    handleChangeConceptPaymentForm(e){
    }

    handleDate(val, chk){
      if (chk==='estimatedDateX'){
        this.setState({estimatedDate: new Date(val)})
      }
    }

    faltaDataConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: '------ Missing data ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "------ Données manquantes ------",dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: '------ Faltan Datos ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: '------ Dados ausentes ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: '------ Saknar data ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: '------ Ontbrekende gegevens ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: '------ Потерянная информация ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '------ 欠測データ ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '------ 缺失數據 ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: '------ Fehlende Daten ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: '------ Dati mancanti ------',dialogTitle: 'Error: 1001',})}
    }

    faltaPayToConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenContractPay: true,dialogMessage: '------ Missing role "PayTo", user "Creator" will receive payment ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'French'){this.setState({dialogOpenContractPay: true,dialogMessage: `------ Rôle manquant "PayTo", l'utilisateur "Créateur" recevra le paiement ------`,dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenContractPay: true,dialogMessage: '------ Falta rol "PayTo", usuario "Creator" recibirá el pago ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenContractPay: true,dialogMessage: '------ Função "PayTo" ausente, o usuário "Criador" receberá o pagamento ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenContractPay: true,dialogMessage: '------ Rollen "PayTo" saknas, användaren "Creator" får betalning ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenContractPay: true,dialogMessage: '------ Ontbrekende rol "PayTo", gebruiker "Creator" ontvangt betaling  ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenContractPay: true,dialogMessage: '------ Отсутствует роль PayTo, пользователь Creator получит платеж. ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenContractPay: true,dialogMessage: '------ 不足している役割「PayTo」、ユーザー「作成者」は支払いを受け取ります ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenContractPay: true,dialogMessage: '------ 缺少角色“PayTo”，用戶“Creator”將收到付款 ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'German'){this.setState({dialogOpenContractPay: true,dialogMessage: '------ Fehlende Rolle "PayTo", Benutzer "Creator" erhält Zahlung ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenContractPay: true,dialogMessage: `------ Manca il ruolo "PayTo", l'utente "Creatore" riceverà il pagamento ------`,dialogTitle: 'Warning: 2001',})}
    }

    faltaSTXAddressConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: '------ The SXT Address of the user "PayTo" is missing ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: `------ L'adresse SXT de l'utilisateur "PayTo" est manquante ------`,dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: '------ Falta el SXT Address del usuario "PayTo" ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: '------ O endereço SXT do usuário "PayTo" está faltando ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: '------ SXT-adressen för användaren "PayTo" saknas ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: '------ Het SXT-adres van de gebruiker "PayTo" ontbreekt ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: '------ SXT-адрес пользователя PayTo отсутствует. ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '------ ユーザー「PayTo」の SXT アドレスがありません ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '------ 缺少用戶“PayTo”的 SXT 地址 ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: '------ Die SXT-Adresse des Benutzers "PayTo" fehlt ------',dialogTitle: 'Error: 1003',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: `------ Manca l'indirizzo SXT dell'utente "PayTo" ------`,dialogTitle: 'Error: 1003',})}
    }

    deliverableConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpenAccept: true,dialogMessage: 'Payment for the Deliverable must be made in the «Product/Service Deliveries» tab',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'French'){this.setState({dialogOpenAccept: true,dialogMessage: "Le paiement du Livrable doit être effectué dans l'onglet «Livraisons de produits/services»",dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpenAccept: true,dialogMessage: 'El pago del Entregable (Deliverable) debe realizarse en la pestaña «Entregas Producto/Servicio»',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpenAccept: true,dialogMessage: 'O pagamento do Entregável deve ser feito na aba «Entregas de Produtos/Serviços»',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpenAccept: true,dialogMessage: 'Betalning för leveransen ska göras på fliken «Produkt/tjänstleveranser»',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpenAccept: true,dialogMessage: 'De betaling voor het resultaat moet worden gedaan op het tabblad «Product-/serviceleveringen»',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpenAccept: true,dialogMessage: 'Оплата Результата должна быть произведена во вкладке «Поставки Товара/Услуги»',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpenAccept: true,dialogMessage: '成果物の支払いは、[製品/サービスの配信]タブで行う必要があります',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpenAccept: true,dialogMessage: '交付物的付款必須在“產品/服務交付”選項卡中進行',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'German'){this.setState({dialogOpenAccept: true,dialogMessage: 'Die Zahlung für die Leistung muss auf der Registerkarte «Produkt-/Dienstleistungslieferungen» erfolgen',dialogTitle: 'Warning: 2002',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpenAccept: true,dialogMessage: 'Il pagamento per la consegna deve essere effettuato nella scheda «Consegne di prodotti/servizi»',dialogTitle: 'Warning: 2002',})}
    }

    getConceptsPaymentForms(userPayer){
        this.getConceptsPaymentFormsLegacy(userPayer)
    }

    getConceptsPaymentFormsLegacy = (userPayer) => {
         const {userSession} = this.state
         const storage = new Storage({ userSession });
         let options = { decrypt: false, verify: false }
         if (this.props.whatUserRole === 'PayTo'){
            options = { username: userPayer, decrypt: false, verify: false }
         }
         let jsonBlockstack4 = []
         return new Promise ((resolve, reject) =>{
           getFileDB(userPayer, `concepts_payment_forms.json`)
            .then((fileContents) => {
              if(fileContents) {
                const jsonBlockstack1 = JSON.parse(fileContents)
                const jsonBlockstack2 = jsonBlockstack1.data
                let jsonBlockstack5 = '[]'
                if (jsonBlockstack2 !== null){
                   const jsonBlockstack3 = jsonBlockstack2.dataobject
                   const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                   if (jsonBlockstack4.substring(0,1) === '"') {
                      jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                   } else {
                      jsonBlockstack5 = jsonBlockstack4
                   }
                }
                let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                this.setState({jsonConceptPaymentForm:jsonBlockstack6});
                resolve(true);
              } else {
                if (this.props.whatUserRole === 'Payer' || this.props.whatUserRole === 'PayTo'){
                   this.getConceptsPaymentForms2()
                }
                resolve(true);
              }
            })
             .catch(error => {
                 if (this.props.whatUserRole === 'Payer' || this.props.whatUserRole === 'PayTo'){
                    this.getConceptsPaymentForms2()
                 }
                 resolve(true);
            });
         });
    }

    getConceptsPaymentForms2(){
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = { username: this.props.userOrigin, decrypt: false, verify: false }
       let jsonBlockstack4 = []
       return new Promise ((resolve, reject) =>{
         getFileDB(this.props.userOrigin, `concepts_payment_forms.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonConceptPaymentForm:jsonBlockstack6});
              resolve();
            } else {
              resolve();
            }
          })
           .catch(error => {
               resolve();
          });
       });
    }

    getDetailPaymentForms(userPayer) {
        this.getDetailPaymentFormsLegacy(userPayer)
    }

    getDetailPaymentFormsLegacy(userPayer) {
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       let options = { decrypt: false, verify: false }
       if (this.props.whatUserRole === 'PayTo'){
          options = { username: userPayer, decrypt: false, verify: false }
       }
       let jsonBlockstack4 = []
       this.setState({jsonPaymentForm:[], pagado:0, porpagar:0 });
       return new Promise ((resolve, reject) =>{
         getFileDB(userPayer, `${this.props.typeContract}_${this.props.numberContract}_detail_payment_form.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              let pagado = 0
              let porpagar = 0
              const largo = jsonBlockstack6.length
              Promise.all(jsonBlockstack6.map( async (todo, i) => {
                 if (todo.txid === undefined){todo.txid = ''}
                 if (todo.status === undefined){todo.status = ''}
                 if (todo.paid === true && (todo.status === 'pending')){
                    let modeActualizar = ''
                    if (this.state.username === this.props.userOrigin || this.props.userRole === 'Creator' || this.props.userRole === 'Payer' ){modeActualizar = 'actualizar'}
                    await getTransaction(this.state.networkUrlX, todo.txid, this.props.typeContract, this.props.numberContract, jsonBlockstack6, i, modeActualizar, userPayer)
                      .then(result => {
                           todo.status = result.tx_status
                           if (result.tx_status === 'failed' || result.tx_status === 'abort_by_post_condition' || result.tx_status === 'abort_by_response' ){
                             todo.paid = false
                             porpagar = porpagar + parseFloat(todo.amount)
                           }else{
                             pagado = pagado + parseFloat(todo.amount)
                           }
                      })
                 }else{
                   porpagar = porpagar + parseFloat(todo.amount)
                 }
              }))
              this.setState({jsonPaymentForm:jsonBlockstack6, pagado:pagado, porpagar:porpagar },resolve());
            } else {
              if (this.props.whatUserRole === 'Payer' || this.props.whatUserRole === 'PayTo'){
                 Promise.all([this.getDetailPaymentForms2()])
                  .then(
                    (resolve) =>{resolve()},
                    (reject) =>{reject()})
              }
            }
          })
           .catch(error => {
             if (this.props.whatUserRole === 'Payer' || this.props.whatUserRole === 'PayTo'){
               Promise.all([this.getDetailPaymentForms2()])
                .then(
                  (resolve) =>{resolve()},
                  (reject) =>{reject()})
             }
          });
       });
    }

    getDetailPaymentForms2(){
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = { username: this.props.userOrigin, decrypt: false, verify: false }
       let jsonBlockstack4 = []
       this.setState({jsonPaymentForm:[], pagado:0, porpagar:0 });
       return new Promise ((resolve, reject) =>{
         getFileDB(this.props.userOrigin, `${this.props.typeContract}_${this.props.numberContract}_detail_payment_form.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              let pagado = 0
              let porpagar = 0
              const largo = jsonBlockstack6.length
              Promise.all(jsonBlockstack6.map( async (todo, i) => {
                 if (todo.txid === undefined){todo.txid = ''}
                 if (todo.status === undefined){todo.status = ''}
                 if (todo.paid === true && (todo.status === 'pending')){
                     let modeActualizar = ''
                     if (this.state.username === this.props.userOrigin || this.props.userRole === 'Creator' || this.props.userRole === 'Payer' ){modeActualizar = 'actualizar'}
                     await getTransaction(this.state.networkUrlX, todo.txid, this.props.typeContract, this.props.numberContract, jsonBlockstack6, i, modeActualizar, this.props.userOrigin)
                      .then(result => {
                           todo.status = result.tx_status
                           if (result.tx_status === 'failed' || result.tx_status === 'abort_by_post_condition' || result.tx_status === 'abort_by_response' ){
                             todo.paid = false
                             porpagar = porpagar + parseFloat(todo.amount)
                           }else{
                             pagado = pagado + parseFloat(todo.amount)
                           }
                      })
                 }else{
                   porpagar = porpagar + parseFloat(todo.amount)
                 }
                 if (i === (largo-1)){
                   this.setState({jsonPaymentForm:jsonBlockstack6, pagado:pagado, porpagar:porpagar },resolve());
                 }
              }))
            } else {
              resolve();
            }
          })
           .catch(error => {
              resolve();
          });
       });
    }

    getHeadPaymentForms(userPayer) {
        this.getHeadPaymentFormsLegacy(userPayer)
    }

    getHeadPaymentFormsLegacy(userPayer){
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       let options = { decrypt: false, verify: false }
       if (this.props.whatUserRole === 'PayTo'){
          options = { username: userPayer, decrypt: false, verify: false }
       }
       let jsonBlockstack4 = []
       return new Promise ((resolve, reject) =>{
         getFileDB(userPayer, `${this.props.typeContract}_${this.props.numberContract}_head_payment_form.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonHeadPaymentForm:jsonBlockstack6});
              if (jsonBlockstack6.length > 0){
                 if (jsonBlockstack6[0].typecurrencypayment !== undefined){
                   this.setState({typeCurrencyPayment: jsonBlockstack6[0].typecurrencypayment})
                 }else{
                   this.setState({typeCurrencyPayment: this.state.typeCurrencyPayment})
                 }
                 this.setState({cryptoCurrency:jsonBlockstack6[0].symbolcryptocurrency, originMoney:jsonBlockstack6[0].symbolcurrency, saldodocumento: parseFloat(jsonBlockstack6[0].amount)})
              }
              resolve();
            } else {
              if (this.props.whatUserRole === 'Payer' || this.props.whatUserRole === 'PayTo'){
                 this.getHeadPaymentForms2()
              }
              resolve();
            }
          })
           .catch(error => {
               if (this.props.whatUserRole === 'Payer' || this.props.whatUserRole === 'PayTo'){
                  this.getHeadPaymentForms2()
               }
               resolve();
          });
       });
    }

    getHeadPaymentForms2(){
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = { username: this.props.userOrigin, decrypt: false, verify: false }
       let jsonBlockstack4 = []
       return new Promise ((resolve, reject) =>{
         getFileDB(this.props.userOrigin, `${this.props.typeContract}_${this.props.numberContract}_head_payment_form.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonHeadPaymentForm:jsonBlockstack6});
              if (jsonBlockstack6.length > 0){
                if (jsonBlockstack6[0].typecurrencypayment !== undefined){
                  this.setState({typeCurrencyPayment: jsonBlockstack6[0].typecurrencypayment})
                }else{
                  this.setState({typeCurrencyPayment: this.state.typeCurrencyPayment})
                }
                this.setState({cryptoCurrency:jsonBlockstack6[0].symbolcryptocurrency, originMoney:jsonBlockstack6[0].symbolcurrency, saldodocumento: parseFloat(jsonBlockstack6[0].amount)})
              }
              resolve(true);
            } else {
              resolve(true);
            }
          })
           .catch(error => {
               this.getHeadPaymentForms2Names()
               resolve(true);
          });
       });
    }

    paySend = (e, amount, userX, itemPay, conceptPay) =>{
      e.preventDefault();
      Promise.all([this.keyForDecrypt(userX)])
          .then(
            (resolve) =>{this.setState({ dialogOpenContractPay: true, dialogMessage: '', dialogTitle: 'To Pay', amount, itemPay })},
            (reject) =>{this.faltaSTXAddressConfirm()})
    }

    paySeeTransaction = (e, txId, jsonBlockstack4, i, concept) => {
      if (txId !== null && txId !== '' ) {
        let modeActualizar = ''
        if (this.state.username === this.props.userOrigin || this.props.userRole === 'Creator' || this.props.userRole === 'Payer' ){modeActualizar = 'actualizar'}
        getTransaction(this.state.networkUrlX, txId, this.props.typeContract, this.props.numberContract, jsonBlockstack4, i, modeActualizar,this.state.username)
          .then(val => {
              this.setState({ getTransaction: val, dialogOpenDisplayTransaction: true, dialogMessage: '', dialogTitle: 'Transaction Detail', tipocambio2X: jsonBlockstack4[i].tipocambio, amountpayment2X:jsonBlockstack4[i].amountpayment, amount2X:jsonBlockstack4[i].amount, conceptX:concept })
          })
      }
    }

    refreshPay = () => {
      this.fillDataPay(this.state.userPayer)
    }

    fillDataPay = (userPayer) => {
      Promise.all([this.getConceptsPaymentForms(userPayer),
                   this.getHeadPaymentForms(userPayer),
                   this.getDetailPaymentForms(userPayer)],
                 )
        .then((resolve) =>{},(reject) =>{})
    }

    keyForDecrypt(userX){
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = { username: userX, decrypt: false, verify: false }
        this.setState({ stacksAddressRecipient: '' })
        return new Promise((resolve1, reject1) => {
            getFileDB(userX, `stxaddress3.json`)
               .then((fileContents) => {
                 if(fileContents) {
                   const jsonBlockstack1 = JSON.parse(fileContents)
                   const jsonBlockstack2 = jsonBlockstack1.data
                   let jsonBlockstack5 = '[]'
                   if (jsonBlockstack2 !== null){
                      const jsonBlockstack3 = jsonBlockstack2.dataobject
                      const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                      if (jsonBlockstack4.substring(0,1) === '"') {
                         jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                      } else {
                         jsonBlockstack5 = jsonBlockstack4
                      }
                   }
                   let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                   if (jsonBlockstack6.stacksNetX === ''){
                      reject1()
                   }
                   let serverStacksX = ''
                   if (!localStorage["serverStacks"]) {
                     serverStacksX = 'MainNet'
                   }else{
                     serverStacksX = localStorage.getItem('serverStacks')
                   }
                   if (serverStacksX === 'MainNet'){
                     this.setState({stacksAddressRecipient: jsonBlockstack6.addressMainNetX},() =>{resolve1();});
                   }
                   if (serverStacksX === 'TestNet'){
                     this.setState({stacksAddressRecipient: jsonBlockstack6.addressTestNetX},() =>{resolve1();});
                   }
                 } else {
                   reject1()
                 }
               })
                .catch(error => {
                  resolve1()
               });
        });
    }

    exitProgram(){
       this.props.returnProps('returnWithFilter',this.props.typeContract,this.props.numberContract);
    }

    render() {
        let titleX = 'Transfer'
        let placeholderX1 = "Select an Option"
        if (this.state.language === 'English'){titleX = "Transfer";placeholderX1 = "Select an Option"}
        if (this.state.language === 'French'){titleX = "Transférer";placeholderX1 = "Sélectionner unproe.ide Option"}
        if (this.state.language === 'Spanish'){titleX = "Transferir";placeholderX1 = "Seleccione una Opción"}
        if (this.state.language === 'Portuguese'){titleX = "Transferir";placeholderX1 = "Selecione uma Opção"}
        if (this.state.language === 'Swedish'){titleX = "Överföra";placeholderX1 = "Välj ett Alternativ"}
        if (this.state.language === 'Netherlands'){titleX = "Overdracht";placeholderX1 = "Kies een Optie"}
        if (this.state.language === 'Russian'){titleX = "Передача";placeholderX1 = "Выберите опцию"}
        if (this.state.language === 'Japanese'){titleX = "移行";placeholderX1 = "オプションを選択"}
        if (this.state.language === 'Chinese'){titleX = "轉移";placeholderX1 = "选择一个选项"}
        if (this.state.language === 'German'){titleX = "Transfer";placeholderX1 = "Wähle eine Option"}
        if (this.state.language === 'Italian'){titleX = "Trasferimento";placeholderX1 = "Seleziona un'opzione"}

        const messageSubtittle = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`
        const {username} = this.state

        const jsonPaymentFormX = this.state.jsonPaymentForm

        let disabledX = false
        let loaderX = true
        if (jsonPaymentFormX.length > 0){
          disabledX = true
          loaderX = false
        }
        let bloquedX = false
        if (this.props.jsonStatusLifeCycle !== 'Draft'){
          bloquedX = true
          disabledX = true
        }

        const saldodocumentoX = this.state.saldodocumento-this.state.pagado

        const jsonConceptPaymentFormX = this.state.jsonConceptPaymentForm

        let item = 0


        let payToX1 = false
        let payToX2 = ''
        let payToX3 = false
        let payToX4 = false
        let payToX5 = true

        if (this.props.jsonUserGroup.length > 0){
          payToX1 = true
          this.props.jsonUserGroup.map((todo, i) => {
              if (todo.role === 'PayTo'){
                payToX2 = todo.id
                payToX3 = true
              }
              if (todo.role === 'Payer'){
                payToX4 = true
              }
          })
        }
        if (payToX3 === false){
            payToX2 = this.props.userOrigin
            payToX3 = true
        }
        if (payToX4 && this.props.whatUserRole !== 'Payer'){
           payToX5 = false
        }else{
           if (!payToX4 && this.props.whatUserRole !== 'Creator'){
             payToX5 = false
           }
        }
        if (this.props.whatUserRole === 'Creator' && payToX4){
           payToX5 = false
        }
        let cryptoPactClarityEthereumX = ''
        let cryptoPactClarityEthereum2X = ''
        let myUserConfig = this.props.myUserConfig
        let balanceX = 0
        let amountpaymentX=parseFloat(this.state.amountpayment)
        let transactionX = parseFloat(this.state.amount)
        let commissionX = parseFloat(this.state.commissionX)
        let rateX = parseFloat(this.state.rateX)
        let saldoX = 0
        cryptoPactClarityEthereum2X = this.state.cryptoCurrency
        if (cryptoPactClarityEthereum2X === 'STX'){cryptoPactClarityEthereumX = 'Stacks'; balanceX = parseFloat(this.state.balanceCtaSTX)}
        if (cryptoPactClarityEthereum2X === 'XCK'){cryptoPactClarityEthereumX = 'Crosscheck'; balanceX = parseFloat(this.state.balanceCtaXCK)}
        if (cryptoPactClarityEthereum2X === 'MXM'){cryptoPactClarityEthereumX = 'Mixmi'; balanceX = parseFloat(this.state.balanceCtaMXM)}
        if (cryptoPactClarityEthereum2X === 'MIA'){cryptoPactClarityEthereumX = 'Miami Coin'; balanceX = parseFloat(this.state.balanceCtaMIA)}
        if (cryptoPactClarityEthereum2X === 'NYC'){cryptoPactClarityEthereumX = 'New York Coin'; balanceX = parseFloat(this.state.balanceCtaNYC)}
        if (cryptoPactClarityEthereum2X === 'FRIE'){cryptoPactClarityEthereumX = 'Friedger Token'; balanceX = parseFloat(this.state.balanceCtaFRIE)}
        if (cryptoPactClarityEthereum2X === 'DIKO'){cryptoPactClarityEthereumX = 'Arkadiko Token'; balanceX = parseFloat(this.state.balanceCtaDIKO)}
        let disableTransferX = true
        if (balanceX > 0){
          if (amountpaymentX !== undefined && amountpaymentX !== null && amountpaymentX !== 0 && amountpaymentX !== ''){
            if (balanceX >= (amountpaymentX + commissionX + rateX)) {
                saldoX = balanceX - (amountpaymentX + commissionX + rateX)
                disableTransferX = false
            }else{
                saldoX = balanceX
            }
          }
        }
        const tx_id = this.state.getTransaction.tx_id
        const url_tx = `https://explorer.stacks.co/txid/${tx_id}?chain=mainnet`
        const tx_status = this.state.getTransaction.tx_status
        const tx_type = this.state.getTransaction.tx_type
        const sender_address = this.state.getTransaction.sender_address
        const url_sender = `https://explorer.stacks.co/address/${sender_address}?chain=mainnet`
        const fee_rate = parseFloat(this.state.getTransaction.fee_rate) / 1000000
        const contract_call_function_name = 'transfer'
        const block_hash = this.state.getTransaction.block_hash
        const url_block_hash = `https://explorer.stacks.co/block/${block_hash}?chain=mainnet`
        const burn_block_time_iso = this.state.getTransaction.burn_block_time_iso

        const avatar2 = 'images/avatar.png'

        const amountPaypal = this.state.amountpayment;
        const currencyPaypal = this.state.cryptoCurrency;
        const stylePaypal = {"layout":"vertical"};
        const showSpinner = true

        let typeCurrencyPaymentX = false
        if (this.state.typeCurrencyPayment === 'FIAT Money'){
          typeCurrencyPaymentX = true
        }

        let equalCurrencyX = false
        let amountTargetX = this.state.amountTarget
        let amountpayment2X = this.state.amountpayment
        if (this.state.cryptoCurrency === this.state.originMoney){
          equalCurrencyX = true
          amountTargetX = 1
          amountpayment2X = transactionX
        }


        const ButtonWrapper = ({ currencyPaypal, showSpinner }) => {
            // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
            // This is the main reason to wrap the PayPalButtons in a new component
            const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
            useEffect(() => {
                dispatch({
                    type: "resetOptions",
                    value: {
                        ...options,
                        currency: currencyPaypal,
                    },
                });
            }, [currencyPaypal, showSpinner]);
            return (<>
                    { (showSpinner && isPending) && <div className="spinner" /> }
                    <PayPalButtons
                        style={stylePaypal}
                        disabled={false}
                        forceReRender={[amountPaypal, currencyPaypal, stylePaypal]}
                        fundingSource={undefined}
                        createOrder={(data, actions) => {
                            return actions.order
                                .create({
                                    purchase_units: [
                                        {
                                            amount: {
                                                currency_code: currencyPaypal,
                                                value: amountPaypal,
                                            },
                                        },
                                    ],
                                })
                                .then((orderId) => {
                                    // Your code here after create the order
                                    return orderId;
                                });
                        }}
                        onApprove={function (data, actions) {
                            return actions.order.capture().then(function () {
                                // Your code here after capture the order
                            });
                        }}
                    />
                </>
            );
        }

        return (
          <Container fluid className="main-content-container px-4">
            <Confirmacion
                handleDialog={this.handleDialog}
                dialogMessage={this.state.dialogMessage}
                dialogOpen={this.state.dialogOpen}
                dialogTitle={this.state.dialogTitle}
            />
            <ConfirmacionAccept
                handleDialog={this.handleDialog}
                dialogMessage={this.state.dialogMessage}
                dialogOpen={this.state.dialogOpenAccept}
                dialogTitle={this.state.dialogTitle}
            />
            <ConfirmacionPayTo
                handleDialog={this.handleDialog}
                dialogMessage={this.state.dialogMessage}
                dialogOpen={this.state.dialogOpenPayTo}
                dialogTitle={this.state.dialogTitle}
            />
            <ConfirmacionContractPay
                handleDialog={this.handleDialog2}
                dialogMessage={this.state.dialogMessage}
                dialogOpen={this.state.dialogOpenContractPay}
                dialogTitle={this.state.dialogTitle}
                cryptoPactClarityEthereumX={cryptoPactClarityEthereumX}
                cryptoPactClarityEthereum2X={cryptoPactClarityEthereum2X}
                addressX={this.state.serverStacksX}
                balanceX={balanceX}
                transactionX={transactionX}
                saldoX={saldoX}
                disableTransferX={disableTransferX}
                rateX={rateX}
                commissionX={commissionX}
                payToX2={payToX2}
                c01ExchangeRate={amountTargetX}
                handleChangeNumber={this.handleChangeNumber}
                handleBlurNumber={this.handleBlurNumber}
                handleInputNumber={this.handleInputNumber}
                amountpaymentX={amountpayment2X}
                cryptoCurrencyX={this.state.cryptoCurrency}
                originMoneyX={this.state.originMoney}
                ButtonWrapper={ButtonWrapper}
                currencyPaypal={currencyPaypal}
                typeCurrencyPaymentX={typeCurrencyPaymentX}
                equalCurrencyX={equalCurrencyX}
            />
            <ConfirmacionDisplayTransaction
                handleDialog={this.handleDialog2}
                dialogMessage={this.state.dialogMessage}
                dialogOpen={this.state.dialogOpenDisplayTransaction}
                dialogTitle={this.state.dialogTitle}
                tx_id={tx_id}
                tx_status={tx_status}
                tx_type={tx_type}
                sender_address={sender_address}
                fee_rate={fee_rate}
                contract_call_function_name={contract_call_function_name}
                block_hash={block_hash}
                burn_block_time_iso={burn_block_time_iso}
                url_tx={url_tx}
                url_sender={url_sender}
                url_block_hash={url_block_hash}
                typeContract={this.props.typeContract}
                numberContract={this.props.numberContract}
                amountpayment2X={this.state.amountpayment2X}
                amount2X={this.state.amount2X}
                cryptoCurrencyX={this.state.cryptoCurrency}
                originMoneyX={this.state.originMoney}
                tipocambio2X={this.state.tipocambio2X}
                usernameX={this.state.username}
                payto={payToX2}
                concept={this.state.conceptX}
            />

            <Row noGutters className="page-header py-4">
                <TituloPagina2
                  sm="4"
                  title={titleX}
                  subtitle={messageSubtittle}
                  className="text-sm-left"
                />
            </Row>
            {payToX1 ?
              <>
                {payToX3 ?
                  <>
                    <Row>
                      <Col md="1"></Col>
                      <Col md="3">
                        <Table size="sm" style={{fontSize:10}} className="text-center" responsive hover >
                          <thead>
                              <tr>
                                <td style={{ width: "20%" }}></td>
                                <td style={{ width: "80%" }}><FormattedMessage id="pay.payto" /></td>
                              </tr>
                          </thead>
                          <tbody>
                             <tr>
                               <td>
                                 <strong style={{cursor: 'pointer'}}>
                                   <a href={`https://${payToX2}`} target="_blank" rel="noopener noreferrer">
                                     <div className="mb-3 mx-auto">
                                        <ReactImageFallback
                                             src={avatar2}
                                             fallbackImage={avatar2}
                                             initialImage={avatar2}
                                             alt={" "}
                                             className="rounded-circle"
                                             width="40"
                                             title="View Profile"
                                           />
                                      </div>
                                    </a>
                                 </strong>
                               </td>
                               <td>
                                 <FormInput
                                   id="AccountAddress"
                                   type="text"
                                   placeholder={payToX2}
                                   disabled={true}
                                   className="text-center"
                                 />
                               </td>
                             </tr>
                           </tbody>
                        </Table>
                      </Col>
                      <Col md="6"></Col>
                      <Col md="2">
                        <label>&nbsp;</label>
                        <br></br>
                        <Button outline pill theme="warning" size="sm" className="mb-2" onClick={this.refreshPay}>
                            <i className="material-icons mr-1">replay</i> <FormattedMessage id="contract.refresh" />
                        </Button>
                      </Col>
                    </Row>
                    <Row>&nbsp;</Row>
                    <Row>
                      <Col md="12">
                         <Table size="sm" className="text-center" responsive hover striped>
                            <thead>
                              <tr>
                                <th style={{fontSize:15}}><FormattedMessage id="payment.item" /></th>
                                <th style={{fontSize:15}}><FormattedMessage id="payment.concept" /></th>
                                <th style={{fontSize:15}}><FormattedMessage id="payment.estimateddate" /></th>
                                <th style={{fontSize:15}}><FormattedMessage id="payment.percent" /></th>
                                <th style={{fontSize:15}}><FormattedMessage id="payment.amountorigin" /></th>
                                <th style={{fontSize:15}}><FormattedMessage id="payment.tipocambio" /></th>
                                <th style={{fontSize:15}}><FormattedMessage id="payment.amountpayment" /></th>
                                <th style={{fontSize:15}}><FormattedMessage id="pay.transfer" /></th>
                                <th style={{fontSize:15}}><FormattedMessage id="pay.seetransaction" /></th>
                                <th style={{fontSize:15}}><FormattedMessage id="iotdevice.status" /></th>
                              </tr>
                            </thead>
                            <tbody>
                              {loaderX ?
                                <>
                                  <td><ContractLoader colorDark={this.props.colorDark}/></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </>
                              :
                                <>
                                  {jsonPaymentFormX.map((todo, i) => {
                                    item = i
                                    let statusWarning = false
                                    let tipoCambioX = ''
                                    let amountPaymentX = ''
                                    let themeButton = 'success'
                                    if (todo.status === 'pending'){ statusWarning = true}
                                    if (todo.tipocambio !== undefined){ tipoCambioX = todo.tipocambio}
                                    if (todo.amountpayment !== undefined){ amountPaymentX = `${todo.amountpayment} ${this.state.cryptoCurrency}`}
                                    if (todo.concept === 'Deliverable'){ themeButton = 'warning'}

                                    return (
                                        <tr key={i} style={ this.props.colorDark ? { color:'white', fontSize:12} : {color:'black', fontSize:12}}>
                                            <td>{++item}</td>
                                            <td>{todo.concept}</td>
                                            <td>{todo.estimateddate}</td>
                                            <td>{`${todo.percent} %`} </td>
                                            <td>{`${todo.amount} ${this.state.originMoney}`}</td>
                                            <td>{tipoCambioX}</td>
                                            <td>{amountPaymentX}</td>
                                            {!todo.paid ?
                                              <>
                                              <td>
                                                {payToX5 ?
                                                  <Button outline pill theme={themeButton} size="sm" className="mb-2" onClick={e=>this.paySend(e,todo.amount,payToX2,i,todo.concept)}>
                                                      <i className="material-icons mr-1">replay</i> <FormattedMessage id="pay.transfer" />
                                                  </Button>
                                                :
                                                  <>
                                                   {null}
                                                  </>
                                                }
                                              </td>
                                              <td>
                                                <Button outline squared theme="light" style={ this.props.colorDark ? { color:'orange'} : {}} onClick={e=>this.paySeeTransaction(e,todo.txid,jsonPaymentFormX,i,todo.concept)}>{`${todo.txid.substring(0,7)}...${todo.txid.substring(todo.txid.length-10)}`}</Button>
                                              </td>
                                              <td style={{color: "red"}}>{todo.status}</td>
                                              </>
                                            :
                                              <>
                                              <td></td>
                                              <td>
                                                <Button outline squared theme="light" style={ this.props.colorDark ? { color:'orange'} : {}} onClick={e=>this.paySeeTransaction(e,todo.txid,jsonPaymentFormX,i,todo.concept)}>{`${todo.txid.substring(0,7)}...${todo.txid.substring(todo.txid.length-10)}`}</Button>
                                              </td>
                                              {statusWarning ?
                                                 <td style={{color: "orange"}}>{todo.status}</td>
                                              :
                                                 <td style={{color: "green"}}>{todo.status}</td>
                                              }
                                              </>
                                            }
                                        </tr>
                                  )})}
                                </>
                              }
                            </tbody>
                          </Table>
                      </Col>
                    </Row>
                    <br></br>
                  </>
                :
                  <>
                   <input type="hidden" ref={this.c01ExchangeRate} />
                   {this.faltaPayToConfirm()}
                  </>
                }
              </>
            :
              <>
                <input type="hidden" ref={this.c01ExchangeRate} />
              </>
            }

          </Container>

        );
    }
}

export default ContractPay;
