import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import SignaturePad from 'react-signature-canvas'

import {Button, FormSelect} from "shards-react";
import { Table } from 'reactstrap';

import styles from '../../styles/signature-pad.css'

//translate
import { FormattedMessage } from 'react-intl';

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

class SignPad  extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userSession: new UserSession(),
      username: '',
      name: '',
      user: {},
      trimmedDataURL: null,
      sigPad: {},
      cColor: 'blue',
      cThickness: 1.0,
    }
  }

  cThickness = React.createRef();
  cColor = React.createRef();

  UNSAFE_componentWillMount() {
    const { userSession } = this.state
    let stacksNetX = ''
    let serverStacksX = ''
    if (userSession.isUserSignedIn()) {
      const user = userSession.loadUserData()
      this.setState({ user })
      if (!localStorage["serverStacks"]) {
        serverStacksX = 'MainNet'
      }else{
        serverStacksX = localStorage.getItem('serverStacks')
      }
      if (serverStacksX === 'MainNet'){
         stacksNetX =  user.profile.stxAddress.mainnet
      }
      if (serverStacksX === 'TestNet'){
         stacksNetX =  user.profile.stxAddress.testnet
      }
    }

    const {name} = userSession.loadUserData().profile;
    this.setState({ name })

    const {username} = userSession.loadUserData();
    if (username === '' || username === undefined || username === null){
      username_new(stacksNetX).then(val => {
        this.setState({username: val})
        Promise.all(
            [this.getSignature(val)]
          ).then(
            (resolve) =>{},
            (reject) =>{}
          )
      })
    }else{
      this.setState({ username })
      Promise.all(
          [this.getSignature(username)]
        ).then(
          (resolve) =>{},
          (reject) =>{}
        )
    }
  }

  changeSelect = (e,optx) => {
    if (optx === 'cColor'){
      this.setState({ cColor: this.cColor.current.value })
    }
    if (optx === 'cThickness'){
      this.setState({ cThickness: parseFloat(this.cThickness.current.value) })
    }
  }

  getSignature = (username) => {
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = { decrypt: false, verify: false }
    return new Promise((resolve1, reject1) => {
        getFileDB(username, `signature.json`)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               this.setState({trimmedDataURL: jsonBlockstack6.signature})
               resolve1()
             } else {
               resolve1()
             }
           })
            .catch(error => {
              resolve1()
            });
    });
  }

  clear = () => {
    if (this.state.trimmedDataURL === null){
      this.sigPad.clear()
    }else{
      this.setState({trimmedDataURL: null})
    }
    const jsonBlockstack =`{"signature":null}`;
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
    putFileDB(this.state.username, `signature.json`, 'Crosscheck_Profile', JSON.stringify(jsonBlockstack), JSON.stringify(options))
     .then(() => {})
  }

  trim = () => {
    this.setState({trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png')})
    const jsonBlockstack =`{"signature":"${this.sigPad.getTrimmedCanvas().toDataURL('image/png')}"}`;
    const {userSession} = this.state
    const storage = new Storage({ userSession });
    const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
    putFileDB(this.state.username, `signature.json`, 'Crosscheck_Profile', JSON.stringify(jsonBlockstack), JSON.stringify(options))
     .then(() => {})
  }

  render () {
    let {trimmedDataURL} = this.state

    return (
      <div className={styles.containersignpad} style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {}}>
        <div className={styles.sigContainer}>
          {trimmedDataURL
            ?
              <>
                <p>&nbsp;</p>
                &nbsp;&nbsp;&nbsp;&nbsp;<img className={styles.sigImage} src={trimmedDataURL} />
                <p>&nbsp;</p>
              </>
            :
              <SignaturePad velocityFilterWeight={0.7}
                            minWidth={0.5}
                            maxWidth={this.state.cThickness}
                            minDistance={5}
                            penColor={this.state.cColor}
                            dotSize={() => (this.minWidth + this.maxWidth) / 2}
                            throttle={16}
                            clearOnResize={false}
                            canvasProps={{width: 800, height: 400, className: 'sigCanvas'}}
                            ref={(ref) => { this.sigPad = ref }}
                            style={ this.props.colorDark ? { backgroundColor:'#111f2c'} : {backgroundColor:'rgba(0,0,0,0)'}} />
          }
        </div>
        <div>
          <Table style={{"color": 'black'}} size="sm" borderless responsive className="text-center">
            <tbody>
                <tr>
                  {this.props.editsignature ?
                    <>
                      <td style={{width: "25%"}}>
                        <FormSelect
                            id="Color"
                            innerRef={this.cColor}
                            placeholder=""
                            onChange={e=>this.changeSelect(e,'cColor')}
                            style={{backgroundColor:"#F2EACE"}}
                        >
                           <option style={{color:'black', fontWeight: 'bold'}} value="black">Black</option>
                           <option style={{color:'blue', fontWeight: 'bold'}} value="blue" selected>Blue</option>
                           <option style={{color:'brown', fontWeight: 'bold'}} value="brown">Brown</option>
                           <option style={{color:'darkblue', fontWeight: 'bold'}} value="darkblue">Dark Blue</option>
                           <option style={{color:'darkgrey', fontWeight: 'bold'}} value="darkgrey">Dark Grey</option>
                           <option style={{color:'darkgreen', fontWeight: 'bold'}} value="darkgreen">Dark Green</option>
                           <option style={{color:'darkred', fontWeight: 'bold'}} value="darkred">Dark Red</option>
                           <option style={{color:'green', fontWeight: 'bold'}} value="green">Green</option>
                           <option style={{color:'navy', fontWeight: 'bold'}} value="navy">Navy</option>
                           <option style={{color:'red', fontWeight: 'bold'}} value="red">Red</option>
                           <option style={{color:'orange', fontWeight: 'bold'}} value="orange">Orange</option>
                        </FormSelect>
                      </td>
                      <td style={{width: "25%"}}>
                        <FormSelect
                            id="Thickness"
                            innerRef={this.cThickness}
                            placeholder=""
                            onChange={e=>this.changeSelect(e,'cThickness')}
                            style={{backgroundColor:"#F2EACE"}}
                        >
                           <option value="0.5">0.5x</option>
                           <option value="0.8">0.8x</option>
                           <option value="1.0" selected>1.0x</option>
                           <option value="1.5">1.5x</option>
                           <option value="2.0">2.0x</option>
                           <option value="2.5">2.5x</option>
                        </FormSelect>
                      </td>
                      <td style={{width: "25%"}}>
                        <Button outline pill theme="danger" className="mb-2" onClick={this.clear}><FormattedMessage id="signpad.clear" /></Button>
                      </td>
                      <td style={{width: "25%"}}>
                        <Button outline pill theme="warning" className="mb-2" onClick={this.trim}><FormattedMessage id="signpad.save" /></Button>
                      </td>
                    </>
                  : null }
                </tr>
              </tbody>
          </Table>
        </div>
      </div>
    )
  }
}

export default SignPad;
