import 'regenerator-runtime/runtime';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
//import storage from 'redux-persist/lib/storage/session';
//import { persistStore, persistReducer } from 'redux-persist';

import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';

import rootReducer from './reducers';

import logger from 'redux-logger'

const initialState = {};
const middleware = [thunk];
const sagaMiddleware = createSagaMiddleware();

//const persistConfig = {
//  key: 'kanstack',
//  storage,
//  whitelist: ['user'],
//};

//const persistedReducer = persistReducer(persistConfig, rootReducer);

//export const store = createStore(persistedReducer, initialState, composeWithDevTools(applyMiddleware(...middleware, sagaMiddleware, logger)));
export const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware, sagaMiddleware, logger)));

//export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
console.log('sagaMiddleware.run(rootSaga)')
console.log(store)
