// import * as blockstack from 'blockstack';
import {
  redirectToSignIn, signUserOut, loadUserData, isSignInPending, handlePendingSignIn,
} from 'blockstack/lib/auth/authApp';
import { User, GroupMembership } from 'radiks';

import { LOGIN, USER_SIGNING_IN, USER_LOGOUT, USER_SIGNED_IN } from './types';

import axios from 'axios';

const URL = 'https://api.hiro.so'

const login = () => {
  const redirect = `${window.location.origin}`;
  const manifest = `${redirect}/manifest.json`;
  const scopes = ['store_write', 'publish_data'];
  redirectToSignIn(redirect, manifest, scopes);
  return {
    type: LOGIN,
  };
};

const loggingIn = () => ({
  type: USER_SIGNING_IN,
});

const logout = () => {
  signUserOut();
  return {
    type: USER_LOGOUT,
  };
};

const gotUserData = userData => ({
  type: USER_SIGNED_IN,
  user: userData,
});

const handleLogIn = () => async function innerHandleSignIn(dispatch) {
  dispatch(loggingIn());
  let userData = loadUserData();
  if (isSignInPending()) {
    userData = await handlePendingSignIn();
    const user = await User.createWithCurrentUser();
    await GroupMembership.cacheKeys();
    await user.save();
    console.log('new user', user);
    dispatch(gotUserData((user)));
    window.location = '/';
    return user;
  } if (userData) {
    await GroupMembership.cacheKeys();
    const user = User.currentUser();
    await user.save();
    dispatch(gotUserData(user));
    console.log('existing user', user);
    return user;
  }
};

export async function username_new(address) {
  let username = ''
  var config = {
    method: 'get',
    url: `${URL}/v1/addresses/stacks/${address}`,
    headers: {'x-hiro-api-key': 'xcheck_WTpZ9LK4BzykUnbTfpPrEMdPiLjMsqPM'}
  };
  await axios(config)
    .then(respuesta => {
      const {data} = respuesta
      if (data.names[0] === undefined || data.names[0] === null || data.names[0] === ''){
        username =  address.substring(0,5).toLowerCase()+'...'+address.substring(address.length-5).toLowerCase()+'.xck.app'
      }else{
        username =  data.names[0]
      }
    })
    .catch(err => {
        console.log(err)
    })
  return username
}

export default {
  login,
  handleLogIn,
  logout,
};
