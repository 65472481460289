import React from "react";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

class YoutubeEmbed extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <LiteYouTubeEmbed
            id="DFYvJrB2eSc"
            title="Cómo descargar e instalar el Web Wallet"
            poster="maxresdefault"
        />
      </div>
    )
  };
}

export default YoutubeEmbed;
