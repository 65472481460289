import React, {Component} from "react";

//UI
import { Container, Row, Col, Card, Button } from "shards-react";
import { Table } from 'reactstrap';
import { Divider, Typography, TextField, Grid, Dialog,
         DialogContent, DialogActions, DialogTitle, DialogContentText,
         Button as ButtonMaterialUI } from '@material-ui/core';

//chart
import { PieChart, Pie, Sector } from 'recharts';

//Components
//import TituloPagina from "../common/TituloPagina";
import TituloPagina2 from "../common/TituloPagina2";
import SmallStats from "../dashboard/SmallStats";
import DetailDataReceived from "../dashboard/DetailDataReceived";
//import UsersByDevice from "../dashboard/UsersByDevice";
import TopReferrals from "../dashboard/TopReferrals";

//SetTimeOut React
import ReactTimeout from 'react-timeout'
import TimerMixin from 'react-timer-mixin'

// Spinning
//import Loader from '../loader'

//Proptype
import PropTypes from 'prop-types';

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

// Redux
import { connect } from 'react-redux';
import {simulateDatossensores} from "../../actions/sensoresActions"
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'


//Axios (API)
import axios from 'axios';

//Sagas (PACT)
import { fetchPactLogisticConfig,saveNewPactDataSensorTemperature,saveNewPactDataSensor,saveNewPactDataSensorHumidity,saveNewPactDataSensorGascontrol } from '../../actions/pactActions';

//translate
import { FormattedMessage } from 'react-intl';

//Number Format
import NumberFormat from 'react-number-format';

//
import { makeRandomPrivKey } from '@stacks/transactions';

import {username_new} from '../../actions/userActions'

//Configuración
import { SMART_CONTRACT_ADDRESS_MAINNET_STX,
         SMART_CONTRACT_NAME_MAINNET_STX,
         SMART_CONTRACT_ADDRESS_TESTNET_STX,
         SMART_CONTRACT_NAME_TESTNET_STX,
         MAINNET_STACKS_API_URL,
         TESTNET_STACKS_API_URL } from '../../config.js'

//Clarity
import {transfer, balanceOf} from "../../clarity/clarityfunctions"


function ConfirmacionActiveDashboard(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Container fluid className="px-0">
                    <Card theme="warning" >
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              &nbsp;&nbsp;<div className="fa fa-info"></div>
                            </td>
                            <td>
                               &nbsp;&nbsp;<FormattedMessage id="usergroup.message" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                </Container>
                <Table size="sm" className="text-left" responsive hover striped bordered style={{ color: 'blue', fontSize:11 }}>
                    <tbody>
                      <tr>
                        <th style={{ width: "40%", textAlign:"left", color: 'blue', fontSize:11 }}><FormattedMessage id="useraction.network" /></th>
                        <th style={{ width: "60%", textAlign:"right", color: 'black', fontSize:13 }}>{props.addressX}</th>
                      </tr>
                    </tbody>
                </Table>
                <Table size="sm" className="text-center" responsive hover striped bordered style={{ color: 'black', fontSize:11 }}>
                    <thead>
                      <tr style={{ textAlign:"center", color: 'blue', fontSize:15 }}>
                        <th><FormattedMessage id="usergroup.concept" /></th>
                        <th>{props.cryptoPactClarityEthereumX}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="pay.previousbalance" /></td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}><NumberFormat value={props.balanceX} displayType={'text'} thousandSeparator={true} /> {props.cryptoPactClarityEthereum2X}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="pay.amounttransfer" /></td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}>{props.transactionX} {props.cryptoPactClarityEthereum2X}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%", textAlign:"left", color: 'black', fontSize:13 }}><FormattedMessage id="pay.commission" /></td>
                        <td style={{ width: "40%", textAlign:"right", color: 'black', fontSize:13 }}>{props.commissionX} {props.cryptoPactClarityEthereum2X}</td>
                      </tr>
                    </tbody>
                </Table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel', props.cryptoPactClarityEthereum2X, props.saldoX)} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button disabled={props.disableTransferX} onClick={() => props.handleDialog(props.dialogTitle, 'pay', props.cryptoPactClarityEthereum2X, props.saldoX)} theme="success">
                    <FormattedMessage id="alert.pay" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}


class ContractDashboard extends Component {
     constructor(props) {
       super(props);

       this.getTrackingContract = this.getTrackingContract.bind(this);
       this.getContractIoTDevice = this.getContractIoTDevice.bind(this);
       this.getMasterFileSensor = this.getMasterFileSensor.bind(this);
       this.getMasterFileDevice = this.getMasterFileDevice.bind(this);
       this.getAPIDataSensor = this.getAPIDataSensor.bind(this);
       this.getDataSensor = this.getDataSensor.bind(this);
       this.putDataSensor = this.putDataSensor.bind(this);
       this.arrayPlusDelay1 = this.arrayPlusDelay1.bind(this);
       this.arrayPlusDelay2 = this.arrayPlusDelay2.bind(this);
       this.arrayPlusDelay3 = this.arrayPlusDelay3.bind(this);
       this.messageError = this.messageError.bind(this);
       this.exitProgram = this.exitProgram.bind(this);
       this.exitIdentification = this.exitIdentification.bind(this);

       this.state = {
         userSession: new UserSession(),
         username: '',
         user: {},
         isLoading: false,
         displayDashboard: false,
         jsonListContract: [],
         jsonContractDevice: undefined,
         jsonMasterFileSensor: undefined,
         jsonMasterFileDEvice: undefined,
         jsonTrackingContract: undefined,
         jsonDataSensor: undefined,
         datasensor: [],
         dataJsonX: [],
         verificationRules: [],
         dataCustomActiveShapePieChart: [],
         existContractIoTDevice: true,
         selectedSearch: 'switchBlockstack',
         showAlert: false,
         showLoader:false,
         smallStats2: [],
         referralData: [],
         doughnutData: [],
         chartData: [],
         mensajeErrorX: '',
         errorMensaje2: false,
         errorMensaje3: false,
         errorMensaje4: false,
         errorMensaje5: false,
         activeIndex: 1,
         colorCustomActiveShapePieChart: "#03E1BC",
         jsonEnrollment: [],
         displayLink: false,
         enrollment: '',
         jsonContractRules: [],
         existContractRules: false,
         displayContractRules: false,
         language: 'English',
         dialogOpen: false,
         dialogMessage: '',
         dialogTitle: '',
         displayActiveDashboardImpide: false,
         balanceCta: 0,
         resultTransfer: '',
         stacksAddress: '',
         privateKey: '',
         contractAddressX: '',
         addressKeyX: '',
         networkUrlX: '',
         contractNameX: '',
         contractAddressX2: '',
         serverStacksX: '',
         usernameX: '',
         userOrigin: '',
         typeContract: '',
         numberContract: '',
       }
     }

     exitIdentification(){
       this.setState({displayDashboard: false})
       TimerMixin.clearTimeout();
     }

     goRulesIoTDevice(sExiste,jsonBlockstack4) {
       if (sExiste === 'existe') {
           const {userSession} = this.state
           const storage = new Storage({ userSession });
           let jsonBlockstack4c = []
           let jsonLink = []
           jsonBlockstack4.map ((todo, key) => {
              let options = ''
              if (todo.typeContract !== 'simulate'){
                 if (this.state.userOrigin !== this.state.username ){
                    options = { username: this.state.userOrigin, decrypt: false, verify: false }
                 }else{
                    options = { decrypt: false, verify: false }
                 }
               }else{
                 options = { decrypt: false, verify: false }
              }

              if (todo.active==="true"){
                    getFileDB(this.state.username, `${todo.typeContract}_${todo.configurationContractNumber}_device.json`)
                      .then((fileContents) => {
                        if(fileContents) {
                           const jsonBlockstack1 = JSON.parse(fileContents)
                           const jsonBlockstack2 = jsonBlockstack1.data
                           let jsonBlockstack5 = '[]'
                           if (jsonBlockstack2 !== null){
                              const jsonBlockstack3 = jsonBlockstack2.dataobject
                              const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                              if (jsonBlockstack4.substring(0,1) === '"') {
                                 jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                              } else {
                                 jsonBlockstack5 = jsonBlockstack4
                              }
                           }
                           let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                           jsonBlockstack4c = JSON.parse(jsonBlockstack5)
                           if (fileContents==='[]'){
                              jsonLink = [...jsonLink, {"typecontract": `${todo.typeContract}`,"contractnumber": `${todo.configurationContractNumber}`,"link":"images/linkbrokenRed.png","sensor":"images/sensorRed.png"}]
                           } else {
                             let yesSensor = false
                             let noSensor = false
                             jsonBlockstack4c.map((todo,i) => {
                               if (todo.activate){
                                  yesSensor = true
                               }else{
                                  noSensor = true
                               }
                             })
                             if (yesSensor && noSensor){
                                jsonLink = [...jsonLink, {"typecontract": `${todo.typeContract}`,"contractnumber": `${todo.configurationContractNumber}`,"link":"images/linkOrange.png","sensor":"images/sensorOrange.png"}]
                             }
                             if (yesSensor && !noSensor){
                                jsonLink = [...jsonLink, {"typecontract": `${todo.typeContract}`,"contractnumber": `${todo.configurationContractNumber}`,"link":"images/linkGreen.png","sensor":"images/sensorGreen.png"}]
                             }
                             if (!yesSensor && noSensor){
                                jsonLink = [...jsonLink, {"typecontract": `${todo.typeContract}`,"contractnumber": `${todo.configurationContractNumber}`,"link":"images/linkGreen.png","sensor":"images/sensorRed.png"}]
                             }
                             if (!yesSensor && !noSensor){
                                jsonLink = [...jsonLink, {"typecontract": `${todo.typeContract}`,"contractnumber": `${todo.configurationContractNumber}`,"link":"images/linkGreen.png","sensor":"images/sensorRed.png"}]
                             }
                           }
                        } else {
                           jsonLink = [...jsonLink, {"typecontract": `${todo.typeContract}`,"contractnumber": `${todo.configurationContractNumber}`,"link":"images/linkbrokenRed.png","sensor":"images/sensorRed.png"}]
                        }
                        this.setState({jsonLinkZ50: jsonLink})
                      })
                     .catch(error => {
                      });
              }else{
                 jsonLink = [...jsonLink,{"typecontract": `${todo.typeContract}`,"contractnumber": `${todo.configurationContractNumber}`,"link":"","sensor":""}]
                 this.setState({jsonLinkZ50: jsonLink})
              }
           })
       }
     }

     UNSAFE_componentWillReceiveProps() {
       this.setState({ userOrigin: this.props.userOrigin, typeContract: this.props.typeContract, numberContract: this.props.numberContract})
     }


     UNSAFE_componentWillMount() {
       const { userSession } = this.state
       let stacksNetX = ''
       let serverStacksX = ''
       let networkUrlX = ''

       if (userSession.isUserSignedIn()) {
         const user = userSession.loadUserData()
         this.setState({ user })
         if (!localStorage["serverStacks"]) {
           serverStacksX = 'MainNet'
         }else{
           serverStacksX = localStorage.getItem('serverStacks')
         }
         if (serverStacksX === 'MainNet'){
           networkUrlX = MAINNET_STACKS_API_URL
   	       stacksNetX =  user.profile.stxAddress.mainnet
         }
         if (serverStacksX === 'TestNet'){
           networkUrlX = TESTNET_STACKS_API_URL
   	       stacksNetX =  user.profile.stxAddress.testnet
         }
       }

       this.setState({networkUrlX})

       const languageX = localStorage.getItem('language')
       if (languageX === undefined || languageX === ''){
         this.setState({ language: 'English',newcontract: 'Select here for new contract' })
       }else{
         this.setState({ language: languageX })
       }

       this.setState({displayActiveDashboardImpide: null})

       const {username} = userSession.loadUserData();
       if (username === '' || username === undefined || username === null){
         username_new(stacksNetX).then(val => this.setState({username: val},()=>{
           if (this.props.activeDataDashboardX === false){
             Promise.all(
                [this.readContractList(val)
             ]
             ).then(
               (resolve) =>{
               },
               (reject) =>{
               }
             )
           }
         })) ;
       }else{
         this.setState({ username },()=>{
           if (this.props.activeDataDashboardX === false){
             Promise.all(
                [this.readContractList(username)
             ]
             ).then(
               (resolve) =>{
               },
               (reject) =>{
               }
             )
           }
         })
       }

       const referralData = `{"title": "Number data imported from the Sensors",
         "referralData": [
           {
             "title": "Temperature",
             "value": "-"
           },
           {
             "title": "Humidity",
             "value": "-"
           },
           {
             "title": "Gas Control",
             "value": "-"
           },
           {
             "title": "Impact",
             "value": "-"
           },
           {
             "title": "Vibration",
             "value": "-"
           },
           {
             "title": "Proximity",
             "value": "-"
           },
           {
             "title": "Seal Integrity",
             "value": "-"
           },
           {
             "title": "GPS",
             "value": "-"
           }
       ]}`

      this.setState({ referralData: JSON.parse(referralData) })

      const chartData = `{
        "title": "Graph Data Received",
        "chartData": {
          "labels": "Array.from(new Array(30), (_, i) => (i === 0 ? 1 : i))",
          "datasets": [
            {
              "label": "Temperature",
              "fill": "start",
              "data": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "backgroundColor": "rgba(0,123,255,0.1)",
              "borderColor": "rgba(0,123,255,1)",
              "pointBackgroundColor": "#ffffff",
              "pointHoverBackgroundColor": "rgb(0,123,255)",
              "borderWidth": 1.5,
              "pointRadius": 0,
              "pointHoverRadius": 3
            },
            {
              "label": "Humidity",
              "fill": "start",
              "data": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "backgroundColor": "rgba(255,65,105,0.1)",
              "borderColor": "rgba(255,65,105,1)",
              "pointBackgroundColor": "#ffffff",
              "pointHoverBackgroundColor": "rgba(255,65,105,1)",
              "borderDash": [3, 3],
              "borderWidth": 1,
              "pointRadius": 0,
              "pointHoverRadius": 2,
              "pointBorderColor": "rgba(255,65,105,1)"
            }
          ]}
        }`

        this.setState({ chartData: JSON.parse(chartData) })

        const dataJsonX = `{"data": [
                {"json": ""},
                {"json": ""},
                {"json": ""},
                {"json": ""},
                {"json": ""},
                {"json": ""},
                {"json": ""},
                {"json": ""},
                {"json": ""}
           ]}`

           this.setState({ dataJsonX: JSON.parse(dataJsonX) })

           const verificationRules = `{
                "title": "Verification of Rules",
                "chartData": {
                  "datasets": [
                    {
                      "hoverBorderColor": "#ffffff",
                      "data": [58.3, 41.7],
                      "backgroundColor": [
                        "rgba(0,123,255,0.9)",
                        "rgba(0,123,255,0.5)"
                      ]
                    }
                  ],
                  "labels": ["Within Range", "Out of Range"]
                }
              }`

           this.setState({ verificationRules: JSON.parse(verificationRules) })

           const dataCustomActiveShapePieChart = `[
             { "name": "Within Range", "value": 10 },
             { "name": "Out of Range", "value": 12 }
           ]`;

           this.setState({ dataCustomActiveShapePieChart: JSON.parse(dataCustomActiveShapePieChart) })

     }

     componentDidMount() {
     }

     componentWillUnmount() {
       TimerMixin.clearTimeout();

     }

     readContractList(username){
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = { decrypt: false, verify: false }
       let jsonBlockstack4 = []
       return new Promise ((resolve, reject) =>{
           getFileDB(username,`contractlist.json`)
              .then((fileContents) => {
                if(fileContents) {
                  this.setState({existListContract: true})
                  const jsonBlockstack1 = JSON.parse(fileContents)
                  const jsonBlockstack2 = jsonBlockstack1.data
                  let jsonBlockstack5 = '[]'
                  if (jsonBlockstack2 !== null){
                     const jsonBlockstack3 = jsonBlockstack2.dataobject
                     const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                     if (jsonBlockstack4.substring(0,1) === '"') {
                        jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                     } else {
                        jsonBlockstack5 = jsonBlockstack4
                     }
                  }
                  let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                  let largo = jsonBlockstack6.length
                  let encontro = false
                  jsonBlockstack6.map((todo, i) => {
                     if (todo.typeContract === this.state.typeContract && todo.configurationContractNumber === this.state.numberContract) {
                        if (todo.activeDashboard === true){
                          this.setState({displayActiveDashboardImpide: true})
                          encontro = true
                        }else{
                          this.setState({displayActiveDashboardImpide: false})
                          encontro = true
                        }
                     }
                     if (encontro === false && (i === largo-1)){
                       this.setState({displayActiveDashboardImpide: false})
                       resolve();
                     }else{
                       if (encontro === true && (i === largo-1)){
                         resolve();
                       }
                     }
                  })
                }else{
                  this.setState({displayActiveDashboardImpide: false})
                  resolve();
                }
              })
             .catch(error => {
                 resolve();
             });
       });
     }

     handleDialog2 = (type, action, cryptoX, saldoX) => {
       if (type === 'Activate Dashboard') {
         if (action === 'pay') {
           this.setState({ dialogOpen: false });
           this.actualizarActiveDashboard(cryptoX, saldoX)
         }
         if (action === 'cancel') {
           this.setState({ dialogOpen: false, displayActiveDashboardImpide: false, });
         }
       }
     }

     actualizarActiveDashboard = (cryptoX, saldoX) => {
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
       let myUserConfig = this.props.myUserConfig
       let balanceSTX2 = myUserConfig.balanceSTX
       let balanceKDA2 = myUserConfig.balanceKDA
       let balanceETH2 = myUserConfig.balanceETH
       if (cryptoX==='STX'){balanceSTX2 = saldoX}
       if (cryptoX==='KDA'){balanceKDA2 = saldoX}
       if (cryptoX==='ETH'){balanceETH2 = saldoX}
       let SMSNotifications = myUserConfig.smsNotifications
       let EmailNotifications = myUserConfig.emailNotifications
       let cryptoSTX = myUserConfig.STX
       let cryptoKDA = myUserConfig.KDA
       let cryptoETH = myUserConfig.ETH
       let cryptoBCT = myUserConfig.BTC
       let profileName = myUserConfig.profileName
       let profileAvatar = myUserConfig.profileAvatar
       const jsonBlockstack =`{"emailNotifications":"${EmailNotifications}","smsNotifications":"${SMSNotifications}","STX":"${cryptoSTX}","BTC":"${cryptoBCT}","KDA":"${cryptoKDA}","ETH":"${cryptoETH}","balanceSTX":"${balanceSTX2}","balanceKDA":"${balanceKDA2}","balanceETH":"${balanceETH2}","profileName":"${profileName}","profileAvatar":"${profileAvatar}","facebook":"${myUserConfig.facebook}","twitter":"${myUserConfig.twitter}","youtube":"${myUserConfig.youtube}","instagram":"${myUserConfig.instagram}","linkedin":"${myUserConfig.linkedin}","pinterest":"${myUserConfig.pinterest}","aboutme":"${myUserConfig.aboutme}","smsScope":"${myUserConfig.smsScope}","emailScope":"${myUserConfig.emailScope}","smsCountry":"${myUserConfig.smsCountry}","smsPrefix":"${myUserConfig.smsPrefix}","stxScope":"${myUserConfig.stxScope}","btcScope":"${myUserConfig.btcScope}","webSite":"${myUserConfig.webSite}","applicationsScope":"${myUserConfig.applicationsScope}"}`;
       putFileDB(this.state.username, `myUserConfig.json`, 'Crosscheck_Dashboard', JSON.stringify(jsonBlockstack), JSON.stringify(options))
        .then(() => {
          this.actualizarActiveDashboard2()
        })
     }

     actualizarActiveDashboard2 = () => {
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = { decrypt: false, verify: false }
       let jsonBlockstack4 = []
       getFileDB(this.state.username, `contractlist.json`)
          .then((fileContents) => {
            if(fileContents) {
              this.setState({existListContract: true})
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              jsonBlockstack6.map((todo, i) => {
                 if (todo.typeContract === this.state.typeContract && todo.configurationContractNumber === this.state.numberContract) {
                    todo.activeDashboard = true
                    this.actualizarActiveDashboard3(jsonBlockstack6)
                 }
              })
            }
          })
         .catch(error => {
         });
     }

     actualizarActiveDashboard3 = (jsonBlockstack4) => {
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
       putFileDB(this.state.username, `contractlist.json`, 'Crosscheck_Dashboard', JSON.stringify(jsonBlockstack4), JSON.stringify(options))
         .then(() => {
            this.setState({displayActiveDashboardImpide: true})
         })
         .catch(error => {});
     }

     activeDashboard = () => {
       this.setState({ dialogOpen: true, dialogMessage: '', dialogTitle: 'Activate Dashboard', })
     }

     getRandomInt (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
     }

     handleChangeOption(optSearch) {
         this.setState({
           selectedSearch: optSearch,
           showAlert: false,
           showLoader:false,
           displayDashboard: false,
         });
     }

     async getTrackingContract(enrollment,dashboard) {
         if (dashboard==='images/eyeGreen.png'){
             const {userSession} = this.state
             const storage = new Storage({ userSession });
             let options = ''
             if (this.state.typeContract !== 'simulate'){
               if (this.state.userOrigin !== this.state.username ){
                  options = { username: this.state.userOrigin, decrypt: false, verify: false }
               }else{
                  options = { decrypt: false, verify: false }
               }
             }else{
               options = { decrypt: false, verify: false }
             }
             this.setState({jsonTrackingContract: undefined,errorMensaje2: false,enrollment: enrollment})
             let jsonBlockstack4 = []
             await getFileDB(this.state.username, `${this.state.typeContract}_${this.state.numberContract}.json`)
                .then((fileContents) => {
                  if(fileContents) {
                    const jsonBlockstack1 = JSON.parse(fileContents)
                    const jsonBlockstack2 = jsonBlockstack1.data
                    let jsonBlockstack5 = '[]'
                    if (jsonBlockstack2 !== null){
                       const jsonBlockstack3 = jsonBlockstack2.dataobject
                       const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                       if (jsonBlockstack4.substring(0,1) === '"') {
                          jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                       } else {
                          jsonBlockstack5 = jsonBlockstack4
                       }
                    }
                    let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                    this.setState({jsonTrackingContract: jsonBlockstack6})
                   //-------------------------
                    this.getContractIoTDevice(jsonBlockstack6,enrollment)
                   //-------------------------
                  } else {
                    //console.log(`No Data (${this.state.typeContract}_${this.state.numberContract}.json)`);
                    this.setState({showLoader:false,
                                   mensajeErrorX: 'Inconsistent Data: Contract Detail not found',
                                   displayDashboard: false,
                                   errorMensaje2: true })
                  }
                })
                 .catch(error => {
                });
          }
     }

     async getContractIoTDevice(jsonTrackingContractX,enrollment) {
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       this.setState({displayContractIoTDevice: true,
                      idx: this.state.numberContract,
                      jsonContractDevice: undefined})
       let jsonBlockstack4 = []
       let options = ''
       if (this.state.typeContract !== 'simulate'){
         if (this.state.userOrigin !== this.state.username ){
            options = { username: this.state.userOrigin, decrypt: false, verify: false }
         }else{
            options = { decrypt: false, verify: false }
         }
       }else{
         options = { decrypt: false, verify: false }
       }
       await getFileDB(this.state.username, `${this.state.typeContract}_${this.state.numberContract}_${enrollment}_device.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonContractDevice: jsonBlockstack6,
                             existContractIoTDevice: true })

              //-------------------------
              this.getContractRules(jsonTrackingContractX,enrollment)
              //-------------------------
            } else {
                this.setState({showLoader:false,
                               displayDashboard: false,
                               existContractIoTDevice: false })
            }
          })
           .catch(error => {
          });
     }

     async getContractRules(jsonTrackingContractX,enrollment) {
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       this.setState({displayContractRules: true,
                      idx: this.state.numberContract,
                      jsonContractRules: undefined})
       let jsonBlockstack4 = []
       let jsonTrackingContractRules = []
       let options = ''
       if (this.state.typeContract !== 'simulate'){
         if (this.state.userOrigin !== this.state.username ){
            options = { username: this.state.userOrigin, decrypt: false, verify: false }
         }else{
            options = { decrypt: false, verify: false }
         }
       }else{
         options = { decrypt: false, verify: false }
       }
       await getFileDB(this.state.username, `${this.state.typeContract}_${this.state.numberContract}_rules.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              jsonBlockstack6.map((todo,i)=>{
                if(todo.enrollment===enrollment){
                  jsonTrackingContractRules=jsonBlockstack6[i]
                }
              })
              this.setState({jsonContractRules: jsonBlockstack6,existContractRules: true })
              //-------------------------
              this.getMasterFileSensor(jsonTrackingContractRules,jsonBlockstack6,enrollment)
              //-------------------------
            } else {
                this.setState({showLoader:false,
                               displayDashboard: false,
                               existContractRules: false })
            }
          })
           .catch(error => {
          });
     }

     async getMasterFileSensor(jsonTrackingContractX,jsonContractRulesX,enrollment) {
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       this.setState({jsonMasterFileSensor: undefined,errorMensaje3: false })
       let jsonBlockstack4 = []
       let options = ''
       if (this.state.typeContract !== 'simulate'){
         if (this.state.userOrigin !== this.state.username ){
            options = { username: this.state.userOrigin, decrypt: false, verify: false }
         }else{
            options = { decrypt: false, verify: false }
         }
       }else{
         options = { decrypt: false, verify: false }
       }
       await getFileDB(this.state.username, `sensor_id.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonMasterFileSensor: jsonBlockstack6})
              //-------------------------
              this.getMasterFileDevice(jsonTrackingContractX,jsonContractRulesX,jsonBlockstack6,enrollment)
              //-------------------------
            } else {
              this.setState({mensajeErrorX: 'Inconsistent Data: Master File Sensors not found',
                             showLoader:false,
                             displayDashboard: false,
                             errorMensaje3: true })
            }
          })
           .catch(error => {
          });
     }

     async getMasterFileDevice(jsonTrackingContractX,jsonContractRulesX,jsonMasterFileSensorX,enrollment) {
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       this.setState({jsonMasterFileDevice: undefined,errorMensaje4: false })
       let jsonBlockstack4 = []
       let jsonContractDeviceX = []
       let options = ''
       if (this.state.typeContract !== 'simulate'){
         if (this.state.userOrigin !== this.state.username ){
            options = { username: this.state.userOrigin, decrypt: false, verify: false }
         }else{
            options = { decrypt: false, verify: false }
         }
       }else{
         options = { decrypt: false, verify: false }
       }
       await getFileDB(this.state.username, `sensor_category.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonMasterFileDevice: jsonBlockstack6})
              //-------------------------
              this.getDataSensor(jsonTrackingContractX,jsonContractRulesX,jsonContractDeviceX,jsonMasterFileSensorX,jsonBlockstack6,enrollment)
              //-------------------------
            } else {
              this.setState({mensajeErrorX: 'Inconsistent Data: Master File Devices not found',
                             showLoader:false,
                             displayDashboard: false,
                             errorMensaje4: true })
            }
          })
           .catch(error => {
           });
     }

     async getDataSensor(jsonTrackingContractX,jsonContractRulesX,jsonContractDeviceX,jsonMasterFileSensorX,jsonMasterFileDeviceX,enrollment) {
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       this.setState({jsonDataSensor: undefined,errorMensaje5: false })
       let jsonBlockstack6 = []
       let options = ''
       if (this.state.typeContract !== 'simulate'){
         if (this.state.userOrigin !== this.state.username ){
            options = { username: this.state.userOrigin, decrypt: false, verify: false }
         }else{
            options = { decrypt: false, verify: false }
         }
       }else{
         options = { decrypt: false, verify: false }
       }
       await getFileDB(this.state.username, `data_sensor_${this.state.numberContract}.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonDataSensor: jsonBlockstack6})
              //-------------------------
              this.getAPIDataSensor(jsonTrackingContractX,jsonContractRulesX,jsonContractDeviceX,jsonMasterFileSensorX,jsonMasterFileDeviceX,jsonBlockstack6,enrollment)
              //-------------------------
            } else {
              //-------------------------
              this.getAPIDataSensor(jsonTrackingContractX,jsonContractRulesX,jsonContractDeviceX,jsonMasterFileSensorX,jsonMasterFileDeviceX,jsonBlockstack6,enrollment)
              //-------------------------
            }
          })
         .catch(error => {
         });
     }

     async getAPIDataSensor(jsonTrackingContractX,jsonContractRulesX,jsonContractDeviceX,jsonMasterFileSensorX,jsonMasterFileDeviceX,jsonDataSensorX,enrollment) {
       //this.props.simulateDatossensores()
       let datasensor = []
       const contractNumber = this.state.numberContract
       await axios.get(`https://xck.app:8443/data`)
         .then(res => {
           datasensor = res.data;
           this.setState({ datasensor: datasensor,displayDashboard: true,showLoader:false});
           if (Object.keys(datasensor).length === 0) {
               this.setState({mensajeErrorX: 'Error: API Server Data sensor not found',
                              displayDashboard: false,
                              errorMensaje5: true })
           }
           clearTimeout()
           //-------------------------
           this.arrayPlusDelay1(datasensor, 5000, contractNumber, jsonTrackingContractX,jsonContractRulesX,jsonContractDeviceX,jsonMasterFileSensorX,jsonMasterFileDeviceX,jsonDataSensorX,enrollment)
           //-------------------------
         })
     }

     arrayPlusDelay1 = (array, delay, contractNumber, jsonTrackingContractX,jsonContractRulesX,jsonContractDeviceX,jsonMasterFileSensorX,jsonMasterFileDeviceX,jsonDataSensorX,enrollment) => {
       let valorTemperature = ''
       let valorHumidity = ''
       let valorGasControl = ''
       let idTypeSensorTemperature='Temperature'
       let idTypeSensorHumidity='Humidity'
       let idTypeSensorGasControl='Gas Control'
       let dataTemperature = [10, 7, 1, 5, 6, 2, 8]
       let dataHumidity = [17, 18, 15, 21, 20, 23, 15]
       let dataGasControl = [11, 12, 14, 17, 13, 17, 19]
       let variationTemperature = 0
       let variationHumidity = 0
       let variationGasControl = 0
       let countTemperature = 0
       let countHumidity = 0
       let countGasControl = 0
       let decreaseTemperature = false
       let increaseTemperature = false
       let decreaseHumidity = false
       let increaseHumidity = false
       let decreaseGasControl = false
       let increaseGasControl = false
       let countBadTemperature = 0
       let countBadHumidity = 0
       let countBadGascontrol = 0
       let countGoodTemperature = 0
       let countGoodHumidity = 0
       let countGoodGascontrol = 0

       let dataJson = ['','','', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']
       let charDataValue = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
       let charDataValue2 = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
       let charDataValue3 = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]

       let unitTemperature = ''
       let unitHumidity = ''
       let unitGasControl = ''
       let unitMeasurement = ''

       let temperatureCheck = ''
       let humidityCheck = ''
       let gascontrolCheck = ''
       //let impactCheck = ''
       //let vibrationCheck = ''
       //let proximityCheck = ''
       //let gpsCheck = ''
       //let sealIntegrityCheck = ''

        array.forEach( (el, i) => {
          TimerMixin.setTimeout( () => {
              let idTypeSensor = ''
              //let idTypeDevice = ''
              if (array[i].sensorid === jsonMasterFileSensorX.code){
                 idTypeSensor = jsonMasterFileSensorX.description
              }
              //if (array[i].deviceid === jsonContractDeviceX.code){
              //   idTypeDevice = jsonContractDeviceX.description
              //}

              unitTemperature = jsonTrackingContractX.temperatureUnitMeasurement
              unitHumidity = jsonTrackingContractX.humidityUnitMeasurement
              unitGasControl = jsonTrackingContractX.gascontrolUnitMeasurement

              temperatureCheck = jsonTrackingContractX.temperature
              humidityCheck = jsonTrackingContractX.humidity
              gascontrolCheck = jsonTrackingContractX.gascontrol
              //impactCheck = jsonTrackingContractX.impact
              //vibrationCheck = jsonTrackingContractX.vibration
              //proximityCheck = jsonTrackingContractX.proximity
              //gpsCheck = jsonTrackingContractX.gps
              //sealIntegrityCheck = jsonTrackingContractX.sealIntegrity

              const idSensor = array[i].sensorid,
                    idTrackingContract = contractNumber,
                    idContainer = jsonTrackingContractX.idContainer,
                    //idBillOfLading = jsonTrackingContractX.idBillOfLading,
                    idCaller = array[i].deviceid,
                    idCategory = array[i].deviceid,
                    valorSensor = parseFloat(array[i].value),
                    //valorSensor = array[i].value,
                    dateDataSensor = array[i].date,
                    latitudDataSensor = parseFloat(array[i].latitude),
                    longituDataSensor = parseFloat(array[i].longitude)
                    //latitudDataSensor = array[i].latitude,
                    //longituDataSensor = array[i].longitude

                    let idPact = null
                    let temperatureLowerThreshold = null
                    let temperatureUpperThreshold = null
                    let temperatureActiveRule = null
                    let humidityLowerThreshold = null
                    let humidityUpperThreshold = null
                    let humidityActiveRule = null
                    let gascontrolLowerThreshold = null
                    let gascontrolUpperThreshold = null
                    let gascontrolActiveRule = null


                    if (this.state.selectedSearch==='switchPACT'){
                        this.props.smartcontract.map((todo, i) => {
                            if (todo.configurationContractNumber === contractNumber) {
                              temperatureLowerThreshold = todo.temperatureLowerThreshold;
                              temperatureUpperThreshold = todo.temperatureUpperThreshold;
                              temperatureActiveRule = todo.temperatureActiveRule;
                              humidityLowerThreshold = todo.humidityLowerThreshold;
                              humidityUpperThreshold = todo.humidityUpperThreshold;
                              humidityActiveRule = todo.humidityActiveRule;
                              gascontrolLowerThreshold = todo.gascontrolLowerThreshold;
                              gascontrolUpperThreshold = todo.gascontrolUpperThreshold;
                              gascontrolActiveRule = todo.gascontrolActiveRule;
                              idPact = todo.id;
                              //idPact = todo.id.toString();
                            }
                            return idPact
                        })
                    }else{
                        temperatureLowerThreshold = jsonTrackingContractX.temperatureLowerThreshold;
                        temperatureUpperThreshold = jsonTrackingContractX.temperatureUpperThreshold;
                        if (jsonTrackingContractX.temperatureActiveRule==='true'){
                           temperatureActiveRule = true;
                        }else{
                          temperatureActiveRule = false;
                        }
                        humidityLowerThreshold = jsonTrackingContractX.humidityLowerThreshold;
                        humidityUpperThreshold = jsonTrackingContractX.humidityUpperThreshold;
                        if (jsonTrackingContractX.humidityActiveRule==='true'){
                           humidityActiveRule = true;
                        }else{
                          humidityActiveRule = false;
                        }
                        gascontrolLowerThreshold = jsonTrackingContractX.gascontrolLowerThreshold;
                        gascontrolUpperThreshold = jsonTrackingContractX.gascontrolUpperThreshold;
                        if (jsonTrackingContractX.gascontrolActiveRule==='true'){
                           gascontrolActiveRule = true;
                        }else{
                          gascontrolActiveRule = false;
                        }
                    }
              //-----------------------
              // Cálculos para PACT
              //-----------------------
              let statusRuleTemperatureLower = false
              let statusRuleTemperatureUpper = false
              let statusRuleHumidityLower = false
              let statusRuleHumidityUpper = false
              let statusRuleGasControlLower = false
              let statusRuleGasControlUpper = false

              if (idSensor === "1") {
                  unitMeasurement = unitTemperature
                if (temperatureActiveRule===true){
                  if (valorSensor < parseFloat(temperatureLowerThreshold)){
                     statusRuleTemperatureLower = true
                     countBadTemperature = countBadTemperature + 1
                  }
                  if (valorSensor > parseFloat(temperatureUpperThreshold)){
                     statusRuleTemperatureUpper = true
                     countBadTemperature = countBadTemperature + 1
                  }
                  if (valorSensor >= parseFloat(temperatureLowerThreshold) &&  valorSensor <= parseFloat(temperatureUpperThreshold)){
                     countGoodTemperature = countGoodTemperature + 1
                  }
                }
              }
              if (idSensor === "2") {
                unitMeasurement = unitHumidity
                if (humidityActiveRule===true){
                  if (valorSensor < parseFloat(humidityLowerThreshold)){
                     statusRuleHumidityLower = true
                     countBadHumidity = countBadHumidity + 1
                  }
                  if (valorSensor > parseFloat(humidityUpperThreshold)){
                     statusRuleHumidityUpper = true
                     countBadHumidity = countBadHumidity + 1
                  }
                  if (valorSensor >= parseFloat(humidityLowerThreshold) &&  valorSensor <= parseFloat(humidityUpperThreshold)){
                     countGoodHumidity = countGoodHumidity + 1
                  }

                }
              }
              if (idSensor === "3") {
                unitMeasurement = unitGasControl
                if (gascontrolActiveRule===true){
                  if (valorSensor < parseFloat(gascontrolLowerThreshold)){
                     statusRuleGasControlLower = true
                     countBadGascontrol = countBadGascontrol + 1
                  }
                  if (valorSensor > parseFloat(gascontrolUpperThreshold)){
                     statusRuleGasControlUpper = true
                     countBadGascontrol = countBadGascontrol + 1
                  }
                  if (valorSensor >= parseFloat(gascontrolLowerThreshold) &&  valorSensor <= parseFloat(gascontrolUpperThreshold)){
                     countGoodGascontrol = countGoodGascontrol + 1
                  }
                }
              }

              //-----------------------
              // Cálculos para gráficos
              //-----------------------
              if (array[i].sensorid === '1'){
                 valorTemperature = valorSensor
                 countTemperature = countTemperature + 1
                 dataTemperature[0] = dataTemperature[1]
                 dataTemperature[1] = dataTemperature[2]
                 dataTemperature[2] = dataTemperature[3]
                 dataTemperature[3] = dataTemperature[4]
                 dataTemperature[4] = dataTemperature[5]
                 dataTemperature[5] = dataTemperature[6]
                 dataTemperature[6] = parseFloat(array[i].value)
                 if (dataTemperature[5]===0) {
                     variationTemperature =  dataTemperature[6] * 100
                 }else{
                     variationTemperature =  ((dataTemperature[6]-dataTemperature[5])/dataTemperature[5]) * 100
                 }
                 if (dataTemperature[6] <  dataTemperature[5]) {
                   decreaseTemperature = true
                   increaseTemperature = false
                 }else{
                   if (dataTemperature[6] ===  dataTemperature[5]) {
                     decreaseTemperature = false
                     increaseTemperature = false
                   }else{
                     if (dataTemperature[6] >  dataTemperature[5]) {
                       decreaseTemperature = false
                       increaseTemperature = true
                     }
                   }
                 }

                 charDataValue[0] = charDataValue[1]
                 charDataValue[1] = charDataValue[2]
                 charDataValue[2] = charDataValue[3]
                 charDataValue[3] = charDataValue[4]
                 charDataValue[4] = charDataValue[5]
                 charDataValue[5] = charDataValue[6]
                 charDataValue[6] = charDataValue[7]
                 charDataValue[7] = charDataValue[8]
                 charDataValue[8] = charDataValue[9]
                 charDataValue[9] = charDataValue[10]
                 charDataValue[10] = charDataValue[11]
                 charDataValue[11] = charDataValue[12]
                 charDataValue[12] = charDataValue[13]
                 charDataValue[13] = charDataValue[14]
                 charDataValue[14] = charDataValue[15]
                 charDataValue[15] = charDataValue[16]
                 charDataValue[16] = charDataValue[17]
                 charDataValue[17] = charDataValue[18]
                 charDataValue[18] = charDataValue[19]
                 charDataValue[19] = charDataValue[20]
                 charDataValue[20] = charDataValue[21]
                 charDataValue[21] = charDataValue[22]
                 charDataValue[22] = charDataValue[23]
                 charDataValue[23] = charDataValue[24]
                 charDataValue[24] = charDataValue[25]
                 charDataValue[25] = charDataValue[26]
                 charDataValue[26] = charDataValue[27]
                 charDataValue[27] = charDataValue[28]
                 charDataValue[28] = charDataValue[29]
                 charDataValue[29] = parseFloat(array[i].value)

              }else{
                if (array[i].sensorid === '2'){
                   valorHumidity = valorSensor
                   countHumidity = countHumidity + 1
                   dataHumidity[0] = dataHumidity[1]
                   dataHumidity[1] = dataHumidity[2]
                   dataHumidity[2] = dataHumidity[3]
                   dataHumidity[3] = dataHumidity[4]
                   dataHumidity[4] = dataHumidity[5]
                   dataHumidity[5] = dataHumidity[6]
                   dataHumidity[6] = parseFloat(array[i].value)
                   if (dataHumidity[5]===0) {
                       variationHumidity =  dataHumidity[6] * 100
                   }else{
                       variationHumidity =  ((dataHumidity[6]-dataHumidity[5])/dataHumidity[5]) * 100
                   }

                   if (dataHumidity[6] <  dataHumidity[5]) {
                     decreaseHumidity = true
                     increaseHumidity = false
                   }else{
                     if (dataHumidity[6] ===  dataHumidity[5]) {
                       decreaseHumidity = false
                       increaseHumidity = false
                     }else{
                       if (dataHumidity[6] >  dataHumidity[5]) {
                         decreaseHumidity = false
                         increaseHumidity = true
                       }
                    }
                  }

                  charDataValue2[0] = charDataValue2[1]
                  charDataValue2[1] = charDataValue2[2]
                  charDataValue2[2] = charDataValue2[3]
                  charDataValue2[3] = charDataValue2[4]
                  charDataValue2[4] = charDataValue2[5]
                  charDataValue2[5] = charDataValue2[6]
                  charDataValue2[6] = charDataValue2[7]
                  charDataValue2[7] = charDataValue2[8]
                  charDataValue2[8] = charDataValue2[9]
                  charDataValue2[9] = charDataValue2[10]
                  charDataValue2[10] = charDataValue2[11]
                  charDataValue2[11] = charDataValue2[12]
                  charDataValue2[12] = charDataValue2[13]
                  charDataValue2[13] = charDataValue2[14]
                  charDataValue2[14] = charDataValue2[15]
                  charDataValue2[15] = charDataValue2[16]
                  charDataValue2[16] = charDataValue2[17]
                  charDataValue2[17] = charDataValue2[18]
                  charDataValue2[18] = charDataValue2[19]
                  charDataValue2[19] = charDataValue2[20]
                  charDataValue2[20] = charDataValue2[21]
                  charDataValue2[21] = charDataValue2[22]
                  charDataValue2[22] = charDataValue2[23]
                  charDataValue2[23] = charDataValue2[24]
                  charDataValue2[24] = charDataValue2[25]
                  charDataValue2[25] = charDataValue2[26]
                  charDataValue2[26] = charDataValue2[27]
                  charDataValue2[27] = charDataValue2[28]
                  charDataValue2[28] = charDataValue2[29]
                  charDataValue2[29] = parseFloat(array[i].value)

                }else{
                  if (array[i].sensorid === '3'){
                     valorGasControl = valorSensor
                     countGasControl = countGasControl + 1
                     dataGasControl[0] = dataGasControl[1]
                     dataGasControl[1] = dataGasControl[2]
                     dataGasControl[2] = dataGasControl[3]
                     dataGasControl[3] = dataGasControl[4]
                     dataGasControl[4] = dataGasControl[5]
                     dataGasControl[5] = dataGasControl[6]
                     dataGasControl[6] = parseFloat(array[i].value)
                     if (dataGasControl[5]===0) {
                         variationGasControl =  dataGasControl[6] * 100
                     }else{
                         variationGasControl =  ((dataGasControl[6]-dataGasControl[5])/dataGasControl[5]) * 100
                     }
                     if (dataGasControl[6] <  dataGasControl[5]) {
                       decreaseGasControl = true
                       increaseGasControl = false
                     }else{
                       if (dataGasControl[6] ===  dataGasControl[5]) {
                         decreaseGasControl = false
                         increaseGasControl = false
                       }else{
                         if (dataGasControl[6] >  dataGasControl[5]) {
                           decreaseGasControl = false
                           increaseGasControl = true
                         }
                      }
                    }

                    charDataValue3[0] = charDataValue3[1]
                    charDataValue3[1] = charDataValue3[2]
                    charDataValue3[2] = charDataValue3[3]
                    charDataValue3[3] = charDataValue3[4]
                    charDataValue3[4] = charDataValue3[5]
                    charDataValue3[5] = charDataValue3[6]
                    charDataValue3[6] = charDataValue3[7]
                    charDataValue3[7] = charDataValue3[8]
                    charDataValue3[8] = charDataValue3[9]
                    charDataValue3[9] = charDataValue3[10]
                    charDataValue3[10] = charDataValue3[11]
                    charDataValue3[11] = charDataValue3[12]
                    charDataValue3[12] = charDataValue3[13]
                    charDataValue3[13] = charDataValue3[14]
                    charDataValue3[14] = charDataValue3[15]
                    charDataValue3[15] = charDataValue3[16]
                    charDataValue3[16] = charDataValue3[17]
                    charDataValue3[17] = charDataValue3[18]
                    charDataValue3[18] = charDataValue3[19]
                    charDataValue3[19] = charDataValue3[20]
                    charDataValue3[20] = charDataValue3[21]
                    charDataValue3[21] = charDataValue3[22]
                    charDataValue3[22] = charDataValue3[23]
                    charDataValue3[23] = charDataValue3[24]
                    charDataValue3[24] = charDataValue3[25]
                    charDataValue3[25] = charDataValue3[26]
                    charDataValue3[26] = charDataValue3[27]
                    charDataValue3[27] = charDataValue3[28]
                    charDataValue3[28] = charDataValue3[29]
                    charDataValue3[29] = parseFloat(array[i].value)

                  }
                }
              }

              dataJson[0] = dataJson[1]
              dataJson[1] = dataJson[2]
              dataJson[2] = dataJson[3]
              dataJson[3] = dataJson[4]
              dataJson[4] = dataJson[5]
              dataJson[5] = dataJson[6]
              dataJson[6] = dataJson[7]
              dataJson[7] = dataJson[8]
              dataJson[8] = `Sensor:${idSensor}, Contract:${idTrackingContract}, Container:${idContainer}, Device:${idCaller}, Valor:${valorSensor}, Unit:${unitMeasurement}, Latitude:${latitudDataSensor}, Longitude:${longituDataSensor}`

              const dataJsonX = `{"data": [
                      {"json": "${dataJson[0]}"},
                      {"json": "${dataJson[1]}"},
                      {"json": "${dataJson[2]}"},
                      {"json": "${dataJson[3]}"},
                      {"json": "${dataJson[4]}"},
                      {"json": "${dataJson[5]}"},
                      {"json": "${dataJson[6]}"},
                      {"json": "${dataJson[7]}"},
                      {"json": "${dataJson[8]}"}
                 ]}`

              //-----------------------
              let smallStatsGood = ''
              let smallStatsBad = ''
              if ((temperatureCheck==="true" && temperatureActiveRule === false) ||
                  (humidityCheck==="true" && humidityActiveRule === false) ||
                  (gascontrolCheck==="true" && gascontrolActiveRule === false)){
                    smallStatsGood = 'You must activate rules'
                    smallStatsBad = 'You must activate rules'
              }else{
                smallStatsGood = countGoodTemperature + countGoodHumidity + countGoodGascontrol
                smallStatsBad = countBadTemperature + countBadHumidity + countBadGascontrol
              }
              let smallStatsTemperature = ''
              let smallStatsHumidity = ''
              let smallStatsGasControl = ''
              if (temperatureCheck==="true"){
                 smallStatsTemperature = `
                     {
                       "label": "${idTypeSensorTemperature}",
                       "value": "${valorTemperature}${unitTemperature}",
                       "percentage": "${variationTemperature.toFixed(2)}%",
                       "increase": ${decreaseTemperature},
                       "decrease": ${increaseTemperature},
                       "chartLabels": [null, null, null, null, null, null, null],
                       "attrs": { "md": "6", "sm": "6" },
                       "datasets": [
                         {
                           "label": "Today",
                           "fill": "start",
                           "borderWidth": 1.5,
                           "backgroundColor": "rgba(0, 184, 216, 0.1)",
                           "borderColor": "rgb(0, 184, 216)",
                           "data": [${dataTemperature[0]}, ${dataTemperature[1]}, ${dataTemperature[2]}, ${dataTemperature[3]}, ${dataTemperature[4]}, ${dataTemperature[5]}, ${dataTemperature[6]}]
                         }
                       ]
                     },`
              }
              if (humidityCheck==="true"){
                 smallStatsHumidity = `
                     {
                       "label": "${idTypeSensorHumidity}",
                       "value": "${valorHumidity}${unitHumidity}",
                       "percentage": "${variationHumidity.toFixed(2)}%",
                       "increase": ${increaseHumidity},
                       "decrease": ${decreaseHumidity},
                       "chartLabels": [null, null, null, null, null, null, null],
                       "attrs": { "md": "6", "sm": "6" },
                       "datasets": [
                         {
                           "label": "Today",
                           "fill": "start",
                           "borderWidth": 1.5,
                           "backgroundColor": "rgba(23,198,113,0.1)",
                           "borderColor": "rgb(23,198,113)",
                           "data": [${dataHumidity[0]}, ${dataHumidity[1]}, ${dataHumidity[2]}, ${dataHumidity[3]}, ${dataHumidity[4]}, ${dataHumidity[5]}, ${dataHumidity[6]}]
                         }
                       ]
                     },`
              }
              if (gascontrolCheck==="true"){
                 smallStatsGasControl = `
                     {
                       "label": "${idTypeSensorGasControl}",
                       "value": "${valorGasControl}${unitGasControl}",
                       "percentage": "${variationGasControl.toFixed(2)}%",
                       "increase": ${increaseGasControl},
                       "decrease": ${decreaseGasControl},
                       "chartLabels": [null, null, null, null, null, null, null],
                       "attrs": { "md": "4", "sm": "6" },
                       "datasets": [
                         {
                           "label": "Today",
                           "fill": "start",
                           "borderWidth": 1.5,
                           "backgroundColor": "rgba(255,180,0,0.1)",
                           "borderColor": "rgb(255,180,0)",
                           "data": [${dataGasControl[0]}, ${dataGasControl[1]}, ${dataGasControl[2]}, ${dataGasControl[3]}, ${dataGasControl[4]}, ${dataGasControl[5]}, ${dataGasControl[6]}]
                         }
                       ]
                     },`
              }
              const smallStats = `[
                      ${smallStatsTemperature}
                      ${smallStatsHumidity}
                      ${smallStatsGasControl}
                      {
                       "label": "Within Range",
                        "value": "${smallStatsGood}",
                        "percentage": "",
                        "increase": false,
                        "decrease": false,
                        "chartLabels": [null, null, null, null, null, null, null],
                        "attrs": { "md": "4", "sm": "6" },
                        "datasets": [
                          {
                            "label": "Today",
                            "fill": "start",
                            "borderWidth": 1.5,
                            "backgroundColor": "rgba(255,65,105,0.1)",
                            "borderColor": "rgb(255,65,105)",
                            "data": [0, 0, 0, 0, 0, 0, 0]
                          }
                        ]
                      },
                      {
                        "label": "Out of Range",
                        "value": "${smallStatsBad}",
                        "percentage": "",
                        "increase": false,
                        "decrease": false,
                        "chartLabels": [null, null, null, null, null, null, null],
                        "attrs": { "md": "4", "sm": "6" },
                        "datasets": [
                          {
                            "label": "Today",
                            "fill": "start",
                            "borderWidth": 1.5,
                            "backgroundColor": "rgb(0,123,255,0.1)",
                            "borderColor": "rgb(0,123,255)",
                            "data": [0, 0, 0, 0, 0, 0, 0]
                          }
                        ]
                      }
                    ]`

              let referalDataTemperature = ''
              let referalDataHumidity = ''
              let referalDataGasControl = ''
              if (temperatureCheck==="true"){
                 referalDataTemperature = `
                 {
                   "title": "${idTypeSensorTemperature}",
                   "value": "${countTemperature}"
                 },`
              }
              if (humidityCheck==="true"){
                 referalDataHumidity = `
                 {
                   "title": "${idTypeSensorHumidity}",
                   "value": "${countHumidity}"
                 },`
              }
              if (gascontrolCheck==="true"){
                 referalDataGasControl = `
                 {
                   "title": "${idTypeSensorGasControl}",
                   "value": "${countGasControl}"
                 },`
              }
              const referralData = `{"title": "Number data imported from the Sensors",
                "referralData": [
                  ${referalDataTemperature}
                  ${referalDataHumidity}
                  ${referalDataGasControl}
                  {
                    "title": "",
                    "value": ""
                  }
              ]}`

              const chartData = `{
                "title": "Graph Data Received",
                "chartData": {
                  "labels": "Array.from(new Array(30), (_, i) => (i === 0 ? 1 : i))",
                  "datasets": [
                    {
                      "label": "Temperature",
                      "fill": "start",
                      "data": [
                        ${charDataValue[0]},
                        ${charDataValue[1]},
                        ${charDataValue[2]},
                        ${charDataValue[3]},
                        ${charDataValue[4]},
                        ${charDataValue[5]},
                        ${charDataValue[6]},
                        ${charDataValue[7]},
                        ${charDataValue[8]},
                        ${charDataValue[9]},
                        ${charDataValue[10]},
                        ${charDataValue[11]},
                        ${charDataValue[12]},
                        ${charDataValue[13]},
                        ${charDataValue[14]},
                        ${charDataValue[15]},
                        ${charDataValue[16]},
                        ${charDataValue[17]},
                        ${charDataValue[18]},
                        ${charDataValue[19]},
                        ${charDataValue[20]},
                        ${charDataValue[21]},
                        ${charDataValue[22]},
                        ${charDataValue[23]},
                        ${charDataValue[24]},
                        ${charDataValue[25]},
                        ${charDataValue[26]},
                        ${charDataValue[27]},
                        ${charDataValue[28]},
                        ${charDataValue[29]}
                      ],
                      "backgroundColor": "rgba(0,123,255,0.1)",
                      "borderColor": "rgba(0,123,255,1)",
                      "pointBackgroundColor": "#ffffff",
                      "pointHoverBackgroundColor": "rgb(0,123,255)",
                      "borderWidth": 1.5,
                      "pointRadius": 0,
                      "pointHoverRadius": 3
                    },
                    {
                      "label": "Humidity",
                      "fill": "start",
                      "data": [
                        ${charDataValue2[0]},
                        ${charDataValue2[1]},
                        ${charDataValue2[2]},
                        ${charDataValue2[3]},
                        ${charDataValue2[4]},
                        ${charDataValue2[5]},
                        ${charDataValue2[6]},
                        ${charDataValue2[7]},
                        ${charDataValue2[8]},
                        ${charDataValue2[9]},
                        ${charDataValue2[10]},
                        ${charDataValue2[11]},
                        ${charDataValue2[12]},
                        ${charDataValue2[13]},
                        ${charDataValue2[14]},
                        ${charDataValue2[15]},
                        ${charDataValue2[16]},
                        ${charDataValue2[17]},
                        ${charDataValue2[18]},
                        ${charDataValue2[19]},
                        ${charDataValue2[20]},
                        ${charDataValue2[21]},
                        ${charDataValue2[22]},
                        ${charDataValue2[23]},
                        ${charDataValue2[24]},
                        ${charDataValue2[25]},
                        ${charDataValue2[26]},
                        ${charDataValue2[27]},
                        ${charDataValue2[28]},
                        ${charDataValue2[29]}
                      ],
                      "backgroundColor": "rgba(255,65,105,0.1)",
                      "borderColor": "rgba(255,65,105,1)",
                      "pointBackgroundColor": "#ffffff",
                      "pointHoverBackgroundColor": "rgba(255,65,105,1)",
                      "borderDash": [3, 3],
                      "borderWidth": 1,
                      "pointRadius": 0,
                      "pointHoverRadius": 2,
                      "pointBorderColor": "rgba(255,65,105,1)"
                    }
                  ]}
                }`

                 const verificationRules = `{
                      "title": "Verification of Rules",
                      "chartData": {
                        "datasets": [
                          {
                            "hoverBorderColor": "#ffffff",
                            "data": [${countGoodTemperature+countGoodHumidity+countGoodGascontrol}, ${countBadTemperature+countBadHumidity+countBadGascontrol}],
                            "backgroundColor": [
                              "rgba(0,123,255,0.9)",
                              "rgba(0,123,255,0.5)"
                            ]
                          }
                        ],
                        "labels": ["Within Range", "Out of Range"]
                      }
                  }`

                 const dataCustomActiveShapePieChart = `[
                    { "name": "Within Range", "value": ${countGoodTemperature+countGoodHumidity+countGoodGascontrol} },
                    { "name": "Out of Range", "value": ${countBadTemperature+countBadHumidity+countBadGascontrol} }
                 ]`;

                 if (countBadTemperature+countBadHumidity+countBadGascontrol===0){
                   this.setState({colorCustomActiveShapePieChart:"#03E1BC"})
                 }else{
                    if (countBadTemperature+countBadHumidity+countBadGascontrol>=1 && countBadTemperature+countBadHumidity+countBadGascontrol<=5){
                      this.setState({colorCustomActiveShapePieChart:"#FF9F00"})
                    }else{
                      if (countBadTemperature+countBadHumidity+countBadGascontrol>=6 && countBadTemperature+countBadHumidity+countBadGascontrol<=10){
                        this.setState({colorCustomActiveShapePieChart:"#FF3D00"})
                      }else{
                        if (countBadTemperature+countBadHumidity+countBadGascontrol>=11 && countBadTemperature+countBadHumidity+countBadGascontrol<=15){
                          this.setState({colorCustomActiveShapePieChart:"#E50505"})
                        }else{
                          if (countBadTemperature+countBadHumidity+countBadGascontrol>=16){
                            this.setState({colorCustomActiveShapePieChart:"#700000"})
                          }
                        }
                      }
                    }
                 }

                 this.setState({ smallStats2: JSON.parse(smallStats),
                                 referralData: JSON.parse(referralData),
                                 chartData: JSON.parse(chartData),
                                 dataJsonX: JSON.parse(dataJsonX),
                                 verificationRules: JSON.parse(verificationRules),
                                 dataCustomActiveShapePieChart: JSON.parse(dataCustomActiveShapePieChart) })

                  // enviar a PACT
                  let statusRule = ''
                  if (idSensor === "1") {
                    //this.props.saveNewPactDataSensorTemperature(idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor)
                    if (statusRuleTemperatureLower===true){
                           statusRule = 'Bad_Lower'
                    }else{
                      if (statusRuleTemperatureUpper===true){
                           statusRule = 'Bad_Upper'
                      }
                    }
                  }else{
                    if (idSensor === "2") {
                        //this.props.saveNewPactDataSensorHumidity(idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor)
                        if (statusRuleHumidityLower===true){
                               statusRule = 'Bad_Lower'
                        }else{
                          if (statusRuleHumidityUpper===true){
                               statusRule = 'Bad_Upper'
                          }
                        }
                    }else{
                      if (idSensor === "3") {
                          //this.props.saveNewPactDataSensorGascontrol(idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor)
                          if (statusRuleGasControlLower===true){
                                 statusRule = 'Bad_Lower'
                          }else{
                            if (statusRuleGasControlUpper===true){
                                 statusRule = 'Bad_Upper'
                            }
                          }
                      }
                    }
                  }
                  const idTypeBillOfLading  = 'Container'
                  if (statusRuleTemperatureLower===true || statusRuleTemperatureUpper===true ||
                      statusRuleHumidityLower===true || statusRuleHumidityUpper===true ||
                      statusRuleGasControlLower===true || statusRuleGasControlUpper===true ){
                     this.props.saveNewPactDataSensor(idPact,idSensor,this.state.typeContract,this.props.configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor,statusRule)
                  }
                  //Retorna estado desde PACT

                  // enviar a Blockstack
                  this.putDataSensor(jsonDataSensorX,idSensor,this.state.typeContract,this.props.configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor,idPact,unitMeasurement)

          }, i * delay);
        })
     }

     arrayPlusDelay2(array, delay, contractNumber) {
       var i = 0
       function loop() {
           //console.log(array[i]);
           if (i++ < array.length - 1)
               setTimeout(loop, delay);
       }
       setTimeout(loop, delay);
     }

     arrayPlusDelay3(array, delay, contractNumber) {
       var i = 0
       var interval = setInterval(function() {
           //console.log(array[i]);
           if (i++ >= array.length - 1)
               clearInterval(interval);
       }, delay)
      return interval
     }

     putDataSensor(jsonDataSensorX,idSensor,idTrackingContract,idContainer,idBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor,idPact,unitMeasurement) {
       const palabra = idTrackingContract
       //-------------------------------------------
       // enviar a Blockstack (Cloud Storage)
       //-------------------------------------------
       // Crear el objeto
       let infoContractIoTDevice =''

       const jsonBlockstack1 = JSON.stringify(jsonDataSensorX)
       const jsonBlockstack2 = jsonBlockstack1.substring(1,jsonBlockstack1.length - 1)
       if (jsonBlockstack2 === '') {
          infoContractIoTDevice =`[{"idSensor":"${idSensor}","idTrackingContract":"${idTrackingContract}","idTrackingContract":"${idTrackingContract}","idContainer":"${idContainer}","idBillOfLading":"${idBillOfLading}","idCaller":"${idCaller}","idCategory":"${idCategory}","idTypeSensor":"${idTypeSensor}","valorSensor":"${valorSensor}","unitMeasurement":"${unitMeasurement}","dateDataSensor":"${dateDataSensor}","latitudDataSensor":"${latitudDataSensor}","longituDataSensor":"${longituDataSensor}","idPact":"${idPact}"}]`
       }else{
          infoContractIoTDevice = `[${jsonBlockstack2},{"idSensor":"${idSensor}","idTrackingContract":"${idTrackingContract}","idTrackingContract":"${idTrackingContract}","idContainer":"${idContainer}","idBillOfLading":"${idBillOfLading}","idCaller":"${idCaller}","idCategory":"${idCategory}","idTypeSensor":"${idTypeSensor}","valorSensor":"${valorSensor}","unitMeasurement":"${unitMeasurement}","dateDataSensor":"${dateDataSensor}","latitudDataSensor":"${latitudDataSensor}","longituDataSensor":"${longituDataSensor}","idPact":"${idPact}"}]`
       }
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
       putFileDB(this.state.username, `data_sensor_${palabra}.json`, 'Crosscheck_Dashboard', JSON.stringify(infoContractIoTDevice), JSON.stringify(options))
         .then(() => {})
     }

     messageError() {
        if (!this.state.existContractIoTDevice){
          return (
              <React.Fragment>
                <br></br>
                <Row form>
                  <Col md="12">
                      <Card theme="secondary" style={{ color: "white" }}>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <div className="fa fa-info mx-2"></div>
                              </td>
                              <td>
                                Contract without IoT Devices &nbsp;&nbsp;&nbsp;
                              </td>
                              <td>
                                &nbsp;&nbsp;
                                <Button pill theme="light" onClick={() => this.setState({ existContractIoTDevice: true })}>Understood</Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Card>
                  </Col>
                </Row>
              </React.Fragment>
          )
        }
      }

      messageError2() {
         if (this.state.errorMensaje2){
           return (
               <React.Fragment>

                 <br></br>
                 <Row form>
                   <Col md="12">
                       <Card theme="secondary" style={{ color: "white" }}>
                         <table>
                           <tbody>
                             <tr>
                               <td>
                                 <div className="fa fa-info mx-2"></div>
                               </td>
                               <td>
                                 Inconsistent Data: Contract Detail not found &nbsp;&nbsp;&nbsp;
                               </td>
                               <td>
                                 &nbsp;&nbsp;
                                 <Button pill theme="light" onClick={() => this.setState({ errorMensaje2: false })}>Understood</Button>
                               </td>
                             </tr>
                           </tbody>
                         </table>
                       </Card>
                   </Col>
                 </Row>
               </React.Fragment>
           )
         }
       }

       messageError3() {
          if (this.state.errorMensaje3){
            return (
                <React.Fragment>
                  <br></br>
                  <Row form>
                    <Col md="12">
                        <Card theme="secondary" style={{ color: "white" }}>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="fa fa-info mx-2"></div>
                                </td>
                                <td>
                                  Inconsistent Data: Master File Sensors not found &nbsp;&nbsp;&nbsp;
                                </td>
                                <td>
                                  &nbsp;&nbsp;
                                  <Button pill theme="light" onClick={() => this.setState({ errorMensaje3: false })}>Understood</Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Card>
                    </Col>
                  </Row>
                </React.Fragment>
            )
          }
        }

        messageError4() {
           if (this.state.errorMensaje4){
             return (
                 <React.Fragment>
                   <br></br>
                   <Row form>
                     <Col md="12">
                         <Card theme="secondary" style={{ color: "white" }}>
                           <table>
                             <tbody>
                               <tr>
                                 <td>
                                   <div className="fa fa-info mx-2"></div>
                                 </td>
                                 <td>
                                   Inconsistent Data: Master File Devices not found &nbsp;&nbsp;&nbsp;
                                 </td>
                                 <td>
                                   &nbsp;&nbsp;
                                   <Button pill theme="light" onClick={() => this.setState({ errorMensaje4: false })}>Understood</Button>
                                 </td>
                               </tr>
                             </tbody>
                           </table>
                         </Card>
                     </Col>
                   </Row>
                 </React.Fragment>
             )
           }
       }

       messageError5() {
          if (this.state.errorMensaje5){
            return (
                <React.Fragment>
                  <br></br>
                  <Row form>
                    <Col md="12">
                        <Card theme="secondary" style={{ color: "white" }}>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="fa fa-info mx-2"></div>
                                </td>
                                <td>
                                  Error: API Server Data sensor not found &nbsp;&nbsp;&nbsp;
                                </td>
                                <td>
                                  &nbsp;&nbsp;
                                  <Button pill theme="light" onClick={() => this.setState({ errorMensaje5: false })}>Understood</Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Card>
                    </Col>
                  </Row>
                </React.Fragment>
            )
          }
      }
     //getDataSensor() {
     //  this.props.simulateDatossensores()
     //  const {sensores} = this.props;
     //}

     onPieEnter = (data, index) => {
       this.setState({activeIndex: index,});
     };

     exitProgram(){
       this.props.returnProps();
     }

     render() {

           let displayActiveDashboard = false
           let displayActiveDashboardMessage = false
           let displayListDashboard = false
           if (this.state.typeContract !== 'simulate'){
             if (this.props.userRole === 'Creator'){
                 if (this.props.activeDataDashboardX === true){
                   displayListDashboard = true
                 }else{
                   if (this.state.displayActiveDashboardImpide === false){
                       displayActiveDashboard = true
                   }
                   if (this.state.displayActiveDashboardImpide === true){
                     displayListDashboard = true
                   }
                 }
             }else{
                 if (this.props.activeDataDashboardX === true){
                   displayListDashboard = true
                 }else{
                   displayActiveDashboardMessage = true
                 }
             }
           }else{
             displayListDashboard = true
           }

           let cryptoPactClarityEthereumX = ''
           let cryptoPactClarityEthereum2X = ''
           let myUserConfig = this.props.myUserConfig
           let balanceX = parseFloat(this.state.balanceCta)
           let transactionX = 0
           let commissionX = 0
           let rateX = 0
           let saldoX = 0
           cryptoPactClarityEthereumX = 'Stacks'
           cryptoPactClarityEthereum2X = 'STX'
           let disableTransferX = false
           if (balanceX < (transactionX + commissionX + rateX)) {
               saldoX = balanceX
               disableTransferX = true
           }else{
               saldoX = balanceX - (transactionX + commissionX + rateX)
           }

          const {smallStats2} = this.state
          //const {chartData,title} = this.state.verificationRules
          const {dataCustomActiveShapePieChart} = this.state
          const messageSubtittle = `${this.state.typeContract}/${this.state.numberContract} - ${this.props.subjectContract}`
          const messageSubtittle2 = `${this.state.typeContract}/${this.state.numberContract}`
          const {jsonEnrollment} = this.props
          const displayLinkX = this.state.displayDashboard
          let correlativo = 0
          const renderActiveShape = (props) => {
            const RADIAN = Math.PI / 180;
            const {
              cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
              fill, payload, percent, value,
            } = props;
            const sin = Math.sin(-RADIAN * midAngle);
            const cos = Math.cos(-RADIAN * midAngle);
            const sx = cx + (outerRadius + 10) * cos;
            const sy = cy + (outerRadius + 10) * sin;
            const mx = cx + (outerRadius + 30) * cos;
            const my = cy + (outerRadius + 30) * sin;
            const ex = mx + (cos >= 0 ? 1 : -1) * 22;
            const ey = my;
            const textAnchor = cos >= 0 ? 'start' : 'end';

            return (
              <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                <Sector
                  cx={cx}
                  cy={cy}
                  innerRadius={innerRadius}
                  outerRadius={outerRadius}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  fill={fill}
                />
                <Sector
                  cx={cx}
                  cy={cy}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  innerRadius={outerRadius + 6}
                  outerRadius={outerRadius + 10}
                  fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Count ${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                  {`(${(percent * 100).toFixed(2)}%)`}
                </text>
              </g>
            );
          };

          let titleX = 'Dashboard'
          if (this.state.language === 'English'){
            titleX = "Dashboard"
          }
          if (this.state.language === 'French'){
            titleX = "Tableau de Bord"
          }
          if (this.state.language === 'Spanish'){
            titleX = "Tablero de Comando"
          }
          if (this.state.language === 'Portuguese'){
            titleX = "Painel de Controle"
          }
          if (this.state.language === 'Swedish'){
            titleX = "Instrumentbräda"
          }
          if (this.state.language === 'Netherlands'){
            titleX = "Instrumentenbord"
          }
          if (this.state.language === 'Russian'){
            titleX = "Приборная доска"
          }
          if (this.state.language === 'Japanese'){
            titleX = "ダッシュボード"
          }
          if (this.state.language === 'Chinese'){
            titleX = "仪表板"
          }
          if (this.state.language === 'German'){
            titleX = "Instrumententafel"
          }
          if (this.state.language === 'Italian'){
            titleX = "Cruscotto"
          }

          return (
              <div>
                <Container fluid className="main-content-container px-4">
                  {/* Page Header */}
                  <Row noGutters className="page-header py-4">
                    <TituloPagina2 title={titleX} subtitle={messageSubtittle} className="text-sm-left mb-3" />
                  </Row>
                  <ConfirmacionActiveDashboard
                      handleDialog={this.handleDialog2}
                      dialogMessage={this.state.dialogMessage}
                      dialogOpen={this.state.dialogOpen}
                      dialogTitle={this.state.dialogTitle}
                      cryptoPactClarityEthereumX={cryptoPactClarityEthereumX}
                      cryptoPactClarityEthereum2X={cryptoPactClarityEthereum2X}
                      addressX={this.state.serverStacksX}
                      balanceX={this.state.balanceCta}
                      transactionX={transactionX}
                      saldoX={saldoX}
                      disableTransferX={disableTransferX}
                      rateX={rateX}
                      commissionX={commissionX}
                  />
                  <Row>&nbsp;</Row>
                  {displayActiveDashboard ?
                    <>
                      <Row>&nbsp;</Row>
                      <Row>
                        <Col md="12">
                          <Card theme="light" style={{ color: "red" }}>
                              <Table size="sm" style={{fontSize:14}} className="text-center" borderless responsive >
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="fa fa-info-circle mx-2">&nbsp;&nbsp;<FormattedMessage id="dashboard.active" /></div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <Button outline pill theme="warning" size="sm" className="mb-2" onClick={this.activeDashboard}>
                                        <i className="material-icons mr-1">replay</i> <FormattedMessage id="dashboard.buttonactive" />
                                    </Button>
                                  </tr>
                                </tbody>
                              </Table>
                          </Card>
                        </Col>
                      </Row>
                    </>
                  : null }
                  {displayActiveDashboardMessage ?
                    <Container fluid className="px-0">
                        <Card theme="warning" >
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  &nbsp;&nbsp;<div className="fa fa-info"></div>
                                </td>
                                <td>
                                   &nbsp;&nbsp;<FormattedMessage id="dashboard.message1" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Card>
                    </Container>
                  : null }
                  {displayListDashboard ?
                    <Row>
                      <Col md="3">
                        <Card small className="mb-12 pt-3">
                          <label>&nbsp;</label>
                          <Table size="sm" style={{fontSize:12}}>
                              <thead>
                                <tr>
                                  <th style={{ width: "30px" }}>#</th>
                                  <th style={{ width: "160px" }}><FormattedMessage id="iotdevice.identification" /></th>
                                  <th style={{ width: "60px" }}></th>
                                  <th style={{ width: "60px" }}></th>
                                  <th style={{ width: "60px" }}></th>
                                </tr>
                              </thead>
                              <tbody>
                                {jsonEnrollment.map((todoX, i) => {
                                  correlativo = i
                                  return (
                                      <tr key={i}>
                                          <td>{++correlativo}</td>
                                          <td><strong style={{cursor: 'pointer'}}  onClick={()=>{this.getTrackingContract(todoX.enrollment)}}>{todoX.enrollment}</strong></td>
                                          <td><strong style={{cursor: 'pointer'}} ><img src={todoX.link} weight="25" height="25" alt=""/></strong></td>
                                          <td><strong style={{cursor: 'pointer'}} ><img src={todoX.sensor} weight="25" height="25" alt=""/></strong></td>
                                          <td><strong style={{cursor: 'pointer'}}  onClick={()=>{this.getTrackingContract(todoX.enrollment,todoX.dashboard)}}><img src={todoX.dashboard} weight="30" height="30" alt=""/></strong></td>
                                      </tr>
                                   )})}
                              </tbody>
                          </Table>

                        </Card>
                      </Col>
                      {displayLinkX ?
                        <React.Fragment>
                          <Col md="9">
                            <Card small className="mb-12 pt-3">
                              <Col md="1"></Col>
                              <Col>
                                <Row noGutters className="page-header py-4">
                                  <TituloPagina2
                                    sm="8"
                                    title={this.state.enrollment}
                                    subtitle={messageSubtittle2}
                                    className="text-sm-left"
                                  />
                                </Row>
                              </Col>
                              <Col>
                                <Row>
                                  {smallStats2.map((stats, idx) => (
                                    <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
                                      <SmallStats
                                        id={`small-stats-${idx}`}
                                        variation="1"
                                        chartData={stats.datasets}
                                        chartLabels={stats.chartLabels}
                                        label={stats.label}
                                        value={stats.value}
                                        percentage={stats.percentage}
                                        increase={stats.increase}
                                        decrease={stats.decrease}
                                      />
                                    </Col>
                                  ))}
                                </Row>
                                <Row>
                                    {/* Top Referrals */}
                                    <Col lg="4" md="4" sm="12" className="mb-4">
                                      <TopReferrals datasensor={this.state.referralData} />
                                    </Col>
                                      {/* Users by Device */}
                                    <Col lg="8" md="8" sm="12" className="mb-2">
                                      {/*chartData={this.state.verificationRules}*/}
                                      {/*<UsersByDevice chartData={chartData} title={title} />*/}
                                      <Card small>
                                        <PieChart width={500} height={400}>
                                          <Pie
                                            activeIndex={this.state.activeIndex}
                                            activeShape={renderActiveShape}
                                            data={dataCustomActiveShapePieChart}
                                            cx={240}
                                            cy={200}
                                            innerRadius={70}
                                            outerRadius={90}
                                            fill={this.state.colorCustomActiveShapePieChart}
                                            dataKey="value"
                                            onMouseEnter={this.onPieEnter}
                                          />
                                        </PieChart>
                                      </Card>
                                    </Col>
                                  </Row>
                                  <Row>
                                      {/* Users Overview */}
                                      <Col lg="12" md="12" sm="12" className="mb-4">
                                        <DetailDataReceived dataJson={this.state.dataJsonX} />
                                      </Col>
                                  </Row>
                                  <Row form>
                                    <Button pill theme="warning" onClick={this.exitIdentification}><FormattedMessage id="button.identificationlist" /></Button>&nbsp;
                                    <Button pill theme="secondary" onClick={this.exitProgram}><FormattedMessage id="button.contractlist" /></Button>
                                  </Row>
                                  <br></br>
                              </Col>
                            </Card>
                          </Col>
                        </React.Fragment>
                      : null }
                    </Row>
                  : null }
                  <br></br>
                </Container>
              </div>
          );
    }
}

ContractDashboard.propTypes = {
    simulateDatossensores : PropTypes.func.isRequired,
    saveNewPactDataSensorTemperature: PropTypes.func.isRequired,
    saveNewPactDataSensor: PropTypes.func.isRequired,
    saveNewPactDataSensorHumidity: PropTypes.func.isRequired,
    saveNewPactDataSensorGascontrol: PropTypes.func.isRequired,
    fetchPactLogisticConfig: PropTypes.func.isRequired,
    smallStats: PropTypes.array
}

// state
const mapStateToProps = state => ({
     sensores: state.sensores.sensores,
     smartcontract: state.smartcontract.smartcontract,
     datasensorx: state.smartcontract.datasensorx
})

const mapDispatchToProps = dispatch => {
  return {
    simulateDatossensores: simulateDatossensores,
    fetchPactLogisticConfig: () => {dispatch(fetchPactLogisticConfig());},
    saveNewPactDataSensorTemperature: (idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor) => {
        dispatch(saveNewPactDataSensorTemperature(idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor));},
    saveNewPactDataSensorHumidity: (idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor) => {
        dispatch(saveNewPactDataSensorHumidity(idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor));},
    saveNewPactDataSensorGascontrol: (idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor) => {
        dispatch(saveNewPactDataSensorGascontrol(idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor));},
        saveNewPactDataSensor: (idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor,statusRule) => {
            dispatch(saveNewPactDataSensor(idPact,idSensor,typeContract,configurationContractNumber,idContainer,idTypeBillOfLading,idCaller,idCategory,idTypeSensor,valorSensor,dateDataSensor,latitudDataSensor,longituDataSensor,statusRule));},
  };
};
export default ReactTimeout(connect(mapStateToProps, mapDispatchToProps)(ContractDashboard));
