//React
import React, { Component } from 'react';
import { UserSession, AppConfig } from '@stacks/auth';
import { Storage } from '@stacks/storage'
import { Container, Row, Col, Card, CardBody, Button, Form, FormRadio, FormSelect, DatePicker } from "shards-react";
import { Table } from 'reactstrap';
import DateFnsUtils from "@date-io/date-fns";
import { es } from "date-fns/locale";
import axios from 'axios';
import TituloPagina2 from "../common/TituloPagina2";
import { FormattedMessage } from 'react-intl';
import { MAINNET_STACKS_API_URL,TESTNET_STACKS_API_URL } from '../../config.js'
import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

class InformeDeliverable extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userSession: new UserSession(new AppConfig()),
          username: '',
          user: '',
          nameBlockstack: '',
          networkUrlX: '',
          language: 'English',
          checkPorOTX: true,
          checkPorFechaX: false,
          generateX: false,
          displayInformeReporteTurno: false,
          ordenTrabajoX: [],
          ordenTrabajoDatosExtrasX: [],
          dateFormatX: 'dd/MM/yyyy',
          isocode: "es",
          workorderfechaterminoperforacion: new Date(),
          workorderfechainiciopozo: new Date(),
        }
    }

    c01Informe = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = 'MainNet'
      let networkUrlX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
           stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
        }
        if (serverStacksX === 'TestNet'){
           stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({ username })
      }
      const {name} = userSession.loadUserData().profile;
      this.setState({ nameBlockstack: name, networkUrlX })


      if (this.props.language === 'English'){this.setState({ isocode: "en", dateFormatX:"MM/dd/yyyy"})}
      if (this.props.language === 'French'){this.setState({ isocode:  "fr", dateFormatX:"MM/dd/yyyy"})}
      if (this.props.language === 'Spanish'){this.setState({ isocode:  "es", dateFormatX:"dd/MM/yyyy"})}
      if (this.props.language === 'Portuguese'){this.setState({ isocode:  "pt", dateFormatX:"dd/MM/yyyy"})}
      if (this.props.language === 'Swedish'){this.setState({ isocode:  "sv", dateFormatX:"MM/dd/yyyy"})}
      if (this.props.language === 'Netherlands'){this.setState({ isocode:  "nl", dateFormatX:"MM/dd/yyyy"})}
      if (this.props.language === 'Russian'){this.setState({ isocode:  "ru", dateFormatX:"MM/dd/yyyy"})}
      if (this.props.language === 'Japanese'){this.setState({ isocode:  "ja_JP", dateFormatX:"MM/dd/yyyy"})}
      if (this.props.language === 'Chinese'){this.setState({ isocode: "zh", dateFormatX:"MM/dd/yyyy"})}
      if (this.props.language === 'German'){this.setState({ isocode:  "de", dateFormatX:"MM/dd/yyyy"})}
      if (this.props.language === 'Italian'){this.setState({ isocode:  "it", dateFormatX:"MM/dd/yyyy"})}

      Promise.all(
         [this.getOrdenTrabajo(networkUrlX),
          this.getOrdenTrabajoDatosExtras(networkUrlX)]
      ).then(
        (resolve) =>{},
        (reject) =>{}
      )

    }

    handleChangeCheck = (e,optX) => {
      if (optX === 1){
        this.setState({checkPorOTX: true, checkPorFechaX: false})
      }
      if (optX === 2){
        this.setState({checkPorOTX: false, checkPorFechaX: true})
      }
    }

    handleDate(val, chk){
       if (chk==='workorderfechainiciopozo'){
         this.setState({workorderfechainiciopozo: new Date(val)})
       }else{
         if (chk==='workorderfechaterminoperforacion'){
           this.setState({workorderfechaterminoperforacion: new Date(val)})
         }
       }
    }

    getOrdenTrabajo = (networkUrlX) => {
      return new Promise((resolve1, reject1) => {
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = { decrypt: false, verify: false }
        let jsonBlockstack4d = []
        let ordenTrabajoX = []
        getFileDB(this.props.userOrigin, `${this.props.typeContract}_${this.props.numberContract}.json`)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               jsonBlockstack4d = JSON.parse(jsonBlockstack5)
               this.setState({ordenTrabajoX:jsonBlockstack4d})
               if (isNaN(Date.parse(jsonBlockstack4d.workorderfechainiciopozo))){
                  this.setState({workorderfechainiciopozo: new Date()})
               }else{
                 this.setState({workorderfechainiciopozo: new Date(jsonBlockstack4d.workorderfechainiciopozo)})
               }
               if (isNaN(Date.parse(jsonBlockstack4d.workorderfechaterminoperforacion))){
                  this.setState({workorderfechaterminoperforacion: new Date()})
               }else{
                 this.setState({workorderfechaterminoperforacion: new Date(jsonBlockstack4d.workorderfechaterminoperforacion)})
               }
               resolve1(true);
             } else {
               reject1(true);
             }
           })
            .catch(error => {
                reject1(true);
           });
        });
    }

    getOrdenTrabajoDatosExtras = (networkUrlX) => {
      return new Promise((resolve1, reject1) => {
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = { decrypt: false, verify: false }
        let jsonBlockstack4d = []
        let ordenTrabajoX = []
        getFileDB(this.props.userOrigin, `${this.props.typeContract}_${this.props.numberContract}_workorder_datoextra.json`)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               jsonBlockstack4d = JSON.parse(jsonBlockstack5)
               this.setState({ordenTrabajoDatosExtrasX:jsonBlockstack4d})
               if (isNaN(Date.parse(jsonBlockstack4d.workorderfecharealinicio))){
                  this.setState({workorderfechainiciopozo: new Date()})
               }else{
                 this.setState({workorderfechainiciopozo: new Date(jsonBlockstack4d.workorderfecharealinicio)})
               }
               if (isNaN(Date.parse(jsonBlockstack4d.workorderfechaterminoperforacion))){
                  this.setState({workorderfechaterminoperforacion: new Date()})
               }else{
                 this.setState({workorderfechaterminoperforacion: new Date(jsonBlockstack4d.workorderfechaterminoperforacion)})
               }
               resolve1(true);
             } else {
               reject1(true);
             }
           })
            .catch(error => {
                reject1(true);
           });
        });
    }

    goInforme = () => {
      if (this.c01Informe.current.value === 'SelectOptionInforme'){
        this.setState({displayInformeReporteTurno: false, generateX: false})
      }
      if (this.c01Informe.current.value === 'ReporteTurno'){
        this.setState({displayInformeReporteTurno: true, generateX: true})

      }
    }

    exitProgram = (e) => {
       this.props.returnProps();
    }

    generateReport = (e) => {
    }

    render() {
        let titleX = 'Informes'
        const subtitleX = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`
        let placeholderX1 = "Select an Option"
        if (this.props.language === 'English'){titleX = "Reports";placeholderX1 = "Select an Option"}
        if (this.props.language === 'French'){titleX = "Reports";placeholderX1 = "Sélectionner unproe.ide Option"}
        if (this.props.language === 'Spanish'){titleX = "Informes";placeholderX1 = "Seleccione una Opción"}
        if (this.props.language === 'Portuguese'){titleX = "Reports";placeholderX1 = "Selecione uma Opção"}
        if (this.props.language === 'Swedish'){titleX = "Reports";placeholderX1 = "Välj ett Alternativ"}
        if (this.props.language === 'Netherlands'){titleX = "Reports";placeholderX1 = "Kies een Optie"}
        if (this.props.language === 'Russian'){titleX = "Reports";placeholderX1 = "Выберите опцию"}
        if (this.props.language === 'Japanese'){titleX = "Reports";placeholderX1 = "オプションを選択"}
        if (this.props.language === 'Chinese'){titleX = "Reports";placeholderX1 = "选择一个选项"}
        if (this.props.language === 'German'){titleX = "Reports";placeholderX1 = "Wähle eine Option"}
        if (this.props.language === 'Italian'){titleX = "Reports";placeholderX1 = "Seleziona un'opzione"}
        return (
          <Container fluid className="main-content-container px-4" style={ this.props.colorDark ? { backgroundColor:'#060a0f'} : {}} >

            <Row noGutters className="page-header py-4">
              <TituloPagina2
                sm="4"
                title={titleX}
                subtitle={subtitleX}
                className="text-sm-left"
              />
            </Row>
            <Row form>
               <Col md="12">
                 <Form style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                    <Card small style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                        <CardBody>
                           <Col md="4">
                               <Row form></Row>
                               <Row form>
                                 <FormSelect
                                      id="TypeSetting"
                                      style={ this.props.colorDark ? { backgroundColor:'#111f2c', color:'white'} : {}}
                                      innerRef={this.c01Informe}
                                      placeholder={placeholderX1}
                                      onChange={this.goInforme}
                                 >
                                      <option value="SelectOptionInforme" selected disabled hidden>{placeholderX1}</option>
                                      <option style={this.props.colorDark ? {color:'orange'} : {color:'darkblue'}} value="ReporteTurno">Reporte de Turno</option>
                                 </FormSelect>
                               </Row>
                               <Row form>&nbsp;</Row>
                           </Col>
                           {this.state.displayInformeReporteTurno ?
                             <>
                               <Row form>
                                 <Table size="sm" className="text-center" responsive hover striped bordered style={{ color: 'black', fontSize:11 }}>
                                     <tbody>
                                       <tr>
                                         <td style={{ width: "30%", textAlign:"center", color: 'darkblue', fontSize:11, fontStyle: 'italic' }}>
                                           <FormRadio
                                                inline
                                                checked={this.state.checkPorOTX}
                                                onChange={e=>this.handleChangeCheck(e, 1)}
                                                disabled={false}
                                                placeholder=""
                                            >
                                                Por Orden de Trabajo
                                            </FormRadio>
                                          </td>
                                          <td style={{ width: "70%", textAlign:"center", color: 'darkblue', fontSize:11, fontStyle: 'italic' }}>
                                              {this.state.checkPorOTX ?
                                                 <>
                                                   {this.props.typeContract} / {this.props.numberContract} - {this.props.subjectContract}
                                                 </>
                                              : null }
                                          </td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: "30%", textAlign:"center", color: 'darkblue', fontSize:11, fontStyle: 'italic' }}>
                                            <FormRadio
                                                 inline
                                                 checked={this.state.checkPorFechaX}
                                                 onChange={e=>this.handleChangeCheck(e, 2)}
                                                 disabled={false}
                                                 placeholder=""
                                             >
                                                 Por Rango de Fecha
                                             </FormRadio>
                                         </td>
                                         <td style={{ width: "70%", textAlign:"center", color: 'darkblue', fontSize:11, fontStyle: 'italic' }}>

                                           {this.state.checkPorFechaX ?
                                             <>
                                               <Table size="sm" className="text-center" borderless responsive style={{ color: 'black', fontSize:11 }}>
                                                   <thead>
                                                     <tr>
                                                       <th style={{ width: "50%", textAlign:"center", color: 'darkblue', fontSize:11, fontStyle: 'italic' }}>
                                                         <label style={{ color: 'darkorange', fontWeight:'bold', fontSize:10 }}><FormattedMessage id="contract.workorderfechainiciopozo" /></label>
                                                         <br></br>
                                                         <DatePicker
                                                           id="workorderfechainiciopozo"
                                                           style={ this.props.colorDark ? { backgroundColor:'#468C77', color:'white'} : {backgroundColor:'#468C77', color:'white'}}
                                                           dateFormat={this.state.dateFormatX}
                                                           placeholder=""
                                                           locale={es} utils={DateFnsUtils}
                                                           disabled={false}
                                                           //size="sm"
                                                           selected={this.state.workorderfechainiciopozo}
                                                           onChange={val=>this.handleDate(val,'workorderfechainiciopozo')}
                                                           dropdownMode="select"
                                                         />
                                                        </th>
                                                        <th style={{ width: "50%", textAlign:"center", color: 'darkblue', fontSize:11, fontStyle: 'italic' }}>
                                                          <label style={{ color: 'darkorange', fontWeight:'bold', fontSize:10 }}><FormattedMessage id="contract.workorderfechaterminoperforacion" /></label>
                                                          <br></br>
                                                          <DatePicker
                                                            id="workorderfechaterminoperforacion"
                                                            style={ this.props.colorDark ? { backgroundColor:'#468C77', color:'white', textAlign:"center" } : { backgroundColor:'#468C77', color:'white', textAlign:"center" }}
                                                            dateFormat={this.state.dateFormatX}
                                                            placeholder=""
                                                            locale={es} utils={DateFnsUtils}
                                                            disabled={false}
                                                            //size="sm"
                                                            selected={this.state.workorderfechaterminoperforacion}
                                                            onChange={val=>this.handleDate(val,'workorderfechaterminoperforacion')}
                                                            dropdownMode="select"
                                                          />
                                                        </th>
                                                      </tr>
                                                   </thead>
                                              </Table>
                                            </>
                                          : null }
                                         </td>
                                      </tr>
                                     </tbody>
                                 </Table>
                               </Row>
                             </>
                           : null }
                        </CardBody>
                     </Card>
                  </Form>
               </Col>
            </Row>
            <Row form>
               <td>
                 {this.state.generateX ?
                   <>
                     <Button pill theme="success" onClick={e=>this.generateReport(e)}><FormattedMessage id="contract.generate" /></Button>
                     &nbsp;&nbsp;&nbsp;&nbsp;
                   </>
                 : null }
                 <Button pill theme="secondary" onClick={e=>this.exitProgram(e)}><FormattedMessage id="pay.exit" /></Button>
               </td>
            </Row>
          </Container>
        );
    }
}

export default InformeDeliverable;
