const currency =
    {
    "CcyNtry": [
        {
            "CcyNm": "US Dollar",
            "Ccy": "USD"
        },
        {
            "CcyNm": "Euro",
            "Ccy": "EUR"
        },
        {
            "CcyNm": "Chilean Peso",
            "Ccy": "CLP"
        },
        {
            "CcyNm": "Afghani",
            "Ccy": "AFN"
        },
        {
            "CcyNm": "Algerian Dinar",
            "Ccy": "DZD"
        },
        {
            "CcyNm": "Argentine Peso",
            "Ccy": "ARS"
        },
        {
            "CcyNm": "Armenian Dram",
            "Ccy": "AMD"
        },
        {
            "CcyNm": "Aruban Florin",
            "Ccy": "AWG"
        },
        {
            "CcyNm": "Australian Dollar",
            "Ccy": "AUD"
        },
        {
            "CcyNm": "Azerbaijan Manat",
            "Ccy": "AZN",
        },
        {
            "CcyNm": "Bahamian Dollar",
            "Ccy": "BSD"
        },
        {
            "CcyNm": "Bahraini Dinar",
            "Ccy": "BHD"
        },
        {
            "CcyNm": "Baht",
            "Ccy": "THB"
        },
        {
            "CcyNm": "Balboa",
            "Ccy": "PAB"
        },
        {
            "CcyNm": "Barbados Dollar",
            "Ccy": "BBD"
        },
        {
            "CcyNm": "Belarusian Ruble",
            "Ccy": "BYN"
        },
        {
            "CcyNm": "Belize Dollar",
            "Ccy": "BZD"
        },
        {
            "CcyNm": "Bermudian Dollar",
            "Ccy": "BMD"
        },
        {
            "CcyNm": "Boliviano",
            "Ccy": "BOB"
        },
        {
            "CcyNm": "Bolívar Soberano",
            "Ccy": "VES"
        },
        {
            "CcyNm": "Brazilian Real",
            "Ccy": "BRL"
        },
        {
            "CcyNm": "Brunei Dollar",
            "Ccy": "BND"
        },
        {
            "CcyNm": "Bulgarian Lev",
            "Ccy": "BGN",
        },
        {
            "CcyNm": "Burundi Franc",
            "Ccy": "BIF"
        },
        {
            "CcyNm": "Cabo Verde Escudo",
            "Ccy": "CVE"
        },
        {
            "CcyNm": "CFA Franc BEAC",
            "Ccy": "XAF"
        },
        {
            "CcyNm": "CFA Franc BCEAO",
            "Ccy": "XOF"
        },
        {
            "CcyNm": "CFP Franc",
            "Ccy": "XPF"
        },
        {
            "CcyNm": "Canadian Dollar",
            "Ccy": "CAD"
        },
        {
            "CcyNm": "Cayman Islands Dollar",
            "Ccy": "KYD"
        },
        {
            "CcyNm": "Colombian Peso",
            "Ccy": "COP"
        },
        {
            "CcyNm": "Comorian Franc ",
            "Ccy": "KMF"
        },
        {
            "CcyNm": "Congolese Franc",
            "Ccy": "CDF"
        },
        {
            "CcyNm": "Cordoba Oro",
            "Ccy": "NIO"
        },
        {
            "CcyNm": "Costa Rican Colon",
            "Ccy": "CRC"
        },
        {
            "CcyNm": "Cuban Peso",
            "Ccy": "CUP"
        },
        {
            "CcyNm": "Czech Koruna",
            "Ccy": "CZK"
        },
        {
            "CcyNm": "Dalasi",
            "Ccy": "GMD"
        },
        {
            "CcyNm": "Danish Krone",
            "Ccy": "DKK"
        },
        {
            "CcyNm": "Denar",
            "Ccy": "MKD"
        },
        {
            "CcyNm": "Djibouti Franc",
            "Ccy": "DJF"
        },
        {
            "CcyNm": "Dobra",
            "Ccy": "STN"
        },
        {
            "CcyNm": "Dominican Peso",
            "Ccy": "DOP"
        },
        {
            "CcyNm": "East Caribbean Dollar",
            "Ccy": "XCD"
        },
        {
            "CcyNm": "Egyptian Pound",
            "Ccy": "EGP"
        },
        {
            "CcyNm": "El Salvador Colon",
            "Ccy": "SVC"
        },
        {
            "CcyNm": "Ethiopian Birr",
            "Ccy": "ETB"
        },
        {
            "CcyNm": "Falkland Islands Pound",
            "Ccy": "FKP"
        },
        {
            "CcyNm": "Fiji Dollar",
            "Ccy": "FJD"
        },
        {
            "CcyNm": "Forint",
            "Ccy": "HUF"
        },
        {
            "CcyNm": "Ghana Cedi",
            "Ccy": "GHS"
        },
        {
            "CcyNm": "Gibraltar Pound",
            "Ccy": "GIP"
        },
        {
            "CcyNm": "Gourde",
            "Ccy": "HTG"
        },
        {
            "CcyNm": "Guinean Franc",
            "Ccy": "GNF"
        },
        {
            "CcyNm": "Guyana Dollar",
            "Ccy": "GYD"
        },
        {
            "CcyNm": "Guarani",
            "Ccy": "PYG"
        },
        {
            "CcyNm": "Hong Kong Dollar",
            "Ccy": "HKD"
        },
        {
            "CcyNm": "Hryvnia",
            "Ccy": "UAH"
        },
        {
            "CcyNm": "Iceland Krona",
            "Ccy": "ISK"
        },
        {
            "CcyNm": "Indian Rupee",
            "Ccy": "INR"
        },
        {
            "CcyNm": "Iranian Rial",
            "Ccy": "IRR"
        },
        {
            "CcyNm": "Iraqi Dinar",
            "Ccy": "IQD"
        },
        {
            "CcyNm": "Jamaican Dollar",
            "Ccy": "JMD"
        },
        {
            "CcyNm": "Jordanian Dinar",
            "Ccy": "JOD"
        },
        {
            "CcyNm": "Kenyan Shilling",
            "Ccy": "KES"
        },
        {
            "CcyNm": "Kina",
            "Ccy": "PGK"
        },
        {
            "CcyNm": "Kuna",
            "Ccy": "HRK"
        },
        {
            "CcyNm": "Kuwaiti Dinar",
            "Ccy": "KWD"
        },
        {
            "CcyNm": "Kwanza",
            "Ccy": "AOA"
        },
        {
            "CcyNm": "Kyat",
            "Ccy": "MMK"
        },
        {
            "CcyNm": "Lari",
            "Ccy": "GEL"
        },
        {
            "CcyNm": "Lao Kip",
            "Ccy": "LAK"
        },
        {
            "CcyNm": "Lebanese Pound",
            "Ccy": "LBP"
        },
        {
            "CcyNm": "Lek",
            "Ccy": "ALL"
        },
        {
            "CcyNm": "Leone",
            "Ccy": "SLL"
        },
        {
            "CcyNm": "Lempira",
            "Ccy": "HNL"
        },
        {
            "CcyNm": "Liberian Dollar",
            "Ccy": "LRD"
        },
        {
            "CcyNm": "Libyan Dinar",
            "Ccy": "LYD"
        },
        {
            "CcyNm": "Lilangeni",
            "Ccy": "SZL"
        },
        {
            "CcyNm": "Loti",
            "Ccy": "LSL"
        },
        {
            "CcyNm": "Malaysian Ringgit",
            "Ccy": "MYR"
        },
        {
            "CcyNm": "Malagasy Ariary",
            "Ccy": "MGA"
        },
        {
            "CcyNm": "Malawi Kwacha",
            "Ccy": "MWK"
        },
        {
            "CcyNm": "Mauritius Rupee",
            "Ccy": "MUR"
        },
        {
            "CcyNm": "Mexican Peso",
            "Ccy": "MXN"
        },
        {
            "CcyNm": "Moldovan Leu",
            "Ccy": "MDL"
        },
        {
            "CcyNm": "Moroccan Dirham",
            "Ccy": "MAD"
        },
        {
            "CcyNm": "Mozambique Metical",
            "Ccy": "MZN"
        },
        {
            "CcyNm": "Naira",
            "Ccy": "NGN"
        },
        {
            "CcyNm": "Nakfa",
            "Ccy": "ERN"
        },
        {
            "CcyNm": "Namibia Dollar",
            "Ccy": "NAD"
        },
        {
            "CcyNm": "Nepalese Rupee",
            "Ccy": "NPR"
        },
        {
            "CcyNm": "Netherlands Antillean Guilder",
            "Ccy": "ANG",
        },
        {
            "CcyNm": "New Israeli Sheqel",
            "Ccy": "ILS"
        },
        {
            "CcyNm": "New Taiwan Dollar",
            "Ccy": "TWD"
        },
        {
            "CcyNm": "New Zealand Dollar",
            "Ccy": "NZD"
        },
        {
            "CcyNm": "Ngultrum",
            "Ccy": "BTN"
        },
        {
            "CcyNm": "North Korean Won",
            "Ccy": "KPW"
        },
        {
            "CcyNm": "Norwegian Krone",
            "Ccy": "NOK"
        },
        {
            "CcyNm": "Ouguiya",
            "Ccy": "MRU"
        },
        {
            "CcyNm": "Pataca",
            "Ccy": "MOP"
        },
        {
            "CcyNm": "Pakistan Rupee",
            "Ccy": "PKR"
        },
        {
            "CcyNm": "Pa’anga",
            "Ccy": "TOP"
        },
        {
            "CcyNm": "Pound Sterling",
            "Ccy": "GBP"
        },
        {
            "CcyNm": "Peso Uruguayo",
            "Ccy": "UYU"
        },
        {
            "CcyNm": "Philippine Peso",
            "Ccy": "PHP"
        },
        {
            "CcyNm": "Pula",
            "Ccy": "BWP"
        },
        {
            "CcyNm": "Qatari Rial",
            "Ccy": "QAR"
        },
        {
            "CcyNm": "Quetzal",
            "Ccy": "GTQ"
        },
        {
            "CcyNm": "Rand",
            "Ccy": "ZAR"
        },
        {
            "CcyNm": "Rial Omani",
            "Ccy": "OMR"
        },
        {
            "CcyNm": "Riel",
            "Ccy": "KHR"
        },
        {
            "CcyNm": "Romanian Leu",
            "Ccy": "RON"
        },
        {
            "CcyNm": "Rupiah",
            "Ccy": "IDR"
        },
        {
            "CcyNm": "Rufiyaa",
            "Ccy": "MVR"
        },
        {
            "CcyNm": "Russian Ruble",
            "Ccy": "RUB"
        },
        {
            "CcyNm": "Rwanda Franc",
            "Ccy": "RWF"
        },
        {
            "CcyNm": "Saint Helena Pound",
            "Ccy": "SHP"
        },
        {
            "CcyNm": "Saudi Riyal",
            "Ccy": "SAR"
        },
        {
            "CcyNm": "Serbian Dinar",
            "Ccy": "RSD"
        },
        {
            "CcyNm": "Seychelles Rupee",
            "Ccy": "SCR"
        },
        {
            "CcyNm": "Singapore Dollar",
            "Ccy": "SGD"
        },
        {
            "CcyNm": "Sol",
            "Ccy": "PEN"
        },
        {
            "CcyNm": "Solomon Islands Dollar",
            "Ccy": "SBD"
        },
        {
            "CcyNm": "Som",
            "Ccy": "KGS"
        },
        {
            "CcyNm": "Somali Shilling",
            "Ccy": "SOS"
        },
        {
            "CcyNm": "Somoni",
            "Ccy": "TJS"
        },
        {
            "CcyNm": "South Sudanese Pound",
            "Ccy": "SSP"
        },
        {
            "CcyNm": "Sri Lanka Rupee",
            "Ccy": "LKR"
        },
        {
            "CcyNm": "Sudanese Pound",
            "Ccy": "SDG"
        },
        {
            "CcyNm": "Surinam Dollar",
            "Ccy": "SRD"
        },
        {
            "CcyNm": "Swedish Krona",
            "Ccy": "SEK"
        },
        {
            "CcyNm": "Swiss Franc",
            "Ccy": "CHF"
        },
        {
            "CcyNm": "Syrian Pound",
            "Ccy": "SYP"
        },
        {
            "CcyNm": "Tanzanian Shilling",
            "Ccy": "TZS"
        },
        {
            "CcyNm": "Tala",
            "Ccy": "WST"
        },
        {
            "CcyNm": "Taka",
            "Ccy": "BDT"
        },
        {
            "CcyNm": "Tenge",
            "Ccy": "KZT"
        },
        {
            "CcyNm": "Trinidad and Tobago Dollar",
            "Ccy": "TTD"
        },
        {
            "CcyNm": "Tugrik",
            "Ccy": "MNT"
        },
        {
            "CcyNm": "Tunisian Dinar",
            "Ccy": "TND"
        },
        {
            "CcyNm": "Turkish Lira",
            "Ccy": "TRY"
        },
        {
            "CcyNm": "Turkmenistan New Manat",
            "Ccy": "TMT"
        },
        {
            "CcyNm": "Uganda Shilling",
            "Ccy": "UGX"
        },
        {
            "CcyNm": "UAE Dirham",
            "Ccy": "AED"
        },
        {
            "CcyNm": "Uzbekistan Sum",
            "Ccy": "UZS"
        },
        {
            "CcyNm": "Vatu",
            "Ccy": "VUV"
        },
        {
            "CcyNm": "Vietnamese Dong",
            "Ccy": "VND"
        },
        {
            "CcyNm": "Won",
            "Ccy": "KRW"
        },
        {
            "CcyNm": "Yemeni Rial",
            "Ccy": "YER"
        },
        {
            "CcyNm": "Yen",
            "Ccy": "JPY"
        },
        {
            "CcyNm": "Yuan Renminbi",
            "Ccy": "CNY"
        },
        {
            "CcyNm": "Zambian Kwacha",
            "Ccy": "ZMW"
        },
        {
            "CcyNm": "Zimbabwe Dollar",
            "Ccy": "ZWL"
        },
        {
            "CcyNm": "Zloty",
            "Ccy": "PLN"
        }
    ]
}

export default currency;
