import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Col } from "shards-react"; 

const TituloPagina3 = ({ title, subtitle, className, ...attrs }) => {
  const classes = classNames(
    className,
    "text-center"
  );

  return (
    <Col xs="12" sm="12" className={classes} { ...attrs }>
      <h3 className="page-title">{title}</h3>
      <span className="text-uppercase page-subtitle">{subtitle}</span>
    </Col>
  )
};

TituloPagina3.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default TituloPagina3;
