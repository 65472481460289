import React, {useEffect} from 'react';

//UI
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import ContractDrawerFinancesSend from "./ContractDrawerFinancesSend";
import ContractDrawerFinancesReceive from "./ContractDrawerFinancesReceive";


import { Table } from 'reactstrap';
import { Container, Row, Col } from "shards-react";

//translate
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  list: {
    width: 400,
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    background: '#0b151d'
  }
});

export default function TemporaryDrawerLeft(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: true,
    language: 'English',
    close: false,
    receive: true,
    documentSidebarStartsOpenX: false,
  });

  useEffect(() => {
    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      setState({ ...state, language: "English" });
    }else{
      setState({ ...state, language: languageX });
    }

    if (props.documentSidebarStartsOpen === 'Yes'){
      setState({ ...state, documentSidebarStartsOpenX: true });
    }

  }, []);

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open, documentSidebarStartsOpenX: true});
  };

  const list = anchor => (
      <>
        {state.documentSidebarStartsOpenX ?
          <>
            <div
                style={ props.colorDark ? { backgroundColor:'#0b151d'} : {}}
                className={clsx(classes.list, {
                  [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                })}
                role="presentation"
            >
              <div>
                  <Container fluid className="main-content-container px-2" style={ props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                    <Table borderless responsive>
                      <tbody>
                        <tr>
                          <td style={{width: "97%"}}>
                            <Row noGutters className="page-header py-2">
                              <Col xs="12" sm="12" className={classes} >
                                <h3 className="page-title"><FormattedMessage id="finances.tittle" /></h3>
                              </Col>
                            </Row>
                          </td>
                          <td style={{width: "3%"}}>
                            <Row noGutters className="page-header py-2">
                                <strong style={{cursor: 'pointer'}} onClick={toggleDrawer(anchor, false)}><img title={props.closeSidebar} src="images/closeSidebarLeftGrey.png" weight="20" height="20" alt=""/></strong>
                            </Row>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Container>
              </div>

              <ContractDrawerFinancesSend colorDark={props.colorDark} />
              {state.receive ?
                <ContractDrawerFinancesReceive colorDark={props.colorDark} />
              : null }
              <div>
                  <Container fluid className="main-content-container px-2" style={ props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                    <Table borderless responsive>
                      <tbody>
                        <tr>
                          <td style={{width: "97%"}}>
                            <Row noGutters className="page-header py-2">
                              &nbsp;
                            </Row>
                          </td>
                          <td style={{width: "3%"}}>
                            <Row noGutters className="page-header py-2">
                                <strong style={{cursor: 'pointer'}} onClick={toggleDrawer(anchor, false)}><img title={props.closeSidebar} src="images/closeSidebarLeftGrey.png" weight="20" height="20" alt=""/></strong>
                            </Row>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Container>
              </div>
            </div>
          </>
        : null }
      </>
  );
  return (
    <div>
      {['left'].map(anchor => (
        <React.Fragment key={anchor}>
          <strong style={{cursor: 'pointer'}} onClick={toggleDrawer(anchor, true)}><img title={props.openSidebar} src="images/openSidebarLeftGrey.png" weight="35" height="35" alt=""/></strong>&nbsp;Finance
          {state.documentSidebarStartsOpenX ?
            <Drawer anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, true)}
                    classes={ props.colorDark ? { paper: classes.paper } : {}}
              >
              {list(anchor)}
            </Drawer>
          : null }
        </React.Fragment>
      ))}
    </div>
  );
}
