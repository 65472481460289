import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Table } from 'reactstrap';
import classnames from 'classnames';

import "./tab.css";

//translate
import { FormattedMessage } from 'react-intl';

class ContractTab extends Component {


  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.opcion0 = this.opcion0.bind(this);

    this.state = {
      activeTab: '1',
      language: 'English',
      tab1_1: false,
      tab1_2: false,
      tab1_3: false,
      tab1_4: false,
      tab1_5: false,
      tab1_6: false,
      tab2_1: false,
      tab2_2: false,
      tab2_3: false,
      tab4_1: false,
      tab4_2: false,
      tab5_1: false,
      tab5_2: false,
      tab5_3: false,
      tab5_4: false,
      tab5_5: false,
      tab5_6: false,
      tab5_7: false,
      tab5_8: false,
      tab6_1: false,
      tab6_2: false,
      tab7_1: false,
      tab8_1: false,
      tab9_1: false,
      tab10_1: false,
      tab10_2: false,
    }
  }

  UNSAFE_componentWillMount() {
    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English' })
    }else{
      this.setState({ language: languageX })
    }

    if (this.props.displayRoleX === 'Support'){
      this.setState({activeTab: '5'});
    }

  }

  toggle = (tab) => {
    this.setState({activeTab: tab});
    this.opcion0()
    //if (tab === '1'){
    //  this.opcion1()
    //}
    if (tab === '8'){
      this.opcion20()
    }
    if (tab === '9'){
      this.opcion21()
    }
    if (tab === '5'){
       if (this.props.displayLifeCycleX !== 'Published'){
          this.opcion25()
       }else{
         if (this.props.deliverableFormsX){
           this.opcion29()
         }else{
           this.opcion11()
         }
       }
    }
  }

  onMouseEnterHandler = (i,mode) =>{
    this.setState({ tab1_1: false,tab1_2: false,tab1_3: false,tab1_4: false,tab1_5: false,tab1_6: false,tab2_1: false,
                    tab2_2: false,tab2_3: false,tab4_1: false,tab4_2: false,tab5_1: false,tab5_2: false,tab5_3: false,tab5_4: false,tab5_5: false,tab5_6: false,
                    tab5_7: false,tab5_8: false,tab6_1: false,tab6_2: false,tab7_1: false,tab8_1: false,tab9_1: false,tab10_1: false,tab10_2: false})
    if (mode === 'tab1'){
      if (i === 1){this.setState({tab1_1: true})}
      if (i === 2){this.setState({tab1_2: true})}
      if (i === 3){this.setState({tab1_3: true})}
      if (i === 4){this.setState({tab1_4: true})}
      if (i === 5){this.setState({tab1_5: true})}
      if (i === 6){this.setState({tab1_6: true})}
    }
    if (mode === 'tab2'){
      if (i === 1){this.setState({tab2_1: true})}
      if (i === 2){this.setState({tab2_2: true})}
      if (i === 3){this.setState({tab2_3: true})}
    }
    if (mode === 'tab4'){
      if (i === 1){this.setState({tab4_1: true})}
      if (i === 2){this.setState({tab4_2: true})}
    }
    if (mode === 'tab5'){
      if (i === 1){this.setState({tab5_1: true})}
      if (i === 2){this.setState({tab5_2: true})}
      if (i === 3){this.setState({tab5_3: true})}
      if (i === 4){this.setState({tab5_4: true})}
      if (i === 5){this.setState({tab5_5: true})}
      if (i === 6){this.setState({tab5_6: true})}
      if (i === 7){this.setState({tab5_7: true})}
      if (i === 8){this.setState({tab5_8: true})}
    }
    if (mode === 'tab6'){
      if (i === 1){this.setState({tab6_1: true})}
      if (i === 2){this.setState({tab6_2: true})}
    }
    if (mode === 'tab7'){
      if (i === 1){this.setState({tab7_1: true})}
    }
    if (mode === 'tab8'){
      if (i === 1){this.setState({tab8_1: true})}
    }
    if (mode === 'tab9'){
      if (i === 1){this.setState({tab9_1: true})}
    }
    if (mode === 'tab10'){
      if (i === 1){this.setState({tab10_1: true})}
      if (i === 2){this.setState({tab10_2: true})}
    }
  }

  onMouseLeaveHandler = (i,mode) => {
    if (mode === 'tab1'){
      if (i === 1){this.setState({tab1_1: false})}
      if (i === 2){this.setState({tab1_2: false})}
      if (i === 3){this.setState({tab1_3: false})}
      if (i === 4){this.setState({tab1_4: false})}
      if (i === 5){this.setState({tab1_5: false})}
      if (i === 6){this.setState({tab1_6: false})}
    }
    if (mode === 'tab2'){
      if (i === 1){this.setState({tab2_1: false})}
      if (i === 2){this.setState({tab2_2: false})}
      if (i === 3){this.setState({tab2_3: false})}
    }
    if (mode === 'tab4'){
      if (i === 1){this.setState({tab4_1: false})}
      if (i === 2){this.setState({tab4_2: false})}
    }
    if (mode === 'tab5'){
      if (i === 1){this.setState({tab5_1: false})}
      if (i === 2){this.setState({tab5_2: false})}
      if (i === 3){this.setState({tab5_3: false})}
      if (i === 4){this.setState({tab5_4: false})}
      if (i === 5){this.setState({tab5_5: false})}
      if (i === 6){this.setState({tab5_6: false})}
      if (i === 7){this.setState({tab5_7: false})}
      if (i === 8){this.setState({tab5_8: false})}
    }
    if (mode === 'tab6'){
      if (i === 1){this.setState({tab6_1: false})}
      if (i === 2){this.setState({tab6_2: false})}
    }
    if (mode === 'tab7'){
      if (i === 1){this.setState({tab7_1: false})}
    }
    if (mode === 'tab8'){
      if (i === 1){this.setState({tab8_1: false})}
    }
    if (mode === 'tab9'){
      if (i === 1){this.setState({tab9_1: false})}
    }
    if (mode === 'tab10'){
      if (i === 1){this.setState({tab10_1: false})}
      if (i === 2){this.setState({tab10_2: false})}
    }
  }

  opcionArrow1(){
    let e = ''
    this.props.buttonArrow1(e, this.props.icontypeContract, this.props.iconconfigurationContractNumber, this.props.ningunoSign, this.props.algunosSign, this.props.todosSign, this.props.ningunoDevice, this.props.algunosDevice, this.props.todosDevice, this.props.icondisplayRoleX, this.props.icondisplayOriginX, this.props.activeContract, this.props.nameContractEditor, this.props.icondisplayReportX, this.props.icondisplayUserX, this.props.icondisplaySignatureX, this.props.icondisplayLinkX, this.props.icondisplayEyeX, this.props.icondisplayChatX, this.props.icondisplayTagX, this.props.icondisplaySubjectX, this.props.icondisplayCryptoCurrencyX, this.props.icondisplayBlockchainX, this.props.icondisplayAssociatedX, this.props.icondisplayRulesX, this.props.icondisplayDocumentX, this.props.icondisplayShareX, this.props.icondisplayActiveParticipantsX, this.props.icondisplayDeliverableX, this.props.icondisplayFormsDeliverableX, this.props.icodisplayAuthorizersDeliverableX, this.props.icodisplayRolDeliverableX, this.props.icodisplayTagDeliverableX, this.props.icodisplayReporteTurnoDeliverableX, this.props.displayStringTagX, this.props.displayColourTagX, this.props.displayCircleGreenX, this.props.activeUserGroupX, this.props.confirmSignX, this.props.activeDeliverableX, this.props.activeDataDashboardX, this.props.displayRoleX, this.props.displayOriginX, this.props.encryptDecryptFileContractX, this.props.encryptDecryptFileX, this.props.displayLifeCycleX, this.props.icondisplayRegisterX, this.props.confirmRegisterX, this.props.selectedSignaturePaymentX, this.props.displayColourLetterTagX, this.props.active_after_publishedX)
  }

  opcionArrow2(){
    this.props.buttonArrow2()
  }

  opcionArrow3(){
    this.props.buttonArrow3(this.props.pageContracts, this.props.scrollEnd)
  }

  opcion0(){
    this.props.button0()
  }

  opcion1(){
    let e = ''
    this.props.button1(e, this.props.icontypeContract, this.props.iconconfigurationContractNumber, this.props.ningunoSign, this.props.algunosSign, this.props.todosSign, this.props.ningunoDevice, this.props.algunosDevice, this.props.todosDevice, this.props.icondisplayRoleX, this.props.icondisplayOriginX, this.props.activeContract, this.props.nameContractEditor, this.props.icondisplayReportX, this.props.icondisplayUserX, this.props.icondisplaySignatureX, this.props.icondisplayLinkX, this.props.icondisplayEyeX, this.props.icondisplayChatX, this.props.icondisplayTagX, this.props.icondisplaySubjectX, this.props.icondisplayCryptoCurrencyX, this.props.icondisplayBlockchainX, this.props.icondisplayAssociatedX, this.props.icondisplayRulesX, this.props.icondisplayDocumentX, this.props.icondisplayShareX, this.props.icondisplayActiveParticipantsX, this.props.icondisplayDeliverableX, this.props.icondisplayFormsDeliverableX, this.props.icondisplayAuthorizersDeliverableX, this.props.icodisplayRolDeliverableX, this.props.icodisplayTagDeliverableX, this.props.icodisplayReporteTurnoDeliverableX, this.props.displayStringTagX, this.props.displayColourTagX, this.props.displayCircleGreenX, this.props.activeUserGroupX, this.props.confirmSignX, this.props.activeDeliverableX, this.props.activeDataDashboardX, this.props.displayRoleX, this.props.displayOriginX, this.props.encryptDecryptFileContractX, this.props.encryptDecryptFileX, this.props.displayLifeCycleX, this.props.icondisplayRegisterX, this.props.confirmRegisterX, this.props.selectedSignaturePaymentX, this.props.displayColourLetterTagX, this.props.active_after_publishedX)
  }

  opcion2(){
    this.props.button2(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayAssociatedX, this.props.displayRoleX)
  }

  opcion3(){
    this.props.button3(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayRulesX, this.props.displayRoleX)
  }
  opcion4(){
    this.props.button4(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayReportX, this.props.displayRoleX, this.props.displayOriginX)
  }

  opcion5(){
    this.props.button5(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayUserX, this.props.displayRoleX, this.props.displayOriginX, this.props.activeUserGroupX)
  }

  opcion6(){
    this.props.button6(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displaySignatureX, this.props.displayRoleX, this.props.displayUserX, this.props.confirmSignX, this.props.displayRegisterX)
  }

  opcion7(){
    this.props.button7(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayCryptoCurrencyX, this.props.displayRoleX)
  }

  opcion8(){
    this.props.button8(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayBlockchainX, this.props.displayRoleX)
  }

  opcion9(){
    this.props.button9(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayLinkX, this.props.displayRoleX)
  }

  opcion10(){
    this.props.button10(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayEyeX, this.props.displayRoleX, this.props.displayOriginX)
  }

  opcion11(){
    this.props.button11(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayDeliverableX, this.props.displayFormsDeliverableX, this.props.displayAuthorizersDeliverableX, this.props.displayRolDeliverableX, this.props.displayTagDeliverableX, this.props.displayReporteTurnoDeliverableX, this.props.displayRoleX)
  }

  opcion12(){
    this.props.button12(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayChatX, this.props.icondisplayTagX, this.props.displayRoleX)
  }

  opcion13(){
    this.props.button13(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayShareX, this.props.displayRoleX)
  }

  opcion14(){
    this.props.button14(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayTagX, this.props.displayRoleX)
  }

  opcion15(){
    this.props.button15(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayActiveParticipantsX, this.props.displayRoleX)
  }

  opcion16(){
    this.props.button16(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayActiveParticipantsX, this.props.displayRoleX)
  }

  opcion17(){
    this.props.button17(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayActiveParticipantsX, this.props.displayRoleX)
  }

  opcion18(){
    this.props.button18(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayActiveParticipantsX, this.props.displayRoleX)
  }

  opcion19(){
  }

  opcion20(){
    this.props.button20(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayRoleX)
  }

  opcion21(){
    this.props.button21(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayRoleX, this.props.displayUserX, this.props.displayOriginX, this.props.activeUserGroupX)
  }

  opcion22(){
    this.props.button22(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayRegisterX, this.props.displayRoleX, this.props.displayUserX, this.props.confirmRegisterX)
  }

  opcion23(){
    this.props.button23(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayRoleX, this.props.displayUserX, this.props.displayOriginX, this.props.activeUserGroupX)
  }

  opcion24(){
    this.props.button24(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayRoleX, this.props.displayUserX, this.props.displayOriginX, this.props.activeUserGroupX)
  }

  opcion25(){
    this.props.button25(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayDeliverableX, this.props.displayFormsDeliverableX, this.props.displayAuthorizersDeliverableX, this.props.displayRolDeliverableX, this.props.displayTagDeliverableX, this.props.displayReporteTurnoDeliverableX, this.props.displayRoleX)
  }

  opcion26(){
    this.props.button26(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayDeliverableX, this.props.displayFormsDeliverableX, this.props.displayAuthorizersDeliverableX, this.props.displayRolDeliverableX, this.props.displayTagDeliverableX, this.props.displayReporteTurnoDeliverableX, this.props.displayRoleX)
  }

  opcion27(){
    this.props.button27(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayDeliverableX, this.props.displayFormsDeliverableX, this.props.displayAuthorizersDeliverableX, this.props.displayRolDeliverableX, this.props.displayTagDeliverableX, this.props.displayReporteTurnoDeliverableX, this.props.displayRoleX)
  }

  opcion28(){
    this.props.button28(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayDeliverableX, this.props.displayFormsDeliverableX, this.props.displayAuthorizersDeliverableX, this.props.displayRolDeliverableX, this.props.displayTagDeliverableX, this.props.displayReporteTurnoDeliverableX, this.props.displayRoleX)
  }

  opcion29(){
    this.props.button29(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayDeliverableX, this.props.displayFormsDeliverableX, this.props.displayAuthorizersDeliverableX, this.props.displayRolDeliverableX, this.props.displayTagDeliverableX, this.props.displayReporteTurnoDeliverableX, this.props.displayRoleX)
  }

  opcion30(){
    this.props.button30(this.props.typeContract, this.props.configurationContractNumber, this.props.displaySubjectX, this.props.displayDeliverableX, this.props.displayFormsDeliverableX, this.props.displayAuthorizersDeliverableX, this.props.displayRolDeliverableX, this.props.displayTagDeliverableX, this.props.displayReporteTurnoDeliverableX, this.props.displayRoleX)
  }

  render() {
      let payX = this.props.pay
      let deliverableX = this.props.deliverable
      let certificatesX = this.props.certificates

      if (this.props.pay){
         if (this.props.displayLifeCycleX !== 'Published'){
            payX = false
         }
      }

      if (this.props.certificates){
         if (this.props.displayLifeCycleX !== 'Published'){
            certificatesX = false
         }
      }

      if (this.props.deliverable){
         if (this.props.displayLifeCycleX !== 'Published'){
            deliverableX = false
         }
      }

      let creatorX = false
      if (this.props.displayRoleX === 'Creator'){
         creatorX = true
      }

      let imgRegister = 'images/registerRed.png'
      if (this.props.todosSign && this.props.displayLifeCycleX === 'Published'){
         imgRegister = 'images/registerGreen.png'
      }else{
        if (this.props.todosSign && this.props.displayLifeCycleX !== 'Published'){
           imgRegister = 'images/registerOrange.png'
        }
      }

      let paymentformX = false
      if (this.props.paymentform === true){
        if (this.props.selectedSignaturePaymentX === true){
          paymentformX = true
        }
      }

      let pay2X = this.props.pay
      if (this.props.pay === true){
        if (this.props.selectedSignaturePaymentX === false){
          pay2X = false
        }
      }

      let contractX = false
      if (this.props.typeContract === 'blank' || this.props.typeContract === 'uploaded'){
        contractX = true
      }

      let documentXSupport = true
      let documentXSupport2 = true
      let largoBarraEntregable = '8'

      let displayFormsDeliverableX = "images/formsGrey.png"
      let displayAuthorizersDeliverableX = "images/userGrey.png"
      let displayRolDeliverableX = "images/rolGrey.png"
      let displayTagDeliverableX = "images/etiqueta2Grey.png"
      let displayReporteTurnoDeliverableX = "images/reporteTurnoGreen.png"

      if (this.props.displayRoleX === 'Support'){
         documentXSupport = false
         if (this.props.username !== this.props.displayOriginX){
            documentXSupport2 = false
            largoBarraEntregable = '3'
         }
         deliverableX = true
      }
      return (
        <div>
          <br></br>
          <Nav tabs pills className="myTab" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
            { documentXSupport ?
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => this.toggle('1')}
                >
                  <FormattedMessage id="tab.document" />
                </NavLink>
              </NavItem>
            : null}
            { paymentformX ?
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '8' })}
                  onClick={() => this.toggle('8')}
                >
                  <FormattedMessage id="tab.paymentform" />
                </NavLink>
              </NavItem>
            : null}
            {this.props.participants ?
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => this.toggle('2')}
                >
                  <FormattedMessage id="tab.participants" />
                </NavLink>
              </NavItem>
            : null}
            {pay2X ?
              <>
                {payX ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '9' })}
                      onClick={() => this.toggle('9')}
                    >
                      <FormattedMessage id="tab.pay" />
                    </NavLink>
                  </NavItem>
                :
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '9' })}
                      disabled={true}
                    >
                      <FormattedMessage id="tab.pay" />
                    </NavLink>
                  </NavItem>
                }
              </>
            : null
            }
            {this.props.certification ?
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => this.toggle('3')}
                >
                  <FormattedMessage id="tab.certification" />
                </NavLink>
              </NavItem>
            : null}
            {this.props.trace ?
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => this.toggle('4')}
                >
                  <FormattedMessage id="tab.trace" />
                </NavLink>
              </NavItem>
            : null}

            {this.props.deliverable ?
              <>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '5' })}
                      onClick={() => this.toggle('5')}
                    >
                      <FormattedMessage id="tab.deliverable" />
                    </NavLink>
                  </NavItem>
              </>
            : null
            }
            {this.props.network ?
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '6' })}
                  onClick={() => this.toggle('6')}
                >
                  <FormattedMessage id="tab.network" />
                </NavLink>
              </NavItem>
            : null}
            {this.props.addons ?
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '7' })}
                  onClick={() => this.toggle('7')}
                >
                  <FormattedMessage id="tab.addons" />
                </NavLink>
              </NavItem>
            : null}
            {this.props.certificates ?
              <>
                {certificatesX ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '10' })}
                      onClick={() => this.toggle('10')}
                    >
                      <FormattedMessage id="tab.certificates" />
                    </NavLink>
                  </NavItem>
                :
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '10' })}
                    disabled={true}
                  >
                    <FormattedMessage id="tab.certificates" />
                  </NavLink>
                </NavItem>
                }
              </>
            : null}
          </Nav>
          <TabContent activeTab={this.state.activeTab} >
            {documentXSupport ?
            <TabPane tabId="1">
                <Col md="6">
                  <Table size="sm"  style={{fontSize:10}} className="text-center" borderless responsive hover striped>
                    <thead>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{height:"45px"}}>
                          <td onMouseEnter={()=>this.onMouseEnterHandler(1,'tab1')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'tab1')}>
                            <strong style={{cursor: 'pointer'}} onClick={(e)=>{this.opcionArrow3()}}>
                              {this.props.colorDark ?
                                <>
                                  {this.state.tab1_1 ?
                                    <img src="images/arrowleftgrey.png" weight="25" height="35" alt=""/>
                                  :
                                    <img src="images/arrowleftgrey.png" weight="25" height="25" alt=""/>
                                  }
                                </>
                              :
                                <>
                                  {this.state.tab1_1 ?
                                    <img src="images/arrowleft.png" weight="25" height="35" alt=""/>
                                  :
                                    <img src="images/arrowleft.png" weight="25" height="25" alt=""/>
                                  }
                                </>
                              }
                            </strong>
                          </td>
                          <td onMouseEnter={()=>this.onMouseEnterHandler(2,'tab1')} onMouseLeave={()=>this.onMouseLeaveHandler(2,'tab1')}>
                            <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion1()}}>
                              {this.state.tab1_2 ?
                                <img src={this.props.displayDocumentX} weight="35" height="35" alt=""/>
                              :
                                <img src={this.props.displayDocumentX} weight="25" height="25" alt=""/>
                              }
                            </strong>
                          </td>
                          <td onMouseEnter={()=>this.onMouseEnterHandler(3,'tab1')} onMouseLeave={()=>this.onMouseLeaveHandler(3,'tab1')}>
                            <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion4()}}>
                              {this.state.tab1_3 ?
                                <img src={this.props.displayReportX} weight="35" height="35" alt=""/>
                              :
                                <img src={this.props.displayReportX} weight="25" height="25" alt=""/>
                              }
                            </strong>
                          </td>
                          <td onMouseEnter={()=>this.onMouseEnterHandler(4,'tab1')} onMouseLeave={()=>this.onMouseLeaveHandler(4,'tab1')}>
                            <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion14()}}>
                              {this.state.tab1_4 ?
                                <img src={this.props.displayTagX} weight="35" height="35" alt=""/>
                              :
                                <img src={this.props.displayTagX} weight="25" height="25" alt=""/>
                              }
                            </strong>
                          </td>
                          <td onMouseEnter={()=>this.onMouseEnterHandler(5,'tab1')} onMouseLeave={()=>this.onMouseLeaveHandler(5,'tab1')}>
                            <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion2()}}>
                              {this.state.tab1_5 ?
                                <img src={this.props.displayAssociatedX} weight="30" height="35" alt=""/>
                              :
                              <img src={this.props.displayAssociatedX} weight="23" height="23" alt=""/>
                              }
                            </strong>
                          </td>
                          <td onMouseEnter={()=>this.onMouseEnterHandler(6,'tab1')} onMouseLeave={()=>this.onMouseLeaveHandler(6,'tab1')}>
                            <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion3()}}>
                              {this.state.tab1_6 ?
                                <img src={this.props.displayRulesX} weight="35" height="35" alt=""/>
                              :
                                <img src={this.props.displayRulesX} weight="25" height="25" alt=""/>
                              }
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol19" /></th>
                          <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol17" /></th>
                          <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol9" /></th>
                          <th style={{ width: "10px" }}><FormattedMessage id="configuration.tag" /></th>
                          <th style={{ width: "10px" }}><FormattedMessage id="configuration.associatedto" /></th>
                          <th style={{ width: "10px" }}><FormattedMessage id="configuration.rules" /></th>
                        </tr>
                    </tbody>
                  </Table>
                </Col>
            </TabPane>
            : null }
            <TabPane tabId="2">
              <Col md="3">
                <Table size="sm"  style={{fontSize:10}} className="text-center" borderless responsive hover striped>
                  <thead>
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                  </thead>
                  <tbody>
                      <tr style={{height:"45px"}}>
                        <td onMouseEnter={()=>this.onMouseEnterHandler(1,'tab2')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'tab2')}>
                          <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion5()}}>
                            {this.state.tab2_1 ?
                              <img src={this.props.displayUserX} weight="35" height="35" alt=""/>
                            :
                              <img src={this.props.displayUserX} weight="25" height="25" alt=""/>
                            }
                          </strong>
                        </td>
                        <td onMouseEnter={()=>this.onMouseEnterHandler(2,'tab2')} onMouseLeave={()=>this.onMouseLeaveHandler(2,'tab2')}>
                          <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion6()}}>
                            {this.state.tab2_2 ?
                              <img src={this.props.displaySignatureX} weight="35" height="35" alt=""/>
                            :
                              <img src={this.props.displaySignatureX} weight="25" height="25" alt=""/>
                            }
                          </strong>
                        </td>
                        {creatorX ?
                          <>
                            {this.props.todosSign ?
                              <td onMouseEnter={()=>this.onMouseEnterHandler(3,'tab2')} onMouseLeave={()=>this.onMouseLeaveHandler(3,'tab2')}>
                                <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion22()}}>
                                  {this.state.tab2_3 ?
                                    <img src={imgRegister} weight="35" height="35" alt=""/>
                                  :
                                    <img src={imgRegister} weight="25" height="25" alt=""/>
                                  }
                                </strong>
                              </td>
                            :
                              <td onMouseEnter={()=>this.onMouseEnterHandler(3,'tab2')} onMouseLeave={()=>this.onMouseLeaveHandler(3,'tab2')}>
                                <strong style={{cursor: 'pointer'}}>
                                  {this.state.tab2_3 ?
                                    <img src="images/registerRed.png" weight="35" height="35" alt=""/>
                                  :
                                    <img src="images/registerRed.png" weight="25" height="25" alt=""/>
                                  }
                                </strong>
                              </td>
                            }
                          </>
                        :
                          <td></td>
                        }
                      </tr>
                      <tr>
                        <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol10" /></th>
                        <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol11" /></th>
                        {creatorX ?
                           <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol27" /></th>
                        : null }
                      </tr>
                  </tbody>
                </Table>
              </Col>
            </TabPane>
            <TabPane tabId="3">
              <Col md="5">
                <Table size="sm"  style={{fontSize:10}} className="text-center" borderless responsive hover striped>
                  <thead>
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                  </thead>
                  <tbody>
                      <tr style={{height:"45px"}}>
                        {this.props.activarparticipants ?
                            <td><strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion15()}}>
                            <img src="images/activateparticipantsGrey.png" weight="25" height="25" alt=""/></strong></td>
                        : <td></td> }
                        {this.props.certificarfirma ?
                            <td><strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion16()}}>
                            <img src="images/certificatesignRed.png" weight="25" height="25" alt=""/></strong></td>
                        : <td></td> }
                        {this.props.activarentregable ?
                            <td><strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion17()}}>
                            <img src="images/activatedeliverableRed.png" weight="25" height="25" alt=""/></strong></td>
                        : <td></td> }
                        {this.props.certificarreglas ?
                            <td><strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion18()}}>
                            <img src="images/certificaterulesRed.png" weight="25" height="25" alt=""/></strong></td>
                        : <td></td> }
                      </tr>
                      <tr>
                        {this.props.activarparticipants ?
                          <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol21" /></th>
                        : <th style={{ width: "10px" }}></th> }
                        {this.props.certificarfirma ?
                          <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol22" /></th>
                        : <th style={{ width: "10px" }}></th> }
                        {this.props.activarentregable ?
                          <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol23" /></th>
                        : <th style={{ width: "10px" }}></th> }
                        {this.props.certificarreglas ?
                          <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol24" /></th>
                        : <th style={{ width: "10px" }}></th> }
                      </tr>
                  </tbody>
                </Table>
              </Col>
            </TabPane>
            <TabPane tabId="4">
              <Col md="3">
                <Table size="sm"  style={{fontSize:10}} className="text-center" borderless responsive hover striped>
                  <thead>
                      <tr>
                          <td></td>
                          <td></td>
                      </tr>
                  </thead>
                  <tbody>
                      <tr style={{height:"45px"}}>
                        <td onMouseEnter={()=>this.onMouseEnterHandler(1,'tab4')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'tab4')}>
                          <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion9()}}>
                            {this.state.tab4_1 ?
                              <img src={this.props.displayLinkX} weight="35" height="35" alt=""/>
                            :
                              <img src={this.props.displayLinkX} weight="25" height="25" alt=""/>
                            }
                          </strong>
                        </td>
                        <td onMouseEnter={()=>this.onMouseEnterHandler(2,'tab4')} onMouseLeave={()=>this.onMouseLeaveHandler(2,'tab4')}>
                          <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion10()}}>
                            {this.state.tab4_2 ?
                              <img src={this.props.displayEyeX} weight="35" height="35" alt=""/>
                            :
                              <img src={this.props.displayEyeX} weight="25" height="25" alt=""/>
                            }
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol14" /></th>
                        <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol15" /></th>
                      </tr>
                  </tbody>
                </Table>
              </Col>
            </TabPane>
            <TabPane tabId="5">
              <Col md={largoBarraEntregable}>
                <Table size="sm"  style={{fontSize:10}} className="text-center" borderless responsive hover striped>
                  <thead>
                      <tr>
                        { !documentXSupport ?
                          <td></td>
                        : null }
                        {documentXSupport2 ?
                          <>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </>
                        : null }
                        {this.props.deliverableFormsX ?
                           <td></td>
                        : null }
                        <td></td>
                        <td></td>
                      </tr>
                  </thead>
                  <tbody>
                      <tr style={{height:"45px"}}>
                        { !documentXSupport ?
                          <td onMouseEnter={()=>this.onMouseEnterHandler(6,'tab5')} onMouseLeave={()=>this.onMouseLeaveHandler(6,'tab5')}>
                            <strong style={{cursor: 'pointer'}} onClick={(e)=>{this.opcionArrow3()}}>
                              {this.props.colorDark ?
                                <>
                                  {this.state.tab5_6 ?
                                    <img src="images/arrowleftgrey.png" weight="25" height="35" alt=""/>
                                  :
                                    <img src="images/arrowleftgrey.png" weight="25" height="25" alt=""/>
                                  }
                                </>
                              :
                                <>
                                  {this.state.tab5_6 ?
                                    <img src="images/arrowleft.png" weight="25" height="35" alt=""/>
                                  :
                                    <img src="images/arrowleft.png" weight="25" height="25" alt=""/>
                                  }
                                </>
                              }
                            </strong>
                          </td>
                        : null }
                        {documentXSupport2 ?
                          <>
                            <td onMouseEnter={()=>this.onMouseEnterHandler(1,'tab5')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'tab5')}>
                              <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion25()}}>
                                {this.state.tab5_1 ?
                                  <img src={displayFormsDeliverableX} weight="35" height="35" alt=""/>
                                :
                                  <img src={displayFormsDeliverableX} weight="25" height="25" alt=""/>
                                }
                              </strong>
                            </td>
                            <td onMouseEnter={()=>this.onMouseEnterHandler(4,'tab5')} onMouseLeave={()=>this.onMouseLeaveHandler(4,'tab5')}>
                              <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion27()}}>
                                {this.state.tab5_4 ?
                                  <img src={displayRolDeliverableX} weight="35" height="35" alt=""/>
                                :
                                  <img src={displayRolDeliverableX} weight="25" height="25" alt=""/>
                                }
                              </strong>
                            </td>
                            <td onMouseEnter={()=>this.onMouseEnterHandler(2,'tab5')} onMouseLeave={()=>this.onMouseLeaveHandler(2,'tab5')}>
                              <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion26()}}>
                                {this.state.tab5_2 ?
                                  <img src={displayAuthorizersDeliverableX} weight="35" height="35" alt=""/>
                                :
                                  <img src={displayAuthorizersDeliverableX} weight="25" height="25" alt=""/>
                                }
                              </strong>
                            </td>
                            <td onMouseEnter={()=>this.onMouseEnterHandler(5,'tab5')} onMouseLeave={()=>this.onMouseLeaveHandler(5,'tab5')}>
                              <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion28()}}>
                                {this.state.tab5_5 ?
                                  <img src={displayTagDeliverableX} weight="35" height="35" alt=""/>
                                :
                                  <img src={displayTagDeliverableX} weight="25" height="25" alt=""/>
                                }
                              </strong>
                            </td>
                          </>
                        : null }
                        {deliverableX ?
                          <>
                            {this.props.deliverableFormsX ?
                              <td onMouseEnter={()=>this.onMouseEnterHandler(7,'tab5')} onMouseLeave={()=>this.onMouseLeaveHandler(7,'tab5')}>
                                <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion29()}}>
                                  {this.state.tab5_7 ?
                                    <img src={displayReporteTurnoDeliverableX} weight="35" height="35" alt=""/>
                                  :
                                    <img src={displayReporteTurnoDeliverableX} weight="25" height="25" alt=""/>
                                  }
                                </strong>
                              </td>
                            : null }
                            <td onMouseEnter={()=>this.onMouseEnterHandler(3,'tab5')} onMouseLeave={()=>this.onMouseLeaveHandler(3,'tab5')}>
                                <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion11()}}>
                                  {this.state.tab5_3 ?
                                    <img src="images/deliverableGrey3.png" weight="36" height="36" alt=""/>
                                  :
                                    <img src="images/deliverableGrey3.png" weight="29" height="29" alt=""/>
                                  }
                                </strong>
                            </td>
                            <td onMouseEnter={()=>this.onMouseEnterHandler(8,'tab5')} onMouseLeave={()=>this.onMouseLeaveHandler(8,'tab5')}>
                                <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion30()}}>
                                  {this.state.tab5_8 ?
                                    <img src="images/reporteria.png" weight="36" height="36" alt=""/>
                                  :
                                    <img src="images/reporteria.png" weight="29" height="29" alt=""/>
                                  }
                                </strong>
                            </td>
                          </>
                        :
                          <>
                            {this.props.deliverableFormsX ?
                              <td>
                                <img src={displayReporteTurnoDeliverableX} weight="25" height="25" alt=""/>
                              </td>
                            : null }
                            <td>
                              <img src="images/deliverableGrey3.png" weight="29" height="29" alt=""/>
                            </td>
                            <td>
                              <img src="images/reporteria.png" weight="29" height="29" alt=""/>
                            </td>
                          </>
                        }
                      </tr>
                      <tr>
                        { !documentXSupport ?
                           <th style={{ width: "10px" }}></th>
                        : null }
                        {documentXSupport2 ?
                          <>
                            <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol28" /></th>
                            <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol30" /></th>
                            <th style={{ width: "10px" }}><FormattedMessage id="configuration.usergroup" /></th>
                            <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol31" /></th>
                          </>
                        : null }
                        {this.props.deliverableFormsX ?
                          <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol34" /></th>
                        : null }
                        <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol20" /></th>
                        <th style={{ width: "10px" }}><FormattedMessage id="tab.reporte" /></th>
                      </tr>
                  </tbody>
                </Table>
              </Col>
            </TabPane>
            <TabPane tabId="6">
              <Col md="3">
                <Table size="sm"  style={{fontSize:10}} className="text-center" borderless responsive hover striped>
                  <thead>
                      <tr>
                         <td></td>
                         <td></td>
                      </tr>
                  </thead>
                  <tbody>
                      <tr style={{height:"45px"}}>
                        <td onMouseEnter={()=>this.onMouseEnterHandler(1,'tab6')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'tab6')}>
                          <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion12()}}>
                            {this.state.tab6_1 ?
                              <img src={this.props.displayChatX} weight="35" height="35" alt=""/>
                            :
                            <img src={this.props.displayChatX} weight="25" height="25" alt=""/>
                            }
                          </strong>
                        </td>
                        <td onMouseEnter={()=>this.onMouseEnterHandler(2,'tab6')} onMouseLeave={()=>this.onMouseLeaveHandler(2,'tab6')}>
                          <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion13()}}>
                            {this.state.tab6_2 ?
                              <img src={this.props.displayShareX} weight="35" height="35" alt=""/>
                            :
                              <img src={this.props.displayShareX} weight="25" height="25" alt=""/>
                            }
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol16" /></th>
                        <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol18" /></th>
                      </tr>
                  </tbody>
                </Table>
              </Col>
            </TabPane>
            <TabPane tabId="7">
              <Col md="2">
                <Table size="sm"  style={{fontSize:10}} className="text-center" borderless responsive hover striped>
                  <thead>
                      <tr>
                          <td></td>
                      </tr>
                  </thead>
                  <tbody>
                      <tr style={{height:"45px"}}>
                        <td onMouseEnter={()=>this.onMouseEnterHandler(1,'tab7')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'tab7')}>
                          <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion19()}}>
                            {this.state.tab7_1 ?
                              <img src="images/calendarGrey.png" weight="35" height="35" alt=""/>
                            :
                              <img src="images/calendarGrey.png" weight="25" height="25" alt=""/>
                            }
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: "10px" }}><FormattedMessage id="contract.listcol25" /></th>
                      </tr>
                  </tbody>
                </Table>
              </Col>
            </TabPane>
            <TabPane tabId="8">
              <Col md="2">
                <Table size="sm"  style={{fontSize:10}} className="text-center" borderless responsive hover striped>
                  <thead>
                      <tr>
                          <td></td>
                      </tr>
                  </thead>
                  <tbody>
                      <tr style={{height:"45px"}}>
                        <td onMouseEnter={()=>this.onMouseEnterHandler(1,'tab8')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'tab8')}>
                          <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion20()}}>
                            {this.state.tab8_1 ?
                              <img src="images/paymentformGreen.png" weight="35" height="35" alt=""/>
                            :
                              <img src="images/paymentformGreen.png" weight="25" height="25" alt=""/>
                            }
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: "10px" }}><FormattedMessage id="tab.paymentform" /></th>
                      </tr>
                  </tbody>
                </Table>
              </Col>
            </TabPane>
            <TabPane tabId="9">
              <Col md="2">
                <Table size="sm"  style={{fontSize:10}} className="text-center" borderless responsive hover striped>
                  <thead>
                      <tr>
                          <td></td>
                      </tr>
                  </thead>
                  <tbody>
                      <tr style={{height:"45px"}}>
                        <td onMouseEnter={()=>this.onMouseEnterHandler(1,'tab9')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'tab9')}>
                           <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion21()}}>
                             {this.state.tab9_1 ?
                               <img src="images/payGreen.png" weight="35" height="35" alt=""/>
                             :
                               <img src="images/payGreen.png" weight="25" height="25" alt=""/>
                             }
                            </strong>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: "10px" }}><FormattedMessage id="tab.pay" /></th>
                      </tr>
                  </tbody>
                </Table>
              </Col>
            </TabPane>
            <TabPane tabId="10">
              <Col md="2">
                <Table size="sm"  style={{fontSize:10}} className="text-center" borderless responsive hover striped>
                  <thead>
                    {pay2X ?
                      <tr>
                          <td></td>
                      </tr>
                    : null
                    }
                  </thead>
                  <tbody>
                      <tr style={{height:"45px"}}>
                        {contractX ?
                          <td onMouseEnter={()=>this.onMouseEnterHandler(1,'tab10')} onMouseLeave={()=>this.onMouseLeaveHandler(1,'tab10')}>
                             <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion24()}}>
                               {this.state.tab10_1 ?
                                 <img src={this.props.displayDocumentX} weight="35" height="35" alt=""/>
                               :
                                 <img src={this.props.displayDocumentX} weight="25" height="25" alt=""/>
                               }
                              </strong>
                          </td>
                        : null
                        }
                        {pay2X ?
                          <td onMouseEnter={()=>this.onMouseEnterHandler(2,'tab10')} onMouseLeave={()=>this.onMouseLeaveHandler(2,'tab10')}>
                             <strong style={{cursor: 'pointer'}} onClick={()=>{this.opcion23()}}>
                               {this.state.tab10_2 ?
                                 <img src="images/certificate1.png" weight="35" height="35" alt=""/>
                               :
                                 <img src="images/certificate1.png" weight="25" height="25" alt=""/>
                               }
                             </strong>
                          </td>
                        : null
                        }
                      </tr>
                      <tr>
                        {contractX ?
                           <th style={{ width: "10px" }}><FormattedMessage id="tab.contract" /></th>
                        : null
                        }
                        {pay2X ?
                           <th style={{ width: "10px" }}><FormattedMessage id="tab.feespaid" /></th>
                        : null
                        }
                      </tr>
                  </tbody>
                </Table>
              </Col>
            </TabPane>
          </TabContent>
        </div>
      );
  }
}

export default ContractTab;
