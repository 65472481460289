import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./components/layout/Default";

// Route Views
import ContenedorContract from "./views/ContenedorContract";
import ContenedorMasterFile from "./views/ContenedorMasterFile";
import UserProfileLite from "./views/UserProfileLite";
import SearchAdvanced from "./views/SearchAdvanced";
import Errors from "./views/Errors";
import UnderConstruction from "./views/UnderConstruction";
import UserGuide from "./views/UserGuide";
import ContenedorDashboard from "./views/ContenedorDashboard";
import ContenedorBlogNew from "./views/ContenedorBlogNew";
import ContenedorBlogViewAllPost from "./views/ContenedorBlogViewAllPost";
import ContenedorBlogEditBlogDetail from "./views/ContenedorBlogEditBlogDetail";
import ContenedorBlogWriteNewPost from "./views/ContenedorBlogWriteNewPost";
import ContenedorSimulador from "./views/ContenedorSimulador";
import ContenedorInvitation from "./views/ContenedorInvitation";
import ContenedorMyFinances from "./views/ContenedorMyFinances";
import ConntenedorMasterFile from "./views/ContenedorMasterFile";

import AboutContact from "./views/AboutContact";
import AboutCrosscheck from "./views/AboutCrosscheck";
import TermsAndConditions from "./views/TermsAndConditions";
import PolicyPrivacy from "./views/PolicyPrivacy";

//Route Components
import DatosSensores from './components/sensors/DatosSensores';

export default [
  {
    path: "/profile",
    exact: true,
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/start",
    exact: true,
    layout: DefaultLayout,
    component: ContenedorContract
  },
  {
    path: "/new",
    exact: true,
    layout: DefaultLayout,
    component: ContenedorContract
  },
  {
    path: "/open",
    exact: true,
    layout: DefaultLayout,
    component: ContenedorContract
  },
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/start" />
  },
  {
    path: "/logout",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/" />
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/sensores/nuevo",
    exact: true,
    layout: DefaultLayout,
    component: DatosSensores
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: ContenedorDashboard
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/help",
    layout: DefaultLayout,
    component: UserGuide
  },
  {
    path: "/about/contact",
    layout: DefaultLayout,
    component: AboutContact
  },
  {
    path: "/about/crosscheck",
    layout: DefaultLayout,
    component: AboutCrosscheck
  },
  {
    path: "/terms",
    layout: DefaultLayout,
    component: TermsAndConditions
  },
  {
    path: "/policy",
    layout: DefaultLayout,
    component: PolicyPrivacy
  },
  {
    path: "/simulator",
    layout: DefaultLayout,
    component: ContenedorSimulador
  },
  {
    path: "/invitation",
    layout: DefaultLayout,
    component: ContenedorInvitation
  },
  {
    path: "/finance",
    layout: DefaultLayout,
    component: ContenedorMyFinances
  },
  {
    path: "/configuration",
    layout: DefaultLayout,
    component: ConntenedorMasterFile
  },
  //-------------------------------------------------
  {
    path: "/blogs/new",
    layout: DefaultLayout,
    component: ContenedorBlogNew
  },
  {
    path: "/blogs/admin",
    layout: DefaultLayout,
    component: ContenedorBlogViewAllPost
  },
  {
    path: "/blogs/edit",
    layout: DefaultLayout,
    component: ContenedorBlogEditBlogDetail
  },
  {
    path: "/blogs/posts/new",
    layout: DefaultLayout,
    component: ContenedorBlogWriteNewPost
  },
  {
    path: "/search/advanced",
    layout: DefaultLayout,
    component: SearchAdvanced
  },
  {
    path: "/masters",
    layout: DefaultLayout,
    component: ContenedorMasterFile
  },
  {
    path: "/underconstruction",
    layout: DefaultLayout,
    component: UnderConstruction
  }
  //---------------------------------------------------
];
