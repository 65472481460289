import React, { Component } from 'react';

import {Button, Card } from "shards-react";

//translate
import { FormattedMessage } from 'react-intl';

//download
import Base64Downloader from 'react-base64-downloader';

//base64
import { Base64 } from 'js-base64';

class MyDownload extends Component {

  constructor(props) {
      super(props);
      this.exitProgram = this.exitProgram.bind(this);
      this.downloadFile = this.downloadFile.bind(this);

      this.state = {
        dialogMessage: '',
        dialogOpenCancel: false,
        dialogTitle: '',
      }
  }

  UNSAFE_componentWillMount() {
    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English' })
    }else{
      this.setState({ language: languageX })
    }
  }

  exitProgram(){
     //window.location = '/'
     this.props.returnProps();
  }

  downloadFile(){
     if (this.props.typeAttachText){
       let fileX = this.props.filedecodeAttachX
       if (this.props.filedecodeAttachX.substring(0,22)!=='data:text/plain;base64'){
          let fileY = 'data:text/plain;base64'
          fileX = Base64.encode(this.props.filedecodeAttachX)
          fileX = `${fileY},${fileX}`
       }
       window.location.replace(fileX)
     }else{
        window.location.replace(this.props.filedecodeAttachX)
     }
  }

  render() {

    let actionsX = 'Click to Download'
    if (this.state.language === 'English'){actionsX = 'Click to Download'}
    if (this.state.language === 'French'){actionsX = 'Cliquer pour télécharger'}
    if (this.state.language === 'Spanish'){actionsX = 'Haga clic para Descargar'}
    if (this.state.language === 'Portuguese'){actionsX = 'Clique para fazer o download'}
    if (this.state.language === 'Swedish'){actionsX = 'Klicka för att ladda ner'}
    if (this.state.language === 'Netherlands'){actionsX = 'Klik om te downloaden'}
    if (this.state.language === 'Russian'){actionsX = 'Нажмите, чтобы загрузить'}
    if (this.state.language === 'Japanese'){actionsX = 'クリックしてダウンロード'}
    if (this.state.language === 'Chinese'){actionsX = '点击下载'}
    if (this.state.language === 'German'){actionsX = 'Klicken Sie hier zum Herunterladen'}
    if (this.state.language === 'Italian'){actionsX = 'Clicca per Scaricare'}

    const {nameAttachX, filedecodeAttachX, fileAttachX, typeAttachX} = this.props
    const { typeAttachText, typeAttachImage, typeAttachPdf, typeAttachAudio, typeAttachVideo } = this.props;

    return (
        <>
          { typeAttachImage ?
              <Base64Downloader
                  base64={filedecodeAttachX}
                  downloadName={nameAttachX}
                  Tag="a"
                  extraAttributes={{ href: '#' }}
                  className="my-class-name"
                  style={{ color: 'orange' }}
                  onDownloadSuccess={() => console.log('File download initiated')}
                  onDownloadError={() => console.warn('Download failed to start')}
              >
                  {actionsX}
              </Base64Downloader>
          :
              <React.Fragment>
                  {this.downloadFile()}
              </React.Fragment>
          }
        </>
    );
  }
}

export default MyDownload;
