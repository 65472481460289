//React
import React, { Component } from 'react';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Container, Button, Card, FormCheckbox, ListGroup, ListGroupItem,
        FormSelect, FormInput } from "shards-react";
import { Table } from 'reactstrap';
import { Divider, Typography, TextField, Grid, Dialog,
        DialogContent, DialogActions, DialogTitle, DialogContentText,
        Button as ButtonMaterialUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';

// blockstack
//import { UserSession } from 'blockstack';
import { writeFile, readFile } from "blockstack-large-storage";
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//translate
import { FormattedMessage } from 'react-intl';

import {username_new} from '../../actions/userActions'
import {putFileDB} from '../../actions/mongoDBActions'


function ConfirmacionSaveDraft(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class NewContractRules extends Component {

    constructor(props) {
        super(props);

        this.clickRulesEnrollment = this.clickRulesEnrollment.bind(this);
        this.closeRulesEnrollment = this.closeRulesEnrollment.bind(this);
        this.clickSaveEnrollment = this.clickSaveEnrollment.bind(this);
        this.saveDraftConfirm = this.saveDraftConfirm.bind(this);
        this.saveDraftRulesConfirm = this.saveDraftRulesConfirm.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          dialogOpen: false,
          language: 'English',
          clickRules: false,
          displayRules: false,
          indiceRules: '',
          displayRulesDisable: false,
          displayRulesEnrollment: '',
          clickRules: false,
          isErrorWrite: false,
          jsonEnrollment: [],
          dialogOpenSaveDraft: false,
          dialogMessage: '',
          dialogTitle: '',

          //----------------------------------------
          //Datos Fijos para checkbox de Sensores
          //----------------------------------------
          c01SensorContract:true,
          c02SensorContract:true,
          c03SensorContract:true,
          c04SensorContract:false,
          c05SensorContract:false,
          c06SensorContract:false,
          c07SensorContract:false,
          c08SensorContract:true,
          c09SensorContract:true,
          c10SensorContract:true,
          c11SensorContract:true,
          c12SensorContract:true,
          c13SensorContract:true,

          //----------------------------------------
          //Datos Fijos para Area Reglas de Sensores
          //----------------------------------------
          sensorTemperatureActiveRule: false,
          sensorHumidityActiveRule: false,
          sensorGasControlActiveRule: false,
          sensorGPSActiveRule: false,

          sTemperatureLowerThreshold: '',
          sTemperatureUpperThreshold: '',
          sTemperatureFrecuency: '',
          sTemperatureUnitFrecuency: '',
          sTemperatureUnitMeasurement: '',
          sTemperatureErrorTime: '',
          sTemperatureErrorTimeUnit: '',
          sTemperatureErrorAction: '',
          sTemperatureActiveRule: '',

          sHumidityLowerThreshold: '',
          sHumidityUpperThreshold: '',
          sHumidityFrecuency: '',
          sHumidityUnitFrecuency: '',
          sHumidityUnitMeasurement: '',
          sHumidityErrorTime: '',
          sHumidityErrorTimeUnit: '',
          sHumidityErrorAction: '',
          sHumidityActiveRule: '',

          sGPSFrecuency: '',
          sGPSUnitFrecuency: '',
          sGPSUnitMeasurement: '',
          sGPSErrorTime: '',
          sGPSErrorTimeUnit: '',
          sGPSErrorAction: '',
          sGPSActiveRule: '',

          sFireErrorAction: '',
          sFireActiveRule: '',

          sFloodErrorAction: '',
          sFloodActiveRule: '',

          sMotionErrorAction: '',
          sMotionActiveRule: '',

          sSealIntegrityAction: '',
          sSealIntegrityActiveRule: '',

          sSmokeAction: '',
          sSmokeActiveRule: '',

          sTemperature:false,
          sHumidity:false,
          sGascontrol:false,
          sImpact:false,
          sVibration:false,
          sProximity:false,
          sSealIntegrity:false,
          sFire:false,
          sFlood:false,
          sFlood:false,
          sMotion:false,
          sSmoke:false,

          //----------------------------------------

        }
    }

    //----------------------------------------
    //Datos Fijos para Area Reglas de Sensores
    //----------------------------------------
    sensorTemperatureLowerThreshold = React.createRef();
    sensorTemperatureUpperThreshold = React.createRef();
    sensorTemperatureFrecuency = React.createRef();
    sensorTemperatureUnitFrecuency = React.createRef();
    sensorTemperatureUnitMeasurement = React.createRef();
    sensorTemperatureErrorTime = React.createRef();
    sensorTemperatureErrorTimeUnit = React.createRef();
    sensorTemperatureErrorAction = React.createRef();

    sensorHumidityLowerThreshold = React.createRef();
    sensorHumidityUpperThreshold = React.createRef();
    sensorHumidityFrecuency = React.createRef();
    sensorHumidityUnitFrecuency = React.createRef();
    sensorHumidityUnitMeasurement = React.createRef();
    sensorHumidityErrorTime = React.createRef();
    sensorHumidityErrorTimeUnit = React.createRef();
    sensorHumidityErrorAction = React.createRef();

    sensorGasControlLowerThreshold = React.createRef();
    sensorGasControlUpperThreshold = React.createRef();
    sensorGasControlFrecuency = React.createRef();
    sensorGasControlUnitFrecuency = React.createRef();
    sensorGasControlUnitMeasurement = React.createRef();
    sensorGasControlErrorTime = React.createRef();
    sensorGasControlErrorTimeUnit = React.createRef();
    sensorGasControlErrorAction = React.createRef();

    sensorGPSFrecuency = React.createRef();
    sensorGPSUnitFrecuency = React.createRef();
    sensorGPSUnitMeasurement = React.createRef();
    sensorGPSErrorTime = React.createRef();
    sensorGPSErrorTimeUnit = React.createRef();
    sensorGPSErrorAction = React.createRef();

    sensorFireErrorAction = React.createRef();
    sensorFloodErrorAction = React.createRef();
    sensorMotionErrorAction = React.createRef();
    sensorSealIntegrityErrorAction = React.createRef();
    sensorSmokeErrorAction = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
      this.setState({c01SensorContract: this.props.c01SensorContract})
      this.setState({c02SensorContract: this.props.c02SensorContract})
      this.setState({c03SensorContract: this.props.c03SensorContract})
      this.setState({c04SensorContract: this.props.c04SensorContract})
      this.setState({c05SensorContract: this.props.c05SensorContract})
      this.setState({c06SensorContract: this.props.c06SensorContract})
      this.setState({c07SensorContract: this.props.c07SensorContract})
      this.setState({c08SensorContract: this.props.c08SensorContract})
      this.setState({c09SensorContract: this.props.c09SensorContract})
      this.setState({c10SensorContract: this.props.c10SensorContract})
      this.setState({c11SensorContract: this.props.c11SensorContract})
      this.setState({c12SensorContract: this.props.c12SensorContract})
      this.setState({c13SensorContract: this.props.c13SensorContract})

      this.setState({jsonEnrollment: this.props.jsonEnrollment})

    }

    componentDidMount() {
      let e = ''
      this.clickRulesEnrollment(e,this.props.indiceRules)
    }

    handleChange(e,chk) {
      const newState = {};
      newState[chk] = !this.state[chk];
      this.setState({...this.state, ...newState});
      this.setState({isErrorWrite: false})
    }

    handleDialog = (type, action) => {
      if (type === 'Error: 1001') {
        if (action === 'cancel') {
          this.setState({ dialogOpenSaveDraft: false });
        }
      }
    }

    handleChangeNumber(e,chk) {
    }

    handleBlurNumber(e,chk) {
    }

    handleInputNumber(e,chk) {
        if (chk==='temperatureLowerThreshold'){
          if (this.sensorTemperatureLowerThreshold.current.value.length===0){
             this.sensorTemperatureLowerThreshold.current.value = ''
          }
        }else{
          if (chk==='temperatureUpperThreshold'){
            if (this.sensorTemperatureUpperThreshold.current.value.length===0){
               this.sensorTemperatureUpperThreshold.current.value = ''
            }
          }
        }
        if (chk==='humidityLowerThreshold'){
          if (this.sensorHumidityLowerThreshold.current.value.length===0){
             this.sensorHumidityLowerThreshold.current.value = ''
          }
        }else{
          if (chk==='humidityUpperThreshold'){
            if (this.sensorHumidityUpperThreshold.current.value.length===0){
               this.sensorHumidityUpperThreshold.current.value = ''
            }
          }
        }
        if (chk==='gascontrolLowerThreshold'){
          if (this.sensorGasControlLowerThreshold.current.value.length===0){
             this.sensorGasControlLowerThreshold.current.value = ''
          }
        }else{
          if (chk==='gascontrolUpperThreshold'){
            if (this.sensorGasControlUpperThreshold.current.value.length===0){
               this.sensorGasControlUpperThreshold.current.value = ''
            }
          }
        }
        if (chk==='Amount'){
          if (this.c20FieldContract.current.value.length===0){
             this.c20FieldContract.current.value = ''
          }
        }
    }

    clickRulesEnrollment = (e,optx) => {
      const {jsonEnrollment} = this.props
      this.setState({displayRulesEnrollment: ''})
      if (this.props.JSONTemplateName === 'delivery' || this.props.JSONTemplateName === 'simulate'){
          jsonEnrollment.map((todo,i) => {
            if (parseInt(i) === parseInt(optx)){
              if (todo.temperature==="true"){
                    this.sensorTemperatureLowerThreshold.current.value = todo.temperatureLowerThreshold
                    this.sensorTemperatureUpperThreshold.current.value = todo.temperatureUpperThreshold
                    this.sensorTemperatureFrecuency.current.value = todo.temperatureFrecuency
                    this.sensorTemperatureUnitFrecuency.current.value = todo.temperatureUnitFrecuency
                    this.sensorTemperatureUnitMeasurement.current.value = todo.temperatureUnitMeasurement
                    this.sensorTemperatureErrorTime.current.value = todo.temperatureErrorTime
                    this.sensorTemperatureErrorTimeUnit.current.value = todo.temperatureErrorTimeUnit
                    this.sensorTemperatureErrorAction.current.value = todo.temperatureErrorAction
                    if (todo.temperatureActiveRule==="true"){this.setState({sensorTemperatureActiveRule: true})}else{this.setState({sensorTemperatureActiveRule: false})}
              }
              if (todo.humidity==="true"){
                    this.sensorHumidityLowerThreshold.current.value = parseFloat(todo.humidityLowerThreshold)
                    this.sensorHumidityUpperThreshold.current.value = parseFloat(todo.humidityUpperThreshold)
                    this.sensorHumidityFrecuency.current.value = todo.humidityFrecuency
                    this.sensorHumidityUnitFrecuency.current.value = todo.humidityUnitFrecuency
                    this.sensorHumidityUnitMeasurement.current.value = todo.humidityUnitMeasurement
                    this.sensorHumidityErrorTime.current.value = todo.humidityErrorTime
                    this.sensorHumidityErrorTimeUnit.current.value = todo.humidityErrorTimeUnit
                    this.sensorHumidityErrorAction.current.value = todo.humidityErrorAction
                    if (todo.humidityActiveRule==="true"){this.setState({sensorHumidityActiveRule: true})}else{this.setState({sensorHumidityActiveRule: false})}
              }
              if (todo.gascontrol==="true"){
                    this.sensorGasControlLowerThreshold.current.value = parseFloat(todo.gascontrolLowerThreshold)
                    this.sensorGasControlUpperThreshold.current.value = parseFloat(todo.gascontrolUpperThreshold)
                    this.sensorGasControlFrecuency.current.value = todo.gascontrolFrecuency
                    this.sensorGasControlUnitFrecuency.current.value = todo.gascontrolUnitFrecuency
                    this.sensorGasControlUnitMeasurement.current.value = todo.gascontrolUnitMeasurement
                    this.sensorGasControlErrorTime.current.value = todo.gascontrolErrorTime
                    this.sensorGasControlErrorTimeUnit.current.value = todo.gascontrolErrorTimeUnit
                    this.sensorGasControlErrorAction.current.value = todo.gascontrolErrorAction
                    if (todo.gascontrolActiveRule==="true"){this.setState({sensorGasControlActiveRule: true})}else{this.setState({sensorGasControlActiveRule: false})}
              }
              if (todo.gps==="true"){
                    this.sensorGPSFrecuency.current.value = todo.GPSFrecuency
                    this.sensorGPSUnitFrecuency.current.value = todo.GPSUnitFrecuency
                    this.sensorGPSUnitMeasurement.current.value = todo.GPSUnitMeasurement
                    this.sensorGPSErrorTime.current.value = todo.GPSErrorTime
                    this.sensorGPSErrorTimeUnit.current.value = todo.GPSErrorTimeUnit
                    this.sensorGPSErrorAction.current.value = todo.GPSErrorAction
                    if (todo.GPSActiveRule==="true"){this.setState({sensorGPSActiveRule: true})}else{this.setState({sensorGPSActiveRule: false})}
              }
              this.setState({displayRulesEnrollment: todo.enrollment})
              if (todo.temperature==="true"){this.setState({c01SensorContract: true})}else{this.setState({c01SensorContract: false})}
              if (todo.humidity==="true"){this.setState({c02SensorContract: true})}else{this.setState({c02SensorContract: false})}
              if (todo.gascontrol==="true"){this.setState({c03SensorContract: true})}else{this.setState({c03SensorContract: false})}
              if (todo.gps==="true"){this.setState({c08SensorContract: true})}else{this.setState({c08SensorContract: false})}
            }
            if (todo.temperature==="false" && todo.humidity==="false" && todo.gascontrol==="false"){
              this.setState({existContractRules: false})
            }else{
              this.setState({existContractRules: true})
            }
          })
      }else{
        if (this.props.JSONTemplateName === 'maintenance'){
            jsonEnrollment.map((todo,i) => {
              if (parseInt(i) === parseInt(optx)){
                if (todo.fire==="true"){
                      this.sensorFireErrorAction.current.value = todo.fireErrorAction
                      if (todo.fireActiveRule==="true"){this.setState({sensorFireActiveRule: true})}else{this.setState({sensorFireActiveRule: false})}
                }
                if (todo.flood==="true"){
                      this.sensorFloodErrorAction.current.value = todo.floodErrorAction
                      if (todo.floodActiveRule==="true"){this.setState({sensorFloodActiveRule: true})}else{this.setState({sensorFloodActiveRule: false})}
                }
                if (todo.sealintegrity==="true"){
                      this.sensorSealIntegrityErrorAction.current.value = todo.sealintegrityErrorAction
                      if (todo.sealintegrityActiveRule==="true"){this.setState({sensorSealIntegrityActiveRule: true})}else{this.setState({sensorSealIntegrityActiveRule: false})}
                }
                if (todo.motion==="true"){
                      this.sensorMotionErrorAction.current.value = todo.motionErrorAction
                      if (todo.motionActiveRule==="true"){this.setState({sensorMotionActiveRule: true})}else{this.setState({sensorMotionActiveRule: false})}
                }
                if (todo.smoke==="true"){
                      this.sensorSmokeErrorAction.current.value = todo.smokeErrorAction
                      if (todo.smokeActiveRule==="true"){this.setState({sensorSmokeActiveRule: true})}else{this.setState({sensorSmokeActiveRule: false})}
                }
                this.setState({displayRulesEnrollment: todo.enrollment})
                if (todo.fire==="true"){this.setState({c09SensorContract: true})}else{this.setState({c09SensorContract: false})}
                if (todo.flood==="true"){this.setState({c10SensorContract: true})}else{this.setState({c10SensorContract: false})}
                if (todo.sealintegrity==="true"){this.setState({c11SensorContract: true})}else{this.setState({c11SensorContract: false})}
                if (todo.motion==="true"){this.setState({c12SensorContract: true})}else{this.setState({c12SensorContract: false})}
                if (todo.smoke==="true"){this.setState({c13SensorContract: true})}else{this.setState({c13SensorContract: false})}
              }
              if (todo.fire==="false" && todo.flood==="false" && todo.sealintegrity==="false" && todo.motion==="false" && todo.smoke==="false"){
                this.setState({existContractRules: false})
              }else{
                this.setState({existContractRules: true})
              }
            })
         }
      }
      this.setState({displayRules: true})
      this.setState({indiceRules:optx})
      this.setState({displayRulesDisable: false})
      this.setState({clickRules: true})
    }

    closeRulesEnrollment = (e) => {
      this.props.returnRules();
    }

    clickSaveEnrollment = (e,optx) => {
      e.preventDefault();
      const {jsonEnrollment} = this.state
      if (this.props.JSONTemplateName === 'delivery' || this.props.JSONTemplateName === 'simulate'){
          jsonEnrollment.map((todo,i) => {
            if (parseInt(i) === parseInt(optx)){
              if (this.state.c01SensorContract){todo.temperature='true'}else{todo.temperature='false'}
              if (this.state.c02SensorContract){todo.humidity='true'}else{todo.humidity='false'}
              if (this.state.c03SensorContract){todo.gascontrol='true'}else{todo.gascontrol='false'}
              if (this.state.c08SensorContract){todo.gps='true'}else{todo.gps='false'}
              todo.active='true'
              todo.temperatureLowerThreshold = ''
              todo.temperatureUpperThreshold = ''
              todo.temperatureFrecuency = ''
              todo.temperatureUnitFrecuency = ''
              todo.temperatureUnitMeasurement = ''
              todo.temperatureErrorTime = ''
              todo.temperatureErrorTimeUnit = ''
              todo.temperatureErrorAction = ''
              todo.temperatureActiveRule = 'false'
              todo.humidityLowerThreshold = ''
              todo.humidityUpperThreshold = ''
              todo.humidityFrecuency = ''
              todo.humidityUnitFrecuency = ''
              todo.humidityUnitMeasurement = ''
              todo.humidityErrorTime = ''
              todo.humidityErrorTimeUnit = ''
              todo.humidityErrorAction = ''
              todo.humidityActiveRule = 'false'
              todo.gascontrolLowerThreshold = ''
              todo.gascontrolUpperThreshold = ''
              todo.gascontrolFrecuency = ''
              todo.gascontrolUnitFrecuency = ''
              todo.gascontrolUnitMeasurement = ''
              todo.gascontrolErrorTime = ''
              todo.gascontrolErrorTimeUnit = ''
              todo.gascontrolErrorAction = ''
              todo.gascontrolActiveRule = 'false'
              todo.GPSFrecuency = ''
              todo.GPSUnitFrecuency = ''
              todo.GPSUnitMeasurement = ''
              todo.GPSErrorTime = ''
              todo.GPSErrorTimeUnit = ''
              todo.GPSErrorAction = ''
              todo.GPSActiveRule = 'false'
              if (this.state.c01SensorContract){
                    todo.temperatureLowerThreshold = this.sensorTemperatureLowerThreshold.current.value
                    todo.temperatureUpperThreshold = this.sensorTemperatureUpperThreshold.current.value
                    todo.temperatureFrecuency = this.sensorTemperatureFrecuency.current.value
                    todo.temperatureUnitFrecuency = this.sensorTemperatureUnitFrecuency.current.value
                    todo.temperatureUnitMeasurement = this.sensorTemperatureUnitMeasurement.current.value
                    todo.temperatureErrorTime = this.sensorTemperatureErrorTime.current.value
                    todo.temperatureErrorTimeUnit = this.sensorTemperatureErrorTimeUnit.current.value
                    todo.temperatureErrorAction = this.sensorTemperatureErrorAction.current.value
                    if (this.state.sensorTemperatureActiveRule){todo.temperatureActiveRule='true'}else{todo.temperatureActiveRule='false'}
              }
              if (this.state.c02SensorContract){
                    todo.humidityLowerThreshold = this.sensorHumidityLowerThreshold.current.value
                    todo.humidityUpperThreshold = this.sensorHumidityUpperThreshold.current.value
                    todo.humidityFrecuency = this.sensorHumidityFrecuency.current.value
                    todo.humidityUnitFrecuency = this.sensorHumidityUnitFrecuency.current.value
                    todo.humidityUnitMeasurement = this.sensorHumidityUnitMeasurement.current.value
                    todo.humidityErrorTime = this.sensorHumidityErrorTime.current.value
                    todo.humidityErrorTimeUnit = this.sensorHumidityErrorTimeUnit.current.value
                    todo.humidityErrorAction = this.sensorHumidityErrorAction.current.value
                    if (this.state.sensorHumidityActiveRule){todo.humidityActiveRule='true'}else{todo.humidityActiveRule='false'}
              }
              if (this.state.c03SensorContract){
                    todo.gascontrolLowerThreshold = this.sensorGasControlLowerThreshold.current.value
                    todo.gascontrolUpperThreshold = this.sensorGasControlUpperThreshold.current.value
                    todo.gascontrolFrecuency = this.sensorGasControlFrecuency.current.value
                    todo.gascontrolUnitFrecuency = this.sensorGasControlUnitFrecuency.current.value
                    todo.gascontrolUnitMeasurement = this.sensorGasControlUnitMeasurement.current.value
                    todo.gascontrolErrorTime = this.sensorGasControlErrorTime.current.value
                    todo.gascontrolErrorTimeUnit = this.sensorGasControlErrorTimeUnit.current.value
                    todo.gascontrolErrorAction = this.sensorGasControlErrorAction.current.value
                    if (this.state.sensorGasControlActiveRule){todo.gascontrolActiveRule='true'}else{todo.gascontrolActiveRule='false'}
              }
              if (this.state.c08SensorContract){
                    todo.GPSFrecuency = this.sensorGPSFrecuency.current.value
                    todo.GPSUnitFrecuency = this.sensorGPSUnitFrecuency.current.value
                    todo.GPSUnitMeasurement = this.sensorGPSUnitMeasurement.current.value
                    todo.GPSErrorTime = this.sensorGPSErrorTime.current.value
                    todo.GPSErrorTimeUnit = this.sensorGPSErrorTimeUnit.current.value
                    todo.GPSErrorAction = this.sensorGPSErrorAction.current.value
                    if (this.state.sensorGPSActiveRule){todo.GPSActiveRule='true'}else{todo.GPSActiveRule='false'}
              }
            }
          })
      }else{
        if (this.props.JSONTemplateName === 'maintenance'){
            jsonEnrollment.map((todo,i) => {
              if (parseInt(i) === parseInt(optx)){
                if (this.state.c09SensorContract){todo.fire='true'}else{todo.fire='false'}
                if (this.state.c10SensorContract){todo.flood='true'}else{todo.flood='false'}
                if (this.state.c11SensorContract){todo.sealintegrity='true'}else{todo.sealintegrity='false'}
                if (this.state.c12SensorContract){todo.motion='true'}else{todo.motion='false'}
                if (this.state.c13SensorContract){todo.smoke='true'}else{todo.smoke='false'}
                todo.active='true'
                todo.fireErrorAction = ''
                todo.fireActiveRule = 'false'
                todo.floodErrorAction = ''
                todo.floodActiveRule = 'false'
                todo.sealintegrityErrorAction = ''
                todo.sealintegrityActiveRule = 'false'
                todo.motionErrorAction = ''
                todo.motionActiveRule = 'false'
                todo.smokeErrorAction = ''
                todo.smokeActiveRule = 'false'
                if (this.state.c09SensorContract){
                      todo.fireErrorAction = this.sensorFireErrorAction.current.value
                      if (this.state.sensorFireActiveRule){todo.fireActiveRule='true'}else{todo.fireActiveRule='false'}
                }
                if (this.state.c10SensorContract){
                      todo.floodErrorAction = this.sensorFloodErrorAction.current.value
                      if (this.state.sensorFloodActiveRule){todo.floodActiveRule='true'}else{todo.floodActiveRule='false'}
                }
                if (this.state.c11SensorContract){
                      todo.sealintegrityErrorAction = this.sensorSealIntegrityErrorAction.current.value
                      if (this.state.sensorSealIntegrityActiveRule){todo.seaslintegrityActiveRule='true'}else{todo.sealintegrityActiveRule='false'}
                }
                if (this.state.c12SensorContract){
                      todo.motionErrorAction = this.sensorMotionErrorAction.current.value
                      if (this.state.sensorMotionActiveRule){todo.motionActiveRule='true'}else{todo.motionActiveRule='false'}
                }
                if (this.state.c13SensorContract){
                      todo.smokeErrorAction = this.sensorSmokeErrorAction.current.value
                      if (this.state.sensorSmokeActiveRule){todo.smokeActiveRule='true'}else{todo.smokeActiveRule='false'}
                }
              }
            })
        }else{
          if (this.props.JSONTemplateName === 'proforma'){
          }else{
            if (this.props.JSONTemplateName === 'blank'){
            }
          }
        }
      }
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_rules.json`, 'Crosscheck_Rules', JSON.stringify(jsonEnrollment), JSON.stringify(options))
        .then(() => {
           console.log(`Grabación Exitosa (${this.props.typeContract}_${this.props.numberContract}_rules.json)`)
        })
      this.setState({jsonEnrollment})
      this.setState({displayRules: false})
      this.setState({existContractRules: true})
      this.closeRulesEnrollment(e)
    }

    saveDraftConfirm(){
      if (this.state.language === 'English'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract contains signatures, you must delete the signatures before modifying the contract',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'French'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract contient des signatures, vous devez les supprimer avant de modifier le contrat.',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Spanish'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract contiene firmas, debe eliminar las firmas antes de modificar el contrato',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Portuguese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Contrato inteligente contém assinaturas, você deve excluir as assinaturas antes de modificar o contrato',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Swedish'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract innehåller signaturer, du måste ta bort signaturerna innan du ändrar kontraktet',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Netherlands'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract bevat handtekeningen, u moet de handtekeningen verwijderen voordat u het contract wijzigt',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Russian'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract содержит подписи, вы должны удалить подписи перед изменением договора',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Japanese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'スマート契約には署名が含まれています。契約を変更する前に署名を削除する必要があります',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Chinese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: '智能合约包含签名，您必须先删除签名，然后再修改合约',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'German'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract enthält Signaturen. Sie müssen die Signaturen löschen, bevor Sie den Vertrag ändern',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Italian'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Il contratto intelligente contiene firme, è necessario eliminare le firme prima di modificare il contratto',dialogTitle: 'Error: 1001',})
      }
    }

    saveDraftRulesConfirm(){
      if (this.state.language === 'English'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract contains signatures, you must delete the signatures before modifying the rules',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'French'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract contient des signatures, vous devez les supprimer avant de modifier les règles',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Spanish'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract contiene firmas, debe eliminar las firmas antes de modificar las reglas',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Portuguese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Contrato inteligente contém assinaturas, você deve excluir as assinaturas antes de modificar as regras',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Swedish'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract innehåller signaturer, du måste radera signaturerna innan du ändrar reglerna',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Netherlands'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract bevat handtekeningen, u moet de handtekeningen verwijderen voordat u de regels wijzigt',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Russian'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract содержит подписи, вы должны удалить подписи перед изменением правил',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Japanese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'スマートコントラクトには署名が含まれています。ルールを変更する前に署名を削除する必要があります',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Chinese'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: '智能合约包含签名，您必须先删除签名，然后再修改规则',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'German'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Smart Contract enthält Signaturen. Sie müssen die Signaturen löschen, bevor Sie die Regeln ändern',dialogTitle: 'Error: 1001',})
      }
      if (this.state.language === 'Italian'){
        this.setState({dialogOpenSaveDraft: true,dialogMessage: 'Il contratto intelligente contiene firme, è necessario eliminare le firme prima di modificare le regole',dialogTitle: 'Error: 1001',})
      }
    }

    render() {
          const {typeContract} = this.props
          const {templateNameMaintenance} = this.props
          const {templateNameDelivery} = this.props
          const {templateNameProforma} = this.props
          const {templateNameBlank} = this.props
          const {rules2X} = this.props
          const {rules3X} = this.props
          const {temperature2X} = this.props
          const {humidity2X} = this.props
          const {gascontrol2X} = this.props
          const {gps2X} = this.props
          const {fire2X} = this.props
          const {flood2X} = this.props
          const {sealintegrity2X} = this.props
          const {motion2X} = this.props
          const {smoke2X} = this.props
          const {displaySaveRulesX} = this.props
          const {todosSignX} = this.props
          const {ningunoSignX} = this.props
          const {indiceRules} = this.props
          const {algunosSignX} = this.props

          const {jsonEnrollment} = this.state

          const displayFullChargeX = true
          const {displayRules} = this.state

          let correlativo = 0
          let activate = false
          let titleX = ''

          return (
            <div>
              <Container fluid className="main-content-container px-4">
                  <ConfirmacionSaveDraft
                      handleDialog={this.handleDialog}
                      dialogMessage={this.state.dialogMessage}
                      dialogOpen={this.state.dialogOpenSaveDraft}
                      dialogTitle={this.state.dialogTitle}
                  />
                  <Row>
                     <React.Fragment>
                          <Card small>
                            <ListGroup flush>
                              <ListGroupItem className="p-3">
                                {!this.state.displayRulesDisable ?
                                   this.state.existContractRules ?
                                      <h5 style={{ color: 'red' }}>{`${this.props.displayRulesEnrollment}`}</h5>
                                    : <h5 style={{ color: 'red' }}>{`${this.props.displayRulesEnrollment}`}</h5>
                                : <h5 style={{ color: 'red' }}>{`${rules3X}`}</h5> }
                                 <Card small>
                                   { templateNameDelivery
                                   ?
                                     <ListGroup>
                                       <ListGroupItem>
                                         <Form style={{fontSize:11}}>
                                           <Row form>
                                               <Col md="2">
                                                  <FormCheckbox
                                                       inline
                                                       checked={this.state.c01SensorContract}
                                                       onChange={e=>this.handleChange(e, "c01SensorContract")}
                                                       disabled={this.state.displayRulesDisable}
                                                   >
                                                       <FormattedMessage id="contract.temperaturesensor" />
                                                   </FormCheckbox>
                                                </Col>
                                                <Col md="2">
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.c02SensorContract}
                                                       onChange={e=>this.handleChange(e, "c02SensorContract")}
                                                       disabled={this.state.displayRulesDisable}
                                                   >
                                                       <FormattedMessage id="contract.humiditysensor" />
                                                   </FormCheckbox>
                                                </Col>
                                                <Col md="2">
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.c03SensorContract}
                                                       onChange={e=>this.handleChange(e, "c03SensorContract")}
                                                       disabled={this.state.displayRulesDisable}
                                                   >
                                                       <FormattedMessage id="contract.gascontrolsensor" />
                                                   </FormCheckbox>
                                                </Col>
                                                <Col md="2">
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.c04SensorContract}
                                                       onChange={e=>this.handleChange(e, "c04SensorContract")}
                                                       disabled={true}
                                                   >
                                                       <FormattedMessage id="contract.impactsensor" />
                                                   </FormCheckbox>
                                                </Col>
                                                <Col md="2">
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.c05SensorContract}
                                                       onChange={e=>this.handleChange(e, "c05SensorContract")}
                                                       disabled={true}
                                                   >
                                                       <FormattedMessage id="contract.vibrationsensor" />
                                                   </FormCheckbox>
                                                </Col>
                                                <Col md="2">
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.c06SensorContract}
                                                       onChange={e=>this.handleChange(e, "c06SensorContract")}
                                                       disabled={true}
                                                   >
                                                       <FormattedMessage id="contract.proximitysensor" />
                                                   </FormCheckbox>
                                                </Col>
                                           </Row>
                                           <Row form>
                                                <Col md="2">
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.c07SensorContract}
                                                       onChange={e=>this.handleChange(e, "c07SensorContract")}
                                                       disabled={true}
                                                   >
                                                       <FormattedMessage id="contract.sealintegritysensor" />
                                                   </FormCheckbox>
                                                </Col>
                                                <Col md="2">
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.c08SensorContract}
                                                       onChange={e=>this.handleChange(e, "c08SensorContract")}
                                                       disabled={true}
                                                   >
                                                       <FormattedMessage id="contract.gsp" />
                                                   </FormCheckbox>
                                                </Col>
                                           </Row>
                                         </Form>
                                       </ListGroupItem>
                                     </ListGroup>
                                   :
                                     null
                                   }
                                   { templateNameMaintenance
                                   ?
                                     <ListGroup>
                                       <ListGroupItem>
                                         <Form style={{fontSize:11}}>
                                           <Row form>
                                               <Col md="2">
                                                  <FormCheckbox
                                                       inline
                                                       checked={this.state.c09SensorContract}
                                                       onChange={e=>this.handleChange(e, "c09SensorContract")}
                                                       disabled={this.state.displayRulesDisable}
                                                   >
                                                       <FormattedMessage id="contract.firesensor" />
                                                   </FormCheckbox>
                                               </Col>
                                               <Col md="2">
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.c10SensorContract}
                                                       onChange={e=>this.handleChange(e, "c10SensorContract")}
                                                       disabled={this.state.displayRulesDisable}
                                                   >
                                                       <FormattedMessage id="contract.floodsensor" />
                                                   </FormCheckbox>
                                               </Col>
                                               <Col md="2">
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.c11SensorContract}
                                                       onChange={e=>this.handleChange(e, "c11SensorContract")}
                                                       disabled={this.state.displayRulesDisable}
                                                   >
                                                       <FormattedMessage id="contract.motionsensor" />
                                                   </FormCheckbox>
                                               </Col>
                                               <Col md="2">
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.c12SensorContract}
                                                       onChange={e=>this.handleChange(e, "c12SensorContract")}
                                                       disabled={this.state.displayRulesDisable}
                                                   >
                                                       <FormattedMessage id="contract.sealintegritysensor" />
                                                   </FormCheckbox>
                                                </Col>
                                                <Col md="2">
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.c13SensorContract}
                                                       onChange={e=>this.handleChange(e, "c13SensorContract")}
                                                       disabled={this.state.displayRulesDisable}
                                                   >
                                                       <FormattedMessage id="contract.smokesensor" />
                                                   </FormCheckbox>
                                                </Col>
                                           </Row>
                                         </Form>
                                       </ListGroupItem>
                                     </ListGroup>
                                   :
                                     null
                                   }
                                   { templateNameProforma
                                   ?
                                     <ListGroup>
                                       <ListGroupItem>
                                         <Form style={{fontSize:11}}>
                                           <Row form>
                                                <Col md="2">
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.c08SensorContract}
                                                       onChange={e=>this.handleChange(e, "c08SensorContract")}
                                                       disabled={false}
                                                   >
                                                       <FormattedMessage id="contract.gsp" />
                                                   </FormCheckbox>
                                                </Col>
                                           </Row>
                                         </Form>
                                       </ListGroupItem>
                                     </ListGroup>
                                   :
                                     null
                                   }
                                   { templateNameBlank
                                   ?
                                     <ListGroup>
                                       <ListGroupItem>
                                         <Form style={{fontSize:11}}>
                                           <Row form>
                                                <Col md="2">
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.c08SensorContract}
                                                       onChange={e=>this.handleChange(e, "c08SensorContract")}
                                                       disabled={false}
                                                   >
                                                       <FormattedMessage id="contract.gsp" />
                                                   </FormCheckbox>
                                                </Col>
                                           </Row>
                                         </Form>
                                       </ListGroupItem>
                                     </ListGroup>
                                   :
                                     null
                                   }
                                 </Card>
                                 { templateNameDelivery
                                 ?
                                   <React.Fragment>
                                      {this.state.c01SensorContract
                                      ?
                                       <Card small className="mb-12 pt-3">
                                         <h6 style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{temperature2X}</h6>
                                         <Row>
                                           <Col>
                                            <Form style={{fontSize:12}}>
                                               <Row form>
                                                 <Col md="1">&nbsp;</Col>
                                                 <Col md="2">
                                                   <br></br>
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.sensorTemperatureActiveRule}
                                                       onChange={e=>this.handleChange(e, "sensorTemperatureActiveRule")}
                                                       disabled={this.state.displayRulesDisable}
                                                   >
                                                       <FormattedMessage id="contract.activerule" />
                                                   </FormCheckbox>
                                                 </Col>
                                               </Row>
                                               <br></br>
                                               <Row form>
                                                 <Col md="1">&nbsp;</Col>
                                                 <Col md="2">
                                                   <label htmlFor="temperatureLowerThreshold"><FormattedMessage id="contract.lowerthreshold" /></label>
                                                   <FormInput
                                                     id="temperatureLowerThreshold"
                                                     type="number"
                                                     innerRef={this.sensorTemperatureLowerThreshold}
                                                     disabled={this.state.displayRulesDisable}
                                                     onChange={e=>this.handleChangeNumber(e, "temperatureLowerThreshold")}
                                                     onBlur={e=>this.handleBlurNumber(e, "temperatureLowerThreshold")}
                                                     onInput={e=>this.handleInputNumber(e, "temperatureLowerThreshold")}
                                                   />
                                                 </Col>
                                                 <Col md="3">
                                                   <label htmlFor="temperatureUpperThreshold"><FormattedMessage id="contract.upperthreshold" /></label>
                                                   <FormInput
                                                     id="temperatureUpperThreshold"
                                                     type="number"
                                                     innerRef={this.sensorTemperatureUpperThreshold}
                                                     disabled={this.state.displayRulesDisable}
                                                     onChange={e=>this.handleChangeNumber(e, "temperatureUpperThreshold")}
                                                     onBlur={e=>this.handleBlurNumber(e, "temperatureUpperThreshold")}
                                                     onInput={e=>this.handleInputNumber(e, "temperatureUpperThreshold")}
                                                   />
                                                 </Col>
                                                 <Col md="3">
                                                   <label htmlFor="temperatureUnitMeasurement"><FormattedMessage id="contract.unitmeasurement" /></label>
                                                   <FormSelect
                                                       id="temperatureUnitMeasurement"
                                                       innerRef={this.sensorTemperatureUnitMeasurement}
                                                       placeholder="Select an Option"
                                                       disabled={this.state.displayRulesDisable}
                                                     >
                                                     <option value="°C">Celsius</option>
                                                     <option value="°F">Farhenheit</option>
                                                   </FormSelect>
                                                 </Col>
                                                 <Col md="1">&nbsp;</Col>
                                               </Row>
                                               <br></br>
                                               <Row form>
                                                 <Col md="1">&nbsp;</Col>
                                                 <Col md="2">
                                                   <label htmlFor="temperatureFrecuency"><FormattedMessage id="contract.frecuency" /></label>
                                                   <FormInput
                                                     id="temperatureFrecuency"
                                                     innerRef={this.sensorTemperatureFrecuency}
                                                     disabled={this.state.displayRulesDisable}
                                                   />
                                                 </Col>
                                                 <Col md="2">
                                                   <label htmlFor="temperatureUnitFrecuency"><FormattedMessage id="contract.unitfrecuency" /></label>
                                                   <FormSelect
                                                       id="temperatureUnitFrecuency"
                                                       innerRef={this.sensorTemperatureUnitFrecuency}
                                                       placeholder="Select an Option"
                                                       disabled={this.state.displayRulesDisable}
                                                     >
                                                     <option value="Second">Second</option>
                                                     <option value="Minute">Minute</option>
                                                     <option value="Hour">Hour</option>
                                                   </FormSelect>
                                                 </Col>
                                                 <Col md="2">
                                                   <label htmlFor="temperatureErrorTime"><FormattedMessage id="contract.errortime" /></label>
                                                   <FormInput
                                                     id="temperatureErrorTime"
                                                     innerRef={this.sensorTemperatureErrorTime}
                                                     disabled={this.state.displayRulesDisable}
                                                   />
                                                 </Col>
                                                 <Col md="2">
                                                   <label htmlFor="temperatureErrorTimeUnit"><FormattedMessage id="contract.errortimeunit" /></label>
                                                   <FormSelect
                                                       id="temperatureErrorTimeUnit"
                                                       innerRef={this.sensorTemperatureErrorTimeUnit}
                                                       placeholder="Select an Option"
                                                       disabled={this.state.displayRulesDisable}
                                                     >
                                                     <option value="Second">Second</option>
                                                     <option value="Minute">Minute</option>
                                                     <option value="Hour">Hour</option>
                                                   </FormSelect>
                                                 </Col>
                                                 <Col md="2">
                                                   <label htmlFor="temperatureErrorAction"><FormattedMessage id="contract.erroraction" /></label>
                                                   <FormSelect
                                                       id="temperatureErrorAction"
                                                       innerRef={this.sensorTemperatureErrorAction}
                                                       placeholder="Select an Option"
                                                       disabled={this.state.displayRulesDisable}
                                                     >
                                                     <option value="SMS">SMS</option>
                                                     <option value="eMail">eMail</option>
                                                     <option value="Alert">Alert</option>
                                                   </FormSelect>
                                                 </Col>
                                                 <Col md="1">&nbsp;</Col>
                                               </Row>
                                             </Form>
                                           </Col>
                                         </Row>
                                         <Row>&nbsp;</Row>
                                       </Card>
                                      : null }

                                      {this.state.c02SensorContract
                                        ?
                                        <Card small className="mb-12 pt-3">
                                          <h6 style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{humidity2X}</h6>
                                          <Row>
                                            <Col>
                                              <Form style={{fontSize:12}}>
                                                <Row form>
                                                  <Col md="1">&nbsp;</Col>
                                                  <Col md="2">
                                                    <br></br>
                                                    <FormCheckbox
                                                        inline
                                                        checked={this.state.sensorHumidityActiveRule}
                                                        onChange={e=>this.handleChange(e, "sensorHumidityActiveRule")}
                                                        disabled={this.state.displayRulesDisable}
                                                    >
                                                        <FormattedMessage id="contract.activerule" />
                                                    </FormCheckbox>
                                                  </Col>
                                                </Row>
                                                <br></br>
                                                <Row form>
                                                  <Col md="1">&nbsp;</Col>
                                                  <Col md="2">
                                                    <label htmlFor="humidityLowerThreshold"><FormattedMessage id="contract.lowerthreshold" /></label>
                                                    <FormInput
                                                      id="humidityLowerThreshold"
                                                      type="number"
                                                      innerRef={this.sensorHumidityLowerThreshold}
                                                      disabled={this.state.displayRulesDisable}
                                                      onChange={e=>this.handleChangeNumber(e, "humidityLowerThreshold")}
                                                      onBlur={e=>this.handleBlurNumber(e, "humidityLowerThreshold")}
                                                      onInput={e=>this.handleInputNumber(e, "humidityLowerThreshold")}
                                                    />
                                                  </Col>
                                                  <Col md="3">
                                                    <label htmlFor="humidityUpperThreshold"><FormattedMessage id="contract.upperthreshold" /></label>
                                                    <FormInput
                                                      id="humidityUpperThreshold"
                                                      type="number"
                                                      innerRef={this.sensorHumidityUpperThreshold}
                                                      disabled={this.state.displayRulesDisable}
                                                      onChange={e=>this.handleChangeNumber(e, "humidityUpperThreshold")}
                                                      onBlur={e=>this.handleBlurNumber(e, "humidityUpperThreshold")}
                                                      onInput={e=>this.handleInputNumber(e, "humidityUpperThreshold")}
                                                    />
                                                  </Col>
                                                  <Col md="3">
                                                    <label htmlFor="humidityUnitMeasurement"><FormattedMessage id="contract.unitmeasurement" /></label>
                                                    <FormSelect
                                                        id="humidityUnitMeasurement"
                                                        innerRef={this.sensorHumidityUnitMeasurement}
                                                        placeholder="Select an Option"
                                                        disabled={this.state.displayRulesDisable}
                                                      >
                                                      <option value="%">%</option>
                                                    </FormSelect>
                                                  </Col>
                                                  <Col md="1">&nbsp;</Col>
                                                </Row>
                                               <br></br>
                                                <Row form>
                                                  <Col md="1">&nbsp;</Col>
                                                  <Col md="2">
                                                    <label htmlFor="humidityFrecuency"><FormattedMessage id="contract.frecuency" /></label>
                                                    <FormInput
                                                      id="humidityFrecuency"
                                                      innerRef={this.sensorHumidityFrecuency}
                                                      disabled={this.state.displayRulesDisable}
                                                    />
                                                  </Col>
                                                  <Col md="2">
                                                    <label htmlFor="humidityUnitFrecuency"><FormattedMessage id="contract.unitfrecuency" /></label>
                                                    <FormSelect
                                                        id="humidityUnitFrecuency"
                                                        innerRef={this.sensorHumidityUnitFrecuency}
                                                        placeholder="Select an Option"
                                                        disabled={this.state.displayRulesDisable}
                                                      >
                                                      <option value="Second">Second</option>
                                                      <option value="Minute">Minute</option>
                                                      <option value="Hour">Hour</option>
                                                    </FormSelect>
                                                  </Col>
                                                  <Col md="2">
                                                    <label htmlFor="humidityErrorTime"><FormattedMessage id="contract.errortime" /></label>
                                                    <FormInput
                                                      id="humidityErrorTime"
                                                      innerRef={this.sensorHumidityErrorTime}
                                                      disabled={this.state.displayRulesDisable}
                                                    />
                                                  </Col>
                                                  <Col md="2">
                                                    <label htmlFor="humidityErrorTimeUnit"><FormattedMessage id="contract.errortimeunit" /></label>
                                                    <FormSelect
                                                        id="humidityErrorTimeUnit"
                                                        innerRef={this.sensorHumidityErrorTimeUnit}
                                                        placeholder="Select an Option"
                                                        disabled={this.state.displayRulesDisable}
                                                      >
                                                      <option value="Second">Second</option>
                                                      <option value="Minute">Minute</option>
                                                      <option value="Hour">Hour</option>
                                                    </FormSelect>
                                                  </Col>
                                                  <Col md="2">
                                                    <label htmlFor="humidityErrorAction"><FormattedMessage id="contract.erroraction" /></label>
                                                    <FormSelect
                                                        id="humidityErrorAction"
                                                        innerRef={this.sensorHumidityErrorAction}
                                                        placeholder="Select an Option"
                                                        disabled={this.state.displayRulesDisable}
                                                      >
                                                      <option value="SMS">SMS</option>
                                                      <option value="eMail">eMail</option>
                                                      <option value="Alert">Alert</option>
                                                    </FormSelect>
                                                  </Col>
                                                  <Col md="1">&nbsp;</Col>
                                                </Row>
                                              </Form>
                                            </Col>
                                          </Row>
                                          <Row>&nbsp;</Row>
                                        </Card>
                                      : null }

                                      {this.state.c03SensorContract
                                         ?
                                         <Card small className="mb-12 pt-3">
                                           <h6 style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{gascontrol2X}</h6>
                                           <Row>
                                             <Col>
                                               <Form style={{fontSize:12}}>
                                                 <Row form>
                                                   <Col md="1">&nbsp;</Col>
                                                   <Col md="2">
                                                     <br></br>
                                                     <FormCheckbox
                                                         inline
                                                         checked={this.state.sensorGasControlActiveRule}
                                                         onChange={e=>this.handleChange(e, "sensorGasControlActiveRule")}
                                                         disabled={this.state.displayRulesDisable}
                                                     >
                                                         <FormattedMessage id="contract.activerule" />
                                                     </FormCheckbox>
                                                   </Col>
                                                 </Row>
                                                 <br></br>
                                                 <Row form>
                                                   <Col md="1">&nbsp;</Col>
                                                   <Col md="2">
                                                     <label htmlFor="gascontrolLowerThreshold"><FormattedMessage id="contract.lowerthreshold" /></label>
                                                     <FormInput
                                                       id="gascontrolLowerThreshold"
                                                       type="number"
                                                       innerRef={this.sensorGasControlLowerThreshold}
                                                       disabled={this.state.displayRulesDisable}
                                                       onChange={e=>this.handleChangeNumber(e, "gascontrolLowerThreshold")}
                                                       onBlur={e=>this.handleBlurNumber(e, "gascontrolLowerThreshold")}
                                                       onInput={e=>this.handleInputNumber(e, "gascontrolLowerThreshold")}
                                                     />
                                                   </Col>
                                                   <Col md="3">
                                                     <label htmlFor="gascontrolUpperThreshold"><FormattedMessage id="contract.upperthreshold" /></label>
                                                     <FormInput
                                                       id="gascontrolUpperThreshold"
                                                       type="number"
                                                       innerRef={this.sensorGasControlUpperThreshold}
                                                       disabled={this.state.displayRulesDisable}
                                                       onChange={e=>this.handleChangeNumber(e, "gascontrolUpperThreshold")}
                                                       onBlur={e=>this.handleBlurNumber(e, "gascontrolUpperThreshold")}
                                                       onInput={e=>this.handleInputNumber(e, "gascontrolUpperThreshold")}
                                                     />
                                                   </Col>
                                                   <Col md="3">
                                                     <label htmlFor="gascontrolUnitMeasurement"><FormattedMessage id="contract.unitmeasurement" /></label>
                                                     <FormSelect
                                                         id="gascontrolUnitMeasurement"
                                                         innerRef={this.sensorGasControlUnitMeasurement}
                                                         placeholder="Select an Option"
                                                         disabled={this.state.displayRulesDisable}
                                                       >
                                                       <option value="%">%</option>
                                                     </FormSelect>
                                                   </Col>
                                                   <Col md="1">&nbsp;</Col>
                                                 </Row>
                                                 <br></br>
                                                 <Row form>
                                                   <Col md="1">&nbsp;</Col>
                                                   <Col md="2">
                                                     <label htmlFor="gascontrolFrecuency"><FormattedMessage id="contract.frecuency" /></label>
                                                     <FormInput
                                                       id="gascontrolFrecuency"
                                                       innerRef={this.sensorGasControlFrecuency}
                                                       disabled={this.state.displayRulesDisable}
                                                     />
                                                   </Col>
                                                   <Col md="2">
                                                     <label htmlFor="gascontrolUnitFrecuency"><FormattedMessage id="contract.unitfrecuency" /></label>
                                                     <FormSelect
                                                         id="gascontrolUnitFrecuency"
                                                         innerRef={this.sensorGasControlUnitFrecuency}
                                                         placeholder="Select an Option"
                                                         disabled={this.state.displayRulesDisable}
                                                       >
                                                       <option value="Second">Second</option>
                                                       <option value="Minute">Minute</option>
                                                       <option value="Hour">Hour</option>
                                                     </FormSelect>
                                                   </Col>
                                                   <Col md="2">
                                                     <label htmlFor="gascontrolErrorTime"><FormattedMessage id="contract.errortime" /></label>
                                                     <FormInput
                                                       id="gascontrolErrorTime"
                                                       innerRef={this.sensorGasControlErrorTime}
                                                       disabled={this.state.displayRulesDisable}
                                                     />
                                                   </Col>
                                                   <Col md="2">
                                                     <label htmlFor="gascontrolErrorTimeUnit"><FormattedMessage id="contract.errortimeunit" /></label>
                                                     <FormSelect
                                                         id="gascontrolErrorTimeUnit"
                                                         innerRef={this.sensorGasControlErrorTimeUnit}
                                                         placeholder="Select an Option"
                                                         disabled={this.state.displayRulesDisable}
                                                       >
                                                       <option value="Second">Second</option>
                                                       <option value="Minute">Minute</option>
                                                       <option value="Hour">Hour</option>
                                                     </FormSelect>
                                                   </Col>
                                                   <Col md="2">
                                                     <label htmlFor="gascontrolErrorAction"><FormattedMessage id="contract.erroraction" /></label>
                                                     <FormSelect
                                                         id="gascontrolErrorAction"
                                                         innerRef={this.sensorGasControlErrorAction}
                                                         placeholder="Select an Option"
                                                         disabled={this.state.displayRulesDisable}
                                                       >
                                                       <option value="SMS">SMS</option>
                                                       <option value="eMail">eMail</option>
                                                       <option value="Alert">Alert</option>
                                                     </FormSelect>
                                                   </Col>
                                                   <Col md="1">&nbsp;</Col>
                                                 </Row>
                                               </Form>
                                             </Col>
                                           </Row>
                                           <Row>&nbsp;</Row>
                                         </Card>
                                       : null }

                                       {this.state.c08SensorContract
                                          ?
                                          <Card small className="mb-12 pt-3">
                                            <h6 style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{gps2X}</h6>
                                            <Row>
                                              <Col>
                                                <Form style={{fontSize:12}}>
                                                  <Row form>
                                                    <Col md="1">&nbsp;</Col>
                                                    <Col md="2">
                                                      <br></br>
                                                      <FormCheckbox
                                                          inline
                                                          checked={this.state.sensorGPSActiveRule}
                                                          onChange={e=>this.handleChange(e, "sensorGPSActiveRule")}
                                                          disabled={this.state.displayRulesDisable}
                                                      >
                                                          <FormattedMessage id="contract.activerule" />
                                                      </FormCheckbox>
                                                    </Col>
                                                  </Row>
                                                  <br></br>
                                                  <Row form>
                                                    <Col md="1">&nbsp;</Col>
                                                    <Col md="3">
                                                      <label htmlFor="GPSUnitMeasurement"><FormattedMessage id="contract.unitmeasurement" /></label>
                                                      <FormSelect
                                                          id="GPSUnitMeasurement"
                                                          innerRef={this.sensorGPSUnitMeasurement}
                                                          placeholder="Select an Option"
                                                          disabled={this.state.displayRulesDisable}
                                                        >
                                                        <option value="Degrees">Degrees</option>
                                                        <option value="Decimal">Decimal</option>
                                                        <option value="Coordinate">Coordinate</option>
                                                      </FormSelect>
                                                    </Col>
                                                    <Col md="1">&nbsp;</Col>
                                                  </Row>
                                                  <br></br>
                                                  <Row form>
                                                    <Col md="1">&nbsp;</Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSFrecuency"><FormattedMessage id="contract.frecuency" /></label>
                                                      <FormInput
                                                        id="GPSFrecuency"
                                                        innerRef={this.sensorGPSFrecuency}
                                                        disabled={this.state.displayRulesDisable}
                                                      />
                                                    </Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSUnitFrecuency"><FormattedMessage id="contract.unitfrecuency" /></label>
                                                      <FormSelect
                                                          id="GPSUnitFrecuency"
                                                          innerRef={this.sensorGPSUnitFrecuency}
                                                          placeholder="Select an Option"
                                                          disabled={this.state.displayRulesDisable}
                                                        >
                                                        <option value="Second">Second</option>
                                                        <option value="Minute">Minute</option>
                                                        <option value="Hour">Hour</option>
                                                      </FormSelect>
                                                    </Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSErrorTime"><FormattedMessage id="contract.errortime" /></label>
                                                      <FormInput
                                                        id="GPSErrorTime"
                                                        innerRef={this.sensorGPSErrorTime}
                                                        disabled={this.state.displayRulesDisable}
                                                      />
                                                    </Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSErrorTimeUnit"><FormattedMessage id="contract.errortimeunit" /></label>
                                                      <FormSelect
                                                          id="GPSErrorTimeUnit"
                                                          innerRef={this.sensorGPSErrorTimeUnit}
                                                          placeholder="Select an Option"
                                                          disabled={this.state.displayRulesDisable}
                                                        >
                                                        <option value="Second">Second</option>
                                                        <option value="Minute">Minute</option>
                                                        <option value="Hour">Hour</option>
                                                      </FormSelect>
                                                    </Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSErrorAction"><FormattedMessage id="contract.erroraction" /></label>
                                                      <FormSelect
                                                          id="GPSErrorAction"
                                                          innerRef={this.sensorGPSErrorAction}
                                                          placeholder="Select an Option"
                                                          disabled={this.state.displayRulesDisable}
                                                        >
                                                        <option value="SMS">SMS</option>
                                                        <option value="eMail">eMail</option>
                                                        <option value="Alert">Alert</option>
                                                      </FormSelect>
                                                    </Col>
                                                    <Col md="1">&nbsp;</Col>
                                                  </Row>
                                                </Form>
                                              </Col>
                                            </Row>
                                            <Row>&nbsp;</Row>
                                          </Card>
                                       : null }
                                     </React.Fragment>
                                : null }
                                { templateNameMaintenance
                                     ?
                                      <React.Fragment>
                                      {this.state.c09SensorContract
                                      ?
                                       <Card small className="mb-12 pt-3">
                                         <h6 style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fire2X}</h6>
                                         <Row>
                                           <Col>
                                             <Form style={{fontSize:12}}>
                                               <Row form>
                                                 <Col md="1">&nbsp;</Col>
                                                 <Col md="2">
                                                   <br></br>
                                                   <FormCheckbox
                                                       inline
                                                       checked={this.state.sensorFireActiveRule}
                                                       onChange={e=>this.handleChange(e, "sensorFireActiveRule")}
                                                       disabled={this.state.displayRulesDisable}
                                                   >
                                                       <FormattedMessage id="contract.activerule" />
                                                   </FormCheckbox>
                                                 </Col>
                                                 <Col md="1">&nbsp;</Col>
                                               </Row>
                                               <br></br>
                                               <Row form>
                                                 <Col md="1">&nbsp;</Col>
                                                 <Col md="2">
                                                   <label htmlFor="FireErrorAction"><FormattedMessage id="contract.erroraction" /></label>
                                                   <FormSelect
                                                       id="FireErrorAction"
                                                       innerRef={this.sensorFireErrorAction}
                                                       placeholder="Select an Option"
                                                       disabled={this.state.displayRulesDisable}
                                                     >
                                                     <option value="SMS">SMS</option>
                                                     <option value="eMail">eMail</option>
                                                     <option value="Alert">Alert</option>
                                                   </FormSelect>
                                                 </Col>
                                                 <Col md="1">&nbsp;</Col>
                                               </Row>
                                             </Form>
                                           </Col>
                                         </Row>
                                         <Row>&nbsp;</Row>
                                       </Card>
                                      : null }

                                      {this.state.c10SensorContract
                                        ?
                                        <Card small className="mb-12 pt-3">
                                          <h6 style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{flood2X}</h6>
                                          <Row>
                                            <Col>
                                              <Form style={{fontSize:12}}>
                                                <Row form>
                                                  <Col md="1">&nbsp;</Col>
                                                  <Col md="2">
                                                    <br></br>
                                                    <FormCheckbox
                                                        inline
                                                        checked={this.state.sensorFloodActiveRule}
                                                        onChange={e=>this.handleChange(e, "sensorFloodActiveRule")}
                                                        disabled={this.state.displayRulesDisable}
                                                    >
                                                        <FormattedMessage id="contract.activerule" />
                                                    </FormCheckbox>
                                                  </Col>
                                                  <Col md="1">&nbsp;</Col>
                                                </Row>
                                                <br></br>
                                                <Row form>
                                                  <Col md="1">&nbsp;</Col>
                                                  <Col md="2">
                                                    <label htmlFor="FloodErrorAction"><FormattedMessage id="contract.erroraction" /></label>
                                                    <FormSelect
                                                        id="FloodErrorAction"
                                                        innerRef={this.sensorFloodErrorAction}
                                                        placeholder="Select an Option"
                                                        disabled={this.state.displayRulesDisable}
                                                      >
                                                      <option value="SMS">SMS</option>
                                                      <option value="eMail">eMail</option>
                                                      <option value="Alert">Alert</option>
                                                    </FormSelect>
                                                  </Col>
                                                  <Col md="1">&nbsp;</Col>
                                                </Row>
                                              </Form>
                                            </Col>
                                          </Row>
                                          <Row>&nbsp;</Row>
                                        </Card>
                                      : null }

                                      {this.state.c11SensorContract
                                         ?
                                         <Card small className="mb-12 pt-3">
                                           <h6 style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{motion2X}</h6>
                                           <Row>
                                             <Col>
                                               <Form style={{fontSize:12}}>
                                                 <Row form>
                                                   <Col md="1">&nbsp;</Col>
                                                   <Col md="2">
                                                     <br></br>
                                                     <FormCheckbox
                                                         inline
                                                         checked={this.state.sensorMotionActiveRule}
                                                         onChange={e=>this.handleChange(e, "sensorMotionActiveRule")}
                                                         disabled={this.state.displayRulesDisable}
                                                     >
                                                         <FormattedMessage id="contract.activerule" />
                                                     </FormCheckbox>
                                                   </Col>
                                                   <Col md="1">&nbsp;</Col>
                                                 </Row>
                                                 <br></br>
                                                 <Row form>
                                                   <Col md="1">&nbsp;</Col>
                                                   <Col md="2">
                                                     <label htmlFor="MotionErrorAction"><FormattedMessage id="contract.erroraction" /></label>
                                                     <FormSelect
                                                         id="MotionErrorAction"
                                                         innerRef={this.sensorMotionErrorAction}
                                                         placeholder="Select an Option"
                                                         disabled={this.state.displayRulesDisable}
                                                       >
                                                       <option value="SMS">SMS</option>
                                                       <option value="eMail">eMail</option>
                                                       <option value="Alert">Alert</option>
                                                     </FormSelect>
                                                   </Col>
                                                   <Col md="1">&nbsp;</Col>
                                                 </Row>
                                               </Form>
                                             </Col>
                                           </Row>
                                           <Row>&nbsp;</Row>
                                         </Card>
                                      : null }

                                      {this.state.c12SensorContract
                                         ?
                                         <Card small className="mb-12 pt-3">
                                           <h6 style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{sealintegrity2X}</h6>
                                           <Row>
                                             <Col>
                                               <Form style={{fontSize:12}}>
                                                 <Row form>
                                                   <Col md="1">&nbsp;</Col>
                                                   <Col md="2">
                                                     <br></br>
                                                     <FormCheckbox
                                                         inline
                                                         checked={this.state.sensorSealIntegrityActiveRule}
                                                         onChange={e=>this.handleChange(e, "sensorSealIntegrityActiveRule")}
                                                         disabled={this.state.displayRulesDisable}
                                                     >
                                                         <FormattedMessage id="contract.activerule" />
                                                     </FormCheckbox>
                                                   </Col>
                                                   <Col md="1">&nbsp;</Col>
                                                 </Row>
                                                 <br></br>
                                                 <Row form>
                                                   <Col md="1">&nbsp;</Col>
                                                   <Col md="2">
                                                     <label htmlFor="SealIntegrityErrorAction"><FormattedMessage id="contract.erroraction" /></label>
                                                     <FormSelect
                                                         id="SealIntegrityErrorAction"
                                                         innerRef={this.sensorSealIntegrityErrorAction}
                                                         placeholder="Select an Option"
                                                         disabled={this.state.displayRulesDisable}
                                                       >
                                                       <option value="SMS">SMS</option>
                                                       <option value="eMail">eMail</option>
                                                       <option value="Alert">Alert</option>
                                                     </FormSelect>
                                                   </Col>
                                                   <Col md="1">&nbsp;</Col>
                                                 </Row>
                                               </Form>
                                             </Col>
                                           </Row>
                                           <Row>&nbsp;</Row>
                                         </Card>
                                       : null }

                                       {this.state.c13SensorContract
                                          ?
                                          <Card small className="mb-12 pt-3">
                                            <h6 style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{smoke2X}</h6>
                                            <Row>
                                              <Col>
                                                <Form style={{fontSize:12}}>
                                                  <Row form>
                                                    <Col md="1">&nbsp;</Col>
                                                    <Col md="2">
                                                      <br></br>
                                                      <FormCheckbox
                                                          inline
                                                          checked={this.state.sensorSmokeActiveRule}
                                                          onChange={e=>this.handleChange(e, "sensorSmokeActiveRule")}
                                                          disabled={this.state.displayRulesDisable}
                                                      >
                                                          <FormattedMessage id="contract.activerule" />
                                                      </FormCheckbox>
                                                    </Col>
                                                    <Col md="1">&nbsp;</Col>
                                                  </Row>
                                                  <br></br>
                                                  <Row form>
                                                    <Col md="1">&nbsp;</Col>
                                                    <Col md="2">
                                                      <label htmlFor="SmokeErrorAction"><FormattedMessage id="contract.erroraction" /></label>
                                                      <FormSelect
                                                          id="SmokeErrorAction"
                                                          innerRef={this.sensorSmokeErrorAction}
                                                          placeholder="Select an Option"
                                                          disabled={this.state.displayRulesDisable}
                                                        >
                                                        <option value="SMS">SMS</option>
                                                        <option value="eMail">eMail</option>
                                                        <option value="Alert">Alert</option>
                                                      </FormSelect>
                                                    </Col>
                                                    <Col md="1">&nbsp;</Col>
                                                  </Row>
                                                </Form>
                                              </Col>
                                            </Row>
                                            <Row>&nbsp;</Row>
                                          </Card>
                                       : null }
                                      </React.Fragment>
                                   : null }
                                   { templateNameProforma
                                   ?
                                   <React.Fragment>
                                     {this.state.c08SensorContract
                                        ?
                                          <Card small className="mb-12 pt-3">
                                            <h6 style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{gps2X}</h6>
                                            <Row>
                                              <Col>
                                                <Form style={{fontSize:12}}>
                                                  <Row form>
                                                    <Col md="1">&nbsp;</Col>
                                                    <Col md="2">
                                                      <br></br>
                                                      <FormCheckbox
                                                          inline
                                                          checked={this.state.sensorGPSActiveRule}
                                                          onChange={e=>this.handleChange(e, "sensorGPSActiveRule")}
                                                          disabled={false}
                                                      >
                                                          <FormattedMessage id="contract.activerule" />
                                                      </FormCheckbox>
                                                    </Col>
                                                  </Row>
                                                  <br></br>
                                                  <Row form>
                                                    <Col md="1">&nbsp;</Col>
                                                    <Col md="3">
                                                      <label htmlFor="GPSUnitMeasurement"><FormattedMessage id="contract.unitmeasurement" /></label>
                                                      <FormSelect
                                                          id="GPSUnitMeasurement"
                                                          innerRef={this.sensorGPSUnitMeasurement}
                                                          placeholder="Select an Option"
                                                          disabled={false}
                                                        >
                                                        <option value="Degrees">Degrees</option>
                                                        <option value="Decimal">Decimal</option>
                                                        <option value="Coordinate">Coordinate</option>
                                                      </FormSelect>
                                                    </Col>
                                                    <Col md="1">&nbsp;</Col>
                                                  </Row>
                                                  <br></br>
                                                  <Row form>
                                                    <Col md="1">&nbsp;</Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSFrecuency"><FormattedMessage id="contract.frecuency" /></label>
                                                      <FormInput
                                                        id="GPSFrecuency"
                                                        innerRef={this.sensorGPSFrecuency}
                                                        disabled={false}
                                                      />
                                                    </Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSUnitFrecuency"><FormattedMessage id="contract.unitfrecuency" /></label>
                                                      <FormSelect
                                                          id="GPSUnitFrecuency"
                                                          innerRef={this.sensorGPSUnitFrecuency}
                                                          placeholder="Select an Option"
                                                          disabled={false}
                                                        >
                                                        <option value="Second">Second</option>
                                                        <option value="Minute">Minute</option>
                                                        <option value="Hour">Hour</option>
                                                      </FormSelect>
                                                    </Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSErrorTime"><FormattedMessage id="contract.errortime" /></label>
                                                      <FormInput
                                                        id="GPSErrorTime"
                                                        innerRef={this.sensorGPSErrorTime}
                                                        disabled={false}
                                                      />
                                                    </Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSErrorTimeUnit"><FormattedMessage id="contract.errortimeunit" /></label>
                                                      <FormSelect
                                                          id="GPSErrorTimeUnit"
                                                          innerRef={this.sensorGPSErrorTimeUnit}
                                                          placeholder="Select an Option"
                                                          disabled={false}
                                                        >
                                                        <option value="Second">Second</option>
                                                        <option value="Minute">Minute</option>
                                                        <option value="Hour">Hour</option>
                                                      </FormSelect>
                                                    </Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSErrorAction"><FormattedMessage id="contract.erroraction" /></label>
                                                      <FormSelect
                                                          id="GPSErrorAction"
                                                          innerRef={this.sensorGPSErrorAction}
                                                          placeholder="Select an Option"
                                                          disabled={false}
                                                        >
                                                        <option value="SMS">SMS</option>
                                                        <option value="eMail">eMail</option>
                                                        <option value="Alert">Alert</option>
                                                      </FormSelect>
                                                    </Col>
                                                    <Col md="1">&nbsp;</Col>
                                                  </Row>
                                                </Form>
                                              </Col>
                                            </Row>
                                            <Row>&nbsp;</Row>
                                          </Card>
                                        : null }
                                     </React.Fragment>
                                   : null }
                                   { templateNameBlank
                                   ?
                                   <React.Fragment>
                                     {this.state.c08SensorContract
                                        ?
                                          <Card small className="mb-12 pt-3">
                                            <h6 style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{gps2X}</h6>
                                            <Row>
                                              <Col>
                                                <Form style={{fontSize:12}}>
                                                  <Row form>
                                                    <Col md="1">&nbsp;</Col>
                                                    <Col md="2">
                                                      <br></br>
                                                      <FormCheckbox
                                                          inline
                                                          checked={this.state.sensorGPSActiveRule}
                                                          onChange={e=>this.handleChange(e, "sensorGPSActiveRule")}
                                                          disabled={false}
                                                      >
                                                          <FormattedMessage id="contract.activerule" />
                                                      </FormCheckbox>
                                                    </Col>
                                                  </Row>
                                                  <br></br>
                                                  <Row form>
                                                    <Col md="1">&nbsp;</Col>
                                                    <Col md="3">
                                                      <label htmlFor="GPSUnitMeasurement"><FormattedMessage id="contract.unitmeasurement" /></label>
                                                      <FormSelect
                                                          id="GPSUnitMeasurement"
                                                          innerRef={this.sensorGPSUnitMeasurement}
                                                          placeholder="Select an Option"
                                                          disabled={false}
                                                        >
                                                        <option value="Degrees">Degrees</option>
                                                        <option value="Decimal">Decimal</option>
                                                        <option value="Coordinate">Coordinate</option>
                                                      </FormSelect>
                                                    </Col>
                                                    <Col md="1">&nbsp;</Col>
                                                  </Row>
                                                  <br></br>
                                                  <Row form>
                                                    <Col md="1">&nbsp;</Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSFrecuency"><FormattedMessage id="contract.frecuency" /></label>
                                                      <FormInput
                                                        id="GPSFrecuency"
                                                        innerRef={this.sensorGPSFrecuency}
                                                        disabled={false}
                                                      />
                                                    </Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSUnitFrecuency"><FormattedMessage id="contract.unitfrecuency" /></label>
                                                      <FormSelect
                                                          id="GPSUnitFrecuency"
                                                          innerRef={this.sensorGPSUnitFrecuency}
                                                          placeholder="Select an Option"
                                                          disabled={false}
                                                        >
                                                        <option value="Second">Second</option>
                                                        <option value="Minute">Minute</option>
                                                        <option value="Hour">Hour</option>
                                                      </FormSelect>
                                                    </Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSErrorTime"><FormattedMessage id="contract.errortime" /></label>
                                                      <FormInput
                                                        id="GPSErrorTime"
                                                        innerRef={this.sensorGPSErrorTime}
                                                        disabled={false}
                                                      />
                                                    </Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSErrorTimeUnit"><FormattedMessage id="contract.errortimeunit" /></label>
                                                      <FormSelect
                                                          id="GPSErrorTimeUnit"
                                                          innerRef={this.sensorGPSErrorTimeUnit}
                                                          placeholder="Select an Option"
                                                          disabled={false}
                                                        >
                                                        <option value="Second">Second</option>
                                                        <option value="Minute">Minute</option>
                                                        <option value="Hour">Hour</option>
                                                      </FormSelect>
                                                    </Col>
                                                    <Col md="2">
                                                      <label htmlFor="GPSErrorAction"><FormattedMessage id="contract.erroraction" /></label>
                                                      <FormSelect
                                                          id="GPSErrorAction"
                                                          innerRef={this.sensorGPSErrorAction}
                                                          placeholder="Select an Option"
                                                          disabled={false}
                                                        >
                                                        <option value="SMS">SMS</option>
                                                        <option value="eMail">eMail</option>
                                                        <option value="Alert">Alert</option>
                                                      </FormSelect>
                                                    </Col>
                                                    <Col md="1">&nbsp;</Col>
                                                  </Row>
                                                </Form>
                                              </Col>
                                            </Row>
                                            <Row>&nbsp;</Row>
                                          </Card>
                                        : null }
                                     </React.Fragment>
                                   : null }
                                   {!this.props.displayRulesDisable ?
                                      <Row>
                                         <Col md="1"></Col>
                                         {displaySaveRulesX ?
                                             <Col md="11">
                                                 {todosSignX ?
                                                   <Button pill theme="success" onClick={this.saveDraftConfirm}><FormattedMessage id="button.saverules" /></Button>
                                                 : null }
                                                 {ningunoSignX ?
                                                   <Button pill theme="success" onClick={e=>this.clickSaveEnrollment(e,indiceRules)}><FormattedMessage id="button.saverules" /></Button>
                                                 : null }
                                                 {algunosSignX ?
                                                   <Button pill theme="success" onClick={this.saveDraftRulesConfirm}><FormattedMessage id="button.saverules" /></Button>
                                                 : null }
                                                 &nbsp;
                                                 <Button pill theme="secondary" onClick={e=>this.closeRulesEnrollment(e)}><FormattedMessage id="button.closerules" /></Button>
                                              </Col>
                                         :
                                              <Col md="11">
                                                 <Button pill theme="secondary" onClick={e=>this.closeRulesEnrollment(e)}><FormattedMessage id="button.closerules" /></Button>
                                              </Col>
                                         }
                                      </Row>
                                   : null }
                              </ListGroupItem>
                            </ListGroup>
                          </Card>
                     </React.Fragment>
                  </Row>
              </Container>
            </div>
        );
     }
}

export default NewContractRules;
