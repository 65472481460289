import React from "react";
import { withRouter } from "react-router-dom";
import { Card } from "shards-react";

import ContractDrawerFinancesSend from "../contracts/ContractDrawerFinancesSend";
import ContractDrawerFinancesReceive from "../contracts/ContractDrawerFinancesReceive";

class MyFinancesRight  extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      receive: true,
      colorDark: false,
    }
  }

  UNSAFE_componentWillMount() {
    let colorThemeX = 'Blue'
    if (localStorage["colorTheme"]) {
      colorThemeX = localStorage.getItem('colorTheme')
      if (colorThemeX === 'Dark') {
         this.setState({colorDark: true})
      }
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <Card small className="mb-4" style={ this.state.colorDark ? { backgroundColor:'#0b151d'} : {}}>
        <ContractDrawerFinancesSend colorDark={this.state.colorDark} />
        {this.state.receive ?
          <ContractDrawerFinancesReceive colorDark={this.state.colorDark} />
        : null }
      </Card>
    )
  }
};

export default withRouter(MyFinancesRight);
