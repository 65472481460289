//React
import React, { Component } from 'react';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Container, Button, Card, FormCheckbox, FormSelect, FormInput } from "shards-react";
import { Table } from 'reactstrap';

import TituloPagina2 from "../common/TituloPagina2";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//translate
import { FormattedMessage } from 'react-intl';

import {username_new} from '../../actions/userActions'
import {putFileDB} from '../../actions/mongoDBActions'


class ContractTag extends Component {

    constructor(props) {
        super(props);

        this.clickRemove = this.clickRemove.bind(this);
        this.crearContractTag = this.crearContractTag.bind(this);
        this.exitProgram = this.exitProgram.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          deleteX: false,
          colourX: '',
          colourLetterX: '',
          language: 'English',
          c02Tag: '',
          c02Tag2: '',
        }
    }

    c01Tag = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
    }

    componentDidMount() {
        this.setState({ c02Tag: this.props.displayStringTagX })
        this.setState({ c02Tag2: this.props.displayStringTagX })
        this.setState({ colourX: this.props.displayColourTagX })
        this.setState({ colourLetterX: this.props.displayColourLetterTagX })
        if (this.props.displayStringTagX!== '') {
          this.setState({ deleteX: true })
        }
    }

    componentUnMount() {
      this.setState({ c02Tag: '' })
      this.setState({ c02Tag2: '' })
      this.setState({ colourX: '' })
      this.setState({ colourLetterX: '' })
    }


    clickRemove = (e,optx) => {
        this.setState({ c02Tag: '' })
        this.setState({ deleteX: false })
        this.setState({ colourX: '' })
        this.setState({ colourLetterX: '' })
        this.updateTagContract(this.props.typeContract,this.props.numberContract,this.props.userOrigin,'','','')
    }

    crearContractTag = (e) => {
      this.setState({ deleteX: true })
      const palabra = this.c01Tag.current.value
      const ArrayIdValue = palabra.split('#$%')
      this.setState({ c02Tag2: ArrayIdValue[0] })
      this.setState({ c02Tag: this.props.displayStringTagX })
      this.setState({ colourX: ArrayIdValue[1] })
      this.setState({ colourLetterX: ArrayIdValue[2] })
      this.updateTagContract(this.props.typeContract,this.props.numberContract,this.props.userOrigin,ArrayIdValue[0],ArrayIdValue[1],ArrayIdValue[2])
    }

    updateTagContract = (typeContract,numberContract,userOrigin,tagContract,colourTagContract,colourLetterTagContract) =>{
      let jsonContractTag = this.props.jsonContractTag
      let existContractTag = false
      let largo = jsonContractTag.length
      let jsonTagFile = []
      if (jsonContractTag.length > 0){
        jsonContractTag.map((todotag, t) => {
          if (todotag.typeContract === typeContract && todotag.configurationContractNumber === numberContract && todotag.origin === userOrigin) {
            existContractTag = true
            todotag.tag = tagContract
            todotag.hex = colourTagContract
            todotag.colorletter = colourLetterTagContract
            todotag.origin = userOrigin
          }
          if (t === (largo - 1)){
             this.updateTagContract2(typeContract,numberContract,userOrigin,tagContract,colourTagContract,existContractTag,jsonContractTag,jsonTagFile,colourLetterTagContract)
          }
        })
      }else{
        this.updateTagContract2(typeContract,numberContract,userOrigin,tagContract,colourTagContract,existContractTag,jsonContractTag,jsonTagFile,colourLetterTagContract)
      }

    }

    updateTagContract2 = (typeContract,numberContract,userOrigin,tagContract,colourTagContract,existContractTag,jsonContractTag,jsonTagFile,colourLetterTagContract) =>{
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
      if (existContractTag === false){
        if (jsonContractTag.length > 0){
          let jsonBlockstack3 = JSON.stringify(jsonContractTag)
          if (jsonBlockstack3.substring(0,1)==='"') {
             jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
          }
          if (jsonBlockstack3.substring(0,1)==='[') {
             jsonBlockstack3 = jsonBlockstack3.substring(1,jsonBlockstack3.length - 1);
          }
          jsonContractTag = jsonBlockstack3
          jsonTagFile =`${jsonContractTag},{"typeContract":"${typeContract}","configurationContractNumber":"${numberContract}","origin":"${userOrigin}","tag":"${tagContract}","hex":"${colourTagContract}","colorlettertag":"${colourLetterTagContract}"}`
          jsonTagFile =`[${jsonTagFile}]`
        }else{
          jsonTagFile =`[{"typeContract":"${typeContract}","configurationContractNumber":"${numberContract}","origin":"${userOrigin}","tag":"${tagContract}","hex":"${colourTagContract}","colorlettertag":"${colourLetterTagContract}"}]`
        }
        jsonTagFile = JSON.parse(jsonTagFile)
      }else{
        jsonTagFile = jsonContractTag
      }
      putFileDB(this.state.username, `contracttag.json`, 'Crosscheck_Tag', JSON.stringify(jsonTagFile), JSON.stringify(options))
          .then(() => {
          })
          .catch(error => {
          });
    }

    exitProgram(){
       this.props.returnProps();
    }

    render() {
        const {jsonBlockstackY} = this.state
        const {idx} = this.state
        const {typeContract} = this.props
        const {enrollment} = this.props
        const messageSubtittle = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`
        const messageSubtittle2 = `${this.props.typeContract}/${this.props.numberContract}`
        const {tagList} = this.props

        const displayFullChargeX = true
        let correlativo = 0
        let activate = false
        let titleX = ''
        let placeholderX1 = "Select an Option"
        if (this.state.language === 'English'){
          titleX = "Tags"
          placeholderX1 = "Select an Option"
        }
        if (this.state.language === 'French'){
          titleX = "Tags"
          placeholderX1 = "Sélectionner une Option"
        }
        if (this.state.language === 'Spanish'){
          titleX = "Tags"
          placeholderX1 = "Seleccione una Opción"
        }
        if (this.state.language === 'Portuguese'){
          titleX = "Tags"
          placeholderX1 = "Selecione uma Opção"
        }
        if (this.state.language === 'Swedish'){
          titleX = "Tags"
          placeholderX1 = "Välj ett Alternativ"
        }
        if (this.state.language === 'Netherlands'){
          titleX = "Tags"
          placeholderX1 = "Kies een Optie"
        }
        if (this.state.language === 'Russian'){
          titleX = "Tags"
          placeholderX1 = "Выберите опцию"
        }
        if (this.state.language === 'Japanese'){
          titleX = "Tags"
          placeholderX1 = "オプションを選択"
        }
        if (this.state.language === 'Chinese'){
          titleX = "Tags"
          placeholderX1 = "选择一个选项"
        }
        if (this.state.language === 'German'){
          titleX = "Tags"
          placeholderX1 = "Wähle eine Option"
        }
        if (this.state.language === 'Italian'){
          titleX = "Tags"
          placeholderX1 = "Seleziona un'opzione"
        }

        return (
          <div>
            <Container fluid className="main-content-container px-4" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                <Row noGutters className="page-header py-4">
                  <TituloPagina2
                    sm="8"
                    title={titleX}
                    subtitle={messageSubtittle}
                    className="text-sm-left"
                  />
                </Row>
                <Row>
                    <React.Fragment>
                      <Col md="9">
                        <Card small className="mb-12 pt-3" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                          <Col md="1"></Col>
                          <Col>
                            <Row>
                              <Col>
                                <Form style={{fontSize:12}}>
                                  <Row form>
                                    <Col md="4">
                                      <label>&nbsp;</label>
                                      <FormSelect
                                          id="Tag"
                                          style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}
                                          innerRef={this.c01Tag}
                                          placeholder="Select an Option"
                                          onChange={e=>this.crearContractTag(e)}
                                      >
                                          <option value="SelectOptionTag">{placeholderX1}</option>
                                          {tagList.map((todo, i) => {
                                            if (this.props.displayStringTagX !== ''){
                                              if (this.props.displayStringTagX === todo.code) {
                                                  return (
                                                    <option key={i} value={`${todo.code}#$%${todo.hex}#$%${todo.colorletter}`} selected>{todo.code}</option>)
                                              }else {
                                                  return (
                                                    <option key={i} value={`${todo.code}#$%${todo.hex}#$%${todo.colorletter}`}>{todo.code}</option>)
                                              }
                                            }else{
                                              return (
                                              <option key={i} value={`${todo.code}#$%${todo.hex}#$%${todo.colorletter}`}>{todo.code} </option>)
                                            }
                                          })}
                                      </FormSelect>
                                    </Col>

                                    <Col md="5">
                                     <label>&nbsp;</label>
                                     <Card small style={{ fontSize: 18, backgroundColor: this.state.colourX, color: this.state.colourLetterX }}>&nbsp;&nbsp;{this.state.c02Tag2}</Card>
                                    </Col>
                                    <Col md="1">
                                       {this.state.deleteX ?
                                         <React.Fragment>
                                           <label>&nbsp;</label>
                                           <br></br>
                                           <Button pill theme="danger" onClick={e=>this.clickRemove(e)}><FontAwesomeIcon icon={faTimes} /></Button>
                                         </React.Fragment>
                                       : null }
                                    </Col>
                                  </Row>
                                </Form>
                              </Col>
                            </Row>
                            <Row form>&nbsp;</Row>
                            <Row form>
                               <Button pill theme="secondary" onClick={this.exitProgram}><FormattedMessage id="button.contractlist" /></Button>
                            </Row>
                            <br></br>
                          </Col>
                        </Card>
                      </Col>
                    </React.Fragment>
                </Row>
                <br></br>
            </Container>
          </div>
        );

     }
}

export default ContractTag;
