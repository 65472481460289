import React from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";

import { Dispatcher, Constants } from "../../../flux";

class SidebarMainNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  handleToggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
  }

  render() {
    const { hideLogoText } = this.props;
    const { menuVisibleX } = this.props;
    return (
      <div className="main-navbar">
        <Navbar
          className= { this.props.colorDark ? "align-items-stretch flex-md-nowrap border-bottom p-0" : "align-items-stretch bg-white flex-md-nowrap border-bottom p-0" }
          style={ this.props.colorDark ? { backgroundColor:'#0e0b1f' } : {} }
          type= { this.props.colorDark ? null : "light" }
          >
          {menuVisibleX ?
              <React.Fragment>
                <NavbarBrand
                  className="w-100 mr-0"
                  href="/"
                  style={ this.props.colorDark ? { backgroundColor:'#0e0b1f', lineHeight: "25px" } : { backgroundColor: "#FFFFFF", lineHeight: "25px" }}
                  >
                  { this.props.colorDark ?
                    <div className="d-table m-auto">
                      <img
                        id="main-logo"
                        className="d-inline-block align-top mr-1"
                        style={{position:"absolute", bottom:0, left:0, marginLeft:12,  marginBottom:10, marginTop:2, width: "68%"}}
                        src={require("../../../images/imagotipo2.svg")}
                        alt="Paradigma Global Fintech"
                      />
                      {/* style={{position:"absolute", bottom:0, left:0, marginLeft:45,  marginBottom:10, width: "45%"}} */}
                      {/* src={require("../../../images/logo-paradigma-cross-horizontal-celeste.png")} */}
                      {!hideLogoText && (
                        <span className="d-none d-md-inline ml-1" style={{color:"#ffffff"}}></span>
                      )}
                    </div>
                  :
                    <div className="d-table m-auto">
                      <img
                        id="main-logo"
                        className="d-inline-block align-top mr-1"
                        style={{position:"absolute", bottom:0, left:0, marginLeft:12,  marginBottom:10, marginTop:2, width: "68%"}}
                        src={require("../../../images/imagotipo1.svg")}
                        alt="Paradigma Global Fintech"
                      />
                      {/* style={{position:"absolute", bottom:0, left:0, marginLeft:45,  marginBottom:10, width: "45%"}} */}
                      {/* src={require("../../../images/logo-paradigma-cross-horizontal-celeste.png")} */}
                      {!hideLogoText && (
                        <span className="d-none d-md-inline ml-1" style={{color:"#4B4E53"}}></span>
                      )}
                    </div>
                  }
                </NavbarBrand>
                {/* eslint-disable-next-line */}
                {/* className="toggle-sidebar d-sm-inline d-md-none d-lg-none" */}
                <a
                    className="toggle-sidebar d-sm-inline"
                    onClick={this.handleToggleSidebar}
                >
                    <i className="material-icons" style={this.props.colorDark ? {color:"white"} : {color:"grey"}}>view_week</i>
                </a>
              </React.Fragment>
          :
              <React.Fragment>
                  { this.props.colorDark ?
                    <div className="d-table m-auto">
                      <img
                        id="main-logo"
                        className="d-inline-block align-top mr-1"
                        style={{position:"absolute", bottom:0, left:0, marginLeft:12,  marginBottom:10, marginTop:5, width: "24%"}}
                        src={require("../../../images/isotipo2.svg")}
                        alt=""
                      />
                      {/* eslint-disable-next-line */}
                      {/* className="toggle-sidebar d-sm-inline d-md-none d-lg-none" */}
                    </div>
                  :
                    <div className="d-table m-auto">
                      <img
                        id="main-logo"
                        className="d-inline-block align-top mr-1"
                        style={{position:"absolute", bottom:0, left:0, marginLeft:12,  marginBottom:10, marginTop:5, width: "24%"}}
                        src={require("../../../images/isotipo1.svg")}
                        alt=""
                      />
                      {/* eslint-disable-next-line */}
                      {/* className="toggle-sidebar d-sm-inline d-md-none d-lg-none" */}
                    </div>
                  }
                  <a
                    className="toggle-sidebar d-sm-inline text-center"
                    onClick={this.handleToggleSidebar}
                    style={{position:"absolute", bottom:0, left:0, marginBottom:12, color:"#4B4E53", fontSize:"1.7rem"}}
                  >
                    <i className="material-icons" style={this.props.colorDark ? {color:"white"} : {color:"grey"}}>menu</i>
                  </a>
              </React.Fragment>
          }
        </Navbar>
      </div>
    );
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

export default SidebarMainNavbar;
