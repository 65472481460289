import React, { Component } from 'react';
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  Link,
} from '@react-pdf/renderer';

//translate
import { FormattedMessage } from 'react-intl';

//base64
import { Base64 } from 'js-base64';

//Clarity
import {getTransactionRegister} from "../../clarity/clarityfunctions"

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  tittle: {
    fontSize: 14,
    textAlign: 'center',
    fontFamily: 'Oswald',
    color: '#909497',
  },

  description: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 30,
    color: '#909497',
  },

  subtitle: {
    fontSize: 12,
    margin: 12,
    fontFamily: 'Oswald',
  },

  text: {
    margin: 10,
    fontSize: 9,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },

  text2: {
    margin: 10,
    fontSize: 8,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },

  text3: {
    margin: 10,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },

  text5: {
    margin: 3,
    fontSize: 9,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },

  text6: {
    margin: 3,
    fontSize: 8,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
  },

  text7: {
    margin: 10,
    fontSize: 9,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
  },

  link: {
    position: 'absolute',
    bottom: 50,
    left: 0,
    right: 0,
    margin: 10,
    fontSize: 9,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    color: 'blue',
  },

  sign: {
    margin: 10,
    fontSize: 8,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
    lineHeight : '0.05px',
  },

  image: {
    marginVertical: 10,
    marginHorizontal: 220,
  },

  imagesign: {
    minWidth:60,
    maxWidth:100,
    minHeight:30,
    maxHeight:50,
    width:150,
    height:50
  },

  imagedigital: {
    minWidth:100,
    maxWidth:100,
    minHeight:100,
    maxHeight:100,
    width:100,
    height:100
  },

  header: {
    fontSize: 14,
    marginBottom: 10,
    textAlign: 'center',
    color: '#ECF0F1',
  },

  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  table: {
      width: '100%',
      fontSize: 8,
      textAlign: 'center',
    },
    rowtable: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
    },
    headertable: {
      borderTop: 'none',
    },
    // So Declarative and unDRY 👌
    row1table: {
      width: '16%',
    },
    row2table: {
      width: '14%',
    },
    row3table: {
      width: '14%',
    },
    row4table: {
      width: '14%',
    },
    row5table: {
      width: '14%',
    },
    row6table: {
      width: '14%',
    },
    row7table: {
      width: '14%',
    },

    table2: {
        width: '100%',
        fontSize: 8,
        textAlign: 'center',
      },
      headertable2: {
        borderTop: 'none',
      },
      rowtable2: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: 'none',
        paddingTop: 1,
        paddingBottom: 1,
      },
      row10table: {
        width: '50%',
      },
      row11table: {
        width: '50%',
    },
});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const Subtitle = ({ children, ...props }) => (
  <Text style={styles.subtitle} {...props}>
    {children}
  </Text>
);

class CertificateReporteTurno extends Component {

    constructor(props) {
        super(props);

        this.state = {
          language: 'English',
          isocode: 'en',
          dateFormatX: 'dd/MM/yyyy',
        }
    }

    UNSAFE_componentWillMount() {
      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English'})
      }else{
        this.setState({ language: languageX })
      }
      if (languageX === 'English'){this.setState({ isocode: "en", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'French'){this.setState({ isocode:  "fr", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Spanish'){this.setState({ isocode:  "es", dateFormatX:"dd/MM/yyyy"})}
      if (languageX === 'Portuguese'){this.setState({ isocode:  "pt", dateFormatX:"dd/MM/yyyy"})}
      if (languageX === 'Swedish'){this.setState({ isocode:  "sv", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Netherlands'){this.setState({ isocode:  "nl", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Russian'){this.setState({ isocode:  "ru", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Japanese'){this.setState({ isocode:  "ja_JP", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Chinese'){this.setState({ isocode: "zh", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'German'){this.setState({ isocode:  "de", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Italian'){this.setState({ isocode:  "it", dateFormatX:"MM/dd/yyyy"})}

    }

    render() {

      const optionsDate = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric"
      };

      const fechaHoyX = new Date()
      const fechaReporteX = this.props.fecha
      const anoX = fechaReporteX.substring(6, 10)
      const mesX = fechaReporteX.substring(3, 5)
      const diaX = fechaReporteX.substring(0, 2)
      const fecha_texto = anoX+"-"+mesX+"-"+diaX+" 13:00:00"
      const ms = Date.parse(fecha_texto);
      const fechaX = new Date(ms);

      let parrafo1X = `Se certifica que el ${fechaX.toLocaleString(this.state.isocode,optionsDate)}, se da cumplimiento con lo establecido en el CONTRATO N° ${this.props.workordernumerocontrato}, TÍTULO DEL SERVICIO: ${this.props.workorderobservacion}, en las materias establecidas en el puntos 2.5. Informes Electrónicos, 2.5.1 Diariamente, en la generación de Informes de Operaciones e Informes de Gestión Preventiva.`
      let parrafo2X = ''
      let parrafo3X = 'El Informe de operación (REPORTE DE TURNO DIGITALIZADO) fue entregado al Administrador de Contrato de la COMPAÑÍA, con la información de los turnos día y noche inmediatamente anteriores y se evidencian su entrega y recepción con firmas electrónicas adjuntas por parte del representante del CONTRATISTA para estos efectos legales y el Administrador del CONTRATO de la COMPAÑÍA o quien él designe en su reemplazo.'
      let certificateTittleX = "CERTIFICADO"

      let imageFirmadoDigitalmenteX = "images/digitally_signed_english.png"
      if (this.state.language === 'Spanish'){
        imageFirmadoDigitalmenteX = "images/firmadodigitalmente.png"
      }

      let borradorX = true
      if (this.props.cReporteTurnoSignatureFirmante1 &&
          this.props.cReporteTurnoSignatureFirmante2 &&
          this.props.cReporteTurnoSignatureFirmante3 &&
          this.props.cReporteTurnoSignatureFirmante4 &&
          this.props.cReporteTurnoSignatureFirmante5 &&
          this.props.cReporteTurnoSignatureFirmante6 &&
          this.props.cReporteTurnoSignatureFirmante7){
          borradorX = false
      }

      return (
        <Document>
           <Page size="A4" style={styles.body} wrap>
             {borradorX ?
               <Text style={styles.header} fixed> ~ B O R R A D O R ~ </Text>
             :
               <View><Text>{" "}</Text></View>
             }
             <Text style={styles.tittle}>{certificateTittleX}</Text>
             <View><Text>{" "}</Text></View>

             <View style={styles.table}>
                 <View style={[styles.rowtable, styles.boldtable, styles.headertable]}>
                   <Text style={styles.row1table}>{'Cliente'}</Text>
                   <Text style={styles.row2table}>{'N° Contrato'}</Text>
                   <Text style={styles.row3table}>{'Campaña'}</Text>
                   <Text style={styles.row4table}>{'Sondaje'}</Text>
                   <Text style={styles.row5table}>{'Tipo Perforación'}</Text>
                   <Text style={styles.row6table}>{'Fase'}</Text>
                   <Text style={styles.row7table}>{'Inclinación'}</Text>
                 </View>

                 <View style={styles.rowtable} wrap={false}>
                   <Text style={styles.row1table}><Text style={styles.boldtable}>{this.props.workordercliente}</Text></Text>
                   <Text style={styles.row2table}>{this.props.workordernumerocontrato}</Text>
                   <Text style={styles.row3table}>{this.props.workordercampaña}</Text>
                   <Text style={styles.row4table}>{this.props.workordersondaje}</Text>
                   <Text style={styles.row5table}>{this.props.workordertipoperforacion}</Text>
                   <Text style={styles.row6table}>{this.props.workorderfase}</Text>
                   <Text style={styles.row7table}>{this.props.workorderdeclinacion}</Text>
                 </View>

                 <View><Text>{" "}</Text></View>

             </View>

             <Text style={styles.text}>{`${parrafo1X}`}</Text>
             <Text style={styles.text}>{`${parrafo3X}`}</Text>
             <Text style={styles.text}>{`${parrafo2X}`}</Text>

             <View style={styles.table2}>
               <View style={[styles.rowtable2, styles.boldtable, styles.headertable2]}>
                  <Text style={styles.row10table}>
                    {this.props.cReporteTurnoSignatureFirmante1 ?
                      <Image
                        style={styles.imagesign}
                        src={this.props.cReporteTurnotrimmedDataURLFirmante1}
                      />
                    :
                      <Image
                        style={styles.imagesign}
                        src="images/signGrey25p.png"
                      />
                    }
                  </Text>
                  <Text style={styles.row11table}>
                    {this.props.cReporteTurnoSignatureFirmante6 ?
                      <Image
                        style={styles.imagesign}
                        src={this.props.cReporteTurnotrimmedDataURLFirmante6}
                      />
                    :
                      <Image
                        style={styles.imagesign}
                        src="images/signGrey25p.png"
                      />
                    }
                  </Text>
               </View>
               <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`SUPERVISOR TURNO DÍA`}</Text>
                  <Text style={styles.row11table}>{`JEFE TURNO DÍA`}</Text>
               </View>
               <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`Firmado por: ${this.props.cReporteTurnoNameFirmante1}`}</Text>
                  <Text style={styles.row11table}>{`Firmado por: ${this.props.cReporteTurnoNameFirmante6}`}</Text>
               </View>
               <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`El día: ${this.props.cReporteTurnoDateTimeFirmante1}`}</Text>
                  <Text style={styles.row11table}>{`El día: ${this.props.cReporteTurnoDateTimeFirmante6}`}</Text>
               </View>
            </View>
            <View><Text>{" "}</Text></View>
            <View style={styles.table}>
               <View style={[styles.rowtable2, styles.boldtable, styles.headertable2]}>
                  <Text style={styles.row10table}>
                    {this.props.cReporteTurnoSignatureFirmante2 ?
                      <Image
                        style={styles.imagesign}
                        src={this.props.cReporteTurnotrimmedDataURLFirmante2}
                      />
                    :
                      <Image
                        style={styles.imagesign}
                        src="images/signGrey25p.png"
                      />
                    }
                  </Text>
                  <Text style={styles.row11table}>
                    {this.props.cReporteTurnoSignatureFirmante7 ?
                      <Image
                        style={styles.imagesign}
                        src={this.props.cReporteTurnotrimmedDataURLFirmante7}
                      />
                    :
                      <Image
                        style={styles.imagesign}
                        src="images/signGrey25p.png"
                      />
                    }
                  </Text>
              </View>
              <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`SUPERVISOR TURNO NOCHE`}</Text>
                  <Text style={styles.row11table}>{`JEFE TURNO NOCHE`}</Text>
              </View>
              <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`Firmado por: ${this.props.cReporteTurnoNameFirmante2}`}</Text>
                  <Text style={styles.row11table}>{`Firmado por: ${this.props.cReporteTurnoNameFirmante7}`}</Text>
              </View>
              <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`El día: ${this.props.cReporteTurnoDateTimeFirmante2}`}</Text>
                  <Text style={styles.row11table}>{`El día: ${this.props.cReporteTurnoDateTimeFirmante7}`}</Text>
              </View>
            </View>
            <View><Text>{" "}</Text></View>

            <View style={styles.table}>
              <View style={[styles.rowtable2, styles.boldtable, styles.headertable2]}>
                <Text style={styles.row10table}>
                  {this.props.cReporteTurnoSignatureFirmante4 ?
                    <Image
                      style={styles.imagesign}
                      src={this.props.cReporteTurnotrimmedDataURLFirmante4}
                    />
                  :
                    <Image
                      style={styles.imagesign}
                      src="images/signGrey25p.png"
                    />
                  }
                </Text>
                <Text style={styles.row11table}>
                  {this.props.cReporteTurnoSignatureFirmante3 ?
                    <Image
                      style={styles.imagesign}
                      src={this.props.cReporteTurnotrimmedDataURLFirmante3}
                    />
                  :
                    <Image
                      style={styles.imagesign}
                      src="images/signGrey25p.png"
                    />
                  }
                </Text>
              </View>
              <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`JEFE TURNO CONTROL SONDAJE`}</Text>
                  <Text style={styles.row11table}>{`ADM. CTO. MD`}</Text>
              </View>
              <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`Firmado por: ${this.props.cReporteTurnoNameFirmante4}`}</Text>
                  <Text style={styles.row11table}>{`Firmado por: ${this.props.cReporteTurnoNameFirmante3}`}</Text>
              </View>
              <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`El día: ${this.props.cReporteTurnoDateTimeFirmante4}`}</Text>
                  <Text style={styles.row11table}>{`El día: ${this.props.cReporteTurnoDateTimeFirmante3}`}</Text>
              </View>
            </View>
            <View><Text>{" "}</Text></View>

            <View style={styles.table}>
               <View style={[styles.rowtable2, styles.boldtable, styles.headertable2]}>
                  <Text style={styles.row10table}>
                    {this.props.cReporteTurnoSignatureFirmante5 ?
                      <Image
                        style={styles.imagesign}
                        src={this.props.cReporteTurnotrimmedDataURLFirmante5}
                      />
                    :
                      <Image
                        style={styles.imagesign}
                        src="images/signGrey25p.png"
                      />
                    }
                  </Text>
               </View>
               <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`ADM. CTO. AA`}</Text>
               </View>
                <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`Firmado por: ${this.props.cReporteTurnoNameFirmante5}`}</Text>
               </View>
               <View style={styles.rowtable2} wrap={false}>
                  <Text style={styles.row10table}>{`El día: ${this.props.cReporteTurnoDateTimeFirmante5}`}</Text>
               </View>
            </View>
            <View><Text>{" "}</Text></View>

            <Text style={styles.text6}>{`Crosscheck ID: ${this.props.typeContract}/${this.props.numberContract}/CertificadoReporteTurno`}</Text>
            <Text style={styles.text7}>{fechaHoyX.toLocaleString(this.state.isocode,optionsDate)}</Text>
          </Page>
        </Document>
      )
    }
}
export default CertificateReporteTurno;
