import React from 'react';
import './_loader.css';

//translate
import { FormattedMessage } from 'react-intl';

const loader = (props) => (
  <>
  <div className="text-center" style={props.colorDark ? {color: 'white'} : {}}>
     <br></br>
     <strong  style={{fontSize: 30}} ><FormattedMessage id="configuration.loadingdata" /></strong>
     <br></br>
  </div>
  <div className="spinner">
    <div className="bounce1" style={props.colorDark ? {backgroundColor: 'white'} : {}}></div>
    <div className="bounce2" style={props.colorDark ? {backgroundColor: 'white'} : {}}></div>
    <div className="bounce3" style={props.colorDark ? {backgroundColor: 'white'} : {}}></div>
  </div>
  </>
)

export default loader
