import React, { Component } from 'react';

//UI
import {Container, Row, Col, FormInput, Button, Card} from "shards-react";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//translate
import { FormattedMessage } from 'react-intl';

//Configuración
import {token} from '../../config.js'

//Email
import emailjs from 'emailjs-com'

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

class ContractDrawerInvitation extends Component {
  constructor(props) {
      super(props);

      this.newInvitation = this.newInvitation.bind(this);
      this.goUserConfig = this.goUserConfig.bind(this);
      this.handleChange = this.handleChange.bind(this);

      this.state = {
        userSession: new UserSession(),
        username: '',
        user: {},
        code: '',
        description: '',
        error: false,
        infoInvitation: '',
        status: false,
        languaje: 'English',
        jsonBlockstackZ: [],
        arrow: false,
       }
    }

    c01Invitation = React.createRef();
    c02Invitation = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val},()=>{
          Promise.all([this.goUserConfig(val)])
             .then(
                (resolve) => {},
                (reject) => {}
             )
        })) ;
      }else{
        this.setState({ username },()=>{
          Promise.all([this.goUserConfig(username)])
             .then(
                (resolve) => {},
                (reject) => {}
             )

        })
      }
    }

    handleChange(e,chk) {
      const newState = {};
      newState[chk] = !this.state[chk];

      if (chk==='arrow' && this.state.arrow===true){
        this.setState({arrow:false})
      }else{
        if (chk==='arrow' && this.state.arrow===false){
          this.setState({arrow:true})
        }
      }
    }

    goUserConfig(username) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({jsonBlockstackZ: []})
      getFileDB(username, `myUserConfig.json`)
         .then((fileContents) => {
           if(fileContents) {
             const jsonBlockstack1 = JSON.parse(fileContents)
             const jsonBlockstack2 = jsonBlockstack1.data
             let jsonBlockstack5 = '[]'
             if (jsonBlockstack2 !== null){
                const jsonBlockstack3 = jsonBlockstack2.dataobject
                const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                if (jsonBlockstack4.substring(0,1) === '"') {
                   jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                } else {
                   jsonBlockstack5 = jsonBlockstack4
                }
             }
             let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
             this.setState({jsonBlockstackZ: jsonBlockstack6})
           } else {}
         })
        .catch(error => {});
    }


    newInvitation = (e) => {
          const {userSession} = this.state
          const storage = new Storage({ userSession });
          const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
          const {emailNotifications} = this.state.jsonBlockstackZ
          const email = this.c01Invitation.current.value
          const name = this.c02Invitation.current.value
          if(email === '' || email === undefined || email.indexOf('@') === -1) {
               return;
          }
          this.setState({error: false})


          emailjs.send("service_9dv38as", "template_do160w7", {name:name, senderEmail:emailNotifications, email:email}, "user_djYXBdBTeG1Km5qtyqkDl")
            .then(response => {
               console.log('SUCCESS!', response.status, response.text)
            }, error => {
               console.log('FAILED...', error)
               return
            });

          /*
          fetch("https://xck.app:5443/sendCrosscheckInvitation",
                {
                  method: "POST",
                  headers:{
                    'Content-Type': 'application/json',
                    "token": `${token}`
                  },
                  body: JSON.stringify({
                    name: name,
                    email: email,
                    senderEmail: emailNotifications
                  })
                }
          ).then((response)=>{
            if (response.status === 200) {
              console.log('email sended');
            }
            else{
              console.log('error sending the email')
              return
            }
          }).catch(()=>{
            console.log('Catch: error sending the email')
            return
          })
          */

          //-------------------------------------------
          // enviar a Blockstack (Cloud Storage)
          //-------------------------------------------
          // Crear el objeto
          let jsonBlockstack2 = ''
          let infoInvitation = ''
          let reviewInvitation = ''
          if (localStorage["jsonBlockstack30I"]) {
            jsonBlockstack2 = localStorage.getItem('jsonBlockstack30I')
            const jsonBlockstack3 = jsonBlockstack2.substring(1,jsonBlockstack2.length - 1)
            if (jsonBlockstack3 === '') {
               infoInvitation =`[{"email":"${email}","name":"${name}","userid":""}]`
               reviewInvitation ='[]'
            }else{
              infoInvitation =`[${jsonBlockstack3},{"email":"${email}","name":"${name}","userid":""}]`
              reviewInvitation =`[${jsonBlockstack3}]`
            }
          }else{
            infoInvitation =`[{"email":"${email}","name":"${name}","userid":""}]`
            reviewInvitation ='[]'
          }
          this.setState({ infoInvitation })

          let existInvitation3 = false
          const infoInvitationJSON = JSON.parse(reviewInvitation)
          infoInvitationJSON.map((todo, i) => {
             if (todo.email === email) {
                existInvitation3 = true
                return(true)
             }
             return false
          })

          if (existInvitation3===true){
             this.c01Invitation.current.value = ''
             return
          }

          putFileDB(this.state.username,`my_invitations.json`, 'Crosscheck_Chat', JSON.stringify(infoInvitation), JSON.stringify(options))
            .then(() => {
              this.c01Invitation.current.value = ''
              this.c02Invitation.current.value = ''
              this.setState({arrow:false})
            })
     }

     render() {
          let imgarrow = ''
          if (this.state.arrow===true){imgarrow='images/arrowdown.png'}else{imgarrow='images/arrowup.png'}
          return (
            <Container fluid className="main-content-container px-2" style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                  <Card className="text-uppercase" style={ this.props.colorDark ? { backgroundColor:'#233b58' } : { backgroundColor:'#e9ecef' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td style={ this.props.colorDark ? {width: "95%", color: "grey"} : {width: "95%"}}>
                             &nbsp;&nbsp;
                             <strong style={{cursor: 'pointer'}} onClick={e => this.handleChange(e,"arrow")}><img src={imgarrow} weight="20" height="20" alt=""/></strong>
                             &nbsp;&nbsp;
                             <FormattedMessage id="invitation.sendnew" />
                          </td>
                          <td style={{width: "5%"}}>
                             <strong style={{cursor: 'pointer'}} onClick={e => this.handleChange(e,"arrow")}><img src={imgarrow} weight="20" height="20" alt=""/></strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </Card>
                {this.state.arrow ?
                  <>
                    <Row>
                        <Col md="0"></Col>
                        <Col md="11">
                              <FormInput
                                id="Email"
                                innerRef={this.c01Invitation}
                                placeholder="Email"
                              />
                          </Col>
                     </Row>
                     <Row>&nbsp;</Row>
                     <Row>
                         <Col md="0"></Col>
                         <Col md="11">
                                <FormInput
                                  id="Name"
                                  innerRef={this.c02Invitation}
                                  placeholder="Name"
                                />
                          </Col>
                      </Row>
                      <Row>&nbsp;</Row>
                      <Row>
                          <Col md="0"></Col>
                          <Col md="11">
                            <div className="text-center">
                                <Button outline pill theme="success" size="sm" className="mb-2" onClick={e=>this.newInvitation(e)}>
                                    <i className="material-icons mr-1">replay</i> <FormattedMessage id="invitation.submit" />
                                </Button>
                            </div>
                          </Col>
                      </Row>
                      <br></br>
                    </>
                  : null }
            </Container>
          );
     }
}

export default ContractDrawerInvitation;
