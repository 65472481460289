//React
import React, { Component } from 'react';

//UI
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormTextarea,
  FormSelect,
  Container,
  InputGroupAddon,
  InputGroupText,
  FormRadio
} from "shards-react";
import { Table } from 'reactstrap';
import { Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';

//base64
import { Base64 } from 'js-base64';

//translate
import { FormattedMessage } from 'react-intl';

//Number Format
import NumberFormat from 'react-number-format';

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { makeRandomPrivKey } from '@stacks/transactions';
import { Storage } from '@stacks/storage'

//UUID
import uuid from 'uuid';

//Axios
import axios from 'axios';

//Configuración
import { SMART_CONTRACT_ADDRESS_MAINNET_STX,
        SMART_CONTRACT_NAME_MAINNET_STX,
        SMART_CONTRACT_ADDRESS_TESTNET_STX,
        SMART_CONTRACT_NAME_TESTNET_STX,
        SMART_CONTRACT_ADDRESS_MAINNET_XCK,
        SMART_CONTRACT_NAME_MAINNET_XCK,
        SMART_CONTRACT_ADDRESS_MAINNET_MXM,
        SMART_CONTRACT_NAME_MAINNET_MXM,
        SMART_CONTRACT_ADDRESS_MAINNET_MIA,
        SMART_CONTRACT_NAME_MAINNET_MIA,
        SMART_CONTRACT_ADDRESS_MAINNET_NYC,
        SMART_CONTRACT_NAME_MAINNET_NYC,
        SMART_CONTRACT_ADDRESS_MAINNET_FRIE,
        SMART_CONTRACT_NAME_MAINNET_FRIE,
        MAINNET_STACKS_API_URL,
        TESTNET_STACKS_API_URL } from '../../config.js'

//Clarity
import {transferDeliverable, balanceOf, getTransactionDeliverable, getBalance} from "../../clarity/clarityfunctions"

import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

function ConfirmacionTasarDeliverable(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Container fluid className="px-0">
                    <Card theme="warning" >
                      <table>
                        <tbody>
                          <tr>
                            <td>
                               &nbsp;&nbsp;<div className="fa fa-info"></div>
                            </td>
                            <td>
                               &nbsp;&nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                </Container>
                <Table size="sm" className="text-center" responsive hover striped bordered style={{ color: 'black', fontSize:10 }}>
                    <thead>
                      <tr style={{ textAlign:"center", color: 'blue', fontSize:12 }}>
                        <th><FormattedMessage id="usergroup.concept" /></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Transaction Id:</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}><a href={props.url_tx} target="_blank" rel="noopener noreferrer">{props.tx_id}</a></td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Status</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.tx_status}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Type</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.tx_type}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Function Name</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.contract_call_function_name}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Sender Address</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}><a href={props.url_sender} target="_blank" rel="noopener noreferrer">{props.sender_address}</a></td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Fee Rate</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}>{props.fee_rate} STX</td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:10 }}>Block Hash</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:10 }}><a href={props.url_block_hash} target="_blank" rel="noopener noreferrer">{props.block_hash}</a></td>
                      </tr>
                      <tr>
                        <td style={{ width: "30%", textAlign:"left", color: 'black', fontSize:9 }}>Block Time</td>
                        <td style={{ width: "70%", textAlign:"right", color: 'black', fontSize:9 }}>{props.burn_block_time_iso}</td>
                      </tr>
                    </tbody>
                </Table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'exit', props.cryptoPactClarityEthereum2X, props.saldoX)} theme="success" autoFocus>
                    <FormattedMessage id="alert.continue" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class ContractDeliverableMail extends Component {

    constructor(props) {
        super(props);

        this.sendMail = this.sendMail.bind(this);
        this.returnMail = this.returnMail.bind(this);
        this.viewDeliverable = this.viewDeliverable.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          disabled: false,
          disabled2: true,
          feTo: '',
          jsonBlockstackZ71: [],
          viewDeliverableX: false,
          toX: '',
          dialogOpen: false,
          dialogMessage: '',
          dialogTitle: '',
          balanceCta: 0,
          resultTransfer: '',
          stacksAddress: '',
          privateKey: '',
          contractAddressX: '',
          addressKeyX: '',
          networkUrlX: '',
          contractNameX: '',
          contractAddressX2: '',
          serverStacksX: '',
          usernameX: '',
          amount: 0.3,
          stacksAddressRecipient: '',
          stacksAddressSender: '',
          registerTransactionRegister: '',
          resultTransfer: '',
          disabledButton: false,
          getDeliverable: '',
          permiteDeliverable: false,
          bconSinPago: true,
          selectedDeliverableCost: 'sinCosto',
          bconSinPagoTableX: false,
          cryptoCurrencyX: '',
          symbolCurrencyX: '',
          symbolCriptoCurrency: '',
          optionCostDeliverable: false,
          optionCostDeliverable2: false,
          jsonPaymentForm: [],
          uuidX: '',
          confirmDeliverableX: false,
          authorizersX: [],
          roleX: [],
          generateX: false,
        }
    }

    c01Contact = React.createRef();
    c02Contact = React.createRef();
    c03Contact = React.createRef();
    c04Contact = React.createRef();
    c05Contact = React.createRef();

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
          }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }

      this.setState({ networkUrlX })

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }

      let selectedDeliverableCostX = 'sinCosto'
      let bconSinPagoTableX = false
      let optionCostDeliverable = false
      let confirmDeliverableX = false
      if (this.props.selectedSignaturePayment === 'SignatureAndPayment') {
         selectedDeliverableCostX = 'conCosto'
         optionCostDeliverable = true
         bconSinPagoTableX = true
         confirmDeliverableX = true
      }
      this.setState({selectedDeliverableCost: selectedDeliverableCostX, bconSinPagoTableX, optionCostDeliverable, confirmDeliverableX})
      const palabra = this.props.cryptoCurrencyPayment
      const ArrayIdValue = palabra.split(' - ')
      let symbolCurrencyX = ArrayIdValue[0]
      this.setState({cryptoCurrencyX: this.props.cryptoCurrencyPayment, symbolCriptoCurrency: symbolCurrencyX})

      if (this.props.CurrencyType === 'FIAT Money'){
         const palabra = this.props.FIACurrency
         const ArrayIdValue = palabra.split(' - ')
         symbolCurrencyX = ArrayIdValue[0]
      }else{
        const palabra = this.props.cryptoCurrencyOrigin
        const ArrayIdValue = palabra.split(' - ')
        symbolCurrencyX = ArrayIdValue[0]
      }
      this.setState({symbolCurrencyX: symbolCurrencyX})
      this.setState({jsonPaymentForm: this.props.jsonPaymentForm})
      const uuid2X = uuid()
      this.setState({uuidX: uuid2X})

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val},()=>{
          Promise.all([this.goDetailRole(val),
                       this.goAuthorizers(val)])
          .then((resolve1) => {},
                (reject1) => {})
        })) ;
      }else{
        this.setState({ username },()=>{
          Promise.all([this.goDetailRole(username),
                       this.goAuthorizers(username)])
          .then((resolve1) => {},
                (reject1) => {})

        })
      }
    }

    componentDidMount() {
      if (this.props.view === true){
         this.viewDeliverable()
      }else{
        this.c01Contact.current.value = this.props.from
      }
    }

    handleChangeOption = (optSearch, disabled2X, disabled3X) => {
      //if (!disabled2X && !disabled3X){
          if (optSearch === 'sinCosto'){
            this.setState({selectedDeliverableCost: 'sinCosto',bconSinPagoTableX:false,confirmDeliverableX:false});
            let jsonPaymentForm = this.state.jsonPaymentForm
            if (this.state.jsonPaymentForm.length === 0){
               jsonPaymentForm = this.props.jsonPaymentForm
            }
            jsonPaymentForm.map((todo2, y) => {
              if (todo2.selectedDeliverableCostWrite === undefined || todo2.selectedDeliverableCostWrite === false) {
                todo2.selectedDeliverableCost = ''
                todo2.selectedDeliverableCostWrite = false
                todo2.selectedDeliverableUuid = ''
              }
            })
            this.setState({jsonPaymentForm: jsonPaymentForm})
          }
          if (optSearch === 'conCosto'){
            this.setState({selectedDeliverableCost: 'conCosto',bconSinPagoTableX:true,confirmDeliverableX:true});
          }
      //}
    }

    handleChangeOptionCostDeliverable(i) {
        let jsonPaymentForm = this.state.jsonPaymentForm
        if (this.state.jsonPaymentForm.length === 0){
           jsonPaymentForm = this.props.jsonPaymentForm
        }
        jsonPaymentForm.map((todo2, y) => {
          if (y === i) {
            todo2.selectedDeliverableCost = 'conCosto'
            todo2.selectedDeliverableCostWrite = false
            todo2.selectedDeliverableUuid = this.state.uuidX
          }else{
            if (todo2.selectedDeliverableCostWrite === undefined || todo2.selectedDeliverableCostWrite === false) {
              todo2.selectedDeliverableCost = ''
              todo2.selectedDeliverableCostWrite = false
              todo2.selectedDeliverableUuid = ''
            }
          }
        })
        this.setState({jsonPaymentForm: jsonPaymentForm})
    }

    viewDeliverable(){
      let jsondisplayListDeliverable = []
      jsondisplayListDeliverable = this.props.jsonBlockstackZ50
      this.setState({disabled:true})
      jsondisplayListDeliverable.map((todo, i) => {
        if (todo.id === this.props.id){
          this.c01Contact.current.value = todo.origin
          this.c02Contact.current.value = todo.to
          this.c03Contact.current.value = todo.subject
          this.c04Contact.current.value = Base64.decode(todo.message)
          this.c05Contact.current.value = todo.cc
          this.setState({viewDeliverableX: true, toX:todo.to, jsonBlockstackZ71:[{"id":todo.cc}]})
          if (todo.deliverableCost !== undefined){
            this.setState({selectedDeliverableCost:'sinCosto' })
            //this.setState({selectedDeliverableCost:todo.deliverableCost })
          }else{
            this.setState({selectedDeliverableCost:'sinCosto' })
          }

        }
        return (false)
      })
    }

    sendMail(){
      if (this.c02Contact.current.value !== 'feTo'){
        this.props.sendMail(
            this.c02Contact.current.value,
            this.c03Contact.current.value,
            this.c04Contact.current.value,
            this.c05Contact.current.value,
            this.props.jsonBlockstackZ50,
            this.state.selectedDeliverableCost,
            this.state.uuidX,
            this.state.jsonPaymentForm)
      }
    }

    returnMail(){
      const {jsonPaymentForm} = this.state
      jsonPaymentForm.map((todo2, y) => {
        if (todo2.selectedDeliverableUuid = this.state.uuidX) {
          todo2.selectedDeliverableCost = false
          todo2.selectedDeliverableCostWrite = false
          todo2.selectedDeliverableUuid = ''
          this.setState({jsonPaymentForm: jsonPaymentForm})
        }
      })
      this.props.returnMail()
    }

    changeSelect = (e,optx) =>{
      if (optx = 'feTo'){
         if (this.c02Contact.current.value !== 'feTo'){
           this.setState({disabled2:false,feTo:this.c02Contact.current.value,jsonBlockstackZ71:[]})
           let jsonBlockstack4 = []
           this.props.jsonBlockstackZ70.map((todo, key) => {
               if (todo.id !== this.props.username && todo.id !== this.c02Contact.current.value){
                  jsonBlockstack4 = [...jsonBlockstack4, {"id":todo.id}]
               }
           })
           this.setState({jsonBlockstackZ71:jsonBlockstack4})
         }
      }
    }

    confirmDeliverable = () => {
       //this.tasaDeliverable()
       this.sendMail()
    }

    goAuthorizers(username) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      let archiveX = ''
      if (this.props.view){
        archiveX = `${this.props.typeContract}_${this.props.numberContract}_${this.props.deliverableUuidX}_authorizer_deliverable.json`
      }else{
        archiveX = `${this.props.typeContract}_${this.props.numberContract}_authorizer_deliverable_2.json`
      }
      this.setState({displayUserGroup: true})
      return new Promise((resolve1, reject1) => {
          getFileDB(username, archiveX)
             .then((fileContents) => {
               if(fileContents) {
                 const jsonBlockstack1 = JSON.parse(fileContents)
                 const jsonBlockstack2 = jsonBlockstack1.data
                 let jsonBlockstack5 = '[]'
                 if (jsonBlockstack2 !== null){
                    const jsonBlockstack3 = jsonBlockstack2.dataobject
                    const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                    if (jsonBlockstack4.substring(0,1) === '"') {
                       jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                    } else {
                       jsonBlockstack5 = jsonBlockstack4
                    }
                 }
                 let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                 let role2X = ''
                 let level2X = ''
                 let generate2X = false
                 jsonBlockstack6.map((todo, i) => {
                   todo.display = false
                   if (todo.codeFormsDeliverable === this.props.form_deliverableX){
                        if (todo.id === this.props.username){
                           role2X = todo.role
                           todo.display = false
                           this.state.roleX.map((todoRoleX, y) => {
                             if (todoRoleX.code === todo.role){
                               todo.level = todoRoleX.level
                               level2X = todoRoleX.level
                               generate2X = todoRoleX.generate
                             }
                           })
                        }else{
                          todo.display = true
                          this.state.roleX.map((todoRoleX, y) => {
                            if (todoRoleX.code === todo.role){
                              todo.level = todoRoleX.level
                            }
                          })
                        }
                   }
                 })
                 jsonBlockstack6.map((todo, i) => {
                   if (todo.display === true){
                        if (todo.level === level2X + 1){
                           todo.display = true
                        }else{
                          todo.display = false
                        }
                   }
                 })

                 this.setState({authorizersX:jsonBlockstack6, levelX: level2X, generateX: generate2X })
                 resolve1()
               } else {
                 resolve1()
               }
             })
              .catch(error => {
                console.log(error)
                resolve1()
              });
      });
    }

    goDetailRole(username) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { decrypt: false, verify: false }
      this.setState({opcionRole: 'Role',typeRole: 'role',jsonBlockstack: [],displayRole: true})
      localStorage.removeItem('jsonBlockstack20')
      return new Promise((resolve1, reject1) => {
        getFileDB(username, `role_deliverable_2.json`)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               localStorage.setItem('jsonBlockstack20',jsonBlockstack5)
               this.setState({roleX: jsonBlockstack6})
               resolve1()
             } else {
               resolve1()
             }
           })
          .catch(error => {
            console.log(error)
            resolve1()
          });
      });
    }

    render() {
          const {jsonBlockstackZ70} = this.props
          const {jsonBlockstackZ71} = this.state

          let  bconSinPagoX = this.state.bconSinPago
          let disabled2X = true
          let disabled3X = true
          let disabled4X = false
          //if (this.props.userRole === 'Creator' && this.props.from === this.props.username){
          //   disabled2X = false
          //   bconSinPagoX = true
          //}

          if (this.props.selectedSignaturePayment === 'SignatureAndPayment'  && this.props.existDeliverableX){
            disabled2X = false
            disabled3X = true
          }else{
            disabled2X = true
            disabled3X = false
          }

          if (this.props.existDeliverableX){
            disabled2X = false
            disabled3X = true
          }

          if (this.props.view){
            disabled4X = true
          }

          const selectedDeliverableCostX = this.state.selectedDeliverableCost

          const disabledX = this.state.disabled

          const bconSinPagoTableX = this.state.bconSinPagoTableX

          let confirmDeliverableX = this.state.confirmDeliverableX
          let jsonPaymentForm = this.state.jsonPaymentForm
          if (this.state.jsonPaymentForm.length === 0){
            jsonPaymentForm = this.props.jsonPaymentForm
            for (var i = jsonPaymentForm.length - 1; i >= 0; i--) {
              if (jsonPaymentForm[i].concept === 'Deliverable'){
                 if (this.props.view === false && jsonPaymentForm[i].selectedDeliverableCostWrite === true) {
                    jsonPaymentForm.splice(i, 1);
                 }
              }else{
                 jsonPaymentForm.splice(i, 1);
              }
            }

          }
          const {authorizersX, roleX} = this.state

          return (
            <div style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>
                <ConfirmacionTasarDeliverable
                    handleDialog={this.handleDialog2}
                    dialogMessage={this.state.dialogMessage}
                    dialogOpen={this.state.dialogOpenDisplayTransaction}
                    dialogTitle={this.state.dialogTitle}
                />
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        {/* Name */}
                        <Col className="form-group">
                          <label htmlFor="feFrom" style={{fontSize:16}} ><FormattedMessage id="aboutcontact.from" /></label>
                          <FormInput
                            id="feFrom"
                            style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'orange'} : {}}
                            innerRef={this.c01Contact}
                            onChange={() => {}}
                            disabled={true}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        <Col className="form-group">
                          <label htmlFor="feTo" style={{fontSize:16}} ><FormattedMessage id="aboutcontact.to" /></label>
                          <FormSelect
                              id="feTo"
                              style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'orange'} : {}}
                              innerRef={this.c02Contact}
                              placeholder="Select an Option"
                              onChange={e => {this.changeSelect(e,'feTo')}}
                              disabled={false}
                            >
                            {authorizersX.map((todoAuthorizer, key) => {
                              let level2X = ''
                              roleX.map((todoRoleX, y) => {
                                if (todoRoleX.code === todoAuthorizer.role){
                                  level2X = todoRoleX.level
                                }
                              })
                              if (this.state.levelX === 1){
                                if (level2X === 2){
                                  if (todoAuthorizer.codeFormsDeliverable === this.props.form_deliverableX  && todoAuthorizer.id !== this.props.username){
                                    return (
                                      <option key={todoAuthorizer.id} value={`${todoAuthorizer.id}`} selected>{todoAuthorizer.id}</option>
                                    )
                                  }
                                }
                              }else{
                                if (level2X > 2){
                                  if (todoAuthorizer.codeFormsDeliverable === this.props.form_deliverableX  && todoAuthorizer.id !== this.props.username){
                                    return (
                                      <option key={todoAuthorizer.id} value={`${todoAuthorizer.id}`} selected>{todoAuthorizer.id}</option>
                                    )
                                  }
                                }
                              }
                            })}
                          </FormSelect>
                        </Col>
                      </Row>
                      <Row form>
                        {/* Name */}
                        <Col className="form-group">
                          <label htmlFor="feCC" style={{fontSize:16}} ><FormattedMessage id="aboutcontact.cc" /></label>
                          <FormSelect
                              id="feCC"
                              style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'orange'} : {}}
                              innerRef={this.c05Contact}
                              placeholder="Select an Option"
                              onChange={() => {}}
                              disabled={true}
                            >
                            <option value="feCC" selected>{"Select an Option"}</option>
                          </FormSelect>
                      </Col>
                      </Row>

                      <Row form>
                        {/* Email */}
                        <Col className="form-group">
                          <label htmlFor="feSubject" style={{fontSize:16}} ><FormattedMessage id="aboutcontact.subject" /></label>
                          <FormInput
                            type="text"
                            id="feSubject"
                            style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'orange'} : {}}
                            innerRef={this.c03Contact}
                            onChange={() => {}}
                            disabled={this.state.disabled}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        {/* Message */}
                        <Col className="form-group">
                          <label htmlFor="feMessage" style={{fontSize:16}} ><FormattedMessage id="aboutcontact.message" /></label>
                          <FormTextarea
                            id="feMessage"
                            rows="7"
                            style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'orange'} : {}}
                            innerRef={this.c04Contact}
                            disabled={this.state.disabled}
                          />
                        </Col>
                      </Row>

                      {bconSinPagoX ?
                        <Row form>
                          {/* Message */}
                          <Col md="12">
                            <label htmlFor="DeliverableCost" style={{fontSize:16}} ><FormattedMessage id="deliverable.cost" /></label>
                            <Card small text-center style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'orange'} : {}}>
                              <InputGroupAddon >
                                <InputGroupText style={ this.props.colorDark ? { backgroundColor:'#111f2c', color: 'orange', fontSize:12} : {fontSize:12}}>
                                  <FormRadio
                                    name="conCosto"
                                    checked={selectedDeliverableCostX === "conCosto"}
                                    disabled={true}
                                    onChange={() => {
                                      this.handleChangeOption("conCosto",disabled2X,disabled3X);
                                    }}
                                  >
                                    <FormattedMessage id="deliverable.concosto" />
                                  </FormRadio>
                                  &nbsp;&nbsp;
                                  <FormRadio
                                    name="sinCosto"
                                    checked={selectedDeliverableCostX === "sinCosto"}
                                    disabled={disabled4X}
                                    onChange={() => {
                                      this.handleChangeOption("sinCosto",disabled2X,disabled3X);
                                    }}
                                  >
                                    <FormattedMessage id="deliverable.sincosto" />
                                  </FormRadio>
                                </InputGroupText>
                              </InputGroupAddon>
                            </Card>
                          </Col>
                        </Row>
                      :
                        null
                      }

                      {bconSinPagoTableX ?
                        <Card theme="light" >
                           <Table size="sm" className="text-center" responsive hover striped>
                              <thead>
                                <tr>
                                  <th style={{fontSize:15}}></th>
                                  <th style={{fontSize:15}}><FormattedMessage id="payment.item" /></th>
                                  <th style={{fontSize:15}}><FormattedMessage id="payment.concept" /></th>
                                  <th style={{fontSize:15}}><FormattedMessage id="payment.estimateddate" /></th>
                                  <th style={{fontSize:15}}><FormattedMessage id="payment.amountorigin" /></th>
                                </tr>
                              </thead>
                              <tbody>
                                {jsonPaymentForm.map((todo, i) => {
                                  if (todo.concept === 'Deliverable'){
                                     if ((this.props.view === true && todo.selectedDeliverableUuid === this.props.deliverableUuidX) || (this.props.view === false && (todo.selectedDeliverableCostWrite === undefined || todo.selectedDeliverableCostWrite === false))) {
                                        let selectedDeliverableCostX = todo.selectedDeliverableCost
                                        let item = i
                                        confirmDeliverableX = false
                                        return (
                                            <tr key={i} style={ this.props.colorDark ? { color:'black', fontSize:12} : {color:'black', fontSize:12}}>
                                                <td>
                                                      <FormRadio
                                                        name="costDeliverable"
                                                        checked={selectedDeliverableCostX}
                                                        disabled={disabled4X}
                                                        onChange={() => {
                                                          this.handleChangeOptionCostDeliverable(i);
                                                        }}
                                                      >&nbsp;</FormRadio>
                                                </td>
                                                <td>{++item}</td>
                                                <td>{todo.concept}</td>
                                                <td>{todo.estimateddate}</td>
                                                <td>{`${todo.amount} ${this.state.symbolCurrencyX}`}</td>
                                            </tr>
                                        )
                                     }
                                  }
                                })}
                              </tbody>
                            </Table>
                        </Card>
                        :
                          null
                        }

                      {this.state.disabled ?
                        <Button theme="dark" onClick={this.returnMail} ><FormattedMessage id="contract.listcol19" /></Button>
                      :
                        <React.Fragment>
                          {confirmDeliverableX ?
                            <>
                              <Button theme="danger" disabled={true} ><FormattedMessage id="aboutcontact.send" /></Button>&nbsp;&nbsp;
                            </>
                          :
                            <>
                              {this.state.generateX ?
                                <>
                                 <Button theme="success" onClick={this.confirmDeliverable} ><FormattedMessage id="aboutcontact.send" /></Button>&nbsp;&nbsp;
                                </>
                              :
                                <>
                                 <Button theme="danger" disabled={true} ><FormattedMessage id="aboutcontact.send" /></Button>&nbsp;&nbsp;
                                </>
                              }
                            </>
                          }
                          <Button theme="dark" onClick={this.returnMail} ><FormattedMessage id="contract.listcol19" /></Button>
                        </React.Fragment>
                    }
                    </Form>
                  </Col>
                </Row>
              </div>
          );
     }
}

export default ContractDeliverableMail;
