import React, { Component } from 'react';
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";

class SidebarNavItem extends Component {
      constructor(props) {
        super(props);
        this.state = {
          language: '',
        }
      }

      UNSAFE_componentWillMount() {
        const languageX = localStorage.getItem('language')
        this.setState({language: languageX})
      }

      render() {
          const { item, menuVisibleX } = this.props
          let descripX = ''

          if (menuVisibleX===true){
            if (this.state.language === 'English'){descripX = item.title}
            if (this.state.language === 'Spanish'){descripX = item.title_es}
            if (this.state.language === 'French'){descripX = item.title_fr}
            if (this.state.language === 'Portuguese'){descripX = item.title_pt}
            if (this.state.language === 'Swedish'){descripX = item.title_sv}
            if (this.state.language === 'Netherlands'){descripX = item.title_nl}
            if (this.state.language === 'Russian'){descripX = item.title_ru}
            if (this.state.language === 'Japanese'){descripX = item.title_jp}
            if (this.state.language === 'Chinese'){descripX = item.title_cn}
            if (this.state.language === 'German'){descripX = item.title_de}
            if (this.state.language === 'Italian'){descripX = item.title_it}
          }

          return (
              <NavItem>
                <NavLink tag={RouteNavLink} to={item.to}>
                  {item.htmlBefore && (
                    <div
                      className="d-inline-block item-icon-wrapper"
                      dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
                    />
                  )}

                  <span>{descripX}</span>

                  {item.htmlAfter && (
                    <div
                      className="d-inline-block item-icon-wrapper"
                      dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
                    />
                  )}
                </NavLink>
              </NavItem>

            );
      }
}

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
