import {
  FETCH_PACT_REQUEST_LOGISTIC_CONFIG,FETCH_PACT_SUCCEEDED_LOGISTIC_CONFIG,FETCH_PACT_FAILED_LOGISTIC_CONFIG,
  FETCH_PACT_REQUEST_LOGISTIC_CONFIG_CONTRACTID,FETCH_PACT_SUCCEEDED_LOGISTIC_CONFIG_CONTRACTID,FETCH_PACT_FAILED_LOGISTIC_CONFIG_CONTRACTID,
  FETCH_DELETED_PACT_REQUEST_LOGISTIC_CONFIG,FETCH_DELETED_PACT_SUCCEEDED_LOGISTIC_CONFIG,FETCH_DELETED_PACT_FAILED_LOGISTIC_CONFIG,
  SAVE_NEW_PACT_REQUEST_LOGISTIC_CONFIG,SAVE_NEW_PACT_SUCCEEDED_LOGISTIC_CONFIG,SAVE_NEW_PACT_FAILED_LOGISTIC_CONFIG,
  SAVE_NEW_PACT_REQUEST_LOGISTIC_RULES,SAVE_NEW_PACT_SUCCEEDED_LOGISTIC_RULES,SAVE_NEW_PACT_FAILED_LOGISTIC_RULES,
  REMOVE_PACT_REQUEST_LOGISTIC_CONFIG,REMOVE_PACT_SUCCEEDED_LOGISTIC_CONFIG,REMOVE_PACT_FAILED_LOGISTIC_CONFIG,
  UPDATE_PACT_REQUEST_LOGISTIC_CONFIG,UPDATE_PACT_SUCCEEDED_LOGISTIC_CONFIG,UPDATE_PACT_FAILED_LOGISTIC_CONFIG,
  TOGGLE_PACT_STATE_REQUEST_LOGISTIC_CONFIG,TOGGLE_PACT_STATE_SUCCEEDED_LOGISTIC_CONFIG,TOGGLE_PACT_STATE_FAILED_LOGISTIC_CONFIG,
  SAVE_NEW_PACT_REQUEST_DATA_SENSOR_TEMPERATURE,SAVE_NEW_PACT_SUCCEEDED_DATA_SENSOR_TEMPERATURE,SAVE_NEW_PACT_FAILED_DATA_SENSOR_TEMPERATURE,
  SAVE_NEW_PACT_REQUEST_DATA_SENSOR_HUMIDITY,SAVE_NEW_PACT_SUCCEEDED_DATA_SENSOR_HUMIDITY,SAVE_NEW_PACT_FAILED_DATA_SENSOR_HUMIDITY,
  SAVE_NEW_PACT_REQUEST_DATA_SENSOR_GASCONTROL,SAVE_NEW_PACT_SUCCEEDED_DATA_SENSOR_GASCONTROL,SAVE_NEW_PACT_FAILED_DATA_SENSOR_GASCONTROL,
  SAVE_NEW_PACT_REQUEST_DATA_SENSOR,SAVE_NEW_PACT_SUCCEEDED_DATA_SENSOR,SAVE_NEW_PACT_FAILED_DATA_SENSOR,
  SAVE_NEW_PACT_REQUEST_MAINTENANCE_CONFIG,SAVE_NEW_PACT_SUCCEEDED_MAINTENANCE_CONFIG,SAVE_NEW_PACT_FAILED_MAINTENANCE_CONFIG,
  SAVE_NEW_PACT_REQUEST_MAINTENANCE_RULES,SAVE_NEW_PACT_SUCCEEDED_MAINTENANCE_RULES,SAVE_NEW_PACT_FAILED_MAINTENANCE_RULES,
  SAVE_NEW_PACT_REQUEST_PROFORMA_CONFIG,SAVE_NEW_PACT_SUCCEEDED_PROFORMA_CONFIG,SAVE_NEW_PACT_FAILED_PROFORMA_CONFIG,
  SAVE_NEW_PACT_REQUEST_PROFORMA_RULES,SAVE_NEW_PACT_SUCCEEDED_PROFORMA_RULES,SAVE_NEW_PACT_FAILED_PROFORMA_RULES

} from '../actions/types';

const initialState = {
  smartcontractIsLoading: false,
  smartcontractRulesIsLoading: false,
  smartcontractError: null,
  smartcontractRulesError: null,
  smartcontract: [],
  smartcontractRules: [],
  newEntry: '',
  newDate: new Date().toISOString().slice(0, 10),
  newSmartContract: '',
  newSmartContractRules: '',
  editStatusSmartContract: false,
  editedSmartContract: null,
  newDataSensorx: '',
  datasensorx: [],
  datasensorxIsLoading: false,
  datasensorxError: null,
};

let workingSmartContract;
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

    case FETCH_PACT_REQUEST_LOGISTIC_CONFIG:
      return { ...state, smartcontractIsLoading: true };

    case FETCH_PACT_SUCCEEDED_LOGISTIC_CONFIG:
      return {
        ...state,
        smartcontractError: null,
        smartcontract: action.smartcontract
          .sort((a, b) => a.id - b.id)
          .map(todo => ({ ...todo, editStatusSmartContract: false })),
        smartcontractIsLoading: false,
      };

    case FETCH_PACT_FAILED_LOGISTIC_CONFIG:
      return {
        ...state,
        smartcontractError: action.error,
        smartcontract: [],
        smartcontractIsLoading: false,
      };

    case FETCH_PACT_REQUEST_LOGISTIC_CONFIG_CONTRACTID:
      return { ...state, smartcontractIsLoading: true };

    case FETCH_PACT_SUCCEEDED_LOGISTIC_CONFIG_CONTRACTID:
      return {
        ...state,
        smartcontractError: null,
        smartcontract: action.smartcontract
          .sort((a, b) => a.id - b.id)
          .map(todo => ({ ...todo, editStatusSmartContract: false })),
        smartcontractIsLoading: false,
      };

    case FETCH_PACT_FAILED_LOGISTIC_CONFIG_CONTRACTID:
      return {
        ...state,
        smartcontractError: action.error,
        smartcontract: [],
        smartcontractIsLoading: false,
      };

    case FETCH_DELETED_PACT_REQUEST_LOGISTIC_CONFIG:
      return { ...state, smartcontractIsLoading: true };

    case FETCH_DELETED_PACT_SUCCEEDED_LOGISTIC_CONFIG:
      return {
        ...state,
        smartcontractError: null,
        smartcontract: action.smartcontract
          .sort((a, b) => a.id - b.id)
          .map(todo => ({ ...todo, editStatusSmartContract: false })),
        smartcontractIsLoading: false,
      };

    case FETCH_DELETED_PACT_FAILED_LOGISTIC_CONFIG:
      return {
        ...state,
        smartcontractError: action.error,
        smartcontract: [],
        smartcontractIsLoading: false,
      };

    //--------------------------------------------------------------------------------------------

    case SAVE_NEW_PACT_REQUEST_LOGISTIC_CONFIG:
      return { ...state, smartcontractIsLoading: true };

    case SAVE_NEW_PACT_REQUEST_LOGISTIC_RULES:
      return { ...state, smartcontractRulesIsLoading: true };

    case SAVE_NEW_PACT_SUCCEEDED_LOGISTIC_CONFIG:
      return {
        ...state,
        smartcontractError: null,
        smartcontract: [...state.smartcontract, action.newSmartContract].sort((a, b) => a.id - b.id),
        smartcontractIsLoading: false,
        newTodo: '',
      };

    case SAVE_NEW_PACT_SUCCEEDED_LOGISTIC_RULES:
        return {
          ...state,
          smartcontractRulesError: null,
          smartcontractRules: [...state.smartcontractRules, action.newSmartContractRules].sort((a, b) => a.id - b.id),
          smartcontractRulesIsLoading: false,
          newTodo: '',
        };

    case SAVE_NEW_PACT_FAILED_LOGISTIC_CONFIG:
          return {
            ...state,
            smartcontractError: action.error,
            smartcontractIsLoading: false,
          };

    case SAVE_NEW_PACT_FAILED_LOGISTIC_RULES:
          return {
            ...state,
            smartcontractRulesError: action.error,
            smartcontractRulesIsLoading: false,
          };

    //--------------------------------------------------------------------------------------------

    case SAVE_NEW_PACT_FAILED_DATA_SENSOR_TEMPERATURE:
        return {
          ...state,
          datasensorxError: action.error,
          datasensorxIsLoading: false,
        };

    case SAVE_NEW_PACT_REQUEST_DATA_SENSOR_TEMPERATURE:
          return { ...state, datasensorxIsLoading: true };

    case SAVE_NEW_PACT_SUCCEEDED_DATA_SENSOR_TEMPERATURE:
          return {
            ...state,
            datasensorxError: null,
            datasensorx: [...state.datasensorx, action.newDataSensorx].sort((a, b) => a.id - b.id),
            datasensorxIsLoading: false,
            newTodo: '',
          };

    case SAVE_NEW_PACT_FAILED_DATA_SENSOR_HUMIDITY:
        return {
          ...state,
          datasensorxError: action.error,
          datasensorxIsLoading: false,
        };

    case SAVE_NEW_PACT_REQUEST_DATA_SENSOR_HUMIDITY:
          return { ...state, datasensorxIsLoading: true };

    case SAVE_NEW_PACT_SUCCEEDED_DATA_SENSOR_HUMIDITY:
          return {
            ...state,
            datasensorxError: null,
            datasensorx: [...state.datasensorx, action.newDataSensorx].sort((a, b) => a.id - b.id),
            datasensorxIsLoading: false,
            newTodo: '',
          };

    case SAVE_NEW_PACT_FAILED_DATA_SENSOR_GASCONTROL:
        return {
          ...state,
          datasensorxError: action.error,
          datasensorxIsLoading: false,
        };

    case SAVE_NEW_PACT_REQUEST_DATA_SENSOR_GASCONTROL:
          return { ...state, datasensorxIsLoading: true };

    case SAVE_NEW_PACT_SUCCEEDED_DATA_SENSOR_GASCONTROL:
          return {
            ...state,
            datasensorxError: null,
            datasensorx: [...state.datasensorx, action.newDataSensorx].sort((a, b) => a.id - b.id),
            datasensorxIsLoading: false,
            newTodo: '',
          };

    case SAVE_NEW_PACT_FAILED_DATA_SENSOR:
        return {
          ...state,
          datasensorxError: action.error,
          datasensorxIsLoading: false,
        };

    case SAVE_NEW_PACT_REQUEST_DATA_SENSOR:
          return { ...state, datasensorxIsLoading: true };

    case SAVE_NEW_PACT_SUCCEEDED_DATA_SENSOR:
          return {
            ...state,
            datasensorxError: null,
            datasensorx: [...state.datasensorx, action.newDataSensorx].sort((a, b) => a.id - b.id),
            datasensorxIsLoading: false,
            newTodo: '',
          };

    //--------------------------------------------------------------------------------------------

    case REMOVE_PACT_REQUEST_LOGISTIC_CONFIG:
      return { ...state, smartcontractIsLoading: true };

    case REMOVE_PACT_SUCCEEDED_LOGISTIC_CONFIG:
      return {
        ...state,
        smartcontractError: null,
        smartcontract: state.smartcontract.filter(todo => todo.id !== action.id),
        smartcontractIsLoading: false,
        newSmartContract: '',
      };

    case REMOVE_PACT_FAILED_LOGISTIC_CONFIG:
      return {
        ...state,
        smartcontractError: action.error,
        smartcontractIsLoading: false,
      };

    //--------------------------------------------------------------------------------------------

    case UPDATE_PACT_REQUEST_LOGISTIC_CONFIG:
      return { ...state, smartcontractIsLoading: true };

    case UPDATE_PACT_SUCCEEDED_LOGISTIC_CONFIG:
      return {
        ...state,
        smartcontractError: null,
        smartcontract: [
          ...state.smartcontract.filter(todo => todo.id !== action.todo.id),
          { ...action.todo, editStatusSmartContract: false },
        ].sort((a, b) => a.id - b.id),
        smartcontractIsLoading: false,
      };

    case UPDATE_PACT_FAILED_LOGISTIC_CONFIG:
      return {
        ...state,
        smartcontractError: action.error,
        smartcontractIsLoading: false,
      };

    //--------------------------------------------------------------------------------------------

    case TOGGLE_PACT_STATE_REQUEST_LOGISTIC_CONFIG:
      return { ...state, smartcontractIsLoading: true };

    case TOGGLE_PACT_STATE_SUCCEEDED_LOGISTIC_CONFIG:
      workingSmartContract = state.smartcontract.find(todo => todo.id === action.id);
      workingSmartContract.state = action.state;
      return {
        ...state,
        smartcontract: [...state.smartcontract.filter(todo => todo.id !== action.id), workingSmartContract].sort(
          (a, b) => a.id - b.id
        ),
        smartcontractError: null,
        smartcontractIsLoading: false,
      };

    case TOGGLE_PACT_STATE_FAILED_LOGISTIC_CONFIG:
      return {
        ...state,
        smartcontractError: action.error,
        smartcontractIsLoading: false,
      };

    //--------------------------------------------------------------------------------------------

    case SAVE_NEW_PACT_REQUEST_MAINTENANCE_CONFIG:
      return { ...state, smartcontractIsLoading: true };

    case SAVE_NEW_PACT_REQUEST_MAINTENANCE_RULES:
      return { ...state, smartcontractRulesIsLoading: true };

    case SAVE_NEW_PACT_SUCCEEDED_MAINTENANCE_CONFIG:
      return {
        ...state,
        smartcontractError: null,
        smartcontract: [...state.smartcontract, action.newSmartContract].sort((a, b) => a.id - b.id),
        smartcontractIsLoading: false,
        newTodo: '',
      };

    case SAVE_NEW_PACT_SUCCEEDED_MAINTENANCE_RULES:
        return {
          ...state,
          smartcontractRulesError: null,
          smartcontractRules: [...state.smartcontractRules, action.newSmartContractRules].sort((a, b) => a.id - b.id),
          smartcontractRulesIsLoading: false,
          newTodo: '',
        };

    case SAVE_NEW_PACT_FAILED_MAINTENANCE_CONFIG:
          return {
            ...state,
            smartcontractError: action.error,
            smartcontractIsLoading: false,
          };

    case SAVE_NEW_PACT_FAILED_MAINTENANCE_RULES:
          return {
            ...state,
            smartcontractRulesError: action.error,
            smartcontractRulesIsLoading: false,
          };

    //--------------------------------------------------------------------------------------------

    case SAVE_NEW_PACT_REQUEST_PROFORMA_CONFIG:
      return { ...state, smartcontractIsLoading: true };

    case SAVE_NEW_PACT_SUCCEEDED_PROFORMA_CONFIG:
      return {
        ...state,
        smartcontractError: null,
        smartcontract: [...state.smartcontract, action.newSmartContract].sort((a, b) => a.id - b.id),
        smartcontractIsLoading: false,
        newTodo: '',
      };

    case SAVE_NEW_PACT_FAILED_PROFORMA_CONFIG:
          return {
            ...state,
            smartcontractError: action.error,
            smartcontractIsLoading: false,
          };

    case SAVE_NEW_PACT_REQUEST_PROFORMA_RULES:
      return { ...state, smartcontractRulesIsLoading: true };

    case SAVE_NEW_PACT_SUCCEEDED_PROFORMA_RULES:
        return {
          ...state,
          smartcontractRulesError: null,
          smartcontractRules: [...state.smartcontractRules, action.newSmartContractRules].sort((a, b) => a.id - b.id),
          smartcontractRulesIsLoading: false,
          newTodo: '',
        };

    case SAVE_NEW_PACT_FAILED_PROFORMA_RULES:
          return {
            ...state,
            smartcontractRulesError: action.error,
            smartcontractRulesIsLoading: false,
          };

    //--------------------------------------------------------------------------------------------

    default:
      return state;
  }
}
