import React, { Component } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import MyDocument from "./GeneratePdf";
import MyDocumentUploaded from "./GeneratePdfUploaded";
import CertificatePaymentMade from "./GeneratePdfCertificatePaymentMade";

// blockstack
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

import {username_new} from '../../actions/userActions'

//Axios
import axios from 'axios';

//Zonefile (Gaia)
import { parseZoneFile } from 'zone-file'

//UUID
import uuid from 'uuid';

//Configuración
import { MAINNET_STACKS_API_URL,
         TESTNET_STACKS_API_URL } from '../../config.js'

//Spinning
import ContractLoader from "../contracts/ContractLoader";

//Clarity
import {getTransactionRegister} from "../../clarity/clarityfunctions"
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

import './ViewerPdf.css'

class ViewerPdf extends Component {

    constructor(props) {
        super(props);

        this.goSignList = this.goSignList.bind(this);
        this.goSignListRemoto = this.goSignListRemoto.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user:{},
          jsonBlockstackY: [],
          networkUrlX: '',
          trimmedDataURL: null,
          jsonBlockstackFormPago: [],
          originMoney: 'USD - US Dollar',
          symbolcurrency: 'USD',
          jsonHeadPaymentForm: [],
          cryptoCurrency: 'STX',
          totaldocumento: '',
          saldodocumento: null,
          uuidX: uuid(),
          endPutFile: false,
          getTransaction: '',
          fullRead: false,
        }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      let networkUrlX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
           networkUrlX = MAINNET_STACKS_API_URL
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
           networkUrlX = TESTNET_STACKS_API_URL
        }
      }
      this.setState({networkUrlX })

      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val},()=>{
          Promise.all([
                       this.goSignList(this.props.typeContract,this.props.numberContract,this.props.userRole,this.props.userOrigin,networkUrlX,val),
                       this.getHeadPaymentForms(val),
                       this.getDetailPaymentForms(val),
                       this.getContract(val)
                     ])
            .then((resolve) => {this.setState({fullRead:true})},(reject) =>{this.setState({fullRead:true})})
        })) ;
      }else{
        this.setState({ username },()=>{
          Promise.all([
                       this.goSignList(this.props.typeContract,this.props.numberContract,this.props.userRole,this.props.userOrigin,networkUrlX,username),
                       this.getHeadPaymentForms(username),
                       this.getDetailPaymentForms(username),
                       this.getContract(username)
                     ])
            .then((resolve) => {this.setState({fullRead:true})},(reject) =>{this.setState({fullRead:true})})
        })
      }
    }

    //-----------------------------------------------------------------------

    goSignList(typeContract,numberContract,displayRole,userOrigin,networkUrlX,username) {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { username: userOrigin, decrypt: false, verify: false }
      this.setState({jsonBlockstackY: []})
      return new Promise ((resolve, reject) =>{
        getFileDB(username,`${typeContract}_${numberContract}_usergroup.json`)
           .then((fileContents) => {
             if(fileContents) {
               const jsonBlockstack1 = JSON.parse(fileContents)
               const jsonBlockstack2 = jsonBlockstack1.data
               let jsonBlockstack5 = '[]'
               if (jsonBlockstack2 !== null){
                  const jsonBlockstack3 = jsonBlockstack2.dataobject
                  const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                  if (jsonBlockstack4.substring(0,1) === '"') {
                     jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                  } else {
                     jsonBlockstack5 = jsonBlockstack4
                  }
               }
               let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
               this.setState({jsonBlockstackY: jsonBlockstack6})
               //-----------------------------------------------------------------------------
               this.goSignListRemoto(typeContract,numberContract,jsonBlockstack6,networkUrlX)
               //-----------------------------------------------------------------------------
               resolve()
             }
           })
            .catch(error => {
              resolve()
            });
        })
    }

    //-----------------------------------------------------------------------

    goSignListRemoto(typeContract,configurationContractNumber,jsonBlockstack4c,networkUrlX){
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const largo = jsonBlockstack4c.length
      return new Promise ((resolve2, reject2) =>{
        jsonBlockstack4c.map((todoUserRemote,keyUserRemote)=>{
          let options = ''
          if ((todoUserRemote.role === 'Creator' || todoUserRemote.role === 'Authorizer' || todoUserRemote.role === 'Authorizer-Inviter')){
            options = { username: todoUserRemote.id, decrypt: false, verify: false }
            let jsonBlockstack4d = []
            new Promise ((resolve3, reject3) =>{
                getFileDB(todoUserRemote.id, `${typeContract}_${configurationContractNumber}_usergroup.json`)
                 .then((fileContents) => {
                   if(fileContents) {
                        const jsonBlockstack1 = JSON.parse(fileContents)
                        const jsonBlockstack2 = jsonBlockstack1.data
                        let jsonBlockstack5 = '[]'
                        if (jsonBlockstack2 !== null){
                           const jsonBlockstack3 = jsonBlockstack2.dataobject
                           const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                           if (jsonBlockstack4.substring(0,1) === '"') {
                              jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                           } else {
                              jsonBlockstack5 = jsonBlockstack4
                           }
                        }
                        let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                        jsonBlockstack4d = JSON.parse(jsonBlockstack5)
                        const {signature} = jsonBlockstack4d[0]
                        if (todoUserRemote.signature !== signature){
                           todoUserRemote.signature = signature
                        }
                        //-----------------------------------------------------------------------------
                        this.getSignature(todoUserRemote.id,jsonBlockstack4c,keyUserRemote,networkUrlX)
                        //-----------------------------------------------------------------------------
                   }
                 })
                 .catch(error => {
                 });
            })
          }
          if (keyUserRemote === largo - 1){
            this.setState({jsonBlockstackY: jsonBlockstack4c})
            resolve2()
          }
        })
      })
    }

    getSignature = (userX,jsonBlockstack4c,keyUserRemote,networkUrlX) => {
      const {userSession} = this.state
      const storage = new Storage({ userSession });
      const options = { username: userX, decrypt: false, verify: false }
      this.setState({trimmedDataURL: null})
      return new Promise((resolve4, reject4) => {
          getFileDB(userX, `signature.json`)
             .then((fileContents) => {
               if(fileContents) {
                   const jsonBlockstack1 = JSON.parse(fileContents)
                   const jsonBlockstack2 = jsonBlockstack1.data
                   let jsonBlockstack5 = '[]'
                   if (jsonBlockstack2 !== null){
                      const jsonBlockstack3 = jsonBlockstack2.dataobject
                      const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                      if (jsonBlockstack4.substring(0,1) === '"') {
                         jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                      } else {
                         jsonBlockstack5 = jsonBlockstack4
                      }
                   }
                   let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                   jsonBlockstack4c.map((todoUserRemote2,keyUserRemote2)=>{
                   if (keyUserRemote2 === keyUserRemote){
                     todoUserRemote2.signpad = jsonBlockstack6.signature
                     todoUserRemote2.bsignpad = true
                     this.setState({trimmedDataURL: jsonBlockstack6.signature})
                   }
                 })
                 resolve4()
               } else {
                 resolve4()
               }
             })
              .catch(error => {
                resolve4()
              });
      });
    }

    getDetailPaymentForms = (userX) => {
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = { username: userX, decrypt: false, verify: false }
        this.setState({trimmedDataURL: null})
        return new Promise((resolve6, reject6) => {
            getFileDB(this.props.userOrigin, `${this.props.typeContract}_${this.props.numberContract}_detail_payment_form.json`)
               .then((fileContents) => {
                 if(fileContents) {
                     const jsonBlockstack1 = JSON.parse(fileContents)
                     const jsonBlockstack2 = jsonBlockstack1.data
                     let jsonBlockstack5 = '[]'
                     if (jsonBlockstack2 !== null){
                        const jsonBlockstack3 = jsonBlockstack2.dataobject
                        const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                        if (jsonBlockstack4.substring(0,1) === '"') {
                           jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                        } else {
                           jsonBlockstack5 = jsonBlockstack4
                        }
                     }
                     let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                     this.setState({jsonBlockstackFormPago: jsonBlockstack6})
                     resolve6()
                 } else {
                   resolve6()
                 }
               })
                .catch(error => {
                  resolve6()
               });
        });
    }

    getHeadPaymentForms = (userX) => {
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = { username: userX, decrypt: false, verify: false }
        this.setState({trimmedDataURL: null})
        return new Promise((resolve7, reject7) => {
            getFileDB(this.props.userOrigin, `${this.props.typeContract}_${this.props.numberContract}_head_payment_form.json`)
               .then((fileContents) => {
                 if(fileContents) {
                     const jsonBlockstack1 = JSON.parse(fileContents)
                     const jsonBlockstack2 = jsonBlockstack1.data
                     let jsonBlockstack5 = '[]'
                     if (jsonBlockstack2 !== null){
                        const jsonBlockstack3 = jsonBlockstack2.dataobject
                        const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                        if (jsonBlockstack4.substring(0,1) === '"') {
                           jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                        } else {
                           jsonBlockstack5 = jsonBlockstack4
                        }
                     }
                     let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                     this.setState({jsonHeadPaymentForm:jsonBlockstack6});
                     this.setState({cryptoCurrency:jsonBlockstack6[0].symbolcryptocurrency, originMoney:jsonBlockstack6[0].originMoney,  symbolcurrency:jsonBlockstack6[0].symbolcurrency, saldodocumento: parseFloat(jsonBlockstack6[0].amount), totaldocumento: jsonBlockstack6[0].amount})
                     resolve7()
                 } else {
                   resolve7()
                 }
               })
                .catch(error => {
                  resolve7()
               });
        });
    }

    getContract = (userX) => {
        const {userSession} = this.state
        const storage = new Storage({ userSession });
        const options = { username: userX, decrypt: false, verify: false }
        this.setState({trimmedDataURL: null})
        return new Promise((resolve8, reject8) => {
            getFileDB(this.props.userOrigin, `${this.props.typeContract}_${this.props.numberContract}.json`)
               .then((fileContents) => {
                 if(fileContents) {
                     const jsonBlockstack1 = JSON.parse(fileContents)
                     const jsonBlockstack2 = jsonBlockstack1.data
                     let jsonBlockstack5 = '[]'
                     if (jsonBlockstack2 !== null){
                        const jsonBlockstack3 = jsonBlockstack2.dataobject
                        const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                        if (jsonBlockstack4.substring(0,1) === '"') {
                           jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                        } else {
                           jsonBlockstack5 = jsonBlockstack4
                        }
                     }
                     let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
                     const txId = jsonBlockstack6.registerTxId
                     if (txId !== undefined){
                       if (txId !== null && txId !== '' ) {
                         getTransactionRegister(this.state.networkUrlX, txId)
                           .then(val => {
                               this.setState({ endPutFile: true, getTransaction: val })
                           })
                       }
                     }
                     resolve8()
                 } else {
                   resolve8()
                 }
               })
                .catch(error => {
                  resolve8()
               });
        });
    }

    render() {
      let typeContractBlank = false
      let typeContractUploaded = false
      if (this.state.fullRead === true){
        if (this.props.typeContract === 'blank') {
          typeContractBlank = true
        }
        if (this.props.typeContract === 'uploaded') {
          typeContractUploaded = true
        }
      }
      return (
        <div>
          {this.state.endPutFile ?
            <>
              {this.props.displayCertificatePaymentsMade ?
                <>
                  <PDFViewer width="1000" height="1200" className="app">
                    <CertificatePaymentMade contentState={this.props.contentState}
                                typeContract={this.props.typeContract}
                                numberContract={this.props.numberContract}
                                jsonBlockstackY={this.state.jsonBlockstackY}
                                description={this.props.description}
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                trimmedDataURL={this.state.trimmedDataURL}
                                jsonBlockstackFormPago={this.state.jsonBlockstackFormPago}
                                totaldocumento={this.state.totaldocumento}
                                originMoney={this.state.originMoney}
                                cryptoCurrency={this.state.cryptoCurrency}
                                symbolcurrency={this.state.symbolcurrency}
                                uuidX={this.state.uuidX}
                                usernameX={this.state.username}
                                userOrigin={this.props.userOrigin}
                                whatUserRole={this.props.whatUserRole}
                                userRole={this.props.whatUserRole}
                                colorDark={this.props.colorDark}
                                getTransaction={this.state.getTransaction} />
                  </PDFViewer>
                </>
              :
                <>
                  {typeContractBlank ?
                    <PDFViewer width="1000" height="1200" className="app">
                      <MyDocument contentState={this.props.contentState}
                                  typeContract={this.props.typeContract}
                                  numberContract={this.props.numberContract}
                                  jsonBlockstackY={this.state.jsonBlockstackY}
                                  description={this.props.description}
                                  startDate={this.props.startDate}
                                  endDate={this.props.endDate}
                                  trimmedDataURL={this.state.trimmedDataURL}
                                  jsonBlockstackFormPago={this.state.jsonBlockstackFormPago}
                                  totaldocumento={this.state.totaldocumento}
                                  originMoney={this.state.originMoney}
                                  cryptoCurrency={this.state.cryptoCurrency}
                                  symbolcurrency={this.state.symbolcurrency}
                                  uuidX={this.state.uuidX}
                                  usernameX={this.state.username}
                                  userOrigin={this.props.userOrigin}
                                  whatUserRole={this.props.whatUserRole}
                                  userRole={this.props.whatUserRole}
                                  colorDark={this.props.colorDark}
                                  getTransaction={this.state.getTransaction} />
                    </PDFViewer>
                  :
                    null
                  }
                  {typeContractUploaded ?
                      <MyDocumentUploaded filedecodeAttachX={this.props.filedecodeAttachX}
                                  typeContract={this.props.typeContract}
                                  numberContract={this.props.numberContract}
                                  jsonBlockstackY={this.state.jsonBlockstackY}
                                  description={this.props.description}
                                  startDate={this.props.startDate}
                                  endDate={this.props.endDate}
                                  trimmedDataURL={this.state.trimmedDataURL}
                                  jsonBlockstackFormPago={this.state.jsonBlockstackFormPago}
                                  totaldocumento={this.state.totaldocumento}
                                  originMoney={this.state.originMoney}
                                  cryptoCurrency={this.state.cryptoCurrency}
                                  symbolcurrency={this.state.symbolcurrency}
                                  uuidX={this.state.uuidX}
                                  usernameX={this.state.username}
                                  userOrigin={this.props.userOrigin}
                                  whatUserRole={this.props.whatUserRole}
                                  userRole={this.props.whatUserRole}
                                  colorDark={this.props.colorDark}
                                  getTransaction={this.state.getTransaction} />
                  :
                    null
                  }
                </>
              }
            </>
          :
            <ContractLoader colorDark={this.props.colorDark}/>
          }
        </div>
      )
   }
}
export default ViewerPdf;
