import { AGREGAR_CONTRACT, MOSTRAR_CONTRACT, MODIFICAR_CONTRACT, ELIMINAR_CONTRACT } from './types';

export const mostrarContract = () => {
    return {
        type: MOSTRAR_CONTRACT
    };
};
export const eliminarContract = (id) => {
    return {
        type: ELIMINAR_CONTRACT,
        payload: id
    };
};

export const modificarContract = (id) => {
    return {
        type: MODIFICAR_CONTRACT,
        payload: id
    };
};

export const agregarContract = (contract) => {
    return {
        type: AGREGAR_CONTRACT,
        payload: contract
    };
};
