import React from "react";
import { Nav, Button } from "shards-react";
import {  Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';

import SidebarNavItem from "./SidebarNavItem";

//translate
import { FormattedMessage } from 'react-intl';

function ControlVersion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"><FormattedMessage id="version.history" /></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">

                    <p><FormattedMessage id="version.032" /></p>
                    <ul>
                      <li type="circle"><FormattedMessage id="version.033" /></li>
                      <li type="circle"><FormattedMessage id="version.034" /></li>
                    </ul>
                    <p><FormattedMessage id="version.029" /></p>
                    <ul>
                      <li type="circle"><FormattedMessage id="version.030" /></li>
                      <li type="circle"><FormattedMessage id="version.031" /></li>
                    </ul>
                    <p><FormattedMessage id="version.025" /></p>
                    <ul>
                      <li type="circle"><FormattedMessage id="version.026" /></li>
                      <li type="circle"><FormattedMessage id="version.027" /></li>
                      <li type="circle"><FormattedMessage id="version.028" /></li>
                    </ul>
                    <p><FormattedMessage id="version.023" /></p>
                    <ul>
                      <li type="circle"><FormattedMessage id="version.024" /></li>
                    </ul>
                    <p><FormattedMessage id="version.021" /></p>
                    <ul>
                      <li type="circle"><FormattedMessage id="version.022" /></li>
                    </ul>
                    <p><FormattedMessage id="version.019" /></p>
                    <ul>
                      <li type="circle"><FormattedMessage id="version.020" /></li>
                    </ul>
                    <p><FormattedMessage id="version.017" /></p>
                    <ul>
                      <li type="circle"><FormattedMessage id="version.018" /></li>
                    </ul>
                    <p><FormattedMessage id="version.001" /></p>
                    <ul>
                      <li type="circle"><FormattedMessage id="version.002" /></li>
                      <li type="circle"><FormattedMessage id="version.003" /></li>
                      <li type="circle"><FormattedMessage id="version.004" /></li>
                    </ul>
                    <p><FormattedMessage id="version.005" /></p>
                    <ul>
                      <li type="circle"><FormattedMessage id="version.006" /></li>
                      <li type="circle"><FormattedMessage id="version.007" /></li>
                      <li type="circle"><FormattedMessage id="version.008" /></li>
                    </ul>
                    <p><FormattedMessage id="version.009" /></p>
                    <ul>
                      <li type="circle"><FormattedMessage id="version.010" /></li>
                      <li type="circle"><FormattedMessage id="version.011" /></li>
                      <li type="circle"><FormattedMessage id="version.012" /></li>
                    </ul>
                    <p><FormattedMessage id="version.013" /></p>
                    <ul>
                      <li type="circle"><FormattedMessage id="version.014" /></li>
                      <li type="circle"><FormattedMessage id="version.015" /></li>
                      <li type="circle"><FormattedMessage id="version.016" /></li>
                    </ul>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'return')} theme="success">
                    <FormattedMessage id="simulator.return" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      language: 'English',
      dialogOpen: false,
      dialogMessage: '',
      dialogTitle: 'Version History',
    };
    this.toggle = this.toggle.bind(this);
  }

  UNSAFE_componentWillMount() {
    const languageX = localStorage.getItem('language')
    if (languageX === undefined || languageX === ''){
      this.setState({ language: 'English' })
    }else{
      this.setState({ language: languageX })
    }
  }

  toggle() {
    this.setState({dialogOpen: !this.state.dialogOpen},() =>{this.load();});
  }

  load = () => {
  }

/*
<br></br>
<div className="text-center">Support us on Product Hunt</div>
<br></br>
<a href="https://www.producthunt.com/posts/paradigma-crosscheck?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-paradigma-crosscheck"
   target="_blank" rel="noopener noreferrer">
   <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=153263&theme=light"
        alt="CrossCheck by Paradigma - Smart contracts to cross-check virtually any event | Product Hunt Embed"
        style={{width:"200px", height:"50px"}}
        width="200px"
        height="50px"
   />
</a>

<div className="text-center" style={{color: "#ffffff", fontSize: "1.2rem"}}>
    <a href="https://www.facebook.com/Paradigma-CrossCheck-1087028231484475/"><i className="fab fa-facebook" aria-hidden="true"> </i>  </a>
    &nbsp;&nbsp;&nbsp;
    <a href="https://twitter.com/checkparadigma"><i className="fab fa-twitter" aria-hidden="true"></i> </a>
    &nbsp;&nbsp;&nbsp;
    <a href="https://www.youtube.com/channel/UCmb9qMswCHruc3IpNG-Pjcw"><i className="fab fa-youtube" aria-hidden="true"></i> </a>
</div>

*/

  render() {
    const {items} = this.props
    const {menuVisibleX} = this.props
    return (
      <>
        <ControlVersion
            handleDialog={this.toggle}
            dialogMessage={this.state.dialogMessage}
            dialogOpen={this.state.dialogOpen}
            dialogTitle={this.state.dialogTitle}
        />
        <div className="nav-wrapper">
          <Nav className="nav--no-borders flex-column">
            {items.map((item, idx) => (
              <SidebarNavItem key={idx} item={item} menuVisibleX= {menuVisibleX }/>
            ))}
          </Nav>
          {menuVisibleX ?
            <div style={{position:"absolute", bottom:0, left:50, marginLeft:20, color:"#888686", fontSize:11}}>
              <br></br>
              <strong className="text-center" style={ this.props.colorDark ? { color: "grey", cursor: 'pointer'} : {color: "blue", cursor: 'pointer'}} onClick={this.toggle}>Version beta 0.9.2</strong>
              <br></br>
            </div>
          :
            null
          }
        </div>
      </>
    )
  }
}

export default SidebarNavItems;
