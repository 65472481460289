import React from "react";
import { Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { Table } from 'reactstrap';

class DetailDataReceived extends React.Component {

  render() {
    const {dataJson} = this.props
    const {data} = dataJson
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Detail Data Received</h6>
        </CardHeader>
        <CardBody className="pt-0">
          <Row className="border-bottom py-2 bg-light">
            <Col sm="12" className="d-flex mb-2 mb-sm-0">
            <Table size="sm">
               <thead>
                 <tr>
                   <th>Data</th>
                 </tr>
               </thead>
               <tbody>
                 {data.map((todo, i) => (
                     <tr key={i}><td>{todo.json}</td></tr>
                 ))}
               </tbody>
             </Table>
            </Col>

          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default DetailDataReceived;
