//React
import React, { Component } from 'react';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit, faEye, faDownload } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Container, Button, Card, FormCheckbox } from "shards-react";
import { Table } from 'reactstrap';
import { Divider, Typography, TextField, Grid, Dialog,
        DialogContent, DialogActions, DialogTitle, DialogContentText,
        Button as ButtonMaterialUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';

import TituloPagina2 from "../common/TituloPagina2";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'
import { writeFile, readFile } from "blockstack-large-storage";

//Uploader
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

//translate
import { FormattedMessage } from 'react-intl';

//base64
import { Base64 } from 'js-base64';

//adblocker
//import AdBlockDetect from 'react-ad-block-detect';

//downloader
import { triggerBase64Download } from 'react-base64-downloader';
import { FileSaver } from 'file-saver';

import {username_new} from '../../actions/userActions'

function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionError(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class NewMasterFileContractUpload extends Component {

    constructor(props) {
        super(props);

        this.getUploadParams = this.getUploadParams.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.exitProgram = this.exitProgram.bind(this);
        this.lenghtFileError = this.lenghtFileError.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          user: {},
          displayAttach: false,
          jsonAttachFile: [],
          jsonAttachFile1: [],
          jsonAttachFile2: [],
          language: 'English',
          seeAttachX: false,
          downloadAttachX: false,
          fileAttachX: '',
          filedecodeAttachX: '',
          typeAttachX: '',
          nameAttachX: '',
          numPages: null,
          pageNumber: 1,
          dialogMessage: '',
          dialogOpen: false,
          dialogOpenError: false,
          dialogTitle: '',
          jsonAttachFileX: [],
          jsonAttachFileXY: '',
          typeAttachText: false,
          typeAttachImage: false,
          typeAttachAudio: false,
          typeAttachVideo: false,
          typeAttachPdf: false,
        }
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      const languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
      }else{
        this.setState({ language: languageX })
      }
    }

    returnProps(){
      this.setState({seeAttachX:false, downloadAttachX:false})
    }

    handleDialog = (type, action) => {
       if (type === 'Error: 1001') {
          if (action === 'cancel') {
           this.setState({ dialogOpenError: false });
          }
       }
    }

    lenghtFileError() {
      if (this.state.language === 'English'){
        this.setState({ dialogOpenError: true,  dialogMessage: 'Long file exceeds the maximum allowed',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})
      }
      if (this.state.language === 'French'){
        this.setState({ dialogOpenError: true,  dialogMessage: 'Le fichier long dépasse le maximum autorisé',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})
      }
      if (this.state.language === 'Spanish'){
        this.setState({ dialogOpenError: true,  dialogMessage: 'Largo archivo supera el máximo permitido',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})
      }
      if (this.state.language === 'Portuguese'){
        this.setState({ dialogOpenError: true,  dialogMessage: 'O arquivo longo excede o máximo permitido',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})
      }
      if (this.state.language === 'Swedish'){
        this.setState({ dialogOpenError: true,  dialogMessage: 'Lång fil överstiger det maximalt tillåtna',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})
      }
      if (this.state.language === 'Netherlands'){
        this.setState({ dialogOpenError: true,  dialogMessage: 'Lang bestand overschrijdt het toegestane maximum',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})
      }
      if (this.state.language === 'Russian'){
        this.setState({ dialogOpenError: true,  dialogMessage: 'Длина файла превышает максимально допустимый',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})
      }
      if (this.state.language === 'Japanese'){
        this.setState({ dialogOpenError: true,  dialogMessage: '長いファイルが許可されている最大値を超えています',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})
      }
      if (this.state.language === 'Chinese'){
        this.setState({ dialogOpenError: true,  dialogMessage: '长文件超过了允许的最大值',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})
      }
      if (this.state.language === 'German'){
        this.setState({ dialogOpenError: true,  dialogMessage: 'Lange Datei überschreitet die maximal zulässige',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})
      }
      if (this.state.language === 'Italian'){
        this.setState({ dialogOpenError: true,  dialogMessage: 'Il file lungo supera il massimo consentito',dialogTitle: 'Error: 1001',seeAttachX: false,downloadAttachX: false,})
      }
    }

    getUploadParams = () => {
        return { url: 'https://httpbin.org/post' }
    }

    handleChangeStatus = ({ meta }, status) => {
        //console.log(status, meta)
    }


    handleSubmit  = (files, allFiles) => {
        const { userSession } = this.state
        const options = { encrypt: false }
        let jsonAttachFile = []
        let jsonAttachFileX= []
        let jsonBlockstackAttach = {}
        let jsonBlockstackAttach2 = {}
        let status = 'Uploaded'
        let fileX = ''
        let file64 = ''
        files.map((todo,i)=>{
          jsonBlockstackAttach = files[i].meta
          jsonBlockstackAttach2 = files[i].xhr.response
          jsonBlockstackAttach2 = JSON.parse(jsonBlockstackAttach2)
            let {file} = jsonBlockstackAttach2.files
            fileX = file
            file64 = Base64.encode(file);
            this.setState({filedecodeAttachX: file, fileAttachX: file64, typeAttachX: '', nameAttachX: '' })
        })
        this.setState({seeAttachX: true, typeAttachText: false, typeAttachImage: false, typeAttachPdf: true, typeAttachAudio: false, typeAttachVideo: false })
        allFiles.forEach(f => f.remove())
        this.exitProgram(fileX, file64)
    }

    exitProgram(file, file64){
       this.props.returnUploadContract(file, file64);
    }

    render() {
          const displayFullChargeX = true
          let correlativo = 0
          let activate = false
          let titleX = ''
          let inputContentX = ''
          let submitButtonContentX = ''
          let inputWithFilesContentX = ''
          if (this.state.language === 'English'){
            titleX = "Attach file using your private and secure storage"
            inputContentX = 'Drag File or Click to Browse'
            submitButtonContentX = 'Submit'
            inputWithFilesContentX = ''
          }
          if (this.state.language === 'French'){
            titleX = "Joignez un fichier à l'aide de votre stockage privé et sécurisé"
            inputContentX = 'Faites glisser le fichier ou cliquez pour parcourir'
            submitButtonContentX = 'Soumettre'
            inputWithFilesContentX = ''
          }
          if (this.state.language === 'Spanish'){
            titleX = "Adjunte archivos usando su almacenamiento privado y seguro"
            inputContentX = 'Arrastre archivo o haga clic para examinar'
            submitButtonContentX = 'Subir'
            inputWithFilesContentX = ''
          }
          if (this.state.language === 'Portuguese'){
            titleX = "Anexar arquivo usando seu armazenamento privado e seguro"
            inputContentX = 'Arraste arquivo ou clique para procurar'
            submitButtonContentX = 'Enviar'
            inputWithFilesContentX = ''
          }
          if (this.state.language === 'Swedish'){
            titleX = "Bifoga filen med din privata och säkra lagring"
            inputContentX = 'Dra fil eller klicka för att bläddra'
            submitButtonContentX = 'Lämna'
            inputWithFilesContentX = ''
          }
          if (this.state.language === 'Netherlands'){
            titleX = "Voeg een bestand toe met uw privé en beveiligde opslag"
            inputContentX = 'Sleep het bestand of klik om te bladeren'
            submitButtonContentX = 'Voorleggen'
            inputWithFilesContentX = ''
          }
          if (this.state.language === 'Russian'){
            titleX = "Прикрепите файл, используя ваше личное и безопасное хранилище"
            inputContentX = 'Перетащите файл или нажмите, чтобы просмотреть'
            submitButtonContentX = 'представить'
            inputWithFilesContentX = ''
          }
          if (this.state.language === 'Japanese'){
            titleX = "プライベートで安全なストレージを使用してファイルを添付"
            inputContentX = 'ファイルをドラッグまたはクリックして参照'
            submitButtonContentX = '提出する'
            inputWithFilesContentX = ''
          }
          if (this.state.language === 'Chinese'){
            titleX = "使用私人和安全存储附加文件"
            inputContentX = '拖动文件或单击浏览'
            submitButtonContentX = '提交'
            inputWithFilesContentX = ''
          }
          if (this.state.language === 'German'){
            titleX = "Hängen Sie die Datei unter Verwendung Ihres privaten und sicheren Speichers an"
            inputContentX = 'Ziehen Sie die Datei oder klicken Sie zum Durchsuchen'
            submitButtonContentX = 'Einreichen'
            inputWithFilesContentX = ''
          }
          if (this.state.language === 'Italian'){
            titleX = "Allega file usando il tuo archivio privato e sicuro"
            inputContentX = 'Trascina il file o fai clic per sfogliare'
            submitButtonContentX = 'Sottoscrivi'
            inputWithFilesContentX = ''
          }

          const { filedecodeAttachX, fileAttachX, typeAttachX, nameAttachX } = this.state;
          const { typeAttachText, typeAttachImage, typeAttachPdf, typeAttachAudio, typeAttachVideo } = this.state;
          const scale = 1

          let mimeTypeX = "image/jpeg,image/jpg,image/png,image/gif"
          if (this.props.mimeTypeX === 'text/plain') {
             mimeTypeX = 'text/plain'
          }

          return (
            <div>
              <Container fluid className="main-content-container px-4"
              style={ this.props.colorDark ? { backgroundColor:'#0b151d'} : {}}>

                  <Confirmacion
                      handleDialog={this.handleDialog}
                      dialogMessage={this.state.dialogMessage}
                      dialogOpen={this.state.dialogOpen}
                      dialogTitle={this.state.dialogTitle}
                  />

                  <ConfirmacionError
                      handleDialog={this.handleDialog}
                      dialogMessage={this.state.dialogMessage}
                      dialogOpen={this.state.dialogOpenError}
                      dialogTitle={this.state.dialogTitle}
                  />
                  <Row form style={{fontSize:13}}>
                     <Col md="12">
                       <Dropzone
                          getUploadParams={this.getUploadParams}
                          onChangeStatus={this.handleChangeStatus}
                          onSubmit={this.handleSubmit}
                          styles={{ dropzone: { minHeight: 100, maxHeight: 250 } }}
                          inputContent={inputContentX}
                          submitButtonContent={submitButtonContentX}
                          inputWithFilesContent={inputWithFilesContentX}
                          multiple={false}
                          maxFilesize={256}
                          forceFallback={true}
                          disablePreviews={true}
                          previewsContainer={null}
                          addRemoveLinks={true}
                          accept={mimeTypeX}
                        />
                     </Col>
                  </Row>
                  <br></br>
                </Container>
            </div>
        );
     }
}

export default NewMasterFileContractUpload;
