//React
import React, { Component } from 'react';

//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Form, Card, Container, FormSelect, Button, FormInput, InputGroupAddon, InputGroupText, InputGroup, DatePicker } from "shards-react";

import { Table } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";
import { Divider, Typography, TextField, Grid, Dialog,
         DialogContent, DialogActions, DialogTitle, DialogContentText,
         Button as ButtonMaterialUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';

//Number Format
import NumberFormat from 'react-number-format';

//Tittle
import TituloPagina2 from "../common/TituloPagina2";

// blockstack
//import { UserSession } from 'blockstack';
import { UserSession } from '@stacks/auth';
import { Storage } from '@stacks/storage'

//Axios
import axios from 'axios';

//translate
import { FormattedMessage } from 'react-intl';

//Configuración
import {token} from '../../config.js'

//
import {CopyToClipboard} from 'react-copy-to-clipboard';

//components
import ConceptManagement from './ConceptManagement';

//Currency
import currencyPayment from '../../utils/currency-payment';
import currencyFIATPayment from '../../utils/currency-fiat-payment';

//
import {username_new} from '../../actions/userActions'
import {getFileDB, putFileDB} from '../../actions/mongoDBActions'

function Confirmacion(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="danger" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionWarning(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'accept')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmacionAccept(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('accept')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'accept')} theme="warning" autoFocus>
                   <FormattedMessage id="alert.accept" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Confirmacion2(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'confirm')} theme="danger">
                    <FormattedMessage id="alert.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Confirmacion3(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialog('cancel')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'cancel')} theme="warning" autoFocus>
                    <FormattedMessage id="alert.cancel" />
                </Button>
                <Button onClick={() => props.handleDialog(props.dialogTitle, 'continue')} theme="success">
                    <FormattedMessage id="alert.continue" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

class ContractPaymentForm extends Component {

    constructor(props) {
        super(props);

        this.exitProgram = this.exitProgram.bind(this);
        this.handleChangeCryptoCurrency = this.handleChangeCryptoCurrency.bind(this);
        this.handleChangeNumber = this.handleChangeNumber.bind(this);
        this.handleBlurNumber = this.handleBlurNumber.bind(this);
        this.handleInputNumber = this.handleInputNumber.bind(this);
        this.handleChangeConceptPaymentForm = this.handleChangeConceptPaymentForm.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        this.newPaymentForm = this.newPaymentForm.bind(this);
        this.deletePaymentForm = this.deletePaymentForm.bind(this);
        this.editPaymentForm = this.editPaymentForm.bind(this);
        this.faltaDataConfirm = this.faltaDataConfirm.bind(this);
        this.faltaDataConfirm2 = this.faltaDataConfirm2.bind(this);
        this.totalPorcentajeConfirm = this.totalPorcentajeConfirm.bind(this);
        this.getHeadPaymentForms = this.getHeadPaymentForms.bind(this);
        this.getDetailPaymentForms = this.getDetailPaymentForms.bind(this);

        this.state = {
          userSession: new UserSession(),
          username: '',
          name: '',
          user: {},
          modifying: false,
          jsonConceptPaymentForm: [],
          jsonPaymentForm: [],
          jsonHeadPaymentForm: [],
          estimatedDate: new Date(),
          amount: 0,
          error: false,
          indice: 0,
          isocode: 'en',
          language: 'English',
          totalpercent: 0,
          totalAmount: 0,
          dateFormatX: 'dd/MM/yyyy',
          displayConceptManagement: false,
          displayPaymentForm: true,
          cryptoCurrencyX: '',
          symbolCriptoCurrency: '',
          symbolCurrencyX: '',
          typeCurrency2: 'Crypto Currency',
        }
    }

    c01CryptoCurrency = React.createRef();
    c01TypeCurrency = React.createRef();
    c02Amount = React.createRef();
    c03ConceptPaymentForm = React.createRef();
    c04EstimatedDate = React.createRef();
    c05Percent = React.createRef();

    componentDidMount() {
      const palabra = this.props.cryptoCurrencyPayment
      const ArrayIdValue = palabra.split(' - ')
      let symbolCurrencyX = ArrayIdValue[0]
      this.setState({cryptoCurrencyX: this.props.cryptoCurrencyPayment, symbolCriptoCurrency: symbolCurrencyX})

      if (this.props.CurrencyType === 'FIAT Money'){
         const palabra = this.props.FIACurrency
         const ArrayIdValue = palabra.split(' - ')
         symbolCurrencyX = ArrayIdValue[0]
      }else{
        const palabra = this.props.cryptoCurrencyOrigin
        const ArrayIdValue = palabra.split(' - ')
        symbolCurrencyX = ArrayIdValue[0]
      }
      this.setState({symbolCurrencyX: symbolCurrencyX})

      Promise.all([this.getConceptsPaymentForms(),
                   this.getHeadPaymentForms(),
                   this.getDetailPaymentForms()])
        .then((resolve) =>{},(reject) =>{})
    }

    UNSAFE_componentWillMount() {
      const { userSession } = this.state
      let stacksNetX = ''
      let serverStacksX = ''
      if (userSession.isUserSignedIn()) {
        const user = userSession.loadUserData()
        this.setState({ user })
        if (!localStorage["serverStacks"]) {
          serverStacksX = 'MainNet'
        }else{
          serverStacksX = localStorage.getItem('serverStacks')
        }
        if (serverStacksX === 'MainNet'){
  	       stacksNetX =  user.profile.stxAddress.mainnet
        }
        if (serverStacksX === 'TestNet'){
  	       stacksNetX =  user.profile.stxAddress.testnet
        }
      }
      const {username} = userSession.loadUserData();
      if (username === '' || username === undefined || username === null){
        username_new(stacksNetX).then(val => this.setState({username: val})) ;
      }else{
        this.setState({username })
      }

      let languageX = localStorage.getItem('language')
      if (languageX === undefined || languageX === ''){
        this.setState({ language: 'English' })
        languageX = 'en'
      }else{
        this.setState({ language: languageX })
      }

      if (languageX === 'English'){this.setState({ isocode: "en", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'French'){this.setState({ isocode:  "fr", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Spanish'){this.setState({ isocode:  "es", dateFormatX:"dd/MM/yyyy"})}
      if (languageX === 'Portuguese'){this.setState({ isocode:  "pt", dateFormatX:"dd/MM/yyyy"})}
      if (languageX === 'Swedish'){this.setState({ isocode:  "sv", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Netherlands'){this.setState({ isocode:  "nl", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Russian'){this.setState({ isocode:  "ru", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Japanese'){this.setState({ isocode:  "ja_JP", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Chinese'){this.setState({ isocode: "zh", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'German'){this.setState({ isocode:  "de", dateFormatX:"MM/dd/yyyy"})}
      if (languageX === 'Italian'){this.setState({ isocode:  "it", dateFormatX:"MM/dd/yyyy"})}
    }

    handleDialog = (type, action) => {
      if (type === 'Error: 1001') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Error: 1002') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
      if (type === 'Warning: 2001') {
          if (action === 'cancel') {
            this.setState({ dialogOpen: false });
          }
      }
    }

    faltaDataConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: '------ Missing data ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "------ Données manquantes ------",dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: '------ Faltan Datos ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: '------ Dados ausentes ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: '------ Saknar data ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: '------ Ontbrekende gegevens ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: '------ Потерянная информация ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '------ 欠測データ ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '------ 缺失數據 ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: '------ Fehlende Daten ------',dialogTitle: 'Error: 1001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: '------ Dati mancanti ------',dialogTitle: 'Error: 1001',})}
    }

    faltaDataConfirm2(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: '------ You must select the concept of the payment method ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "------ Vous devez sélectionner le concept du moyen de paiement ------",dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: '------ Debe seleccionar el concepto de la forma de Pago ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: '------ Você deve selecionar o conceito do método de pagamento  ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: '------ Du måste välja begreppet betalningsmetod ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: '------ U moet het concept van de betalingsmethode selecteren ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: '------ Вы должны выбрать концепцию способа оплаты ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '------ お支払い方法のコンセプトを選択する必要があります ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '------ 您必須選擇付款方式的概念 ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: '------ Sie müssen das konzept der zahlungsmethode auswählen ------',dialogTitle: 'Warning: 2001',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: '------ Devi selezionare il concetto del metodo di pagamento ------',dialogTitle: 'Warning: 2001',})}
    }

    totalPorcentajeConfirm(){
      if (this.state.language === 'English'){this.setState({dialogOpen: true,dialogMessage: '------ Total percentage cannot exceed 100% ------',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'French'){this.setState({dialogOpen: true,dialogMessage: "------ Le pourcentage total ne peut pas dépasser 100% ------",dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Spanish'){this.setState({dialogOpen: true,dialogMessage: '------ Total porcentaje no puede superar el 100% ------',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Portuguese'){this.setState({dialogOpen: true,dialogMessage: '------ A porcentagem total não pode exceder 100% ------',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Swedish'){this.setState({dialogOpen: true,dialogMessage: '------ Den totala procentsatsen får inte överstiga 100% ------',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Netherlands'){this.setState({dialogOpen: true,dialogMessage: '------ Totaal percentage mag niet hoger zijn dan 100% ------',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Russian'){this.setState({dialogOpen: true,dialogMessage: '------ Общий процент не может превышать 100% ------',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Japanese'){this.setState({dialogOpen: true,dialogMessage: '------ 合計パーセンテージは100％を超えることはできません ------',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Chinese'){this.setState({dialogOpen: true,dialogMessage: '------ 總百分比不能超過 100% ------',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'German'){this.setState({dialogOpen: true,dialogMessage: '------ Gesamtprozentsatz darf 100% nicht überschreiten ------',dialogTitle: 'Error: 1002',})}
      if (this.state.language === 'Italian'){this.setState({dialogOpen: true,dialogMessage: '------ La percentuale totale non può superare il 100% ------',dialogTitle: 'Error: 1002',})}
    }

    handleChangeCryptoCurrency = (e) => {
      const palabra = this.c01CryptoCurrency.current.value
      const ArrayIdValue = palabra.split(' - ')
      const symbolCurrencyX = ArrayIdValue[0]
      this.setState({cryptoCurrencyX: this.c01CryptoCurrency.current.value, symbolCriptoCurrency: symbolCurrencyX})
    }

    handleChangeNumber = (e,chk) => {

      if (chk==='Amount'){
         this.c05Percent.current.value = null
         this.setState({amount: null})
      }
      if (chk==='Percent'){
        if (this.props.amountPayment.length !== 0){
            if (parseFloat(this.c05Percent.current.value) < 0){
              this.c05Percent.current.value = '0'
            }
            if (parseFloat(this.c05Percent.current.value) >= 100){
              this.c05Percent.current.value = '100'
            }
            if (this.state.totalpercent + parseFloat(this.c05Percent.current.value) >= 100){
              const totalp = 100 - this.state.totalpercent
              this.c05Percent.current.value = totalp.toString()
            }
            this.setState({amount: +((Math.round((parseFloat(this.props.amountPayment) * parseFloat(this.c05Percent.current.value) / 100) + "e+2") + "e-2"))})
        }
      }
    }

    handleBlurNumber(e,chk) {
    }

    handleInputNumber(e,chk) {
      if (chk==='Percent'){
        if (this.c05Percent.current.value.length===0){
           this.c05Percent.current.value = ''
        }
      }
    }

    handleChangeConceptPaymentForm(e){
    }

    handleDate(val, chk){
      if (chk==='estimatedDateX'){
        this.setState({estimatedDate: new Date(val)})
      }
    }

    handleChangeCriptoFIAT = (e) => {
      this.setState({typeCurrency2: this.c01TypeCurrency.current.value});
      if (this.c01TypeCurrency.current.value === 'FIAT Money'){
         this.setState({cryptoCurrencyX: 'USD - US Dollar', symbolCriptoCurrency: 'USD'})
      }else{
         this.setState({cryptoCurrencyX: 'STX - Stacks', symbolCriptoCurrency: 'STX'})
      }
    }

    newPaymentForm = (e,code) => {
          const {userSession} = this.state
          const storage = new Storage({ userSession });
          const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
          const isocode = this.state.isocode
          const optionsDate = {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "numeric"
          };
          e.preventDefault();
          if (this.c01CryptoCurrency.current.value.length === 0) {
             this.c01CryptoCurrency.current.value = this.props.cryptoCurrencyPayment
          }
          if(this.props.amountPayment.length === 0 || this.c03ConceptPaymentForm.current.value.length === 0 || this.c05Percent.current.value.length === 0  || this.c05Percent.current.value <= 0) {
               this.setState({error: true});
               this.faltaDataConfirm2()
               return;
          }

          const c01CryptoCurrency = this.c01CryptoCurrency.current.value
          const c02Amount = this.props.amountPayment

          const c03ConceptPaymentForm = this.c03ConceptPaymentForm.current.value

          const c04EstimatedDate =  this.state.estimatedDate.toLocaleString(isocode,optionsDate)
          const c05Percent = this.c05Percent.current.value
          const amount = this.state.amount

          this.setState({error: false})
          const {indice} = this.state
          let jsonPaymentFormX = this.state.jsonPaymentForm

          let jsonBlockstack2 = ''
          let putPaymentFormX = true
          if (!this.state.modifying){
            if (jsonPaymentFormX.length === 0){
              jsonBlockstack2 =`[{"concept":"${c03ConceptPaymentForm}","estimateddate":"${c04EstimatedDate}","percent":"${c05Percent}","amount":"${amount}","paid":false}]`
              this.setState({totalpercent: parseFloat(this.c05Percent.current.value), totalAmount:parseFloat(amount)})
            }else{
              jsonPaymentFormX = JSON.stringify(jsonPaymentFormX)
              if (jsonPaymentFormX.substring(0,1)==='"') {
                 jsonPaymentFormX = jsonPaymentFormX.substring(1,jsonPaymentFormX.length - 1);
              }
              if (jsonPaymentFormX.substring(0,1)==='[') {
                 jsonPaymentFormX = jsonPaymentFormX.substring(1,jsonPaymentFormX.length - 1);
              }

              const jsonBlockstack2X = this.state.jsonPaymentForm
              let totalPercentX = 0
              let totalAmountX = 0
              jsonBlockstack2X.map((todo, i) => {
                totalPercentX = totalPercentX + parseFloat(todo.percent)
                totalAmountX = totalAmountX + parseFloat(todo.amount)
              })
              if (totalPercentX + parseFloat(c05Percent) > 100){
                putPaymentFormX = false
              }
              if (putPaymentFormX){
                 jsonBlockstack2 =`[${jsonPaymentFormX},{"concept":"${c03ConceptPaymentForm}","estimateddate":"${c04EstimatedDate}","percent":"${c05Percent}","amount":"${amount}","paid":false,"txid":"","status":""}]`
                 this.setState({totalpercent: parseFloat(this.c05Percent.current.value) + totalPercentX, totalAmount:parseFloat(amount) + totalAmountX })
              }else{
                this.totalPorcentajeConfirm()
              }
            }
            this.setState({jsonPaymentForm:JSON.parse(jsonBlockstack2)})
          }else{

            let totalPercentX = 0
            let totalAmountX = 0
            jsonPaymentFormX.map((todo, i) => {
               if (indice === i){
                 totalPercentX = totalPercentX + parseFloat(c05Percent)
                 totalAmountX = totalAmountX + parseFloat(amount)
               }else{
                 totalPercentX = totalPercentX + parseFloat(todo.percent)
                 totalAmountX = totalAmountX + parseFloat(todo.amount)
               }
            })
            if (totalPercentX > 100){
              putPaymentFormX = false
            }
            if (putPaymentFormX){
              jsonPaymentFormX.map((todo, i) => {
                 if (indice === i){
                   todo.concept = c03ConceptPaymentForm
                   todo.estimateddate = c04EstimatedDate.toString()
                   todo.percent = c05Percent.toString()
                   todo.amount = amount.toString()
                 }
              })
              this.setState({totalpercent: totalPercentX, totalAmount: totalAmountX })
            }
            jsonBlockstack2 = jsonPaymentFormX
            this.setState({modifying:false})
          }
          if (putPaymentFormX){
            putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_detail_payment_form.json`, 'Crosscheck_PaymentForm', JSON.stringify(jsonBlockstack2), JSON.stringify(options))
              .then(() => {
                let originMoneyX = ''
                if (this.props.CurrencyType === 'FIAT Money'){
                   originMoneyX = this.props.FIACurrency
                }else{
                  originMoneyX = this.props.cryptoCurrencyOrigin
                }
                const typeCurrencyPaymentX =  this.state.typeCurrency2
                jsonBlockstack2 =`[{"cryptocurrency":"${this.state.cryptoCurrencyX}","symbolcryptocurrency":"${this.state.symbolCriptoCurrency}","amount":"${c02Amount}","originMoney":"${originMoneyX}","symbolcurrency":"${this.state.symbolCurrencyX}","typecurrencypayment":"${typeCurrencyPaymentX}"}]`
                putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_head_payment_form.json`, 'Crosscheck_PaymentForm', JSON.stringify(jsonBlockstack2), JSON.stringify(options))
                  .then(() => {})
              })
          }else{
            this.totalPorcentajeConfirm()
          }
          this.setState({estimateddate:new Date(), amount:0})
          this.c03ConceptPaymentForm.current.value = ''
          this.c05Percent.current.value = ''
    }

    editPaymentForm = (e,optx) => {
       const {jsonPaymentForm} = this.state
       const {concept} = jsonPaymentForm[optx]
       const {estimateddate} = jsonPaymentForm[optx]
       const {percent} = jsonPaymentForm[optx]
       const {amount} = jsonPaymentForm[optx]

       this.c03ConceptPaymentForm.current.value = concept
       this.c05Percent.current.value = percent
       this.setState({estimateddate:estimateddate, amount:amount, modifying:true, indice:optx, totalpercent: this.state.totalpercent - parseFloat(percent), totalAmount: this.state.totalAmount - parseFloat(amount) })

    }

    deletePaymentForm = (e,optx, codeX, percentX, amountX) => {
         const {userSession} = this.state
         const storage = new Storage({ userSession });
         const options = {contentType: 'application/json', encrypt: false, dangerouslyIgnoreEtag: true }
         const setting = this.state.jsonPaymentForm
         setting.splice(optx,1);
         this.setState({jsonPaymentForm: setting})
         const jsonBlockstack1 = JSON.stringify(setting)
         putFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_detail_payment_form.json`, 'Crosscheck_PaymentForm', jsonBlockstack1, JSON.stringify(options))
           .then(() => {
             this.setState({totalpercent: this.state.totalpercent - parseFloat(percentX), totalAmount: this.state.totalAmount - Number(amountX) })

           })
         this.c03ConceptPaymentForm.current.value = ''
         this.c05Percent.current.value = ''
         this.setState({estimateddate:new Date(), amount:0, modifying:false})
    }

    managementConceptPaymentForm = () => {
      this.setState({displayConceptManagement: true, displayPaymentForm: false })
    }

    exitManagementConceptPaymentForm = () => {
      Promise.all([this.getConceptsPaymentForms()])
        .then((resolve) =>{},(reject) =>{})
      this.setState({displayConceptManagement: false, displayPaymentForm: true })
    }

    getConceptsPaymentForms(){
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = { decrypt: false, verify: false }
       let jsonBlockstack4 = []
       return new Promise ((resolve, reject) =>{
         getFileDB(this.state.username, `concepts_payment_forms.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonConceptPaymentForm:jsonBlockstack6});
              resolve(true);
            } else {
              resolve(true);
            }
          })
           .catch(error => {
               resolve(true);
          });
       });
    }

    getDetailPaymentForms(){
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = { decrypt: false, verify: false }
       let jsonBlockstack4 = []
       return new Promise ((resolve, reject) =>{
         getFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_detail_payment_form.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)

              let totalPercentX = 0
              let totalAmountX = 0
              jsonBlockstack6.map((todo, i) => {
                 totalPercentX = totalPercentX + parseFloat(todo.percent)
                 totalAmountX = totalAmountX + parseFloat(todo.amount)
              })
              this.setState({jsonPaymentForm:jsonBlockstack6, totalpercent: totalPercentX, totalAmount: totalAmountX })
              resolve(true);
            } else {
              resolve(true);
            }
          })
           .catch(error => {
               resolve(true);
          });
       });
    }

    getHeadPaymentForms(){
       const {userSession} = this.state
       const storage = new Storage({ userSession });
       const options = { decrypt: false, verify: false }
       this.c01CryptoCurrency.current.value = this.props.cryptoCurrencyPayment
       //this.c01TypeCurrency.current.value = this.state.typeCurrency2
       let jsonBlockstack4 = []
       return new Promise ((resolve, reject) =>{
         getFileDB(this.state.username, `${this.props.typeContract}_${this.props.numberContract}_head_payment_form.json`)
          .then((fileContents) => {
            if(fileContents) {
              const jsonBlockstack1 = JSON.parse(fileContents)
              const jsonBlockstack2 = jsonBlockstack1.data
              let jsonBlockstack5 = '[]'
              if (jsonBlockstack2 !== null){
                 const jsonBlockstack3 = jsonBlockstack2.dataobject
                 const jsonBlockstack4 = jsonBlockstack3.replace(/\\/g,"");
                 if (jsonBlockstack4.substring(0,1) === '"') {
                    jsonBlockstack5 = jsonBlockstack4.substring(1,jsonBlockstack4.length - 1)
                 } else {
                    jsonBlockstack5 = jsonBlockstack4
                 }
              }
              let jsonBlockstack6 = JSON.parse(jsonBlockstack5)
              this.setState({jsonHeadPaymentForm:jsonBlockstack6});
              if (jsonBlockstack6.length > 0){
                this.c01CryptoCurrency.current.value = jsonBlockstack6[0].cryptocurrency
                this.setState({cryptoCurrencyX: jsonBlockstack6[0].cryptocurrency, symbolCriptoCurrency: jsonBlockstack6[0].symbolcryptocurrency })
                if (jsonBlockstack6[0].typecurrencypayment !== undefined){
                   this.c01TypeCurrency.current.value = jsonBlockstack6[0].typecurrencypayment
                   this.setState({typeCurrency2: jsonBlockstack6[0].typecurrencypayment})
                }
                //this.c02Amount.current.value = jsonBlockstack6[0].amount
              }
              resolve(true);
            } else {
              resolve(true);
            }
          })
           .catch(error => {
               resolve(true);
          });
       });
    }

    exitProgram(){
       this.props.returnProps();
    }

    render() {

        let titleX = 'Payment Form'
        let placeholderX1 = "Select an Option"
        if (this.state.language === 'English'){titleX = "Method of payment";placeholderX1 = "Select an Option"}
        if (this.state.language === 'French'){titleX = "Formulaire de Paiement";placeholderX1 = "Sélectionner unproe.ide Option"}
        if (this.state.language === 'Spanish'){titleX = "Forma de Pago";placeholderX1 = "Seleccione una Opción"}
        if (this.state.language === 'Portuguese'){titleX = "Formulário de Pagamento";placeholderX1 = "Selecione uma Opção"}
        if (this.state.language === 'Swedish'){titleX = "Betalningsformulär";placeholderX1 = "Välj ett Alternativ"}
        if (this.state.language === 'Netherlands'){titleX = "Betalingsvorm";placeholderX1 = "Kies een Optie"}
        if (this.state.language === 'Russian'){titleX = "Форма оплаты";placeholderX1 = "Выберите опцию"}
        if (this.state.language === 'Japanese'){titleX = "お支払いフォーム";placeholderX1 = "オプションを選択"}
        if (this.state.language === 'Chinese'){titleX = "用户组";placeholderX1 = "选择一个选项"}
        if (this.state.language === 'German'){titleX = "Zahlungsformular";placeholderX1 = "Wähle eine Option"}
        if (this.state.language === 'Italian'){titleX = "Modulo di Pagamento";placeholderX1 = "Seleziona un'opzione"}

        const messageSubtittle = `${this.props.typeContract}/${this.props.numberContract} - ${this.props.subjectContract}`
        const {username} = this.state

        const currencyPaymentX = currencyPayment.CcyNtry
        const currencyFIATPaymentX = currencyFIATPayment.CcyNtry

        const jsonPaymentFormX = this.state.jsonPaymentForm
        let disabledX = false
        if (jsonPaymentFormX.length > 0){
          disabledX = true
        }

        let bloquedX = false
        if (this.props.jsonStatusLifeCycle !== 'Draft'){
          bloquedX = true
          disabledX = true
        }

        const jsonConceptPaymentFormX = this.state.jsonConceptPaymentForm

        let item = 0

        const totalpercentX = +(Math.round(this.state.totalpercent + "e+2")  + "e-2");
        const totalamountX = +(Math.round(this.state.totalAmount + "e+2")  + "e-2");
        //const totalpercentX =  Math.round( this.state.totalpercent * 1e2 ) / 1e2;
        //const totalamountX =  Math.round( this.state.totalAmount * 1e2 ) / 1e2;

        const displayConceptManagementX = this.state.displayConceptManagement
        const displayPaymentFormX  = this.state.displayPaymentForm

        const cryptoCurrencyX = this.state.cryptoCurrencyX

        let originMoneyX = ''
        let symbolCurrencyX = ''

        if (this.props.CurrencyType === 'FIAT Money'){
           originMoneyX = this.props.FIACurrency
           const palabra = this.props.FIACurrency
           const ArrayIdValue = palabra.split(' - ')
           symbolCurrencyX = ArrayIdValue[0]
        }else{
          originMoneyX = this.props.cryptoCurrencyOrigin
          const palabra = this.props.cryptoCurrencyOrigin
          const ArrayIdValue = palabra.split(' - ')
          symbolCurrencyX = ArrayIdValue[0]
        }

        let typeCurrency2X = false
        if (this.state.typeCurrency2 === 'FIAT Money'){
           typeCurrency2X = true
        }

        return (
          <Container fluid className="main-content-container px-4">

            <Confirmacion
                handleDialog={this.handleDialog}
                dialogMessage={this.state.dialogMessage}
                dialogOpen={this.state.dialogOpen}
                dialogTitle={this.state.dialogTitle}
            />
            <Confirmacion2
                handleDialog={this.handleDialog}
                dialogMessage={this.state.dialogMessage}
                dialogOpen={this.state.dialogOpenPrint}
                dialogTitle={this.state.dialogTitle}
            />
            <Confirmacion3
                handleDialog={this.handleDialog}
                dialogMessage={this.state.dialogMessage}
                dialogOpen={this.state.dialogOpenContinue}
                dialogTitle={this.state.dialogTitle}
            />
            <ConfirmacionWarning
                handleDialog={this.handleDialog}
                dialogMessage={this.state.dialogMessage}
                dialogOpen={this.state.dialogOpenWarning}
                dialogTitle={this.state.dialogTitle}
            />
            <ConfirmacionAccept
                handleDialog={this.handleDialog}
                dialogMessage={this.state.dialogMessage}
                dialogOpen={this.state.dialogOpenAccept}
                dialogTitle={this.state.dialogTitle}
            />
            <Row noGutters className="page-header py-4">
                <TituloPagina2
                  sm="4"
                  title={titleX}
                  subtitle={messageSubtittle}
                  className="text-sm-left"
                />
            </Row>
            {displayConceptManagementX ?
              <ConceptManagement exitManagementConceptPaymentForm={this.exitManagementConceptPaymentForm} colorDark={this.props.colorDark}/>
            : null }
            { displayPaymentFormX ?
                <Row>
                  <Col lg="2">
                    <Form style={{fontSize:13}}>
                      <Row>
                        <label htmlFor="listUserGroup"><FormattedMessage id="payment.amountorigin" /></label>
                        <FormInput
                          id="Amount"
                          type="number"
                          placeholder={this.props.amountPayment}
                          className="text-right"
                          disabled={true}
                          style={{fontSize:16, color:'black'}}
                          onChange={e=>this.handleChangeNumber(e, "Amount")}
                          onBlur={e=>this.handleBlurNumber(e, "Amount")}
                          onInput={e=>this.handleInputNumber(e, "Amount")}
                        />
                      </Row>
                      <Row>&nbsp;</Row>
                      <Row>
                        <label htmlFor="listUserGroup"><FormattedMessage id="payment.currency" /></label>
                        <FormInput
                          id="Currency"
                          type="text"
                          placeholder={originMoneyX}
                          disabled={true}
                          style={{fontSize:16, color:'black'}}
                        />
                      </Row>
                      <Row>&nbsp;</Row>
                      <Row>&nbsp;</Row>
                      <Row>
                        <label htmlFor="CryptoFiat"><FormattedMessage id="payment.paymentcurrency" /></label>
                        <FormSelect
                            id="CryptoFiat"
                            style={{fontSize:16, color:'black', backgroundColor:"#F2EACE"}}
                            innerRef={this.c01TypeCurrency}
                            placeholder="Currency Type"
                            disabled={bloquedX}
                            onChange={e=>this.handleChangeCriptoFIAT(e)}
                          >
                            <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} value={`Crypto Currency`}>{`Crypto Currency`}</option>
                            <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} value={`FIAT Money`}>{`FIAT Money`}</option>
                        </FormSelect>
                      </Row>
                      <Row>&nbsp;</Row>
                      <Row>
                        <FormSelect
                            id="cryptocurrency"
                            innerRef={this.c01CryptoCurrency}
                            placeholder=""
                            disabled={bloquedX}
                            onChange={this.handleChangeCryptoCurrency}
                            style={{fontSize:16, color:'black', backgroundColor:"#F2EACE"}}
                        >
                            {typeCurrency2X ?
                              <>
                                {currencyFIATPaymentX.map((todo, i) => {
                                  if (this.state.cryptoCurrencyX === `${todo.Ccy} - ${todo.CcyNm}`){
                                    return (
                                       <option selected style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={i} value={`${todo.Ccy} - ${todo.CcyNm}`}>{`${todo.Ccy} - ${todo.CcyNm}`}</option>
                                    )
                                  }else{
                                    return (
                                       <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={i} value={`${todo.Ccy} - ${todo.CcyNm}`}>{`${todo.Ccy} - ${todo.CcyNm}`}</option>
                                    )
                                  }
                                })}
                              </>
                            :
                              <>
                                {currencyPaymentX.map((todo, i) => {
                                  if (this.state.cryptoCurrencyX === `${todo.Ccy} - ${todo.CcyNm}`){
                                    return (
                                       <option selected style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={i} value={`${todo.Ccy} - ${todo.CcyNm}`}>{`${todo.Ccy} - ${todo.CcyNm}`}</option>
                                    )
                                  }else{
                                    return (
                                       <option style={this.props.colorDark ? {color:'darkblue'} : {color:'darkblue'}} key={i} value={`${todo.Ccy} - ${todo.CcyNm}`}>{`${todo.Ccy} - ${todo.CcyNm}`}</option>
                                    )
                                  }
                                })}
                              </>
                            }
                        </FormSelect>
                      </Row>
                      <Row>&nbsp;</Row>
                      <Row>&nbsp;</Row>
                      <Row form>
                          <Button pill theme="info" onClick={e=>this.managementConceptPaymentForm(e)}><FormattedMessage id="configuration.managementconcept" /></Button>
                      </Row>
                      <Row>&nbsp;</Row>
                      <Row form>
                         <Button pill theme="secondary" onClick={this.exitProgram}><FormattedMessage id="button.contractlist" /></Button>
                      </Row>
                      <br></br>
                    </Form>
                  </Col>
                  <Col lg="10">
                    <Row>
                      <Col md="1"></Col>
                      <Col md="10">
                        <Table size="sm" className="text-center" responsive borderless>
                           <thead>
                             <tr>
                               <th style={{width: "5%"}}></th>
                               <th style={{width: "25%", fontsize:16}}><FormattedMessage id="payment.concept" /></th>
                               <th style={{width: "17%", fontsize:16}}><FormattedMessage id="payment.estimateddate" /></th>
                               <th style={{width: "17%", fontsize:16}}><FormattedMessage id="payment.percent" /></th>
                               <th style={{width: "17%", fontsize:16}}><FormattedMessage id="payment.amount" /></th>
                               <th style={{width: "19%"}}></th>
                               <th></th>
                             </tr>
                           </thead>
                           <tbody>
                             <tr>
                               <td></td>
                               <td>
                                 <FormSelect
                                     id="ConceptPaymentForm"
                                     innerRef={this.c03ConceptPaymentForm}
                                     placeholder="Select an Option"
                                     onChange={e=>this.handleChangeConceptPaymentForm(e)}
                                     disabled={false}
                                     style={{backgroundColor:"#F2EACE"}}
                                   >
                                   <option value={``} selected disabled hidden>{placeholderX1}</option>
                                   {jsonConceptPaymentFormX.map((todo, key) => {
                                       return (
                                         <option style={{color:'darkblue'}} key={key} value={todo.description}>{todo.description}</option>
                                   )})}
                                 </FormSelect>
                               </td>
                               <td>
                                 <DatePicker
                                   id="EstimatedDate"
                                   dateFormat={this.state.dateFormatX}
                                   innerRef={this.c04EstimatedDate}
                                   placeholder=""
                                   disabled={false}
                                   //size="sm"
                                   selected={this.state.estimatedDate}
                                   onChange={val=>this.handleDate(val,'estimatedDateX')}
                                   dropdownMode="select"
                                 />
                               </td>
                               <td>
                                 <FormInput
                                   id="Percent"
                                   type="number"
                                   innerRef={this.c05Percent}
                                   placeholder=""
                                   className="text-right"
                                   onChange={e=>this.handleChangeNumber(e, "Percent")}
                                   onBlur={e=>this.handleBlurNumber(e, "Percent")}
                                   onInput={e=>this.handleInputNumber(e, "Percent")}
                                 />

                               </td>
                               <td>
                                 <FormInput
                                   id="Amount"
                                   type="number"
                                   innerRef={this.state.amount}
                                   placeholder=""
                                   disabled={true}
                                   placeholder={this.state.amount}
                                   className="text-right"
                                 />
                               </td>
                               <td>
                                 {!this.state.modifying ?
                                    <Button pill theme="warning" disabled={bloquedX} onClick={e=>this.newPaymentForm(e)}>---<FormattedMessage id="configuration.add" />---</Button>
                                 :
                                    <Button pill theme="warning" disabled={bloquedX} onClick={e=>this.newPaymentForm(e)}>---<FormattedMessage id="configuration.modify" />---</Button>
                                 }
                               </td>
                               <td></td>
                             </tr>
                           </tbody>
                        </Table>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                    <Row>&nbsp;</Row>
                    <Row>&nbsp;</Row>
                    <Row>
                      <Col md="1"></Col>
                      <Col md="10">
                         <Table size="sm" className="text-center" responsive hover striped>
                            <thead>
                              <tr>
                                <th style={{width: "5%", fontsize:16}}><FormattedMessage id="payment.item" /></th>
                                <th style={{width: "25%", fontsize:16}}><FormattedMessage id="payment.concept" /></th>
                                <th style={{width: "17%", fontsize:16}}><FormattedMessage id="payment.estimateddate" /></th>
                                <th style={{width: "17%", fontsize:16}}><FormattedMessage id="payment.percent" /></th>
                                <th style={{width: "17%", fontsize:16}}><FormattedMessage id="payment.amount" /></th>
                                <th style={{width: "19%", fontsize:16}}><FormattedMessage id="configuration.edit" /></th>
                                <th><FormattedMessage id="configuration.delete" /></th>
                              </tr>
                            </thead>
                            <tbody>
                              {jsonPaymentFormX.map((todo, i) => {
                                item = i
                                return (
                                    <tr key={i} style={ this.props.colorDark ? { color:'white'} : {color:'black'}}>
                                        <td>{++item}</td>
                                        <td>{todo.concept}</td>
                                        <td>{todo.estimateddate}</td>
                                        <td>{`${todo.percent}%`}</td>
                                        <td>{`${todo.amount} ${symbolCurrencyX}`}</td>
                                        <td><Button pill theme="success" disabled={bloquedX} onClick={e=>this.editPaymentForm(e, i)}><FontAwesomeIcon icon={faEdit} /></Button></td>
                                        <td><Button pill theme="danger" disabled={bloquedX} onClick={e=>this.deletePaymentForm(e, i, todo.code, todo.percent, todo.amount)}><FontAwesomeIcon icon={faTimes} /></Button></td>
                                    </tr>
                              )})}
                            </tbody>
                          </Table>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                    <Row>
                      <Col md="1"></Col>
                      <Col md="10">
                         <Table size="sm" className="text-center" responsive hover striped>
                            <thead>
                              <tr>
                                <th style={{width: "5%"}}></th>
                                <th style={{width: "22.5%"}}></th>
                                <th style={this.props.colorDark ? {width: "16%", color: "orange"} : {width: "16%", color: "blue"}}><FormattedMessage id="payment.total" /></th>
                                <th style={this.props.colorDark ? {width: "17%", color: "orange"} : {width: "17%", color: "blue"}}>{`${totalpercentX}%`}</th>
                                <th style={this.props.colorDark ? {width: "17%", color: "orange"} : {width: "17%", color: "blue"}}>{`${totalamountX} ${symbolCurrencyX}`}</th>
                                <th style={{width: "22.5%"}}></th>
                                <th></th>
                              </tr>
                            </thead>
                          </Table>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                    <Row>
                      <Col md="1"></Col>
                      <Col md="10">
                        <Container fluid className="px-0">
                            <Card theme="warning" >
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      &nbsp;&nbsp;<div className="fa fa-info"></div>
                                    </td>
                                    <td style={{fontSize: 11, color:"black"}}>
                                       &nbsp;&nbsp;<FormattedMessage id="payment.descriptipocambio" />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Card>
                        </Container>
                      </Col>
                      <Col md="1"></Col>
                    </Row>
                    <br></br>
                  </Col>
                </Row>
            : null }
          </Container>

        );
    }
}

export default ContractPaymentForm;
